import { useState } from 'react';
import { Card, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Iconify from '../../../../components/Iconify';
import Api from '../../../../http/serviceApi';
import ApiAdmin from '../../../../http/adminHttp/serviceApiAdmin';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { imageToBase64 } from '../../../../utils';
import { SET_LAST_UPDATE_TICKET } from '../../../../store/actionTypes';
import { useDispatch } from 'react-redux';

export default function ViewTicketForm({ onReload, ticketId, isAdmin = false }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  // -------------------------
  const [message, setMessage] = useState('');
  const [attach, setAttach] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // -------------------------
  const onAttach = (file) => {
    if (!file) {
      return;
    }

    const allowedFormats = ['image/jpeg', 'image/png', 'image/tiff', 'image/jpg'];
    const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!allowedFormats.includes(file.type)) {
      enqueueSnackbar(t('Invalid file format! Allowed formats: jpg, png, tiff, jpeg'), { variant: 'warning' });
      return;
    }

    if (file.size > maxFileSize) {
      enqueueSnackbar(t('File size exceeds 2MB limit!'), { variant: 'warning' });
      return;
    }

    setAttach(file);
  };

  // -------------------------
  const onSend = async (e) => {
    e.preventDefault();

    if (!message) {
      return enqueueSnackbar(t('Please enter the message!'), { variant: 'warning' });
    }

    setIsSubmitting(true);

    try {
      let attachFile = null;
      if (attach) {
        attachFile = await imageToBase64(attach);
      }

      let data;
      if (isAdmin) {
        data = await ApiAdmin.addTicketMessage({ message, ticketId, image: attachFile });
      } else {
        data = await Api.addTicketMessage({ message, ticketId, image: attachFile });
      }

      if (data?.status === 'SUCCESS') {
        enqueueSnackbar(t('The new message was successfully sent.'), { variant: 'success' });
        setMessage('');
        setAttach(null);
        onReload();

        // reload ticket counter in nav menu
        if (isAdmin) {
          dispatch({ type: SET_LAST_UPDATE_TICKET, payload: new Date() });
        }
      }
    } catch (e) {}

    setIsSubmitting(false);
  };

  // -------------------------
  return (
    <form onSubmit={onSend}>
      <Card sx={{ py: 0, pb: { xs: 3, md: 0 } }}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 3, md: 1 }}
          alignItems={'flex-start'}
          sx={{ height: { xs: 'auto', md: 98 }, pt: 2.9 }}
        >
          <Stack direction={'row'} spacing={1} sx={{ width: '100%' }}>
            <IconButton component='label' sx={{ backgroundColor: 'grey.700', width: 52, height: 52 }}>
              <Iconify icon={'fluent:attach-24-regular'} color='grey.0' sx={{ width: 17, height: 17 }} />
              <TextField type='file' hidden onChange={(e) => onAttach(e.target?.files?.[0])} />
            </IconButton>

            <Stack sx={{ width: '100%' }}>
              <TextField
                fullWidth
                variant='outlined'
                placeholder={t('Type a message')}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                sx={{
                  '& .MuiInputBase-root': {
                    borderRadius: '32px',
                    backgroundColor: 'grey.700',
                  },
                  '& fieldset': {
                    border: 'none',
                  },
                  '& input': {
                    py: 1.5,
                    px: 2,
                  },
                }}
              />

              {attach && (
                <Stack direction='row' alignItems={'center'} sx={{ pl: 2 }} spacing={1}>
                  <IconButton sx={{ p: 0 }} onClick={() => setAttach(null)}>
                    <Iconify icon='eva:close-outline' color='error.main' sx={{ width: 15, height: 15 }} />
                  </IconButton>
                  <Typography variant='body4' color='grey.300' sx={{ pt: 0.1 }}>
                    {attach?.name || ''}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>

          <LoadingButton
            fullWidth
            size='large'
            variant='contained'
            type='submit'
            sx={{ maxWidth: { xs: '100%', md: 150 }, typography: 'body1' }}
            loading={isSubmitting}
          >
            {t('Send')}
          </LoadingButton>
        </Stack>
      </Card>
    </form>
  );
}
