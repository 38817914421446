import { Avatar, Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Iconify from '../../../../components/Iconify';
import { base64ToImage } from '../../../../utils';
import Image from '../../../../components/Image';

export default function ViewTicketMessageCard({
  isSelf = true,
  message = '',
  avatar = '',
  messageImage = null,
  createDate,
}) {
  const language = useSelector((store) => store.language);
  const { t } = useTranslation();

  const openImage = () => {
    var base64Image = base64ToImage(messageImage);

    // open new window and display image
    var newWindow = window.open();
    var img = document.createElement('img');
    img.src = base64Image;
    newWindow.document.body.appendChild(img);
  };
  const showImage = () => {
    var base64Image = base64ToImage(messageImage);

    var img = document.createElement('img');
    img.src = base64Image;
    return img.src;
  };

  return (
    <>
    <Stack
      direction={isSelf ? 'row' : 'row-reverse'}
      alignSelf={isSelf ? 'flex-start' : 'flex-end'}
      spacing={2}
      alignItems={'flex-start'}
      sx={{ mb: 2, maxWidth: { xs: '95%', md: '80%' }, mx: 4 }}
    >
      <Box>
        {' '}
        <Avatar sx={{ bgcolor: 'grey.250', mt: 1 }}></Avatar>
        <Typography sx={{ pt: 1, fontSize: '13px !IMPORTANT', textAlign: 'center' }} color={'grey.100'}>
          {t(isSelf ? 'Admin' : 'User')}
        </Typography>
      </Box>

      <Stack>
        <Stack
          sx={{
            ...(isSelf && { backgroundColor: '#5D6588' }),
            ...(!isSelf && { backgroundColor: '#07baaedb' }),
            borderRadius: isSelf ? '12px 12px 12px 0px' : '12px 12px 0px 12px',
            px: 1.5,
            py: 1.5,
            minWidth: 100,
          }}
        >
          <Typography variant='body2' color={'grey.100'}>
            {message}
          </Typography>

        </Stack>

        {
          !!messageImage ? null : <Typography sx={{ mt: '4px' }} variant='body3' color={'grey.100'}>
            {moment(createDate).locale(language).format('HH : mm')}
          </Typography>
        }

      </Stack>
    </Stack>

      {messageImage && (
      <Stack
        direction={isSelf ? 'row' : 'row-reverse'}
        alignSelf={isSelf ? 'flex-start' : 'flex-end'}
        spacing={2}
        alignItems={'flex-start'}
        sx={{ mb: 2, maxWidth: { xs: '95%', md: '80%' }, mx: 4 }}
      >
        <Box sx={{opacity:0}}>
          {' '}
          <Avatar sx={{ bgcolor: 'grey.250', mt: 1 }}></Avatar>
          <Typography sx={{ pt: 1, fontSize: '13px !IMPORTANT', textAlign: 'center' }} color={'grey.100'}>
            {t(isSelf ? 'Admin' : 'User')}
          </Typography>
        </Box>

        <Stack>
          <Stack
            sx={{
              ...(isSelf && { backgroundColor: '#5D6588' }),
              ...(!isSelf && { backgroundColor: '#07baaedb' }),
              borderRadius: isSelf ? '12px 12px 12px 0px' : '12px 12px 0px 12px',
              px: 1.5,
              py: 1.5,
              minWidth: 100,
            }}
          >

            {messageImage && (
              <Stack
                direction='row'
                alignItems={'end'}
                spacing={1}
                sx={{ mt: 2, cursor: 'pointer' }}
                onClick={openImage}
              >
                <Iconify icon={'fluent:attach-24-regular'} color='grey.0' sx={{ width: 17, height: 17 }} />
                <Image src={showImage()} sx={{ width: '100%', height: 'auto', maxWidth: '250px' }} />

                {/* <Typography variant='body3' sx={{ '&:hover': { textDecoration: 'underline' } }}>
                {t('Attach File')}
              </Typography> */}
              </Stack>
            )}
          </Stack>

          <Typography sx={{ mt: '4px' }} variant='body3' color={'grey.100'}>
            {moment(createDate).locale(language).format('HH : mm')}
          </Typography>
        </Stack>
      </Stack> )}
    </>
  );
}
