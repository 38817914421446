import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';

// ----------------------------------------------------------------------

// const FONT_PRIMARY = 'Public Sans, sans-serif'; // Google Font
export const FONT_PRIMARY = 'Graphik, Poppins, Iransans, sans-serif';
export const FONT_SECONDARY = 'Poppins, Graphik, Iransans, sans-serif';
export const FONT_RTL = 'Iransans, Poppins, Graphik, sans-serif';

const typography = (fontFamily = null) => {
  const main = {
    fontFamily: fontFamily || FONT_PRIMARY,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(42),
      ...responsiveFontSizes({ xs: 30, sm: 32, md: 38, lg: 42 }),
    },
    h2: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(39),
      ...responsiveFontSizes({ xs: 33, sm: 33, md: 36, lg: 39 }),
    },
    h3: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(36),
      ...responsiveFontSizes({ xs: 32, sm: 32, md: 34, lg: 36 }),
    },
    h4: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(32),
      ...responsiveFontSizes({ xs: 28, sm: 28, md: 30, lg: 32 }),
    },
    h5: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(25),
      ...responsiveFontSizes({ xs: 20, sm: 20, md: 23, lg: 25 }),
    },
    h6: {
      fontWeight: 700,
      lineHeight: 28 / 18,
      fontSize: pxToRem(20),
      ...responsiveFontSizes({ xs: 16, sm: 16, md: 18, lg: 20 }),
    },
    subtitle1: {
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: pxToRem(18),
      ...responsiveFontSizes({ xs: 14, sm: 14, md: 16, lg: 18 }),
    },
    subtitle2: {
      fontWeight: 600,
      lineHeight: 22 / 16,
      fontSize: pxToRem(16),
      ...responsiveFontSizes({ xs: 12, sm: 12, md: 14, lg: 16 }),
    },
    subtitle3: {
      fontWeight: 600,
      lineHeight: 22 / 14,
      fontSize: pxToRem(14),
      ...responsiveFontSizes({ xs: 10, sm: 10, md: 12, lg: 14 }),
    },
    subtitle4: {
      fontWeight: 600,
      lineHeight: 22 / 12,
      fontSize: pxToRem(12),
      ...responsiveFontSizes({ xs: 10, sm: 10, md: 11, lg: 12 }),
    },
    body1: {
      lineHeight: 1.5,
      fontWeight: 400,
      fontSize: pxToRem(18),
      ...responsiveFontSizes({ xs: 14, sm: 14, md: 16, lg: 18 }),
    },
    body2: {
      lineHeight: 22 / 16,
      fontWeight: 400,
      fontSize: pxToRem(16),
      ...responsiveFontSizes({ xs: 12, sm: 12, md: 14, lg: 16 }),
    },
    body3: {
      lineHeight: 22 / 14,
      fontWeight: 400,
      fontSize: pxToRem(14),
      ...responsiveFontSizes({ xs: 12, sm: 12, md: 13, lg: 14 }),
    },
    body4: {
      lineHeight: 22 / 12,
      fontWeight: 400,
      fontSize: pxToRem(12),
      ...responsiveFontSizes({ xs: 10, sm: 10, md: 11, lg: 12 }),
    },
    body5: {
      lineHeight: 22 / 8,
      fontWeight: 400,
      fontSize: pxToRem(8),
      ...responsiveFontSizes({ xs: 8, sm: 8, md: 8, lg: 8 }),
    },
    caption: {
      lineHeight: 1.5,
      fontWeight: 400,
      fontSize: pxToRem(10),
      ...responsiveFontSizes({ xs: 10, sm: 10, md: 10, lg: 10 }),
    },
    overline: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(12),
      textTransform: 'uppercase',
      ...responsiveFontSizes({ xs: 10, sm: 10, md: 11, lg: 12 }),
    },
    button: {
      fontFamily: FONT_SECONDARY,
      fontWeight: 700,
      lineHeight: 24 / 14,
      fontSize: pxToRem(14),
      textTransform: 'capitalize',
      ...responsiveFontSizes({ xs: 12, sm: 12, md: 13, lg: 14 }),
    },
  };

  for (let i = 1; i <= 6; i++) {
    main[`h${i}_secondary`] = { ...main[`h${i}`], fontFamily: fontFamily || FONT_SECONDARY };
  }
  for (let i = 1; i <= 4; i++) {
    main[`subtitle${i}_secondary`] = { ...main[`subtitle${i}`], fontFamily: fontFamily || FONT_SECONDARY };
    main[`body${i}_secondary`] = { ...main[`body${i}`], fontFamily: fontFamily || FONT_SECONDARY };
  }

  return main;
};

export default typography;
