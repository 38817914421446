import { Card, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Iconify from '../../../components/Iconify';
import SmallAreaChart from '../../../components/SmallAreaChart';
import SymbolIcon from '../../../components/SymbolIcon';
import { fFloat, fPercent } from '../../../utils/formatNumber';

export default function MarketStatistic({ data }) {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      {data.map((item) => (
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ p: 2 }}>
            <Typography variant='body2' color='grey.400'>
              {t(item.title)}
            </Typography>

            <Stack sx={{ mt: 1 }}>
              <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
                <Stack direction='row' spacing={2} alignItems='center'>
                  <SymbolIcon symbol={item.symbol} width={32} height={32} />
                  <Typography variant='h6' sx={{ fontWeight: 600 }}>
                    USD {fFloat(item.usdValue)}
                  </Typography>
                </Stack>

                {item?.changePercent ? (
                  <Stack direction='row' spacing={0.5} alignItems='center'>
                    <Iconify
                      icon={item.changePercent < 0 ? 'ph:arrow-down-fill' : 'ph:arrow-up-fill'}
                      color={item.changePercent < 0 ? 'negative.main' : 'positive.main'}
                      height={18}
                    />
                    <Typography variant='body2' color={item.changePercent < 0 ? 'negative.main' : 'positive.main'}>
                      {fPercent(item.changePercent < 0 ? -1 * item.changePercent : item.changePercent)}
                    </Typography>
                  </Stack>
                ) : (
                  <Typography variant='body3' color='grey.300'>
                    {item.description}
                  </Typography>
                )}
              </Stack>

              <Stack sx={{ mt: 0.5 }} direction='row' spacing={1} alignItems='flex-end' justifyContent='space-between'>
                <Stack sx={{ ml: 6.2 }} spacing={0.5}>
                  <Typography variant='body2' color='grey.100'>
                    {item.name}
                  </Typography>
                  <Typography variant='body2' color='grey.400'>
                    {item.symbol}
                  </Typography>
                </Stack>

                <SmallAreaChart
                  data={item.prices}
                  isPositive={item?.changePercent < 0 ? false : true}
                  width={96}
                  height={28}
                />
              </Stack>
            </Stack>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
