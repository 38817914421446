import { useEffect, useState } from 'react';
import { m } from 'framer-motion';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Button, Card, Container, Typography } from '@mui/material';

import errorIcon from '../../../assets/error_icon.svg';
import MainNavBar from '../../../components/mainNavBar/mainNavBar';
import SideMenu from '../../../components/sideMenu/sideMenu';
import useResponsive from '../../../hooks/useResponsive';
import useAdminAuth from '../../../hooks/useAdminAuth';
import LoadingScreen from '../../../components/LoadingScreen';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { navigationUrl } from '../../../navigationUrl';
import MotionContainer from '../../../components/animate/MotionContainer';
import { varBounce } from '../../../components/animate/variants/bounce';
import Image from '../../../components/Image';
import { accessUrls } from '../../../navConfig';
import { SET_PAGE_STATE } from '../../../store/actionTypes';

// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 250,
  DASHBOARD_WIDTH: 250,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 30,
    paddingBottom: 20,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },

  '@media print': {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
}));

// ----------------------------------------------------------------------

export default function AdminLayout() {
  const [open, setOpen] = useState(false);
  const { access } = useSelector((store) => store.adminUserInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [allowAccess, setAllowAccess] = useState(null);

  const { loading } = useAdminAuth(true);

  const isDesktop = useResponsive('up', 'lg');
  const [collapse, setCollapse] = useState({
    click: false,
    hover: false,
  });
  const isCollapse = collapse.click && !collapse.hover;

  useEffect(() => {
    if (!isDesktop) {
      setCollapse({
        click: false,
        hover: false,
      });
    }
  }, [isDesktop]);

  const onToggleCollapse = () => {
    setCollapse({ ...collapse, click: !collapse.click });
  };

  const onHoverEnter = () => {
    if (collapse.click) {
      setCollapse({ ...collapse, hover: true });
    }
  };

  const onHoverLeave = () => {
    setCollapse({ ...collapse, hover: false });
  };

  useEffect(() => {
    let allow = true;

    if (location.pathname && access) {
      const accessUrl = accessUrls?.[location.pathname] || null;
      if (accessUrl) {
        allow = access.find((a) => a.accessType === accessUrl)?.status;
      }
    }

    if (!allow) {
      dispatch({
        type: SET_PAGE_STATE,
        payload: { title: 'Access Denied' },
      });
    }

    setTimeout(() => {
      setAllowAccess(allow);
    }, 300);
  }, [location.pathname, access]);

  useEffect(() => {
    setAllowAccess(null);
  }, [location.pathname]);

  if (loading || allowAccess === null) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        <MainNavBar isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} isAdmin />

        <SideMenu
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
          onHoverLeave={onHoverLeave}
          onToggleCollapse={onToggleCollapse}
          onHoverEnter={onHoverEnter}
          isCollapse={isCollapse}
          collapse={collapse}
          isAdmin
        />

        <MainStyle collapseClick={collapse.click}>
          {allowAccess ? (
            <Outlet />
          ) : (
            <RootStyle>
              <Container component={MotionContainer}>
                <ContentStyle sx={{ maxWidth: { xs: 300, md: 400 } }}>
                  <m.div variants={varBounce().in}>
                    <Card sx={{ textAlign: 'center', pt: 5 }}>
                      <Box
                        sx={{
                          width: 91,
                          height: 91,
                          backgroundColor: 'grey.600',
                          backdropFilter: 'blur(7px)',
                          borderRadius: '50%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mx: 'auto',
                        }}
                      >
                        <Image src={errorIcon} sx={{ m: 'auto', width: 40 }} />
                      </Box>

                      <Typography variant='body1' color={'error.darker'} sx={{ mt: 2, mb: 1 }}>
                        {t('Access Denied')}
                      </Typography>

                      <Button
                        variant='outlined'
                        color='inherit'
                        sx={{ width: 144, mt: 3, mb: 2, typography: 'body3' }}
                        size='large'
                        onClick={() => navigate(navigationUrl.admin)}
                      >
                        {t('Back')}
                      </Button>
                    </Card>
                  </m.div>
                </ContentStyle>
              </Container>
            </RootStyle>
          )}
        </MainStyle>
      </Box>
    </>
  );
}

const RootStyle = styled('div')(() => ({
  minHeight: '75vh',
  width: '100%',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '75vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));
