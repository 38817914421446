import { useEffect } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

export default function useDepositSettingForm(depositData) {

  const validators = Yup.object().shape({
    active: Yup.boolean(),
    name: Yup.string().notRequired(),
    family: Yup.string().notRequired()
  });

  const defaultValues = {
    active: depositData.active || false,
    name: depositData.name || '',
    family: depositData.family || '',
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (depositData) {
      reset(defaultValues);
    }
    if (!depositData) {
      reset(defaultValues);
    }
  }, [depositData]);

  return {
    handleSubmit,
    isSubmitting,
    methods,
    isActive: watch('active'),
  };
}
