import React from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Image from '../../../../components/Image';
import { RHFNumberField, RHFSwitch } from '../../../../components/hookForm';

const InventoryRow = ({ field }) => {
  const { t } = useTranslation();
  const { name, symbol, statusField, amountField } = field;

  console.log('field', field);

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='flex-end'
      spacing={{ xs: 1, sm: 2 }}
      sx={{ mb: 3.75 }}
    >
      <Stack direction='row' alignItems='center' sx={{ width: { xs: 120, md: 180 } }}>
        {symbol !== '' ? (
          <Image src={symbol} sx={{ mr: { xs: 1, md: 2.5 }, width: { xs: 30, md: 40 }, height: { xs: 30, md: 40 } }} />
        ) : (
          <Box sx={{ width: 48, height: 48, backgroundColor: 'info.darker', mr: { xs: 1, md: 2.5 } }} />
        )}

        <Typography variant='body2'>{name}</Typography>
      </Stack>

      <RHFNumberField
        label={t('Inventory')}
        name={amountField}
        variant='standard'
        sx={{
          width: { xs: 80, md: 200 },
          '& label.MuiFormLabel-root': {
            color: 'grey.400',
          },
          '& .Mui-disabled': {
            '& input': { WebkitTextFillColor: 'grey.550' },
            '&:before': { borderBottomStyle: 'solid' },
          },
        }}
      />

      <Stack direction='row' alignItems={'center'} spacing={1} sx={{ width: { xs: 50, sm: 250 } }}>
        <RHFSwitch name={statusField} />
        <Typography variant='body3' sx={{ display: { xs: 'none', sm: 'inline-block' } }}>
          {t('API status for inventory')}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default InventoryRow;
