import React, { useEffect, useState } from 'react';
import { TableRow, TableCell, Typography, Box, CircularProgress } from '@mui/material';

import { FONT_RTL } from '../../../../theme/typography';
import CustomSwitch from '../../../../components/CustomSwitch';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import { useTranslation } from 'react-i18next';

const NetworkTableRow = ({ row, index, onChangeActive }) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const { name, networkName, symbol, cryptiziStatus } = row;

  const handleSwitch = async () => {
    setLoading(true);

    try {
      const response = await Api.editNetworkStatus({ name, symbol, status: !isActive, symbolType: 'SELECTED' });
      if (response.status === 'SUCCESS') {
        setIsActive(!isActive);
        onChangeActive(name, symbol, !isActive);
      }
    } catch (e) {}

    setLoading(false);
  };

  useEffect(() => {
    setIsActive(cryptiziStatus);
  }, [cryptiziStatus]);

  return (
    <TableRow hover sx={{ '& td': { py: 1.5, typography: 'body2' } }}>
      <TableCell>{index + 1}</TableCell>

      <TableCell>{name}</TableCell>

      <TableCell>
        <Typography variant='body2' fontFamily={FONT_RTL}>
          {networkName}
        </Typography>
      </TableCell>

      <TableCell>{symbol}</TableCell>

      <TableCell sx={{ width: 170 }} align='center'>
        {loading ? (
          <Box
            sx={{
              width: 130,
              borderRadius: '22px',
              textAlign: 'center',
              m: 'auto',
              p: 0.8,
              bgcolor: 'rgba(242, 127, 22, 0.2)',
            }}
          >
            <CircularProgress size={13} sx={{ color: 'secondary.lighter' }} />
          </Box>
        ) : (
          <Box
            sx={{
              width: 130,
              borderRadius: '22px',
              textAlign: 'center',
              m: 'auto',
              p: 0.8,
              ...(isActive ? { bgcolor: 'rgba(48, 224, 161, 0.2)' } : { bgcolor: 'rgba(250, 34, 86, 0.2)' }),
            }}
          >
            <Typography variant='body2' sx={{ ...(isActive ? { color: '#30E0A1' } : { color: 'negative.main' }) }}>
              {isActive ? t('Active') : t('Inactive')}
            </Typography>
          </Box>
        )}
      </TableCell>

      <TableCell align='center' sx={{ width: 150 }}>
        <CustomSwitch checked={isActive} name='activation' onClick={handleSwitch} />
      </TableCell>
    </TableRow>
  );
};

export default NetworkTableRow;
