import { useEffect } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Label from './Label';
import Api from '../http/adminHttp/serviceApiAdmin';
import { SET_WAITING_COUNTER } from '../store/actionTypes';

export default function WaitingCounter() {
  const dispatch = useDispatch();
  const reloadTimer = useRef();
  const users = useSelector((state) => state.waitingUserCounter);
  const bankCards = useSelector((state) => state.waitingBankCardCounter);
  const withdraws = useSelector((state) => state.waitingWithdrawCounter);
  const deposits = useSelector((state) => state.waitingDepositCounter);

  const getData = async () => {
    if (reloadTimer.current) clearTimeout(reloadTimer.current);

    try {
      const { waitingUser } = await Api.getAllUser(0, 1, {
        authenticationLevel: 'ALL',
        balance: 0,
        balance2: 1000000000,
      });
      const { totalElement: waitingBankCardCounter } = await Api.getAllBankCards(0, 1, { status: 'WAITING' });
      const { fiatWithdrawInfos } = await Api.getFiatWithdraw();
      const { fiatDepositInfos } = await Api.getFiatDeposit();

      dispatch({
        type: SET_WAITING_COUNTER,
        payload: {
          waitingUserCounter: waitingUser,
          waitingBankCardCounter,
          waitingWithdrawCounter: fiatWithdrawInfos?.length || 0,
          waitingDepositCounter: fiatDepositInfos?.length || 0,
        },
      });
    } catch (e) {}

    // Getting new data each 5 min
    reloadTimer.current = setTimeout(() => {
      getData();
    }, 1000 * 60 * 5);
  };

  useEffect(() => {
    getData();
  }, []);

  return <Label color='warning'>{users + bankCards + withdraws + deposits}</Label>;
}

export function UserWaitingCounter() {
  const count = useSelector((state) => state.waitingUserCounter);
  return <Label color='warning'>{count}</Label>;
}

export function BankCardWaitingCounter() {
  const count = useSelector((state) => state.waitingBankCardCounter);
  return <Label color='warning'>{count}</Label>;
}

export function WithdrawWaitingCounter() {
  const count = useSelector((state) => state.waitingWithdrawCounter);
  return <Label color='warning'>{count}</Label>;
}

export function DepositWaitingCounter() {
  const count = useSelector((state) => state.waitingDepositCounter);
  return <Label color='warning'>{count}</Label>;
}
