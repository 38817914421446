import React, { useEffect, useState } from 'react';
import useQuery from '../hooks/useQuery';
import ModalContainer from './ModalContainer';
import { Box, Button, Stack ,Typography} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

const AfterGatewayModal = ({type , justModal = false}) => {
  const query = useQuery();
  const refId = query.get('refId')
  const amount = query.get('amount')
  const otcAmount = query.get('otcAmount')
  const fromMobile = query.get('fromMobile')

  const {t} = useTranslation()
  const navigate = useNavigate()
  const [openModal , setOpenModal] = useState(false)
  const [data , setData] = useState({})

  const isSuccessful =justModal ? true :  type === "success"

  useEffect(()=>{
    if(refId){
      setOpenModal(true)
    }else {
      setOpenModal(false)
    }
  },[refId])
  return (
    justModal ?
      <ModalContainer hasCloseButton={true} open={openModal} onClose={()=>setOpenModal(false)} title={data?.title} width={480} sx={{ p: 3 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"center"}
          sx={{
            px: 2,
            height: 48,
            borderRadius: 1,
            bgcolor:isSuccessful? 'primary.dark' :'error.dark',
            color:"#fff"
          }}
        >
          {t(isSuccessful? "Successful Payment" : "Failed payment")}
        </Stack>

        <Stack
          sx={{
            width:"100%",
            my:2,
            px: 2,
            borderRadius: 1,
            bgcolor:'grey.600',
            color:"#fff"
          }}
        >
          <Stack direction={'row'} alignItems={'center'} justifyContent={"space-between"}
                 sx={{width:"100%" , my:'0.5rem'}}
          >
            <Typography  variant='body1' color={'grey.100'}>
              {t("Ref Id")}
            </Typography>
            <Typography  variant='body1' color={'grey.100'}>
              {refId}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} justifyContent={"space-between"}
                 sx={{width:"100%" , my:'0.5rem'}}
          >
            <Typography  variant='body1' color={'grey.100'}>
              {t("Amount")}
            </Typography>
            <Typography  variant='body1' color={'grey.100'}>
              {amount}
            </Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} justifyContent={"space-between"}
                 sx={{width:"100%" , my:'0.5rem'}}
          >
            <Typography  variant='body1' color={'grey.100'}>
              {t("OTC Amount")}
            </Typography>
            <Typography  variant='body1' color={'grey.100'}>
              {otcAmount}
            </Typography>
          </Stack>
        </Stack>

        {/*<Button*/}
        {/*    onClick={()=>navigate('/')}*/}

        {/*    sx={{*/}
        {/*        px: 2,*/}
        {/*        height: 48,*/}
        {/*        borderRadius: 1,*/}
        {/*        bgcolor:'info.darkest',*/}
        {/*        color:"#fff"*/}
        {/*    }}*/}
        {/*>*/}
        {/*    {t("Go to Main Page")}*/}

        {/*</Button>*/}
      </ModalContainer>
      :

      <div
        style={{minWidth:"100vw" , minHeight:"100svh" ,
          background: isSuccessful?"linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,0,0,0.7833727240896359) 0%, rgba(0,255,156,1) 100%)":"linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,0,0,0.7833727240896359) 0%, rgba(255,0,54,1) 100%)"}}
      >

        <ModalContainer hasCloseButton={false} open={openModal} onClose={()=>setOpenModal(true)} title={data?.title} width={480} sx={{ p: 3 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"center"}
            sx={{
              px: 2,
              height: 48,
              borderRadius: 1,
              bgcolor:isSuccessful? 'primary.dark' :'error.dark',
              color:"#fff"
            }}
          >
            {t(isSuccessful? "Successful Payment" : "Failed payment")}
          </Stack>

          <Stack
            sx={{
              width:"100%",
              my:2,
              px: 2,
              borderRadius: 1,
              bgcolor:'grey.600',
              color:"#fff"
            }}
          >
            <Stack direction={'row'} alignItems={'center'} justifyContent={"space-between"}
                   sx={{width:"100%" , my:'0.5rem'}}
            >
              <Typography  variant='body1' color={'grey.100'}>
                {t("Ref Id")}
              </Typography>
              <Typography  variant='body1' color={'grey.100'}>
                {refId}
              </Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} justifyContent={"space-between"}
                   sx={{width:"100%" , my:'0.5rem'}}
            >
              <Typography  variant='body1' color={'grey.100'}>
                {t("Amount")}
              </Typography>
              <Typography  variant='body1' color={'grey.100'}>
                {amount}
              </Typography>
            </Stack>

          </Stack>

          {
            fromMobile ?
              <Button
                onClick={() => (window.location.href = 'aiochange://')}
                sx={{
                  px: 2,
                  height: 48,
                  borderRadius: 1,
                  bgcolor:'info.darkest',
                  color:"#fff"
                }}
              >
                {t("Go Mobile App")}

              </Button>
              :
              <Button
                onClick={()=>navigate('/')}

                sx={{
                  px: 2,
                  height: 48,
                  borderRadius: 1,
                  bgcolor:'info.darkest',
                  color:"#fff"
                }}
              >
                {t("Go to Main Page")}

              </Button>

          }
        </ModalContainer>
      </div>

  );
};

export default AfterGatewayModal;