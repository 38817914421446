import http from './adminHttp';

const Api = {
  /**
   * **********************************************************
   * Tokens
   */
  addToken: (data) => {
    return http.post(`cryptizi/api/v1/admin/tokenListing/token`, data);
  },

  editToken: (data) => {
      return http.put(`cryptizi/api/v1/admin/tokenListing/token`, data);
  },
  deleteToken: (symbol) => {
    return http.delete(`cryptizi/api/v1/admin/tokenListing/token`,{params:{symbol}} );
  },
  getTokenList: (params) => {
    return http.get(`cryptizi/api/v1/admin/tokenListing/token`, {
      params,
    });
  },
  getActiveSymbolsTokenListingPair: () => {
    return http.get(`cryptizi/api/v1/tokenListing/activeSymbols`);
  },
  saveMainWalletAddress: (data) => {
    return http.post(`cryptizi/api/v1/admin/tokenListing/mainWalletAddress`, data);
  },
  saveGasPumpWalletAddress: (data) => {
    return http.post(`cryptizi/api/v1/admin/tokenListing/gasConfig`, data);
  },
  getActiveSymbols: () => {
    return http.get(`cryptizi/api/v1/wallet/activeSymbols`);
  },
  getMainWalletAddress: () => {
    return http.get(`cryptizi/api/v1/admin/tokenListing/mainWalletAddress`);
  },
  getGasWalletAddress: () => {
    return http.get(`cryptizi/api/v1/admin/tokenListing/gasPumpAddress`);
  },
  addTokenNetworkInfo: (data) => {
    return http.post(`/cryptizi/api/v1/admin/tokenListing/networkInfo`, data);
  },

  editTokenNetworkInfo: (data) => {
    return http.put(`cryptizi/api/v1/admin/tokenListing/networkInfo`, data);
  },

  getTokenNetworkInfo: () => {
    return http.get(`cryptizi/api/v1/admin/tokenListing/networkInfo`);
  },

  /**
   * **********************************************************
   * general
   */
  getCountryList: () => {
    return http.get(`cryptizi/api/v1/general/getCountryList`);
  },
  getEMoneySetting: () => {
    return http.get(`cryptizi/api/v1/general/eMoneySetting`);
  },

  /**
   * **********************************************************
   * setting
   */
  blockIp: (ip, block) => {
    return http.post(`cryptizi/api/v1/admin/setting/blockIp`, null, { params: { ip, block } });
  },
  getAllBlockIp: () => {
    return http.get(`cryptizi/api/v1/admin/setting/getAllBlockIp`);
  },
  deleteBlockIp: (ip) => {
    return http.delete(`cryptizi/api/v1/admin/setting/deleteBlockIp`, { params: { ip } });
  },
  userLevelSetting: (data) => {
    return http.post(`cryptizi/api/v1/admin/setting/userLevelSetting`, data);
  },
  getUserLevelSetting: (level) => {
    return http.get(`cryptizi/api/v1/admin/setting/userLevelSetting`, { params: { level } });
  },

  /**
   * **********************************************************
   * user
   */
  userLogin: (data) => {
    return http.post(`cryptizi/api/v1/user/userLogin`, data);
  },
  // operation = login | register | changeEmail
  resendOtp: (emailAddress, operation) => {
    return http.get(`cryptizi/api/v1/user/resendOtp`, { params: { emailAddress, operation } });
  },
  loginTwoFaVerify: (data) => {
    return http.post(`cryptizi/api/v1/user/loginTwoFaVerify`, data);
  },
  getUserInfo: () => {
    return http.get(`cryptizi/api/v1/user/getUserInfo`);
  },
  signOut: () => {
    return http.get(`cryptizi/api/v1/user/signOut`);
  },
  forgetPassword: (emailAddress) => {
    return http.post(`cryptizi/api/v1/user/forgetPassword`, { params: { emailAddress } });
  },
  resetPassword: (data) => {
    return http.post(`cryptizi/api/v1/user/resetPassword`, data);
  },
  changePassword: (data) => {
    return http.post(`cryptizi/api/v1/user/changePassword`, data);
  },
  getDefaultUserSetting: () => {
    return http.get(`cryptizi/api/v1/user/getDefaultUserSetting`);
  },
  getUserProfilePicture: () => {
    return http.get(`cryptizi/api/v1/user/getUserProfilePicture`);
  },
  saveUserSetting: (data) => {
    return http.post(`cryptizi/api/v1/user/saveUserSetting`, data);
  },
  preChangeEmail: (oldEmailAddress, newEmailAddress) => {
    return http.post(`cryptizi/api/v1/user/preChangeEmail`, { oldEmailAddress, newEmailAddress });
  },
  changeEmail: (newEmailOtp, emailOtp = null) => {
    return http.post(`cryptizi/api/v1/user/changeEmail`, null, {
      newEmailOtp,
      emailOtp,
    });
  },
  // type: ADD | CHANGE
  preChangeOrAddMobile: (oldMobile, newMobile, type = 'CHANGE') => {
    return http.post(`cryptizi/api/v1/user/preChangeOrAddMobile`, { oldMobile, newMobile, type });
  },
  // type: ADD | CHANGE
  changeOrAddMobile: (newSmsOtp, oldSmsOtp, type = 'CHANGE') => {
    return http.post(`cryptizi/api/v1/user/changeOrAddMobile`, {
      newSmsOtp,
      oldSmsOtp,
      type,
    });
  },
  preTwoFaActivation: (payload) => {
    return http.post(`cryptizi/api/v1/user/preTwoFaActivation`, payload);
  },
  twoFaActivation: (payload, otp) => {
    return http.post(`cryptizi/api/v1/user/twoFaActivation`, payload, { params: { otp } });
  },
  preTwoFaDeActivation: (data) => {
    return http.post(`cryptizi/api/v1/user/preTwoFaDeActivation`, data);
  },
  twoFaDeActivation: (payload, otp) => {
    return http.post(`cryptizi/api/v1/user/twoFaDeActivation`, payload, { params: { otp } });
  },
  getAllUser: (page = 0, size = 10, filters = {}) => {
    return http.get(`cryptizi/api/v1/admin/user/getAllUser`, { params: { ...filters } });
  },
  getAllWaitingUser: (page = 0, size = 10, filters = {}) => {
    return http.get(`cryptizi/api/v1/admin/user/waitingUsers`, { params: { page, size, ...filters } });
  },
  getUser: (userId) => {
    return http.get(`cryptizi/api/v1/admin/user/getUser`, { params: { userId } });
  },
  getUserCardsInformation: (page = 0, size = 10, filters = {}) => {
    return http.get(`cryptizi/api/v1/admin/user/userBankCard`, { params: { page, size, ...filters } });
  },
  getUserWalletInformation: (filters = {}) => {
    return http.get(`cryptizi/api/v1/admin/user/userWallet`, { params: { ...filters } });
  },
  getAllCurrencies: () => {
    return http.get(`cryptizi/api/v1/wallet/getAllCurrencies`);
  },
  confirmAuthenticationLevel: (userId) => {
    return http.post(`cryptizi/api/v1/admin/user/confirmAuthenticationLevel`, null, { params: { userId } });
  },
  rejectAuthenticationLevel: (payload) => {
    return http.post(`cryptizi/api/v1/admin/user/rejectAuthenticationLevel`, payload);
  },
  setUserInfo: (userId, payload) => {
    return http.post(`cryptizi/api/v1/admin/user/setUserInfo`, payload, { params: { userId } });
  },
  blockUser: (userId, block) => {
    return http.post(`cryptizi/api/v1/admin/user/blockUser`, null, { params: { userId, block } });
  },
  adminOtherPolicy: (userId, payload) => {
    return http.post(`cryptizi/api/v1/admin/user/adminOtherPolicy`, payload, { params: { userId } });
  },
  getUserOrders: (page = 0, size = 10, filters = {}) => {
    return http.get(`cryptizi/api/v1/admin/user/userOrder`, { params: { page, size, ...filters } });
  },
  getUserTradeHistory: (page = 0, size = 10, filters = {}) => {
    return http.get(`cryptizi/api/v1/admin/user/userTradeHistory`, { params: { page, size, ...filters } });
  },
  getUserTransactions: (page = 0, size = 10, filters = {}) => {
    return http.get(`cryptizi/api/v1/admin/user/userTransaction`, { params: { page, size, ...filters } });
  },
  deleteUserByAdmin: (userId) => {
    return http.delete(`cryptizi/api/v1/admin/user/deleteUser`, { params: { userId } });
  },
  createUserTransaction: (payload, userId) => {
    return http.post(`cryptizi/api/v1/admin/user/createUserTransaction`, payload, { params: { userId } });
  },
  getUserEarn: (page = 0, size = 10, filters = {}) => {
    return http.get(`cryptizi/api/v1/admin/user/userEarn`, { params: { page, size, ...filters } });
  },

  /**
   * **********************************************************
   * admin
   */
  getAdminAccess: (userId) => {
    return http.get(`cryptizi/api/v1/admin/user/getAdminAccess`, { params: { userId } });
  },
  getAllAdmin: (page = 0, size = 10, filters = {}) => {
    return http.get(`cryptizi/api/v1/admin/user/allAdmins`, { params: { page, size, ...filters } });
  },
  getAdminById: (userId) => {
    return http.get(`cryptizi/api/v1/admin/user/admins`, { params: { userId } });
  },
  deleteAdmin: (userId) => {
    return http.delete(`cryptizi/api/v1/admin/user/admins`, { params: { userId, deleteByAdmin: 'DELETE_BY_ADMIN' } });
  },
  updateAdmin: (payload) => {
    return http.put(`cryptizi/api/v1/admin/user/admins`, payload);
  },
  insertAdmin: (payload) => {
    return http.post(`cryptizi/api/v1/admin/user/admins`, payload);
  },
  changeStatusAdmin: (userId, isActive = true) => {
    return http.delete(`cryptizi/api/v1/admin/user/admins`, {
      params: { userId, deleteByAdmin: isActive ? 'Active' : 'DE_ACTIVE' },
    });
  },
  updateAdminLimitAmounts: (payload) => {
    return http.post(`cryptizi/api/v1/admin/user/adminAccount`, payload);
  },

  /**
   * **********************************************************
   * Inventory setting
   */
  getInventorySetting: () => {
    return http.get(`cryptizi/api/v1/admin/setting/inventorySetting`);
  },
  editInventorySetting: (payload) => {
    return http.post(`cryptizi/api/v1/admin/setting/inventorySetting`, null, { params: payload });
  },

  /**
   * **********************************************************
   * General setting
   */
  getGeneralSettings: () => {
    return http.get(`cryptizi/api/v1/admin/setting/generalSetting`);
  },
  editGeneralSettings: (payload) => {
    return http.post(`cryptizi/api/v1/admin/setting/generalSetting`, payload);
  },

  /**
   * **********************************************************
   * Network setting
   */
  getNetworkSettings: () => {
    return http.get(`cryptizi/api/v1/admin/setting/networkSetting`);
  },
  editNetworkStatus: (payload) => {
    return http.post(`cryptizi/api/v1/admin/setting/networkSetting`, payload);
  },

  /**
   * **********************************************************
   * Dedicated Gateway setting
   */
  getDedicatedGatewaySettings: () => {
    return http.get(`cryptizi/api/v1/admin/setting/dedicatedGatewaySetting`);
  },
  editDedicatedGatewaySettings: (payload) => {
    return http.post(`cryptizi/api/v1/admin/setting/dedicatedGatewaySetting`, payload);
  },
  getIranianPaymentSetting: () => {
    return http.get(`cryptizi/api/v1/admin/setting/iranianPayment`);
  },
  /**
   * **********************************************************
   * Deposit Receipt Setting
   */
  getDepositReceiptSettings: () => {
    return http.get(`cryptizi/api/v1/admin/setting/depositReceiptSetting`);
  },
  editDepositReceiptSettings: (payload) => {
    return http.post(`cryptizi/api/v1/admin/setting/depositReceiptSetting`, payload);
  },
  deleteDepositReceiptBank: (bank) => {
    return http.delete(`cryptizi/api/v1/admin/setting/depositReceiptSetting`, { params: bank });
  },
  deleteDepositReceiptTransfer: (transfer) => {
    return http.delete(`cryptizi/api/v1/admin/setting/depositReceiptSetting`, {
      params: { transferMethodName: transfer.name },
    });
  },

  /**
   * **********************************************************
   * Gateway setting
   */
  getGatewaySettings: (name) => {
    return http.get(`cryptizi/api/v1/admin/setting/gatewaySetting`, { params: { name } });
  },
  editGatewaySettings: (payload) => {
    return http.post(`cryptizi/api/v1/admin/setting/gatewaySetting`, payload);
  },

  /**
   * **********************************************************
   * Popup setting
   */
  getPopUpbData: (popupType) => {
    return http.get(`cryptizi/api/v1/admin/setting/popupSetting`, { params: { popupType } });
  },
  editPopUpbData: (payload , param) => {
    return http.post(`cryptizi/api/v1/admin/setting/popupSetting`, payload , {params:{value:param}});
  },
  /**
   * **********************************************************
   * pay safe
   */
  getPaySafeData: () => {
    return http.get(`cryptizi/api/v1/admin/setting/paySafeSetting`);
  },
  editPaySafeData: (payload) => {
    return http.post(`cryptizi/api/v1/admin/setting/paySafeSetting`, payload);
  },

  /**
   * **********************************************************
   * perfect money
   */
  getPerfectMoneyData: () => {
    return http.get(`cryptizi/api/v1/admin/setting/perfectMoneySetting`);
  },
  editPerfectMoneyData: (payload) => {
    return http.post(`cryptizi/api/v1/admin/setting/perfectMoneySetting`, payload);
  },

  /**
   * **********************************************************
   * Reference Account Info
   */

  getReferenceAccountInfo: () => {
    return http.get(`cryptizi/api/v1/admin/setting/referenceAccountInfo`);
  },
  editReferenceAccountInfo: (payload) => {
    return http.post(`cryptizi/api/v1/admin/setting/referenceAccountInfo`, payload);
  },

  /**
   * **********************************************************
   * Fiat Transaction Waiting
   */
  getAllFiats: (page = 0, size = 10) => {
    return [];
  },

  /**
   * **********************************************************
   * bankCard
   */
  getAllBankCards: (page = 0, size = 10, filters = {}) => {
    return http.get(`cryptizi/api/v1/admin/user/bankCards`, { params: { page, size, ...filters } });
  },
  addBankCard: (userId, payload) => {
    return http.post(`cryptizi/api/v1/admin/user/addBankCardByAdmin`, payload, { params: { userId } });
  },
  updateBankCard: (payload) => {
    return http.put(`cryptizi/api/v1/admin/user/bankCards`, payload);
  },
  getChangeBankCards: (page = 0, size = 10, filters = {}) => {
    return http.get(`cryptizi/api/v1/admin/user/adminChangeBankCard`, { params: { page, size, ...filters } });
  },

  /**
   * **********************************************************
   * log
   */
  getAdminLogs: (page = 0, size = 10, filters = {}) => {
    return http.get(`cryptizi/api/v1/admin/user/adminLog`, { params: { page, size, ...filters } });
  },

  /**
   * **********************************************************
   * user credential
   */
  getAdminUsersCredential: (page = 0, size = 10, filters = {}) => {
    return http.get(`cryptizi/api/v1/admin/user/adminChangeUserCredential`, { params: { page, size, ...filters } });
  },

  /**
   * **********************************************************
   * Ticket
   */
  // date / firstName / lastName / subject / ticketId, ticketStatus: CLOSE,CLOSE_BY_ADMIN,OPEN,PENDING
  getAllTickets: (page = 0, size = 10, filters = {}) => {
    return http.get(`cryptizi/api/v1/admin/ticket/allTicket`, { params: { page, size, ...filters } });
  },
  getTicketMessages: (ticketId) => {
    return http.get(`cryptizi/api/v1/admin/ticket/ticketMessage`, { params: { ticketId } });
  },
  // message, ticketId
  addTicketMessage: (payload) => {
    return http.post(`cryptizi/api/v1/admin/ticket/ticketMessage`, payload);
  },
  closeTicket: (ticketId) => {
    return http.put(`cryptizi/api/v1/admin/ticket/closeTicket`, null, { params: { ticketId } });
  },
  deleteTicket: (ticketId) => {
    return http.put(`cryptizi/api/v1/admin/ticket/deleteTicket`, null, { params: { ticketId } });
  },
  getTicketStatistics: () => {
    return http.get(`cryptizi/api/v1/admin/ticket/ticketStatistics`);
  },

  /**
   * **********************************************************
   * Add user by Admin
   */
  addUserByAdmin: (payload) => {
    return http.post(`cryptizi/api/v1/admin/user/addUser`, payload);
  },

  /**
   * **********************************************************
   * Referral
   */
  // start / finish
  getReferralCommissionPayments: (page = 0, size = 10, filters = {}) => {
    return http.get(`cryptizi/api/v1/admin/referral/commissionPayments`, { params: { page, size, ...filters } });
  },
  // start / finish
  getReferralIntroducedUsers: (page = 0, size = 10, filters = {}) => {
    return http.get(`cryptizi/api/v1/admin/referral/introducedUsers`, { params: { page, size, ...filters } });
  },
  getReferralOverallInfo: () => {
    return http.get(`cryptizi/api/v1/admin/referral/overallInfo`);
  },
  setMaxReferralInfo: (percent) => {
    return http.post(`cryptizi/api/v1/admin/referral/maximumReferral`, null, { params: { percent } });
  },

  /**
   * **********************************************************
   * Dashboard
   */
  getCurrencyStatistics: () => {
    return http.get(`cryptizi/api/v1/admin/dashboard/currencyStatistics`);
  },

  getDashboardListedTokens: () => {
    return http.get(`cryptizi/api/v1/admin/dashboard/listedTokens?page=0&size=200`);
  },

  getEMoneyStatistics: () => {
    return http.get(`cryptizi/api/v1/admin/dashboard/eMoneyStatistics`);
  },
  getGeneralStatistics: () => {
    return http.get(`cryptizi/api/v1/admin/dashboard/generalStatistics`);
  },
  getSecondStatistics: () => {
    return http.get(`cryptizi/api/v1/admin/dashboard/secondStatistics`);
  },
  getGeneralStatisticsByDate: () => {
    return http.get(`cryptizi/api/v1/admin/dashboard/generalStatisticsByDate`);
  },
  getSymbolStatisticsByDate: (symbol) => {
    return http.get(`cryptizi/api/v1/admin/dashboard/symbolStatisticsByDate`, { params: { symbol } });
  },
  getPercentageStatistics: () => {
    return http.get(`cryptizi/api/v1/admin/dashboard/percentageStatistics`);
  },
  getChartStatistics: () => {
    return http.get(`cryptizi/api/v1/admin/dashboard/chartStatistics`);
  },

  /**
   * **********************************************************
   * Report
   */
  // HALF_OF_DAY | DAY | WEEK | MONTH | YEAR
  getReportTotalStatistics: (period = 'WEEK') => {
    return http.get(`cryptizi/api/v1/admin/report/firstChartStatistics`, { params: { reportBuyAndSellDate: period } });
  },
  getReportBuySellStatistics: (period = 'WEEK') => {
    return http.get(`cryptizi/api/v1/admin/report/buyAndSellChartStatistics`, {
      params: { reportBuyAndSellDate: period },
    });
  },
  getReportBuySellStatisticsByCountry: (period = 'WEEK') => {
    return http.get(`cryptizi/api/v1/admin/report/buyAndSellChartStatisticsByCountry`, {
      params: { reportBuyAndSellDate: period },
    });
  },
  getReportLoginPercentage: () => {
    return http.get(`cryptizi/api/v1/admin/report/loginPercentage`);
  },
  getReportUserStatistics: (startTime, endTime, userStatisticsTypes) => {
    return http.get(`cryptizi/api/v1/admin/report/userChartStatistics`, {
      params: { startTime, endTime, userStatisticsTypes },
    });
  },
  getReportSuccessTradeStatistics: (startTime, endTime, tradeStatisticsTypes) => {
    return http.get(`cryptizi/api/v1/admin/report/tradeChartStatistics`, {
      params: { startTime, endTime, tradeStatisticsTypes },
    });
  },
  getReportUnSuccessTradeStatistics: (startTime, endTime, tradeStatisticsTypes) => {
    return http.get(`cryptizi/api/v1/admin/report/unSuccessTradeChartStatistics`, {
      params: { startTime, endTime, tradeStatisticsTypes },
    });
  },
  getReportOpenTradeStatistics: (startTime, endTime, tradeStatisticsTypes) => {
    return http.get(`cryptizi/api/v1/admin/report/openTradeChartStatistics`, {
      params: { startTime, endTime, tradeStatisticsTypes },
    });
  },
  getReportDepositStatistics: (startTime, endTime, depositStatisticsTypes) => {
    return http.get(`cryptizi/api/v1/admin/report/depositChartStatistics`, {
      params: { startTime, endTime, depositStatisticsTypes },
    });
  },
  getReportFuturesOpenChartStatistics: (filters) => {
    return http.get(`cryptizi/api/v1/admin/report/futuresOpenChartStatistics`, {
      params: filters,
    });
  },
  getReportCryptoChartStatistics: (filters) => {
    return http.get(`cryptizi/api/v1/admin/report/cryptoChartStatistics`, {
      params: filters,
    });
  },
  getReportWithdrawStatistics: (startTime, endTime, withdrawStatisticsTypes) => {
    return http.get(`cryptizi/api/v1/admin/report/withdrawChartStatistics`, {
      params: { startTime, endTime, withdrawStatisticsTypes },
    });
  },
  getReportTradeHistory: (page = 0, size = 10, filters = { side: 'BUY', state: 'CANCELED', type: 'LIMIT' }) => {
    return http.get(`cryptizi/api/v1/admin/report/tradeHistory`, { params: { page, size, ...filters } });
  },
  getReportOtcHistory: (page = 0, size = 10, filters = { side: 'BUY' }) => {
    return http.get(`cryptizi/api/v1/admin/report/otcHistory`, { params: { page, size, ...filters } });
  },
  getReportP2PHistory: (page, size, filters = { side: 'ALL', state: 'ALL' }) => {
    return http.get(`cryptizi/api/v1/admin/report/p2pHistory`, { params: { page, size, ...filters } });
  },
  getReportTransaction: (
    page = 0,
    size = 10,
    filters = {
      allTransaction: 'true',
      depositStatus: 'SUCCESS',
      fiatStatus: 'SUCCESS',
      withdrawStatus: 'Completed',
      startTime: '',
      endTime: '',
      symbol: '',
      transactionId: '',
      type: 'Deposit',
    }
  ) => {
    return http.get(`cryptizi/api/v1/admin/report/transactionHistory`, { params: { page, size, ...filters } });
  },
  getReportConvertHistory: (page = 0, size = 10, filters = { startTime: '', endTime: '', status: 'SUCCESS' }) => {
    return http.get(`cryptizi/api/v1/admin/report/convertHistory`, { params: { page, size, ...filters } });
  },
  getReportFuturesHistory: (page = 0, size = 10, filters = { side: 'LONG', status: 'CLOSED' }) => {
    return http.get(`cryptizi/api/v1/admin/report/futuresHistory`, { params: { page, size, ...filters } });
  },
  getReportPaysafeHistory: (page = 0, size = 10, filters = { side: 'BUY', startTime: '', endTime: '' }) => {
    return http.get(`cryptizi/api/v1/admin/report/paysafeHistory`, { params: { page, size, ...filters } });
  },
  getReportPerfectMoneyHistory: (
    page = 0,
    size = 10,
    filters = { side: 'BUY', status: 'COMPLETED', type: 'NORMAL', startTime: '', endTime: '' }
  ) => {
    return http.get(`cryptizi/api/v1/admin/report/perfectMoneyHistory`, { params: { page, size, ...filters } });
  },

  /**
   * **********************************************************
   * Futures symbols
   */
  getFuturesSymbols: () => {
    return http.get(`cryptizi/api/v1/admin/futures/symbols`);
  },
  addFuturesSymbols: (payloads = []) => {
    return http.post(`cryptizi/api/v1/admin/futures/symbols`, { symbolInfos: payloads });
  },

  /**
   * **********************************************************
   * Notification
   */
  sendNotification: (payload) => {
    return http.post(`cryptizi/api/v1/admin/notification/notification`, payload);
  },
  getNotifications: (page = 0, size = 10) => {
    return http.get(`cryptizi/api/v1/admin/notification/notifications`, { params: { page, size } });
  },
  deleteNotification: (id) => {
    return http.delete(`cryptizi/api/v1/admin/notification/notification`, { params: { id } });
  },

  /**
   * **********************************************************
   * Fiat
   */
  exchangeAccountForDeposit: () => {
    return http.get(`/cryptizi/api/v1/fiat/exchangeAccountForDeposit`);
  },
  getFiatWithdraw: () => {
    return http.get(`cryptizi/api/v1/admin/fiat/withdraw`);
  },
  acceptFiatWithdraw: (id, data) => {
    return http.post(`cryptizi/api/v1/admin/fiat/acceptWithdraw`, { withdrawId: id, ...data });
  },
  denyFiatWithdraw: (id, message = '') => {
    return http.post(`cryptizi/api/v1/admin/fiat/denyWithdraw`, { id, message });
  },
  getFiatDeposit: () => {
    return http.get(`cryptizi/api/v1/admin/fiat/deposit`);
  },
  acceptFiatDeposit: (id, data) => {
    return http.post(`cryptizi/api/v1/admin/fiat/acceptDeposit`, data, { params: { id } });
  },
  denyFiatDeposit: (id, message = '') => {
    return http.post(`cryptizi/api/v1/admin/fiat/denyDeposit`, { id, message });
  },

  getDedicateWalletSettingsTable: (page = 0, rowsPerPage = 10, filters) => {
    return http.get(`/cryptizi/api/v1/admin/binanceTokenInfo/binanceTokenInfo`, { params: {
      all : filters?.typeFilter ==='ALL' ? 'true' : 'false' ,
        page:0,
        size:rowsPerPage,
      ...(filters?.nameFilter && {name:filters?.nameFilter}),
        ...(filters?.symbolFilter && {symbol:filters?.symbolFilter}),
        ...(filters?.networkNameFilter && {networkName:filters?.networkNameFilter}),

        // page ,

    }
    });
  },
  editDedicatedWallet: (data) => {
    return http.put(`/cryptizi/api/v1/admin/binanceTokenInfo/binanceTokenInfo`, data);
  },

  // admin edit page ;
  adminFiatTransaction: (page = 0, size = 10, filters) => {
    return http.get(`cryptizi/api/v1/admin/user/adminFiatTransaction` ,{ params: { page, size, ...filters } });
  },

  adminConfirmFiatTransaction: (page = 0, size = 10, filters) => {
    return http.get(`cryptizi/api/v1/admin/user/adminConfirmFiatTransaction` ,{ params: { page, size, ...filters } });
  },

};

export default Api;
