import React from 'react';
import { TableRow, TableCell, IconButton, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Iconify from '../../../../components/Iconify';
import { navigationUrl } from '../../../../navigationUrl';
import { prettyUppercases } from '../../../../utils';

const TokenRow = ({ row, handleEdit }) => {
  const { t } = useTranslation();
  const { id, network, withdrawFee, minWithdrawAmount } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1.5, typography: 'body2' } }}>
      <TableCell>{id}</TableCell>
      <TableCell>{network}</TableCell>
      <TableCell>{withdrawFee}</TableCell>
      <TableCell>{minWithdrawAmount}</TableCell>

      <TableCell align='center' sx={{ display: 'flex', justifyContent: { xs: 'space-between', md: 'center' } }}>
        <IconButton onClick={() => handleEdit(row)} sx={{ mr: { xs: 0, md: 4 } }}>
          <Iconify icon='eva:edit-2-outline' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default TokenRow;
