import { TableRow, TableCell, Typography, Box } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import { prettyUppercases } from '../../../utils';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function WithdrawTableRow({ row }) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const { createDate, status, fiatType, amount, message, trackingCode } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 2, typography: 'body2', color: 'grey.100' } }}>
      <TableCell>{moment(createDate).locale(language).format('YYYY-MM-DD HH:mm')}</TableCell>

      <TableCell>{amount}</TableCell>

      <TableCell>{fiatType}</TableCell>

      <TableCell>{message || '-'}</TableCell>

      <TableCell>{trackingCode || '-'}</TableCell>

      <TableCell sx={{ width: 120 }} align='center'>
        <Box
          sx={{
            width: 100,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            p: 1,
            bgcolor:
              (status === 'SUCCESS' && 'rgba(48, 224, 161, 0.1)') ||
              (status === 'FAILURE' && 'rgba(224, 48, 48, 0.1)') ||
              'rgba(250, 174, 34, 0.1)',
          }}
        >
          <Typography
            variant='body3'
            sx={{
              color:
                (status === 'SUCCESS' && '#30E0A1') || (status === 'FAILURE' && 'negative.main') || 'rgb(218, 146, 13)',
              lineHeight: 1,
            }}
          >
            {t(prettyUppercases(status))}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
}
