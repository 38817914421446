import React, { lazy } from 'react';
import { Table } from 'antd';
import { FaStar } from 'react-icons/fa';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { TiArrowUnsorted } from 'react-icons/ti';
import { useTheme } from '@mui/material';

import Api from '../../../../http/serviceApi';
import confirmWrapper from '../../../../utils/confirm';

import classesLight from './pairTableLight.module.scss';
import classesDark from './pairTable.module.scss';
import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';

// -------------------------------------------------

const ThemeDark = lazy(() => import('./ThemeDark'));
const ThemeLight = lazy(() => import('./ThemeLight'));

// -------------------------------------------------

const PairTable = ({ style, dataSource, loading, onRow, userFavorites, onReloadUserFavorites }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const classes = theme.palette.mode === 'dark' ? classesDark : classesLight;

  // -----------------------------
  const onFavorite = async (e, symbol) => {
    e.stopPropagation();

    const isFavorite = userFavorites.find((fSymbol) => fSymbol === symbol);

    let response = {};
    if (!isFavorite) {
      response = await Api.addUserFavoriteSymbol(symbol);
    } else {
      if (!(await confirmWrapper(t('Are you sure to remove this symbol from your favorites list?')))) {
        return false;
      }
      response = await Api.deleteUserFavoriteSymbol(symbol);
    }

    if (response?.status === 'SUCCESS') {
      enqueueSnackbar(
        !isFavorite
          ? t('{{symbol}} added to favorites successfully.', { symbol })
          : t('{{symbol}} deleted from your favorites.', { symbol }),
        { variant: 'success' }
      );
      onReloadUserFavorites();
    }

    return true;
  };

  // -----------------------------
  const columns = [
    {
      title: (
        <div className='tradeTableTitleLeft'>
          Pair
          <TiArrowUnsorted style={{ marginLeft: '5px' }} />
        </div>
      ),
      dataIndex: 'symbol',
      key: 'symbol',
      align: 'left',
      sorter: (a, b) => {
        a = a.symbol || '';
        b = b.symbol || '';
        return a.localeCompare(b);
      },
      render: (key, record) => (
        <div className={classes.pairItemDiv}>
          <FaStar
            color={userFavorites.find((fSymbol) => fSymbol === record.symbol) ? '#efba0b' : '#808080'}
            onClick={(e) => onFavorite(e, record.symbol)}
          />
          <div className={classes.pairCoinsDiv}>
            <span>{record.baseAsset}</span>
            <span>{'/'}</span>
            <span>{record.quoteAsset}</span>
          </div>
          {/* <span className={classes.ratio}>1x</span> */}
        </div>
      ),
      showSorterTooltip:false
    },
    {
      title: (
        <div className='tradeTableTitleRight'>
          Price
          <TiArrowUnsorted style={{ marginLeft: '5px' }} />
        </div>
      ),
      dataIndex: 'lastPrice',
      key: 'lastPrice',
      align: 'right',
      sorter: (a, b) => a.lastPrice - b.lastPrice,
      render: (key) => <span style={{ fontSize: '10px' }}>{key}</span>,
      showSorterTooltip:false
    },
    {
      title: (
        <div className='tradeTableTitleRight'>
          Change
          <TiArrowUnsorted style={{ marginLeft: '5px' }} />
        </div>
      ),
      dataIndex: 'priceChangePercent',
      key: 'priceChangePercent',
      align: 'right',
      sorter: (a, b) => a.priceChangePercent - b.priceChangePercent,
      render: (key) => <span className={key >= 0 ? classes.changeGreen : classes.changeRed}>{key + '%'}</span>,
      showSorterTooltip:false
    },
  ];

  // -----------------------------
  let uniqueId = 0;
  return (
    <div>
      <React.Suspense fallback={() => null}>
        {theme.palette.mode === 'dark' ? <ThemeDark /> : <ThemeLight />}
      </React.Suspense>

      <Table
        direction='horizental'
        style={style}
        className={theme.palette.mode === 'dark' ? 'tradePairTableStyle' : 'tradePairTableStyleLight'}
        dataSource={dataSource}
        columns={columns}
        rowKey={(record) => {
          if (!record.__uniqueId) record.__uniqueId = ++uniqueId;
          return record.__uniqueId;
        }}
        pagination={false}
        bordered={false}
        loading={loading}
        scroll={{ x: 170, y: 240 }}
        onRow={onRow}
      />
    </div>
  );
};

export default PairTable;
