import React from 'react';
import { TableCell, TableRow, Typography, Box, Stack, IconButton } from '@mui/material';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Iconify from '../../../../../../components/Iconify';
import SymbolIcon from '../../../../../../components/SymbolIcon';
import { prettyUppercases } from '../../../../../../utils';

const CryptoTransactionTableRow = ({ row, index, onShowModalDetail, status }) => {
  const language = useSelector((store) => store.language);
  const { t } = useTranslation();

  const { amount, createDate, symbol, transactionType } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1, typography: 'body2' } }}>
      <TableCell align='center' sx={{ width: 100 }}>
        {index + 1}
      </TableCell>

      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <SymbolIcon symbol={symbol} />
          <Typography variant='body3'>{symbol}</Typography>
        </Stack>
      </TableCell>

      <TableCell align='center'>{Number(amount).toLocaleString('en-US')}</TableCell>

      <TableCell align='center'>{moment(createDate).locale(language).format('D MMM YYYY - HH:mm')}</TableCell>

      <TableCell align='center'>
        <Stack direction={'row'} alignItems={'center'} spacing={1} justifyContent='center'>
          <Iconify
            icon={transactionType === 'Withdraw' ? 'ph:arrow-up-fill' : 'ph:arrow-down-fill'}
            color={transactionType === 'Withdraw' ? 'error.darker' : 'positive.main'}
            height={18}
          />
          <Typography variant='body2' color={transactionType === 'Withdraw' ? 'error.darker' : 'positive.main'}>
            {transactionType}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell sx={{ width: 170 }} align='center'>
        <Box
          sx={{
            width: 100,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            p: 1,
            bgcolor:
              row?.status?.toString()?.toLocaleLowerCase() === 'success' || row?.status?.toString()?.toLocaleLowerCase() === 'completed' ? 'rgba(48, 224, 161, 0.2)' : 'rgba(250, 34, 86, 0.2)',
          }}
        >
          <Typography
            variant='body3'
            sx={{ color: row?.status?.toString()?.toLocaleLowerCase() === 'success' || row?.status?.toString()?.toLocaleLowerCase() === 'completed' ? '#30E0A1' : 'negative.main', lineHeight: 1 }}
          >
            {t(prettyUppercases(row?.status))}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align='center' sx={{ width: 100 }}>
        <IconButton onClick={onShowModalDetail}>
          <Iconify icon='ph:link-duotone' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default CryptoTransactionTableRow;
