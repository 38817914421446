import React from 'react';
import { Stack, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { RHFTextField, RHFSelect } from '../../../../components/hookForm';

//=====================================================

const DEPOSIT_MODELS = [
  { value: 'DEFAULT', label: 'Default' },
  { value: 'INSTANT', label: 'Instant payment' },
  { value: 'PAYA', label: 'Paya payment' },
];

//=====================================================

const GatewayInputs = ({ selectedGateway }) => {
  const { t } = useTranslation();
  return (
    <Stack
      direction={selectedGateway === 'vandar' ? 'row' : 'column'}
      justifyContent={'space-between'}
      flexWrap={'wrap'}
      sx={{ width: { xs: '100%', md: 680 }, mt: 1 }}
    >
      {selectedGateway === 'vandar' ? (
        <>
          {/* <RHFTextField
            name='business'
            label={'Business'}
            variant='standard'
            fullWidth
            sx={{ maxWidth: 320, mb: 2.8 }}
          /> */}

          <RHFTextField
            name='walletId'
            label={t('Wallet ID')}
            variant='standard'
            fullWidth
            sx={{ maxWidth: 320, mb: 2.8 }}
          />

          <RHFSelect
            name='gatewayDepositModel'
            SelectProps={{
              native: false,
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' } },
              },
            }}
            label={t('Deposit model')}
            variant='standard'
            fullWidth
            sx={{ maxWidth: 320, mb: 2.8 }}
          >
            {DEPOSIT_MODELS.map((each, index) => (
              <MenuItem value={each.value} key={index}>
                <Stack direction='row' spacing={1} alignItems={'center'}>
                  <Typography typography='body3' color='grey.300'>
                    {t(each.label)}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </RHFSelect>

          <RHFTextField name='mobile' label={t('Mobile')} variant='standard' fullWidth sx={{ maxWidth: 320, mb: 2.8 }} />

          <RHFTextField name='token' label={t('Token')} variant='standard' fullWidth sx={{ maxWidth: 320, mb: 2.8 }} />
        </>
      ) : selectedGateway === 'zibal' ? (
        <>
          <RHFTextField name='token' label={t('Token')} variant='standard' fullWidth sx={{ maxWidth: 320, mb: 2.8 }} />
          <RHFTextField
            name='walletId'
            label={t('Wallet ID')}
            variant='standard'
            fullWidth
            sx={{ maxWidth: 320, mb: 2.8 }}
          />
          <RHFSelect
            name='gatewayDepositModel'
            SelectProps={{
              native: false,
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' } },
              },
            }}
            label={t('Deposit model')}
            variant='standard'
            fullWidth
            sx={{ maxWidth: 320, mb: 2.8 }}
          >
            {DEPOSIT_MODELS.map((each, index) => (
              <MenuItem value={each.value} key={index}>
                <Stack direction='row' spacing={1} alignItems={'center'}>
                  <Typography typography='body3' color='grey.300'>
                    {t(each.label)}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </RHFSelect>
        </>
      ) : selectedGateway === 'pir' ? (
        <>
          <RHFTextField name='mobile' label={t('Mobile')} variant='standard' fullWidth sx={{ maxWidth: 320, mb: 2.8 }} />
          <RHFTextField
            name='password'
            type='password'
            label={t('Password')}
            variant='standard'
            fullWidth
            sx={{ maxWidth: 320, mb: 2.8 }}
          />
        </>
      ) : (
        ''
      )}
    </Stack>
  );
};

export default GatewayInputs;
