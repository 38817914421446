import Excel from 'exceljs';

export const exportExcel = ({ language, fileName, heads = [], columnsWidth = [], list = [] }) => {
  let workbook = new Excel.Workbook();

  const worksheet = workbook.addWorksheet(`Sheet 1`);
  worksheet.views = [{ rightToLeft: language === 'fa' ? true : false }];
  worksheet.pageSetup.showGridLines = true;
  worksheet.pageSetup.paperSize = 9;
  worksheet.pageSetup.orientation = 'landscape';

  worksheet.addRow([fileName]);
  worksheet.mergeCells(1, 1, 1, heads.length);
  worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.getRow(1).font = { size: 12, bold: true, color: { argb: 'FFFFFFFF' } };

  worksheet.addRow(heads);

  list.forEach((items, i) => {
    worksheet.addRow([i + 1, ...items]);
  });

  worksheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FF0ABFB4' },
  };
  worksheet.getRow(2).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFBFBFBF' },
  };

  columnsWidth.forEach((columnWidth, index) => {
    worksheet.getColumn(index + 1).width = columnWidth;
  });

  workbook.xlsx.writeBuffer().then((data) => downloadExcel(data, fileName));
};

// -------------------------------
export const downloadExcel = (data, fileName) => {
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = `${fileName}.xlsx`;
  anchor.click();
  window.URL.revokeObjectURL(url);
};
