class NavigationService {
  constructor() {
    this._navigation = null;
    this._location = null;
  }

  set navigate(nav) {
    this._navigation = nav;
  }

  set location(nav) {
    this._location = nav;
  }

  get navigate() {
    return this._navigation;
  }

  get location() {
    return this._location;
  }
}

const navigationService = new NavigationService();
export default navigationService;
