import React from 'react';
import { Col } from 'antd';
import { useTheme } from '@mui/material';
import BasicAuthIdentityInfoForm from './basicAuthIdentityInfoForm';

import classesDark from './basicAuthIdentityInfo.module.scss';
import classesLight from './basicAuthIdentityInfoLight.module.scss';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';

const BasicAuthIdentityInfo = () => {
  const theme = useTheme();
  const classes = theme.palette.mode === 'dark' ? classesDark : classesLight;

  const colPropsForm = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
  };

  return (
    <div className={classes.container}>
      <div className={classes.formBody}>
        <Col {...colPropsForm}>
          <BasicAuthIdentityInfoForm />
        </Col>
      </div>
    </div>
  );
};

export default BasicAuthIdentityInfo;
