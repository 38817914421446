import { Typography, Stack, Tooltip, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

export default function CopyItem({ value, textVariant = 'body2', textWidth = 120, sx = {} }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Stack direction='row' alignItems='center' spacing={0.5} sx={sx}>
      {value && (
        <CopyToClipboard text={value} onCopy={() => enqueueSnackbar(t('Copied!'))}>
          <Tooltip title={t('Copy')}>
            <IconButton>
              <Iconify icon={'ep:copy-document'} sx={{ width: 20, height: 20, color: 'grey.100' }} />
            </IconButton>
          </Tooltip>
        </CopyToClipboard>
      )}

      <Typography variant={textVariant} sx={{ width: textWidth }} noWrap>
        {value || '-'}
      </Typography>
    </Stack>
  );
}
