import { Box, Typography } from '@mui/material';
import { css } from 'styled-components';
import { fDecimal, fNumber } from '../../../utils/formatNumber';

export default function OTCConvert({ side, spendSymbol, receiveSymbol, calculated }) {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography
        variant='body4'
        color='grey.100'
        sx={css`
          /* @noflip */
          direction: ltr;
          display: block;
          letter-spacing: 1px;
        `}
      >
        {side === 'sell' ? (
          <>
            1 {spendSymbol} = {receiveSymbol === 'IRR' ? fNumber(calculated) : fDecimal(calculated)} {receiveSymbol}
          </>
        ) : (
          <>
            1 {spendSymbol} = {fDecimal(calculated > 0 ? calculated : 0)} {receiveSymbol}
          </>
        )}
      </Typography>
    </Box>
  );
}
