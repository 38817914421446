import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import moment from 'jalali-moment';
import Api from '../../../../../http/adminHttp/serviceApiAdmin';

export default function useUserSubmit(userInfo, onReload, updateProfile = false, updatePolicy = false) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  // -------------------------
  const onSubmit = async (payload) => {
    if (updateProfile) {
      await onProfileSubmit(payload);
    } else if (updatePolicy) {
      await onPolicySubmit(payload);
    } else {
      await onConfirmRejectSubmit(payload);
    }
  };

  // -------------------------
  const onConfirmRejectSubmit = async (payload) => {
    try {
      if (payload.status === 'approve') {
        const response = await Api.confirmAuthenticationLevel(userInfo.userId);
        if (response.status === 'SUCCESS') {
          enqueueSnackbar(t('The user approve successfully'), { variant: 'success' });
          onReload();
        }
      } else if (payload.status === 'reject') {
        const response = await Api.rejectAuthenticationLevel({ userId: userInfo.userId });
        if (response.status === 'SUCCESS') {
          enqueueSnackbar(t('The user reject successfully'), { variant: 'success' });
          onReload(false);
        }
      }
    } catch (e) {}
  };

  // -------------------------
  const onProfileSubmit = async (payload) => {
    let birthday = null;
    if (payload.birthday) {
      birthday = /^[0-9]+$/.test(payload.birthday) ? +payload.birthday : moment(payload.birthday).valueOf();
    }

    try {
      const response = await Api.setUserInfo(userInfo.userId, {
        address: payload.address,
        birthday,
        cityName: payload.cityName,
        countryId: payload.countryId ? +payload.countryId : null,
        middleName: payload.middleName,
        phoneNumber: payload.phoneNumber,
        postalCode: payload.postalCode,
      });
      if (response.status === 'SUCCESS') {
        enqueueSnackbar(t('The user updated successfully'), { variant: 'success' });
      }
    } catch (e) {}
  };

  // -------------------------
  const onPolicySubmit = async (payload) => {
    try {
      const response = await Api.adminOtherPolicy(userInfo.userId, {
        authenticationLevel: payload.authenticationLevel,
        changePassword: payload.changePassword?.toString()?.toLowerCase() === 'active',
        twoStepLogin: payload.twoStepLogin?.toString()?.toLowerCase() === 'active',
        softOtpAuthEnable: payload.softOtpAuthEnable,
        emailAuthEnable: payload.emailAuthEnable,
        smsAuthEnable: payload.smsAuthEnable,
        ...(payload.password?.length > 0 &&  {password:payload.password})
      });
      if (response.status === 'SUCCESS') {
        enqueueSnackbar(t('The user policy updated successfully'), { variant: 'success' });
      }
    } catch (e) {}
  };

  // -------------------------
  return {
    onSubmit,
  };
}
