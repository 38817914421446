import { Slider } from '@mui/material';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';

// ----------------------------------------------------------------------

RHFSlider.propTypes = {
  name: PropTypes.string,
};

export default function RHFSlider({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Slider
          {...field}
          value={+`${field.value || 0}`.replace(/,/g, '')}
          onChange={(e, newValue) => field.onChange(newValue)}
          {...other}
        />
      )}
    />
  );
}
