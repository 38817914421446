import React from 'react';
import { Stack, InputAdornment, useTheme } from '@mui/material';

import { RHFAutocomplete, RHFDatePicker, RHFTextField } from '../../../../../components/hookForm';
import Iconify from '../../../../../components/Iconify';
import { useTranslation } from 'react-i18next';

const BasicTab = ({ countries }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        mt: 5,
        width: { xs: '100%', md: '80%' },
        '& input': { color: `${theme.palette.grey[100]} !important` },
        '& .Mui-disabled': {
          color: `${theme.palette.grey[500]} !important`,
          '& input': { WebkitTextFillColor: `${theme.palette.grey[300]} !important` },
        },
      }}
      spacing={{ xs: 5, md: 3 }}
    >
      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={5}>
        <RHFTextField
          name='firstName'
          label={t('First Name')}
          variant='standard'
          sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
          disabled
        />
        <RHFTextField
          name='lastName'
          label={t('Last Name')}
          variant='standard'
          sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
          disabled
        />
      </Stack>

      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={5}>
        <RHFTextField
          name='middleName'
          label={t('Middle Name')}
          variant='standard'
          sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
        />
        <RHFAutocomplete
          variant='standard'
          name='countryId'
          label={t('Country')}
          options={countries}
          disableClearable
          sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
        />
      </Stack>

      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={5}>
        <RHFTextField
          name='address'
          label={t('Address')}
          variant='standard'
          sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
        />
        <RHFDatePicker
          label={t('Birth Date')}
          name={'birthday'}
          variant='standard'
          sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Iconify icon={'akar-icons:calendar'} sx={{ width: 23, height: 23, color: 'grey.100', mb: 2, mr: 1 }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={5}>
        <RHFTextField
          name='postalCode'
          label={t('Postal Code')}
          variant='standard'
          sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
        />
        <RHFTextField
          name='cityName'
          label={t('City')}
          variant='standard'
          sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
        />
      </Stack>

      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={5}>
        <RHFTextField name='phoneNumber' label={t('Phone Number')} variant='standard' sx={{ minWidth: 150 }} />
        <RHFTextField name='emailAddress' label={t('Email')} variant='standard' sx={{ minWidth: 150 }} disabled />
      </Stack>

      {/* <Stack direction={{ xs: 'column', md: 'row' }}>
        <Box sx={{ position: 'relative', width: { xs: '100%', md: '48%' } }}>
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              borderBottom: '1px solid #5D6588',
              pr: 1.5,
              pb: 0.5,
            }}
          >
            <Typography
              variant='body2'
              color={'grey.300'}
              sx={{
                '&::before': {
                  content: '"|"',
                  display: 'block',
                  position:"absolute",
                  width: 1,
                  height: 10,
                  color: 'grey.300',
                  left:38,
                },
              }}
            >
              +54
            </Typography>
          </Box>
          <RHFTextField name='phoneNumber' label={'Phone Number'} variant='standard' sx={{ minWidth: 150, pl: 5.5 }} />
        </Box>
      </Stack> */}
    </Stack>
  );
};

export default BasicTab;
