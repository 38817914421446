import { Button, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../components/Iconify';
import ModalContainer from '../../../../components/ModalContainer';

export default function WalletDetailModal({ open, onClose, data, status }) {
  const { t } = useTranslation();

  return (
    <ModalContainer open={open} onClose={onClose} width={450}>
      <Stack sx={{ mt: -3, mb: 3.6, alignItems: 'center' }}>
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ borderRadius: '100%', backgroundColor: 'grey.600', width: 91, height: 91 }}
        >
          <Iconify
            icon={status === 'SUCCESS' ? 'eva:checkmark-circle-2-outline' : 'eva:close-circle-outline'}
            sx={{
              mt: 0.2,
              ml: 0.2,
              width: 40,
              height: 40,
              color: status === 'SUCCESS' ? '#30E0A1' : '#FA2256',
            }}
          />
        </Stack>

        <Typography variant='body2' sx={{ mt: 3.25, color: status === 'SUCCESS' ? '#30E0A1' : '#FA2256' }}>
          {status === 'SUCCESS' ? t('Transfer completed successfully') : t('The transfer encountered an error')}
        </Typography>
      </Stack>

      <Stack spacing={2}>
        <Stack direction={'row'} alignItems={'center'} spacing={4} justifyContent={'space-between'}>
          <Typography variant='body3' color={'grey.400'}>
            {t('TxID')}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {data?.txId}
          </Typography>
        </Stack>

        <Stack direction={'row'} alignItems={'center'} spacing={4} justifyContent={'space-between'}>
          <Typography variant='body3' color={'grey.400'}>
            {t('Deposit ID')}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {data?.depositId}
          </Typography>
        </Stack>

        <Stack direction={'row'} alignItems={'center'} spacing={4} justifyContent={'space-between'}>
          <Typography variant='body3' color={'grey.400'}>
            {t('Amount')}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {data?.amount} {data?.fiatType}
          </Typography>
        </Stack>

        <Stack direction={'row'} alignItems={'center'} spacing={4} justifyContent={'space-between'}>
          <Typography variant='body3' color={'grey.400'}>
            {t('Gateway')}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {data?.gateway}
          </Typography>
        </Stack>

        <Stack direction={'row'} alignItems={'center'} spacing={4} justifyContent={'space-between'}>
          <Typography variant='body3' color={'grey.400'}>
            {t('Shetab Gateway')}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {data?.shetabGateway}
          </Typography>
        </Stack>

        {/* <Stack direction={'row'} alignItems={'center'} spacing={4} justifyContent={'space-between'}>
          <Typography variant='body3' color={'grey.400'}>
            {t('IP')}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {ip}
          </Typography>
        </Stack> */}

        <Stack direction={'row'} alignItems={'center'} spacing={4} justifyContent={'space-between'}>
          <Typography variant='body3' color={'grey.400'}>
            {t('Name')}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {data?.firstName || ''} {data?.lastName || ''}
          </Typography>
        </Stack>

        <Stack direction={'row'} alignItems={'center'} spacing={4} justifyContent={'space-between'}>
          <Typography variant='body3' color={'grey.400'}>
            {t('Mobile')}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {data?.mobile || '-'}
          </Typography>
        </Stack>

        <Divider />

        {/* <Typography variant='body3' color={'grey.400'}>
          {t('Description')}
        </Typography> */}
      </Stack>

      <Stack alignItems={'center'}>
        <Button
          variant='outlined'
          size='large'
          color='inherit'
          sx={{
            typography: 'body3',
            fontWeight: 500,
            mt: 4.25,
            width: 145,
          }}
          onClick={onClose}
        >
          {t('Done')}
        </Button>
      </Stack>
    </ModalContainer>
  );
}
