import { TableRow, TableCell, Typography, IconButton, Stack, Box } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import CopyItem from '../../../../components/CopyItem';
import { prettyUppercases } from '../../../../utils';
import Iconify from '../../../../components/Iconify';
import SymbolIcon from '../../../../components/SymbolIcon';
import { useTranslation } from 'react-i18next';
import { fFloat } from '../../../../utils/formatNumber';

// ----------------------------------------------------------------------

export default function OrderHistoryTableRow({ row, onShowModalDetail, isAdmin = false }) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const {
    amount,
    firstName,
    lastName,
    emailAddress,
    mobile,
    createdDate,
    transactionCode,
    status,
    fiat,
    price,
    side,
    symbol,
    symbolAmount,
    fiatAmount
  } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 2, typography: 'body2', color: 'grey.100' } }}>
      <TableCell>{moment(createdDate).locale(language).format('YYYY-MM-DD HH:mm')}</TableCell>

      {isAdmin && (
        <TableCell>
          <Typography variant='body3'>
            {firstName} {lastName}
          </Typography>
          {emailAddress && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {emailAddress}
            </Typography>
          )}
          {mobile && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {mobile}
            </Typography>
          )}
        </TableCell>
      )}

      <TableCell align='center'>
        <Typography
          variant='body4'
          color={(side === 'BUY' && 'positive.main') || (side === 'SELL' && 'negative.main') || 'grey.100'}
        >
          {side}
        </Typography>
      </TableCell>

      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ width: 140 }}>
          <SymbolIcon symbol={symbol} width={22} height={22} />
          <Typography variant='body3' noWrap sx={{ color: 'grey.100' }}>
            {fFloat(symbolAmount)} {symbol}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>
        {fiatAmount} {fiat}
      </TableCell>


      <TableCell>
        {price}
      </TableCell>


      <TableCell>
        <CopyItem value={transactionCode} textVariant='body4' />
      </TableCell>

      <TableCell sx={{ width: 120 }} align='center'>
        <Box
          sx={{
            width: 100,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            p: 1,
            bgcolor:
              (status === 'COMPLETED' && 'rgba(48, 224, 161, 0.1)') ||
              (status === 'FAILED' && 'rgba(224, 48, 48, 0.1)') ||
              'rgba(250, 174, 34, 0.1)',
          }}
        >
          <Typography
            variant='body3'
            sx={{
              color:
                (status === 'COMPLETED' && '#30E0A1') ||
                (status === 'FAILED' && 'negative.main') ||
                'rgb(218, 146, 13)',
              lineHeight: 1,
            }}
          >
            {t(prettyUppercases(status))}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align='center' sx={{ width: 100 }}>
        <IconButton onClick={onShowModalDetail}>
          <Iconify icon='ph:link-duotone' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
