import { useState, useEffect } from 'react';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Api from '../../../http/serviceApi';
import { SET_PAGE_STATE } from '../../../store/actionTypes';
import LoadingScreen from '../../../components/LoadingScreen';
import AddBankCard from './addBankCard';
import BankCardList from './bankCardList';

export default function BankCard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [cardList, setCardList] = useState(null);

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Credit Card' },
    });

    getBankCard();
  }, []);

  const getBankCard = async () => {
    const response = await Api.getBankCard();
    if (response) {
      setCardList(response.bankCardInfos);
    }
  };

  if (cardList === null) {
    return <LoadingScreen />;
  }

  return (
    <Box sx={{ mx: { xs: 2, lg: 0 } }}>
      <Grid container spacing={3.5}>
        <Grid item xs={12} md={7}>
          <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 } }}>
            <Typography variant='h6' sx={{ fontWeight: 500, mb: 2 }}>
              {t('Add a bank card')}
            </Typography>

            <Typography variant='body3' color='grey.100'>
              {t('To insert a bank card, fill out the form below and note that the bank card must be in your name.')}
            </Typography>

            <AddBankCard reloadList={getBankCard} />
          </Card>
        </Grid>

        <Grid item xs={12} md={5}>
          {cardList?.length > 0 ? (
            <BankCardList list={cardList} />
          ) : (
            <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 } }}>
              <Stack alignItems={'center'} justifyContent={'center'}>
                <Typography variant='body3' color='grey.100'>
                  {t('You do not have a registered bank card')}
                </Typography>
              </Stack>
            </Card>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
