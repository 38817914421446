import { Box, Card, Grid, Stack, Typography, useTheme } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { BaseOptionChart } from '../../../components/chart';
import { fPercent } from '../../../utils/formatNumber';
import mergeDeep from '../../../utils/merge';
import { LightChartBorder } from '../report/style';
import Api from '../../../http/adminHttp/serviceApiAdmin';
import { useEffect } from 'react';

export const WALLET_CHART_HEIGHT = 250;

export default function ChartDonutStatistic() {
  const { t } = useTranslation();

  const [data, setData] = useState({
    userWebRegisterPercent: 0,
    userMobileRegisterPercent: 0,
    orderWebPercent: 0,
    orderMobilePercent: 0,
    paySafePercent: 0,
    perfectMoneyPercent: 0,
    tetherPercent: 0,
    bitcoinPercent: 0,
  });

  // --------------------
  const getData = async () => {
    try {
      const response = await Api.getPercentageStatistics();
      setData({
        userWebRegisterPercent: response?.userWebRegisterPercent || 0,
        userMobileRegisterPercent: response?.userMobileRegisterPercent || 0,
        orderWebPercent: response?.orderWebPercent || 0,
        orderMobilePercent: response?.orderMobilePercent || 0,
        paySafePercent: response?.paySafePercent || 0,
        perfectMoneyPercent: response?.perfectMoneyPercent || 0,
        tetherPercent: response?.tetherPercent || 0,
        bitcoinPercent: response?.bitcoinPercent || 0,
      });
    } catch (e) {}
  };

  // ---------------------
  useEffect(() => {
    getData();
  }, []);

  // ---------------------
  return (
    <>
      <Grid item xs={12} sm={4}>
        <Card sx={{ minHeight: 420 }}>
          <Typography variant='h6' sx={{ fontWeight: 700, textAlign: 'center', mb: 3 }}>
            {t('Register')}
          </Typography>
          <DonutChart
            colors={['#BD47FB', '#F27F16']}
            labels={[t('Website'), t('Mobile')]}
            series={[data.userWebRegisterPercent, data.userMobileRegisterPercent]}
          />
        </Card>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Card sx={{ minHeight: 420 }}>
          <Typography variant='h6' sx={{ fontWeight: 700, textAlign: 'center', mb: 3 }}>
            {t('Orders')}
          </Typography>
          <DonutChart
            colors={['#BD47FB', '#F27F16']}
            labels={[t('Website'), t('Mobile')]}
            series={[data.orderWebPercent, data.orderMobilePercent]}
          />
        </Card>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Card sx={{ minHeight: 420 }}>
          <Typography variant='h6' sx={{ fontWeight: 700, textAlign: 'center', mb: 3 }}>
            {t('Currency')}
          </Typography>
          <DonutChart
            colors={['#BD47FB', '#11CABE', '#1E68F6', '#F27F16']}
            labels={[t('Bitcoin'), t('PaySafe Voucher'), t('PMvoucher'), t('Tether')]}
            series={[data.bitcoinPercent, data.paySafePercent, data.perfectMoneyPercent, data.tetherPercent]}
          />
        </Card>
      </Grid>
    </>
  );
}

const DonutChart = ({ colors, labels, series }) => {
  const theme = useTheme();

  const chartOptions = mergeDeep(BaseOptionChart(), {
    colors: colors,
    labels: labels,
    sparkline: { enabled: true },
    stroke: { colors: [theme.palette.background.paper] },
    legend: { show: false },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value) => fPercent(value),
        title: {
          formatter: (label) => label,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '77%',
          labels: {
            show: false,
          },
        },
      },
    },
  });

  return (
    <>
      <Stack justifyContent='center' alignItems='center' sx={{ position: 'relative', flex: 1, height: '100%' }}>
        <LightChartBorder />

        <ReactApexChart
          type='donut'
          series={series}
          options={chartOptions}
          height={WALLET_CHART_HEIGHT}
          width={WALLET_CHART_HEIGHT}
        />
      </Stack>

      <Stack direction='row' justifyContent={'space-evenly'} flexWrap='wrap' sx={{ px: { xl: 3 }, mt: 3 }}>
        {labels.map((label, index) => (
          <Stack
            direction='row'
            key={index}
            alignItems='center'
            justifyContent={labels.length === 2 ? 'center' : 'flex-start'}
            spacing={1.5}
            sx={{ mt: 1.5, ...(labels.length > 1 && { width: { xs: '100%', sm: '50%' } }) }}
          >
            <Box sx={{ width: 16, height: 16, borderRadius: '100%', bgcolor: colors[index] }} />
            <Typography variant='body3'>{label}</Typography>
          </Stack>
        ))}
      </Stack>
    </>
  );
};
