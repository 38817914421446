import { Col, Row, Form } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Api from '../../../http/adminHttp/serviceApiAdmin';
import IpBlockTable from './ipBlockTable/ipBlockTable';
import classes from './ipBlock.module.scss';
import { InputCustom } from '../../../components/InputCustom/inputCustom';
import { useSnackbar } from 'notistack';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

const IpBlock = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const [adminUserInfoList, setAdminUserInfoList] = useState([]);
  const [blockIpList, setBlockIpList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const colProps = {
    xs: 24,
    sm: 15,
    md: 10,
    lg: 8,
    xl: 8,
  };

  useEffect(() => {
    getAllBlockIp();
  }, []);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        blockIp(values?.ip, true);
      })
      .catch((errorInfo) => {});
  };

  const blockIp = async (ip, block) => {
    setLoading(true);
    try {
      const token = window.localStorage.getItem('adminToken');
      const response = await Api.blockIp(ip, block, token);
      if (response?.status === 'SUCCESS') {
        enqueueSnackbar(t('The entered ip was successfully blocked.'), { variant: 'success' });
        getAllBlockIp();
      }
    } catch (e) {}
    setLoading(false);
  };

  const getAllBlockIp = async () => {
    setLoading(true);
    try {
      const token = window.localStorage.getItem('adminToken');
      const response = await Api.getAllBlockIp(token);
      if (response) {
        const ipList = response.map((item) => ({ ip: item }));
        setBlockIpList(ipList);
      }
    } catch (e) {}
    setLoading(false);
  };

  const onDeleteRowClick = async (ip) => {
    setLoading(true);
    try {
      const token = window.localStorage.getItem('adminToken');
      const response = await Api.deleteBlockIp(ip, token);
      if (response?.status === 'SUCCESS') {
        enqueueSnackbar(t('The blocked ip was successfully deleted.'), { variant: 'success' });
        getAllBlockIp();
      }
    } catch (e) {}

    setLoading(false);
  };

  return (
    <div>
      <div className={classes.card} style={{ marginBottom: '10px' }}>
        <Form
          layout='vertical'
          form={form}
          name='login'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <div style={{ fontSize: '16px', marginBottom: '20px' }}>
            <span>IP Block</span>
          </div>
          <Row gutter={[10, 0]}>
            <Col {...colProps}>
              <Form.Item
                name='ip'
                rules={[
                  {
                    required: true,
                    message: t('Please input IP!'),
                  },
                  {
                    //IPv6
                    //((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}
                    //IPv4
                    //(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])
                    pattern: new RegExp(
                      `(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])`
                    ),
                    message: t('The IP format is not correct.'),
                  },
                ]}
              >
                <InputCustom label={t('Ip')} placeholder='xxx.xxx.xxx.xxx' required={true} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <LoadingButton sx={{ width: '120px', height: 35 }} type='submit' variant='contained'>
                  {t('Block')}
                </LoadingButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className={classes.card}>
        <IpBlockTable dataSource={blockIpList} loading={loading} onDeleteRowClick={onDeleteRowClick} />
      </div>
    </div>
  );
};

export default IpBlock;
