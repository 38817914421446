import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import ModalContainer from '../../../components/ModalContainer';
import ModalFilter from '../../../components/ModalFilter';
import SymbolIcon from '../../../components/SymbolIcon';

const itemsPerPage = 15;

export default function ConvertPairModal({ open, onClose, onSelect = () => {}, title = 'Select Coin', list = [] }) {
  const [filtered, setFiltered] = useState([]);

  // fetching data from server
  const [dataSource, setDataSource] = useState([]);

  // -------------------------
  useEffect(() => {
    let newList = list.map((item) => ({ ...item, symbol: item.toAsset }));
    setDataSource(newList);
    setFiltered(newList);
  }, [list]);

  // lazy load list
  const [hasMore, setHasMore] = useState(true);
  const [countPerPage, setCountPerPage] = useState(itemsPerPage);
  const loadMore = () => {
    if (countPerPage >= filtered.length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setCountPerPage((prev) => prev + itemsPerPage);
      }, 300);
    }
  };

  const onSearch = (newList) => {
    setFiltered(newList);
    setCountPerPage(itemsPerPage);
    if (itemsPerPage < newList.length) {
      setHasMore(true);
    }
  };

  useEffect(() => {
    if (!open) {
      setFiltered(dataSource);
    }
  }, [open]);

  return (
    <ModalContainer open={open} onClose={onClose} title={title} width={400}>
      <ModalFilter dataSource={dataSource} setFiltered={onSearch} />

      <Box
        sx={{
          height: 340,
          overflow: 'auto',
          '&::-webkit-scrollbar': { width: 18 },
          '&::-webkit-scrollbar-track': { backgroundColor: 'transparent' },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#53555f',
            borderRadius: '18px',
            border: '6px solid transparent',
            backgroundClip: 'content-box',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#6c6e75',
          },
        }}
      >
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <Stack key='loader' alignItems={'center'} justifyContent={'center'} sx={{ my: 2 }}>
              <CircularProgress size={20} color='success' />
            </Stack>
          }
          useWindow={false}
        >
          {filtered.slice(0, countPerPage).map((item) => (
            <RowSymbol
              key={item.symbol}
              item={item}
              onSelect={() => {
                onSelect(item.symbol);
                onClose();
              }}
            />
          ))}
        </InfiniteScroll>
      </Box>
    </ModalContainer>
  );
}

const RowSymbol = ({ item, onSelect }) => {
  const { symbol } = item;

  return (
    <Button fullWidth onClick={onSelect} sx={{ py: 1.5, px: 2, borderRadius: 1, textAlign: 'left' }}>
      <Stack direction='row' alignItems={'center'} sx={{ width: '100%' }} spacing={2}>
        <SymbolIcon symbol={symbol} width={24} height={24} />

        <Typography variant='subtitle2'>{symbol}</Typography>

        {/* <Typography variant='body3' color='grey.300' noWrap>
          {symbol}
        </Typography> */}
      </Stack>
    </Button>
  );
};
