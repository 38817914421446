import { alpha, Box, Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Image from '../../../../components/Image';
import ModalContainer from '../../../../components/ModalContainer';

import tickIcon from '../../../../assets/landing/tick.svg';
import SymbolIcon from '../../../../components/SymbolIcon';

export default function WithdrawSuccessModal({ open, onClose, symbolSelected, networkSelected, address, amount , withdrawFee ,  finalAmount ,internalValues , fee}) {
  const { t } = useTranslation();

  console.log(withdrawFee , fee , internalValues , amount , address , networkSelected)
  return (
    <ModalContainer open={open} onClose={onClose} width={590} title={'Withdraw'}>
      <Stack alignItems={'center'} sx={{ mt: 3, mb: 5 }}>
        <PayoutLogoWrapper justifyContent={'center'} alignItems={'center'}>
          <PayoutLogoShadow />
          <Image src={tickIcon} alt='' sx={{ width: 120, mt: -1, ml: 0.5 }} />
        </PayoutLogoWrapper>
      </Stack>

      <Stack spacing={1} alignItems={'center'} sx={{ mb: 5 }}>
        <Typography variant='body2' color={'grey.100'}>
          {t('Withdrawal Request Submitted')}
        </Typography>
        <Typography variant='body3' color={'grey.100'}>
          {t('Please note that you will receive an email once it is completed')}
        </Typography>
      </Stack>

      <Stack spacing={0.5} alignItems={'center'} sx={{ mb: 7 }}>
        <Typography variant='body2' color={'grey.100'}>
          {t('The receiver will get')}
        </Typography>
        <Typography variant='body2'>
          {amount || 0} {symbolSelected || ''}{' '}
          {address &&
            t('(Fee : {{fee}} {{symbol}})', {
              fee: networkSelected?.withdrawFee || 0,
              symbol: symbolSelected || '',
            })}

          {
            fee?.fee &&
            t('(Fee {{fee}} {{symbol}})', {
              fee: fee?.fee || 0,
              symbol: symbolSelected || '',
            })
          }
        </Typography>
      </Stack>

      <Stack sx={{ mx: 2 }} spacing={1.5}>
        <Stack direction={'row'} alignItems={'center'}>
          <Typography variant='body3' color={'grey.400'} sx={{ width: 100 }}>
            {t('Coin')}
          </Typography>

          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            {symbolSelected && <SymbolIcon symbol={symbolSelected} />}

            <Typography variant='body3' color={'grey.100'}>
              {symbolSelected || ''}
            </Typography>
          </Stack>
        </Stack>

        {address &&

          <Stack direction={'row'} alignItems={'center'}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 100 }}>
              {t('Address')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {address || ''}
            </Typography>
          </Stack>
        }


        {internalValues?.recipientId &&

          <Stack direction={'row'} alignItems={'center'}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 100 }}>
              {t('Recipient’s Id')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {internalValues?.recipientId || ''}
            </Typography>
          </Stack>
        }

        {internalValues?.recipientEmailAddress &&

          <Stack direction={'row'} alignItems={'center'}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 100 }}>
              {t('Recipient’s email')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {internalValues?.recipientEmailAddress || ''}
            </Typography>
          </Stack>
        }

        {internalValues?.recipientPhoneNumber &&

          <Stack direction={'row'} alignItems={'center'}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 100 }}>
              {t('Recipient’s phone number')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {internalValues?.recipientPhoneNumber || ''}
            </Typography>
          </Stack>
        }
        {
          finalAmount &&
          <Stack direction={'row'} alignItems={'center'}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 100 }}>
              {t('Final Amount')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {finalAmount || ''}
            </Typography>
          </Stack>
        }

        {address &&

          <Stack direction={'row'} alignItems={'center'}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 100 }}>
              {t('Withdraw fee')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {withdrawFee || ''}
            </Typography>
          </Stack>
        }

        {
          fee?.fee &&

          <Stack direction={'row'} alignItems={'center'}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 100 }}>
              {t('Withdraw fee')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {fee?.fee || 0} {symbolSelected || ''}
            </Typography>
          </Stack>

        }

        {address && (
          <>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography variant='body3' color={'grey.400'} sx={{ width: 100 }}>
                {t('Address')}
              </Typography>
              <Typography variant='body3' color={'grey.100'}>
                {address || ''}
              </Typography>
            </Stack>


          </>
        )}
        {
          networkSelected?.name &&
          <Stack direction={'row'} alignItems={'center'}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 100 }}>
              {t('Network')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {networkSelected?.name || ''}
            </Typography>
          </Stack>
        }

        <Stack direction={'row'} alignItems={'center'}>
          <Typography variant='body3' color={'grey.400'} sx={{ width: 100 }}>
            {t('Source')}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {t('Main Wallet')}
          </Typography>
        </Stack>
      </Stack>

      {address && (
        <Stack alignItems={'center'} sx={{ mt: 4.5, mb: 5 }}>
          <Button color='warning' sx={{ borderRadius: 1, typography: 'subtitle2', width: 150 }}>
            {t('Save Address')}
          </Button>
        </Stack>
      )}

      <Stack spacing={2} sx={{ backgroundColor: 'grey.600_5', p: 2, borderRadius: 1, mb: 2 }}>
        <Typography variant='body2' color={'grey.100'} fontWeight={600}>
          {t('One-Step Withdrawal')}
        </Typography>
        {/*<Typography variant='body2' color={'grey.100'}>*/}
        {/*  {t('Enable One-Step Withdrawal to withdraw to whitelis addresses easily.')}*/}
        {/*</Typography>*/}
      </Stack>
    </ModalContainer>
  );
}

const PayoutLogoWrapper = styled(Stack)({ position: 'relative', width: 100, height: 100 });

const PayoutLogoShadow = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'absolute',
  background: 'rgba(48, 224, 161, 0.2)',
  opacity: '0.3',
  border: '4px solid rgba(48, 224, 161, 0.5)',
  boxShadow: '0px 0px 22px rgba(48, 224, 161, 0.5)',
  borderRadius: '75.5px',
});
