import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import Api from '../http/serviceApi';

export default function useWallet(onlyNoneZero = false) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);

  const [userWallet, setUserWallet] = useState({
    btcValue: 0,
    dollar: 0,
    monthPnlDollar: 0,
    monthPnlPercent: 0,
    rial: 0,
    todayPnlDollar: 0,
    todayPnlPercent: 0,
    usdtValue: 0,
    walletInfos: [],
  });

  useEffect(() => {
    getUserWallet();
  }, []);

  const getUserWallet = async () => {
    setLoading(true);

    const response = await Api.getUserWallet(onlyNoneZero);
    if (response?.status === 'SUCCESS') {
      setUserWallet(response);
    } else if (response?.status === 'FAILURE') {
      enqueueSnackbar(response.message, { variant: 'error' });
    }

    setLoading(false);
  };

  return { userWallet, loading, onReloadWallet: getUserWallet };
}
