import { useEffect, useState } from 'react';
import { Box, Card, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'jalali-moment';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import ViewTicketMessages from './viewTicketMessages';
import TicketRateModal from './ticketRateModal';
import ViewTicketForm from './viewTicketForm';
import { useNavigate, useParams } from 'react-router';
import Api from '../../../../http/serviceApi';
import ApiAdmin from '../../../../http/adminHttp/serviceApiAdmin';
import LoadingScreen from '../../../../components/LoadingScreen';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { navigationUrl } from '../../../../navigationUrl';
import { prettyUppercases } from '../../../../utils';
import SymbolIcon from '../../../../components/SymbolIcon';
import Image from '../../../../components/Image';

import USD from '../../../../assets/logo/dolar.svg';
import IRR from '../../../../assets/logo/IRR.svg';

export default function ViewTicket({ isAdmin }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const language = useSelector((store) => store.language);
  const userInfo = useSelector((store) => store.userInfo);
  const adminUserInfo = useSelector((store) => store.adminUserInfo);

  const { id: ticketId } = useParams();

  // -------------------------
  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Ticket' },
    });
  }, []);

  // -------------------------
  const [messages, setMessages] = useState(null);
  const [openTicketRateModal, setOpenTicketRateModal] = useState(false);
  const [details, setDetails] = useState(null);
  const [isCloseLoading, setIsCloseLoading] = useState(false);

  // -------------------------
  let user = userInfo;
  if (isAdmin) {
    user = adminUserInfo;
  }

  // -------------------------
  const getList = async () => {
    try {
      let response;
      if (isAdmin) {
        response = await ApiAdmin.getTicketMessages(ticketId);
      } else {
        response = await Api.getTicketMessages(ticketId);
      }

      const otherSideMessages = response.ticketMessageInfos.find((m) => m.userId !== user?.userId);

      setDetails({
        registerDate: response.registerDate,
        lastActivity: response?.lastActivity || response.registerDate,
        id: response.ticketId,
        subject: response.ticketName,
        status: response.ticketStatus,
        userId: otherSideMessages && isAdmin ? otherSideMessages.userId : null,
        fullName:
          otherSideMessages && isAdmin ? `${otherSideMessages?.firstName} ${otherSideMessages?.lastName}` : null,
        transaction: response?.transactionInfoForTicketInfo || null,
      });

      setMessages(
        response.ticketMessageInfos.reduce((rv, x) => {
          const date = moment(x.createDate).format('YYYY-MM-DD');
          (rv[date] = rv[date] || []).push(x);
          return rv;
        }, {})
      );

      setTimeout(() => {
        const messageEl = document
          .getElementById('ticket-messages')
          ?.getElementsByClassName('simplebar-content-wrapper')[0];
        messageEl.scrollTop = messageEl.scrollHeight;
      }, 300);
    } catch (e) {}
  };

  // -------------------------
  useEffect(() => {
    getList();
  }, [ticketId]);

  // -------------------------
  const onCloseTicket = async () => {
    setIsCloseLoading(true);

    try {
      let data;
      if (isAdmin) {
        data = await ApiAdmin.closeTicket(ticketId);
      } else {
        data = await Api.closeTicket(ticketId);
      }

      if (data?.status === 'SUCCESS') {
        enqueueSnackbar(t('The ticket was successfully closed.'), { variant: 'success' });

        setDetails((prev) => ({ ...prev, status: 'CLOSE' }));

        if (!isAdmin) {
          setOpenTicketRateModal(true);
        }
      }
    } catch (e) {}

    setIsCloseLoading(false);
  };

  // -------------------------
  if (messages === null) {
    return <LoadingScreen />;
  }

  // -------------------------
  const isOpen = details.status === 'OPEN';
  const isPending = details.status === 'PENDING';

  return (
    <>
      <Stack spacing={2.5} sx={{ mx: { xs: 2, md: 0 } }}>
        {!isPending && (
          <LoadingButton
            fullWidth
            size='large'
            color='inherit'
            variant='outlined'
            loading={isCloseLoading}
            onClick={onCloseTicket}
            disabled={!isOpen}
            sx={{ maxWidth: 150, borderColor: 'grey.300', typography: 'body1', fontWeight: 500, color: 'grey.300' }}
          >
            {isOpen ? t('Close ticket') : t('Ticket closed')}
          </LoadingButton>
        )}

        <Card sx={{ px: { xs: 2, md: 5 }, py: 2, pr: { xs: 2, md: 20 } }}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={'space-between'}
            alignItems={'flex-start'}
            spacing={{ xs: 2, md: 0 }}
          >
            <Stack>
              <Stack direction={'row'} sx={{ mt: 1 }}>
                <Typography sx={{ width: 130 }} variant={'body3'} color={'grey.300'}>
                  {t('Ticket Number')}
                </Typography>
                <Typography
                  variant={'body3'}
                  color={'grey.100'}
                  sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                  onClick={() => navigate(navigationUrl.admin_edit_user(details.userId))}
                >
                  # {details?.id}
                </Typography>
              </Stack>
              <Stack>
                {details.fullName && (
                  <Stack direction={'row'} sx={{ mt: 1 }}>
                    <Typography sx={{ width: 130 }} variant={'body3'} color={'grey.300'}>
                      {t('User')}
                    </Typography>
                    <Typography
                      variant={'body3'}
                      color={'grey.100'}
                      sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                      onClick={() => navigate(navigationUrl.admin_edit_user(details.userId))}
                    >
                      {details.fullName}
                    </Typography>
                  </Stack>
                )}
                {details.userId && (
                  <Stack direction={'row'} sx={{ mt: 1 }}>
                    <Typography sx={{ width: 130 }} variant={'body3'} color={'grey.300'}>
                      {t('User ID')}
                    </Typography>
                    <Typography
                      variant={'body3'}
                      color={'grey.100'}
                      sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                      onClick={() => navigate(navigationUrl.admin_edit_user(details.userId))}
                    >
                      {details.userId}
                    </Typography>
                  </Stack>
                )}
                <Stack direction={'row'} sx={{ mt: 1 }}>
                  <Typography sx={{ width: 130 }} variant={'body3'} color={'grey.300'}>
                    {t('Ticket Title')}
                  </Typography>

                  <Typography variant='body4'>{t(prettyUppercases(details.subject))}</Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <Stack>
                <Stack direction={'row'}>
                  <Typography sx={{ width: 130 }} variant={'body3'} color={'grey.300'}>
                    {t('Creation Date')}
                  </Typography>
                  <Typography variant={'body3'} color={'grey.100'}>
                    {moment(details?.registerDate).locale(language).format('YYYY MMM D - HH:mm')}
                  </Typography>
                </Stack>
                <Stack direction={'row'} sx={{ mt: 1 }}>
                  <Typography sx={{ width: 130 }} variant={'body3'} color={'grey.300'}>
                    {t('Updated Date')}
                  </Typography>
                  <Typography variant={'body3'} color={'grey.100'}>
                    {moment(details?.lastActivity).locale(language).format('YYYY MMM D - HH:mm')}
                  </Typography>
                </Stack>
                {/* {details.fullName && (
                  <Stack direction={'row'} sx={{ mt: 1 }}>
                    <Typography sx={{ width: 130 }} variant={'body3'} color={'grey.300'}>
                      {t('User')}
                    </Typography>
                    <Typography
                      variant={'body3'}
                      color={'grey.100'}
                      sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                      onClick={() => navigate(navigationUrl.admin_edit_user(details.userId))}
                    >
                      {details.fullName}
                    </Typography>
                  </Stack>
                )} */}
                <Stack direction={'row'} sx={{ mt: 1 }}>
                  <Typography sx={{ width: 130 }} variant={'body3'} color={'grey.300'}>
                    {t('Status')}
                  </Typography>
                  <Box
                    sx={{
                      maxWidth: 130,
                      borderRadius: 0.8,
                      textAlign: 'center',
                      py: 0.3,
                      px: 2,
                      ...(details.status === 'OPEN'
                        ? { bgcolor: 'rgba(48, 224, 161, 0.2)' }
                        : details.status === 'CLOSE_BY_ADMIN' || details.status === 'CLOSE'
                        ? { bgcolor: 'rgba(12, 2, 5, 0.521)' }
                        : details.status === 'PENDING'
                        ? { bgcolor: 'rgba(242, 127, 22, 0.2)' }
                        : details.status === 'DELETE'
                        ? { bgcolor: 'rgba(228, 7, 36, 0.123)' }
                        : ''),
                    }}
                  >
                    <Typography
                      variant='body4'
                      sx={{
                        lineHeight: 1,
                        py: 0.4,
                        ...(details.status === 'OPEN'
                          ? { color: '#30E0A1' }
                          : details.status === 'CLOSE_BY_ADMIN' || details.status === 'CLOSE'
                          ? { color: 'white' }
                          : details.status === 'PENDING'
                          ? { color: 'warning.main' }
                          : details.status === 'DELETE'
                          ? { color: 'rgb(255, 73, 73)' }
                          : ''),
                      }}
                    >
                      {t(prettyUppercases(details.status))}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Card>

        {details.transaction && (
          <Card sx={{ px: { xs: 2, md: 5 }, py: 2, pr: { xs: 2, md: 20 } }}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              justifyContent={'space-between'}
              alignItems={'flex-start'}
              spacing={{ xs: 2, md: 0 }}
            >
              {details.transaction?.symbol && (
                <Stack direction={'row'} spacing={1} alignItems='center'>
                  <SymbolIcon symbol={details.transaction?.commissionAsset} />
                  <Typography variant={'body3'} color={'grey.100'}>
                    {details.transaction.symbol}
                  </Typography>
                </Stack>
              )}
              {details.transaction?.fiatType && (
                <Stack direction={'row'} spacing={1} alignItems='center'>
                  {details.transaction.fiatType === 'USD' ? <Image src={USD} />
                   : details.transaction.fiatType === 'IRR' ? <Image src={IRR}/> :
                      <SymbolIcon
                      symbol={
                        details.transaction.fiatType !== 'USDT' ?  details.transaction.fiatType.replace('USDT', '') :  details.transaction.fiatType?.toUpperCase()
                    }
                      width={18}
                      height={18}
                      />

                   }
                  {/*  */}
                  {/*{details.transaction.fiatType === 'IRR' && <Image src={IRR} />}*/}

                  <Typography variant={'body3'} color={'grey.100'}>
                    {details.transaction.fiatType}
                  </Typography>
                </Stack>
              )}
              {details.transaction?.transactionId && (
                <Stack direction={'row'} spacing={2} alignItems='center'>
                  <Typography variant={'body3'} color={'grey.300'}>
                    {t('Transaction ID')}
                  </Typography>
                  <Typography variant={'body3'} color={'grey.100'}>
                    #{details.transaction.transactionId}
                  </Typography>
                </Stack>
              )}
              {details.transaction?.amount && (
                <Stack direction={'row'} spacing={2} alignItems='center'>
                  <Typography variant={'body3'} color={'grey.300'}>
                    {t('Amount')}
                  </Typography>
                  <Typography variant={'body3'} color={'grey.100'}>
                    {details.transaction.amount}
                  </Typography>
                </Stack>
              )}
              {details.transaction?.futuresSide && (
                <Stack direction={'row'} spacing={2} alignItems='center'>
                  <Typography variant={'body3'} color={'grey.300'}>
                    {t('Futures Side')}
                  </Typography>
                  <Typography variant={'body3'} color={'grey.100'}>
                    {t(prettyUppercases(details.transaction.futuresSide))}
                  </Typography>
                </Stack>
              )}
              {details.transaction?.side && (
                <Stack direction={'row'} spacing={2} alignItems='center'>
                  <Typography variant={'body3'} color={'grey.300'}>
                    {t('Side')}
                  </Typography>
                  <Typography variant={'body3'} color={'grey.100'}>
                    {t(prettyUppercases(details.transaction.side))}
                  </Typography>
                </Stack>
              )}
              {details.transaction?.type && (
                <Stack direction={'row'} spacing={2} alignItems='center'>
                  <Typography variant={'body3'} color={'grey.300'}>
                    {t('Type')}
                  </Typography>
                  <Typography variant={'body3'} color={'grey.100'}>
                    {t(prettyUppercases(details.transaction.type))}
                  </Typography>
                </Stack>
              )}
              {details.transaction?.createDate && (
                <Stack direction={'row'} spacing={2} alignItems='center'>
                  <Typography variant={'body3'} color={'grey.300'}>
                    {t('Date')}
                  </Typography>
                  <Typography variant={'body3'} color={'grey.100'}>
                    {moment(details.transaction.createDate).locale(language).format('YYYY MMM D - HH:mm')}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Card>
        )}

        <ViewTicketMessages messages={messages} isAdmin={isAdmin} userId={details.userId}/>

        {(isOpen || isPending) && <ViewTicketForm onReload={getList} ticketId={ticketId} isAdmin={isAdmin} />}
      </Stack>

      <TicketRateModal open={openTicketRateModal} ticketId={ticketId} onClose={() => setOpenTicketRateModal(false)} />
    </>
  );
}
