import React, { useEffect, useState } from 'react';
import { Stack, Table, TableBody, TableContainer, Box, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

import WalletToolbar from './cryptoWalletComponents/walletToolbar';
import Scrollbar from '../../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../../components/table';
import useTable from '../../../../../hooks/useTable';
import StatusModal from '../../../../../components/StatusModal';
import WalletTableRow from './cryptoWalletComponents/walletTableRow';
import CreateTransactionModal from './cryptoWalletComponents/createTransactionModal';
import Api from '../../../../../http/adminHttp/serviceApiAdmin';
import { exportExcel } from '../../../../../utils/excel';
import { useSelector } from 'react-redux';
import { exportPDF } from '../../../../../utils/pdf';

// ===================================================================

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'amount', label: 'Amount', align: 'center' },
  { id: 'blocked', label: 'Blocked', align: 'center' },
  { id: 'available', label: 'Available', align: 'center' },
  { id: 'btcValue', label: 'BTC Value', align: 'center' },
  { id: 'usdtValue', label: 'USDT Value', align: 'center' },
  { id: 'usdValue', label: 'USD Value', align: 'center' },
  { id: 'createTransaction', label: 'Create a transaction', align: 'center' },
];

// ===================================================================

const CryptoWallet = ({ userId }) => {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);
  const [showModal, setShowModal] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState('');
  const [statusModal, setStatusModal] = useState({
    show: false,
    status: '',
  });

  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  const [tableData, setTableData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);

  // ----------------------------------------------
  const getData = async () => {
    try {
      const filters = { userId };

      const response = await Api.getUserWalletInformation(filters);
      const { currencies } = await Api.getAllCurrencies();

      if (response.status === 'SUCCESS') {
        const list = currencies
          .map((currency) => {
            const found = response.userWalletInfoList.find((item) => item.symbol === currency.symbol);
            if (found) return found;

            return {
              symbol: currency.symbol,
              name: currency.name,
              amount: 0,
              blocked: 0,
              available: 0,
              btcValue: 0,
              usdtValue: 0,
              usdValue: 0,
            };
          })
          .sort((a, b) => b.usdtValue - a.usdtValue);

        setTableData(list);
        setFilteredData(list);
      }
    } catch (e) {
      setTableData([]);
    }
  };

  // ----------------------------------------------
  useEffect(() => {
    if (userId) getData();
  }, [page, rowsPerPage, userId]);

  // ----------------------------------------------
  const onFilter = ({ nameFilter }) => {
    setFilteredData(tableData.filter((d) => d.name?.toString()?.toLowerCase().indexOf(nameFilter?.toString()?.toLowerCase()) !== -1 || d.symbol?.toString()?.toLowerCase().indexOf(nameFilter?.toString()?.toLowerCase()) !== -1));
  };

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);
  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    // generate excel and download
    const config = {
      language,
      fileName: `${t('User')} - ${t('Crypto Wallet')}`,
      heads: [
        '#',
        t('Symbol'),
        t('Name'),
        t('Amount'),
        t('Blocked'),
        t('Available'),
        t('BTC Value'),
        t('USDT Value'),
        t('USD Value'),
      ],
      columnsWidth: [5, 8, 29, 15, 15, 15, 13, 13, 15],
      list: filteredData.map((item) => [
        item.symbol,
        item.name,
        item.amount,
        item.blocked,
        item.available,
        item.btcValue,
        item.usdtValue,
        item.usdValue,
      ]),
    };

    type === 'PDF' ? exportPDF(config) : exportExcel(config);

    setIsExportLoading(false);
  };

  return (
    <>
      <Stack>
        {/* <PageTitle title='Crypto Wallet' /> */}

        <WalletToolbar onFilter={onFilter} isExportLoading={isExportLoading} onExport={onExport} />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative', mt: 2 }}>
            <Table size='small'>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={filteredData?.length || 0}
                onSort={onSort}
              />
              <TableBody>
                {filteredData === null ? (
                  <TableLoading isLoading />
                ) : (
                  <>
                    {filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                      <WalletTableRow
                        key={index}
                        row={row}
                        index={index}
                        setShowModal={setShowModal}
                        setSelectedSymbol={setSelectedSymbol}
                      />
                    ))}
                    <TableNoData isNotFound={!filteredData?.length} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component='div'
            count={filteredData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
          />
        </Box>
      </Stack>

      <CreateTransactionModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onReload={getData}
        setStatusModal={setStatusModal}
        userId={userId}
        selectedSymbol={selectedSymbol}
      />

      <StatusModal
        open={statusModal.show}
        onClose={() => setStatusModal({ show: false, status: '' })}
        type={statusModal.status}
        title={statusModal.status === 'success' ? 'Successfully' : 'Error'}
        subtitle={
          statusModal.status === 'success' ? 'Transfer completed successfully' : 'The transfer encountered an error'
        }
        buttonLabel='Done'
        onAction={() => setStatusModal({ show: false, status: '' })}
      />
    </>
  );
};

export default CryptoWallet;
