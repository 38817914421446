import React from 'react';
import { Box, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { prettyUppercases } from '../../../../../../utils';
import SymbolIcon from '../../../../../../components/SymbolIcon';
import USD from '../../../../../../assets/logo/dolar.svg';
import IRR from '../../../../../../assets/logo/IRR.svg';
import Image from '../../../../../../components/Image';
import Iconify from '../../../../../../components/Iconify';
import USDLogo from '../../../../../../assets/logo/USD2.svg'
import PSLogo from '../../../../../../assets/logo/paysef.svg'
import PMLogo from '../../../../../../assets/logo/PM.svg'

const OrdersTableRow = ({ row, type, index, onShowModalDetail }) => {
  const language = useSelector((store) => store.language);
  const { t } = useTranslation();

  const symbol = row?.symbol !== 'USDT' ? row.symbol?.replace('USDT', '') : row?.symbol || '';




  return (
    <TableRow hover sx={{ '& td': { py: 1, typography: 'body3' } }}>
      <TableCell align='center' sx={{ width: 100 }}>
        {index + 1}
      </TableCell>

      {type === 'otc' && (
        <TableCell>
          <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ width: 80 }}>
            <Image src={row.fiat === 'USD' ? USD : IRR} disabledEffect />
            <Typography variant='body3' noWrap sx={{ color: 'grey.100' }}>
              {row.fiat}
            </Typography>
          </Stack>
        </TableCell>
      )}


      {
        symbol &&
        <TableCell>
          <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ width: 80 }}>

            {
              symbol === 'USD' ?
              <Image src={USDLogo} sx={{ width: 20 }} />
              :
              <SymbolIcon symbol={symbol} />
            }
            <Typography variant='body3' noWrap sx={{ color: 'grey.100' }}>
              {symbol}
            </Typography>
          </Stack>
        </TableCell>
      }


      {
        type === 'futures' &&
        <TableCell align='center'>
          <Typography variant='body3'>{row?.leverage}</Typography>
        </TableCell>
      }

      {
        type === 'futures' &&
        <TableCell align='center'>
          <Typography variant='body3'>{row?.type}</Typography>
        </TableCell>
      }

      {
        type === 'futures' &&
        <TableCell align='center'>
          <Typography variant='body3'>{row?.margin}</Typography>
        </TableCell>
      }


      {type === 'otc' || type === 'paysafe' ?  (
        <TableCell align='center'>
          <Typography variant='body3'>{row?.side?.toString()?.toLowerCase() === 'sell'  ? 'WALLET' : row.depositType}</Typography>
        </TableCell>
      ) : null}

      { type === 'paysafe' ||type === 'perfectmoney'?  (
        <TableCell align='center'>
          <Typography variant='body3'>{row.emailAddress}</Typography>
        </TableCell>
      ) : null}

      { type === 'paysafe'||type === 'perfectmoney' ?  (
        <TableCell align='center'>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'start'} spacing={1} sx={{ width: 'auto' }}>
            {
              row?.fiatType === 'USD' ?

                  type === 'paysafe' ?
                  <Image src={PSLogo} sx={{ width: 20 }} />
                  :
                  type === 'perfectmoney' ?
                    <Image src={PMLogo} sx={{ width: 20 }} />
                    :

                <Image src={USDLogo} sx={{ width: 20 }} />
                :
                <SymbolIcon symbol={row?.fiatType} />
            }
            <Typography variant='body3'>{row?.fiatAmount}</Typography>
            <Typography variant='body3' noWrap sx={{ color: 'grey.100' }}>
              {row?.fiatType}
            </Typography>
          </Stack>

        </TableCell>
      ) : null}

      { type === 'paysafe'||type === 'perfectmoney' ?  (
        <TableCell align='center'>
          <Typography variant='body3'>{row?.feeAmount?.toLocaleString('en-US')}</Typography>
        </TableCell>
      ) : null}

      {
        type === 'futures' || type === 'paysafe'||type === 'perfectmoney'? null

          : type === 'otc' ?
          <TableCell align='center'>{row?.fiatAmount?.toLocaleString('en-US')}</TableCell>

          :
          <TableCell align='center'>{row.amount?.toLocaleString('en-US')}</TableCell>
      }



      {type === 'p2p' && (
        <TableCell align='center'>
          <Typography variant='body3'>{row.minLimit?.toLocaleString('en-US')}</Typography>
        </TableCell>
      )}

      {
        row.side &&
          <TableCell align='center'>
          <Typography
            variant='body3'
            color={(row.side === 'BUY' && 'positive.main') || (row.side === 'SELL' && 'negative.main') || 'grey.100'}
          >
            {row.side}
          </Typography>
        </TableCell>
      }

      {(type === 'p2p' || type === 'trade') && (
        <TableCell align='center'>
          <Typography variant='body3'>{row.price?.toLocaleString('en-US')}</Typography>
        </TableCell>
      )}

      {type === 'trade' && (
        <TableCell align='center'>
          <Typography variant='body3'>{row.stopPrice?.toLocaleString('en-US')}</Typography>
        </TableCell>
      )}

      {type === 'trade' && (
        <TableCell align='center'>
          <Typography variant='body3'>{row.commission?.toLocaleString('en-US')}</Typography>
        </TableCell>
      )}

      {
        row.createdDate ?   <TableCell align='center'>{moment(row.createdDate ? row.createdDate : row.createDate).locale(language).format('D MMM YYYY - HH:mm')}</TableCell>
          : row.createdDate ?   <TableCell align='center'>{moment(row.createdDate ? row.createdDate : row.createDate).locale(language).format('D MMM YYYY - HH:mm')}</TableCell>
            : row.createDate ?   <TableCell align='center'>{moment(row.createdDate ? row.createdDate : row.createDate).locale(language).format('D MMM YYYY - HH:mm')}</TableCell>
            : null
      }


      {type === 'p2p' && (
        <TableCell sx={{ width: 170 }} align='center'>
          <Box
            sx={{
              width: 160,
              borderRadius: '22px',
              textAlign: 'center',
              m: 'auto',
              p: 1,
              bgcolor:
                ((row.state === 'COMPLETED' || row.state === 'PARTIALLY_COMPLETED') && 'rgba(48, 224, 161, 0.1)') ||
                ((row.state === 'CANCELED' || row.state === 'FAILED') && 'rgba(224, 48, 48, 0.1)') ||
                'rgba(250, 174, 34, 0.1)',
            }}
          >
            <Typography
              variant='body3'
              sx={{
                color:
                  ((row.state === 'COMPLETED' || row.state === 'PARTIALLY_COMPLETED') && '#30E0A1') ||
                  ((row.state === 'CANCELED' || row.state === 'FAILED') && 'negative.main') ||
                  'rgb(218, 146, 13)',
                lineHeight: 1,
              }}
            >
              {t(prettyUppercases(row.state))}
            </Typography>
          </Box>
        </TableCell>
      )}

      {type === 'trade' && (
        <TableCell sx={{ width: 120 }} align='center'>
          <Box
            sx={{
              width: 100,
              borderRadius: '22px',
              textAlign: 'center',
              m: 'auto',
              p: 1,
              bgcolor:
                (row.state === 'FILLED' && 'rgba(48, 224, 161, 0.1)') ||
                (row.state === 'CANCELED' && 'rgba(224, 48, 48, 0.1)') ||
                'rgba(250, 174, 34, 0.1)',
            }}
          >
            <Typography
              variant='body3'
              sx={{
                color:
                  (row.state === 'FILLED' && '#30E0A1') ||
                  (row.state === 'CANCELED' && 'negative.main') ||
                  'rgb(218, 146, 13)',
                lineHeight: 1,
              }}
            >
              {t(prettyUppercases(row.state))}
            </Typography>
          </Box>
        </TableCell>
      )}

      {type === 'otc' || type === 'futures' ?(
        <TableCell sx={{ width: 120 }} align='center'>
          <Box
            sx={{
              width: 100,
              borderRadius: '22px',
              textAlign: 'center',
              m: 'auto',
              p: 1,
              bgcolor:
                (row.status === 'COMPLETED' && 'rgba(48, 224, 161, 0.1)') ||
                (row.status === 'FAILED' && 'rgba(224, 48, 48, 0.1)') ||
                'rgba(250, 174, 34, 0.1)',
            }}
          >
            <Typography
              variant='body3'
              sx={{
                color:
                  (row.status === 'COMPLETED' && '#30E0A1') ||
                  (row.status === 'FAILED' && 'negative.main') ||
                  'rgb(218, 146, 13)',
                lineHeight: 1,
              }}
            >
              {t(prettyUppercases(row.status))}
            </Typography>
          </Box>
        </TableCell>
      ) : null}
      {
        type === 'futures' &&
        <TableCell align='center'>
          <Typography variant='body3'>{row?.transactionId}</Typography>
        </TableCell>
      }

      {(type === 'otc' || type === 'trade') && (
        <TableCell align='center'>
          <IconButton onClick={onShowModalDetail}>
            <Iconify icon='ph:link-duotone' />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};

export default OrdersTableRow;
