import { api } from './adminHttp';

export const setTokenAdmin = (accessToken) => {
  if (accessToken) {
    window.localStorage.setItem('tokenAdmin', accessToken);
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    removeTokenAdmin();
  }
};

export const getTokenAdmin = () => {
  const accessToken = window.localStorage.getItem('tokenAdmin');
  if (accessToken) {
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    return accessToken;
  }

  return null;
};

export const removeTokenAdmin = () => {
  window.localStorage.removeItem('tokenAdmin');
  delete api.defaults.headers.common.Authorization;
};
