export const screenSize = {
    small: "(max-width: 575px)",
    medium: "(min-width: 576px) and (max-width: 991px)",
    large: "(min-width: 992px)",

    xs: "(max-width: 575px)", // screen < 576px
    sm: "(min-width: 576px) and (max-width: 767px)", // screen ≥ 576px
    md: "(min-width: 768px) and (max-width: 991px)", // screen ≥ 768px
    lg: "(min-width: 992px) and (max-width: 1199px)", // screen ≥ 992px
    xl: "(min-width: 1200px)", // screen ≥ 1200px
    largerThanSm: "(min-width: 768px)",
    smallerThanMd: "(max-width: 767px)",
    largerThan_1399: "(min-width: 1400px)",
    between_1399_768: "(min-width: 768px) and (max-width: 1399px)"
}