import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';

import ModalContainer from '../ModalContainer';
import RowSymbol from './rowSymbol';
import Iconify from '../Iconify';
import Api from '../../http/serviceApi';
import ModalFilter from '../ModalFilter';

const sortList = [
  { type: 'hot', title: 'Hot' },
  { type: '24h_vol', title: '24h Vol' },
  { type: '24h_change_up', title: '24h Change' },
  { type: '24h_change_down', title: '24h Change' },
];

const itemsPerPage = 10;

export default function SelectCryptoModal({ open, onClose, onSelect = () => {} }) {
  const [filtered, setFiltered] = useState([]);
  const [sortSelected, setSortSelected] = useState('hot');

  // fetching data from server
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchOtcSymbolsInfo = async () => {
    setLoading(true);
    try {
      const data = await Api.getOtcSymbolsInfo();
      if (data.status === 'SUCCESS') {
        setDataSource(data.otcSymbolInfos);
        setFiltered(data.otcSymbolInfos.sort((a, b) => Number(b.hot) - Number(a.hot)));
      }
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    fetchOtcSymbolsInfo();
  }, []);

  const onSort = (type) => {
    if (dataSource.length === 0) return;

    setSortSelected(type);

    if (type === 'hot') {
      setFiltered([...dataSource].sort((a, b) => Number(b.hot) - Number(a.hot)));
    } else if (type === '24h_vol') {
      setFiltered([...dataSource].sort((a, b) => b.volumeUsdt - a.volumeUsdt));
    } else if (type === '24h_change_up') {
      setFiltered([...dataSource].sort((a, b) => b.changeUsdt - a.changeUsdt));
    } else if (type === '24h_change_down') {
      setFiltered([...dataSource].sort((a, b) => a.changeUsdt - b.changeUsdt));
    }

    setCountPerPage(itemsPerPage);
  };

  // lazy load list
  const [hasMore, setHasMore] = useState(true);
  const [countPerPage, setCountPerPage] = useState(itemsPerPage);
  const loadMore = () => {
    if (countPerPage >= filtered.length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setCountPerPage((prev) => prev + itemsPerPage);
      }, 300);
    }
  };

  const onSearch = (newList) => {
    setFiltered(newList);
    setCountPerPage(itemsPerPage);
    if (itemsPerPage < newList.length) {
      setHasMore(true);
    }
  };

  useEffect(() => {
    if (!open) {
      setFiltered(dataSource);
    }
  }, [open]);

  return (
    <ModalContainer open={open} onClose={onClose} title={'Select Crypto'} width={515}>
      <ModalFilter dataSource={dataSource} setFiltered={onSearch} />

      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ mb: 2.3 }}>
        <Typography variant='body4'>{t('Sort by')}</Typography>

        <Stack direction={'row'} spacing={1}>
          {sortList.map((item, index) => (
            <Button
              key={index}
              onClick={() => onSort(item.type)}
              color={item.type === sortSelected ? 'secondary' : 'inherit'}
              size='small'
              sx={{
                typography: 'body4_secondary',
                px: 1,
                ...(item.type === sortSelected && { backgroundColor: 'rgba(242, 127, 22, 0.1)' }),
              }}
              endIcon={
                item.type === '24h_change_up' ? (
                  <Iconify icon='ph:arrow-up-fill' sx={{ width: 12, height: 12 }} />
                ) : item.type === '24h_change_down' ? (
                  <Iconify icon='ph:arrow-down-fill' sx={{ width: 12, height: 12 }} />
                ) : null
              }
            >
              {item.title}
            </Button>
          ))}
        </Stack>
      </Stack>

      {loading ? (
        <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: 365 }}>
          <CircularProgress size={30} color='success' />
        </Stack>
      ) : (
        <Box
          sx={{
            height: 365,
            overflow: 'auto',
            '&::-webkit-scrollbar': { width: 18 },
            '&::-webkit-scrollbar-track': { backgroundColor: 'transparent' },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#53555f',
              borderRadius: '18px',
              border: '6px solid transparent',
              backgroundClip: 'content-box',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#6c6e75',
            },
          }}
        >
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={
              <Stack key='loader' alignItems={'center'} justifyContent={'center'} sx={{ my: 2 }}>
                <CircularProgress size={20} color='success' />
              </Stack>
            }
            useWindow={false}
          >
            {filtered.slice(0, countPerPage).map((item) => (
              <RowSymbol
                key={item.symbol}
                item={item}
                onSelect={() => {
                  onSelect(item.symbol);
                  onClose();
                }}
              />
            ))}
          </InfiniteScroll>
        </Box>
      )}
    </ModalContainer>
  );
}
