import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

export default function useDedicatedForm(iranianPaymentStatus) {
  const validators = Yup.object().shape({
    iranianPayment: Yup.boolean(),
  });

  const defaultValues = {
    iranianPayment: iranianPaymentStatus || false,
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (iranianPaymentStatus) {
      reset(defaultValues);
    }
    if (!iranianPaymentStatus) {
      reset(defaultValues);
    }
  }, [iranianPaymentStatus]);

  return {
    methods,
    handleSubmit,
    isSubmitting,
  };
}
