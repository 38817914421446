import { useTranslation } from 'react-i18next';
import { m } from 'framer-motion';
// @mui
import { Button, Container, Grid, Stack, Typography, useTheme } from '@mui/material';
// components
import MotionViewport from '../../../components/animate/MotionViewport';
import { varFade } from '../../../components/animate/variants/fade';
import { RootSubStyle, PortfolioShadow, PortfolioWrapper, PayoutCurveShape } from '../style';
import Image from '../../../components/Image';

import deviceImage from '../../../assets/landing/new-device.png';
import deviceImageLight from '../../../assets/landing/new-device-light.png';
import googlePlayLogo from '../../../assets/landing/google-play-logo.svg';
import appStoreLogo from '../../../assets/landing/app-store-logo.svg';
import curveShape from '../../../assets/landing/curveShape.svg';

// ----------------------------------------------------------------------

export default function Portfolio() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <RootSubStyle sx={{ position: 'relative', pt: 0, mt: -12 }}>
      <PayoutCurveShape src={curveShape} position='right' sx={{ display: { xs: 'none', md: 'block' } }} />

      <Container component={MotionViewport}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ pt: 16, textAlign: { xs: 'center', md: 'left' } }}>
            <m.div variants={varFade().inRight}>
              <Typography
                variant='h3'
                sx={{ fontWeight: 400, width: { xs: '100%', md: 500 }, display: 'inline-block' }}
              >
                {t('Build your crypto portfolio anywhere.')}
              </Typography>
              <Typography
                variant='body2'
                color={{ xs: 'grey.0', md: 'grey.300' }}
                sx={{ mt: 5, width: { xs: '100%', md: 420 } }}
              >
                {t('A powerful cryptocurrency exchange in your pocket. Buy, sell and trade crypto on the go.')}
              </Typography>

              <Image
                src={theme.palette.mode === 'dark' ? deviceImage : deviceImageLight}
                sx={{ m: 'auto', mt: 5, width: '90%', display: { xs: 'block', md: 'none' } }}
              />

              <Typography variant='subtitle2' color={{ xs: 'grey.0', md: 'grey.300' }} sx={{ mt: 2.5 }}>
                {t('Get the Cryptizi Wallet Mobile App Now!')}
              </Typography>

              <Stack direction={{ xs: 'column', md: 'row' }} sx={{ mt: { xs: 2, md: 8 } }} spacing={2}>
                <AppButton logo={googlePlayLogo} title='GET IT ON' subtitle='Google Play' />
                <AppButton logo={appStoreLogo} title='Download on the' subtitle='App Store' />
              </Stack>
            </m.div>
          </Grid>

          <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
            <m.div variants={varFade().inUp}>
              <PortfolioWrapper alignItems={'center'} justifyContent={'center'}>
                <PortfolioShadow />
                <Image src={theme.palette.mode === 'dark' ? deviceImage : deviceImageLight} sx={{ width: 540 }} />
              </PortfolioWrapper>
            </m.div>
          </Grid>
        </Grid>
      </Container>
    </RootSubStyle>
  );
}

const AppButton = ({ logo, title, subtitle }) => {
  const { t } = useTranslation();

  return (
    <Button
      variant='outlined'
      color='inherit'
      size='large'
      sx={{ width: { xs: '100%', md: 215 }, height: 70, px: 4, borderColor: { xs: 'grey.0', md: 'grey.400' } }}
    >
      <Stack
        direction='row'
        justifyContent={{ xs: 'center', md: 'space-between' }}
        alignItems={'center'}
        sx={{ width: '100%' }}
        spacing={{ xs: 3, md: 0 }}
      >
        <Image src={logo} sx={{ width: 32, height: 32 }} />
        <Stack>
          <Typography variant='body4' color={{ xs: 'grey.0', md: 'grey.300' }}>
            {t(title)}
          </Typography>
          <Typography variant='subtitle2' color={{ xs: 'grey.0', md: 'grey.300' }}>
            {t(subtitle)}
          </Typography>
        </Stack>
      </Stack>
    </Button>
  );
};
