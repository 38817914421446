import React, { useEffect } from 'react';
import { Row, Col, Image, Avatar, Divider, Switch, Button as AntButton } from 'antd';
import classes from './accountSettingBasicInfo.module.scss';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { InputCustom } from '../../../../components/InputCustom/inputCustom';
import UserApi from '../../../../http/serviceApi';
import AdminApi from '../../../../http/adminHttp/serviceApiAdmin';
import Media from 'react-media';
import { screenSize } from '../../../../common/screenSize';
import profileAvatar from '../../../../assets/user.svg';
import RoundedImage from 'react-rounded-image';
import { convertTimeStampToDate } from '../../../../utils/dateConverter';
import { useSnackbar } from 'notistack';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { LoadingButton } from '@mui/lab';
import { Button, Card, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AccountSettingBasicInfo = ({ isAdmin = true }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [defaultSwitchValues, setDefaultSwitchValues] = useState({});
  const [switchValues, setSwitchValues] = useState({
    emailAfterLogin: false,
    emailAfterTransaction: false,
    notificationAfterTransaction: false,
    smsAfterLogin: false,
    smsAfterTransaction: false,
  });
  const userInfo = useSelector((store) => store.userInfo);
  const [profilePic, setProfilePic] = useState('');
  const [selectPic, setSelectPic] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);

  const colPropsForm = { xs: 24, sm: 24, md: 13, lg: 8, xl: 8 };
  const colPropsRight = { xs: 0, sm: 0, md: 10, lg: 8, xl: 8 };
  const colPropsButton = { xs: 24, sm: 24, md: 6, lg: 5, xl: 4 };

  useEffect(() => {
    fetchData();
  }, []);

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const onFileInputChange = (e) => {
    console.log('file: ', e.target.files[0]);
    let file = e.target.files[0];
    getBase64(file, (result) => {
      let spl = result.split('/')[1];
      let format = spl.split(';')[0];
      let resultRemovedheader = result.replace(`data:image/${format};base64,`, '');
      setSelectPic(resultRemovedheader);
      console.log('selectPic: ', resultRemovedheader);
    });
  };

  const fetchData = async () => {
    getUserProfilePicture();
    const response = await getDefaultUserSetting();
    if (response)
      setDefaultSwitchValues({
        ...response,
      });

    if (userInfo.emailAfterLogin === undefined) {
      if (response) {
        setSwitchValues({
          ...response,
        });
      }
    } else {
      setSwitchValues({
        emailAfterLogin: userInfo.emailAfterLogin,
        emailAfterTransaction: userInfo.emailAfterTransaction,
        notificationAfterTransaction: userInfo.notificationAfterTransaction,
        smsAfterLogin: userInfo.smsAfterLogin,
        smsAfterTransaction: userInfo.smsAfterTransaction,
      });
    }
    setFirstName(userInfo.firstName);
    setLastName(userInfo.lastName);
  };

  const onFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const onLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const onChangeSmsAfterTransactions = (checked) => {
    setSwitchValues({
      ...switchValues,
      smsAfterTransaction: checked,
    });
  };
  const onChangeEmailAfterTransactions = (checked) => {
    setSwitchValues({
      ...switchValues,
      emailAfterTransaction: checked,
    });
  };
  const onChangeNotificationsAfterTransactions = (checked) => {
    setSwitchValues({
      ...switchValues,
      notificationAfterTransaction: checked,
    });
  };

  const onChangeSmsAfterLogin = (checked) => {
    setSwitchValues({
      ...switchValues,
      smsAfterLogin: checked,
    });
  };

  const onChangeEmailAfterLogin = (checked) => {
    setSwitchValues({
      ...switchValues,
      emailAfterLogin: checked,
    });
  };

  const getDefaultUserSetting = async () => {
    let response;
    if (isAdmin) {
      response = await AdminApi.getDefaultUserSetting();
    } else {
      response = await UserApi.getDefaultUserSetting();
    }

    return response;
  };

  const getUserProfilePicture = async () => {
    let response;
    if (isAdmin) {
      response = await AdminApi.getUserProfilePicture();
    } else {
      response = await UserApi.getUserProfilePicture();
    }

    if (response) {
      setProfilePic(response);
    }
    console.log('getUserProfilePicture: ', response);
  };

  const saveUserSetting = async (data, saveMessage) => {
    setSaveLoading(true);

    try {
      let response;
      if (isAdmin) {
        response = await AdminApi.saveUserSetting(data);
      } else {
        response = await UserApi.saveUserSetting(data);
      }

      if (response?.status === 'SUCCESS') {
        enqueueSnackbar(saveMessage, { variant: 'success' });
        getUserProfilePicture();
      }
      console.log('saveResp: ', response);
    } catch (e) {}

    setSaveLoading(false);
  };

  const onSaveClick = () => {
    const data = {
      ...switchValues,
      image: selectPic,
      firstName: firstName,
      lastName: lastName,
    };
    console.log('dataForSave: ', data);
    saveUserSetting(data, t('Setting saved successfully.'));
  };

  const onDefaultClick = async () => {
    const response = await getDefaultUserSetting();
    if (response) {
      setSwitchValues({
        ...response,
      });
      const data = {
        ...response,
        image: '',
        firstName: firstName,
        lastName: lastName,
      };
      saveUserSetting(data, t('Default setting restored successfully.'));
    }
  };

  return (
    <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 } }}>
      <Row justify='space-between'>
        <Col {...colPropsForm}>
          <Row>
            <div className={classes.formContainer}>
              <Media
                query={screenSize.smallerThanMd}
                render={() => {
                  return (
                    <Row justify='center' style={{ marginBottom: '30px' }}>
                      <Col>
                        <div className={classes.profilePicDivMobile}>
                          {/* <Avatar size="large"
                                                        src={
                                                            <Image 
                                                                src={profilePic && profilePic !== "" ? profilePic : profileAvatar}
                                                            />
                                                        }
                                                    /> */}
                          <RoundedImage
                            image={
                              !selectPic
                                ? profilePic && profilePic !== ''
                                  ? `data:image/jpg;base64,${profilePic}`
                                  : profileAvatar
                                : `data:image/jpg;base64,${selectPic}`
                            }
                            roundedColor='#5D6588'
                            imageWidth='150'
                            imageHeight='150'
                            roundedSize='6'
                            borderRadius='500'
                          />
                          <input
                            type='file'
                            name='Edit'
                            id='selectedFile'
                            onChange={onFileInputChange}
                            style={{ display: 'none' }}
                          />
                          <AntButton
                            type='text'
                            style={{ backgroundColor: 'transparent' }}
                            onClick={() => document.getElementById('selectedFile').click()}
                          >
                            {t('Edit')}
                          </AntButton>
                        </div>
                      </Col>
                    </Row>
                  );
                }}
              />

              <div className={classes.inputsDiv}>
                <InputCustom
                  value={firstName}
                  label={t('First Name')}
                  placeholder={t('First Name')}
                  onChange={onFirstNameChange}
                />
                <InputCustom
                  value={lastName}
                  label={t('Last Name')}
                  placeholder={t('Last Name')}
                  onChange={onLastNameChange}
                />
              </div>

              <div className={classes.switchContainer}>
                <span className={classes.switchContainerTitle}>Trades</span>

                <div className={classes.switchItems}>
                  <Switch checked={switchValues.smsAfterTransaction} onChange={onChangeSmsAfterTransactions} />
                  <span className={classes.switchItemsText}>{t('Receive SMS after transactions')}</span>
                </div>
                <div className={classes.switchItems}>
                  <Switch checked={switchValues.emailAfterTransaction} onChange={onChangeEmailAfterTransactions} />
                  <span className={classes.switchItemsText}>{t('Receive emails after transactions')}</span>
                </div>
                <div className={classes.switchItems}>
                  <Switch
                    checked={switchValues.notificationAfterTransaction}
                    onChange={onChangeNotificationsAfterTransactions}
                  />
                  <span className={classes.switchItemsText}>
                    {t('Receive notifications after transactions (Guide)')}
                  </span>
                </div>
              </div>

              <div className={classes.switchContainer}>
                <span className={classes.switchContainerTitle}>{t('Activities')}</span>

                <div className={classes.switchItems}>
                  <Switch checked={switchValues.smsAfterLogin} onChange={onChangeSmsAfterLogin} />
                  <span className={classes.switchItemsText}>{t('Receive SMS after each login')}</span>
                </div>
                <div className={classes.switchItems}>
                  <Switch checked={switchValues.emailAfterLogin} onChange={onChangeEmailAfterLogin} />
                  <span className={classes.switchItemsText}>{t('Receive emails after each login')}</span>
                </div>
              </div>
            </div>

            {/* <SettingBasicInfoForm profilePic={profilePic} onEditClick={onFileInputChange}/> */}
          </Row>
        </Col>
        <Col {...colPropsRight}>
          <Row justify='center'>
            <Col style={{ width: '100%' }}>
              <div className={classes.profilePicDiv}>
                {/* <Avatar style={{ width: "150px", height: "150px" }}
                                    src={
                                        <Image
                                            style={{ width: "150px", height: "150px" }}
                                            src={profilePic && profilePic !== "" ? profilePic : profileAvatar}
                                        />
                                    }
                                /> */}
                <RoundedImage
                  image={
                    !selectPic
                      ? profilePic && profilePic !== ''
                        ? `data:image/jpg;base64,${profilePic}`
                        : profileAvatar
                      : `data:image/jpg;base64,${selectPic}`
                  }
                  roundedColor='#5D6588'
                  imageWidth='150'
                  imageHeight='150'
                  roundedSize='6'
                  borderRadius='500'
                />

                <input
                  type='file'
                  name='Edit'
                  id='selectedFile'
                  onChange={onFileInputChange}
                  style={{ display: 'none' }}
                />
                <AntButton
                  type='text'
                  style={{ backgroundColor: 'transparent' }}
                  onClick={() => document.getElementById('selectedFile').click()}
                >
                  {t('Edit')}
                </AntButton>
              </div>
            </Col>
            <Col>
              <div className={classes.infoDiv}>
                <span>{`${t('Registration via : ')}${userInfo?.registeredType}`}</span>
                <span>
                  {t('User')} : {userInfo.firstName} {userInfo.lastName}
                </span>
                <span>
                  {t('User ID')} : {userInfo.userId}
                </span>
                <span>
                  {t('Registration Date')} : {convertTimeStampToDate(userInfo.createdDate)}
                </span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify='start' style={{ marginTop: '30px' }}>
        <Divider />
        <Stack direction={'row'} spacing={5}>
          <LoadingButton
            size='large'
            variant='contained'
            sx={{ width: 200, typography: 'body3' }}
            onClick={onSaveClick}
            loading={saveLoading}
          >
            {t('Save Changes')}
          </LoadingButton>

          <Button
            size='large'
            variant='outlined'
            color='inherit'
            sx={{ width: 170, typography: 'body3' }}
            onClick={onDefaultClick}
          >
            {t('Default')}
          </Button>
        </Stack>
      </Row>
    </Card>
  );
};

export default AccountSettingBasicInfo;
