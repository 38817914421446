import React , { useEffect, useState }  from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import * as Yup from 'yup';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { Card, Divider, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { FormProvider, RHFTextField } from '../../../../components/hookForm';
import { PageTitle } from '../../../../components/PageTitle';
import CustomSwitch from '../../../../components/CustomSwitch';
import { css } from 'styled-components';
import Iconify from '../../../../components/Iconify';
import { LoadingButton } from '@mui/lab';

const GasPumpWalletSetting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [canEdit , setCanEdit] = useState(true)
  const [canEditTron , setCanEditTron] = useState(true)

  const [views , setViews] = useState({
    mainWalletAddress : false ,
    mainWalletPrivateKey : false ,
    gasPumpAddress : false ,
    gasPumpPrivateKey : false ,
    tronGasPumpAddress: false,
    tronGasPumpPrivateKey: false,
    tronMainWalletAddress: false,
    tronMainWalletPrivateKey: false
  })

  const validators = Yup.object().shape({
    mainWalletAddress: Yup.string().notRequired(),
    mainWalletPrivateKey: Yup.string().notRequired(),
    gasPumpAddress: Yup.string().notRequired(),
    gasPumpPrivateKey: Yup.string().notRequired(),

    tronGasPumpAddress: Yup.string().notRequired(),
    tronGasPumpPrivateKey: Yup.string().notRequired(),
    tronMainWalletAddress: Yup.string().notRequired(),
    tronMainWalletPrivateKey: Yup.string().notRequired(),


  });


  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'GasPump wallet setting' },
    });
  }, []);


  const defaultValues = {
    mainWalletAddress: '',
    mainWalletPrivateKey: '',
    gasPumpAddress: '',
    gasPumpPrivateKey: '',
    tronGasPumpAddress: '',
    tronGasPumpPrivateKey: '',
    tronMainWalletAddress: '',
    tronMainWalletPrivateKey: ''

  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    control,


    formState: { isSubmitting },
  } = methods;


  const { enqueueSnackbar } = useSnackbar();


  const onSubmit = async (payload) => {
    const data = {
      ...payload,
    };
    try {
      let response;
      response = await Api.saveGasPumpWalletAddress(data);

      if (response?.status === 'SUCCESS') {
        enqueueSnackbar(t('Successfully saved!'), { variant: 'success' });
      }
    } catch (err) {}
  };

  useEffect(()=>{
    const getData = async ()=>{
      try {
        const res = await Api.getGasWalletAddress()
        setValue('gasPumpAddress' ,res?.gasPumpAddress)
        setValue('gasPumpPrivateKey' ,res?.gasPumpPrivateKey)
        setValue('tronGasPumpAddress' ,res?.tronGasPumpAddress)
        setValue('tronGasPumpPrivateKey' ,res?.tronGasPumpPrivateKey)

      }catch (e) {

      }
    }

    getData()
  },[])
  return (
    <Stack sx={{ padding: { xs: '0 20px 20px', lg: '20px 0 97px' } }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card
          sx={{
            px: { xs: 2, md: 5 },
            py: { xs: 3, md: 5 },
          }}
          >
          <PageTitle
            title={'Ethereum, Polygon, Binance Networks'}
            subTitle={
              ''
            }
          />
          <div style={{marginTop:"2rem"}}/>
          {/*<Stack direction='row' alignItems='center' spacing={1} sx={{mb:'1rem'}} >*/}
          {/*  <CustomSwitch value={canEdit} onChange={(e)=>setCanEdit(e?.target?.checked)} />*/}
          {/*  <Typography variant='body3'>{t('Can Edit')}</Typography>*/}
          {/*</Stack>*/}


          <div style={{marginTop:"3rem"}}/>
          <Typography variant='subtitle3' sx={{ mb: { xs: 1, sm: 2 ,my:4} }}>
            {t('Gas Pump addresses')}
          </Typography>

          <Grid container spacing={4}  >
            <Grid item xs={12} md={12} lg={12}>
              <RHFTextField
                type={'text'}
                InputProps={{
                  sx: css`
                  input {
                    /* @noflip */
                    direction: ltr;
                  }
                `
                }}
                name={'gasPumpAddress'} label={t('Gas pump address')} variant='standard' fullWidth disabled={!canEdit}/>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <RHFTextField
                type={'text'}
                InputProps={{
                  sx: css`
                  input {
                    /* @noflip */
                    direction: ltr;
                  }
                `
                }}
                name={'gasPumpPrivateKey'} label={t('Gas pump private key')} variant='standard' fullWidth disabled={!canEdit}/>
            </Grid>
          </Grid>

        </Card>
        <Divider sx={{my:'1rem'}} />

        <Card>
          <PageTitle
            title={'Tron'}
            subTitle={
              ''
            }
          />
          <div style={{marginTop:"2rem"}}/>
          {/*<Stack direction='row' alignItems='center' spacing={1} sx={{mb:'1rem'}} >*/}
          {/*  <CustomSwitch value={canEditTron} onChange={(e)=>setCanEditTron(e?.target?.checked)} />*/}
          {/*  <Typography variant='body3'>{t('Can Edit')}</Typography>*/}
          {/*</Stack>*/}


          <div style={{marginTop:"1.5rem"}}/>

          <Typography variant='subtitle3' sx={{  }}>
            {t('Gas Pump Settings')}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <RHFTextField
                type={'text'}

                InputProps={{
                  sx: css`
                  input {
                    /* @noflip */
                    direction: ltr;
                  }
                `
                }}
                name={'tronGasPumpAddress'} label={t('Gas pump address')} variant='standard' fullWidth disabled={!canEditTron}/>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <RHFTextField
                type={'text'}

                InputProps={{
                  sx: css`
                  input {
                    /* @noflip */
                    direction: ltr;
                  }
                `
                }}
                name={'tronGasPumpPrivateKey'}
                label={t('Main wallet private key')}
                variant='standard'
                fullWidth
                disabled={!canEditTron}
              />
            </Grid>
          </Grid>
        </Card>


        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ maxWidth: 300, typography: 'body3', fontWeight: 500, mt: 5 }}
        >
          {t('Save')}
        </LoadingButton>
      </FormProvider>
    </Stack>
  );
};

export default GasPumpWalletSetting;