import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TableContainer, Table, TableBody, Stack, Card, Box, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import Scrollbar from '../../../../components/Scrollbar';
import { TableLoading, TableHeadCustom, TableNoData } from '../../../../components/table';
import NetworkTableRow from './networkTableRow';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import useTable from '../../../../hooks/useTable';
import NetworkToolbar from './networkToolbar';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'number', label: 'Num', align: 'left' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'networkName', label: 'Network Name', align: 'left' },
  { id: 'symbol', label: 'Symbol', align: 'left' },
  { id: 'status', label: 'Network Status', align: 'center' },
  { id: 'activation', label: 'Activation', align: 'center' },
];

// ----------------------------------------------------------------------

const NetworkSetting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [tableData, setTableData] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [dataFiltered, setDataFiltered] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [networkList, setNetworkList] = useState([]);

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Settings network' },
    });
  }, []);

  const [isAllActive, setIsAllActive] = useState(null);

  const getNetworkData = async () => {
    const response = await Api.getNetworkSettings();
    if (response.status === 'SUCCESS') {
      const list = response.networkSettingInfoList;
      setTableData(list);

      const groups = {};
      list.forEach((item) => {
        if (groups[item.networkName]) {
          groups[item.networkName].push(item);
        } else {
          groups[item.networkName] = [item];
        }
      });

      setNetworkList(Object.keys(groups));

      const countActive = list.length - list.filter((n) => !n.cryptiziStatus).length;
      const countInactive = list.length - countActive;
      setIsAllActive(countActive > countInactive);
    }
  };

  useEffect(() => {
    getNetworkData();
  }, []);

  // Filtered
  useEffect(() => {
    if (!tableData) return;

    onChangePage(null, 0);

    let filtered = [...tableData];

    if (selectedStatus !== 'all') {
      filtered = filtered.filter((item) => item.cryptiziStatus === (selectedStatus === 'active'));
    }

    if (selectedNetwork) {
      filtered = filtered.filter((item) => item.networkName === selectedNetwork);
    }

    if (selectedNetwork || selectedStatus !== 'all') {
      const countActive = filtered.length - filtered.filter((n) => !n.cryptiziStatus).length;
      const countInactive = filtered.length - countActive;
      setIsAllActive(countActive > countInactive);
    }

    filtered = filtered.filter(
      (item) =>
        item.name.toLowerCase().indexOf(searchValue.toLowerCase().trim()) !== -1 ||
        item.networkName.toLowerCase().indexOf(searchValue.toLowerCase().trim()) !== -1 ||
        item.symbol.toLowerCase().indexOf(searchValue.toLowerCase().trim()) !== -1
    );

    setDataFiltered(filtered);
  }, [tableData, searchValue, selectedNetwork, selectedStatus]);

  // ---------------------
  const onChangeActive = (name, symbol, isActive) => {
    const newList = [...tableData];

    const found = newList.find((item) => item.name === name && item.symbol === symbol);
    found.cryptiziStatus = isActive;

    setTableData(newList);
  };

  // ---------------------
  return (
    <Stack sx={{ padding: { xs: '0 25px', lg: '0' }, mt: 3 }}>
      {/* <Button
        variant='contained'
        onClick={() => navigate(navigationUrl.admin_setting_new_network)}
        size='large'
        sx={{
          typography: 'body3',
          width: 150,
          mt: 2.5,
          mb: 3.5,
        }}
      >
        {t('Add Market')}
      </Button> */}

      <Card>
        <NetworkToolbar
          onSearch={setSearchValue}
          isAllActive={isAllActive}
          onReload={getNetworkData}
          networkList={networkList}
          selectedNetwork={selectedNetwork}
          setSelectedNetwork={setSelectedNetwork}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          dataFiltered={dataFiltered}
        />

        <Scrollbar sx={{ mt: 2 }}>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size='small'>
              <TableHeadCustom headLabel={TABLE_HEAD} rowCount={dataFiltered?.length} />
              <TableBody>
                {tableData === null ? (
                  <TableLoading isLoading />
                ) : (
                  <>
                    {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                      <NetworkTableRow
                        key={`${row.networkName}-${row.symbol}`}
                        row={row}
                        index={index + page * rowsPerPage}
                        onChangeActive={onChangeActive}
                      />
                    ))}

                    <TableNoData isNotFound={!dataFiltered?.length} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        {dataFiltered?.length > 0 && (
          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              component='div'
              count={dataFiltered?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              labelRowsPerPage={t('Rows per page')}
              labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
            />
          </Box>
        )}
      </Card>
    </Stack>
  );
};

export default NetworkSetting;
