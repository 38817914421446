import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import Api from '../../http/serviceApi';
import OptInput from '../OptInput';
import useTimer from '../../hooks/useTimer';

const LoginOTP = ({ data: { emailAddress, smsAuthEnabled, emailAuthEnabled, softOtpAuthEnabled ,mobileNumber }, onSuccess }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [smsOtp, setSmsOtp] = useState('');
  const [emailOtp, setEmailOtp] = useState('');
  const [softOtp, setSoftOtp] = useState('');

  const { onRequestAgainClick, timer, stopTimer, activeTimerState } = useTimer({
    emailAddress,
    operationType: 'Login',
  });

  const onSubmit = async () => {
    if (!emailOtp && emailAuthEnabled) {
      return enqueueSnackbar(t('Enter the email verification code!'), { variant: 'error' });
    }
    if (!smsOtp && smsAuthEnabled) {
      return enqueueSnackbar(t('Enter the phone number verification code!'), { variant: 'error' });
    }
    if (!softOtp && softOtpAuthEnabled) {
      return enqueueSnackbar(t('Enter the authenticator code!'), { variant: 'error' });
    }

    setIsSubmitting(true);
    try {
      const response = await Api.loginTwoFaVerify({ emailAddress, emailOtp, smsOtp, softOtp });
      if (response?.status === 'SUCCESS') {
        stopTimer();
        onSuccess(response, emailAddress);
      }
    } catch (e) {}

    setIsSubmitting(false);
  };

  useEffect(() => {
    if (emailAuthEnabled && emailOtp.length !== 5) return;
    if (smsAuthEnabled && smsOtp.length !== 5) return;
    if (softOtpAuthEnabled && softOtp.length !== 6) return;
    onSubmit();
  }, [smsOtp, emailOtp, softOtp]);


  function transformEntry(item, type) {

    return item[0] +item[1] + item[2] +  "*".repeat(item.length - 7) + item.slice(-4);

  }

  return (
    <>
      <Typography variant='h4' color={'grey.100'} sx={{ fontWeight: 400 }}>
        Security verification
      </Typography>

      {emailAuthEnabled && (
        <OptInput
          code={emailOtp}
          setCode={setEmailOtp}
          label={t('Email verification code')}
          description={t('Enter the {{number}}-digit code send to {{email}}', { email: emailAddress, number: 5 })}
          shouldAutoFocus
        />
      )}

      {smsAuthEnabled && (
        <OptInput
          code={smsOtp}
          setCode={setSmsOtp}
          label={t('Phone number verification code')}
          description={t('Enter the {{number}}-digit code send to {{phone}}', { phone: transformEntry(mobileNumber) })}
        />
      )}

      {softOtpAuthEnabled && (
        <OptInput
          code={softOtp}
          setCode={setSoftOtp}
          label={t('Authenticator code')}
          numInputs={6}
          description={t('Enter the {{number}}-digit code from Google authenticator', { number: 6 })}
        />
      )}

      <Stack sx={{ mt: 5 }}>
        <Typography variant='body3' sx={{ letterSpacing: 1 }}>
          {timer}
        </Typography>
        <Stack direction='row' alignItems={'center'}>
          <Typography variant='body3_secondary' color='grey.400'>
            {t('Didn’t recieve code?')}
          </Typography>
          <Button
            color='warning'
            sx={{ typography: 'body3_secondary', borderRadius: 1, px: 1 }}
            onClick={onRequestAgainClick}
            size='small'
            disabled={!activeTimerState}
          >
            {t('Request Again')}
          </Button>
        </Stack>
      </Stack>

      <Box sx={{ width: 320 }}>
        <LoadingButton
          fullWidth
          size='large'
          type='button'
          variant='contained'
          loading={isSubmitting}
          onClick={() => onSubmit(null)}
          sx={{ mt: '20px' }}
        >
          {t('Submit')}
        </LoadingButton>
      </Box>
    </>
  );
};

export default LoginOTP;
