import { Box, Button, Card, Tab, Tabs } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SET_PAGE_STATE } from '../../../store/actionTypes';
import useWallet from '../../../hooks/useWallet';
import Api from '../../../http/serviceApi';
import SelectCryptoModal from '../../../components/selectCryptoModal/selectCryptoModal';
import { toNumber } from '../../../utils/formatNumber';
import { navigationUrl } from '../../../navigationUrl';
import OTCTab from './otcTab';
import OTCPayment from './otcPayment';
import useQuery from '../../../hooks/useQuery';
import debounce from "lodash.debounce";
const DEBOUNCE_TIME_MS = 300;
export default function OTC() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const query = useQuery();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Buy Crypto with Debit / Credit Card' },
    });
  }, []);

  const { userWallet, onReloadWallet } = useWallet();

  const sideQuery = query.get('side') ? query.get('side') : 'buy';

  const [step, setStep] = useState('convert');
  const [tab, setTab] = useState(sideQuery === 'buy' ? 0 : 1);
  const [showCryptoModal, setShowCryptoModal] = useState(false);
  const [side, setSide] = useState(sideQuery);
  const [spend, setSpend] = useState(query.get('value') ? +query.get('value') : '');
  const [receive, setReceive] = useState(0);
  const [spendSymbol, setSpendSymbol] = useState(query.get('spend') || 'USD');
  const [receiveSymbol, setReceiveSymbol] = useState(query.get('symbol') || query.get('receive') || 'BTC');
  const [calculated, setCalculated] = useState(0);

  const handleTabChange = (e, newTab) => {
    console.log('tab-change'  , newTab)
    setTab(newTab);
    setSpend('');
    setReceive(0);
    setCalculated(0);

    if (newTab === 0) {
      setSpendSymbol('USD');
      setReceiveSymbol('BTC');
      setSide('buy');
    } else {
      setSpendSymbol('BTC');
      setReceiveSymbol('USD');
      setSide('sell');
    }
  };


  const getCalculate = async (data) => {

    const fiat = data?.side === 'buy' ? data?.spendSymbol : data?.receiveSymbol;
    const symbol = data?.side === 'sell' ? data?.spendSymbol : data?.receiveSymbol;
    const sp = data?.spend ? data?.spend  : spend ? spend : 1
    const sellSp = data?.spend ? data?.spend  : spend ? spend : 1
     try {
      const response = await Api.calculateOTC(
        symbol,
        fiat,
        data?.side === 'sell' ? parseFloat(sp?.toString()?.replace(',','')?.replace(',','')?.replace(',','')?.replace(',','')?.replace(',','')) : 0, // symbolAmount
        data?.side === 'buy' ? parseFloat(sp?.toString()?.replace(',','')?.replace(',','')?.replace(',','')?.replace(',','')?.replace(',','')) : 0, // fiatAmount
        data?.side.toUpperCase()
      );
      if (response.status === 'SUCCESS') {
        const value = data?.side === 'buy' ? response.symbolValue : response.fiatValue;
        setCalculated(value);
        setReceive(value);
      }
    } catch (e) {
      setCalculated(1);
    }
  };

  useEffect(() => {
    getCalculate({spend, side:side , spendSymbol , receiveSymbol});
  }, [spendSymbol, receiveSymbol]);

  const onSelectSymbol = (value) => {
    if (tab === 0) {
      setReceiveSymbol(value);
    } else {
      setSpendSymbol(value);
      setSpend('');
    }
  };

  useEffect(() => {
    // onCalculate();
  }, [spend]);

  const onCalculate = (value) => {
    setReceive(toNumber(spend) * (value || calculated));
  };

  const onReset = () => {
    setStep('convert');
    handleTabChange(null, 0);
    onReloadWallet();
  };
  console.log(spend)
  const changeSpendHandler = useMemo(
    () =>
      debounce(({spend , side , spendSymbol ,receiveSymbol}) => {
        setSpend(spend)
         getCalculate({spend : spend , side:side , spendSymbol , receiveSymbol})
      }, DEBOUNCE_TIME_MS),
    []
  );

  const changeReceiveHandler = useMemo(
    () =>
      debounce((event) => {
        setReceive(event)
        getCalculate({receive : event ,side:side , spendSymbol , receiveSymbol})
      }, DEBOUNCE_TIME_MS),
    []
  );
  return (
    <>
      {step === 'convert' ? (
        <Box sx={{ position: 'relative', mt: 7 }}>
          <Button
            variant='outlined'
            color='inherit'
            component={Link}
            to={navigationUrl.order_history}
            sx={{
              position: 'absolute',
              right: { xs: 25, md: 0 },
              top: { xs: -60, md: 0 },
              zIndex: 1,
              borderColor: 'grey.400',
              typography: 'body3',
              height: 40,
              width: 150,
            }}
          >
            {t('Orders history')}
          </Button>

          <Box sx={{ mx: { xs: 2, md: 0 } }}>
            <Tabs
              centered
              value={tab}
              onChange={handleTabChange}
              aria-label='basic tabs example'
              textColor='inherit'
              indicatorColor='secondary'
            >
              <Tab label={t('Buy')} sx={{ width: 200, borderBottom: '2px solid grey.400' }} />
              <Tab label={t('Sell')} sx={{ width: 200, borderBottom: '2px solid grey.400' }} />
            </Tabs>
          </Box>

          <TabPanel value={tab} index={0}>
            <OTCTab
              side='buy'
              spendSymbol={spendSymbol}
              receiveSymbol={receiveSymbol}
              onChangeSpendSymbol={setSpendSymbol}
              onChangeReceiveSymbol={setReceiveSymbol}
              spend={spend}
              receive={receive}
              onChangeSpend={(e)=>{
                setSpend(e)
                changeSpendHandler({spend:e , side , spendSymbol ,receiveSymbol})
              }}
              onChangeReceive={setReceive}
              openCryptoModal={() => setShowCryptoModal(true)}
              calculated={calculated}
              userWallet={userWallet}
              onNext={() => setStep('payment')}
            />
          </TabPanel>

          <TabPanel value={tab} index={1}>
            <OTCTab
              side='sell'
              spendSymbol={spendSymbol}
              receiveSymbol={receiveSymbol}
              onChangeSpendSymbol={setSpendSymbol}
              onChangeReceiveSymbol={setReceiveSymbol}
              spend={spend}
              receive={receive}
              onChangeSpend={(e)=>{
                setSpend(e)
                changeSpendHandler({spend:e , side , spendSymbol ,receiveSymbol})
              }}
              onChangeReceive={setReceive}
              openCryptoModal={() => setShowCryptoModal(true)}
              calculated={calculated}
              userWallet={userWallet}
              onNext={() => setStep('payment')}
            />
          </TabPanel>
        </Box>
      ) : (
        <Box sx={{ mt: 6 }}>
          <OTCPayment
            onBack={() => {
              setStep('convert');
              getCalculate({side:side , spend , spendSymbol , receiveSymbol});
            }}
            side={side}
            spend={spend}
            receive={receive}
            spendSymbol={spendSymbol}
            receiveSymbol={receiveSymbol}
            calculated={calculated}
            onReset={onReset}
          />
        </Box>
      )}

      <SelectCryptoModal open={showCryptoModal} onClose={() => setShowCryptoModal(false)} onSelect={onSelectSymbol} />
    </>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  if (value === index) {
    return (
      <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 }, mx: { xs: 2, md: 0 } }}>
        <Box sx={{ width: { sx: '100%', md: 627 }, m: 'auto' }}>{children}</Box>
      </Card>
    );
  }
}
