import React from 'react';
import { TableRow, TableCell, IconButton, Typography, Stack } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import Iconify from '../../../../components/Iconify';
import Image from '../../../../components/Image';

import USD from '../../../../assets/logo/dolar.svg';
import IRR from '../../../../assets/logo/IRR.svg';

const WaitingWithdrawFiatTableRow = ({ row, onShowModalDetail }) => {
  const language = useSelector((store) => store.language);

  const { emailAddress, mobile, firstName, lastName, createdDate, fiatType, trackingCode, account, amount } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1.5, typography: 'body2' } }}>
      <TableCell>{moment(createdDate).locale(language).format('YYYY-MM-DD HH:mm')}</TableCell>

      <TableCell>
        <Typography variant='body3'>
          {firstName} {lastName}
        </Typography>
        {emailAddress && (
          <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
            {emailAddress}
          </Typography>
        )}
        {mobile && (
          <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
            {mobile}
          </Typography>
        )}
      </TableCell>

      <TableCell align='center'>{amount}</TableCell>

      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ width: 80 }}>
          <Image src={fiatType === 'USD' ? USD : IRR} disabledEffect />
          <Typography variant='body3' noWrap sx={{ color: 'grey.100' }}>
            {fiatType}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>
        {account
          .replace(/[^\dA-Z]/g, '')
          .replace(/(.{4})/g, '$1 ')
          .trim()}
      </TableCell>

      <TableCell>{trackingCode || '-'}</TableCell>

      <TableCell align='center' sx={{ display: 'flex', justifyContent: { xs: 'space-between', lg: 'center' } }}>
        <IconButton onClick={onShowModalDetail} sx={{ mr: { xs: 0, lg: 1 } }}>
          <Iconify icon='ph:link-duotone' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default WaitingWithdrawFiatTableRow;
