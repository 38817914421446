import React, { useEffect, useState } from 'react';
import { Stack, Table, TableBody, TableContainer, Box, TablePagination, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CardsToolbar from './cardsComponents/cardsToolbar';
import Scrollbar from '../../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../../components/table';
import useTable from '../../../../../hooks/useTable';
import CardsTableRow from './cardsComponents/cardsTableRow';
import Api from '../../../../../http/adminHttp/serviceApiAdmin';
import AddEditCardModal from '../../../bankCard/editCardModal';
import { exportExcel } from '../../../../../utils/excel';
import { useSelector } from 'react-redux';
import { prettyUppercases } from '../../../../../utils';
import { exportPDF } from '../../../../../utils/pdf';

// ===================================================================

const TABLE_HEAD = [
  { id: 'num', label: 'Num', align: 'center' },
  { id: 'cardNumber', label: 'Card Number', align: 'left' },
  { id: 'accountNumber', label: 'Account Number', align: 'left' },
  { id: 'shebaNum', label: 'Sheba', align: 'left' },
  { id: 'bank', label: 'Bank', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'condition', label: 'Condition', align: 'center' },
  { id: 'edit', label: 'Edit', align: 'center' },
];

// ===================================================================

const Cards = ({ userId, userInfo }) => {
  const language = useSelector((store) => store.language);
  const { t } = useTranslation();

  // -------------------------
  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  // -------------------------
  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });
  const [showUpdateModal, setShowUpdateModal] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);

  // ----------------------------------------------
  const getData = async () => {
    try {
      const filters = { userId };

      const response = await Api.getUserCardsInformation(page, rowsPerPage, filters);
      if (response) {
        setTableData(response.bankCardInfos);
        setTablePagination({ totalItems: response.bankCardInfosTotalPages * rowsPerPage });
      }
    } catch (e) {
      setTableData([]);
    }
  };

  // -------------------------
  useEffect(() => {
    if (userId) getData();
  }, [page, rowsPerPage, userId]);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);
  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    // generate excel and download
    const config = {
      language,
      fileName: `${t('User')} - ${t('Cards')}`,
      heads: ['#', t('Card Number'), t('Account Number'), t('Sheba'), t('Bank'), t('Date'), t('Condition')],
      columnsWidth: [5, 23, 19, 29, 18, 11, 16],
      list: tableData.map((item) => [
        item.cardNumber,
        item.accountNumber || '-',
        item.shebaNum || '-',
        t(prettyUppercases(item.cardType)),
        item.expiryDateYear ? `${item.expiryDateYear}/${item.expiryDateMonth}` : '-',
        t(prettyUppercases(item.status)),
      ]),
    };

    type === 'PDF' ? exportPDF(config) : exportExcel(config);

    setIsExportLoading(false);
  };

  // -------------------------
  return (
    <>
      <Stack>
        <Stack
          direction={'row'}
          sx={{ width: '100%' }}
          spacing={2}
          justifyContent='space-between'
          alignItems={'flex-start'}
        >
          <Button
            onClick={() => setShowAddModal(true)}
            variant='contained'
            sx={{ maxWidth: 161, typography: 'body3', fontWeight: 500, mt: 4, mb: 2.75 }}
          >
            {t('Add Card')}
          </Button>
          <Stack sx={{ width: 'calc(100% - 177px)' }}>
            <CardsToolbar isExportLoading={isExportLoading} onExport={onExport} />
          </Stack>
        </Stack>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size='small'>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length || 0}
                onSort={onSort}
              />
              <TableBody>
                {tableData === null ? (
                  <TableLoading isLoading />
                ) : (
                  <>
                    {tableData?.map((row, index) => (
                      <CardsTableRow
                        key={index}
                        row={row}
                        index={index}
                        onUpdateRow={() => setShowUpdateModal(row)}
                        reload={getData}
                      />
                    ))}
                    <TableNoData isNotFound={!tableData?.length} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component='div'
            count={tablePagination.totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
          />
        </Box>
      </Stack>

      <AddEditCardModal
        open={!!showUpdateModal}
        onClose={() => setShowUpdateModal(null)}
        data={showUpdateModal}
        reload={getData}
      />

      <AddEditCardModal
        open={!!showAddModal}
        onClose={() => setShowAddModal(false)}
        userId={userId}
        reload={getData}
        userInfo={userInfo}
      />
    </>
  );
};

export default Cards;
