import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import ReferralsReportTrade from './referralsReportTrade';
import ReferralsReportIntroduce from './referralsReportIntroduce';

export default function ReferralsReport({ info }) {
  const { t } = useTranslation();

  // -------------------------
  const [tab, setTab] = useState(0);

  // -------------------------
  const handleTabChange = (e, newTab) => {
    setTab(newTab);
  };

  // -------------------------
  return (
    <>
      <Typography variant='h6' sx={{ fontWeight: 500, mb: 2.5 }}>
        {t('Reports')}
      </Typography>

      <Box sx={{ borderBottom: 2, borderColor: 'divider', maxWidth: 440 }}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label={t('List of trades in your subset')} sx={{ width: '50%', typography: 'body3' }} />
          <Tab label={t('List of introduced users')} sx={{ width: '50%', typography: 'body3' }} />
        </Tabs>
      </Box>

      <TabPanel value={tab} index={0}>
        <ReferralsReportTrade list={info.trades} />
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <ReferralsReportIntroduce list={info.referrals} />
      </TabPanel>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  if (value === index) {
    return <Box sx={{ mt: 5 }}>{children}</Box>;
  }
}
