import React from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import Iconify from '../../../components/Iconify';
import { useTranslation } from 'react-i18next';
import { fFloat, fPercent } from '../../../utils/formatNumber';

const BalanceStatistic = ({ dashboardInfo, userWallet }) => {
  const { t } = useTranslation();

  const [balanceVisible, setBalanceVisible] = useState(false);

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant='h6_secondary'>{t('Balance')}</Typography>
        <Stack direction={'row'} spacing={1.2} alignItems={'center'}>
          {dashboardInfo.todayPnlPercent !== 0 && (
            <Iconify
              icon={dashboardInfo.todayPnlPercent >= 0 ? 'ph:arrow-up-fill' : 'ph:arrow-down-fill'}
              color={dashboardInfo.todayPnlPercent >= 0 ? 'positive.main' : 'error.darker'}
              height={18}
            />
          )}

          <Typography
            variant='subtitle1'
            color={
              dashboardInfo.todayPnlPercent === 0
                ? 'grey.300'
                : dashboardInfo.todayPnlPercent >= 0
                ? 'positive.main'
                : 'error.darker'
            }
          >
            {fPercent(
              dashboardInfo.todayPnlPercent >= 0 ? dashboardInfo.todayPnlPercent : -1 * dashboardInfo.todayPnlPercent
            )}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mt: 1 }}>
        <Typography variant='h2_secondary'>
          {balanceVisible ? fFloat(dashboardInfo.balanceAmount) : '***'} USD
        </Typography>

        <IconButton onClick={() => setBalanceVisible((prev) => !prev)}>
          <Iconify icon={balanceVisible ? 'ant-design:eye-outlined' : 'ant-design:eye-invisible-outlined'} />
        </IconButton>
      </Stack>

      <Stack direction={{ xs: 'row', sm: 'column', lg: 'row' }} justifyContent={'space-between'} sx={{ mt: 2 }}>
        <Box>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Iconify
              icon={userWallet?.todayPnlDollar >= 0 ? 'ph:arrow-up-fill' : 'ph:arrow-down-fill'}
              color={userWallet.todayPnlDollar >= 0 ? 'positive.main' : 'error.darker'}
              height={15}
            />
            <Typography variant='body2' color='grey.400'>
              {t("Today's PNL")}
            </Typography>
          </Stack>

          <Typography variant='subtitle1' sx={{ mt: 1 }}>
            {userWallet?.todayPnlDollar > 0 ? userWallet?.todayPnlDollar : -1 * userWallet?.todayPnlDollar} USD
          </Typography>
        </Box>

        <Box>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Iconify
              icon={userWallet?.monthPnlDollar >= 0 ? 'ph:arrow-up-fill' : 'ph:arrow-down-fill'}
              color={userWallet.monthPnlDollar >= 0 ? 'positive.main' : 'error.darker'}
              height={15}
            />
            <Typography variant='body2' color='grey.400'>
              {t('30 Days PNL')}
            </Typography>
          </Stack>

          <Typography variant='subtitle1' sx={{ mt: 1, textAlign: { xs: 'inherit', lg: 'right' } }}>
            {userWallet?.monthPnlDollar > 0 ? userWallet?.monthPnlDollar : -1 * userWallet?.monthPnlDollar} USD
          </Typography>
        </Box>
      </Stack>
    </>
  );
};

export default BalanceStatistic;
