// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: theme.palette.mode === 'light' ? '0 5px' : '0 5px',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.black : theme.palette.grey[700],

          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',

          '&:first-child': {
            borderRadius: theme.palette.mode === 'light' ? '8px 0 0 8px' : '8px 0 0 8px',
          },
          '&:last-child': {
            borderRadius: theme.palette.mode === 'light' ? '0 8px 8px 0' : '0 8px 8px 0',
          },
        },
        head: {
          ...theme.typography.body2,
          color: theme.palette.grey[400],
          borderBottom: 'none',
          // borderBottom: theme.palette.mode === 'dark' ? `solid 1px ${theme.palette.grey[600]}` : 'none',
          // '&:first-of-type': {
          //   paddingLeft: theme.spacing(3),
          //   borderTopLeftRadius: theme.shape.borderRadius,
          //   borderBottomLeftRadius: theme.shape.borderRadius,
          //   boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`,
          // },
          // '&:last-of-type': {
          //   paddingRight: theme.spacing(3),
          //   borderTopRightRadius: theme.shape.borderRadius,
          //   borderBottomRightRadius: theme.shape.borderRadius,
          //   boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`,
          // },
        },
        // stickyHeader: {
        //   backgroundColor: theme.palette.background.paper,
        //   backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
        // },
        // body: {
        //   '&:first-of-type': {
        //     paddingLeft: theme.spacing(3),
        //   },
        //   '&:last-of-type': {
        //     paddingRight: theme.spacing(3),
        //   },
        // },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.grey[600]}`,
        },
        toolbar: {
          // height: 64,
          '& p': {
            margin: 0,
          },
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius,
          },
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: -4,
        },
      },
    },
  };
}
