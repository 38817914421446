import { TableCell, TableRow, Typography, Stack, IconButton } from '@mui/material';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Iconify from '../../../../components/Iconify';
import { prettyUppercases } from '../../../../utils';
import Image from '../../../../components/Image';

import USD from '../../../../assets/logo/dolar.svg';
import IRR from '../../../../assets/logo/IRR.svg';

export default function WalletFiatTransactionTableRow({ row, onShowModalDetail, status }) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const { id, fiatType, firstName, lastName, emailAddress, mobile, amount, createDate, transactionType } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1, typography: 'body2' } }}>
      <TableCell align='center' sx={{ width: 50 }}>
        {id}
      </TableCell>

      <TableCell>
        <Typography variant='body3'>
          {firstName} {lastName}
        </Typography>
        {emailAddress && (
          <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
            {emailAddress}
          </Typography>
        )}
        {mobile && (
          <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
            {mobile}
          </Typography>
        )}
      </TableCell>

      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ width: 140 }}>
          {fiatType === 'USD' && <Image src={USD} />}
          {fiatType === 'IRR' && <Image src={IRR} />}

          <Typography variant='body2' noWrap sx={{ width: 100 }}>
            {fiatType}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell align='center'>{amount}</TableCell>

      <TableCell>{moment(createDate).locale(language).format('D MMM YYYY - HH:mm')}</TableCell>

      <TableCell align='center'>{t(prettyUppercases(status))}</TableCell>

      <TableCell align='center' sx={{ width: 100 }}>
        <IconButton onClick={onShowModalDetail}>
          <Iconify icon='ph:link-duotone' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
