import { CircularProgress, IconButton, MenuItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FillSelect from '../../../../components/FillSelect';
import Iconify from '../../../../components/Iconify';
import FillTextField from '../../../../components/FillTextField';
import NumberRange from '../../../../components/NumberRange';
import FillRangeDatePicker from '../../../../components/FillRangeDatePicker';
import { useSelector } from 'react-redux';
import {
  FIAT_TYPES_JUST_IRR_EUR_USD,
  NEW_FIAT_TYPES_WITHOUT_IRR,
} from '../cryptoTransaction/cryptoTransactionTableToolbar';
import NumRangeWithInput from '../../../../components/NumRangeWithInput';
import React from 'react';

const SIDES = [
  { value: 'ALL', label: 'ALL' },
  { value: 'BUY', label: 'BUY' },
  { value: 'SELL', label: 'SELL' },
];

const FIAT_TYPES = [
  { value: 'ALL', label: 'ALL' },
  { value: 'AUD', label: 'AUD' },
  { value: 'BRL', label: 'BRL' },
  { value: 'EUR', label: 'EUR' },
  { value: 'GBP', label: 'GBP' },
  { value: 'IRR', label: 'IRR' },
  { value: 'NGN', label: 'NGN' },
  { value: 'PLN', label: 'PLN' },
  { value: 'RON', label: 'RON' },
  { value: 'RUB', label: 'RUB' },
  { value: 'TRY', label: 'TRY' },
  { value: 'UAH', label: 'UAH' },
  { value: 'USD', label: 'USD' },
  { value: 'ZAR', label: 'ZAR' },
];

export default function PaysafeHistoryTableToolbar({
  isAdmin = false,
  isExportLoading = false,
  onExport = () => {},
  sideFilter,
  setSideFilter,
  timeFilter,
  setTimeFilter,
  transactionIdFilter,
  setTransactionIdFilter,
  fiatTypeFilter,
  setFiatTypeFilter,
  fiatAmountFilter,
  setFiatAmountFilter,
  firstNameFilter,
  setFirstNameFilter,
  lastNameFilter,
  setLastNameFilter,
}) {
  const { t } = useTranslation();
  const isIranianPaymentActive = useSelector((store) => store.isIranianPaymentActive);

  const allFiats = isIranianPaymentActive ?  FIAT_TYPES_JUST_IRR_EUR_USD : NEW_FIAT_TYPES_WITHOUT_IRR
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent='space-between'
      alignItems={'flex-start'}
      spacing={2}
      sx={{ mb: { xs: 3, md: 9 } }}
    >
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Stack sx={{ width: '100%' }} flexDirection={'row'} alignItems={'flex-end'} gap={'15px'} flexWrap={'wrap'}>
          <FillRangeDatePicker
            value={timeFilter}
            setValue={setTimeFilter}
            variant='outlined'
            fullWidth
            sx={{ width: { xs: '100%', md: 335 } }}
            InputProps={null}
          />

          <FillSelect
            value={sideFilter}
            onChange={setSideFilter}
            label={t('Side')}
            sx={{ width: { xs: '100%', md: 160 } }}
          >
            {SIDES.map((side) => (
              <MenuItem key={side.value} value={side.value} sx={{ typography: 'body2' }}>
                {t(side.label)}
              </MenuItem>
            ))}
          </FillSelect>

          <FillSelect
            value={fiatTypeFilter}
            onChange={setFiatTypeFilter}
            label={t('Fiat Type')}
            sx={{ width: { xs: '100%', md: 160 } }}
          >
            {allFiats.map((fiatType) => (
              <MenuItem key={fiatType.value} value={fiatType.value} sx={{ typography: 'body2' }}>
                {t(fiatType.label)}
              </MenuItem>
            ))}
          </FillSelect>

          <FillTextField
            value={transactionIdFilter}
            onChange={setTransactionIdFilter}
            label={t('Tx-Code')}
            placeholder={'Enter Tx-Code'}
            sx={{ width: { xs: '100%', md: 160 } }}
            isDebounced
          />

          {/*<NumberRange*/}
          {/*  value={fiatAmountFilter}*/}
          {/*  setValue={setFiatAmountFilter}*/}
          {/*  max={100000}*/}
          {/*  sx={{*/}
          {/*    width: { xs: '100%', md: 160 },*/}
          {/*    border: '1px solid #52576e',*/}
          {/*    p: '3px 8px',*/}
          {/*    bgcolor: '#272833',*/}
          {/*    '& .MuiInputBase-root:before': { display: 'none' },*/}
          {/*    '& .MuiInputBase-root:after': { display: 'none' },*/}
          {/*  }}*/}
          {/*/>*/}

          {isAdmin && (
            <>
              <FillTextField
                value={firstNameFilter}
                onChange={setFirstNameFilter}
                label={t('First name')}
                sx={{ width: { xs: '100%', md: 160 } }}
                isDebounced
              />

              <FillTextField
                value={lastNameFilter}
                onChange={setLastNameFilter}
                label={t('Last name')}
                sx={{ width: { xs: '100%', md: 160 } }}
                isDebounced
              />
            </>
          )}
          <NumRangeWithInput
            max={100000}
            label={t('Amount')}
            value={fiatAmountFilter}
            setValue={setFiatAmountFilter}
            sx={{
              width: { xs: '100%', md: 160 },
              border: '1px solid #52576e',
              p: '3px 8px',
              bgcolor: '#272833',
              '& .MuiInputBase-root:before': { display: 'none' },
              '& .MuiInputBase-root:after': { display: 'none' },
            }}  />
        </Stack>
      </Stack>

      {onExport && (
        <>
          {isExportLoading ? (
            <CircularProgress size={22} color='success' sx={{ margin: '11px !important' }} />
          ) : (
            <>
              <IconButton onClick={onExport}>
                <Iconify icon='tabler:receipt' sx={{ width: 28, height: 28 }} />
              </IconButton>
              <IconButton onClick={() => onExport('PDF')}>
                <Iconify icon='majesticons:printer-line' sx={{ width: 28, height: 28 }} />
              </IconButton>
            </>
          )}
        </>
      )}
    </Stack>
  );
}
