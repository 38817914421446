import { Box, Button, Stack, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';

import { PageTitle } from '../../../../components/PageTitle';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import BriefInfoCard from '../../../../components/BriefInfoCard';
import { navigationUrl } from '../../../../navigationUrl';
import UserTable from './userTable';
import UserToolbar from './userToolbar';
import useUserInit from './hook/useUserInit';
import useTable from '../../../../hooks/useTable';
import { getDefaultDateFilterValues } from '../../../../utils/filter';

const User = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'User' },
    });
  }, []);

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage , setPage } = useTable({
    defaultOrderBy: '',
  });

  const [conditionFilter, setConditionFilter] = useState('ALL');
  const [authenticationLevelFilter, setAuthenticationLevelFilter] = useState('ALL');
  const [dateFromFilter, setDateFromFilter] = useState(getDefaultDateFilterValues().firstDay);
  const [dateToFilter, setDateToFilter] = useState(getDefaultDateFilterValues().lastDay);
  const [nameFilter, setNameFilter] = useState('');
  const [userIdFilter, setUserIdFilter] = useState(null);
  const [balanceFilter, setBalanceFilter] = useState([0, 50000]);


  const { userStatistic, allUsers, handleDeleteUser, getAllUser, isExportLoading, onExport } = useUserInit({
    page,
    rowsPerPage,
    conditionFilter,
    dateFromFilter,
    dateToFilter,
    nameFilter,
    userIdFilter,
    balanceFilter,
    authenticationLevelFilter,
    setPage
  });

  return (
    <Stack sx={{ padding: { xs: '0 20px 20px', lg: '0 0 97px' }, mt: 0.5 }}>
      <Stack
        direction={'row'}
        alignItems='center'
        flexWrap='wrap'
        sx={{ justifyContent: { xs: 'center', md: 'space-between' } }}
        className='no-print'
      >
        {userStatistic.map((user, index) => (
          <Box width='calc(25% - 20px)' sx={{ minWidth: 250, mt: 2, mr: 2 }}>
            <BriefInfoCard data={user} key={index} />
          </Box>
        ))}
      </Stack>

      <Button
        variant='contained'
        onClick={() => navigate(navigationUrl.admin_add_user)}
        size='large'
        sx={{
          maxWidth: 150,
          my: 2.5,
        }}
        className='no-print'
      >
        {t('Add User')}
      </Button>

      <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 } }}>
        <PageTitle title='User' subTitle='You can not disable a port that is in the default state.' />

        <UserToolbar
          conditionFilter={conditionFilter}
          setConditionFilter={setConditionFilter}
          dateFromFilter={dateFromFilter}
          setDateFromFilter={setDateFromFilter}
          dateToFilter={dateToFilter}
          setDateToFilter={setDateToFilter}
          nameFilter={nameFilter}
          setNameFilter={setNameFilter}
          userIdFilter={userIdFilter}
          setUserIdFilter={setUserIdFilter}
          balanceFilter={balanceFilter}
          setBalanceFilter={setBalanceFilter}
          authenticationLevelFilter={authenticationLevelFilter}
          setAuthenticationLevelFilter={setAuthenticationLevelFilter}
          onFilter={getAllUser}
          onExport={onExport}
          isExportLoading={isExportLoading}
        />

        <UserTable
          tableData={allUsers}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          totalUsers={userStatistic.filter((each) => each.name === 'Total users')[0]?.value}
          handleDeleteUser={handleDeleteUser}
        />
      </Card>
    </Stack>
  );
};

export default User;
