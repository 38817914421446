import { useEffect, useState } from 'react';
import Api from '../../../../http/serviceApi';

export default function useDashboardInfo() {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({
    dashboardInfo: {
      balanceAmount: 0,
      expense: 0,
      income: 0,
      todayPnlPercent: 0,
      topWalletInfo: {
        firstWalletName: '',
        firstWalletPercent: 0,
        firstWalletSymbol: '',
        otherWalletPercent: 0,
        secondWalletName: '',
        secondWalletPercent: 0,
        secondWalletSymbol: '',
        thirdWalletName: '',
        thirdWalletPercent: 0,
        thirdWalletSymbol: '',
      },
    },
    topAssetInfo: [
      {
        changePercent: 0,
        name: '',
        prices: [],
        symbol: '',
        usdtValue: 0,
      },
    ],
    transactionInfo: {
      cryptoDepositInfos: [],
      cryptoDepositInfosTotalPages: 0,
      cryptoWithdrawsInfos: [],
      cryptoWithdrawsInfosTotalPages: 0,
      all: [],
      fiatDepositInfos: [],
      fiatDepositInfosTotalPages: 0,
      message: '',
      status: '',
    },
  });

  useEffect(() => {
    userDashboardInfo();
  }, []);

  const userDashboardInfo = async () => {
    setLoading(true);

    try {
      const response = await Api.getUserDashboardInfo();

      const deposits = response.transactionInfo.cryptoDepositInfos;
      const withdrawals = response.transactionInfo.cryptoWithdrawsInfos;

      const transactions = [...deposits, ...withdrawals].sort((a, b) => b.createDate - a.createDate);

      setData({ ...response, transactionInfo: { ...response.transactionInfo, all: transactions } });
    } catch (e) {}

    setLoading(false);
  };

  return { data, loading };
}
