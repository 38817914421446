import React, { useState } from 'react';
import { Card, Stack, Typography, Box, MenuItem, TextField, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import {
  FormProvider,
  RHFEditor,
  RHFNumberField,
  RHFSelect,
  RHFSwitch,
  RHFTextField,
} from '../../../../components/hookForm';
import usePopupMobileForm from './hooks/usePopupMobileForm';
import usePopupMobileSubmit from './hooks/usePopupMobileSubmit';
import Iconify from '../../../../components/Iconify';
import Image from '../../../../components/Image';
import { base64ToImage, imageToBase64 } from '../../../../utils';
import { useEffect } from 'react';

const LINKS = [
  {
    label: 'SCREEN_TRADE',
    value: 'SCREEN_TRADE',
  },
  {
    label: 'SCREEN_OTC',
    value: 'SCREEN_OTC',
  },
  {
    label: 'SCREEN_P2P',
    value: 'SCREEN_P2P',
  },
  {
    label: 'SCREEN_E_MONEY',
    value: 'SCREEN_E_MONEY',
  },
  {
    label: 'SCREEN_WALLET',
    value: 'SCREEN_WALLET',
  },

  {
    label: 'SCREEN_DASHBOARD',
    value: 'SCREEN_DASHBOARD',
  },

  {
    label: 'SCREEN_TRADE',
    value: 'SCREEN_TRADE',
  },

  {
    label: 'SCREEN_TRADE/FUTURES —— > params: {tab: 1}',
    value: 'SCREEN_TRADE/FUTURES —— > params: {tab: 1}',
  },

  {
    label: 'SCREEN_P2P',
    value: 'SCREEN_P2P',
  },
  {
    label: 'SCREEN_E_MONEY',
    value: 'SCREEN_E_MONEY',
  },
  {
    label: 'SCREEN_WALLET',
    value: 'SCREEN_WALLET',
  },
  {
    label: 'SCREEN_MARKET',
    value: 'SCREEN_MARKET',
  },
  {
    label: 'SCREEN_CREDIT_CARD',
    value: 'SCREEN_CREDIT_CARD',
  },
  {
    label: 'SCREEN_ACCOUNT_SETTINGS',
    value: 'SCREEN_ACCOUNT_SETTINGS',
  },
  {
    label: 'SCREEN_PERSONAL_VERIFICATION',
    value: 'SCREEN_PERSONAL_VERIFICATION',
  },
  {
    label: 'SCREEN_HISTORY',
    value: 'SCREEN_HISTORY',
  },

  {
    label: 'SCREEN_OTC',
    value: 'SCREEN_OTC',
  },

  {
    label: 'SCREEN_TICKET',
    value: 'SCREEN_TICKET',
  },

  {
    label: 'SCREEN_REFERRAL',
    value: 'SCREEN_REFERRAL',
  },

  {
    label: 'SCREEN_CONVERT',
    value: 'SCREEN_CONVERT',
  },
];

const MobileCard = ({ data }) => {
  const { t } = useTranslation();
  const { handleSubmit, methods, isActive } = usePopupMobileForm(data);
  const { onSubmit, isLoading } = usePopupMobileSubmit();
  const [attach, setAttach] = useState();
  const [image, setImage] = useState();

  useEffect(() => {
    (async () => {
      let src = data?.messageImage;
      if (attach) {
        src = await imageToBase64(attach);
      }
      const img = document.createElement('img');
      img.src = base64ToImage(src);

      setImage(img.src);
    })();
  }, [attach, data?.messageImage]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit((data) => onSubmit(data, attach))}>
      <Card sx={{ mt: 5, px: { xs: 2, sm: 4 }, py: { xs: 3, sm: 4 } }}>
        <Typography variant='subtitle3' sx={{ mt: 1 }}>
          {t('Mobile Advertisement Setting')}
        </Typography>

        <Stack direction='row' alignItems={'center'} spacing={1} sx={{ mt: 2.25 }}>
          <RHFSwitch name='active' />
          <Typography variant='body3'>{isActive  ? t('Active') : t('Disable')}</Typography>
        </Stack>

        <Box sx={{ width: { xs: '100%', md: '45%', lg: '25%' }, mt: 2.25 }}>
          <RHFSelect
            name='popupType'
            SelectProps={{
              native: false,
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' } },
              },
            }}
            label={t('Popup link')}
            variant='standard'
            fullWidth
          >
            {LINKS.map((link) => (
              <MenuItem value={link.value}>
                <Typography typography='body3' color='grey.300'>
                  {t(link.label)}
                </Typography>
              </MenuItem>
            ))}
          </RHFSelect>
        </Box>

        <Box sx={{ width: { xs: '100%', md: '45%', lg: '25%' }, mt: 2.25 }}>
          <RHFNumberField name='showDays' label={t('Show how many days?')} variant='standard' fullWidth />
        </Box>

        <Box sx={{ width: { xs: '100%', md: '60%' }, mt: 2.75 }}>
          <RHFEditor name='value' />
        </Box>
        <Stack direction={'row'} spacing={1} alignItems='center' mt={5}>
          <IconButton component='label' sx={{ backgroundColor: 'grey.600', width: 52, height: 52 }}>
            <Iconify icon={'fluent:attach-24-regular'} color='grey.0' sx={{ width: 17, height: 17 }} />
            <TextField
              type='file'
              inputProps={{ accept: 'image/*' }}
              hidden
              onChange={(e) => setAttach(e.target?.files?.[0])}
            />
          </IconButton>
          <Stack>
            <Image src={image} sx={{ width: '100%', height: 'auto', maxWidth: '250px' }} />
            {attach && (
              <Stack direction='row' alignItems={'center'} sx={{ pl: 2 }} spacing={1}>
                <IconButton sx={{ p: 0 }} onClick={() => setAttach(null)}>
                  <Iconify icon='eva:close-outline' color='error.main' sx={{ width: 15, height: 15 }} />
                </IconButton>
                <Typography variant='body4' color='grey.300' sx={{ pt: 0.1 }}>
                  {attach?.name || ''}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack direction={'row'} spacing={1} alignItems='center' mt={5}>
          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            loading={isLoading}
            sx={{ maxWidth: 283 }}
          >
            {t('Save')}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
};

export default MobileCard;
