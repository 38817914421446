import React, { useState } from 'react';
import { Stack, Table, TableBody, TableContainer, Box, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

// import { PageTitle } from '../../../../../components/PageTitle';
import CryptoTransactionToolbar from './cryptoTransactionComponents/cryptoTransactionToolbar';
import Scrollbar from '../../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../../components/table';
import useTable from '../../../../../hooks/useTable';
import CryptoTransactionTableRow from './cryptoTransactionComponents/cryptoTransactionTableRow';
import CryptoTransactionDetailModal from './cryptoTransactionComponents/cryptoTransactionDetailModal';
import useCryptoTransactionInit from './cryptoTransactionComponents/hooks/useCryptoTransactionInit';
// ===================================================================

const TABLE_HEAD = [
  { id: 'num', label: 'Num', align: 'center' },
  { id: 'symbol', label: 'Symbol', align: 'left' },
  { id: 'amount', label: 'Amount', align: 'center' },
  { id: 'createDate', label: 'Create Date', align: 'center' },
  { id: 'transactionType', label: 'Transaction Type', align: 'center' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'detail', label: 'Detail', align: 'center' },
];

// ===================================================================

const CryptoTransaction = ({ userId }) => {
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [modalDetailData, setModalDetailData] = useState({});

  const { t } = useTranslation();
  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  const {
    status,
    typeFilter,
    setTypeFilter,
    depositStatusFilter,
    setDepositStatusFilter,
    withdrawStatusFilter,
    setWithdrawStatusFilter,
    dateFromFilter,
    setDateFromFilter,
    dateToFilter,
    setDateToFilter,
    transactionIdFilter,
    setTransactionIdFilter,
    onFilter,
    tableData,
    tablePagination,
    isExportLoading,
    onExport,
  } = useCryptoTransactionInit(page, rowsPerPage, userId);

  return (
    <>
      <Stack>
        {/* <PageTitle title='Crypto Transaction' /> */}
        <CryptoTransactionToolbar
          typeFilter={typeFilter}
          setTypeFilter={setTypeFilter}
          depositStatusFilter={depositStatusFilter}
          setDepositStatusFilter={setDepositStatusFilter}
          withdrawStatusFilter={withdrawStatusFilter}
          setWithdrawStatusFilter={setWithdrawStatusFilter}
          dateFromFilter={dateFromFilter}
          setDateFromFilter={setDateFromFilter}
          dateToFilter={dateToFilter}
          setDateToFilter={setDateToFilter}
          transactionIdFilter={transactionIdFilter}
          setTransactionIdFilter={setTransactionIdFilter}
          onFilter={onFilter}
          isExportLoading={isExportLoading}
          onExport={onExport}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size='small'>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length || 0}
                onSort={onSort}
              />
              <TableBody>
                {tableData === null ? (
                  <TableLoading isLoading />
                ) : (
                  <>
                    {tableData?.map((row, index) => (
                      <CryptoTransactionTableRow
                        key={row.id}
                        row={row}
                        index={index}
                        onShowModalDetail={() => {
                          setShowModalDetail(true);
                          setModalDetailData(row);
                        }}
                        status={status}
                      />
                    ))}

                    <TableNoData isNotFound={!tableData?.length} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component='div'
            count={tablePagination.totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
          />
        </Box>
      </Stack>

      <CryptoTransactionDetailModal
        open={showModalDetail}
        onClose={() => setShowModalDetail(false)}
        data={modalDetailData}
        status={modalDetailData?.status}
      />
    </>
  );
};

export default CryptoTransaction;
