import React,{ useEffect, useState } from 'react';
import { Table, TableBody, TableContainer, TablePagination, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useTable, { getComparator } from '../../../../hooks/useTable';
import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../components/table';
import WalletTableRow from './walletTableRow';
import { applySortFilter } from '../../../../utils';
import FutureTransferModal from '../../futuresWallet/FutureTransferModal';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'symbol', label: 'Coin', align: 'left' },
  { id: 'amount', label: 'Total', align: 'left' },
  { id: 'available', label: 'Available', align: 'left' },
  { id: 'blocked', label: 'In Order', align: 'left' },
  { id: 'usdtValue', label: 'BTC Value', align: 'left' },
  { id: 'action', label: 'Action', align: 'left', showSearchBox: true, visiblePriceButton: true },
];

// ----------------------------------------------------------------------

export default function WalletTable({ dataSource, valueVisible = false, setValueVisible }) {
  const { t } = useTranslation();

  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: 'usdtValue',
  });

  console.log(page)
  const [tableData, setTableData] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [dataFiltered, setDataFiltered] = useState([]);
  const [openTransfer , setOpenTransfer] = useState(false)
  useEffect(() => {
    if (dataSource?.length > 0) {
      setTableData(dataSource);
    }
  }, [dataSource]);

  // Filtered
  useEffect(() => {
    if (!tableData) return;

    let filtered = applySortFilter({
      tableData,
      comparator: getComparator(order, orderBy),
    });

    // filtered = filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    filtered = filtered.filter((item) => item.name.toLowerCase().indexOf(searchValue.toLowerCase().trim()) !== -1 || item.symbol.toLowerCase().indexOf(searchValue.toLowerCase().trim()) !== -1);

    setDataFiltered(filtered);
  }, [order, orderBy, tableData, page, rowsPerPage, searchValue]);

  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ position: 'relative' }}>
          <Table size={'small'}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={dataFiltered?.length || 0}
              onSort={onSort}
              onSearch={setSearchValue}
              onVisiblePrice={() => setValueVisible(!valueVisible)}
              visiblePrice={valueVisible}
            />

            <TableBody>
              {tableData === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <WalletTableRow key={index} row={row} valueVisible={valueVisible} setOpenTransfer={()=>setOpenTransfer(true)}/>
                  ))}

                  <TableNoData isNotFound={!dataFiltered.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      {dataFiltered?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 200]}
          component='div'
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage={t('Rows per page')}
          labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
        />
      )}
      <FutureTransferModal  open={openTransfer} onClose={()=>setOpenTransfer(false)}  />

    </Card>
  );
}
