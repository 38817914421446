import { useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { FaStar } from 'react-icons/fa';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import classesDark from '../trade.module.scss';
import classesLight from '../trade-light.module.scss';

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

  return (
    <IoIosArrowBack
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
      style={{ marginRight: '5px', marginLeft: '10px' }}
      className={classesDark.arrowButton}
    />
  );
}
function RightArrow() {
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

  return (
    <IoIosArrowForward
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
      style={{ marginLeft: '5px', marginRight: '10px' }}
      className={classesDark.arrowButton}
    />
  );
}

export default function PairFavoriteScroll({ favoritList, onHorizentalListItemClick }) {
  const theme = useTheme();
  const classes = theme.palette.mode === 'dark' ? classesDark : classesLight;

  const [selected, setSelected] = useState('');

  const onAction = (item) => {
    if (selected === item || selected === item?.coin) {
      setSelected('');
      onHorizentalListItemClick('');
      return;
    }

    setSelected(item === 'favorite' ? 'favorite' : item.coin);
    onHorizentalListItemClick(item);
  };

  return (
    <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} alignCenter={true}>
      <button
        onClick={() => onAction('favorite')}
        className={classes.horizentalListItem}
        style={{
          marginTop: 2,
          marginRight: 10,
          color: selected === 'favorite' ? '#efba0b' : '#a5adcf',
        }}
      >
        <FaStar />
      </button>

      {favoritList.map((item) => {
        return (
          <button
            onClick={() => onAction(item)}
            className={classes.horizentalListItem}
            style={{ color: selected === item.coin ? '#19DAB6' : '#a5adcf' }}
          >
            {item.coin}
          </button>
        );
      })}
    </ScrollMenu>
  );
}
