import { useState } from 'react';
import { IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { faIR, enUS , ruRU ,arSA , trTR ,kuCKB ,hyAM } from '@mui/material/locale';

import Image from '../Image';
import MenuPopover from '../MenuPopover';
import Iconify from '../Iconify';
import { alpha, useTheme } from '@mui/system';

import enFlag from '../../assets/flags/ic_flag_en.svg';
import faFlag from '../../assets/flags/ic_flag_fa.png';
import ruFlag from '../../assets/flags/rusi.jpeg';
import trFlag from '../../assets/flags/turkey.png';
import arFlag from '../../assets/flags/ar.png';
import kuFlag from '../../assets/flags/ku-flag.png';
import armFlag from '../../assets/flags/armani.png'
import { useDispatch, useSelector } from 'react-redux';
import { SET_THEME_DIRECTION, SET_LANGUAGE } from '../../store/actionTypes';
import { useTranslation } from 'react-i18next';
import { showLoading } from '../../utils';

// ----------------------------------------------------------------------

const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: enFlag,
    dir: 'ltr',
  },
  {
    label: 'فارسی',
    value: 'fa',
    systemValue: faIR,
    icon: faFlag,
    dir: 'rtl',
  },
  {
    label: 'Russian',
    value: 'ru',
    systemValue: ruRU,
    icon: ruFlag,
    dir: 'ltr',
  },
  {
    label: 'عربی',
    value: 'ar',
    systemValue: arSA,
    icon: arFlag,
    dir: 'rtl',
  },
  {
    label: 'turkey',
    value: 'tr',
    systemValue: trTR,
    icon: trFlag,
    dir: 'ltr',
  },
  {
    label: 'کوردی',
    value: 'ku',
    systemValue: kuCKB,
    icon: kuFlag,
    dir: 'rtl',
  },
  {
    label: 'Armenian',
    value: 'arm',
    systemValue: hyAM,
    icon: armFlag,
    dir: 'ltr',
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover({ lightIcon = true ,mobile }) {
  const [open, setOpen] = useState(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const language = useSelector((store) => store.language);
  const { i18n } = useTranslation();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onSelect = ({ value, dir }) => {
    showLoading(1000);

    handleClose();

    setTimeout(() => {
      dispatch({ type: SET_LANGUAGE, payload: value });
      dispatch({ type: SET_THEME_DIRECTION, payload: dir });
      i18n.changeLanguage(value);
      localStorage.setItem('i18nextLng', value);
      localStorage.setItem('dir', dir);
    }, 100);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && { bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8) }),
        }}
      >
        <Iconify icon='iconoir:language' color={lightIcon && 'grey.0'} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          background: theme.palette.grey[800],
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {allLangs.map((option) => (
            <MenuItem key={option.value} selected={option.value === language} onClick={() => onSelect(option)}>
              <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

              <Typography variant='body3' sx={{ ...(option.value === 'fa' && { fontFamily: 'IranSans' }) }}>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
