import { Button, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ComplexToolbar from '../../../components/ComplexToolbar';
import DatePicker from '../../../components/DatePicker';
import Image from '../../../components/Image';
import { CARD_TYPE, CONDITION } from './bankCardTable';

export default function BankCardToolbar({
  bankFilter,
  setBankFilter,
  conditionFilter,
  setConditionFilter,
  dateFromFilter,
  setDateFromFilter,
  dateToFilter,
  setDateToFilter,
  onFilter,
  onExport,
  isExportLoading,
}) {
  const { t } = useTranslation();

  const onReset = async () => {
    setBankFilter('');
    setConditionFilter('WAITING');
    setDateFromFilter(null);
    setDateToFilter(null);
    onFilter(false);
  };

  return (
    <>
      <ComplexToolbar
        onPrint={() => console.log('print')}
        onExport={onExport}
        isExportLoading={isExportLoading}
        // onSearch={(v) => console.log(v)}
      >
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} sx={{ mt: 2 }} alignItems={'center'}>
          <Stack direction={'row'}>
            <DatePicker
              label={t('From')}
              value={dateFromFilter}
              setValue={setDateFromFilter}
              variant='standard'
              maxDate={dateToFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
              InputProps={null}
            />
            <DatePicker
              label={t('To')}
              value={dateToFilter}
              setValue={setDateToFilter}
              variant='standard'
              minDate={dateFromFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
            />
          </Stack>

          <TextField
            select
            label={t('Bank')}
            variant='standard'
            fullWidth
            value={bankFilter}
            onChange={(e) => setBankFilter(e.target.value)}
            sx={{ maxWidth: 300 }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            {CARD_TYPE.map((type) => (
              <MenuItem value={type.value}>
                <Stack direction='row' spacing={1} alignItems={'center'}>
                  <Image src={type.icon} />
                  <Typography typography='body3' color='grey.300'>
                    {t(type.label)}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label={t('Condition')}
            variant='standard'
            fullWidth
            value={conditionFilter}
            onChange={(e) => setConditionFilter(e.target.value)}
            sx={{ maxWidth: 300 }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            {CONDITION.map((type) => (
              <MenuItem value={type.value}>
                <Stack direction='row' spacing={1} alignItems={'center'}>
                  <Typography typography='body3' color='grey.300'>
                    {t(type.label)}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 5 }} sx={{ mt: 5, mb: 5.75 }}>
          <Button
            onClick={onFilter}
            fullWidth
            variant='contained'
            sx={{ maxWidth: 300, typography: 'body3', fontWeight: 500 }}
          >
            {t('Filter')}
          </Button>

          <Button
            onClick={onReset}
            fullWidth
            variant='outlined'
            color='inherit'
            sx={{ maxWidth: 80, typography: 'body3', fontWeight: 500, borderColor: 'grey.400' }}
          >
            {t('Reset')}
          </Button>
        </Stack>
      </ComplexToolbar>
    </>
  );
}
