import { Box, Button, Card, Divider, Grid, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router';

import Image from '../../../components/Image';
import { SET_PAGE_STATE } from '../../../store/actionTypes';
import iconSampleWalletRial from '../../../assets/sampleWalletIconGreen.svg';
import iconSampleWalletUSD from '../../../assets/sampleWalletIconPurple.svg';
import FillSelect from '../../../components/FillSelect';
import SmallAreaChart from '../../../components/SmallAreaChart';
import { FormProvider, RHFNumberField, RHFSlider, RHFTextField } from '../../../components/hookForm';
import useWallet from '../../../hooks/useWallet';
import LoadingScreen from '../../../components/LoadingScreen';
import Api from '../../../http/serviceApi';
import { navigationUrl } from '../../../navigationUrl';
import WithdrawTable from './withdrawTable';

export default function FiatWithdraw() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isIranianPaymentActive = useSelector((store) => store.isIranianPaymentActive);

  // ---------------------------
  const { userWallet, loading } = useWallet();

  // ---------------------------
  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Fiat Withdraw' },
    });
  }, []);

  // ---------------------------
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [bankCardList, setBankCardList] = useState([]);

  // ---------------------------
  const validators = Yup.object().shape({
    amount: Yup.string().required(t('Please input the value!')),
    cardId: Yup.string().required(t('Please select a bank card!')),
  });

  // ---------------------------
  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues: {
      amount: '',
      cardId: '',
    },
  });

  // ---------------------------
  const {
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting },
  } = methods;

  // ---------------------------
  const onSubmit = async (payload) => {
    try {
      const response = await Api.requestWithdrawFiat(
        payload.cardId.replace(/\s/g, ''),
        +payload.amount.replace(',', ''),
        selectedCurrency
      );

      if (response?.status === 'SUCCESS') {
        enqueueSnackbar(t('Withdrawal request successfully submitted'), { variant: 'success' });
        reset();
      }
    } catch (e) {}
  };

  // ---------------------------
  useEffect(() => {
    getBankCard();
  }, []);

  // ---------------------------
  const getBankCard = async () => {
    const response = await Api.getBankCard();
    if (response) {
      setBankCardList(response.bankCardInfos);
    }
  };

  // ---------------------------
  if (loading) {
    return <LoadingScreen />;
  }

  // ---------------------------
  return (
    <Box>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={8}>
          <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 } }}>
            <Stack direction='row' justifyContent={'space-between'} sx={{ mb: 3.75 }}>
              <Stack direction='row' spacing={1.5}>
                <Stack
                  justifyContent={'center'}
                  alignItems={'center'}
                  sx={{
                    backgroundColor: selectedCurrency === 'USD' ? 'rgba(189, 71, 251, 0.1)' : 'rgba(114, 220, 96, 0.1)',
                    width: 48,
                    height: 48,
                    borderRadius: '100%',
                  }}
                >
                  <Image src={selectedCurrency === 'USD' ? iconSampleWalletUSD : iconSampleWalletRial} alt='' />
                </Stack>
                <Stack>
                  <Typography variant='h6' sx={{ fontWeight: 500 }}>
                    {selectedCurrency === 'USD' ? userWallet.dollar : userWallet.rial}
                  </Typography>
                  <Typography variant='body3' color='grey.100'>
                    {t('Your inventory')}
                  </Typography>
                </Stack>
              </Stack>

              <FillSelect
                value={selectedCurrency}
                onChange={setSelectedCurrency}
                sx={{ minWidth: 145, '& .MuiSelect-select': { color: 'grey.0' } }}
              >
                {isIranianPaymentActive && (
                  <MenuItem value={'IRR'} sx={{ typography: 'body3' }}>
                    {t('Rial')}
                  </MenuItem>
                )}
                <MenuItem value={'USD'} sx={{ typography: 'body3' }}>
                  {t('USD')}
                </MenuItem>
              </FillSelect>

              <SmallAreaChart
                data={[17, 25, 23, 20, 25, 20, 15, 18, 14, 10, 26, 21, 20, 28, 35, 30, 32, 28, 27, 20]}
                isPositive
                width={137}
                height={38}
              />
            </Stack>

            <Box sx={{ maxWidth: 440 }}>
              <Divider />

              <Typography variant='h6' sx={{ mt: 2.5, fontWeight: 500 }}>
                {t('Withdrawal of wallet')}
              </Typography>

              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <RHFNumberField
                  name='amount'
                  label='the amount of'
                  fullWidth
                  variant='standard'
                  sx={{ mt: 5 }}
                  thousandSeparator
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Typography color='grey.100' variant='body2'>
                          {selectedCurrency}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />

                <RHFSlider
                  name='amount'
                  valueLabelDisplay='auto'
                  color='success'
                  size='small'
                  step={10}
                  marks
                  min={0}
                  max={selectedCurrency === 'USD' ? userWallet.dollar : userWallet.rial}
                  sx={{ mt: 1.5 }}
                />

                <Stack direction={'row'} spacing={0.5} sx={{ mt: 2.8, mb: 1.5 }}>
                  <Typography variant='subtitle4' color={'secondary'}>
                    #{watch('amount') || 0}
                  </Typography>
                  <Typography variant='subtitle4' color={'grey.400'}>
                    {t('Your recoverable inventory:')}
                  </Typography>
                </Stack>

                <Typography variant='subtitle4' color={'grey.400'}>
                  {t('Equivalent daily withdrawal amount: 0 out of 500,000,000 Tomans')}
                </Typography>

                <RHFTextField
                  name='cardId'
                  label='Bank account to receive'
                  select
                  fullWidth
                  variant='standard'
                  sx={{ mt: 3 }}
                  helperText={t('You will see a list of your approved bank accounts')}
                  SelectProps={{
                    MenuProps: {
                      sx: { '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' } },
                    },
                  }}
                >
                  {bankCardList
                    .filter((card) => {
                      if (selectedCurrency === 'USD') return card.cardType !== 'SHETAB_CARD';
                      return card.cardType === 'SHETAB_CARD';
                    })
                    .map((card, index) => (
                      <MenuItem key={index} value={card.cardNumber}>
                        <Typography variant='body2'>
                          {card.cardNumber
                            .replace(/[^\dA-Z]/g, '')
                            .replace(/(.{4})/g, '$1 ')
                            .trim()}
                        </Typography>
                      </MenuItem>
                    ))}
                </RHFTextField>

                <Stack direction='row' sx={{ mt: 6.25 }} spacing={2}>
                  <LoadingButton
                    size='large'
                    type='submit'
                    variant='contained'
                    loading={isSubmitting}
                    sx={{ width: '60%', typography: 'body3' }}
                  >
                    {t('Submit')}
                  </LoadingButton>

                  <Button
                    onClick={() => navigate(navigationUrl.wallet)}
                    variant='outlined'
                    color='inherit'
                    type='button'
                    sx={{ width: '40%', typography: 'body3' }}
                  >
                    {t('Back')}
                  </Button>
                </Stack>
              </FormProvider>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 }, height: '100%' }}>
            <Typography variant='h6' fontWeight={500} sx={{ mb: 2 }}>
              {t('Tips')}
            </Typography>

            <Stack spacing={3}>
              <Typography variant='body3' color={'grey.100'} sx={{ width: 323 }}>
                {t(
                  'In order to deposit money, you must use bank cards in your own name, which are registered and approved in the user section.'
                )}
              </Typography>
              <Typography variant='body3' color={'grey.100'} sx={{ width: 323 }}>
                {t(
                  'When determining the deposit amount, make sure that the minimum purchase and sale amount in the exchange is $ 11.'
                )}
              </Typography>
            </Stack>
          </Card>
        </Grid>
      </Grid>

      <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 }, mt: 2 }}>
        <Typography sx={{ mb: 2, fontWeight: 500 }} variant='h6'>
          {t('List of withdrawals')}
        </Typography>
        <Typography variant='body3' color={'grey.200'}>
          {t('You can enter the amount, transaction ID, etc. in the search field.')}
        </Typography>

        <Box sx={{ mt: 5 }}>
          <WithdrawTable />
        </Box>
      </Card>
    </Box>
  );
}
