import { Box, Card, Divider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';

import P2PSection from './p2pSection';
import P2PFilter from './p2pFilter';
import P2PTable from './p2pTable';
import { fiatList, symbolList } from '../request/p2pTab';
import Api from '../../../../http/serviceApi';

export default function P2PList() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'P2P' },
    });
  }, []);

  const [sideFilter, setSideFilter] = useState('BUY');
  const [symbolFilter, setSymbolFilter] = useState(symbolList[0].label);
  const [fiatFilter, setFiatFilter] = useState(fiatList[0].label);
  const [paymentFilter, setPaymentFilter] = useState('ALL');
  const [amountFilter, setAmountFilter] = useState('');
  const [tableData, setTableData] = useState(null);
  const [filtered, setFiltered] = useState(null);

  const fetchAllPendingOrders = async () => {
    setTableData(null);

    try {
      const data = await Api.getAllPendingP2POrders(sideFilter, symbolFilter);
      if (data.status === 'SUCCESS') {
        const list = data.p2pOrders.map((order) => ({
          ...order,
          payment: 'WALLET',
          side: order.side === 'BUY' ? 'BUY' : 'SELL',
        }));

        setTableData(list);
      }
    } catch (e) {}
  };

  useEffect(() => {
    fetchAllPendingOrders();
  }, [sideFilter, symbolFilter]);

  useEffect(() => {
    if (tableData) {
      let filtering = tableData;

      if (amountFilter) {
        filtering = filtering.filter((d) => d.remainderAmount === +amountFilter);
      }

      if (paymentFilter !== 'ALL') {
        filtering = filtering.filter((d) => d.payment === paymentFilter);
      }

      setFiltered(filtering);
    }
  }, [tableData, amountFilter, paymentFilter]);

  console.log(sideFilter)
  return (
    <Card sx={{ p: 0, mx: { xs: 2, md: 0 } }}>
      <P2PSection
        sideFilter={sideFilter}
        setSideFilter={setSideFilter}
        symbolFilter={symbolFilter}
        setSymbolFilter={setSymbolFilter}
      />

      <Divider />

      <P2PFilter
        onRefresh={fetchAllPendingOrders}
        onSearch={setAmountFilter}
        fiatFilter={fiatFilter}
        setFiatFilter={setFiatFilter}
        paymentFilter={paymentFilter}
        setPaymentFilter={setPaymentFilter}
      />

      <Box sx={{ mx: { xs: 3, md: 5 }, mb: 4 }}>
        <P2PTable tableData={filtered} onReload={fetchAllPendingOrders} />
      </Box>
    </Card>
  );
}
