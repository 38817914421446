export const TRANSFER_METHODS = [
  {
    label: 'Card to card',
    value: 'CARD_TO_CARD',
  },
  {
    label: 'Deposit to account',
    value: 'DEPOSIT_TO_ACCOUNT',
  },
  {
    label: 'Shetab',
    value: 'SHETAB',
  },
];
