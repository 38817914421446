import { useEffect } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function usePaySafeForm(currentData) {
  const { t } = useTranslation();

  const validators = Yup.object().shape({
    // userName: Yup.string().notRequired(),
    password: Yup.string().notRequired(),
    query: Yup.boolean().notRequired(),
    purchasePrice: Yup.string().notRequired(),
    sellingPrice: Yup.string().notRequired(),
    buyPercentage: Yup.string().notRequired(),
    salePercentage: Yup.string().notRequired(),
    accountID: Yup.string().notRequired(),
    purchaseStatus: Yup.boolean().notRequired(),
    saleStatus: Yup.boolean().notRequired(),
    purchasePricePercentage: Yup.string().notRequired(),
    salePricePercentage: Yup.string().notRequired(),
  });

  const defaultValues = {
    // userName: currentData?.userName || '',
    accountID: currentData?.accountId || '',
    password: currentData?.password || '',
    walletId: currentData?.walletId || '',
    queryPerfectMoneyPrice: currentData?.queryPerfectMoneyPrice || '',
    purchaseDollarPrice: currentData?.purchaseDollarPrice || '',
    saleDollarPrice: currentData?.saleDollarPrice || '',
    purchasePercentage: currentData?.purchasePercentage || '',
    salePercentage: currentData?.salePercentage || '',
    purchaseStatus: currentData?.purchaseStatus || '',
    saleStatus: currentData?.saleStatus || '',
    purchasePercentageMarketing: currentData?.purchasePercentageMarketing || '',
    salePercentageMarketing: currentData?.salePercentageMarketing || '',
    voucherPurchasePercentage: currentData?.voucherPurchasePercentage || '',
    voucherPurchaseStatus: currentData?.voucherPurchaseStatus || '',
    voucherSalePercentage: currentData?.voucherSalePercentage || '',
    voucherSaleStatus: currentData?.voucherSaleStatus || '',
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    getValues,
    reset,
    handleSubmit,
    formState: { isSubmitting },
    watch ,
  } = methods;

  useEffect(() => {
    if (currentData) {
      reset(defaultValues);
    }
    if (!currentData) {
      reset(defaultValues);
    }
  }, [currentData]);

  return {
    handleSubmit,
    isSubmitting,
    methods,
    getValues,
    watch
  };
}
