const ru = {
    translation: {
        "Cryptizi": "Криптизи",
  "Hi, Welcome Back": "Привет, с возвращением",
  "Nice to meet you!": "Рад встрече с вами!",
  "Best Wallet For Your Coins": "Лучший кошелек для ваших монет",
  "Dashboard": "Панель управления",
  "Wallet": "Кошелек",
  "{{number}} Currencies": "{{number}} валют",
  "Sign up": "Регистрация",
  "Log in": "Вход",
  "Search": "Поиск",
  "No": "Нет",
  "Yes": "Да",
  "Balance": "Баланс",
  "Income": "Доход",
  "Expenses": "Расходы",
  "Exchange": "Обмен",
  "Top Assets": "Топ активы",
  "Transaction": "Транзакция",
  "All": "Все",
  "Receive": "Получить",
  "Send": "Отправить",
  "Overall Growth": "Общий рост",
  "Open trade": "Открытая торговля",
  "Market": "Рынок",
  "Trade": "Торговля",
  "P2P": "P2P",
  "E-Money": "Электронные деньги",
  "Convert": "Конвертировать",
  "Crypto": "Криптовалюта",
  "Futures": "Фьючерсы",
  "Possibilities": "Возможности",
  "Referrals & Income": "Рефералы и доход",
  "Ticket": "Тикет",
  "History": "История",
  "Authentication": "Аутентификация",
  "Bank Card": "Банковская карта",
  "Account settings": "Настройки аккаунта",
  "Academy": "Академия",
  "Security": "Безопасность",
  "Identification": "Идентификация",
  "Referral": "Реферальный",
  "Verified": "Подтверждено",
  "Regular user": "Обычный пользователь",
  "Log out": "Выход",
  "Add New favorite": "Добавить новое избранное",
  "Total Balance": "Общий баланс",
  "30 Days PNL": "PNL за 30 дней",
  "Today PNL": "PNL за сегодня",
  "Withdraw": "Вывод средств",
  "Deposit": "Пополнение",
  "Your inventory": "Ваш инвентарь",
  "Rial": "Риал",
  "Coin": "Монета",
  "Total": "Итого",
  "Available": "Доступно",
  "In Order": "В порядке",
  "BTC Value": "Стоимость BTC",
  "Action": "Действие",
  "Buy": "Купить",
  "Rows per page": "Строк на странице",
  "of": "из",
  
  "All Situations": "Все ситуации",
  "History of your orders or transactions": "История ваших заказов или транзакций",
  "You can enter the name and symbol of Coin, transaction ID, etc. in the search field":
    "Вы можете ввести название и символ монеты, ID транзакции и т.д. в поле поиска",
  "Spend": "Тратить",
  "Transaction requirements": "Требования к транзакции",
  "Convert ": "Конвертировать ",
  
  "last 1hr": "последний 1 час",
  "last 24hr": "последние 24 часа",
  "last week": "прошлая неделя",
  "last month": "прошлый месяц",
  
  "Crypto convert": "Крипто конвертер",
  "Futures Classic": "Фьючерсы Классик",
  "Token Listing": "Листинг токенов",
  "Total Sell": "Общая продажа",
  "APIs": "API",
  "iOS App": "Приложение для iOS",
  "Android App": "Приложение для Android",
  "Bank deposit": "Банковский депозит",
  "Credit/Debit card": "Кредитная/Дебетовая карта",
  "P2P trading": "P2P торговля",
  "Crytizi Convert": "Конвертер Криптизи",
  "Spot": "Спот",
  "Margin": "Маржа",
  "Markets": "Рынки",
  "Strategy Trading": "Стратегическая торговля",
  "About Us": "О нас",
  "Service": "Сервис",
  "More": "Еще",
  "Learn": "Учиться",
  "Community": "Сообщество",
  "About": "О нас ",
  "Contact us": "Свяжитесь с нами",
  "Terms": "Условия",
  "FAQ": "Часто задаваемые вопросы",
  "Token listing": "Листинг токенов",
  "Cryptizi Convert": "Конвертер Криптизи",
  "Buy/Sell Crypto": "Купить/Продать крипто",
  "USDⓈ-M Futures": "Фьючерсы USDⓈ-M",
  "Coin-M Futures": "Фьючерсы Coin-M",
  "Submit A request": "Отправить запрос",
  "Fees": "Комиссии",
  "Trading Rules": "Правила торговли",
  "Browse Crypto Prices": "Просмотреть цены на крипто",
  "Cryptizi Academy": "Академия Криптизи",
  "Sign Up": "Регистрация",
  
  "We are the most trusted cryptocurrency platform.": "Мы - самая надежная платформа для криптовалют.",
  
  "View more prices": "Посмотреть больше цен",
  
  "Build your crypto portfolio anywhere.": "Создавайте свой крипто-портфель где угодно.",
  
  "A powerful cryptocurrency exchange in your pocket. Buy, sell and trade crypto on the go.":
    "Мощная биржа криптовалют в вашем кармане. Покупайте, продавайте и торгуйте крипто в дороге.",
  
  "Get the Cryptizi Wallet Mobile App Now!": "Получите мобильное приложение Криптизи Кошелек сейчас!",
  
  "The most trusted cryptocurrency platform.": "Самая надежная платформа для криптовалют.",
  
  "Cryptizi has a variety of features that make it the best place to start trading.":
    "У Криптизи есть множество функций, которые делают ее лучшим местом для начала торговли.",
  
  "Buy and sell popular digital currencies, keep track of them in the one place.":
    "Покупайте и продавайте популярные цифровые валюты, отслеживайте их в одном месте.",
  
  "For added security, store your funds in a vault with time delayed withdrawals.":
    "Для дополнительной безопасности храните свои средства в хранилище с задержкой по времени при снятии.",
  
  "Stay on top of the markets with the Cryptizi app for Android or iOS.":
    "Оставайтесь на вершине рынков с приложением Криптизи для Android или iOS.",
  
  "Portofolio Manager": "Менеджер портфеля",
  "Mobile Apps": "Мобильные приложения",
  
  "One click, instant payout with credit or debit card.": "Один клик, мгновенный платеж с кредитной или дебетовой картой.",
  
  "Become a crypto owner in minutes using your debit or credit card and quickly purchase top cryptocurrencies.":
    "Станьте владельцем крипто за несколько минут, используя вашу дебетовую или кредитную карту, и быстро покупайте ведущие криптовалюты.",
  
  "Intermediate Verification": "Промежуточная верификация",
  "Intermediate Verified": "Промежуточная верификация подтверждена",
  "Additional Information": "Дополнительная информация",
  "OK": "ОК",
  "Upgrade to Higher Limits": "Увеличить лимиты",
  
  "New users can earn up to {{amount}} in crypto rewards.":
    "Новые пользователи могут заработать до {{amount}} в криптовалютных вознаграждениях.",
  
  "Ready to start trading cryptocurrency?": "Готовы начать торговать криптовалютой?",
  
  "Enter you email address": "Введите ваш адрес электронной почты",
  "Get Started": "Начать",
  
  "Your guide to the world of an open financial system. Get started with the easiest and most secure platform to buy and trade cryptocurrency.":
    "Ваш путеводитель по миру открытой финансовой системы. Начните с самой простой и безопасной платформы для покупки и торговли криптовалютой.",
  
  "A trusted and secure crypto currency exchange.": "Надежная и безопасная биржа криптовалют.",
  
  "Cryptizi ©. All rights reserved.": "Cryptizi ©. Все права защищены.",
  "Terms Of Use": "Условия использования",
  "Privacy Policy": "Политика конфиденциальности",
  "Assets": "Активы",
  "Last price": "Последняя цена",
  "Change": "Изменение",
  "Chart": "График",
  
  "Create an Account": "Создать аккаунт",
  "You are not a normal user!": "Вы не обычный пользователь!",
  "Forgot Password": "Забыли пароль",
  "Login": "Вход",
  "Please input your username!": "Пожалуйста, введите ваше имя пользователя!",
  "Please input your password!": "Пожалуйста, введите ваш пароль!",
  "Username": "Имя пользователя",
  "Password": "Пароль",
  "Remember me": "Запомнить меня",
  "You don't have permission to login!": "У вас нет разрешения на вход!",
  "Please input your first name!": "Пожалуйста, введите ваше имя!",
  "Please input your last name!": "Пожалуйста, введите вашу фамилию!",
  "Please input your email!": "Пожалуйста, введите вашу электронную почту!",
  "Please input your password": "Пожалуйста, введите ваш пароль",
  "Please input your confirm password!": "Пожалуйста, введите подтверждение вашего пароля!",
  "The confirm password does not match the password": "Подтверждение пароля не совпадает с паролем",
  "You must accept the terms and conditions!": "Вы должны принять условия и положения!",
  "I have read and agree to Cryptizi's Terms of Service and Privacy Policy.":
    "Я прочитал и согласен с Условиями обслуживания и Политикой конфиденциальности Cryptizi.",
  "Confirm Password": "Подтвердите Пароль",
  "Register": "Зарегистрироваться",
  "First Name": "Имя",
  "Last Name": "Фамилия",
  "Email": "Электронная почта",
  "Enter the email verification code!": "Введите код подтверждения по электронной почте!",
  "Enter the phone number verification code!": "Введите код подтверждения номера телефона!",
  "Enter the authenticator code!": "Введите код аутентификатора!",
  "Enter the {{number}}-digit code send to {{email}}": "Введите {{number}}-значный код, отправленный на {{email}}",
  "Email verification code": "Код подтверждения электронной почты",
  "Phone number verification code": "Код подтверждения номера телефона",
  "Enter the {{number}}-digit code send to {{phone}}": "Введите {{number}}-значный код, отправленный на {{phone}}",
  "Authenticator code": "Код аутентификации",

  "Enter the {{number}}-digit code from Google authenticator":
    "Введите {{number}}-значный код из аутентификатора Google",

  "Passwords must have at least 8 characters and contain at least one uppercase letters, lowercase letters, number and symbol.":
    "Пароли должны содержать не менее 8 символов и содержать как минимум одну заглавную букву, строчную букву, цифру и символ.",

  "You do not have a registered bank card": "У вас нет зарегистрированной банковской карты",
  "Card added successfully.": "Карта успешно добавлена.",
  "Add a bank card": "Добавить банковскую карту",
  "Type of Card": "Тип карты",
  "Register the card": "Зарегистрировать карту",
  "Please input the type of your card": "Пожалуйста, укажите тип вашей карты",
  "Done Successfully": "Успешно выполнено",
  "Account Number": "Номер счета",
  "CVV2": "CVV2",
  "Month of date": "Месяц",
  "Year of date": "Год",
  "Card Number": "Номер карты",
  "Please input the type of your card!": "Пожалуйста, укажите тип вашей карты!",
  "Please input your card number!": "Пожалуйста, введите номер вашей карты!",
  "Please input month of date!": "Пожалуйста, введите месяц!",
  "Please input 2-digits number": "Пожалуйста, введите двузначное число",
  "Please input year of date!": "Пожалуйста, введите год!",
  "Please input CVV2!": "Пожалуйста, введите CVV2!",
  "Please input your account number!": "Пожалуйста, введите номер вашего счета!",
  "Please input your Sheba number!": "Пожалуйста, введите ваш номер Шеба!",
  "Please input a valid Sheba number!": "Пожалуйста, введите действительный номер Шеба!",
  "Card Holder name": "Имя владельца карты",
  "Expiry date": "Дата истечения срока действия",

  "To insert a bank card, fill out the form below and note that the bank card must be in your name.":
    "Чтобы вставить банковскую карту, заполните форму ниже и обратите внимание, что банковская карта должна быть оформлена на ваше имя.",

  "A trusted and secure cryptocurrency exchange.": "Надежная и безопасная криптовалютная биржа.",
  "Vault Protection": "Защита хранилища",
  "Successful": "Успешно",
  "Learn & Earn": "Учиться и зарабатывать",
  "GET IT ON": "Получите это",
  "Google Play": "Google Play",
  "Download on the": "Скачать на",
  "App Store": "App Store",
  "Additional Infomation": "Дополнительная информация",
  "Countries": "Страны",
  "Traders": "Трейдеры",
  "Trading Pairs": "Торговые пары",

  "Are you sure to remove this symbol from your favorites list?":
    "Вы уверены, что хотите удалить этот символ из вашего списка избранного?",

  "Tips": "Советы",
  "Deposit via receipt": "Депозит через квитанцию",
  "Deposit to wallet": "Внести на кошелек",
  "Deposit payment gateway": "Платежный шлюз депозита",
  "Back": "Назад",
  "Go to payment": "Перейти к оплате",
  "Save": "Сохранить",

  "When determining the deposit amount, make sure that the minimum purchase and sale amount in the exchange is {{amount}}.":
    "Определяя сумму депозита, убедитесь, что минимальная сумма покупки и продажи на бирже составляет {{amount}}.",

  "In order to deposit money, you must use bank cards in your own name, which are registered and approved in the user section.":
    "Чтобы внести деньги, вы должны использовать банковские карты на свое имя, которые зарегистрированы и одобрены в разделе пользователя.",

  "Payment is only possible through approved accounts, otherwise your payment will be unsuccessful.":
    "Оплата возможна только через утвержденные счета, иначе ваш платеж будет неуспешным.",

  "Only for level {{level}} it is possible to deposit with a receipt.":
    "Внесение депозита через квитанцию возможно только для уровня {{level}}.",

  "We believe Cryptizi is here to stay - and that a future worth building is one which opens its doors and invites everyone in.":
    "Мы верим, что Cryptizi пришел, чтобы остаться - и что будущее, которое стоит строить, - это то, которое открывает свои двери и приглашает всех.",

  "Please input the repeat password!": "Пожалуйста, введите пароль повторно!",
  "The repeat password does not match the password": "Повторно введенный пароль не совпадает с паролем",
  "Repeat Password": "Повторите Пароль",
  "Successfully": "Успешно",
  "Your email has been successfully verified": "Ваш адрес электронной почты успешно подтвержден",
  "Verify Email": "Подтвердите адрес электронной почты",
  "Request Again": "Запросить снова",
  "Didn’t recieve code?": "Не получили код?",
  "Forgot password": "Забыли пароль",
  "The input is not a valid email!": "Введенный адрес электронной почты недействителен!",
  "Reset password": "Сбросить пароль",
  "Back to login": "Вернуться к входу",
  "No worries, we’ll send you reset instructions":
    "Не волнуйтесь, мы отправим вам инструкции по сбросу",
  "Submit": "Отправить",
  "Others": "Другие",
  "Fiat Deposit": "Фиатный депозит",
  "USD": "USD",

  "You can enter the amount, transaction ID, etc. in the search field.":
    "Вы можете ввести сумму, идентификатор транзакции и т. д. в поле поиска.",

  "List of Toman deposits and withdrawals": "Список томановских депозитов и снятий",
  "Please input the value!": "Пожалуйста, введите значение!",
  "Please select a bank card!": "Пожалуйста, выберите банковскую карту!",
  "You will see a list of your approved bank accounts": "Вы увидите список ваших утвержденных банковских счетов",
  "Num": "Номер",
  "Date": "Дата",
  "Creation Date": "Дата создания",
  "Type": "Тип",
  "Value": "Значение",
  "Inventory": "Инвентарь",
  "Condition": "Условие",
  "Description": "Описание",
  "details": "Детали",
  "Please input the tracking code!": "Пожалуйста, введите код отслеживания!",
  "Optional and to speed up transaction confirmation time": "Необязательно и для ускорения времени подтверждения транзакции",
  "select to deposit": "выбрать для депозита",
  "Payer": "Плательщик",
  "Tracking Code": "Код отслеживания",
  "Date and time of transaction": "Дата и время транзакции",
  "How to deposit": "Как внести депозит",
  "Receipt image (optional)": "Изображение квитанции (необязательно)",
  "Sell": "Продать",
  "USDT": "Тетер",
  "Stay tuned for more coin types!": "Оставайтесь на связи для большего количества типов монет!",
  "Enter Amount": "Введите сумму",
  "All Payment Methods": "Все способы оплаты",
  "Refresh": "Обновить",
  "Advertiser": "Рекламодатель",
  "Price": "Цена",
  "Available/Limits": "Доступно/Лимиты",
  "Payment Method": "Метод оплаты",
  "Order(s)": "Заказ(ы)",
  "Limits": "Лимиты",
  "Please select the currency!": "Пожалуйста, выберите валюту!",
  "Please input your wallet ID!": "Пожалуйста, введите ID вашего кошелька!",

  "Complete the following information to buy digital currency": "Заполните следующую информацию, чтобы купить цифровую валюту.",

  "Received money": "Полученные деньги",
  "Please input the value currency!": "Пожалуйста, введите валюту значения!",
  "Voucher Perfect Money": "Ваучер Perfect Money",
  "Price per unit of Perfect Money": "Цена за единицу Perfect Money",
  "You can also enter the amount in tomans": "Вы также можете ввести сумму в томанах",
  "Enter the relevant account ID": "Введите соответствующий идентификатор счета",
  "With description": "С описанием",
  "With security code": "С кодом безопасности",
  "Payment method": "Метод оплаты",
  "Visa/Mastercards": "Visa/Mastercard",
  "Shetab Card": "Карта Шетаб",
  "Fast Pay": "Быстрая оплата",

  "By charging the wallet, make your payment without going to the bank portal":
    "Пополнив кошелек, совершите платеж, не переходя на портал банка",

  "Pay": "Оплатить",
  "Select currency": "Выберите валюту",
  "Value currency": "Валюта стоимости",
  "Wallet ID": "ID кошелька",
  "One percent equals 0 Dollar": "Один процент равен 0 долларов",
  "The sales fee is deducted from the amount you receive": "Комиссия за продажу вычитается из полученной вами суммы",
  "Sales fee": "Комиссия за продажу",
  "Voucher Code": "Код ваучера",
  "Activation code": "Код активации",
  "The purchase fee will be added to your purchase amount": "Комиссия за покупку будет добавлена к сумме покупки",
  "Purchase fee": "Комиссия за покупку",
  "cryptizi convert": "конвертация cryptizi",
  "Guaranteed price": "Гарантированная цена",
  "Any pairs": "Любые пары",
  "Zero fee": "Нулевая комиссия",
  "Orders": "Заказы",
  "From": "От",
  "Spot wallet available": "Доступен спот-кошелек",
  "Please enter {{range}}": "Пожалуйста, введите {{range}}",
  "To": "К",
  "Please enter 20-42000000": "Пожалуйста, введите от 20 до 42000000",
  "Enter an amount": "Введите сумму",
  "Select Crypto": "Выберите криптовалюту",
  "Deposit Crypto": "Депозит криптовалюты",
  "Withdraw Crypto": "Вывод криптовалюты",
  "OTC": "OTC",
  "Please input coin!": "Пожалуйста, введите название монеты!",
  "Please input TXID!": "Пожалуйста, введите TXID!",
  "Deposit crypto": "Депозит криптовалюты",
  "Recent Deposit": "Последний депозит",
  "Bitcoin": "Биткойн",
  "Network": "Сеть",
  "Select Deposit network": "Выберите сеть депозита",
  "Copy": "Копировать",
  "Expected Arrival": "Ожидаемое поступление",
  "{{number}} Network Confirmations": "{{number}} подтверждений сети",
  "Expected Unlock": "Ожидаемая разблокировка",
  "Minimum deposit": "Минимальный депозит",
  "Selected wallet": "Выбранный кошелек",
  "Main wallet": "Основной кошелек",
  "change": "изменить",
  "Send Only {{symbol}} to this deposit address": "Отправляйте только {{symbol}} на этот депозитный адрес",
  "Ensure the network is": "Убедитесь, что сеть",
  "Do not send NFTs to this address.": "Не отправляйте NFT на этот адрес.",
  "learn how to deposit NFTs": "узнайте, как внести NFT",
  "submit": "отправить",
  "Video Tutorial": "Видеоурок",
  "How to Withdraw crypto step-by-step Guide": "Пошаговое руководство по выводу криптовалюты",
  "Why has my withdrawal Not Arrived?": "Почему мой вывод не прибыл?",
  "How to find My transaction ID (TxID)?": "Как найти мой ID транзакции (TxID)?",
  "how to recover My BEP-20 token?": "как восстановить мой токен BEP-20?",
  "Deposit & withdrawal status query": "Запрос статуса депозита и вывода",
  "Select coin": "Выбрать монету",
  "TXID": "TXID",
  "Scan the code on the withdrawal page of the trading platform APP or wallet App": "Сканируйте код на странице вывода средств в приложении торговой платформы или кошелька",
  "Send only {{symbol}} to this deposit address": "Отправляйте только {{symbol}} на этот депозитный адрес",
  "Learn how to deposit NFTs": "Узнайте, как внести NFT",
  "Unmatched networks automatically removed. Select a network below": "Неподходящие сети автоматически удаляются. Выберите сеть ниже",
  "SOL": "SOL",
  "Solana": "Солана",
  "TRX": "TRX",
  "Tron (TRC20)": "Трон (TRC20)",
  "Arrival time = {{min}} min": "Время прибытия = {{min}} мин",
  "fee": "комиссия",
  "BSC ": "BSC",
  "BNB Smart chain (BEP20)": "Умная цепочка BNB (BEP20)",
  "Fee rules": "Правила комиссии",
  "Select Network": "Выберите сеть",
  "Crypto Address": "Криптоадрес",
  "Completed": "Завершено",
  "Withdraw wallet": "Вывести кошелек",
  "Spot Wallet": "Спот-кошелек",
  "TxID": "TxID",
  "View All": "Посмотреть все",
  "Cryptizi user": "Пользователь Cryptizi",
  "Recent Withdrawals": "Последние выводы",
  "Select Withdrawal network": "Выберите сеть для вывода",
  "SQl Network Withdrawal suspended. withdrawals can be processed through alternative network.": "Вывод через сеть SQL приостановлен. Вывод можно обработать через альтернативную сеть.",
  "withdrawal": "вывод",
  "Address": "Адрес",
  "Enter Address": "Введите адрес",
  "USDT balance": "Баланс USDT",
'Network Fee': 'Сетевая комиссия',
'Minimum withdrawal': 'Минимальный вывод',
'24h remaining limit': 'Остаток лимита на 24 часа',
'Please enter SMS verification code!': 'Пожалуйста, введите код подтверждения из СМС!',
Amount: 'Сумма',
'Receive {{receive}} {{symbol}} (Network fee {{fee}} USDT)':
  'Получите {{receive}} {{symbol}} (Сетевая комиссия {{fee}} USDT)',
Source: 'Источник',
'Spot wallet': 'Спотовый кошелек',
'Verification code sent': 'Код подтверждения отправлен',
'security verification unavailable?': 'Подтверждение безопасности недоступно?',
'Security verification': 'Подтверждение безопасности',
'Withdrawal Request Submitted': 'Запрос на вывод средств отправлен',

'Please note that you will receive an email once it is completed':
  'Обратите внимание, что вы получите электронное письмо после завершения операции',

'The receiver will get': 'Получатель получит',
'Save Address': 'Сохранить адрес',
'One-Step Withdrawal': 'Одношаговый вывод средств',

'Enable One-Step Withdrawal to withdraw to whitelis addresses easily.':
  'Включите одношаговый вывод средств для удобства перевода на адреса из белого списка.',

'Please input your Recipient!': 'Пожалуйста, введите получателя!',
'Cryptizi ID': 'ID Cryptizi',
'Recipient’s email': 'Электронная почта получателя',
'Orders history': 'История заказов',
Recive: 'Получить',
'Buy Crypto with Debit / Credit Card': 'Покупка криптовалюты с дебетовой/кредитной картой',
max: 'максимум',
'Trades History': 'История сделок',
'Orders History': 'История заказов',
'Convert History': 'История конвертаций',
'Internal Transactions': 'Внутренние транзакции',
'Futures History': 'История фьючерсов',
'Crypto Transactions': 'Криптовалютные транзакции',
'Fiat Transactions': 'Фиатные транзакции',
ALL: 'ВСЕ',
BUY: 'КУПИТЬ',
SELL: 'ПРОДАТЬ',
MARKET: 'РЫНОК',
LIMIT: 'ЛИМИТ',
'STOP LIMIT': 'ЛИМИТ ОСТАНОВКИ',
CANCELED: 'ОТМЕНЕНО',
EXPIRED: 'ИСТЕКЛО',
FILLED: 'ЗАПОЛНЕНО',
NEW: 'НОВЫЙ',
'PARTIALLY FILLED': 'ЧАСТИЧНО ЗАПОЛНЕНО',
'PENDING CANCEL': 'ОЖИДАЕТ ОТМЕНЫ',
REJECTED: 'ОТКЛОНЕНО',
Pair: 'Пара',
Side: 'Сторона',
Filed: 'Зарегистрировано',
Commission: 'Комиссия',
State: 'Состояние',
'Credit Card': 'Кредитная карта',
'Please input a valid CVV2!': 'Пожалуйста, введите действительный CVV2!',
'Master Card': 'Мастеркард',
'Visa Card': 'Виза',
'Please input your old email address!': 'Пожалуйста, введите ваш старый адрес электронной почты!',
'Change Email': 'Изменить электронную почту',
'Please input your new email!': 'Пожалуйста, введите вашу новую электронную почту!',
'New Email': 'Новый Email',
'Email changed successfully': 'Электронная почта успешно изменена',
Tickets: 'Билеты',
Order: 'Заказ',
'Account Settings': 'Настройки аккаунта',
'Settings Network': 'Настройки сети',
Settings: 'Настройки',
Users: 'Пользователи',
Admins: 'Администраторы',
'Referral marketing': 'Реферальный маркетинг',
'Crypto Wallet Transactions': 'Транзакции криптокошелька',
'Wallet Transactions': 'Транзакции кошелька',
'Automatic Transactions': 'Автоматические транзакции',
'List Of All Bank Cards': 'Список всех банковских карт',
      "User's": 'Пользователи',
Notification: 'Уведомление',
Reporting: 'Отчетность',
'Tether Setting': 'Настройки Tether',
'Inventory Settings': 'Настройки инвентаря',
'Daily Rial withdrawal ceiling': 'Ежедневный лимит снятия риалов',
'Buy Digital Currency': 'Покупка цифровой валюты',
'API settings': 'Настройки API',
'General settings': 'Общие настройки',
'IP Block': 'Блокировка IP',
'User level settings': 'Настройки уровня пользователя',
'Deposit With Receipt': 'Депозит с квитанцией',
'Popup Settings': 'Настройки всплывающих окон',
'Commission settings': 'Настройки комиссии',
'Related To Reference Account': 'Относится к справочному счету',
'Automatic Deposit settings': 'Настройки автоматического депозита',
'Dedicated Gateway settings': 'Настройки выделенного шлюза',
'All users': 'Все пользователи',
'Add user': 'Добавить пользователя',
'Add Admin': 'Добавить администратора',
'Two-step login via Google': 'Двухэтапный вход через Google',
Activation: 'Активация',
'6-digit google code': '6-значный код Google',
'Two-step login via Sms': 'Двухэтапный вход через SMS',
'Send SMS': 'Отправить SMS',
'SMS Code': 'SMS код',
'Two-step login via Email': 'Двухэтапный вход через электронную почту',
'Send Email': 'Отправить Email',
'Email Code': 'Код электронной почты',
'Tether price inquiry from api and automatic': 'Автоматический запрос цены Tether через API',
'Settings Tether': 'Настройки Tether',
Caption: 'Заголовок',
'Please select a gateway!': 'Пожалуйста, выберите шлюз!',
'Inventory settings': 'Настройки инвентаря',
'API status for inventory': 'Статус API для инвентаря',
Cancel: 'Отмена',
'Buy digital currency': 'Покупка цифровой валюты',
'Sell digital currency': 'Продажа цифровой валюты',
'Digital currency': 'Цифровая валюта',
'Gateway Setting': 'Настройки шлюза',
'Voucher Settings': 'Настройки ваучера',
'Pay Safe Settings': 'Настройки безопасной оплаты',
'Perfect Money Settings': 'Настройки Perfect Money',
'Cryptocurrency Setting': 'Настройки криптовалюты',
'Settings related to vouchers': 'Настройки, связанные с ваучерами',
'Purchase status': 'Статус покупки',
'Sales status': 'Статус продажи',
apikey: 'ключ API',
seckey: 'секретный ключ',
'Query the price of dollars from the api': 'Запросить цену долларов через API',
'Purchase price in dollars': 'Цена покупки в долларах',
'Percentage on the price to buy': 'Процент на цену покупки',
'Purchase price times this number': 'Цена покупки, умноженная на это число',
'Selling price in dollars': 'Цена продажи в долларах',
'Percentage in price for sale': 'Процент от цены продажи',
'Selling price times this number': 'Цена продажи, умноженная на это число',

'Percentage in the price for purchase (above one hundred tomans)': 'Процент от цены покупки (свыше ста туманов)',

'Percentage in the price for sale (above one hundred tomans)': 'Процент от цены продажи (свыше ста туманов)',

'Settings related to Pay Safe Voucher': 'Настройки, связанные с ваучером Pay Safe',
UserName: 'Имя пользователя',
AccountID: 'Идентификатор аккаунта',
'Purchase status of Perfect Money voucher': 'Статус покупки ваучера Perfect Money',
'Settings related to Perfect Money': 'Настройки, связанные с Perfect Money',
'Perfect Money Voucher sales status': 'Статус продажи ваучера Perfect Money',
'Perfect Money Voucher': 'Ваучер Perfect Money',
'Settings related to cryptocurrency reference account': 'Настройки, связанные с референтным аккаунтом криптовалюты',
'Binance apikey': 'ключ API Binance',
'Binance seckey': 'секретный ключ Binance',
'Settings Cryptocurrency': 'Настройки криптовалюты',

'Enter the code that was sent to your previous mobile number and email in the box below':
  'Введите код, который был отправлен на ваш предыдущий мобильный номер и электронную почту, в поле ниже',
'New Email verification code': 'Код подтверждения новой электронной почты',
'Add Market': 'Добавить рынок',
Name: 'Имя',
Symbol: 'Символ',
'Name Persian': 'Персидское имя',
Selector: 'Селектор',
'Network Status': 'Статус сети',
'Settings network': 'Настройки сети',
'Please input networks name!': 'Пожалуйста, введите название сети!',
"Please input network's selector!": 'Пожалуйста, введите селектор сети!',
"Please input network's persian name!": 'Пожалуйста, введите персидское название сети!',
"Please input network's symbol!": 'Пожалуйста, введите символ сети!',
'Please input Minimum allowed!': 'Пожалуйста, введите минимально допустимое значение!',
'Minimum allowed purchase': 'Минимально допустимая покупка',
'Please input Daily purchase amount!': 'Пожалуйста, введите сумму ежедневной покупки!',
'IP test mode (to disable, leave the field blank)': 'Тестовый режим IP (для отключения оставьте поле пустым)',

'Deactivating purchases from the site (inserting a message in the box below means deactivating purchases from the site)':
  'Деактивация покупок с сайта (вставка сообщения в поле ниже означает деактивацию покупок с сайта)',

'Daily purchase amount during registration': 'Сумма ежедневной покупки во время регистрации',
'Deposit settings with receipt': 'Настройки депозита с квитанцией',
'Bank name': 'Название банка',
'Card number': 'Номер карты',
'Account number': 'Номер счета',
'Shaba number': 'Номер Шаба',
'Please input number of days!': 'Пожалуйста, введите количество дней!',
'Popup settings': 'Настройки всплывающих окон',

'After each login, it shows the password (that is, if he has not used remember me) and also shows it every few days if he has remembered me!':
  'После каждого входа в систему отображается пароль (если пользователь не выбрал опцию "Запомнить меня"), а также отображается каждые несколько дней, если он выбрал эту опцию.',

Percentage: 'Процент',
'Automatic deposit settings': 'Настройки автоматического депозита',
Business: 'Бизнес',
'Deposit model': 'Модель депозита',
Mobile: 'Мобильный',
      'Rejection of automatic deposit for the following banks': 'Отклонение автоматического депозита для следующих банков',
Sat: 'Суббота',
Sun: 'Воскресенье',
Mon: 'Понедельник',
Tue: 'Вторник',
Wed: 'Среда',
Thu: 'Четверг',
Fri: 'Пятница',
Active: 'Активный',
Token: 'Токен',
Inactive: 'Неактивный',
'Iran GATEWAY': 'Иранский шлюз',
'Contract text': 'Текст контракта',
'SMS confirmation for withdrawal and purchase': 'СМС-подтверждение для вывода и покупки',
'International GATEWAY': 'Международный шлюз',
'Iranian payment gateways Appearance': 'Внешний вид иранских платежных шлюзов',
'Minimum wallet withdrawal amount': 'Минимальная сумма вывода с кошелька',
'Minimum amount of currency sales': 'Минимальная сумма продажи валюты',
'Dedicated Gateway settings': 'Настройки выделенного шлюза',
disable: 'отключить',
active: 'активировать',

'This is the agreement between Iranianex.com and the user who uses the various services of the site. All users of Iranianex.com should read these rules completely and then become a member of the site if they accept them. Iranianex.com can update the terms of this agreement every month according to the changes in the laws. Registering on this site, using its services such as buying and selling, etc., means that you have read and accepted the terms of this contract. The user accepts that he is the legal owner. This is the agreement between Iranianex.com and the user who uses the various services of the site.':
'Это соглашение между Iranianex.com и пользователем, использующим различные услуги сайта. Все пользователи Iranianex.com должны полностью прочитать эти правила и затем стать участником сайта, если они их принимают. Iranianex.com может обновлять условия этого соглашения каждый месяц в соответствии с изменениями в законах. Регистрация на этом сайте, использование его услуг, таких как покупка и продажа и т.д., означает, что вы прочитали и приняли условия этого контракта. Пользователь принимает, что он является законным владельцем. Это соглашение между Iranianex.com и пользователем, использующим различные услуги сайта.',

'Add New Admin': 'Добавить нового администратора',
Admin: 'Администратор',
Block: 'Блокировать',
Edit: 'Редактировать',
Delete: 'Удалить',
'List of accounts': 'Список аккаунтов',
'Crypto transactions': 'Криптотранзакции',
'Bank cards': 'Банковские карты',
Log: 'Лог',
'Toman transactions': 'Транзакции томана',
'Please input name!': 'Пожалуйста, введите имя!',
'Please input mobile!': 'Пожалуйста, введите мобильный!',
'Please input email!': 'Пожалуйста, введите email!',
'Please input password!': 'Пожалуйста, введите пароль!',
'Add new admin': 'Добавить нового администратора',
Deactive: 'Деактивировать',
'Delete user': 'Удалить пользователя',
Access: 'Доступ',
'Two-step login': 'Двухфакторный вход',
'Upload photo': 'Загрузить фото',
'Change photo': 'Изменить фото',
Notifications: 'Уведомления',
'Wallet deposit and withdrawal': 'Пополнение и снятие с кошелька',
Filter: 'Фильтр',
'View transactions': 'Просмотр транзакций',
'Edit Admin': 'Редактировать администратора',
'Wallet transactions': 'Транзакции кошелька',

'User wallet transactions that have been changed by this admin':
'Транзакции кошелька пользователя, измененные этим администратором',

Bank: 'Банк',
'Amount of': 'Количество',
Currency: 'Валюта',
Status: 'Статус',
Detail: 'Деталь',
'Cryptocurrency wallet transactions': 'Транзакции кошелька криптовалют',
Regarding: 'Относительно',
'Business transactions': 'Бизнес транзакции',
'Transactions of a transaction': 'Транзакции одной сделки',

'ID transaction': 'ID транзакции',

'You can see the cryptocurrency wallet transactions that the admin has changed':
'Вы можете увидеть транзакции кошелька криптовалют, которые изменил администратор',

'The list of users whose document image has been changed by this admin.':
'Список пользователей, чье изображение документа было изменено этим администратором.',

Level: 'Уровень',
'Add admin': 'Добавить администратора',
'New Admin': 'Новый администратор',

'You can not disable a port that is in the default state.':
'Вы не можете отключить порт, находящийся в состоянии по умолчанию.',

'Order type': 'Тип заказа',
Tether: 'Тетер',
User: 'Пользователь',
'Transfer fee': 'Комиссия за перевод',
network: 'сеть',
'The amount': 'Сумма',
Close: 'Закрыть',
'Address wallet': 'Адрес кошелька',
'Displaying saved parameters (Click)': 'Отображение сохраненных параметров (Клик)',
'Transactions Details': 'Детали транзакций',
Success: 'Успех',
Failed: 'Неудача',

'You can see the total number of records on the current page, and for the total number of all displays':
'Вы можете увидеть общее количество записей на текущей странице и общее количество всех отображений',
 "Account list": "Список счетов",
  "Group payment": "Групповой платеж",
  "Sheba's list": "Список Шеба",
  "You can see the wallet transactions of users.": "Вы можете видеть транзакции кошелька пользователей.",
  "View": "Просмотр",
  "The transfer encountered an error": "Перевод завершился с ошибкой",
  "Transaction number": "Номер транзакции",
  "Transaction amount": "Сумма транзакции",
  "IP": "IP",
  "Done": "Выполнено",
  "New": "Новый",
  "Your Notifications": "Ваши уведомления",
  "View All Notifications": "Посмотреть все уведомления",
  "Sort by": "Сортировать по",
  "last 2 week": "последние 2 недели",
  "No Data": "Нет данных",
  "Dollar": "Доллар",
  "Payment unit": "Платежная единица",
  "Done successfully": "Успешно выполнено",
  "Voucher code": "Код ваучера",
  "MAX": "МАКС",
  "Select Coin": "Выберите монету",
  "Main Wallet": "Основной кошелек",
  "Funding Wallet": "Фондовый кошелек",
  "Select Wallet": "Выбрать кошелек",
  "Please select a coin firstly!": "Сначала выберите монету!",
  "Enter Tag": "Введите тег",
  "Add New Address": "Добавить новый адрес",
  "Select from Address Book": "Выбрать из адресной книги",
  "Phone": "Телефон",
  "balance": "баланс",
  "Receive {{receive}} {{symbol}}": "Получить {{receive}} {{symbol}}",
  "Top gainer (24h)": "Лучший прирост (24ч)",
  "Highest volume (24h)": "Наибольший объем (24ч)",
  "Most Visited": "Наиболее посещаемые",
  "New listing": "Новый список",
  "24h Change": "Изменение за 24ч",
  "24h Volume": "Объем за 24ч",
  "Market Cap": "Рыночная капитализация",
  "Crypto Market": "Криптовалютный рынок",
 "If you need help, watch the guide video in this section": "Если вам нужна помощь, посмотрите видеоруководство в этом разделе",
"Watch the movie": "Смотреть фильм",
"Your Share": "Ваша доля",
"Share friends": "Поделиться с друзьями",
"Reports": "Отчеты",
"List of trades in your subset": "Список сделок в вашем подмножестве",
"Your income chart": "Ваша доходная диаграмма",
"List of introduced users": "Список представленных пользователей",
"Link and introduction code": "Ссылка и код введения",
"By providing a link or referral code to your friends, you can earn money from their transaction fees and also allocate a part of this income to them, which is both for their benefit and for your benefit.":
"Предоставляя своим друзьям ссылку или реферальный код, вы можете зарабатывать на их транзакционных комиссиях, а также распределять часть этих доходов между ними, что выгодно как для них, так и для вас.",
"Set percentage for submissions": "Установить процент для отправлений",
"30% of your friends transaction fee is provided for you and by setting this section you can divide your income among the people you have introduced.":
"30% от комиссии за транзакцию ваших друзей предоставляется вам, и, настроив этот раздел, вы можете распределить свой доход среди представленных вами людей.",
"Number": "Номер",
"Details": "Детали",
"Add Ticket": "Добавить тикет",
"Subject": "Тема",
"Please input section!": "Пожалуйста, введите раздел!",
"Please input description!": "Пожалуйста, введите описание!",
  "Finance": "Финансы",
  "Technical": "Технический",
  "KYC": "KYC",
  "Name Ticket": "Название билета",
  "Section": "Раздел",
  "History of your trades": "История ваших торгов",
  "Time": "Время",
  "History of your transactions": "История ваших транзакций",
  "Past 30 days": "Последние 30 дней",
  "Past 90 days": "Последние 90 дней",
  "Credited": "Зачислено",
  "Pending": "В ожидании",
  "Past 7 days": "Последние 7 дней",
  "Asset": "Актив",
  "Enter TxID": "Введите TxID",
  "Select Asset": "Выберите актив",
  "Destination": "Назначение",
  "Failure": "Неудача",
  "Canceled": "Отменено",
  "Deposit ID": "ID депозита",
  "Please input your old Email address!": "Пожалуйста, введите ваш старый адрес электронной почты!",
  "Fiat Withdraw": "Вывод фиата",
  "Withdrawal of wallet": "Вывод из кошелька",
  "Your recoverable inventory:": "Ваш восстанавливаемый инвентарь:",
  "Equivalent daily withdrawal amount: 0 out of 500,000,000 Tomans": "Эквивалентная сумма ежедневного вывода: 0 из 500,000,000 туманов",
  "When determining the deposit amount, make sure that the minimum purchase and sale amount in the exchange is $ 11.": "При определении суммы депозита убедитесь, что минимальная сумма покупки и продажи на бирже составляет 11 долларов.",
  "Bank account to receive": "Банковский счет для получения",
      'Buy & Sell': 'Купить & Продать',
"All Model": "Все модели",
"Management": "Управление",
"Account Setting": "Настройки аккаунта",
"Profile": "Профиль",
"Waiting Actions": "Действия в ожидании",
"Admin Utilities": "Административные утилиты",
"Title": "Название",
"Today": "Сегодня",
"Yesterday": "Вчера",
"This week": "На этой неделе",
"This month": "В этом месяце",
"This Year": "В этом году",
"Total value": "Общая стоимость",
"Sales": "Продажи",
"System status": "Состояние системы",
"Website": "Веб-сайт",
"PaySafe Voucher": "Ваучер PaySafe",
"PMvoucher": "PMvoucher",
"Name Currency": "Название валюты",
"Sale": "Продажа",
"Stock": "Запас",
"Amount of orders": "Количество заказов",
"Sales number": "Номер продажи",
"Selling price": "Цена продажи",
"Purchases": "Покупки",
"Purchase amount": "Сумма покупки",
"New User": "Новый пользователь",
"Most sales": "Наибольшее количество продаж",
"Most purchases": "Наибольшее количество покупок",
"Users waiting for Verify": "Пользователи, ожидающие подтверждения",
"Fiat Transaction Waiting": "Ожидание фиатной транзакции",
"Reset": "Сброс",
"You can see the list of users whose information must be confirmed.": "Вы можете увидеть список пользователей, чья информация должна быть подтверждена.",
"Edit / Delete": "Редактировать / Удалить",
"Basic": "Основной",
"Crypto wallet": "Криптовалютный кошелек",
"Fiat wallet": "Фиатный кошелек",
"Crypto Transaction": "Криптовалютная транзакция",
"Cards": "Карты",
"Trade history": "История торговли",
"Earn ": "Заработать ",
"please fill the blank": "пожалуйста, заполните пустое место",
"Other": "Другие",
"Intermediate": "Средний",
"Advanced": "Продвинутый",
"Send Form": "Отправить форму",
"Middle Name": "Отчество",
"Country": "Страна",
"Street Address": "Адрес улицы",
"birthDate": "Дата рождения",
"Postal Code": "Почтовый индекс",
"City": "Город",
"Phone Number": "Номер телефона",
"Approve": "Утвердить",
"Reject": "Отклонить",
"Send Notification": "Отправить уведомление",
"Wallet’s User": "Пользователь кошелька",
"Total inventory": "Общий инвентарь",
"Equivalent to Dollar": "Эквивалент в долларах",
"Inventory available": "Доступный инвентарь",
"Create a transaction": "Создать транзакцию",
"Add transaction": "Добавить транзакцию",
"Error": "Ошибка",
"Edit User": "Редактировать пользователя",
"Create": "Создать",
"Update photo": "Обновить фото",
"Value Transaction": "Транзакция со значением",
"Account Transaction": "Банковская транзакция",
  'Add Card': 'Добавить карту',
  'Add card': 'Добавить карту',
  Shaba: 'Шаба',
  Waiting: 'В ожидании',
  Message: 'Сообщение',
  Payment: 'Оплата',
  Port: 'Порт',
  'Order value': 'Стоимость заказа',
  success: 'Успешно',
  Earn: 'Заработать',
  'List of users that this user has introduced': 'Список пользователей, представленных этим пользователем',
  'Value Order': 'Стоимость заказа',
  'User commission': 'Комиссия пользователя',
  'ID card': 'Удостоверение личности',
  Passport: 'Паспорт',
  'Upload .PDF/.JPG/.JPEG/.PNG file and no more than 5M': 'Загрузите файл .PDF/.JPG/.JPEG/.PNG размером не более 5M',
  'Select your Country / region': 'Выберите вашу страну / регион',
  'Change level': 'Изменить уровень',
  'Change password': 'Изменить пароль',
  'Representative mobile': 'Представительский мобильный',
  'List of all bank cards': 'Список всех банковских карт',
  'To edit, delete or approve or disapprove a bank card, click on the link to be redirected to the bank cards section in the users profile.': 'Чтобы редактировать, удалять или одобрять или отклонять банковскую карту, нажмите на ссылку, чтобы перейти в раздел банковских карт в профиле пользователя.',
  'Edit card': 'Редактировать карту',
  'Edit Card': 'Редактировать карту',
  'Fiat transactions': 'Фиатные транзакции',
  'Edit admin': 'Редактировать администратора',
  'API Setting': 'Настройка API',
  'Network Setting': 'Настройки сети',
  Referrals: 'Рефералы',
  'Dollar balance': 'Баланс в долларах',
  'Rial balance': 'Баланс в риалах',
      'Percentage in the price for ( marketing )': 'Процент от цены для (маркетинга)',
'Perfect Money ': 'Перфект Мани ',
'Percentage in the price for purchase': 'Процент от цены покупки',
'Percentage in the price for sale': 'Процент от цены продажи',
'Network Name': 'Название сети',
'General Settings': 'Общие настройки',
'Type ...': 'Тип ...',
"Account Holder's name": 'Имя владельца счета',
Vandar: 'Вандар',
PIR: 'ПИР',
Zibal: 'Зибал',
'Add User': 'Добавить пользователя',
BASIC: 'БАЗОВЫЙ',
Disapproval: 'Неодобрение',
REGISTERED: 'ЗАРЕГИСТРИРОВАННЫЙ',
BASIC_BY_ADMIN: 'БАЗОВЫЙ ОТ АДМИНИСТРАТОРА',
INTERMEDIATE_REQUESTED: 'ЗАПРОШЕН СРЕДНИЙ',
ADVANCED_BY_ADMIN: 'ПРОДВИНУТЫЙ ОТ АДМИНИСТРАТОРА',
INTERMEDIATE_BY_ADMIN: 'СРЕДНИЙ ОТ АДМИНИСТРАТОРА',
ADVANCED_REQUESTED: 'ЗАПРОШЕН ПРОДВИНУТЫЙ',
Google: 'Google',
LastName: 'Фамилия',
Sms: 'СМС',
'Father Name': 'Имя отца',
'National Number': 'Национальный номер',
'Birth Date': 'Дата рождения',
'Login Date': 'Дата входа',
Verification: 'Проверка',
Tell: 'Сказать',
'Add New Card': 'Добавить новую карту',
Support: 'Поддержка',
caption: 'Заголовок',
'List of commission payments to the wallet': 'Список выплат комиссий в кошелек',
'Referral Marketing': 'Реферальный маркетинг',
waiting: 'Ожидание',
'Transfer completed successfully': 'Перевод успешно завершен',
'All User': 'Все пользователи',
'Perfect Money buyers/sellers': 'Покупатели/продавцы Перфект Мани',
'CryptoCurrency buyers/sellers': 'Покупатели/продавцы криптовалюты',
'Paysafe buyers/sellers': 'Покупатели/продавцы Paysafe',
'Vouchers buyers/sellers': 'Покупатели/продавцы ваучеров',
'Referral Users': 'Реферальные пользователи',
'Verified Users': 'Проверенные пользователи',
"Users Who hasn't trade yet": 'Пользователи, которые еще не торговали',
  'This category does not apply to header alerts and shows for everyone': 'Эта категория не применяется к предупреждениям заголовка и показывается всем',
  'The title does not apply to sms': 'Заголовок не относится к SMS',
  'Unverified Users': 'Непроверенные пользователи',
  'Notification type': 'Тип уведомления',
  'Users whos Google Authenticator is inactive': 'Пользователи, у которых Google Authenticator неактивен',
  'Users whos SMS Authenticator is inactive': 'Пользователи, у которых SMS Authenticator неактивен',
  'Basic level Users': 'Пользователи базового уровня',
  'Intermediate Users': 'Пользователи среднего уровня',
  'Advanced Users': 'Продвинутые пользователи',
  'User Statistics': 'Статистика пользователей',
  'Open trades': 'Открытые сделки',
  'Futures Open position': 'Открытая позиция по фьючерсам',
  'Deposit Statistics': 'Статистика депозитов',
  'Crypto Statistics': 'Статистика криптовалют',
  'Successful Orders': 'Успешные сделки',
  'Withdraw Statistics': 'Статистика вывода средств',
  'Unsuccessful Orders': 'Неудачная сделка',
  '12 H': '12 часов',
  '1 Day': '1 день',
  '1 Week': '1 неделя',
  '1 Months': '1 месяц',
  '1 Years': '1 год',
  'last day': 'последний день',
  'Buy/Sell': 'Купить/Продать',
  Desktop: 'Рабочий стол',
  Select: 'Выбрать',
  Report: 'Отчет',
  'Deactivate user': 'Деактивировать пользователя',
  'Active user': 'Активный пользователь',
  Excel: 'Excel',
  PDF: 'PDF',
  Gateway: 'Шлюз',
  'Post New Ad': 'Разместить новое объявление',
  Remainder: 'Остаток',
  'Ads List': 'Список объявлений',
  Post: 'Пост',
  'Total Amount': 'Общая сумма',
  'With Fiat': 'С фиатом',
  'Minimum order limit': 'Минимальный лимит заказа',
  'Maximum order limit': 'Максимальный лимит заказа',
'Please select the type!': 'Пожалуйста, выберите тип!',
'Perfect Money': 'Perfect Money',
'Voucher PaySafe': 'Подарочный сертификат PaySafe',
'You can also enter the amount': 'Вы также можете ввести сумму',
'Date & Time': 'Дата и время',
'Serial number': 'Серийный номер',
Continue: 'Продолжить',
Hot: 'Горячее',
'Choose Payment method': 'Выберите метод оплаты',
Previous: 'Назад',
Confirm: 'Подтвердить',
'History of your orders': 'История ваших заказов',
Fiat: 'Фиат',
'P2P History': 'История P2P',
'History of your P2P orders': 'История ваших P2P заказов',
TAKE: 'ВЗЯТЬ',
MAKE: 'СОЗДАТЬ',
COMPLETED: 'ЗАВЕРШЕНО',
FAILED: 'НЕ УДАЛОСЬ',
PARTIALLY_COMPLETED: 'ЧАСТИЧНО ЗАВЕРШЕНО',
PENDING: 'В ОЖИДАНИИ',
'Please input your oldMobile!': 'Пожалуйста, введите ваш старый мобильный номер!',
'Please input your new mobile!': 'Пожалуйста, введите ваш новый мобильный номер!',
'Change Mobile': 'Изменить мобильный номер',
'New Mobile': 'Новый мобильный номер',
'Please input your oldEmailAddress!': 'Пожалуйста, введите ваш старый адрес электронной почты!',
'Paysafe total value': 'Общая стоимость Paysafe',
'Perfect Money total value': 'Общая стоимость Perfect Money',
'Tether total value': 'Общая стоимость Tether',
'Automatic Deposit History': 'История автоматических вкладов',
'Sheba number': 'Номер Sheba',
Unblock: 'Разблокировать',
undefined: 'Не определено',
Approved: 'Подтверждено',
Rejectd: 'Отклонено',
'You can see the list of commissions received by this user through introduction.':
  'Вы можете увидеть список комиссий, полученных этим пользователем через введение.',
INTERMEDIATE: 'СРЕДНИЙ',
ADVANCED: 'ПРОДВИНУТЫЙ',
Family: 'Семья',
'Please input first name!': 'Пожалуйста, введите имя!',
'Please input email address!': 'Пожалуйста, введите адрес электронной почты!',
'Please input last name!': 'Пожалуйста, введите фамилию!',
"If you don't decide to change the password, leave this field blank":
  'Если вы не решили изменить пароль, оставьте это поле пустым',
'Access Denied': 'Доступ запрещен',
'Fixed panel page header': 'Фиксированный заголовок страницы панели',
'By notification': 'По уведомлению',
'Header panel pages only once': 'Заголовок страницы панели только один раз',
'by Email': 'по электронной почте',
'Via SMS': 'Через SMS',
Desktap: 'Рабочий стол',
'Deactive user': 'Отключить пользователя',
'Buy Crypto': 'Купить криптовалюту',
'Contact Us': 'Свяжитесь с нами',
'Privacy policy': 'Политика конфиденциальности',
'E-money': 'Электронные деньги',
Verify: 'Проверить',
Announcement: 'Объявление',
API: 'API (Интерфейс прикладного программирования)',
'OTC Terms': 'Условия OTC (Внебиржевой торговли)',
'Convert Terms': 'Условия обмена',
'Please enter {{min}}-{{max}}': 'Пожалуйста, введите от {{min}} до {{max}}',
Favorites: 'Избранное',
'All Cryptos': 'Все криптовалюты',
'Spot Markets': 'Рынки наличных средств',
'Futures Markets': 'Фьючерсные рынки',
'New Markets': 'Новые рынки',
Metaverse: 'Метавселенная',
Gaming: 'Игры',
Defi: 'DeFi (Децентрализованные финансы)',
Innovation: 'Инновации',
'Layer1/layer2': 'Уровень 1/Уровень 2',
'Fan Token': 'Токен фана',
NFT: 'NFT (Токены с непереносимыми правами)',
Storage: 'Хранение',
Palkadot: 'Палкадот',
POS: 'DPoS (Доказательство доли)',
POW: 'PoW (Доказательство работы)',
'1 Month': '1 месяц',
Symbols: 'Символы',
'1 Year': '1 год',
Open: 'Открыть',
Remove: 'Удалить',
FINANCE: 'Финансы',
TECHNICAL: 'Технический',
'Close ticket': 'Закрыть заявку',
'Updated Date': 'Дата обновления',
'Type a message': 'Введите сообщение',
'How satisfying it was ?': 'На сколько это было удовлетворительно?',
'Ticket closed': 'Заявка закрыта',
'Your current Limit': 'Ваш текущий лимит',
'Switch to enterprise Account': 'Переключиться на корпоративный аккаунт',
'Video Guide': 'Видео-инструкция',
'Personal Information': 'Личная информация',
'Crypto Deposit': 'Депозит в криптовалюте',
Unlimited: 'Неограниченно',
'Most Popular': 'Самое популярное',
'All basic Requirements': 'Все основные требования',
'Government ID': 'Паспортные данные',
 'Facial Verification': 'Верификация лица',
'Review time': 'Время рассмотрения',
days: 'дни',
'Fiat Deposit & Withdrawal Limit': 'Лимит ввода и вывода Fiat',
Daily: 'Ежедневно',
'P2P Transaction Limits': 'Лимиты P2P-сделок',
'Crypto Withdraw': 'Вывод криптовалюты',
'Highest Fiat Limit': 'Максимальный лимит Fiat',
'Proof of Address': 'Подтверждение адреса',
'Quick & Easy': 'Быстро и легко',
'WAITING': 'ОЖИДАНИЕ',
'Advanced requested': 'Запрошен продвинутый',
'Intermediate requested': 'Запрошен промежуточный',
'ACTIVE': 'АКТИВНЫЙ',
'Intermediate by admin': 'Промежуточный администратором',
'Advanced by admin': 'Продвинутый администратором',
'Registered': 'Зарегистрирован',
'Select Approve or Reject status': 'Выберите статус "Подтвердить" или "Отклонить"',
'Blocked': 'Заблокирован',
'USDT Value': 'Стоимость USDT',
'USD Value': 'Стоимость USD',
'Please fill the blank': 'Пожалуйста, заполните пустое поле',
'Please select one': 'Пожалуйста, выберите один из вариантов',
'ID Card': 'Удостоверение личности',
'User Image': 'Изображение пользователя',
'Country / region': 'Страна / регион',
'The user reject successfully': 'Пользователь успешно отклонен',
'Advanced Verification': 'Продвинутая верификация',
'Confirm Residential Address': 'Подтвердите адрес проживания',
'Please choose and upload one of the following documents': 'Выберите и загрузите один из следующих документов',
'Utility bill (electricity, gaz. water/sewage, fibre/broadband service)':
  'Квитанция за коммунальные услуги (электроэнергия, газ, вода/канализация, услуги по фиброоптике/широкополосному доступу)',
'Select Photo': 'Выберите фотографию',
'Upload .JPG/.JPEG/.PNG file and no more than 5M': 'Загрузите файл .JPG/.JPEG/.PNG, размером не более 5 МБ',
'Bank statement': 'Выписка из банка',
'Basic Verification': 'Основная верификация',
'Identity Information': 'Информация о личности',
'Nationality': 'Национальность',
'Select your nationality': 'Выберите вашу национальность',
'date of birth': 'Дата рождения',
'Please input your street address!': 'Пожалуйста, введите вашу улицу!',
'Please input your city!': 'Пожалуйста, введите ваш город!',
'Please input your phone number!': 'Пожалуйста, введите ваш номер телефона!',
'Please input your postal code!': 'Пожалуйста, введите ваш почтовый индекс!',
'Use a valid government-issued document': 'Используйте действительный документ, выданный государством',
'Only the following documents listed below will be accepted': 'Будут приняты только следующие перечисленные ниже документы',
'Id Card': 'Удостоверение личности',
'Government-issued': 'Выдано государством',
'place documents against a single-colored background': 'Разместите документы на фоне одного цвета',
'Readable, well-lit, colored images': 'Читаемые, хорошо освещенные цветные изображения',
'Original Full-size, unedited documents': 'Оригинальные документы в полном размере, без редактирования',
'No black and white images': 'Без черно-белых изображений',
'No edited or expired documents': 'Без отредактированных или устаревших документов',
'file size must be between 10kb and 512kb in jpg/jpeg/png format':
  'Размер файла должен быть от 10 кб до 512 кб в форматах jpg/jpeg/png',
'Basic information': 'Основная информация',
'Change Password': 'Изменить пароль',
'Two-step verification': 'Двухэтапная проверка',
'Change email': 'Изменить email',
'Receive SMS after transactions': 'Получать SMS после сделок',
'Receive notifications after transactions (Guide)': 'Получать уведомления после сделок (Руководство)',
'Activities': 'Действия',
'Receive SMS after each login': 'Получать SMS после каждого входа',
'Receive emails after transactions': 'Получать email после сделок',
'Receive emails after each login': 'Получать email после каждого входа',
'User ID': 'ID пользователя',
'Registration via : Website': 'Регистрация через: Веб-сайт',
'Registration Date': 'Дата регистрации',
'Save Changes': 'Сохранить изменения',
'Default': 'По умолчанию',
'Please input a valid oldEmailAddress': 'Пожалуйста, введите действительный старый адрес электронной почты',
'Please input a valid email': 'Пожалуйста, введите действительный адрес электронной почты',
'GO BACK': 'НАЗАД',
'Total Buy': 'Общая покупка',
'Total Referrals': 'Общее количество рефералов',
'Total Tickets': 'Общее количество билетов',
'Total Transactions': 'Общее количество транзакций',
'Highest Buy': 'Самая высокая покупка',
'Highest Sell': 'Самая высокая продажа',
'Total Tade': 'Общий оборот',
'Total Order': 'Общее количество заказов',
'Total Fiat Income': 'Общий доход в Fiat',
'Block user': 'Заблокировать пользователя',
'Intermediate user': 'Промежуточный пользователь',
'Advanced user': 'Продвинутый пользователь',
'items': 'элементов',
'Close By Admin': 'Закрыто администратором',
'All tickets': 'Все билеты',
'Ticket Title': 'Заголовок билета',
'Pending tickets': 'Тикеты в ожидании',
'Total messages': 'Всего сообщений',
'Open tickets ': 'Открытые тикеты',
'Please input dollar limit amount!': 'Пожалуйста, введите ограничение в долларах!',
'Please input rial limit amount!': 'Пожалуйста, введите ограничение в риалах!',
'To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.':
  'Чтобы активировать, сначала нажмите на кнопку отправки SMS, чтобы отправить вам текстовое сообщение с кодом активации, вставьте код в поле и нажмите кнопку активации.',
'To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.':
  'Чтобы активировать, сначала нажмите на кнопку отправки email, чтобы отправить вам код на вашу почту, вставьте код в поле и нажмите кнопку активации. Возможно, вы получили письмо в папке "Спам / Нежелательная почта".',
'To activate this feature, follow these steps:': 'Чтобы активировать эту функцию, выполните следующие шаги:',
'2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program.':
  '2. После установки и запуска программы Google Authenticator по одному из следующих методов, добавьте ключ в программу.',
'- Enter a provided key: Select this option and enter the following code carefully.':
  '- Введите предоставленный ключ: Выберите эту опцию и введите следующий код аккуратно.',
'3. Enter the received code (6-digit number) in the box below and click the activate button.':
  '3. Введите полученный код (6-значный номер) в поле ниже и нажмите кнопку активации.',
'1. Get the latest version of Google Authenticator from Google Play or Apple Store':
  '1. Получите последнюю версию Google Authenticator из Google Play или Apple Store',
'- Scan a barcode: Select this option and scan the following barcode.':
  '- Сканировать штрих-код: Выберите эту опцию и отсканируйте следующий штрих-код.',
'WalletID': 'Идентификатор кошелька',
'All Networks Status': 'Статус всех сетей',
'Networks Status': 'Статус сетей',
'UnVerified': 'Непроверенный',
'User level settings can be changed from this section, and please change the settings of this section carefully.':
  'Настройки уровня пользователя можно изменить в этом разделе, и пожалуйста, изменяйте настройки этого раздела осторожно.',
'Please input withdrawal of cryptocurrency!': 'Пожалуйста, введите сумму вывода криптовалюты!',
'Please input withdrawal of fiat!': 'Пожалуйста, введите сумму вывода fiat!',
'Please input buying digital money (equivalent to dollar)!':
  'Пожалуйста, введите сумму покупки цифровой валюты (эквивалент доллара)!',
'Please input buying digital money (equivalent to rial)!':
  'Пожалуйста, введите сумму покупки цифровой валюты (эквивалент риала)!',
'Please input fees for buy cryptocurrency!': 'Пожалуйста, введите комиссию за покупку криптовалюты!',
'Please input fees for sell cryptocurrency!': 'Пожалуйста, введите комиссию за продажу криптовалюты!',
'Please input trade maker fee!': 'Пожалуйста, введите комиссию за ордера мэйкера!',
'Please input futures maker fee!': 'Пожалуйста, введите комиссию за ордера мэйкера в фьючерсах!',
'Please input trade taker fee!': 'Пожалуйста, введите комиссию за ордера тейкера!',
'Please input futures taker fee!': 'Пожалуйста, введите комиссию за ордера тейкера в фьючерсах!',
'Please input internal transaction fee!': 'Пожалуйста, введите внутреннюю комиссию за транзакции!',
'Please input OTC minimum buy!': 'Пожалуйста, введите минимальную сумму покупки в OTC!',
'Cryptocurrency & Fiat Withdrawal': 'Вывод криптовалюты и fiat',
'Tether Fee': 'Комиссия Tether',
'Calculation of percentage in the price for buy': 'Расчет процента в цене на покупку',
'Calculation of the percentage in the price for sale': 'Расчет процента в цене на продажу',
'Futures Fee': 'Комиссия фьючерсов',
'Futures Fee (Coin-M Futures)': 'Комиссия фьючерсов (Coin-M Futures)',
'Futures Fee (USDⓈ-M Futures)': 'Комиссия фьючерсов (USDⓈ-M Futures)',
'Trade Fee': 'Торговая комиссия',
'Internal Transaction Fee': 'Внутренняя комиссия за транзакции',
'OTC Minimum Buy': 'Минимальная покупка в OTC',
'DISAPPROVAL': 'НЕУТВЕРЖДЕНИЕ',
'Advanced rejected': 'Отклонено (продвинуто)',
'Are you sure to remove this user from users?': 'Вы уверены, что хотите удалить этого пользователя из списка?',
'Are you sure to remove this ticket?': 'Вы уверены, что хотите удалить этот тикет?',
'Please input your mobile number!': 'Пожалуйста, введите ваш номер мобильного телефона!',
'Please select your birth date': 'Пожалуйста, выберите вашу дату рождения!',
'Please select one!': 'Пожалуйста, выберите одно из полей!',
'Please input your email adress!': 'Пожалуйста, введите ваш адрес email!',
'Please input your photo!': 'Пожалуйста, загрузите вашу фотографию!',
'Fiat type': 'Тип Fiat',
'Fiat Status': 'Статус Fiat',
'Withdraw Status': 'Статус вывода',
'Awaiting Approval': 'Ожидает подтверждения',
'Cancelled': 'Отменено',
'Email Sent': 'Email отправлен',
'Processing': 'Обработка',
'Rejected': 'Отклонено',
'Transaction ID': 'Идентификатор транзакции',
'Deposit Status': 'Статус депозита',
'Deposit Id': 'Идентификатор депозита',
'Create Date': 'Дата создания',
'Transaction Type': 'Тип транзакции',
'Transaction Id': 'Идентификатор транзакции',
'Sheba': 'Шеба',
'Deposit type': 'Тип депозита',
'Created Date': 'Дата создания',
'Expired': 'Истекший',
'Partially Filled': 'Частично заполнено',
'Pending Cancel': 'Ожидает отмены',
'Limit': 'Лимит',
'Stop Limit': 'Лимит стоп-заказа',
'Filled': 'Заполнено',
'Transaction Date': 'Дата транзакции',
'Stop Price': 'Цена стоп-заказа',
'Stop limit': 'Лимит стоп-заказа',
'Minimum limit': 'Минимальный лимит',
'Stop price': 'Цена стоп-заказа',
'Are you sure to remove this admin account?': 'Вы уверены, что хотите удалить этот административный аккаунт?',
'Dynamic profit percentage': 'Динамический процент прибыли',
'User Share': 'Доля пользователя',
'Iran': 'Иран',
'Armenia': 'Армения',
  
      /**
       * **************************************************************************************
       * Please translate the below statements
       */
'can see the list of commissions received by this user through introduction.':
  'можете видеть список комиссий, полученных этим пользователем через введение.',
'Crypto Desposit': 'Криптовалютный депозит',
'Monthly': 'Ежемесячно',
'Contry / region': 'Страна / регион',
'Use a valid goverment-issued document': 'Используйте действующий документ, выданный правительством',
'place documents against a single-coloured background': 'разместите документы на однотонном фоне',
'Readable, well-lit, colored images': 'читаемые, хорошо освещенные, цветные изображения',
'Total Trade': 'Общая сделка',
// Fiat
'Account': 'Аккаунт',
'Tracking code': 'Код отслеживания',
'This transaction is in progress, and after review, you can either confirm or reject it.':
  'Эта транзакция выполняется, и после проверки вы можете подтвердить или отклонить ее.',
'Are you sure to deny this transaction?': 'Вы уверены, что хотите отклонить эту транзакцию?',
'Are you sure to accept this transaction?': 'Вы уверены, что хотите принять эту транзакцию?',
// Missing Translations
'List of withdrawals': 'Список выводов',
'There is no record for this table': 'Для этой таблицы нет записей',
'Recurring Buy': 'Повторная покупка',
'In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We`ve got to make it easier for you to activate with each one.':
  'Для повышения уровня безопасности вашей учетной записи мы рекомендуем вам включить двухэтапный вход. Вы можете включить следующий двухэтапный вход, который включает двухэтапный вход через Google, по электронной почте и SMS. Самый безопасный метод - двухэтапный вход через Google, но для вас мы предусмотрели три типа для удобства активации каждого из них.',
'Are you sure to remove this order?': 'Вы уверены, что хотите удалить этот заказ?',
'I want to pay': 'Я хочу оплатить',
'I want to sell': 'Я хочу продать',
'I will receive': 'Я получу',
'Deposit account number': 'Номер депозита',
'The total number of users': 'Общее количество пользователей',
'Total balance(Fiat)': 'Общий баланс (Fiat)',
'Total number of purchases': 'Общее количество покупок',
'Total sales': 'Общие продажи',
'Total sales amount': 'Общая сумма продаж',
'number of orders': 'количество заказов',
'otal order amount': 'общая сумма заказа',
'Transaction': 'Транзакция',
'Referral Users': 'Реферальные пользователи',
'Existing users': 'Существующие пользователи',
'Active users': 'Активные пользователи',
'All tickets': 'Все билеты',
'Opened Tickets': 'Открытые билеты',
'Change': 'Изменить',
'Price': 'Цена',
'Pair': 'Пара',
'Market Trades': 'Сделки на рынке',
'Time Amount(MATIC) price(USDT)': 'Время Сумма(MATIC) цена(USDT)',
'Market Activities': 'Рыночная активность',
'XMRBUSD': 'XMRBUSD',
'MANAUSDT': 'MANAUSDT',
'SLAPETH': 'SLAPETH',
'ONGBTS': 'ONGBTS',
'MATIC/USDT': 'MATIC/USDT',
'Binance': 'Binance',
'Volume USDT': 'Объем USDT',
'Volume MATIC': 'Объем MATIC',
'low': 'Низко',
'Hight': 'Высоко',
'Change': 'Изменение',
'Polygon': 'Полигон',
'STOP_LIMIT': 'СТОП ЛИМИТ',
'MARKET': 'РЫНОК',
'LIMIT': 'ЛИМИТ',
'Total': 'Всего',
'Amout(MATIC)': 'Количество(MATIC)',
'Price(USDT)': 'Цена(USDT)',
'Recurring Buy': 'Повторная покупка',
'Rol 0.00% got .years for USDT Recurring Buy': 'Процент 0.00% получено .лет для USDT повторной покупки',
'Oder Book': 'Книга ордеров',
'Sum(BTC)': 'Сумма(BTC)',
'Size(BTC)': 'Размер(BTC)',
'Trades': 'Сделки',
'BTC/USDT': 'BTC/USDT',
'Sep': 'Сен',
'Oct': 'Окт',
'Nov': 'Ноя',
'3y': '3 года',
'50y': '50 лет',
'Trade History': 'История сделок',
'Open Trades': 'Открытые сделки',
'Positione': 'Позиция',
'Margin Ratio': 'Маржинальное соотношение',
'Liq.Price': 'Цена ликвидации',
'Entry Price': 'Цена входа',
'Size': 'Размер',
'BTCUSDT': 'BTCUSDT',
'Layer1': 'Слой 1',
'Layer2': 'Слой 2',
'Fav': 'Избранное',
'Number of referrals': 'Количество рефералов',
'Number of transactions': 'Количество транзакций',
'your total income is based on USD': 'Ваш общий доход основан на USD',
'Updated At': 'Обновлено',
'Creat At': 'Создано',
'Deleted': 'Удалено',
'Close by admin': 'Закрыто администратором',
'Oder ID': 'ID ордера',
'Paysafe History': 'История Paysafe',
'Perfact Money History': 'История Perfect Money',
'Oder ID': 'ID ордера',
'Tx Code': 'Код Tx',
'sum': 'Сумма',
'BUY': 'Покупка',
'Unit price': 'Цена за единицу',
'in order to increase the security leveled of yor account. we recommend that you enable your two-step login. you can enable the following two-step login. which includes two-step login via Google. email and SMS,and the safest method is two-step login via Google.but for you to consider three types we ot to make it easier for you to activate with each one.':'Чтобы увеличить уровень безопасности вашей учетной записи, мы рекомендуем вам включить двухэтапную авторизацию. Вы можете включить следующий двухэтапный вход, который включает двухэтапную авторизацию через Google, по электронной почте и SMS, и самый безопасный метод - двухэтапная авторизация через Google, но для вашего удобства мы предусмотрели три варианта активации.',
'Two-step login via Google': 'Двухэтапная авторизация через Google',
'Two-step login via Sms': 'Двухэтапная авторизация через SMS',
'Two-step login via Email': 'Двухэтапная авторизация через Email',
'Withdrawal of cryptocurrency(equivalent to Dollar)': 'Вывод криптовалюты (эквивалент в долларах)',
'Withdrawal of Fiat(equivalent to Dollar)': 'Вывод фиатных средств (эквивалент в долларах)',
'Buying digital money(equivalent to Dollar)': 'Покупка цифровой валюты (эквивалент в долларах)',
'Buying digital money(equivalent to Rial)': 'Покупка цифровой валюты (эквивалент в риалах)',
'Fees for buy Tether': 'Сборы за покупку Tether',
'Fees for Sell Tether': 'Сборы за продажу Tether',
'Future Fee(Marker)': 'Будущие сборы (Маркер)',
'Future Fee(Taker)': 'Будущие сборы (Тейкер)',
'Trade Fee(Marker)': 'Сборы за сделки (Маркер)',
'Trede Fee(Taker)': 'Сборы за сделки (Тейкер)',
'Fees for internal transaction': 'Сборы за внутренние транзакции',
'Min internal transaction': 'Минимальная внутренняя транзакция',
'Max internal transaction': 'Максимальная внутренняя транзакция',
'Max leverage': 'Максимальное плечо',
'Max Margin': 'Максимальный маржа',
'Card Number': 'Номер карты',
'Month Of date': 'Месяц даты',
'Year of date': 'Год даты',
'Paysafe Price': 'Цена Paysafe',
'Deposit Type': 'Тип депозита',
'Voucher Currency': 'Валюта ваучера',
'First Name': 'Имя',
'Last Name': 'Фамилия',
'Old Password': 'Старый пароль',
'New Password': 'Новый пароль',
'Confirm Password': 'Подтвердите пароль',
'Add Mobile': 'Добавить мобильный номер',
'There is no record for this table': 'Здесь нет записей для этой таблицы',
'Paysafe Price': 'Цена Paysafe',
'Deposite Type': 'Тип депозита',
'Voucher Currency': 'Валюта ваучера',
'Fiat Type': 'Тип фиата',
'Perfect Money Price': 'Цена Perfect Money',
'TxID': 'ID транзакции',
'Origin': 'Источник',
'Entry Price': 'Цена входа',
'Leverage': 'Плечо',
'Margin Ratio': 'Маржинальное соотношение',
'Value currency': 'Валютная единица',
'Address': 'Адрес',
'Recurring Buy': 'Повторная покупка',
'Long': 'Длинная позиция',
'Short': 'Короткая позиция',
'Cost': 'Стоимость',
'Max': 'Максимальное',
'Introduction link': 'Ссылка на представление',
'Introduction Code': 'Код представления',
'Number of referrals': 'Количество рефералов',
'Number of transactions': 'Количество сделок',
'Your total income is based on USD': 'Ваш общий доход основан на USD',
'Verify Now': 'Проверить сейчас',
'After Basic': 'После базового',
'After Intermediate': 'После промежуточного',
'Fiat': 'Фиат',
"You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": 'У вас пока нет активности. После начала торговли в cryptizi здесь будет показана живая диаграмма.',
'Save Change': 'Сохранить изменения',
'Setting Saved Successfully.': 'Настройки успешно сохранены.',
'Default setting restored successfully.': 'Настройки по умолчанию успешно восстановлены.',
'Please enter Spend amount!': 'Пожалуйста, введите сумму расходов!',
'added to favorites successfully.': 'Добавлено в избранное успешно.',
'Bad Request': 'Плохой запрос',
'Enter the amount!': 'Введите сумму!',
'Please enter total amount!': 'Пожалуйста, введите общую сумму!',
'Internal Server Error': 'Внутренняя ошибка сервера',
'Insufficient funds': 'Недостаточно средств',
'Please select a coin!': 'Пожалуйста, выберите монету!',
'Please fill in all fields': 'Пожалуйста, заполните все поля',
'Share percentage changed successfully.': 'Процент доли успешно изменен.',
'deleted from your favorites': 'удалено из избранного',
'Invalid request': 'Недопустимый запрос',
'Invalid symbol USDTUSDT': 'Недопустимый символ USDTUSDT',
'Card ExpiryDate Month is invalid': 'Недопустимый месяц срока действия карты',
'card is expired': 'карта истекла',
'Month of date': 'Месяц даты',
'Account Number': 'Номер счета',
'Card ExpiryDate Month is invalid': 'Недопустимый месяц срока действия карты',
'To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.': 'Чтобы активировать эту функцию, следуйте этим шагам:\n 1. Получите последнюю версию Google Authenticator из Google Play или Apple Store.\n 2. После установки и запуска программы Google Authenticator с помощью одного из следующих методов, добавьте ключ в программу. - Сканировать штрих-код: Выберите эту опцию и отсканируйте следующий штрих-код.',
'- Enter a provided key: Select this option and enter the following code carefully.': '- Введите предоставленный ключ: Выберите эту опцию и внимательно введите следующий код.',
'3. Enter the received code (6-digit number) in the box below and click the activate button.': '3. Введите полученный код (6-значный номер) в поле ниже и нажмите кнопку активации.',
'Google Code': 'Код Google',
'To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.': 'Для активации сначала нажмите кнопку «Отправить SMS», чтобы отправить вам текстовое сообщение с кодом активации, вставьте gad в поле и нажмите кнопку «Активировать».',
'Send SMS': 'Отправить SMS',
'Email Code': 'Код электронной почты',
'To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.': 'Для активации сначала нажмите кнопку «Отправить email», чтобы отправить вам код на вашу электронную почту, вставьте код в поле и нажмите кнопку «Активировать». Возможно, вы получили email в папке (Спам / Нежелательные).',
'Send Email': 'Отправить email',
'In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We got to make it easier for you to activate with each one.': 'Для повышения уровня безопасности вашей учетной записи мы рекомендуем вам включить двухэтапный вход. Вы можете включить следующий двухэтапный вход, который включает двухэтапный вход через Google, по электронной почте и SMS, а самым безопасным методом является двухэтапный вход через Google, но для вашего удобства мы предусмотрели три типа активации.',
'Selecting higher leverage such as [10x] increases your liquidation risk. Always manage your risk levels.': 'Выбор более высокого плеча, такого как [10x], увеличивает ваш риск ликвидации. Всегда управляйте своими уровнями риска.',
'Maximum position: 15,000,000 USDT': 'Максимальная позиция: 15,000,000 USDT',
'Adjust Leverage': 'Настройка плеча',
'Leverage': 'Плечо',
'Select Symbol': 'Выберите символ',
'See what the potential risk and reward will be in monetary terms on any given trade. Use our Futures Calculator to establish your potential profit/loss on a future trade. Read tips on how to use.':
'Посмотрите, каков потенциальный риск и вознаграждение в денежных терминах на любой сделке. Используйте наш калькулятор фьючерсов, чтобы определить ваш потенциальный прибыль/убыток по фьючерсной сделке. Прочтите советы по использованию.',
'Liquidation Price': 'Цена ликвидации',
'Target Price': 'Целевая цена',
'Initial Margin': 'Начальная маржа',
'Shot': 'Продажа',
'Long': 'Покупка',
'Entry Price': 'Цена входа',
'Quantity': 'Количество',
'Calculate': 'Рассчитать',
'Result': 'Результат',
'Maximum position': 'Максимальная позиция',
'Introduction code': 'Код представления',
'Introduction link': 'Ссылка на представление',
'No Data': 'Нет данных',
'Total': 'Итого',
'Number of referrals': 'Количество рефералов',
'Number of transactions': 'Количество сделок',
'Your total income is based on USD': 'Ваш общий доход основан на USD',
'introduction code': 'код представления',
'introduction link': 'ссылка на представление',
      //***********************************************************************//
'The total number of users': 'Общее количество пользователей',
'Total number of orders': 'Общее количество заказов',
'Total sales amount': 'Общая сумма продаж',
'Total number of purchases': 'Общее количество покупок',
'Total balance ( Fiat )': 'Общий баланс (Фиат)',
'Total order amount': 'Общая сумма заказов',
'Total sales': 'Общие продажи',
'Total amount of purchases': 'Общая сумма покупок',
'Opened Tickets': 'Открытые билеты',
'Existing users': 'Существующие пользователи',
'All tickets': 'Все билеты',
'Referral Users': 'Реферальные пользователи',
'Active users': 'Активные пользователи',
'Transaction': 'Транзакция',
'Purchases amount': 'Сумма покупок',
'Sales amount': 'Сумма продаж',
'Creat At': 'Создано',
'Updated At': 'Обновлено',
'Order': 'Заказ',
'Enter Order ID': 'Введите ID заказа',
'Order ID': 'ID заказа',
'First Name': 'Имя',
'Last Name': 'Фамилия',
'Open Trades': 'Открытые сделки',
'Tx-Code': 'Код Tx',
'Enter Tx-code': 'Введите код Tx',
'Tx Code': 'Код Tx',
'Paysafe Price': 'Цена Paysafe',
'Deposit Type': 'Тип депозита',
'Voucher Currency': 'Валюта ваучера',
'Paysafe History': 'История Paysafe',
'Perfect Money History': 'История Perfect Money',
'Fiat Amount': 'Сумма Фиата',
'Perfect Money Price': 'Цена Perfect Money',
'There is no record for this table': 'Записей в этой таблице нет',
'Origin': 'Происхождение',
'TxID': 'TxID',
'Entry Price': 'Цена входа',
'Leverage': 'Плечо',
'Margin Ratio': 'Маржинальное соотношение',
'User id': 'ID пользователя',
'Fiat withdraw transaction waiting': 'Ожидание транзакции вывода Fiat',
'You can see the list of fiat transactions whose information must be confirmed.': 'Вы можете увидеть список транзакций Fiat, информацию о которых необходимо подтвердить.',
'Fiat Type': 'Тип Fiat',
'Account': 'Аккаунт',
'Actions': 'Действия',
'Fiat deposit transaction waiting': 'Ожидание транзакции внесения Fiat',
'You can see the list of fiat transactions whose information must be confirmed.': 'Вы можете увидеть список транзакций Fiat, информацию о которых необходимо подтвердить.',
'Receipt': 'Квитанция',
'Token': 'Токен',
'Getway': 'Шлюз',
'Percentage in price for sale': 'Процент в цене на продажу',
'Purchase price in dollars': 'Цена покупки в долларах',
'Percentage on the price to buy': 'Процент на цену покупки',
'Percentage in the price for ( marketing )': 'Процент в цене на (маркетинг)',
'Selling price in dollars': 'Цена продажи в долларах',
'Percentage in price for sale': 'Процент в цене на продажу',
'Percentage in the price for ( marketing )': 'Процент в цене на (маркетинг)',
'Percentage in the price for purchase': 'Процент в цене на покупку',
'Percentage in price for sale': 'Процент в цене на продажу',
'Inventory': 'Инвентарь',
'Active': 'Активные',
'Minimum allowed purchase': 'Минимально допустимая покупка',
'Only Iranian IPs are allowed': 'Разрешены только иранские IP-адреса',
'Deactivating purchase from site': 'Деактивация покупки с сайта',
'IP Block': 'Блокировка IP',
'Num': 'Номер',
'Delete': 'Удалить',
'Withdrawal of cryptocurrency (equivalent to Dollar)': 'Вывод криптовалюты (эквивалент в долларах)',
'Buying digital money (equivalent to Dollar)': 'Покупка цифровых денег (эквивалент в долларах)',
'Fees for buy Tether': 'Сборы за покупку Tether',
'Marker': 'Маркер',
'Taker': 'Тейкер',
'Fees for internal transaction': 'Сборы за внутреннюю транзакцию',
'Min internal transaction': 'Минимальная внутренняя транзакция',
'Internal Transaction': 'Внутренняя транзакция',
'Internal Transaction': 'Внутренняя транзакция',
'Max Leverage': 'Максимальное плечо',
'Withdrawal of Fiat (equivalent to Dollar)': 'Вывод Fiat (эквивалент в долларах)',
'Buying digital money (equivalent to Rial)': 'Покупка цифровых денег (эквивалент в риалах)',
'Fees for Sell Tether': 'Сборы за продажу Tether',
'OTC Minimum Buy': 'Минимальная покупка OTC',
'Max internal transaction': 'Максимальная внутренняя транзакция',
'Max Margin': 'Максимальная маржа',
'Futures settings': 'Настройки фьючерсов',
'Disabled': 'Отключено',
'Enabled': 'Включено',
'Max Borrow': 'Максимальное заемное средство',
'Add More Coins': 'Добавить еще монет',
'List of bank accounts': 'Список банковских счетов',
'Bank name': 'Название банка',
'Card number': 'Номер карты',
'Account number': 'Номер счета',
'Shaba number': 'Номер Shaba',
'Add New': 'Добавить новое',
"Transfer methods": "Способы перевода",
"Name": "Имя",
"Card to Card": "Карта на карту",
"Bank deposit": "Банковский депозит",
"Remember me": "Запомнить меня",
"Web Advertisement Setting": "Настройки веб-рекламы",
"Show how many days": "Показать, сколько дней",
"Advanced editor": "Расширенный редактор",
"Mobile Advertisement Setting": "Настройки мобильной рекламы",
"Write Something awesome...": "Напишите что-то потрясающее...",
"Zibal": "Зибал",
"Vander": "Вандер",
"Default": "По умолчанию",
"Total users": "Всего пользователей",
"Verified users": "Подтвержденные пользователи",
"Users are waiting": "Пользователи ожидают",
"Rejected users": "Отклоненные пользователи",
"User Id": "Идентификатор пользователя",
"Mobile": "Мобильный",
"Tell": "Телефон",
"Select or Drag photo": "Выберите или перетащите фото",
"Introduced user": "Представленный пользователь",
"Total orders": "Всего заказов",
"Total commission orders": "Всего комиссионных заказов",
"Average commission per order": "Средняя комиссия за заказ",
"There is no record for this table": "Записей для этой таблицы нет",
"Fiat Wallet Transaction": "Транзакции с Fiat кошелька",
"Notification Tite": "Заголовок уведомления",
"Color background": "Цвет фона",
"Normal(black)": "Обычный (черный)",
"Users": "Пользователи",
"Active days": "Активные дни",
"Headers": "Заголовки",
"Notification /Email /SMS": "Уведомление / Email / SMS",
"Types": "Типы",
"Recipient": "Получатель",
"Color": "Цвет",
"Active Days": "Активные дни",
"Total Trade": "Всего сделок",
"Russia": "Россия",
"United Arab Emirates": "Объединенные Арабские Эмираты",
"Saudi Arabia": "Саудовская Аравия",
"User Statistics": "Статистика пользователя",
"Old Password": "Старый пароль",
"New Password": "Новый пароль",
"Confirm Password": "Подтвердите пароль",
"In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We`ve got to make it easier for you to activate with each one.": "Для повышения уровня безопасности вашей учетной записи мы рекомендуем включить двухэтапный вход. Вы можете включить следующий двухэтапный вход, который включает двухэтапный вход через Google, электронную почту и SMS, и самым безопасным методом является двухэтапный вход через Google, но чтобы учесть три типа, мы сделали процесс активации более простым для вас. с каждым из них.",
"To activate this feature, follow these steps: 1. Get the latest version of Google Authenticator from Google Play or Apple Store. 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "Чтобы активировать эту функцию, выполните следующие шаги: 1. Получите последнюю версию Google Authenticator из Google Play или Apple Store. 2. После установки и запуска программы Google Authenticator с помощью одного из следующих методов, добавьте ключ в программу. - Сканировать штрих-код: Выберите эту опцию и отсканируйте следующий штрих-код.",
"- Enter a provided key: Select this option and enter the following code carefully.": "- Введите предоставленный ключ: Выберите эту опцию и введите следующий код осторожно.",
"3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. Введите полученный код (6-значное число) в поле ниже и нажмите кнопку активации.",
"Google Code": "Код Google",
"New Email": "Новый адрес электронной почты",
"Add Mobile": "Добавить мобильный",
"Internal Server Error": "Внутренняя ошибка сервера",
"Only normal user allowed": "Разрешены только обычные пользователи",
"Please input your old password!": "Пожалуйста, введите ваш старый пароль!",
"Passwords must have at least 8 characters and contain at least one uppercase letter, lowercase letter, number, and symbol.": "Пароли должны содержать не менее 8 символов и включать хотя бы одну заглавную букву, строчную букву, цифру и символ.",
"Please input your confirm password!": "Пожалуйста, подтвердите ваш пароль!",
"Invalid soft otp": "Недействительный мягкий OTP",
"Session not found": "Сессия не найдена",
"Send SMS": "Отправить SMS",
"send SMS otp need phone number!": "Для отправки SMS OTP необходим номер телефона!",
"User Id": "Идентификатор пользователя",
"Final amount": "Итоговая сумма",
"Fiat Type": "Тип Fiat",
"Waller ID": "Идентификатор кошелька",
"Pay Safe Voucher": "Платежный ваучер Pay Safe",
"Pay Safe": "Pay Safe",
"Black": "Черный",
"Red": "Красный",
"Green": "Зеленый",
"Advanced level": "Продвинутый уровень",
"Fixed alert": "Фиксированный алерт",
"Notification Fixed alert": "Уведомление о фиксированном алерте",
"Test": "Тест",
"Fixed alert test": "Тест фиксированного алерта",
"Fixed alert test 2": "Тест фиксированного алерта 2",
"Welcome back": "Добро пожаловать обратно",
"Please active your 2FA Authenticator": "Пожалуйста, активируйте ваш 2FA Authenticator",
"Success (green)": "Успех (зеленый)",
"Danger (red)": "Опасность (красный)",
"Warning (yellow)": "Предупреждение (желтый)",
"Order Book": "Книга заказов",
"Futures Fee(Marker)": "Комиссия по фьючерсам (Создатель)",
"Futures Fee(Taker)": "Комиссия по фьючерсам (Забирающий)",
"Trade Fee(Marker)": "Комиссия за торговлю (Создатель)",
"Trade Fee(Traker)": "Комиссия за торговлю (Забирающий)",
      'Popup link': 'Popup link',
      'Please choose an image': 'Пожалуйста, выберите изображение',
      Tokens: 'Токены',
      'Add token': 'Добавить токен',
      'Main wallet setting': 'Основные настройки кошелька',
      'Network setting': 'Настройка сети',
      "Listing":'Листинг',
      "Cryptizi offers a powerful platform to take advantage of a broad user base and solid infrastructure, improving your token's market visibility and accessibility." : "Cryptizi предлагает мощную платформу, позволяющую воспользоваться широкой базой пользователей и надежной инфраструктурой, улучшая видимость и доступность вашего токена на рынке.",
      "Application Process" :'Прикладной процесс',
      "Listing Application" : 'Заявка на листинг',
      "To apply for listing, simply click on 'Listing Application'. Our business manager will promptly get in touch with you.": "Чтобы подать заявку на листинг, просто нажмите «Заявка на листинг». Наш бизнес-менеджер оперативно свяжется с вами.",
      "For any queries, reach out to us at listing@cryptizi.com. Thank you!":"По любым вопросам обращайтесь к нам по адресу listing@cryptizi.com. Спасибо!" ,
      "Ticket Number" : 'Номер билета',
      "Created At" : 'Создан в',
      "Dedicated Wallet Settings" : 'Специальная настройка кошелька'
    },
  };
  
  export default ru;
  