import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { Stack, Drawer, Typography, Link } from '@mui/material';

import useResponsive from '../../hooks/useResponsive';
import cssStyles from '../../utils/cssStyles';
import Scrollbar from '../Scrollbar';
import { adminNavConfig, userNavConfig } from '../../navConfig';
import Navbar from './navbar';
import CollapseButton from './collapseButton';
import { NAVBAR } from '../../layouts/mainLayout/mainLayout';
import Image from '../Image';
import { navigationUrl } from '../../navigationUrl';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

SideMenu.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function SideMenu({
  isOpenSidebar,
  onCloseSidebar,
  isCollapse,
  collapse,
  onToggleCollapse,
  onHoverEnter,
  onHoverLeave,
  isAdmin = false,
}) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          backgroundColor: 'grey.900',
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Link component={RouterLink} to={isAdmin ? navigationUrl.admin : navigationUrl.panel} underline='none'>
            <Stack direction={theme.direction === 'rtl' ? 'row-reverse' : 'row'} alignItems={'center'}>
              <Image src={'/assets/logo.png'} sx={{ width: 34 }} disabledEffect />
              <Typography
                variant='h5_secondary'
                fontFamily={'Graphik'}
                color='#19dab6'
                sx={{ ...(isCollapse && { display: 'none' }), ml: 2 }}
              >
                Cryptizi
              </Typography>
            </Stack>
          </Link>

          {isDesktop && !isCollapse && (
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapse.click} />
          )}
        </Stack>
      </Stack>

      <Navbar
        navConfig={isAdmin ? adminNavConfig : userNavConfig}
        isCollapse={isCollapse}
        sx={{
          backgroundColor: 'grey.900',
        }}
      />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapse.click && {
          position: 'absolute',
        }),
      }}
      className='no-print'
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant='persistent'
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapse.hover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
