import { useEffect, useState } from 'react';

import Api from '../../../../../http/serviceApi';

export default function useTicketInit() {
  const [transactions, setTransactions] = useState([]);

  // -------------
  const getTransactions = async () => {
    try {
      const data = await Api.getTicketTransactions();
      setTransactions(data.transactionInfoForTicketInfos);
    } catch (e) {}
  };

  // -------------
  useEffect(() => {
    getTransactions();
  }, []);

  // -------------
  return {
    transactions,
  };
}
