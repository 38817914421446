import { useEffect, useState } from 'react';
import { Box, Card, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'jalali-moment';

import useTable, { getComparator } from '../../../../hooks/useTable';
import { PageTitle } from '../../../../components/PageTitle';
import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../components/table';
import { applySortFilter, prettyUppercases } from '../../../../utils';
import TradeHistoryTableRow from './tradeHistoryTableRow';
import Api from '../../../../http/serviceApi';
import AdminApi from '../../../../http/adminHttp/serviceApiAdmin';
import TradeHistoryTableToolbar from './tradeHistoryTableToolbar';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { exportExcel } from '../../../../utils/excel';
import TradeHistoryModal from './tradeHistoryModal';
import { getDefaultDateFilterValues } from '../../../../utils/filter';
import { exportPDF } from '../../../../utils/pdf';

// ----------------------------------------------------------------------

export default function TradeHistoryTable({ isAdmin = false, showOpenTrades = false }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((store) => store.language);

  // --------------------
  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: showOpenTrades ? 'Open Trades' : 'Trades History' },
    });
  }, []);

  // --------------------
  const TABLE_HEAD = [
    { id: 'createdDate', label: 'Time', align: 'left' },
    ...(isAdmin ? [{ id: 'user', label: 'User', align: 'left' }] : []),
    { id: 'pair', label: 'Pair', align: 'left' },
    { id: 'type', label: 'Type', align: 'center' },
    { id: 'side', label: 'Side', align: 'center' },
    { id: 'price', label: 'Price', align: 'center' },
    { id: 'amount', label: 'Amount', align: 'center' },
    { id: 'executed', label: 'Filled', align: 'center' },
    { id: 'total', label: 'Total', align: 'center' },
    { id: 'state', label: 'State', align: 'center' },
    { id: 'detail', label: 'Detail', align: 'center' },
  ];

  // --------------------
  const [sideFilter, setSideFilter] = useState('ALL'); // ALL, BUY, SELL
  const [typeFilter, setTypeFilter] = useState('ALL'); // ALL, LIMIT, MARKET, STOP_LIMIT
  const [stateFilter, setStateFilter] = useState('ALL'); // ALL, CANCELED, EXPIRED, FILLED, NEW, PARTIALLY_FILLED, PENDING_CANCEL, REJECTED
  const [timeFilter, setTimeFilter] = useState({
    startTime: getDefaultDateFilterValues().firstDay,
    endTime: getDefaultDateFilterValues().lastDay,
  });
  const [transactionIdFilter, setTransactionIdFilter] = useState('');
  const [symbolFilter, setSymbolFilter] = useState('');
  const [amountFilter, setAmountFilter] = useState([0, 100000]);
  const [firstNameFilter, setFirstNameFilter] = useState('');
  const [lastNameFilter, setLastNameFilter] = useState('');

  // --------------------
  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  // --------------------
  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });
  const [showModalDetail, setShowModalDetail] = useState(null);

  // --------------------
  const getData = async () => {
    try {
      const filters = {
        side: sideFilter,
        state: stateFilter,
        type: typeFilter,
        endTime: timeFilter.endTime?.valueOf?.(),
        startTime: timeFilter.startTime?.valueOf?.(),
        amount: amountFilter[0],
        amount2: amountFilter[1],
        ...(transactionIdFilter && { transactionId: transactionIdFilter }),
        ...(symbolFilter && { symbol: `${symbolFilter}USDT` }),
        ...(isAdmin && {
          ...(firstNameFilter && { firstName: firstNameFilter }),
          ...(lastNameFilter && { lastName: lastNameFilter }),
        }),
      };

      let response;

      if (isAdmin) {
        response = await AdminApi.getReportTradeHistory(page, rowsPerPage, filters);
      } else {
        response = await Api.getUserTradeHistory(page, rowsPerPage, filters);
      }

      if (showOpenTrades) {
        setTableData(response.openTradeInfos);
        setTablePagination({ totalItems: response.openTradeInfosTotalPages * rowsPerPage });
      } else {
        setTableData(response.tradeHistoryInfos);
        setTablePagination({ totalItems: response.tradeHistoryInfosTotalPages * rowsPerPage });
      }
    } catch (e) {
      setTableData([]);
    }
  };

  // --------------------
  useEffect(() => {
    getData();
  }, [
    page,
    rowsPerPage,
    sideFilter,
    stateFilter,
    typeFilter,
    timeFilter,
    transactionIdFilter,
    symbolFilter,
    amountFilter,
    firstNameFilter,
    lastNameFilter,
  ]);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);

  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    const filters = {
      side: sideFilter,
      state: stateFilter,
      type: typeFilter,
      endTime: timeFilter.endTime?.valueOf?.(),
      startTime: timeFilter.startTime?.valueOf?.(),
      amount: amountFilter[0],
      amount2: amountFilter[1],
      ...(transactionIdFilter && { transactionId: transactionIdFilter }),
      ...(symbolFilter && { symbol: `${symbolFilter}USDT` }),
      ...(isAdmin && {
        ...(firstNameFilter && { firstName: firstNameFilter }),
        ...(lastNameFilter && { lastName: lastNameFilter }),
      }),
    };

    try {
      let response;
      if (isAdmin) {
        response = await AdminApi.getReportTradeHistory(0, 10000000, filters);
      } else {
        response = await Api.getUserTradeHistory(0, 10000000, filters);
      }

      const heads = [
        '#',
        t('Time'),
        ...(isAdmin ? [t('Name'), t('Email'), t('Mobile')] : []),
        t('Pair'),
        t('Type'),
        t('Side'),
        t('Price'),
        t('Amount'),
        t('Filed'),
        t('Total'),
        t('Commission'),
        t('State'),
      ];

      const columnsWidth = [5, 15, ...(isAdmin ? [20, 30, 13] : []), 15, 9, 6, 12, 12, 12, 12, 12, 12];

      // generate excel and download
      const config = {
        language,
        fileName: t('Admins'),
        heads: heads,
        columnsWidth,
        list: response.tradeHistoryInfos.map((item) => [
          moment(item.createdDate).locale(language).format('YYYY-MM-DD HH:mm'),
          ...(isAdmin ? [`${item.firstName} ${item.lastName}`, item.emailAddress || '-', item.mobile || '-'] : []),
          item.symbol,
          t(prettyUppercases(item.type)),
          t(prettyUppercases(item.side)),
          item.price,
          item.amount,
          item.executed,
          item.total,
          item.commission,
          t(prettyUppercases(item.state)),
        ]),
      };
      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  return (
    <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 }, mx: { xs: 2, lg: 0 } }}>
      <PageTitle
        title={showOpenTrades ? 'Open Trades' : 'History of your trades'}
        subTitle={
          showOpenTrades ? '' : 'You can enter the name and symbol of Coin, transaction ID, etc. in the search field'
        }
      />

      <Box sx={{ mt: 5 }}>
        {!showOpenTrades && (
          <TradeHistoryTableToolbar
            isAdmin={isAdmin}
            onExport={onExport}
            isExportLoading={isExportLoading}
            sideFilter={sideFilter}
            setSideFilter={setSideFilter}
            typeFilter={typeFilter}
            setTypeFilter={setTypeFilter}
            stateFilter={stateFilter}
            setStateFilter={setStateFilter}
            timeFilter={timeFilter}
            setTimeFilter={setTimeFilter}
            transactionIdFilter={transactionIdFilter}
            setTransactionIdFilter={setTransactionIdFilter}
            symbolFilter={symbolFilter}
            setSymbolFilter={setSymbolFilter}
            amountFilter={amountFilter}
            setAmountFilter={setAmountFilter}
            firstNameFilter={firstNameFilter}
            setFirstNameFilter={setFirstNameFilter}
            lastNameFilter={lastNameFilter}
            setLastNameFilter={setLastNameFilter}
          />
        )}

        <Scrollbar>
          <TableContainer sx={{ position: 'relative' }}>
            <Table size={'small'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length || 0}
                onSort={onSort}
              />

              <TableBody>
                {tableData === null ? (
                  <TableLoading isLoading />
                ) : (
                  <>
                    {applySortFilter({
                      tableData,
                      comparator: getComparator(order, orderBy),
                    }).map((row) => (
                      <TradeHistoryTableRow
                        key={row.orderId}
                        row={row}
                        isAdmin={isAdmin}
                        onShowModalDetail={() => setShowModalDetail(row)}
                      />
                    ))}

                    <TableNoData isNotFound={!tableData?.length} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        {tableData?.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            component='div'
            count={tablePagination.totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
          />
        )}
      </Box>

      <TradeHistoryModal
        open={!!showModalDetail}
        onClose={() => setShowModalDetail(null)}
        data={showModalDetail}
        isAdmin={isAdmin}
      />
    </Card>
  );
}
