import { useState } from 'react';
import { Stack, Button, Slider, Box, Typography, Grid, Tabs, Tab, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

import ModalContainer from '../../../../components/ModalContainer';
import { fNumber } from '../../../../utils/formatNumber';
import { leverageMarks } from './futuresLeverageModal';
import { CustomNumberField } from '../futuresForm';
import Api from '../../../../http/serviceApi';

const FuturesCalculatorModal = ({ open, onClose, initLeverage, selectedSymbol }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  // ---------------
  const [type, setType] = useState('PNL');
  const [position, setPosition] = useState('LONG');
  const [entryPrice, setEntryPrice] = useState('');
  const [exitPrice, setExitPrice] = useState('');
  const [ROE, setROE] = useState('');
  const [quantity, setQuantity] = useState('');
  const [result, setResult] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // ---------------
  const [leverage, setLeverage] = useState(initLeverage);
  useEffect(() => {
    setLeverage(initLeverage);
  }, [initLeverage]);

  // ---------------
  const onCalculate = async () => {
    if (!entryPrice) {
      return enqueueSnackbar(t('Please fill in all fields'), { variant: 'warning' });
    }
    if (type === 'PNL' && (!exitPrice || !quantity)) {
      return enqueueSnackbar(t('Please fill in all fields'), { variant: 'warning' });
    }
    if (type === 'TARGET_PRICE' && !ROE) {
      return enqueueSnackbar(t('Please fill in all fields'), { variant: 'warning' });
    }
    if (type === 'LIQUIDATION_PRICE' && !quantity) {
      return enqueueSnackbar(t('Please fill in all fields'), { variant: 'warning' });
    }

    setIsSubmitting(true);

    try {
      const r = await Api.calculateFutures({
        entryPrice,
        exitPrice,
        leverage,
        quantity,
        roe: ROE,
        side: position,
        type,
      });

      setResult(r);
    } catch (e) {
      setResult(null);
    }

    setIsSubmitting(false);
  };

  // ---------------
  return (
    <ModalContainer open={open} onClose={onClose} width={800}>
      <Tabs
        value={type}
        onChange={(e, newTab) => setType(newTab)}
        sx={{
          px: 1,
          mb: 3,
          '& button': {
            typography: 'body1',
            color: 'grey.300',
            borderRadius: 1.5,
            minHeight: 40,
            '&.Mui-selected': { color: 'warningButton', bgcolor: 'transparent' },
          },
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
      >
        <Tab label={t('PNL')} value={'PNL'} sx={{ minWidth: 55 }} />
        <Tab label={t('Target Price')} value={'TARGET_PRICE'} sx={{ minWidth: 110 }} />
        <Tab label={t('Liquidation Price')} value={'LIQUIDATION_PRICE'} sx={{ minWidth: 140 }} />
      </Tabs>

      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            borderBottom: { xs: `1px solid ${theme.palette.grey[600]}`, md: 0 },
            borderRight: { md: `1px solid ${theme.palette.grey[600]}` },
            pr: { xs: 0, md: 3 },
            pl: { xs: 0, md: 1 },
          }}
        >
          <Stack sx={{ mb: 1 }} spacing={3}>
            <Stack direction='row'>
              <PositionButton
                color='primary'
                title={'Long'}
                isLeft
                isSelected={position === 'LONG'}
                onClick={() => setPosition('LONG')}
              />
              <PositionButton
                color='error'
                title={'Short'}
                isLeft={false}
                isSelected={position === 'SHORT'}
                onClick={() => setPosition('SHORT')}
              />
            </Stack>

            <Stack alignItems='center'>
              <Box sx={{ width: '90%' }}>
                <Slider
                  fullWidth
                  size='small'
                  getAriaValueText={(value) => `${value}x`}
                  marks={leverageMarks}
                  valueLabelDisplay='auto'
                  min={1}
                  max={125}
                  value={leverage}
                  onChange={(e, newValue) => setLeverage(newValue)}
                />
              </Box>
            </Stack>

            <Typography variant='body3' color='grey.300' sx={{ px: 1 }}>
              {t('Maximum position')}: {fNumber(15000000)} {selectedSymbol.quoteAsset}
            </Typography>

            <CustomNumberField
              label={t('Entry Price')}
              symbol={selectedSymbol.quoteAsset}
              value={entryPrice}
              onChange={setEntryPrice}
            />

            {type === 'PNL' && (
              <CustomNumberField
                label={t('Exit Price')}
                symbol={selectedSymbol.quoteAsset}
                value={exitPrice}
                onChange={setExitPrice}
              />
            )}

            {type === 'TARGET_PRICE' && (
              <CustomNumberField label={t('ROE')} symbol={'%'} value={ROE} onChange={setROE} />
            )}

            {(type === 'PNL' || type === 'LIQUIDATION_PRICE') && (
              <CustomNumberField
                label={t('Quantity')}
                symbol={selectedSymbol.baseAsset}
                value={quantity}
                onChange={setQuantity}
              />
            )}

            <LoadingButton
              fullWidth
              size='large'
              variant='contained'
              sx={{ mt: 2 }}
              onClick={onCalculate}
              loading={isSubmitting}
            >
              {t('Calculate')}
            </LoadingButton>
          </Stack>
        </Grid>

        {/* ********************************** */}
        <Grid item xs={12} md={6} sx={{ pl: { xs: 0, md: 3 }, pr: { xs: 0, md: 1 } }}>
          <Typography variant='subtitle1'>{t('Result')}</Typography>

          <Stack sx={{ mt: 4 }} spacing={2}>
            {type === 'PNL' && (
              <>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography variant='body2' color='grey.300'>
                    {t('Initial Margin')}
                  </Typography>
                  <Typography variant='body2'>
                    {result?.margin || '-'} {selectedSymbol.quoteAsset}
                  </Typography>
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography variant='body2' color='grey.300'>
                    {t('PNL')}
                  </Typography>
                  <Typography variant='body2'>
                    {result?.pnl || '-'} {selectedSymbol.quoteAsset}
                  </Typography>
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography variant='body2' color='grey.300'>
                    {t('ROE')}
                  </Typography>
                  <Typography variant='body2'>{result?.roe || '-'} %</Typography>
                </Stack>
              </>
            )}

            {type === 'TARGET_PRICE' && (
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant='body2' color='grey.300'>
                  {t('Target Price')}
                </Typography>
                <Typography variant='body2'>
                  {result?.targetPrice || '-'} {selectedSymbol.quoteAsset}
                </Typography>
              </Stack>
            )}

            {type === 'LIQUIDATION_PRICE' && (
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant='body2' color='grey.300'>
                  {t('Liquidation Price')}
                </Typography>
                <Typography variant='body2'>
                  {result?.liquidationPrice || '-'} {selectedSymbol.quoteAsset}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>

      <Box sx={{ mt: 3 }}>
        <Typography variant='body3' color='grey.300'>
          {t(
            'See what the potential risk and reward will be in monetary terms on any given trade. Use our Futures Calculator to establish your potential profit/loss on a future trade. Read tips on how to use.'
          )}
        </Typography>
      </Box>
    </ModalContainer>
  );
};

export default FuturesCalculatorModal;

// ----------------------------------------
const PositionButton = ({ title, color = 'primary', isSelected = false, isLeft = false, onClick }) => {
  const { t } = useTranslation();

  return (
    <Button
      variant='contained'
      sx={{
        borderRadius: 1,
        typography: 'subtitle1',
        height: 40,
        backgroundImage: 'none',
        ...(isLeft
          ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
          : { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }),
        ...(!isSelected && { bgcolor: 'grey.400', '&:hover': { bgcolor: 'grey.600' } }),
        ...(isSelected && color === 'primary' && { bgcolor: 'primary.main', '&:hover': { bgcolor: 'primary.dark' } }),
      }}
      color={color}
      onClick={onClick}
      fullWidth
    >
      {t(title)}
    </Button>
  );
};
