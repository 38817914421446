import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ModalContainer from '../../../../components/ModalContainer';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';

import { prettyUppercases } from '../../../../utils';
import SymbolIcon from '../../../../components/SymbolIcon';
import { fFloat } from '../../../../utils/formatNumber';
import Scrollbar from '../../../../components/Scrollbar';

export default function FuturesHistoryModal({
  open,
  onClose,
  data,
  side = 'LONG',

  isAdmin = false,
}) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const status = data?.status
  return (
    <ModalContainer open={open} onClose={onClose} title={'Details'} width={600}>
      <Stack sx={{ mt: 0.5, alignItems: 'center' }}>
        <SymbolIcon symbol={data?.symbol} width={50} height={50} />
        <Typography variant='body2' color={'grey.100'} fontWeight={500} sx={{ mt: 1, mb: 0.5}}>
          {data?.symbol}
        </Typography>

        <Box
          sx={{
            width: 120,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            mb: 3,
            p: 0.8,
            bgcolor:
              (status === 'CLOSED' && 'rgba(48, 224, 161, 0.1)') ||
              (status === 'CANCELED' && 'rgba(224, 48, 48, 0.1)') ||
              'rgba(250, 174, 34, 0.1)',
          }}
        >
          <Typography
            variant='body2'
            sx={{
              color:
                (status === 'CLOSED' && '#30E0A1') || (status === 'CANCELED' && 'negative.main') || 'rgb(218, 146, 13)',
            }}
          >
            {t(prettyUppercases(status))}
          </Typography>
        </Box>
      </Stack>

      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} sx={{ mb: 1.5 }} spacing={2}>
        {isAdmin && (
          <Stack spacing={1.5}>
            <Stack direction={'row'} alignItems={'center'} spacing={4}>
              <Typography variant='body3' color={'grey.400'} sx={{ width: 40 }}>
                {t('User')}
              </Typography>
              <Typography variant='body3' color={'grey.100'}>
                {data?.firstName} {data?.lastName}
              </Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} spacing={4}>
              <Typography variant='body3' color={'grey.400'} sx={{ width: 40 }}>
                {t('Mobile')}
              </Typography>
              <Typography variant='body3' color={'grey.100'}>
                {data?.mobile || '-'}
              </Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} spacing={4}>
              <Typography variant='body3' color={'grey.400'} sx={{ width: 40 }}>
                {t('Email')}
              </Typography>
              <Typography variant='body3' color={'grey.100'}>
                {data?.emailAddress || '-'}
              </Typography>
            </Stack>
          </Stack>
        )}

        <Stack spacing={1.5}>
          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Side')}
            </Typography>
            <Typography
              variant='body4'
              color={(side === 'LONG' && 'positive.main') || (side === 'SHORT' && 'negative.main') || 'grey.100'}
            >
              {side}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Date')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>

              {`${new Date(data?.createDate).getFullYear()}/${new Date(data?.createDate).getMonth() + 1}/${new Date(data?.createDate).getDate()}`}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('TxID')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {data?.transactionId || ''}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Tx Code')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {data?.transactionCode || ''}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Scrollbar>
        <Table size='small' sx={{ mt: 5, ...(isLight && { bgcolor: 'grey.700', px: 0.5, borderRadius: 1 }) }}>
          <TableHead>
            <TableRow sx={{ '& th': { typography: 'body2' } }}>
              <TableCell align='left'>{t('Amount')}</TableCell>
              <TableCell align='center'>{t('Entry Price')}</TableCell>
              <TableCell align='center'>{t('Leverage')}</TableCell>
              <TableCell align='center'>{t('PNL')}</TableCell>
              <TableCell align='center'>{t('Margin')}</TableCell>
              <TableCell align='center'>{t('Margin Ratio')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ '& td': { typography: 'body2' } }}>
            <TableRow>
              <TableCell align='center'>{data?.amount}</TableCell>
              <TableCell align='center'>{fFloat(data?.entryPrice, 4)}</TableCell>
              <TableCell align='center'>{data?.leverage}</TableCell>
              <TableCell align='center'>{fFloat(data?.pnl, 4)}</TableCell>
              <TableCell align='center'>{fFloat(data?.margin, 4)}</TableCell>
              <TableCell align='center'>{fFloat(data?.marginRatio, 4)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Scrollbar>

      <Button
        fullWidth
        variant='contained'
        size='large'
        sx={{
          typography: 'body3',
          fontWeight: 500,
          mt: 4.25,
        }}
        onClick={onClose}
      >
        {t('Close')}
      </Button>
    </ModalContainer>
  );
}
