import { useState } from 'react';
import { CircularProgress, IconButton, MenuItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ActiveCryptoModal from '../../../../components/activeCryptoModal/activeCryptoModal';
import FillSelect from '../../../../components/FillSelect';
import FillTextField from '../../../../components/FillTextField';
import Iconify from '../../../../components/Iconify';
import { NumberField } from '../../../../components/NumberField';
import FillRangeDatePicker from '../../../../components/FillRangeDatePicker';
import { useSelector } from 'react-redux';

export const TYPES = [
  // { value: 'ALL', label: 'All' },
  { value: 'Deposit', label: 'Deposit' },
  { value: 'Withdraw', label: 'Withdraw' },
];

export const FIAT_TYPES = [
  { value: 'ALL', label: 'All' },
  { value: 'AUD', label: 'AUD' },
  { value: 'BRL', label: 'BRL' },
  { value: 'EUR', label: 'EUR' },
  { value: 'GBP', label: 'GBP' },
  { value: 'IRR', label: 'IRR' },
  { value: 'NGN', label: 'NGN' },
  { value: 'PLN', label: 'PLN' },
  { value: 'RON', label: 'RON' },
  { value: 'RUB', label: 'RUB' },
  { value: 'TRY', label: 'TRY' },
  { value: 'UAH', label: 'UAH' },
  { value: 'USD', label: 'USD' },
  { value: 'ZAR', label: 'ZAR' },
];

export const NEW_FIAT_TYPES_WITHOUT_IRR = [
  { value: 'ALL', label: 'All' },
  { value: 'EUR', label: 'EUR' },
  { value: 'USD', label: 'USD' },

];

export const FIAT_TYPES_JUST_IRR_EUR_USD = [
  { value: 'ALL', label: 'All' },
  { value: 'EUR', label: 'EUR' },
  { value: 'USD', label: 'USD' },
  { value: 'IRR', label: 'IRR' },

];

export const DEPOSIT_STATUS = [
  { value: 'ALL', label: 'All' },
  { value: 'CREDITED', label: 'Credited' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'SUCCESS', label: 'Success' },
];

export const WITHDRAW_STATUS = [
  { value: 'All', label: 'All' },
  { value: 'Awaiting_Approval', label: 'Awaiting Approval' },
  { value: 'Cancelled', label: 'Cancelled' },
  { value: 'Completed', label: 'Completed' },
  { value: 'Email_Sent', label: 'Email_Sent' },
  { value: 'Failure', label: 'Failure' },
  { value: 'Processing', label: 'Processing' },
  { value: 'Rejected', label: 'Rejected' },
];

export default function CryptoTransactionTableToolbar({
  onlyInternalType = false,
  typeFilter,
  fiatTypeFilter,
  setFiatTypeFilter,
  depositStatusFilter,
  withdrawStatusFilter,
  timeFilter,
  transactionIdFilter,
  symbolFilter,
  setTypeFilter,
  setDepositStatusFilter,
  setWithdrawStatusFilter,
  setTimeFilter,
  setTransactionIdFilter,
  setSymbolFilter,
  isExportLoading = false,
  onExport = () => {},
  isAdmin
}) {
  const { t } = useTranslation();
  const [openSymbolModal, setOpenSymbolModal] = useState(false);
  const isIranianPaymentActive = useSelector((store) => store.isIranianPaymentActive);

  const allFiats = isIranianPaymentActive ?  FIAT_TYPES_JUST_IRR_EUR_USD :isAdmin ? FIAT_TYPES_JUST_IRR_EUR_USD : NEW_FIAT_TYPES_WITHOUT_IRR

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent='space-between'
      alignItems={'center'}
      spacing={2}
      sx={{ mb: { xs: 3, md: 9 } }}
    >
      <Stack sx={{ width: '100%' }} flexDirection={'row'} alignItems={'flex-end'} gap={'15px'} flexWrap={'wrap'}>
        <FillRangeDatePicker
          value={timeFilter}
          setValue={setTimeFilter}
          variant='outlined'
          fullWidth
          sx={{ width: { xs: '100%', md: 335 } }}
          InputProps={null}
        />

        <FillSelect
          value={fiatTypeFilter}
          onChange={setFiatTypeFilter}
          label={t('Fiat Type')}
          sx={{ width: { xs: '100%', md: 150 } }}
        >
          {allFiats.map((item) => (
            <MenuItem key={item.value} value={item.value} sx={{ typography: 'body2' }}>
              {t(item.label)}
            </MenuItem>
          ))}
        </FillSelect>

        {!onlyInternalType && (
          <FillSelect
            value={typeFilter}
            onChange={setTypeFilter}
            label={t('Type')}
            sx={{ width: { xs: '100%', md: 150 } }}
          >
            {TYPES.map((item) => (
              <MenuItem key={item.value} value={item.value} sx={{ typography: 'body2' }}>
                {t(item.label)}
              </MenuItem>
            ))}
          </FillSelect>
        )}

        <FillTextField
          value={symbolFilter}
          onChange={setSymbolFilter}
          label={t('Asset')}
          sx={{ width: { xs: '100%', md: 150 } }}
          onClick={() => setOpenSymbolModal(true)}
          isDebounced
          hasCloseButton
        />

        {typeFilter === 'Deposit' ? (
          <FillSelect
            value={depositStatusFilter}
            onChange={setDepositStatusFilter}
            label={t('Status')}
            sx={{ width: { xs: '100%', md: 150 } }}
          >
            {DEPOSIT_STATUS.map((item) => (
              <MenuItem key={item.value} value={item.value} sx={{ typography: 'body2' }}>
                {t(item.label)}
              </MenuItem>
            ))}
          </FillSelect>
        ) : (
          <FillSelect
            value={withdrawStatusFilter}
            onChange={setWithdrawStatusFilter}
            label={t('Status')}
            sx={{ width: { xs: '100%', md: 150 } }}
          >
            {WITHDRAW_STATUS.map((item) => (
              <MenuItem key={item.value} value={item.value} sx={{ typography: 'body2' }}>
                {t(item.label)}
              </MenuItem>
            ))}
          </FillSelect>
        )}

        <FillTextField
          value={transactionIdFilter}
          onChange={setTransactionIdFilter}
          label={t('TxID')}
          placeholder={t('Enter TxID')}
          sx={{ width: { xs: '100%', md: 170 } }}
          isDebounced
        />
      </Stack>

      {onExport && (
        <>
          {isExportLoading ? (
            <CircularProgress size={22} color='success' sx={{ margin: '11px !important' }} />
          ) : (
            <>
              <IconButton onClick={onExport}>
                <Iconify icon='tabler:receipt' sx={{ width: 28, height: 28 }} />
              </IconButton>
              <IconButton onClick={() => onExport('PDF')}>
                <Iconify icon='majesticons:printer-line' sx={{ width: 28, height: 28 }} />
              </IconButton>
            </>
          )}
        </>
      )}

      <ActiveCryptoModal
        open={openSymbolModal}
        onClose={() => setOpenSymbolModal(false)}
        onSelect={setSymbolFilter}
        title='Select Asset'
      />
    </Stack>
  );
}
