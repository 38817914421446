import React, { useState } from 'react';
import * as Yup from 'yup';
import { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { Box, IconButton, InputAdornment, Link, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Api from '../../http/serviceApi';
import { FormProvider, RHFCheckbox, RHFTextField } from '../hookForm';
import Iconify from '../Iconify';

import SocialLogin from '../SocialLogin';

const LoginForm = ({ onSuccess }) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const validators = Yup.object().shape({
    emailAddress: Yup.string().required(t('Please input your username!')),
    password: Yup.string().required(t('Please input your password!')),
  });

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues: {
      emailAddress: '',
      password: '',
      remember: true,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (payload) => {
    try {
      const response = await Api.userLogin({ ...payload, captchaData: 'captcha', captchaId: 'captchaId' });

      if (response?.status === 'SUCCESS') {
        onSuccess(response, payload.emailAddress);
      }
    } catch (e) {}
  };

  return (
    <>
      <Box>
        <Typography variant='h4' color={'grey.100'} sx={{ fontWeight: 400 }}>
          {t('Hi, Welcome Back')}
        </Typography>
        <Typography variant='body4' color={'grey.400'}>
          {t('Best Wallet For Your Coins')}
        </Typography>
      </Box>

      <Box sx={{ width: 320, mt: 8 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <RHFTextField
              name='emailAddress'
              label={'Username'}
              variant='standard'
              fullWidth
              InputProps={{
                sx: css`
                  input {
                    /* @noflip */
                    direction: ltr;
                  }
                `,
                endAdornment: (
                  <InputAdornment position='end'>
                    <Iconify icon={'ant-design:user-add-outlined'} />
                  </InputAdornment>
                ),
              }}
            />

            <RHFTextField
              name='password'
              label={'Password'}
              variant='standard'
              type={showPassword ? 'text' : 'password'}
              fullWidth
              InputProps={{
                sx: css`
                  input {
                    /* @noflip */
                    direction: ltr;
                  }
                `,
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                      <Iconify
                        icon={showPassword ? 'ant-design:eye-outlined' : 'ant-design:eye-invisible-outlined'}
                        sx={{ width: 18, mr: 0.5 }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <RHFCheckbox
                name='remember'
                label={'Remember me'}
                sx={{ '& .MuiTypography-root': { color: 'grey.400', fontSize: 14 } }}
              />

              <Link component={RouterLink} variant='body4' to={'/forgotpass'} sx={{ color: 'grey.400' }}>
                {t('Forgot Password')}
              </Link>
            </Stack>
          </Stack>

          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            loading={isSubmitting}
            sx={{ mt: 6.25 }}
          >
            {t('Login')}
          </LoadingButton>
        </FormProvider>

        <SocialLogin />
      </Box>
    </>
  );
};

export default LoginForm;
