import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import Api from '../../../http/serviceApi';

export default function useFutureWallet() {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);

  const [userWallet, setUserWallet] = useState({

    "amount": 0,
    "blocked": 0,
    "btcValue": 0,
    "message": "string",
    "orderMargin": 0,
    "pnl": 0,
    "positionMargin": 0,
    "status": "FAILURE",
    "usdValue": 0

  });

  useEffect(() => {
    getUserWallet();
  }, []);

  const getUserWallet = async () => {
    setLoading(true);

    const response = await Api.getUserFutureWallet();
    if (response  ) {
      setUserWallet(response);
    } else if (response?.status === 'FAILURE') {
      enqueueSnackbar(response.message, { variant: 'error' });
    }

    setLoading(false);
  };

  return { userWallet, loading, onReloadWallet: getUserWallet };
}
