import {
  Button,
  Divider,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddressBookModal from '../../../../components/AddressBookModal';

import Iconify from '../../../../components/Iconify';

export default function WithdrawCryptoExternal({
  onOpenNetworkModal,
  networkSelected,
  externalValues,
  onChangeExternalValues,
  userWallet,
}) {
  const { t } = useTranslation();

  const [openAddressBook, setOpenAddressBook] = useState(false);

  const onSelectFromAddressBook = (item) => {
    onChangeExternalValues('address', item?.address || '');
    onChangeExternalValues('addressTag', item?.memo || '');
  };

  return (
    <>
      <TextField
        label={'Address'}
        variant='standard'
        value={externalValues.address}
        onChange={(e) => onChangeExternalValues('address', e.target.value)}
        fullWidth
        placeholder={t('Enter Address')}
        sx={{ maxWidth: 550, mb: 2.5 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Button color='inherit' sx={{ minWidth: 40, borderRadius: 0.5 }} onClick={() => setOpenAddressBook(true)}>
                <Iconify icon='eva:file-text-outline' sx={{ width: 20, height: 20 }} />
              </Button>
            </InputAdornment>
          ),
        }}
      />

      {networkSelected?.requireMemo && (
        <TextField
          label={'Memo'}
          variant='standard'
          value={externalValues.addressTag}
          onChange={(e) => onChangeExternalValues('addressTag', e.target.value)}
          fullWidth
          placeholder={t('Enter Tag')}
          sx={{ maxWidth: 550, mb: 2.5 }}
        />
      )}

      {/* ************************** select network ************************** */}
      <Stack alignItems={'flex-start'}>
        <Typography variant='body3' sx={{ color: 'grey.400' }}>
          {t('Network')}
        </Typography>

        <Button
          fullWidth
          color='inherit'
          sx={{ borderRadius: 0, maxWidth: 550, justifyContent: 'space-between' }}
          onClick={onOpenNetworkModal}
          endIcon={<Iconify icon={'eva:arrow-ios-downward-fill'} sx={{ width: 15, height: 15, color: 'grey.300' }} />}
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            {networkSelected ? (
              <>
                <Typography variant='body2' sx={{ color: 'grey.100' }}>
                  {networkSelected?.network || ''}
                </Typography>
                <Typography variant='body3' sx={{ color: 'grey.300' }}>
                  {networkSelected?.name || ''}
                </Typography>
              </>
            ) : (
              <Typography variant='body2' sx={{ color: 'grey.100' }}>
                {t('Select Withdrawal network')}
              </Typography>
            )}
          </Stack>
        </Button>

        <Divider sx={{ width: '100%', maxWidth: 550 }}></Divider>

        {networkSelected?.withdrawDescription && (
          <Stack direction='row' spacing={0.6} alignItems='center' sx={{ mt: 0.5 }}>
            <Iconify
              icon='material-symbols:info-outline-rounded'
              sx={{ color: 'secondary.main', width: 16, height: 16, transform: 'rotate(180deg)' }}
            />
            <Typography variant='caption' sx={{ color: 'grey.100' }}>
              {t(networkSelected.withdrawDescription)}
            </Typography>
          </Stack>
        )}
      </Stack>

      {/* ************************** wallet ************************** */}
      <RadioGroup name='wallet' sx={{ mt: 3, '& .MuiRadio-root': { py: 0.6 } }} defaultChecked='main_wallet'>
        <FormControlLabel
          value={'main_wallet'}
          control={<Radio checked sx={{ p: 1 }} />}
          label={
            <Stack direction={{ xs: 'column', sm: 'row' }}>
              <Typography variant='body3' color='grey.300' sx={{ width: 220 }}>
                {t('Funding Wallet')}
              </Typography>
              <Typography variant='body3' color='grey.300'>
                {userWallet?.usdtValue || 0} USDT
              </Typography>
            </Stack>
          }
        />

        <FormControlLabel
          value={'spot_wallet'}
          control={<Radio disabled sx={{ p: 1 }} />}
          sx={{ mt: { xs: 2, sm: 0 } }}
          label={
            <Stack direction={{ xs: 'column', sm: 'row' }}>
              <Typography variant='body3' color='grey.300' sx={{ width: 220 }}>
                {t('Spot Wallet')}
              </Typography>
              <Typography variant='body3' color='grey.300'>
                0 USDT
              </Typography>
            </Stack>
          }
        />

        <FormControlLabel
          value={'funding_wallet'}
          control={<Radio disabled />}
          sx={{ mt: { xs: 2, sm: 0 } }}
          label={
            <Stack direction={{ xs: 'column', sm: 'row' }}>
              <Typography variant='body3' color='grey.300' sx={{ width: 220 }}>
                {t('Future Wallet')}
              </Typography>
              <Typography variant='body3' color='grey.300'>
                0 USDT
              </Typography>
            </Stack>
          }
        />
      </RadioGroup>

      <AddressBookModal
        open={openAddressBook}
        onClose={() => setOpenAddressBook(false)}
        onSelect={onSelectFromAddressBook}
      />
    </>
  );
}
