import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Link, Popover, Stack, Typography } from '@mui/material';
import QRCode from 'qrcode';

import Image from '../../../../components/Image';
import scan from '../../../../assets/scan.svg';

export default function DepositBarcode({ addressHash, network = '', symbol = '' }) {
  const { t } = useTranslation();
  const [qrCode, setQrCode] = useState('');

  const generateQRCode = () => {
    QRCode.toDataURL(
      addressHash,
      {
        width: 180,
        margin: 1,
      },
      (err, data) => {
        if (err) return console.error(err);
        setQrCode(data);
      }
    );
  };

  useEffect(() => {
    if (addressHash) {
      generateQRCode();
    }
  }, [addressHash]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'barcode' : undefined;

  return (
    <>
      <IconButton onClick={handleClick} sx={{ ml: 1.5 }} disabled={!addressHash}>
        <Image src={scan} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            p: 2,
            width: 310,
            minHeight: 380,
            maxHeight: 405,
            backgroundImage: 'none',
            backgroundColor: 'grey.800',
          },
        }}
      >
        <Stack spacing={2}>
          <Typography variant='body3' color={'grey.100'}>
            {t('Scan the code on the withdrawal page of the trading platform APP or wallet App')}
          </Typography>

          <Stack alignItems={'center'}>
            <Image src={qrCode} sx={{ width: 180 }} />
          </Stack>
          <Stack spacing={0.3}>
            <Typography variant='body3' color={'grey.100'}>
              {t('Send only {{symbol}} to this deposit address', { symbol })}
            </Typography>

            <Stack direction={'row'} flexWrap={'wrap'} spacing={1}>
              <Typography variant='body3' color={'grey.100'}>
                {t('Ensure the network is')}
              </Typography>
              <Typography variant='body3' color='secondary'>
                {network}
              </Typography>
            </Stack>

            <Typography variant='body3' color={'grey.100'}>
              {t('Do not send NFTs to this address.')}
            </Typography>
            <Link sx={{ typography: 'body3', color: 'grey.300' }} component={RouterLink} to={'#'}>
              {t('Learn how to deposit NFTs')}
            </Link>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
}
