import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Button, Stack, TableCell, TableRow, Typography } from '@mui/material';
import moment from 'jalali-moment';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Iconify from '../../../../components/Iconify';
import Api from '../../../../http/serviceApi';
import confirmWrapper from '../../../../utils/confirm';

export default function P2PTableRow({ row, onAction, onReload }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { userId: userLoggedInId } = useSelector((store) => store.userInfo);
  const language = useSelector((store) => store.language);

  const { id, userId, side, price, symbol, amount, remainderAmount, maxLimit, minLimit, createdDate } = row;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onDelete = async () => {
    if (!(await confirmWrapper(t('Are you sure to remove this order?')))) {
      return false;
    }

    setIsSubmitting(true);

    try {
      const res = await Api.deleteP2POrder(id);
      if (res.status === 'SUCCESS') {
        enqueueSnackbar(res.message || 'Deleted', { variant: 'success' });
        onReload();
      }
    } catch (e) {}

    setIsSubmitting(false);
    return true;
  };

  return (
    <TableRow hover sx={{ '& td': { py: 2, typography: 'body2', color: 'grey.0' } }}>
      <TableCell>
        <Stack direction={'row'} spacing={3}>
          <Box sx={{ width: 44, height: 44 }}>
            {/* <Badge
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              color='primary'
              overlap='circular'
              badgeContent=' '
              variant='dot'
              sx={{ '& .MuiBadge-badge': { bottom: 6, backgroundColor: '#30E0A1' } }}
            > */}
            <Avatar sx={{ width: 44, height: 44, bgcolor: '#78621e' }}>{userId}</Avatar>
            {/* </Badge> */}
          </Box>

          <Stack spacing={0.5}>
            <span>ID: {userId}</span>
            <Stack direction={'row'} spacing={3}>
              {/* <Typography variant='body4'>
                {11} {t('Order(s)')}
              </Typography> */}
              <Typography variant='body4'>
                {t('Remainder')}: {Math.round((remainderAmount / amount) * 100)} %
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </TableCell>

      <TableCell sx={{ width: 200 }}>
        <Typography variant='body3'>{moment(createdDate).locale(language).format('YYYY-MM-DD HH:mm')}</Typography>
      </TableCell>

      <TableCell sx={{ width: 200 }}>
        <Typography variant='body2' component={'span'}>
          {price}
        </Typography>
        <Typography variant='body4' color='grey.300' sx={{ ml: 1 }}>
          USD
        </Typography>
      </TableCell>

      <TableCell>
        <Stack direction={'row'} spacing={1.6} alignItems={'center'}>
          <Stack spacing={0.5} sx={{ opacity: 0.8 }}>
            <Typography variant='body4'>{t('Available')}</Typography>
            <Typography variant='body4'>{t('Limits')}</Typography>
          </Stack>

          <Stack spacing={0.5}>
            <Typography variant='body3'>
              {remainderAmount}
              <Typography variant='body3' color='grey.300' sx={{ ml: 1 }}>
                {symbol}
              </Typography>
            </Typography>
            <Typography variant='body3'>
              ${minLimit * price} {maxLimit ? `- ${maxLimit * price}` : ''}
              <Typography variant='body3' color='grey.400' sx={{ ml: 1 }}>
                USD
              </Typography>
            </Typography>
          </Stack>
        </Stack>
      </TableCell>

      <TableCell align='center' sx={{ width: 200 }}>
        {t('Wallet')}
      </TableCell>

      <TableCell align='right' sx={{ width: 150 }}>
        {userLoggedInId === userId ? (
          <LoadingButton
            variant='contained'
            color={'inherit'}
            loading={isSubmitting}
            sx={{
              borderRadius: 0,
              typography: 'body3',
              width: 113,
              height: 40,
              lineHeight: 1,
              background:"transparent",
              color: 'common.white',

            }}
            onClick={onDelete}
            startIcon={<Iconify icon='uil:trash-alt' color='common.white' sx={{ width: 20, height: 20 }} />}
          >
            {t('Delete')}
          </LoadingButton>
        ) : (
          <Button
            variant='contained'
            color={side === 'BUY' ? 'success' : 'error'}
            sx={{
              borderRadius: 0,
              typography: 'body3',
              width: 113,
              height: 40,
              color: 'grey.0',
            }}
            onClick={onAction}
          >
            {side === 'BUY' ? t('Buy') : t('Sell')} {symbol}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
}
