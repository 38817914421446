import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Api from '../../../../../../../http/adminHttp/serviceApiAdmin';

export default function useAccountListSubmit({ userId }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const onSubmit = async (payload) => {
    try {
      const response = await Api.updateAdminLimitAmounts({ ...payload, userId });
      if (response?.status === 'SUCCESS') {
        enqueueSnackbar(t('The limit amounts are updated successfuly'), { variant: 'success' });
      }
    } catch (e) {}
  };

  return {
    onSubmit,
  };
}
