import { useTranslation } from 'react-i18next';
import { Button, MenuItem, Stack, TextField } from '@mui/material';
import moment from 'jalali-moment';
import DatePicker from '../../../../components/DatePicker';
import ComplexToolbar from '../../../../components/ComplexToolbar';
import {
  DEPOSIT_STATUS,
  TYPES,
  WITHDRAW_STATUS,
} from '../../../common/history/cryptoTransaction/cryptoTransactionTableToolbar';

export default function WalletCryptoTransactionToolbar({
  typeFilter,
  setTypeFilter,
  dateFromFilter,
  setDateFromFilter,
  dateToFilter,
  setDateToFilter,
  transactionIdFilter,
  setTransactionIdFilter,
  symbolFilter,
  setSymbolFilter,
  depositStatusFilter,
  withdrawStatusFilter,
  setDepositStatusFilter,
  setWithdrawStatusFilter,

  onFilter,
  onExport,
  isExportLoading,
}) {
  const { t } = useTranslation();

  const onReset = async () => {
    setDateFromFilter(moment(dateFromFilter).startOf('days').valueOf());
    setDateToFilter(moment(dateToFilter).endOf('days').valueOf());
    setDepositStatusFilter('ALL');
    setWithdrawStatusFilter('All');
    setTypeFilter('Deposit');
    setTransactionIdFilter('');
    setSymbolFilter('');
    onFilter(true);
  };

  return (
    <ComplexToolbar onExport={onExport} onPrint={() => console.log('print')} isExportLoading={isExportLoading}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} sx={{ mt: 2 }} alignItems={'center'}>
          <TextField
            select
            label={t('Type')}
            variant='standard'
            fullWidth
            value={typeFilter}
            onChange={(e) => setTypeFilter(e.target.value)}
            sx={{ maxWidth: 300 }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            {TYPES.map((item) => (
              <MenuItem key={item.value} value={item.value} sx={{ typography: 'body2' }}>
                {t(item.label)}
              </MenuItem>
            ))}
          </TextField>

          {typeFilter === 'Deposit' ? (
            <TextField
              select
              label={t('Status')}
              variant='standard'
              fullWidth
              value={depositStatusFilter}
              onChange={(e) => setDepositStatusFilter(e.target.value)}
              sx={{ maxWidth: 300 }}
              SelectProps={{
                MenuProps: {
                  sx: {
                    '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                    '& .MuiMenuItem-root': { typography: 'body2' },
                  },
                },
              }}
            >
              {DEPOSIT_STATUS.map((item) => (
                <MenuItem key={item.value} value={item.value} sx={{ typography: 'body2' }}>
                  {t(item.label)}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <TextField
              select
              label={t('Status')}
              variant='standard'
              fullWidth
              value={withdrawStatusFilter}
              onChange={(e) => setWithdrawStatusFilter(e.target.value)}
              sx={{ maxWidth: 300 }}
              SelectProps={{
                MenuProps: {
                  sx: {
                    '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                    '& .MuiMenuItem-root': { typography: 'body2' },
                  },
                },
              }}
            >
              {WITHDRAW_STATUS.map((item) => (
                <MenuItem key={item.value} value={item.value} sx={{ typography: 'body2' }}>
                  {t(item.label)}
                </MenuItem>
              ))}
            </TextField>
          )}

          <Stack direction={'row'}>
            <DatePicker
              label={t('From')}
              value={dateFromFilter}
              // setValue={setDateFromFilter}
              setValue={(e)=>{
                setDateFromFilter(e?.$d)
              }}
              variant='standard'
              maxDate={dateToFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
              InputProps={null}
            />
            <DatePicker
              label={t('To')}
              value={dateToFilter}
              // setValue={setDateToFilter}
              setValue={(e)=>{
                setDateToFilter(e?.$d)
              }}
              variant='standard'
              minDate={dateFromFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
            />
          </Stack>
        </Stack>

        <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'center'} spacing={5}>
          <TextField
            label={t('TxID')}
            variant='standard'
            placeholder={'Enter TxID'}
            fullWidth
            sx={{ maxWidth: 300 }}
            value={transactionIdFilter}
            onChange={(e) => setTransactionIdFilter(e.target.value)}
          />

          <TextField
            label={t('Currency')}
            variant='standard'
            fullWidth
            sx={{ maxWidth: 300 }}
            value={symbolFilter}
            onChange={(e) => setSymbolFilter(e.target.value)}
          />
        </Stack>
      </Stack>

      <Stack direction='row' spacing={2} sx={{ mt: 5, mb: 5.75 }}>
        <Button
          onClick={() => onFilter()}
          fullWidth
          variant='contained'
          sx={{ width: 200, typography: 'body3', fontWeight: 500 }}
        >
          {t('Filter')}
        </Button>
        <Button
          onClick={onReset}
          fullWidth
          variant='outlined'
          color='inherit'
          sx={{ width: 80, typography: 'body3', fontWeight: 500, borderColor: 'grey.400' }}
        >
          {t('Reset')}
        </Button>
      </Stack>
    </ComplexToolbar>
  );
}
