import React, { useEffect } from 'react';
import classes from './inputPassStrength.module.scss';
import { Input, Progress } from 'antd';
import { useState } from 'react';
import { red, green } from '@ant-design/colors';
import './passStrengthAntdStyle.scss';
import { InputCustom } from '../InputCustom/inputCustom';

let levelItems = [];

export const InputPassStrong = (props) => {
  const [focus, setFocus] = useState(false);

  let { label, value, placeholder, required, onChange, type, levelFill } = props;
  if (!placeholder) placeholder = label;
  const isOccupied = focus || (value && value.length !== 0);
  const labelClass = isOccupied ? `${classes.label} ${classes.asLabel}` : `${classes.label} ${classes.asPlaceholder}`;
  const requiredMark = required ? <span></span> : null;

  const creatPassLevelItems = () => {
    levelItems = [];
    let itemClass = '';
    let fillCount = levelFill;
    switch (fillCount) {
      case 0: {
        itemClass = `${classes.item}`;
        break;
      }
      case 1: {
        itemClass = `${classes.item} ${classes.itemRed}`;
        break;
      }
      case 2: {
        itemClass = `${classes.item} ${classes.itemOrange}`;
        break;
      }
      case 3: {
        itemClass = `${classes.item} ${classes.itemYellow}`;
        break;
      }
      case 4: {
        itemClass = `${classes.item} ${classes.itemYellow}`;
        break;
      }
      case 5: {
        itemClass = `${classes.item} ${classes.itemGreen}`;
        break;
      }
    }

    for (let i = 0; i < 5; i++) {
      if (fillCount > 0) {
        levelItems.push(<div className={itemClass}></div>);
        fillCount--;
      } else {
        levelItems.push(<div className={classes.item}></div>);
      }
    }

    return [...levelItems];
  };

  return (
    <div className={classes.floatLabel} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      <InputCustom
        type='password'
        size='small'
        className='inputPassStrong'
        value={value}
        bordered={false}
        onChange={onChange}
        defaultValue={value}
      />

      <div className={classes.container}>{creatPassLevelItems()}</div>

      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>

      {props.iconName && <props.iconName style={{ marginLeft: '-25px', fontSize: '18px' }} />}
    </div>
  );
};
