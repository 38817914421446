import { Box, IconButton, styled, RangeDatePicker, Stack } from '@mui/material';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounced } from '../utils';
import Iconify from './Iconify';
import DatePicker from './DatePicker';

// ----------------------------------------------------------------------

const RangeDatePickerStyle = styled(DatePicker)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 0,
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #7c829d',
      },
    },
  },
  '& input': {
    padding: '10px 16px 8px',
    color: theme.palette.grey[300],
    zIndex: 1,
    ...theme.typography.body3,
  },
  '& fieldset': {
    background: theme.palette.grey[700],
    border: '1px solid #555a72',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    background: theme.palette.grey[700],
    border: '1px solid #52576e',
  },
  '& .MuiFormLabel-root': {
    top: '-7px',
    '&.Mui-focused': {
      top: '0 !important',
    },
    '&.MuiFormLabel-filled': {
      top: 0,
    },
  },
}));

// ----------------------------------------------------------------------

export default function FillRangeDatePicker({ value = {}, setValue, label, placeholder, children, sx = {}, ...other }) {
  const { t } = useTranslation();
  console.log(value);
  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <Stack direction={'row'} sx={{ minWidth: 150, width: { xs: '100%', gap: '15px' } }}>
        <RangeDatePickerStyle
          variant='outlined'
          label={t('From')}
          placeholder={t(placeholder)}
          sx={{ width: { xs: '100%', md: '50%' } }}
          value={value.startTime}
          setValue={(startTime) => setValue({ ...value, startTime })}
          maxDate={value.endTime}
          {...other}
        >
          {children}
        </RangeDatePickerStyle>
        <RangeDatePickerStyle
          variant='outlined'
          label={t('To')}
          placeholder={t(placeholder)}
          sx={{ width: { xs: '100%', md: '50%' } }}
          mixDate={value.startTime}
          value={value.endTime}
          setValue={(endTime) => setValue({ ...value, endTime })}
          {...other}
        >
          {children}
        </RangeDatePickerStyle>
      </Stack>
    </Box>
  );
}
