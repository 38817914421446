import { Box, Button, Card, Stack, Tab, Tabs, Typography, alpha } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import { SET_PAGE_STATE } from '../../../store/actionTypes';
import ReferralMarketingIntroduced from './referralMarketingIntroduced';
import ReferralMarketingCommission from './referralMarketingCommission';
import ReferralMarketingStatistic from './referralMarketingStatistic';
import Api from '../../../http/adminHttp/serviceApiAdmin';
import LoadingScreen from '../../../components/LoadingScreen';

const percentageButtons = [0, 5, 10, 15, 20, 25, 30];

export default function ReferralMarketing() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // -------------------------
  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Referral Marketing' },
    });
  }, []);

  // -------------------------
  const [tab, setTab] = useState(0);
  const [profit, setProfit] = useState(0);
  const [data, setData] = useState(null);

  // -------------------------
  const handleTabChange = (e, newTab) => {
    setTab(newTab);
  };

  // -------------------------
  const onProfit = async (percent) => {
    setProfit(percent);

    try {
      const response = await Api.setMaxReferralInfo(percent);

      if (response?.status === 'SUCCESS') {
        enqueueSnackbar(t('Percentage changed successfully.'), { variant: 'success' });
      }
    } catch (e) {}
  };

  // -------------------------
  const getData = async () => {
    try {
      const data = await Api.getReferralOverallInfo();
      setData(data);
      setProfit(data?.referralPercent || 0);
    } catch (e) {}
  };

  // -------------------------
  useEffect(() => {
    getData();
  }, []);

  // -------------------------
  if (!data) {
    return <LoadingScreen />;
  }

  // -------------------------
  return (
    <Stack sx={{ mx: { xs: 2, lg: 0 } }} spacing={2.5}>
      <ReferralMarketingStatistic data={data} />

      <Card sx={{ mt: 3, px: { xs: 2, sm: 4 }, py: { xs: 3, sm: 4 } }}>
        <Stack sx={{ mb: 6 }} spacing={2}>
          <Typography variant='h6' sx={{ fontWeight: 500 }}>
            {t('Referral marketing')}
          </Typography>
        </Stack>

        <Typography>{t('Dynamic profit percentage')}</Typography>
        <Stack direction={'row'} spacing={1} sx={{ my: 2 }}>
          {percentageButtons.map((percentage, index) => (
            <Button
              key={index}
              variant='contained'
              color='success'
              onClick={() => onProfit(percentage)}
              sx={{
                background: profit === percentage ? '#30E0A1' : '#ccc',
                border: '1px solid grey.600',
                minWidth: { xs: 38, md: 65 },
                px: 0,
                color: profit === percentage ? 'common.black' : '#3d3d3d',
                typography: 'body3',
                borderRadius: 0,
              }}
            >
              {percentage}%
            </Button>
          ))}
        </Stack>

        <Stack spacing={2} sx={{ mb: 8 }}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              width: { xs: '100%', md: 510 },
              backgroundColor: alpha('#F27F16', 0.2),
              borderRadius: 1.5,
              px: 5,
              py: 2,
            }}
          >
            <Typography variant='body2'>{t('Referral Share')}</Typography>
            <Typography variant='h6' fontWeight={400}>
              {profit} %
            </Typography>
          </Stack>

          {/*<Stack*/}
          {/*  direction={'row'}*/}
          {/*  alignItems={'center'}*/}
          {/*  justifyContent={'space-between'}*/}
          {/*  sx={{*/}
          {/*    width: { xs: '100%', md: 510 },*/}
          {/*    backgroundColor: alpha('#30E0A1', 0.2),*/}
          {/*    borderRadius: 1.5,*/}
          {/*    px: 5,*/}
          {/*    py: 2,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Typography variant='body2'>{t('Share friends')}</Typography>*/}
          {/*  <Typography variant='h6' fontWeight={400}>*/}
          {/*    {profit} %*/}
          {/*  </Typography>*/}
          {/*</Stack>*/}
        </Stack>

        <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label={t('List of introduced users')} sx={{ width: '50%', typography: 'body3' }} />
            <Tab label={t('List of commission payments to the wallet')} sx={{ width: '50%', typography: 'body3' }} />
          </Tabs>
        </Box>

        <TabPanel value={tab} index={0}>
          <ReferralMarketingIntroduced />
        </TabPanel>

        <TabPanel value={tab} index={1}>
          <ReferralMarketingCommission />
        </TabPanel>
      </Card>
    </Stack>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  if (value === index) {
    return <Box sx={{ mt: 5 }}>{children}</Box>;
  }
}
