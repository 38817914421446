import React from 'react';
import { Form, Input, Select, Button, Row, Col, DatePicker } from 'antd';
import { InputCustom } from '../../../../../components/InputCustom/inputCustom';
import { useNavigate } from 'react-router';
import Api from '../../../../../http/serviceApi';
import { showNotification } from '../../../../../utils/notification';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SET_AUTHENTICATION_BASIC } from '../../../../../store/actionTypes';
import { useSelector } from 'react-redux';
import './basicAuthFormAntdStyle.scss';
import { useState } from 'react';
import { navigationUrl } from '../../../../../navigationUrl';

import classesDark from './basicAuthIdentityInfoForm.module.scss';
import classesLight from './basicAuthIdentityInfoFormLight.module.scss';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const BasicAuthIdentityInfoForm = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = theme.palette.mode === 'dark' ? classesDark : classesLight;

  const [firstName, setFirstName] = React.useState('Ali');
  const [lastName, setLastName] = React.useState('Ahmadi');
  const [dateOfBirth, setDateOfBirth] = React.useState('');
  const [middleName, setMiddleName] = React.useState('');
  const [countryList, setCountryList] = React.useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((store) => store.userInfo);
  const { getFieldDecorator } = form;

  const onFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const onLastNameChange = (e) => {
    setLastName(e.target.value);
  };
  const onDateOfBirthChange = (e) => {
    setDateOfBirth(e.target.value);
  };
  const onMiddleNameChange = (e) => {
    setMiddleName(e.target.value);
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        debugger;
        const foundedItem = countryList.find((item) => item.name === values.nationality);
        const data = {
          authBasic: {
            infos: {
              countryId: foundedItem?.id,
              middleName: values.middleName,
              birthday: selectedDate,
            },
            postalCodeFormat: foundedItem?.postalCodeFormat,
          },
        };
        dispatch({
          type: SET_AUTHENTICATION_BASIC,
          payload: data,
        });
        navigate(navigationUrl.auth_basic_additionalinfo);
      })
      .catch((errorInfo) => {});
  };

  const colPropsForm = {
    xs: 24,
    sm: 11,
    md: 11,
    lg: 11,
    xl: 11,
  };

  const colPropsBotton = {
    xs: 24,
    sm: 10,
    md: 10,
    lg: 10,
    xl: 10,
  };

  const getCountryList = async () => {
    const response = await Api.getCountryList();
    console.log(response);
    if (response) {
      setCountryList(response.countries);
    }
  };

  useEffect(() => {
    getCountryList();
  }, []);

  const onDatePickerChange = (date, dateString) => {
    setSelectedDate(date?.unix() * 1000);
  };

  return (
    <div className={classes.container}>
      <div className={classes.formTitleDiv}>
        <span className={classes.formTitleBig}>{t('Basic Verification')}</span>
        <span className={classes.formTitleSmall}>{t('Identity Information')}</span>
      </div>

      <Form
        layout='vertical'
        form={form}
        name='basicAuthentication'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete='off'
      >
        <Form.Item
          className={classes.formItem}
          label={t('Nationality')}
          name='nationality'
          rules={[
            {
              required: true,
              message: 'Please select your nationality.',
            },
          ]}
        >
          <Select
            className={classes.comboCustom}
            bordered={false}
            shape='round'
            showSearch
            placeholder={t('Select your nationality')}
          >
            {countryList.map((country) => {
              return (
                <Select.Option value={country.name} key={country.id}>
                  <div className={classes.selectItem}>
                    <img src={`data:image/jpeg;base64,${country.logo}`} style={{ width: '25px' }} />
                    <span>{country.name}</span>
                  </div>
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Row justify='space-between'>
          <Col {...colPropsForm}>
            <Form.Item className={classes.formItem} name='firstName'>
              <InputCustom
                label={t('First Name')}
                placeholder={userInfo.firstName}
                required={true}
                disabled={true}
                onChange={onFirstNameChange}
              />
            </Form.Item>
          </Col>
          <Col {...colPropsForm}>
            <Form.Item className={classes.formItem} name='lastName'>
              <InputCustom
                label={t('Last Name')}
                placeholder={userInfo.lastName}
                required={true}
                disabled={true}
                onChange={onLastNameChange}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify='space-between'>
          <Col {...colPropsForm}>
            <Form.Item className={classes.formItem} name='middleName'>
              <InputCustom
                value={middleName}
                label={t('Middle Name')}
                placeholder={t('Middle Name')}
                required={true}
                onChange={onMiddleNameChange}
              />
            </Form.Item>
          </Col>
          <Col {...colPropsForm}>
            <div className='datePicketBasicAuth'>
              <Form.Item
                className={classes.formItem}
                name='dateOfBirth'
                rules={[
                  {
                    type: 'object',
                    required: true,
                    message: 'Please select your date of birth!',
                  },
                ]}
              >
                <DatePicker
                  bordered={false}
                  size='small'
                  onChange={onDatePickerChange}
                  placeholder={t('date of birth')}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row justify='space-evenly' style={{ marginTop: '30px' }}>
          <Col {...colPropsBotton}>
            <Form.Item>
              <Button type='primary' htmlType='submit' block shape='round'>
                {t('Continue')}
              </Button>
            </Form.Item>
          </Col>
          <Col {...colPropsBotton}>
            <Form.Item>
              <Button
                type='default'
                block
                shape='round'
                onClick={() => navigate(navigationUrl.auth)}
                style={{ color: theme.palette.mode === 'dark' ? '#ccc' : '#000' }}
              >
                {t('Back')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div className={classes.indicatorDiv}>
        <div className={classes.indicatorItemGreen} />
        <div className={classes.indicatorItemGray} />
      </div>
    </div>
  );
};

export default BasicAuthIdentityInfoForm;
