import {
  alpha,
  Box,
  Button,
  Container,
  Link,
  MenuItem,
  Select,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { m } from 'framer-motion';

import useUserAuth from '../../hooks/useUserAuth';
import LoadingScreen from '../../components/LoadingScreen';
import TopMenu from '../landing/sections/topMenu';
import Footer from '../landing/sections/footer';
import MotionViewport from '../../components/animate/MotionViewport';
import { varFade } from '../../components/animate/variants/fade';
import { useTranslation } from 'react-i18next';

import bannerImage from '../../assets/landing/background-top.svg';
import Api from '../../http/serviceApi';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { FormProvider, RHFTextField } from '../../components/hookForm';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';

export default function ContactUs() {
  const { loading } = useUserAuth(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const [isloading, setIsLoading] = useState();
  const validators = Yup.object().shape({
    email: Yup.string().required().email(),
    message: Yup.string().required(),
    name: Yup.string().required(),
    problem: Yup.string().required(),
  });

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues: {
      email: '',
      message: '',
      name: '',
      problem: '',
    },
  });

  if (loading) {
    return <LoadingScreen />;
  }

  const sendData = async () => {
    try {
      setIsLoading(true);
      await Api.contactUs(methods.getValues());
    } finally {
      setIsLoading(false);
      methods.reset();
    }
  };

  return (
    <Stack sx={{ minHeight: 1 }}>
      <TopMenu />

      <RootSubStyle sx={{ position: 'relative', pt: 20 }}>
        <BackgroundShadow />

        {theme.palette.mode === 'dark' && <HeroOverlayStyle alt='overlay' src={bannerImage} variants={varFade().in} />}

        <Container component={MotionViewport}>
          <m.div variants={varFade().in}>
            <StartedBox>
              <StartedContent>
                <Typography
                  variant='h3_secondary'
                  component='h3'
                  color='grey.100'
                  sx={{ fontWeight: 700, textAlign: 'center', mb: 3 }}
                >
                  {t('Contact us')}
                </Typography>

                <Typography
                  variant='body3'
                  color='grey.300'
                  component='p'
                  sx={{ fontWeight: 400, textAlign: 'justify' }}
                >
                  {t(
                    'We always want to hear from you! let us know how we can best help you and we’ll do our very best. We always want to hear from you! let us know how we can best help you and we’ll do our very best. We always want to hear from you! let us know how we can best help you and we’ll do our very bes'
                  )}
                </Typography>

                <Stack spacing={1} sx={{ mt: 3 }}>
                  <Stack
                    spacing={{ xs: 1, md: 0 }}
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent='space-between'
                  >
                    <Link
                      sx={{ width: { xs: '100%', md: 170 }, textAlign: { xs: 'center', md: 'left' } }}
                      href='mailto:info@cryptizi.com'
                    >
                      info@cryptizi.com
                    </Link>
                    <Link
                      sx={{ width: { xs: '100%', md: 170 }, textAlign: { xs: 'center', md: 'left' } }}
                      href='mailto:support@cryptizi.com'
                    >
                      support@cryptizi.com
                    </Link>
                    <Link
                      sx={{ width: { xs: '100%', md: 170 }, textAlign: { xs: 'center', md: 'left' } }}
                      href='mailto:marketing@cryptizi.com'
                    >
                      marketing@cryptizi.com
                    </Link>
                  </Stack>
                  <Stack
                    spacing={{ xs: 1, md: 0 }}
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent='space-between'
                  >
                    <Link
                      sx={{ width: { xs: '100%', md: 170 }, textAlign: { xs: 'center', md: 'left' } }}
                      href='mailto:tech@cryptizi.com'
                    >
                      tech@cryptizi.com
                    </Link>
                    <Link
                      sx={{ width: { xs: '100%', md: 170 }, textAlign: { xs: 'center', md: 'left' } }}
                      href='mailto:listing@cryptizi.com'
                    >
                      listing@cryptizi.com
                    </Link>
                    <Link
                      sx={{ width: { xs: '100%', md: 170 }, textAlign: { xs: 'center', md: 'left' } }}
                      href='mailto:finance@cryptizi.com'
                    >
                      finance@cryptizi.com
                    </Link>
                  </Stack>
                </Stack>

                <FormProvider methods={methods} onSubmit={methods.handleSubmit(sendData)}>
                  <Stack spacing={4} sx={{ mt: 4 }}>
                    <RHFTextField name='name' variant='standard' label={t('Your name')} fullWidth />

                    <RHFTextField name='email' variant='standard' label={t('Your email')} fullWidth />

                    <RHFTextField
                      name='problem'
                      select
                      variant='standard'
                      SelectProps={{
                        native: false,
                        MenuProps: {
                          sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'common.black' } },
                        },
                      }}
                      label={t(`What's your problem`)}
                      fullWidth
                      sx={{ maxWidth: 730 }}
                    >
                      <MenuItem value='Finance'>{t('Finance')}</MenuItem>
                      <MenuItem value='Support'>{t('Support')}</MenuItem>
                      <MenuItem value='Token listing'>{t('Token listing')}</MenuItem>
                      <MenuItem value='Marketing'>{t('Marketing')}</MenuItem>
                      <MenuItem value='Technical'>{t('Technical')}</MenuItem>

                    </RHFTextField>

                    <RHFTextField name='message' variant='standard' label={t('Message')} fullWidth multiline rows={2} />

                    <Box sx={{ textAlign: 'center', pt: 3 }}>
                      <LoadingButton
                        type='submit'
                        loading={isloading}
                        variant='contained'
                        color='success'
                        sx={{ width: 250, height: 40 }}
                      >
                        <Typography variant='subtitle1'>{t('Get Started')}</Typography>
                      </LoadingButton>
                    </Box>
                  </Stack>
                </FormProvider>
              </StartedContent>

              <StartedBorder />
            </StartedBox>
          </m.div>
        </Container>
      </RootSubStyle>

      <Footer />
    </Stack>
  );
}

const RootSubStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(8.5),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(6),
  },
}));

const StartedBorder = styled(Box)({
  position: 'absolute',
  transform: 'rotate(-1.6deg)',
  borderRadius: '5px',
  border: '1px solid #34384C',
  width: '100%',
  height: '100%',
  left: -8,
  top: -6,
  zIndex: 1,
});

const StartedBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  background: alpha(theme.palette.grey[900], 0.8),
  borderRadius: '10px',
  width: '100%',
  height: 900,
  [theme.breakpoints.up('md')]: {
    height: 800,
  },
}));

const StartedContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '90%',
  height: '100%',
  top: 0,
  left: '50%',
  zIndex: 10,
  transform: 'translateX(-50%)',
  padding: '30px 16px',
  [theme.breakpoints.up('md')]: {
    width: 750,
    padding: '58px 98px',
  },
}));

const BackgroundShadow = styled(Box)({
  position: 'absolute',
  top: 0,
  zIndex: 9,
  left: '50%',
  transform: 'translateX(-50%)',
  width: 228,
  height: 114,
  background: 'radial-gradient(50% 50% at 50% 50%, rgba(87, 174, 254, 0.64) 0%, rgba(87, 254, 134, 0.16) 100%)',
  opacity: 0.6,
  filter: 'blur(62px)',
});

const HeroOverlayStyle = styled(m.img)({
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});
