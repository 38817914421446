import * as actionTypes from './actionTypes';
// import jwt_decode from 'jwt-decode';

const defaultUserInfo = {
  userInfo: {
    address: '',
    authLevel: '',
    cityName: '',
    countryId: 1,
    createdDate: 0,
    emailAuthEnabled: false,
    firstName: '',
    lastName: '',
    postalCode: '',
    smsAuthEnabled: false,
    softOtpAuthEnabled: false,
    userId: 0,
    userType: '',

    // address: "",
    // authLevel: "",
    // cityName: "",
    // countryId: -1,
    // createdDate: -1,
    // emailAfterLogin: true,
    // emailAfterTransaction: true,
    // emailAuthEnabled: true,
    // firstName: "",
    // lastName: "",
    // message: "",
    // notificationAfterTransaction: true,
    // postalCode: "",
    // registrationBy: "",
    // smsAfterLogin: true,
    // smsAfterTransaction: true,
    // smsAuthEnabled: true,
    // softOtpAuthEnabled: true,
    // status: "",
    // userId: -1,
    // userType: "",
  },
};
const defaultAdminUserInfo = {
  adminUserInfo: {
    address: '',
    authLevel: '',
    cityName: '',
    countryId: 1,
    createdDate: 0,
    emailAuthEnabled: false,
    firstName: '',
    lastName: '',
    postalCode: '',
    smsAuthEnabled: false,
    softOtpAuthEnabled: false,
    userId: 1,
    userType: '',
  },
};

const defaultOrderBookItem = {
  obListItem: {
    a: 0,
    d: 0,
    m: 0,
    p: '',
  },
};

const defaultOrderBook = {
  // orderBook: {
  //     asks: [],
  //     bids: [],
  //     dollarPrice: 0,
  //     tradeInfos: []
  // }
  orderBook: null,
};

const defaultUserTradeInfo = {
  openTradeInfos: [],
  tradeHistoryInfos: [],
};

const defaultLoadingUserTradeInfo = {
  loading_UserTradeInfo: false,
};
const defaultLoadingOrderBook = {
  loading_OrderBook: true,
};

const defaultOrderHistoryDate = {
  orderHistoryDate: {
    startDate: Date.now() - 86400000,
    finishDate: Date.now(),
  },
};

const defaultPageState = {
  pageState: {
    title: '',
  },
};

const defaultLanguageState = {
  language: 'en',
};

const defaultThemeDirectionState = {
  themeDirection: 'ltr',
};

const defaultThemeModeState = {
  themeMode: 'dark',
};

const defaultLastUpdateTicket = {
  lastUpdateTicket: new Date(),
};

const defaultWaitingCounter = {
  waitingUserCounter: 0,
  waitingBankCardCounter: 0,
  waitingWithdrawCounter: 0,
  waitingDepositCounter: 0,
};

const defaultToastState = {
  toast: {
    message: '',
    type: '',
  },
};

const defaultIranianPaymentStatus = {
  isIranianPaymentActive: false,
};

const defaultState = {
  ...defaultUserInfo,
  ...defaultAdminUserInfo,
  ...defaultOrderBookItem,
  ...defaultOrderBook,
  ...defaultUserTradeInfo,
  ...defaultOrderHistoryDate,
  ...defaultLoadingUserTradeInfo,
  ...defaultLoadingOrderBook,
  ...defaultPageState,
  ...defaultLanguageState,
  ...defaultThemeDirectionState,
  ...defaultThemeModeState,
  ...defaultToastState,
  ...defaultLastUpdateTicket,
  ...defaultWaitingCounter,
  ...defaultIranianPaymentStatus,
};

export function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SET_USERINFO:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_ADMIN_USERINFO:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_AUTHENTICATION_BASIC:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_AUTHENTICATION_Intermediate:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_OrderBookList_SelectedItem:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_ORDERBOOK:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_USER_TRADEINFO:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_ORDER_HISTORY_DATE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_Loading_USER_TRADEINFO:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_LOADING_ORDERBOOK:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_SIGNOUT:
      return {
        ...state,
        ...defaultUserInfo,
        ...defaultAdminUserInfo,
        ...defaultOrderBookItem,
        ...defaultOrderBook,
        ...defaultUserTradeInfo,
        ...defaultOrderHistoryDate,
        ...defaultLoadingUserTradeInfo,
        ...defaultLoadingOrderBook,
      };
    case actionTypes.SET_PAGE_STATE:
      return {
        ...state,
        pageState: { ...state.pageState, ...action.payload },
      };
    case actionTypes.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case actionTypes.SET_THEME_DIRECTION:
      return {
        ...state,
        themeDirection: action.payload,
      };
    case actionTypes.SET_THEME_MODE:
      return {
        ...state,
        themeMode: action.payload,
      };
    case actionTypes.SET_TOAST:
      return {
        ...state,
        toast: action.payload,
      };
    case actionTypes.SET_LAST_UPDATE_TICKET:
      return {
        ...state,
        lastUpdateTicket: action.payload,
      };
    case actionTypes.SET_WAITING_COUNTER:
      return {
        ...state,
        waitingWithdrawCounter: action.payload.waitingWithdrawCounter,
        waitingDepositCounter: action.payload.waitingDepositCounter,
        waitingUserCounter: action.payload.waitingUserCounter,
        waitingBankCardCounter: action.payload.waitingBankCardCounter,
        waitingTransactionCounter: action.payload.waitingTransactionCounter,
      };
    case actionTypes.SET_IRANIAN_PAYMENT_STATUS:
      return {
        ...state,
        isIranianPaymentActive: action.payload.isIranianPaymentActive,
      };
    default:
      return state;
  }
}
