import { CircularProgress, IconButton, MenuItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FillSelect from '../../../../components/FillSelect';
import Iconify from '../../../../components/Iconify';
import FillTextField from '../../../../components/FillTextField';
import NumberRange from '../../../../components/NumberRange';
import ActiveCryptoModal from '../../../../components/activeCryptoModal/activeCryptoModal';
import { useState } from 'react';
import FillRangeDatePicker from '../../../../components/FillRangeDatePicker';
import NumRangeWithInput from '../../../../components/NumRangeWithInput';
import React from 'react';

const SECTIONS = [
  // { value: 'ALL', label: 'All' },
  { value: 'MAKE', label: 'MAKE' },
  { value: 'TAKE', label: 'TAKE' },
];

const SIDES = [
  { value: 'ALL', label: 'ALL' },
  { value: 'BUY', label: 'BUY' },
  { value: 'SELL', label: 'SELL' },
];

const STATES = [
  { value: 'ALL', label: 'ALL' },
  { value: 'CANCELED', label: 'CANCELED' },
  { value: 'COMPLETED', label: 'COMPLETED' },
  { value: 'FAILED', label: 'FAILED' },
  { value: 'PARTIALLY_COMPLETED', label: 'PARTIALLY_COMPLETED' },
  { value: 'PENDING', label: 'PENDING' },
];

export default function P2PHistoryTableToolbar({
  sideFilter,
  setSideFilter,
  stateFilter,
  setStateFilter,
  sectionFilter,
  setSectionFilter,
  timeFilter,
  setTimeFilter,
  transactionIdFilter,
  setTransactionIdFilter,
  symbolFilter,
  setSymbolFilter,
  amountFilter,
  setAmountFilter,
  firstNameFilter,
  setFirstNameFilter,
  lastNameFilter,
  setLastNameFilter,
  isAdmin = false,
  isExportLoading = false,
  onExport = () => {},
}) {
  const { t } = useTranslation();
  const [openSymbolModal, setOpenSymbolModal] = useState(false);

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent='space-between'
      alignItems={'flex-start'}
      spacing={2}
      sx={{ mb: { xs: 3, md: 9 } }}
    >
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Stack sx={{ width: '100%' }} flexDirection={'row'} alignItems={'flex-end'} gap={'15px'} flexWrap={'wrap'}>
          <FillRangeDatePicker
            value={timeFilter}
            setValue={setTimeFilter}
            variant='outlined'
            fullWidth
            sx={{ width: { xs: '100%', md: 335 } }}
            InputProps={null}
          />

          {!isAdmin && (
            <FillSelect
              value={sectionFilter}
              onChange={setSectionFilter}
              label={t('Section')}
              sx={{ width: { xs: '100%', md: 160 } }}
            >
              {SECTIONS.map((section) => (
                <MenuItem key={section.value} value={section.value} sx={{ typography: 'body2' }}>
                  {t(section.label)}
                </MenuItem>
              ))}
            </FillSelect>
          )}

          <FillSelect
            value={sideFilter}
            onChange={setSideFilter}
            label={t('Side')}
            sx={{ width: { xs: '100%', md: 160 } }}
          >
            {SIDES.map((side) => (
              <MenuItem key={side.value} value={side.value} sx={{ typography: 'body2' }}>
                {t(side.label)}
              </MenuItem>
            ))}
          </FillSelect>

          <FillSelect
            value={stateFilter}
            onChange={setStateFilter}
            label={t('State')}
            sx={{ width: { xs: '100%', md: 160 } }}
          >
            {STATES.map((state) => (
              <MenuItem key={state.value} value={state.value} sx={{ typography: 'body2' }}>
                {t(state.label)}
              </MenuItem>
            ))}
          </FillSelect>

          <FillTextField
            value={symbolFilter}
            onChange={setSymbolFilter}
            label={t('Symbol')}
            sx={{ width: { xs: '100%', md: 160 } }}
            onClick={() => setOpenSymbolModal(true)}
            isDebounced
            hasCloseButton
          />

          <FillTextField
            value={transactionIdFilter}
            onChange={setTransactionIdFilter}
            label={t('Tx-Code')}
            placeholder={'Enter Tx-Code'}
            sx={{ width: { xs: '100%', md: 160 } }}
            isDebounced
          />

          {/*<NumberRange*/}
          {/*  value={amountFilter}*/}
          {/*  setValue={setAmountFilter}*/}
          {/*  max={100000}*/}
          {/*  sx={{*/}
          {/*    width: { xs: '100%', md: 160 },*/}
          {/*    border: '1px solid #52576e',*/}
          {/*    p: '3px 8px',*/}
          {/*    bgcolor: '#272833',*/}
          {/*    '& .MuiInputBase-root:before': { display: 'none' },*/}
          {/*    '& .MuiInputBase-root:after': { display: 'none' },*/}
          {/*  }}*/}
          {/*/>*/}

          {isAdmin && (
            <>
              <FillTextField
                value={firstNameFilter}
                onChange={setFirstNameFilter}
                label={t('First name')}
                sx={{ width: { xs: '100%', md: 160 } }}
                isDebounced
              />

              <FillTextField
                value={lastNameFilter}
                onChange={setLastNameFilter}
                label={t('Last name')}
                sx={{ width: { xs: '100%', md: 160 } }}
                isDebounced
              />
            </>
          )}

          <NumRangeWithInput
            max={100000}
            label={t('Amount')}
            value={amountFilter}
            setValue={setAmountFilter}
            sx={{
              width: { xs: '100%', md: 160 },
              border: '1px solid #52576e',
              p: '3px 8px',
              bgcolor: '#272833',
              '& .MuiInputBase-root:before': { display: 'none' },
              '& .MuiInputBase-root:after': { display: 'none' },
            }}  />
        </Stack>
      </Stack>

      {onExport && (
        <>
          {isExportLoading ? (
            <CircularProgress size={22} color='success' sx={{ margin: '11px !important' }} />
          ) : (
            <>
              <IconButton onClick={onExport}>
                <Iconify icon='tabler:receipt' sx={{ width: 28, height: 28 }} />
              </IconButton>
              <IconButton onClick={() => onExport('PDF')}>
                <Iconify icon='majesticons:printer-line' sx={{ width: 28, height: 28 }} />
              </IconButton>
            </>
          )}
        </>
      )}
      {/* <Stack
        direction='row'
        sx={{
          backgroundColor: 'grey.700',
          border: '1px solid',
          borderColor: 'grey.400',
          pl: 2,
          pr: 0,
          height: 40,
          width: 160,
        }}
      >
        <InputBase
          sx={{
            flex: 1,
            '& input': { ...theme.typography.body2, color: 'grey.0', '&::placeholder': { color: 'grey.400' } },
          }}
          placeholder={t('Search')}
          inputProps={{ 'aria-label': 'search' }}
        />

        <IconButton type='button' aria-label='search'>
          <Iconify icon={'eva:search-fill'} width={25} height={25} color='grey.0' />
        </IconButton>
      </Stack> */}

      <ActiveCryptoModal
        open={openSymbolModal}
        onClose={() => setOpenSymbolModal(false)}
        onSelect={setSymbolFilter}
        title='Select Symbol'
      />
    </Stack>
  );
}
