import { IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import Iconify from '../../../components/Iconify';
import Image from '../../../components/Image';
import SelectBoxStatus from '../../../components/SelectBoxStatus';
import Api from '../../../http/adminHttp/serviceApiAdmin';
import { CARD_TYPE } from './bankCardTable';

export default function BankCardTableRow({ row, index, onUpdateRow, isChangeList = false, reload }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { cardType, status, firstName, lastName, cardNumber, accountNumber, expiryDateYear, expiryDateMonth } = row;

  const cartType = CARD_TYPE.find((ct) => ct.value === cardType);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onUpdateStatusRow = async (newStatus) => {
    setIsSubmitting(true);

    try {
      const response = await Api.updateBankCard({ ...row, status: newStatus });
      if (response.status === 'SUCCESS') {
        enqueueSnackbar(t('The bank card is updated successfully'), { variant: 'success' });
        reload();
      }
    } catch (e) {}

    setIsSubmitting(false);
  };

  return (
    <TableRow hover sx={{ '& td': { py: 1.5, typography: 'body2' } }}>
      <TableCell align='center' sx={{ width: 80 }}>
        {index + 1}
      </TableCell>

      <TableCell>
        {firstName} {lastName}
      </TableCell>

      <TableCell>{cardNumber}</TableCell>

      <TableCell>{accountNumber || '-'}</TableCell>

      <TableCell sx={{ width: 150 }}>
        <Stack direction='row' spacing={2} alignItems={'center'} justifyContent='space-between'>
          <Typography typography='body3' color='grey.300'>
            {t(cartType.label)}
          </Typography>
          <Image src={cartType.icon} />
        </Stack>
      </TableCell>

      <TableCell>{expiryDateYear ? `${expiryDateYear}/${expiryDateMonth}` : '-'}</TableCell>

      {!isChangeList && (
        <>
          <TableCell align='center'>
            <SelectBoxStatus
              variant='filled'
              condition={status}
              onChange={onUpdateStatusRow}
              isSubmitting={isSubmitting}
            />
          </TableCell>

          <TableCell align='center' sx={{ width: 100 }}>
            <IconButton onClick={onUpdateRow}>
              <Iconify icon='eva:edit-2-outline' />
            </IconButton>
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
