import React, { useEffect, useRef } from 'react';
import classesDark from './customListBoxContainer.module.scss';
import classesLight from './customListBoxContainer.module.scss';
import iconArrowDown from '../../../../assets/trade/arrow-down.svg';
import iconArrowUp from '../../../../assets/trade/arrow-up.svg';
import Api from '../../../../http/serviceApi';
import { Spin } from 'antd';
import usePrevious from '../../../../hooks/usePrevious';
import { useDispatch } from 'react-redux';
import { SET_ORDERBOOK, SET_LOADING_ORDERBOOK } from '../../../../store/actionTypes';
import { useSelector } from 'react-redux';
import CustomListBoxNew from '../customListBox/customListBoxNew';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTheme } from '@mui/material';

const CustomListBoxContainer = (props) => {
  const theme = useTheme();
  const endOfListRef = useRef(null);
  const funcLoaded = useRef(false);
  const dispatch = useDispatch();
  const orderBook = useSelector((store) => store.orderBook);
  const prevLastPrice = usePrevious(orderBook?.tradeInfos[0]?.p);
  const loading_OrderBook = useSelector((store) => store.loading_OrderBook);

  const classes = theme.palette.mode === 'dark' ? classesDark : classesLight;

  useEffect(() => {
    if (orderBook && !funcLoaded.current) {
      scroll();
    }
  }, [orderBook]);

  useEffect(() => {
    const intervalOneSecond = setInterval(fetchDataEveryOneSecond, 1000);
    return () => {
      clearInterval(intervalOneSecond);
    };
  });

  const fetchDataEveryOneSecond = () => {
    if (props.symbolInfo) {
      getOrderBook(`${props.symbolInfo?.baseAsset}${props.symbolInfo?.quoteAsset}`);
    }
  };

  const scroll = async () => {
    scrollToBottom();
    console.log('scroll');

    funcLoaded.current = true;
  };

  const getOrderBook = async (symbol) => {
    const response = await Api.getOrderBook(symbol);
    if (response?.status === 'SUCCESS') {
      dispatch({
        type: SET_ORDERBOOK,
        payload: {
          orderBook: {
            asks: response.asks,
            bids: response.bids,
            dollarPrice: response.dollarPrice,
            sizeReference: response.sizeReference,
            tradeInfos: response.tradeInfos,
          },
        },
      });
    }

    dispatch({
      type: SET_LOADING_ORDERBOOK,
      payload: {
        loading_OrderBook: false,
      },
    });
  };

  const scrollToBottom = () => {
    endOfListRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  };

  return (
    <div>
      {loading_OrderBook && (
        <div className={classes.loadingPopup}>
          <Spin />
        </div>
      )}
      {/* <CustomListBox
                dataSource={orderBook?.asks}
                inverseSeection={true}
                showHeader={true}
                keyField="id"
                className={
                    (props.shape === "both" && classes.halfHeight) ||
                    (props.shape === "red" && classes.fullHeight) ||
                    (props.shape === "green" && classes.hide)
                }
                endOfListRef={endOfListRef}
                title={{
                    baseAsset: props.symbolInfo?.baseAsset,
                    quoteAsset: props.symbolInfo?.quoteAsset
                }}
                onItemClick={props.onItemClick}
            /> */}
      <CustomListBoxNew
        dataSource={orderBook?.asks}
        sizeReference={orderBook?.sizeReference}
        inverseSeection={true}
        showHeader={true}
        keyField='id'
        style={props.style}
        className={
          (props.shape === 'both' && classes.halfHeight) ||
          (props.shape === 'red' && classes.fullHeight) ||
          (props.shape === 'green' && classes.hide)
        }
        endOfListRef={endOfListRef}
        title={{
          baseAsset: props.symbolInfo?.baseAsset,
          quoteAsset: props.symbolInfo?.quoteAsset,
        }}
        onItemClick={props.onItemClick}
      />
      <div className={classes.ticker}>
        <div className={classes.tickerContent}>
          <span className={orderBook?.tradeInfos[0]?.p >= prevLastPrice ? classes.bigTextGreen : classes.bigTextRed}>
            {orderBook?.tradeInfos && orderBook?.tradeInfos[0]?.p}
          </span>
          <img
            className={classes.arrowIcon}
            src={orderBook?.tradeInfos[0]?.p >= prevLastPrice ? iconArrowUp : iconArrowDown}
            alt=''
          />
          <span className={classes.smallText}>{'$' + orderBook?.dollarPrice}</span>
        </div>
        {/* <span>More</span> */}
      </div>
      {/* <CustomListBox
                dataSource={orderBook?.bids}
                inverseSeection={false}
                showHeader={(props.shape === "green")}
                keyField="id"
                className={
                    (props.shape === "both" && classes.halfHeight) ||
                    (props.shape === "red" && classes.hide) ||
                    (props.shape === "green" && classes.fullHeight)
                }
                title={{
                    baseAsset: props.symbolInfo?.baseAsset,
                    quoteAsset: props.symbolInfo?.quoteAsset
                }}
                onItemClick={props.onItemClick}
            />
 */}
      <CustomListBoxNew
        dataSource={orderBook?.bids}
        sizeReference={orderBook?.sizeReference}
        inverseSeection={false}
        showHeader={props.shape === 'green' ? true : false}
        keyField='id'
        style={props.style}
        className={
          (props.shape === 'both' && classes.halfHeight) ||
          (props.shape === 'red' && classes.hide) ||
          (props.shape === 'green' && classes.fullHeight)
        }
        title={{
          baseAsset: props.symbolInfo?.baseAsset,
          quoteAsset: props.symbolInfo?.quoteAsset,
        }}
        onItemClick={props.onItemClick}
      />
    </div>
  );
};

export default CustomListBoxContainer;
