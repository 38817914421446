import { useEffect } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

export default function useCryptoForm(cryptoData) {

  const validators = Yup.object().shape({
    binanceApiValue: Yup.string().notRequired(),
    binanceSecValue: Yup.string().notRequired(),
  });

  const defaultValues = {
    binanceApiValue: cryptoData?.binanceApiValue || '',
    binanceSecValue: cryptoData?.binanceSecValue || '',
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (cryptoData) {
      reset(defaultValues);
    }
    if (!cryptoData) {
      reset(defaultValues);
    }
  }, [cryptoData]);

  return {
    handleSubmit,
    isSubmitting,
    methods,
  };
}
