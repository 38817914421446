import React from 'react';
import { Stack, Typography, MenuItem, useTheme, Link, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { RHFTextField, RHFSelect } from '../../../../../components/hookForm';
import Image from '../../../../../components/Image';
import enFlag from '../../../../../assets/flags/ic_flag_en.svg';
import faFlag from '../../../../../assets/flags/ic_flag_fa.png';
import iconEmptyPic from '../../../../../assets/gallery.svg';
import { openBase64 } from '../../../../../utils';
// import { RHFCustomUploadImage } from '../../../../../components/hookForm/RHFUpload';

const AdvancedTab = ({ data, handleDrop }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const countries = [
    { name: 'Iran', flag: faFlag },
    { name: 'England', flag: enFlag },
  ];

  return (
    <Stack
      sx={{
        mt: 5,
        width: { xs: '100%', md: '80%' },
        '& .Mui-disabled': {
          color: `${theme.palette.grey[500]} !important`,
          '& input': { WebkitTextFillColor: `${theme.palette.grey[0]} !important` },
        },
      }}
      spacing={{ xs: 5, md: 3 }}
    >
      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={5}>
        <RHFTextField
          name='country'
          label={t('Country / region')}
          variant='standard'
          sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
          disabled
        />
        {/* <RHFSelect
          name='country'
          SelectProps={{
            native: false,
            MenuProps: {
              sx: {
                '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' },
              },
            },
          }}
          label='Select your Country / region'
          variant='standard'
          sx={{ width: { xs: '100%', md: '50%' }, minWidth: 150, '& .MuiSelect-select': { display: 'flex' } }}
          disabled
        >
          {countries.map((country, index) => (
            <MenuItem key={index} value={country.name}>
              <Image
                disabledEffect
                alt={country.name}
                src={country.flag}
                sx={{ width: 24, mr: 1, borderRadius: '50%' }}
              />

              <Typography variant='body2' color='grey.100'>
                {country.name}
              </Typography>
            </MenuItem>
          ))}
        </RHFSelect> */}
        <RHFTextField
          name='address'
          label={t('Street Address')}
          variant='standard'
          sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
          disabled
        />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={5}>
        <RHFTextField
          name='postalCode'
          label={t('Postal Code')}
          variant='standard'
          sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
          disabled
        />
        <RHFTextField
          name='cityName'
          label={t('City')}
          variant='standard'
          sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
          disabled
        />
      </Stack>

      <Stack
        alignItems='center'
        justifyContent='center'
        sx={{
          mt: 5,
          width: 320,
          height: 200,
          border: '1px solid',
          borderColor: 'grey.500',
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        {data?.bill ? (
          <button
            onClick={() => openBase64(data?.bill)}
            style={{ outline: 0, backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
          >
            <Image src={data?.bill} />
          </button>
        ) : (
          <Image src={iconEmptyPic} sx={{ width: 30 }} />
        )}

        {/* <RHFCustomUploadImage
          name='bill'
          accept={{
            'image/jpeg': [],
            'image/png': [],
          }}
          showChangePhotoLabel
          onDrop={handleDrop}
        />
        <Typography variant='body3' color='grey.300' sx={{ mt: 1.75, pl: 0.5 }}>
          {t(`Upload .PDF/.JPG/.JPEG/.PNG file and no more than 5M`)}
        </Typography> */}
      </Stack>
    </Stack>
  );
};

export default AdvancedTab;
