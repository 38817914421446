export const filterDateRange = (arr, fromDate, toDate, attr = 'registeredDate') => {
  let fromTimestamp = 0;
  let toTimestamp = 0;

  if (fromDate !== null) {
    const from = new Date(fromDate);
    fromTimestamp = from.getTime();
  }

  if (toDate !== null) {
    const to = new Date(toDate);
    toTimestamp = to.getTime();
  }

  if (fromTimestamp !== 0 && toTimestamp !== 0) {
    return arr.filter((item) => fromTimestamp <= item[attr] && item[attr] <= toTimestamp);
  }

  if (fromTimestamp !== 0 && toTimestamp === 0) {
    return arr.filter((item) => fromTimestamp <= item[attr]);
  }

  if (fromTimestamp === 0 && toTimestamp !== 0) return arr.filter((item) => item[attr] <= toTimestamp);
};

const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate();

export const getDefaultDateFilterValues = () => {
  const firstDay = new Date();
  const lastDay = new Date();

  firstDay.setDate(1);
  firstDay.setMonth(firstDay.getMonth() - 6);
  firstDay.setDate(Math.min(lastDay.getDate(), getDaysInMonth(firstDay.getFullYear(), firstDay.getMonth() + 1)));

  return { firstDay, lastDay };
};
