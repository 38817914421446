import ComplexToolbar from '../../../../../../components/ComplexToolbar';

export default function AdminUserToolbar({ isExportLoading, onExport }) {
  return (
    <ComplexToolbar
      onSearch={null}
      onExport={onExport}
      isExportLoading={isExportLoading}
      onPrint={() => console.log('print')}
    />
  );
}
