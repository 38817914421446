import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ModalContainer from '../../../../components/ModalContainer';
import useWallet from '../../../../hooks/useWallet';

export default function DepositWalletModal({ open, onClose }) {
  const { userWallet } = useWallet();
  const { t } = useTranslation();

  return (
    <ModalContainer open={open} onClose={onClose} width={400} title={'Select Wallet'}>
      <RadioGroup name='wallet' sx={{ mt: 3 }} defaultChecked='main_wallet'>
        <FormControlLabel
          value={'main_wallet'}
          control={<Radio checked />}
          label={
            <Stack direction='row'>
              <Typography variant='body3' color='grey.300' sx={{ width: 175 }}>
                {t('Main Wallet')}
              </Typography>
              <Typography variant='body3' color='grey.300'>
                {userWallet?.usdtValue || 0} USDT
              </Typography>
            </Stack>
          }
        />

        <FormControlLabel
          value={'spot_wallet'}
          control={<Radio disabled />}
          label={
            <Stack direction='row'>
              <Typography variant='body3' color='grey.300' sx={{ width: 175 }}>
                {t('Spot Wallet')}
              </Typography>
              <Typography variant='body3' color='grey.300'>
                0 USDT
              </Typography>
            </Stack>
          }
        />

        <FormControlLabel
          value={'funding_wallet'}
          control={<Radio disabled />}
          label={
            <Stack direction='row'>
              <Typography variant='body3' color='grey.300' sx={{ width: 175 }}>
                {t('Funding Wallet')}
              </Typography>
              <Typography variant='body3' color='grey.300'>
                0 USDT
              </Typography>
            </Stack>
          }
        />
      </RadioGroup>
    </ModalContainer>
  );
}
