import { useEffect, useState } from 'react';
import QRCode from 'qrcode';

export default function useVerifyViaGoogle() {
  const [url, setUrl] = useState('https://google.com');
  const [qr, setQr] = useState('');
  const [code, setCode] = useState('S5AVELN6LQNUOBYF');

  const generateQRCode = () => {
    QRCode.toDataURL(
      url,
      {
        width: 116,
        margin: 2,
      },
      (err, url) => {
        if (err) return console.error(err);
        setQr(url);
      }
    );
  };

  useEffect(() => {
    if (url) {
      generateQRCode();
    }
  }, [url]);

  return {
    qr,
    code,
  };
}
