import { m } from 'framer-motion';
// @mui
import { Container, Stack, Typography } from '@mui/material';
// components
import MotionViewport from '../../../components/animate/MotionViewport';
import { varFade } from '../../../components/animate/variants/fade';
import { CircleShadow, CircleWrapper, CounterBack, RootSubStyle } from '../style';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Counter() {
  const { t } = useTranslation();

  return (
    <RootSubStyle sx={{ position: 'relative' }}>
      <CounterBack sx={{ display: { xs: 'none', md: 'block' } }} />

      <Container component={MotionViewport}>
        <m.div variants={varFade().in}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={'space-evenly'}
            alignItems={'center'}
            spacing={{ xs: 2.5, md: 0 }}
          >
            {[
              { count: '198+', size: 'small', label: 'Countries' },
              { count: '20 million+', size: 'large', label: 'Traders' },
              { count: '350+', size: 'small', label: 'Trading Pairs' },
            ].map((item, index) => (
              <CircleWrapper key={index} size={item.size} alignItems={'center'} justifyContent={'center'}>
                <CircleShadow size={item.size} />
                <Typography variant='h1_secondary' color='grey.100' sx={{ fontWeight: 500 }}>
                  {item.count}
                </Typography>
                <Typography variant='h6_secondary' color='grey.300' sx={{ fontWeight: 400 }}>
                  {t(item.label)}
                </Typography>
              </CircleWrapper>
            ))}
          </Stack>
        </m.div>
      </Container>
    </RootSubStyle>
  );
}
