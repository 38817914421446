import { CircularProgress, IconButton, MenuItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FillSelect from '../../../../components/FillSelect';
import Iconify from '../../../../components/Iconify';
import {
  FIAT_TYPES,
  FIAT_TYPES_JUST_IRR_EUR_USD,
  NEW_FIAT_TYPES, NEW_FIAT_TYPES_WITHOUT_IRR,
} from '../cryptoTransaction/cryptoTransactionTableToolbar';
import FillRangeDatePicker from '../../../../components/FillRangeDatePicker';
import { useSelector } from 'react-redux';

export const FIAT_STATUS = [
  { value: 'ALL', label: 'All' },
  { value: 'CANCELED', label: 'Canceled' },
  { value: 'FAILURE', label: 'Failure' },
  { value: 'NEW', label: 'New' },
  { value: 'SUCCESS', label: 'Success' },
];

export default function FiatTransactionTableToolbar({
  fiatStatusFilter,
  fiatTypeFilter,
  setFiatTypeFilter,
  timeFilter,
  setFiatStatusFilter,
  setTimeFilter,
  isExportLoading = false,
  onExport = () => {},
}) {
  const { t } = useTranslation();

  const isIranianPaymentActive = useSelector((store) => store.isIranianPaymentActive);

  const allFiats = isIranianPaymentActive ?  FIAT_TYPES_JUST_IRR_EUR_USD : NEW_FIAT_TYPES_WITHOUT_IRR
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent='space-between'
      alignItems={'center'}
      spacing={2}
      sx={{ mb: { xs: 3, md: 9 } }}
    >
      <Stack sx={{ width: '100%' }} flexDirection={'row'} alignItems={'flex-end'} gap={'15px'} flexWrap={'wrap'}>
        <FillRangeDatePicker
          value={timeFilter}
          setValue={setTimeFilter}
          variant='outlined'
          fullWidth
          sx={{ width: { xs: '100%', md: 335 } }}
          InputProps={null}
        />

        <FillSelect
          value={fiatTypeFilter}
          onChange={setFiatTypeFilter}
          label={t('Fiat Type')}
          sx={{ width: { xs: '100%', md: 150 } }}
        >
          {allFiats.map((item) => (
            <MenuItem key={item.value} value={item.value} sx={{ typography: 'body2' }}>
              {t(item.label)}
            </MenuItem>
          ))}
        </FillSelect>

        <FillSelect
          value={fiatStatusFilter}
          onChange={setFiatStatusFilter}
          label={t('Status')}
          sx={{ width: { xs: '100%', md: 220 } }}
        >
          {FIAT_STATUS.map((item) => (
            <MenuItem key={item.value} value={item.value} sx={{ typography: 'body2' }}>
              {t(item.label)}
            </MenuItem>
          ))}
        </FillSelect>
      </Stack>

      {onExport && (
        <>
          {isExportLoading ? (
            <CircularProgress size={22} color='success' sx={{ margin: '11px !important' }} />
          ) : (
            <>
              <IconButton onClick={onExport}>
                <Iconify icon='tabler:receipt' sx={{ width: 28, height: 28 }} />
              </IconButton>
              <IconButton onClick={() => onExport('PDF')}>
                <Iconify icon='majesticons:printer-line' sx={{ width: 28, height: 28 }} />
              </IconButton>
            </>
          )}
        </>
      )}
    </Stack>
  );
}
