import React from 'react';
import { TableCell, TableRow, Typography, Box, Stack, IconButton } from '@mui/material';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Iconify from '../../../../../../components/Iconify';
import { prettyUppercases } from '../../../../../../utils';
// import SymbolIcon from '../../../../../../components/SymbolIcon';

const FiatTransactionTableRow = ({ row, index }) => {
  const language = useSelector((store) => store.language);
  const { t } = useTranslation();

  const { amount, createDate, depositId, status, transactionType } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1, typography: 'body2' } }}>
      <TableCell align='left' sx={{ width: 100 }}>
        {index + 1}
      </TableCell>

      {/* <TableCell>
        <Stack spacing={0.5}>
          <Typography variant='body2'>{name}</Typography>
          <Typography variant='body2'>{mobile}</Typography>
        </Stack>
      </TableCell> */}
      {/* 
      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <SymbolIcon symbol={symbol} />
          <Typography variant='body2'>{currency}</Typography>
        </Stack>
      </TableCell> */}

      {/* <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <Iconify
            icon={arrow === 'up' ? 'ph:arrow-up-fill' : 'ph:arrow-down-fill'}
            color={arrow === 'up' ? 'error.darker' : 'positive.main'}
            height={18}
          />
          <Typography variant='body2' color={arrow === 'up' ? 'error.darker' : 'positive.main'}>
            {type}
          </Typography>
        </Stack>
      </TableCell> */}

      <TableCell align='left'>{depositId}</TableCell>

      <TableCell align='center'>{Number(amount).toLocaleString('en-US')}</TableCell>

      <TableCell align='center'>{moment(createDate).locale(language).format('D MMM YYYY - HH:mm')}</TableCell>

      <TableCell align='center'>
        <Box
          sx={{
            width: 100,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            p: 0.75,
            bgcolor:
              (status === 'SUCCESS' && 'rgba(48, 224, 161, 0.1)') ||
              (status === 'FAILURE' && 'rgba(224, 48, 48, 0.1)') ||
              'rgba(250, 174, 34, 0.1)',
          }}
        >
          <Typography
            variant='body3'
            sx={{
              color:
                (status === 'SUCCESS' && '#30E0A1') || (status === 'FAILURE' && 'negative.main') || 'rgb(218, 146, 13)',
              lineHeight: 1,
            }}
          >
            {t(prettyUppercases(status))}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align='center'>{t(prettyUppercases(transactionType))}</TableCell>

      {/* <TableCell sx={{ width: 90, display: 'flex' }} align='right'>
        <Box
          sx={{
            width: 130,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            p: 0.8,
            bgcolor: 'rgba(48, 224, 161, 0.2)',
          }}
        >
          <Typography variant='body2' color={'#30E0A1'}>
            {detail}
          </Typography>
        </Box>

        <IconButton>
          <Iconify icon='ph:link-duotone' />
        </IconButton>
      </TableCell> */}
    </TableRow>
  );
};

export default FiatTransactionTableRow;
