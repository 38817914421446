import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useEffect } from 'react';

export default function useLevelSettingForm(data) {
  const { t } = useTranslation();

  const validators = Yup.object().shape({
    cryptoWithdrawal: Yup.string().required(t('Please input withdrawal of cryptocurrency!')),
    fiatWithdrawal: Yup.string().required(t('Please input withdrawal of fiat!')),
    buyingDigitalMoneyDollar: Yup.string().required(t('Please input buying digital money (equivalent to dollar)!')),
    buyingDigitalMoneyRial: Yup.string().required(t('Please input buying digital money (equivalent to rial)!')),

    futuresMakerFee: Yup.string().required(t('Please input futures maker fee!')),
    futuresTakerFee: Yup.string().required(t('Please input futures taker fee!')),
    maxLeverage: Yup.string().required(t('Please input max leverage!')),
    maxMargin: Yup.string().required(t('Please input max margin!')),

    internalTransactionFee: Yup.string().required(t('Please input internal transaction fee!')),
    otcMinimumBuy: Yup.string().required(t('Please input minimum OTC buy!')),

    maximumInternalTransaction: Yup.string().required(t('Please input maximum internal transaction!')),
    minimumInternalTransaction: Yup.string().required(t('Please input minimum internal transaction!')),

    tradeMakerFee: Yup.string().required(t('Please input trade maker fee!')),
    tradeTakerFee: Yup.string().required(t('Please input trade taker fee!')),

    tetherBuyFee: Yup.string().required(t('Please input tether buy fee!')),
    tetherSellFee: Yup.string().required(t('Please input tether sell fee!')),
  });

  const defaultValues = useMemo(
    () => ({
      cryptoWithdrawal: data?.cryptoWithdrawal || '',
      fiatWithdrawal: data?.fiatWithdrawal || '',

      buyingDigitalMoneyDollar: data?.buyingDigitalMoneyDollar || '',
      buyingDigitalMoneyRial: data?.buyingDigitalMoneyRial || '',

      tradeMakerFee: data?.tradeMakerFee || '',
      tradeTakerFee: data?.tradeTakerFee || '',

      futuresTakerFee: data?.futuresTakerFee || '',
      futuresMakerFee: data?.futuresMakerFee || '',
      maxLeverage: data?.maxLeverage || '',
      maxMargin: data?.maxMargin || '',

      internalTransactionFee: data?.internalTransactionFee || '',
      otcMinimumBuy: data?.otcMinimumBuy || '',

      maximumInternalTransaction: data?.maximumInternalTransaction || '',
      minimumInternalTransaction: data?.minimumInternalTransaction || '',

      tetherBuyFee: data?.tetherBuyFee || '',
      tetherSellFee: data?.tetherSellFee || '',
    }),
    [data]
  );

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,

    formState: { isSubmitting, errors },
  } = methods;

  console.log(errors);

  useEffect(() => {
    reset(defaultValues);
  }, [data]);

  return {
    handleSubmit,
    isSubmitting,
    methods,
  };
}
