import { TableRow, TableCell, Typography, Tooltip, IconButton, Stack } from '@mui/material';
import moment from 'jalali-moment';
import { useSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Iconify from '../../../../components/Iconify';
import CopyItem from '../../../../components/CopyItem';

// ----------------------------------------------------------------------

export default function FiatTransactionTableRow({ row, isAdmin = false }) {
  const language = useSelector((store) => store.language);

  const { depositId, firstName, lastName, emailAddress, mobile, amount, createDate, status } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 2, typography: 'body2', color: 'grey.100' } }}>
      <TableCell>{moment(createDate).locale(language).format('YYYY-MM-DD HH:mm')}</TableCell>

      {isAdmin && (
        <TableCell>
          <Typography variant='body3'>
            {firstName} {lastName}
          </Typography>
          {emailAddress && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {emailAddress}
            </Typography>
          )}
          {mobile && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {mobile}
            </Typography>
          )}
        </TableCell>
      )}
      <TableCell>{amount}</TableCell>

      <TableCell>{status}</TableCell>

      <TableCell>
        <CopyItem value={depositId} textVariant='body4' />
      </TableCell>
    </TableRow>
  );
}
