import { alpha, styled } from '@mui/material/styles';
import { ListItemText, ListItemButton, ListItemIcon } from '@mui/material';

import { NAVBAR } from '../../../layouts/mainLayout/mainLayout';

// ----------------------------------------------------------------------

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'activeRoot' && prop !== 'activeSub' && prop !== 'subItem',
})(({ activeRoot, activeSub, subItem, theme }) => ({
  ...theme.typography.body3,
  position: 'relative',
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(1),
  marginBottom: theme.spacing(0),
  color: theme.palette.grey[300],
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    color: theme.palette.grey[0],
  },
  ...(theme.palette.mode === 'light' && {
    '& img': {
      filter: `grayscale(100%) brightness(0%) saturate(0%) contrast(20%)`,
      WebkitFilter: `grayscale(100%) brightness(0%) saturate(0%) contrast(20%)`,
      MozFilter: `grayscale(100%) brightness(0%) saturate(0%) contrast(20%)`,
    },
  }),
  // activeRoot
  ...(activeRoot && {
    color: theme.palette.grey[0],
    '&:before': {
      content: "''",
      width: 8,
      height: 8,
      backgroundColor: theme.palette.grey[0],
      borderRadius: '100%',
      marginLeft: theme.spacing(2.5),
    },
    '& img': {
      filter: `grayscale(100%) brightness(${
        theme.palette.mode === 'dark' ? '200%' : '0%'
      }) saturate(0%) contrast(1000%)`,
      WebkitFilter: `grayscale(100%) brightness(${
        theme.palette.mode === 'dark' ? '200%' : '0%'
      }) saturate(0%) contrast(1000%)`,
      MozFilter: `grayscale(100%) brightness(${
        theme.palette.mode === 'dark' ? '200%' : '0%'
      }) saturate(0%) contrast(1000%)`,
    },
  }),
  // activeSub
  ...(activeSub && {
    ...theme.typography.body2,
    color: theme.palette.grey[0],
  }),
  // subItem
  ...(subItem && {
    height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
  }),
}));

export const ListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
})(({ isCollapse, theme }) => ({
  whiteSpace: 'nowrap',
  transition: theme.transitions.create(['width', 'opacity'], {
    duration: theme.transitions.duration.shorter,
  }),
  ...(isCollapse && {
    width: 0,
    opacity: 0,
  }),
}));

export const ListItemIconStyle = styled(ListItemIcon)(({ subItem }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...(subItem && { minWidth: 30, marginLeft: 15 }),
  '& svg': { width: '100%', height: '100%' },
}));
