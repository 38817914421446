import { Container, Divider, Link, Stack, styled, Typography } from '@mui/material';

import useUserAuth from '../../hooks/useUserAuth';
import LoadingScreen from '../../components/LoadingScreen';
import TopMenu from '../landing/sections/topMenu';
import Footer from '../landing/sections/footer';

export default function PrivacyPolicy() {
  const { loading } = useUserAuth(false);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Stack sx={{ minHeight: 1 }}>
      <TopMenu />

      <RootStyle>
        <Container>
          <Typography variant='h5'>Cryptizi Privacy policy</Typography>

          <Typography variant='body2' color='grey.300' sx={{ mt: 4 }}>
            Version: 17 February 2023
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Cryptizi (“Cryptizi,” “we,” or “us”) is committed to protecting the privacy of our customers, and we take
            our data protection responsibilities with the utmost seriousness.
            <br />
            This Privacy Notice describes how Cryptizi collects and processes your personal information through the
            Cryptizi websites and applications that reference this Privacy Notice. Cryptizi refers to an ecosystem
            comprising Cryptizi websites (whose domain names include but are not limited to (
            <Link href='www.cryptizi.com' color='primary.main'>
              www.cryptizi.com
            </Link>
            ), mobile applications, clients, applets, and other applications that are developed to offer Cryptizi
            Services, and includes independently-operated platforms, websites, and clients within the ecosystem (e.g.,
            Cryptizi’s Open Platform, Cryptizi Launchpad, Cryptizi Labs, Cryptizi Charity, Cryptizi DEX, gateways).
            “Cryptizi Operators” refer to all parties that run Cryptizi, including but not limited to legal persons,
            unincorporated organizations, and teams that provide Cryptizi Services and are responsible for such
            services. “Cryptizi” as used in this Privacy Notice includes Cryptizi Operators.
            <br />
            This Privacy Notice applies to all Personal Information processing activities carried out by us across
            platforms, websites, and departments of Cryptizi and Cryptizi Operators. To the extent that you are a
            customer or user of our services, this Privacy Notice applies together with any terms of business and other
            contractual documents, including but not limited to any agreements we may have with you.
            <br />
            To the extent that you are not a relevant stakeholder, customer, or user of our services but are using our
            website, this Privacy Notice also applies to you with our Cookie Notice. This Notice should be read with our
            Cookie Notice, which provides further details on our use of cookies on the website. Our Cookie Notice is
            available here.
          </Typography>

          <Typography variant='subtitle2' sx={{ mt: 3 }}>
            1. Cryptizi Relationship with you
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Depending on your place of legal residence, certain other Cryptizi entities could be involved in processing
            activities such as Know Your Customer (“KYC”) activities which are necessary for us to provide Services to
            you. For example, if you are a resident of France, Cryptizi France is responsible for running KYC checks.
            These Cryptizi entities could act as Controllers of your personal information and use it in accordance with
            this Privacy Notice.
          </Typography>

          <Typography variant='subtitle2' sx={{ mt: 3 }}>
            2. What Personal Information does Cryptizi collect and process? Why does Cryptizi process my personal
            information? What is the legal basis for our use of personal information? What personal information does
            Cryptizi collect and process?
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 0.5, ml: 1, textAlign: 'justify' }}>
            - email address;
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: 2, mt: 0.5, ml: 1, textAlign: 'justify' }}>
            - name;
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: 2, mt: 0.5, ml: 1, textAlign: 'justify' }}>
            - gender;
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: 2, mt: 0.5, ml: 1, textAlign: 'justify' }}>
            - date of birth;
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: 2, mt: 0.5, ml: 1, textAlign: 'justify' }}>
            - home address;
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: 2, mt: 0.5, ml: 1, textAlign: 'justify' }}>
            - phone number;
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: 2, mt: 0.5, ml: 1, textAlign: 'justify' }}>
            - nationality;
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: 2, mt: 0.5, ml: 1, textAlign: 'justify' }}>
            - device ID;
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: 2, mt: 0.5, ml: 1, textAlign: 'justify' }}>
            - a video recording of you and a photographic image;
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: 2, mt: 0.5, ml: 1, textAlign: 'justify' }}>
            - transactional information;
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: 2, mt: 0.5, ml: 1, textAlign: 'justify' }}>
            - the Internet protocol (IP) address used to connect your computer to the Internet;
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: 2, mt: 0.5, ml: 1, textAlign: 'justify' }}>
            - login, e-mail address, password and location of your device or computer;
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: 2, mt: 0.5, ml: 1, textAlign: 'justify' }}>
            - Cryptizi Services metrics (e.g., the occurrences of technical errors, your interactions with service
            features and content, and your settings preferences);
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: 2, mt: 0.5, ml: 1, textAlign: 'justify' }}>
            - version and time zone settings;
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: 2, mt: 0.5, ml: 1, textAlign: 'justify' }}>
            - transaction history;
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: 2, mt: 0.5, ml: 1, textAlign: 'justify' }}>
            - Information from other sources: we may receive information about you from other sources such as credit
            history information from credit bureaus;
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: 2, mt: 0.5, ml: 1, textAlign: 'justify' }}>
            - Information about your activity we may process information about you on your behavior and your activity
            for marketing and advertising purposes.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Why does Cryptizi process my personal information?</strong>
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
            - Transaction Services. We use your personal information to process your orders and to communicate with you
            about orders and services;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
            - Communicate with you. We use your personal information to communicate with you in relation to Cryptizi
            Services;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
            - We collect and process identity information and Sensitive Personal Data (as detailed in section I) to
            comply with our Know Your Customer (“KYC”) obligations under applicable laws and regulations and Anti-Money
            Laundering laws and regulations;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
            - Provide, troubleshoot, and improve Cryptizi Services. We use your personal information to provide
            functionality, analyze performance, fix errors, and improve the usability and effectiveness of Cryptizi
            Services.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
            - Fraud prevention and credit risks. We process personal information to prevent and detect fraud and abuse
            to protect the security of our users, Cryptizi Services, and others. We may also use scoring methods to
            assess and manage credit risks.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
            - Improve our services. We process personal information to improve our services and for you to have a better
            user experience;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
            - Recommendations and personalization. We use your personal information to recommend features and services
            that might be of interest to you, identify your preferences, and personalize your experience with Cryptizi
            Services;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Legal Basis for our use of personal information (EU and UK GDPR)</strong>
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Performance of a contract</strong> when we provide you with products or services or communicate with
            you about them. This includes when we use your personal information to take and handle orders and process
            payments.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Legal obligation;</strong> to comply with our legal obligations under applicable laws and
            regulations and Anti-Money Laundering laws and regulations.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Your consent</strong> when we ask for your consent to process your personal information for a
            specific purpose that we communicate to you. When you consent to process your personal information for a
            specified purpose, you may withdraw your consent at any time, and we will stop processing your personal
            information for that purpose. The withdrawal of consent does not affect the lawfulness of processing based
            on consent before its withdrawal.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Our legitimate</strong> interests and the interests of our users when, for example, we detect and
            prevent fraud and abuse in order to protect the security of our users, ourselves, or others;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Performance of a contract</strong> when we provide you with products or services or communicate with
            you about them. This includes when we use your personal information to take and handle orders and process
            payments.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Legal obligation;</strong> to comply with our legal obligations under applicable laws and
            regulations, and Anti-Money Laundering laws and regulations
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Our legitimate interests</strong> and the interests of our users when, for example, we detect and
            prevent fraud and abuse in order to protect the security of our users, ourselves, or others;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Our legitimate interest</strong> to improve our services;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Your consent</strong> when we ask for your consent to process your personal information for a
            specific purpose that we communicate to you. When you consent to process your personal information for a
            specified purpose, you may withdraw your consent at any time, and we will stop processing your personal
            information for that purpose. The withdrawal of consent does not affect the lawfulness of processing based
            on consent before its withdrawal
          </Typography>

          <Typography variant='subtitle2' sx={{ mt: 3 }}>
            3. Can Children Use Cryptizi Services?
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Cryptizi does not allow anyone under the age of 18 to use Cryptizi Services and does not knowingly collect
            personal information from children under 18.
          </Typography>

          <Typography variant='subtitle2' sx={{ mt: 3 }}>
            4. What About Cookies and Other Identifiers?
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            We use cookies and similar tools to enhance your user experience, provide our services, enhance our
            marketing efforts, and understand how customers use our services so we can make improvements. Depending on
            applicable laws in the region you are located in, the cookie banner on your browser will tell you how to
            accept or refuse cookies. A copy of our cookie policy is available here.
          </Typography>

          <Typography variant='subtitle2' sx={{ mt: 3 }}>
            5. Does Cryptizi Share My Personal Information?
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            We may share your Personal Data with third parties (including other Cryptizi entities) if we believe that
            sharing your Personal Data is in accordance with, or required by, any contractual relationship with you or
            us, applicable law, regulation, or legal process. When sharing your Personal Information with other Cryptizi
            entities, we will use our best endeavors to ensure that such entity is either subject to this Privacy Notice
            or follow practices at least as protective as those described in this Privacy Notice. For example, if you
            are a resident of France, Cryptizi France is responsible for running KYC checks.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            We may also share personal information with the following persons:
          </Typography>

          <ul style={{ marginLeft: 50 }}>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
                <strong>Third-party service providers:</strong> We employ other companies and individuals to perform
                functions on our behalf. Examples include analyzing data, providing marketing assistance, processing
                payments, transmitting content, and assessing and managing credit risk. These third-party service
                providers only have access to personal information needed to perform their functions but may not use it
                for other purposes. Further, they must process the personal information in accordance with our
                contractual agreements and only as permitted by applicable data protection laws.
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                <strong>Legal Authorities:</strong> We may be required by law or by Court to disclose certain
                information about you or any engagement we may have with you to relevant regulatory, law enforcement,
                and/or other competent authorities. We will disclose information about you to legal authorities to the
                extent we are obliged to do so according to the law. We may also need to share your information in order
                to enforce or apply our legal rights or to prevent fraud.
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                <strong>Business transfers:</strong> As we continue to develop our business, we might sell or buy other
                businesses or services. In such transactions, user information generally is one of the transferred
                business assets but remains subject to the promises made in any pre-existing Privacy Notice (unless, of
                course, the user consents otherwise). Also, in the unlikely event that Cryptizi or substantially all of
                its assets are acquired by a third party, user information will be one of the transferred assets.
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                <strong>Protection of Cryptizi and others:</strong> We release accounts and other personal information
                when we believe release is appropriate to comply with the law or with our regulatory obligations;
                enforce or apply our Terms of Use and other agreements; or protect the rights, property or safety of
                Cryptizi, our users or others. This includes exchanging information with other companies and
                organizations for fraud protection and credit risk reduction.
              </Typography>
            </li>
          </ul>

          <Typography variant='subtitle2' sx={{ mt: 3 }}>
            6. International transfers of Personal Information
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            To facilitate our global operations, Cryptizi may transfer your personal information outside of the European
            Economic Area (“EEA”), UK, and Switzerland. The EEA includes the European Union countries as well as
            Iceland, Liechtenstein, and Norway. Transfers outside of the EEA are sometimes referred to as “third-country
            transfers”.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            We may transfer your personal data to our Affiliates, third-party partners, and service providers based
            throughout the world. In cases where we intend to transfer personal data to third countries or international
            organizations outside of the EEA. Cryptizi puts in place suitable technical, organizational, and contractual
            safeguards (including Standard Contractual Clauses) to ensure that such transfer is carried out in
            compliance with applicable data protection rules, except where the country to which the personal information
            is transferred has already been determined by the European Commission to provide an adequate level of
            protection.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            We also rely on decisions from the European Commission, which they recognise that certain countries and
            territories outside of the European Economic Area ensure an adequate level of protection for personal
            information. These decisions are referred to as “adequacy decisions”. We transfer personal data to Japan on
            the basis of the Japanese Adequacy Decision.
          </Typography>

          <Typography variant='subtitle2' sx={{ mt: 3 }}>
            7. How Secure is My Information?
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            We design our systems with your security and privacy in mind. We have appropriate security measures in place
            to prevent your information from being accidentally lost, used, or accessed in an unauthorized way, altered,
            or disclosed. We work to protect the security of your personal information during transmission and while
            stored by using encryption protocols and software. We maintain physical, electronic, and procedural
            safeguards in connection with the collection, storage, and disclosure of your personal information. In
            addition, we limit access to your personal information to those employees, agents, contractors, and other
            third parties who have a business need to know.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Our security procedures mean that we may ask you to verify your identity to protect you against unauthorized
            access to your account password. We recommend using a unique password for your Cryptizi account that is not
            utilized for other online accounts and signing off when you finish using a shared computer.
          </Typography>

          <Typography variant='subtitle2' sx={{ mt: 3 }}>
            8. What About Advertising?
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            In order for us to provide you with the best user experience, we may share your personal information with
            our marketing partners for the purposes of targeting, modeling, and/or analytics ,as well as marketing and
            advertising. You have a right to object at any time to the processing of your personal information for
            direct marketing purposes (see Section 9 below).
          </Typography>

          <Typography variant='subtitle2' sx={{ mt: 3 }}>
            9. What Rights Do I Have?
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Subject to applicable law, as outlined below, you have a number of rights in relation to your privacy and
            the protection of your personal information. You have the right to request access to, correct, and delete
            your personal information and to ask for data portability. You may also object to our processing of your
            personal information or ask that we restrict the processing of your personal information in certain
            instances. In addition, when you consent to our processing of your personal information for a specified
            purpose, you may withdraw your consent at any time. If you want to exercise any of your rights, please
            contact us at{' '}
            <Link href='mailto:dpo@cryptizi.com' color='primary.main'>
              dpo@cryptizi.com
            </Link>{' '}
            . These rights may be limited in some situations - for example, where we can demonstrate we have a legal
            requirement to process your personal data.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Right to access:</strong> you have the right to obtain confirmation that your personal information
            is processed and to obtain a copy of it as well as certain information related to its processing;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Right to rectify:</strong> you can request the rectification of your personal information which are
            inaccurate, and also add to it. You can also change your personal information in your Account at any time.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Right to delete:</strong> you can, in some cases, have your personal information deleted;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Right to object:</strong> you can object, for reasons relating to your particular situation, to the
            processing of your personal information. For instance, you have the right to object where we rely on
            legitimate interest or where we process your data for direct marketing purposes;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Right to restrict processing:</strong> You have the right, in certain cases, to temporarily restrict
            the processing of your personal information by us, provided there are valid grounds for doing so. We may
            continue to process your personal information if it is necessary for the defense of legal claims or for any
            other exceptions permitted by applicable law;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Right to portability:</strong> in some cases, you can ask to receive your personal information which
            you have provided to us in a structured, commonly used and machine-readable format, or, when this is
            possible, that we communicate your personal information on your behalf directly to another data controller;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>Right to withdraw your consent:</strong> for processing requiring your consent, you have the right
            to withdraw your consent at any time. Exercising this right does not affect the lawfulness of the processing
            based on the consent given before the withdrawal of the latter; Right to lodge a complaint with the relevant
            data protection authority: We hope that we can satisfy any queries you may have about the way in which we
            process your personal information. However, if you have unresolved concerns, you also have the right to
            complain to the Irish Data Protection Commission or the data protection authority in the location in which
            you live, work or believe a data protection breach has occurred.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            If you have any questions or objections as to how we collect and process your personal information, please
            contact{' '}
            <Link href='mailto:dpo@cryptizi.com' color='primary.main'>
              dpo@cryptizi.com
            </Link>
          </Typography>

          <Typography variant='subtitle2' sx={{ mt: 3 }}>
            10. How Long Does Cryptizi Keep My Personal Information?
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            We keep your personal information to enable your continued use of Cryptizi Services for as long as it is
            required in order to fulfill the relevant purposes described in this Privacy Notice and as may be required
            by law, such as for tax and accounting purposes, compliance with Anti-Money Laundering laws, or as otherwise
            communicated to you.
          </Typography>

          <Typography variant='subtitle2' sx={{ mt: 3 }}>
            11. Contact Information
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Our data protection officer can be contacted at{' '}
            <Link href='mailto:dpo@cryptizi.com' color='primary.main'>
              dpo@cryptizi.com
            </Link>{' '}
            and will work to address any questions or issues that you have with respect to the collection and processing
            of your personal information.
          </Typography>

          <Typography variant='subtitle2' sx={{ mt: 3 }}>
            12. Notices and Revisions
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            If you have any concerns about privacy at Cryptizi, please contact us, and we will try to resolve it. You
            also have the right to contact your local Data Protection Authority.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Our business changes regularly, and our Privacy Notice may change also. You should check our websites
            frequently to see recent changes. Unless stated otherwise, our current Privacy Notice applies to all
            information that we have about you and your account.
          </Typography>

          <Divider sx={{ mt: 8, mb: -6 }} />
        </Container>

        <Footer />
      </RootStyle>
    </Stack>
  );
}

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  width: '100%',
  marginTop: 130,

  direction: theme.direction === 'rtl' ? 'rtl' : 'ltr',
}));
