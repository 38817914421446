import { useState } from 'react';
import { Stack, Typography, MenuItem, TextField, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';

import ModalContainer from '../../../../components/ModalContainer';
import { NumberField } from '../../../../components/NumberField';
import { fFloat } from '../../../../utils/formatNumber';
import Api from '../../../../http/serviceApi';

const FuturesMarginModal = ({ open, onClose, data }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // ----------------------
  const [type, setType] = useState('ADD');
  const [amount, setAmount] = useState(data?.entryPrice || 0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // ----------------------
  useEffect(() => {
    if (amount !== data?.entryPrice) {
      setAmount(data?.entryPrice || 0);
    }
  }, [data?.entryPrice]);

  // ---------------
  const onConfirm = async () => {
    if (!amount) {
      return enqueueSnackbar(t('Please fill in all fields'), { variant: 'warning' });
    }

    setIsSubmitting(true);

    try {
      await Api.adjustMarginFutures(amount, data?.id, type);
      onClose();
    } catch (e) {}

    setIsSubmitting(false);
  };

  // ----------------------
  return (
    <ModalContainer open={open} onClose={onClose} title={'Adjust Margin'} width={470}>
      <Stack sx={{ mt: 3, mb: 1 }} spacing={2}>
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ pt: 2 }}>
          <Typography variant='body2' color='grey.300'>
            {t('Amount')} ({data?.dstSymbol || ''})
          </Typography>
          <Typography variant='body2'>
            {t('Max addable')}: {fFloat(data?.entryPrice || 0)} {data?.dstSymbol || ''}
          </Typography>
        </Stack>

        <Stack
          direction={'row'}
          alignItems={'center'}
          sx={{
            backgroundColor: 'black.100',
            borderRadius: 1,
            py: 1,
            px: 1.5,
          }}
        >
          <TextField
            fullWidth
            select
            variant='standard'
            label=''
            value={type}
            onChange={(event) => setType(event.target.value)}
            SelectProps={{
              sx: { pl: 1 },
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              mr: 1,
              '& .MuiInput-root::before': {
                display: 'none',
              },
              '& .MuiInput-root::after': {
                display: 'none',
              },
              width: 130,
            }}
          >
            {[
              { label: 'Add', value: 'ADD' },
              { label: 'Remove', value: 'REMOVE' },
            ].map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body3',
                }}
              >
                {t(item.label)}
              </MenuItem>
            ))}
          </TextField>
          <Divider orientation='vertical' variant='middle' flexItem />
          <NumberField
            variant='standard'
            value={amount}
            onChange={(event) => setAmount(event.target.value)}
            fullWidth
            placeholder='0'
            sx={{
              ml: 2,
              '& .MuiInput-root::before': {
                display: 'none',
              },
              '& .MuiInput-root::after': {
                display: 'none',
              },
              '& input': { color: 'grey.0', height: '1.55em' },
              '& .Mui-disabled': {
                '& input': { WebkitTextFillColor: 'grey.0 !important' },
                '&:before': { borderBottomStyle: 'solid !important' },
              },
            }}
          />
        </Stack>

        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ pt: 2 }}>
          <Typography variant='body2' color='grey.300'>
            {t('Currently Margin')}
          </Typography>
          <Typography variant='body2'>
            {fFloat(data?.margin || 0)} {data?.dstSymbol || ''}
          </Typography>
        </Stack>

        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='body2' color='grey.300'>
            {t('Max addable')}
          </Typography>
          <Typography variant='body2'>
            {fFloat(data?.entryPrice || 0)} {data?.dstSymbol || ''}
          </Typography>
        </Stack>

        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='body2' color='grey.300'>
            {t('Est.Liq.Price after increase')}
          </Typography>
          <Typography variant='body2'>
            {fFloat(0)} {data?.dstSymbol || ''}
          </Typography>
        </Stack>
      </Stack>

      <LoadingButton
        fullWidth
        size='large'
        variant='contained'
        sx={{ mt: 3.5 }}
        loading={isSubmitting}
        onClick={onConfirm}
      >
        {t('Confirm')}
      </LoadingButton>
    </ModalContainer>
  );
};

export default FuturesMarginModal;
