import { useTranslation } from 'react-i18next';
import { Button, InputBase, MenuItem, Stack, Typography } from '@mui/material';

import FillSelect from '../../../../components/FillSelect';

import Iconify from '../../../../components/Iconify';
import { fiatList } from '../request/p2pTab';
import Image from '../../../../components/Image';
import { useRef, useState } from 'react';
import { debounced } from '../../../../utils';
import { useSelector } from 'react-redux';

export default function P2PFilter({
  fiatFilter,
  setSymbolFilter,
  paymentFilter,
  setPaymentFilter,
  onRefresh = () => {},
  onSearch = () => {},
}) {
  const { t } = useTranslation();
  const isIranianPaymentActive = useSelector((store) => store.isIranianPaymentActive);

  const paymentList = [
    { label: 'DEBIT', value: 'DEBIT' },
    ...(isIranianPaymentActive ? [{ label: 'SHETAB', value: 'SHETAB' }] : []),
    { label: 'FAST PAY', value: 'FAST_PAY' },
    { label: 'WALLET', value: 'WALLET' },
  ];

  const [searchValue, setSearchValue] = useState('');
  const onSearchValue = useRef(
    debounced((q) => {
      onSearch(q);
    }, 700)
  ).current;

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      alignItems={'center'}
      justifyContent={'space-between'}
      sx={{ mx: { xs: 3, md: 5 }, mt: 3.75, mb: 6 }}
      spacing={1}
    >
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} alignItems={'center'} sx={{ width: '100%' }}>
        <Stack
          direction='row'
          sx={{
            backgroundColor: 'grey.700',
            border: '1px solid',
            borderColor: 'grey.400',
            pl: 2,
            pr: 1,
            height: 45,
            width: { xs: '100%', lg: 280 },
          }}
        >
          <InputBase
            sx={{
              flex: 1,
              '& input': { typography: 'body2', color: 'grey.0', '&::placeholder': { color: 'grey.400' } },
            }}
            type='number'
            placeholder={t('Enter Amount')}
            inputProps={{ 'aria-label': 'search' }}
            onChange={(e) => {
              onSearchValue(e.target.value);
              setSearchValue(e.target.value);
            }}
            value={searchValue}
          />

          <Button type='button' aria-label='search' color='secondary'>
            {t('Search')}
          </Button>
        </Stack>

        <FillSelect
          value={fiatFilter}
          onChange={setSymbolFilter}
          sx={{ width: { xs: '100%', md: 200 }, '& .MuiSelect-select': { color: 'grey.0', p: '13px 16px 12px' } }}
        >
          {fiatList.map((fiat, index) => (
            <MenuItem value={fiat.label} key={index}>
              <Stack direction='row' alignItems={'center'} spacing={1.5}>
                <Image src={fiat.icon} sx={{ width: 20 }} />
                <Typography variant='body2'>{fiat.label}</Typography>
              </Stack>
            </MenuItem>
          ))}
        </FillSelect>

        <FillSelect
          value={paymentFilter}
          onChange={setPaymentFilter}
          sx={{ width: { xs: '100%', md: 200 }, '& .MuiSelect-select': { color: 'grey.0', p: '13px 16px 12px' } }}
        >
          {[{ label: t('All Payment Methods'), value: 'ALL' }, ...paymentList].map((payment, index) => (
            <MenuItem value={payment.value} key={index}>
              <Typography variant='body2'>{payment.label}</Typography>
            </MenuItem>
          ))}
        </FillSelect>
      </Stack>

      <Button
        variant={'contained'}
        size='large'
        color={'secondary'}
        onClick={onRefresh}
        sx={{ background: 'grey.700', width: { xs: '100%', md: 140 }, height: 48, borderRadius: 0 }}
        startIcon={<Iconify icon='ant-design:swap-outlined' />}
      >
        {t('Refresh')}
      </Button>
    </Stack>
  );
}
