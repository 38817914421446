import { useTranslation } from 'react-i18next';
import { Button, MenuItem, Stack, TextField } from '@mui/material';

import ComplexToolbar from '../../../../components/ComplexToolbar';
import DatePicker from '../../../../components/DatePicker';
import NumberRange from '../../../../components/NumberRange';
import { NumberField } from '../../../../components/NumberField';
import NumRangeWithInput from '../../../../components/NumRangeWithInput';

const AUTH_LEVELS = [
  'ALL',
  'ADVANCED',
  'ADVANCED_BY_ADMIN',
  'ADVANCED_REJECTED',
  'ADVANCED_REQUESTED',
  'BASIC',
  'BASIC_BY_ADMIN',
  'BASIC_REQUESTED',
  'INTERMEDIATE',
  'INTERMEDIATE_BY_ADMIN',
  'INTERMEDIATE_REJECTED',
  'INTERMEDIATE_REQUESTED',
  'REGISTERED',
];

const CONDITIONS = ['ALL', 'ACTIVE', 'DISAPPROVAL', 'NO_CONDITION', 'WAITING'];

const UserToolbar = ({
  conditionFilter,
  setConditionFilter,
  dateFromFilter,
  setDateFromFilter,
  dateToFilter,
  setDateToFilter,
  nameFilter,
  setNameFilter,
  userIdFilter,
  setUserIdFilter,
  balanceFilter,
  setBalanceFilter,
  authenticationLevelFilter,
  setAuthenticationLevelFilter,
  onFilter,
  onExport,
  isExportLoading,
}) => {
  const { t } = useTranslation();

  const onReset = async () => {
    setConditionFilter('ALL');
    setDateFromFilter(null);
    setDateToFilter(null);
    setNameFilter('');
    setUserIdFilter(null);
    setBalanceFilter([0, 50000]);
    setAuthenticationLevelFilter('ALL');
    onFilter(false);
  };

  return (
    <ComplexToolbar
      onSearch={null}
      onExport={onExport}
      onPrint={() => console.log('print')}
      isExportLoading={isExportLoading}
    >
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} sx={{ mt: 2 }} alignItems={'center'}>
          <NumberField
            label={t('User Id')}
            fullWidth
            variant='standard'
            sx={{ maxWidth: 300 }}
            value={userIdFilter}
            onChange={(e) => setUserIdFilter(e.target.value)}
          />

          <TextField
            label={t('Name')}
            variant='standard'
            fullWidth
            sx={{ maxWidth: 300 }}
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />

          <TextField
            select
            label={t('Condition')}
            variant='standard'
            fullWidth
            value={conditionFilter}
            onChange={(e) => setConditionFilter(e.target.value)}
            sx={{ maxWidth: 300 }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            {CONDITIONS.map((condition) => (
              <MenuItem key={condition} value={condition}>
                {t(condition)}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'center'} spacing={5}>
          <Stack direction={'row'}>
            <DatePicker
              label={t('From')}
              value={dateFromFilter}
              setValue={(e)=>{
                setDateFromFilter(e.$d)
              }}
              variant='standard'
              maxDate={dateToFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
              InputProps={null}
            />
            <DatePicker
              label={t('To')}
              value={dateToFilter}
              setValue={(e)=>{
                setDateToFilter(e.$d)
              }}
              variant='standard'
              minDate={dateFromFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
            />
          </Stack>
          <TextField
            select
            label={t('Level')}
            variant='standard'
            fullWidth
            value={authenticationLevelFilter}
            onChange={(e) => setAuthenticationLevelFilter(e.target.value)}
            sx={{ maxWidth: 300 }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            {AUTH_LEVELS.map((level) => (
              <MenuItem key={level} value={level}>
                {t(level)}
              </MenuItem>
            ))}
          </TextField>
          <NumRangeWithInput label={'Balance'} value={balanceFilter} setValue={setBalanceFilter} sx={{ maxWidth: 300 }} />

          {/*<NumberRange label={'Balance'} value={balanceFilter} setValue={setBalanceFilter} sx={{ maxWidth: 300 }} />*/}
        </Stack>
      </Stack>

      <Stack direction={'row'} spacing={2} sx={{ mt: 5, mb: 5.75 }}>
        <Button
          onClick={onFilter}
          fullWidth
          variant='contained'
          sx={{ maxWidth: 204, typography: 'body3', fontWeight: 500 }}
        >
          {t('Filter')}
        </Button>
        <Button
          onClick={onReset}
          fullWidth
          variant='outlined'
          color='inherit'
          sx={{ maxWidth: 80, typography: 'body3', fontWeight: 500, borderColor: 'grey.400' }}
        >
          {t('Reset')}
        </Button>
      </Stack>
    </ComplexToolbar>
  );
};

export default UserToolbar;
