import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { fCurrency, fFloat } from '../../utils/formatNumber';

import SymbolIcon from '../SymbolIcon';

export default function RowSymbol({ item, onSelect }) {
  const { t } = useTranslation();
  const { name, symbol, volumeUsdt, changeUsdt, usdtValue, usdValue, hot } = item;

  return (
    <Button fullWidth onClick={onSelect} sx={{ py: 1.5, px: 2, borderRadius: 1, textAlign: 'left' }}>
      <Stack direction='row' alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
        <Stack direction='row' alignItems={'center'} spacing={2}>
          <SymbolIcon symbol={symbol} width={24} height={24} />

          <Stack spacing={0.2}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Typography variant='subtitle2'>{symbol}</Typography>
              {hot && (
                <Typography
                  variant='body3'
                  color='secondary'
                  sx={{ bgcolor: 'rgba(242, 127, 22, 0.1)', borderRadius: 1.5, px: 1, py: 0.1 }}
                >
                  {t('Hot')}
                </Typography>
              )}
            </Stack>

            <Typography variant='body3' color='grey.300' noWrap>
              {name}
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing={0.2} alignItems={'flex-end'}>
          <Typography variant='body2'>{fFloat(volumeUsdt)}</Typography>
          <Typography variant='body4_secondary' color='#FA2256'>
            = {fCurrency(usdValue)}
          </Typography>
        </Stack>
      </Stack>
    </Button>
  );
}
