import React , {useEffect , useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { SET_PAGE_STATE } from '../../../store/actionTypes';
import { Box, Button, Card, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { PageTitle } from '../../../components/PageTitle';
import TradeHistoryTableToolbar from '../../common/history/tradeHistory/tradeHistoryTableToolbar';
import DedicatedWalletTableToolbar from './DedicatedWalletTableToolbar';
import { getDefaultDateFilterValues } from '../../../utils/filter';
import AdminApi from '../../../http/adminHttp/serviceApiAdmin';
import Api from '../../../http/serviceApi';
import useTable, { getComparator } from '../../../hooks/useTable';
import Scrollbar from '../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../components/table';
import { applySortFilter } from '../../../utils';
import TradeHistoryTableRow from '../../common/history/tradeHistory/tradeHistoryTableRow';
import DedicatedWalletSettingsTableRow from './dedicatedWalletSettingsTableRow';
import TradeHistoryModal from '../../common/history/tradeHistory/tradeHistoryModal';
import DediactedWalletTableRowViewModal from './DediactedWalletTableRowViewModal';
import { navigationUrl } from '../../../navigationUrl';
import AddDedicatedWallet from './AddDedicatedWallet';

const DedicatedWalletSettings = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Dedicated Wallet Settings' },
    });
  }, []);

  // --------------------
  const TABLE_HEAD = [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'symbol', label: 'Symbol', align: 'left' },
    { id: 'withdrawFee', label: 'Withdraw Fee', align: 'center' },
    { id: 'networkName', label: 'Network Name', align: 'center' },
    { id: 'details', label: 'Details', align: 'center' },
    { id: 'edit', label: 'Edit', align: 'center' },

  ];

  // --------------------


  const [dataFiltered, setDataFiltered] = useState([]);

  const [nameFilter, setNameFilter] = useState('');
  const [networkNameFilter, setNetworkNameFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('ALL'); // ALL, NOT_SET
  const [timeFilter, setTimeFilter] = useState({
    startTime: getDefaultDateFilterValues().firstDay,
    endTime: getDefaultDateFilterValues().lastDay,
  });
  const [symbolFilter, setSymbolFilter] = useState('');



  // --------------------
  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });


  // --------------------

  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });
  const [showModalDetail, setShowModalDetail] = useState(null);
  const [showAddEditDetail, setShowAddEditDetail] = useState(null);


  // --------------------

  const getData = async (typeF) => {
    try {
      const filters = {
        nameFilter,
        networkNameFilter,
        symbolFilter,
        typeFilter : typeF ? typeF : typeFilter
      };

      let response;

      onChangePage(null , 0)
        response = await AdminApi.getDedicateWalletSettingsTable(page, rowsPerPage, filters);
        setTableData(response?.binanceTokenInfoResList);
        setTablePagination({ totalItems: response.binanceTokenInfoResList?.length });

    } catch (e) {
      setTableData([]);
    }
  };
  useEffect(() => {
    getData();
  }, [
    // page,
    // rowsPerPage,
    // typeFilter,
    // timeFilter,
    // symbolFilter,
    // nameFilter,
    // networkNameFilter,
  ]);


  useEffect(() => {
    if (!tableData) return;

    onChangePage(null, 0);

    let filtered = [...tableData];

    // if (selectedStatus !== 'all') {
    //   filtered = filtered.filter((item) => item.cryptiziStatus === (selectedStatus === 'active'));
    // }

    if (networkNameFilter !=='') {
      filtered = filtered.filter((item) => item.network === networkNameFilter);
    }

    // if (selectedNetwork || selectedStatus !== 'all') {
    //   const countActive = filtered.length - filtered.filter((n) => !n.cryptiziStatus).length;
    //   const countInactive = filtered.length - countActive;
    //   setIsAllActive(countActive > countInactive);
    // }
    //

    filtered = filtered.filter(
      (item) =>
        item.name.toLowerCase().indexOf(nameFilter.toLowerCase().trim()) !== -1 ||
        item.network.toLowerCase().indexOf(nameFilter.toLowerCase().trim()) !== -1 ||
        item.symbol.toLowerCase().indexOf(nameFilter.toLowerCase().trim()) !== -1
    );

    setDataFiltered(filtered);
  }, [tableData, nameFilter, networkNameFilter]);


  const viewForTable = dataFiltered?.slice(page * 20 , (page * 20) + 20)

  console.log(page)
  return (
    <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 }, mx: { xs: 2, lg: 0 } }}>
      <PageTitle
        title={'Dedicated Wallet Settings'}
        subTitle={
          ''
        }
      />

      {/*<Button*/}
      {/*  variant='contained'*/}
      {/*  onClick={() => setShowAddEditDetail({})}*/}
      {/*  size='large'*/}
      {/*  sx={{*/}
      {/*    maxWidth: 150,*/}
      {/*    my: 2.5,*/}
      {/*  }}*/}
      {/*  className='no-print'*/}
      {/*>*/}
      {/*  {t('Add')}*/}
      {/*</Button>*/}

      <Box sx={{ mt: 5 }}>
        <DedicatedWalletTableToolbar
          nameFilter={nameFilter}
          setNameFilter={(e)=>{
            setNameFilter(e)
          }}
          typeFilter={typeFilter}
          setTypeFilter={(e)=>{
            getData(e)
            setTypeFilter(e)
          }}
          timeFilter={timeFilter}
          setTimeFilter={setTimeFilter}
          symbolFilter={symbolFilter}
          setSymbolFilter={setSymbolFilter}
          networkNameFilter={networkNameFilter}
          setNetworkNameFilter={setNetworkNameFilter}
          onFilter={getData}
          />
      </Box>


      <Scrollbar>
        <TableContainer sx={{ position: 'relative' }}>
          <Table size={'small'}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length || 0}
              onSort={onSort}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <TableBody>
              {tableData === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {
                    viewForTable?.map((row , idx)=>{
                      return  <DedicatedWalletSettingsTableRow
                        key={idx+1}
                        row={row}
                        handleEdit={setShowAddEditDetail}
                        onShowModalDetail={() => setShowModalDetail(row)}
                      />
                    })
                  }

                  <TableNoData isNotFound={!tableData?.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {tableData?.length > 0 && (
        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[20]}
            component='div'
            count={tablePagination.totalItems}
            rowsPerPage={20}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
          />
        </Box>
      )}
      <DediactedWalletTableRowViewModal
        open={!!showModalDetail}
        onClose={() => setShowModalDetail(null)}
        data={showModalDetail}

      />

      <AddDedicatedWallet
        open={!!showAddEditDetail}
        onClose={() => setShowAddEditDetail(null)}
        data={showAddEditDetail}
        getData={getData}
      />
    </Card>
  );
};

export default DedicatedWalletSettings;