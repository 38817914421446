import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

export default function useGatewayForm(data) {
  const validators = Yup.object().shape({
    perfectMoneyInventoryAmount: Yup.string().notRequired(),
    perfectMoneyInventoryStatus: Yup.boolean().notRequired(),
    tetherInventoryAmount: Yup.string().notRequired(),
    tetherInventoryStatus: Yup.boolean().notRequired(),
  });

  const defaultValues = {
    perfectMoneyInventoryAmount: '',
    perfectMoneyInventoryStatus: false,
    tetherInventoryAmount: '',
    tetherInventoryStatus: false,
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (data) {
      setValue('perfectMoneyInventoryAmount', data?.perfectMoneyInventoryAmount || '');
      setValue('perfectMoneyInventoryStatus', data?.perfectMoneyInventoryStatus || false);
      setValue('tetherInventoryAmount', data?.tetherInventoryAmount || '');
      setValue('tetherInventoryStatus', data?.tetherInventoryStatus || false);
    }
  }, [data]);

  return {
    handleSubmit,
    isSubmitting,
    methods,
  };
}
