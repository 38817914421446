import { useState, useCallback } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function useAddUserForm() {
  const { t } = useTranslation();

  const [userImg, setUserImg] = useState(null);
  const [cardsAmount, setCardsAmount] = useState([1]);

  const handleAddCard = () => {
    let array = [...cardsAmount];
    array.push(cardsAmount.length + 1);
    setCardsAmount(array);
  };

  const handleDeleteCard = (index) => {
    let copy = [...cardsAmount];
    if (copy.length === 1) {
      return;
    } else if (copy.filter((each) => each !== undefined).length === 1) {
      return;
    } else {
      delete copy[index];
      setCardsAmount(copy);
    }
  };

  const validators = Yup.object().shape({
    firstName: Yup.string().required(t(`Please input your first name!`)),
    lastName: Yup.string().required(t('Please input your last name!')),
    mobile: Yup.string().required(t('Please input your mobile number!')),
    emailAddress: Yup.string().required(t('Please input your email address!')),
    password:Yup.string().required(t('Please input a password!')),
    // fatherName: Yup.string().notRequired(),
    // nationalNumber: Yup.string().notRequired(),
    birthDay: Yup.string().required(t('Please select your birth date')),
    // loginDate: Yup.string().notRequired(),
    verification: Yup.string().required(t('Please select one!')),
    phoneNumber: Yup.string().required(t('Please input your phone number!')),
    userImg: Yup.string().required(t('Please input your photo!')),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    mobile: '',
    emailAddress: '',
    password:"",
    // fatherName: '',
    // nationalNumber: '',
    birthDay: '',
    // loginDate: '',
    verification: '',
    phoneNumber: '',
    userImg: '',
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setUserImg(file);

        setValue(
          'userImg',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return {
    handleSubmit,
    isSubmitting,
    methods,
    handleDrop,
    cardsAmount,
    handleAddCard,
    handleDeleteCard,
    userImg,
    reset,
  };
}
