import { alpha, Card, Grid, Stack, Typography } from '@mui/material';

import Image from '../../../components/Image';
import order from '../../../assets/order.svg';
import dollar from '../../../assets/dollar-circle.svg';
import introduces from '../../../assets/introduces-user.svg';
import chart from '../../../assets/chart.svg';
import { useTranslation } from 'react-i18next';

export default function ReferralMarketingStatistic({ data }) {
  const { t } = useTranslation();
  const statistic = [
    { value: data.introducedUsers, label: 'Introduced user', image: introduces, color: '#11CABE' },
    { value: data.totalCommission, label: 'Total commission orders', image: order, color: '#BD47FB' },
    { value: data.totalOrders, label: 'Total orders', image: dollar, color: '#F27F16' },
    { value: data.averageCommission, label: 'Average commission per order', image: chart, color: '#30E0A1' },
  ];

  // -------------------------
  return (
    <Grid container  className='no-print'>
      {statistic?.map((item, index) => (
        <Grid key={index} item xs={12} sm={6} xl={3} px={0.5} py={1}>
          <Card sx={{ display: 'flex' }}>
            <Stack
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ backgroundColor: alpha(item.color, 0.1), borderRadius: '100%', width: 48, height: 48, mr: 2 }}
            >
              <Image src={item.image} />
            </Stack>
            <Stack spacing={'0.25'}>
              <Typography variant='h6' fontWeight={500}>
                {item.value}
              </Typography>
              <Typography variant='body3' color={'grey.100'}>
                {t(item.label)}
              </Typography>
            </Stack>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
