import { useEffect, useState } from 'react';
import moment from 'jalali-moment';

import { getDefaultDateFilterValues } from '../../../../../../../utils/filter';
import Api from '../../../../../../../http/adminHttp/serviceApiAdmin';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { exportExcel } from '../../../../../../../utils/excel';
import { prettyUppercases } from '../../../../../../../utils';
import { exportPDF } from '../../../../../../../utils/pdf';

export default function useUserOrdersInit(page, rowsPerPage, userId) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const [typeFilter, setTypeFilter] = useState('otc');
  const [dateFromFilter, setDateFromFilter] = useState(getDefaultDateFilterValues().firstDay);
  const [dateToFilter, setDateToFilter] = useState(getDefaultDateFilterValues().lastDay);
  const [tableData, setTableData] = useState([]);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });
  const [userData, setUserData] = useState({ fullName: '' });
  const [loading ,setLoading] = useState(true)
  const fetchTableData = async () => {
    setLoading(true)
    try {
      const filters = { userId, side: 'ALL', state: 'ALL', orderType: 'ALL', tradeState: 'ALL', status: 'ALL' ,futureSide:'ALL', futureStatus:"ALL" , paysafeFiatType:"ALL" , perfectStatus:"ALL" ,perfectType:"ALL"};

      if (dateFromFilter && dateToFilter) {
        // filters.startTime = moment(dateFromFilter).startOf('days').valueOf();
        filters.startTime = dateFromFilter?.valueOf?.();

        filters.endTime =dateToFilter?.valueOf?.();
      }

      const response = await Api.getUserOrders(page, rowsPerPage, filters);

      if (response.status === 'SUCCESS') {
        setUserData({ fullName: response.firstName + ' ' + response.lastName });
        if (typeFilter === 'otc') {
          setTableData(response.otcHistoryInfos);
          setTablePagination({ totalItems: response.otcHistoryInfosTotalPages * rowsPerPage });
        } else if (typeFilter === 'p2p') {
          setTableData(response.p2pOrders);
          setTablePagination({ totalItems: response.p2pOrdersTotalPages * rowsPerPage });
        } else if (typeFilter === 'trade') {
          setTableData(response.tradeHistoryInfos);
          setTablePagination({ totalItems: response.tradeHistoryInfosTotalPages * rowsPerPage });
        } else if (typeFilter === 'futures') {
          setTableData(response.futuresHistoryInfos);
          setTablePagination({ totalItems: response.futuresHistoryInfosTotalPages * rowsPerPage });
        } else if (typeFilter === 'paysafe') {
          setTableData(response.paysafeHistoryInfos);
          setTablePagination({ totalItems: response.paysafeHistoryInfosTotalPages * rowsPerPage });
        } else if (typeFilter === 'perfectmoney') {
          setTableData(response.perfectMoneyHistoryInfos);
          setTablePagination({ totalItems: response.perfectMoneyHistoryInfosTotalPages * rowsPerPage });
        }
        else if(typeFilter?.toLowerCase() === 'all'){
          console.log('ressssppppss')
          // setTableData([...response.otcHistoryInfos]);
        }  else {
          setTableData([]);
        }
      }
    } catch (e) {
      setTableData([]);
    }finally {
      setLoading(false)
    }
  };

  const handleFilter = () => {
    fetchTableData();
  };

  const handleResetFilters = () => {
    setTypeFilter('otc');
    setDateFromFilter(getDefaultDateFilterValues().firstDay);
    setDateToFilter(getDefaultDateFilterValues().lastDay);
  };

  // --------------------------
  useEffect(() => {
    if (userId) fetchTableData();
  }, [page, rowsPerPage, userId , typeFilter]);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);

  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    const heads =
      typeFilter === 'otc'
        ? [t('Num'), t('Fiat'), t('Crypto'), t('Deposit type'), t('Amount'), t('Created Date'), t('Side')]
        : typeFilter === 'p2p'
        ? [t('Num'), t('Minimum limit'), t('Crypto'), t('State'), t('Amount'), t('Created Date'), t('Side'), t('Price')]
        : typeFilter === 'trade'
        ? [
            t('Num'),
            t('Commission'),
            t('Crypto'),
            t('State'),
            t('Amount'),
            t('Created Date'),
            t('Side'),
            t('Price'),
            t('Stop price'),
          ]
            : typeFilter === 'futures'
              ? [
                t('Num'),
                t('Commission'),
                t('Crypto'),
                t('State'),
                t('Amount'),
                t('Created Date'),
                t('Side'),
                t('Price'),
                t('Stop price'),
              ]
              : typeFilter === 'paysafe'
                ? [
                  t('Num'),
                  t('Commission'),
                  t('Crypto'),
                  t('State'),
                  t('Amount'),
                  t('Created Date'),
                  t('Side'),
                  t('Price'),
                  t('Stop price'),
                ]
                : typeFilter === 'perfectmoney'
                  ? [
                    t('Num'),
                    t('Commission'),
                    t('Crypto'),
                    t('State'),
                    t('Amount'),
                    t('Created Date'),
                    t('Side'),
                    t('Price'),
                    t('Stop price'),
                  ]
        : [];


    const filters = { userId };

    if (dateFromFilter && dateToFilter) {
      filters.startTime = moment(dateFromFilter).startOf('days').valueOf();
      filters.endTime = moment(dateToFilter).endOf('days').valueOf();
    }

    try {
      const response = await Api.getUserOrders(0, 10000000, filters);

      let list = [];

      // ---------------
      if (typeFilter === 'otc') {
        const result = response.otcHistoryInfos;
        list = result.map((item) => [
          item.fiat,
          item.symbol,
          item.depositType,
          item.amount?.toLocaleString('en-US'),
          moment(item.createdDate).locale(language).format('D MMM YYYY - HH:mm'),
          item.side,
        ]);
      }
      // ---------------
      else if (typeFilter === 'p2p') {
        const result = response.p2pOrders;
        list = result.map((item) => [
          item.minLimit?.toLocaleString('en-US'),
          item.symbol,
          `${t(prettyUppercases(item.state))}`,
          item.amount?.toLocaleString('en-US'),
          moment(item.createdDate).locale(language).format('D MMM YYYY - HH:mm'),
          item.side,
          item.price?.toLocaleString('en-US'),
        ]);
      }
      // ---------------
      else if (typeFilter === 'trade') {
        const result = response.tradeHistoryInfos;
        list = result.map((item) => [
          item.commission?.toLocaleString('en-US'),
          item.symbol,
          `${t(prettyUppercases(item.state))}`,
          item.amount?.toLocaleString('en-US'),
          moment(item.createdDate).locale(language).format('D MMM YYYY - HH:mm'),
          item.side,
          item.price?.toLocaleString('en-US'),
          item.stopPrice?.toLocaleString('en-US'),
        ]);
      }

      // generate excel and download
      const config = {
        language,
        fileName: t(`User - Orders  ${typeFilter}`),
        heads,
        columnsWidth: [5, 16, 13, 24, 26, 23, 32, 11, 11],
        list,
      };

      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  return {
    typeFilter,
    setTypeFilter,
    dateFromFilter,
    setDateFromFilter,
    dateToFilter,
    setDateToFilter,
    tableData,
    tablePagination,
    handleFilter,
    handleResetFilters,
    userData,
    isExportLoading,
    onExport,
    loading ,setLoading
  };
}
