import { useEffect, useRef, useState, memo } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FuturesPositionTable from './futuresPositionTable/futuresPositionTable';
import Api from '../../../http/serviceApi';
import useTable from '../../../hooks/useTable';
import FuturesOpenTradeTable from './futuresOpenTradeTable/futuresOpenTradeTable';
import FuturesTradeHistoryTable from './futuresTradeHistoryTable/futuresTradeHistoryTable';

const FuturesTables = ({ reloadTime }) => {
  const { t } = useTranslation();

  // ----------------
  const [tab, setTab] = useState('positions');
  const [positionsTableData, setPositionsTableData] = useState(null);
  const [positionsTablePagination, setPositionsTablePagination] = useState({ totalItems: 0 });
  const [openTradeTableData, setOpenTradeTableData] = useState(null);
  const [openTradeTablePagination, setOpenTradeTablePagination] = useState({ totalItems: 0 });
  const [tradeHistoryTableData, setTradeHistoryTableData] = useState(null);
  const [tradeHistoryTablePagination, setTradeHistoryTablePagination] = useState({ totalItems: 0 });

  // ----------------
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
    defaultRowsPerPage: 30,
  });

  // ----------------
  const reloadRef = useRef();

  // ----------------
  const getPositionData = async () => {
    clearTimeout(reloadRef.current);

    try {
      const response = await Api.getFuturesPositions();
      if (response.status === 'SUCCESS') {
        setPositionsTableData(response.positionInfos);
        setPositionsTablePagination({ totalItems: response.positionInfos.length });
      } else {
        setPositionsTableData([]);
      }
    } catch (e) {
      setPositionsTableData([]);
    }

    onReload();
  };

  // ----------------
  const getTradeData = async () => {
    try {
      const response = await Api.getFuturesOrders(page, rowsPerPage);

      if (response.status === 'SUCCESS') {
        setOpenTradeTableData(response.openTradeInfos);
        setOpenTradeTablePagination({ totalItems: response.openTradeInfosTotalPages * rowsPerPage });

        setTradeHistoryTableData(response.tradeHistoryInfos);
        setTradeHistoryTablePagination({ totalItems: response.tradeHistoryInfosTotalPages * rowsPerPage });
      } else {
        setPositionsTableData([]);
      }
    } catch (e) {
      setPositionsTableData([]);
    }
  };

  // ----------------
  useEffect(() => {
    getPositionData();
  }, [reloadTime]);

  // ----------------
  useEffect(() => {
    getTradeData();
  }, [page, rowsPerPage, reloadTime]);

  // -----------------
  const onReload = () => {
    clearTimeout(reloadRef.current);

    reloadRef.current = setTimeout(() => {
      getPositionData();
      getTradeData();
    }, 5000);
  };

  // ----------------
  useEffect(() => {
    return () => {
      clearTimeout(reloadRef.current);
    };
  }, []);

  // ----------------
  return (
    <>
      <Tabs
        value={tab}
        onChange={(e, newTab) => setTab(newTab)}
        variant='scrollable'
        scrollButtons='auto'
        sx={{
          '& button': {
            typography: 'body1',
            width: 140,
            color: 'grey.300',
            borderRadius: 1.5,
            minHeight: 40,
            '&.Mui-selected': { color: 'warningButton', bgcolor: 'transparent' },
          },
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
      >
        <Tab label={`${t('Positions')} (${positionsTablePagination?.totalItems || 0})`} value={'positions'} />
        <Tab label={`${t('Open Trades')} (${openTradeTableData?.length || 0})`} value={'open_trade'} />
        <Tab label={`${t('Trade History')}`} value={'trade_history'} />
      </Tabs>

      <TabPanel value={tab} index={'positions'}>
        <FuturesPositionTable
          positionsTableData={positionsTableData}
          positionsTablePagination={positionsTablePagination}
          onReload={() => {
            getPositionData();
            getTradeData();
          }}
        />
      </TabPanel>

      <TabPanel value={tab} index={'open_trade'}>
        <FuturesOpenTradeTable
          openTradeTableData={openTradeTableData}
          openTradeTablePagination={openTradeTablePagination}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onReload={getTradeData}
        />
      </TabPanel>

      <TabPanel value={tab} index={'trade_history'}>
        <FuturesTradeHistoryTable
          tradeHistoryTableData={tradeHistoryTableData}
          tradeHistoryTablePagination={tradeHistoryTablePagination}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </TabPanel>
    </>
  );
};

export default memo(
  FuturesTables,
  (prev, next) => prev.selectedSymbol === next.selectedSymbol && prev.reloadTime === next.reloadTime
);

// ----------------------------------
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && <Box sx={{ mt: 0.5 }}>{children}</Box>}
    </div>
  );
}
