import { Link, Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Container, Stack, Typography } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
//
import MenuDesktop from './menuDesktop';
import MenuMobile from './menuMobile';
import { landingNavConfig } from '../../../navConfig';
import { ToolbarShadowStyle, ToolbarStyle, TopbarDivider } from '../style';
import Image from '../../../components/Image';
import { navigationUrl } from '../../../navigationUrl';
import LanguagePopover from '../../../components/mainNavBar/languagePopover';
import AccountPopover from '../../../components/mainNavBar/accountPopover';
import { useSelector } from 'react-redux';

import importIcon from '../../../assets/landing/import.svg';
import importLightIcon from '../../../assets/landing/import_light.svg';
import { useTranslation } from 'react-i18next';
import ThemeSwitch from '../../../components/mainNavBar/themeSwitch';

// ----------------------------------------------------------------------

export default function TopMenu() {
  const { t } = useTranslation();

  const isOffset = useOffSetTop(88);
  const user = useSelector((store) => store.userInfo);
  const isLoggedIn = !!user?.userId;

  const theme = useTheme();

  const isDesktop = useResponsive('up', 'lg');

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: 88 - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {!isDesktop && <MenuMobile isOffset={isOffset} isHome={true} navConfig={landingNavConfig} />}

          <Link to={'/'}>
            <Stack
              direction={theme.direction === 'rtl' ? 'row-reverse' : 'row'}
              alignItems={'center'}
              spacing={1}
              sx={{ mr: 6 }}
            >
              {isDesktop && <Image src={'/assets/logo.png'} sx={{ width: 32 }} disabledEffect />}
              <Typography variant='subtitle1'>Cryptizi</Typography>
            </Stack>
          </Link>

          {isDesktop && <MenuDesktop isOffset={isOffset} isHome={true} navConfig={landingNavConfig} />}

          <Box sx={{ flexGrow: 1 }} />

          <Stack direction='row' spacing={2} alignItems={'center'}>
            {!isLoggedIn && (
              <>
                <Button
                  sx={{ borderRadius: 0, width: 80, height: 47, px: 0 }}
                  size='large'
                  variant='outlined'
                  color='success'
                  component={RouterLink}
                  to={navigationUrl.register}
                >
                  <Typography variant='body3_secondary' color='positive.main'>
                    {t('Sign up')}
                  </Typography>
                </Button>

                <Button
                  sx={{ borderRadius: 0, width: 80, height: 47, px: 0 }}
                  size='large'
                  variant='contained'
                  color='success'
                  component={RouterLink}
                  to={navigationUrl.panel}
                >
                  <Typography variant='body3_secondary'>{t('Log in')}</Typography>
                </Button>
              </>
            )}

            <ThemeSwitch />

            {isDesktop ? (
              <Box
                sx={{ width: 40, height: 40, backgroundColor: 'grey.700', borderRadius: 0.5, '& svg': { width: 20 } }}
              >
                <LanguagePopover />
              </Box>
            ) : (
              null
              // <LanguagePopover />
            )}

            {isDesktop && (
              <Stack
                sx={{ width: 57, height: 40, backgroundColor: 'grey.700', borderRadius: 0.5 }}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Typography variant='body2' color='grey.100'>
                  USD
                </Typography>
              </Stack>
            )}

            {isDesktop && (
              <Stack
                sx={{ width: 40, height: 40, backgroundColor: 'grey.700', borderRadius: 0.5 }}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Image src={theme.palette.mode === 'dark' ? importIcon : importLightIcon} alt='' />
              </Stack>
            )}

            {isLoggedIn && <AccountPopover showFull={false} />}
          </Stack>
        </Container>
      </ToolbarStyle>

      <TopbarDivider />

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
