import { Box, Card, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'jalali-moment';

import { PageTitle } from '../../../../components/PageTitle';
import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../components/table';
import useTable, { getComparator } from '../../../../hooks/useTable';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import WalletFiatTransactionTableRow from './walletFiatTransactionTableRow';
import WalletFiatTransactionToolbar from './walletFiatTransactionToolbar';
import WalletFiatDetailModal from './walletFiatDetailModal';
import { exportExcel } from '../../../../utils/excel';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import { applySortFilter, prettyUppercases } from '../../../../utils';
import { useRef } from 'react';
import { exportPDF } from '../../../../utils/pdf';

const TABLE_HEAD = [
  { id: 'num', label: 'Num', align: 'center' },
  { id: 'user', label: 'User', align: 'left' },
  { id: 'fiatType', label: 'Fiat', align: 'left' },
  { id: 'amount', label: 'Amount', align: 'center' },
  { id: 'createDate', label: 'Date', align: 'left' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'detail', label: 'Detail', align: 'center' },
];

export default function WalletFiatTransaction() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((store) => store.language);

  // --------------------
  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Fiat Wallet Transaction' },
    });
  }, []);

  // --------------------
  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  // --------------------
  const resetFilterRef = useRef();
  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });
  const [fiatStatusFilter, setFiatStatusFilter] = useState('SUCCESS'); // CANCELED, FAILURE, NEW, SUCCESS
  const [dateFromFilter, setDateFromFilter] = useState(moment().subtract(3, 'month').toDate());
  const [dateToFilter, setDateToFilter] = useState(moment().toDate());
  const [showModalDetail, setShowModalDetail] = useState(null);
  const [status, setStatus] = useState('SUCCESS');

  // --------------------
  const fetchData = async (resetFilter = false) => {
    const filters = {};
    filters.type = 'Fiat';
    filters.startTime = moment(dateFromFilter).startOf('days').valueOf();
    filters.endTime = moment(dateToFilter).endOf('days').valueOf();
    filters.allTransaction = 'false';
    filters.fiatStatus = fiatStatusFilter;
    filters.depositStatus = 'SUCCESS';
    filters.withdrawStatus = 'Completed';

    if (!resetFilterRef.current) {
      resetFilterRef.current = { ...filters };
    }

    const filtersBase = resetFilter ? resetFilterRef.current : filters;
    setStatus(filtersBase.fiatStatus);

    const response = await Api.getReportTransaction(page, rowsPerPage, filtersBase);
    setTableData(response.fiatDepositInfos);
    setTablePagination({ totalItems: response.fiatDepositInfosTotalPages * rowsPerPage });
  };

  // --------------------
  useEffect(() => {
    fetchData();
  }, []);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);

  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    const filters = {};
    filters.type = 'Fiat';
    filters.startTime = moment(dateFromFilter).startOf('days').valueOf();
    filters.endTime = moment(dateToFilter).endOf('days').valueOf();
    filters.allTransaction = 'false';
    filters.fiatStatus = fiatStatusFilter;
    filters.depositStatus = 'SUCCESS';
    filters.withdrawStatus = 'Completed';

    try {
      const response = await Api.getReportTransaction(0, 100000000, filters);

      // generate excel and download
      const config = {
        language,
        fileName: t('Fiat Wallet Transaction'),
        heads: [
          '#',
          t('Name'),
          t('Mobile'),
          t('Email'),
          t('Fiat'),
          t('Amount'),
          t('Date'),
          t('Status'),
          t('Gateway'),
          t('Shetab Gateway'),
          t('Deposit ID'),
          t('TxID'),
        ],
        columnsWidth: [5, 25, 13, 30, 10, 13, 12, 20, 12, 45, 100],
        list: response.fiatDepositInfos.map((item) => [
          `${item.firstName} ${item.lastName}`,
          item.mobile,
          item.emailAddress,
          item.fiatType,
          item.amount,
          moment(item.createDate).locale(language).format('D MMM YYYY - HH:mm'),
          t(prettyUppercases(fiatStatusFilter)),
          item.gateway || '-',
          item.shetabGateway || '-',
          item.depositId,
          item.txId,
        ]),
      };

      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  // --------------------
  return (
    <>
      <Card sx={{ mx: { xs: 2, lg: 0 }, p: { md: 4, lg: 5 } }}>
        <PageTitle title='Fiat Wallet Transaction' subTitle='You can see the wallet transactions of users.' />

        {/* Toolbar */}

        <WalletFiatTransactionToolbar
          dateFromFilter={dateFromFilter}
          setDateFromFilter={setDateFromFilter}
          dateToFilter={dateToFilter}
          setDateToFilter={setDateToFilter}
          fiatStatusFilter={fiatStatusFilter}
          setFiatStatusFilter={setFiatStatusFilter}
          onFilter={fetchData}
          onExport={onExport}
          isExportLoading={isExportLoading}
        />

        {/* Table */}

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size='small'>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length || 0}
                onSort={onSort}
              />
              <TableBody>
                {tableData === null ? (
                  <TableLoading isLoading />
                ) : (
                  <>
                    {applySortFilter({
                      tableData,
                      comparator: getComparator(order, orderBy),
                    }).map((row) => (
                      <WalletFiatTransactionTableRow
                        key={row.id}
                        row={row}
                        onShowModalDetail={() => setShowModalDetail(row)}
                        status={status}
                      />
                    ))}

                    <TableNoData isNotFound={!tableData?.length} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: 'relative' }}>
          {tableData?.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              component='div'
              count={tablePagination.totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              labelRowsPerPage={t('Rows per page')}
              labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
            />
          )}
        </Box>
      </Card>

      <WalletFiatDetailModal
        open={!!showModalDetail}
        onClose={() => setShowModalDetail(null)}
        data={showModalDetail}
        status={status}
      />
    </>
  );
}
