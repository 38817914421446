import moment from 'jalali-moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Api from '../../../../http/serviceApi';

export default function useOverallGrowth(periodSelected) {
  const [chartLabels, setChartLabels] = useState([]);
  const language = useSelector((store) => store.language);

  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    userDashboardInfo();
  }, [periodSelected, language]);

  const userDashboardInfo = async () => {
    setChartData(null);

    try {
      const response = await Api.getUserDashboardCandleInfo(periodSelected.value);
      let list = response.topWalletInfos;

      if (list?.length > 0) {
        list = list.sort((a, b) => a.date - b.date);

        const foundExistData = list.find((item) => item.firstWalletPercent !== 0);
        if (foundExistData) {
          const chart = [
            { name: foundExistData.firstWalletName, symbol: foundExistData.firstWalletSymbol, data: [] },
            { name: foundExistData.secondWalletName, symbol: foundExistData.secondWalletSymbol, data: [] },
            { name: foundExistData.thirdWalletName, symbol: foundExistData.thirdWalletSymbol, data: [] },
            { name: 'Other', symbol: '', data: [] },
          ];

          const labels = [];
          list.forEach((item) => {
            chart[0].data.push(item.firstWalletPercent);
            chart[1].data.push(item.secondWalletPercent);
            chart[2].data.push(item.thirdWalletPercent);
            chart[3].data.push(item.otherWalletPercent);

            labels.push(moment(item.date).locale(language).format('MMM D'));
          });

          setChartData(chart);
          setChartLabels(labels);
          return;
        }
      }

      setChartData([]);
      setChartLabels([]);
    } catch (e) {}
  };

  // const makeLables = () => {
  //   const startDate = moment();
  //   const endDate = moment().subtract(periodSelected.days, 'days');
  //   const startingMoment = startDate;

  //   const days = [];
  //   while (startingMoment > endDate) {
  //     days.push(startingMoment.locale(language).format('DD'));
  //     startingMoment.subtract(1, 'days');
  //   }
  //   (days.reverse());
  // };

  return { chartLabels, chartData };
}
