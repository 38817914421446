import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom } from '../../../../components/table';

// ===================================================================

const TABLE_HEAD = [
  { id: 'contractAddress', label: 'Contract address', align: 'left' },
  { id: 'decimal', label: 'decimal', align: 'left' },
  { id: 'minWithdrawAmount', label: 'Min withdraw amount', align: 'left' },
  { id: 'withdrawFee', label: 'withdrawFee', align: 'left' },
  { id: 'networkConfirmation', label: 'Network confirmation', align: 'left' },
  { id: 'network', label: 'Network', align: 'left' },
  { id: 'isActive', label: 'Status', align: 'left' },
  { id: 'depositActive', label: 'Deposit status', align: 'left' },
  { id: 'withdrawActive', label: 'Withdraw status', align: 'left' },
];

// ===================================================================

const ViewTokenTable = ({ tableData }) => {
  const { t } = useTranslation();

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative', width: '100%' }}>
          <Table size='small'>
            <TableHeadCustom headLabel={TABLE_HEAD} rowCount={tableData?.length} />

            <TableBody>
              {tableData.map((row, index) => (
                <TableRow hover sx={{ '& td': { py: 1.5, typography: 'body2' } }}>
                  {TABLE_HEAD.map((head, headIndex) =>
                    head.id === 'isActive' ? (
                      <TableCell align='center'>
                        <Box
                          sx={{
                            maxWidth: 130,
                            borderRadius: '22px',
                            textAlign: 'center',
                            py: 0.8,
                            px: 2,
                            ...(row.isActive
                              ? { bgcolor: 'rgba(48, 224, 161, 0.2)' }
                              : { bgcolor: 'rgba(250, 34, 86, 0.2)' }),
                          }}
                        >
                          <Typography
                            variant='body4'
                            sx={{
                              lineHeight: 1,
                              py: 0.4,
                              ...(row.isActive ? { color: '#30E0A1' } : { color: 'error.darker' }),
                            }}
                          >
                            {t(row.isActive ? 'Active' : 'Disabled')}
                          </Typography>
                        </Box>
                      </TableCell>
                    ) : head.id === 'depositActive' ? (
                      <TableCell align='center'>
                        <Box
                          sx={{
                            maxWidth: 130,
                            borderRadius: '22px',
                            textAlign: 'center',
                            py: 0.8,
                            px: 2,
                            ...(row.depositActive
                              ? { bgcolor: 'rgba(48, 224, 161, 0.2)' }
                              : { bgcolor: 'rgba(250, 34, 86, 0.2)' }),
                          }}
                        >
                          <Typography
                            variant='body4'
                            sx={{
                              lineHeight: 1,
                              py: 0.4,
                              ...(row.depositActive ? { color: '#30E0A1' } : { color: 'error.darker' }),
                            }}
                          >
                            {t(row.depositActive ? 'Active' : 'Disabled')}
                          </Typography>
                        </Box>
                      </TableCell>
                    ) : head.id === 'withdrawActive' ? (
                      <TableCell align='center'>
                        <Box
                          sx={{
                            maxWidth: 130,
                            borderRadius: '22px',
                            textAlign: 'center',
                            py: 0.8,
                            px: 2,
                            ...(row.withdrawActive
                              ? { bgcolor: 'rgba(48, 224, 161, 0.2)' }
                              : { bgcolor: 'rgba(250, 34, 86, 0.2)' }),
                          }}
                        >
                          <Typography
                            variant='body4'
                            sx={{
                              lineHeight: 1,
                              py: 0.4,
                              ...(row.withdrawActive ? { color: '#30E0A1' } : { color: 'error.darker' }),
                            }}
                          >
                            {t(row.withdrawActive ? 'Active' : 'Disabled')}
                          </Typography>
                        </Box>
                      </TableCell>
                    ) : (
                      <TableCell key={`${index}_${headIndex}`}>{row[head.id]}</TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </>
  );
};

export default ViewTokenTable;
