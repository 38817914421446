import { Button, MenuItem, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ComplexToolbar from '../../../components/ComplexToolbar';
import DatePicker from '../../../components/DatePicker';
import { prettyUppercases } from '../../../utils';
import { NumberField } from '../../../components/NumberField';

const STATESES = [
  { value: 'ALL', label: 'ALL' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'DISAPPROVAL', label: 'Disapproval' },
  { value: 'NO_CONDITION', label: 'No condition' },
  { value: 'WAITING', label: 'Waiting' },
];
const authenticationLevel = [
  { value: 'ALL', label: 'ALL' },
  { value: 'ADVANCED', label: 'Advanced' },
  { value: 'ADVANCED_BY_ADMIN', label: 'Advanced by admin' },
  { value: 'ADVANCED_REJECTED', label: 'Advanced rejected' },
  { value: 'ADVANCED_REQUESTED', label: 'Advanced requested' },
  { value: 'BASIC', label: 'Basic ' },
  { value: 'BASIC_BY_ADMIN', label: 'Basic by admin' },
  { value: 'BASIC_REQUESTED', label: 'Basic Requested' },
  { value: 'INTERMEDIATE', label: 'Intermediate ' },
  { value: 'INTERMEDIATE_BY_ADMIN', label: 'Intermediate by admin' },
  { value: 'INTERMEDIATE_REJECTED', label: 'Intermediate Rejected' },
  { value: 'INTERMEDIATE_REQUESTED', label: 'Intermediate Requested' },
  { value: 'REGISTERED ', label: 'Registered' },
];

export default function ReferralMarketingToolbar({
  onFilter,
  amountFilter,
  setAmountFilter,
  authenticationLevelFilter,
  setAuthenticationLevelFilter,
  conditionFilter,
  setConditionFilter,
  dateFromFilter,
  setDateFromFilter,
  dateToFilter,
  setDateToFilter,
  statusFilter,
  setStatusFilter,
  nameFilter,
  setNameFilter,
  isExportLoading,
  onExport,
}) {
  const { t } = useTranslation();

  const onReset = async () => {
    setDateFromFilter(null);
    setDateToFilter(null);
    setAmountFilter(null);
    setAuthenticationLevelFilter('ALL');
    setConditionFilter('ALL');
    setStatusFilter('ALL');
    setNameFilter('');
    onFilter(false);
  };

  return (
    <ComplexToolbar onExport={onExport} onPrint={() => console.log('print')} isExportLoading={isExportLoading}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 2 }} alignItems={'center'}>
        <Stack direction={'row'}>
          <DatePicker
            label={t('From')}
            value={dateFromFilter}
            setValue={setDateFromFilter}
            variant='standard'
            maxDate={dateToFilter}
            fullWidth
            sx={{ maxWidth: 150 }}
            InputProps={null}
          />
          <DatePicker
            label={t('To')}
            value={dateToFilter}
            setValue={setDateToFilter}
            variant='standard'
            minDate={dateFromFilter}
            fullWidth
            sx={{ maxWidth: 150 }}
          />
        </Stack>

        <TextField
          select
          SelectProps={{
            native: false,
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' } },
            },
          }}
          label={t('Status')}
          variant='standard'
          fullWidth
          sx={{ maxWidth: 150 }}
          onChange={(e) => setStatusFilter(e.target.value)}
          value={statusFilter}
        >
          {STATESES.map((state) => (
            <MenuItem key={state.value} value={state.value} sx={{ typography: 'body2' }}>
              {t(prettyUppercases(state.label))}
            </MenuItem>
          ))}
        </TextField>

        {/*<TextField*/}
        {/*  select*/}
        {/*  SelectProps={{*/}
        {/*    native: false,*/}
        {/*    MenuProps: {*/}
        {/*      sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' } },*/}
        {/*    },*/}
        {/*  }}*/}
        {/*  label={t('Level')}*/}
        {/*  variant='standard'*/}
        {/*  fullWidth*/}
        {/*  sx={{ maxWidth: 150 }}*/}
        {/*  onChange={(e) => setAuthenticationLevelFilter(e.target.value)}*/}
        {/*  value={authenticationLevelFilter}*/}
        {/*  >*/}
        {/*  {authenticationLevel.map((state) => (*/}
        {/*    <MenuItem key={state.value} value={state.value} sx={{ typography: 'body2' }}>*/}
        {/*      {t(prettyUppercases(state.label))}*/}
        {/*    </MenuItem>*/}
        {/*  ))}*/}
        {/*</TextField>*/}

        <TextField
          label={t('Name')}
          variant='standard'
          fullWidth
          sx={{ maxWidth: 200 }}
          onChange={(e) => setNameFilter(e.target.value)}
          value={nameFilter}
        />

        <NumberField
          label={t('Amount')}
          variant='standard'
          value={amountFilter}
          onChange={(event) => setAmountFilter(event.target.value)}
          fullWidth
          sx={{ maxWidth: 200 }}
        />
      </Stack>

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ my: 4 }}>
        <Button
          onClick={onFilter}
          fullWidth
          variant='contained'
          sx={{ maxWidth: 210, typography: 'body3', fontWeight: 500 }}
        >
          {t('Filter')}
        </Button>

        <Button
          onClick={onReset}
          fullWidth
          variant='outlined'
          color='inherit'
          sx={{ maxWidth: 80, typography: 'body3', fontWeight: 500, borderColor: 'grey.400' }}
        >
          {t('Reset')}
        </Button>
      </Stack>
    </ComplexToolbar>
  );
}
