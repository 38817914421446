import { useTranslation } from 'react-i18next';
import { Button, Stack, TextField } from '@mui/material';

import ComplexToolbar from '../../../../components/ComplexToolbar';
import DatePicker from '../../../../components/DatePicker';
import { NumberField } from '../../../../components/NumberField';

const WaitingUserToolbar = ({
  onFilter,
  onExport,
  userIdFilter,
  setUserIdFilter,
  nameFilter,
  setNameFilter,
  dateFromFilter,
  setDateFromFilter,
  dateToFilter,
  setDateToFilter,
  isExportLoading,
}) => {
  const { t } = useTranslation();

  const onReset = async () => {
    setNameFilter('');
    setUserIdFilter(null);
    setDateFromFilter(null);
    setDateToFilter(null);
    onFilter(false);
  };

  return (
    <ComplexToolbar
      onSearch={null}
      onExport={onExport}
      onPrint={() => console.log('print')}
      isExportLoading={isExportLoading}
    >
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} sx={{ mt: 2 }} alignItems={'center'}>
          <NumberField
            label={t('User Id')}
            fullWidth
            variant='standard'
            sx={{ maxWidth: 300 }}
            value={userIdFilter}
            onChange={(e) => setUserIdFilter(e.target.value)}
          />

          <TextField
            label={t('Name')}
            variant='standard'
            fullWidth
            sx={{ maxWidth: 300 }}
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />

          <Stack direction={'row'}>
            <DatePicker
              label={t('From')}
              value={dateFromFilter}
              setValue={setDateFromFilter}
              variant='standard'
              maxDate={dateToFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
              InputProps={null}
            />
            <DatePicker
              label={t('To')}
              value={dateToFilter}
              setValue={setDateToFilter}
              variant='standard'
              minDate={dateFromFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack direction={'row'} spacing={2} sx={{ mt: 5, mb: 5.75 }}>
        <Button
          onClick={onFilter}
          fullWidth
          variant='contained'
          sx={{ maxWidth: 204, typography: 'body3', fontWeight: 500 }}
        >
          {t('Filter')}
        </Button>
        <Button
          onClick={onReset}
          fullWidth
          variant='outlined'
          color='inherit'
          sx={{ maxWidth: 80, typography: 'body3', fontWeight: 500, borderColor: 'grey.400' }}
        >
          {t('Reset')}
        </Button>
      </Stack>
    </ComplexToolbar>
  );
};

export default WaitingUserToolbar;
