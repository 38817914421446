import { Stack, Box, Card, Typography, Button, MenuItem, InputAdornment, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import { FormProvider, RHFCheckbox, RHFSelect, RHFSwitch, RHFTextField } from '../../../../components/hookForm';
import useAdminForm from './hooks/useAdminForm';
import useAdminSubmit from './hooks/useAdminSubmit';
import { RHFUploadAvatar } from '../../../../components/hookForm/RHFUpload';
import Api from '../../../../http/adminHttp/serviceApiAdmin';

import confirmWrapper from '../../../../utils/confirm';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { navigationUrl } from '../../../../navigationUrl';
import { useState } from 'react';
import Iconify from '../../../../components/Iconify';
import CustomSwitch from '../../../../components/CustomSwitch';

// ----------------------------------------------------------------------

export default function AdminForm({ data }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(data?.active);
  const [showPassword, setShowPassword] = useState(false);

  const isEdit = data !== null;

  const { handleSubmit, isSubmitting, methods, handleDrop, waitingAction ,  walletSettingsView, setWalletView } = useAdminForm(data, isEdit);

  const { onSubmit } = useAdminSubmit(isEdit, data?.userId || null);

  // ----------------------------------------------
  const onDelete = async () => {
    if (!(await confirmWrapper(t('Are you sure to remove this admin account?')))) {
      return false;
    }

    try {
      const response = await Api.deleteAdmin(data?.userId);

      if (response?.status === 'SUCCESS') {
        enqueueSnackbar(t('The admin account is deleted successfuly'), { variant: 'success' });
        navigate(navigationUrl.admin_list);
      }
    } catch (e) {}
    return true;
  };

  // ----------------------------------------------
  const onChangeStatus = async () => {
    try {
      const response = await Api.changeStatusAdmin(data?.userId, !isActive);

      if (response?.status === 'SUCCESS') {
        enqueueSnackbar(t(`The admin account is ${isActive ? 'blocked' : 'unblocked'} successfully`), {
          variant: 'success',
        });
        setIsActive(!isActive);
      }
    } catch (e) {}
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 2.5 }}>
        <Stack sx={{ mb: 2.5 }}>
          <Typography variant='h6' fontWeight={500}>
            {isEdit ? t('Edit admin') : t('Add new admin')}
          </Typography>
        </Stack>

        <Stack direction={{ xs: 'column', md: 'row' }} justifyContent={'space-between'}>
          <Stack spacing={2.5} sx={{ width: { xs: '100%', md: 500 } }}>
            <RHFTextField name='firstName' label={t('First Name')} variant='standard' fullWidth />

            <RHFTextField name='lastName' label={t('Last Name')} variant='standard' fullWidth />

            <RHFTextField name='mobileNumber' label={t('Mobile')} variant='standard' fullWidth />

            <RHFTextField name='emailAddress' label={t('Email')} variant='standard' fullWidth />

            <RHFTextField
              name='password'
              type={showPassword ? 'text' : 'password'}
              label={t('Password')}
              variant='standard'
              fullWidth
              autoComplete='new-password'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                      <Iconify
                        icon={showPassword ? 'ant-design:eye-outlined' : 'ant-design:eye-invisible-outlined'}
                        sx={{ width: 18, mr: 0.5 }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={

                  <Typography variant='body4' color='warning.main'>
                    {t(`If you don't decide to change the password, leave this field blank`)}
                  </Typography>

              }
            />

            {isEdit && (
              <RHFSelect
                name='twoStepLogin'
                SelectProps={{
                  native: false,
                  MenuProps: {
                    sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' } },
                  },
                }}
                label={t('Two-step login')}
                variant='standard'
                fullWidth
              >
                <MenuItem value={'Active'}>
                  <Typography typography='body3' color='grey.300'>
                    {t('Active')}
                  </Typography>
                </MenuItem>
                <MenuItem value={'Deactive'}>
                  <Typography typography='body3' color='grey.300'>
                    {t('Deactive')}
                  </Typography>
                </MenuItem>
              </RHFSelect>
            )}

            {/* {isEdit && <RHFNumberField name='amount' label='Amount' fullWidth variant='standard' thousandSeparator />} */}
          </Stack>

          {isEdit && (
            <Box sx={{ mr: { xs: 0, md: 10 }, mt: { xs: 5, md: 0 } }}>
              <RHFUploadAvatar
                name='avatar'
                accept={{
                  'image/jpeg': [],
                  'image/png': [],
                }}
                onDrop={handleDrop}
                showChangePhotoLabel
              />
            </Box>
          )}
        </Stack>

        <Typography component='p' variant='body2' sx={{ mt: 5, mb: 2.5 }}>
          {t('Access')}
        </Typography>

        <Stack spacing={2}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 0 }}>
            <SwicthLabel name='API_SETTING' label={t('API Setting')} sx={{ width: 250 }} />
            <SwicthLabel name='TICKETS' label={t('Tickets')} />
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 0 }}>
            <SwicthLabel name='USERS' label={t('Users')} sx={{ width: 250 }} />
            <SwicthLabel name='SETTING' label={t('Settings')} />
          </Stack>

          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 0 }}>
            <SwicthLabel name='NETWORK_SETTING' label={t('Network Setting')} sx={{ width: 250 }} />
            <SwicthLabel name='ACADEMY' label={t('Academy')} sx={{ width: 250 }} />
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 0 }}>
            <SwicthLabel name='ADMINS' label={t('Admins')} sx={{ width: 250 }} />
            <SwicthLabel name='REFERRALS' label={t('Referrals')} />
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 0 }}>
            <SwicthLabel name='REPORTING' label={t('Reporting')} sx={{ width: 250 }} />
            <SwicthLabel name='NOTIFICATION' label={t('Notifications')} />
          </Stack>

          <Stack spacing={2}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 0 }}>
              <SwicthLabel name='WAITING_ACTIONS' label={t('Waiting Actions')} sx={{ width: 250 }} />
              <SwicthLabel name='TOKEN_LISTING' label={t('Token Listing')} sx={{ width: 250 }} />
             </Stack>

            {waitingAction && (
              <Stack spacing={{ xs: 2, md: 1 }} sx={{ pl: 2 }}>
                <SwicthLabel name='USERS_WAITING_FOR_VERIFY' label={t('Users waiting for Verify')} />
                <SwicthLabel name='LIST_OF_ALL_BANK_CARD' label={t('List of all bank cards')} sx={{ width: 250 }} />
                <SwicthLabel name='FIAT_TRANSACTION_WAITING' label={t('Fiat Transaction Waiting')} />
              </Stack>
            )}


          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 0 }}>

            <Stack direction='row' alignItems='center' spacing={1}  sx={{ width: 250 }} >
              <CustomSwitch checked={walletSettingsView} onChange={()=>setWalletView(!walletSettingsView)} />
              <Typography variant='body3'>{t('Wallet Settings')}</Typography>
            </Stack>


          </Stack>
          {
            walletSettingsView && (
              <Stack spacing={{ xs: 2, md: 1 }} sx={{pl: 2 }}>
                <SwicthLabel name='DEDICATED_WALLET' label={t('Dedicated Wallet')} sx={{ width: 250 }} />
                <SwicthLabel name='GAS_CONFIG' label={t('GasPump Wallet Settings')} />
                <SwicthLabel name='MAIN_WALLET' label={t('Main Wallet Settings')} sx={{ width: 250 }} />
              </Stack>
            )
          }

        </Stack>

        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 5 }} sx={{ mt: 5 }}>
          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            loading={isSubmitting}
            sx={{ maxWidth: 300, typography: 'body3', fontWeight: 500 }}
          >
            {isEdit ? t('Save') : t('Add admin')}
          </LoadingButton>

          {isEdit && (
            <>
              <Button
                fullWidth
                size='large'
                type='button'
                variant='outlined'
                color='inherit'
                sx={{ maxWidth: 300, typography: 'body3', fontWeight: 500 }}
                onClick={() => onDelete(data?.userId)}
              >
                {t('Delete user')}
              </Button>

              <Button
                fullWidth
                size='large'
                variant='contained'
                type='button'
                color='error'
                sx={{
                  maxWidth: 300,
                  typography: 'body3',
                  fontWeight: 500,
                  backgroundColor: 'rgba(250, 34, 86, 0.2)',
                  color: '#FA2256',
                  '&:hover': { color: 'grey.0' },
                }}
                onClick={onChangeStatus}
              >
                {isActive ? t('Block') : t('unblocked')}
              </Button>
            </>
          )}
        </Stack>
      </Card>
    </FormProvider>
  );
}

const SwicthLabel = ({ name, label, ...others }) => {
  const { t } = useTranslation();

  return (
    <Stack direction='row' alignItems='center' spacing={1} {...others}>
      <RHFSwitch name={name} />
      <Typography variant='body3'>{t(label)}</Typography>
    </Stack>
  );
};
