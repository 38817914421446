import { Table, TableBody, TableContainer } from '@mui/material';
import { useState } from 'react';
import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../components/table';
import P2PModal from './p2pModal';
import P2PTableRow from './p2pTableRow';

const TABLE_HEAD = [
  { id: 'advertiser', label: 'Advertiser', align: 'left' },
  { id: 'createdDate', label: 'Date', align: 'left' },
  { id: 'price', label: 'Price', align: 'left' },
  { id: 'available', label: 'Available/Limits', align: 'left' },
  { id: 'payment', label: 'Payment Method', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

export default function P2PTable({ tableData, onReload }) {
  const [openModal, setOpenModal] = useState(null);

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ position: 'relative' }}>
          <Table size={'small'}>
            <TableHeadCustom headLabel={TABLE_HEAD} rowCount={tableData?.length || 0} />
            <TableBody>
              {tableData === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {tableData?.map((row, index) => (
                    <P2PTableRow
                      key={row.id}
                      row={row}
                      index={index}
                      onAction={() => setOpenModal(row)}
                      onReload={onReload}
                    />
                  ))}

                  <TableNoData isNotFound={!tableData?.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <P2PModal open={!!openModal} onClose={() => setOpenModal(false)} data={openModal} onReload={onReload} />
    </>
  );
}
