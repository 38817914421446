import { useEffect, useState } from 'react';
import { Box, Fade, IconButton, Stack } from '@mui/material';

import useUserAuth from '../../hooks/useUserAuth';
import Image from '../../components/Image';
import LoadingScreen from '../../components/LoadingScreen';
import { ContentStyle } from './style';

import lineImage from '../../assets/landing/lineShape.svg';
import Payout from './sections/payout';
import Counter from './sections/counter';
import Trust from './sections/trust';
import Portfolio from './sections/portfolio';
import GetStarted from './sections/getStarted';
import TopAssets from './sections/topAssets';
import TopMenu from './sections/topMenu';
import LandingBanner from './sections/banner';
import Footer from './sections/footer';
import Platform from './sections/platform';
import Api from '../../http/serviceApi';
import ModalContainer from '../../components/ModalContainer';
import Iconify from '../../components/Iconify';
import ModalBody from 'rsuite/cjs/Modal/ModalBody';

export default function Landing() {
  const { loading } = useUserAuth(false);

  // ------------------
  const [popup, setPopup] = useState(null);

  // ------------------
  const getPopup = async () => {
    try {
      const data = await Api.getPopup();
      if (data?.active) {
        const lastPopup = localStorage.getItem('popup');
        if (lastPopup !== data?.value) {
          setPopup(data?.value || null);
        }
      }
    } catch (e) {}
  };

  // ------------------
  useEffect(() => {
    getPopup();
  }, []);

  // ------------------
  const onClosePopup = () => {
    setPopup(null);
    localStorage.setItem('popup', popup);
  };

  // ------------------
  if (loading) {
    return <LoadingScreen />;
  }

  // ------------------
  return (
    <>
       <ModalContainer open={!!popup} onClose={onClosePopup} title={''} width={500} hasCloseButton={true}>
      {popup && (
        <Box
          sx={{
            my: 1
            // position: 'fixed',
            // left: 0,
            // top: 0,
            // bgcolor: 'rgba(0,0,0,0.5)',
            // width: '100%',
            // height: '100%',
            // zIndex: 10000,
            // cursor: 'pointer',
          }}
          onClick={onClosePopup}
        >
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{
                __html: popup,
              }}
            >
            </div>
          </ModalBody>
            {/* <IconButton onClick={onClosePopup}>
            <Iconify icon='akar-icons:cross' color='grey.0' sx={{ width: 10, height: 10 }} />
          </IconButton> */}

        </Box>
      )}
       </ModalContainer>

      <Stack sx={{ minHeight: 1 }}>
        <TopMenu />

        <LandingBanner />

        <ContentStyle>
          <TopAssets />

          <Image src={lineImage} alt='' sx={{ maxWidth: 712, width: '90%', m: 'auto' }} />

          <Platform />

          <Image src={lineImage} alt='' sx={{ maxWidth: 712, width: '90%', m: 'auto', mt: 13 }} />

          <Payout />

          <Image
            src={lineImage}
            alt=''
            sx={{ maxWidth: 712, width: '90%', m: 'auto', display: { xs: 'none', md: 'block' } }}
          />

          <Counter />

          <Trust />

          <Portfolio />

          <GetStarted />

          <Footer />
        </ContentStyle>
      </Stack>
    </>
  );
}
