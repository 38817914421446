import Slider from 'react-slick';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Typography, Stack } from '@mui/material';
// components
import { CarouselDots } from '../../../components/carousel';

import Image from '../../../components/Image';
import { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

// import cardTempleteRed from '../../../assets/bankCard/card_templete_red.svg';
import cardTempleteBlue from '../../../assets/bankCard/card_templete_blue.svg';
import cardTempletePurple from '../../../assets/bankCard/card_templete_purple.svg';
import cardTempleteGrey from '../../../assets/bankCard/card_templete_grey.svg';

import masterCardLogo from '../../../assets/bankCard/mastercard_logo.svg';
import visaCardLogo from '../../../assets/bankCard/visacard_logo.svg';
import shetabCardLogo from '../../../assets/bankCard/shetabcard_logo.png';

// ----------------------------------------------------------------------

const HEIGHT = 350;

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  '& .slick-list': {
    borderRadius: '40px',
  },
  marginTop: theme.spacing(2),
  height: HEIGHT - 120,

  [theme.breakpoints.up('md')]: {
    height: HEIGHT,
  },
}));

const CardItemStyle = styled('div')(({ theme, templeteUrl }) => ({
  position: 'relative',
  backgroundSize: 'cover',
  padding: theme.spacing(3, 5),
  backgroundRepeat: 'no-repeat',
  color: theme.palette.common.white,
  backgroundImage: `url(${templeteUrl})`,
  backgroundColor: 'black.0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: HEIGHT - 136,

  [theme.breakpoints.up('md')]: {
    height: HEIGHT - 16,
  },
  [theme.breakpoints.down('md')]: {
    '& .card-number': {
      fontSize: '1.4rem',
    },
  },
}));

const shadowStyle = {
  mx: 'auto',
  width: 'calc(100% - 16px)',
  borderRadius: '40px',
  position: 'absolute',
  height: 200,
  zIndex: 8,
  bottom: 8,
  left: 0,
  right: 0,
  bgcolor: 'grey.500',
  opacity: 0.32,
};

// ----------------------------------------------------------------------

export default function BankCardList({ list, sx }) {
  const theme = useTheme();

  const settings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    ...CarouselDots({
      position: 'absolute',
      left: '50%',
      bottom: -70,
      style: { transform: 'translateX(-50%)' },
      color: theme.palette.grey[300],
    }),
  };

  return (
    <RootStyle sx={sx}>
      <Box sx={{ position: 'relative', zIndex: 9 }}>
        <Slider {...settings}>
          {list.map((card) => (
            <CardItem key={card.id} card={card} />
          ))}
        </Slider>
      </Box>

      <Box sx={{ ...shadowStyle }} />

      <Box
        sx={{
          ...shadowStyle,
          opacity: 0.16,
          bottom: 0,
          zIndex: 7,
          width: 'calc(100% - 40px)',
        }}
      />
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

function CardItem({ card }) {
  const { t } = useTranslation();
  const { firstName, lastName, cardNumber, expiryDateMonth, expiryDateYear, shebaNum, cardType } = card;

  return (
    <CardItemStyle
      templeteUrl={
        (cardType === 'MASTER_CARD' && cardTempleteGrey) ||
        (cardType === 'VISA_CARD' && cardTempleteBlue) ||
        (cardType === 'SHETAB_CARD' && cardTempletePurple) ||
        ''
      }
    >
      <Image
        src={
          (cardType === 'MASTER_CARD' && masterCardLogo) ||
          (cardType === 'VISA_CARD' && visaCardLogo) ||
          (cardType === 'SHETAB_CARD' && shetabCardLogo) ||
          ''
        }
        sx={{ width: 71, height: 67 }}
        objectFit={'contain'}
      />

      <Stack alignItems='center' justifyContent='center' spacing={1}>
        <Typography
          variant='h4_secondary'
          sx={css`
            /* @noflip */
            direction: ltr;
            font-family: 'Poppins';
          `}
          className='card-number'
          dangerouslySetInnerHTML={{
            __html: cardNumber
              ?.replace(/[^\dA-Z]/g, '')
              .replace(/(.{4})/g, '$1 &nbsp;')
              .trim(),
          }}
        />

        {shebaNum !== '' && shebaNum !== '0' && (
          <Typography
            variant='body2_secondary'
            color='grey.100'
            sx={css`
              /* @noflip */
              direction: ltr;
              font-family: 'Poppins';
              letter-spacing: 2px;
            `}
          >
            {shebaNum}
          </Typography>
        )}
      </Stack>

      <Stack direction='row' justifyContent={'space-between'}>
        <div>
          <Typography sx={{ mb: 1, typography: 'body4' }}>{t('Card Holder name')}</Typography>

          <Typography variant='subtitle1'>
            {firstName} {lastName}
          </Typography>
        </div>

        <div>
          <Typography sx={{ mb: 1, typography: 'body4' }}>{t('Expiry date')}</Typography>

          <Typography variant='subtitle1'>
            {expiryDateMonth}/{expiryDateYear}
          </Typography>
        </div>
      </Stack>
    </CardItemStyle>
  );
}
