import { Card, Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

export default function DepositFAQ() {
  const { t } = useTranslation();

  return (
    <Card sx={{ px: { xs: 2, md: 3 }, py: { xs: 3, md: 3 }, pb: 11, mx: { xs: 2, md: 0 } }}>
      <Typography component={'h6'} variant='h6'>
        {t('FAQ')}
      </Typography>

      <Stack spacing={2.5} sx={{ mt: 2 }}>
        <Link to={'#'} component={RouterLink} sx={{ typography: 'body3', color: 'grey.100' }}>
          {t('Video Tutorial')}
        </Link>
        <Link to={'#'} component={RouterLink} sx={{ typography: 'body3', color: 'grey.100' }}>
          {t('How to Withdraw crypto step-by-step Guide')}
        </Link>
        <Link to={'#'} component={RouterLink} sx={{ typography: 'body3', color: 'grey.100' }}>
          {t('Why has my withdrawal Not Arrived?')}
        </Link>
        <Link to={'#'} component={RouterLink} sx={{ typography: 'body3', color: 'grey.100' }}>
          {t('How to find My transaction ID (TxID)?')}
        </Link>
        <Link to={'#'} component={RouterLink} sx={{ typography: 'body3', color: 'grey.100' }}>
          {t('how to recover My BEP-20 token?')}
        </Link>
        <Link to={'#'} component={RouterLink} sx={{ typography: 'body3', color: 'grey.100' }}>
          {t('Deposit & withdrawal status query')}
        </Link>
      </Stack>
    </Card>
  );
}
