import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

import { FormProvider, RHFNumberField } from '../../../../components/hookForm';

export default function UserLevelSettingForm({ methods, onSubmit, isSubmitting }) {
  const { t } = useTranslation();

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Typography variant='body1' color='grey.100' sx={{ mt: 5 }}>
        {t('Cryptocurrency & Fiat Withdrawal')}
      </Typography>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems='center'
        spacing={{ xs: 3, md: 8, lg: 7, xl: 11 }}
        sx={{ mt: 3 }}
      >
        <RHFNumberField
          name='cryptoWithdrawal'
          label={t('Withdrawal of cryptocurrency (equivalent to Dollar)')}
          variant='standard'
          fullWidth
        />
        <RHFNumberField
          name='fiatWithdrawal'
          label={t('Withdrawal of Fiat (equivalent to Dollar)')}
          variant='standard'
          fullWidth
        />
      </Stack>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems='center'
        spacing={{ xs: 3, md: 8, lg: 7, xl: 11 }}
        sx={{ mt: 3 }}
      >
        <RHFNumberField
          name='buyingDigitalMoneyDollar'
          label={t('Buying digital money (equivalent to Dollar)')}
          variant='standard'
          fullWidth
        />
        <RHFNumberField
          name='buyingDigitalMoneyRial'
          label={t('Buying digital money (equivalent to Rial)')}
          variant='standard'
          fullWidth
        />
      </Stack>

      <Typography variant='body1' color='grey.100' sx={{ mt: 5 }}>
        {t('Tether Fee')}
      </Typography>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems='center'
        spacing={{ xs: 3, md: 8, lg: 7, xl: 11 }}
        sx={{ mt: 3 }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography variant='body3' color='grey.300'>
            {t('Calculation of percentage in the price for buy')}
          </Typography>
          <RHFNumberField name='tetherBuyFee' label={t('Fees for buy Tether (Percentage)')} variant='standard' fullWidth sx={{ mt: 1 }} />
        </Box>

        <Box sx={{ width: '100%' }}>
          <Typography variant='body3' color='grey.300'>
            {t('Calculation of the percentage in the price for sale')}
          </Typography>
          <RHFNumberField
            name='tetherSellFee'
            label={t('Fees for Sell Tether (Percentage)')}
            variant='standard'
            fullWidth
            sx={{ mt: 1 }}
          />
        </Box>
      </Stack>

      <Typography variant='body1' color='grey.100' sx={{ mt: 5 }}>
        {t('Futures Fee')}
      </Typography>

      <Box sx={{ width: '100%', mt: 3 }}>
        <Typography variant='body3' color='grey.300'>
          {t('Futures Fee')}
        </Typography>
        <RHFNumberField name='futuresMakerFee' label={t('Marker (Percentage)')} variant='standard' fullWidth sx={{ mt: 1 }} />
        <RHFNumberField name='futuresTakerFee' label={t('Taker (Percentage)')} variant='standard' fullWidth sx={{ mt: 3 }} />
      </Box>

      <Typography variant='body1' color='grey.100' sx={{ mt: 5 }}>
        {t('Trade Fee')}
      </Typography>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems='center'
        spacing={{ xs: 3, md: 8, lg: 7, xl: 11 }}
        sx={{ mt: 2 }}
      >
        <RHFNumberField name='tradeMakerFee' label={t('Marker (Percentage)')} variant='standard' fullWidth />
      </Stack>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems='center'
        spacing={{ xs: 3, md: 8, lg: 7, xl: 11 }}
        sx={{ mt: 3 }}
      >
        <RHFNumberField name='tradeTakerFee' label={t('Taker (Percentage)')} variant='standard' fullWidth />
      </Stack>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems='center'
        spacing={{ xs: 3, md: 8, lg: 7, xl: 11 }}
        sx={{ mt: 5 }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography variant='body1' color='grey.100'>
            {t('Internal Transaction Fee')}
          </Typography>
          <RHFNumberField
            name='internalTransactionFee'
            label={t('Fees for internal transaction (USDT)')}
            variant='standard'
            fullWidth
            sx={{ mt: 1 }}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <Typography variant='body1' color='grey.100'>
            {t('OTC Minimum Buy')}
          </Typography>
          <RHFNumberField name='otcMinimumBuy' label={t('OTC Minimum Buy (USDT)')} variant='standard' fullWidth sx={{ mt: 1 }} />
        </Box>
      </Stack>

      <Typography variant='body1' color='grey.100' sx={{ mt: 5 }}>
        {t('Internal Transaction')}
      </Typography>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems='center'
        spacing={{ xs: 3, md: 8, lg: 7, xl: 11 }}
        sx={{ mt: 1 }}
      >
        <Box sx={{ width: '100%' }}>
          <RHFNumberField
            name='minimumInternalTransaction'
            label={t('Min internal transaction (USDT)')}
            variant='standard'
            fullWidth
            sx={{ mt: 1 }}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <RHFNumberField
            name='maximumInternalTransaction'
            label={t('Max internal transaction (USDT)')}
            variant='standard'
            fullWidth
            sx={{ mt: 1 }}
          />
        </Box>
      </Stack>

      <Typography variant='body1' color='grey.100' sx={{ mt: 5 }}>
        {t('Futures Setting')}
      </Typography>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems='center'
        spacing={{ xs: 3, md: 8, lg: 7, xl: 11 }}
        sx={{ mt: 1 }}
      >
        <Box sx={{ width: '100%' }}>
          <RHFNumberField name='maxLeverage' label={t('Max Leverage')} variant='standard' fullWidth sx={{ mt: 1 }} />
        </Box>
        <Box sx={{ width: '100%' }}>
          <RHFNumberField name='maxMargin' label={t('Max Margin')} variant='standard' fullWidth sx={{ mt: 1 }} />
        </Box>
      </Stack>

      <LoadingButton
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        loading={isSubmitting}
        sx={{ maxWidth: 283, mt: 6 }}
      >
        {t('Save')}
      </LoadingButton>
    </FormProvider>
  );
}
