export const SET_USERINFO = 'SET_USERINFO';
export const SET_ADMIN_USERINFO = 'SET_ADMIN_USERINFO';
export const SET_AUTHENTICATION_BASIC = 'SET_AUTHENTICATION_BASIC';
export const SET_SIGNOUT = 'SET_SIGNOUT';
export const SET_AUTHENTICATION_Intermediate = 'SET_AUTHENTICATION_Intermediate';
export const SET_OrderBookList_SelectedItem = 'SET_OrderBookList_SelectedItem';
export const SET_ORDERBOOK = 'SET_ORDERBOOK';
export const SET_USER_TRADEINFO = 'SET_USER_TRADEINFO';
export const SET_ORDER_HISTORY_DATE = 'SET_ORDER_HISTORY_DATE';
export const SET_Loading_USER_TRADEINFO = 'SET_Loading_USER_TRADEINFO';
export const SET_LOADING_ORDERBOOK = 'SET_LOADING_ORDERBOOK';
export const SET_PAGE_STATE = 'SET_PAGE_STATE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_THEME_DIRECTION = 'SET_THEME_DIRECTION';
export const SET_THEME_MODE = 'SET_THEME_MODE';
export const SET_TOAST = 'SET_TOAST';
export const SET_LAST_UPDATE_TICKET = 'SET_LAST_UPDATE_TICKET';
export const SET_WAITING_COUNTER = 'SET_WAITING_COUNTER';
export const SET_IRANIAN_PAYMENT_STATUS = 'SET_IRANIAN_PAYMENT_STATUS';
