import { useSnackbar } from 'notistack';

import Api from '../../../../../http/adminHttp/serviceApiAdmin';
import { useTranslation } from 'react-i18next';

export default function useDedicatedSubmit(gateways) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async (payload) => {
    const data = {
      dedicatedGatewaySettingInfoList: gateways,
      iranianPayment: payload.iranianPayment,
    };
    const response = await Api.editDedicatedGatewaySettings(data);
    if (response?.status === 'SUCCESS') {
      enqueueSnackbar(t('The values saved successfully.'), { variant: 'success' });
    }
  };

  return {
    onSubmit,
  };
}
