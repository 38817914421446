import { useEffect, useState } from 'react';
import { Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useTable from '../../../hooks/useTable';
import Scrollbar from '../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../components/table';
import WithdrawTableToolbar from './withdrawTableToolbar';
import WithdrawTableRow from './withdrawTableRow';
import Api from '../../../http/serviceApi';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'amount', label: 'Amount', align: 'left' },
  { id: 'fiatType', label: 'Fiat type', align: 'left' },
  { id: 'message', label: 'Message', align: 'left' },
  { id: 'trackingCode', label: 'Tx Code', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
];

// ----------------------------------------------------------------------

export default function WithdrawTable() {
  const { t } = useTranslation();

  // --------------------
  const [statusFilter, setStatusFilter] = useState('NEW');
  const [typeFilter, setTypeFilter] = useState('ALL');

  // --------------------
  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  // --------------------
  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });

  // ----------------------------------------------
  const getData = async () => {
    try {
      const filters = {
        status: statusFilter,
        type: typeFilter,
        action: 'WITHDRAW',
      };

      const response = await Api.getFiatHistory(page, rowsPerPage, filters);
      if (response.status === 'SUCCESS') {
        setTableData(response.transactionInfos);
        setTablePagination({ totalItems: response.totalElements });
      } else {
        setTableData([]);
      }
    } catch (e) {
      setTableData([]);
    }
  };

  // ----------------------------------------------
  useEffect(() => {
    getData();
  }, [page, rowsPerPage, statusFilter, typeFilter]);

  // --------------------
  return (
    <>
      <WithdrawTableToolbar
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        typeFilter={typeFilter}
        setTypeFilter={setTypeFilter}
      />

      <Scrollbar>
        <TableContainer sx={{ position: 'relative' }}>
          <Table size={'small'}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length || 0}
              onSort={onSort}
            />

            <TableBody>
              {tableData === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {tableData?.map((row) => (
                    <WithdrawTableRow key={row.id} row={row} />
                  ))}

                  <TableNoData isNotFound={!tableData?.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      {tableData?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 200]}
          component='div'
          count={tablePagination.totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage={t('Rows per page')}
          labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
        />
      )}
    </>
  );
}
