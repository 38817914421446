import { useEffect, useState } from 'react';

import Api from '../../../../../../../http/adminHttp/serviceApiAdmin';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { exportExcel } from '../../../../../../../utils/excel';
import { prettyUppercases } from '../../../../../../../utils';
import { exportPDF } from '../../../../../../../utils/pdf';
import { getDefaultDateFilterValues } from '../../../../../../../utils/filter';

export default function useTradeHistoryInit(page, rowsPerPage, userId) {
  const [sideFilter, setSideFilter] = useState('ALL');
  const [stateFilter, setStateFilter] = useState('ALL');
  const [typeFilter, setTypeFilter] = useState('ALL');
  const [tradeTypeFilter, setTradeTypeFilter] = useState('done');
  const [dateFromFilter, setDateFromFilter] = useState(getDefaultDateFilterValues().firstDay);
  const [dateToFilter, setDateToFilter] = useState(getDefaultDateFilterValues().lastDay);

  const language = useSelector((store) => store.language);
  const { t } = useTranslation();

  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });

  const fetchTableData = async () => {
    try {
      const filters = {
        userId,
        side: sideFilter,
        state: stateFilter,
        type: typeFilter,
        endTime: dateToFilter?.valueOf?.(),
        // startTime: moment(dateFromFilter).valueOf(),
        startTime: dateFromFilter?.valueOf?.() ,
      };

      const response = await Api.getUserTradeHistory(page, rowsPerPage, filters);

      if (response.status === 'SUCCESS') {
        if (tradeTypeFilter === 'open') {
          setTableData(response.openTradeInfos);
          setTablePagination({ totalItems: response.openTradeInfosTotalPages * rowsPerPage });
        } else if (tradeTypeFilter === 'done') {
          setTableData(response.tradeHistoryInfos);
          setTablePagination({ totalItems: response.tradeHistoryInfosTotalPages * rowsPerPage });
        } else {
          setTableData([]);
        }
      }
    } catch (e) {
      setTableData([]);
    }
  };

  const handleFilter = () => {
    fetchTableData();

  };

  const handleResetFilters = () => {
    setSideFilter('ALL');
    setStateFilter('ALL');
    setTypeFilter('ALL');
    setTradeTypeFilter('open');
    setDateFromFilter(getDefaultDateFilterValues().firstDay);
    setDateToFilter(getDefaultDateFilterValues().lastDay);
  };

  // --------------------------
  useEffect(() => {
    if (userId) fetchTableData();
  }, [page, rowsPerPage, userId]);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);

  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    const filters = {
      userId,
      side: sideFilter,
      state: stateFilter,
      type: typeFilter,
    };

    try {
      const response = await Api.getUserTradeHistory(0, 10000000, filters);

      let result = [];
      if (tradeTypeFilter === 'open') {
        result = response.openTradeInfos;
      } else if (tradeTypeFilter === 'done') {
        result = response.tradeHistoryInfos;
      }

      // generate excel and download
      const config = {
        language,
        fileName: t('User - Trade History'),
        heads: [
          '#',
          t('Transaction Date'),
          t('Symbol'),
          t('Side'),
          t('State'),
          t('Type'),
          t('Amount'),
          t('Commission'),
          t('Price'),
          t('Stop Price'),
          t('Total'),
        ],
        columnsWidth: [5, 18, 10, 10, 14, 12, 11, 12, 11, 10, 9],
        list: result.map((item) => [
          moment(item.transactionDate).locale(language).format('D MMM YYYY - HH:mm'),
          item.commissionAsset,
          `${t(prettyUppercases(item.side))}`,
          `${t(prettyUppercases(item.state))}`,
          `${t(prettyUppercases(item.type))}`,
          `${Number(item.amount)?.toLocaleString('en-US')}`,
          `${Number(item.commission)?.toLocaleString('en-US')}`,
          `${Number(item.price)?.toLocaleString('en-US')}`,
          `${Number(item.stopPrice)?.toLocaleString('en-US')}`,
          `${Number(item.total)?.toLocaleString('en-US')}`,
        ]),
      };

      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  // --------------------------
  return {
    sideFilter,
    setSideFilter,
    stateFilter,
    setStateFilter,
    typeFilter,
    setTypeFilter,
    tradeTypeFilter,
    dateFromFilter,
    setDateFromFilter,
    dateToFilter,
    setDateToFilter,
    setTradeTypeFilter,
    tableData,
    tablePagination,
    handleFilter,
    handleResetFilters,
    isExportLoading,
    onExport,
  };
}
