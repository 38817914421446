import { useSnackbar } from 'notistack';
import Api from '../../../../../../../http/adminHttp/serviceApiAdmin';
import { useTranslation } from 'react-i18next';

export default function useTransactionSubmit({ setStatusModal, onClose, onReload, userId, newAmount }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const onSubmit = async (payload) => {
    if (newAmount <= 0) {
      return enqueueSnackbar(t('The new balance cannot be less than zero'), { variant: 'warning' });
    }

    const response = await Api.createUserTransaction(
      {
        amount: Number(payload.amount.replace(/,/g, '')),
        fiatType: payload.fiatType,
        transactionType: payload.type,
        description: payload.caption,
      },
      userId
    );

    if (response.status === 'SUCCESS') {
      setStatusModal({
        show: true,
        status: 'success',
      });
      onReload();
    } else {
      setStatusModal({
        show: true,
        status: 'error',
      });
    }
    onClose();
  };

  return {
    onSubmit,
  };
}
