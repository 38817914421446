import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import Api from '../../../../../http/adminHttp/serviceApiAdmin';

export default function useLevelSettingSubmit(selectedLevel) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const onSubmit = async (payload) => {
    const body = {};
    for (const key in payload) {
      body[key] = +(payload[key] || 0);
    }

    body.authenticationLevel = selectedLevel;

    try {
      const response = await Api.userLevelSetting(body);
      if (response?.status === 'SUCCESS') {
        enqueueSnackbar(t('The values saved successfully.'), { variant: 'success' });
      }
    } catch (e) {}
  };

  return {
    onSubmit,
  };
}
