import { Box, Card, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';

import { SET_PAGE_STATE } from '../../../store/actionTypes';
import SystemStatus from './systemStatus';
import Statistic from './statistic';
import StatisticTable from './statisticTable';
import CurrencyTable from './currencyTable';
import StatisticCounter from './statisticCounter';
import ChartDonutStatistic from './chartDonutStatistic';
import { useEffect } from 'react';
import CurrencySlider from './currencySlider';
import StatisticSymbolTable from './statisticSymbolTable';

//==============================================================

const AdminMainPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Dashboard' },
    });
  }, []);

  return (
    <Box sx={{ mx: { xs: 2, lg: 0 } }}>
      <Grid container spacing={2.5}>
        <Statistic />
      </Grid>

      <Grid container spacing={2.5} sx={{ mt: 0 }}>
        <Grid item xs={12} md={8} lg={9}>
          <Card sx={{ height: '100%' }}>
            <StatisticTable />
          </Card>
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <Card sx={{ height: '100%' }}>
            <CurrencySlider />
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2.5} sx={{ mt: 0 }}>
        <StatisticCounter />
      </Grid>

      <Grid container spacing={2.5} sx={{ mt: 0 }}>
        <Grid item xs={12}>
          <Card>
            <StatisticSymbolTable />
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2.5} sx={{ mt: 0 }}>
        <Grid item xs={12}>
          <Card>
            <SystemStatus />
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2.5} sx={{ mt: 0 }}>
        <ChartDonutStatistic />
      </Grid>

      <Grid container spacing={2.5} sx={{ mt: 0 }}>
        <Grid item xs={12}>
          <Card>
            <CurrencyTable />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminMainPage;
