import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// ----------------------------------------------------------------------

RHFDateTimePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default function RHFDateTimePicker({ name, label = '', ...other }) {
  const { t } = useTranslation();

  const { control } = useFormContext();
  const [open, setOpen] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DateTimePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderInput={(props) => (
            <TextField
              fullWidth
              helperText={error?.message}
              {...props}
              {...other}
              error={!!error}
              onClick={(e) => setOpen(true)}
            />
          )}
          label={t(label)}
          value={field.value}
          onChange={(newValue) => {
            field.onChange(newValue);
          }}
        />
      )}
    />
  );
}
