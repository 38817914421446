import { useEffect, useState } from 'react';
import { Box, Card, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'jalali-moment';

import useTable, { getComparator } from '../../../../hooks/useTable';
import { PageTitle } from '../../../../components/PageTitle';
import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../components/table';
import { applySortFilter, prettyUppercases } from '../../../../utils';
import OrderHistoryTableRow from './orderHistoryTableRow';
import Api from '../../../../http/serviceApi';
import AdminApi from '../../../../http/adminHttp/serviceApiAdmin';
import OrderHistoryTableToolbar from './orderHistoryTableToolbar';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { exportExcel } from '../../../../utils/excel';
import OrderHistoryModal from './orderHistoryModal';
import { getDefaultDateFilterValues } from '../../../../utils/filter';
import { exportPDF } from '../../../../utils/pdf';

// ----------------------------------------------------------------------

export default function OrderHistoryTable({ isAdmin = false }) {
  const language = useSelector((store) => store.language);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Orders History' },
    });
  }, []);

  // ===================================================================

  const TABLE_HEAD = [
    { id: 'createDate', label: 'Time', align: 'left' },
    ...(isAdmin ? [{ id: 'user', label: 'User', align: 'left' }] : []),
    { id: 'side', label: 'Side', align: 'center' },
    { id: 'amount', label: 'Crypto Amount', align: 'left' },
    { id: 'price', label: 'Fiat Amount', align: 'left' },
    { id: 'pricePerCoin', label: 'Price per Coin', align: 'left' },
    { id: 'transactionId', label: 'Tx Code', align: 'left' },
    { id: 'status', label: 'Status', align: 'center' },
    { id: 'detail', label: 'Detail', align: 'center' },
  ];

  const [sideFilter, setSideFilter] = useState('ALL'); // ALL, BUY, SELL

  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  // --------------------
  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });
  const [showModalDetail, setShowModalDetail] = useState(null);

  // --------------------
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [timeFilter, setTimeFilter] = useState({
    startTime: getDefaultDateFilterValues().firstDay,
    endTime: getDefaultDateFilterValues().lastDay,
  });
  const [transactionIdFilter, setTransactionIdFilter] = useState('');
  const [symbolFilter, setSymbolFilter] = useState('');
  const [fiatAmountFilter, setFiatAmountFilter] = useState([0, 10000000000]);
  const [firstNameFilter, setFirstNameFilter] = useState('');
  const [lastNameFilter, setLastNameFilter] = useState('');

  // ----------------------------------------------
  const getData = async () => {
    try {
      const filters = {
        side: sideFilter,
        status: statusFilter,
        endTime: timeFilter.endTime?.valueOf?.(),
        startTime: timeFilter.startTime?.valueOf?.(),
        ...(transactionIdFilter && { transactionId: transactionIdFilter }),
        ...(symbolFilter && { symbol: `${symbolFilter}` }),
        ...(isAdmin
          ? {
              fiatAmount: fiatAmountFilter[0],
              fiatAmount2: fiatAmountFilter[1],
              ...(firstNameFilter && { firstName: firstNameFilter }),
              ...(lastNameFilter && { lastName: lastNameFilter }),
            }
          : {
              amount: fiatAmountFilter[0],
              amount2: fiatAmountFilter[1],
            }),
      };

      if (isAdmin) {
        const response = await AdminApi.getReportOtcHistory(page, rowsPerPage, filters);
        if (response.status === 'SUCCESS') {
          setTableData(response.otcHistoryInfos);
          setTablePagination({ totalItems: response.otcHistoryInfosTotalPages * rowsPerPage });
        } else {
          setTableData([]);
        }
      } else {
        const response = await Api.getUserOtcHistory(page, rowsPerPage, filters);
        if (response.status === 'SUCCESS') {
          setTableData(response.otcHistoryInfos);
          setTablePagination({ totalItems: response.otcHistoryInfosTotalPages * rowsPerPage });
        } else {
          setTableData([]);
        }
      }
    } catch (e) {
      setTableData([]);
    }
  };

  // ----------------------------------------------
  useEffect(() => {
    getData();
  }, [
    page,
    rowsPerPage,
    sideFilter,
    statusFilter,
    timeFilter,
    transactionIdFilter,
    symbolFilter,
    fiatAmountFilter,
    firstNameFilter,
    lastNameFilter,
  ]);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);
  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    const filters = {
      side: sideFilter,
      status: statusFilter,
      endTime: timeFilter.endTime?.valueOf?.(),
      startTime: timeFilter.startTime?.valueOf?.(),
      ...(transactionIdFilter && { transactionId: transactionIdFilter }),
      ...(symbolFilter && { symbol: `${symbolFilter}` }),
      ...(isAdmin
        ? {
            fiatAmount: fiatAmountFilter[0],
            fiatAmount2: fiatAmountFilter[1],
            ...(firstNameFilter && { firstName: firstNameFilter }),
            ...(lastNameFilter && { lastName: lastNameFilter }),
          }
        : {
            amount: fiatAmountFilter[0],
            amount2: fiatAmountFilter[1],
          }),
    };

    try {
      let response;
      if (isAdmin) {
        response = await AdminApi.getReportOtcHistory(0, 10000000000, filters);
      } else {
        response = await Api.getUserOtcHistory(0, 10000000000, filters);
      }

      const heads = [
        '#',
        t('Time'),
        ...(isAdmin ? [t('Name'), t('Email'), t('Mobile')] : []),
        t('Side'),
        t('Amount'),
        t('Price'),
        t('Payment Method'),
        t('TxID'),
        t('Wallet ID'),
        t('Status'),
      ];

      const columnsWidth = [5, 15, ...(isAdmin ? [20, 30, 13] : []), 6, 12, 12, 12, 12, 12, 12];

      // generate excel and download
      const config = {
        language,
        fileName: t('Order History'),
        heads: heads,
        columnsWidth,
        list: response.otcHistoryInfos.map((item) => [
          moment(item.createdDate).locale(language).format('YYYY-MM-DD HH:mm'),
          ...(isAdmin ? [`${item.firstName} ${item.lastName}`, item.emailAddress || '-', item.mobile || '-'] : []),
          item.side,
          `${item.amount} ${item.symbol}`,
          `${item.price} ${item.fiat}`,
          t(prettyUppercases(item.depositType) || '-'),
          item.transactionCode,
          t(item.walletId || '-'),
          t(prettyUppercases(item.status)),
        ]),
      };

      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  // ----------------------------------------------
  return (
    <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 }, mx: { xs: 2, lg: 0 } }}>
      <PageTitle
        title={'History of your orders'}
        subTitle={'You can enter the name and symbol of Coin, transaction ID, etc. in the search field'}
      />

      <Box sx={{ mt: 5 }}>
        <OrderHistoryTableToolbar
          isAdmin={isAdmin}
          isExportLoading={isExportLoading}
          onExport={onExport}
          sideFilter={sideFilter}
          setSideFilter={setSideFilter}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          timeFilter={timeFilter}
          setTimeFilter={setTimeFilter}
          transactionIdFilter={transactionIdFilter}
          setTransactionIdFilter={setTransactionIdFilter}
          symbolFilter={symbolFilter}
          setSymbolFilter={setSymbolFilter}
          fiatAmountFilter={fiatAmountFilter}
          setFiatAmountFilter={setFiatAmountFilter}
          firstNameFilter={firstNameFilter}
          setFirstNameFilter={setFirstNameFilter}
          lastNameFilter={lastNameFilter}
          setLastNameFilter={setLastNameFilter}
        />

        <Scrollbar>
          <TableContainer sx={{ position: 'relative' }}>
            <Table size={'small'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length || 0}
                onSort={onSort}
              />

              <TableBody>
                {tableData === null ? (
                  <TableLoading isLoading />
                ) : (
                  <>
                    {applySortFilter({
                      tableData,
                      comparator: getComparator(order, orderBy),
                    }).map((row, index) => (
                      <OrderHistoryTableRow
                        key={index}
                        row={row}
                        isAdmin={isAdmin}
                        onShowModalDetail={() => setShowModalDetail(row)}
                      />
                    ))}

                    <TableNoData isNotFound={!tableData?.length} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        {tableData?.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            component='div'
            count={tablePagination.totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
          />
        )}
      </Box>

      <OrderHistoryModal
        open={!!showModalDetail}
        onClose={() => setShowModalDetail(null)}
        data={showModalDetail}
        isAdmin={isAdmin}
      />
    </Card>
  );
}
