import { Box, Grid, Typography } from '@mui/material';
import ModalContainer from '../../../../components/ModalContainer';
import { useTranslation } from 'react-i18next';
import Image from '../../../../components/Image';
import ViewTokenTable from './viewTokenTable';

export const ViewTokenModal = ({ open, onClose, data }) => {
  const { t } = useTranslation();

  return (
    <ModalContainer open={open} onClose={onClose} title={'Token'} width={'95vw'}>
      <Grid container spacing={4} mb={4} mt={0}>
        <Grid item xs={12} md={4} lg={3} xl={2}>
          <Image sx={{ width: '40px', height: '40px' }} src={data.imageUrl}></Image>
        </Grid>
        <Grid item container alignItems={'center'} xs={12} md={4} lg={3} xl={2} gap={1.5}>
          <Typography variant='body3' fontWeight={'bold'}>
            {t('Name')}:
          </Typography>
          <Typography variant='body3' mt={0.5}>
            {data.name}
          </Typography>
        </Grid>
        <Grid item container alignItems={'center'} xs={12} md={4} lg={3} xl={2} gap={1.5}>
          <Typography variant='body3' fontWeight={'bold'}>
            {t('Symbol')}:
          </Typography>{' '}
          <Typography variant='body3' mt={0.5}>
            {data.symbol}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ViewTokenTable tableData={data.networkInfoList}></ViewTokenTable>
        </Grid>
      </Grid>
    </ModalContainer>
  );
};
