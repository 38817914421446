import { CircularProgress, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const borderColor = {
  APPROVED: 'rgba(48, 224, 161, 1)',
  WAITING: 'warning.light',
  REJECTED: 'error.darker',
};

const backColor = {
  APPROVED: 'rgba(48, 224, 161, 0.2)',
  WAITING: 'rgba(242, 127, 22, 0.2)',
  REJECTED: 'rgba(250, 34, 86, 0.2)',
};

export default function SelectBoxStatus({ condition, variant = 'outlined', onChange, isSubmitting = false }) {
  const { t } = useTranslation();

  if (isSubmitting) {
    return <CircularProgress size={20} />;
  }

  return (
    <TextField
      select
      variant='outlined'
      fullWidth
      size='small'
      value={condition}
      onChange={(e) => onChange(e.target.value)}
      sx={{
        '& .MuiInputBase-root': {
          maxWidth: 130,
          border: '1px solid',
          borderRadius: '50px',

          ...(variant === 'outlined' && { borderColor: borderColor[condition] || '' }),
          ...(variant === 'filled' && { backgroundColor: backColor[condition] || '', border: 'none' }),
        },
        '& fieldset': {
          ...(variant === 'filled' && { border: 'none' }),
        },
        '& .MuiSelect-select': {
          // width: 68,
          py: 0.7,
          typography: 'body2',
          pl: 2,
          ...(condition === 'APPROVED'
            ? { color: 'rgba(48, 224, 161, 1)' }
            : condition === 'WAITING'
            ? { color: 'warning.main' }
            : condition === 'REJECTED'
            ? { color: 'error.darker' }
            : ''),
        },
        '& .MuiSvgIcon-root': {
          mr: 1,
          ...(condition === 'APPROVED'
            ? { color: 'rgba(48, 224, 161, 1)' }
            : condition === 'WAITING'
            ? { color: 'warning.main' }
            : condition === 'REJECTED'
            ? { color: 'error.darker' }
            : ''),
        },
        // '& .MuiInput-root:before': {
        //   borderBottom: 'none',
        // },
        // '& .MuiInput-root:after': {
        //   borderBottom: 'none',
        // },
      }}
      SelectProps={{
        MenuProps: {
          sx: {
            '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
            '& .MuiMenuItem-root': { typography: 'body2' },
          },
        },
      }}
    >
      <MenuItem value={'APPROVED'}>{t('Approved')}</MenuItem>
      <MenuItem value={'REJECTED'}>{t('Rejectd')}</MenuItem>
      <MenuItem value={'WAITING'}>{t('Waiting')}</MenuItem>
    </TextField>
  );
}
