import { useState } from 'react';
import { Box, Card, CircularProgress, Stack, Typography } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import moment from 'jalali-moment';

import { BaseOptionChart } from '../../../components/chart';
import mergeDeep from '../../../utils/merge';
import { BadgeIcon } from './style';
import TimeChart from './timeChart';
import Api from '../../../http/adminHttp/serviceApiAdmin';
import fakeBar from '../../../assets/fake-bar.png';
import fakeGrid from '../../../assets/GridDark.png';

export default function TradeChart() {
  const { t } = useTranslation();

  // --------------------
  const [period, setPeriod] = useState('MONTH');

  // --------------------
  const [chartData, setChartData] = useState(null);
  const [chartLabels, setChartLabels] = useState([]);

  // --------------------
  const getData = async () => {
    try {
      setChartData(null);

      const response = await Api.getReportBuySellStatistics(period);

      let hasData = false;
      const datas = [];
      const labels = [];
      (response?.reportBuySellChartInfos || []).forEach((item) => {
        datas.push({ buy: item.buyCount, sell: item.sellCount });
        labels.push(moment(item.date).format('MMM D'));

        if (item.buyCount > 0 || item.sellCount > 0) {
          hasData = true;
        }
      });

      if (hasData) {
        setChartData(datas);
        setChartLabels(labels);
      } else {
        setChartData([]);
      }
    } catch (e) {}
  };

  // ---------------------
  useEffect(() => {
    getData();
  }, [period]);

  // --------------------
  return (
    <Card sx={{ my: 2 }}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography>{t('Buy/Sell')}</Typography>

        <TimeChart period={period} setPeriod={setPeriod} />

        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant='body4'>{t('Buy')}</Typography>
            <BadgeIcon bgColor='#BD47FB' direction='left' />
          </Stack>

          <Stack direction={'row'} justifyContent={'space-between'} sx={{ ml: '44px' }}>
            <Typography variant='body4'>{t('Sell')}</Typography>
            <BadgeIcon bgColor='#30E0A1' direction='left' />
          </Stack>
        </Stack>
      </Stack>

      {chartData === null ? (
        <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: 200 }}>
          <CircularProgress color='success' size={40} />
        </Stack>
      ) : (
        <>
          {chartData.length > 0 ? (
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  backgroundImage: `url("${fakeGrid}")`,
                  backgroundRepeat: 'repeat',
                  width: 'calc(100% - 70px)',
                  height: 'calc(100% - 50px)',
                  position: 'absolute',
                  left: 40,
                  bottom: 50,
                }}
              >
                <Box
                  sx={{
                    backgroundImage: `url("${fakeBar}")`,
                    backgroundRepeat: 'repeat-x',
                    backgroundPosition: '0 115px',
                    width: '100%',
                    height: '100%',
                  }}
                />
              </Box>
              <Chart chartData={chartData} chartLabels={chartLabels} />
            </Box>
          ) : (
            <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: 200 }}>
              <Typography>{t('No Data')}</Typography>
            </Stack>
          )}
        </>
      )}
    </Card>
  );
}

const Chart = ({ chartData, chartLabels }) => {
  const { t } = useTranslation();

  const chartOptions = mergeDeep(BaseOptionChart(), {
    stroke: {
      curve: 'smooth',
      width: 1.5,
    },
    legend: {
      show: false,
    },
    colors: ['#BD47FB', '#30E0A1'],
    chart: { animations: { enabled: true } },
    xaxis: {
      categories: chartLabels,
    },
    grid: {
      show: true,
      strokeDashArray: 4,
      borderColor: 'info.darkest',
    },
    tooltip: {
      x: { show: false },
      y: {
        formatter: (seriesName) => `${seriesName || 0} ${t('items')}`,
        title: {
          formatter: (label) => ` ${t(label)} `,
        },
      },
      marker: { show: false },
    },
  });

  return (
    <ReactApexChart
      series={[
        {
          name: t('Buy'),
          type: 'line',
          data: chartData.map((c) => c.buy),
        },
        {
          name: t('Sell'),
          type: 'line',
          data: chartData.map((c) => c.sell),
        },
      ]}
      options={chartOptions}
      height={180}
    />
  );
};
