import React from 'react';
import { Image, Row, Col } from 'antd';
import classes from './forgotNewPass.module.scss';
import logo from '../../../../assets/logo.png';
import ForgotNewPassForm from './forgotNewPassForm';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';

const ForgotNewPass = () => {
  const colPropsForm = {
    xs: 20,
    sm: 14,
    md: 8,
    lg: 6,
    xl: 6,
  };

  const colPropsImage = {
    xs: 0,
    sm: 0,
    md: 9,
    lg: 9,
    xl: 9,
  };

  return (
    <div className={classes.container}>
      <Row justify='space-around' style={{ marginTop: '50px' }}>
        <Col {...colPropsForm}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Image width={32} src={logo} preview={false} />
            <span style={{ fontSize: 18, lineHeight: 1, marginLeft: 8 }}>Cryptizi</span>
          </div>
        </Col>
        <Col {...colPropsImage}></Col>
      </Row>

      <Row justify='center'>
        <Col {...colPropsForm}>
          <ForgotNewPassForm />
        </Col>
      </Row>
    </div>
  );
};

export default ForgotNewPass;
