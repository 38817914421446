import { Container, Divider, Link, Stack, styled, Typography } from '@mui/material';

import useUserAuth from '../../hooks/useUserAuth';
import LoadingScreen from '../../components/LoadingScreen';
import TopMenu from '../landing/sections/topMenu';
import Footer from '../landing/sections/footer';

export default function TermsConvert() {
  const { loading } = useUserAuth(false);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Stack sx={{ minHeight: 1 }}>
      <TopMenu />

      <RootStyle>
        <Container>
          <Typography variant='h5'>Cryptizi Privacy policy</Typography>

          <Typography variant='body2' color='grey.300' sx={{ mt: 4 }}>
            Version: 17 February 2023
          </Typography>

          <Typography variant='h6' sx={{ mt: 3 }}>
            A. Convert Services
          </Typography>

          <ol style={{ marginLeft: 30 }}>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                By accessing and using the Convert Services (as defined below), you acknowledge and agree that you have
                read, understood and accepted all of the terms and conditions in these terms (the “Convert Terms”), and
                you acknowledge and agree that you will be bound by and will comply with these Convert Terms. If you do
                not understand and accept these Convert Terms in their entirety, you should refrain from accessing or
                using the Convert Services.{' '}
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                Upon completion of the registration and identity verification for your Cryptizi Account, you may be able
                to make use of the Cryptizi Convert services, which enable Users to buy and sell Digital Assets based on
                prices provided by Cryptizi (the “Convert Services”). Pursuant to the Convert Services, Users may
                provide Cryptizi with a request to convert one Digital Asset into another Digital Asset or a particular
                Fiat currency into a Digital Asset (or vice versa), as applicable, based on supported trading pairs as
                available on the Platform (a “Conversion”), which Cryptizi may, in its sole discretion, elect to accept
                or reject. If accepted, a transaction will be entered into between Cryptizi and the User subject to the
                terms as set out in these Convert Terms.
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                To request a Conversion between a supported trading pair through the Platform you may place: (i) a
                market order, by specifying the type and amount of the Digital Assets or Fiat currency (each an “Asset”)
                to be converted and the type of Assets to be received pursuant to such Conversion (a “Convert Market
                Order”); and/or (ii) a limit order, by specifying the type and amount of the Assets to be converted and
                the type of Assets to be received pursuant to such Conversion, the limit price at which such Conversion
                is to take place and the date upon which the limit order is to expire (a “Convert Limit Order”). For the
                purposes of the Convert Services, reference to “Platform” in these Convert Terms shall be to the digital
                platform made accessible via any website, page, feature, or content owned or operated by Cryptizi, any
                mobile application developed or provided by Cryptizi or any Cryptizi API Connection (as defined below)
                made available to Users or any third-party application relying on such Cryptizi API Connection or by
                such other means as Cryptizi may prescribe from time to time for the use of Convert Services.
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                If you place a Convert Market Order, Cryptizi may (but is not obliged to) provide a non-binding,
                indicative and discretionary quote for the conversion (a “Convert Quote”), which you may accept or
                decline within such a period of time as Cryptizi may, in its sole discretion, specify. It is your
                responsibility to check the competitiveness of the price and decide whether to accept the Convert Quote
                or not within the specified time. Your acceptance of the Convert Quote will result in the submission of
                a trade request to Cryptizi for Cryptizi to process the requested Conversion at the price provided in
                the Convert Quote (a “Trade Request”). Submission of a Trade Request authorizes Cryptizi to temporarily
                lock the requisite amount of the User’s Assets that are to be converted until the Conversion is
                completed (subject to Cryptizi’s right to reject any such Trade Request as detailed further below).
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                User’s acceptance of a Convert Quote and submission of a Trade Request does not obligate Cryptizi to
                process the requested Conversion on behalf of the User. Cryptizi may either accept or reject the Trade
                Request at its sole discretion, depending on market conditions and other factors at the relevant time.
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                If Cryptizi accepts the Trade Request, Cryptizi will guarantee the price of the requested Conversion
                (regardless of price movement) as well as the amount of the relevant Assets that are to be converted and
                provided to the User pursuant to such Conversion. Cryptizi will settle the requested Conversion
                accordingly by debiting the User’s Cryptizi Account of the agreed amount of Assets to be converted and
                crediting the User’s Cryptizi Account with the agreed amount of Assets to be received pursuant to such
                Conversion. In most cases, the settlement will complete within seconds from the acceptance by Cryptizi
                of the relevant Trade Request, however Cryptizi does not guarantee the settlement of the Conversion
                within this timeframe and in some instances the settlement process may take several hours or even
                longer, depending on various factors, including market conditions, technical issues, etc. Once a Trade
                Request has been accepted by Cryptizi, the User will not be permitted to cancel the requested Conversion
                or to transfer, withdraw, or otherwise trade the Assets that are the subject of such Conversion.
                Cryptizi shall not be held responsible for potential loss or opportunity cost in connection with any
                price change of the Asset subject to the Conversion during this settlement period.
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                In respect of Convert Limit Orders, Cryptizi may accept or reject any Convert Limit Order in its sole
                discretion. If Cryptizi accepts a Convert Limit Order, Cryptizi does not guarantee the fulfillment of
                the limit order, even if the market price of the relevant Asset subject to the Conversion hits or
                crosses the limit price set by you, as orders are executed subject to market conditions and liquidity.
                Cryptizi shall not be held responsible for any potential loss or opportunity cost due to the failure to
                execute any Convert Limit Order in such case. If any Convert Market Order is executed, the provisions
                detailed in clause 6 above with respect to the settlement of the relevant Conversion shall apply.{' '}
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                Cryptizi has the exclusive authority to determine which Assets are listed on the Platform and are
                available for the purposes of a Conversion and may add or remove Assets from the Platform in its sole
                discretion, from time to time without prior notice. Cryptizi may also change the order size available
                for a Conversion with respect to a particular Asset. In respect of such additions, removals, or
                amendments, Cryptizi may, but is not obliged to, notify Users in advance and Cryptizi shall have no
                liability to users in connection with such additions, removals or amendments.
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                It is recommended that Users conduct independent research into different Digital Assets prior to using
                Convert services and that Users take steps to ensure they understand the risks associated with the
                trading of Digital Assets. Users bear full responsibility for ensuring that the Convert Services are
                suitable for them, taking into account their own financial objectives and circumstances, and shall be
                solely responsible for any profit or loss sustained in connection with their use of the Convert
                Services. Cryptizi does not make any representation of guarantee as to the outcome to be received by the
                trading of Digital Assets whether pursuant to the Convert Services or otherwise.
              </Typography>
            </li>
          </ol>

          <Typography variant='h6' sx={{ mt: 3 }}>
            B. General Terms
          </Typography>

          <ol style={{ marginLeft: 30 }}>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                All terms and references used in these Convert Terms and which are defined and construed in the{' '}
                <Link href='https://www.Cryptizi.com/terms-of-use' color='primary.main'>
                  Cryptizi Terms of Use
                </Link>{' '}
                (the “<strong>Terms of Use</strong>”), but are not defined or construed in these Convert Terms, shall
                have the same meaning and construction in these Terms.
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                These Convert Terms are to be read together with, and are supplemental to, the Terms of Use. In the
                event of any conflict or inconsistency between any provision of these Convert Terms and a provision of
                the Terms of Use, the Convert Terms shall prevail so far as such inconsistency relates to the Convert
                Services.
              </Typography>
            </li>
          </ol>

          <Divider sx={{ mt: 8, mb: -6 }} />
        </Container>

        <Footer />
      </RootStyle>
    </Stack>
  );
}

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  width: '100%',
  marginTop: 130,

  direction: theme.direction === 'rtl' ? 'rtl' : 'ltr',
}));
