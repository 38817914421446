import React, { useEffect, useState } from 'react';
import useTable from '../../../hooks/useTable';
import { Table, TableBody, TableContainer } from '@mui/material';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../components/table';
import WalletTableRow from '../../admin/user/edit/userTabs/cryptoWalletComponents/walletTableRow';
import Scrollbar from '../../../components/Scrollbar';
import FutureWalletAssetDetailsRow from './FutureWalletAssetDetailsRow';




const TABLE_HEAD = [
  { id: 'assets', label: 'Assets', align: 'left' },
  { id: 'totalAssets', label: 'Total Assets', align: 'center' },
  { id: 'positionMargin', label: 'Position Margin', align: 'center' },
  { id: 'orderMargin', label: 'Order Margin', align: 'center' },
  { id: 'unrealizedPNL', label: 'Unrealized PNL', align: 'center' },
  { id: 'availableBalance', label: 'Available Balance', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

const FutureWalletAssetDetails = ({userWallet , setShowModal}) => {
   const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });


  const [tableData, setTableData] = useState(
    [{ name:"USDT", symbol:"USDT", totalAssets:"1", positionMargin:"1", orderMargin:"1", unrealizedPNL:"1",
      availableBalance:"1" }]
  );

  useEffect(()=>{
    setTableData([{
      name:"USDT", symbol:"USDT",
     totalAssets:userWallet?.amount + userWallet?.blocked, positionMargin:userWallet?.positionMargin,
      orderMargin:userWallet?.orderMargin, unrealizedPNL:userWallet?.pnl, availableBalance:userWallet?.amount
    }])
  },[userWallet])
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800, position: 'relative', mt: 2 }}>
        <Table size='small'>
          <TableHeadCustom
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            // rowCount={filteredData?.length || 0}
            onSort={onSort}
          />
          <TableBody>
            {tableData === null ? (
              <TableLoading isLoading />
            ) : (
              <>
                {tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <FutureWalletAssetDetailsRow
                      key={index}
                      row={row}
                      index={index}
                      setShowModal={setShowModal}


                  />
                ))}
                <TableNoData isNotFound={!tableData?.length} />
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
};

export default FutureWalletAssetDetails;