import { useState } from 'react';
import { Card } from '@mui/material';

import StatusModal from '../../../../components/StatusModal';
import ChangeMobileForm from './changeMobileForm';
import OtpMobileForm from './otpMobileForm';

const AccountSettingChangeMobile = ({ isAdmin = true }) => {
  const [statusModal, setStatusModel] = useState(false);
  const [step, setStep] = useState(0);
  const [mobiles, setMobiles] = useState({ oldMobile: '', newMobile: '' });

  const onSuccess = (payload) => {
    setMobiles(payload);
    setStep(1);
  };

  const onFinished = () => {
    setStatusModel(true);
  };

  const onBack = () => {
    setStatusModel(false);
    setStep(0);
  };

  return (
    <>
      <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 } }}>
        {step === 0 && <ChangeMobileForm onSuccess={onSuccess} isAdmin={isAdmin} />}

        {step === 1 && <OtpMobileForm onFinished={onFinished} mobiles={mobiles} isAdmin={isAdmin} />}
      </Card>

      <StatusModal
        open={statusModal}
        onClose={() => setStatusModel(false)}
        delay={0}
        type='success'
        title='Successfully'
        subtitle='Mobile changed successfully'
        onAction={onBack}
      />
    </>
  );
};

export default AccountSettingChangeMobile;
