import { Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import Scrollbar from '../../../components/Scrollbar';
import SymbolIcon from '../../../components/SymbolIcon';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../components/table';
import Api from '../../../http/adminHttp/serviceApiAdmin';

const TABLE_HEAD = [
  // { id: 'currency', label: 'Currency', align: 'left' },
  // { id: 'nameCurrency', label: 'Name Currency', align: 'left' },
  // { id: 'buy', label: 'Buy', align: 'center' },
  // { id: 'sale', label: 'Sale', align: 'center' },
  { id: 'currency', label: 'Currency', align: 'left' },
  { id: 'symbol', label: 'symbol', align: 'center' },
  { id: 'lasPrice', label: 'Last Price', align: 'center' },
  { id: 'holding', label: 'Holding', align: 'center' },];

export default function CurrencyTable() {
  const [tableData, setTableData] = useState([]);

  // --------------------
  const getData = async () => {
    try {
      const response = await Api.getCurrencyStatistics();
      // setTableData([
      //   {
      //     currency: 'BTC',
      //     nameCurrency: 'Bitcoin',
      //     buy: response?.btcBuy || 0,
      //     sale: response?.btcSell || 0,
      //   },
      //   {
      //     currency: 'ETH',
      //     nameCurrency: 'Ethereum',
      //     buy: response?.ethBuy || 0,
      //     sale: response?.ethSell || 0,
      //   },
      //   {
      //     currency: 'USDT',
      //     nameCurrency: 'Tether',
      //     buy: response?.usdtBuy || 0,
      //     sale: response?.usdtSell || 0,
      //   },
      //   {
      //     currency: 'XRP',
      //     nameCurrency: 'XRP',
      //     buy: response?.xrpBuy || 0,
      //     sale: response?.xrpSell || 0,
      //   },
      // ]);
      const res = await Api.getDashboardListedTokens()
      setTableData(res?.listedTokensInfoList)

    } catch (e) {}
  };

  // ---------------------
  useEffect(() => {
    getData();
  }, []);

  // ---------------------
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
        <Table size='small'>
          <TableHeadCustom headLabel={TABLE_HEAD} />
          <TableBody>
            {tableData === null ? (
              <TableLoading isLoading />
            ) : (
              <>
                {tableData?.map((row, index) => (
                  <CurrencyTableRow key={index} row={row} />
                ))}

                <TableNoData isNotFound={!tableData?.length} />
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}

const CurrencyTableRow = ({ row }) => {
  const { symbol, name, lastPrice, holding } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1.5, typography: 'body2' } }}>
      <TableCell   align='left' >
        <Stack direction={'row'} alignItems={'center'} justifyContent={'start'}>
          <SymbolIcon symbol={symbol} width={40} height={40} />
          <Typography variant='body3' color={'grey.200'} fontWeight={500} sx={{ ml:2 }}>
            {name}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align='center'>{symbol}</TableCell>

      <TableCell align='center'>{lastPrice}</TableCell>
      <TableCell align='center'>{holding ? holding : 0}</TableCell>
      {/* <TableCell>{stock}</TableCell> */}
    </TableRow>
  );
};
