import React, { useEffect } from 'react';
import { Stack, Typography, Grid, IconButton, TextField } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import Iconify from '../../../../components/Iconify';
import CustomSwitch from '../../../../components/CustomSwitch';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import { useTranslation } from 'react-i18next';

const TransferCard = ({ data, transferList, setTransferList }) => {
  const { t } = useTranslation();
  const handleChangeInput = ({ target }, transfer) => {
    const index = transferList.findIndex((c) => c.id === transfer.id);

    const copyArray = [...transferList];
    const changedObject = copyArray[index];
    changedObject[target.name] = target.value;

    copyArray[index] = changedObject;
    setTransferList(copyArray);
  };

  const handleChangeStatus = (transfer) => {
    const index = transferList.findIndex((c) => c.id === transfer.id);

    const copyArray = [...transferList];
    const changeStatus = copyArray[index];
    changeStatus.active = !copyArray[index].active;

    copyArray[index] = changeStatus;
    setTransferList(copyArray);
  };

  const handleAddInput = () => {
    const copyArray = [...transferList];

    const newObject = {
      id: uuidv4(),
      active: true,
      name: '',
      isNew: true,
    };

    const newArray = copyArray.concat(newObject);
    setTransferList(newArray);
  };

  const handleDelete = async (transfer) => {
    if (!transfer.isNew) {
      const response = await Api.deleteDepositReceiptTransfer(transfer);
      if (response.status !== 'SUCCESS') {
        return false;
      }
    }

    let newArray = [...transferList];
    setTransferList(newArray.filter((t) => t.id !== transfer.id));
  };

  useEffect(() => {
    setTransferList(data);
  }, [data]);

  return (
    <Stack>
      <Typography sx={{ fontSize: '20px !important', fontWeight: '500' }}>{t('Transfer methods')}</Typography>
      <Stack direction={'column'}>
        <Grid container display='flex' flexDirection={'column'} sx={{ mt: 3, width: '100%' }}>
          {transferList.map((transfer) => (
            <Stack direction={'row'} alignItems='flex-end' sx={{ mt: 3 }} key={transfer.id}>
              <Grid item xs={7} md={3}>
                <TextField
                  label={t('Name')}
                  name={'name'}
                  variant='standard'
                  fullWidth
                  defaultValue={transfer.name}
                  onChange={(e) => handleChangeInput(e, transfer)}
                  disabled={!transfer.isNew}
                  sx={{
                    '& label.MuiFormLabel-root': {
                      color: 'grey.400',
                    },
                    '& .Mui-disabled': {
                      '& input': { WebkitTextFillColor: 'grey.550' },
                      '&:before': { borderBottomStyle: 'solid' },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={2} sx={{ ml: { xs: 3, sm: 8, md: 12.5 } }}>
                <Stack direction='row' alignItems='center' spacing={1}>
                  <CustomSwitch name='active' checked={transfer.active} onClick={() => handleChangeStatus(transfer)} />
                  <Typography variant='body3' color='grey.100' sx={{ display: { xs: 'none', sm: 'inline-block' } }}>
                    {transfer.active ? t('Active') : t('Disable')}
                  </Typography>
                </Stack>
              </Grid>

              <Grid
                item
                xs={1}
                md={2}
                sx={{ display: 'flex', justifyContent: { xs: 'flex-end', md: 'flex-start' }, ml: { xs: 2, sm: 0 } }}
              >
                <IconButton onClick={() => handleDelete(transfer)} sx={{ mb: -1 }}>
                  <Iconify icon='uil:trash-alt' color='grey.0' sx={{ width: 24, height: 24 }} />
                </IconButton>
              </Grid>
            </Stack>
          ))}
        </Grid>
        <IconButton onClick={handleAddInput} sx={{ mt: 3, mr: 'auto', borderRadius: 1 }}>
          <Iconify icon='eva:plus-outline' color='grey.0' sx={{ width: 12, height: 12 }} />
          <Typography variant='body3' sx={{ ml: 0.6 }}>
            {t('Add New')}
          </Typography>
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default TransferCard;
