import React from 'react';
import { Button, Divider, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import useDashboardCalculate from './hooks/useDashboardCalculate';

import Image from '../../../components/Image';
import Iconify from '../../../components/Iconify';
import { NumberField } from '../../../components/NumberField';
import { fiatList } from '../otc/otcTab';
import { useState } from 'react';
import SelectCryptoModal from '../../../components/selectCryptoModal/selectCryptoModal';
import SymbolIcon from '../../../components/SymbolIcon';

export default function ExchangeForm() {
  const { t } = useTranslation();

  const [showCryptoModal, setShowCryptoModal] = useState(false);

  const {
    side,
    receiveSymbol,
    setReceiveSymbol,
    spendSymbol,
    setSpendSymbol,
    receive,
    setReceive,
    spend,
    setSpend,
    handleSideChange,
    onSelectSymbol,
    onExchange,
  } = useDashboardCalculate();

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mt: 3 }}>
        <Typography variant='h6_secondary'>{t('Exchange')}</Typography>

        <IconButton onClick={handleSideChange}>
          <Iconify icon='charm:refresh' sx={{ width: 16, height: 16, color: 'grey.0' }} />
        </IconButton>
      </Stack>

      <Stack spacing={2} sx={{ mt: 2 }}>
        <Stack>
          <Typography variant='body3' color='grey.400'>
            {t('Spend')}
          </Typography>

          {side === 'buy' ? (
            <FiatInput amount={spend} value={spendSymbol} onChange={setSpendSymbol} onChangeAmount={setSpend} />
          ) : (
            <SymbolInput
              amount={spend}
              symbol={spendSymbol}
              openModal={() => setShowCryptoModal(true)}
              onChangeAmount={setSpend}
            />
          )}
        </Stack>

        <Stack>
          <Typography variant='body3' color='grey.400'>
            {t('Receive')}
          </Typography>

          {side === 'sell' ? (
            <FiatInput
              amount={receive}
              value={receiveSymbol}
              onChange={setReceiveSymbol}
              onChangeAmount={setReceive}
              disabled
            />
          ) : (
            <SymbolInput
              amount={receive}
              onChangeAmount={setReceive}
              symbol={receiveSymbol}
              openModal={() => setShowCryptoModal(true)}
              disabled
            />
          )}
        </Stack>
      </Stack>

      <LoadingButton fullWidth size='large' variant='contained' loading={false} onClick={onExchange} sx={{ mt: 3 }}>
        {t('Exchange')}
      </LoadingButton>

      <SelectCryptoModal open={showCryptoModal} onClose={() => setShowCryptoModal(false)} onSelect={onSelectSymbol} />
    </>
  );
}

const FiatInput = ({ amount, onChangeAmount, value, onChange, ...other }) => (
  <Stack
    direction={'row'}
    alignItems={'center'}
    sx={{ mt: 0.8, backgroundColor: 'black.100', borderRadius: 3, py: 1, px: 2, border: '1px solid black.200' }}
  >
    <NumberField
      variant='standard'
      value={amount}
      onChange={(event) => onChangeAmount(event.target.value)}
      fullWidth
      placeholder='0'
      thousandSeparator
      sx={{
        '& .MuiInput-root::before': {
          display: 'none',
        },
        '& .MuiInput-root::after': {
          display: 'none',
        },
        '& input': { color: 'grey.0', height: '1.55em' },
        '& .Mui-disabled': {
          '& input': { WebkitTextFillColor: 'grey.0 !important' },
          '&:before': { borderBottomStyle: 'solid !important' },
        },
      }}
      {...other}
    />
    <Divider orientation='vertical' variant='middle' flexItem />
    <TextField
      fullWidth
      select
      variant='standard'
      label=''
      value={value}
      onChange={(event) => onChange(event.target.value)}
      SelectProps={{
        sx: { pl: 1 },
        MenuProps: {
          sx: { '& .MuiPaper-root': { maxHeight: 260 } },
        },
      }}
      sx={{
        '& .MuiInput-root::before': {
          display: 'none',
        },
        '& .MuiInput-root::after': {
          display: 'none',
        },
        width: { xs: 140, md: 145 },
      }}
    >
      {fiatList.map((item, index) => (
        <MenuItem
          key={index}
          value={item.label}
          sx={{
            mx: 1,
            my: 0.5,
            borderRadius: 0.75,
          }}
        >
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Image src={item.icon} sx={{ width: 20 }} />

            <Typography variant='subtitle2_secondary'>{item.label}</Typography>
          </Stack>
        </MenuItem>
      ))}
    </TextField>
  </Stack>
);

const SymbolInput = ({ openModal, amount, onChangeAmount, symbol, ...other }) => (
  <Stack
    direction={'row'}
    alignItems={'center'}
    sx={{ mt: 0.8, backgroundColor: 'black.100', borderRadius: 3, py: 1, px: 2, border: '1px solid black.200' }}
  >
    <NumberField
      variant='standard'
      value={amount}
      onChange={(event) => onChangeAmount(event.target.value)}
      fullWidth
      placeholder='0'
      thousandSeparator
      sx={{
        '& .MuiInput-root::before': {
          display: 'none',
        },
        '& .MuiInput-root::after': {
          display: 'none',
        },
        '& input': {
          color: 'grey.100',
          height: '1.55em',
        },
        '& .Mui-disabled': { '& input': { WebkitTextFillColor: 'grey.0' }, '&:before': { borderBottomStyle: 'solid' } },
      }}
      {...other}
    />

    <Divider orientation='vertical' variant='middle' flexItem />

    <Button
      onClick={openModal}
      size='small'
      color='inherit'
      sx={{
        width: 140,
        justifyContent: 'flex-start',
        borderRadius: 0,
        '&:hover': {
          background: 'none',
        },
      }}
    >
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
        <Stack direction={'row'} spacing={1.2} alignItems={'center'} sx={{ ml: 0.5 }}>
          <SymbolIcon symbol={symbol} width={20} height={20} />
          <Typography variant='subtitle2_secondary'>{symbol}</Typography>
        </Stack>
        <Iconify icon={'eva:arrow-ios-downward-fill'} sx={{ width: 15, height: 15, mr: -0.7, color: 'grey.300' }} />
      </Stack>
    </Button>
  </Stack>
);
