import React from 'react';
import { Image, Button, Row, Col } from 'antd';
import classes from './interVerified.module.scss';
import verifiedIcon from '../../../../../assets/verified.svg';
import { useNavigate } from 'react-router';
import Api from '../../../../../http/serviceApi';
import { useDispatch } from 'react-redux';
import { SET_USERINFO } from '../../../../../store/actionTypes';
import { useState } from 'react';
import { navigationUrl } from '../../../../../navigationUrl';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTranslation } from 'react-i18next';

const InterVerified = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const colProps = {
    xs: 24,
    sm: 18,
    md: 13,
    lg: 11,
    xl: 8,
  };
  const colPropsBotton = {
    xs: 24,
    sm: 20,
    md: 20,
    lg: 20,
    xl: 20,
  };

  const getUserInfo = async () => {
    setLoading(true);
    const response = await Api.getUserInfo();
    if (response) {
      dispatch({
        type: SET_USERINFO,
        payload: {
          userInfo: response,
        },
      });
      navigate(navigationUrl.auth);
    }
    setLoading(false);
  };

  const onOkBtnClick = () => {
    getUserInfo();
  };
  const onUpgradeBtnClick = () => {
    getUserInfo();
  };

  return (
    <div className={classes.container}>
      <Row justify='center' style={{ marginLeft: '10px', marginRight: '10px' }}>
        <Col {...colProps}>
          <div className={classes.formBody}>
            <div className={classes.formTitleDiv}>
              <span className={classes.formTitle}>{t('Intermediate Verification')}</span>
            </div>

            <div className={classes.iconContainer}>
              <Image width={170} src={verifiedIcon} preview={false} />

              <span className={classes.iconTextBig}>{t('Waiting to be Verified')}</span>
              <span className={classes.iconTextSmall}>{t('Additional Infomation')}</span>
            </div>

            <Row justify='center' style={{ marginTop: '50px' }}>
              <Col {...colPropsBotton} style={{ marginBottom: '20px' }}>
                <Button type='primary' block shape='round' onClick={onOkBtnClick} loading={loading}>
                  {t('Ok')}
                </Button>
              </Col>
              <Col {...colPropsBotton}>
                <Button type='default' block shape='round' onClick={onUpgradeBtnClick} loading={loading}>
                  {t('Upgrade to Higher Limits')}
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InterVerified;
