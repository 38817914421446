import { Box, Button, Card, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import Iconify from '../../../../components/Iconify';
import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../components/table';
import useTable from '../../../../hooks/useTable';
import Api from '../../../../http/serviceApi';
import ApiAdmin from '../../../../http/adminHttp/serviceApiAdmin';
import { navigationUrl } from '../../../../navigationUrl';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import TicketTableRow from './ticketTableRow';
import TicketListToolbar from './ticketListToolbar';
import TicketStatistic from './ticketStatistic';
import { getDefaultDateFilterValues } from '../../../../utils/filter';
import moment from 'jalali-moment';

export default function TicketList({ isAdmin = true }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Ticket' },
    });
  }, []);

  const TABLE_HEAD = [
    { id: 'id', label: '#', align: 'center' },
    ...(isAdmin ? [{ id: 'name', label: 'Name', align: 'left' }] : []),
    { id: 'subject', label: 'Subject', align: 'left' },
    { id: 'section', label: 'Section', align: 'left' },
    { id: 'status', label: 'Status', align: 'center' },
    { id: 'createdDate', label: 'Created At', align: 'left' },
    { id: 'updatedDate', label: 'Updated At', align: 'left' },
    { id: 'score', label: <Iconify icon={'eva:star-outline'} width={20} height={20} />, align: 'center' },
    { id: 'actions', label: '', align: 'right' },
  ];

  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });

  const [statusFilter, setStatusFilter] = useState('');
  const [idFilter, setIdFilter] = useState('');
  const [subjectFilter, setSubjectFilter] = useState('');
  const [firstNameFilter, setFirstNameFilter] = useState('');
  const [lastNameFilter, setLastNameFilter] = useState('');
  const [dateFromFilter, setDateFromFilter] = useState(getDefaultDateFilterValues().firstDay);
  const [dateToFilter, setDateToFilter] = useState(getDefaultDateFilterValues().lastDay);

  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  const getList = async (withFilter = true) => {
    try {
      let response;
      if (isAdmin) {
        const filters = { filter: 'true' };
        if (withFilter) {
          if (statusFilter) filters.ticketStatus = statusFilter;
          if (idFilter) filters.ticketId = idFilter;
          if (subjectFilter) filters.subject = subjectFilter;
          if (firstNameFilter) filters.firstName = firstNameFilter;
          if (lastNameFilter) filters.lastName = lastNameFilter;
          if (dateFromFilter) filters.date =  new Date(dateFromFilter).getTime().valueOf()
          if (dateToFilter) filters.date2 =  new Date(dateToFilter).getTime().valueOf()
        }

        response = await ApiAdmin.getAllTickets(page, rowsPerPage, filters);
      } else {
        response = await Api.getTickets(page, rowsPerPage);
      }

      setTableData(response.ticketInfoList);
      setTablePagination({ totalItems: response.ticketInfoListPages * rowsPerPage });
    } catch (e) {
      setTableData([]);
    }
  };

  useEffect(() => {
    getList();
  }, [page, rowsPerPage]);

  return (
    <Box sx={{ mx: { xs: 2, lg: 0 } }}>
      {isAdmin && <TicketStatistic />}

      {!isAdmin && (
        <Button
          variant='contained'
          onClick={() => navigate(isAdmin ? navigationUrl.admin_ticket_new : navigationUrl.ticket_new)}
          size='large'
          sx={{
            typography: 'body3',
            fontWeight: 500,
            width: 160,
            mt: 2.5,
            mb: 3.5,
          }}
        >
          {t('Add Ticket')}
        </Button>
      )}

      <Card>
        {isAdmin && (
          <TicketListToolbar
            onFilter={getList}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            idFilter={idFilter}
            setIdFilter={setIdFilter}
            subjectFilter={subjectFilter}
            setSubjectFilter={setSubjectFilter}
            firstNameFilter={firstNameFilter}
            setFirstNameFilter={setFirstNameFilter}
            lastNameFilter={lastNameFilter}
            setLastNameFilter={setLastNameFilter}
            dateFromFilter={dateFromFilter}
            setDateFromFilter={setDateFromFilter}
            dateToFilter={dateToFilter}
            setDateToFilter={setDateToFilter}
          />
        )}

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size='small'>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length || 0}
                onSort={onSort}
              />
              <TableBody>
                {tableData === null ? (
                  <TableLoading isLoading />
                ) : (
                  <>
                    {tableData?.map((row, index) => (
                      <TicketTableRow key={row.id} row={row} index={index} isAdmin={isAdmin} onReload={getList} />
                    ))}

                    <TableNoData isNotFound={!tableData?.length} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            component='div'
            count={tablePagination.totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
          />
        </Box>
      </Card>
    </Box>
  );
}
