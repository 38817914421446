import { alpha, Card, Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import Image from '../../../../components/Image';
import ApiAdmin from '../../../../http/adminHttp/serviceApiAdmin';

import allTickets from '../../../../assets/ticket-2.svg';
import pendingTicket from '../../../../assets/ticket.svg';
import openTicket from '../../../../assets/ticket-expired.svg';
import totalNumbers from '../../../../assets/sms-tracking.svg';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function TicketStatistic() {
  const { t } = useTranslation();
  const lastUpdateTicket = useSelector((state) => state.lastUpdateTicket);

  const [statistic, setStatistic] = useState([
    { value: '...', label: 'All tickets', image: allTickets, color: '#11CABE' },
    { value: '...', label: 'Open tickets ', image: openTicket, color: '#BD47FB' },
    { value: '...', label: 'Pending tickets', image: pendingTicket, color: '#F27F16' },
    { value: '...', label: 'Total messages', image: totalNumbers, color: '#30E0A1' },
  ]);

  const getData = async () => {
    try {
      const res = await ApiAdmin.getTicketStatistics();

      setStatistic([
        { value: res.allCount, label: 'All tickets', image: allTickets, color: '#11CABE' },
        { value: res.openTicketCount, label: 'Open tickets ', image: openTicket, color: '#BD47FB' },
        { value: res.pendingTicketCount, label: 'Pending tickets', image: pendingTicket, color: '#F27F16' },
        { value: res.ticketMessageCount, label: 'Total messages', image: totalNumbers, color: '#30E0A1' },
      ]);
    } catch (e) {}
  };

  useEffect(() => {
    getData();
  }, [lastUpdateTicket]);

  return (
    <Grid container spacing={2.5} sx={{ mb: 2 }}>
      {statistic?.map((item, index) => (
        <Grid key={index} item xs={12} sm={6} md={3}>
          <Card sx={{ display: 'flex' }}>
            <Stack
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ backgroundColor: alpha(item.color, 0.1), borderRadius: '100%', width: 48, height: 48, mr: 2 }}
            >
              <Image src={item.image} />
            </Stack>
            <Stack spacing={'0.25'}>
              <Typography variant='h6' fontWeight={500}>
                {item.value}
              </Typography>
              <Typography variant='body3' color={'grey.100'}>
                {t(item.label)}
              </Typography>
            </Stack>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
