import { Box, Button, Stack, Table, TableBody, TableContainer, TablePagination, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'jalali-moment';

import DatePicker from '../../../../../../components/DatePicker';
import Scrollbar from '../../../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../../../components/table';
import useTable from '../../../../../../hooks/useTable';
import Api from '../../../../../../http/adminHttp/serviceApiAdmin';
import AdminLogTableRow from './adminLogTableRow';

const TABLE_HEAD = [
  { id: 'num', label: 'Num', align: 'center' },
  { id: 'description', label: 'Description', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
];

export default function AdminLogTab({ userId }) {
  const { t } = useTranslation();

  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });

  const [dateFromFilter, setDateFromFilter] = useState(null);
  const [dateToFilter, setDateToFilter] = useState(null);

  // ----------------------------------------------
  const getData = async () => {
    try {
      const filters = { userId };

      if (dateFromFilter && dateToFilter) {
        filters.startTime = moment(dateFromFilter).startOf('days').valueOf();
        filters.endTime = moment(dateToFilter).endOf('days').valueOf();
      }

      const response = await Api.getAdminLogs(page, rowsPerPage, filters);
      if (response) {
        setTableData(response.adminLogInfos);
        setTablePagination({ totalItems: response.adminLogInfosTotalPages * rowsPerPage });
      }
    } catch (e) {
      setTableData([]);
    }
  };

  // ----------------------------------------------
  useEffect(() => {
    if (userId) getData();
  }, [page, rowsPerPage, userId]);

  return (
    <>
      <Typography variant='h6' fontWeight={500} sx={{ mb: 5, mt: 5 }}>
        {t('Log')}
      </Typography>

      <Stack direction={'row'}>
        <DatePicker
          label={t('From')}
          value={dateFromFilter}
          setValue={setDateFromFilter}
          variant='standard'
          maxDate={dateToFilter}
          fullWidth
          sx={{ maxWidth: 150 }}
          InputProps={null}
        />
        <DatePicker
          label={t('To')}
          value={dateToFilter}
          setValue={setDateToFilter}
          variant='standard'
          minDate={dateFromFilter}
          fullWidth
          sx={{ maxWidth: 150 }}
        />
      </Stack>

      <Button
        variant='contained'
        fullWidth
        sx={{
          typography: 'body3',
          fontWeight: 500,
          maxWidth: 200,
          my: 5,
        }}
        onClick={getData}
      >
        {t('Filter')}
      </Button>

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          <Table size='small'>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length || 0}
              onSort={onSort}
            />
            <TableBody>
              {tableData === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {tableData?.map((row, index) => (
                    <AdminLogTableRow key={index} row={row} index={index} />
                  ))}

                  <TableNoData isNotFound={!tableData?.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      {tableData?.length > 0 && (
        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            component='div'
            count={tablePagination.totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
          />
        </Box>
      )}
    </>
  );
}
