import { Box, Button, Stack, styled } from '@mui/material';
import { WALLET_CHART_HEIGHT } from './roundChart';

export const PeriodButton = styled(Button)(({ selected, theme }) => ({
  width: 70,
  height: 30,
  borderRadius: 27,
  ...(selected && { backgroundColor: theme.palette.background.default, '& span': { color: theme.palette.grey[0] } }),
}));

export const BadgeIcon = styled(Box)(({ bgColor, direction = 'right', theme }) => ({
  width: 20,
  height: 20,
  borderRadius: direction === 'right' ? theme.spacing(10, 10, 0, 10) : theme.spacing(10, 10, 10, 0),
  backgroundColor: bgColor,
  ...(direction === 'left' && { marginLeft: 8 }),
  ...(direction === 'right' && { marginRight: 8 }),
}));

export const ChartBorder = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 'calc(50% + 1px)',
  left: theme.direction === 'rtl' ? 'calc(50% - 1.5px)' : 'calc(50% + 2px)',
  width: 113,
  height: 113,
  transform: 'translate(-50%, -50%)',
  borderRadius: '100%',
  border: `2px dashed ${theme.palette.grey[600]}`,
}));

export const LightChartBorder = styled(ChartBorder)(({ theme }) => ({
  border: `2px dashed ${theme.palette.grey[400]}`,
  width: 140,
  height: 140,
}));

export const ChartBackground = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 'calc(50% + 1px)',
  left: theme.direction === 'rtl' ? 'calc(50% - 1.5px)' : 'calc(50% + 2px)',
  width: 100,
  height: 100,
  transform: 'translate(-50%, -50%)',
  borderRadius: '100%',
  backgroundColor: theme.palette.grey[700],
}));
