import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, Card, Typography, Switch, Button, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import SymbolIcon from '../../../../components/SymbolIcon';
import { NumberField } from '../../../../components/NumberField';
import Iconify from '../../../../components/Iconify';
import ActiveCryptoModal from '../../../../components/activeCryptoModal/activeCryptoModal';

export default function FuturesSetting() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // -------------------
  const [list, setList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openSymbolModal, setOpenSymbolModal] = useState(false);

  // -------------------
  const fetchList = async () => {
    try {
      const response = await Api.getFuturesSymbols();
      setList(response.symbolInfos.map((item) => ({ ...item, isNew: false })));
    } catch (e) {}
  };

  // -------------------
  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Futures settings' },
    });

    fetchList();
  }, []);

  // -------------------
  const onSelectSymbol = (newSymbol) => {
    if (list.find((item) => item.symbol === newSymbol)) {
      return enqueueSnackbar(t('This symbol already added!'), { variant: 'warning' });
    }

    setList((prev) => [
      ...prev,
      {
        symbol: newSymbol,
        maxBorrow: '',
        active: true,
        isNew: true,
      },
    ]);
  };

  // -------------------
  const onChangeMaxBorrow = (symbol, newValue) => {
    const newList = [...list];
    const foundIndex = newList.findIndex((item) => item.symbol === symbol);
    if (foundIndex !== -1) {
      newList[foundIndex].maxBorrow = newValue;
    }
    setList(newList);
  };

  // -------------------
  const onChangeActive = (symbol) => {
    const newList = [...list];
    const foundIndex = newList.findIndex((item) => item.symbol === symbol);
    if (foundIndex !== -1) {
      newList[foundIndex].active = !newList[foundIndex].active;
    }
    setList(newList);
  };

  // -------------------
  const onDelete = (symbol) => {
    const newList = [...list];
    setList(newList.filter((s) => s.symbol !== symbol));
  };

  // -------------------
  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      const response = await Api.addFuturesSymbols(list);
      if (response.status === 'SUCCESS') {
        fetchList();
        enqueueSnackbar(t('Updated successfully!'), { variant: 'success' });
      }
    } catch (e) {}

    setIsSubmitting(false);
  };

  // -------------------
  return (
    <Stack sx={{ px: { xs: '25px', lg: 0 } }}>
      <Card sx={{ m: '40px 0 12px 0', px: { xs: 2, sm: 4 }, py: { xs: 3, sm: 4 } }}>
        <Stack spacing={2.5}>
          {list.map((item) => (
            <Stack direction='row' alignItems='center' key={item.symbol}>
              <SymbolIcon symbol={item.symbol} sx={{ width: 24, height: 24 }} />
              <Typography sx={{ ml: 1, width: { xs: 40, sm: 150 } }}>{item.symbol}</Typography>

              <Stack direction='row' alignItems='center'>
                <Typography variant='caption' sx={{ display: { xs: 'none', sm: 'inline-block' } }}>
                  {t('Disabled')}
                </Typography>
                <Switch checked={item.active} onChange={() => onChangeActive(item.symbol)} />
                <Typography variant='caption' sx={{ display: { xs: 'none', sm: 'inline-block' } }}>
                  {t('Enabled')}
                </Typography>
              </Stack>

              <NumberField
                label={t('Max Borrow')}
                size='small'
                sx={{ ml: 6, width: { xs: 100, sm: 150 }, '& input': { py: 0.9 } }}
                value={item.maxBorrow}
                onChange={(e) => onChangeMaxBorrow(item.symbol, e.target.value)}
              />

              {item.isNew && (
                <IconButton sx={{ ml: { xs: 0, sm: 3 } }} onClick={() => onDelete(item.symbol)}>
                  <Iconify icon='eva:trash-2-outline' sx={{ width: 20, height: 20, opacity: 0.5 }} />
                </IconButton>
              )}
            </Stack>
          ))}
        </Stack>

        <Button
          variant='outlined'
          size='large'
          sx={{ height: 40, mt: 6 }}
          startIcon={<Iconify icon='eva:plus-outline' />}
          onClick={() => setOpenSymbolModal(true)}
        >
          {t('Add more coins')}
        </Button>
      </Card>

      <LoadingButton
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        loading={isSubmitting}
        onClick={onSubmit}
        sx={{ maxWidth: 250, mt: 2 }}
      >
        {t('Save')}
      </LoadingButton>

      <ActiveCryptoModal
        open={openSymbolModal}
        onClose={() => setOpenSymbolModal(false)}
        onSelect={onSelectSymbol}
        title='Select Asset'
      />
    </Stack>
  );
}
