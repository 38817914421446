import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from './theme';
import ThemeRtlLayout from './theme/ThemeRtlLayout';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import store from './store';

// import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/stable';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// scroll bar
import 'simplebar/src/simplebar.css';

import './configs/i18n';

// document.addEventListener('contextmenu', (event) => event.preventDefault());
// document.addEventListener('keydown', function (e) {
//   console.log(e.key, e.code);

//   if (e.code === 'F12' || (e.ctrlKey && e.shiftKey && e.code === 'KeyI')) {
//     e.preventDefault();
//   }
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeRtlLayout>
            <NotistackProvider>
              <App />
            </NotistackProvider>
          </ThemeRtlLayout>
        </ThemeProvider>
      </MotionLazyContainer>
    </LocalizationProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
