import ModalContainer from '../../../../components/ModalContainer';
import { TokenForm } from '../../../../components/adminTokens/TokenForm';

export function EditTokenModal({ open, onClose, data }) {
  return (
    <ModalContainer open={open} onClose={onClose} title={'Edit token'} width={'90vw'}>
      <TokenForm editData={data}></TokenForm>
    </ModalContainer>
  );
}
