import React from 'react';
import { TableRow, TableCell, IconButton, Typography, Stack } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import Iconify from '../../../../components/Iconify';
import Image from '../../../../components/Image';

import USD from '../../../../assets/logo/dolar.svg';
import IRR from '../../../../assets/logo/IRR.svg';
import { base64ToImage } from '../../../../utils';
import { useTranslation } from 'react-i18next';

const WaitingDepositFiatTableRow = ({ row, onShowModalDetail }) => {
  const language = useSelector((store) => store.language);
  const { t } = useTranslation();

  const { emailAddress, mobile, firstName, lastName, createDate, fiatType, trackingCode, receipt, amount } = row;

  const openImage = () => {
    var base64Image = base64ToImage(receipt);

    // open new window and display image
    var newWindow = window.open();
    var img = document.createElement('img');
    img.src = base64Image;
    newWindow.document.body.appendChild(img);
  };

  return (
    <TableRow hover sx={{ '& td': { py: 1.5, typography: 'body2' } }}>
      <TableCell>{moment(createDate).locale(language).format('YYYY-MM-DD HH:mm')}</TableCell>

      <TableCell>
        <Typography variant='body3'>
          {firstName} {lastName}
        </Typography>
        {emailAddress && (
          <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
            {emailAddress}
          </Typography>
        )}
        {mobile && (
          <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
            {mobile}
          </Typography>
        )}
      </TableCell>

      <TableCell align='center'>{amount}</TableCell>

      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ width: 80 }}>
          <Image src={fiatType === 'USD' ? USD : IRR} disabledEffect />
          <Typography variant='body3' noWrap sx={{ color: 'grey.100' }}>
            {fiatType}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>{trackingCode || '-'}</TableCell>

      <TableCell>
        {receipt ? (
          <Stack direction='row' alignItems={'center'} spacing={1} sx={{ cursor: 'pointer' }} onClick={openImage}>
            <Iconify icon={'fluent:attach-24-regular'} color='grey.0' sx={{ width: 17, height: 17 }} />
            <Typography variant='body3' sx={{ '&:hover': { textDecoration: 'underline' } }}>
              {t('Receipt File')}
            </Typography>
          </Stack>
        ) : (
          '-'
        )}
      </TableCell>

      <TableCell align='center' sx={{ display: 'flex', justifyContent: { xs: 'space-between', lg: 'center' } }}>
        <IconButton onClick={onShowModalDetail} sx={{ mr: { xs: 0, lg: 1 } }}>
          <Iconify icon='ph:link-duotone' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default WaitingDepositFiatTableRow;
