import React from 'react';
import { useDispatch } from 'react-redux';
import { Stack, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { FormProvider } from '../../../../components/hookForm';
import useCommissionForm from './hooks/useCommissionForm';
import useCommissionSubmit from './hooks/useCommissionSubmit';
import { PageTitle } from '../../../../components/PageTitle';
import imgBinance from '../../../../assets/inventories/binance.png';
import imgPaypal from '../../../../assets/inventories/paypal.png';
import imgPerfectMoney from '../../../../assets/inventories/perfectMoney.png';
import imgPaySafeCard from '../../../../assets/inventories/paySafeCard.png';
import CommissionRow from './commissionRow';
import { useEffect } from 'react';

//=====================================================

const COMMISSION_MODELS = [
  { id: '1', name: 'Binance', percentage: '0.0001', symbol: imgBinance },
  { id: '2', name: 'Perfect Money', percentage: '0.0001', symbol: imgPerfectMoney },
  { id: '3', name: 'Paypel', percentage: '0.0001', symbol: imgPaypal },
  { id: '4', name: 'Pay Safe', percentage: '0.0001', symbol: imgPaySafeCard },
];

//=====================================================

const CommissionSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { handleSubmit, isSubmitting, methods } = useCommissionForm();
  const { onSubmit } = useCommissionSubmit();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Settings' },
    });
  }, []);

  return (
    <Stack sx={{ padding: { xs: '0 25px', lg: '0' } }}>
      <Card sx={{ m: '40px 0 12px 0', px: { xs: 2, sm: 4 }, py: { xs: 3, sm: 4 }, mt: 5 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <PageTitle title={t('Commission settings')} />

          <Stack sx={{ mt: 5.3 }} spacing={3.75}>
            {COMMISSION_MODELS.map((commission, index) => (
              <CommissionRow data={commission} key={commission.id || index} />
            ))}
          </Stack>

          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            loading={isSubmitting}
            sx={{ mt: 15, maxWidth: 283 }}
          >
            {t('Save')}
          </LoadingButton>
        </FormProvider>
      </Card>
    </Stack>
  );
};

export default CommissionSettings;
