import { Stack, Card, Box, TablePagination, TableBody, TableContainer, Table } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../../../../components/PageTitle';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import WaitingUserToolbar from './waitingUserToolbar';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import WaitingUserTableRow from './waitingUserTableRow';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../components/table';
import Scrollbar from '../../../../components/Scrollbar';
import useTable from '../../../../hooks/useTable';
import confirmWrapper from '../../../../utils/confirm';
import { useSnackbar } from 'notistack';
import { exportExcel } from '../../../../utils/excel';
import { prettyUppercases } from '../../../../utils';
import { exportPDF } from '../../../../utils/pdf';

// ===================================================================

const TABLE_HEAD = [
  { id: 'num', label: 'Num', align: 'center' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'mobile', label: 'Mobile', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'amount', label: 'Balance', align: 'center' },
  { id: 'condition', label: 'Condition', align: 'center' },
  { id: 'level', label: 'Level', align: 'center' },
  { id: 'actions', label: 'Edit / Delete', align: 'center' },
];

// ===================================================================

const WaitingUser = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  // --------------------
  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Users waiting for Verify' },
    });
  }, []);

  // --------------------
  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });

  // --------------------
  const [userIdFilter, setUserIdFilter] = useState(null);
  const [nameFilter, setNameFilter] = useState('');
  const [dateFromFilter, setDateFromFilter] = useState(null);
  const [dateToFilter, setDateToFilter] = useState(null);

  // --------------------
  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  // --------------------
  const getAllUser = async (withFilter = true) => {
    const filters = {};

    if (withFilter) {
      if (dateFromFilter && dateToFilter) {
        filters.from = moment(dateFromFilter).startOf('days').valueOf();
        filters.to = moment(dateToFilter).endOf('days').valueOf();
      }
      filters.name = nameFilter;
      filters.userId = userIdFilter;
    }

    try {
      const response = await Api.getAllWaitingUser(page, rowsPerPage, filters);
      if (response) {
        setTableData(response.adminUserInfoList);
        setTablePagination({ totalItems: response.totalPage * rowsPerPage });
      }
    } catch (e) {}
  };

  // --------------------
  useEffect(() => {
    getAllUser();
  }, [page, rowsPerPage]);

  // --------------------
  const handleDeleteUser = async (userId, firstName, lastName) => {
    if (!(await confirmWrapper(t(`Are you sure to remove this user from users?`)))) {
      return false;
    }
    try {
      const response = await Api.deleteUserByAdmin(userId);
      if (response?.status === 'SUCCESS') {
        getAllUser();
        enqueueSnackbar(t('The user account is deleted successfuly'), { variant: 'success' });
      }
    } catch (e) {}
    return true;
  };

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);

  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    const filters = {};
    if (dateFromFilter && dateToFilter) {
      filters.from = moment(dateFromFilter).startOf('days').valueOf();
      filters.to = moment(dateToFilter).endOf('days').valueOf();
    }
    filters.name = nameFilter;

    try {
      const response = await Api.getAllWaitingUser(0, 10000000, filters);

      // generate excel and download
      const config = {
        language,
        fileName: t('Users waiting for Verify'),
        heads: ['#', t('Name'), t('Mobile'), t('Date'), t('Balance'), t('Condition'), t('Level')],
        columnsWidth: [5, 26, 15, 23, 10, 15, 26],
        list: response.adminUserInfoList.map((item) => [
          `${item.firstName} ${item.lastName}`,
          '-',
          moment(item.registeredDate).locale(language).format('D MMM YYYY - HH:mm'),
          `${item.amount}`,
          `${t(item.condition)}`,
          `${t(prettyUppercases(item.authenticationLevel))}`,
        ]),
      };

      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  // --------------------
  return (
    <Stack sx={{ padding: { xs: '0 20px 20px', lg: '0 0 97px' }, mt: 0.5 }}>
      <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 } }}>
        <PageTitle
          title='Users waiting for Verify'
          subTitle='You can see the list of users whose information must be confirmed.'
        />

        <WaitingUserToolbar
          onFilter={getAllUser}
          userIdFilter={userIdFilter}
          setUserIdFilter={setUserIdFilter}
          nameFilter={nameFilter}
          setNameFilter={setNameFilter}
          dateFromFilter={dateFromFilter}
          setDateFromFilter={setDateFromFilter}
          dateToFilter={dateToFilter}
          setDateToFilter={setDateToFilter}
          onExport={onExport}
          isExportLoading={isExportLoading}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size='small'>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length || 0}
                onSort={onSort}
              />

              <TableBody>
                {tableData === null ? (
                  <TableLoading isLoading />
                ) : (
                  <>
                    {tableData?.map((row, index) => (
                      <WaitingUserTableRow
                        key={row.userId}
                        row={row}
                        index={index}
                        handleDeleteUser={handleDeleteUser}
                      />
                    ))}

                    <TableNoData isNotFound={!tableData?.length} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        {tableData?.length > 0 && (
          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              component='div'
              count={tablePagination.totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              labelRowsPerPage={t('Rows per page')}
              labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
            />
          </Box>
        )}
      </Card>
    </Stack>
  );
};

export default WaitingUser;
