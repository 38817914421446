import { useEffect, useState } from 'react';
import moment from 'jalali-moment';

import { getDefaultDateFilterValues } from '../../../../../../../utils/filter';
import Api from '../../../../../../../http/adminHttp/serviceApiAdmin';
import { exportExcel } from '../../../../../../../utils/excel';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { prettyUppercases } from '../../../../../../../utils';
import { exportPDF } from '../../../../../../../utils/pdf';

export default function useFiatTransactionInit(page, rowsPerPage, userId) {
  const [typeFilter, setTypeFilter] = useState('ALL');
  const [fiatStatusFilter, setFiatStatusFilter] = useState('ALL');
  const [depositStatusFilter, setDepositStatusFilter] = useState('ALL');
  const [withdrawStatusFilter, setWithdrawStatusFilter] = useState('All');
  const [dateFromFilter, setDateFromFilter] = useState(getDefaultDateFilterValues().firstDay);
  const [dateToFilter, setDateToFilter] = useState(getDefaultDateFilterValues().lastDay);
  const [transactionIdFilter, setTransactionIdFilter] = useState('');
  const [amountFilter, setAmountFilter] = useState([0, 100000]);

  // --------------------------

  const language = useSelector((store) => store.language);
  const { t } = useTranslation();

  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });

  // --------------------------
  const fetchTableData = async () => {
    try {
      const filters = {
        userId,
        type: 'Fiat',
        fiatType: 'ALL',
        allTransaction: 'true',
        endTime: dateToFilter?.valueOf?.(),
        // startTime: moment(dateFromFilter).valueOf(),
        startTime: dateFromFilter?.valueOf?.() ,
        fiatStatus: fiatStatusFilter,
        depositStatus: depositStatusFilter,
        withdrawStatus: withdrawStatusFilter,
        amount: amountFilter[0],
        amount2: amountFilter[1],
        ...(transactionIdFilter && { transactionId: transactionIdFilter }),
      };

      const response = await Api.getUserTransactions(page, rowsPerPage, filters);

      if (response.status === 'SUCCESS') {
        if (typeFilter === 'deposit') {
          setTableData(response.fiatDepositInfos);
          setTablePagination({ totalItems: response.fiatDepositInfosTotalPages * rowsPerPage });
        } else if (typeFilter === 'withdraw') {
          setTableData(response.fiatWithdrawsInfos ? response.fiatWithdrawsInfos : []);
          setTablePagination({
            totalItems: response.fiatWithdrawsInfosTotalPages ? response.fiatWithdrawsInfosTotalPages * rowsPerPage : 0,
          });
        } else {
          setTableData([]);
        }
      }
    } catch (e) {
      setTableData([]);
    }
  };

  // --------------------------
  const handleFilter = () => {
    fetchTableData();
  };

  // --------------------------
  const handleResetFilters = () => {
    setFiatStatusFilter('SUCCESS');
    setDepositStatusFilter('SUCCESS');
    setWithdrawStatusFilter('Completed');
    setDateFromFilter(getDefaultDateFilterValues().firstDay);
    setDateToFilter(getDefaultDateFilterValues().lastDay);
    setTypeFilter('deposit');
    setTransactionIdFilter('');
  };

  // --------------------------
  useEffect(() => {
    if (userId) fetchTableData();
  }, [page, rowsPerPage, userId]);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);

  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    const filters = {
      userId,
      type: 'Fiat',
      fiatType: 'ALL',
      allTransaction: 'true',
      endTime: moment(dateToFilter).valueOf(),
      startTime: moment(dateFromFilter).valueOf(),
      fiatStatus: fiatStatusFilter,
      depositStatus: depositStatusFilter,
      withdrawStatus: withdrawStatusFilter,
      amount: amountFilter[0],
      amount2: amountFilter[1],
      ...(transactionIdFilter && { transactionId: transactionIdFilter }),
    };

    try {
      const response = await Api.getUserTransactions(0, 10000000, filters);

      let result = [];
      if (typeFilter === 'deposit') {
        result = response.fiatDepositInfos;
      } else if (typeFilter === 'withdraw') {
        result = response.fiatWithdrawsInfos;
      }

      // generate excel and download
      const config = {
        language,
        fileName: t('User - Fiat Transactions'),
        heads: ['#', t('Deposit Id'), t('Amount'), t('Create Date'), t('Status'), t('Transaction Type')],
        columnsWidth: [5, 11, 8, 23, 14, 17],
        list: result.map((item) => [
          `${t(item.depositId)}`,
          `${Number(item.amount).toLocaleString('en-US')}`,
          moment(item.createDate).locale(language).format('D MMM YYYY - HH:mm'),
          `${t(prettyUppercases(item.status))}`,
          `${t(prettyUppercases(item.transactionType))}`,
        ]),
      };

      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };
  // --------------------------
  return {
    fiatStatusFilter,
    setFiatStatusFilter,
    depositStatusFilter,
    amountFilter,
    setAmountFilter,
    setDepositStatusFilter,
    withdrawStatusFilter,
    setWithdrawStatusFilter,
    dateFromFilter,
    setDateFromFilter,
    dateToFilter,
    setDateToFilter,
    typeFilter,
    setTypeFilter,
    transactionIdFilter,
    setTransactionIdFilter,
    handleFilter,
    handleResetFilters,
    tableData,
    tablePagination,
    isExportLoading,
    onExport,
  };
}
