import { TableRow, TableCell, Typography, IconButton, Box } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import Iconify from '../../../../components/Iconify';
import Image from '../../../../components/Image';
import paySafe from '../../../../assets/logo/paysef.svg';
import { prettyUppercases } from '../../../../utils';

// ----------------------------------------------------------------------

export default function PaysafeHistoryTableRow({ row, onShowModalDetail, isAdmin = false }) {
  const language = useSelector((store) => store.language);

  const {
    createdDate,
    firstName,
    lastName,
    emailAddress,
    mobile,
    fiatAmount,
    fiatType,
    paysafeAmount,
    paysafePrice,
    depositType,
    voucherCurrency,
    side
  } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 2, typography: 'body2', color: 'grey.100' } }}>
      <TableCell>{moment(createdDate).locale(language).format('YYYY-MM-DD HH:mm')}</TableCell>

      {isAdmin && (
        <TableCell>
          <Typography variant='body3'>
            {firstName} {lastName}
          </Typography>
          {emailAddress && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {emailAddress}
            </Typography>
          )}
          {mobile && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {mobile}
            </Typography>
          )}
        </TableCell>
      )}

      <TableCell align='center'>
        <Image src={paySafe} sx={{ width: 30, height: 30, m: 'auto' }} />
      </TableCell>

      <TableCell align='center'>
        {fiatAmount} {fiatType}
      </TableCell>

      <TableCell align='center'>{paysafePrice}</TableCell>

      <TableCell align='center'>{paysafeAmount}</TableCell>

      <TableCell align='center'>{depositType}</TableCell>

      <TableCell align='center'>{voucherCurrency}</TableCell>
      <TableCell sx={{ width: 120 }} align='center'>
        <Box
          sx={{
            width: 100,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            p: 1,
            bgcolor:
              (side?.toLowerCase() === 'buy' && 'rgba(48, 224, 161, 0.1)') ||
              (side?.toLowerCase() === 'sell' && 'rgba(224, 48, 48, 0.1)') ||
              'rgba(250, 174, 34, 0.1)',
          }}
        >
          <Typography
            variant='body3'
            sx={{
              color:
                (side?.toLowerCase() === 'buy' && '#30E0A1') ||
                (side?.toLowerCase() === 'sell' && 'negative.main') ||
                'rgb(218, 146, 13)',
              lineHeight: 1,
            }}
          >
            {side}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align='center' sx={{ width: 100 }}>
        <IconButton onClick={onShowModalDetail}>
          <Iconify icon='ph:link-duotone' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
