import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import AdminForm from '../adminForm/adminForm';

export default function AddAdmin() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'New Admin' },
    });
  }, []);

  return <AdminForm data={null} />;
}
