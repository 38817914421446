import { api } from './http';
import { Log } from './serviceLog';
import navigationService from '../utils/navigation';
import { navigationUrl } from '../navigationUrl';
import { removeToken } from './tokenHandler';

export const interceptError = () => {
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // const originalRequest = error.config;

      // if (isNetworkError(error)) {
      //   return new Promise((resolve) => {
      //     if (originalRequest) {
      //       setTimeout(() => {
      //         resolve(api(originalRequest));
      //       }, 5000);
      //     }
      //   });
      // }

      if (error?.response?.status === 401) {
        removeToken();

        if (navigationService.location?.pathname !== '/' && navigationService.navigate) {
          navigationService.navigate(navigationUrl.login);
        }

        console.log(error.response.data);
        return false;
      } else if (error?.code === 'ERR_NETWORK') {
        // navigationService.navigate('/0');
        console.log(error);
        return false;
      }

      Log(error);

      return Promise.reject(isNetworkError(error) ? 'CONNECTION_FAILED' : error?.response?.data || error);
    }
  );
};

export const isNetworkError = (err) => {
  let result = false;
  if (err.isAxiosError) {
    console.log('err.response', err.response);
    if (err.response?.status === 404) {
      result = false;
    } else if (err.response?.data?.status === 'FAILURE') {
      result = false;
    } else if (err.response?.data) {
      result = false;
    } else {
      result = true;
    }
  }

  return result;
};
