import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';

export default function OptInput({ code, setCode, label, description, numInputs = 5, shouldAutoFocus = false }) {
  const { t } = useTranslation();

  return (
    <>
      <Stack direction='row' alignItems={'center'} spacing={1} sx={{ mt: 2, mb: 1 }}>
        <Typography variant='body2' color={'grey.100'}>
          {label}
        </Typography>
        {/* <Button color='warning'>
          <Typography variant='body2' color={'secondary.main'}>
            {t('Get code')}
          </Typography>
        </Button> */}
      </Stack>

      <Box sx={{ width: 53 * numInputs }}>
        <OtpInput
          value={code}
          onChange={setCode}
          numInputs={numInputs}
          isInputNum
          shouldAutoFocus={shouldAutoFocus}
          containerStyle={{ justifyContent: 'space-between', direction: 'ltr' }}
          inputStyle={{
            width: 48,
            textAlign: 'center',
            fontSize: 20,
            borderRadius: 4,
            border: '1px solid grey.400',
            backgroundColor: 'transparent',
            padding: '7px 0',
          }}
        />
      </Box>

      {description && (
        <Typography variant='body4' color='grey.100' sx={{ mt: 1 }}>
          {description}
        </Typography>
      )}
    </>
  );
}
