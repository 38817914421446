import { useState } from 'react';
import { Button, IconButton, InputAdornment, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import ModalContainer from '../../../../components/ModalContainer';
import { LoadingButton } from '@mui/lab';
import { NumberField } from '../../../../components/NumberField';
import Api from '../../../../http/serviceApi';
import Iconify from '../../../../components/Iconify';

export default function P2PModal({ open, onClose, data, onReload }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [amount, setAmount] = useState('');
  const [receive, setReceive] = useState('');

  const onSubmit = async () => {
    if (!amount) {
      return enqueueSnackbar(t('Enter the amount!'), { variant: 'warning' });
    }

    setIsSubmitting(true);

    try {
      const res = await Api.takeP2POrder(data.id, data.side === 'BUY' ? amount / data.price : amount);
      if (res.status === 'SUCCESS') {
        enqueueSnackbar(res.message || 'Done', { variant: 'success' });
        onReload();
        onClose();
      }
    } catch (e) {}

    setIsSubmitting(false);
  };

  const onChange = (e) => {
    const value = e.target.value;

    setAmount(+value);
    setReceive(data.side === 'BUY' ? +value / data.price : +value * data.price);
  };

  const handleClose = () => {
    setAmount('');
    setReceive('');
    onClose();
  };

  if (!data) {
    return null;
  }


  return (
    <ModalContainer open={open} onClose={handleClose} width={400} title={data.side === 'BUY' ? 'Buy' : 'Sell'}>
      <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant='body6'>{t('')}</Typography>

        <Typography variant='body6' sx={{cursor:"pointer"}}
          onClick={()=>{
            if(data.side === 'BUY'){
              const a =data?.remainderAmount * data.price
              onChange({target:{value:a}})

            }else {
              onChange({target:{value:data?.remainderAmount}})
            }
          }}

        > {data?.remainderAmount}</Typography>
      </Stack>

      <NumberField
        value={amount}
        onChange={onChange}
        variant='standard'
        fullWidth
        placeholder='0'
        label={data.side === 'BUY' ? t('I want to pay') : t('I want to sell')}
        sx={{ mt: 2 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Typography color='grey.300' variant='body3'>
                {data.side === 'BUY' ? 'USD' : data.symbol}
              </Typography>
            </InputAdornment>
          ),
        }}
      />

      <NumberField
        value={receive}
        variant='standard'
        fullWidth
        disabled
        label={t('I will receive')}
        sx={{
          mt: 2,
          '& .MuiFormLabel-root': {
            color: 'grey.400',
          },
          '& .Mui-disabled': {
            '& input': { WebkitTextFillColor: 'grey.300 !important' },
            '&:before': { borderBottomStyle: 'solid !important' },
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Typography color='grey.300' variant='body3'>
                {data.side === 'BUY' ? data.symbol : 'USD'}
              </Typography>
            </InputAdornment>
          ),
        }}
      />

      <Stack direction='row' sx={{ mt: 3 }} spacing={2}>
        <LoadingButton
          fullWidth
          type='submit'
          color={data.side === 'BUY' ? 'success' : 'error'}
          variant='contained'
          loading={isSubmitting}
          onClick={onSubmit}
          sx={{
            height: 40,
            color: 'grey.0',
            borderRadius: 0,
            typography: 'body3',
          }}
        >
          {data.side === 'BUY' ? t('Buy') : t('Sell')} {data.symbol}
        </LoadingButton>

        <Button
          variant='outlined'
          color='inherit'
          onClick={handleClose}
          sx={{
            height: 40,
            width: 100,
            borderRadius: 0,
            typography: 'body3',
          }}
        >
          {t('Cancel')}
        </Button>
      </Stack>
    </ModalContainer>
  );
}
