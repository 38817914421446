import { TableRow, TableCell, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router';
import moment from 'jalali-moment';
import { useSnackbar } from 'notistack';

import { navigationUrl } from '../../../../navigationUrl';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../components/Iconify';
import confirmWrapper from '../../../../utils/confirm';

import Api from '../../../../http/serviceApi';
import ApiAdmin from '../../../../http/adminHttp/serviceApiAdmin';
import { prettyUppercases } from '../../../../utils';
import { SET_LAST_UPDATE_TICKET } from '../../../../store/actionTypes';

export default function TicketTableRow({ row, isAdmin = true, onReload }) {
  const { enqueueSnackbar } = useSnackbar();
  const language = useSelector((store) => store.language);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // -------------------------
  const { id, name, firstName, lastName, ticketStatus, score, section, createdDate, updatedDate } = row;

  // -------------------------
  const onDelete = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!(await confirmWrapper(t('Are you sure to remove this ticket?')))) {
      return false;
    }

    try {
      let data;
      if (isAdmin) {
        data = await ApiAdmin.deleteTicket(id);
      } else {
        data = await Api.deleteTicket(id);
      }

      if (data?.status === 'SUCCESS') {
        enqueueSnackbar(t('The ticket was successfully removed.'), { variant: 'success' });
        onReload();

        // reload ticket counter in nav menu
        if (isAdmin) {
          dispatch({ type: SET_LAST_UPDATE_TICKET, payload: new Date() });
        }
      }
    } catch (e) {}

    return true;
  };

  return (
    <TableRow
      hover
      sx={{ cursor: 'pointer', '& td': { py: 1.5, typography: 'body2' } }}
      onClick={() => navigate(isAdmin ? navigationUrl.admin_ticket_view(id) : navigationUrl.ticket_view(id))}
    >
      <TableCell align='center' sx={{ width: 80 }}>
        {id}
      </TableCell>

      {isAdmin && (
        <TableCell>
          {firstName} {lastName}
        </TableCell>
      )}

      <TableCell>{name}</TableCell>

      <TableCell align='left'>{t(section) || '-'}</TableCell>

      <TableCell align='center'>
        <Box
          sx={{
            maxWidth: 130,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            py: 0.8,
            px: 2,
            ...(ticketStatus === 'OPEN'
              ? { bgcolor: 'rgba(48, 224, 161, 0.2)' }
              : ticketStatus === 'CLOSE_BY_ADMIN' || ticketStatus === 'CLOSE'
              ? { bgcolor: 'rgba(224, 48, 48, 0.1)' }
              : ticketStatus === 'PENDING'
              ? { bgcolor: 'rgba(242, 127, 22, 0.2)' }
              : ticketStatus === 'DELETE'
              ? { bgcolor: 'rgba(228, 7, 36, 0.123)' }
              : ''),
          }}
        >
          <Typography
            variant='body4'
            sx={{
              lineHeight: 1,
              py: 0.4,
              ...(ticketStatus === 'OPEN'
                ? { color: '#30E0A1' }
                : ticketStatus === 'CLOSE_BY_ADMIN' || ticketStatus === 'CLOSE'
                ? { color: 'negative.main' }
                : ticketStatus === 'PENDING'
                ? { color: 'warning.main' }
                : ticketStatus === 'DELETE'
                ? { color: 'rgb(255, 73, 73)' }
                : ''),
            }}
          >
            {t(prettyUppercases(ticketStatus === 'DELETE' ? 'DELETED' : ticketStatus))}
          </Typography>
        </Box>
      </TableCell>

      <TableCell>{moment(createdDate).locale(language).format('YYYY-MM-DD HH:mm')}</TableCell>

      <TableCell>{updatedDate ? moment(updatedDate).locale(language).format('YYYY-MM-DD HH:mm') : '-'}</TableCell>

      <TableCell align='center'>{score || '-'}</TableCell>

      <TableCell align='center'>
        <Button
          color='inherit'
          size='small'
          startIcon={<Iconify icon='eva:eye-outline' sx={{ height: 16, width: 16 }} />}
          sx={{ typography: 'body2', borderRadius: 1, px: 1, mr: 2 }}
        >
          {t('View')}
        </Button>

        <Button
          color='inherit'
          size='small'
          startIcon={<Iconify icon='eva:trash-2-outline' sx={{ height: 16, width: 16 }} />}
          sx={{ typography: 'body2', borderRadius: 1, px: 1 }}
          onClick={onDelete}
        >
          {t('Remove')}
        </Button>
      </TableCell>
    </TableRow>
  );
}
