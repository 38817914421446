import { CircularProgress, IconButton, MenuItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FillSelect from '../../../../components/FillSelect';
import Iconify from '../../../../components/Iconify';
import FillTextField from '../../../../components/FillTextField';
import NumberRange from '../../../../components/NumberRange';
import FillRangeDatePicker from '../../../../components/FillRangeDatePicker';
import NumRangeWithInput from '../../../../components/NumRangeWithInput';
import React from 'react';

const SIDES = [
  { value: 'ALL', label: 'ALL' },
  { value: 'BUY', label: 'BUY' },
  { value: 'SELL', label: 'SELL' },
];

const TYPES = [
  { value: 'ALL', label: 'ALL' },
  { value: 'E_VOUCHER', label: 'E_VOUCHER' },
  { value: 'NORMAL', label: 'NORMAL' },
];

const STATUS = [
  { value: 'ALL', label: 'ALL' },
  { value: 'COMPLETED', label: 'COMPLETED' },
  { value: 'FAILED', label: 'FAILED' },
  { value: 'PENDING', label: 'PENDING' },
];

export default function PerfectMoneyHistoryTableToolbar({
  isAdmin = false,
  isExportLoading = false,
  onExport = () => {},
  sideFilter,
  setSideFilter,
  statusFilter,
  setStatusFilter,
  typeFilter,
  setTypeFilter,
  timeFilter,
  setTimeFilter,
  transactionIdFilter,
  setTransactionIdFilter,
  fiatAmountFilter,
  setFiatAmountFilter,
  firstNameFilter,
  setFirstNameFilter,
  lastNameFilter,
  setLastNameFilter,
  userIdFilter,
  setUserIdFilter,
}) {
  const { t } = useTranslation();

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent='space-between'
      alignItems={'flex-start'}
      spacing={2}
      sx={{ mb: { xs: 3, md: 9 } }}
    >
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Stack sx={{ width: '100%' }} flexDirection={'row'} alignItems={'flex-end'} gap={'15px'} flexWrap={'wrap'}>
          <FillRangeDatePicker
            value={timeFilter}
            setValue={setTimeFilter}
            variant='outlined'
            fullWidth
            sx={{ width: { xs: '100%', md: 335 } }}
            InputProps={null}
          />

          <FillSelect
            value={sideFilter}
            onChange={setSideFilter}
            label={t('Side')}
            sx={{ width: { xs: '100%', md: 160 } }}
          >
            {SIDES.map((side) => (
              <MenuItem key={side.value} value={side.value} sx={{ typography: 'body2' }}>
                {t(side.label)}
              </MenuItem>
            ))}
          </FillSelect>

          <FillSelect
            value={typeFilter}
            onChange={setTypeFilter}
            label={t('Type')}
            sx={{ width: { xs: '100%', md: 160 } }}
          >
            {TYPES.map((type) => (
              <MenuItem key={type.value} value={type.value} sx={{ typography: 'body2' }}>
                {t(type.label)}
              </MenuItem>
            ))}
          </FillSelect>

          <FillSelect
            value={statusFilter}
            onChange={setStatusFilter}
            label={t('Status')}
            sx={{ width: { xs: '100%', md: 160 } }}
          >
            {STATUS.map((status) => (
              <MenuItem key={status.value} value={status.value} sx={{ typography: 'body2' }}>
                {t(status.label)}
              </MenuItem>
            ))}
          </FillSelect>

          <FillTextField
            value={transactionIdFilter}
            onChange={setTransactionIdFilter}
            label={t('Tx-Code')}
            placeholder={'Enter Tx-Code'}
            sx={{ width: { xs: '100%', md: 160 } }}
            isDebounced
          />

          {/*<NumberRange*/}
          {/*  value={fiatAmountFilter}*/}
          {/*  setValue={setFiatAmountFilter}*/}
          {/*  max={100000}*/}
          {/*  sx={{*/}
          {/*    width: { xs: '100%', md: 160 },*/}
          {/*    border: '1px solid #52576e',*/}
          {/*    p: '3px 8px',*/}
          {/*    bgcolor: '#272833',*/}
          {/*    '& .MuiInputBase-root:before': { display: 'none' },*/}
          {/*    '& .MuiInputBase-root:after': { display: 'none' },*/}
          {/*  }}*/}
          {/*/>*/}



          {isAdmin && (
            <>
              {/* <FillTextField
                value={firstNameFilter}
                onChange={setFirstNameFilter}
                label={t('First name')}
                sx={{ width: { xs: '100%', md: 160 } }}
                isDebounced
              />

              <FillTextField
                value={lastNameFilter}
                onChange={setLastNameFilter}
                label={t('Last name')}
                sx={{ width: { xs: '100%', md: 160 } }}
                isDebounced
              /> */}
              <FillTextField
                value={userIdFilter}
                onChange={setUserIdFilter}
                label={t('User ID')}
                placeholder={'Enter User ID'}
                sx={{ width: { xs: '100%', md: 160 } }}
                isDebounced
              />
            </>
          )}
          <NumRangeWithInput
            max={100000}
            label={t('Amount')}
            value={fiatAmountFilter}
            setValue={setFiatAmountFilter}
            sx={{
              width: { xs: '100%', md: 160 },
              border: '1px solid #52576e',
              p: '3px 8px',
              bgcolor: '#272833',
              '& .MuiInputBase-root:before': { display: 'none' },
              '& .MuiInputBase-root:after': { display: 'none' },
            }}  />
        </Stack>
      </Stack>

      {onExport && (
        <>
          {isExportLoading ? (
            <CircularProgress size={22} color='success' sx={{ margin: '11px !important' }} />
          ) : (
            <>
              <IconButton onClick={onExport}>
                <Iconify icon='tabler:receipt' sx={{ width: 28, height: 28 }} />
              </IconButton>
              <IconButton onClick={() => onExport('PDF')}>
                <Iconify icon='majesticons:printer-line' sx={{ width: 28, height: 28 }} />
              </IconButton>
            </>
          )}
        </>
      )}
    </Stack>
  );
}
