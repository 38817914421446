import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
//
import Image from '../Image';
import RejectionFiles from './RejectionFiles';
import img from '../../assets/image.svg';
// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme, height }) => ({
  height: height ?? '219px',
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.grey[800],
  border: `1px solid ${theme.palette.grey[400]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

const PlaceholderStyle = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

CustomUploadImage.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
  hideText: PropTypes.bool,
};

export default function CustomUploadImage({ error = false, file, helperText, sx, hideText, ...other }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        height={'100%'}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...(file && {
            padding: '12% 0',
          }),
          ...(hideText && {
            padding: '0',
          }),
        }}
      >
        <input {...getInputProps()} />

        {file && (
          <Image
            alt='file preview'
            src={typeof file === 'string' ? file : file.preview}
            sx={{
              top: 8,
              left: 8,
              borderRadius: 1,
              position: 'absolute',
              width: 'calc(100% - 16px)',
              height: 'calc(100% - 16px)',
            }}
          />
        )}

        <PlaceholderStyle
          className='placeholder'
          sx={{
            ...(file && {
              opacity: 0,
              display: 'none',
              '&:hover': { opacity: 0.72 },
            }),
            ...((isDragReject || error) && {
              bgcolor: 'error.lighter',
            }),
          }}
        >
          <Image src={img} sx={{ width: 24, height: 24, mb: 2.25 }} objectFit={'contain'} />
          {!hideText && (
            <Typography variant='body3' color='grey.300'>
              Select or Drag photo
            </Typography>
          )}
        </PlaceholderStyle>
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}
    </Box>
  );
}
