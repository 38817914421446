import { Button, Col, DatePicker, Form, Image, Radio, Row, Space } from 'antd';
import React, { useState } from 'react';
import '../btnBlockStyle.scss';
import iconEmptyPic from '../../../../../assets/gallery.svg';
import classes from './intermediateForm.module.scss';
import { useEffect } from 'react';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTranslation } from 'react-i18next';

const IntermediateForm = (props) => {
  const { t } = useTranslation();
  const [document, setDocument] = useState({});
  const [approvement, setApprovenent] = useState('');

  const colPropsFormItem = {
    xs: 24,
    sm: 12,
    md: 10,
    lg: 8,
    xl: 6,
  };

  useEffect(() => {
    setDocument(props.formData?.documents?.find((item) => item.type === 'PASSPORT' || item.type === 'NATIONAL_CARD'));
  });

  const onApprovementChange = (value) => {
    setApprovenent(value);
  };

  const onSaveClick = () => {
    props.onSaveClick(approvement);
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <Row>
        <Radio.Group value={document?.type}>
          <Space direction='vertical'>
            <Radio value='NATIONAL_CARD'>{t('ID card')}</Radio>
            <Radio value='PASSPORT'>{t('Passport')}</Radio>
          </Space>
        </Radio.Group>
      </Row>
      <Row>
        <div className={classes.picDiv}>
          {document?.data ? (
            <img
              style={{ width: '100%', height: '100%' }}
              src={document?.data ? `data:image/jpeg;base64,${document?.data}` : null}
            />
          ) : (
            <img src={iconEmptyPic} style={{ position: 'absolute', marginLeft: '75px', marginTop: '75px' }} />
          )}
        </div>
      </Row>
      <Row>
        <Radio.Group
          disabled={props.formData?.userInfo?.authenticationLevel === 'INTERMEDIATE_REQUESTED' ? false : true}
          onChange={onApprovementChange}
        >
          <Space direction='vertical'>
            <Radio value='Approve'>{t('Approve')}</Radio>
            <Radio value='Reject'>{t('Reject')}</Radio>
          </Space>
        </Radio.Group>
      </Row>
      <Row gutter={[10, 0]} style={{ marginTop: '20px' }}>
        <Col>
          <Button type='primary' shape='round' onClick={onSaveClick} loading={props.saveLoading}>
            Save
          </Button>
        </Col>
        <Col>
          <Button shape='round'>{t('Send Notifiaction')}</Button>
        </Col>
        <Col>
          <Button
            type='primary'
            shape='round'
            className='btnBlock'
            onClick={props.onBlockClick}
            loading={props.blockLoading}
          >
            {t('Block')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default IntermediateForm;
