import { Stack, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ComplexToolbar from '../../../../../../components/ComplexToolbar';
import DatePicker from '../../../../../../components/DatePicker';
import { getDefaultDateFilterValues } from '../../../../../../utils/filter';

const EarnToolbar = ({
  dateFromFilter,
  setDateFromFilter,
  dateToFilter,
  setDateToFilter,
  onFilter,
  onExport,
  isExportLoading,
}) => {
  const { t } = useTranslation();

  const onReset = async () => {
    setDateFromFilter(getDefaultDateFilterValues().firstDay);
    setDateToFilter(getDefaultDateFilterValues().lastDay);
    onFilter(true);
  };

  return (
    <ComplexToolbar
      onSearch={false}
      onExport={onExport}
      onPrint={() => console.log('print')}
      isExportLoading={isExportLoading}
    >
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} sx={{ mt: 2 }} alignItems={'center'}>
          <Stack direction={'row'} sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}>
            <DatePicker
              label={t('From')}
              value={dateFromFilter}
              setValue={setDateFromFilter}
              variant='standard'
              maxDate={dateToFilter}
              fullWidth
              sx={{ minWidth: 75 }}
              InputProps={null}
            />
            <DatePicker
              label={t('To')}
              value={dateToFilter}
              setValue={setDateToFilter}
              variant='standard'
              minDate={dateFromFilter}
              fullWidth
              sx={{ minWidth: 75 }}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={'row'} spacing={2} sx={{ mt: 5, mb: 5.75 }}>
        <Button
          onClick={() => onFilter()}
          fullWidth
          variant='contained'
          sx={{ maxWidth: 204, typography: 'body3', fontWeight: 500 }}
        >
          {t('Filter')}
        </Button>
        <Button
          onClick={onReset}
          fullWidth
          variant='outlined'
          color='inherit'
          sx={{ maxWidth: 80, typography: 'body3', fontWeight: 500, borderColor: 'grey.400' }}
        >
          {t('Reset')}
        </Button>
      </Stack>
    </ComplexToolbar>
  );
};

export default EarnToolbar;
