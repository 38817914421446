import React from 'react';
import { Image, Row, Col } from 'antd';
import imgEarth from '../../../assets/earth.svg';
import classes from './verify.module.scss';
import logo from '../../../assets/logo.png';
import VerifyForm from './verifyForm';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';

const Verify = () => {
  const colPropsForm = {
    xs: 20,
    sm: 14,
    md: 8,
    lg: 6,
    xl: 6,
  };

  const colPropsImage = {
    xs: 0,
    sm: 0,
    md: 9,
    lg: 9,
    xl: 9,
  };

  return (
    <div className={classes.container}>
      <Row justify='space-around' style={{ marginTop: '50px' }}>
        <Col {...colPropsForm}>
          <div>
            <Image width={55} src={logo} preview={false} />
            Cryptizi
          </div>
        </Col>
        <Col {...colPropsImage}></Col>
      </Row>

      <Row justify='space-around' align='middle'>
        <Col {...colPropsForm}>
          <VerifyForm />
        </Col>
        <Col {...colPropsImage}>
          <Image height={450} src={imgEarth} preview={false} />
        </Col>
      </Row>

      <Row justify='space-around' style={{ marginBottom: '70px' }}>
        <Col {...colPropsForm}>
          <a href='/' className={classes.loginCreateAccount}>
            Login
          </a>
        </Col>
        <Col {...colPropsImage}></Col>
      </Row>
    </div>
  );
};

export default Verify;
