import { alpha, Card, Grid, Stack, Typography } from '@mui/material';

import Image from '../../../../../components/Image';
import moment from 'jalali-moment';

import bank from '../../../../../assets/bank.svg';
import order from '../../../../../assets/order.svg';
import ticket from '../../../../../assets/ticket.svg';
import wallet from '../../../../../assets/wallet.svg';
import adminUser from '../../../../../assets/adminUser.svg';
import log from '../../../../../assets/log.svg';
import entry from '../../../../../assets/entry.svg';
import { useSelector } from 'react-redux';
import exit from '../../../../../assets/exit.svg';
import { useTranslation } from 'react-i18next';

export default function Statistic({ data }) {
  const { t } = useTranslation();

  const language = useSelector((store) => store.language);

  const statistic = [
    { value: data?.changeBankCard || 0, label: 'Change bank cards', image: bank, color: '#11CABE' },
    { value: data?.changeOrder, label: 'Change orders', image: order, color: '#BD47FB' },
    { value: data?.replyToTicket, label: 'Reply to the ticket', image: ticket, color: '#F27F16' },
    { value: data?.changeWallet, label: 'Change wallet', image: wallet, color: '#FA2256' },
    { value: data?.changeUserCredential, label: 'Change user credentials', image: adminUser, color: '#30E0A1' },
    { value: data?.totalNumberOfLog, label: 'Total number of logs', image: log, color: '#FA2256' },
    {
      value: moment(data?.lastEntry).locale(language).format('D MMM YYYY - HH:mm'),
      label: 'last entry',
      image: entry,
      color: '#11CABE',
      fontVariant: 'body2',
    },
    {
      value: moment(data?.lastExit).locale(language).format('D MMM YYYY - HH:mm'),
      label: 'last exit',
      image: exit,
      color: '#BD47FB',
      fontVariant: 'body2',
    },
  ];

  return (
    <Grid container spacing={2.5}>
      {statistic?.map((item, index) => (
        <Grid key={index} item xs={12} sm={6} md={3}>
          <Card sx={{ display: 'flex' }}>
            <Stack
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                backgroundColor: alpha(item.color, 0.1),
                borderRadius: '100%',
                width: { xs: 40, xl: 48 },
                height: { xs: 40, xl: 48 },
              }}
            >
              <Image src={item.image} sx={{ width: { xs: 20, xl: 24 }, height: { xs: 20, xl: 24 } }} />
            </Stack>
            <Stack spacing={'0.25'} sx={{ ml: { xs: 1, xl: 2 } }}>
              <Typography variant={item.fontVariant || 'h6'} fontWeight={500} sx={{ height: 30 }}>
                {item.value}
              </Typography>
              <Typography variant={'body3'} color={'grey.100'}>
                {t(item.label)}
              </Typography>
            </Stack>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
