import { LoadingButton } from '@mui/lab';
import { TableRow, TableCell, Typography, Stack, IconButton } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Api from '../../../../http/serviceApi';
import { fFloat } from '../../../../utils/formatNumber';
import { NumberField } from '../../../../components/NumberField';
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

export default function FuturesPositionTableRow({ row, onReload, onEditMargin }) {
  const { t } = useTranslation();

  // ---------------
  const { id, symbol, leverage, amount, entryPrice, liquidationPrice, marginRation, margin, pnl, status } = row;

  // ---------------
  const [isClosing, setIsClosing] = useState(null);
  const [closeAmount, setCloseAmount] = useState(amount);
  const [closePrice, setClosePrice] = useState(entryPrice);

  // ---------------
  const onClosePosition = async (type = 'MARKET') => {
    setIsClosing(type);
    try {
      await Api.closePositionFutures(id, { amount: closeAmount, price: closePrice, type, symbol, side: 'ALL' });
      onReload();
    } catch (e) {}

    setIsClosing(null);
  };

  // ---------------
  return (
    <TableRow hover sx={{ '& td': { py: 2, typography: 'body2', color: 'grey.100' } }}>
      <TableCell>
        {symbol}{' '}
        <Typography variant='body4' color='warning' sx={{ pl: 1, color: 'warning.main' }}>
          {leverage}x
        </Typography>
      </TableCell>

      <TableCell>{fFloat(amount)}</TableCell>

      <TableCell>{fFloat(entryPrice)}</TableCell>

      <TableCell>{fFloat(liquidationPrice)}</TableCell>

      <TableCell>{fFloat(marginRation)}</TableCell>

      <TableCell>
        <Stack direction='row' alignItems='center'>
          <Typography variant='body4'>{fFloat(margin)}</Typography>
          <IconButton onClick={onEditMargin}>
            <Iconify icon='eva:edit-2-outline' sx={{ width: 20, height: 20 }} />
          </IconButton>
        </Stack>
      </TableCell>

      <TableCell>
        <Typography variant='body4' color={pnl >= 0 ? '#30E0A1' : '#FA2256'}>
          {fFloat(pnl)}
        </Typography>
      </TableCell>

      <TableCell>{status}</TableCell>

      <TableCell>
        <Stack direction='row'>
          <LoadingButton
            size='small'
            color='warning'
            loading={isClosing === 'MARKET'}
            disabled={isClosing !== null}
            onClick={() => onClosePosition('MARKET')}
            sx={{ typography: 'body3' }}
          >
            {t('Market')}
          </LoadingButton>

          <LoadingButton
            size='small'
            color='warning'
            loading={isClosing === 'LIMIT'}
            disabled={isClosing !== null}
            onClick={() => onClosePosition('LIMIT')}
            sx={{ typography: 'body3' }}
          >
            {t('Limit')}
          </LoadingButton>

          <NumberField
            size='small'
            placeholder={t('Price')}
            value={closePrice}
            onChange={(e) => setClosePrice(e.target.value)}
            sx={{ width: 100, '& input': { py: 0.8, px: 1, typography: 'body2' }, ml: 0.3 }}
          />

          <NumberField
            size='small'
            value={closeAmount}
            placeholder={t('Amount')}
            onChange={(e) => setCloseAmount(e.target.value)}
            sx={{ width: 100, '& input': { py: 0.8, px: 1, typography: 'body2' }, ml: 1 }}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
}
