import { Button, Chip, Divider, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ModalContainer from '../../../../components/ModalContainer';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import Image from '../../../../components/Image';
import { CARD_TYPE } from '../../bankCard/bankCardTable';

import USD from '../../../../assets/logo/dolar.svg';
import IRR from '../../../../assets/logo/IRR.svg';

export default function WaitingWithdrawFiatModal({ open, onClose, data, isAdmin = false, onAccept, onDeny }) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);
  const cardType = CARD_TYPE.find((ct) => ct.value === data.cardType);

  return (
    <ModalContainer open={open} onClose={onClose} title={'Account Information'} width={660}>
      <Grid container rowGap={2} mt={3.5}>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Tracking code')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='body3' color={'grey.100'}>
              {data?.trackingCode || '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Amount')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='body3' color={'grey.100'}>
              {data?.amount || '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          <Grid item xs={4}>
            <Typography variant='body3' color={'grey.400'}>
              {t('Fiat type')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Stack direction='row' alignItems={'center'}>
              <Typography variant='body3' noWrap sx={{ color: 'grey.100' }} marginInlineEnd={1}>
                {data.fiatType}
              </Typography>
              <Image src={data.fiatType === 'USD' ? USD : IRR} disabledEffect />
            </Stack>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Status')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Chip color={data.status === 'NEW' ? 'info' : 'success'} label={data?.status || '-'} size='small' />
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={6}>
          <Grid item xs={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Receipt')}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            {data.receipt ? (
              <a href={data.receipt} download={data.trackingCode} target='_black'>
                <Button color='primary' size='small' sx={{ px: 2 }} variant='outlined'>
                  {t('Download')}
                </Button>
              </a>
            ) : (
              '-'
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} py={1}>
          <Divider />
        </Grid>

        <Grid container item xs={12} sm={6}>
          <Grid item xs={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Account')}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant='body3' color={'grey.100'}>
              {data?.account || '-'}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={6}>
          <Grid item xs={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('User')}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant='body3' color={'grey.100'}>
              {data?.firstName} {data?.lastName}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={6}>
          <Grid item xs={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Date')}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant='body3' color={'grey.100'}>
              {moment(data?.createdDate).locale(language).format('YYYY/M/D')}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={2}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Email')}
            </Typography>
          </Grid>

          <Grid item xs={10}>
            <Typography variant='body3' color={'grey.100'}>
              {data?.emailAddress || '-'}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} py={1}>
          <Divider />
        </Grid>

        <Grid item xs={12} py={1}>
          <Typography variant='body2' color={'grey.100'} textAlign={'center'}>
            {t('This transaction is in progress, and after review, you can either confirm or reject it.')}
          </Typography>
          <Stack direction={'row'} justifyContent={'center'} spacing={2} mt={3}>
            <Button
              onClick={() => onDeny(false)}
              color='secondary'
              variant='contained'
              sx={{
                fontSize: 14,
                fontWeight: 800,
                px: 4,
                py: 1,
              }}
            >
              {t('Deny')}
            </Button>
            <Button
              onClick={() => onAccept(true)}
              color='primary'
              variant='contained'
              sx={{
                fontSize: 12,
                px: 4,
                py: 1,
              }}
            >
              {t('Accept')}
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12} py={1}>
          <Divider />
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={4}>
            <Typography variant='body3' color={'grey.400'}>
              {t('Card type')}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Stack direction='row' alignItems={'center'}>
              <Typography typography='body3' color='grey.100' marginInlineEnd={1}>
                {t(cardType.label)}
              </Typography>
              <Image src={cardType.icon} />
            </Stack>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={4}>
            <Typography variant='body3' color={'grey.400'}>
              {t('Card number')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid item xs={8}>
              <Typography variant='body3' color={'grey.100'}>
                {data?.cardNumber || '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Transaction type')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='body3' color={'grey.100'}>
              {data?.transactionType || '-'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Button
        fullWidth
        variant='contained'
        size='large'
        sx={{
          typography: 'body3',
          fontWeight: 500,
          mt: 5.25,
        }}
        onClick={onClose}
      >
        {t('Close')}
      </Button>
    </ModalContainer>
  );
}
