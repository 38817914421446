import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import moment from 'jalali-moment';

import totalUsers from '../../../../../assets/totalUsers.svg';
import verifiedUsers from '../../../../../assets/verifiedUsers.svg';
import waitingUsers from '../../../../../assets/waitingUsers.svg';
import rejectedUsers from '../../../../../assets/rejected.svg';
import Api from '../../../../../http/adminHttp/serviceApiAdmin';
import confirmWrapper from '../../../../../utils/confirm';
import { useSelector } from 'react-redux';
import { exportExcel } from '../../../../../utils/excel';
import { prettyUppercases } from '../../../../../utils';
import { exportPDF } from '../../../../../utils/pdf';

const attributes = {
  rejectedUsers: {
    name: 'Rejected users',
    icon: rejectedUsers,
    bgColor: 'rgba(250, 34, 86, 0.1)',
  },
  totalUsers: {
    name: 'Total users',
    icon: totalUsers,
    bgColor: 'rgba(17, 202, 190, 0.1)',
  },
  verifiedUsers: {
    name: 'Verified users',
    icon: verifiedUsers,
    bgColor: 'rgba(189, 71, 251, 0.1)',
  },
  waitingUsers: {
    name: 'Users are waiting',
    icon: waitingUsers,
    bgColor: 'rgba(242, 127, 22, 0.1)',
  },
};

export default function useUserInit({
                                      setPage,
  page,
  rowsPerPage,
  conditionFilter,
  dateFromFilter,
  dateToFilter,
  nameFilter,
  userIdFilter,
  balanceFilter,
  authenticationLevelFilter,
}) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const { enqueueSnackbar } = useSnackbar();

  const [allUsers, setAllUsers] = useState(null);

  const [userStatisticValues, setUserStatisticValues] = useState({});
  const [userStatistic, setUserStatistic] = useState([]);

  // --------------------
  const getAllUser = async (withFilter = true) => {
    try {
      const filters = {
        authenticationLevel: authenticationLevelFilter,
        balance: balanceFilter?.[0] || 0,
        balance2: balanceFilter?.[1] || 10000000,
      };
      if (withFilter) {
        if (dateFromFilter && dateToFilter) {
          filters.startTime = moment(dateFromFilter).startOf('days').valueOf();
          filters.endTime = moment(dateToFilter).endOf('days').valueOf();
        }

        if (conditionFilter !== 'ALL') filters.userCondition = conditionFilter;
        if (nameFilter) filters.name = nameFilter;
        userIdFilter && (filters.userId = userIdFilter);
      }

      const response = await Api.getAllUser(page, rowsPerPage, filters);
      if (response) {
        setAllUsers(balanceFilter?.[0] === 0 || balanceFilter?.[0] === undefined? response.adminUserInfoList : response.adminUserInfoList );
        setPage(0)
        setUserStatisticValues({
          totalUsers: response.totalUser,
          verifiedUsers: response.verifiedUser,
          waitingUsers: response.waitingUser,
          rejectedUsers: response.rejectedUser,
        });
      }
    } catch (e) {}
  };

  // Fetching data from server
  useEffect(() => {
    getAllUser();
  }, []);

  // Statistic
  useEffect(() => {
    const allUsersList = [];

    Object.entries(userStatisticValues).forEach(([key, value]) => {
      const attribute = attributes[key];

      allUsersList.push({
        value,
        name: attribute.name,
        bgColor: attribute.bgColor,
        icon: attribute.icon,
      });
    });

    setUserStatistic(allUsersList);
  }, [userStatisticValues]);

  const handleDeleteUser = async (userId, firstName, lastName) => {
    if (!(await confirmWrapper(t(`Are you sure to remove this user from users?`)))) {
      return false;
    }
    try {
      const response = await Api.deleteUserByAdmin(userId);
      if (response?.status === 'SUCCESS') {
        getAllUser();
        enqueueSnackbar(t('The user account is deleted successfuly'), { variant: 'success' });
      }
    } catch (e) {}
    return true;
  };

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);

  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    const filters = {};
    if (dateFromFilter && dateToFilter) {
      filters.from = moment(dateFromFilter).startOf('days').valueOf();
      filters.to = moment(dateToFilter).endOf('days').valueOf();
    }
    filters.name = nameFilter;

    try {
      const response = await Api.getAllWaitingUser(0, 10000000, filters);

      // generate excel and download
      const config = {
        language,
        fileName: t('User'),
        heads: ['#', t('Name'), t('Mobile'), t('Date'), t('Balance'), t('Condition'), t('Level')],
        columnsWidth: [5, 26, 13, 23, 11, 15, 27],
        list: response.adminUserInfoList.map((item) => [
          `${item.firstName} ${item.lastName}`,
          '-',
          moment(item.registeredDate).locale(language).format('D MMM YYYY - HH:mm'),
          `${item.amount}`,
          `${t(item.condition)}`,
          `${t(prettyUppercases(item.authenticationLevel))}`,
        ]),
      };

      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  // --------------------
  return {
    allUsers,
    userStatistic,
    handleDeleteUser,
    getAllUser,
    isExportLoading,
    onExport,
  };
}
