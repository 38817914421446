import { Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../components/table';
import FuturesOpenTradeTableRow from './futuresOpenTradeTableRow';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'createdDate', label: 'Time', align: 'left' },
  { id: 'symbol', label: 'Symbol', align: 'center' },
  { id: 'type', label: 'Type', align: 'center' },
  { id: 'side', label: 'Side', align: 'center' },
  { id: 'price', label: 'Price', align: 'center' },
  { id: 'amount', label: 'Amount', align: 'center' },
  { id: 'executed', label: 'Filled', align: 'center' },
  { id: 'actions', label: '', align: 'left' },
];

// ----------------------------------------------------------------------

export default function FuturesOpenTradeTable({
  openTradeTableData,
  openTradeTablePagination,
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  onReload,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ position: 'relative' }}>
          <Table size={'small'}>
            <TableHeadCustom headLabel={TABLE_HEAD} rowCount={openTradeTableData?.length || 0} />

            <TableBody>
              {openTradeTableData === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {openTradeTableData.map((row) => (
                    <FuturesOpenTradeTableRow key={row.orderId} row={row} onReload={onReload} />
                  ))}

                  <TableNoData isNotFound={!openTradeTableData?.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      {openTradeTableData?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 200]}
          component='div'
          count={openTradeTablePagination.totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage={t('Rows per page')}
          labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
        />
      )}
    </>
  );
}
