import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../../components/DatePicker';
import TabChart from './tabChart';
import { getDefaultDateFilterValues } from '../../../../utils/filter';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import { BadgeIcon } from '../style';
import { useSelector } from 'react-redux';
import { exportExcel } from '../../../../utils/excel';
import { LoadingButton } from '@mui/lab';
import { exportPDF } from '../../../../utils/pdf';

export default function SuccessfulTradesTab({ section = 'successful' }) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  // ---------------------
  const TYPES = [
    { label: t('Convert'), value: 'convert', color: '#30E0A1' },
    { label: t('P2P'), value: 'p2p', color: '#F27F16' },
    { label: t('OTC'), value: 'otc', color: '#155eeb' },
    { label: t('Trade'), value: 'trade', color: '#9747FF' },

    { label: t('Futures'), value: 'futures', color: '#CA3211' },
    { label: t('E-money'), value: 'emoney', color: '#22412a' },


  ];

  // ---------------------
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [dateFromFilter, setDateFromFilter] = useState(getDefaultDateFilterValues().firstDay);
  const [dateToFilter, setDateToFilter] = useState(getDefaultDateFilterValues().lastDay);
  const [typesFilter, setTypesFilter] = useState([TYPES[1].value, TYPES[2].value]);

  // ---------------------
  const onTypeChange = (e) => {
    const value = e.target.value;

    if (typesFilter.includes(value)) {
      setTypesFilter(typesFilter.filter((item) => item !== value));
    } else {
      setTypesFilter([...typesFilter, value]);
    }
  };

  // ---------------------
  const onReport = async () => {
    if (typesFilter.length === 0) {
      return;
    }

    let response;
    if (section === 'successful') {
      response = await Api.getReportSuccessTradeStatistics(
        dateFromFilter.valueOf(),
        dateToFilter.valueOf(),
        typesFilter.join(',')
      );
    } else if (section === 'unSuccessful') {
      response = await Api.getReportUnSuccessTradeStatistics(
        dateFromFilter.valueOf(),
        dateToFilter.valueOf(),
        typesFilter.join(',')
      );
    } else if (section === 'open') {
      response = await Api.getReportOpenTradeStatistics(
        dateFromFilter.valueOf(),
        dateToFilter.valueOf(),
        typesFilter.join(',')
      );
    }

    const datas = [];
    typesFilter.forEach((type) => {
      const TYPE = TYPES.find((t) => t.value === type);

      datas.push({
        name: TYPE.label,
        type: 'line',
        data: response?.[type === 'emoney' ? 'emoney' : type === 'p2p' ? 'p2Ps' : type === 'futures'? 'futures': `${type}s`]?.map((t) => t.sum) || [],
        color: TYPE.color,
      });
    });

    setChartData(datas);

    const rndType = typesFilter[0];
    setChartLabels(
      response?.[rndType === 'emoney' ? 'emoney' : rndType === 'p2p' ? 'p2Ps' : `${rndType}s`]?.map((t) => moment(t.date).format('YYYY MMM D'))
    );
  };

  // ---------------------
  useEffect(() => {
    onReport();
  }, []);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);

  // --------------------
  const onExport = async (type = 'Excel') => {
    if (chartData?.length === 0) return;

    setIsExportLoading(true);

    const list = [];
    chartData[0].data.forEach((d, index) => {
      list.push([chartLabels[index], ...chartData.map((cd) => cd.data[index])]);
    });

    try {
      let fileName;
      if (section === 'successful') {
        fileName = t('Reporting Successful Trades');
      }
      if (section === 'unSuccessful') {
        fileName = t('Reporting Unsuccessful Trades');
      }
      if (section === 'open') {
        fileName = t('Reporting Open trades');
      }

      const config = {
        language,
        fileName: `${fileName} - ${moment(dateFromFilter).locale(language).format('YYYY-MM-DD')} - ${moment(
          dateToFilter
        )
          .locale(language)
          .format('YYYY-MM-DD')}`,
        heads: ['#', t('Date'), ...chartData.map((cd) => t(cd.name))],
        columnsWidth: [5, 19, 16, 20, 15, 21, 20],
        list,
      };

      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  // ---------------------
  return (
    <Grid container sx={{ mt: 5, mb: 2 }}>
      <Grid item xs={12} sm={5} md={4} sx={{ pl: 3 }}>
        <Stack direction={'row'}>
          <DatePicker
            label={t('From')}
            value={dateFromFilter}
            setValue={setDateFromFilter}
            variant='standard'
            maxDate={dateToFilter}
            fullWidth
            sx={{ maxWidth: 150 }}
            InputProps={null}
          />
          <DatePicker
            label={t('To')}
            value={dateToFilter}
            setValue={setDateToFilter}
            variant='standard'
            minDate={dateFromFilter}
            fullWidth
            sx={{ maxWidth: 150 }}
          />
        </Stack>

        <Stack spacing={2} sx={{ my: 5 }}>
          <Typography variant='body3'>{t('Select')}</Typography>

          <FormGroup>
            {TYPES.map((TYPE, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox checked={typesFilter.includes(TYPE.value)} onChange={onTypeChange} value={TYPE.value} />
                }
                label={TYPE.label}
              />
            ))}
          </FormGroup>
        </Stack>

        <Button
          onClick={onReport}
          fullWidth
          size='large'
          variant='contained'
          className='no-print'
          sx={{ maxWidth: 283, typography: 'body3', fontWeight: 500, mt: 10.5 }}
        >
          {t('Report')}
        </Button>
      </Grid>

      <Grid item xs={12} sm={7} md={8} sx={{ pr: 3, mt: { xs: 4, sm: 0 } }}>
        <Stack direction={'row'} spacing={3} justifyContent='flex-end'>
          {TYPES.reverse().map((TYPE, index) => (
            <Stack direction={'row'} spacing={0.5} key={index}>
              <Typography variant='body4' color='grey.100' sx={{ mt: 0.5 }}>
                {TYPE.label}
              </Typography>
              <BadgeIcon bgColor={TYPE.color} direction={'left'} />
            </Stack>
          ))}
        </Stack>

        <Box sx={{ my: 3.2 }}>
          <Chart chartData={chartData} chartLabels={chartLabels} />
        </Box>

        <Stack direction={'row'} justifyContent={'center'} spacing={4} className='no-print'>
          <LoadingButton
            color='inherit'
            size='large'
            variant='outlined'
            loading={isExportLoading}
            onClick={onExport}
            sx={{ width: 172, typography: 'body3', fontWeight: 500, color: 'grey.400' }}
          >
            {t('Excel')}
          </LoadingButton>
          <Button
            color='inherit'
            size='large'
            variant='outlined'
            sx={{ width: 172, typography: 'body3', fontWeight: 500, color: 'grey.400' }}
            onClick={() => window.print()}
          >
            {t('PDF')}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

const Chart = ({ chartData, chartLabels }) => <TabChart chartData={chartData} chartLabels={chartLabels} label={'Sum USDT'}/>;
