import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PeriodButton } from './style';

const periods = [
  { label: '12 H', value: 'HALF_OF_DAY' },
  { label: '1 Day', value: 'DAY' },
  { label: '1 Week', value: 'WEEK' },
  { label: '1 Month', value: 'MONTH' },
  { label: '1 Year', value: 'YEAR' },
];

const TimeChart = ({ period, setPeriod }) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        {periods.map((p, index) => (
          <PeriodButton
            size='small'
            color='inherit'
            selected={period === p.value}
            onClick={() => setPeriod(p.value)}
            key={index}
          >
            <Typography variant='body4' color='grey.400' sx={{ fontWeight: 900 }}>
              {t(p.label)}
            </Typography>
          </PeriodButton>
        ))}
      </Stack>
    </>
  );
};

export default TimeChart;
