import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function usePerfectMoneyForm() {
  const { t } = useTranslation();

  const validators = Yup.object().shape({
    type: Yup.string().required(t('Please select the type!')),
  });

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues: {
      type: 'NORMAL', // E_VOUCHER
      perfectMoneyAmount: '',
      fiatAmount: '',
      fiatType: 'USD',
      walletId: '',
      withDescription: 'no',
      withSecurityCode: 'no',
      description: '',
      depositType: 'WALLET',
      purchase: '',
      voucherCode: '',
      activationCode: '',
      serialNumber: '',
      sales: '',
    },
  });

  return { methods };
}
