import { MenuItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FillSelect from '../../../components/FillSelect';
import { useSelector } from 'react-redux';

const STATUSES = [
  { value: 'ALL', label: 'ALL' },
  { value: 'NEW', label: 'NEW' },
  { value: 'SUCCESS', label: 'SUCCESS' },
  { value: 'FAILURE', label: 'FAILURE' },
  { value: 'CANCELED', label: 'CANCELED' },
];

const TYPES = [
  { value: 'ALL', label: 'ALL' },
  { value: 'IRR', label: 'IRR' },
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
  { value: 'AUD', label: 'AUD' },
  { value: 'TRY', label: 'TRY' },
  { value: 'GBP', label: 'GBP' },
  { value: 'BRL', label: 'BRL' },
  { value: 'NGN', label: 'NGN' },
  { value: 'PLN', label: 'PLN' },
  { value: 'RON', label: 'RON' },
  { value: 'RUB', label: 'RUB' },
  { value: 'UAH', label: 'UAH' },
  { value: 'ZAR', label: 'ZAR' },
];

export default function DepositTableToolbar({ statusFilter, setStatusFilter, typeFilter, setTypeFilter }) {
  const { t } = useTranslation();
  const isIranianPaymentActive = useSelector((store) => store.isIranianPaymentActive);

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent='space-between'
      alignItems={'center'}
      spacing={2}
      sx={{ mb: 9 }}
    >
      <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'center'} spacing={2}>
        <FillSelect value={statusFilter} onChange={setStatusFilter} label={'Status'} sx={{ minWidth: 220 }}>
          {STATUSES.map((item) => (
            <MenuItem key={item.value} value={item.value} sx={{ typography: 'body2' }}>
              {t(item.label)}
            </MenuItem>
          ))}
        </FillSelect>

        <FillSelect value={typeFilter} onChange={setTypeFilter} label={'Asset'} sx={{ minWidth: 220 }}>
          {TYPES.filter((f) => (isIranianPaymentActive ? true : f.label !== 'IRR')).map((item) => (
            <MenuItem key={item.value} value={item.value} sx={{ typography: 'body2' }}>
              {t(item.label)}
            </MenuItem>
          ))}
        </FillSelect>
      </Stack>
    </Stack>
  );
}
