import React, { useState } from 'react';
import { Stack, Card, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';

import { FormProvider, RHFTextField, RHFSwitch, RHFNumberField } from '../../../../components/hookForm';
import { PageTitle } from '../../../../components/PageTitle';
import { useEffect } from 'react';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import useGeneralForm from './hooks/useGeneralForm';
import useGeneralSubmit from './hooks/useGeneralSubmit';

const GeneralSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [generalSetting, setGeneralSetting] = useState({
    deactivatingMessage: '',
    deactivatingPurchase: false,
    minimumAllowedPurchase: '',
    onlyIranianIps: false,
  });

  const { handleSubmit, isSubmitting, methods } = useGeneralForm(generalSetting);
  const { onSubmit } = useGeneralSubmit();

  const fetchGeneralSettings = async () => {
    const response = await Api.getGeneralSettings();
    setGeneralSetting({
      deactivatingMessage: response.deactivatingMessage,
      deactivatingPurchase: response.deactivatingPurchase,
      minimumAllowedPurchase: response.minimumAllowedPurchase,
      onlyIranianIps: response.onlyIranianIps,
    });
  };

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'General Settings' },
    });

    fetchGeneralSettings();
  }, []);

  return (
    <Stack sx={{ padding: { xs: '0 25px', lg: '0' } }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ mt: 5, px: { xs: 2, sm: 4 }, py: { xs: 3, sm: 4 } }}>
          <PageTitle title='General settings' subTitle='Caption' />
          <Stack spacing={2.5} sx={{ mt: 5, '& div': { width: { xs: '100%', md: '76%' } } }}>
            <RHFNumberField
              name='minimumAllowedPurchase'
              label={t('Minimum allowed purchase')}
              variant='standard'
              fullWidth
            />
            {/* 
            <RHFTextField
              name='ip'
              label={'IP test mode (to disable, leave the field blank)'}
              variant='standard'
              fullWidth
            /> */}

            <Stack
              direction='row'
              alignItems='center'
              spacing={1}
              sx={{ mt: '32px !important', mb: '20px !important' }}
            >
              <RHFSwitch name='onlyIranianIps' />
              <Typography variant='body3'>{t('Only Iranian IPs are allowed')}</Typography>
            </Stack>

            <RHFTextField
              name='deactivatingMessage'
              label={
                t('Deactivating purchases from the site (inserting a message in the box below means deactivating purchases from the site)')
              }
              variant='standard'
              fullWidth
            />

            <Stack
              direction='row'
              alignItems='center'
              spacing={1}
              sx={{ mt: '32px !important', mb: '20px !important' }}
            >
              <RHFSwitch name='deactivatingPurchase' />
              <Typography variant='body3'>{t('Deactivating purchase from site')}</Typography>
            </Stack>

            {/* <RHFTextField
              name='dailyPurchase'
              label={'Daily purchase amount during registration'}
              variant='standard'
              fullWidth
            /> */}
          </Stack>
        </Card>

        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ mt: 5, maxWidth: 283 }}
        >
          {t('Save')}
        </LoadingButton>
      </FormProvider>
    </Stack>
  );
};

export default GeneralSettings;
