import { m } from 'framer-motion';
// @mui
import { Container, Grid, Typography, useTheme } from '@mui/material';
// components
import MotionViewport from '../../../components/animate/MotionViewport';
import { varFade } from '../../../components/animate/variants/fade';
import { RootSubStyle, TrustShadow, TrustWrapper } from '../style';
import Image from '../../../components/Image';

import earthImage from '../../../assets/landing/earth.png';
import earthImageLight from '../../../assets/landing/earthLight.png';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Trust() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <RootSubStyle sx={{ position: 'relative', pt: 0, pb: 0 }}>
      <Container component={MotionViewport}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
            <m.div variants={varFade().inUp}>
              <TrustWrapper alignItems={'center'} justifyContent={'center'}>
                <TrustShadow />
                <Image src={theme.palette.mode === 'dark' ? earthImage : earthImageLight} sx={{ width: 500 }} />
              </TrustWrapper>
            </m.div>
          </Grid>

          <Grid item xs={12} md={6} sx={{ pt: { xs: 8, md: 25 } }}>
            <m.div variants={varFade().inRight}>
              <Typography
                variant='h3'
                sx={{
                  fontWeight: 400,
                  textAlign: { xs: 'center', md: 'left' },
                  width: { xs: '100%', md: 500 },
                  display: 'inline-block',
                }}
              >
                {t('We are the most trusted cryptocurrency platform.')}
              </Typography>
              <Typography variant='body2' color='grey.300' sx={{ mt: 3, width: { xs: '100%', md: 470 } }}>
                {t(
                  'We believe Cryptizi is here to stay - and that a future worth building is one which opens its doors and invites everyone in.'
                )}
              </Typography>
            </m.div>
          </Grid>
        </Grid>
      </Container>
    </RootSubStyle>
  );
}
