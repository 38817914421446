import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { useEffect } from 'react';

export default function useAdminForm(data, isEdit) {
  const { t } = useTranslation();

  const [avatar, setAvatar] = useState(null);
  const [walletSettingsView , setWalletViewState] = useState(true)

  const validators = Yup.object().shape({
    firstName: Yup.string().required(t('Please input first name!')),
    lastName: Yup.string().required(t('Please input last name!')),
    mobileNumber: Yup.string(),
    emailAddress: Yup.string().required(t('Please input email address!')),
    password: isEdit ? Yup.string().notRequired() : Yup.string().required(t('Please input password!')),
  });

  const defaultValues = {
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    mobileNumber: data?.mobileNumber || '',
    emailAddress: data?.emailAddress || '',
    password: '',
    twoStepLogin: data?.twoStepLogin ? 'Active' : 'Deactive',

    ORDERS: data?.adminAccessInfoList.find((access) => access.accessType === 'ORDERS')?.status || false,
    TICKETS: data?.adminAccessInfoList.find((access) => access.accessType === 'TICKETS')?.status || false,
    USERS: data?.adminAccessInfoList.find((access) => access.accessType === 'USERS')?.status || false,
    SETTING: data?.adminAccessInfoList.find((access) => access.accessType === 'SETTING')?.status || false,
    NOTIFICATION: data?.adminAccessInfoList.find((access) => access.accessType === 'NOTIFICATION')?.status || false,
    API_SETTING: data?.adminAccessInfoList.find((access) => access.accessType === 'API_SETTING')?.status || false,
    ACADEMY: data?.adminAccessInfoList.find((access) => access.accessType === 'ACADEMY')?.status || false,
    ADMINS: data?.adminAccessInfoList.find((access) => access.accessType === 'ADMINS')?.status || false,
    REFERRALS: data?.adminAccessInfoList.find((access) => access.accessType === 'REFERRALS')?.status || false,
    REPORTING: data?.adminAccessInfoList.find((access) => access.accessType === 'REPORTING')?.status || false,
    NETWORK_SETTING:
      data?.adminAccessInfoList.find((access) => access.accessType === 'NETWORK_SETTING')?.status || false,
    WAITING_ACTIONS:
      data?.adminAccessInfoList.find((access) => access.accessType === 'WAITING_ACTIONS')?.status || false,
    USERS_WAITING_FOR_VERIFY:
      data?.adminAccessInfoList.find((access) => access.accessType === 'USERS_WAITING_FOR_VERIFY')?.status || false,
    LIST_OF_ALL_BANK_CARD:
      data?.adminAccessInfoList.find((access) => access.accessType === 'LIST_OF_ALL_BANK_CARD')?.status || false,
    FIAT_TRANSACTION_WAITING:
      data?.adminAccessInfoList.find((access) => access.accessType === 'FIAT_TRANSACTION_WAITING')?.status || false,
    TOKEN_LISTING: data?.adminAccessInfoList.find((access) => access.accessType === 'TOKEN_LISTING')?.status || false,
    DEDICATED_WALLET: data?.adminAccessInfoList.find((access) => access.accessType === 'DEDICATED_WALLET')?.status || false,
    MAIN_WALLET: data?.adminAccessInfoList.find((access) => access.accessType === 'MAIN_WALLET')?.status || false,
    GAS_CONFIG: data?.adminAccessInfoList.find((access) => access.accessType === 'GAS_CONFIG')?.status || false,


  };


  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { isSubmitting },
  } = methods;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setAvatar(file);

        setValue(
          'avatar',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const waitingAction = watch('WAITING_ACTIONS');

  useEffect(() => {
    if (!waitingAction) {
      setValue('USERS_WAITING_FOR_VERIFY', false);
      setValue('FIAT_TRANSACTION_WAITING', false);
      setValue('LIST_OF_ALL_BANK_CARD', false);
    }
  }, [waitingAction]);


  const walletSettings = watch('DEDICATED_WALLET') || watch('GAS_CONFIG')  || watch('MAIN_WALLET') ;

  useEffect(() => {
    if (walletSettings) {
      setWalletView(true)
    }else {
      setWalletView(false)
    }
  }, [walletSettings]);

  const setWalletView = (e) =>{
    setWalletViewState(e)
    if(!e){
      setValue('DEDICATED_WALLET' , false)
      setValue('GAS_CONFIG' , false)
      setValue('MAIN_WALLET' , false)

    }
  }
  return {
    handleSubmit,
    isSubmitting,
    methods,
    avatar,
    handleDrop,
    waitingAction,
    walletSettingsView,
    setWalletView
  };
}
