import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../components/table';
import Api from '../../../http/adminHttp/serviceApiAdmin';

const TABLE_HEAD = [
  { id: 'title', label: 'Title', align: 'left' },
  { id: 'today', label: 'Today', align: 'center' },
  { id: 'yesterday', label: 'Yesterday', align: 'center' },
  { id: 'week', label: 'This week', align: 'center' },
  { id: 'month', label: 'This month', align: 'center' },
  { id: 'year', label: 'This Year', align: 'center' },
];

export default function StatisticTable() {
  const [tableData, setTableData] = useState([]);

  // --------------------
  const getData = async () => {
    try {
      const response = await Api.getGeneralStatisticsByDate();
      setTableData([
        {
          title: 'Orders',
          today: response?.orders?.today || 0,
          yesterday: response?.orders?.yesterday || 0,
          week: response?.orders?.week || 0,
          month: response?.orders?.month || 0,
          year: response?.orders?.year || 0,
        },
        {
          title: 'Amount of orders',
          today: response?.ordersAmount?.today || 0,
          yesterday: response?.ordersAmount?.yesterday || 0,
          week: response?.ordersAmount?.week || 0,
          month: response?.ordersAmount?.month || 0,
          year: response?.ordersAmount?.year || 0,
        },
        {
          title: 'Sales number',
          today: response?.sales?.today || 0,
          yesterday: response?.sales?.yesterday || 0,
          week: response?.sales?.week || 0,
          month: response?.sales?.month || 0,
          year: response?.sales?.year || 0,
        },
        {
          title: 'Selling price',
          today: response?.salesAmount?.today || 0,
          yesterday: response?.salesAmount?.yesterday || 0,
          week: response?.salesAmount?.week || 0,
          month: response?.salesAmount?.month || 0,
          year: response?.salesAmount?.year || 0,
        },
        {
          title: 'Purchases',
          today: response?.purchases?.today || 0,
          yesterday: response?.purchases?.yesterday || 0,
          week: response?.purchases?.week || 0,
          month: response?.purchases?.month || 0,
          year: response?.purchases?.year || 0,
        },
        {
          title: 'Purchase amount',
          today: response?.purchasesAmount?.today || 0,
          yesterday: response?.purchasesAmount?.yesterday || 0,
          week: response?.purchasesAmount?.week || 0,
          month: response?.purchasesAmount?.month || 0,
          year: response?.purchasesAmount?.year || 0,
        },
        {
          title: 'New User',
          today: response?.users?.today || 0,
          yesterday: response?.users?.yesterday || 0,
          week: response?.users?.week || 0,
          month: response?.users?.month || 0,
          year: response?.users?.year || 0,
        },
      ]);
    } catch (e) {}
  };

  // ---------------------
  useEffect(() => {
    getData();
  }, []);

  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
        <Table size='small'>
          <TableHeadCustom headLabel={TABLE_HEAD} />
          <TableBody>
            {tableData === null ? (
              <TableLoading isLoading />
            ) : (
              <>
                {tableData?.map((row, index) => (
                  <StatisticTableRow key={index} row={row} />
                ))}

                <TableNoData isNotFound={!tableData?.length} />
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}

const StatisticTableRow = ({ row }) => {
  const { t } = useTranslation();
  const { title, today, yesterday, week, month, year } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1.5, typography: 'body2' } }}>
      <TableCell>{t(title)}</TableCell>
      <TableCell align='center'>{today}</TableCell>
      <TableCell align='center'>{yesterday}</TableCell>
      <TableCell align='center'>{week}</TableCell>
      <TableCell align='center'>{month}</TableCell>
      <TableCell align='center'>{year}</TableCell>
    </TableRow>
  );
};
