import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SET_PAGE_STATE } from '../../../store/actionTypes';
import AdminNotificationForm from './adminNotificationForm';
import AdminNotificationTable from './adminNotificationTable';

export default function AdminNotification() {
  const dispatch = useDispatch();

  // --------------
  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Notification' },
    });
  }, []);

  const [reloadTime, setReloadTime] = useState(new Date());

  // --------------
  return (
    <>
      <AdminNotificationForm onReload={() => setReloadTime(new Date())} />

      <AdminNotificationTable reloadTime={reloadTime} />
    </>
  );
}
