import { useTranslation } from 'react-i18next';
import { Button, MenuItem, Stack, TextField } from '@mui/material';

import ComplexToolbar from '../../../../components/ComplexToolbar';
import DatePicker from '../../../../components/DatePicker';
import { getDefaultDateFilterValues } from '../../../../utils/filter';

const TokenToolbar = ({ filters, setFilters, onFilter, onExport, isExportLoading }) => {
  const { t } = useTranslation();

  const onReset = () => {
    setFilters({
      startTime: getDefaultDateFilterValues().firstDay,
      endTime: getDefaultDateFilterValues().lastDay,
      name: '',
      symbol: '',
      active: undefined,
    });
  };

  return (
    <ComplexToolbar onSearch={null} onExport={onExport} isExportLoading={isExportLoading}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', lg: 'row' }} spacing={5} sx={{ mt: 2 }} alignItems={'center'}>
          <Stack direction={'row'}>
            <DatePicker
              label={t('From')}
              value={filters.startTime}
              setValue={(startTime) => setFilters((prev) => ({ ...prev, startTime }))}
              variant='standard'
              maxDate={filters.endTime}
              fullWidth
              sx={{ maxWidth: 125 }}
              InputProps={null}
            />
            <DatePicker
              label={t('To')}
              value={filters.endTime}
              setValue={(endTime) => setFilters((prev) => ({ ...prev, endTime }))}
              variant='standard'
              minDate={filters.startTime}
              fullWidth
              sx={{ maxWidth: 125 }}
            />
          </Stack>
          <TextField
            label={t('Name')}
            variant='standard'
            fullWidth
            sx={{ maxWidth: 200 }}
            value={filters.name}
            onChange={(e) => setFilters((prev) => ({ ...prev, name: e.target.value }))}
          />
          <TextField
            label={t('Symbol')}
            variant='standard'
            fullWidth
            sx={{ maxWidth: 200 }}
            value={filters.symbol}
            onChange={(e) => setFilters((prev) => ({ ...prev, symbol: e.target.value }))}
          />
          <TextField
            select
            label={t('Status')}
            variant='standard'
            fullWidth
            value={filters.active}
            onChange={(e) => setFilters((prev) => ({ ...prev, active: e.target.value }))}
            sx={{ maxWidth: 200 }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            <MenuItem value={true}>{t('Active')}</MenuItem>
            <MenuItem value={false}>{t('Disabled')}</MenuItem>
          </TextField>
        </Stack>
      </Stack>

      <Stack direction={'row'} spacing={2} sx={{ mt: 5, mb: 5.75 }}>
        <Button
          onClick={onFilter}
          fullWidth
          variant='contained'
          sx={{ maxWidth: 204, typography: 'body3', fontWeight: 500 }}
        >
          {t('Filter')}
        </Button>
        <Button
          onClick={onReset}
          fullWidth
          variant='outlined'
          color='inherit'
          sx={{ maxWidth: 80, typography: 'body3', fontWeight: 500, borderColor: 'grey.400' }}
        >
          {t('Reset')}
        </Button>
      </Stack>
    </ComplexToolbar>
  );
};

export default TokenToolbar;
