import { Box, IconButton, styled, TextField } from '@mui/material';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounced } from '../utils';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const TextFieldStyle = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 0,
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #7c829d',
      },
    },
  },
  '& input': {
    padding: '10px 16px 8px',
    color: theme.palette.grey[300],
    zIndex: 1,
    ...theme.typography.body3,
  },
  '& fieldset': {
    background: theme.palette.grey[700],
    border: '1px solid #555a72',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    background: theme.palette.grey[700],
    border: '1px solid #52576e',
  },
  '& .MuiFormLabel-root': {
    top: '-7px',
    '&.Mui-focused': {
      top: '0 !important',
    },
    '&.MuiFormLabel-filled': {
      top: 0,
    },
  },
}));

// ----------------------------------------------------------------------

export default function FillTextField({
  value,
  onChange,
  isDebounced = false,
  debounceDelay = 700,
  label,
  placeholder,
  children,
  hasCloseButton = false,
  sx = {},
  ...other
}) {
  const { t } = useTranslation();

  const [tempValue, setTempValue] = useState(value);
  useEffect(() => {
    setTempValue(value);
  }, [value]);

  const onChangeValue = useRef(
    debounced((v) => {
      onChange(v);
    }, debounceDelay)
  ).current;

  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <TextFieldStyle
        variant='outlined'
        label={t(label)}
        placeholder={t(placeholder)}
        value={tempValue}
        onChange={({ target }) => {
          setTempValue(target.value);

          if (isDebounced) {
            onChangeValue(target.value);
          } else {
            onChange(target.value);
          }
        }}
        sx={sx}
        {...other}
      >
        {children}
      </TextFieldStyle>

      {hasCloseButton && tempValue && (
        <IconButton
          onClick={() => {
            setTempValue('');
            onChangeValue('');
          }}
          sx={{ zIndex: 1, position: 'absolute', right: 1, top: 5 }}
        >
          <Iconify icon='akar-icons:cross' color='grey.0' sx={{ width: 12, height: 12 }} />
        </IconButton>
      )}
    </Box>
  );
}
