import { useEffect } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// import { useTranslation } from 'react-i18next';

export default function useGeneralForm(generalSetting) {
//   const { t } = useTranslation();

  const validators = Yup.object().shape({
    deactivatingMessage: Yup.string().notRequired(),
    deactivatingPurchase: Yup.boolean(),
    minimumAllowedPurchase: Yup.string().notRequired(),
    onlyIranianIps: Yup.boolean(),
  });

  const defaultValues = {
    deactivatingMessage: generalSetting.deactivatingMessage || "",
    deactivatingPurchase: generalSetting.deactivatingPurchase || false,
    minimumAllowedPurchase: generalSetting.minimumAllowedPurchase || "",
    onlyIranianIps: generalSetting.onlyIranianIps || false,
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (generalSetting) {
      reset(defaultValues);
    }
    if (!generalSetting) {
      reset(defaultValues);
    }
  }, [generalSetting]);

  return {
    handleSubmit,
    isSubmitting,
    methods,
  };
}
