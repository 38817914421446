import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ModalContainer from '../../../../components/ModalContainer';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';

import { prettyUppercases } from '../../../../utils';
import SymbolIcon from '../../../../components/SymbolIcon';
import { fFloat } from '../../../../utils/formatNumber';
import Scrollbar from '../../../../components/Scrollbar';

export default function TradeHistoryModal({ open, onClose, data, isAdmin = false }) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <ModalContainer open={open} onClose={onClose} title={'Details'} width={600}>
      <Stack sx={{ mt: 4.5, alignItems: 'center' }}>
        {data?.commissionAsset && <SymbolIcon symbol={data?.commissionAsset} width={80} height={80} />}
        <Typography variant='body2' color={'grey.100'} fontWeight={500} sx={{ mt: 2.5, mb: 2 }}>
          {data?.symbol}
        </Typography>

        <Box
          sx={{
            width: 120,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            mb: 3,
            p: 0.8,
            bgcolor:
              (data?.state === 'FILLED' && 'rgba(48, 224, 161, 0.1)') ||
              (data?.state === 'CANCELED' && 'rgba(224, 48, 48, 0.1)') ||
              'rgba(250, 174, 34, 0.1)',
          }}
        >
          <Typography
            variant='body2'
            sx={{
              color:
                (data?.state === 'FILLED' && '#30E0A1') ||
                (data?.state === 'CANCELED' && 'negative.main') ||
                'rgb(218, 146, 13)',
            }}
          >
            {t(prettyUppercases(data?.state))}
          </Typography>
        </Box>
      </Stack>

      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} sx={{ mb: 1.5 }} spacing={2}>
        {isAdmin && (
          <Stack spacing={1.5}>
            <Stack direction={'row'} alignItems={'center'} spacing={4}>
              <Typography variant='body3' color={'grey.400'} sx={{ width: 40 }}>
                {t('User')}
              </Typography>
              <Typography variant='body3' color={'grey.100'}>
                {data?.firstName} {data?.lastName}
              </Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} spacing={4}>
              <Typography variant='body3' color={'grey.400'} sx={{ width: 40 }}>
                {t('Mobile')}
              </Typography>
              <Typography variant='body3' color={'grey.100'}>
                {data?.mobile || '-'}
              </Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} spacing={4}>
              <Typography variant='body3' color={'grey.400'} sx={{ width: 40 }}>
                {t('Email')}
              </Typography>
              <Typography variant='body3' color={'grey.100'}>
                {data?.emailAddress || '-'}
              </Typography>
            </Stack>
          </Stack>
        )}

        <Stack spacing={1.5}>
          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Side')}
            </Typography>
            <Typography
              variant='body4'
              color={
                (data?.side === 'BUY' && 'positive.main') || (data?.side === 'SELL' && 'negative.main') || 'grey.100'
              }
            >
              {data?.side}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Type')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {t(prettyUppercases(data?.type))}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Date')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {moment(data?.createdDate).locale(language).format('YYYY/M/D')}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Tx-Code')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {data?.orderId || ''}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Tx Code')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {data?.transactionCode || ''}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Scrollbar>
        <Table size='small' sx={{ mt: 5, ...(isLight && { bgcolor: 'grey.700', px: 0.5, borderRadius: 1 }) }}>
          <TableHead>
            <TableRow sx={{ '& th': { typography: 'body2' } }}>
              <TableCell align='center'>#</TableCell>
              <TableCell align='left'>{t('Description')}</TableCell>
              <TableCell align='center'>
                {t('Amount')}/{t('Number')}
              </TableCell>
              <TableCell align='center'>{t('Unit price')}</TableCell>
              <TableCell align='center'>{t('Total Amount')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ '& td': { typography: 'body2' } }}>
            <TableRow>
              <TableCell align='center'>1</TableCell>
              <TableCell align='left'>
                {data?.side === 'BUY' ? t('Buy') : t('Sell')} {data?.symbol}
              </TableCell>
              <TableCell align='center'>
                {fFloat(data?.executed || data?.amount)} {data?.commissionAsset}
              </TableCell>
              <TableCell align='center'>{fFloat(data?.price)}</TableCell>
              <TableCell align='center'>{fFloat(data?.total)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='center'>2</TableCell>
              <TableCell align='left'>{t('Commission')}</TableCell>
              <TableCell align='center'>
                {fFloat(data?.commission)} {data?.commissionAsset}
              </TableCell>
              <TableCell align='center'>-</TableCell>
              <TableCell align='center'>-</TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={4} />
              <TableCell align='center' sx={{ color: 'grey.300', typography: 'body1' }}>
                {t('sum')}: {data?.total}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Scrollbar>

      <Button
        fullWidth
        variant='contained'
        size='large'
        sx={{
          typography: 'body3',
          fontWeight: 500,
          mt: 4.25,
        }}
        onClick={onClose}
      >
        {t('Close')}
      </Button>
    </ModalContainer>
  );
}
