import React from 'react';
import { Input } from 'antd';
import './inputCustom.scss';
import { useTheme } from '@mui/material';

// export const InputCustom = (props) => {

//     return (
//         <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
//             <Input
//                 className="inputCustom"
//                 bordered={false}
//                 onChange={props.onChange}
//                 value={props.value}
//                 type={props.type}
//                 placeholder={props.placeholder}
//                 disabled={props.disabled}
//             />

//             { props.iconName && <props.iconName style={{ marginLeft: "-25px", fontSize: "18px" }} /> }
//         </div>
//     )
// }

export const InputCustom = (props) => {
  const theme = useTheme();
  const [focus, setFocus] = React.useState(false);

  let { label, value, placeholder, type, required, variant } = props;
  if (!placeholder) placeholder = label;
  const isOccupied = focus || (value && value.length !== 0);
  const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';
  const requiredMark = required ? <span className='text-danger'></span> : null;
  // const requiredMark = required ? <span className="text-danger">*</span> : null;

  return (
    <div className={`float-label ${variant || ''}`} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      {type === 'password' ? (
        <Input.Password
          className={theme.palette.mode === 'dark' ? 'inputCustom' : 'inputCustomLight'}
          size='small'
          maxLength={props.maxLength}
          minLength={props.maxLength}
          pattern={props.pattern}
          value={props.value}
          bordered={false}
          onChange={props.onChange}
          defaultValue={value}
          disabled={props.disabled}
        />
      ) : (
        <>
          {variant === 'filled' ? (
            <Input
              className={theme.palette.mode === 'dark' ? 'inputCustomFilled' : 'inputCustomFilledLight'}
              size='small'
              maxLength={props.maxLength}
              minLength={props.maxLength}
              pattern={props.pattern}
              value={props.value}
              bordered={true}
              onChange={props.onChange}
              type={type}
              defaultValue={value}
              disabled={props.disabled}
            />
          ) : (
            <Input
              className={theme.palette.mode === 'dark' ? 'inputCustom' : 'inputCustomLight'}
              size='small'
              maxLength={props.maxLength}
              minLength={props.maxLength}
              pattern={props.pattern}
              value={props.value}
              bordered={false}
              onChange={props.onChange}
              type={type}
              defaultValue={value}
              disabled={props.disabled}
            />
          )}
        </>
      )}
      <label className={`${labelClass} ${variant || ''} ${theme.palette.mode}`}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>

      {props.iconName && <props.iconName style={{ marginLeft: '-25px', fontSize: '18px' }} />}
    </div>
  );
};
