import React from 'react';
import { TableRow, TableCell, Typography, Box, Stack } from '@mui/material';

import SymbolIcon from '../../../../../../components/SymbolIcon';
import { useTranslation } from 'react-i18next';
import Image from '../../../../../../components/Image';
import plus from '../../../../../../assets/plus.svg';

const WalletTableRow = ({ row, setShowModal, setSelectedSymbol }) => {
  const { t } = useTranslation();
  const { name, symbol, amount, blocked, available, btcValue, usdtValue, usdValue } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1, typography: 'body2' } }}>
      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <SymbolIcon symbol={symbol} />
          <Typography
            variant='body2'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 140,
              whiteSpace: 'nowrap',
              display: 'block',
              ml: 2,
            }}
          >
            {name}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell align='center'>{amount?.toLocaleString('en-US', { minimumFractionDigits: 3 })}</TableCell>
      <TableCell align='center'>{blocked?.toLocaleString('en-US', { minimumFractionDigits: 3 })}</TableCell>
      <TableCell align='center'>{available?.toLocaleString('en-US', { minimumFractionDigits: 3 })}</TableCell>
      <TableCell align='center'>{btcValue?.toLocaleString('en-US', { minimumFractionDigits: 3 })}</TableCell>
      <TableCell align='center'>{usdtValue?.toLocaleString('en-US', { minimumFractionDigits: 3 })}</TableCell>
      <TableCell align='center'>{usdValue?.toLocaleString('en-US', { minimumFractionDigits: 3 })}</TableCell>

      <TableCell align='center'>
        <Box
          sx={{
            width: 105,
            borderRadius: '8px',
            textAlign: 'center',
            m: 'auto',
            p: 0.8,
            bgcolor: 'rgba(48, 224, 161, 0.2)',
            cursor: 'pointer',
          }}
          onClick={() => {
            setShowModal(true);
            setSelectedSymbol(row);
          }}
        >
          <Typography
            variant='body2'
            color={'#30E0A1'}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Image src={plus} sx={{ width: 12, height: 12, mr: 1.25 }} />
            {t('Create')}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default WalletTableRow;
