import { LoadingButton } from '@mui/lab';
import { TableRow, TableCell, Typography, Stack, Box } from '@mui/material';
import moment from 'jalali-moment';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Iconify from '../../../../components/Iconify';
import Api from '../../../../http/serviceApi';
import confirmWrapper from '../../../../utils/confirm';
import { prettyUppercases } from '../../../../utils';
import { fFloat } from '../../../../utils/formatNumber';

// ----------------------------------------------------------------------

export default function P2PHistoryTableMakeRow({ row, onReload, isAdmin = false }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const {
    id,
    amount,
    firstName,
    lastName,
    emailAddress,
    mobile,
    createdDate,
    remainderAmount,
    price,
    side,
    symbol,
    state,
    minLimit,
    maxLimit,
    transactionCode,
  } = row;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onDelete = async () => {
    if (!(await confirmWrapper(t('Are you sure to remove this order?')))) {
      return false;
    }

    setIsSubmitting(true);

    try {
      const res = await Api.deleteP2POrder(id);
      if (res.status === 'SUCCESS') {
        enqueueSnackbar(res.message || 'Deleted', { variant: 'success' });
        onReload();
      }
    } catch (e) {}

    setIsSubmitting(false);
    return true;
  };

  return (
    <TableRow hover sx={{ '& td': { py: 2, typography: 'body2', color: 'grey.100', px: 1 } }}>
      <TableCell sx={{ pl: '16px !important' }}>
        {moment(createdDate).locale(language).format('YYYY-MM-DD HH:mm')}
      </TableCell>

      {isAdmin && (
        <TableCell sx={{ width: 200 }}>
          <Typography variant='body3'>
            {firstName} {lastName}
          </Typography>
          {emailAddress && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {emailAddress}
            </Typography>
          )}
          {mobile && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {mobile}
            </Typography>
          )}
        </TableCell>
      )}

      <TableCell align='center'>
        <Typography
          variant='body2'
          color={(side === 'BUY' && 'positive.main') || (side === 'SELL' && 'negative.main') || 'grey.100'}
        >
          {side}
        </Typography>
      </TableCell>

      <TableCell>
        {fFloat(price)}
        <Typography variant='body3' color='grey.300' sx={{ ml: 1 }}>
          USD
        </Typography>
      </TableCell>

      <TableCell>
        {fFloat(amount)}
        <Typography variant='body3' color='grey.300' sx={{ ml: 1 }}>
          {symbol}
        </Typography>
      </TableCell>

      <TableCell align='center'>{Math.round((remainderAmount / amount) * 100)} %</TableCell>

      <TableCell>
        <Stack direction={'row'} spacing={1.6} alignItems={'center'} sx={{ width: 160 }}>
          <Stack spacing={0.5} sx={{ opacity: 0.8 }}>
            <Typography variant='body4'>{t('Available')}</Typography>
            <Typography variant='body4'>{t('Limits')}</Typography>
          </Stack>

          <Stack spacing={0.5}>
            <Typography variant='body3'>
              {fFloat(remainderAmount)}
              <Typography variant='body3' color='grey.300' sx={{ ml: 1 }}>
                {symbol}
              </Typography>
            </Typography>
            <Typography variant='body3'>
              ${fFloat(minLimit * price)} {maxLimit ? `- ${maxLimit * price}` : ''}
              <Typography variant='body3' color='grey.400' sx={{ ml: 1 }}>
                USD
              </Typography>
            </Typography>
          </Stack>
        </Stack>
      </TableCell>

      <TableCell align='center'>{transactionCode}</TableCell>

      <TableCell sx={{ width: 170 }} align='center'>
        <Box
          sx={{
            width: 160,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            p: 1,
            bgcolor:
              ((state === 'COMPLETED' || state === 'PARTIALLY_COMPLETED') && 'rgba(48, 224, 161, 0.1)') ||
              ((state === 'CANCELED' || state === 'FAILED') && 'rgba(224, 48, 48, 0.1)') ||
              'rgba(250, 174, 34, 0.1)',
          }}
        >
          <Typography
            variant='body3'
            sx={{
              color:
                ((state === 'COMPLETED' || state === 'PARTIALLY_COMPLETED') && '#30E0A1') ||
                ((state === 'CANCELED' || state === 'FAILED') && 'negative.main') ||
                'rgb(218, 146, 13)',
              lineHeight: 1,
            }}
          >
            {t(prettyUppercases(state))}
          </Typography>
        </Box>
      </TableCell>

      {/*{!isAdmin && (*/}
      {/*  <TableCell align='right' sx={{ width: 135, pr: '16px !important' }}>*/}
      {/*    {state === 'PENDING' && (*/}
      {/*      <LoadingButton*/}
      {/*        color='error'*/}
      {/*        variant='outlined'*/}
      {/*        loading={isSubmitting}*/}
      {/*        onClick={onDelete}*/}
      {/*        startIcon={<Iconify icon='uil:trash-alt' sx={{ width: 20, height: 20 }} />}*/}
      {/*        sx={{ typography: 'subtitle3', borderRadius: 0 }}*/}
      {/*      >*/}
      {/*        {t('Delete')}*/}
      {/*      </LoadingButton>*/}
      {/*    )}*/}
      {/*  </TableCell>*/}
      {/*)}*/}
    </TableRow>
  );
}
