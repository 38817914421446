import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';

import ComplexToolbar from '../../../components/ComplexToolbar';
import DatePicker from '../../../components/DatePicker';
import NumberRange from '../../../components/NumberRange';
import NumRangeWithInput from '../../../components/NumRangeWithInput';

const AutomaticDepositHistoryToolbar = ({ onFilter, onExport, isExportLoading }) => {
  const { t } = useTranslation();

  const [balanceFilter, setBalanceFilter] = useState([0, 50000]);
  const [dateFromFilter, setDateFromFilter] = useState(null);
  const [dateToFilter, setDateToFilter] = useState(null);

  const handleFilter = () => {
    onFilter({ balanceFilter, dateFromFilter, dateToFilter });
  };

  const resetFilter = () => {
    setBalanceFilter([0, 50000]);
    setDateFromFilter(null);
    setDateToFilter(null);

    onFilter({
      balanceFilter: [0, 50000],
      dateFromFilter: null,
      dateToFilter: null,
    });
  };

  return (
    <ComplexToolbar
      onSearch={null}
      onExport={onExport}
      onPrint={() => console.log('print')}
      isExportLoading={isExportLoading}
    >
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} sx={{ mt: 2 }}>
          <Stack direction={'row'}>
            <DatePicker
              label={t('From')}
              value={dateFromFilter}
              setValue={setDateFromFilter}
              variant='standard'
              maxDate={dateToFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
              InputProps={null}
            />
            <DatePicker
              label={t('To')}
              value={dateToFilter}
              setValue={setDateToFilter}
              variant='standard'
              minDate={dateFromFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
            />
          </Stack>
          {/*<NumberRange label={t('Balance')} value={balanceFilter} setValue={setBalanceFilter} sx={{ maxWidth: 300 }} />*/}
          <NumRangeWithInput label={t('Balance')} value={balanceFilter} setValue={setBalanceFilter} sx={{ maxWidth: 300 }} />

        </Stack>
      </Stack>

      <Stack direction={'row'} spacing={2} sx={{ mt: 5, mb: 5.75 }}>
        <Button
          onClick={handleFilter}
          fullWidth
          variant='contained'
          sx={{ maxWidth: 204, typography: 'body3', fontWeight: 500 }}
        >
          {t('Filter')}
        </Button>
        <Button
          onClick={resetFilter}
          fullWidth
          variant='outlined'
          color='inherit'
          sx={{ maxWidth: 80, typography: 'body3', fontWeight: 500, borderColor: 'grey.400' }}
        >
          {t('Reset')}
        </Button>
      </Stack>
    </ComplexToolbar>
  );
};

export default AutomaticDepositHistoryToolbar;
