import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, Divider, Grid, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FormProvider, RHFNumberField, RHFSelect, RHFSwitch, RHFTextField } from '../hookForm';
import { RHFCustomUploadImage } from '../hookForm/RHFUpload';
import React ,{ useState } from 'react';
import Iconify from '../Iconify';
import Api from '../../http/adminHttp/serviceApiAdmin';
import { useSnackbar } from 'notistack';
import { imageToBase64 } from '../../utils';
import { LoadingButton } from '@mui/lab';
import { imageUrlToBase64 } from '../../utils/image';
import Scrollbar from '../Scrollbar';
import ActiveCryptoForTokenListing from '../activeCryptoForTokenListing/activeCryptoForTokenListing';
import { PageTitle } from '../PageTitle';

const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

export const TokenForm = ({ mode, editData }) => {
  const { t } = useTranslation();
  const [image, setImage] = useState(null);
  const [openSymbolModal , setOpenSymbolModal] = useState(false)
  const validators = Yup.object().shape({
    symbol: Yup.string().required(),
    name: Yup.string().required(),
    isActive: Yup.boolean().required(),
    futuresActive: Yup.boolean().required(t('This is a required field')),
    tradeActive: Yup.boolean().required(t('This is a required field')),
    convertActive: Yup.boolean().required(t('This is a required field')),
    image: Yup.string(),
    networkInfoList: Yup.array().of(
      Yup.object().shape({
        contractAddress: Yup.string().required(t('This is a required field')),
        decimal: Yup.number().typeError(t('Please enter a valid number')).required(t('This is a required field')),
        isActive: Yup.boolean().required(t('This is a required field')),
        withdrawActive: Yup.boolean().required(t('This is a required field')),
        depositActive: Yup.boolean().required(t('This is a required field')),
        network: Yup.string().required(t('This is a required field')),
        networkConfirmation: Yup.number()
          .typeError(t('Please enter a valid number'))
          .required(t('This is a required field')),
        withdrawFee: Yup.number().typeError(t('Please enter a valid number')).required(t('This is a required field')),
        minWithdrawAmount: Yup.number()
          .typeError(t('Please enter a valid number'))
          .required(t('This is a required field')),
      })
    ),
    listedTokenTradePairInfoRes: Yup.array().of(
      Yup.object().shape({
        active : Yup.boolean().typeError(t('This is a required field')),
        currencySymbol:Yup.string().typeError(t('This is a required field')),
        minNotional: Yup.string().typeError(t('Please enter a valid number')),
        tradeAmountLotSize: Yup.string().typeError(t('Please enter a valid number')),
        tradeMaxAmount:  Yup.string().typeError(t('Please enter a valid number')),
        tradeMaxPrice: Yup.string().typeError(t('Please enter a valid number')),
        tradeMinAmount: Yup.string().typeError(t('Please enter a valid number')),
        tradePriceTickSize:  Yup.string().typeError(t('Please enter a valid number')),
      })
    )
  });

  const defaultValues = editData
    ? {
        ...editData,
        image: editData.imageUrl,
      }
    : {
        symbol: '',
        name: '',
        isActive: true,
        futuresActive:false,
        tradeActive:false,
        convertActive:false,
        image: '',
        networkInfoList: [
          {
            contractAddress: '',
            decimal: 0,
            isActive: true,
            withdrawActive: false,
            depositActive: false,
            network: 'BSC',
            networkConfirmation: null,
            withdrawFee: null,
            minWithdrawAmount: null,
          },
        ],
      listedTokenTradePairInfoRes:[

      ]
      };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    control,
    getValues,
    formState: { isSubmitting ,errors},
  } = methods;

  console.log(getValues() , errors)
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'networkInfoList',
  });

  const { fields: pairFields, append : pairAppend, remove  : pairRemove} = useFieldArray({
    control,
    name: 'listedTokenTradePairInfoRes',
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (payload) => {
    console.log(control , payload)
    const img =
      image && !isValidUrl(image)
        ? await imageToBase64(image)
        : isValidUrl(editData.imageUrl)
        ? await imageUrlToBase64(editData.imageUrl)
        : undefined;

    const d = ()=>{
      let a = []
      for (let i in payload?.listedTokenTradePairInfoRes){
          a.push({
            ...payload?.listedTokenTradePairInfoRes[i],
            active :payload?.listedTokenTradePairInfoRes[i]?.active,
            currencySymbol: payload?.listedTokenTradePairInfoRes[i]?.currencySymbol,
            minNotional: parseFloat(payload?.listedTokenTradePairInfoRes[i]?.minNotional),
            tradeAmountLotSize: parseFloat(payload?.listedTokenTradePairInfoRes[i]?.tradeAmountLotSize),
            tradeMaxAmount:  parseFloat(payload?.listedTokenTradePairInfoRes[i]?.tradeMaxAmount),
            tradeMaxPrice: parseFloat(payload?.listedTokenTradePairInfoRes[i]?.tradeMaxPrice),
            tradeMinAmount: parseFloat(payload?.listedTokenTradePairInfoRes[i]?.tradeMinAmount),
            tradePriceTickSize: parseFloat(payload?.listedTokenTradePairInfoRes[i]?.tradePriceTickSize),
          })
      }
      return a
    }
    const data = {
      ...payload,
      listedTokenTradePairInfos : d(),
      image: img,
    };
    try {
      let response;
      if (mode === 'add') {
        response = await Api.addToken(data);
      } else {
        response = await Api.editToken(data);
      }
      if (response?.status === 'SUCCESS') {
        enqueueSnackbar(t('The token successfully saved!'), { variant: 'success' });
      }
    } catch (err) {}
  };

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setImage(file);

      setValue(
        'image',
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
    }
  };

  return (
    <Stack sx={{ padding: { xs: '0 20px 20px', lg: mode === 'add' ? '20px 0 97px' : '' } }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card
          sx={{
            px: { xs: 2, md: 5 },
            py: { xs: 3, md: 5 },
            height: '100%',
            boxShadow: mode !== 'create' ? 'none' : undefined,
          }}
        >
          <Scrollbar>
            <Grid container spacing={4} mb={4}>
              <Grid item xs={12} md={4} lg={3} xl={2}>
                <RHFTextField name={'name'} label={t('Name')} variant='standard' fullWidth />
              </Grid>
              <Grid item xs={12} md={4} lg={3} xl={2}>
                <RHFTextField name={'symbol'} label={t('Symbol')} variant='standard' fullWidth />
              </Grid>
              <Grid container item spacing={4} mb={4}>
                <Grid  xs={12} md={4} lg={3} xl={2} item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <RHFSwitch name={'isActive'} label={t('Is active')} variant='standard' fullWidth />
                    </Grid>
                    <Grid item>
                      <Typography variant='body3'>{t('Is active')}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid  xs={12} md={4} lg={3} xl={2} item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <RHFSwitch name={'futuresActive'} label={t('Futures active')} variant='standard' fullWidth />
                    </Grid>
                    <Grid item>
                      <Typography variant='body3'>{t('Futures active')}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid  xs={12} md={4} lg={3} xl={2} item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <RHFSwitch name={'tradeActive'} label={t('Trade active')} variant='standard' fullWidth />
                    </Grid>
                    <Grid item>
                      <Typography variant='body3'>{t('Trade active')}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid  xs={12} md={4} lg={3} xl={2} item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <RHFSwitch name={'convertActive'} label={t('Convert active')} variant='standard' fullWidth />
                    </Grid>
                    <Grid item>
                      <Typography variant='body3'>{t('Convert active')}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} pt={3}>
                  <Grid item>
                    <RHFCustomUploadImage
                      sx={{ width: '70px', height: '70px' }}
                      name='image'
                      accept='image/*'
                      showChangePhotoLabel
                      hideText
                      onDrop={handleDrop}
                    />
                  </Grid>
                  <Grid item flexDirection={'column'} display={'flex'}>
                    <Typography variant='body3' mb={0.5}>
                      {t('We highly recommended to use the following image properties:')}
                    </Typography>
                    <Typography variant='body4'>{t('Width')}: 40px</Typography>
                    <Typography variant='body4'>{t('Height')}: 40px</Typography>
                    <Typography variant='body4'>
                      {t('Background')}: {t('Transparent')}
                    </Typography>
                    <Typography variant='body4'>
                      {t('Type')}: {t('Rounded')}
                    </Typography>
                    <Typography variant='body4'>
                      {t('Allowed types')}:{' '}
                      <Typography variant='body4' fontWeight={'bold'}>
                        jpg, png
                      </Typography>
                    </Typography>
                    <Typography variant='body4'>{t('Max filesize')}: 1MB</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <PageTitle title={t('Networks')} subTitle='' />

            {fields.map((field, index) => (
              <Box key={field.id}>
                {index !== 0 && <Divider sx={{ my: 4 }} variant='middle'></Divider>}
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={12} md={4} lg={3} xl={2}>
                    <RHFTextField
                      name={`networkInfoList.${index}.contractAddress`}
                      label={t('Contract address')}
                      variant='standard'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} xl={2}>
                    <RHFNumberField
                      name={`networkInfoList.${index}.decimal`}
                      label={t('decimal')}
                      variant='standard'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} xl={2}>
                    <RHFNumberField
                      name={`networkInfoList.${index}.minWithdrawAmount`}
                      label={t('Min withdraw amount')}
                      variant='standard'
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={3} xl={2}>
                    <RHFNumberField
                      name={`networkInfoList.${index}.withdrawFee`}
                      label={t('Withdraw fee')}
                      variant='standard'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} xl={2}>
                    <RHFNumberField
                      name={`networkInfoList.${index}.networkConfirmation`}
                      label={t('Network confirmation')}
                      variant='standard'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} xl={2}>
                    <RHFSelect
                      name={`networkInfoList.${index}.network`}
                      SelectProps={{
                        native: false,
                        MenuProps: {
                          sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' } },
                        },
                      }}
                      label={t('Network')}
                      variant='standard'
                      fullWidth
                    >
                      <MenuItem value={'BSC'}>
                        <Typography typography='body3' color='grey.300'>
                          {t('BSC')}
                        </Typography>
                      </MenuItem>
                      <MenuItem value={'ETH'}>
                        <Typography typography='body3' color='grey.300'>
                          {t('ETH')}
                        </Typography>
                      </MenuItem>
                      <MenuItem value={'MATIC'}>
                        <Typography typography='body3' color='grey.300'>
                          {t('MATIC')}
                        </Typography>
                      </MenuItem>
                    </RHFSelect>
                  </Grid>
                  <Grid item xs={12} md={2} textAlign={'right'} mt={-3} ml={2}>
                    <IconButton onClick={() => remove(index)}>
                      <Iconify icon='eva:trash-2-outline' width={24} height={24} color='grey.100' />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container mt={4}>
                  <Grid xs={12} md={4} lg={3} xl={2}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <RHFSwitch
                          name={`networkInfoList.${index}.isActive`}
                          label={t('Is active')}
                          variant='standard'
                          fullWidth
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant='body3'>{t('Is active')}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={4} lg={3} xl={2}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <RHFSwitch name={`networkInfoList.${index}.withdrawActive`} variant='standard' fullWidth />
                      </Grid>
                      <Grid item>
                        <Typography variant='body3'>{t('Withdraw active')}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={4} lg={3} xl={2}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <RHFSwitch name={`networkInfoList.${index}.depositActive`} variant='standard' fullWidth />
                      </Grid>
                      <Grid item>
                        <Typography variant='body3'>{t('Deposit active')}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            ))}

            <Grid item xs={12}>
              <Button
                variant='outlined'
                color='inherit'
                size='large'
                sx={{ width: 204, mt: 5 }}
                onClick={() =>
                  append({
                    contractAddress: '',
                    decimal: 0,
                    isActive: true,
                    network: 'BSC',
                    withdrawFee: null,
                    minWithdrawAmount: null,
                  })
                }
              >
                {t('Add network')}
              </Button>
            </Grid>
            <Divider sx={{mt:'1rem'}} />
            <Divider   />
            <Divider   />
            <Divider   />
            <Divider sx={{mb:'1rem'}} />
            <PageTitle title={t('Trade Setting for Tokens')} subTitle={pairFields?.length === 0 ? t('No currency pair yet') : ''} />

            {pairFields.map((field, index) => {
              console.log('ssss' ,field?.currencySymbol ,field)
             return <Box key={field.id}>
                {index !== 0 && <Divider sx={{ my: 4 }} variant='middle'></Divider>}
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={12} md={4} lg={3} xl={2}>
                    <RHFTextField
                      name={`listedTokenTradePairInfoRes.${index}.currencySymbol`}
                      label={t('Currency Symbol')}
                      variant='standard'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} xl={2}>
                    <RHFNumberField
                      name={`listedTokenTradePairInfoRes.${index}.minNotional`}
                      label={t('Min notional')}
                      variant='standard'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} xl={2}>
                    <RHFNumberField
                      name={`listedTokenTradePairInfoRes.${index}.tradeAmountLotSize`}
                      label={t('Trade amount lot size')}
                      variant='standard'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} xl={2}>
                    <RHFNumberField
                      name={`listedTokenTradePairInfoRes.${index}.tradePriceTickSize`}
                      label={t('Trade price tick size')}
                      variant='standard'
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={3} xl={2}>
                    <RHFNumberField
                      name={`listedTokenTradePairInfoRes.${index}.tradeMaxAmount`}
                      label={t('Trade Max amount')}
                      variant='standard'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} xl={2}>
                    <RHFNumberField
                      name={`listedTokenTradePairInfoRes.${index}.tradeMaxPrice`}
                      label={t('Trade max price')}
                      variant='standard'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} xl={2}>
                    <RHFNumberField
                      name={`listedTokenTradePairInfoRes.${index}.tradeMinAmount`}
                      label={t('Trade min amount')}
                      variant='standard'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={2} textAlign={'center'} mt={-3} ml={2} style={{display:"flex" , flexDirection:"row" , alignItems:"end" , justifyContent:"center"}}>
                    <IconButton onClick={() => pairRemove(index)}>
                      <Iconify icon='eva:trash-2-outline' width={24} height={24} color='grey.100' />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container mt={4}>

                  <Grid xs={12} md={4} lg={3} xl={2}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <RHFSwitch
                          name={`listedTokenTradePairInfoRes.${index}.active`}
                          label={t('Is active')}
                          variant='standard'
                          fullWidth
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant='body3'>{t('Is active')}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>



                </Grid>
              </Box>
            })}


            <Grid  container spacing={2}>

                <ActiveCryptoForTokenListing
                  open={openSymbolModal}
                  onClose={() => setOpenSymbolModal(false)}
                  onSelect={(e)=>{

                    pairAppend({
                      active : true,
                      currencySymbol:e,
                      minNotional: 0,
                      tradeAmountLotSize: 0,
                      tradeMaxAmount:  0,
                      tradeMaxPrice: 0,
                      tradeMinAmount: 0,
                      tradePriceTickSize:  0,
                    })
                  }}
                  title='Select Symbol'
                />

              <Grid  xs={12} md={4} lg={3} xl={2} item>
                <Button
                  variant='outlined'
                  color='inherit'
                  size='large'
                  sx={{ width: 204, mt: 5 , mb:1}}
                  onClick={() =>setOpenSymbolModal(true)}
                >
                  {t('Add Pair Currency')}
                </Button>
              </Grid>

            </Grid>

          </Scrollbar>
        </Card>
        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ maxWidth: 300, typography: 'body3', fontWeight: 500, mt: 5 }}
        >
          {t(mode === 'add' ? 'Add Token' : 'Edit Token')}
        </LoadingButton>
      </FormProvider>
    </Stack>
  );
};
