import { useTranslation } from 'react-i18next';
import Api from '../../../../../http/adminHttp/serviceApiAdmin';
import { useSnackbar } from 'notistack';

export default function useGatewaySubmit() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async (payload) => {
    const response = await Api.editInventorySetting({
      perfectMoneyInventoryAmount: payload.perfectMoneyInventoryAmount,
      perfectMoneyInventoryStatus: payload.perfectMoneyInventoryStatus,
      tetherInventoryAmount: payload.tetherInventoryAmount,
      tetherInventoryStatus: payload.tetherInventoryStatus,
    });
    if (response?.status === 'SUCCESS') {
      enqueueSnackbar(t('The values saved successfully.'), { variant: 'success' });
    }
  };

  return {
    onSubmit,
  };
}
