import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Stack, MenuItem, Avatar, useTheme, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import MenuPopover from '../MenuPopover';
import Image from '../Image';

import dashboardIcon from '../../assets/sideMenu/dashboard.svg';
import shieldTickIcon from '../../assets/sideMenu/shield-tick.svg';
import refreshCircleIcon from '../../assets/sideMenu/refresh-circle.svg';
import profileAddIcon from '../../assets/sideMenu/profile-add.svg';
import walletIcon from '../../assets/sideMenu/wallets.svg';
import logoutIcon from '../../assets/sideMenu/logout.svg';
import accountSettingIcon from '../../assets/sideMenu/userOctagon.svg';

import dashboardIconLight from '../../assets/sideMenu/dashboard-light.svg';
import shieldTickIconLight from '../../assets/sideMenu/shield-tick-light.svg';
import refreshCircleIconLight from '../../assets/sideMenu/refresh-circle-light.svg';
import profileAddIconLight from '../../assets/sideMenu/profile-add-light.svg';
import walletIconLight from '../../assets/sideMenu/wallets-light.svg';
import logoutIconLight from '../../assets/sideMenu/logout-light.svg';
import accountSettingIconLight from '../../assets/sideMenu/userOctagon-light.svg';

import crownIcon from '../../assets/sideMenu/crown.svg';
import Iconify from '../Iconify';
import Api from '../../http/serviceApi';
import AdminApi from '../../http/adminHttp/serviceApiAdmin';
import { SET_SIGNOUT } from '../../store/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import useResponsive from '../../hooks/useResponsive';

import userAvatar from '../../assets/user.svg';
import { navigationUrl } from '../../navigationUrl';
import { prettyUppercases } from '../../utils';

// ----------------------------------------------------------------------

export default function AccountPopover({ showFull = true, isAdmin = false }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isTablet = useResponsive('up', 'md');

  const USER_MENU_OPTIONS = [
    {
      label: 'Dashboard',
      linkTo: navigationUrl.panel,
      icon: theme.palette.mode === 'dark' ? dashboardIcon : dashboardIconLight,
    },
    {
      label: 'Security',
      linkTo: navigationUrl.accountSetting,
      icon: theme.palette.mode === 'dark' ? shieldTickIcon : shieldTickIconLight,
    },
    {
      label: 'Identification',
      linkTo: navigationUrl.auth,
      icon: theme.palette.mode === 'dark' ? refreshCircleIcon : refreshCircleIconLight,
    },
    {
      label: 'Referral',
      linkTo: navigationUrl.referrals_income,
      icon: theme.palette.mode === 'dark' ? profileAddIcon : profileAddIconLight,
    },
    {
      label: 'Wallet',
      linkTo: navigationUrl.wallet,
      icon: theme.palette.mode === 'dark' ? walletIcon : walletIconLight,
    },
  ];

  const ADMIN_MENU_OPTIONS = [
    {
      label: 'Dashboard',
      linkTo: navigationUrl.admin,
      icon: theme.palette.mode === 'dark' ? dashboardIcon : dashboardIconLight,
    },
    {
      label: 'Account Setting',
      linkTo: navigationUrl.admin_account_setting,
      icon: theme.palette.mode === 'dark' ? accountSettingIcon : accountSettingIconLight,
    },
    {
      label: 'Profile',
      linkTo: navigationUrl.admin_edit(0),
      icon: theme.palette.mode === 'dark' ? profileAddIcon : profileAddIconLight,
    },
    {
      label: 'Security verification',
      linkTo: navigationUrl.admin_setting_verification,
      icon: theme.palette.mode === 'dark' ? shieldTickIcon : shieldTickIconLight,
    },
  ];

  const [open, setOpen] = useState(null);
  const dispatch = useDispatch();
  const userInfo = useSelector((store) => store.userInfo);
  const adminUserInfo = useSelector((store) => store.adminUserInfo);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    if (isAdmin) {
      const response = await AdminApi.signOut();
      if (response?.status === 'SUCCESS') {
        dispatch({
          type: SET_SIGNOUT,
        });

        window.localStorage.removeItem('tokenAdmin');
        navigate(navigationUrl.admin_login);
      }
    } else {
      const response = await Api.signOut();
      if (response?.status === 'SUCCESS') {
        dispatch({
          type: SET_SIGNOUT,
        });

        window.localStorage.removeItem('token');
        navigate(navigationUrl.login);
      }
    }
  };

  const user = isAdmin ? adminUserInfo : userInfo;

  let status = 'Verified';
  const authLevel = user?.authLevel || '';
  if (authLevel.indexOf('_REJECTED') !== -1) {
    status = 'Rejected';
  } else if (authLevel.indexOf('_REQUESTED') !== -1) {
    status = 'Pending';
  } else if (authLevel === 'REGISTERED') {
    status = '';
  }

  return (
    <>
      <Button sx={{ pl: 1.5 }} onClick={handleOpen}>
        <Stack direction='row' alignItems={'center'} spacing={2} justifyContent={'space-between'}>
          {showFull && isTablet && (
            <Typography variant='subtitle2_secondary' sx={{ fontWeight: 900 }}>
              {user?.firstName || ''} {user?.lastName || ''}
            </Typography>
          )}

          <Avatar src={userAvatar} alt='' sx={{ width: 38, height: 38 }} />

          {showFull && isTablet && (
            <Iconify icon='eva:arrow-ios-downward-fill' color='grey.0' sx={{ width: 25, height: '100%' }} />
          )}
        </Stack>
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          width: 270,
          background: theme.palette.grey[800],
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >

        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ mb: 1.5 }}>
          <Box sx={{ my: 1.5, pl: 3, pr: 2 }}>
            <Typography variant='body3' color='grey.300' noWrap>
              {user?.firstName || ''} {user?.lastName || ''}
            </Typography>
          </Box>
          <Box sx={{ my: 1.5, pl: 3, pr: 2 }}>
            <Typography variant='body3' color='grey.300' noWrap>
              {'User ID: '} {user?.userId || ''}
            </Typography>
          </Box>

        </Stack>


        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ mb: 1.5 }}>
          <Stack direction='row' alignItems={'center'} spacing={1.8} sx={{ ml: 3 }}>
            {status && <Image src={crownIcon} alt='' />}
            <Typography variant='body3' color='#F27F16' noWrap>
              {isAdmin
                ? t('Management')
                : t(
                    user?.authLevel
                      ? prettyUppercases(user?.authLevel.replace('_REQUESTED', '').replace('_REQUESTED', ''))
                      : 'Regular user'
                  )}
            </Typography>
          </Stack>

          {status && (
            <Stack
              direction={'row'}
              alignItems={'center'}
              spacing={0.5}
              sx={{
                backgroundColor: status === 'Verified' ? 'rgba(17, 202, 190, 0.1)' : 'rgb(202 116 17 / 10%)',
                px: 1,
                borderRadius: '8px 0px 0px 8px',
              }}
            >
              {status === 'Verified' && (
                <Iconify icon='humbleicons:verified' color='positive.main' sx={{ width: 15, height: 15 }} />
              )}
              <Typography variant='body3' color={status === 'Verified' ? 'positive.main' : '#F27F16'}>
                {t(status)}
              </Typography>
            </Stack>
          )}
        </Stack>

        <Typography variant='body4' color='grey.400' sx={{ px: 2.7 }}>
          {isAdmin ? t('Admin') : t('Market')}
        </Typography>

        <Stack sx={{ p: 1 }}>
          {(isAdmin ? ADMIN_MENU_OPTIONS : USER_MENU_OPTIONS).map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => {
                navigate(option.linkTo);
                handleClose();
              }}
            >
              <Image src={option.icon || ''} alt='' sx={{ width: 22, mr: 2 }} />

              <Typography variant='body3' color='grey.300' noWrap>
                {t(option.label)}
              </Typography>
            </MenuItem>
          ))}

          <MenuItem onClick={handleLogout} sx={{ backgroundColor: 'grey.700' }}>
            <Image
              src={theme.palette.mode === 'dark' ? logoutIcon : logoutIconLight}
              alt=''
              sx={{ width: 22, mr: 2 }}
            />
            <Typography variant='body3' color='grey.300' noWrap>
              {t('Log out')}
            </Typography>
          </MenuItem>
        </Stack>
      </MenuPopover>
    </>
  );
}
