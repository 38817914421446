import React from 'react';
import { useTranslation } from 'react-i18next';
import useResponsive from '../../../hooks/useResponsive';
import { Box, Typography, useTheme } from '@mui/material';
import Chart from 'react-apexcharts';
import mergeDeep from '../../../utils/merge';


const commonOptions = {
  chart: {
    type: 'radialBar',
    sparkline: {
      enabled: true,
    },
  },
  series: [67],
  grid: {
    padding: {
      top: 0,
      bottom: 0,
    },
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '55%',
        background: 'transparent',
      },
      dataLabels: {
        showOn: 'always',
        name: {
          show: false,
        },
        value: {
          offsetY: 9,
          color: '#fff',
          fontSize: '25px',
          show: false,
        },
      },
      track: {
        background: '#34384C',
      },
    },
  },
  stroke: {
    curve: 'smooth',
    lineCap: 'round',
  },
  labels: [''],
};

const greenOptions = mergeDeep(
  { ...commonOptions },
  {
    fill: {
      type: 'solid',
      colors: ['#11CABE'],
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          value: {
            color: '#11CABE',
          },
        },
      },
    },
  }
);

const redOptions = mergeDeep(
  { ...commonOptions },
  {
    fill: {
      type: 'solid',
      colors: ['#BD47FB'],
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          value: {
            color: '#BD47FB',
          },
        },
      },
    },
  }
);

const FutureWalletChart = ({userWallet}) => {
  const { t } = useTranslation();
  const isDesktop = useResponsive('up', 'lg');
  const theme = useTheme();

  return (
    <>
      <Box sx={{
        width:isDesktop?'50%' : '100%',
        borderLeft: { xs: 'none', md: `1px solid ${theme.palette.grey[600]}` },
        borderRight: { xs: 'none', md: `1px solid ${theme.palette.grey[600]}` },
        }}
           direction='column'
           justifyContent={'center'}
           alignItems={'center'}
        >
        <Typography variant='body2' color='grey.400'>
          {t("Today's PNL")}
        </Typography>
        <Typography variant='h4' sx={{ mb: 1.5 }}>
          {userWallet?.todayPnl > 0 ? '+' + userWallet?.todayPnl : userWallet?.todayPnl}
        </Typography>

        <Box display={'flex'} direction='column'
             justifyContent={'center'}
             alignItems={'center'} sx={{ position: 'relative' }}>
          <Chart
            options={userWallet?.todayPnlPercent >= 0 ? greenOptions : redOptions}
            series={[userWallet?.todayPnlPercent]}
            type='radialBar'
            height={isDesktop ? '170' : '140'}
            width={isDesktop ? '170' : '140'}
          />
          <Typography
            variant='h5'
            color={userWallet?.todayPnlPercent >= 0 ? 'positive.main' : 'negative.main'}
            sx={{
              position: 'absolute',
              fontWeight: 400,
              // top: { xs: 54, lg: 68 },
              // left: { xs: 25, lg: 40 },
              width: 90,
            }}
          >
            {userWallet?.todayPnlPercent > 0 ? '+' + userWallet?.todayPnlPercent : userWallet?.todayPnlPercent}
          </Typography>
        </Box>
      </Box>

      <Box sx={{
        width:isDesktop?'50%' : '100%'
      }}
           direction='column'
           justifyContent={'center'}
           alignItems={'center'}
      >
        <Typography variant='body2' color='grey.400'>
          {t('30 Days PNL')}
        </Typography>
        <Typography variant='h4' sx={{ mb: 1.5 }}>
          {userWallet?.monthPnlDollar > 0 ? '+' + userWallet?.monthPnlDollar : userWallet?.monthPnlDollar}
        </Typography>

        <Box display={'flex'} direction='column'
             justifyContent={'center'}
             alignItems={'center'} sx={{ position: 'relative' }}>
          <Chart
            options={userWallet?.monthPnlPercent >= 0 ? greenOptions : redOptions}
            series={[userWallet?.monthPnlPercent]}
            type='radialBar'
            height={isDesktop ? '170' : '140'}
            width={isDesktop ? '170' : '140'}
          />
          <Typography
            variant='h5'
            color={userWallet?.monthPnlPercent >= 0 ? 'positive.main' : 'negative.main'}
            sx={{
              position: 'absolute',
              fontWeight: 400,
              // top: { xs: 54, lg: 68 },
              // left: { xs: 135, lg: 150 },
              width: 90,
            }}
          >
            {userWallet?.monthPnlPercent > 0 ? '+' + userWallet?.monthPnlPercent : userWallet?.monthPnlPercent}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default FutureWalletChart;