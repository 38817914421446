import React from 'react';
import { TableCell, TableRow, Typography, Stack } from '@mui/material';
// import moment from 'jalali-moment';

import Iconify from '../../../../../../components/Iconify';
import SymbolIcon from '../../../../../../components/SymbolIcon';
// import { useSelector } from 'react-redux';

const EarnTableRow = ({ row, index }) => {
  // const language = useSelector((store) => store.language);
  const { firstName, lastName, mobileNumber, symbol, userCommission, executedQuoteQtyInUSDT } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1, typography: 'body2' } }}>
      <TableCell align='center' sx={{ width: 100 }}>
        {index + 1}
      </TableCell>

      <TableCell>
        {firstName} {lastName}
      </TableCell>

      <TableCell>{mobileNumber}</TableCell>

      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ width: 140 }}>
          <SymbolIcon symbol={symbol} />
          <Typography variant='body2' noWrap sx={{ width: 100 }}>
            {symbol}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <Iconify
            icon={executedQuoteQtyInUSDT >= 0 ? 'ph:arrow-up-fill' : 'ph:arrow-down-fill'}
            color={executedQuoteQtyInUSDT >= 0 ? 'positive.main' : 'error.darker'}
            height={18}
          />
          <Typography variant='body2' color={executedQuoteQtyInUSDT >= 0 ? 'positive.main' : 'error.darker'}>
            {executedQuoteQtyInUSDT} USDT
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>{userCommission}</TableCell>

      {/* <TableCell>{moment(date).locale(language).format('D MMM YYYY - HH:mm')}</TableCell> */}
    </TableRow>
  );
};

export default EarnTableRow;
