import React from 'react';
import { toFixedIfNecessary } from '../../../../utils/toFixedIfNecessary';
import classesDark from './customListBoxNew.module.scss';
import classesLight from './customListBoxNewLight.module.scss';
import { useDispatch } from 'react-redux';
import { SET_OrderBookList_SelectedItem } from '../../../../store/actionTypes';
import { useEffect } from 'react';
import { useTheme } from '@mui/material';

const CustomListBoxNew = (props) => {
  const theme = useTheme();
  //   const [selectedItems, setSelectedItems] = React.useState([]);
  //   const [position, setPosition] = React.useState({ x: 0, y: 0 });
  //   const [showPopup, setShowPopup] = React.useState(false);
  const dispatch = useDispatch();

  const classes = theme.palette.mode === 'dark' ? classesDark : classesLight;

  useEffect(() => {
    // console.log(props.sizeReference);
  });

  //   useEffect(() => {
  //     const handleWindowMouseMove = (event) => {
  //       setPosition({
  //         x: event.screenX,
  //         y: event.screenY,
  //       });

  //       console.log(event.screenX, ' - ' + event.screenY);
  //     };
  //     // window.addEventListener('mousemove',handleWindowMouseMove);

  //     // return () => {
  //     //     window.addEventListener('mousemove',handleWindowMouseMove);
  //     // }
  //   }, []);

  // const isSelected = (itemId) => {
  //     return selectedItems.findIndex(id => id === itemId) > -1;
  // };

  // const onMouseLeaveListArea = () => {
  //     setSelectedItems([]);
  //     setShowPopup(false);
  // };

  // const onMouseMove = event => {
  //     setPosition({
  //         x: event.clientX - event.target.offsetLeft,
  //         y: event.clientY - event.target.offsetTop,
  //     })
  // };

  return (
    // <div className={classes.container}
    //     onMouseLeave={onMouseLeaveListArea}
    //     onMouseMove={props.onMouseMove}
    // >
    //     {
    //         showPopup &&
    //         <div className={classes.popup}>
    //             <div>
    //                 <span>Avg.Price</span>
    //                 <span>0.690</span>
    //             </div>
    //             <div>
    //                 <span>Sum YGG</span>
    //                 <span>0.690</span>
    //             </div>
    //             <div>
    //                 <span>Sum USDT</span>
    //                 <span>0.690</span>
    //             </div>
    //         </div>
    //     }

    //     {
    //         props.showHeader
    //         &&
    //         <div className={classes.listItemTemplateContainer}>
    //             <div className={classes.listItemTemplate}>
    //                 <span className={classes.price}>Price (USDT)</span>
    //                 <span className={classes.amount}>Amout (BTC)</span>
    //                 <span className={classes.total}>total</span>
    //             </div>
    //         </div>
    //     }
    //     {
    //         props.dataSource.map((item, index) => {
    //             return <div className={classes.listItemTemplateContainer}>
    //                 {/* <div id={index} className={isSelected(index) ? classes.selectHighlight : classes.selectHighlightHide}
    //                     onMouseOver={() => {
    //                         let temp = [];
    //                         if (!props.inverseSeection) {
    //                             for (let i = 0; i <= index; i++)
    //                                 temp.push(i);
    //                         }
    //                         if (props.inverseSeection) {
    //                             for (let i = props.dataSource.length - 1; i >= index; i--)
    //                                 temp.push(i);
    //                         }
    //                         setSelectedItems(temp);
    //                         setShowPopup(true);
    //                     }}
    //                 // onMouseMove={onMouseMove}
    //                 /> */}
    //                 {/* <div id={index} className={classes.progressBar} style={{ width: `${10}%`, backgroundColor: `${!props.inverseSeection ? "#30E0A1" : "#FA2256"}` }}></div> */}

    //                 <div className={classes.listItemTemplate}>
    //                     <span className={!props.inverseSeection ? classes.priceGreen : classes.priceRed}>{item.p}</span>
    //                     <span className={classes.amount}>{item.a}</span>
    //                     <span className={classes.total}></span>
    //                 </div>

    //             </div>

    //         })
    //     }

    // </div>

    <div style={props.style} className={`${classes.container} ${props.className}`}>
      {/* <table cellSpacing="0" cellPadding="0" border="0" style={{ width: "100%" }}> */}
      <table cellPadding='3' border='0' style={{ width: '100%' }}>
        {props.showHeader && (
          <thead>
            <tr
              style={{
                position: 'sticky',
                top: '0',
                zIndex: '1',
                backgroundColor: theme.palette.mode === 'dark' ? '#1E1F25' : '#eee',
              }}
            >
              <th>
                <div class={classes.tblHeaderDiv_alignLeft}>
                  <span class={classes.tblHeaderText}>Price ({props.title.quoteAsset})</span>
                </div>
              </th>
              <th>
                <div class={classes.tblHeaderDiv_alignRight}>
                  <span class={classes.tblHeaderText}>Amout ({props.title.baseAsset})</span>
                </div>
              </th>
              <th>
                <div class={classes.tblHeaderDiv_alignRight}>
                  <span class={classes.tblHeaderText}>Total</span>
                </div>
              </th>
            </tr>
          </thead>
        )}
        <tbody>
          {props.dataSource?.map((item, index) => {
            return (
              <tr
                style={{ position: 'relative', cursor: 'pointer' }}
                onClick={() => {
                  dispatch({
                    type: SET_OrderBookList_SelectedItem,
                    payload: {
                      obListItem: item,
                    },
                  });
                }}
              >
                <td class={classes.td}>
                  <div class={classes.tblCellDiv_alignLeft}>
                    <span className={!props.inverseSeection ? classes.priceGreen : classes.priceRed}>{item.p}</span>
                  </div>
                </td>
                <td class={classes.td}>
                  <div class={classes.tblCellDiv_alignRight}>
                    <span>{item.a}</span>
                  </div>
                </td>
                <td class={classes.td}>
                  <div class={classes.tblCellDiv_alignRight}>
                    <span>{toFixedIfNecessary(item.p * item.a, 4)}</span>
                  </div>
                </td>
                <td class={classes.percentTd}>
                  <div
                    class={!props.inverseSeection ? classes.percentdivGreen : classes.percentdivRed}
                    style={{
                      // width: "80%"
                      width: `${
                        item.a / props.sizeReference >= 1 ? '100%' : (item.a / props.sizeReference) * 100 + '%'
                      }`,
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div ref={props.endOfListRef} />
    </div>
  );
};

export default CustomListBoxNew;
