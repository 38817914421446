import { useEffect } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import moment from 'jalali-moment';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseOptionChart } from '../../../components/chart';
import Scrollbar from '../../../components/Scrollbar';
import mergeDeep from '../../../utils/merge';
import { BadgeIcon, PeriodButton } from '../report/style';
import { useState } from 'react';
import Api from '../../../http/adminHttp/serviceApiAdmin';

import fakeBar from '../../../assets/fake-bar.png';
import fakeGrid from '../../../assets/fake-grid2.png';

export default function SystemStatus() {
  const { t } = useTranslation();
  const theme = useTheme();
  const language = useSelector((store) => store.language);

  // --------------------
  const [typeSelected, setTypeSelected] = useState(['user', 'buy', 'sell']);

  // ---------------------
  const chartOptions = mergeDeep(BaseOptionChart(), {
    stroke: {
      curve: 'smooth',
      width: 1.5,
    },
    legend: {
      show: false,
    },
    colors: ['#BD47FB', '#30E0A1', '#F27F16'],
    chart: { animations: { enabled: true }, foreColor: theme.palette.grey[400] },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 0.5,
        inverseColors: true,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 70, 100],
      },
    },
    xaxis: {
      categories: new Array(12)
        .fill(1)
        .map((item, index) => moment().add(index, 'months').locale(language).format('MMM')),
    },
    yaxis: {
      labels: {
        formatter: (value) => `${value}`,
      },
    },
    grid: {
      show: true,
      strokeDashArray: 4,
      borderColor: 'info.darkest',
    },
    tooltip: {
      x: { show: false },
      y: {
        formatter: (seriesName) => `${seriesName || 0} ${t('items')}`,
        title: {
          formatter: (label) => ` ${t(label)} `,
        },
      },
      marker: { show: false },
    },
  });

  // ---------------------
  const [data, setData] = useState({
    buyChartCount: null,
    sellChartCount: null,
    userChartCount: null,
  });

  // --------------------
  const getData = async () => {
    try {
      const response = await Api.getChartStatistics();
      setData(response);
    } catch (e) {}
  };

  // ---------------------
  useEffect(() => {
    getData();
  }, []);

  // ---------------------
  const onTypeSelected = (name) => {
    if (typeSelected.indexOf(name) !== -1) {
      setTypeSelected((prev) => prev.filter((type) => type !== name));
    } else {
      setTypeSelected((prev) => [...prev, name]);
    }
  };

  // ---------------------
  return (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} spacing={2}>
        <Typography variant='h6' sx={{ fontWeight: 700 }}>
          {t('System status')}
        </Typography>

        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <PeriodButton
            size='small'
            color='inherit'
            selected={typeSelected.indexOf('user') !== -1}
            onClick={() => onTypeSelected('user')}
            sx={{ width: 'auto', px: 2 }}
          >
            <Stack direction={'row'} justifyContent={'space-between'}>
              <BadgeIcon bgColor='#BD47FB' direction='right' />
              <Typography variant='subtitle4' color='#BD47FB'>
                {t('User')}
              </Typography>
            </Stack>
          </PeriodButton>

          <PeriodButton
            size='small'
            color='inherit'
            selected={typeSelected.indexOf('buy') !== -1}
            onClick={() => onTypeSelected('buy')}
            sx={{ width: 'auto', px: 2 }}
          >
            <Stack direction={'row'} justifyContent={'space-between'}>
              <BadgeIcon bgColor='#30E0A1' direction='right' />
              <Typography variant='subtitle4' color='#30E0A1'>
                {t('Buy')}
              </Typography>
            </Stack>
          </PeriodButton>

          <PeriodButton
            size='small'
            color='inherit'
            selected={typeSelected.indexOf('sell') !== -1}
            onClick={() => onTypeSelected('sell')}
            sx={{ width: 'auto', px: 2 }}
          >
            <Stack direction={'row'} justifyContent={'space-between'}>
              <BadgeIcon bgColor='#F27F16' direction='right' />
              <Typography variant='subtitle4' color='#F27F16'>
                {t('Sell')}
              </Typography>
            </Stack>
          </PeriodButton>
        </Stack>
      </Stack>

      <Scrollbar>
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              backgroundImage: `url("${fakeGrid}")`,
              backgroundRepeat: 'repeat-x',
              width: 'calc(100% - 70px)',
              height: 'calc(100% - 90px)',
              position: 'absolute',
              left: 40,
              bottom: 65,
            }}
          />

          <Box sx={{ width: { xs: 600, md: '100%' }, pb: 2 }}>
            <ReactApexChart
              series={[
                {
                  name: 'User',
                  type: 'area',
                  color: '#BD47FB',
                  data: [
                    data?.userChartCount?.aprCount || 0,
                    data?.userChartCount?.mayCount || 0,
                    data?.userChartCount?.junCount || 0,
                    data?.userChartCount?.julCount || 0,
                    data?.userChartCount?.augCount || 0,
                    data?.userChartCount?.sepCount || 0,
                    data?.userChartCount?.octCount || 0,
                    data?.userChartCount?.novCount || 0,
                    data?.userChartCount?.decCount || 0,
                    data?.userChartCount?.janCount || 0,
                    data?.userChartCount?.febCount || 0,
                    data?.userChartCount?.marCount || 0,
                  ],
                },
                {
                  name: 'Buy',
                  type: 'area',
                  color: '#30E0A1',
                  data: [
                    data?.buyChartCount?.aprCount || 0,
                    data?.buyChartCount?.mayCount || 0,
                    data?.buyChartCount?.junCount || 0,
                    data?.buyChartCount?.julCount || 0,
                    data?.buyChartCount?.augCount || 0,
                    data?.buyChartCount?.sepCount || 0,
                    data?.buyChartCount?.octCount || 0,
                    data?.buyChartCount?.novCount || 0,
                    data?.buyChartCount?.decCount || 0,
                    data?.buyChartCount?.janCount || 0,
                    data?.buyChartCount?.febCount || 0,
                    data?.buyChartCount?.marCount || 0,
                  ],
                },
                {
                  name: 'Sell',
                  type: 'area',
                  color: '#F27F16',
                  data: [
                    data?.sellChartCount?.aprCount || 0,
                    data?.sellChartCount?.mayCount || 0,
                    data?.sellChartCount?.junCount || 0,
                    data?.sellChartCount?.julCount || 0,
                    data?.sellChartCount?.augCount || 0,
                    data?.sellChartCount?.sepCount || 0,
                    data?.sellChartCount?.octCount || 0,
                    data?.sellChartCount?.novCount || 0,
                    data?.sellChartCount?.decCount || 0,
                    data?.sellChartCount?.janCount || 0,
                    data?.sellChartCount?.febCount || 0,
                    data?.sellChartCount?.marCount || 0,
                  ],
                },
              ].filter((item) => {
                return typeSelected.indexOf(item.name.toLowerCase()) !== -1;
              })}
              options={chartOptions}
              height={330}
            />
          </Box>
        </Box>
      </Scrollbar>
    </>
  );
}
