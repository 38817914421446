import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Card, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { PageTitle } from '../../../../components/PageTitle';
import { FormProvider } from '../../../../components/hookForm';
import useAddUserForm from './hooks/useAddUserForm';
import useAddUserSubmit from './hooks/useAddUserSubmit';
import AddUserInputs from './addUserInputs';
import AddCardInputs from './addCardInputs';
import { useEffect } from 'react';

const AddUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Add User' },
    });
  }, []);

  const {
    handleSubmit,
    isSubmitting,
    methods,
    handleDrop,
    cardsAmount,
    handleAddCard,
    handleDeleteCard,
    userImg,
    reset,
  } = useAddUserForm();

  const { onSubmit } = useAddUserSubmit(cardsAmount, userImg, reset);

  return (
    <Stack sx={{ padding: { xs: '0 20px 20px', lg: '20px 0 97px' } }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 } }}>
          <PageTitle title='Add User' />
          <AddUserInputs handleDrop={handleDrop} />
        </Card>

        <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 }, mt: 2.5 }}>
          <PageTitle title='Add Card' />
          <AddCardInputs handleAddCard={handleAddCard} cardsAmount={cardsAmount} handleDeleteCard={handleDeleteCard} />
        </Card>

        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ maxWidth: 300, typography: 'body3', fontWeight: 500, mt: 5 }}
        >
          {t('Add User')}
        </LoadingButton>
      </FormProvider>
    </Stack>
  );
};

export default AddUser;
