import { useState } from 'react';

export default function useVerifyViaSms() {
  const [isSending, setIsSending] = useState(false);

  const handleSendingSms = () => {
    console.log('====================================');
    console.log('sms has been sent');
    console.log('====================================');
  };

  return {
    isSending,
    handleSendingSms,
  };
}
