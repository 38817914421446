import { TableCell, TableRow, Typography, Stack, IconButton, Box } from '@mui/material';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Iconify from '../../../../components/Iconify';
import SymbolIcon from '../../../../components/SymbolIcon';
import { prettyUppercases } from '../../../../utils';

export default function WalletCryptoTransactionTableRow({ row, onShowModalDetail, status }) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const { id, symbol, firstName, lastName, emailAddress, mobile, amount, createDate, transactionType , origin} = row;


  return (
    <TableRow hover sx={{ '& td': { py: 1, typography: 'body2' } }}>
      <TableCell align='center' sx={{ width: 50 }}>
        {id}
      </TableCell>

      <TableCell>
        <Typography variant='body3'>
          {firstName} {lastName}
        </Typography>
        {emailAddress && (
          <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
            {emailAddress}
          </Typography>
        )}
        {mobile && (
          <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
            {mobile}
          </Typography>
        )}
      </TableCell>

      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ width: 140 }}>
          <SymbolIcon symbol={symbol} />
          <Typography variant='body2' noWrap sx={{ width: 100 }}>
            {symbol}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell align='center'>{amount}</TableCell>

      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Iconify
            icon={transactionType === 'Withdraw' ? 'ph:arrow-up-fill' : 'ph:arrow-down-fill'}
            color={transactionType === 'Withdraw' ? 'error.darker' : 'positive.main'}
            height={18}
          />
          <Typography variant='body2' color={transactionType === 'Withdraw' ? 'error.darker' : 'positive.main'}>
            {transactionType}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>{moment(createDate).locale(language).format('D MMM YYYY - HH:mm')}</TableCell>

      <TableCell sx={{ width: 170 }} align='center'>
        <Box
          sx={{
            width: 100,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            p: 1,
            bgcolor:
              row?.status?.toUpperCase() === 'SUCCESS' || row?.status?.toUpperCase() === 'COMPLETED' ? 'rgba(48, 224, 161, 0.2)' : row?.status?.toUpperCase() === 'PENDING'  ? 'rgba(242, 127, 22, 0.2)' : 'rgba(250, 34, 86, 0.2)',
          }}
        >
          <Typography
            variant='body3'
            sx={{ color: row?.status?.toUpperCase() === 'SUCCESS' || row?.status?.toUpperCase() === 'COMPLETED' ? '#30E0A1' : row?.status?.toUpperCase() === 'PENDING'  ? 'rgba(242, 127, 22, 1)' : 'negative.main', lineHeight: 1 }}
          >
            {t(row?.status)}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align='center' sx={{ width: 100 }}>
        <IconButton onClick={onShowModalDetail}>
          <Iconify icon='ph:link-duotone' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
