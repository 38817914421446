import React from 'react';
import { Stack, Typography, Grid, IconButton, TextField , MenuItem } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import Iconify from '../../../../components/Iconify';
import { useEffect } from 'react';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import { useTranslation } from 'react-i18next';
import { RHFSelect } from '../../../../components/hookForm';

const BankList = ({ data, bankList, setBankList }) => {
  const { t } = useTranslation();
  const handleChangeInput = ({ target }, bank) => {
    const index = bankList.findIndex((c) => c.id === bank.id);

    const copyArray = [...bankList];
    const changedObject = copyArray[index];
    changedObject[target.name] = target.value;

    copyArray[index] = changedObject;
    setBankList(copyArray);
  };

  const handleAddNewObject = () => {
    const newArray = [...bankList];
    const newObject = {
      id: uuidv4(),
      accountNumber: '',
      bankName: '',
      cardNumber: '',
      shebaNumber: '',
      isNew: true,
      accountType:"RIAL"
    };
    newArray.push(newObject);
    setBankList(newArray);
  };

  const handleDeleteObject = async (bank) => {
    if (!bank.isNew) {
      const response = await Api.deleteDepositReceiptBank(bank);
      if (response.status !== 'SUCCESS') {
        return false;
      }
    }

    let newArray = [...bankList];
    setBankList(newArray.filter((t) => t.id !== bank.id));
  };

  const inputLists = [
    {
      name: 'bankName',
      label: 'Bank name',
    },
    {
      name: 'cardNumber',
      label: 'Card number',
    },
    {
      name: 'accountNumber',
      label: 'Account number',
    },
    {
      name: 'shebaNumber',
      label: 'Shaba number',
    },
    {
      name: 'accountType',
      label: 'Account Type',
    },
  ];

  useEffect(() => {
    setBankList(data);
  }, [data]);



  return (
    <Stack>
      <Typography sx={{ fontSize: '20px !important', fontWeight: '500' }}>List of bank accounts</Typography>
      {bankList.map((bank) => (
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems={'flex-end'}
          justifyContent={'space-between'}
          key={bank.id}
        >
          <Grid
            container
            spacing={2}
            display='flex'
            justifyContent={'space-between'}
            sx={{ width: { xs: '100%', md: 'calc(100% - 90px)' }, mt: 1, flexDirection: { xs: 'column', md: 'row' } }}
          >
            {inputLists.map((input, index) => (
              <Grid item xs={12} md={input?.name === 'accountType' ?  1 :  input?.name === 'bankName'  ?  2 :3} key={index}>
                {input?.name === 'accountType' ?

                  <RHFSelect
                    label={t(input.label)}
                    name={input.name}
                    defaultValue={bank[input.name]}
                    SelectProps={{
                      native: false,
                      MenuProps: {
                        sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'common.black' } },
                      },
                    }}
                    onChange={(e) => handleChangeInput(e, bank)}
                    variant='standard'
                    fullWidth
                    sx={{
                      maxWidth: 300,
                      '& label.MuiFormLabel-root': {
                        color: 'grey.400',
                      },
                      '& .Mui-disabled': {
                        '& input': { WebkitTextFillColor: 'grey.550' },
                        '&:before': { borderBottomStyle: 'solid' },
                      },
                    }}
                  >
                    {[{label:"RIAL" , value:"RIAL"},{label:"USD" , value:"USD"}].map((category) => (
                      <MenuItem value={category.value} key={category.value}>
                        <Typography typography='body3' color='grey.300'>
                          {t(category.label)}
                        </Typography>
                      </MenuItem>
                    ))}
                  </RHFSelect>

                  :

                  <TextField
                    label={t(input.label)}
                    name={input.name}
                    variant='standard'
                    fullWidth
                    defaultValue={bank[input.name]}
                    onChange={(e) => handleChangeInput(e, bank)}
                    // disabled={!bank.isNew && input.name === 'accountNumber'}
                    sx={{
                      maxWidth: 300,
                      '& label.MuiFormLabel-root': {
                        color: 'grey.400',
                      },
                      '& .Mui-disabled': {
                        '& input': { WebkitTextFillColor: 'grey.550' },
                        '&:before': { borderBottomStyle: 'solid' },
                      },
                    }}
                  />
                }

              </Grid>
            ))}
          </Grid>

          <IconButton
            onClick={() => handleDeleteObject(bank)}
            sx={{ mt: { xs: 2, md: 0 }, mb: { xs: 0, md: -1.5 }, mr: { xs: 'auto', md: 1 } }}
          >
            <Iconify icon='uil:trash-alt' color='grey.0' sx={{ width: 24, height: 24 }} />
          </IconButton>
        </Stack>
      ))}
      <IconButton onClick={handleAddNewObject} sx={{ mt: 3, mr: 'auto', borderRadius: 1 }}>
        <Iconify icon='eva:plus-outline' color='grey.0' sx={{ width: 12, height: 12 }} />
        <Typography variant='body3' sx={{ ml: 0.6 }}>
          {t('Add New')}
        </Typography>
      </IconButton>
    </Stack>
  );
};

export default BankList;
