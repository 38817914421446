import React from 'react';
import { Stack, Box, Typography, Avatar } from '@mui/material';
import moment from 'jalali-moment';

import { useSelector } from 'react-redux';
import { prettyUppercases } from '../../../../utils';
import userAvatar from '../../../../assets/user.svg';
import { useTranslation } from 'react-i18next';

const UserHeader = ({ data, handleDrop }) => {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  return (
    <Stack direction={{ xs: 'column-reverse', md: 'row' }} justifyContent={'space-between'} alignItems='center'>
      <Stack direction={'row'} alignItems='center'>
        <Avatar src={data?.avatar || userAvatar} alt='' sx={{ width: 142, height: 142 }} />

        {/* <RHFUploadAvatar
          name='avatar'
          accept={{
            'image/jpeg': [],
            'image/png': [],
          }}
          onDrop={handleDrop}
        /> */}
        <Box sx={{ ml: 4 }}>
          <Typography sx={{ fontSize: 24 }}>{`${data.firstName} ${data.lastName}`}</Typography>
          <Typography variant='body2' color='grey.300'>
            {prettyUppercases(data?.authenticationLevel)}
          </Typography>
        </Box>
      </Stack>

      <Stack
        sx={{
          width: 320,
          height: 148,
          border: '1px solid',
          borderColor: 'grey.400',
          borderRadius: 1.5,
          p: 2.5,
          mb: { xs: 2, md: 0 },
        }}
        spacing={1}
      >
        <Typography variant='body3'>{t(`Registration via:`)} {data.registrationType || ''}</Typography>
        <Typography variant='body3'>
          User: {data.firstName || data.firstName ? `${data.firstName} ${data.lastName}` : '-'}
        </Typography>
        <Typography variant='body3'>{t(`User ID:`)} {data.userId}</Typography>
        <Typography variant='body3'>{t(`Registration Date:`)} {moment(data.registeredDate)
          .locale(language)
          .format('YYYY/MM/DD - HH:mm')}</Typography>
      </Stack>
    </Stack>
  );
};

export default UserHeader;
