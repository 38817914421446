import React from "react";
import { Space, Table, Tag } from "antd";
import "./ipBlockTableAntdStyles.scss";
import classes from "./ipBlockTable.module.scss";
import { MdDelete, MdOutlineDeleteOutline } from "react-icons/md";
import { FiEdit3 } from "react-icons/fi";

const IpBlockTable = (props) => {

    const columns = [
        {
            title: 'Num',
            dataIndex: 'num',
            key: 'num',
            width: 100,
            align: "center",
            render: (text, record, index) => index + 1,
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            width: 200,
            align: "center",
            render: (key, record) => (
                <MdDelete className={classes.deletRowBtn} size={20} onClick={()=>props.onDeleteRowClick(record?.ip)}/>
            ),
        },
    ];

    return (
        <div>
            <Table
                direction="horizental"
                className="ipBlockTableStyle"
                dataSource={props.dataSource}
                columns={columns}
                rowKey="userId"
                pagination={{ defaultPageSize: 20 }}
                bordered={false}
                loading={props.loading}
                scroll={{ x: 800, y: 250 }}
            />
        </div>
    )
}

export default IpBlockTable;