import { Button, Stack, Typography } from '@mui/material';

import SymbolIcon from '../SymbolIcon';

export default function RowSymbol({ item, onSelect }) {
  const { name, symbol } = item;

  return (
    <Button fullWidth onClick={onSelect} sx={{ py: 1.5, px: 2, borderRadius: 1, textAlign: 'left' }}>
      <Stack direction='row' alignItems={'center'} sx={{ width: '100%' }} spacing={2}>
        <SymbolIcon symbol={symbol} width={24} height={24} />

        <Typography variant='subtitle2'>{symbol}</Typography>

        <Typography variant='body3' color='grey.300' noWrap>
          {name}
        </Typography>
      </Stack>
    </Button>
  );
}
