import { TableRow, TableCell, Typography, Box, IconButton } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import Iconify from '../../../../../../components/Iconify';

export default function AdminUserTableRow({ row, index }) {
  const language = useSelector((store) => store.language);
  const { firstName, lastName, mobileNumber, registeredDate, amount, condition, authenticationLevel } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1.5, typography: 'body2', color: 'grey.100' } }}>
      <TableCell align='center' sx={{ width: 100 }}>
        {index + 1}
      </TableCell>

      <TableCell>
        {firstName} {lastName}
      </TableCell>

      <TableCell>{mobileNumber}</TableCell>

      <TableCell>{moment(registeredDate).locale(language).format('D MMM YYYY - HH:mm')}</TableCell>

      <TableCell align='center'>{amount}</TableCell>

      <TableCell sx={{ width: 170 }} align='center'>
        <Box
          sx={{
            width: 130,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            p: 0.8,
            bgcolor: 'rgba(48, 224, 161, 0.2)',
          }}
        >
          <Typography variant='body2' color={'#30E0A1'}>
            {condition}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align='center'>{authenticationLevel}</TableCell>
      <TableCell align='center'>
        <IconButton>
          <Iconify icon='carbon:settings-adjust' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
