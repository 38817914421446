import { toNumber } from '../../../../utils/formatNumber';
import Api from '../../../../http/serviceApi';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

export default function usePerfectMoneySubmit({ side, onOpenStatusModal, reset ,fiatAmount , fiatType}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (payload) => {
    try {
      // BUY - NORMAL
      if (side === 'BUY' && payload.type === 'NORMAL') {
        const res = await Api.perfectMoneyOrder({
          perfectMoneyType: payload.type,
          side,
          fiat: payload.fiatType,
          perfectMoneyAmount: toNumber(payload.perfectMoneyAmount),
          userAccount: payload.walletId,
          depositType: payload.depositType,
        });

        onOpenStatusModal({ ...res, amount: payload.perfectMoneyAmount, onReset: reset , fiatAmount , fiatType });
      }
      // BUY - E_VOUCHER
      else if (side === 'BUY' && payload.type === 'E_VOUCHER') {
        const res = await Api.perfectMoneyOrder({
          perfectMoneyType: payload.type,
          side,
          fiat: payload.fiatType,
          perfectMoneyAmount: toNumber(payload.perfectMoneyAmount),
          userAccount: payload.walletId,
          depositType: payload.depositType,
        });

        onOpenStatusModal({ ...res, amount: payload.perfectMoneyAmount,onReset: reset , fiatAmount, fiatType , userAccount: payload.walletId, });
      }
      // SELL - NORMAL
      else if (side === 'SELL' && payload.type === 'NORMAL') {
        const res = await Api.perfectMoneyOrder({
          perfectMoneyType: payload.type,
          side,
          perfectMoneyAmount: toNumber(payload.perfectMoneyAmount),
          // depositType: payload.depositType,
          fiat: 'USD',
        });

        let div = document.createElement('div');
        div.innerHTML = res.form;
        document.body.appendChild(div);
        document.forms['perfectMoney'].submit();

        return enqueueSnackbar(t('We are redirecting you to Perfect Money'), { variant: 'warning' });
      }
      // SELL - E_VOUCHER
      else if (side === 'SELL' && payload.type === 'E_VOUCHER') {
        const res = await Api.perfectMoneyOrder({
          perfectMoneyType: payload.type,
          side,
          voucherNum: payload.voucherCode,
          voucherCode: payload.activationCode,
        });

        onOpenStatusModal({ ...res, onReset: reset, fiatType  , voucherNum: payload.voucherCode, perfectMoneyType: payload.type, side, voucherCode: payload.activationCode,});
      }
      // BUY - PAYSAFE
      if (side === 'BUY' && payload.type === 'PAYSAFE') {
        const res = await Api.paysafeOrder({
          side,
          fiat: payload.fiatType,
          paysafeAmount: toNumber(payload.perfectMoneyAmount),
          depositType: payload.depositType,
        });

        onOpenStatusModal({ ...res, amount: payload.perfectMoneyAmount, onReset: reset , fiatAmount , fiatType});
      }
      // SELL - PAYSAFE
      else if (side === 'SELL' && payload.type === 'PAYSAFE') {
        const res = await Api.paysafeOrder({
          side,
          paysafeAmount: toNumber(payload.perfectMoneyAmount),
          voucherActivation: payload.activationCode,
          voucherCode: payload.serialNumber,
        });

        onOpenStatusModal({ ...res , amount: payload.perfectMoneyAmount , onReset: reset , fiatAmount , fiatType});
      }
    } catch (e) {}
  };

  return { onSubmit };
}
