import { SET_TOAST } from '../store/actionTypes';
import store from '../store';

export const Log = (ex) => {
  console.log('exception: ', ex);
  // debugger
  if (ex?.code === 'ERR_NETWORK') {
  } else if (ex.response?.data) {
    let message = '';
    if (ex.response.data.message) message = ex.response.data.message;
    else if (ex.response.data.error) message = `${ex.response.data.status} - ${ex.response.data.error}`;
    else message = ex.response.data;

    console.log(message);
    try {
      store.dispatch({ type: SET_TOAST, payload: { type: 'error', message } });
    } catch (e) {}
  } else {
    console.log('message: ' + ex.message + '\ncode: ' + ex.code);
    // store.dispatch({type: SET_TOAST, payload: {type: "error", message: "message: " + ex.message + "\ncode: " + ex.code}});
  }

  // console.log("exception: ", ex);
  // console.log("message: " + ex.message + "\ncode: " + ex.code);
  // if (ex.respose && WebGLTexture.respose.status == 400) {
  //     console.log("status: 400");
  //     store.dispatch({type: SET_TOAST, payload: {type: "error", message: "The request was invalid."}});
  // } else if (ex.respose && WebGLTexture.respose.status == 500) {
  //     console.log("status: 500");
  //     store.dispatch({type: SET_TOAST, payload: {type: "error", message: "The request was not completed due to an internal error on th server side."}});
  // } else if (ex.respose && WebGLTexture.respose.status == 500) {
  //     console.log("status: other");
  //     store.dispatch({type: SET_TOAST, payload: {type: "error", message: "سرور پاسخگو نمی باشد."}});
  // } else {
  //     store.dispatch({type: SET_TOAST, payload: {type: "error", message: "message: " + ex.message + "\ncode: " + ex.code}});
  // }
};
