import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
import cssStyles from '../../utils/cssStyles';
import Iconify from '../Iconify';

import AccountPopover from './accountPopover';
import LanguagePopover from './languagePopover';
import Searchbar from './searchbar';
import { HEADER, NAVBAR } from '../../layouts/mainLayout/mainLayout';
import NotificationPopover from './NotificationPopover';
import ThemeSwitch from './themeSwitch';

// ----------------------------------------------------------------------

MainNavBar.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function MainNavBar({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
  isAdmin = false,
  notifications,
}) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');
  const isTablet = useResponsive('up', 'md');
  const isMobile = useResponsive('down', 'sm');
  const pageState = useSelector((store) => store.pageState);
  const { t } = useTranslation();

  // ------------------
  return (
    <>
      <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout} className='no-print'>
        <Toolbar
          sx={{
            minHeight: '100% !important',
            px: { lg: 5 },
            // bgcolor: 'background.topMenu',
            bgcolor: 'grey.900',
            borderRadius: 1.5,
          }}
        >
          {!isDesktop && (
            <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
              <Iconify icon='eva:menu-2-fill' />
            </IconButton>
          )}

          {isTablet && <Typography variant='subtitle1'>{pageState.title ? t(pageState.title) : ''}</Typography>}

          <Box sx={{ flexGrow: 1 }} />

          <Stack direction='row' alignItems='center' spacing={{ xs: 0.5, sm: 1.5 }}>
            {!isMobile && <Searchbar isAdmin={isAdmin} />}
            <ThemeSwitch />
            <LanguagePopover />
            {!isAdmin && <NotificationPopover isAdmin={isAdmin} list={notifications?.notificationInfos || []} />}
            <AccountPopover isAdmin={isAdmin} />
          </Stack>
        </Toolbar>
      </RootStyle>
    </>
  );
}

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  // backgroundColor: theme.palette.common.black,
  backgroundColor: theme.palette.mode === 'dark' ? '#141518' : '#fff',
  // backgroundColor: theme.palette.background.topBackMenu,
  // background: theme.palette.grey[800],
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 10,
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      background: theme.palette.grey[900],
      marginTop: 0,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));
