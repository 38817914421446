import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Iconify from '../../../components/Iconify';
import FuturesSymbolModal from './futuresModals/futuresSymbolModal';
import Scrollbar from '../../../components/Scrollbar';

export default function FuturesSymbol({
  selectedSymbol,
  setSelectedSymbol,
  lastPrice,
  isPositive = true,
  dollarPrice,
  showSymbolModal,
  setShowSymbolModal,
}) {
  const { t } = useTranslation();

  // ----------------
  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        spacing={1}
        sx={{
          borderBottom: '1px solid',
          borderColor: 'grey.600',
          height: 80,
          '& .simplebar-content': { height: '100%' },
        }}
      >
        <Button
          size='large'
          sx={{
            width: 180,
            minWidth: 180,
            height: '100%',
            typography: 'h5',
            fontWeight: 600,
            borderRadius: 0,
            borderTopLeftRadius: 10,
            color: 'common.white',
            borderRight: '1px solid',
            borderColor: 'grey.600',
          }}
          onClick={() => setShowSymbolModal(true)}
          endIcon={<Iconify icon='eva:chevron-down-outline' />}
        >
          {selectedSymbol?.baseAsset ? `${selectedSymbol.baseAsset}/${selectedSymbol.quoteAsset}` : 'BTC/USD'}
        </Button>

        <Scrollbar sx={{ height: '100%' }}>
          <Stack direction='row' sx={{ width: { xs: 470, sm: 550 }, height: '100%' }} alignItems='center' spacing={2}>
            <Stack>
              <Typography variant='subtitle1' color={isPositive ? 'positive.main' : 'negative.main'}>
                {lastPrice || selectedSymbol?.lastPrice || ''}
              </Typography>
              <Typography variant='body4' color={'common.white'}>
                {dollarPrice}$
              </Typography>
            </Stack>

            <Stack>
              <Typography variant='body4' color={'grey.300'}>
                {t('24h Change')}
              </Typography>
              <Typography
                variant='body4'
                color={selectedSymbol?.priceChangePercent >= 0 ? 'positive.main' : 'negative.main'}
              >
                {selectedSymbol?.priceChange || ''} {selectedSymbol?.priceChangePercent || ''}%
              </Typography>
            </Stack>

            <Stack>
              <Typography variant='body4' color={'grey.300'}>
                {t('24h High')}
              </Typography>
              <Typography variant='body4' color={'common.white'}>
                {selectedSymbol?.highPrice || ''}
              </Typography>
            </Stack>

            <Stack>
              <Typography variant='body4' color={'grey.300'}>
                {t('24h low')}
              </Typography>
              <Typography variant='body4' color={'common.white'}>
                {selectedSymbol?.lowPrice || ''}
              </Typography>
            </Stack>

            <Stack>
              <Typography variant='body4' color={'grey.300'}>
                {t('24h Volume HIGH')}
              </Typography>
              <Typography variant='body4' color={'common.white'}>
                {selectedSymbol?.quoteVolume || ''}
              </Typography>
            </Stack>

            <Stack>
              <Typography variant='body4' color={'grey.300'}>
                {t('24h Volume')} {selectedSymbol?.baseAsset}
              </Typography>
              <Typography variant='body4' color={'common.white'}>
                {selectedSymbol?.volume || ''}
              </Typography>
            </Stack>
          </Stack>
        </Scrollbar>
      </Stack>

      <FuturesSymbolModal
        open={showSymbolModal}
        onClose={() => setShowSymbolModal(false)}
        onSelect={setSelectedSymbol}
      />
    </>
  );
}
