import React, { useEffect, useState } from 'react';
import { Box, Button, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { LoadingButton } from '@mui/lab';

import { FormProvider, RHFNumberField, RHFTextField } from '../../../components/hookForm';
import { navigationUrl } from '../../../navigationUrl';
import Api from '../../../http/serviceApi';
import imgFastPay from '../../../assets/gateways/fastPay.png';
import imgVisa from '../../../assets/gateways/visa.png';
import imgZarinpal from '../../../assets/gateways/zarinpal.png';
import imgZibal from '../../../assets/gateways/zibal.png';
import imgVandar from '../../../assets/gateways/vandar.png';
import Image from '../../../components/Image';

export default function DepositPaymentForm({ selectedCurrency, bankCardList }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [gateways , setGateways] = useState([])
  const [selectedGateway , setSelectedGateway] = useState('Zarinpal')
  const validators = Yup.object().shape({
    amount: Yup.string().required(t('Please input the value!')),
    cardId: Yup.string().required(t('Please select a bank card!')),
  });

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues: {
      amount: '',
      cardId: '',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting  ,errors},

  } = methods;

  const getGatewaysData =async () =>{
    try {
      const response = await Api.getGatewaySettingsForUser();
      const dedicatedGatewaySettingWithSymbols = response?.dedicatedGatewaySettingInfoList.map((data) => ({
        ...data,
        symbol:
          data.name === 'FastPay'
            ? imgFastPay
            : data.name === 'VISA'
              ? imgVisa
              : data.name === 'Zarinpal'
                ? imgZarinpal
                : data.name === 'zibal'
                  ? imgZibal
                  : data.name === 'vandar'
                    ? imgVandar
                    : '',
      }));
      setGateways(dedicatedGatewaySettingWithSymbols);
    }catch (e) {

    }
  }

  console.log(gateways)
  const onSubmit = async (payload) => {
    console.log(payload)
    // try {
    //   let response = {};
    //   if (selectedCurrency === 'USD') {
    //     // requestDepositUsd
    //   } else {
    //     response = await Api.requestDepositRial(+payload.amount.replace(',', ''), payload.cardId);
    //   }
    //   if (response?.status === 'SUCCESS') {
    //     reset();
    //   }
    // } catch (e) {}
    try {
      let response = {};
      if (selectedCurrency === 'USD') {
        // requestDepositUsd
       } else {
        response = selectedGateway === 'Zarinpal' ? await Api.zarinpalRequestDeposit(+payload.amount.replace(',', ''),   parseInt(payload.cardId ))
            : await Api.vandarRequestDeposit(+payload.amount.replace(',', ''),   parseInt(payload.cardId ))
          ;
        window.open(response?.url)
      }
      if (response?.status === 'SUCCESS') {
        reset();
      }
    } catch (e) {}
  };

  useEffect(()=>{
    getGatewaysData()
  },[])
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant='body3' color='secondary'>
        {t('Payment is only possible through approved accounts, otherwise your payment will be unsuccessful.')}
      </Typography>

      <RHFNumberField
        name='amount'
        label='Value'
        fullWidth
        variant='standard'
        sx={{ mt: 5 }}
        thousandSeparator
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Typography color='grey.100' variant='body2'>
                {selectedCurrency}
              </Typography>
            </InputAdornment>
          ),
        }}
        />

      <RHFTextField
        name='cardId'
        label='Bank Card'
        select
        fullWidth
        variant='standard'
        sx={{ mt: 3 }}
        helperText={t('You will see a list of your approved bank accounts')}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' } },
          },
        }}
      >
        {bankCardList
          .filter((card) => {
            if (selectedCurrency === 'USD') return card.cardType !== 'SHETAB_CARD';
            return card.cardType === 'SHETAB_CARD';
          })
          .map((card, index) => (
            <MenuItem key={index} value={card.cardId}>
              <Typography variant='body2'>
                {card.cardNumber
                  .replace(/[^\dA-Z]/g, '')
                  .replace(/(.{4})/g, '$1 ')
                  .trim()}
              </Typography>
            </MenuItem>
          ))}
      </RHFTextField>

      <Stack direction='column' sx={{ mt: 1.25 }} spacing={2}>
        <Typography variant='body2'>
          Select a gateway
        </Typography>

        <Stack direction='row' sx={{ mt: 1.25 }} spacing={2}>
          {

            selectedCurrency === 'USD'?
            gateways?.filter(x=>x.status === true)?.filter((g) => ['FastPay', 'VISA'].indexOf(g.name) !== -1)?.map((itm, idx)=>{
              return (
                <Stack onClick={()=>setSelectedGateway(itm?.name)} key={idx+1} flexWrap={'wrap'} direction='column' alignItems='center' justifyContent={'center'}

                       sx={{ width: { xs: 85, md: 125 },
                        borderRadius:"0.5rem", border:itm?.name === selectedGateway ?"2px solid #30E0A1" :"1px solid #A5ADCF",
                         padding:1
                       }}>
                  {itm?.symbol !== '' ? (
                    <Image
                      src={`${itm?.symbol}`}
                      sx={{ width: { xs: 30, md: 40 }, height: { xs: 30, md: 40 } }}
                    />
                  ) : (
                    <Box sx={{ width: 48, height: 48, backgroundColor: 'info.darker', mr: { xs: 1, md: 2.5 } }} />
                  )}

                  <Typography variant='body2'>{itm?.name}</Typography>
                </Stack>
              )
            })
              :

              gateways?.filter(x=>x.status === true)?.filter((g) => ['FastPay', 'VISA'].indexOf(g.name) === -1)?.map((itm, idx)=>{
                return (
                  <Stack onClick={()=>setSelectedGateway(itm?.name)} key={idx+1} flexWrap={'wrap'} direction='column' alignItems='center' justifyContent={'center'}

                         sx={{ width: { xs: 85, md: 125 },
                           borderRadius:"0.5rem", border:itm?.name === selectedGateway ?"2px solid #30E0A1" :"1px solid #A5ADCF",
                           padding:1
                         }}>
                    {itm?.symbol !== '' ? (
                      <Image
                        src={`${itm?.symbol}`}
                        sx={{ width: { xs: 30, md: 40 }, height: { xs: 30, md: 40 } }}
                      />
                    ) : (
                      <Box sx={{ width: 48, height: 48, backgroundColor: 'info.darker', mr: { xs: 1, md: 2.5 } }} />
                    )}

                    <Typography variant='body2'>{itm?.name}</Typography>
                  </Stack>
                )
              })

          }
        </Stack>

      </Stack>
      <Stack direction='row' sx={{ mt: 6.25 }} spacing={2}>
        <LoadingButton
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ width: '60%', typography: 'body3' }}
        >
          {t('Go to payment')}
        </LoadingButton>

        <Button
          onClick={() => navigate(navigationUrl.wallet)}
          variant='outlined'
          color='inherit'
          sx={{ width: '40%', typography: 'body3' }}
        >
          {t('Back')}
        </Button>
      </Stack>
    </FormProvider>
  );
}
