import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';

import Api from '../../../../http/adminHttp/serviceApiAdmin';
import { filterDateRange } from '../../../../utils/filter';
import { exportExcel } from '../../../../utils/excel';
import { exportPDF } from '../../../../utils/pdf';

export default function useAutomaticDepositHistoryInit(page, rowsPerPage) {
  const [tableData, setTableData] = useState(null);
  const [dataFiltered, setDataFiltered] = useState(null);
  const language = useSelector((store) => store.language);
  const { t } = useTranslation();

  // --------------------
  const getAllFiats = async () => {
    try {
      const response = await Api.getAllFiats(page, rowsPerPage);
      if (response) {
        setTableData(mock);
        setDataFiltered(mock);
      }
    } catch (e) {}
  };

  // Fetching data from server
  useEffect(() => {
    getAllFiats();
  }, [page, rowsPerPage]);

  // Filtering
  const onFilter = ({ balanceFilter, dateFromFilter, dateToFilter }) => {
    if (tableData?.length > 0) {
      let newList = [...tableData];

      // balance filter
      const [min, max] = balanceFilter;
      newList = newList.filter((item) => min <= item.amount && item.amount <= max);

      // date filter
      if (dateFromFilter !== null || dateToFilter !== null) {
        newList = filterDateRange(newList, dateFromFilter, dateToFilter);
      }

      setDataFiltered(newList);
    }
  };

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);

  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    try {
      // generate excel and download
      const config = {
        language,
        fileName: t('Automatic Deposit History'),
        heads: ['#', t('Name'), t('Sheba number'), t('Type'), t('Balance'), t('Date')],
        columnsWidth: [5, 26, 20, 14, 13, 21, 26],
        list: mock.map((item) => [
          `${item.firstName} ${item.lastName}`,
          item.sheba,
          `${t(item.type)}`,
          item.balance,
          moment(item.date).locale(language).format('D MMM YYYY - HH:mm'),
          `${item.amount}`,
        ]),
      };
      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  // --------------------
  return {
    dataFiltered,
    onFilter,
    isExportLoading,
    onExport,
  };
}

const mock = [
  {
    firstName: 'Mojtaba',
    lastName: 'Fatvayi',
    sheba: '1234567899',
    type: 'ACTIVE',
    balance: '4000000',
    date: Date.now(),
  },
  {
    firstName: 'Mojtaba',
    lastName: 'Fatvayi',
    sheba: '1234567899',
    type: 'ACTIVE',
    balance: '4000000',
    date: Date.now(),
  },
  {
    firstName: 'Mojtaba',
    lastName: 'Fatvayi',
    sheba: '1234567899',
    type: 'ACTIVE',
    balance: '4000000',
    date: Date.now(),
  },
];
