import { useState, useEffect } from 'react';
import { Box, Button, Divider, IconButton, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ModalContainer from './ModalContainer';
import Api from '../http/serviceApi';
import Scrollbar from './Scrollbar';
import useResponsive from '../hooks/useResponsive';
import { Stack } from '@mui/system';
import EmptyContent from './EmptyContent';
import Iconify from './Iconify';
import confirmWrapper from '../utils/confirm';
import { useSnackbar } from 'notistack';

export default function AddressBookModal({ open, onClose, onSelect = () => {} }) {
  const isTablet = useResponsive('up', 'sm');
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // ---------------------
  const [dataSource, setDataSource] = useState([]);
  const [address, setAddress] = useState('');
  const [symbol, setSymbol] = useState('');
  const [memo, setMemo] = useState('');

  // ---------------------
  const fetchAddressBook = async () => {
    try {
      const data = await Api.getAddressBooks();
      if (data.status === 'SUCCESS') {
        setDataSource(data.addressBookInfos);
      }
    } catch (e) {}
  };

  // ---------------------
  useEffect(() => {
    fetchAddressBook();
  }, []);

  // ---------------------
  const onAdd = async () => {
    try {
      const response = await Api.addAddressBook(address, symbol, memo);

      if (response?.status === 'SUCCESS') {
        enqueueSnackbar(t('The address is added successfuly'), { variant: 'success' });
        setAddress('');
        setSymbol('');
        setMemo('');
        fetchAddressBook();
      }
    } catch (e) {}
    return true;
  };

  // ---------------------
  const onDelete = async (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    if (!(await confirmWrapper(t('Are you sure to remove this address?')))) {
      return false;
    }

    try {
      await Api.deleteAddressBook(id);
      fetchAddressBook();
    } catch (e) {}
    return true;
  };

  // ---------------------
  return (
    <ModalContainer open={open} onClose={onClose} width={600} title={'Select from Address Book'}>
      <Box sx={{ my: 2 }}>
        <Scrollbar
          height={isTablet ? '295px' : '100%'}
          style={
            isTablet
              ? {
                  paddingTop: 10,
                  marginTop: 10,
                  paddingRight: 10,
                  paddingLeft: 10,
                }
              : { padding: 0 }
          }
        >
          <Stack spacing={1.5}>
            {dataSource?.length > 0 ? (
              dataSource.map((item, index) => (
                <>
                  <Button
                    fullWidth
                    onClick={() => {
                      onClose();
                      onSelect(item);
                    }}
                    sx={{
                      borderRadius: 1,
                      py: 1,
                      px: 2,
                    }}
                    key={index}
                  >
                    <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
                      <Stack justifyContent='flex-start' sx={{ width: '100%', textAlign: 'left' }} spacing={0.5}>
                        <Stack direction='row' alignItems='center'>
                          <Typography color='grey.300' variant='body2' sx={{ width: 100 }}>
                            {t('Address')}
                          </Typography>
                          <Typography variant='body2'>{item.address}</Typography>
                        </Stack>
                        <Stack direction='row' alignItems='center'>
                          <Typography color='grey.300' variant='body2' sx={{ width: 100 }}>
                            {t('Symbol')}
                          </Typography>
                          <Typography variant='body2'>{item.symbol}</Typography>
                        </Stack>
                      </Stack>

                      <IconButton onClick={(e) => onDelete(e, item.id)} sx={{ opacity: 0.5 }}>
                        <Iconify icon='eva:trash-2-outline' />
                      </IconButton>
                    </Stack>
                  </Button>

                  <Divider sx={{ width: '100%' }}></Divider>
                </>
              ))
            ) : (
              <EmptyContent title='' description='There is no address' />
            )}
          </Stack>
        </Scrollbar>

        <Stack direction='row' spacing={1} sx={{ mt: 4 }}>
          <TextField
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            label={t('Address')}
            size='small'
            sx={{ width: '70%' }}
          />
          <TextField
            value={symbol}
            onChange={(e) => setSymbol(e.target.value)}
            label={t('Symbol')}
            size='small'
            sx={{ width: '15%' }}
          />
          <TextField
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
            label={t('Memo')}
            size='small'
            sx={{ width: '15%' }}
          />
        </Stack>

        <Stack alignItems={'center'} sx={{ my: 2 }}>
          <Button color='warning' sx={{ borderRadius: 1, typography: 'subtitle2', width: 200 }} onClick={onAdd}>
            {t('Add New Address')}
          </Button>
        </Stack>

        <Stack spacing={2} sx={{ backgroundColor: 'grey.600_5', p: 2, borderRadius: 1 }}>
          <Typography variant='body2' color={'grey.100'} fontWeight={600}>
            {t('One-Step Withdrawal')}
          </Typography>
          {/*<Typography variant='body2' color={'grey.100'}>*/}
          {/*  {t('Enable One-Step Withdrawal to withdraw to whitelis addresses easily.')}*/}
          {/*</Typography>*/}
        </Stack>
      </Box>
    </ModalContainer>
  );
}
