import React, { useEffect, useState } from 'react';
import { Stack, Table, TableBody, TableContainer, Box, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

// import { PageTitle } from '../../../../../components/PageTitle';
import FiatWalletToolbar from './fiatWalletComponents/fiatWalletToolbar';
import Scrollbar from '../../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../../components/table';
import useTable from '../../../../../hooks/useTable';
import FiatWalletTableRow from './fiatWalletComponents/fiatWalletTableRow';
import CreateFiatModal from './fiatWalletComponents/createFiatModal';
import StatusModal from '../../../../../components/StatusModal';
import Api from '../../../../../http/adminHttp/serviceApiAdmin';
import { exportExcel } from '../../../../../utils/excel';
import { useSelector } from 'react-redux';
import { exportPDF } from '../../../../../utils/pdf';

// ===================================================================

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'amount', label: 'Amount', align: 'center' },
  { id: 'blocked', label: 'Blocked', align: 'center' },
  { id: 'create', label: 'Create a transaction', align: 'center' },
];

const types = ['USD', 'IRR'];

// ===================================================================

const FiatWallet = ({ userId }) => {
  const language = useSelector((store) => store.language);

  const [showModal, setShowModal] = useState(false);
  const [selectedFiat, setSelectedFiat] = useState('');

  const [statusModal, setStatusModal] = useState({
    show: false,
    status: '',
  });

  const { t } = useTranslation();
  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  const [tableData, setTableData] = useState(null);

  // ----------------------------------------------
  const getData = async () => {
    try {
      const filters = { userId };

      const response = await Api.getUserWalletInformation(filters);
      if (response.status === 'SUCCESS') {
        setTableData(
          types
            .map((type) => {
              const found = response.userFiatInfoList.find((item) => item.type === type);
              if (found) return found;

              return {
                amount: 0,
                blocked: 0,
                type,
              };
            })
            .sort((a, b) => b.amount - a.amount)
        );
      }
    } catch (e) {
      setTableData([]);
    }
  };

  // ----------------------------------------------
  useEffect(() => {
    if (userId) getData();
  }, [page, rowsPerPage, userId]);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);
  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    // generate excel and download
    const config = {
      language,
      fileName: `${t('User')} - ${t('Fiat Wallet')}`,
      heads: ['#', t('Symbol'), t('Name'), t('Amount'), t('Blocked')],
      columnsWidth: [5, 8, 29, 18, 15],
      list: tableData.map((item) => [item.symbol, item.type, item.amount, item.blocked]),
    };
    type === 'PDF' ? exportPDF(config) : exportExcel(config);

    setIsExportLoading(false);
  };

  // ----------------------------------------------

  return (
    <>
      <Stack>
        {/* <PageTitle title='Fiat Wallet' /> */}
        <FiatWalletToolbar isExportLoading={isExportLoading} onExport={onExport} />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size='small'>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length || 0}
                onSort={onSort}
              />
              <TableBody>
                {tableData === null ? (
                  <TableLoading isLoading />
                ) : (
                  <>
                    {tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                      <FiatWalletTableRow
                        key={row.id}
                        row={row}
                        index={index}
                        setShowModal={setShowModal}
                        setSelectedFiat={setSelectedFiat}
                      />
                    ))}

                    <TableNoData isNotFound={!tableData?.length} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component='div'
            count={tableData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
          />
        </Box>

        <CreateFiatModal
          open={showModal}
          onClose={() => setShowModal(false)}
          onReload={getData}
          setStatusModal={setStatusModal}
          selectedFiat={selectedFiat}
          userId={userId}
        />

        <StatusModal
          open={statusModal.show}
          onClose={() => setStatusModal({ show: false, status: '' })}
          type={statusModal.status}
          title={statusModal.status === 'success' ? 'Successfully' : 'Error'}
          subtitle={
            statusModal.status === 'success' ? 'Transfer completed successfully' : 'The transfer encountered an error'
          }
          buttonLabel='Done'
          onAction={() => setStatusModal({ show: false, status: '' })}
        />
      </Stack>
    </>
  );
};

export default FiatWallet;
