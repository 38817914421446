import { useState, useEffect } from 'react';

import receipt from '../../../../../assets/rollingReceipt.svg';
import linearReceipt from '../../../../../assets/linearReceipt.svg';
import shoppingBag from '../../../../../assets/shoppingBag.svg';
import shoppingCart from '../../../../../assets/shoppingCart.svg';
import Api from '../../../../../http/adminHttp/serviceApiAdmin';
import { useParams } from 'react-router';
import { base64ToImage } from '../../../../../utils';

const attributes = {
  buyAmountOtc: {
    name: 'Total Buy in OTC',
    icon: receipt,
    bgColor: 'rgba(17, 202, 190, 0.1)',
  },
  sellAmountOtc: {
    name: 'Total Sell in OTC',
    icon: linearReceipt,
    bgColor: 'rgba(189, 71, 251, 0.1)',
  },
  buyAmountTrade: {
    name: 'Total Buy in Trades',
    icon: shoppingBag,
    bgColor: 'rgba(242, 127, 22, 0.1)',
  },
  sellAmountTrade: {
    name: 'Total Sell in Trades',
    icon: shoppingCart,
    bgColor: 'rgba(250, 34, 86, 0.1)',
  },
};

export default function useUserInit() {
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const { id: userId } = useParams();

  const [userStatisticValues, setUserStatisticValues] = useState({});
  const [userStatistic, setUserStatistic] = useState([]);
  const [countries, setCountries] = useState([]);

  // filtering part
  const [allEarns, setAllEarns] = useState(null);
  const [earnsFiltered, setEarnsFiltered] = useState(null);

  const getUserInfo = async (withLoading = true) => {
    if (withLoading) setLoading(true);

    try {
      const response = await Api.getUser(userId);

      if (response?.status === 'SUCCESS') {
        const userImage = response.documents?.find((item) => item.type === 'USER_IMAGE');
        const userPassport = response.documents?.find(
          (item) => item.type === 'PASSPORT' || item.type === 'NATIONAL_CARD'
        );
        const userBill = response.documents?.find((item) => item.type === 'BILL');

        setUserInfo({
          ...response.userInfo,
          // twoStepLogin:response?.userInfo?.twoStepLogin ? 'Active' : "Inactive" , changePassword:response?.userInfo?.changePassword ? 'Active' : "Inactive" ,
          authenticationLevel:response.userInfo?.authenticationLevel?.includes("ADVANCED") ?  "ADVANCED" :response.userInfo?.authenticationLevel?.includes("BASIC") ?  "BASIC" :'INTERMEDIATE',
          avatar: response.userInfo?.profilePicture ? base64ToImage(response.userInfo.profilePicture) : null,
          passport: userPassport ? base64ToImage(userPassport.data) : null,
          userImage: userPassport ? base64ToImage(userImage.data) : null,
          bill: userBill ? base64ToImage(userBill.data) : null,
        });

        setUserStatisticValues({
          buyAmountOtc: response?.buyAmountOtc || 0,
          sellAmountOtc: response?.sellAmountOtc || 0,
          buyAmountTrade: response?.buyAmountTrade || 0,
          sellAmountTrade: response?.sellAmountTrade || 0,
        });
      }

      setAllEarns([
        {
          name: 'keyvan Yazdi',
          mobile: '09157877788',
          order: 'Withdraw',
          arrow: 'up',
          value_order: 'USDT 120.89',
          user_commission: '1.234',
          date: new Date(),
        },
      ]);
      setEarnsFiltered([
        {
          name: 'keyvan Yazdi',
          mobile: '09157877788',
          order: 'Withdraw',
          arrow: 'up',
          value_order: 'USDT 120.89',
          user_commission: '1.234',
          date: new Date(),
        },
      ]);
    } catch (e) {}

    if (withLoading) setLoading(false);
  };

  const getCountries = async () => {
    try {
      const response = await Api.getCountryList();
      setCountries(
        response.countries.map((c) => ({
          value: c.id,
          title: c.name,
        }))
      );
    } catch (e) {}
  };

  // Fetching data from server
  useEffect(() => {
    getUserInfo();
    getCountries();
  }, []);

  // Statistic
  useEffect(() => {
    const statistics = [];

    Object.entries(userStatisticValues).forEach(([key, value]) => {
      const attribute = attributes[key];

      statistics.push({
        value,
        name: attribute.name,
        bgColor: attribute.bgColor,
        icon: attribute.icon,
      });
    });

    setUserStatistic(statistics);
  }, [userStatisticValues]);

  const onEarnFilter = ({ nameFilter }) => {
    if (allEarns?.length > 0) {
      let newList = [...allEarns];

      // Name filter
      if (nameFilter !== '') {
        newList = newList.filter((item) => item.name.indexOf(nameFilter) !== -1);
      }
      setEarnsFiltered(newList);
    }
  };

  return {
    userStatistic,
    userInfo,
    onEarnFilter,
    earnsFiltered,
    getUserInfo,
    countries,
    loading,
  };
}
