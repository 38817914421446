import React from 'react';
import { TableRow, TableCell, IconButton, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import moment from 'jalali-moment';

import Iconify from '../../../../components/Iconify';
import { navigationUrl } from '../../../../navigationUrl';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { prettyUppercases } from '../../../../utils';

const WaitingUserTableRow = ({ row, handleDeleteUser }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const { userId, firstName, lastName, authenticationLevel, registeredDate, amount, condition } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1.5, typography: 'body2' } }}>
      <TableCell align='center' sx={{ width: 80 }}>
        {userId}
      </TableCell>

      <TableCell>
        {firstName} {lastName}
      </TableCell>

      <TableCell>{'-'}</TableCell>

      <TableCell>{moment(registeredDate).locale(language).format('D MMM YYYY - HH:mm')}</TableCell>

      <TableCell align='center'>{amount}</TableCell>

      <TableCell align='center'>
        <Box
          sx={{
            maxWidth: 130,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            py: 0.8,
            px: 2,
            ...(condition === 'ACTIVE'
              ? { bgcolor: 'rgba(48, 224, 161, 0.2)' }
              : condition === 'DISAPPROVAL'
              ? { bgcolor: 'rgba(250, 34, 86, 0.2)' }
              : condition === 'WAITING'
              ? { bgcolor: 'rgba(242, 127, 22, 0.2)' }
              : ''),
          }}
        >
          <Typography
            variant='body4'
            sx={{
              lineHeight: 1,
              py: 0.4,
              ...(condition === 'ACTIVE'
                ? { color: '#30E0A1' }
                : condition === 'DISAPPROVAL'
                ? { color: 'error.darker' }
                : condition === 'WAITING'
                ? { color: 'warning.main' }
                : ''),
            }}
          >
            {t(condition)}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align='center'>{t(prettyUppercases(authenticationLevel))}</TableCell>

      <TableCell align='center' sx={{ display: 'flex', justifyContent: { xs: 'space-between', lg: 'center' } }}>
        <IconButton
          onClick={() => {
            navigate(navigationUrl.admin_edit_user(userId));
          }}
          sx={{ mr: { xs: 0, lg: 3 } }}
        >
          <Iconify icon='eva:edit-2-outline' />
        </IconButton>
        <IconButton onClick={() => handleDeleteUser(userId, firstName, lastName)}>
          <Iconify icon='eva:trash-2-outline' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default WaitingUserTableRow;
