import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import ModalContainer from '../../../../../components/ModalContainer';
import { FormProvider, RHFTextField } from '../../../../../components/hookForm';
import Api from '../../../../../http/adminHttp/serviceApiAdmin';
import { useSnackbar } from 'notistack';

const BlockModal = ({ open, onClose, onReload = () => {}, userInfo = () => {}, reject = false }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const validators = Yup.object().shape({
    // message: Yup.string().required(t('please fill the blank')),
  });

  const defaultValues = {
    message: '',
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const onSubmit = async () => {
    try {
      const response = await Api.blockUser(userInfo?.userId, userInfo?.active);
      if (response.status === 'SUCCESS') {
        enqueueSnackbar(t('The user blocked successfully'), { variant: 'success' });
        onReload();
        onClose();
        reset();
      }
    } catch (e) {}
  };

  return (
    <ModalContainer open={open} onClose={onClose} title={reject ? 'Reject' : 'Block'} width={380}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack alignItems={'center'} sx={{ mt: 5 }} spacing={9}>
          <RHFTextField name='message' label={t('Message')} variant='standard' fullWidth sx={{ maxWidth: 300 }} />
          <LoadingButton fullWidth size='large' type='submit' variant='contained' loading={isSubmitting}>
            {t('Send')}
          </LoadingButton>
        </Stack>
      </FormProvider>
    </ModalContainer>
  );
};

export default BlockModal;
