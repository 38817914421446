import { alpha, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import Image from '../../../components/Image';
import people from '../../../assets/people.svg';
import purchase from '../../../assets/purchase.svg';
import sale from '../../../assets/sale.svg';
import order1 from '../../../assets/order1.svg';
import people1 from '../../../assets/people1.svg';
import purchase1 from '../../../assets/purchase1.svg';
import amount1 from '../../../assets/amount1.svg';
import amount2 from '../../../assets/amount2.svg';
import Api from '../../../http/adminHttp/serviceApiAdmin';
import { fFloat } from '../../../utils/formatNumber';
import Iconify from '../../../components/Iconify';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function Statistic() {
  const [statistic, setStatistic] = useState(null);
  const [balanceType, setBalanceType] = useState('Fiat');
  const { t } = useTranslation();

  // --------------------
  const onToggleBalance = () => {
    setBalanceType((prev) => (prev === 'Fiat' ? 'USDT' : 'Fiat'));
  };

  // --------------------
  const timerRef = useRef();
  const getData = async () => {
    if (timerRef.current) clearTimeout(timerRef.current);

    try {
      const response = await Api.getGeneralStatistics();
      setStatistic(response);

      timerRef.current = setTimeout(() => {
        getData();
      }, 5 * 60 * 1000);
    } catch (e) {}
  };

  // ---------------------
  useEffect(() => {
    getData();

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  // ---------------------
  const list =
    statistic === null
      ? []
      : [
          {
            value: statistic?.totalNumberOfUser || 0,
            label: t('The total number of users'),
            image: people,
            color: '#11CABE',
          },
          {
            value: statistic?.totalNumberOfPurchases || 0,
            label: t('Total number of purchases'),
            image: purchase,
            color: '#BD47FB',
          },
          { value: statistic?.totalNumberOfSales || 0, label: t('Total sales'), image: sale, color: '#F27F16' },
          {
            value: statistic?.totalNumberOfOrders || 0,
            label: t('Total number of orders'),
            image: order1,
            color: '#30E0A1',
          },
          {
            value:
              balanceType === 'Fiat'
                ? `${fFloat(statistic?.totalFiatAmount || 0)} USD`
                : fFloat(statistic?.totalUSDTAmount || 0),
            label: t(`Total balance ( ${balanceType} )`),
            image: people1,
            color: '#11CABE',
            onClick: onToggleBalance,
          },
          {
            value: statistic?.totalAmountOfPurchases || 0,
            label: t('Total amount of purchases'),
            image: purchase1,
            color: '#BD47FB',
          },
          {
            value: statistic?.totalAmountOfSales || 0,
            label: t('Total sales amount'),
            image: amount1,
            color: '#F27F16',
          },
          {
            value: statistic?.totalAmountOfOrders || 0,
            label: t('Total order amount'),
            image: amount2,
            color: '#30E0A1',
          },
        ];

  // ---------------------
  return list?.map((item, index) => (
    <Grid key={index} item xs={12} sm={6} md={4} xl={3}>
      <Card sx={{ display: 'flex', alignItems: 'center', p: 2.2, position: 'relative' }}>
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ backgroundColor: alpha(item.color, 0.1), borderRadius: '100%', width: 48, height: 48, mr: 2 }}
        >
          <Image src={item.image} />
        </Stack>
        <Stack spacing={'0.25'}>
          <Typography variant='h6' fontWeight={500}>
            {item.value}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {item.label}
          </Typography>
        </Stack>

        {item.onClick && (
          <IconButton onClick={item.onClick} sx={{ position: 'absolute', top: 8, right: 8, opacity: 0.7 }}>
            <Iconify icon='charm:refresh' sx={{ width: 16, height: 16, color: 'grey.0' }} />
          </IconButton>
        )}
      </Card>
    </Grid>
  ));
}
