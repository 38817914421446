import { Box, Button, Card, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SET_PAGE_STATE } from '../../../store/actionTypes';
import MarketStatistic from './marketStatistic';
import MarketTable from './marketTable';
import Api from '../../../http/serviceApi';
import useTable from '../../../hooks/useTable';
import ComplexToolbar from '../../../components/ComplexToolbar';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';

const mockStatistic = [
  {
    title: 'Top gainer (24h)',
    symbol: 'BNB',
    name: 'Binance Coin',
    usdValue: 567.34,
    changePercent: 110.34,
    prices: [17, 25, 23, 20, 25, 20, 15, 18, 14, 10, 26, 21, 20, 28, 35, 30, 32, 28, 27, 20],
  },
  {
    title: 'New listing',
    symbol: 'BNB',
    name: 'IoT Chain',
    usdValue: 34.02,
    description: 'Added Apr, 13',
    prices: [17, 25, 23, 20, 25, 20, 28, 35, 30, 32, 28, 27, 20, 20, 15, 18, 14, 10, 26, 21],
  },
  {
    title: 'Highest volume (24h)',
    symbol: 'BTC',
    name: 'Bitcoin',
    usdValue: 236213.22,
    changePercent: 0.53,
    prices: [17, 25, 23, 20, 25, 20, 15, 18, 14, 10, 26, 21, 20, 28, 35, 30, 32, 28, 27, 20],
  },
  {
    title: 'Most Visited',
    symbol: 'OGN',
    name: 'Orogin Protocol',
    usdValue: 123.34,
    changePercent: 2.23,
    prices: [17, 25, 23, 20, 25, 20, 28, 35, 30, 32, 28, 27, 20, 20, 15, 18, 14, 10, 26, 21],
  },
];

export default function Market() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // ----------------------------
  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Crypto Market' },
    });
  }, []);

  // ----------------------------
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  // ----------------------------
  const [tableData, setTableData] = useState(null);
  const [marketTab, setMarketTab] = useState('all_cryptos');
  const [typeTab, setTypeTab] = useState('All');

  const [filters, setFilters] = useState({});

  // ----------------------------

  const handleFilterChange = ({ target }) => {
    const newFilters = {
      ...filters,
      [target.name]: target.value,
    };

    setFilters(newFilters);
    // we have this only for name now..
    if (newFilters.name) {
      console.log(totalItems, newFilters?.name);
      setTableData(totalItems.filter((item) => item?.name?.toLowerCase().includes(newFilters?.name.toLowerCase())));
    } else {
      setTableData(totalItems);
    }
  };

  const [totalItems, setTotalItems] = useState();

  const getList = async () => {
    try {
      let response;

      if (typeTab === 'All') {
        response = await Api.getMarketCaps(filters);
      } else {
        response = await Api.getMarketCategory(typeTab, filters);
      }

      const { fwalletInfos } = await Api.getUserFavoriteWallet();

      const favTableData = response.marketCapInfos.map((m) => ({
        ...m,
        isFav: fwalletInfos.findIndex((f) => f.symbol === m.symbol) !== -1,
      }));

      if (marketTab === 'favorites') {
        const favs = favTableData.filter((d) => !!d.isFav);
        setTableData(favs);
        setTotalItems(favs);
      } else {
        setTableData(favTableData);
        setTotalItems(favTableData);
      }
    } catch (e) {
      setTableData([]);
      setTotalItems([]);
    }
  };

  // ----------------------------
  useEffect(() => {
    if (page !== 0) {
      onChangePage(null, 0);
    }

    getList();
  }, [typeTab, marketTab]);

  // ----------------------------
  return (
    <Box sx={{ px: { xs: 3, lg: 0 } }}>
      <MarketStatistic data={mockStatistic} />
      <Card sx={{ mt: 2.5 }}>
        <MarketTable
          tableData={tableData}
          marketTab={marketTab}
          setMarketTab={setMarketTab}
          typeTab={typeTab}
          setTypeTab={setTypeTab}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        >
          <Stack spacing={3} sx={{ mb: 2 }}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={5}
              sx={{ mt: 2, maxWidth: 500 }}
              alignItems={'center'}
            >
              <TextField
                label={t('Name')}
                variant='standard'
                fullWidth
                name='name'
                value={filters.name}
                onChange={(e) => handleFilterChange(e)}
                sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton type='button' aria-label='search' sx={{ mb: 1 }}>
                        <Iconify icon={'eva:search-fill'} width={22} height={22} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Stack>
        </MarketTable>
      </Card>
    </Box>
  );
}
