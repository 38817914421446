import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import Image from '../../../components/Image';
import PaymentMethod from '../../../components/PaymentMethod';
import StatusModal from '../../../components/StatusModal';
import SymbolIcon from '../../../components/SymbolIcon';
import Api from '../../../http/serviceApi';
import OTCConvert from './otcConvert';
import { fiatList } from './otcTab';

export default function OTCPayment({ onBack, onReset, side, spend, receive, spendSymbol, receiveSymbol, calculated }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [gateway, setGateway] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(undefined);

  const intervalRef = useRef(null);
  const timerRef = useRef(15);
  const [timer, setTimer] = useState(15);

  const onSubmit = async () => {
    clearInterval(intervalRef.current);

    if (side === 'buy' && !gateway) {
      return enqueueSnackbar(t('Please select one of the payment methods!'), { variant: 'warning' });
    }

    setIsSubmitting(true);

    try {
      const data = await Api.requestOtcOrder({
        ...(side === 'buy' && { depositType: gateway }),
        side: side.toUpperCase(),
        fiat: side === 'buy' ? spendSymbol : receiveSymbol,
        fiatAmount: side === 'buy' ? +String(spend).replace(/,/g, '') : undefined,
        symbol: side === 'sell' ? spendSymbol : receiveSymbol,
        symbolAmount: side === 'sell' ? +String(spend).replace(/,/g, '') : undefined,
        otcTypeOrder: 'WEB',
        // cardId
        // shetabGateway
      });

      if (data.status === 'SUCCESS') {
        setOpenSuccessModal({
          ...(side === 'buy' && { depositType: gateway }),
          side: side.toUpperCase(),
          fiat: side === 'buy' ? spendSymbol : receiveSymbol,
          fiatAmount: side === 'buy' ? +String(spend).replace(/,/g, '') : undefined,
          symbol: side === 'sell' ? spendSymbol : receiveSymbol,
          symbolAmount: side === 'sell' ? +String(spend).replace(/,/g, '') : undefined,
          otcTypeOrder: 'WEB', spend, receive, spendSymbol, receiveSymbol, calculated
          // cardId
          // shetabGateway
        });
      }
    } catch (e) {
      startTimer();
    }

    setIsSubmitting(false);
  };

  const handleCloseSucess = () => {
    onReset();
    setOpenSuccessModal(undefined);
  };

  useEffect(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);

    startTimer();

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  const startTimer = () => {
    intervalRef.current = setInterval(() => {
      timerRef.current--;
      setTimer(timerRef.current);

      if (timerRef.current === 0) {
        clearInterval(intervalRef.current);

        setTimeout(() => {
          onBack();
        }, 2000);
      }
    }, 1000);
  };

  return (
    <>
      <Typography variant='h6' sx={{ fontWeight: 700, textAlign: 'center', mb: 3 }}>
        {side === 'buy' ? t('Choose Payment method') : t('Confirm Order')}
      </Typography>

      <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 }, mx: { xs: 2, md: 0 } }}>
        <Box sx={{ width: { sx: '100%', md: 627 }, m: 'auto' }}>
          <OTCConvert side={side} spendSymbol={spendSymbol} receiveSymbol={receiveSymbol} calculated={calculated} />

          <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mt: 6.5 }}>
            <Stack direction='row' spacing={2} alignItems='center'>
              {side === 'buy' ? (
                <Image src={fiatList.find((f) => f.label === spendSymbol)?.icon} alt='' />
              ) : (
                <SymbolIcon symbol={spendSymbol} />
              )}
              <Typography variant='body3' color='grey.400'>
                {t('Spend')}
              </Typography>
            </Stack>

            <Stack
              justifyContent={'center'}
              alignItems={'center'}
              sx={{ borderRadius: '100%', backgroundColor: 'grey.600', width: 28, height: 28 }}
            >
              <Iconify icon={'eva:arrow-forward-outline'} />
            </Stack>

            <Stack direction='row' spacing={2} alignItems='center'>
              <Typography variant='body3' color='grey.400'>
                {t('Receive')}
              </Typography>
              {side === 'sell' ? (
                <Image src={fiatList.find((f) => f.label === receiveSymbol)?.icon} alt='' />
              ) : (
                <SymbolIcon symbol={receiveSymbol} width={23} height={23} />
              )}
            </Stack>
          </Stack>

          <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mt: 2, mb: 1 }}>
            <Typography variant='body2' color='grey.100'>
              {spend} {spendSymbol}
            </Typography>
            <Typography variant='body2' color='grey.100'>
              {receive} {receiveSymbol}
            </Typography>
          </Stack>

          {side === 'buy' && (
            <>
              <Divider sx={{ mb: 5 }} />
              <PaymentMethod onSelectGateway={setGateway} paymentMethod={gateway} />
            </>
          )}

          <Stack direction='row' spacing={2.5} sx={{ mt: 5 }}>
            <Button
              variant='contained'
              color='inherit'
              size='large'
              onClick={onBack}
              sx={{ width: { xs: '35%', sm: '50%' }, bgcolor: 'grey.400', '&:hover': { bgcolor: 'grey.600' } }}
            >
              {t('Previous')}
            </Button>
            <LoadingButton
              variant='contained'
              size='large'
              sx={{ width: { xs: '65%', sm: '50%' } }}
              loading={isSubmitting}
              onClick={onSubmit}
            >
              {timer === 0 ? (
                t('Confirmation Time is Over')
              ) : (
                <>
                  {t('Confirm')}
                  <Typography
                    variant='body2'
                    color='inherit'
                    sx={{
                      width: 50,
                      textAlign: 'center',
                      display: 'inline-block',
                      lineHeight: 1,
                      letterSpacing: 1,
                    }}
                  >
                    ({timer}s)
                  </Typography>
                </>
              )}
            </LoadingButton>
          </Stack>
        </Box>
      </Card>

      <StatusModal
        open={!!openSuccessModal}
        onClose={handleCloseSucess}
        onAction={handleCloseSucess}
        delay={0}
        buttonLabel='Close'
        data={openSuccessModal}
      />
    </>
  );
}
