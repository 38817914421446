import { useEffect, useRef } from 'react';
import { Box } from '@mui/material';

import FuturesOrderBook from './futuresOrderBook';
import FuturesOrderTrades from './futuresOrderTrades';
import Api from '../../../../http/serviceApi';

export default function FuturesOrders({ selectedSymbol, orderBook, setOrderBook, onSelectPrice }) {
  // ----------------
  const lastPriceRef = useRef(0);
  const reloadRef = useRef();

  // ----------------
  const getOrderBook = async () => {
    clearTimeout(reloadRef.current);

    try {
      const response = await Api.getOrderBook(selectedSymbol.symbol);

      if (response?.status === 'SUCCESS') {
        const lastPrice = response.tradeInfos?.[0]?.p || 0;

        setOrderBook({
          lastPrice,
          isPositive: lastPrice > lastPriceRef.current,
          dollarPrice: response.dollarPrice,
          trades: response.tradeInfos.map((info) => ({ price: info.p, amount: info.a, time: info.d, isSell: info.m })),
          asks: response.asks.map((info) => ({
            price: info.p,
            amount: info.a,
            sum: info.p * info.a,
            percent: info.a / response.sizeReference >= 1 ? 100 : (info.a / response.sizeReference) * 100,
          })),
          bids: response.bids.map((info) => ({
            price: info.p,
            amount: info.a,
            sum: info.p * info.a,
            percent: info.a / response.sizeReference >= 1 ? 100 : (info.a / response.sizeReference) * 100,
          })),
        });

        lastPriceRef.current = lastPrice;
      }
    } catch (e) {}

    onReload();
  };

  // ----------------
  useEffect(() => {
    if (selectedSymbol?.symbol) {
      getOrderBook();
    }
  }, [selectedSymbol?.symbol]);

  // -----------------
  const onReload = () => {
    clearTimeout(reloadRef.current);

    reloadRef.current = setTimeout(() => {
      getOrderBook();
    }, 2000);
  };

  // ----------------
  useEffect(() => {
    return () => {
      clearTimeout(reloadRef.current);
    };
  }, []);

  // ----------------
  return (
    <>
      <Box sx={{ height: 445 }}>
        <FuturesOrderBook
          selectedSymbol={selectedSymbol}
          asks={orderBook.asks}
          bids={orderBook.bids}
          lastPrice={orderBook.lastPrice}
          isPositive={orderBook.isPositive}
          dollarPrice={orderBook.dollarPrice}
          onSelectPrice={onSelectPrice}
        />
      </Box>

      <FuturesOrderTrades selectedSymbol={selectedSymbol} trades={orderBook.trades} />
    </>
  );
}
