import { Box, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'jalali-moment';

import Scrollbar from '../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../components/table';
import useTable from '../../../hooks/useTable';
import BankCardTableRow from './bankCardTableRow';
import BankCardToolbar from './bankCardToolbar';
import imgShetabCard from '../../../assets/bankCard/shetab-card.png';
import imgVisaCard from '../../../assets/bankCard/visa-card.png';
import imgMasterCard from '../../../assets/bankCard/master-card.png';
import EditCardModal from './editCardModal';
import Api from '../../../http/adminHttp/serviceApiAdmin';
import { exportExcel } from '../../../utils/excel';
import { useSelector } from 'react-redux';
import { exportPDF } from '../../../utils/pdf';

export const CARD_TYPE = [
  { value: '', label: 'ALL', icon: '' },
  { value: 'MASTER_CARD', label: 'Master Card', icon: imgMasterCard },
  { value: 'VISA_CARD', label: 'Visa Card', icon: imgVisaCard },
  { value: 'SHETAB_CARD', label: 'Shetab Card', icon: imgShetabCard },
];
export const CARD_INTERNATIONAL_FORM = {
  MASTER_CARD: true,
  VISA_CARD: true,
  SHETAB_CARD: false,
};

export const CONDITION = [
  { value: '', label: 'ALL' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejectd' },
  { value: 'WAITING', label: 'Waiting' },
];

// ----------------------------------------------------------------------

export default function BankCardTable({ userId = null, isChangeList = false }) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });

  const [bankFilter, setBankFilter] = useState('');
  const [conditionFilter, setConditionFilter] = useState('WAITING');
  const [dateFromFilter, setDateFromFilter] = useState(null);
  const [dateToFilter, setDateToFilter] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(null);

  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  const TABLE_HEAD = [
    { id: 'num', label: 'Num', align: 'center' },
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'cardNumber', label: 'Card Number', align: 'left' },
    { id: 'accountNumber', label: 'Account Number', align: 'left' },
    { id: 'bank', label: 'Bank', align: 'left' },
    { id: 'date', label: 'Date', align: 'left' },
    ...(!isChangeList
      ? [
          { id: 'condition', label: 'Condition', align: 'center' },
          { id: 'edit', label: 'Edit', align: 'center' },
        ]
      : []),
  ];

  // ----------------------------------------------
  const getAllBankCards = async (withFilter = true) => {
    try {
      const filters = {};

      if (withFilter) {
        if (dateFromFilter && dateToFilter) {
          filters.from = moment(dateFromFilter).startOf('days').valueOf();
          filters.to = moment(dateToFilter).endOf('days').valueOf();
        }

        if (conditionFilter) filters.status = conditionFilter;
        if (bankFilter) filters.cardType = bankFilter;
      } else {
        filters.status = 'WAITING';
      }

      if (userId) filters.userId = userId;

      let response;
      if (isChangeList) {
        response = await Api.getChangeBankCards(page, rowsPerPage, filters);
      } else {
        response = await Api.getAllBankCards(page, rowsPerPage, filters);
      }
      if (response) {
        setTableData(response.bankCardInfos);
        setTablePagination({
          totalItems: response?.totalElement || (response?.bankCardInfosTotalPages || 0) * rowsPerPage,
        });
      }
    } catch (e) {
      setTableData([]);
    }
  };

  useEffect(() => {
    getAllBankCards();
  }, [page, rowsPerPage]);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);

  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    const filters = {};

    if (dateFromFilter && dateToFilter) {
      filters.from = moment(dateFromFilter).startOf('days').valueOf();
      filters.to = moment(dateToFilter).endOf('days').valueOf();
    }

    if (conditionFilter) filters.status = conditionFilter;
    if (bankFilter) filters.cardType = bankFilter;

    if (userId) filters.userId = userId;

    try {
      let response;
      if (isChangeList) {
        response = await Api.getChangeBankCards(0, 10000000, filters);
      } else {
        response = await Api.getAllBankCards(0, 10000000, filters);
      }
      // generate excel and download
      const config = {
        language,
        fileName: t('List of all bank cards'),
        heads: [
          '#',
          t('Name'),
          t('Card Number'),
          t('Account Number'),
          t('Bank'),
          t('Date'),
          ...(!isChangeList ? [t('Condition'), t('Edit')] : []),
        ],

        columnsWidth: [5, 32, 20, 26, 13, 8, 14, 9],
        list: response.bankCardInfos.map((item) => [
          `${item.firstName} ${item.lastName}`,
          item.cardNumber,
          item.accountNumber || '-',
          t(CARD_TYPE.find((ct) => ct.value === item.cardType)?.label),
          item.expiryDateYear ? `${item.expiryDateYear}/${item.expiryDateMonth}` : '-',
          t(item.status),
        ]),
      };
      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  return (
    <>
      {!isChangeList && (
        <BankCardToolbar
          bankFilter={bankFilter}
          setBankFilter={setBankFilter}
          conditionFilter={conditionFilter}
          setConditionFilter={setConditionFilter}
          dateFromFilter={dateFromFilter}
          setDateFromFilter={setDateFromFilter}
          dateToFilter={dateToFilter}
          setDateToFilter={setDateToFilter}
          onFilter={getAllBankCards}
          onExport={onExport}
          isExportLoading={isExportLoading}
        />
      )}

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          <Table size='small'>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length || 0}
              onSort={onSort}
            />
            <TableBody>
              {tableData === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {tableData?.map((row, index) => (
                    <BankCardTableRow
                      key={row.cardId || index}
                      row={row}
                      index={index}
                      onUpdateRow={() => setShowUpdateModal(row)}
                      isChangeList={isChangeList}
                      reload={getAllBankCards}
                    />
                  ))}

                  <TableNoData isNotFound={!tableData?.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      {tableData?.length > 0 && (
        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            component='div'
            count={tablePagination.totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
          />
        </Box>
      )}

      <EditCardModal
        open={!!showUpdateModal}
        onClose={() => setShowUpdateModal(null)}
        data={showUpdateModal}
        reload={getAllBankCards}
      />
    </>
  );
}
