import React from 'react';
import { Box, Link, Stack, Typography, useTheme } from '@mui/material';
import { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';

import Iconify from '../../../components/Iconify';
import { BaseOptionChart } from '../../../components/chart';
import mergeDeep from '../../../utils/merge';
import { fPercent } from '../../../utils/formatNumber';
import { ChartBorder, ChartTotal, WalletChartContainer } from './style';

import { navigationUrl } from '../../../navigationUrl';
import SymbolIcon from '../../../components/SymbolIcon';
import otherIcon from '../../../assets/others.svg';
import Image from '../../../components/Image';

export const WALLET_CHART_HEIGHT = 200;

const DonutChart = ({ dashboardInfo }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { topWalletInfo } = dashboardInfo;
  const list = [
    {
      symbol: topWalletInfo?.firstWalletSymbol,
      name: topWalletInfo?.firstWalletName,
      percent: topWalletInfo?.firstWalletPercent,
    },
    {
      symbol: topWalletInfo?.secondWalletSymbol,
      name: topWalletInfo?.secondWalletName,
      percent: topWalletInfo?.secondWalletPercent,
    },
    {
      symbol: topWalletInfo?.thirdWalletSymbol,
      name: topWalletInfo?.thirdWalletName,
      percent: topWalletInfo?.thirdWalletPercent,
    },
  ];

  const chartOptions = mergeDeep(BaseOptionChart(), {
    colors: ['#F7931A', '#BD47FB', '#30E0A1'],
    labels: list.map((i) => i.symbol),
    sparkline: { enabled: true },
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value) => fPercent(value),
        title: {
          formatter: (label) => label,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '80%',
          labels: {
            show: false,
          },
        },
      },
    },
  });

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mt: 2 }}>
        <Typography variant='h6_secondary'>{t('Wallet')}</Typography>
        <Typography variant='body2' color='grey.300'>
          {t('{{number}} Currencies', { number: '3' })}
        </Typography>
      </Stack>

      <Stack
        direction={{ xs: 'column', xl: 'row' }}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ mt: 4 }}
      >
        <Box sx={{ position: 'relative' }}>
          <WalletChartContainer dir='ltr'>
            <ReactApexChart
              type='donut'
              series={list.map((i) => (i.percent >= 0 ? i.percent : -1 * i.percent))}
              options={chartOptions}
              height={WALLET_CHART_HEIGHT}
              width={WALLET_CHART_HEIGHT}
            />
          </WalletChartContainer>

          <ChartBorder
            sx={css`
              /* @noflip */
              left: 44px;
            `}
          />
          <ChartTotal direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'}>
            <Iconify
              icon={dashboardInfo.todayPnlPercent >= 0 ? 'ph:arrow-up-fill' : 'ph:arrow-down-fill'}
              color={dashboardInfo.todayPnlPercent >= 0 ? 'positive.main' : 'error.darker'}
              height={30}
            />
            <Typography variant='h6' sx={{ fontWeight: 600 }}>
              {fPercent(
                dashboardInfo.todayPnlPercent >= 0 ? dashboardInfo.todayPnlPercent : -1 * dashboardInfo.todayPnlPercent
              )}
            </Typography>
          </ChartTotal>
        </Box>

        <Stack spacing={2} sx={{ width: { xs: '100%', xl: 'auto' } }}>
          {list.map((item) => (
            <Stack key={item.symbol} sx={{ width: { xs: '100%', xl: 160 } }}>
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack>
                  <Stack direction={'row'} spacing={1.2} alignItems={'center'}>
                    <SymbolIcon symbol={item.symbol} width={20} height={20} />
                    <Typography variant='subtitle2'>{item.symbol}</Typography>
                  </Stack>
                </Stack>

                <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
                  {/* {item.percent !== 0 && (
                    <Iconify
                      icon={item.percent >= 0 ? 'ph:arrow-up-fill' : 'ph:arrow-down-fill'}
                      color={item.percent >= 0 ? 'positive.main' : 'error.darker'}
                      height={12}
                    />
                  )} */}
                  <Typography
                    variant='body3'
                    color={item.percent === 0 ? 'grey.300' : item.percent >= 0 ? 'positive.main' : 'error.darker'}
                  >
                    {fPercent(item.percent >= 0 ? item.percent : -1 * item.percent)}
                  </Typography>
                </Stack>
              </Stack>

              <Typography noWrap variant='body2' color='grey.400' sx={{ ml: 3.5 }}>
                {item.name}
              </Typography>
            </Stack>
          ))}

          <Stack sx={{ width: { xs: '100%', xl: 160 } }}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Stack direction={'row'} spacing={1.1} alignItems={'center'}>
                <Image src={otherIcon} alt='' sx={{ height: 22 }} />
                <Link sx={{ typography: 'subtitle2' }} component={RouterLink} to={navigationUrl.wallet}>
                  {t('Others')}
                </Link>
              </Stack>

              <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
                {/* <Iconify icon='ph:arrow-up-fill' color='positive.main' height={12} /> */}
                <Typography
                  variant='body3'
                  color={
                    topWalletInfo?.otherWalletPercent === 0
                      ? 'grey.300'
                      : topWalletInfo?.otherWalletPercent >= 0
                      ? 'positive.main'
                      : 'error.darker'
                  }
                >
                  {fPercent(topWalletInfo?.otherWalletPercent)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default DonutChart;
