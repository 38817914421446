import { Button, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function WithdrawCryptoInternal({
  internalValues,
  onChangeInternalValues,
  recipientType,
  setRecipientType,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ mb: 5 }}>
        <CustomButton title={t('Email')} value='email' setType={setRecipientType} type={recipientType} />
        <CustomButton title={t('Phone')} value='phone' setType={setRecipientType} type={recipientType} />
        <CustomButton title={t('Cryptizi ID')} value='id' setType={setRecipientType} type={recipientType} />
      </Stack>

      {recipientType === 'email' && (
        <TextField
          label={t('Recipient’s email')}
          value={internalValues.recipientEmailAddress}
          onChange={(e) => onChangeInternalValues('recipientEmailAddress', e.target.value)}
          variant='standard'
          fullWidth
          sx={{ maxWidth: 550, mb: 2.5 }}
        />
      )}

      {recipientType === 'phone' && (
        <TextField
          label={t('Recipient’s phone number')}
          value={internalValues.recipientPhoneNumber}
          onChange={(e) => onChangeInternalValues('recipientPhoneNumber', e.target.value)}
          variant='standard'
          fullWidth
          sx={{ maxWidth: 550, mb: 2.5 }}
        />
      )}

      {recipientType === 'id' && (
        <TextField
          label={t('Recipient’s ID')}
          value={internalValues.recipientId}
          onChange={(e) => onChangeInternalValues('recipientId', e.target.value)}
          variant='standard'
          fullWidth
          sx={{ maxWidth: 550, mb: 2.5 }}
        />
      )}
    </>
  );
}

const CustomButton = ({ title, value, setType, type }) => (
  <Button
    onClick={() => setType(value)}
    sx={{
      typography: 'body2',
      borderRadius: 0.5,
      p: '10px 20px 8px',
      color: value === type ? 'common.black' : 'grey.100',
      backgroundColor: value === type ? 'grey.300' : 'grey.600',
      '&:hover': {
        backgroundColor: value === type ? 'grey.300' : 'grey.400',
      },
    }}
  >
    {console.log('value', value)}
    {title}
  </Button>
);
