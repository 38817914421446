import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';

import backImage from '../../../assets/not_found.jpg';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Stack sx={{ width: '100%', height: '100vh', backgroundColor: '#171717' }} alignItems={'center'}>
      <Box
        sx={{
          backgroundImage: `url('${backImage}')`,
          backgroundSize: 'contain',
          backgroundPositionX: 'center',
          backgroundRepeat: 'no-repeat',
          width: { xl: 1200, lg: '80%', xs: '100%' },
          height: '100%',
          textAlign: 'center',
        }}
      >
        <Button
          variant='outlined'
          color='inherit'
          onClick={() => navigate(-2)}
          size='large'
          sx={{ borderRadius: 0.5, typography: 'body3', color: 'grey.400', mt: { xl: 70, md: 55, sm: 40, xs: 45 } }}
        >
          {t('GO BACK')}
        </Button>
        {/* <Result
        status='404'
        title='404'
        subTitle='Page not found!'
        extra={<Button onClick={() => navigate(-2)}>Back</Button>}
      /> */}
      </Box>
    </Stack>
  );
};

export default NotFound;
