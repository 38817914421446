import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// @mui
import { Container, Divider, Grid, Link, Stack, Typography } from '@mui/material';
// components
import { RootSubStyle } from '../style';
import MotionViewport from '../../../components/animate/MotionViewport';

import { varFade } from '../../../components/animate/variants/fade';
import { footerNavConfig } from '../../../navConfig';
import Image from '../../../components/Image';
import { navigationUrl } from '../../../navigationUrl';

// ----------------------------------------------------------------------

export default function Footer() {
  const { t } = useTranslation();

  return (
    <RootSubStyle>
      <Container component={MotionViewport}>
        <m.div variants={varFade().inUp}>
          <Grid container sx={{ mt: 5 }}>
            {footerNavConfig.map((section, sIndex) => (
              <Grid item xs={6} md={2} key={sIndex} sx={{ mb: { xs: 3, md: 0 } }}>
                <Stack spacing={2}>
                  <Typography variant='h6_secondary'>{section.subheader}</Typography>

                  {section?.grid ? (
                    <Grid container sx={{ width: 150 }}>
                      {section.children.map((link, cIndex) => (
                        <Grid item {...section.grid} sx={{ mt: 1, mb: 2 }} key={cIndex}>
                          <Link variant='body2' href={link.path} target='_blank' sx={{ color: 'grey.300' }}>
                            {link.icon && <Image src={link.icon} alt='' sx={{ width: 24 }} />}
                            {t(link.title)}
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <>
                      {section.children.map((link, cIndex) => (
                        <Link
                          component={RouterLink}
                          variant='body2'
                          to={link.path}
                          sx={{ color: 'grey.300' }}
                          key={cIndex}
                        >
                          {link.icon && <Image src={link.icon} alt='' sx={{ width: 24 }} />}
                          {t(link.title)}
                        </Link>
                      ))}
                    </>
                  )}
                </Stack>
              </Grid>
            ))}
          </Grid>

          <Stack
            direction={{ xs: 'column-reverse', md: 'row' }}
            spacing={{ xs: 3, md: 0 }}
            justifyContent={'space-between'}
            sx={{
              borderTop: '2px dashed #999',
              mt: { xs: 4, md: 8.5 },
              pt: 4,
              px: { xs: 0, md: 12 },
              mb: { xs: 6, md: 0 },
            }}
          >
            <Typography variant='body2' color='grey.400'>
              {t('Cryptizi ©. All rights reserved.')}
            </Typography>

            <Stack direction='row' spacing={2.5}>
              <Link component={RouterLink} variant='body2' to={navigationUrl.terms} sx={{ color: 'grey.400' }}>
                {t('Terms Of Use')}
              </Link>

              <Divider orientation='vertical' />

              <Link component={RouterLink} variant='body2' to={navigationUrl.privacy} sx={{ color: 'grey.400' }}>
                {t('Privacy Policy')}
              </Link>
            </Stack>
          </Stack>
        </m.div>
      </Container>
    </RootSubStyle>
  );
}
