import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, Card, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { FormProvider } from '../../../../components/hookForm';
import useInventoryForm from './hooks/useInventoryForm';
import useInventorySubmit from './hooks/useInventorySubmit';
import { PageTitle } from '../../../../components/PageTitle';
import InventoryRow from './inventoryRow';

import imgPerfectMoney from '../../../../assets/inventories/perfectMoney.png';
import imgTether from '../../../../assets/inventories/tether.png';
import Api from '../../../../http/adminHttp/serviceApiAdmin';

const inventoryFields = [
  {
    id: '1',
    name: 'Tether',
    statusField: 'tetherInventoryStatus',
    amountField: 'tetherInventoryAmount',
    symbol: imgTether,
  },
  {
    id: '2',
    name: 'Perfect Money',
    statusField: 'perfectMoneyInventoryStatus',
    amountField: 'perfectMoneyInventoryAmount',
    symbol: imgPerfectMoney,
  },
];

const InventorySetting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [data, setData] = useState(null);

  const { handleSubmit, isSubmitting, methods } = useInventoryForm(data);
  const { onSubmit } = useInventorySubmit();

  const fetchInventorySetting = async () => {
    const response = await Api.getInventorySetting();
    setData(response);
  };

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Inventory settings' },
    });

    fetchInventorySetting();
  }, []);

  return (
    <Stack sx={{ padding: { xs: '0 25px', lg: '0' } }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ mt: 3.5, px: { xs: 2, md: '40px' }, py: { xs: 3, md: '40px' } }}>
          <PageTitle title='Inventory settings' subTitle='Caption' />

          {data === null ? (
            <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: 170 }}>
              <CircularProgress size={30} color='success' />
            </Stack>
          ) : (
            <Stack sx={{ mt: 4 }} spacing={3.75}>
              {inventoryFields.map((inventory) => (
                <InventoryRow field={inventory} key={inventory.id} />
              ))}
            </Stack>
          )}
        </Card>

        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ mt: 2.5, maxWidth: { xs: 360, sm: 283 } }}
        >
          {t('Save')}
        </LoadingButton>
      </FormProvider>
    </Stack>
  );
};

export default InventorySetting;
