import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';

import ModalContainer from '../../../../components/ModalContainer';
import ModalFilter from '../../../../components/ModalFilter';
import SymbolIcon from '../../../../components/SymbolIcon';
import Api from '../../../../http/serviceApi';
import LoadingScreen from '../../../../components/LoadingScreen';

const itemsPerPage = 15;

export default function FuturesSymbolModal({ open, onClose, onSelect = () => {} }) {
  const [filtered, setFiltered] = useState([]);

  // fetching data from server
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  // -------------------------
  const fetchSymbols = async () => {
    setLoading(true);

    try {
      const data = await Api.getAllSymbolFutures();
      const list = Object.values(data.symbolMap);

      if (list.length > 0) {
        onSelect(list.find((i) => i.baseAsset === 'BTC') || list[0]);
      }

      setDataSource(list);
      setFiltered(list);
    } catch (e) {}

    setLoading(false);
  };

  // -------------------------
  useEffect(() => {
    fetchSymbols();
  }, []);

  // lazy load list
  const [hasMore, setHasMore] = useState(true);
  const [countPerPage, setCountPerPage] = useState(itemsPerPage);
  const loadMore = () => {
    if (countPerPage >= filtered.length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setCountPerPage((prev) => prev + itemsPerPage);
      }, 300);
    }
  };

  // -------------------------
  const onSearch = (newList) => {
    setFiltered(newList);
    setCountPerPage(itemsPerPage);
    if (itemsPerPage < newList.length) {
      setHasMore(true);
    }
  };

  // -------------------------
  useEffect(() => {
    if (!open) {
      setFiltered(dataSource);
    }
  }, [open]);

  // -------------------------
  if (loading) {
    return <LoadingScreen />;
  }

  // -------------------------
  return (
    <ModalContainer open={open} onClose={onClose} title={'Select Symbol'} width={400}>
      <ModalFilter dataSource={dataSource} setFiltered={onSearch} />

      {loading ? (
        <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: 340 }}>
          <CircularProgress size={30} color='success' />
        </Stack>
      ) : (
        <Box
          sx={{
            height: 340,
            overflow: 'auto',
            '&::-webkit-scrollbar': { width: 18 },
            '&::-webkit-scrollbar-track': { backgroundColor: 'transparent' },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#53555f',
              borderRadius: '18px',
              border: '6px solid transparent',
              backgroundClip: 'content-box',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#6c6e75',
            },
          }}
        >
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={
              <Stack key='loader' alignItems={'center'} justifyContent={'center'} sx={{ my: 2 }}>
                <CircularProgress size={20} color='success' />
              </Stack>
            }
            useWindow={false}
          >
            {filtered.slice(0, countPerPage).map((item) => (
              <RowSymbol
                key={item.baseAsset}
                item={item}
                onSelect={() => {
                  onSelect(item);
                  onClose();
                }}
              />
            ))}
          </InfiniteScroll>
        </Box>
      )}
    </ModalContainer>
  );
}

const RowSymbol = ({ item, onSelect }) => {
  const { baseAsset, quoteAsset, baseName } = item;

  return (
    <Button fullWidth onClick={onSelect} sx={{ py: 1.5, px: 2, borderRadius: 1, textAlign: 'left' }}>
      <Stack direction='row' alignItems={'center'} sx={{ width: '100%' }} spacing={2}>
        <SymbolIcon symbol={baseAsset} width={24} height={24} />

        <Typography variant='subtitle2'>
          {baseAsset}/{quoteAsset}
        </Typography>

        <Typography variant='body3' color='grey.300' noWrap>
          {baseName}
        </Typography>
      </Stack>
    </Button>
  );
};
