import { Button, MenuItem, Stack, TextField } from '@mui/material';
import DatePicker from '../../../../../../components/DatePicker';
import NumberRange from '../../../../../../components/NumberRange';
import { useTranslation } from 'react-i18next';
import ComplexToolbar from '../../../../../../components/ComplexToolbar';
import NumRangeWithInput from '../../../../../../components/NumRangeWithInput';
import FillSelect from "../../../../../../components/FillSelect";
export const FIAT_STATUS = [
  { value: 'ALL', label: 'All' },
  { value: 'CANCELED', label: 'Canceled' },
  { value: 'FAILURE', label: 'Failure' },
  { value: 'NEW', label: 'New' },
  { value: 'SUCCESS', label: 'Success' },
];
export default function AdminTomanTransactionToolbar({
                                                       fiatStatusFilter,
                                                       setFiatStatusFilter,
                                                       dateFromFilter,
                                                       setDateFromFilter,
                                                       dateToFilter,
                                                       setDateToFilter,
                                                     }) {
  const { t } = useTranslation();

  return (
    <>
      {/*<ComplexToolbar*/}
      {/*  onSearch={(v) => console.log(v)}*/}
      {/*  onExport={() => console.log('export')}*/}
      {/*  onPrint={() => console.log('print')}*/}
      {/*>*/}
      <Stack spacing={3} sx={{mt:4}}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} sx={{ mt: 2 }} alignItems={'center'}>
          <FillSelect
            variant='standard'
            value={fiatStatusFilter}
            onChange={setFiatStatusFilter}
            label={t('Status')}
            sx={{ width: { xs: '100%', md: 220 } }}
          >
            {FIAT_STATUS.map((item) => (
              <MenuItem key={item.value} value={item.value} sx={{ typography: 'body2' }}>
                {t(item.label)}
              </MenuItem>
            ))}
          </FillSelect>

          <Stack direction={'row'}>
            <DatePicker
              label={t('From')}
              value={dateFromFilter}
              setValue={setDateFromFilter}
              variant='standard'
              maxDate={dateToFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
              InputProps={null}
            />
            <DatePicker
              label={t('To')}
              value={dateToFilter}
              setValue={setDateToFilter}
              variant='standard'
              minDate={dateFromFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
            />
          </Stack>
        </Stack>

      </Stack>

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 5 }} sx={{ mt: 5, mb: 5.75 }}>
        {/*<Button fullWidth variant='contained' sx={{ maxWidth: 300, typography: 'body3', fontWeight: 500 }}>*/}
        {/*  {t('Filter')}*/}
        {/*</Button>*/}
      </Stack>
      {/*</ComplexToolbar>*/}
    </>
  );
}
