import { useState } from 'react';
import { useNavigate } from 'react-router';

import AuthLayout from '../../../components/authLayout/authLayout';
import { navigationUrl } from '../../../navigationUrl';
import RegisterForm from '../../../components/register/registerForm';
import RegisterOTP from '../../../components/register/registerOTP';
import StatusModal from '../../../components/StatusModal';

const Register = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [emailAddress, setEmailAddress] = useState(null);
  const [statusModal, setStatusModel] = useState(false);

  const onSuccess = ({ email }) => {
    setEmailAddress(email);
    setStep(1);
  };

  const onFinished = () => {
    setStatusModel(true);
  };

  return (
    <AuthLayout linkText='Login' linkTo={navigationUrl.login}>
      {step === 0 && <RegisterForm onSuccess={onSuccess} />}

      {step === 1 && <RegisterOTP emailAddress={emailAddress} onFinished={onFinished} />}

      <StatusModal
        open={statusModal}
        onClose={() => setStatusModel(false)}
        delay={0}
        type='success'
        title='Successfully'
        subtitle='Your email has been successfully verified'
        onAction={() => navigate(navigationUrl.login)}
        buttonLabel='Login'
      />
    </AuthLayout>
  );
};

export default Register;
