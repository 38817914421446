import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import Api from '../http/serviceApi';
import ApiAdmin from '../http/adminHttp/serviceApiAdmin';

export default function useTimer({ emailAddress, mobiles, isAdmin, type, operationType = 'Login' }) {
  const { enqueueSnackbar } = useSnackbar();
  const timerRef = useRef(null);
  const [timer, setTimer] = useState('00:00:00');

  const onRequestAgainClick = async () => {
    try {
      let response = {};
      switch (operationType) {
        case 'Wallet':
          response = await Api.resendOtpWallet();
          break;
        case 'ChangeMobile':
          if (isAdmin) {
            response = await ApiAdmin.preChangeOrAddMobile(mobiles.oldMobile, mobiles.newMobile, type);
          } else {
            response = await Api.preChangeOrAddMobile(mobiles.oldMobile, mobiles.newMobile, type);
          }
          break;
        default:
          response = await Api.resendOtp(emailAddress, operationType);
      }

      if (response?.status === 'SUCCESS') {
        clearTimer(getDeadTime());
        enqueueSnackbar('message: OTP code has been sent.', { variant: 'success' });
      }
    } catch (e) {}
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  const clearTimer = (e) => {
    setTimer('00:02:00');

    if (timerRef.current) clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      startTimer(e);
    }, 1000);
  };

  const getDeadTime = (total = 120) => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + total);
    return deadline;
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (hours > 9 ? hours : '0' + hours) +
          ':' +
          (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds)
      );
    }
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  return {
    onRequestAgainClick,
    timer,
    stopTimer: () => clearInterval(timerRef?.current),
    reloadTimer: () => clearTimer(getDeadTime()),
    activeTimerState: timer === '00:00:00',
  };
}
