import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function WithdrawCryptoInfo({
  isExternal = false,
  symbolSelected,
  networkSelected,
  defaultNetwork,
  balance,
}) {
  const { t } = useTranslation();

  return (
    <Stack spacing={{ xs: 1, md: 0 }} direction={{ xs: 'column', sm: 'row' }} sx={{ mt: 5 }}>
      {/* Left */}
      <Stack spacing={{ xs: 2, md: 5 }} sx={{ width: { xs: 'auto', sm: 250 } }}>
        <Box>
          <Typography variant='body3' sx={{ color: 'grey.400' }}>
            {symbolSelected || ''} {t('balance')}
          </Typography>
          <Typography variant='body2' sx={{ color: 'grey.100' }}>
            {balance || 0} {symbolSelected || ''}
          </Typography>
        </Box>

        {isExternal && (
          <Box>
            <Typography variant='body3' sx={{ color: 'grey.400' }}>
              {t('Network Fee')}
            </Typography>
            <Typography variant='body2' sx={{ color: 'grey.100' }}>
              {networkSelected?.withdrawFee || 0} {symbolSelected || ''}
            </Typography>
          </Box>
        )}
      </Stack>

      {/* Right */}
      <Stack spacing={{ xs: 2, md: 5 }}>
        <Box>
          <Typography variant='body3' sx={{ color: 'grey.400' }}>
            {t('Minimum withdrawal')}
          </Typography>
          <Typography variant='body2' sx={{ color: 'grey.100' }}>
            {/* TODO: withdrawMin for internal */}
            {isExternal ? networkSelected?.withdrawMin || 0 : 0} {symbolSelected || ''}
          </Typography>
        </Box>

        {isExternal && (
          <Box>
            <Typography variant='body3' sx={{ color: 'grey.400' }}>
              {t('24h remaining limit')}
            </Typography>
            <Typography variant='body2' sx={{ color: 'grey.100' }}>
              0
            </Typography>
          </Box>
        )}
      </Stack>
    </Stack>
  );
}
