import React from 'react';
import { Form, Input, Checkbox, Button, Row, Image } from 'antd';
import classes from './adminForgotNewPassForm.module.scss';
import { InputCustom } from '../../../../components/InputCustom/inputCustom';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { useNavigate } from 'react-router';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
// import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { InputPassStrong } from '../../../../components/inputPassStrength/inputPassStrength';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { navigationUrl } from '../../../../navigationUrl';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';

const AdminForgotNewPassForm = () => {
  const { t } = useTranslation();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [form] = Form.useForm();
  let navigate = useNavigate();
  // const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [passLevel, setPassLevel] = useState(0);
  const [loading, setLoading] = useState(false);

  let fillCount = 0;
  const checkValue = (val) => {
    fillCount = 0;
    if (!val) {
      setPassLevel(0);
      return;
    }
    let str = val?.toString();
    const matched1 = str.match(/.*[0-9].*/);
    if (matched1?.length > 0) fillCount++;
    const matched2 = str.match(/.*[a-z].*/);
    if (matched2?.length > 0) fillCount++;
    const matched3 = str.match(/.*[A-Z].*/);
    if (matched3?.length > 0) fillCount++;
    const matched4 = str.match(/^.{8,}$/);
    if (matched4?.length > 0) fillCount++;
    const matched5 = str.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
    if (matched5?.length > 0) fillCount++;

    setPassLevel(fillCount);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
    checkValue(e.target.value);
  };
  const onConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        resetPassword(values.password);
      })
      .catch((errorInfo) => {});
  };

  const resetPassword = async (password) => {
    setLoading(true);
    let otp = location?.search?.substring(5);
    const data = {
      otp: otp,
      password: password,
    };
    const response = await Api.resetPassword(data);
    if (response?.status === 'SUCCESS') {
      navigate(navigationUrl.admin_forgotpass_resetok);
    }
    setLoading(false);
    console.log(`otp: ${otp} - password: ${password}`);
    console.log('resetPassword_response: ', response);
  };

  return (
    <div className={classes.container}>
      <div className={classes.formTitleDiv}>
        <span className={classes.formTitleBig}>Set new password</span>
        <span className={classes.formTitleSmall}>
          your new password must be different to previously used passwords.
        </span>
      </div>

      <Form
        layout='vertical'
        form={form}
        name='confirmPass'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <Form.Item
          className={classes.formItem}
          name='password'
          rules={[
            {
              required: true,
              message: t('Please input your password!'),
            },
            {
              validator: async () => {
                return passLevel === 5 ? Promise.resolve() : Promise.reject('error');
              },
              message:
                t('Passwords must have at least 8 characters and contain at least one uppercase letters, lowercase letters, number and symbol.'),
            },
          ]}
          tooltip={t('What do you want others to call you?')}
        >
          <InputPassStrong
            type='password'
            value={password}
            label={t('Password')}
            required={true}
            levelFill={passLevel}
            onChange={onPasswordChange}
          />
        </Form.Item>

        <Form.Item
          className={classes.formItem}
          name={t('confirmPassword')}
          rules={[
            {
              required: true,
              message: t('Please input confirm password!'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('The two passwords that you entered do not match!')));
              },
            }),
          ]}
        >
          <InputCustom
            type='password'
            value={confirmPassword}
            label={t('Confirm Password')}
            placeholder={t('Confirm Password')}
            required={true}
            onChange={onConfirmPasswordChange}
          />
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit' block shape='round' loading={loading}>
            {t('Reset password')}
          </Button>
        </Form.Item>
      </Form>

      <div className={classes.btnBackDiv}>
        <HiOutlineArrowNarrowLeft className={classes.icon} />
        <Button
          type='link'
          shape='round'
          className={classes.btnBack}
          onClick={() => navigate(navigationUrl.admin_login)}
        >
          {t('Back to login')}
        </Button>
      </div>
    </div>
  );
};

export default AdminForgotNewPassForm;
