import { useState } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect } from 'react';

import Api from '../../../http/adminHttp/serviceApiAdmin';
import { useTranslation } from 'react-i18next';

export default function StatisticCounter() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  // --------------------
  const getData = async () => {
    try {
      const response = await Api.getSecondStatistics();
      setData([
        { label: 'Opened Tickets', value: response?.totalNumberOfOpenTicket || 0 },
        { label: 'All tickets', value: response?.totalNumberOfTicket || 0 },
        { label: 'Active users', value: response?.totalNumberOfActiveUser || 0 },
        { label: 'Existing users', value: response?.totalNumberOfUser || 0 },
        { label: 'Referral Users', value: response?.totalNumberOfIntroduce || 0 },
        { label: 'Transaction', value: response?.totalNumberOfOtcTransaction || 0 },
      ]);
    } catch (e) {}
  };

  // ---------------------
  useEffect(() => {
    getData();
  }, []);

  // ---------------------
  return data.map((item, index) => (
    <Grid item xs={12} sm={4} xl={2} key={index}>
      <Card sx={{ p: 2 }}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems='center'>
          <Stack spacing={1}>
            <Typography>{item.value}</Typography>
            <Typography variant='body3'>{t(item.label)}</Typography>
          </Stack>
          <Stack
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ backgroundColor: 'rgba(189, 71, 251 , 0.2)', borderRadius: 1.5, width: 50, height: 50 }}
          >
            {icons[item.label]}
          </Stack>
        </Stack>
      </Card>
    </Grid>
  ));
}

const icons = {
  'Opened Tickets': (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.91 14.6888C18.91 15.9788 19.97 17.0287 21.26 17.0287C21.26 20.7787 20.32 21.7188 16.57 21.7188H7.19C3.44 21.7188 2.5 20.7787 2.5 17.0287V16.5688C3.79 16.5688 4.85 15.5087 4.85 14.2188C4.85 12.9288 3.79 11.8687 2.5 11.8687V11.4087C2.51 7.65875 3.44 6.71875 7.19 6.71875H16.56C20.31 6.71875 21.25 7.65875 21.25 11.4087V12.3488C19.96 12.3488 18.91 13.3888 18.91 14.6888Z'
        stroke='#BD47FB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.2111 6.7225H7.12109L10.0511 3.7925C12.4411 1.4025 13.6411 1.4025 16.0311 3.7925L16.6311 4.3925C16.0011 5.0225 15.8511 5.9525 16.2111 6.7225Z'
        stroke='#BD47FB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.87891 6.71875L9.87891 21.7188'
        stroke='#BD47FB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke-dasharray='5 5'
      />
    </svg>
  ),

  'All tickets': (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.5 12.5C19.5 11.12 20.62 10 22 10V9C22 5 21 4 17 4H7C3 4 2 5 2 9V9.5C3.38 9.5 4.5 10.62 4.5 12C4.5 13.38 3.38 14.5 2 14.5V15C2 19 3 20 7 20H17C21 20 22 19 22 15C20.62 15 19.5 13.88 19.5 12.5Z'
        stroke='#BD47FB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 4L10 20'
        stroke='#BD47FB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke-dasharray='5 5'
      />
    </svg>
  ),

  'Active users': (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.4399 19.0512L15.9599 20.5712L18.9999 17.5312'
        stroke='#BD47FB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.16 10.87C12.06 10.86 11.94 10.86 11.83 10.87C9.44997 10.79 7.55997 8.84 7.55997 6.44C7.54997 3.99 9.53997 2 11.99 2C14.44 2 16.43 3.99 16.43 6.44C16.43 8.84 14.53 10.79 12.16 10.87Z'
        stroke='#BD47FB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.99 21.8097C10.17 21.8097 8.36004 21.3497 6.98004 20.4297C4.56004 18.8097 4.56004 16.1697 6.98004 14.5597C9.73004 12.7197 14.24 12.7197 16.99 14.5597'
        stroke='#BD47FB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ),

  'Existing users': (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18 7.16C17.94 7.15 17.87 7.15 17.81 7.16C16.43 7.11 15.33 5.98 15.33 4.58C15.33 3.15 16.48 2 17.91 2C19.34 2 20.49 3.16 20.49 4.58C20.48 5.98 19.38 7.11 18 7.16Z'
        stroke='#BD47FB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.97 14.4402C18.34 14.6702 19.85 14.4302 20.91 13.7202C22.32 12.7802 22.32 11.2402 20.91 10.3002C19.84 9.59016 18.31 9.35016 16.94 9.59016'
        stroke='#BD47FB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.96998 7.16C6.02998 7.15 6.09998 7.15 6.15998 7.16C7.53998 7.11 8.63998 5.98 8.63998 4.58C8.63998 3.15 7.48998 2 6.05998 2C4.62998 2 3.47998 3.16 3.47998 4.58C3.48998 5.98 4.58998 7.11 5.96998 7.16Z'
        stroke='#BD47FB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7 14.4402C5.63 14.6702 4.12 14.4302 3.06 13.7202C1.65 12.7802 1.65 11.2402 3.06 10.3002C4.13 9.59016 5.66 9.35016 7.03 9.59016'
        stroke='#BD47FB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 14.6288C11.94 14.6188 11.87 14.6188 11.81 14.6288C10.43 14.5788 9.32996 13.4488 9.32996 12.0488C9.32996 10.6188 10.48 9.46875 11.91 9.46875C13.34 9.46875 14.49 10.6288 14.49 12.0488C14.48 13.4488 13.38 14.5888 12 14.6288Z'
        stroke='#BD47FB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.08997 17.7794C7.67997 18.7194 7.67997 20.2594 9.08997 21.1994C10.69 22.2694 13.31 22.2694 14.91 21.1994C16.32 20.2594 16.32 18.7194 14.91 17.7794C13.32 16.7194 10.69 16.7194 9.08997 17.7794Z'
        stroke='#BD47FB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ),

  Transaction: (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.73 19.7C7.55 18.82 8.8 18.89 9.52 19.85L10.53 21.2C11.34 22.27 12.65 22.27 13.46 21.2L14.47 19.85C15.19 18.89 16.44 18.82 17.26 19.7C19.04 21.6 20.49 20.97 20.49 18.31V7.04C20.5 3.01 19.56 2 15.78 2H8.22C4.44 2 3.5 3.01 3.5 7.04V18.3C3.5 20.97 4.96 21.59 6.73 19.7Z'
        stroke='#BD47FB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8 7H16' stroke='#BD47FB' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 11H15' stroke='#BD47FB' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  ),

  'Referral Users': (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 9.10937V14.8794C3 16.9994 3 16.9994 5 18.3494L10.5 21.5294C11.33 22.0094 12.68 22.0094 13.5 21.5294L19 18.3494C21 16.9994 21 16.9994 21 14.8894V9.10937C21 6.99937 21 6.99937 19 5.64937L13.5 2.46937C12.68 1.98937 11.33 1.98937 10.5 2.46937L5 5.64937C3 6.99937 3 6.99937 3 9.10937Z'
        stroke='#BD47FB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z'
        stroke='#BD47FB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ),
};
