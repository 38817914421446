import { Button, Col, DatePicker, Form, Radio, Row, Space } from 'antd';
import React, { useState } from 'react';
import { convertTimeStampToDate } from '../../../../../utils/dateConverter';
import { InputCustom } from '../../../../../components/InputCustom/inputCustom';
import '../btnBlockStyle.scss';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTranslation } from 'react-i18next';

const BasicForm = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  React.useEffect(() => {
    form.setFieldsValue({
      firstName: props.formData?.firstName,
      lastName: props.formData?.lastName,
      middleName: props.formData?.middleName,
      country: props.formData?.country,
      streetAddress: props.formData?.address,
      birthDay: convertTimeStampToDate(props.formData?.birthday),
      postalCode: props.formData?.postalCode,
      city: props.formData?.cityName,
      phoneNumber: props.formData?.phoneNumber,
    });
  });

  const onFinish = () => {};

  const colPropsFormItem = {
    xs: 24,
    sm: 12,
    md: 10,
    lg: 8,
    xl: 6,
  };

  const onSaveClick = () => {
    const data = {};
    props.onSaveClick(data);
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <Form
        loading={true}
        form={form}
        layout='vertical'
        name='basicForm'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <Row gutter={[20, 0]}>
          <Col {...colPropsFormItem}>
            <Form.Item name='firstName'>
              <InputCustom label={t('First Name')} placeholder='First Name' />
            </Form.Item>
          </Col>
          <Col {...colPropsFormItem}>
            <Form.Item name='lastName'>
              <InputCustom label={t('Last Name')} placeholder='Last Name' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 0]}>
          <Col {...colPropsFormItem}>
            <Form.Item name='middleName'>
              <InputCustom label={t('Middle Name')} placeholder='Middle Name' />
            </Form.Item>
          </Col>
          <Col {...colPropsFormItem}>
            <Form.Item name='country'>
              <InputCustom label={t('Country')} placeholder='Country' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 0]}>
          <Col {...colPropsFormItem}>
            <Form.Item name='streetAddress'>
              <InputCustom label={t('Street Address')} placeholder='Street Address' />
            </Form.Item>
          </Col>
          <Col {...colPropsFormItem}>
            <Form.Item name='birthDay'>
              <InputCustom label={t('Birth Day')} placeholder='Birth Day' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 0]}>
          <Col {...colPropsFormItem}>
            <Form.Item name='postalCode'>
              <InputCustom type='number' label={t('Postal Code')} placeholder='Postal Code' />
            </Form.Item>
          </Col>
          <Col {...colPropsFormItem}>
            <Form.Item name='city'>
              <InputCustom label={t('City')} placeholder='City' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 0]}>
          <Col {...colPropsFormItem}>
            <Form.Item name='phoneNumber'>
              <InputCustom label={t('Phone Number')} placeholder='Phone Number' />
            </Form.Item>
          </Col>
        </Row>
        {/* <Radio.Group onChange={onChange} value={value}> */}
        {/* <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={1}>Approve</Radio>
                        <Radio value={2}>Reject</Radio>
                    </Space>
                </Radio.Group> */}
        <Row gutter={[10, 0]} style={{ marginTop: '20px' }}>
          {/* <Col>
                        <Button type="primary" shape="round">Save</Button>
                    </Col> */}
          <Col>
            <Button shape='round'>{t('Send Notifiaction')}</Button>
          </Col>
          <Col>
            <Button type='primary' shape='round' className='btnBlock' onClick={props.onBlockClick}>
              {t('Block')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BasicForm;
