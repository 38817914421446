import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import ModalContainer from '../../../../../../components/ModalContainer';
import SymbolIcon from '../../../../../../components/SymbolIcon';
import { prettyUppercases } from '../../../../../../utils';
import { fFloat } from '../../../../../../utils/formatNumber';

export default function CryptoTransactionDetailModal({ open, onClose, data, status }) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  return (
    <ModalContainer open={open} onClose={onClose} title={'Transactions Details'} width={590}>
      <Stack sx={{ mt: 4.5, alignItems: 'center' }}>
        <SymbolIcon symbol={data?.symbol} width={80} height={80} />
        <Typography variant='body2' color={'grey.100'} fontWeight={500} sx={{ mt: 2.5, mb: 2 }}>
          {data?.symbol}
        </Typography>

        <Box
          sx={{
            width: 105,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            mb: 3,
            p: 0.8,
            bgcolor:
              status === 'SUCCESS' || status === 'Completed' ? 'rgba(48, 224, 161, 0.1)'  : status?.toString()?.toLowerCase() === 'pending'  ? 'rgba(242, 127, 22, 0.1)' :   'rgba(250, 34, 86, 0.1)',
          }}
        >
          <Typography
            variant='body2'
            sx={{ color: status === 'SUCCESS' || status === 'Completed' ? '#30E0A1' : status?.toString()?.toLowerCase() === 'pending'  ? 'rgba(242, 127, 22, 1)' :  'negative.main' }}
          >
            {t(status)}
          </Typography>
        </Box>
      </Stack>

      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} sx={{ mb: 1.5 }} spacing={2}>
        <Stack spacing={1.5}>
          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Transaction')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              #{data?.id}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('User')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {data?.firstName} {data?.lastName}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Mobile')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {data?.mobile || '-'}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Email')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {data?.emailAddress || '-'}
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing={1.5}>
          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Type')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {t(prettyUppercases(data?.transactionType))}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Amount')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {fFloat(data?.amount)}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Date')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {moment(data?.createDate).locale(language).format('YYYY/M/D')}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
              {t('Fee')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {data?.fee || '-'}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={1.5} sx={{ mb: 5 }}>
        <Stack>
          <Typography variant='body3' color={'grey.400'}>
            {t('Address wallet')}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {data?.address}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant='body3' color={'grey.400'}>
            {t('TxID')}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {data?.transactionId}
          </Typography>
        </Stack>
      </Stack>

      {/* <Box sx={{ backgroundColor: 'grey.600', borderRadius: 1, py: 1, pl: 1.5, mb: 4.4 }}>
        <Typography variant='body3' color={'grey.100'}>
          {t('Displaying saved parameters (Click)')}
        </Typography>
      </Box> */}

      {/* <Stack direction={'row'} alignItems={'center'} spacing={{ xs: 2, md: 4.25 }}>
        <Button
          fullWidth
          size='large'
          color='inherit'
          variant='outlined'
          sx={{ typography: 'body3', fontWeight: 500, maxWidth: '50%' }}
        >
          {t('Cancel')}
        </Button>

        <Button
          fullWidth
          variant='contained'
          size='large'
          color='error'
          sx={{
            maxWidth: '50%',
            typography: 'body3',
            fontWeight: 500,
            backgroundColor: 'rgba(250, 34, 86, 0.2)',
            color: '#FA2256',
            '&:hover': {
              color: 'grey.0',
            },
          }}
        >
          {t('Delete')}
        </Button>
      </Stack> */}

      <Button
        fullWidth
        variant='contained'
        size='large'
        sx={{
          typography: 'body3',
          fontWeight: 500,
          mt: 4.25,
        }}
        onClick={onClose}
      >
        {t('Close')}
      </Button>
    </ModalContainer>
  );
}
