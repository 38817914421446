import { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';

import ModalContainer from './ModalContainer';
import Api from '../http/serviceApi';
import Scrollbar from './Scrollbar';
import SymbolIcon from './SymbolIcon';
import useResponsive from '../hooks/useResponsive';

export default function SelectNetworkModal({
  open,
  onClose,
  symbol,
  setDefault = () => {},
  onSelect = () => {},

  isWithdraw = true,
}) {
  const isTablet = useResponsive('up', 'sm');
  const { t } = useTranslation();

  // fetching data from server
  const [dataSource, setDataSource] = useState([]);
  const [defaultNetwork, setDefaultNetwork] = useState(null);
  const [inactive , setInactive] = useState(false)
  const fetchSymbolNetworkInfo = async () => {
    try {
      const data = await Api.getSymbolNetworkInfo(symbol);
      if (data.status === 'SUCCESS') {
        if(
          data?.networkInfos?.[0]?.activeInCryptizi === false
        ){
          setInactive(true)
        }
        setDataSource(data.networkInfos?.filter((item) => item.activeInCryptizi));
        const dn = data.networkInfos.filter((item) => item.activeInCryptizi)?.[0] || null;
        setDefaultNetwork(dn);
        setDefault(dn);
        if(data?.networkInfos?.length > 1){
          onSelect(null);
        }else {
          onSelect(dn);
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (symbol) {
      fetchSymbolNetworkInfo();
    }
  }, [symbol]);

  console.log('inActive' , dataSource)
  return (
    <ModalContainer open={open} onClose={onClose} width={590} title={'Select Network'}>
      <Box sx={{ my: 2.5 }}>
        {!defaultNetwork && (
          <Stack
            direction={'row'}
            spacing={1}
            alignItems='center'
            sx={{ backgroundColor: 'rgb(255 193 7 / 13%)', p: 2, borderRadius: 1, mt: 3 }}
          >
            {symbol && <SymbolIcon symbol={symbol} />}
            {
              inactive ?

                <Typography variant='body3' color='error.main'>
                  {t('This Network is inactive in Cryptizi')}
                </Typography>
                :
                <Typography variant='body3' color='warning.main'>
                  {t('Unmatched networks automatically removed. Select a network below')}
                </Typography>
            }

          </Stack>
        )}

        <Scrollbar
          height={isTablet ? '380px' : '100%'}
          style={
            isTablet
              ? {
                  paddingTop: 10,
                  marginTop: 10,
                  paddingRight: 10,
                  paddingLeft: 10,
                }
              : { padding: 0 }
          }
        >
          <Stack spacing={1.5}>
            {dataSource.map((item, index) =>  (

              <>
                <Button
                  fullWidth
                  disabled={(isWithdraw && !item.withdrawEnable) || (!isWithdraw && !item.depositEnable)}
                  onClick={() => {
                    onClose();
                    onSelect(item);
                  }}
                  sx={{
                    borderRadius: 1,
                    ...(((isWithdraw && !item.withdrawEnable) || (!isWithdraw && !item.depositEnable)) && {
                      opacity: 0.2,
                    }),
                  }}
                  key={index}
                >
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    spacing={2}
                    justifyContent='space-between'
                    sx={{ width: '100%', mx: { xs: 0, sm: 0.5 } }}
                  >
                    <Stack alignItems={'flex-start'} spacing={0.5}>
                      <Typography variant='body2' sx={{ color: 'grey.100' }}>
                        {item.network}
                      </Typography>
                      <Typography variant='body3' sx={{ color: 'grey.100' }}>
                        {item.name}
                      </Typography>
                    </Stack>

                    <Stack alignItems={{ xs: 'flex-start', sm: 'flex-end' }} spacing={0.5}>
                      <Typography variant='body2' sx={{ color: 'grey.100' }}>
                        {t('Arrival time = {{min}} min', { min: item.arrivalTime || 0 })}
                      </Typography>
                      {isWithdraw && (
                        <Typography variant='body3' sx={{ color: 'grey.100' }}>
                          {t('fee')} {item.withdrawFee} {symbol}
                          {/* (=${item.withdrawFee}) */}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Button>

                <Divider sx={{ width: '100%' }}></Divider>
              </>
            ))}
          </Stack>
        </Scrollbar>

        <Stack alignItems={'flex-end'} sx={{ mt: 5 }}>
          <Typography>{t('Fee rules')}</Typography>
        </Stack>
      </Box>
    </ModalContainer>
  );
}
