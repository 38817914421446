import { IconButton, InputAdornment, Link, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../../../components/PageTitle';
import Iconify from '../../../components/Iconify';

export default function ReferralInfo({ code, link }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return (
    <>
      <PageTitle
        title='Link and introduction code'
        subTitle='By providing a link or referral code to your friends, you can earn money from their transaction fees and also allocate a part of this income to them, which is both for their benefit and for your benefit.'
      />

      <Stack sx={{ mt: 5 }} spacing={2.5} direction={'row'} alignItems={'center'}>
        <TextField fullWidth variant='standard' label={'Introduction code'} value={code} sx={{ maxWidth: 150 }} />
        <TextField
          fullWidth
          variant='standard'
          label={'Introduction link'}
          value={link}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <CopyToClipboard text={link} onCopy={() => enqueueSnackbar(t('Copied!'))}>
                  <Tooltip title={t('Copy')}>
                    <IconButton sx={{ ml: 4.5 }}>
                      <Iconify icon={'ep:copy-document'} sx={{ width: 22, height: 22, color: 'grey.400' }} />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction={'row'} alignItems={'center'} spacing={5} sx={{ mt: 3.75 }}>
        <Typography variant='body3' color={'grey.100'}>
          {t('If you need help, watch the guide video in this section')}
        </Typography>
        <Link variant='body3' component={RouterLink} to={'#'} color='secondary.main' underline='always'>
          {t('Watch the movie')}
        </Link>
      </Stack>
    </>
  );
}
