import { useEffect, useState } from 'react';
import { Card, Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { BaseOptionChart } from '../../../components/chart';
import { fPercent } from '../../../utils/formatNumber';
import mergeDeep from '../../../utils/merge';
import { BadgeIcon, ChartBackground, ChartBorder } from './style';
import Api from '../../../http/adminHttp/serviceApiAdmin';

const RoundChart = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  // --------------------
  const [data, setData] = useState({ web: 0, mobile: 0 });

  // --------------------
  const getData = async () => {
    try {
      const response = await Api.getReportLoginPercentage();
      setData({
        web: response?.userWebLoginPercent || 0,
        mobile: response?.userMobileLoginPercent || 0,
      });
    } catch (e) {}
  };

  // ---------------------
  useEffect(() => {
    getData();
  }, []);

  // --------------------
  const chartOptions = mergeDeep(BaseOptionChart(), {
    colors: ['#BD47FB', '#30E0A1'],
    labels: [t('Desktop'), t('Mobile')],
    sparkline: { enabled: true },
    stroke: { colors: [theme.palette.background.paper] },
    legend: { show: false },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value) => fPercent(value),
        title: {
          formatter: (label) => label,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '60%',
          labels: {
            show: false,
          },
        },
      },
    },
  });

  // --------------------
  return (
    <>
      <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 }, height: '100%' }}>
        <Stack direction='row' sx={{ height: '100%' }}>
          <Stack justifyContent='space-between'>
            <Typography variant='body1_secondary' sx={{ fontWeight: 900 }}>
              {t('Login')}
            </Typography>

            <Stack spacing={3}>
              <Stack direction={'row'}>
                <BadgeIcon bgColor='#30E0A1' />
                <Typography variant='body4' color='grey.100' sx={{ mt: 0.5 }}>
                  {t('Mobile')}
                </Typography>
              </Stack>

              <Stack direction={'row'}>
                <BadgeIcon bgColor='#BD47FB' />
                <Typography variant='body4' color='grey.100' sx={{ mt: 0.5 }}>
                  {t('Desktap')}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack justifyContent='center' alignItems='center' sx={{ position: 'relative', flex: 1, height: '100%' }}>
            <ChartBorder />
            <ChartBackground />

            <ReactApexChart
              type='donut'
              series={[data.web, data.mobile]}
              options={chartOptions}
              height={250}
              width={250}
            />
          </Stack>
        </Stack>
      </Card>
    </>
  );
};

export default RoundChart;
