import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import successIcon from '../../../assets/success_icon.svg';
import Image from '../../../components/Image';
import { fFloat } from '../../../utils/formatNumber';
import { navigationUrl } from '../../../navigationUrl';

export default function ConvertResult({ showResult: prices, onBack }) {
  console.log(prices);
  const { t } = useTranslation();

  return (
    <>
      <Image src={successIcon} sx={{ width: 91, height: 91, m: 'auto' }} />

      <Typography variant='body1' align='center' sx={{ mt: 4, mb: 2 }}>
        {t('Successful')}
      </Typography>

      <Typography variant='body3' color={'grey.300'} component={'p'} align='center'>
        {t('You will spend')}
      </Typography>

      <Typography variant='h6_secondary' color={'grey.100'} component={'p'} align='center' fontWeight={400}>
        {prices?.fromAmount} {prices?.fromSymbol}
      </Typography>

      <Stack spacing={2} sx={{ mt: 5.5, mb: 3.5 }}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant='body2' color={'grey.100'}>
            {t('Converted')}
          </Typography>
          <Typography variant='body2' color={'grey.100'}>
            {fFloat(prices?.toAmount)} {prices?.toSymbol}
          </Typography>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant='body2' color={'grey.100'}>
            {t('Price')}
          </Typography>
          <Typography variant='body2' color={'grey.100'}>
            1 {prices.fromSymbol} = {fFloat(prices.toAmount / prices.fromAmount)} {prices.toSymbol}
          </Typography>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant='body2' color={'grey.100'}>
            {t('Inverse Price')}
          </Typography>
          <Typography variant='body2' color={'grey.100'} fontWeight='400'>
            1 {prices.toSymbol} = {fFloat(1 / (prices.toAmount / prices.fromAmount))} {prices.fromSymbol}
          </Typography>
        </Stack>
      </Stack>

      {/* <Divider sx={{ mb: 1 }} />

      <Typography variant='body3' color={'grey.100'}>
        {t('Please confirm conversion within the time')}
      </Typography> */}

      <Stack direction={'row'} alignItems={'center'} spacing={3.3} sx={{ mt: 5 }}>
        <Button
          variant='contained'
          size='large'
          color='inherit'
          fullWidth
          sx={{
            backgroundColor: 'grey.400',
            typography: 'body3',
            fontWeight: 500,
            width: '50%',
            '&:hover': {
              backgroundColor: 'grey.600',
            },
          }}
          onClick={onBack}
        >
          {t('Back')}
        </Button>

        <Button
          variant='contained'
          size='large'
          color='primary'
          fullWidth
          component={Link}
          to={navigationUrl.convert_history}
          sx={{
            typography: 'body3',
            fontWeight: 500,
            width: '50%',
            '&:hover': { color: '#eee' },
          }}
          onClick={() => {}}
        >
          {t('View Status')}
        </Button>
      </Stack>
    </>
  );
}
