import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import Api from '../../../../../http/serviceApi';
import { imageToBase64 } from '../../../../../utils';
import { navigationUrl } from '../../../../../navigationUrl';
import { useTranslation } from 'react-i18next';

export default function useTicketSubmit({ transactions }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // -------------
  const onSubmit = async (payload) => {
    try {
      if (payload.image) {
        payload.image = await imageToBase64(payload.image);
      }

      if (payload.transactionId) {
        payload.transactionId = +payload.transactionId;
        payload.type = transactions?.find((t) => t.transactionId === payload.transactionId)?.type || '';
      }

      const data = await Api.addTicket(payload);

      if (data?.status === 'SUCCESS') {
        enqueueSnackbar(t('The new ticket was successfully registered.'), { variant: 'success' });
        navigate(navigationUrl.ticket_list);
      }
    } catch (e) {}
  };

  // -------------
  return {
    onSubmit,
  };
}
