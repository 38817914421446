import { Box, Button, Stack, styled } from '@mui/material';
import { pxToRem } from '../../../utils/getFontValue';
import { WALLET_CHART_HEIGHT } from './donutChart';

export const ChartBorder = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 31,
  width: 115,
  height: 115,
  borderRadius: '100%',
  border: `2px dashed ${theme.palette.grey[600]}`,
}));

export const ChartTotal = styled(Stack)(() => ({ position: 'absolute', top: 75, left: 43, width: 115 }));

export const PeriodButton = styled(Button)(({ selected, theme }) => ({
  width: 120,
  height: 42,
  padding: 0,
  ...(selected && { backgroundColor: theme.palette.background.default, '& span': { color: theme.palette.grey[0] } }),
}));

export const BottomShadowList = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: 30,
  background: `linear-gradient(0, ${theme.palette.grey[800]} / 93%) 0%, rgb(${theme.palette.black[0]} / 0%) 100%)`,
}));

export const OverallGrowthChartContainer = styled(Box)(({ theme }) => ({
  '& .apexcharts-tooltip': {
    boxShadow: 'none !important',
    borderRadius: '8px !important',
    background: `${theme.palette.mode === 'dark' ? theme.palette.black[300_8] : '#fff'} !important`,
    backdropFilter: 'blur(7px)',
  },
  '& .tooltip': {
    padding: theme.spacing(2),
    width: 210,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  '& .tooltip-item': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .tooltip-label': { display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: theme.spacing(1) },
  '& .tooltip-icon': {
    '& img': {
      width: 25,
    },
  },
  '& .tooltip-value': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& span': {
      color: theme.palette.success.light,
      fontWeight: 400,
      fontSize: pxToRem(14),
    },
  },
  '& .tooltip-content': {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      color: theme.palette.grey[0],
      fontWeight: 600,
      fontSize: pxToRem(16),
    },
    '& small': {
      color: theme.palette.grey[400],
      fontWeight: 400,
      fontSize: pxToRem(16),
    },
    '& .tooltip-other': {
      color: theme.palette.grey[0],
      marginLeft: 33,
    },
  },
}));

export const WalletChartContainer = styled('div')(({ theme }) => ({
  height: WALLET_CHART_HEIGHT,
  // marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: WALLET_CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    display: 'none',
  },
}));
