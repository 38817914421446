import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, Link, ListItemText, Typography, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Iconify from '../../Iconify';
import { ListItemStyle as ListItem, ListItemTextStyle, ListItemIconStyle } from './style';
import Image from '../../Image';

function isExternalLink(path) {
  return path.includes('http');
}

// ----------------------------------------------------------------------

ListItem.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.arrayOf(PropTypes.string),
};

NavItemRoot.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  isCollapse: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    caption: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
};

export function NavItemRoot({ item, isCollapse, open = false, active, onOpen }) {
  const { t } = useTranslation();

  const { title, path, icon, info, children, disabled, caption, roles } = item;

  const renderContent = (
    <>
      {icon && (
        <ListItemIconStyle>
          <Image src={icon} alt='' sx={{ width: 24 }} />
        </ListItemIconStyle>
      )}
      <ListItemTextStyle
        disableTypography
        primary={t(title)}
        secondary={
          <Tooltip title={caption || ''} arrow>
            <Typography
              noWrap
              variant='caption'
              component='div'
              sx={{ textTransform: 'initial', color: 'text.secondary' }}
            >
              {caption}
            </Typography>
          </Tooltip>
        }
        isCollapse={isCollapse}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      />
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <ListItem onClick={onOpen} activeRoot={false} disabled={disabled} roles={roles}>
        {renderContent}
      </ListItem>
    );
  }

  return isExternalLink(path) ? (
    <ListItem component={Link} href={path} target='_blank' rel='noopener' disabled={disabled} roles={roles}>
      {renderContent}
    </ListItem>
  ) : (
    <ListItem component={RouterLink} to={path} activeRoot={active} disabled={disabled} roles={roles}>
      {renderContent}
    </ListItem>
  );
}

// ----------------------------------------------------------------------

NavItemSub.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    caption: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
};

export function NavItemSub({ item, open = false, active = false, onOpen }) {
  const { t } = useTranslation();
  const { title, path, info, children, disabled, caption, roles } = item;

  const renderContent = (
    <>
      <DotIcon active={active} subItem />
      <ListItemText
        disableTypography
        primary={t(title)}
        sx={{ typography: 'body3' }}
        secondary={
          <Tooltip title={caption || ''} arrow>
            <Typography
              noWrap
              variant='caption'
              component='div'
              sx={{ textTransform: 'initial', color: 'text.secondary' }}
            >
              {caption}
            </Typography>
          </Tooltip>
        }
      />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItem onClick={onOpen} activeSub={active} subItem disabled={disabled} roles={roles}>
        {renderContent}
      </ListItem>
    );
  }

  return isExternalLink(path) ? (
    <ListItem component={Link} href={path} target='_blank' rel='noopener' subItem disabled={disabled} roles={roles}>
      {renderContent}
    </ListItem>
  ) : (
    <ListItem component={RouterLink} to={path} activeSub={active} subItem disabled={disabled} roles={roles}>
      {renderContent}
    </ListItem>
  );
}

// ----------------------------------------------------------------------

DotIcon.propTypes = {
  active: PropTypes.bool,
};

export function DotIcon({ active, subItem = false }) {
  return (
    <ListItemIconStyle subItem={subItem}>
      <Box
        component='span'
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

ArrowIcon.propTypes = {
  open: PropTypes.bool,
};

export function ArrowIcon({ open }) {
  return (
    <Iconify
      icon={open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
