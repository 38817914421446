import { createConfirmation } from 'react-confirm';
import ConfirmModal from '../components/ConfirmModal';

// create confirm function
export const confirm = createConfirmation(ConfirmModal);

// This is optional. But wrapping function makes it easy to use.
export default function confirmWrapper(confirmation, Content, options = {}) {
  return confirm({ confirmation, Content, ...options });
}
