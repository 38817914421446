import { TableRow, TableCell, Stack, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CARD_TYPE } from '../../../../bankCard/bankCardTable';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import Api from '../../../../../../http/adminHttp/serviceApiAdmin';
import Iconify from '../../../../../../components/Iconify';
import SelectBoxStatus from '../../../../../../components/SelectBoxStatus';
import Image from '../../../../../../components/Image';

const CardsTableRow = ({ row, index, onUpdateRow, reload }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { cardType, cardNumber, accountNumber, shebaNum, status, expiryDateMonth, expiryDateYear } = row;

  const cartType = CARD_TYPE.find((ct) => ct.value === cardType); //////////////////////////////

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onUpdateStatusRow = async (newStatus) => {
    setIsSubmitting(true);

    try {
      const response = await Api.updateBankCard({ ...row, status: newStatus });
      if (response.status === 'SUCCESS') {
        enqueueSnackbar(t('The bank card is updated successfully'), { variant: 'success' });
        reload();
      }
    } catch (e) {}

    setIsSubmitting(false);
  };

  return (
    <TableRow hover sx={{ '& td': { py: 1.5, typography: 'body2' } }}>
      <TableCell align='center' sx={{ width: 80 }}>
        {index + 1}
      </TableCell>

      <TableCell>{cardNumber}</TableCell>

      <TableCell>{accountNumber || '-'}</TableCell>

      <TableCell>{shebaNum || '-'}</TableCell>

      <TableCell sx={{ width: 150 }}>
        <Stack direction='row' spacing={2} alignItems={'center'} justifyContent='space-between'>
          <Typography typography='body3' color='grey.300'>
            {t(cartType.label)}
          </Typography>
          <Image src={cartType.icon} />
        </Stack>
      </TableCell>

      <TableCell>{expiryDateYear ? `${expiryDateYear}/${expiryDateMonth}` : '-'}</TableCell>

      <TableCell align='center'>
        <SelectBoxStatus variant='filled' condition={status} onChange={onUpdateStatusRow} isSubmitting={isSubmitting} />
      </TableCell>

      <TableCell align='center' sx={{ width: 100 }}>
        <IconButton onClick={onUpdateRow}>
          <Iconify icon='eva:edit-2-outline' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default CardsTableRow;
