import React, { useState } from 'react';
import { Stack, Table, TableBody, TableContainer, Box, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

import OrdersToolbar from './ordersComponents/ordersToolbar';
import Scrollbar from '../../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../../components/table';
import useTable from '../../../../../hooks/useTable';
import OrdersTableRow from './ordersComponents/ordersTableRow';
import useUserOrdersInit from './ordersComponents/hooks/useUserOrdersInit';
import OrderHistoryModal from '../../../../common/history/orderHistory/orderHistoryModal';
import TradeHistoryModal from '../../../../common/history/tradeHistory/tradeHistoryModal';

const Orders = ({ userId }) => {
  const { t } = useTranslation();
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [modalDetailData, setModalDetailData] = useState({});

  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  const {
    typeFilter,
    setTypeFilter,
    dateFromFilter,
    setDateFromFilter,
    dateToFilter,
    setDateToFilter,
    tableData,
    tablePagination,
    handleFilter,
    handleResetFilters,
    userData,
    isExportLoading,
    onExport,
    loading ,setLoading
  } = useUserOrdersInit(page, rowsPerPage, userId);

  const TABLE_HEAD =
    typeFilter === 'otc'
      ? [
          { id: 'num', label: 'Num', align: 'center' },
          { id: 'fiat', label: 'Fiat', align: 'left' },
          { id: 'crypto', label: 'Crypto', align: 'left' },
          { id: 'type', label: 'Deposit type', align: 'center' },
          { id: 'amount', label: 'Amount', align: 'center' },
          { id: 'side', label: 'Side', align: 'center' },
          { id: 'date', label: 'Created Date', align: 'center' },
          { id: 'status', label: 'Status', align: 'center' },
          { id: 'actions' },
        ]
      : typeFilter === 'p2p'
      ? [
          { id: 'num', label: 'Num', align: 'center' },
          { id: 'crypto', label: 'Crypto', align: 'left' },
          { id: 'amount', label: 'Amount', align: 'center' },
          { id: 'minLimit', label: 'Minimum limit', align: 'center' },
          { id: 'side', label: 'Side', align: 'center' },
          { id: 'price', label: 'Price', align: 'center' },
          { id: 'date', label: 'Created Date', align: 'center' },
          { id: 'state', label: 'State', align: 'center' },
        ]
      : typeFilter === 'trade'
      ? [
          { id: 'num', label: 'Num', align: 'center' },
          { id: 'crypto', label: 'Crypto', align: 'left' },
          { id: 'amount', label: 'Amount', align: 'center' },
          { id: 'side', label: 'Side', align: 'center' },
          { id: 'price', label: 'Price', align: 'center' },
          { id: 'stopPrice', label: 'Stop price', align: 'center' },
          { id: 'commission', label: 'Commission', align: 'center' },
          { id: 'date', label: 'Created Date', align: 'center' },
          { id: 'state', label: 'State', align: 'center' },
          { id: 'actions' },
        ] : typeFilter === 'futures'
            ? [
              { id: 'num', label: 'Num', align: 'center' },
              { id: 'crypto', label: 'Crypto', align: 'left' },
              { id: 'leverage', label: 'Leverage', align: 'center' },
              { id: 'type', label: 'Type', align: 'center' },
              { id: 'margin', label: 'Margin', align: 'center' },
              { id: 'side', label: 'Side', align: 'center' },
              { id: 'date', label: 'Created Date', align: 'center' },
              { id: 'status', label: 'status', align: 'center' },
              { id: 'transactionId', label: 'transactionId', align: 'center' },
            ]
            : typeFilter === 'paysafe'
              ? [
                { id: 'num', label: 'Num', align: 'center' },
                { id: 'type', label: 'Deposit type', align: 'center' },
                { id: 'email', label: 'Email', align: 'center' },
                { id: 'amount', label: 'Amount', align: 'left' },
                { id: 'fee', label: 'Fee', align: 'center' },
                { id: 'side', label: 'Side', align: 'center' },
                { id: 'date', label: 'Created Date', align: 'center' },

              ]
              : typeFilter === 'perfectmoney'
                ? [
                  { id: 'num', label: 'Num', align: 'center' },

                  { id: 'email', label: 'Email', align: 'center' },
                  { id: 'amount', label: 'Amount', align: 'left' },
                  { id: 'fee', label: 'Fee', align: 'center' },
                  { id: 'side', label: 'Side', align: 'center' },
                  { id: 'date', label: 'Created Date', align: 'center' },

                ]
      : [];

  // ===================================================================


  return (
    <>
      <Stack>
        {/* <PageTitle title='Orders' /> */}
        <OrdersToolbar
          typeFilter={typeFilter}
          setTypeFilter={setTypeFilter}
          dateFromFilter={dateFromFilter}
          setDateFromFilter={setDateFromFilter}
          dateToFilter={dateToFilter}
          setDateToFilter={setDateToFilter}
          handleFilter={handleFilter}
          handleResetFilters={handleResetFilters}
          isExportLoading={isExportLoading}
          onExport={onExport}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size='small'>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length || 0}
                onSort={onSort}
              />
              <TableBody>
                {loading ? (
                  <TableLoading isLoading />
                ) : (
                  <>
                    {tableData?.map((row, index) =>{
                      return(
                        <OrdersTableRow
                          key={`${typeFilter}-${row.id}-${index+1}`}
                          row={row}
                          userData={userData}
                          type={typeFilter}
                          index={index}
                          onShowModalDetail={() => {
                            setShowModalDetail(true);
                            setModalDetailData(row);
                          }}
                        />
                      )
                    })}

                    <TableNoData isNotFound={!tableData?.length} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component='div'
            count={tablePagination.totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
          />
        </Box>
      </Stack>

      {typeFilter === 'otc' && (
        <OrderHistoryModal open={showModalDetail} onClose={() => setShowModalDetail(false)} data={modalDetailData} />
      )}

      {typeFilter === 'trade' && (
        <TradeHistoryModal open={showModalDetail} onClose={() => setShowModalDetail(false)} data={modalDetailData} />
      )}
    </>
  );
};

export default Orders;
