import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../../components/DatePicker';
import TabChart from './tabChart';
import { getDefaultDateFilterValues } from '../../../../utils/filter';
import { useEffect } from 'react';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import { BadgeIcon } from '../style';
import { useSelector } from 'react-redux';
import { exportExcel } from '../../../../utils/excel';
import { LoadingButton } from '@mui/lab';
import { exportPDF } from '../../../../utils/pdf';

export default function DepositWithdrawStatisticsTab({ section = 'deposit' }) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  // ---------------------
  const TYPES = [
    { label: t('Dollar'), value: 'dollar', color: '#30E0A1' },
    { label: t('Rial'), value: 'rial', color: '#F27F16' },
    { label: t('Crypto'), value: 'crypto', color: '#9747FF' },
  ];

  // ---------------------
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [dateFromFilter, setDateFromFilter] = useState(getDefaultDateFilterValues().firstDay);
  const [dateToFilter, setDateToFilter] = useState(getDefaultDateFilterValues().lastDay);
  const [typesFilter, setTypesFilter] = useState([TYPES[0].value, TYPES[1].value]);

  // ---------------------
  const onTypeChange = (e) => {
    const value = e.target.value;

    if (typesFilter.includes(value)) {
      setTypesFilter(typesFilter.filter((item) => item !== value));
    } else {
      setTypesFilter([...typesFilter, value]);
    }
  };

  // ---------------------
  const onReport = async () => {
    if (typesFilter.length === 0) {
      return;
    }

    let response;
    if (section === 'deposit') {
      response = await Api.getReportDepositStatistics(
        dateFromFilter.valueOf(),
        dateToFilter.valueOf(),
        typesFilter.join(',')
      );
    } else if (section === 'withdraw') {
      response = await Api.getReportWithdrawStatistics(
        dateFromFilter.valueOf(),
        dateToFilter.valueOf(),
        typesFilter.join(',')
      );
    }

    const datas = [];
    typesFilter.forEach((type) => {
      const TYPE = TYPES.find((t) => t.value === type);

      datas.push({
        name: TYPE.label,
        type: 'line',
        data: response?.[type]?.map((t) => t.sum) || [],
        color: TYPE.color,
      });
    });

    setChartData(datas);
    setChartLabels(response?.[typesFilter[0]]?.map((t) => moment(t.date).format('YYYY MMM D')));
  };

  // ---------------------
  useEffect(() => {
    onReport();
  }, []);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);

  // --------------------
  const onExport = async (type = 'Excel') => {
    if (chartData?.length === 0) return;

    setIsExportLoading(true);

    const list = [];

    for (let index20 = 0; index20 < chartData[0].data.length; index20++) {
      const items = [];
      for (let index2 = 0; index2 < chartData.length; index2++) {
        items.push(chartData[index2].data[index20]);
      }

      list.push([chartLabels[index20], ...items]);
    }
    console.log(list);

    // const list = [
    //   ['20 may',20,15,5,2,1],
    //   ['21 may',20,15,5,2,1],
    // ]

    try {
      const config = {
        language,

        fileName: `${
          section === 'deposit' ? t('Reporting Deposit Statistics') : t('Reporting Withdraw Statistics')
        } - ${moment(dateFromFilter).locale(language).format('YYYY-MM-DD')} - ${moment(dateToFilter)
          .locale(language)
          .format('YYYY-MM-DD')}`,
        heads: ['#', t('Date'), ...chartData.map((cd) => t(cd.name))],
        columnsWidth: [5, 19, 16, 20, 15, 21, 20],
        list,
      };

      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  // ---------------------
  return (
    <Grid container sx={{ mt: 5, mb: 2 }}>
      <Grid item xs={12} sm={5} md={4} sx={{ pl: 3 }}>
        <Stack direction={'row'}>
          <DatePicker
            label={t('From')}
            value={dateFromFilter}
            setValue={setDateFromFilter}
            variant='standard'
            maxDate={dateToFilter}
            fullWidth
            sx={{ maxWidth: 150 }}
            InputProps={null}
          />
          <DatePicker
            label={t('To')}
            value={dateToFilter}
            setValue={setDateToFilter}
            variant='standard'
            minDate={dateFromFilter}
            fullWidth
            sx={{ maxWidth: 150 }}
          />
        </Stack>

        <Stack spacing={2} sx={{ my: 5 }}>
          <Typography variant='body3'>{t('Select')}</Typography>

          <FormGroup>
            {TYPES.map((TYPE, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox checked={typesFilter.includes(TYPE.value)} onChange={onTypeChange} value={TYPE.value} />
                }
                label={TYPE.label}
              />
            ))}
          </FormGroup>
        </Stack>

        <Button
          onClick={onReport}
          fullWidth
          size='large'
          variant='contained'
          className='no-print'
          sx={{ maxWidth: 283, typography: 'body3', fontWeight: 500, mt: 14.5 }}
        >
          {t('Report')}
        </Button>
      </Grid>

      <Grid item xs={12} sm={7} md={8} sx={{ pr: 3, mt: { xs: 4, sm: 0 } }}>
        <Stack direction={'row'} spacing={3} justifyContent='flex-end'>
          {TYPES.reverse().map((TYPE, index) => (
            <Stack direction={'row'} spacing={0.5} key={index}>
              <Typography variant='body4' color='grey.100' sx={{ mt: 0.5 }}>
                {TYPE.label}
              </Typography>
              <BadgeIcon bgColor={TYPE.color} direction={'left'} />
            </Stack>
          ))}
        </Stack>

        <Box sx={{ my: 3.2 }}>
          <Chart chartData={chartData} chartLabels={chartLabels}  label={'SUM'}/>
        </Box>

        <Stack direction={'row'} justifyContent={'center'} spacing={4} className='no-print'>
          <LoadingButton
            color='inherit'
            size='large'
            variant='outlined'
            loading={isExportLoading}
            onClick={onExport}
            sx={{ width: 172, typography: 'body3', fontWeight: 500, color: 'grey.400' }}
          >
            {t('Excel')}
          </LoadingButton>
          <Button
            color='inherit'
            size='large'
            variant='outlined'
            sx={{ width: 172, typography: 'body3', fontWeight: 500, color: 'grey.400' }}
            onClick={() => window.print()}
          >
            {t('PDF')}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

const Chart = ({ chartData, chartLabels , label }) => <TabChart chartData={chartData} chartLabels={chartLabels} label={label}/>;
