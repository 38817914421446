import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import MultipleSelectChip from '../../../../components/MultipleSelectChip';

//=====================================================

const BANKS = ['Mellat', 'Melli', 'Ayande', 'Parsian', 'Shahr', 'Novin', 'Mehr'];

//=====================================================

const RejectionBanks = ({ banksList , setValues, values}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (banksList) {
      setValues(banksList);
    }
  }, [banksList]);

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant='body3' color='grey.100'>
        {t('Rejection of automatic deposit for the following banks')}
      </Typography>
      <Box sx={{ width: { xs: '100%', md: 680 }, mt: 1 }}>
        <MultipleSelectChip options={BANKS} label='' name='rejectedBanks' setValues={setValues} values={values} />
      </Box>
    </Box>
  );
};

export default RejectionBanks;
