import { useTranslation } from 'react-i18next';
import Api from '../../../../../http/adminHttp/serviceApiAdmin';
import { useSnackbar } from 'notistack';

export default function useGeneralSubmit() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (payload) => {
    const response = await Api.editGeneralSettings(payload);
    if (response?.status === 'SUCCESS') {
      enqueueSnackbar(t('The values saved successfully.'), { variant: 'success' });
    }
  };

  return { onSubmit };
}
