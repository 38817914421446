// ----------------------------------------------------------------------

export default function LoadingButton(theme) {
  return {
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          ...theme.typography.body2,
          lineHeight: 1,
          borderRadius: '100px',
          '&.MuiButton-text': {
            '& .MuiLoadingButton-startIconPendingStart': {
              marginLeft: 0,
            },
            '& .MuiLoadingButton-endIconPendingEnd': {
              marginRight: 0,
            },
          },
          '& .MuiLoadingButton-loadingIndicator': {
            color: '#fff',
          },
        },
      },
    },
  };
}
