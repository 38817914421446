import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Card, Divider, Grid } from '@mui/material';

import { SET_PAGE_STATE } from '../../../store/actionTypes';

import ExchangeForm from './exchangeForm';
import DonutChart from './donutChart';
import BalanceStatistic from './balanceStatistic';
import OverallGrowth from './overallGrowth';
import TopAsset from './topAsset';
import Transaction from './transaction';
import { useEffect } from 'react';
import LoadingScreen from '../../../components/LoadingScreen';
import useDashboardInfo from './hooks/useDashboardInfo';
import useWallet from '../../../hooks/useWallet';

const MainPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Dashboard' },
    });
  }, []);

  const { data, loading } = useDashboardInfo();
  const { userWallet, loading: walletLoading } = useWallet();

  if (loading || walletLoading) {
    return <LoadingScreen />;
  }

  return (
    <Box sx={{ mx: { xs: 2, lg: 0 } }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card>
            <BalanceStatistic dashboardInfo={data.dashboardInfo} userWallet={userWallet} />
            <Divider sx={{ mt: 2 }} />
            <DonutChart dashboardInfo={data.dashboardInfo} />
            <Divider sx={{ mt: 3 }} />
            <ExchangeForm />
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card>
            <OverallGrowth />
          </Card>

          <Grid container spacing={3} sx={{ mt: 0.5 }}>
            <Grid item xs={12} lg={6}>
              <TopAsset list={data.topAssetInfo} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Transaction data={data.transactionInfo} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MainPage;
