import React, { useEffect, useState } from 'react';
import { Stack, Table, TableBody, TableContainer, Box, TablePagination, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'jalali-moment';

import { PageTitle } from '../../../../../components/PageTitle';
import EarnToolbar from './earnComponents/earnToolbar';
import Scrollbar from '../../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../../components/table';
import useTable from '../../../../../hooks/useTable';
import EarnTableRow from './earnComponents/earnTableRow';
import Api from '../../../../../http/adminHttp/serviceApiAdmin';
import { getDefaultDateFilterValues } from '../../../../../utils/filter';
import { useRef } from 'react';
import { exportExcel } from '../../../../../utils/excel';
import { useSelector } from 'react-redux';
import { exportPDF } from '../../../../../utils/pdf';

// ===================================================================

const TABLE_HEAD = [
  { id: 'num', label: 'Num', align: 'center' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'mobile', label: 'Mobile', align: 'left' },
  { id: 'symbol', label: 'Symbol', align: 'left' },
  { id: 'executedQuoteQtyInUSDT', label: 'Value', align: 'left' },
  { id: 'userCommission', label: 'User commission', align: 'left' },
  // { id: 'date', label: 'Date', align: 'left' },
];

// ===================================================================

const Earn = ({ userId }) => {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  // -------------------------
  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  // -------------------------
  const resetFilterRef = useRef();
  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });
  const [dateFromFilter, setDateFromFilter] = useState(getDefaultDateFilterValues().firstDay);
  const [dateToFilter, setDateToFilter] = useState(getDefaultDateFilterValues().lastDay);

  // ----------------------------------------------
  const fetchData = async (resetFilter = false) => {
    try {
      const filters = {
        userId,
        endTime: dateToFilter?.valueOf?.(),
        // startTime: moment(dateFromFilter).valueOf(),
        startTime: dateFromFilter?.valueOf?.() ,
      };

      if (!resetFilterRef.current) {
        resetFilterRef.current = { ...filters };
      }

      const filtersBase = resetFilter ? resetFilterRef.current : filters;
      const response = await Api.getUserEarn(page, rowsPerPage, filtersBase);
      if (response) {
        setTableData(response.userEarnInfoList);
        setTablePagination({ totalItems: response.userEarnInfosTotalPages * rowsPerPage });
      }
    } catch (e) {
      setTableData([]);
    }
  };

  // -------------------------
  useEffect(() => {
    if (userId) fetchData();
  }, [page, rowsPerPage, userId]);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);

  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    const filters = {
      userId,
      endTime: moment(dateToFilter).valueOf(),
      startTime: moment(dateFromFilter).valueOf(),
    };

    try {
      const response = await Api.getUserEarn(0, 100000000, filters);

      // generate excel and download
      const config = {
        language,
        fileName: t('User Earn'),
        heads: ['#', t('Name'), t('Mobile'), t('Symbol'), t('Value'), t('User commission')],
        columnsWidth: [5, 25, 15, 15, 15, 15],
        list: response.userEarnInfoList.map((item) => [
          `${item.firstName} ${item.lastName}`,
          item.mobileNumber,
          item.symbol,
          item.executedQuoteQtyInUSDT,
          item.userCommission,
        ]),
      };
      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  // -------------------------
  return (
    <Stack sx={{ mt: 5 }}>
      <PageTitle title='Earn' subTitle='List of users that this user has introduced' />
      <Typography variant='body2'>
        {t('You can see the list of commissions received by this user through introduction.')}
      </Typography>

      <EarnToolbar
        dateFromFilter={dateFromFilter}
        setDateFromFilter={setDateFromFilter}
        dateToFilter={dateToFilter}
        setDateToFilter={setDateToFilter}
        onFilter={fetchData}
        onExport={onExport}
        isExportLoading={isExportLoading}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          <Table size='small'>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length || 0}
              onSort={onSort}
            />
            <TableBody>
              {tableData === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {tableData?.map((row, index) => (
                    <EarnTableRow key={index} row={row} index={index} />
                  ))}

                  <TableNoData isNotFound={!tableData?.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={tablePagination.totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage={t('Rows per page')}
          labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
        />
      </Box>
    </Stack>
  );
};

export default Earn;
