import { Box, Card, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { SET_PAGE_STATE } from '../../../store/actionTypes';
import ReferralsStatistic from './referralsStatistic';
import ReferralInfo from './referralInfo';
import Api from '../../../http/serviceApi';
import LoadingScreen from '../../../components/LoadingScreen';
import ReferralsShare from './referralsShare';
import ReferralsReport from './referralsReport';
import ReferralsChart from './referralsChart';

export default function ReferralsIncome() {
  const dispatch = useDispatch();

  // -------------------------
  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Referrals & Income' },
    });
  }, []);

  // -------------------------
  const [data, setData] = useState({
    code: null,
    link: '',
    share: 0,
    referral: 0,
    transactions: 0,
    income: 0,
  });
  const [info, setInfo] = useState({ referrals: null, trades: null, incomes: [] });

  // -------------------------
  const getData = async () => {
    try {
      const i = await Api.getReferralReport();
      setInfo(i);

      const d = await Api.getReferralInfo();
      setData(d);
    } catch (e) {
      setData((p) => ({ ...p, code: '' }));
    }
  };

  // -------------------------
  useEffect(() => {
    getData();
  }, []);

  // -------------------------
  if (data.code === null) {
    return <LoadingScreen />;
  }

  // -------------------------
  return (
    <Box sx={{ mx: { xs: 2, lg: 0 } }}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={5}>
          <ReferralsStatistic referral={data.referral} transactions={data.transactions} income={data.income} />
        </Grid>

        <Grid item xs={12} md={7}>
          <Card sx={{ height: '100%' }}>
            <ReferralInfo code={data.code} link={data.link} />
          </Card>
        </Grid>
      </Grid>

      <Card sx={{ mt: 3 }}>
        <ReferralsShare share={data.share} />
      </Card>

      <Card sx={{ mt: 3, p: 5 }}>
        <ReferralsReport info={info} />
      </Card>

      <Card sx={{ mt: 3, p: 5, pb: 2 }}>
        <ReferralsChart info={info} />
      </Card>
    </Box>
  );
}
