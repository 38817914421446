import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'jalali-moment';

import Scrollbar from '../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../components/table';

const TABLE_HEAD = [
  { id: 'num', label: 'Num', align: 'center' },
  { id: 'createdDate', label: 'Date', align: 'left' },
  { id: 'firstName', label: 'Name', align: 'left' },
];

export default function ReferralsReportIntroduce({ list }) {
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
        <Table size='small'>
          <TableHeadCustom headLabel={TABLE_HEAD} rowCount={list?.length || 0} />

          <TableBody>
            {list === null ? (
              <TableLoading isLoading />
            ) : (
              <>
                {list?.map((row, index) => (
                  <ReportTableRow key={index} row={row} index={index} />
                ))}

                <TableNoData isNotFound={!list?.length} />
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}

const ReportTableRow = ({ row, index }) => {
  const language = useSelector((store) => store.language);

  const { firstName, lastName, createdDate } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1, typography: 'body2' } }}>
      <TableCell align='center' sx={{ width: 100 }}>
        {index + 1}
      </TableCell>

      <TableCell sx={{ width: 200 }}>{moment(createdDate).locale(language).format('D MMM YYYY - HH:mm')}</TableCell>

      <TableCell>
        {firstName} {lastName}
      </TableCell>
    </TableRow>
  );
};
