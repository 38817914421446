import React from 'react';
import { Row, Col } from 'antd';
import classes from './advancedAuthAddress.module.scss';
import AdvancedAuthAddressForm from './advancedAuthAddressForm';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';

const AdvancedAuthAddress = () => {
  const colPropsForm = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
  };
  const colProps = {
    xs: 24,
    sm: 18,
    md: 15,
    lg: 11,
    xl: 9,
  };

  return (
    <Row justify='center'>
      <Col {...colProps}>
        <div className={classes.formBody}>
          <AdvancedAuthAddressForm />
        </div>
      </Col>
    </Row>
  );
};

export default AdvancedAuthAddress;
