import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Grid, List, Stack, Popover, useTheme } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import { LinkStyle, ListItemStyle } from '../style';
import Image from '../../../components/Image';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

MenuDesktop.propTypes = {
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function MenuDesktop({ isOffset, isHome, navConfig }) {
  return (
    <Stack direction='row'>
      {navConfig.map((item, index) => (
        <MenuDesktopItem key={index} item={item} isOffset={isOffset} isHome={isHome} />
      ))}
    </Stack>
  );
}

// ----------------------------------------------------------------------

IconBullet.propTypes = {
  type: PropTypes.oneOf(['item', 'subheader']),
};

function IconBullet({ type = 'item' }) {
  return (
    <Box sx={{ width: 24, height: 16, display: 'flex', alignItems: 'center' }}>
      <Box
        component='span'
        sx={{
          ml: '2px',
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'currentColor',
          ...(type !== 'item' && { ml: 0, width: 8, height: 2, borderRadius: 2 }),
        }}
      />
    </Box>
  );
}

// ----------------------------------------------------------------------

MenuDesktopItem.propTypes = {
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.array,
  }),
};

function MenuDesktopItem({ item, isHome, isOffset }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';

  const { title, icon, path, children } = item;

  const [anchorEl, setAnchorEl] = useState(null);
  const [offsetLeft, setOffsetLeft] = useState(0);

  const onMouseEnter = (event) => {
    onOpen(event);
    const left = event.currentTarget.offsetLeft;
    setOffsetLeft(isRTL ? left - children.length * 270 + 100 : left);
  };
  const onOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };
  const isOpen = Boolean(anchorEl);

  if (children) {
    return (
      <>
        <LinkStyle
          onMouseEnter={onMouseEnter}
          onMouseLeave={onClose}
          sx={{
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            ...(isOpen && { opacity: 0.7 }),
          }}
          onClick={path ? () => navigate(path) : null}
        >
          {icon && (
            <Image
              src={theme.palette.mode === 'dark' ? icon : icon.replace('.svg', '-light.svg')}
              alt=''
              sx={{ height: 20 }}
            />
          )}
          {t(title)}
          <Iconify
            icon={isOpen ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
            sx={{ ml: 1, width: 16, height: 16 }}
          />
        </LinkStyle>

        <Popover
          sx={{ pointerEvents: 'none' }}
          open={isOpen}
          anchorEl={anchorEl}
          anchorReference='anchorPosition'
          anchorPosition={{ top: 50, left: offsetLeft }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            onMouseEnter: onOpen,
            onMouseLeave: onClose,
            sx: {
              pointerEvents: 'auto',
              right: 16,
              borderRadius: 0,
              width: children.length * 270,
              background: 'transparent',
              boxShadow: (theme) => theme.customShadows.z24,
            },
          }}
        >
          <Box
            sx={{
              px: 3,
              pb: 3,
              pt: 1,
              mt: isOffset ? 3 : 5,
              background: (theme) => theme.palette.grey[800],
              backdropFilter: 'blur(200px)',
              borderRadius: '10px',
            }}
          >
            <Grid container>
              {children.map((list, index) => {
                const { items } = list;

                return (
                  <Grid key={index} item xs={12} md={12 / children.length}>
                    <List disablePadding>
                      {items.map((item, cIndex) => (
                        <ListItemStyle key={cIndex} to={item.path} component={RouterLink} underline='none'>
                          {item.icon && (
                            <Image
                              src={theme.palette.mode === 'dark' ? item.icon : item.icon.replace('.svg', '-light.svg')}
                              alt=''
                              sx={{ width: 24, mr: 1.2 }}
                            />
                          )}
                          {t(item.title)}
                        </ListItemStyle>
                      ))}
                    </List>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Popover>
      </>
    );
  }

  return (
    <LinkStyle to={path} component={RouterLink} end={path === '/'}>
      {icon && <Image src={theme.palette.mode === 'dark' ? icon : icon.replace('.svg', '-light.svg')} alt='' />}
      {t(item.title)}
    </LinkStyle>
  );
}
