import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Api from '../../../../http/serviceApi';

export default function useConvertQuote({ fromAmount, fromSymbol, toSymbol, setToAmount }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  // -------------------------
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prices, setPrices] = useState(null);

  // -------------------------
  const onQuote = async () => {
    const amount = `${fromAmount}`.replace(/,/g, '');

    if (!amount || amount === '0') {
      return enqueueSnackbar(t('Please enter the amount!'), { variant: 'warning' });
    }

    setIsSubmitting(true);

    try {
      const data = await Api.getConvertQuote(toSymbol, fromSymbol, amount);
      if (data.status === 'SUCCESS') {
        setPrices({ ...data, fromAmount: amount });
      }
    } catch (e) {}

    setIsSubmitting(false);
  };

  // -------------------------
  const onQuoteQuick = async (from, fromSymbol, toSymbol) => {
    const amount = `${from}`.replace(/,/g, '');

    if (!amount || amount === '0') {
      return enqueueSnackbar(t('Please enter the amount!'), { variant: 'warning' });
    }

    try {
      const data = await Api.getConvertQuote(toSymbol, fromSymbol, amount);
      if (data.status === 'SUCCESS') {
        setToAmount(data.toAmount);
      }
    } catch (e) {}
  };

  // -------------------------
  return {
    onQuote,
    onQuoteQuick,
    isSubmitting,
    prices,
    onReset: () => setPrices(null),
  };
}
