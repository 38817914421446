import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import UserApi from '../../../../http/serviceApi';
import AdminApi from '../../../../http/adminHttp/serviceApiAdmin';

import { FormProvider, RHFTextField } from '../../../../components/hookForm';
import { useSelector } from 'react-redux';

const ChangeMobileForm = ({ onSuccess, isAdmin = true }) => {
  const userInfo = useSelector((store) => store.userInfo);
  const { t } = useTranslation();

  const type = userInfo?.mobileNumber ? 'CHANGE' : 'ADD';

  const validators = Yup.object().shape({
    oldMobile:
      type === 'CHANGE' ? Yup.string().required(t('Please input your old mobile!')) : Yup.string().notRequired(),
    newMobile: Yup.string().required(t('Please input your new mobile!')),
  });

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues: {
      oldMobile: userInfo?.mobileNumber || '',
      newMobile: '',
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async ({ oldMobile, newMobile }) => {
    try {
      let response;
      if (isAdmin) {
        response = await AdminApi.preChangeOrAddMobile(oldMobile, newMobile, type);
      } else {
        response = await UserApi.preChangeOrAddMobile(oldMobile, newMobile, type);
      }

      if (response?.status === 'SUCCESS') {
        onSuccess({ oldMobile, newMobile });
      }
    } catch (e) {}
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ width: 320 }}>
        {type === 'CHANGE' && <RHFTextField name='oldMobile' label={t('Mobile')} variant='standard' disabled fullWidth />}

        <RHFTextField name='newMobile' label={t('New Mobile')} variant='standard' fullWidth sx={{ mt: 2 }} />

        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ mt: 7.5, typography: 'body3' }}
        >
          {type === 'CHANGE' ? t('Change Mobile') : t('Add Mobile')}
        </LoadingButton>
      </Box>
    </FormProvider>
  );
};

export default ChangeMobileForm;
