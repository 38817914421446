import { useState } from 'react';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Image from '../../../../components/Image';
import Iconify from '../../../../components/Iconify';
import Scrollbar from '../../../../components/Scrollbar';

import iconListRed from '../../../../assets/trade/listRed.svg';
import iconListGreen from '../../../../assets/trade/listGreen.svg';
import iconListRedGreen from '../../../../assets/trade/listRedGreen.svg';
import iconListRedLight from '../../../../assets/trade/listRedLight.svg';
import iconListGreenLight from '../../../../assets/trade/listGreenLight.svg';
import iconListRedGreenLight from '../../../../assets/trade/listRedGreenLight.svg';
import { fFloat } from '../../../../utils/formatNumber';

export default function FuturesOrderBook({
  selectedSymbol,
  asks,
  bids,
  lastPrice,
  isPositive = true,
  dollarPrice,
  onSelectPrice,
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  // ----------------
  const isLight = theme.palette.mode === 'light';
  const [selectedType, setSelectedType] = useState('both');

  // ----------------
  const onChangeType = (type) => {
    if (type === selectedType) return;

    setSelectedType(type);
  };

  // ----------------
  return (
    <Box sx={{ py: 1.5 }}>
      <Typography variant='subtitle3' color='common.white' sx={{ px: 1.8 }}>
        {t('Order Book')}
      </Typography>

      <Stack direction='row' alignItems='center' sx={{ my: 0.5, px: 1 }} spacing={1}>
        <IconButton onClick={() => onChangeType('both')}>
          <Image
            src={isLight ? iconListRedGreenLight : iconListRedGreen}
            sx={{ opacity: selectedType === 'both' ? 1 : 0.5 }}
          />
        </IconButton>

        <IconButton onClick={() => onChangeType('buy')}>
          <Image
            src={isLight ? iconListGreenLight : iconListGreen}
            sx={{ opacity: selectedType === 'buy' ? 1 : 0.5 }}
          />
        </IconButton>

        <IconButton onClick={() => onChangeType('sell')}>
          <Image src={isLight ? iconListRedLight : iconListRed} sx={{ opacity: selectedType === 'sell' ? 1 : 0.5 }} />
        </IconButton>
      </Stack>

      <Stack sx={{ mt: 2 }}>
        {selectedType !== 'both' && (
          <BookResult lastPrice={lastPrice} isPositive={isPositive} dollarPrice={dollarPrice} sx={{ mt: 0 }} />
        )}

        <Stack direction='row' sx={{ py: 0.5, px: 1, pb: 1 }}>
          <Typography color={'grey.300'} typography='body4' sx={{ width: '33%' }} lineHeight={1}>
            {t('Price')} ({selectedSymbol.quoteAsset})
          </Typography>
          <Typography color={'grey.300'} typography='body4' sx={{ width: '33%', textAlign: 'right' }} lineHeight={1}>
            {t('Size')} ({selectedSymbol.baseAsset})
          </Typography>
          <Typography color={'grey.300'} typography='body4' sx={{ width: '33%', textAlign: 'right' }} lineHeight={1}>
            {t('Sum')} ({selectedSymbol.baseAsset})
          </Typography>
        </Stack>

        {(selectedType === 'both' || selectedType === 'sell') && (
          <Scrollbar
            height={selectedType !== 'both' ? '315px' : '150px'}
            sx={{ px: 1, height: selectedType !== 'both' ? '315px' : '150px' }}
          >
            {asks.map((ask, index) => (
              <OrderRow
                key={index}
                isBuy={false}
                price={ask.price}
                amount={ask.amount}
                sum={ask.sum}
                percent={ask.percent}
                onSelectPrice={onSelectPrice}
              />
            ))}
          </Scrollbar>
        )}

        {selectedType === 'both' && (
          <BookResult lastPrice={lastPrice} isPositive={isPositive} dollarPrice={dollarPrice} />
        )}

        {(selectedType === 'both' || selectedType === 'buy') && (
          <Scrollbar
            height={selectedType !== 'both' ? '315px' : '150px'}
            sx={{ px: 1, height: selectedType !== 'both' ? '315px' : '150px' }}
          >
            {bids.map((bid, index) => (
              <OrderRow
                key={index}
                isBuy
                price={bid.price}
                amount={bid.amount}
                sum={bid.sum}
                percent={bid.percent}
                onSelectPrice={onSelectPrice}
              />
            ))}
          </Scrollbar>
        )}
      </Stack>
    </Box>
  );
}

// ----------------------------------------------------
const OrderRow = ({ isBuy = true, price, amount, sum, percent = 0, onSelectPrice }) => (
  <Stack
    direction='row'
    sx={{
      position: 'relative',
      py: 0.5,
      cursor: 'pointer',
      '&:before': {
        content: "''",
        position: 'absolute',
        bgcolor: isBuy ? 'positive.main' : 'negative.main',
        opacity: 0.1,
        width: `${percent}%`,
        height: '100%',
        right: 0,
        top: 0,
      },
    }}
    onClick={() => onSelectPrice(price)}
  >
    <Typography
      color={isBuy ? 'positive.main' : 'negative.main'}
      typography='body4'
      sx={{ width: '33%' }}
      lineHeight={1}
    >
      {price}
    </Typography>
    <Typography color={'grey.100'} typography='body4' sx={{ width: '33%', textAlign: 'right' }} lineHeight={1}>
      {amount}
    </Typography>
    <Typography color={'grey.100'} typography='body4' sx={{ width: '33%', textAlign: 'right' }} lineHeight={1}>
      {fFloat(sum)}
    </Typography>
  </Stack>
);

// ----------------------------------------------------
const BookResult = ({ lastPrice, isPositive = true, dollarPrice, sx = {} }) => (
  <Stack direction='row' alignItems={'center'} spacing={1} sx={{ my: 1.5, px: 1, ...sx }}>
    <Typography variant='subtitle2' color={isPositive ? 'positive.main' : 'negative.main'}>
      {lastPrice || 0}
    </Typography>

    <Iconify
      icon={isPositive ? 'ph:arrow-up-fill' : 'ph:arrow-down-fill'}
      color={isPositive ? 'positive.main' : 'negative.main'}
      height={18}
    />

    <Typography variant='body4' color={'grey.300'}>
      {dollarPrice || 0}$
    </Typography>
  </Stack>
);
