import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';


export default function useAddEditDedicatedWallet(info) {

  const { t } = useTranslation();


  const validators = Yup.object().shape({
    contractAddress: Yup.string().notRequired(t(`Please input contract address!`)),
    withdrawFee: Yup.string().required(t('Please input withdraw fee!')),
    decimal: Yup.string().required(t('Please input decimal!')),
  });

  const defaultValues = {
    contractAddress:info?.contractAddress || '',
    withdrawFee:info?.withdrawFee || '',
    decimal: info?.decimal ||'',
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(()=>{
    if(info?.contractAddress){
      setValue('contractAddress' , info?.contractAddress)
    }else setValue('contractAddress' , '')
    if(info?.withdrawFee){
      setValue('withdrawFee' , info?.withdrawFee)
    }else setValue('withdrawFee' , '')

    if(info?.decimal){
      setValue('decimal' , info?.decimal)
    }else setValue('decimal' , '')

  },[info])

  return {
    handleSubmit,
    isSubmitting,
    methods,
    reset,
  };
}

