import { Card, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import SmallAreaChart from '../../../components/SmallAreaChart';
import TimeChart from './timeChart';
import Api from '../../../http/adminHttp/serviceApiAdmin';
import { sum } from '../../../utils';

export default function StatisticChart() {
  const { t } = useTranslation();

  // --------------------
  const [period, setPeriod] = useState('MONTH');

  // --------------------
  const [data, setData] = useState([
    { label: t('Total Sell'), total: 0, chart: null },
    { label: t('Total Buy'), total: 0, chart: null },
    { label: t('Register'), total: 0, chart: null },
    { label: t('Login'), total: 0, chart: null },
    { label: t('Total Order'), total: 0, chart: null },
    { label: t('Total Referrals'), total: 0, chart: null },
    { label: t('Total Tickets'), total: 0, chart: null },
    { label: t('Total Transactions'), total: 0, chart: null },
    { label: t('Highest Buy'), total: 0, chart: null },
    { label: t('Highest Sell'), total: 0, chart: null },
    { label: t('Total Trade'), total: 0, chart: null },
    { label: t('Total Fiat Income'), total: 0, chart: null },
  ]);

  // --------------------
  const getData = async () => {
    try {
      const response = await Api.getReportTotalStatistics(period);

      setData([
        { label: t('Total Sell'), total: sum(response?.sells || []), chart: response?.sells || [] },
        { label: t('Total Buy'), total: sum(response?.buys || []), chart: response?.buys || [] },
        { label: t('Register'), total: sum(response?.registers || []), chart: response?.registers || [] },
        { label: t('Login'), total: sum(response?.logins || []), chart: response?.logins || [] },
        { label: t('Total Order'), total: sum(response?.total || []), chart: response?.total || [] },
        { label: t('Total Referrals'), total: sum(response?.referrals || []), chart: response?.referrals || [] },
        { label: t('Total Tickets'), total: sum(response?.tickets || []), chart: response?.tickets || [] },
        {
          label: t('Total Transactions'),
          total: sum(response?.totalTransaction || []),
          chart: response?.totalTransaction || [],
        },
        { label: t('Highest Buy'), total: sum(response?.highestBuy || []), chart: response?.highestBuy || [] },
        { label: t('Highest Sell'), total: sum(response?.highestSell || []), chart: response?.highestSell || [] },
        { label: t('Total Trade'), total: sum(response?.trades || []), chart: response?.trades || [] },
        {
          label: t('Total Fiat Income'),
          total: sum(response?.totalFiatIncome || []),
          chart: response?.totalFiatIncome || [],
        },
      ]);
    } catch (e) {}
  };

  // ---------------------
  useEffect(() => {
    getData();
  }, [period]);

  // ---------------------
  return (
    <>
      <Stack alignItems={'center'} sx={{ mb: 2 }}>
        <TimeChart period={period} setPeriod={setPeriod} />
      </Stack>

      <Grid container spacing={2.5}>
        {data.map((item, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Card>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack>
                  <Typography variant='h6'>{item.total?.toString()?.split('.')[1] ? item.total?.toString()?.split('.')[1]?.length > 8 ? `${item.total?.toFixed(8)}` : item.total : item.total}</Typography>
                  {/*<Typography variant='h6'>{item.total}</Typography>*/}
                  <Typography variant='body3' color='grey.100'>
                    {item.label}
                  </Typography>
                </Stack>

                {item.chart && <SmallAreaChart data={item.chart} isPositive width={101} height={37} />}
              </Stack>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
