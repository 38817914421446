import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

import { SET_ADMIN_USERINFO } from '../../../store/actionTypes';
import AuthLayout from '../../../components/authLayout/authLayout';
import { setTokenAdmin } from '../../../http/adminHttp/tokenHandlerAdmin';
import { navigationUrl } from '../../../navigationUrl';
import LoginForm from '../../../components/login/loginForm';
import LoginOTP from '../../../components/login/loginOTP';
import Api from '../../../http/adminHttp/serviceApiAdmin';

const Login = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [twoFaRequired, setTwoFaRequired] = useState(null);

  const onSuccess = async (response, emailAddress) => {
    if (response.userType !== 'ADMIN') {
      return enqueueSnackbar(t("You don't have permission to login!"), { variant: 'error' });
    }

    if (response.twoFaRequired) {
      const { smsAuthEnabled, emailAuthEnabled, softOtpAuthEnabled } = response.userInfo;
      setTwoFaRequired({ emailAddress, smsAuthEnabled, emailAuthEnabled, softOtpAuthEnabled });
    } else {
      setTokenAdmin(response.token);

      try {
        const { adminAccessInfoList } = await Api.getAdminAccess(response.userInfo.userId);

        dispatch({
          type: SET_ADMIN_USERINFO,
          payload: { adminUserInfo: { ...response.userInfo, access: adminAccessInfoList } },
        });
        navigate(navigationUrl.admin);
      } catch (e) {}
    }
  };

  return (
    <AuthLayout isAdmin linkTo={null}>
      {twoFaRequired ? <LoginOTP data={twoFaRequired} onSuccess={onSuccess} /> : <LoginForm onSuccess={onSuccess} />}
    </AuthLayout>
  );
};

export default Login;
