import Image from '../../../../components/Image';
import ModalContainer from '../../../../components/ModalContainer';
import circleStar from '../../../../assets/circle-star.svg';
import { Rating, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Iconify from '../../../../components/Iconify';
import Api from '../../../../http/serviceApi';
import { useNavigate } from 'react-router';
import { navigationUrl } from '../../../../navigationUrl';
import { useSnackbar } from 'notistack';

const StyledRating = styled(Rating)({
  '& label': {
    paddingRight: 3,
    paddingLeft: 3,
  },
  '& .MuiRating-iconEmpty': {
    color: 'grey.300',
  },
  '& .MuiRating-iconFilled': {
    color: '#30E0A1',
    filter: 'drop-shadow(0px 0px 3px #30E0A1)',
  },
  '& .MuiRating-iconHover': {
    color: '#30E0A1',
  },
});

export default function TicketRateModal({ open, onClose, ticketId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // -------------------------
  const onSendScore = async (e, star) => {
    try {
      const data = await Api.setTicketScore(ticketId, star);

      if (data?.status === 'SUCCESS') {
        enqueueSnackbar(t('Thank you for submitting your comment.'), { variant: 'success' });
        navigate(navigationUrl.ticket_list);
      }
    } catch (e) {}
  };

  return (
    <ModalContainer open={open} onClose={onClose} width={285} hasCloseButton={false}>
      <Stack alignItems={'center'} spacing={4} sx={{ mt: 3, mb: 5 }}>
        <Image src={circleStar} sx={{ width: 91, height: 91 }} />

        <Typography variant='body1_secondary'>{t('How satisfying it was ?')}</Typography>

        <StyledRating
          name='customized-color'
          defaultValue={0}
          icon={<Iconify icon='cil:star' sx={{ width: 22, height: 22 }} />}
          emptyIcon={<Iconify icon='cil:star' sx={{ width: 22, height: 22 }} />}
          onChange={onSendScore}
        />
      </Stack>
    </ModalContainer>
  );
}
