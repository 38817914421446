import { Button, MenuItem, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ComplexToolbar from '../../../../components/ComplexToolbar';
import DatePicker from '../../../../components/DatePicker';

export default function AdminListToolbar({
  conditionFilter,
  setConditionFilter,
  dateFromFilter,
  setDateFromFilter,
  dateToFilter,
  setDateToFilter,
  onFilter,
  isExportLoading,
  onExport,
}) {
  const { t } = useTranslation();

  const onReset = async () => {
    setConditionFilter('');
    setDateFromFilter(null);
    setDateToFilter(null);
    onFilter(false);
  };

  return (
    <>
      <ComplexToolbar
        onPrint={() => console.log('print')}
        onExport={onExport}
        onSearch={(v) => console.log(v)}
        isExportLoading={isExportLoading}
      >
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} sx={{ mt: 2 }} alignItems={'center'}>
          <TextField
            select
            label={t('Condition')}
            variant='standard'
            fullWidth
            value={conditionFilter}
            onChange={(e) => setConditionFilter(e.target.value)}
            sx={{ maxWidth: 300 }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            <MenuItem value={''}>{t('ALL')}</MenuItem>
            <MenuItem value={'ACTIVE'}>{t('Active')}</MenuItem>
            <MenuItem value={'INACTIVE'}>{t('Inactive')}</MenuItem>
          </TextField>

          <Stack direction={'row'}>
            <DatePicker
              label={t('From')}
              value={dateFromFilter}
              setValue={setDateFromFilter}
              variant='standard'
              maxDate={dateToFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
              InputProps={null}
            />
            <DatePicker
              label={t('To')}
              value={dateToFilter}
              setValue={setDateToFilter}
              variant='standard'
              minDate={dateFromFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
            />
          </Stack>
        </Stack>

        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 5 }} sx={{ mt: 5, mb: 5.75 }}>
          <Button
            onClick={onFilter}
            fullWidth
            variant='contained'
            sx={{ maxWidth: 300, typography: 'body3', fontWeight: 500 }}
          >
            {t('Filter')}
          </Button>

          <Button
            onClick={onReset}
            fullWidth
            variant='outlined'
            color='inherit'
            sx={{ maxWidth: 80, typography: 'body3', fontWeight: 500, borderColor: 'grey.400' }}
          >
            {t('Reset')}
          </Button>
        </Stack>
      </ComplexToolbar>
    </>
  );
}
