import { Box, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'jalali-moment';
import { prettyUppercases } from '../../../utils';

import Scrollbar from '../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../components/table';
import useTable from '../../../hooks/useTable';
import ReferralMarketingToolbar from './referralMarketingToolbar';
import ReferralMarketingCommissionRow from './referralMarketingCommissionRow';
import Api from '../../../http/adminHttp/serviceApiAdmin';
import { useSelector } from 'react-redux';
import { exportExcel } from '../../../utils/excel';
import { exportPDF } from '../../../utils/pdf';
import { getDefaultDateFilterValues } from '../../../utils/filter';

const TABLE_HEAD = [
  { id: 'num', label: 'Number', align: 'center' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'refereeId', label: 'Referee Id', align: 'center' },
  { id: 'referrerId', label: 'referrer Id', align: 'center' },
  { id: 'orderAmount', label: 'Order Amount', align: 'center' },
  { id: 'referrerIncome', label: 'Referrer Income', align: 'center' },
  { id: 'type', label: 'Type', align: 'center' },

];

export default function ReferralMarketingCommission() {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  // --------------------------
  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });

  const [dateFromFilter, setDateFromFilter] = useState(getDefaultDateFilterValues().firstDay);
  const [dateToFilter, setDateToFilter] = useState(getDefaultDateFilterValues().lastDay);
  const [nameFilter, setNameFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('ALL');

  // --------------------------
  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  // --------------------------
  const getList = async (withFilter = true) => {
    try {
      const filters = { status: statusFilter };

      if (withFilter) {
        if (nameFilter) filters.name = nameFilter;
        if (dateFromFilter && dateToFilter) {
          filters.start =  dateFromFilter?.valueOf?.();
          filters.finish = dateToFilter?.valueOf?.();
          // filters.start = moment(dateFromFilter).startOf('days').valueOf();
          // filters.finish = moment(dateToFilter).endOf('days').valueOf();
        }
      }

      const response = await Api.getReferralCommissionPayments(page, rowsPerPage, filters);
      if (response) {
        setTableData(response.referralCommissionInfoList);
        setTablePagination({ totalItems: response.totalUser });
      }
    } catch (e) {
      setTableData([]);
    }
  };

  // --------------------------
  useEffect(() => {
    getList();
  }, [page, rowsPerPage]);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);

  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    const filters = { status: statusFilter };
    if (nameFilter) filters.name = nameFilter;
    if (dateFromFilter && dateToFilter) {    filters.start =  dateFromFilter?.valueOf?.();
      filters.finish = dateToFilter?.valueOf?.();
      // filters.start = moment(dateFromFilter).startOf('days').valueOf();
      // filters.finish = moment(dateToFilter).endOf('days').valueOf();
    }

    try {
      const response = await Api.getReferralCommissionPayments(0, 10000000, filters);

      // generate excel and download
      const config = {
        language,
        fileName: t('Referral Marketing - Commission'),
        heads: ['#', t('Name'), t('Mobile'), t('Date'), t('Amount'), t('Condition'), t('Level')],
        columnsWidth: [5, 26, 16, 25, 11, 17, 26],
        list: response.adminUserInfoList.map((item) => [
          `${item.firstName} ${item.lastName}`,
          item.mobileNumber || '-',
          moment(item.registeredDate).locale(language).format('D MMM YYYY - HH:mm'),
          `${item.amount}`,
          `${t(item.condition)}`,
          `${t(prettyUppercases(item.authenticationLevel))}`,
        ]),
      };
      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  // --------------------------
  return (
    <>
      <ReferralMarketingToolbar
        dateFromFilter={dateFromFilter}
        setDateFromFilter={setDateFromFilter}
        dateToFilter={dateToFilter}
        setDateToFilter={setDateToFilter}
        onFilter={getList}
        onExport={onExport}
        setNameFilter={setNameFilter}
        nameFilter={nameFilter}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        isExportLoading={isExportLoading}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          <Table size='small'>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length || 0}
              onSort={onSort}
            />

            <TableBody>
              {tableData === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {tableData?.map((row, index) => (
                    <ReferralMarketingCommissionRow key={row.id} row={row} index={index} />
                  ))}

                  <TableNoData isNotFound={!tableData?.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      {tableData?.length > 0 && (
        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            component='div'
            count={tablePagination.totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
          />
        </Box>
      )}
    </>
  );
}
