// ----------------------------------------------------------------------

export default function Paper(theme) {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundColor: theme.palette.common.black,
        },
      },
    },
  };
}
