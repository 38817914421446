import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Box, Card, IconButton, Stack, Typography } from '@mui/material';

import SymbolIcon from '../../../../components/SymbolIcon';
import Iconify from '../../../../components/Iconify';

const options = {
  grid: {
    show: false,
    padding: {
      left: 0,
      right: 0,
    },
  },
  chart: {
    toolbar: {
      show: false,
    },
    sparkline: {
      enabled: true,
    },
    zoom: {
      enabled: false,
    },
    background: 'transparent',
    height: '50px',
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
  },
  xaxis: {
    type: 'numeric',
    lines: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    labels: {
      show: false,
    },
  },
  yaxis: [
    {
      y: 0,
      offsetX: 0,
      offsetY: 0,
      padding: {
        left: 0,
        right: 0,
      },
      labels: {
        show: false,
      },
    },
  ],
  tooltip: {
    x: {
      show: false,
      format: 'dd/MM/yy HH:mm',
    },
    enabled: false,
  },
  theme: {
    mode: 'dark',
    palette: 'palette1',
    monochrome: {
      enabled: false,
      color: '#255aee',
      shadeTo: 'light',
      shadeIntensity: 0.65,
    },
  },
};

export default function FavoriteItem({ data, chartType, onDelete }) {
  const [chartColor, setChartColor] = useState('');

  useEffect(() => {
    if (data?.changePercent > 0) setChartColor('#30E0A1');
    else if (data?.changePercent < 0) setChartColor('#BD47FB');
    else setChartColor('#90909081');
  }, [data?.changePercent, data?.symbol]);

  return (
    <Box sx={{ pl: '20px' }}>
      <Card sx={{ height: 220, overflow: 'unset', cursor: 'pointer', position: 'relative' }}>
        <IconButton sx={{ position: 'absolute', right: 3, top: 3 }} onClick={onDelete}>
          <Iconify icon='akar-icons:cross' color='grey.0' sx={{ width: 10, height: 10 }} />
        </IconButton>

        <Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <SymbolIcon symbol={data?.symbol} />
              <Typography variant='body1'>{data?.symbol}</Typography>
            </Stack>

            <Typography variant='subtitle1' color={data?.changePercent >= 0 ? 'positive.main' : 'negative.main'}>
              {data?.changePercent < 0 ? data?.changePercent * -1 : data?.changePercent}%
            </Typography>
          </Stack>

          <Typography variant='h5' sx={{ mt: 1.5 }}>
            {data?.amount}
          </Typography>

          <Typography variant='body2' color={'grey.300'}>
            {data?.usdtValue} USD
          </Typography>
        </Stack>

        <Chart
          options={{
            ...options,
            colors: [chartColor],
          }}
          series={[
            {
              data: data?.prices,
            },
          ]}
          colors={['#ffffff']}
          type={chartType}
          width='100%'
          height='70'
        />
      </Card>
    </Box>
  );
}
