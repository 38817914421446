import React ,{ useState } from 'react';
import people from '../../../assets/people.svg';
import order1 from '../../../assets/order1.svg';
import { alpha, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import Image from '../../../components/Image';
import Iconify from '../../../components/Iconify';
import { useTranslation } from 'react-i18next';
import purchase from '../../../assets/purchase.svg';
import amount1 from '../../../assets/amount1.svg';
import sale from '../../../assets/sale.svg';


const FutureStatistic = ({userWallet}) => {


  const { t } = useTranslation();

  const list =
    !!userWallet
      ?  [
        {
          value: userWallet?.amount +userWallet?.blocked  || 0,
          label: t('Total assets'),
          image: sale,
          color: '#F27F16',
        },
        {
          value: userWallet?.orderMargin || 0,
          label: t('Future margin balance'),
          image: purchase,
          color: '#BD47FB',
        },
        {
          value: userWallet?.positionMargin || 0,
          label: t('Order margin balance'),
          image: order1,
          color: '#11CABE',
        },
        {
          value: userWallet?.amount || 0,
          label: t('Available balance'),
          image: amount1,
          color: '#F27F16',
        },
        ] : []
  return list?.map((item, index) => (
    <Grid key={index} item xs={12} sm={6} md={4} xl={3}>
      <Card sx={{ display: 'flex', alignItems: 'center', p: 2.2, position: 'relative' }}>
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ backgroundColor: alpha(item.color, 0.1), borderRadius: '100%', width: 48, height: 48, mr: 2 }}
        >
          <Image src={item.image} />
        </Stack>
        <Stack spacing={'0.25'}>
          <Typography variant='h6' fontWeight={500}>
            {item.value}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {item.label}
          </Typography>
        </Stack>

        {item.onClick && (
          <IconButton onClick={item.onClick} sx={{ position: 'absolute', top: 8, right: 8, opacity: 0.7 }}>
            <Iconify icon='charm:refresh' sx={{ width: 16, height: 16, color: 'grey.0' }} />
          </IconButton>
        )}
      </Card>
    </Grid>
  ));
}
export default FutureStatistic;