import React from 'react';
import { Stack, Table, TableBody, TableContainer, Box, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FiatTransactionToolbar from './fiatTransactionComponents/fiatTransactionToolbar';
import Scrollbar from '../../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../../components/table';
import useTable from '../../../../../hooks/useTable';
import FiatTransactionTableRow from './fiatTransactionComponents/fiatTransactionTableRow';
import useFiatTransactionInit from './fiatTransactionComponents/hooks/useFiatTransactionInit';

// ===================================================================

const TABLE_HEAD = [
  { id: 'num', label: 'Num', align: 'left' },
  { id: 'depositId', label: 'Deposit Id', align: 'left' },
  { id: 'amount', label: 'Amount', align: 'center' },
  { id: 'createDate', label: 'Create Date', align: 'center' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'transactionType', label: 'Transaction Type', align: 'center' },
  // { id: 'detail', label: 'Detail', align: 'center' },
];

// ===================================================================

const FiatTransaction = ({ userId }) => {
  const { t } = useTranslation();
  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  const {
    fiatStatusFilter,
    setFiatStatusFilter,
    depositStatusFilter,
    setDepositStatusFilter,
    withdrawStatusFilter,
    setWithdrawStatusFilter,
    dateFromFilter,
    setDateFromFilter,
    dateToFilter,
    setDateToFilter,
    typeFilter,
    setTypeFilter,
    transactionIdFilter,
    setTransactionIdFilter,
    handleFilter,
    handleResetFilters,
    tableData,
    tablePagination,
    isExportLoading,
    onExport,
    amountFilter,
    setAmountFilter,
  } = useFiatTransactionInit(page, rowsPerPage, userId);

  return (
    <Stack>
      {/* <PageTitle title='Fiat Transactions' /> */}
      <FiatTransactionToolbar
        typeFilter={typeFilter}
        setTypeFilter={setTypeFilter}
        fiatStatusFilter={fiatStatusFilter}
        setFiatStatusFilter={setFiatStatusFilter}
        depositStatusFilter={depositStatusFilter}
        amountFilter={amountFilter}
        setAmountFilter={setAmountFilter}
        setDepositStatusFilter={setDepositStatusFilter}
        withdrawStatusFilter={withdrawStatusFilter}
        setWithdrawStatusFilter={setWithdrawStatusFilter}
        dateFromFilter={dateFromFilter}
        setDateFromFilter={setDateFromFilter}
        dateToFilter={dateToFilter}
        setDateToFilter={setDateToFilter}
        handleFilter={handleFilter}
        handleResetFilters={handleResetFilters}
        transactionIdFilter={transactionIdFilter}
        setTransactionIdFilter={setTransactionIdFilter}
        isExportLoading={isExportLoading}
        onExport={onExport}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          <Table size='small'>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length || 0}
              onSort={onSort}
            />
            <TableBody>
              {tableData === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {tableData?.map((row, index) => (
                    <FiatTransactionTableRow key={row.id} row={row} index={index} />
                  ))}

                  <TableNoData isNotFound={!tableData?.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={tablePagination.totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage={t('Rows per page')}
          labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
        />
      </Box>
    </Stack>
  );
};

export default FiatTransaction;
