import React, { useState, useRef } from 'react';
import { Button, Row, Col, Form } from 'antd';
import Webcam from 'react-webcam';
import classes from './interFaceScan.module.scss';
import { useNavigate } from 'react-router';
import faceCaptureAvatar from '../../../../../assets/faceCaptureAvatar.svg';
import { useSelector } from 'react-redux';
import imageToBase64 from 'image-to-base64/browser';
import Api from '../../../../../http/serviceApi';
import { showNotification } from '../../../../../utils/notification';
import { navigationUrl } from '../../../../../navigationUrl';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTranslation } from 'react-i18next';

const InterFaceScan = () => {
  const { t } = useTranslation();
  const webCamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(faceCaptureAvatar);
  const [imgBase64, setImgBase64] = useState('');
  const [isCapture, setIsCapture] = useState(false);
  const [isMediaActive, setIsMediaActive] = useState(false);
  const [captureBtnText, setCaptureBtnText] = useState('Enable Webcam');
  const [isDisabledContinue, setIsDisabledContinue] = useState(true);
  const [captureValidationMessage, setCaptureValidationMessage] = useState('');
  let navigate = useNavigate();
  const interAuthInfo = useSelector((store) => store.interAuthInfo);

  const onCapture = () => {
    if (isCapture) {
      if (!isMediaActive) {
        setCaptureValidationMessage('There is no camera available!');
        return;
      }

      const imageSrc = webCamRef.current.getScreenshot();
      setImgSrc(imageSrc);
      imgToBase64();
      setIsCapture(false);
      setCaptureBtnText('Enable Webcam again');
      setIsDisabledContinue(false);
    } else {
      setIsCapture(true);
      setCaptureBtnText('Capture');
      setIsDisabledContinue(true);
    }
  };

  const imgToBase64 = () => {
    imageToBase64(imgSrc)
      .then((response) => {
        setImgBase64(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const colProps = {
    xs: 24,
    sm: 24,
    md: 15,
    lg: 12,
    xl: 8,
  };

  const colPropsBotton = {
    xs: 24,
    sm: 10,
    md: 10,
    lg: 10,
    xl: 10,
  };

  const colPropsWebCam = {
    xs: 24,
    sm: 15,
    md: 15,
    lg: 15,
    xl: 15,
  };

  const videoConstraints = {
    facingMode: 'user',
  };

  const onContinueButtonClick = async () => {
    const data = {
      interAuthInfo: {
        ...interAuthInfo,
        userImage: imgBase64,
      },
    };
    console.log(data);
    const response = await setUserIntermediateAuthInfo(data.interAuthInfo);
    if (response?.status === 'SUCCESS') {
      navigate(navigationUrl.auth_inter_verified);
    }
  };

  const setUserIntermediateAuthInfo = async (data) => {
    const response = await Api.setUserIntermediateAuthInfo(data);
    return response;
  };

  const onUserMediaError = (e) => {
    setIsMediaActive(false);
    setCaptureValidationMessage('There is no camera available!');
  };
  const onUserMedia = (e) => {
    if (e.active) {
      setIsMediaActive(true);
      setCaptureValidationMessage('');
    }
  };

  return (
    <Row justify='center' style={{ marginLeft: '10px', marginRight: '10px' }}>
      <Col {...colProps}>
        <div className={classes.formBody}>
          <div className={classes.formTitleDiv}>
            <span className={classes.formTitleBig}>{t('Intermediate Verification')}</span>
          </div>

          <div className={classes.bodyWithoutButtons}>
            <div className={classes.webCamHelpText}>
              <span>{t('Enable your webcam and take a photo of yourself')}</span>
            </div>

            <Col {...colPropsWebCam}>
              <div className={classes.webCamDiv}>
                {isCapture ? (
                  <Webcam
                    className={classes.webCam}
                    videoConstraints={videoConstraints}
                    audio={false}
                    ref={webCamRef}
                    screenshotFormat='image/jpeg'
                    screenshotQuality={1}
                    onUserMediaError={onUserMediaError}
                    onUserMedia={onUserMedia}
                  />
                ) : (
                  <img src={imgSrc} className={classes.capturedPic} />
                )}
              </div>
            </Col>
            <span className={classes.validationText}>{captureValidationMessage}</span>
            <Button shape='round' onClick={onCapture}>
              {captureBtnText}
            </Button>

            <div className={classes.items}>
              <div>
                <span>{t('1. Avoid wearing hats')}</span>
              </div>
              <div>
                <span>{t('2. Avoid wearing glasses')}</span>
              </div>
              <div>
                <span>{t('3. Use enough lighting')}</span>
              </div>
              <div>
                <span>{t('4. Avoid using Filters')}</span>
              </div>
            </div>
          </div>
          <Row justify='space-evenly' style={{ marginTop: '40px' }}>
            <Col {...colPropsBotton}>
              <Form.Item>
                <Button
                  type='primary'
                  block
                  shape='round'
                  onClick={onContinueButtonClick}
                  disabled={isDisabledContinue}
                >
                  {t('Continue')}
                </Button>
              </Form.Item>
            </Col>
            <Col {...colPropsBotton}>
              <Form.Item>
                <Button
                  type='default'
                  block
                  shape='round'
                  onClick={() => navigate(navigationUrl.auth_inter_additionalinfo)}
                >
                  {t('Back')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default InterFaceScan;
