const ku = {
    translation: {
  "Cryptizi": "کریپتیزی",
  "Hi, Welcome Back": "سڵاو، خۆش ئەوەی بچیتە سەرەتا.",
  "Nice to meet you!": "!خوێندراویت",
  "Best Wallet For Your Coins": "بەهۆی کیفی پۆلەکانت، بەهۆی بەشەکانی خۆی",
  "Dashboard": "داشبۆرد",
  "Wallet": "کیفی پۆل",
  "{{number}} Currencies": "{{number}} پارە",
  "Sign up": "تۆماربوون",
  "Log in": "چوونەژوورەوە",
  "Search": "گەڕان",
  "No": "نەخێر",
  "Yes": "بەڵێ",
  "Balance": "باڵانس",
  "Income": "داهات",
  "Expenses": "خەرچاوەکان",
  "Exchange": "گۆڕانکاری",
  "Top Assets": "سەرەتای داراکان",
  "Transaction": "کاردان",
  "All": "هەموو",
  "Receive": "وەرگرتن",
  "Send": "ناردن",
  "Overall Growth": "پێشراوی گشتی",
  "Open trade": "کارەی کردن",
  "Market": "بازار",
  "Trade": "کار",
  "P2P": "ناوه‌ڕۆک بۆ ناوه‌ڕۆک",
  "E-Money": "پرفەکت مانی",
  "Convert": "گۆڕینی",
  "Crypto": "کریپتۆ",
  "Futures": "پێشگر",
  "Possibilities": "داهاتنی دۆخ",
  "Referrals & Income": "ڕابردوودەکان و داهات",
  "Ticket": "تێکەڵ",
  "History": "مێژوو",
  "Authentication": "چاکسازی",
  "Bank Card": "کارتی بانک",
  "Account settings": "ڕێکخستنەکانی حیساب",
  "Academy": "ئەکادیمی",
  "Security": "پاراستن",
  "Identification": "شناخت",
  "Referral": "ڕابردوو",
  "Verified": "پشتگیریکراو",
  "Regular user": "بەکارهێنەری بنەڕەتی",
  "Log out": "دەرچوون",
  "Add New favorite": "زیادکردنی نوێی دڵخواز",
  "Total Balance": "باڵانسی گشتی",
  "30 Days PNL": "30 ڕۆژەکانی PNL",
  "Today PNL": "PNL ی ئەمڕۆ",
  "Withdraw": "واڕدکردن",
  "Deposit": "پارەدان",
  "Your inventory": "ئایتمەکانت",
  "Rial": "ریال",
  "Coin": "پارە",
  "Total": "گشتی",
  "Available": "دەستپێدان",
  "In Order": "بەرز",
  "BTC Value": "قیمەتی بیتکۆین",
  "Action": "کردنەوە",
  "Buy": "کڕین",
  "Rows per page": "ڕیزەکان بۆ هەر پەڕە",
  "of": "لە",
  "All Situations": "هەموو شرایەتەکان",
  "History of your orders or transactions": "مێژووی فرمانەکان یان کارەکانی تۆ",
  "You can enter the name and symbol of Coin, transaction ID, etc. in the search field": "دەتوانی ناو و نمادی پارە، ناسنامەی کار، و ... لە خانەی گەڕاندا بنووسیت.",
  "Spend": "بەسەربردن",
  "Transaction requirements": "پێویستیەکانی کاردان",
  "Convert ": "گۆڕینی مستقیم",
  "last 1hr": "1 کاتژمێر گەڕمی کاتی",
  "last 24hr": "24 کاتژمێر گەڕمی کاتی",
  "last week": "هەفتەی گەڕمی کاتی",
  "last month": "مانگی گەڕمی کاتی",
  "Crypto convert": "کریپتویەک بەشێوەیەکی مستقیم",
  "Futures Classic": "فیوچرزی کلاسیک",
  "Token Listing": "لیستکردنی تۆکینەکان",
  "Total Sell": "کۆی فرۆشتنی گشتی",
  "APIs": "APIs",
  "iOS App": "ئەپڵی iOS",
  "Android App": "ئەپڵی ئەندرۆید",
  "Bank deposit": "داخڵکردنی بانک",
  "Credit/Debit card": "کارتی کرێدیت/دێبیت",
  "P2P trading": "پلە بە پلەی هاوبەشە",
  "Crytizi Convert": "کریپتیزی گۆڕین",
  "Spot": "Spot",
  "Margin": "Margin",
  "Markets": "بازارەکان",
  "Strategy Trading": "بازرگانی ڕێکخراو",
  "About Us": "دەربارەی ئێمە",
  "Service": "خزمەتگوزاری",
  "More": "زیاتر",
  "Learn": "فێربوون",
  "Community": "کۆمەڵایەتی",
  "About": "دەربارەی",
  "Contact us": "پەیوەندی بکەوە بۆ ما",
  "Terms": "وەشان",
  "FAQ": "پرسیار و وەڵام",
  "Token listing": "لیستکردنی تۆکین",
  "Cryptizi Convert": "کریپتیزی گۆڕین",
  "Buy/Sell Crypto": "کریپتو بەکرێ/بەفرۆشێ",
  "USDⓈ-M Futures": "فیوچرەکانی USDⓈ-M",
  "Coin-M Futures": "فیوچرەکانی کۆین-م",
  "Submit A request": "پەیوەندییەکەت بنێرە",
  "Fees": "کرێ",
  "Trading Rules": "ڕێڕەوی بازرگانی",
  "Browse Crypto Prices": "گشتی رێکخراوەکانی کریپتۆ",
  "Cryptizi Academy": "ئاکادیمیای کریپتیزی",
  "Sign Up": "تۆماربوون",
  "We are the most trusted cryptocurrency platform.": "ئێمە بەشێوەیەکی زۆر باپێکەوتنی پلەتفۆرمی کریپتۆپاوەکان هیوای ئەوە دەکەین.",
  "View more prices": "دیاری کردنی قیمتەکانی زیاتر",
  "Build your crypto portfolio anywhere.": "پۆرتفۆلیۆی کریپتۆی خۆت بەهەرجێ بکە لە هەر کۆچەیەک.",
  "A powerful cryptocurrency exchange in your pocket. Buy, sell and trade crypto on the go.": "بازرگانییەکی قووڵی کریپتۆپاوەکان لە سەنجاقەکەی خۆت. کریپتۆی خرید، فرۆشتن و گشتی لە لایدا دەکەین.",
  "Get the Cryptizi Wallet Mobile App Now!": "ئێستا بەرنامەی مۆبایلی کیفپارەی کریپتیزی بگرە!",
  "The most trusted cryptocurrency platform.": "پلەتفۆرمی کریپتۆپاوەکانی باپێکەوتنی زۆرترین.",
  "Cryptizi has a variety of features that make it the best place to start trading.": "Cryptizi بەلای ئەو ویژگیەی کۆنتا دەکات کە ئەو بەشێوەیەکی باشترین شوێن بۆ دەستپێکردنی کارگێڕی دەکەین.",
  "Cryptizi has a variety of features that make it the best place to start trading.": "Cryptizi هەندێک بەرزەراستەکان هەیە کە یەکەمین شوێنێکە بۆ دەستپێکردنی کارگێڕی دەکات.",
  "Buy and sell popular digital currencies, keep track of them in the one place.": "فراخرترین و گرانترین ورزەکانی دیجیتال بفریژە بە، هەنووکەیی بچین و فرۆش بکە و دەگەڕێ بکە لە یەک جا.",
  "For added security, store your funds in a vault with time delayed withdrawals.": "بۆ زیادکردنی پارەی تایبەتی، پارەکانت بەشێوەی خۆکاریەوە بەرزی لە سندوقێکدا پاشگری بکە.",
  "Stay on top of the markets with the Cryptizi app for Android or iOS.": "بەرز بمانەوە لەسەر بازارەکان بە ئاپڵیکەیشنی Cryptizi بۆ ئەندرۆید یان ئایۆس.",
  "Portofolio Manager": "بەڕێوەبەری پۆرتفۆلیۆ",
  "Mobile Apps": "ئاپڵیکەیشنەکانی مۆبایل",
  "One click, instant payout with credit or debit card.": "بەیەک کلیک، پارە دایبانە لەگەڵ کارتی کرێدیت یان دیبەتی.",
  "Become a crypto owner in minutes using your debit or credit card and quickly purchase top cryptocurrencies.": "چرکەوتنێکدا دوای چرکەی، بەکارهێنەری کریپتۆی مبنە و بەهۆی کارتی دیبەت یان کرێدیتی خۆت خۆتەوە وەکو بەرزترین کریپتۆکارە بچە وەکوی بەرزەرترین کریپتۆکارەکان خۆرایی دژی بخەرەوە.",
  "Intermediate Verification": "پشتگیریی نیشانکردن",
  "Intermediate Verified": "پشتگیریی نیشانکردن دووبارە دانەوە.",
  "Additional Information": "زانیاری زیاتر",
  "OK": "پەیوەندی",
  "Upgrade to Higher Limits": "بەرزبوون بۆ سنووری زۆرتر",
  "New users can earn up to {{amount}} in crypto rewards.": "بەکارهێنەرانی نوێ میتوانن تا {{amount}} لە مافە کریپتۆو بگرن.",
  "Ready to start trading cryptocurrency?": "ئایا ئامادەیە بۆ دەستپێکردنی کارگێڕی کریپتۆوە؟",
  "Enter you email address": "ناونیشانی ئیمەیلت بنووسە",
  "Get Started": "دەستپێبکە",
  "Your guide to the world of an open financial system. Get started with the easiest and most secure platform to buy and trade cryptocurrency.": "راهنمایت بۆ دنیایی سیستمی مالیێ کۆتایی. دەستپێبکە بە ئاسانترین و زۆرترین پلەتفۆرم بۆ فرۆشتن و کارگێڕی کریپتۆو.",
  "A trusted and secure crypto currency exchange.": "یەکێکەوە دووپاتی و سەرنج و پارەی کریپتۆوەی قابلپێکردن.",
  "Cryptizi ©. All rights reserved.": "Cryptizi ©. هەموو مافەکان پارەیژەکراوە.",
  "Terms Of Use": "درێژەی بەکارهێنان",
  "Privacy Policy": "سیاستی تایبەتی",
  "Assets": "بەشداریەکان",
  "Last price": "دوایین نرخ",
  "Change": "گۆڕان",
  "Chart": "شکێن",
  "Create an Account": "حسابی بەشیک بکە",
  "You are not a normal user!": "تۆ بەکارهێنەری بنەڕەت نیت!",
  "Forgot Password": "وشەی نهێنیت بیرمەرەوە",
  "Login": "چوونەژوورەوە",
  "Please input your username!": "تکایە ناوی بەکارهێنەرت بنووسە!",
  "Please input your password!": "تکایە وشەی نهێنیت بنووسە!",
  "Username": "ناوی بەکارهێنەر",
  "Password": "وشەی نهێنی",
  "Remember me": "من بیرکار بنێم",
  "You don't have permission to login!": "تۆ دەسەڵاتی چوونەژوورەوەت نییە!",
  "Please input your first name!": "تکایە ناوی یەکەمت بنووسە!",
  "Please input your last name!": "تکایە ناوی وەرەکیت بنووسە!",
  "Please input your email!": "تکایە ئیمەیلەکەت بنووسە!",
  "Please input your password": "تکایە وشەی نهێنیت بنووسە!",
  "Please input your confirm password!": "تکایە وشەی نهێنیت دووبارە بنووسە!",
  "The confirm password does not match the password": "وشەی نهێنیت دووبارە هاوبه‌ش نییه‌ لە وشەی نهێنیت",
  "You must accept the terms and conditions!": "تۆ پێویستە شرایط و ڕێکخراوەکان قبول بکەیت!",
  "I have read and agree to Cryptizi's Terms of Service and Privacy Policy.": "من خوێندویەوە و پەیوەندیدارم لە بەراوردەکانی خدمەتگوزاری و پێشانگای کریپتیزی.",
  "Confirm Password": "وشەی نهێنی بپشکنەوە",
  "Register": "تۆماربوون",
  "First Name": "ناو",
  "Last Name": "ناوی وەرەکی",
  "Email": "ئیمەیل",
  "Enter the email verification code!": "کۆدی دڵنیایی ئیمەیلت بنووسە!",
  "Enter the phone number verification code!": "کۆدی دڵنیایی ژمارەی تەلەفۆنت بنووسە!",
  "Enter the authenticator code!": "کۆدی دڵنیایی چاودێر بنووسە!",
  "Enter the {{number}}-digit code send to {{email}}": "کۆدی {{number}} چوار ژمارەیی ناردرا بۆ {{email}} بنووسە",
  "Email verification code": "کۆدی دڵنیایی ئیمەیل",
  "Phone number verification code": "کۆدی دڵنیایی ژمارەی تەلەفۆن",
  "Enter the {{number}}-digit code send to {{phone}}": "کۆدی {{number}} چوار ژمارەیی ناردرا بۆ {{phone}} بنووسە",
  "Authenticator code": "کۆدی چاودێر",
  "Enter the {{number}}-digit code from Binance/Google authenticator": "کۆدی {{number}} چوار ژمارەیی لە چاودێری باینانس/گووگڵ بنووسە",
  "Passwords must have at least 8 characters and contain at least one uppercase letters, lowercase letters, number and symbol.": "وشەی نهێنیەکان پێویستە کەمترین 8 نووسەر و هەلبژاردنی کەمترین یەک حرفی بزوور، یەک حرفی کەم، یەک ژمارە و نماد هەیە.",
  "You do not have a registered bank card": "تۆ کارتی بانکیت تۆمار نەکراوە!",
  "Card added successfully.": "کارت بە سەرکەوتووی زیادکراوە.",
  "Add a bank card": "کارتێکی بانکی زیاد بکە",
  "Type of Card": "جۆری کارت",
  "Register the card": "کارتەکە تۆمار بکە",
  "Please input the type of your card": "تکایە جۆری کارتەکەت بنووسە.",
  "Done Successfully": "بە سەرکەوتوویی ئەنجام درا.",
  "Account Number": "ژمارەی حساب",
  "CVV2": "CVV2",
  "Month of date": "مانگ",
  "Year of date": "ساڵ",
  "Card Number": "ژمارەی کارت",
  "Please input the type of your card!": "تکایە جۆری کارتەکەت بنووسە!",
  "Please input your card number!": "تکایە ژمارەی کارتەکەت بنووسە!",
  "Please input month of date!": "تکایە مانگەکە بنووسە!",
  "Please input 2-digits number": "تکایە ژمارەیەکی ڕێکخستنی دوو چوارگانی بنووسە!",
  "Please input year of date!": "تکایە ساڵەکە بنووسە!",
  "Please input CVV2!": "تکایە CVV2 بنووسە!",
  "Please input your account number!": "تکایە ژمارەی حسابەکەت بنووسە!",
  "Please input your Sheba number!": "تکایە ژمارەی شێبەکەت بنووسە!",
  "Please input a valid Sheba number!": "تکایە ژمارەی شێبەی باس بنووسە!",
  "Card Holder name": "ناوی دارنەکەری کارت",
  "Expiry date": "بەرواری کۆتایی",
  "To insert a bank card, fill out the form below and note that the bank card must be in your name.": "بۆ دانانی کارتی بانکی، فۆرمی خوارەوە پر بکە و ئاگاداری بکە کە کارتی بانکی پێویستە بە ناوی خۆت بێت.",
  "A trusted and secure cryptocurrency exchange.": "یکەمینە ڕێکخستنەوە و پارێزباڵی لەگەڵی کارتی دیجیتال.",
  "Vault Protection": "پاراستنی قەفە",
  "Successful": "سەرکەوتوو",
  "Learn & Earn": "پیشەوکەی و درێژکردن",
  "GET IT ON": "داگرتن لەسەر",
  "Google Play": "گۆگڵ پلەی",
  "Download on the": "داگرتن لەسەر",
  "App Store": "ئاپ ستۆر",
  "Additional Infomation": "زانیاری زیاتر",
  "Countries": "وڵاتەکان",
  "Traders": "فرۆشیاران",
  "Trading Pairs": "جۆرە بازرگانی",
  "Are you sure to remove this symbol from your favorites list?": "دڵنیایت کە ئایا دڵنیایت کە دەتەوێت ئەم ئامڕازە لە لیستی دڵخوازەکانت بیڕیت؟",
  "Tips": "پێناسەکان",
  "Deposit via receipt": "واریز لە ژمارەگرتن",
  "Deposit to wallet": "واریز بۆ کەفی پۆشتە",
  "Deposit payment gateway": "دروستکردنی دروازەی پرداختی",
  "Back": "بگەڕەوە",
  "Go to payment": "بڕۆ بۆ پرداخت",
  "Save": "پاشکەوت کردن",
  "When determining the deposit amount, make sure that the minimum purchase and sale amount in the exchange is {{amount}}.": "کاتێک وەسف بکەیت مبلغی واریز، دڵنیابەوە کە مەبلغی کڕین و فرۆشتنی کەمتر لە بازار کە {{amount}}ە.",
  "In order to deposit money, you must use bank cards in your own name, which are registered and approved in the user section.": "بۆ واریز کردنی پارە، پێویستە کارتە بانکی بە ناوی خۆت بەکارببەیت، کە لە بەشی بەکارهێنەر دەبێتەوە تۆمار و پەسەند کراوە.",
  "Payment is only possible through approved accounts, otherwise your payment will be unsuccessful.": "پارەدان تەنها لە رێگای حسابەکانی پەسەندکراو دەکرێت، ئەگەرنەبێت، پارەدانی تۆ بەسەرکەوتوو نابێت.",
  "Only for level {{level}} it is possible to deposit with a receipt.": "تەنها بۆ مرتبەی {{level}} گەورە میکرۆفۆنی بە ژمارە گرتن هەیە.",
  "We believe Cryptizi is here to stay - and that a future worth building is one which opens its doors and invites everyone in.": "ئێمە پێویستی دانێیژەی کریپتیزی لە لایەنیان هەیە - و ئاینەیەک کە بەردەوام بوونی هاتووە کە لەدەروازەکانی خۆی کڵاوان دابەزاندووە و هەمووان بەشداریی بکەن.",
  "Please input the repeat password!": "تکایە وشەی نهێنی پێویستە مەرجە کەوتو بنووسی!",
  "The repeat password does not match the password": "پاسۆردی پێویست دووبارەکردنەوە بە پاسۆردی ئێستا هاوبەش نییە",
  "Repeat Password": "دووبارەکردنەوەی پاسۆرد",
  "Successfully": "بە سەرکەوتویی",
  "Your email has been successfully verified": "ئیمەیلەکەت بە سەرکەوتویی دڵنیایەوە",
  "Verify Email": "دڵنیایی ئیمەیل",
  "Request Again": "داواکردنی دووبارە",
  "Didn’t recieve code?": "کۆدێک هەلەیە؟",
  "Forgot password": "پاسۆرد بیرماکرا",
  "The input is not a valid email!": "ئیمەیلێکی دڵنیای نییە!",
  "Reset password": "پاسۆردەکە بەتاڵکردووە",
  "Back to login": "گەڕانەوە بۆ چوونەژوورەوە",
  "No worries, we’ll send you reset instructions": "نەپێویستی نییە، ئەمە دەنێردرێتەوە بۆت لەکاتی بکردنەوە",
  "Submit": "ناردن",
  "Others": "تر",
  "Fiat Deposit": "پارە دابنێ فیات",
  "USD": "دۆلاری ئەمریکی",
  "You can enter the amount, transaction ID, etc. in the search field.": "دەتوانیت مقدارێک، ئایدی مامەڵە وەکو، لە خشتی گەڕاندندا دابنێیت.",
  "List of Toman deposits and withdrawals": "لیستی دابنێ و کاستنەوەکانی تۆمان",
  "Please input the value!": "تکایە مقدار داخڵبکە!",
  "Please select a bank card!": "تکایە کارتی بانکی هەڵبژێرە!",
  "You will see a list of your approved bank accounts": "لیستێکی حیسابەکانی بانکیتی پەسندکراو دەبینیت",
  "Num": "ژمارە",
  "Date": "ڕێکەوت",
  "Creation Date": "ڕێکەوتی دروستکردن",
  "Type": "جۆر",
  "Value": "مانا",
  "Inventory": "هەڵبژاردن",
  "Condition": "شرایط",
  "Description": "پسپڕە",
  "details": "وردەکاریەکان",
  "Please input the tracking code!": "تکایە کۆدی پیشاندان داخڵبکە!",
  "Optional and to speed up transaction confirmation time": "هەڵبژاردن و بۆ خاوەنکردنی کاتی پشتڕاستکردنی مامەڵە",
  "select to deposit": "هەڵبژاردن بۆ دابنێ",
  "Payer": "دانەدان",
  "Tracking Code": "کۆدی پیشاندان",
  "Date and time of transaction": "ڕێکەوت و کاتی مامەڵە",
  "How to deposit": "چۆنیەتی دانان",
  "Receipt image (optional)": "وێنەی وەرز (هەڵبژاردنی)",
  "Sell": "فرۆشتن",
  "USDT": "دۆلاری تەتڕی",
  "Stay tuned for more coin types!": "بۆ جۆرەکانی پارە زیاتر دڵنیابە!",
  "Enter Amount": "مقدار داخڵبکە",
  "All Payment Methods": "تۆشی دەستپاریی گشتی",
  "Refresh": "نوێکردنەوە",
  "Advertiser": "ڕیکلامی",
  "Price": "نرخ",
  "Available/Limits": "ئامادە/محدودیتەکان",
  "Payment Method": "ڕێگەپاندن",
  "Order(s)": "فەرمانەکان",
  "Limits": "محدودیتەکان",
  "Please select the currency!": "تکایە نوعی پارە هەڵبژێرە!",
  "Please input your wallet ID!": "تکایە شناسەی کیفی پولەکەت داخڵبکە!",
  "Complete the following information to buy digital currency": "زانیاری خوارەوە پر کردن بۆ خریدنی پارەی دیجیتاڵ",
  "Received money": "پارەی دریافتکراو",
  "Please input the value currency!": "تکایە مقداری پارە داخڵبکە!",
  "Voucher Perfect Money": "ووچر پەرفێکت مانی",
  "Price per unit of Perfect Money": "نرخ بۆ هەر واحدەکەی پەرفێکت مانی",
  "You can also enter the amount in tomans": "دەتوانیت مبلغەکە هەروەها لە تۆمان داخڵبکەیت",
  "Enter the relevant account ID": "ئایدی حیسابێکی پەیوەندیدار داخڵبکە",
  "With description": "بە تێبینی",
  "With security code": "بە کۆدی پارێزبەر",
  "Payment method": "شێوازی پارەدان",
  "Visa/Mastercards": "کارتە ویزا/ماستەرکارد",
  "Shetab Card": "کارتی شتاب",
  "Fast Pay": "پارەدانی خێرا",
  "By charging the wallet, make your payment without going to the bank portal": "لە خوارەوەی کیف پۆشیل، پارەدانت بەدوای مراجعەکردن بۆ دروازەی بانک پێ نەداوە",
  "Pay": "پارەدان",
  "Select currency": "واحد پارە هەڵبژێرە",
  "Value currency": "مقداری پارە",
  "Wallet ID": "ئایدی کیف پۆشیل",
  "One percent equals 0 Dollar": "یەک درصد بەرابرە لە 0 دۆلارە",
  "The sales fee is deducted from the amount you receive": "کارمزدی فرۆش بەردەستکراوە لە مبلغەی بەدوایت گرفتووە",
  "Sales fee": "کارمزدی فرۆش",
  "Voucher Code": "کۆدی ووچر",
  "Activation code": "کۆدی چالاککردن",
  "The purchase fee will be added to your purchase amount": "کارمزدی کڕین دابەشکراوە بۆ مبلغی کڕینت",
  "Purchase fee": "کارمزدی کڕین",
  "cryptizi convert": "گۆڕانکاری cryptizi",
  "Guaranteed price": "نرخی چاکراو",
  "Any pairs": "هەر جۆرێکی جێگر",
  "Zero fee": "کارمزدی صفر",
  "Orders": "فەرمانەکان",
  "From": "لە",
  "Spot wallet available": "کیف پۆشیلی بنکەی دواتر",
  "Please enter {{range}}": "تکایە {{range}} داخڵبکە",
  "To": "بۆ",
  "Please enter 20-42000000": "تکایە 20-42000000 داخڵبکە",
  "Enter an amount": "مقدارێک داخڵبکە",
  "Select Crypto": "Crypto هەڵبژێرە",
  "Deposit Crypto": "Crypto سپرده گذاری",
  "Withdraw Crypto": "Crypto بیروبچوون",
  "OTC": "مامەڵەکان",
  "Please input coin!": "تکایە کۆین داخڵبکە!",
  "Please input TXID!": "تکایە TXID داخڵبکە!",
  "Deposit crypto": "سپرده گذاری کریپتۆ",
  "Recent Deposit": "سپرده گذاری هەرێم",
  "Bitcoin": "بیتکۆین",
  "Network": "شبکە",
  "Select Deposit network": "شبکەی سپردە گذاری هەڵبژێرە",
  "Copy": "کۆپی",
  "Expected Arrival": "چاکرایی داخڵی",
  "{{number}} Network Confirmations": "{{number}} چاکردنەوەی شبکە",
  "Expected Unlock": "کۆد دابخە",
  "Minimum deposit": "کەمترین سپردە گذاری",
  "Selected wallet": "کیف پۆشیلی هەڵبژاردراو",
  "Main wallet": "کیف پۆشیلی سەرەکی",
  "change": "گۆڕانکاری",
  "Send Only {{symbol}} to this deposit address": "تەنها {{symbol}} بۆ ئەم ناونیشانەی سپردن دابنێ",
  "Ensure the network is": "دڵنیابەرە کە شبکە:",
  "Do not send NFTs to this address.": "NFT دابنێت بۆ ئەم ناونیشانەیە.",
  "learn how to deposit NFTs": "بۆ یادگیری چۆنیەتی سپردنی NFT دەربارە",
  "submit": "ناردن",
  "Video Tutorial": "ئامۆژگاری فیلم",
  "How to Withdraw crypto step-by-step Guide": "چۆنیەتی برداشت رمزارز لە ڕێنمای گامبەگام",
  "Why has my withdrawal Not Arrived?": "بەرای کێشەی برداشتم نەدۆزراوە؟",
  "How to find My transaction ID (TxID)?": "چۆنیەتی دۆزینەوەی ID ی تۆمار (TxID)ەکەم؟",
  "how to recover My BEP-20 token?": "چۆنیەتی گەڕاندنەوەی تۆکنی BEP-20 ئەمە؟",
  "Deposit & withdrawal status query": "پرسیاری و وضعیت سپردن و برداشت",
  "Select coin": "سکۆینت دیاری بکە",
  "TXID": "هەشی ID",
  "Scan the code on the withdrawal page of the trading platform APP or wallet App": "کۆدەکەی لە پەڕەی برداشتی ئەپڵی پلاتفۆرمی کارگێری یان ئەپڵی کیف پارە بسکانەوە",
  "Learn how to deposit NFTs": "یاد بگرە چۆنیەتی سپردنی NFT",
  "Unmatched networks automatically removed. Select a network below": "شبکەیەک بە همسانی نیەکان بە خۆکاری لابرایەتی سڕدرا. شبکەیەک هەڵبژێرە بۆ خوارەوە",
  "SOL": "SOL",
  "Solana": "سۆلانا",
  "TRX": "TRX",
  "Tron (TRC20)": "ترۆن (TRC20)",
  "Arrival time = {{min}} min": "کاتی هاتن = {{min}} دقیقە",
  "fee": "کۆی",
  "BSC ": "BSC ",
  "BNB Smart chain (BEP20)": "جێگای باشەکانی BNB (BEP20)",
  "Fee rules": "یاسای کۆی",
  "Select Network": "شبکە هەڵبژێرە",
  "Crypto Address": "ناونیشانی کریپتو",
  "Completed": "کتێبێتەوە",
  "Withdraw wallet": "کیف پارە برداشت بکە",
  "Spot Wallet": "کیف پارە نقطە",
  "View All": "هەموو بینینەکان",
  "Cryptizi user": "بەکارهێنەری Cryptizi",
  "Recent Withdrawals": "برداشتی نوێ",
  "Select Withdrawal network": "شبکەی برداشت هەڵبژێرە",
  "SQl Network Withdrawal suspended. withdrawals can be processed through alternative network.": "بەردەوامی بەرداشتی شبکەی SQl تۆمارکرا. بەرداشتەکان دەتوانرێت لە چارەسەر شبکەی هەڵبژاردن پرداخت کراوە.",
  "withdrawal": "برداشت",
  "Address": "ناونیشان",
  "Enter Address": "ناونیشان بنووسە",
  "USDT balance": "بەرهەمی USDT",
  "Network Fee": "کۆی شبکە",
  "Minimum withdrawal": "بەرداشتی کەمترین",
  "24h remaining limit": "سنووری باقی ماندنی 24کاتژمێر",
  "Please enter SMS verification code!": "تکایە کۆدی دوایینی پەیامکی بنووسە!",
  "Amount": "مقدار",
  "Receive {{receive}} {{symbol}} (Network fee {{fee}} USDT)": "گوازراندنی {{receive}} {{symbol}} (کۆی شبکە {{fee}} USDT)",
  "Source": "دەرکەوتن",
  "Spot wallet": "کیف پارە نقطە",
  "Verification code sent": "کۆدی دوایینی نێردرا",
  "security verification unavailable?": "چۆنیەتی پشتڕاستکردنی پارە نیە؟",
  "Security verification": "پشتگیریی دیاری",
  "Withdrawal Request Submitted": "داواکاری بۆ بەرگرتنەوە ناردرا",
  "Please note that you will receive an email once it is completed": "تکایە ئاگادار بەرەوەی کە دوایەمەی پەیامی ئیمەیلی ئەنجامدرا",
  "The receiver will get": "گیراو دەبێتەوە",
  "Save Address": "ناونیشان پاشکه‌وت‌بکه",
  "One-Step Withdrawal": "بەرگرتنی یەک مرحلەیی",
  "Enable One-Step Withdrawal to withdraw to whitelis addresses easily.": "بەرگرتنی یەک مرحلەیی بکات بۆ ئاسان بکردنی بەرگرتن بۆ ناونیشانەکانی لیستی سپی",
  "Please input your Recipient!": "تکایە گوێنەوەکەت داخلبکە!",
  "Cryptizi ID": "ناسنامەی Cryptizi",
  "Recipient’s email": "ئیمەیلی گوێنەوە",
  "Orders history": "مێژووی داواکاری",
  "Recive": "وەرگرتن",
  "Buy Crypto with Debit / Credit Card": "کریپتۆ بکە بە کارتی بەدیت / کارتی کرێدیت",
  "max": "زۆرترین",
  "Trades History": "مێژووی وەرزشەکان",
  "Orders History": "مێژووی داواکاری",
  "Convert History": "مێژووی گۆڕین",
  "Internal Transactions": "وەرزیاتی داخلی",
  "Futures History": "مێژووی کاتی ئێتەی",
  "Crypto Transactions": "وەرزیاتی کریپتۆ",
  "Fiat Transactions": "وەرزیاتی فیات",
  "ALL": "هەموو",
  "BUY": "کرین",
  "SELL": "فرۆشتن",
  "MARKET": "بازار",
  "LIMIT": "سنوور",
  "STOP LIMIT": "سنووری ڕاگرتن",
  "CANCELED": "لابرا",
  "EXPIRED": "کۆیتەوە",
  "FILLED": "پڕ",
  "NEW": "نوێ",
  "PARTIALLY FILLED": "بەشێک پڕ",
  "PENDING CANCEL": "چاوەڕوانی بۆ لابردن",
  "REJECTED": "ڕەتکرا",
  "Pair": "هاوپۆل",
  "Side": "کەمپۆل",
  "Filed": "تۆمارکرا",
  "Commission": "کۆمیسیۆن",
  "State": "وەلاسەر",
  "Credit Card": "کارتی کرێدیت",
  "Please input a valid CVV2!": "تکایە CVV2 ی باپێنەر دراو داخلبکە!",
  "Master Card": "کارتی ماستەر",
  "Visa Card": "کارتی ڤیزا",
  "Please input your old email address!": "تکایە ئیمەیلی کۆنت داخلبکە!",
  "Change Email": "گۆڕانکاری ئیمەیل",
  "Please input your new email!": "تکایە ئیمەیلی نوێت داخلبکە!",
  "New Email": "ئیمەیلی نوێ",
  "Email changed successfully": "ئیمەیل بە سەرکەوتوویی گۆڕدرا",
  "Tickets": "تێکەسییەکان",
  "Order": "داواکاری",
  "Account Settings": "چاککردنەوەی حیساب",
  "Settings Network": "چاککردنەوەی تەندروستی",
  "Settings": "چاککردنەوە",
  "Users": "بەکارهێنەران",
  "Admins": "ئەدمینەکان",
  "Referral marketing": "بازارکردنی هاوپێچ",
  "Crypto Wallet Transactions": "وەرزیاتی کیفی کریپتۆ",
  "Wallet Transactions": "وەرزیاتی کیفی",
  "Automatic Transactions": "وەرزیاتی خۆکار",
  "List Of All Bank Cards": "لیستی هەموو کارتەکانی بانک",
  "User's": "بەکارهێنەر",
  "Notification": "ئاگاداری",
  "Reporting": "ڕاپۆرتکردن",
  "Tether Setting": "چاککردنەوەی تەتەر",
  "Inventory Settings": "چاککردنەوەی ماوه",
  "Daily Rial withdrawal ceiling": "تیمارکردنی بەرگرتنی رۆزانە بۆ ریال",
  "Buy Digital Currency": "خریدارز دیجیتال",
  "API settings": "چاککردنەوەی API",
  "General settings": "چاککردنەوەی عام",
  "IP Block": "قاتی IP",
  "User level settings": "چاککردنەوەی سطحی بەکارهێنەر",
  "Deposit With Receipt": "داخستن بە گوێندنەوە",
  "Popup Settings": "چاککردنەوەی پاپ ئاپ",
  "Commission settings": "چاککردنەوەی کۆمیسیۆن",
  "Related To Reference Account": "پەیوەندیدان بە حیسابی ئەندامەکان",
  "Automatic Deposit settings": "چاککردنەوەی گوێندنەوەی خۆکار",
  "Dedicated Gateway settings": "چاککردنەوەی دروازەی تایبەت",
  "All users": "هەموو بەکارهێنەرەکان",
  "Add user": "زیادکردنی بەکارهێنەر",
  "Add Admin": "زیادکردنی ئەدمین",
  "Two-step login via Google": "تایبەتکردنی ناوه‌ڕۆکی دووپاتی لەپێگه‌ی گووگڵ",
  "Activation": "گواستنەوە",
  "6-digit google code": "کۆدی 6 نووسراوی گووگڵ",
  "Two-step login via Sms": "تایبەتکردنی ناوه‌ڕۆکی دووپاتی لەپێگه‌ی SMS",
  "Send SMS": "ناردنی SMS",
  "SMS Code": "کۆدی SMS",
  "Two-step login via Email": "تایبەتکردنی ناوه‌ڕۆکی دووپاتی لەپێگه‌ی ئیمەیل",
  "Send Email": "ناردنی ئیمەیل",
  "Email Code": "کۆدی ئیمەیل",
  "Tether price inquiry from api and automatic": "پرسیاری نرخی تەتەر لە API و خۆکار",
  "Settings Tether": "چاککردنەوەی تەتەر",
  "Caption": "کاپشن",
  "Please select a gateway!": "تکایە دروازەی تایبەتی هەڵبژێرە!",
  "Inventory settings": "چاککردنەوەی ماوه",
  "API status for inventory": "وضعیتی API بۆ ماوه",
  "Cancel": "لابردن",
  "Buy digital currency": "خریدارز دیجیتال",
  "Sell digital currency": "فرۆشتنیارز دیجیتال",
  "Digital currency": "ارزی دیجیتال",
  "Gateway Setting": "چاککردنەوەی دروازە",
  "Voucher Settings": "چاککردنەوەی کۆپون",
  "Pay Safe Settings": "چاککردنەوەی پارێزگا",
  "Perfect Money Settings": "چاککردنەوەی پارێزگا",
  "Cryptocurrency Setting": "چاککردنەوەی کریپتۆ",
  "Settings related to vouchers": "چاککردنەوەی کۆپون",
  "Purchase status": "وضعیتی کڕین",
  "Sales status": "وضعیتی فرۆشتن",
  "apikey": "کۆدی API",
  "seckey": "کلیدی ئاسایی",
  "Query the price of dollars from the api": "قیمتی دۆلارەکان لە لایەن api بپرسە",
  "Purchase price in dollars": "نرخی کڕین لە دۆلار",
  "Percentage on the price to buy": "درصد لەسەر نرخ بۆ کڕین",
  "Purchase price times this number": "نرخی کڕینی دوای ئەم ژمارەیە",
  "Selling price in dollars": "نرخی فرۆشتن لە دۆلار",
  "Percentage in price for sale": "درصد لە نرخ بۆ فرۆشتن",
  "Selling price times this number": "نرخی فرۆشتنی دوای ئەم ژمارەیە",
  "Percentage in the price for purchase (above one hundred tomans)": "درصد لە نرخ بۆ کڕین (سەر سەد تۆمان)",
  "Percentage in the price for sale (above one hundred tomans)": "درصد لە نرخ بۆ فرۆشتن (سەر سەد تۆمان)",
  "Settings related to Pay Safe Voucher": "چاککردنەوەی پیشەکی پارێزگا کراو",
  "UserName": "ناوی بەکارهێنەر",
  "AccountID": "هەژماری حیساب",
  "Purchase status of Perfect Money voucher": "وضعیتی کڕینی پیشەکی پارێزگا",
  "Settings related to Perfect Money": "چاککردنەوەی پارێزگای پارێزگا",
  "Perfect Money Voucher sales status": "وضعیتی فرۆشی پیشەکی پارێزگا",
  "Perfect Money Voucher": "پیشەکی پارێزگای پارێزگا",
  "Settings related to cryptocurrency reference account": "چاککردنەوەی حیسابی پێویست بە زانیاری کریپتۆ پارێزگا",
  "Binance apikey": "کلیدی api ی بایننس",
  "Binance seckey": "کلیدی ئاسایی ی بایننس",
  "Settings Cryptocurrency": "چاککردنەوەی کریپتۆ پارێزگا",
  "Enter the code that was sent to your previous mobile number and email in the box below": "کۆدێک بنووسە کە بۆ ژمارەی مۆبایل و ئیمەیلی پێشینەکەت ناردرا لە خوارەوە",
  "New Email verification code": "کۆدی پشتگیریی ئیمەیلی نوێ",
  "Add Market": "بازار زیاد بکە",
  "Name": "ناو",
  "Symbol": "ئاست",
  "Name Persian": "ناوی فارسی",
  "Selector": "هەڵگر",
  "Network Status": "دۆخی شبکە",
  "Settings network": "چاککردنەوەی شبکە",
  "Please input networks name!": "تکایە ناوی شبکە داخڵبکە!",
  "Please input network's selector!": "تکایە هەڵگری شبکە داخڵبکە!",
  "Please input network's persian name!": "تکایە ناوی فارسی شبکە داخڵبکە!",
  "Please input network's symbol!": "تکایە ئاستی شبکە داخڵبکە!",
  "Please input Minimum allowed!": "تکایە ڕێگای خراپی کەمترین داخڵبکە!",
  "Minimum allowed purchase": "خریدی کڕینە ڕێگای کەمترین",
  "Please input Daily purchase amount!": "تکایە مەوجی خرید رۆژانە داخڵبکە!",
  "IP test mode (to disable, leave the field blank)": "مۆدی تاقیکردنی IP (بۆ ناکردن، خانە بەتاقی بەبێ)",
  "Deactivating purchases from the site (inserting a message in the box below means deactivating purchases from the site)": "ناکردنەوەی کڕین لە سایت (ناردنی پەیامێک لە خوارەوە بەنێردرەوەی ناکردنەوەی کڕین لە سایتەوە)",
  "Daily purchase amount during registration": "مەوجی خریدی رۆژانە لە کاتی تۆمارکردن",
  "Deposit settings with receipt": "چاککردنەوەی واریز بە رێسید",
  "Bank name": "ناوی بانک",
  "Card number": "ژمارەی کارت",
  "Account number": "ژمارەی حیساب",
  "Shaba number": "شماره شابا",
  "Please input number of days!": "تکایە ژمارەی ڕۆژەکان بنوسە!",
  "Popup settings": "ده‌ستكاریكردنەوەی پۆپ‌ئاپ",
  "After each login, it shows the password (that is, if he has not used remember me) and also shows it every few days if he has remembered me!": "پاش هەر جار تۆماركردن، پاسۆردی نیشان دەدات (یانی ئەگەر بەیادكردنی من دانەکەوێت) و هەروەها پاش چەندین ڕۆژ نیشان دەدات ئەگەر بەیادكردنی من هەیە!",
  "Percentage": "درصد",
  "Automatic deposit settings": "ده‌ستكاریكردنەوەی وەرزی خۆڕای",
  "Business": "کار",
  "Deposit model": "مۆدێلی وەرز",
  "Mobile": "مۆبایل",
  "Rejection of automatic deposit for the following banks": "ردی وەرزی خۆڕای بۆ بانكەكانی خوارەوە",
  "Sat": "شەنی",
  "Sun": "یه‌كشه‌ممه‌",
  "Mon": "دووشه‌ممه‌",
  "Tue": "سێشه‌ممه‌",
  "Wed": "چوارشه‌ممه‌",
  "Thu": "پێنجشه‌ممه‌",
  "Fri": "جمعه‌",
  "Active": "چالاك",
  "Token": "تۆكەن",
  "Inactive": "چالاكناكراو",
  "Iran GATEWAY": "درگاهی ئێران",
  "Contract text": "واژۆی قەرارداد",
  "SMS confirmation for withdrawal and purchase": "دڵنیابونەوەی پەیامی SMS بۆ گەران و فراوانی",
  "International GATEWAY": "درگاهی نێودەوڵەتی",
  "Iranian payment gateways Appearance": "ڕوونکردنەوەی درگاهە پارەدانی ئێرانی",
  "Minimum wallet withdrawal amount": "جاری كردنەوەی كۆتایی بەرهەمەكانی كیف پارە",
  "Minimum amount of currency sales": "کۆتایی كردنەوەی بەرهەمی پارە",
  "Dedicated  Gateway settings": "ده‌ستكاریكردنەوەی دروستكردنی درگاه",
  "disable": "ناچالاك",
  "active": "چالاك",
  "This is the agreement between Iranianex.com and the user who uses the various services of the site. All users of Iranianex.com should read these rules completely and then become a member of the site if they accept them. Iranianex.com can update the terms of this agreement every month according to the changes in the laws. Registering on this site, using its services such as buying and selling, etc., means that you have read and accepted the terms of this contract. The user accepts that he is the legal owner.This is the agreement between Iranianex.com and the user who uses the various services of the site. ": "ئەمە قەراردادەی بەرزی Iranianex.com و بەكارهێنەری كە بەخۆی خزمەتگوزاریەكانی سایت بەكاردێت. هەموو بەكارهێنەرانی Iranianex.com دەبێت ئەم قەوانینە به پێی تواناییان بە دەقوو بخوانن و ئەگەر قبوول بکەن پاشان بەرەو یارمەتییان ئەدامەزرێنن. Iranianex.com دەتوانێت بە هەر مانگێكی گۆڤاری ئەم قەراردادە بە رێگای گۆڤاری قوانین نوێ بكات. تۆماركردن لەم سایتەوە، به كارهێنانی خزمەتگوزارییەكانی بەكاربردنی وەشانها و بازرگانی و ... ئەمە به معنای دەكات كە تۆ ئەو شرایطە قراردادەی پێیشانی دەقیوتە پەڕیوە و قبوول کردووە. بەكارهێنەر دەخەڵێت لە بابەتی ئەوەی پاڵپشتیی لەوێ بووه‌. ئەمە قەراردادێكە بەرزی Iranianex.com و بەكارهێنەری كە بەخۆی خزمەتگوزاریەكانی سایت بەكاردێت.",
  "Add New Admin": "زیادكردنی ئادمینی نوێ",
  "Admin": "ئادمین",
  "Block": "قاڵبکێشاندن",
  "Edit": "ده‌ستكاریكردن",
  "Delete": "سڕینه‌وه‌",
  "List of accounts": "لیستی هەژمارەكان",
  "Crypto transactions": "کاروبارەکانی كریپتۆ",
  "Bank cards": "کارتە بانکیەکان",
  "Log": "ژوره‌وه‌",
  "Toman transactions": "کاروبارەکانی تۆمان",
  "Please input name!": "تکایە ناوێك بنووسە!",
  "Please input mobile!": "تکایە ژمارەی مۆبایل بنووسە!",
  "Please input email!": "تکایە ئیمه‌یل بنووسە!",
  "Please input password!": "تکایە تێپەڕەوشە بنووسە!",
  "Add new admin": "زیادكردنی ئادمینی نوێ",
  "Deactive": "چالاکناكراو",
  "Delete user": "سڕینه‌وه‌ی به‌كارهێنه‌ر",
  "Access": "گرتن",
  "Two-step login": "چرکه‌ی دووپه‌ڕگه‌یی",
  "Upload photo": "بارکردنی وێنه‌",
  "Change photo": "گۆڕینی وێنه‌",
  "Notifications": "ئاگادارکردنه‌وه‌کان",
  "Wallet deposit and withdrawal": "دابینکردن و بیڕینی کارتەکان",
  "Filter": "فیلتر",
  "View transactions": "بینینی کاروبارەکان",
  "Edit Admin": "ده‌ستكاریكردنی ئادمین",
  "Wallet transactions": "کاروبارەکانی کارت",
  "User wallet transactions that have been changed by this admin": "کاروبارەکانی کارتی بەکارهێنەر کە لەلایەن ئەم ئادمینەوە گۆڕاون",
  "Bank": "بانک",
  "Amount of": "مقداری",
  "Currency": "پارە",
  "Status": "حاڵی",
  "Detail": "وردە",
  "Cryptocurrency wallet transactions": "کاروبارەکانی کارتی کریپتۆ",
  "Regarding": "پەیوەندی بە",
  "Business transactions": "کاروبارەکانی کاری",
  "Transactions of a transaction": "کاروبارەکانی کاری",
  "ID transaction": "شناسه‌ی کاری",
  "You can see the cryptocurrency wallet transactions that the admin has changed": "دەتوانیت کاروبارەکانی کارتی کریپتۆ ببینی کە ئەم ئادمینەوە گۆڕاوە",
  "The list of users whose document image has been changed by this admin.": "لیستی بەکارهێنەران کە وێنەی پەیوەندیدارییان لەلایەن ئەم ئادمینەوە گۆڕاوە.",
  "Level": "سطح",
  "Add admin": "زیادکردنی ئەدمین",
  "New Admin": "ئەدمینی نوێ",
  "You can not disable a port that is in the default state.": "ناتوانیت پۆرتێکی لە حالتی پێشفراوان ناچالاک بکەیت.",
  "Order type": "جۆری داواکردن",
  "Tether": "تتێر",
  "User": "بەکارهێنەر",
  "Transfer fee": "کرێی نەقل و بار",
  "network": "شبکە",
  "The amount": "مقدار",
  "Close": "داخستن",
  "Address wallet": "ناونیشانی کەیف پۆش",
  "Displaying saved parameters (Click)": "پیشاندانی پارامەترەکانی پاشکەوتکراو (کلیک بکە)",
  "Transactions Details": "زانیاریەکانی کاروبار",
  "Success": "سەرکەوتوو",
  "Failed": "سەرکەوتوو نەبوو",
  "You can see the total number of records on the current page, and for the total number of all displaysl": "دەتوانیت ژمارەی کۆی ریکۆردەکان لە پەڕەی ئێستادا ببینیت، و بۆ ژمارەی کۆی هەموو پیشاندانەکان",
  "Account list": "لیستی هەژمار",
  "Group payment": "پارەدانی گروپ",
  "Sheba's list": "لیستی شێبا",
  "You can see the wallet transactions of users.": "دەتوانیت کاروبارەکانی کەیف پۆشی بەکارهێنەران ببینیت.",
  "View": "سەیرکردن",
  "The transfer encountered an error": "نەتوانرا نەقل و بار بکرێت بەهۆی هەڵە",
  "Transaction number": "ژمارەی کار",
  "Transaction amount": "مقداری کار",
  "IP": "IP",
  "Done": "کرا",
  "New": "نوێ",
  "Your Notifications": "ئاگادارکردنەکانی تۆ",
  "View All Notifications": "سەیری هەموو ئاگاداریەکان",
  "Sort by": "ڕیزکردن بە پێی",
  "last 2 week": "دوو هەفتای ڕابردوو",
  "No Data": "داتایەک نییە",
  "Dollar": "دۆلار",
  "Payment unit": "یەکەی پارەدان",
  "Done successfully": "بە سەرکەوتوویی ئەنجامدرا",
  "Voucher code": "کۆدی ڤووچەر",
  "MAX": "بەرزترین",
  "Select Coin": "سکۆیەک هەڵبژێرە",
  "Main Wallet": "کەیف پۆشی سەرەکی",
  "Funding Wallet": "کەیف پۆشی پارە دان",
  "Select Wallet": "کەیف پۆش هەڵبژێرە",
  "Please select a coin firstly!": "تکایە لەسەر یەکێک لە سکۆکان هەڵبژێرە!",
  "Enter Tag": "تاگ بنووسە",
  "Add New Address": "ناونیشانی نوێ زیاد بکە",
  "Select from Address Book": "لەلایەن بەشداری ناونیشان ها هەڵبژێرە",
  "Phone": "تەلەفۆن",
  "balance": "بیلانس",
  "Receive {{receive}} {{symbol}}": "وەرگرتنی {{receive}} {{symbol}}",
  "Top gainer (24h)": "بەرزترین کرێکار (24 کاتژمێر)",
  "Highest volume (24h)": "بەرزترین میزان (24 کاتژمێر)",
  "Most Visited": "بەرزترین سەیریاندن",
  "New listing": "لیستی نوێ",
  "24h Change": "گۆڕانکاری 24 کاتژمێر",
  "24h Volume": "میزانی 24 کاتژمێر",
  "Market Cap": "پارچەی بازار",
  "Crypto Market": "بازاری کریپتۆ",
  "If you need help, watch the guide video in this section": "ئەگەر پشتگیریت بهیت، فیلمی راهنما لەم بەشە ببینە",
  "Watch the movie": "فیلم ببینە",
  "Your Share": "بڕیت",
  "Share friends": "بڕیت لەگەڵ دووستان",
  "Reports": "ڕاپۆرتەکان",
  "List of trades in your subset": "لیستی مۆدێلەکانی فرعییەکانتداکردبە",
  "Your income chart": "پێداویستی دراوەکانت",
  "List of introduced users": "لیستی بەکارهێنەرانی پێشنیازکراوەکان",
  "Link and introduction code": "بەستەر و کۆدی پێشنیارکراو",
  "By providing a link or referral code to your friends, you can earn money from their transaction fees and also allocate a part of this income to them, which is both for their benefit and for your benefit.": "لەڕێگای فراۋەربەستنی بەستەر یان کۆدی پێشنیارکراو بۆ دوستانت، دەتوانی پارەیێک بەرز بکەیت لە گەشتی خواستنەکانیان و هەروەها بەشێکی لەم دراوەیە دانەتوانی بۆیانەیان کردنەوە کە ئەوانەی هەروەها بۆ بەرآوریتان و بۆ بەرآوریتان بەکارببەیت.",
  "Set percentage for submissions": "درصدی بۆ ناردنەکردن دابینکراوەکاندا دابنێ",
  "30% of your friends transaction fee is provided for you and by setting this section you can divide your income among the people you have introduced.": "30٪ لە کارمزدی تراکنشی دوستانت بۆتان داهاتووە و لەڕێگای دابینکردنی ئەم بەشەدا دەتوانی پارەیەکی دراوی خۆت پێ بدەیت لە نێوان کەسانی بەرداچووەکانیت.",
  "Number": "ژمارە",
  "Details": "وردەکاریەکان",
  "Add Ticket": "تیکێت زیاد بکە",
  "Subject": "بابەت",
  "Please input section!": "تکایە بەشێکی وەربگرە!",
  "Please input description!": "تکایە وەرنووسی بابەت وەربگرە!",
  "Finance": "کەرەستن",
  "Technical": "تەکنیکی",
  "KYC": "KYC",
  "Name Ticket": "ناوی تیکێت",
  "Section": "بەش",
  "History of your trades": "مێژووی مۆدێلەکانیت",
  "Time": "کات",
  "History of your transactions": "مێژووی لێکۆڵینەکانیت",
  "Past 30 days": "30 ڕۆژی پێشوو",
  "Past 90 days": "90 ڕۆژی پێشوو",
  "Credited": "پارەی دەستپێکراو",
  "Pending": "لە چاککردنەوە",
  "Past 7 days": "7 ڕۆژی پێشوو",
  "Asset": "دارایی",
  "Enter TxID": "TxID بنووسە",
  "Select Asset": "دارایی هەڵبژێرە",
  "Destination": "ناونیشان",
  "Failure": "سەرکەوتوویی",
  "Canceled": "لابراوی",
  "Deposit ID": "هۆکاری پارەدان",
  "Please input your old Email address!": "تکایە ناونیشانی ئیمەیلی کۆنت وەربگرە!",
  "Fiat Withdraw": "فیات بەرداشت",
  "Withdrawal of wallet": "بەرداشتی کەفی پول",
  "Your recoverable inventory:": "مەزگاڵی قابل بازگەشتت:‌",
  "Equivalent daily withdrawal amount: 0 out of 500,000,000 Tomans": "موزونگرانەی مبلغی بەرداشتی ڕۆژانە: 0 لە 500,000,000 تۆمان",
  "When determining the deposit amount, make sure that the minimum purchase and sale amount in the exchange is $ 11.": "کاتێک ژمارەی پارەدان دیاری بکە، دڵنیابە بەردەوام بوونی کە گشتی نرخی کڕین و فرۆشتن لە گەیشتی $ 11 دەبێت.",
  "Bank account to receive": "هەژماری بانکی بۆ بەدەستهێنان",
  "Buy & Sell": "کریدار و فرۆش",
  "All Model": "هەموو مۆدێل",
  "Management": "بەڕێوەبەرایەتی",
  "Account Setting": "ڕێکخستنی هەژمار",
  "Profile": "پڕۆفایل",
  "Waiting Actions": "کردارەکانی چاوەڕوانی",
  "Admin Utilities": "ئامرازەکانی بەڕێوەبەر",
  "Title": "سەردێڕ",
  "Today": "ئەمڕۆ",
  "Yesterday": "دوێنێ",
  "This week": "ئەم هەفتە",
  "This month": "ئەم مانگە",
  "This Year": "ساڵی ئەم",
  "Total value": "نرخی گشتی",
  "Sales": "فرۆشتنەکان",
  "System status": "دۆخی سیستم",
  "Website": "ماڵپەڕ",
  "PaySafe Voucher": "کۆدی PaySafe",
  "PMvoucher": "کۆدی PM",
  "Name Currency": "ناوی دراوە",
  "Sale": "فرۆش",
  "Stock": "مەوجودی",
  "Amount of orders": "مقداری سەرجەم",
  "Sales number": "ژمارەی فرۆشی",
  "Selling price": "نرخی فرۆش",
  "Purchases": "کریدارەکان",
  "Purchase amount": "مقداری کریدار",
  "New User": "بەکارهێنەری نوێ",
  "Most sales": "بەرزترین فرۆش",
  "Most purchases": "بەرزترین کریدار",
  "Users waiting for Verify": "بەکارهێنەرانی کە چاوەڕوانییان منتظرن",
  "Fiat Transaction Waiting": "چاوەڕوانیی معاملە فیات",
  "Reset": "دووبارە دانان",
  "You can see the list of users whose information must be confirmed.": "دەتوانی لیستی بەکارهێنەرانی کە زانیاریەکانیان پێویستی بە پشتڕاستی دابنێت ببینی.",
  "Edit / Delete": "دەستکاری / سڕینەوە",
  "Basic": "بنچینە",
  "Crypto wallet": "کیف پارە کریپتۆ",
  "Fiat wallet": "کیف پارە فیات",
  "Crypto Transaction": "ماملە کریپتۆ",
  "Cards": "کارتەکان",
  "Trade history": "مێژووی گەشتی",
  "Earn ": "بار کردن ",
  "please fill the blank": "تکایە جۆرە خالی پڕ بکە",
  "Other": "تر",
  "Intermediate": "ناوەڕاست",
  "Advanced": "پەرەپێدان",
  "Send Form": "ناردنی فۆرم",
  "Middle Name": "ناوی ناوەڕاست",
  "Country": "وڵات",
  "Street Address": "ناونیشانی کۆچ",
  "birthDate": "ڕۆژی لە دنیای بوون",
  "Postal Code": "کۆدی پۆست",
  "City": "شار",
  "Phone Number": "ژمارەی مۆبایل",
  "Approve": "پشتگیری",
  "Reject": "ردکردن",
  "Send Notification": "ناردنی ئاگاداری",
  "Wallet’s User": "بەکارهێنەری کیف پارە",
  "Total inventory": "کۆتایی مەوجودی",
  "Equivalent to Dollar": "بەرهەمبەر دۆلار",
  "Inventory available": "مەوجودی بەردەستە",
  "Create a transaction": "ماملەیەک دروست بکە",
  "Add transaction": "ماملە زیاد بکە",
  "Error": "هەڵە",
  "Edit User": "دەستکاری بەکارهێنەر",
  "Create": "دروستکردن",
  "Update photo": "نوێکردنەوەی وێنە",
  "Value Transaction": "قیمتی ماملە",
  "Account Transaction": "ماملەی هەژمار",
  "Add Card": "کارتێک زیاد بکە",
  "Shaba": "شابا",
  "Waiting": "چاوەڕوانی",
  "Message": "پەیام",
  "Payment": "پارەدان",
  "Port": "پۆرت",
  "Order value": "قیمتی سەرجەم",
  "success": "سەرکەوتوو",
  "Earn": "بارکردن",
  "List of users that this user has introduced": "لیستی بەکارهێنەرانی کە ئەم بەکارهێنەرە معرفی کراوە",
  "Value Order": "قیمتی سفارش",
  "User commission": "کۆمیسیۆنی بەکارهێنەر",
  "ID card": "کارتی شناسایی",
  "Passport": "پاسپۆرت",
  "Upload .PDF/.JPG/.JPEG/.PNG file and no more than 5M": "فایل .PDF/.JPG/.JPEG/.PNG و حداکثر 5M بارگذاری کنید",
  "Select your Country / region": "وڵات / ناوچەت هەڵبژێرە",
  "Change level": "پلەی گۆڕین",
  "Change password": "گۆڕینی تێپەڕەوشە",
  "Representative mobile": "مۆبایلی نمایندە",
  "List of all bank cards": "لیستی هەموو کارتە بانکیەکان",
  "To edit, delete or approve or disapprove a bank card, click on the link to be redirected to the bank cards section in the users profile.": "بۆ ویرایش، سڕینەوە یان پشتگیری یان رد کردنی کارتێکی بانکی، کلیک لەسەر بەستەرەکە بکە بۆ گواستنەوەی بۆ بەشی کارتە بانکی لە پڕۆفایلی بەکارهێنەر.",
  "Edit card": "کارتی دەستکاری بکە",
  "Edit Card": "کارتی دەستکاری بکە",
  "Fiat transactions": "ماملەکانی فیات",
  "Edit admin": "دەستکاری ئەدمین",
  "API Setting": "ده‌ستکارییه‌کانی API",
  "Network Setting": "ده‌ستکارییه‌کانی شبکه",
  "Referrals": "بازرگانی",
  "Dollar balance": "هەژماری دۆلار",
  "Rial balance": "هەژماری ریال",
  "Percentage in the price for ( marketing )": "ژمارەیی لە نرخ بۆ (بازاریابی)",
  "Perfect Money ": "پارەی تەواو ",
  "Percentage in the price for purchase": "ژمارەیی لە نرخ بۆ کریدار",
  "Percentage in the price for sale": "ژمارەیی لە نرخ بۆ فرۆش",
  "Network Name": "ناوی شبکە",
  "General Settings": "ده‌ستکارییه‌کانی گشتی",
  "Type ...": "جۆر ...",
  "Account Holder's name": "ناوی دارندەی هەژمار",
  "Vandar": "Vandar",
  "PIR": "PIR",
  "Zibal": "Zibal",
  "Add User": "زیادکردنی بەکارهێنەر",
  "BASIC": "سەرەتایی",
  "Disapproval": "پشتگیری",
  "REGISTERED": "تۆمارکراو",
  "BASIC_BY_ADMIN": "سەرەتایی لە لایەن ئەدمین",
  "INTERMEDIATE_REQUESTED": "ناوەڕاستی داواکراوەتەوە",
  "ADVANCED_BY_ADMIN": "پەرەپێدانی لە لایەن ئەدمین",
  "INTERMEDIATE_BY_ADMIN": "ناوەڕاستی لە لایەن ئەدمین",
  "ADVANCED_REQUESTED": "پەرەپێدان داواکراوەتەوە",
  "Google": "Google",
  "LastName": "ناوی خانووادگی",
  "Sms": "پەیامیک",
  "Father Name": "ناوی باوک",
  "National Number": "ژمارەی ناوچەی",
  "Birth Date": "ڕێکەوتی لە دنیای دایک",
  "Login Date": "ڕێکەوتی داخڵبوون",
  "Verification": "پشتگیری",
  "Tell": "گوێ",
  "Add New Card": "زیادکردنی کارتی نوێ",
  "Support": "پشتیوانی",
  "caption": "سەرنووس",
  "List of commission payments to the wallet": "لیستی پارەدانی کۆمیسیۆن بۆ کیف پارە",
  "Referral Marketing": "بازاریابی ئەرجاوە",
  "waiting": "لە چاوەڕوانی",
  "Transfer completed successfully": "گواستنەوەی سەرکەوتوو بەسەرکەوتو",
  "All User": "هەموو بەکارهێنەر",
  "Perfect Money buyers/sellers": "فرۆشنەران/کڕینەرانی پارەی تەواو",
  "CryptoCurrency buyers/sellers": "فرۆشنەران/کڕینەرانی کریپتۆکارنسی",
  "Paysafe buyers/sellers": "فرۆشنەران/کڕینەرانی Paysafe",
  "Vouchers buyers/sellers": "فرۆشنەران/کڕینەرانی کوپن",
  "Referral Users": "بەکارهێنەرانی ئەرجاوە",
  "Verified Users": "بەکارهێنەرانی پشتگیریکراوەکان",
  "Users Who hasnt trade yet": "بەکارهێنەرانی کە هێشتا ماملە ناکراون",
  "This category does not apply to header alerts and shows for everyone": "ئەم پۆلە بۆ هاوڕێیەکانی سەرپەڕەی هەژمارەکان و پیشان دەدات بۆ هەمووان",
  "The title does not apply to sms": "سەردێڕەکە بۆ پەیامکی دیاری نەکراو",
  "Unverified Users": "بەکارهێنەرانی پشتگیری نەکراو",
  "Notification type": "جۆری ئاگادارکردنەوە",
  "Users whos Google Authenticator is inactive": "بەکارهێنەرانی کە Google Authenticator غیرفعالە",
  "Users whos SMS Authenticator is inactive": "بەکارهێنەرانی کە SMS Authenticator غیرفعالە",
  "Basic level Users": "بەکارهێنەرانی سەرەتایی",
  "Intermediate Users": "بەکارهێنەرانی ناوەڕاست",
  "Advanced Users": "بەکارهێنەرانی پەرەپێدان",
  "User Statistics": "ئاماری بەکارهێنەر",
  "Open trades": "ماملەکانی کراو",
  "Futures Open position": "موقعیتی کراوی فیوچرز",
  "Deposit Statistics": "ئاماری سپردن",
  "Crypto Statistics": "ئاماری کریپتۆ",
  "Successful Orders": "ماملەکانی سەرکەوتوو",
  "Withdraw Statistics": "ئاماری برداشت",
  "Unsuccessful Orders": "ماملەی ناکراو",
  "12 H": "12 کاتژمێر",
  "1 Day": "1 ڕۆژ",
  "1 Week": "1 هەفتە",
  "1 Months": "1 مانگ",
  "1 Years": "1 ساڵ",
  "last day": "ڕۆژی دوایین",
  "Buy/Sell": "کریدار / فرۆش",
  "Desktop": "دێسکتاپ",
  "Select": "هەڵبژێرە",
  "Report": "ڕاپۆرت",
  "Deactivate user": "بەکارهێنەری ناچالاککراو",
  "Active user": "بەکارهێنەری چالاک",
  "Excel": "Excel",
  "PDF": "PDF",
  "Gateway": "درگاه",
  "Post New Ad": "ئاگەی نوێ بنێرە",
  "Remainder": "باقیمانده",
  "Ads List": "لیستی ئاگەیەکان",
  "Post": "بنێرە",
  "Total Amount": "کۆی مەبەست",
  "With Fiat": "بە فیات",
  "Minimum order limit": "سنووردی کەمترین سفارش",
  "Maximum order limit": "سنووردی زۆرترین سفارش",
  "Please select the type!": "تکایە جۆرت دیاری بکە!",
  "Perfect Money": "پارەی تەواو",
  "Voucher PaySafe": "واتاری پەی‌سیف",
  "You can also enter the amount": "تۆ هەروەها دەتوانی مەبەست بکەیت",
  "Date & Time": "کات و دەق",
  "Serial number": "ژمارەی سیریال",
  "Continue": "دوام بدە",
  "Hot": "گرم",
  "Choose Payment method": "شێوازی پارەدان هەڵبژێرە",
  "Previous": "پێشوو",
  "Confirm": "پشتڕکەوت بەدە",
  "History of your orders": "سەرچاوەی سفارشەکانت",
  "Fiat": "فیات",
  "P2P History": "سەرچاوەی P2P",
  "History of your P2P orders": "سەرچاوەی سفارشەکانی P2Pت",
  "TAKE": "گرفتن",
  "MAKE": "دروستکردن",
  "COMPLETED": "تەواو",
  "FAILED": "سەرکەوتونەوە نەکرا",
  "PARTIALLY_COMPLETED": "بەشی تەواو",
  "PENDING": "لە چاک",
  "Please input your oldMobile!": "تکایە مۆبایلی کۆنت بنووسە!",
  "Please input your new mobile!": "تکایە مۆبایلی نوێت بنووسە!",
  "Change Mobile": "گۆڕینی مۆبایل",
  "New Mobile": "مۆبایلی نوێ",
  "Please input your oldEmailAddress!": "تکایە پۆستی ئیمەیلی کۆنت بنووسە!",
  "Paysafe total value": "کۆی ئەستێرەی پەی‌سیف",
  "Perfect Money total value": "کۆی ئەستێرەی پارەی تەواو",
  "Tether total value": "کۆی ئەستێرەی تەتەر",
  "Automatic Deposit History": "سەرچاوەی خاوەنی خۆکار",
  "Sheba number": "ژمارەی شێبە",
  "Unblock": "رەفتی کردن",
  "undefined": "نەگراو",
  "Approved": "پەسند کرا",
  "Rejectd": "ڕەت کرا",
  "You can see the list of commissions received by this user through introduction.": "تۆ دەتوانی لیستی کۆمیسیۆنەکانی گرفتووەکانی ئەم بەکارهێنەرە ببینیت لەپێشنیاری.",
  "INTERMEDIATE": "نیشانەوە",
  "ADVANCED": "پێشین",
  "Family": "خۆیاندن",
  "Please input first name!": "تکایە ناوی یەکەمت بنووسە!",
  "Please input email address!": "تکایە پۆستی ئیمەیل بنووسە!",
  "Please input last name!": "تکایە ناوی تووچەم بنووسە!",
  "If you don't decide to change the password, leave this field blank": "ئەگەر ئەم فیلدە بەتوانیت بە بەتاڵی خالی بگەیت",
  "Access Denied": "چارەسەرییەتی دانا",
  "Fixed panel page header": "سەرپەڕەی پەڕەی پانێڵ ڕێکخراو",
  "By notification": "بە ئاگادارکردنەوە",
  "Header panel pages only once": "تەنها یەکجارە سەرپەڕەی پانێڵ",
  "by Email": "بە ئیمەیل",
  "Via SMS": "بە پەیامی تەکسی",
  "Desktap": "پەڕه‌ی کاریگەری",
  "Deactive user": "کاربەری ناچالاککراو",
  "Buy Crypto": "کریداری رمزارز",
  "Contact Us": "پەیوەندی بە مێ",
  "Privacy policy": "سیاسەتی تایبەتی",
  "E-money": "پارەی ئلیکترۆنیکی",
  "Verify": "پشتگیری بدە",
  "Announcement": "ئاگەداری",
  "API": "API",
  "OTC Terms": "شرایطی OTC",
  "Convert Terms": "شرایطی گۆڕانکاری",
  "Please enter {{min}}-{{max}}": "تکایە {{min}}-{{max}} بنووسە",
  "Favorites": "دڵخوازەکان",
  "All Cryptos": "هەموو رمزارزەکان",
  "Spot Markets": "بازاری خشتی",
  "Futures Markets": "بازاری پێشبینینەکان",
  "New Markets": "بازاری نوێ",
  "Metaverse": "دنیای بەرپرس",
  "Gaming": "کۆچی بازی",
  "Defi": "Defi",
  "Innovation": "نوێترین",
  "Layer1/layer2": "لایه١/لایه٢",
  "Fan Token": "تۆکینی خەڵک",
  "NFT": "تۆکینەکانی بێگوڵ",
  "Storage": "پاککردن",
  "Palkadot": "پاڵکادۆت",
  "POS": "سوودانی پڕ",
  "POW": "سوودانی ڕووداو",
  "1 Month": "١ مانگ",
  "Symbols": "ئاستی ئەڵمەکان",
  "1 Year": "١ ساڵ",
  "Open": "کردنەوە",
  "Remove": "لابردن",
  "FINANCE": "کۆمەڵە",
  "TECHNICAL": "تەکنیکی",
  "Close ticket": "داخستنی تیکەت",
  "Updated Date": "بەرواری نوێکردنەوە",
  "Type a message": "پەیامێک بنووسە",
  "How satisfying it was ?": "چقدر راضی بوویت؟",
  "Ticket closed": "تیکەت داخرا",
  "Your current Limit": "محدودیتی ئێستا بەردەستتە",
  "Switch to enterprise Account": "گۆڕین بۆ ژمارەی کۆمپانیا",
  "Video Guide": "ڕۆژهەڵاتی ڤیدیۆیی",
  "Personal Information": "زانیاری شەخسی",
  "Crypto Deposit": "خستنەژمێری رمزدان",
  "Unlimited": "بێ سنوور",
  "Most Popular": "زۆرترین پسندیدە",
  "All basic Requirements": "هەموو داواکاریەکانی بنەڕەت",
  "Government ID": "ناسنامەی دۆلەت",
  "Facial Verification": "پشتڕاستکردنەوەی چەک",
  "Review time": "کاتی بریکردنەوە",
  "days": "ڕۆژ",
  "Fiat Deposit & Withdrawal Limit": "محدودیتی خستنەژمێری و بەرگرتنی پارە",
  "Daily": "ڕۆژانە",
  "P2P Transaction Limits": "محدودیتی مامورییەکانی P2P",
  "Crypto Withdraw": "بەرگرتنی رمزدان",
  "Highest Fiat Limit": "بەرزترین محدودیتی پارە",
  "Proof of Address": "پڕۆفی لەناونیشان",
  "Quick & Easy": "بە خێرایی و ئاسان",
  "WAITING": "لە چاک",
  "Advanced requested": "داواکراوی پیشەرفتە",
  "Intermediate requested": "داواکراوی نیشانەوە",
  "ACTIVE": "چالاک",
  "Intermediate by admin": "نیشانەوە لەلایەن بەڕێوەبەر",
  "Advanced by admin": "پیشەرفتە لەلایەن بەڕێوەبەر",
  "Registered": "خۆت تۆمارکردووە",
  "Select Approve or Reject status": "دۆخی پەسند یان رد را هەڵبژێرە",
  "Blocked": "چاودێریکراو",
  "USDT Value": "قیمەتی USDT",
  "USD Value": "قیمەتی USD",
  "Please fill the blank": "تکایە خانەی بەتوان پڕبکەرەوە",
  "Please select one": "تکایە یەکە دیاری بکە",
  "ID Card": "کارتی هویت",
  "User Image": "وێنەی بەکارهێنەر",
  "Country / region": "وڵات / ناوچە",
  "The user reject successfully": "بە سەرکەوتویی کاربر رد کرا",
  "Advanced Verification": "پشتڕاستکردنەوەی پیشەرفتە",
  "Confirm Residential Address": "پشتڕاستکردنەوەی ناونیشانی مەسکونی",
  "Please choose and upload one of the following documents": "تکایە یەک لە داگرتنی دۆکیومێنتەکانی خوارەوە هەڵبژێرە",
  "Utility bill (electricity, gaz. water/sewage, fibre/broadband service)": "سندوقی بەکارهێنانی (بەرق، گاز، ئاو/کۆلان، خدمەتی فایبر/باندی)",
  "Select Photo": "وێنەیەک هەڵبژێرە",
  "Upload .JPG/.JPEG/.PNG file and no more than 5M": "فایلی .JPG/.JPEG/.PNG باربکە و مەبەستی 5M لە زیاتر بێت",
  "Bank statement": "ئاماری بانک",
  "Basic Verification": "پشتڕاستکردنەوەی بنەڕەت",
  "Identity Information": "زانیاریی هویتی",
  "Nationality": "وڵاتی تابعیت",
  "Select your nationality": "وڵاتی تابعیتەکەت هەڵبژێرە",
  "date of birth": "بەرواری لەدایکبوون",
  "Please input your street address!": "تکایە ناونیشانی خیابانەکەت بنووسە!",
  "Please input your city!": "تکایە ناونیشانی شارەکەت بنووسە!",
  "Please input your phone number!": "تکایە ژمارەی مۆبایلەکەت بنووسە!",
  "Please input your postal code!": "تکایە کۆدی پۆستیەکەت بنووسە!",
  "Use a valid government-issued document": "مدرکی دولەتی گونجاو بەکارببە",
  "Only the following documents listed below will be accepted": "تەنها دۆکیومێنتەکانی خوارەوە پەسند دەکرێت",
  "Id Card": "کارتی هویت",
  "Government-issued": "لەلایەن دۆلەت دابنراوە",
  "place documents against a single-colored background": "دۆکیومێنتەکان لە پشتەی پسزەمینەیەکی ڕەنگی دابنێ",
  "Readable, well-lit, colored images": "وێنەکانی خوانا، بەرنورد، و رەنگا",
  "Original Full-size, unedited documents": "دۆکیومێنتەکانی سەرچاوە، پڕۆفیلە، و نەپڕکراو",
  "No black and white images": "هیچ وێنەیەکی سیاو و سپی نییە",
  "No edited or expired documents": "هیچ دۆکیومێنتێکی دەستکاری شدووە یان ماوەی دەکرێت",
  "file size must be between 10kb and 512kb in jpg/jpeg/png format": "قەبارەی فایل پێویستە لە نێوان 10kb و 512kb بێت لە فۆرماتی jpg/jpeg/png",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ فعالکردن، سه‌رۆك پێشوو رووی دوگمەی ناردنی پەیامی تێکست بکە بۆ بنێرە وەکو پەیامێك کەرەوە هەمووی کۆدی فعالکردن لە خولی داخڵ بکە و دوگمەی فعالکردن دابگرەوە.",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ فعالکردن، سه‌رۆك پێشوو رووی دوگمەی ناردنی ئیمەیل بکە بۆ بنێرە وەکو کۆدێک بۆ ئیمەیلەکەت دابنێ و کۆدەکە لە خولی داخڵ بکە و دوگمەی فعالکردن دابگرەوە. ممکنە لە پوختەی (هرزنامە / اسپم) ئیمەیلێکی گرتووته.",
  "To activate this feature, follow these steps:": "بۆ فعالکردنی ئەم تایبەتمەندیە، پەیوەندی لەگەڵ ئەم مامەڵەکانەوە بکە:",
  "2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program.": "2. پاش نصبکردن و دانانی بەرنامەی Google Authenticator بەرزی ئەکرەوە لە پێی یەکێک لە رێگای مێتۆدەکانی خوارەوە، کلیلێک بۆ بەرنامە زیاد بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کلیلێکی پارێزراو دابنێ: ئەم هەڵبژاردنە هەڵبژێرە و کۆدێکی خوارەوە بە بازنەوەیی دابنێ.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدەکەی پێگەیاو (ژمارەی 6 رەقەم) لە خانەی خوارەوە دابنێ و دوگمەی فعالکردن بزنەوە.",
  "1. Get the latest version of Google Authenticator from Google Play or Apple Store": "1. دوای نوێکردنەوەی وەرژنی نوێتری Google Authenticator لە گووگڵ پلەی یان فرۆشگاهی ئەپڵ دریافت بکە",
  "- Scan a barcode: Select this option and scan the following barcode.": "- بارکۆدێکی سکان کردن: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدێکی خوارەوە سکان بکەوە.",
  "WalletID": "شناسەی کیف پارە",
  "All Networks Status": "وضعیتی هەموو شبکەکان",
  "Networks Status": "وضعیتی شبکەکان",
  "UnVerified": "پەسندنەکراو",
  "User level settings can be changed from this section, and please change the settings of this section carefully.": "تەچڵەکانی سطحی بەکارهێنەر لەم بەشەداگرتووە، و تکایە چاکسازییەکانی ئەم بەشە بە دقت گۆڕانکاری بکە.",
  "Please input withdrawal of cryptocurrency!": "تکایە مقداری بۆ برداشتی کریپتۆکۆین داخڵ بکە!",
  "Please input withdrawal of fiat!": "تکایە مقداری بۆ برداشتی Fiat داخڵ بکە!",
  "Please input buying digital money (equivalent to dollar)!": "تکایە مقداری بۆ خریدی پارەی دیجیتاڵ (بەرابری دۆلار) داخڵ بکە!",
  "Please input buying digital money (equivalent to rial)!": "تکایە مقداری بۆ خریدی پارەی دیجیتاڵ (بەرابری ریال) داخڵ بکە!",
  "Please input fees for buy cryptocurrency!": "تکایە مقداری بۆ هەزینە بۆ خریدی کریپتۆکۆین داخڵ بکە!",
  "Please input fees for sell cryptocurrency!": "تکایە مقداری بۆ هەزینە بۆ فرۆشتنی کریپتۆکۆین داخڵ بکە!",
  "Please input trade maker fee!": "تکایە مقداری بۆ هەزینەی تۆڕی کریار داخڵ بکە!",
  "Please input futures maker fee!": "تکایە مقداری بۆ هەزینەی تۆڕی کریاری مەبەست داخڵ بکە!",
  "Please input trade taker fee!": "تکایە مقداری بۆ هەزینەی تۆڕی کریار داخڵ بکە!",
  "Please input futures taker fee!": "تکایە مقداری بۆ هەزینەی تۆڕی کریاری مەبەست داخڵ بکە!",
  "Please input internal transaction fee!": "تکایە مقداری بۆ هەزینەی تراکنشی ناوەراست داخڵ بکە!",
  "Please input OTC minimum buy!": "تکایە مقداری بۆ هەزینەی کەمترین خرید OTC داخڵ بکە!",
  "Cryptocurrency & Fiat Withdrawal": "بەردەوامکردنی کریپتۆکۆین و Fiat",
  "Tether Fee": "هەزینەی Tether",
  "Calculation of percentage in the price for buy": "حیسابکردنی پەرەنتەیج لە نرخ بۆ خرید",
  "Calculation of the percentage in the price for sale": "حیسابکردنی پەرەنتەیج لە نرخ بۆ فرۆشتن",
  "Futures Fee": "هەزینەی مەبەست",
  "Futures Fee (Coin-M Futures)": "هەزینەی مەبەست (Coin-M Futures)",
  "Futures Fee (USDⓈ-M Futures)": "هەزینەی مەبەست (USDⓈ-M Futures)",
  "Trade Fee": "هەزینەی کریار",
  "Internal Transaction Fee": "هەزینەی تراکنشی ناوەراست",
  "OTC Minimum Buy": "کەمترین خرید OTC",
  "DISAPPROVAL": "پێناسە نەکراو",
  "Advanced rejected": "پێشوفتە ردکراو",
  "Are you sure to remove this user from users?": "دڵنیایت کە دەتەوێت ئەم بەکارهێنەرە لە بەکارهێنەران لابرێت؟",
  "Are you sure to remove this ticket?": "دڵنیایت کە دەتەوێت ئەم تیکەتە لابرێت؟",
  "Please input your mobile number!": "تکایە ژمارەی مۆبایلەکەت بنووسە!",
  "Please select your birth date": "تکایە ڕۆژی لە دایکبوونت دیاری بکە",
  "Please select one!": "تکایە یەکی دیاری بکە!",
  "Please input your email adress!": "تکایە ئیمەیلەکەت داخڵ بکە!",
  "Please input your photo!": "تکایە وێنەیەکەت داخڵ بکە!",
  "Fiat type": "جۆری Fiat",
  "Fiat Status": "وضعیتی Fiat",
  "Withdraw Status": "وضعیتی برداشت",
  "Awaiting Approval": "چاوەڕوانیی پێویستکراو",
  "Cancelled": "لابراوە",
  "Email Sent": "ئیمەیل ناردرا",
  "Processing": "لە چاکسازی",
  "Rejected": "ردکراو",
  "Transaction ID": "ناسنامەی تراکنش",
  "Deposit Status": "وضعیتی واریز",
  "Deposit Id": "ناسنامەی واریز",
  "Create Date": "ڕێکەوتی دروستکردن",
  "Transaction Type": "جۆری تراکنش",
  "Transaction Id": "ناسنامەی تراکنش",
  "Sheba": "شێبە",
  "Deposit type": "جۆری واریز",
  "Created Date": "ڕێکەوتی دروستکردن",
  "Expired": "بەسەرچوونەوە",
  "Partially Filled": "بەشێک پرکراوە",
  "Pending Cancel": "چاکسازیی بەلامانەوە",
  "Limit": "حاجب",
  "Stop Limit": "حاجبی توقف",
  "Filled": "پرکراوە",
  "Transaction Date": "ڕێکەوتی تراکنش",
  "Stop Price": "نرخی توقف",
  "Stop limit": "حاجبی توقف",
  "Minimum limit": "کۆڵانەوەی کەمترین",
  "Stop price": "نرخی توقف",
  "Are you sure to remove this admin account?": "دڵنیایت کە دەتەوێت ئەم هەژماری بەڕێوەبەرە لابرێت؟",
  "Dynamic profit percentage": "پەرەژەی سودی دینامیک",
  "User Share": "بڵاوکراوی بەکارهێنەر",
  "Iran": "ئێران",
  "Armenia": "ئەرمێنستان",
  
      /**
       * **************************************************************************************
       * Please translate the below statements
       */
  "can see the list of commissions received by this user through introduction.": "توانایی دەدەیت لیستی کۆمیسیۆنەکانی گرێبەستی کراوەکان بە پێی ئەم بەکارهێنەرە ببینیت.",
  "Crypto Desposit": "پارەدانی رەمزی",
  "Monthly": "مانگانە",
  "Contry / region": "وڵات / ناوچە",
  "Use a valid goverment-issued document": "بەکارهێنانی دۆکیومێنتی دۆلەتی بەڵامێکی تایبەت",
  "place documents against a single-coloured background": "دوکیومێنتەکان دابەش بکە لەپێش ڕووناکی یەکگرتوو",
  "Readable, well-lit, colured images": "وێنەکانی خوانا، باڵا، و رەنگارناو",
  "Total Trade": "کۆی مارکێرکردن",
  "Account": "حساب",
  "Tracking code": "کۆدی پیشتیارکردن",
  "This transaction is in progress, and after review, you can either confirm or reject it.": "ئەم تراکسیۆنە لە ئەنجامداندا دەگەڕێنەوە، و پاش پشکنین، دەتوانیت ئەو وەشانەی تایبەتیت یان بڵاوکردنەوە",
  "Are you sure to deny this transaction?": "دڵنیایت کە دەتەوێت ئەم تراکسیۆنە رد بکەیت؟",
  "Are you sure to accept this transaction?": "دڵنیایت کە دەتەوێت ئەم تراکسیۆنە قبول بکەیت؟",
  "List of withdrawals": "لیستی چیاوبووان",
  "There is no record for this table": "بۆ ئەم جدولە هیچ لێکۆڵینەک نییە",
  "Recurring Buy": "خریدی دووبارەیی",
  "In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We`ve got to make it easier for you to activate with each one.": "بۆ زۆرکردنی پلەی ئاسایشی حیسابی خۆت، پێویستی بە فعالکردنی گۆشەی دووپێشبینیی خۆت هەیە. دەتوانیت گۆشەی دووپێشبینیی خۆکاری دووپێشبینیی خوارەوە فعال بکەیت، کە شامل گۆشەی دووپێشبینیی لەپەیوە، ئیمەیل و پەیامی کەسی وە هەیە، و بەترین رووژی ئاسایشە وەکو گۆشەی دووپێشبینیی لەپەیوەی گووگڵە، بەڵام بۆ ئەوەی پێویستیت بە چوار جۆرە چاوپێکەوتوو، ئەوانی کارییەکانی بەڵامەرەوە پێدانی لەسەر هەر کۆتاییەک.",
  "Are you sure to remove this order?": "دڵنیایت کە دەتەوێت ئەم فرمانە بسڕیت؟",
  "I want to pay": "من ماوەی پارەدانم",
  "I want to sell": "من ماوەی فرۆشتنم",
  "I will receive": "من دەستگەیشتنم",
  "Deposit account number": "ژمارەی حسابی واریز",
  "The total number of users": "کۆی ژمارەی بەکارهێنەران",
  "Total balance(Fiat)": "کۆی موجودی (رمزی)",
  "Total number of purchases": "کۆی ژمارەی خریداری",
  "Total sales": "کۆی فرۆشییەکان",
  "Total sales amount": "ژمارەی موجودی فرۆشی",
  "number of orders": "ژمارەی فرمانەکان",
  "otal order amount": "موجودی ژمارەی سفارش",
  "Transaction": "تراکسیۆن",
  "Referral Users": "بەکارهێنەرانی پێناسەکراو",
  "Existing users": "بەکارهێنەرانی هەریم",
  "Active users": "بەکارهێنەرانی چالاک",
  "All tickets": "تێکەڵەکانی تەواو",
  "Opened Tickets": "تێکەڵەکانی کراو",
  "Change": "گۆڕانکاری",
  "Price": "نرخ",
  "Pair": "جووار",
  "Market Trades": "کاروبارەکانی بازار",
  "Time Amount(MATIC) price(USDT)": "کاتی ڕێکەوت (MATIC) نرخ (USDT)",
  "Market Activities": "فعالیتەکانی بازار",
  "XMRBUSD": "XMRBUSD",
  "MANAUSDT": "MANAUSDT",
  "SLAPETH": "SLAPETH",
  "ONGBTS": "ONGBTS",
  "MATIC/USDT": "MATIC/USDT",
  "Binance": "بایننس",
  "Volume USDT": "قەبارەی USDT",
  "Volume MATIC": "قەبارەی MATIC",
  "low": "کەم",
  "High": "زۆر",
  "Polygon": "پۆلیگۆن",
  "STOP_LIMIT": "سندوقی ڕێگای بەستن",
  "MARKET": "بازار",
  "LIMIT": "سندوق",
  "Total": "کۆی",
  "Amount(MATIC)": "قەبارە (MATIC)",
  "Price(USDT)": "نرخ (USDT)",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Rol 0.00% got .years for USDT Recurring Buy": "Rol 0.00% got .years بۆ کڕینی دووبارەییی USDT",
  "Oder Book": "دفتری سفارشات",
  "Sum(BTC)": "کۆ (BTC)",
  "Size(BTC)": "قەبارە (BTC)",
  "Trades": "کاروبارەکان",
  "BTC/USDT": "BTC/USDT",
  "Sep": "ئەیل",
  "Oct": "تشرینی یەكەم",
  "Nov": "تشرینی دووەم",
  "3y": "٣ ساڵ",
  "50y": "٥٠ ساڵ",
  "Trade History": "مێژووی کاروباری",
  "Open Trades": "کاروبارەکانی کراو",
  "Positions": "بڕوانامەکان",
  "Margin Ratio": "ڕێژەی مارجین",
  "Liq.Price": "نرخی بازدەم",
  "Entry Price": "نرخی داخلی",
  "Size": "قەبارە",
  "BTCUSDT": "BTCUSDT",
  "Layer1": "لایەری ١",
  "Layer2": "لایەری ٢",
  "Fav": "خۆشە",
  "Number of referrals": "ژمارەی پێناسەکراوەکان",
  "Number of transactions": "ژمارەی معاملاتەکان",
  "Your total income is based on USD": "کۆی دراوتیت لەسەر دۆلار دا بنەڕەتەوە",
  "Updated At": "نوێکراوە لە",
  "Created At": "دروستکراوە لە",
  "Deleted": "سڕدرایەوە",
  "Close by admin": "داخستنەوە لەلایەن بەڕێوەبەر",
  "Oder ID": "ناسنامەی سفارش",
  "Paysafe History": "مێژووی Paysafe",
  "Perfect Money History": "مێژووی پێرفێکت مەنی",
  "Tx Code": "کۆدی Tx",
  "sum": "جمع",
  "BUY": "کڕین",
  "Unit price": "نرخی یەکە",
  "In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We`ve got to make it easier for you to activate with each one.": "بۆ زیادکردنی سطحی پارێزگای حیسابی خۆت، پێویستی بە ڕێکخستنەوەی وەرگرتنی دووپێشبینی خۆت دەکەین. دەتوانیت وەرگرتنی دووپێشبینی خۆکارانەی خوارەوە دەکەین، کە ئەوەش شامل وەرگرتنی دووپێشبینی لەژێر گووگڵ، ئیمەیل و پەیامی کەس وە هەیە، و زۆرترین شێوازی پارێزراو بۆ وەرگرتنی دووپێشبینی لەگووگڵە، بەڵام بۆ ئەوەی تۆ لەسەر چوار جۆرە ئەمە بینیت، دەبێت ئاسان بکرێت بۆ هەریک لەوانەی فعال بکرێت.",
  "Two-step login via Google": "ورگرتنی دووپێشبینی لەژێر گووگڵ",
  "Two-step login via Sms": "ورگرتنی دووپێشبینی لەگووگڵ",
  "Two-step login via Email": "ورگرتنی دووپێشبینی لەپەیام",
  "Withdrawal of cryptocurrency(equivalent to Dollar)": "بەردەوامکردنی پارەی دیجیتال (بەرابری دۆلار)",
  "Withdrawal of Fiat(equivalent to Dollar)": "بەردەوامکردنی پارەی موجودی (بەرابری دۆلار)",
  "Buying digital money(equivalent to Dollar)": "کڕینی پارەی دیجیتال (بەرابری دۆلار)",
  "Buying digital money(equivalent to Rial)": "کڕینی پارەی دیجیتال (بەرابری ریال)",
  "Fees for buy Tether": "کرێکاری بۆ کڕینی Tether",
  "Fees for Sell Tether": "کرێکاری بۆ فرۆشتنی Tether",
  "Future Fee(Marker)": "کرێکاری ئێستا (نشانگر)",
  "Future Fee(Taker)": "کرێکاری ئێستا (تیکر)",
  "Trade Fee(Marker)": "کرێکاری معامله (نشانگر)",
  "Trade Fee(Taker)": "کرێکاری معامله (تیکر)",
  "Fees for internal transaction": "کرێکاری بۆ معاملەکانی داخلی",
  "Min internal transaction": "کەمترین معاملەی داخلی",
  "Max internal transaction": "زۆرترین معاملەی داخلی",
  "Max leverage": "زۆرترین ڕێز",
  "Max Margin": "زۆرترین مارجین",
  "Card Number": "ژمارەی کارت",
  "Month Of date": "مانگی ڕێکەوت",
  "Year of date": "ساڵی ڕێکەوت",
  "Paysafe Price": "نرخی Paysafe",
  "Deposit Type": "جۆری واریز",
  "Voucher Currency": "وەڵامی کارت",
  "First Name": "ناوی یەکەم",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل" ,
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل" ,
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
      "Send Email": "ناردنی ئیمەیل",
      "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
      "Send Email": "ناردنی ئیمەیل",
      "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
      "Send Email": "ناردنی ئیمەیل",
      "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
      "Send Email": "ناردنی ئیمەیل",  "Last Name": "ناوی خۆشی",
  "Old Password": "وشەی نوێ",
  "New Password": "وشەی نوێ",
  "Confirm Password": "پشتڕاستکردنەوەی وشە",
  "Add Mobile": "زیادکردنی ژمارەی مۆبایل",
  "There is no record for this table": "هیچ ریکۆردێک بۆ ئەم خشتەیە نییە",
  "Paysafe Price": "نرخی پیسیف",
  "Deposite Type": "جۆری واریز",
  "Voucher Currency": "واحدی پارەدان",
  "Fiat Type": "جۆری فیات",
  "Perfect Money Price": "نرخی پەرفێکت مەنی",
  "TxID": "شناسه‌ی تراکنش",
  "Origin": "بنەڕەت",
  "Entry Price": "نرخی داخلی",
  "Leverage": "ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "Value currency": "واحدی پارەدان",
  "Address": "ناونیشان",
  "Recurring Buy": "کڕینی دووبارەیی",
  "Long": "کڕین",
  "Short": "فرۆشتن",
  "Cost": "هزینە",
  "Max": "زۆرترین",
  "Introduction link": "بەستەری دەرنوسین",
  "Introduction Code": "کۆدی دەرنوسین",
  "Number of referrals": "ژمارەی دەرنوسینەکان",
  "Number of transactions": "ژمارەی مامکردەکان",
  "Your total income is based on USD": "دراوتی کلەکەی شما لەسەر دۆلار بنەڕەتەوە",
  "Verify Now": "دروستکردنەوەی ئێستا",
  "After Basic": "پاش بنچینە",
  "After Intermediate": "پاش ناوەڕاست",
  "Fiat": "فیات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "تا ئێستا هیچ کاری نەکردووە. پاش دەستپێکردن بە فرۆشتن لە کریپتزی، یەکەم نموداری پێشبینیکراو ئەمجا دەرکەوێتەوە.",
  "Save Change": "پاشکەوتکردنەوەی گۆڕانکاری",
  "Setting Saved Successfully.": "ڕێکخستنەکان بەسەرکەوتویی پاشکەوتکراون.",
  "Default setting restored successfully.": "ڕێکخستنی پێش‌فراوان بەسەرکەوتویی گەڕاندنەوە.",
  "Please enter Spend amount!": "تکایە دانەی خرچەکردنەوە بنووسە!",
  "added to favorites successfully.": "بەسەرکەوتوویی بە موردی علاقەمندیەکان زیادکرا.",
  "Bad Request": "داوا نادرستە",
  "Enter the amount!": "مقدار بنووسە!",
  "Please enter total amount!": "تکایە دانەی سەرجەم بنووسە!",
  "Internal Server Error": "هەڵەی سێرڤەری ناوخۆیی",
  "Insufficient funds": "دوچھێنانی موجودی نییە",
  "Please select a coin!": "تکایە یەکێک لە سکۆیەکان هەڵبژێرە!",
  "Please fill in all fields": "تکایە هەموو خانەکان پڕ بکەوە",
  "Share percentage changed successfully.": "پارچەی سه‌رۆکی سه‌یه‌ک بە سه‌رکەوتوویی گۆڕایەوە.",
  "deleted from your favorites": "لە موردی علاقەمندییەکانت سڕدرایەوە",
  "Invalid request": "داوا نادروستە",
  "Invalid symbol USDTUSDT": "نمادی نادروست USDTUSDT",
  "Card ExpiryDate Month is invalid": "مانگی دوایەمەندی کارت نادروستە",
  "card is expired": "کارت بەسەرچووە",
  "Account Number": "ژمارەی هەژمار",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مامەڵە بەتاڵ دەکەوێت:\n 1. دوا نسخەی گۆگڵ ئۆتێنتیکاتۆرە بگرە لە گووگڵ پلەی یان فرۆشگاکانی ئەپڵ.\n 2. پاش نصبکردن و بەکارهێنانی پێوەرەکەی گۆگڵ ئۆتێنتیکاتۆر بە کاری پێشکەوتوویەکان، کۆدی پێویستی بۆ بەرنامە زیاد بکە. - بەرکەدی بەکارهێنان: ئەم هەڵبژاردنە هەڵبژێرە و بارکۆدی خوارەوە بەرکەوتە بکە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کۆدی پێویستی بنووسە: ئەم هەڵبژاردنە هەڵبژێرە و کۆدی خوارەوە بە باخەڕێنی بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدی پێویستی (ژمارەی 6 ڕەقەم) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردنەوە بکە.",
  "Google Code": "کۆدی گووگڵ",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی پەیامی مەکۆک بکە بۆ ناردنی پەیامی تێیدا کە ناوی کۆدی چالاککردنەوە دەگۆڕێت و gad لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە.",
  "Send SMS": "ناردنی SMS",
  "Email Code": "کۆدی ئیمەیل",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "بۆ چالاککردن، یەکەم کرتە لەسەر دوگمەی ناردنی ئیمەیل بکە بۆ ناردنی کۆد بۆ ئیمەیلەکەت و کۆدەکە لە خانەی کۆد بنووسە و کرتە لەسەر دوگمەی چالاککردنەوە بکە. ئیمەیلێکیت لە بوخچەی (Spam / Junk) گرتەکەوە.",
  "Send Email": "ناردنی ئیمەیل",
    "In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We got to make it easier for you to activate with each one.": "بۆ زیادکردنی سطحی پارێزگای هەژمارەکەت، پێشنیار دەکەین کە وەرودی دوو مرحلەییەکەت چالاک بکەیت. دەتوانیت وەرودی دوو مرحلەییەکەت چالاک بکەیت، کە تایبەتە بە وەرودی دوو مرحلەییەکە لەپێشی گوگل، ئیمەیل و پەیامیکە، و مێتابەتترین شێواز لەگەڵ گوگلەوە، بەڵام بۆ کاتێکدا سەرجەم وەرچەیەکان پێشنیار دەکەین بۆ پێویستە ئاسان بکرێت بۆ چالاککردنی هەریەکە لەگەڵ هەریەکەیەک. ",
  "Selecting higher leverage such as [10x] increases your liquidation risk. Always manage your risk levels.": "هەڵبژاردنی ئه‌هرمی باشتر وه‌كو [10x] خێرایی‌كانی لیكویده‌یشنی شما زیاد ده‌كات. هه‌میشه‌ به‌رپه‌ڕی بەرزی خێراییەكانتان.",
  "Maximum position: 15,000,000 USDT": "شوێنی زۆرترین: 15,000,000 USDT",
  "Adjust Leverage": "رێکخستنەوەی ئه‌هرم",
  "Leverage": "ئه‌هرم",
  "Select Symbol": "نماد هه‌ڵبژێرە",
  "See what the potential risk and reward will be in monetary terms on any given trade. Use our Futures Calculator to establish your potential profit/loss on a future trade. Read tips on how to use.": "بینێره‌ چی ئه‌و خێرا و پاراستی په‌سه‌ندی بۆ له‌ ئامرایی پاره‌دانی که‌یانی به‌ بهه‌رهه‌مێكی پاره‌ییان. بۆ ئه‌وه‌ی بەخێرهاتووی له‌گه‌ڵه‌ چوونه‌ چاره‌سه‌ری خۆتان بۆ چالاككردنی سود و بازه‌رزی په‌سه‌ندبوون له‌ پاره‌دانێكی به‌هه‌ریانی. دەقێنینه‌وه‌یه‌كانی دوووه‌ بخوێنه‌وه‌.",
  "Liquidation Price": "نرخی لیكویده‌یشن",
  "Target Price": "نرخی مه‌ڕجه‌كراو",
  "Initial Margin": "پارچەی هەژماری ئەوەی بەرەت بۆ داخڵکردن",
  "Shot": "شۆت",
  "Long": "لۆنگ",
  "Entry Price": "نرخی دانان",
  "Quantity": "دانه‌یه‌ك",
  "Calculate": "هەژماردان",
  "Result": "ئەنجام",
  "Maximum position": "شوێنی زۆرترین",
  "Introduction code": "كۆدی پێشنیار",
  "Introduction link": "لینکی پێشنیار",
  "No Data": "هیچ داتایەك نییە",
  "Total": "کۆی",
  "Number of referrals": "ژمارەی پێشنیارکراوەکان",
  "Number of transactions": "ژمارەی مامۆرییەکان",
  "Your total income is based on USD": "داواكاری گشتیت له‌سه‌ر دۆلاره‌",
  "introduction code": "كۆدی پێشنیار",
  "introduction link": "لینکی پێشنیار",
  "The total number of users": "کۆی ژمارەی کاربران",
  "Total number of orders": "کۆی ژمارەی فەرمانەکان",
  "Total sales amount": "کۆی مقداری فرۆشی",
  "Total number of purchases": "کۆی ژمارەی کڕینەوەکان",
  "Total balance ( Fiat )": "کۆی موجودی (فیات)",
  "Total order amount": "کۆی مقداری فەرمانەکان",
  "Total sales": "کۆی فرۆشەکان",
  "Total amount of purchases": "کۆی مقداری کڕینەوەکان",
  "Opened Tickets": "تێکەشەکانی کراو",
  "Existing users": "کاربرانی هەستیار",
  "All tickets": "هەموو تێکەشەکان",
  "Referral Users": "کاربرانی پێشنیارکراو",
  "Active users": "کاربرانی چالاک",
  "Transaction": "مامۆری",
  "Purchases amount": "مقداری کڕینەوەکان",
  "Sales amount": "مقداری فرۆشەکان",
  "Creat At": "ئه‌مرۆی ئه‌نجامدان",
  "Updated At": "نوێكردنه‌وه‌ی ئه‌نجامدان",
  "Order": "فەرمان",
  "Enter Order ID": "IDی فەرمان بنوسە",
  "Order ID": "IDی فەرمان",
  "First Name": "ناوی یەکەم",
  "Last Name": "ناوی تایبەت",
  "Open Trades": "فەرمانەکانی کراو",
  "Tx-Code": "کۆدی Tx",
  "Enter Tx-code": "کۆدی Tx بنووسە",
  "Tx Code": "کۆدی Tx",
  "Paysafe Price": "نرخی پێساف",
  "Deposit Type": "جۆری واریز",
  "Voucher Currency": "واحدی پێشکەش",
  "Paysafe History": "پەیجەی پیسیف",
  "Perfect Money History": "پەیجەی پەرفێکت مانی",
  "Fiat Amount": "قیمەتی فیات",
  "Perfect Money Price": "نرخی پەرفێکت مانی",
  "There is no record for this table": "هیچ بابەتێک بۆ ئەم خشتەیە نییە",
  "Origin": "سەرچاوە",
  "TxID": "IDی تراکسی",
  "Entry Price": "نرخی داخڵکردن",
  "Leverage": "بەرزبەری ئەهەرم",
  "Margin Ratio": "ڕێژەی مارجین",
  "User id": "IDی بەکارهێنەر",
  "Fiat withdraw transaction waiting": "تراکسی داخڵکردنی فیات لە چاوی",
  "You can see the list of fiat transactions whose information must be confirmed.": "دەتوانیت لیستی تراکسیۆنەکانی فیات ببینی کە زانیاریەکانیان پێویستە دووپاتی کراون.",
  "Fiat Type": "جۆری فیات",
  "Account": "حساب",
  "Actions": "کردارەکان",
  "Fiat deposit transaction waiting": "تراکسی داخڵکردنی فیات لە چاوی",
  "Receipt": "پێوانە",
  "Token": "تۆکەن",
  "Getway": "دەرگا",
  "Percentage in price for sale": "درصد لە نرخ بۆ فرۆش",
  "Purchase price in dollars": "نرخی خرید لە دۆلار",
  "Percentage on the price to buy": "درصد لە نرخ بۆ خرید",
  "Percentage in the price for ( marketing )": "درصد لە نرخ بۆ (بازاریابی)",
  "Selling price in dollars": "نرخی فرۆش لە دۆلار",
  "Inventory": "هەنگاوی",
  "Active": "کارگێری ئاکتیڤ",
  "Minimum allowed purchase": "کڕینەوەی ڕێگەپێدراو",
  "Only Iranian IPs are allowed": "تەنها IP ی ئیرانی دەخوێندرێت",
  "Deactivating purchase from site": "ناچالاککردنی کڕینەوە لە سایت",
  "IP Block": "بۆند ئای پی",
  "Num": "ژمارە",
  "Delete": "سڕینەوە",
  "Withdrawal of cryptocurrency (equivalent to Dollar)": "بەردەوامکردنی کریپتۆکۆین (بە هاوکردنەوەی دۆلار)",
  "Buying digital money (equivalent to Dollar)": "کڕینی پارەی دیجیتاڵ (بە هاوکردنەوەی دۆلار)",
  "Fees for buy Tether": "کرێیەکانی بۆ کڕینی تەتەر",
  "Marker": "نمای",
  "Taker": "گرفتار",
  "Fees for internal transaction": "کرێیەکانی تراکسی داخلی",
  "Min internal transaction": "تراکسی داخلیی چەکووک",
  "Max Leverage": "بەرزبەری زۆرترین",
  "Withdrawal of Fiat (equivalent to Dollar)": "بەردەوامکردنی فیات (بە هاوکردنەوەی دۆلار)",
  "Buying digital money (equivalent to Rial)": "کڕینی پارەی دیجیتاڵ (بە هاوکردنەوەی ریال)",
  "Fees for Sell Tether": "کرێیەکانی بۆ فرۆشی تەتەر",
  "OTC Minimum Buy": "کڕینی ژێرهێڵی OTC",
  "Max internal transaction": "تراکسی داخلیی زۆرترین",
  "Max Margin": "زۆرترین مارجین",
  "Futures settings": "ده‌ستکاریکردنەکانی ئه‌تی",
  "Disabled": "ناچالاککراو",
  "Enabled": "چالاککراو",
  "Max Borrow": "زۆرترین قەرز",
  "Add More Coins": "زیادکردنی زیاتر سکۆینەکان",
  "List of bank accounts": "لیستی حسابەکانی بانک",
  "Bank name": "ناوی بانک",
  "Card number": "ژمارەی کارت",
  "Account number": "ژمارەی حساب",
  "Shaba number": "ژمارەی شابا",
  "Add New": "زیادکردنی نوێ",
  "Transfer methods": "پێوەکراوی گواستنەوە",
  "Name": "ناو",
  "Card to Card": "کارت بۆ کارت",
  "Bank deposit": "داخڵکردن بۆ بانک",
  "Remember me": "من بپەخشە",
  "Web Advertisement Setting": "ده‌ستکاریکردنی ئاگاداری وێب",
  "Show how many days": "پیشاندانی چۆن چەند رۆژ",
  "Advanced editor": "چاودێریکردنی دەق",
  "Mobile Advertisement Setting": "ده‌ستکاریکردنی ئاگاداری مۆبایل",
  "Write Something awesome...": "کتێبەکەی خۆشە...",
  "Zibal": "زیبال",
  "Vander": "واندەر",
  "Default": "بنەڕەت",
  "Total users": "کۆی کاربرانی هەموو",
  "Verified users": "کاربرانی دیاریکراو",
  "Users are waiting": "کاربران لە چاوی",
  "Rejected users": "کاربرانی بەرزکراو",
  "User Id": "IDی بەکارهێنەر",
  "Mobile": "مۆبایل",
  "Tell": "پەیوەندی",
  "Select or Drage photo": "وێنەیەک هەڵبژێرە یان گواستنەوە",
  "Introduced user": "بەکارهێنەری دەرچوواو",
  "Total orders": "کۆی سفارشەکان",
  "Total commission orders": "کۆی سفارشەکانی کۆمیسیۆن",
  "Average commission per order": "نرخی کۆمیسیۆنی گۆڕاوی هەر سفارش",
  "Fiat Wallet Transaction": "تراکسیۆنی کیفپارەی فیات",
  "Notification Tite": "سەردێڕی ئاگاداری",
  "Color background": "پسپەڕەی رەنگ",
  "Normal(black)": "ڕەسا (سیاو)",
  "Users": "بەکارهێنەران",
  "Active days": "ڕۆژەکانی چالاک",
  "Headers": "سەرسەرەکان",
  "Notification /Email /SMS": "ئاگاداری / ئیمەیل / پەیامی کۆتا",
  "Types": "جۆرەکان",
  "Recipient": "گیراو",
  "Color": "رەنگ",
  "Active Days": "ڕۆژەکانی چالاک",
  "Total Trade": "کۆی مەعاملەکان",
  "Russia": "ڕووسیا",
  "United Arab Emirates": "ئەماراتی متحدەی عەرەب",
  "Saudi Arabia": "عەرەبستانی سعودی",
  "User Statistics": "ئاماری بەکارهێنەر",
  "Old Password": "وشەی گۆڕاوی کۆن",
  "New Password": "وشەی گۆڕاوی نوێ",
  "Confirm Password": "پشتیبانیکردنی وشەی گۆڕاو",
  "In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We`ve got to make it easier for you to activate with each one.": "بۆ زیادکردنی مەرجی بەیانی ڕازی ئەکاونتەکەت، پێویستیان لە هەژماری خۆتان ئاگادار بکات کە ورودی دوو مرحلەیەکی بەکارهێنانی خۆتان چالاک بکەین. دەتوانیت ورودی دوو مرحلەیەکی خوارەوە چالاک بکەین، کە شامل ورودی دوو مرحلەیەکی بە پەیوەندی بە گووگڵ، ئیمەیل و پەیامی کۆتا هەیە، و تایبەتمەندترین شێواز بۆ چالاککردن ورودی دوو مرحلەیەکی بە پەیوەندی بە گووگڵە، بەڵام بۆ ئێوە کە سێ جۆرێکی ئەنجام بدەین، پێویستیان بە ئاسانتر چالاک بکەین لەگەڵ هەر جۆرێک.",
  "To activate this feature, follow these steps: 1. Get the latest version of Google Authenticator from Google Play or Apple Store. 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "بۆ چالاککردنی ئەم تایبەتمەندییە، ئەم مەرجەکان بەبێ یەکگرتن: 1. وەرگرتنی نوێترین وەشانی Google Authenticator لە Google Play یان Apple Store. 2. پاش دامەزراندن و بەکارهێنانی برنامەی Google Authenticator بە یەکی لە مەرجەکانی خوارەوە، کلیلی بۆ برنامە زیاد بکەن. - یەکی ڕووکردنەوەی بارکۆد: ئەم هەڵبژاردنە دیاری بکە و بارکۆدی خوارەوە ڕووکرابە.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- کلیلی فراواندراو بنووسە: ئەم هەڵبژاردنە دیاری بکە و کۆدی خوارەوە بە دقت بنووسە.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. کۆدە گیراو (ژمارەی شش پیت) لە خانەی خوارەوە بنووسە و دوگمەی چالاککردن کرتەی بکەوە.",
  "Google Code": "کۆدی Google",
  "New Email": "ئیمەیلی نوێ",
  "Add Mobile": "مۆبایل زیاد بکە",
"Internal Server Error": "هەڵەیەکی داخڵی لە سێرڤەر",
"Only normal user allowed": "تەنها بەکارهێنەری ئاسایشی ڕێگراوە",
"Please input your old password!": "تکایە گۆڕانکارییەکەت بنووسە!",
"Passwords must have at least 8 characters and contain at least one uppercase letter, lowercase letter, number, and symbol.": "وشەی نهێنیەکان پێویستە کەمترین ٨ پیت وەکوو پێویستە حتماً یەک هەرفی بزرگ، یەک هەرفی کچک، یەک ژمارە و یەک نماد هەبێت.",
"Please input your confirm password!": "تکایە وشەی نهێنییەکەت دووبارە بنووسە!",
"Invalid soft otp": "کۆدی OTP نرم‌افزار نادروستە",
"Session not found": "سیشن نەدۆزرایەوە",
"Send SMS": "پەیامی کۆتا بنێرە",
"send SMS otp need phone number!": "بۆ ناردنی کۆدی پیامی کۆتای بەم بەرنامە، پێویستە شمارەی مۆبایل هەبێت!",
"User Id": "ئایدی بەکارهێنەر",
"Final amount": "مەوجودی دوایی",
"Fiat Type": "جۆری فیات",
"Waller ID": "ئایدی کیف پارە",
"Pay Safe Voucher": "کوپۆنی پیسیف",
"Pay Safe": "پیسیف",
"Black": "سیاو",
"Red": "سور",
"Green": "سەوز",
"Advanced level": "ڕیژبەینی پێشەمەند",
"Fixed alert": "ئاگادارکردنەوەی دانەوە",
"Notification Fixed alert": "ئاگادارکردنەوەی دانەوەی ئاگادارکردن",
"Test": "تاقیکردن",
"Fixed alert test": "تاقیکردنی ئاگادارکردنەوەی دانەوە",
"Fixed alert test 2": "تاقیکردنی ئاگادارکردنەوەی دانەوە ٢",
"Welcome back": "بەخێرهاتیت بۆگەڕێوە",
"Please active your 2FA Authenticator": "تکایە ئاحرازهەوەی ٢FA خۆت بکەوە",
"Success (green)": "سەرکەوتن (سەوز)",
"Danger (red)": "خۆش (سور)",
"Warning (yellow)": "ئاگادارکردن (زرد)",
"Order Book": "دەرچوونی داواکاری",
"Futures Fee(Marker)": "کرێیەکانی پیشنیارەکان (پاراستنەوەکەر)",
"Futures Fee(Taker)": "کرێیەکانی پیشنیارەکان (بارداشتکەر)",
"Trade Fee(Marker)": "کرێیەکانی ماڵپەڕ (پاراستنەوەکەر)",
"Trade Fee(Traker)": "کرێیەکانی ماڵپەڕ (تراکر)" ,
'Popup link': 'Popup link',
      'Please choose an image': 'تکایە وێنەیەک هەڵبژێرە',
      Tokens: 'تۆکنەکان',
      'Add token': 'زیادکردنی نیشانە',
      'Main wallet setting': 'ڕێکخستنی جزدانی سەرەکی',
      'Network setting': 'ریکخستنی تۆرەکە',
      "Listing":'لیستکردن',
      "Cryptizi offers a powerful platform to take advantage of a broad user base and solid infrastructure, improving your token's market visibility and accessibility." : "کریپتیزی پلاتفۆرمێکی بەهێز پێشکەش دەکات بۆ سوودوەرگرتن لە بنکەیەکی بەکارهێنەری فراوان و ژێرخانی تۆکمە، باشترکردنی بینین و دەستگەیشتن بە بازاڕی تۆکنەکەت.",
      "Application Process" :'پرۆسەی داواکاری',
      "Listing Application" : 'بەرنامەی لیستکردن',
      "To apply for listing, simply click on 'Listing Application'. Our business manager will promptly get in touch with you.": "بۆ پێشکەشکردنی داواکاری بۆ لیستکردن، تەنها کلیک لەسەر  بەرنامەی لیستکردن بکە. بەڕێوەبەری کارەکەمان بەپەلە پەیوەندیت پێوە دەکات.",
      "For any queries, reach out to us at listing@cryptizi.com. Thank you!":"بۆ هەر پرسیارێک، پەیوەندیمان پێوە بکەن بەم ئیمەیڵە: listing@cryptizi.com. سوپاس!" ,
      "Ticket Number" : 'ژمارەی بلیت',
      "Created At" : 'دروستکراوە لە',
      "Dedicated Wallet Settings" : 'ڕێکخستنی جزدانی تایبەت'

    },
  };
  
  export default ku;
  