import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, MenuItem, useTheme } from '@mui/material';

import { RHFCheckbox, RHFSelect, RHFTextField } from '../../../../../components/hookForm';
import { prettyUppercases } from '../../../../../utils';

const OtherTab = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const levels = [
    'ADVANCED',
    // 'ADVANCED_BY_ADMIN',
    // 'ADVANCED_REJECTED',
    // 'ADVANCED_REQUESTED',
    // 'ALL',
    'BASIC',
    // 'BASIC_BY_ADMIN',
    // 'BASIC_REQUESTED',
    'INTERMEDIATE',
    // 'INTERMEDIATE_BY_ADMIN',
    // 'INTERMEDIATE_REJECTED',
    // 'INTERMEDIATE_REQUESTED',
    // 'REGISTERED',
  ];
  const states = ['Active', 'Inactive'];

  return (
    <Stack
      sx={{
        mt: 5,
        width: { xs: '100%', md: '80%' },
        '& .Mui-disabled': {
          color: `${theme.palette.grey[500]} !important`,
          '& input': { WebkitTextFillColor: `${theme.palette.grey[0]} !important` },
        },
      }}
      spacing={{ xs: 5, md: 3 }}
    >


      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={5}>
        <RHFTextField
          name='amount'
          label={t('Amount')}
          variant='standard'
          sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
          disabled
        />
        <RHFSelect
          name='authenticationLevel'
          SelectProps={{
            native: false,
            MenuProps: {
              sx: {
                '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' },
              },
            },
          }}
          label={t('Change level')}
          variant='standard'
          sx={{ width: { xs: '100%', md: '50%' }, minWidth: 150, '& .MuiSelect-select': { display: 'flex' } }}
        >
          {levels.map((level, index) => (
            <MenuItem key={index} value={level}>
              <Typography variant='body2' color='grey.100'>
                {t(prettyUppercases(level))}
              </Typography>
            </MenuItem>
          ))}
        </RHFSelect>
      </Stack>

      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={5}>
        <RHFSelect
          name='changePassword'
          SelectProps={{
            native: false,
            MenuProps: {
              sx: {
                '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' },
              },
            },
          }}
          label={t('Change Password')}
          variant='standard'
          sx={{ width: { xs: '100%', md: '50%' }, minWidth: 150, '& .MuiSelect-select': { display: 'flex' } }}
        >
          {states.map((state, index) => (
            <MenuItem key={index} value={state}>
              <Typography variant='body2' color='grey.100'>
                {t(state)}
              </Typography>
            </MenuItem>
          ))}
        </RHFSelect>

        <RHFSelect
          name='twoStepLogin'
          SelectProps={{
            native: false,
            MenuProps: {
              sx: {
                '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' },
              },
            },
          }}
          label={t('Two-step login')}
          variant='standard'
          sx={{ width: { xs: '100%', md: '50%' }, minWidth: 150, '& .MuiSelect-select': { display: 'flex' } }}
        >
          {states.map((state, index) => (
            <MenuItem key={index} value={state}>
              <Typography variant='body2' color='grey.100'>
                {t(state)}
              </Typography>
            </MenuItem>
          ))}
        </RHFSelect>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={5}>
        <RHFTextField
          name='password'
          label={t('Password')}
          variant='standard'
          sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}

        />
        <RHFTextField
          name='password222222'
          label={t('Password222222')}
          variant='standard'
          sx={{ minWidth: 150, width: { xs: '100%', md: '50%' }, opacity:0 } }
          disabled

        />
      </Stack>

      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={5}>
        <RHFTextField
          name='mobile'
          label={t('Representative mobile')}
          variant='standard'
          sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
          disabled
        />

        <Stack
          direction='row'
          alignItems='center'
          spacing={3}
          sx={{ minWidth: 150, width: { xs: '100%', md: '50%' }, pt: 2 }}
        >
          <RHFCheckbox
            name='softOtpAuthEnable'
            label={t('Google authenticator')}
            sx={{ '& .MuiTypography-root': { color: 'grey.300', fontSize: 14 } }}
          />

          <RHFCheckbox
            name='emailAuthEnable'
            label={t('Email')}
            sx={{ '& .MuiTypography-root': { color: 'grey.300', fontSize: 14 } }}
          />

          <RHFCheckbox
            name='smsAuthEnable'
            label={t('SMS')}
            sx={{ '& .MuiTypography-root': { color: 'grey.300', fontSize: 14 } }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OtherTab;
