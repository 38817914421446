import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';

import Image from '../../../components/Image';
import arrow from '../../../assets/arrow.svg';
import SymbolInput from '../../../components/SymbolInput';
import useConvertForm from './hooks/useConvertForm';
import ActiveCryptoModal from '../../../components/activeCryptoModal/activeCryptoModal';
import ConvertPrice from './convertPrice';
import { LoadingButton } from '@mui/lab';
import useConvertQuote from './hooks/useConvertQuote';
import ConvertPairModal from './convertPairModal';
import { debounced } from '../../../utils';

export default function ConvertForm({ onSuccess }) {
  const { t } = useTranslation();

  // -------------------------
  const [showFromSymbolModal, setShowFromSymbolModal] = useState(false);
  const [showToSymbolModal, setShowToSymbolModal] = useState(false);
  const [spotAvailable, setSpotAvailable] = useState('');

  // -------------------------
  const {
    fromAmount,
    setFromAmount,
    toAmount,
    setToAmount,
    fromSymbol,
    setFromSymbol,
    toSymbol,
    setToSymbol,
    symbolSelectedWallet,
    convertPairs,
    selectedConvertPair,
    onMax,
    onSwap,
  } = useConvertForm();

  // -------------------------
  const { onQuote, onQuoteQuick, isSubmitting, prices, onReset } = useConvertQuote({
    fromAmount,
    fromSymbol,
    toSymbol,
    setToAmount,
  });

  // -------------------------
  const onChangeFromQuick = useRef(
    debounced((from, fromSymbol, toSymbol) => {
      onQuoteQuick(from, fromSymbol, toSymbol);
    }, 500)
  ).current;

  // -------------------------
  return (
    <>
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ mt: 2, mb: 0.5 }}>
        <Typography variant='body3' color={'grey.400'}>
          {t('From')}
        </Typography>

        <Stack direction='row' alignItems={'center'} spacing={2}>
          <FormControl variant='standard' sx={{ mt: -2, minWidth: 150 }}>
            <InputLabel id='spot'>{t('Spot wallet available')}</InputLabel>
            <Select
              labelId='spot'
              id='spot'
              disabled={!!prices}
              value={spotAvailable}
              onChange={(e) => setSpotAvailable(e.target.value)}
              label={t('Spot wallet available')}
              sx={{
                '&::before': {
                  display: 'none',
                },
                '&:after': {
                  display: 'none',
                },
              }}
            >
              <MenuItem value='1'>spot</MenuItem>
            </Select>
          </FormControl>

          <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
            <Typography variant='body4'>{symbolSelectedWallet?.available || 0}</Typography>
            <Typography variant='body4' color={'grey.300'}>
              {fromSymbol}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <SymbolInput
        amount={fromAmount}
        symbol={fromSymbol}
        disabled={!!prices}
        openModal={() => setShowFromSymbolModal(true)}
        onChangeAmount={(from) => {
          setFromAmount(from);
          onChangeFromQuick(from, fromSymbol, toSymbol);
        }}
        placeholder={
          selectedConvertPair
            ? t('Please enter {{min}}-{{max}}', {
                min: selectedConvertPair?.fromAssetMinAmount || 0,
                max: selectedConvertPair?.fromAssetMaxAmount,
              })
            : ''
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Button
                size='small'
                color='warning'
                disabled={!!prices}
                sx={{
                  typography: 'body1',
                  fontWeight: 900,
                  px: 1,
                  minWidth: 50,
                  height: 24,
                  borderRadius: 0.6,
                }}
                onClick={() => {
                  onMax();
                  onChangeFromQuick(symbolSelectedWallet?.available || 0, fromSymbol, toSymbol);
                }}
              >
                {t('MAX')}
              </Button>
            </InputAdornment>
          ),
        }}
      />

      <Stack alignItems={'center'} sx={{ mt: 4.5 }}>
        <IconButton onClick={onSwap} disabled={!!prices}>
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ borderRadius: '100%', backgroundColor: 'grey.600', width: 40, height: 40 }}
          >
            <Image src={arrow} sx={{ width: 18, height: 18 }} />
          </Stack>
        </IconButton>
      </Stack>

      <Stack direction='row' alignItems='center' sx={{ mt: -3, mb: 0.5 }}>
        <Typography variant='body3' color={'grey.400'}>
          {t('To')}
        </Typography>
      </Stack>

      <SymbolInput
        amount={toAmount}
        symbol={toSymbol}
        disabledValue
        disabled
        openModal={() => setShowToSymbolModal(true)}
        onChangeAmount={setToAmount}
        placeholder={
          selectedConvertPair
            ? t('Please enter {{min}}-{{max}}', {
                min: selectedConvertPair?.toAssetMinAmount || 0,
                max: selectedConvertPair?.toAssetMaxAmount,
              })
            : ''
        }
        sx={{
          '& input': {
            color: 'grey.100',
            height: '1.55em',
          },
          '& .Mui-disabled': {
            '& input': { WebkitTextFillColor: '#b5b8cc', color: 'grey.100' },
            '&:before': { borderBottomStyle: 'solid' },
          },
        }}
      />

      {prices ? (
        <ConvertPrice
          fromSymbol={fromSymbol}
          toSymbol={toSymbol}
          fromAmount={fromAmount}
          toAmount={toAmount}
          prices={prices}
          onBack={onReset}
          onSuccess={onSuccess}
        />
      ) : (
        <LoadingButton
          variant='contained'
          size='large'
          color='inherit'
          fullWidth
          loading={isSubmitting}
          sx={{
            backgroundColor: 'grey.400',
            typography: 'body3',
            fontWeight: 500,
            mt: 7.5,
            maxwidth: 627,
            '&:hover': {
              backgroundColor: 'grey.600',
            },
          }}
          onClick={() => onQuote()}
        >
          {t('Enter an amount')}
        </LoadingButton>
      )}

      <ActiveCryptoModal
        open={showFromSymbolModal}
        onClose={() => setShowFromSymbolModal(false)}
        onSelect={(v) => {
          setFromAmount('');
          setFromSymbol(v);
        }}
      />

      <ConvertPairModal
        open={showToSymbolModal}
        onClose={() => setShowToSymbolModal(false)}
        list={convertPairs}
        onSelect={(to) => {
          setToAmount('');
          setToSymbol(to);
          onChangeFromQuick(fromAmount, fromSymbol, to);
        }}
      />
    </>
  );
}
