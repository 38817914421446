import { Card, MenuItem, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import moment from 'jalali-moment';

import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import { BottomShadowList } from './style';
import { useSelector } from 'react-redux';

const Transaction = ({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const language = useSelector((store) => store.language);

  const [filter, setFilter] = useState('All');
  const [list, setList] = useState([]);

  useEffect(() => {
    let newList = [...data?.all];

    if (filter !== 'All') {
      newList = newList.filter((l) => l.transactionType === filter);
    }

    setList(newList.slice(0, 15));
  }, [filter, data]);

  return (
    <Card sx={{ pr: 1, pb: 0, position: 'relative' }}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant='h6_secondary'>{t('Transaction')}</Typography>
        <TextField
          select
          variant='outlined'
          label=''
          size='small'
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          SelectProps={{
            sx: { pl: 0, height: 38, pt: 0.5 },
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'black.0' } },
            },
          }}
          sx={{
            width: 110,
            mr: 2,
            '& fieldset': {
              borderColor: 'grey.0 !important',
              borderRadius: 50,
            },
          }}
        >
          {['All', 'Deposit', 'Withdraw'].map((item, index) => (
            <MenuItem
              key={index}
              value={item}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
              }}
            >
              <Typography variant='subtitle2'>{t(item)}</Typography>
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <Scrollbar height={'263px'} sx={{ mt: 3, pr: 2, pb: 6 }}>
        {list.length === 0 ? (
          <Stack alignItems={'center'} justifyContent={'center'} sx={{ mt: 6 }}>
            <Typography>{t('No Data')}</Typography>
          </Stack>
        ) : (
          <Stack spacing={2}>
            {list.map((item, index) => (
              <Stack key={index} direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                  <Stack
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                      backgroundColor: theme.palette.mode === 'dark' ? 'info.darkest' : '#fff',
                      width: 40,
                      height: 40,
                      borderRadius: '100%',
                    }}
                  >
                    <Iconify
                      icon={item.transactionType === 'Withdraw' ? 'ph:arrow-up-fill' : 'ph:arrow-down-fill'}
                      color={item.transactionType === 'Deposit' ? 'positive.main' : 'negative.main'}
                      height={20}
                    />
                  </Stack>
                  <Stack spacing={0.5}>
                    <Typography variant='body2'>
                      {moment(item.createDate).locale(language).format('MMM, Do YYYY')}
                    </Typography>
                    <Typography variant='body2' color='grey.400'>
                      {item.transactionType === 'Withdraw' ? t('Send') : t('Receive')}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack spacing={0.5} alignItems={'flex-end'}>
                  <Stack direction={'row'} spacing={0.5}>
                    <Typography
                      variant='subtitle2'
                      color={item.transactionType === 'Deposit' ? 'positive.main' : 'negative.main'}
                    >
                      {item.amount}
                    </Typography>
                    <Typography variant='subtitle2'>{item.symbol}</Typography>
                  </Stack>

                  <Typography variant='body2' color='grey.400' sx={{ width: 150 }} noWrap>
                    ID: {item.transactionId ? item?.transactionId : item?.id}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        )}
      </Scrollbar>

      <BottomShadowList />
    </Card>
  );
};

export default Transaction;
