import React,{ useState } from 'react';
import { Button, Card, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
import Image from '../../../components/Image';
import IconUSDT from '../../../assets/logo/USDT.svg';
import IconBTC from '../../../assets/logo/BTC.svg';

import Iconify from '../../../components/Iconify';
import { navigationUrl } from '../../../navigationUrl';
import WalletChart from '../wallet/walletChart';
import iconSampleWallet from '../../../assets/sampleWalletIcon.svg';
import { fFloat } from '../../../utils/formatNumber';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import useWallet from '../../../hooks/useWallet';
import FutureWalletChart from './FutureWalletChart';
import useResponsive from '../../../hooks/useResponsive';

const FutureWalletBalanceAndCharts = ({userWallet ,setTransferModal}) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const [valueVisible, setValueVisible] = useState(true);


  const isDesktop = useResponsive('up', 'lg');

  return (
    <Card sx={{ mt: { xs: 2, sm: 2 , md:3 }, mb: 2, px: { xs: 2, md: 3, lg: 4, xl: 6 } }}>
      <Grid container spacing={{ xs: 4, md: 0 }}>
        <Grid item xs={12} md={4} lg={4} xl={4}>
          <Stack
            alignItems={{ xs: 'start', md: 'start' }}
            sx={{ float: { xs: 'none', md: 'center' }, width: { xs: '100%', lg:  '100%' } }}
          >
            <Stack direction='row' alignItems='center' spacing={3}>
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Image src={IconBTC} alt='' sx={{ width: 36 }} />
                <Typography variant='h5'>BTC</Typography>
              </Stack>
              <IconButton onClick={() => setValueVisible((prev) => !prev)}>
                <Iconify icon={valueVisible ? 'ant-design:eye-outlined' : 'ant-design:eye-invisible-outlined'} />
              </IconButton>
            </Stack>

            <Typography variant='body2' color='grey.400' sx={{ mt: 4 }}>
              {t('Total Balance')}
            </Typography>

            <Typography variant='h4' sx={{ fontWeight: 600 }}>
              {valueVisible ? userWallet?.btcValue : '***'}

            </Typography>

            <Typography variant='h5' color={'grey.300'} sx={{ fontWeight: 400 }}>
              {valueVisible ? userWallet?.usdValue : '***'} USDT
            </Typography>

            <Stack direction={'row'} sx={{ mt: 4 }} spacing={{ xs: 1, lg: 2 }}>
              <Button
                variant='contained'
                color='secondary'
                onClick={() => navigate(navigationUrl.futures)}
                sx={{ width: { xs: 100, lg: 123 }, height: { xs: 40, lg: 47 } }}
              >
                {t('Trade')}
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={setTransferModal}
                sx={{ width: { xs: 100, lg: 123 }, height: { xs: 40, lg: 47 } }}
              >
                {t('Transfer')}
              </Button>
            </Stack>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          lg={8}
          xl={8}

        >
          <Stack
            direction={isDesktop ? 'row' : 'column'}
            justifyContent={'space-between'}
            sx={{
              width: { xs: '100%', lg: '100%', xl: '100%' },
              margin: 'auto',
              textAlign: 'center',
              mt: 2,
            }}
          >

            <FutureWalletChart userWallet={userWallet} />

          </Stack>
        </Grid>


      </Grid>
    </Card>

  );
};

export default FutureWalletBalanceAndCharts;