import { Box, Button, CircularProgress, IconButton, Stack, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import Iconify from './Iconify';
import Image from './Image';
import filterIcon from '../assets/filter.svg';
import { debounced } from '../utils';
import { useTranslation } from 'react-i18next';

export default function ComplexToolbar({
  onSearch,
  onPrint,
  onExport,
  isExportLoading = false,
  children = null,
  defaultFilter = '',
  ...other
}) {
  const { t } = useTranslation();

  const [mode, setMode] = useState(defaultFilter || (children ? 'filter' : ''));

  const [searchValue, setSearchValue] = useState('');
  const onSearchValue = useRef(
    debounced((q) => {
      onSearch(q);
    }, 700)
  ).current;

  const onMode = (m) => {
    if (!children && !!onSearch) {
      setMode('search');
    } else {
      setMode(mode === m ? '' : m);
    }
  };

  return (
    <Box className='no-print'>
      <Stack
        direction={{ xs: mode === 'search' ? 'column' : 'row', md: 'row' }}
        justifyContent={'space-between'}
        alignItems={{ xs: mode === 'search' ? 'flex-start' : 'center', md: 'center' }}
        spacing={2}
        sx={{ mt: 3, mb: 2.5, mx: { xs: 0, md: 2 } }}
        {...other}
      >
        <Stack direction={'row'} alignItems={'center'} spacing={3.5}>
          {children && (
            <Button
              variant={mode === 'filter' ? 'contained' : 'text'}
              color={mode === 'filter' ? 'primary' : 'inherit'}
              sx={{ borderRadius: 1.5, minWidth: 40, height: 40, p: 0 }}
              onClick={() => onMode('filter')}
            >
              <Image src={filterIcon} disabledEffect />
            </Button>
          )}

          {onSearch && (
            <Stack direction='row'>
              <Button
                variant={mode === 'search' ? 'contained' : 'text'}
                color={mode === 'search' ? 'primary' : 'inherit'}
                sx={{ borderRadius: mode === 'search' ? '10px 0 0 10px' : '10px', minWidth: 40, height: 40, p: 0 }}
                onClick={() => onMode('search')}
              >
                <Iconify
                  icon='eva:search-fill'
                  sx={{ width: 28, height: 28 }}
                  color={mode === 'search' ? 'grey.0' : 'grey.300'}
                />
              </Button>
              {mode === 'search' && (
                <TextField
                  sx={{ width: 170, '& .MuiInputBase-root': { borderRadius: '0 10px 10px 0', height: 40 } }}
                  onChange={(e) => {
                    onSearchValue(e.target.value);
                    setSearchValue(e.target.value);
                  }}
                  placeholder={t('Search')}
                  value={searchValue}
                />
              )}
            </Stack>
          )}
        </Stack>

        <Stack direction={'row'} alignItems={'center'} spacing={3}>
          {onExport && (
            <>
              {isExportLoading ? (
                <CircularProgress size={20} color='success' sx={{ margin: '12px' }} />
              ) : (
                <IconButton onClick={onExport}>
                  <Iconify icon='tabler:receipt' sx={{ width: 28, height: 28 }} />
                </IconButton>
              )}
            </>
          )}
          {onPrint && (
            <>
              {isExportLoading ? (
                <CircularProgress size={20} color='success' sx={{ margin: '12px' }} />
              ) : (
                <IconButton onClick={() => onExport('PDF')}>
                  <Iconify icon='majesticons:printer-line' sx={{ width: 27, height: 27 }} />
                </IconButton>
              )}
            </>
          )}
        </Stack>
      </Stack>

      {children && mode === 'filter' && children}
    </Box>
  );
}
