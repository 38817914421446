import { useState } from 'react';
import { Stack, Button, InputAdornment, IconButton, Slider, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ModalContainer from '../../../../components/ModalContainer';
import { NumberField } from '../../../../components/NumberField';
import Iconify from '../../../../components/Iconify';
import { fNumber } from '../../../../utils/formatNumber';

export const leverageMarks = [
  {
    value: 1,
    label: '1x',
  },
  {
    value: 25,
    label: '25x',
  },
  {
    value: 50,
    label: '50x',
  },
  {
    value: 75,
    label: '75x',
  },
  {
    value: 100,
    label: '100x',
  },
  {
    value: 125,
    label: '125x',
  },
];

const FuturesLeverageModal = ({ open, onClose, defaultValue = 20, symbol, onConfirm }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(defaultValue);

  return (
    <ModalContainer open={open} onClose={onClose} title={'Adjust Leverage'} width={420}>
      <Stack sx={{ mt: 3, mb: 1 }} spacing={3}>
        <NumberField
          value={value}
          onChange={(e) => {
            const v = e.target.value;
            setValue(v > 125 ? 125 : v);
          }}
          label={t('Leverage')}
          fullWidth
          sx={{ '& input': { textAlign: 'center' } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <IconButton onClick={() => setValue((prev) => (prev - 1 < 1 ? 1 : prev - 1))}>
                  <Iconify icon='eva:minus-outline' />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => setValue((prev) => (prev + 1 > 125 ? 125 : prev + 1))}>
                  <Iconify icon='eva:plus-outline' />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Stack alignItems='center'>
          <Box sx={{ width: '90%' }}>
            <Slider
              fullWidth
              size='small'
              getAriaValueText={(value) => `${value}x`}
              marks={leverageMarks}
              valueLabelDisplay='auto'
              min={1}
              max={125}
              value={value}
              onChange={(e, newValue) => setValue(newValue)}
            />
          </Box>
        </Stack>

        <Typography variant='body3' color='grey.300' sx={{ px: 1, pt: 2 }}>
          {t('Maximum position')}: {fNumber(15000000)} {symbol}
        </Typography>

        <Stack direction='row' alignItems='flex-start' spacing={1} sx={{ px: 0.5 }}>
          <Iconify icon='eva:alert-circle-outline' sx={{ color: 'error.main', width: 35, height: 35 }} />
          <Typography variant='body3' color='error' sx={{ pt: 0.7 }}>
            {t(
              'Selecting higher leverage such as [10x] increases your liquidation risk. Always manage your risk levels.'
            )}
          </Typography>
        </Stack>

        <Button
          fullWidth
          size='large'
          variant='contained'
          sx={{ mt: 2 }}
          onClick={() => {
            onConfirm(value);
            onClose();
          }}
        >
          {t('Confirm')}
        </Button>
      </Stack>
    </ModalContainer>
  );
};

export default FuturesLeverageModal;
