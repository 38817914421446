import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

import ModalContainer from '../../../../components/ModalContainer';
import { FormProvider, RHFNumberField } from '../../../../components/hookForm';
import SymbolIcon from '../../../../components/SymbolIcon';
import useWithdrawVerification from './hooks/useWithdrawVerification';
import useTimer from '../../../../hooks/useTimer';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export default function SecurityVerificationModal({
  open,
  onClose,
  onSuccess,
  symbolSelected,
  networkSelected,
  address,
  amount,
  fee,
}) {
  const { t } = useTranslation();
  const userInfo = useSelector((store) => store.userInfo);

  const { timer, stopTimer, activeTimerState, onRequestAgainClick, reloadTimer } = useTimer({
    emailAddress: null,
    operationType: 'Wallet',
  });

  useEffect(() => {
    if (open) {
      reloadTimer();
    }
  }, [open]);

  const { handleClose, methods, handleSubmit, onSubmit, isSubmitting, showSmsOtp, showSoftOtp } =
    useWithdrawVerification({
      onSuccess,
      onClose,
      stopTimer,
    });


  return (
    <ModalContainer open={open} onClose={handleClose} width={590} title={'Security verification'}>
      <Stack sx={{ backgroundColor: 'grey.600', p: 2, mt: 5, borderRadius: 1, mb: 3 }} spacing={1.5}>
        <Stack direction={'row'}>
          <Typography variant='body3' color={'grey.400'} sx={{ width: 100 }}>
            {t('Amount')}
          </Typography>

          <Stack direction={'row'} spacing={1} alignItems='center'>
            {symbolSelected && <SymbolIcon symbol={symbolSelected} />}

            <Typography variant='body3'>
              {t('Receive {{receive}} {{symbol}}', { symbol: symbolSelected || '', receive: amount || 0 })}{' '}
              {address &&
                t('(Network fee {{fee}} {{symbol}})', {
                  fee: networkSelected?.withdrawFee || 0,
                  symbol: symbolSelected || '',
                })}

              {
                fee?.fee &&
                t('(Fee {{fee}} {{symbol}})', {
                  fee: fee?.fee || 0,
                  symbol: symbolSelected || '',
                })
              }
            </Typography>
          </Stack>
        </Stack>

        {address && (
          <>
            <Stack direction={'row'}>
              <Typography variant='body3' color={'grey.400'} sx={{ width: 100 }}>
                {t('Address')}
              </Typography>
              <Typography variant='body3'>{address || ''}</Typography>
            </Stack>

            <Stack direction={'row'}>
              <Typography variant='body3' color={'grey.400'} sx={{ width: 100 }}>
                {t('Network')}
              </Typography>
              <Typography variant='body3'>{networkSelected?.name || ''}</Typography>
            </Stack>
          </>
        )}

        <Stack direction={'row'}>
          <Typography variant='body3' color={'grey.400'} sx={{ width: 100 }}>
            {t('Source')}
          </Typography>
          <Typography variant='body3'>{t('Main wallet')}</Typography>
        </Stack>
      </Stack>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <RHFNumberField
          name='emailOtp'
          label={'Email Verification Code'}
          variant='standard'
          fullWidth
          sx={{ mb: 1 }}
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position='end'>
          //       <Typography variant='body3' color={'grey.300'}>
          //         {t('Verification code sent')}
          //       </Typography>
          //     </InputAdornment>
          //   ),
          // }}
        />
        <Typography variant='body4' color={'grey.300'} component='p' sx={{ mb: 3 }}>
          {t('Enter the {{number}}-digit code send to {{email}}', { email: userInfo?.email || '', number: 5 })}
        </Typography>

        {showSmsOtp && (
          <>
            <RHFNumberField
              name='smsOtp'
              label={'Phone number Verification Code'}
              variant='standard'
              fullWidth
              sx={{ mb: 1 }}
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position='end'>
              //       <Typography variant='body3' color={'grey.300'}>
              //         {t('Verification code sent')}
              //       </Typography>
              //     </InputAdornment>
              //   ),
              // }}
            />
            <Typography variant='body4' color={'grey.300'} component='p' sx={{ mb: 3 }}>
              {t('Enter the {{number}}-digit code send to {{phone}}', { phone: '', number: 5 })}
            </Typography>
          </>
        )}

        {showSoftOtp && (
          <>
            <RHFNumberField name='softOtp' label={'authenticator code'} variant='standard' fullWidth sx={{ mb: 1 }} />
            <Typography variant='body4' color={'grey.300'} component='p' sx={{ mb: 3 }}>
              {t('Enter the {{number}}-digit code from Google authenticator', { number: 6 })}
            </Typography>{' '}
          </>
        )}

        {/* <Typography variant='body2' color={'#F27F16'} component='p' sx={{ mb: 3 }}>
          {t('security verification unavailable?')}
        </Typography> */}

        <Stack sx={{ mt: 5, mb: 2 }}>
          <Typography variant='body3' sx={{ letterSpacing: 1 }}>
            {timer}
          </Typography>
          <Stack direction='row' alignItems={'center'}>
            <Typography variant='body3_secondary' color='grey.400'>
              {t('Didn’t recieve code?')}
            </Typography>
            <Button
              color='warning'
              sx={{ typography: 'body3_secondary', borderRadius: 1, px: 1 }}
              onClick={onRequestAgainClick}
              size='small'
              disabled={!activeTimerState}
            >
              {t('Request Again')}
            </Button>
          </Stack>
        </Stack>

        <LoadingButton
          variant='contained'
          type='submit'
          size='large'
          fullWidth
          loading={isSubmitting}
          sx={{ typography: 'body3' }}
        >
          {t('Submit')}
        </LoadingButton>
      </FormProvider>
    </ModalContainer>
  );
}
