import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Card, Stack, Collapse } from '@mui/material';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { FormProvider, RHFCheckbox } from '../../../../components/hookForm';
import useVerificationForm from './hooks/useVerificationForm';
import useVerificationSubmit from './hooks/useVerificationSubmit';
import VerificationViaGoogle from './verificationViaGoogle';
import VerificationViaSms from './verificationViaSms';
import VerificationViaEmail from './verificationViaEmail';
import { useEffect } from 'react';

const Verification = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { handleSubmit, isSubmitting, methods, google, sms, email } = useVerificationForm();
  const { onSubmit } = useVerificationSubmit();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Security verification' },
    });
  }, []);

  return (
    <Stack sx={{ padding: { xs: '0 20px 20px', lg: '0 0 97px' } }}>
      <Card sx={{ mt: 2.5, px: { xs: 2, sm: 4 }, pt: { xs: 3, sm: 4 }, pb: { xs: 3, sm: 11 } }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <RHFCheckbox
              name='google'
              label={t('Two-step login via Google')}
              sx={{
                '& .MuiTypography-root': { color: 'grey.100', fontSize: 14 },
                '& .Mui-checked': { color: '#1E68F6 !important' },
              }}
            />

            <Collapse orientation='vertical' in={google}>
              <VerificationViaGoogle isSubmitting={isSubmitting} />
            </Collapse>

            <RHFCheckbox
              name='sms'
              label={t('Two-step login via Sms')}
              sx={{
                '& .MuiTypography-root': { color: 'grey.100', fontSize: 14 },
                '& .Mui-checked': { color: '#1E68F6 !important' },
              }}
            />
            <Collapse orientation='vertical' in={sms}>
              <VerificationViaSms isSubmitting={isSubmitting} />
            </Collapse>
            <RHFCheckbox
              name='email'
              label={t('Two-step login via Email')}
              sx={{
                '& .MuiTypography-root': { color: 'grey.100', fontSize: 14 },
                '& .Mui-checked': { color: '#1E68F6 !important' },
              }}
            />
            <Collapse orientation='vertical' in={email}>
              <VerificationViaEmail isSubmitting={isSubmitting} />
            </Collapse>
          </Stack>
        </FormProvider>
      </Card>
    </Stack>
  );
};

export default Verification;
