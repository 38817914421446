import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, RHFNumberField, RHFTextField } from '../../../../../../components/hookForm';

import useAccountListForm from './hooks/useAccountListForm';
import useAccountListSubmit from './hooks/useAccountListSubmit';

export default function AdminAccountTab({ dollarLimit, rialLimit, userId }) {
  const { t } = useTranslation();

  const { handleSubmit, isSubmitting, methods } = useAccountListForm({ dollarLimit, rialLimit, userId });
  const { onSubmit } = useAccountListSubmit({ userId });

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography variant='h6' fontWeight={500} sx={{ mt: 5, mb: 3 }}>
          {t('List of accounts')}
        </Typography>

        <Stack spacing={2}>
          <Stack direction={'row'} spacing={5}>
            <RHFNumberField
              name='dollarLimitAmount'
              label={t('Dollar limit')}
              variant='standard'
              fullWidth
              thousandSeparator
              sx={{ maxWidth: 300 }}
            />
            <RHFTextField
              disabled
              name='dollarBalance'
              label={t('Dollar balance')}
              variant='standard'
              fullWidth
              sx={{ maxWidth: 300 }}
            />
          </Stack>

          <Stack direction={'row'} spacing={5}>
            <RHFNumberField
              name='rialLimitAmount'
              thousandSeparator
              label={t('Rial limit')}
              variant='standard'
              fullWidth
              sx={{ maxWidth: 300 }}
            />
            <RHFTextField
              disabled
              name='rialBalance'
              label={t('Rial balance')}
              variant='standard'
              fullWidth
              sx={{ maxWidth: 300 }}
            />
          </Stack>
        </Stack>

        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 5 }} sx={{ mt: 5 }}>
          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            loading={isSubmitting}
            sx={{ maxWidth: 300, typography: 'body3', fontWeight: 500 }}
          >
            {t('Save')}
          </LoadingButton>

          {/* <Button
            fullWidth
            size='large'
            variant='outlined'
            color='inherit'
            sx={{ maxWidth: 300, typography: 'body3', fontWeight: 500 }}
          >
            {t('View transactions')}
          </Button> */}
        </Stack>
      </FormProvider>
    </>
  );
}
