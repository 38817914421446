import { useState, useEffect } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { RootSubStyle, TopAssetsCard } from '../style';
import MotionViewport from '../../../components/animate/MotionViewport';
import useTable, { emptyRows } from '../../../hooks/useTable';
import { TableEmptyRows, TableHeadCustom, TableNoData } from '../../../components/table';
import { fPercent } from '../../../utils/formatNumber';
import { navigationUrl } from '../../../navigationUrl';

import Iconify from '../../../components/Iconify';
import { varFade } from '../../../components/animate/variants/fade';
import SmallAreaChart from '../../../components/SmallAreaChart';
import Api from '../../../http/serviceApi';
import SymbolIcon from '../../../components/SymbolIcon';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'symbol', label: 'Assets', align: 'left' },
  { id: 'amount', label: 'Last price', align: 'left' },
  { id: 'change', label: 'Change', align: 'left' },
  { id: 'chart', label: 'Chart', align: 'left' },
  { id: 'action', label: 'Trade', align: 'left' },
];

// ----------------------------------------------------------------------

export default function TopAssets() {
  const { t } = useTranslation();

  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';

  const { page, order, orderBy, rowsPerPage, onSort } = useTable({
    defaultOrderBy: '',
  });

  const [tableData, setTableData] = useState(null);

  const fetchSymbolPriceList = async () => {
    const { currencies } = await Api.getSymbolPriceList();
    setTableData(currencies.slice(0, 8));
  };

  useEffect(() => {
    fetchSymbolPriceList();
  }, []);

  return (
    <RootSubStyle sx={{ pt: { md: 0 } }}>
      <Container component={MotionViewport}>
        <TopAssetsCard variants={varFade().inUp}>
          {tableData === null ? (
            <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: 602 }}>
              <CircularProgress size={30} color='success' />
            </Stack>
          ) : (
            <TableContainer sx={{ position: 'relative' }}>
              <Table size={'small'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  onSort={onSort}
                  noBorder
                />

                <TableBody>
                  {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <AssetsTableRow key={row.symbol} row={row} />
                  ))}

                  <TableEmptyRows height={52} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                  <TableNoData isNotFound={!tableData.length} />
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TopAssetsCard>

        <Link component={RouterLink} to={navigationUrl.panel} sx={{ display: 'block', mb: { xs: 7, md: 0 } }}>
          <Stack direction='row' alignItems={'center'} spacing={2.5} justifyContent={'center'}>
            <Typography variant='subtitle1_secondary'>{t('View more prices')}</Typography>
            <Iconify
              icon={isRTL ? 'eva:arrow-back-outline' : 'eva:arrow-forward-outline'}
              sx={{ width: 22, height: 22 }}
            />
          </Stack>
        </Link>
      </Container>
    </RootSubStyle>
  );
}

const AssetsTableRow = ({ row }) => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const { symbol, name, prices, priceChange } = row;
  const lastPrice = prices?.length ? prices[prices.length - 1] || 0 : 0;

  return (
    <TableRow hover>
      <TableCell>
        <Stack direction='row' spacing={2} alignItems='center' sx={{ py: 2.2 }}>
          <SymbolIcon symbol={symbol} />

          <Stack direction={'row'}>
            <Typography sx={{ width: 65 }} variant='subtitle2'>
              {symbol}
            </Typography>
            <Typography variant='body2' noWrap color='grey.300'>
              {name}
            </Typography>
          </Stack>
        </Stack>
      </TableCell>

      <TableCell>
        <Typography variant='body2'>USD {lastPrice}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant='body2' color={priceChange >= 0 ? 'positive.main' : 'negative.main'}>
          {fPercent(priceChange >= 0 ? priceChange : -1 * priceChange)}
        </Typography>
      </TableCell>

      <TableCell>
        <SmallAreaChart data={prices} isPositive={priceChange >= 0} width={137} height={33} />
      </TableCell>

      <TableCell>
        <Button
          color='primary'
          variant='outlined'
          onClick={() =>
            navigate(navigationUrl.trade, {
              state: {
                passedFromWallet: {
                  // symbol: tradingPair,
                  baseAsset: symbol,
                  quoteAsset: 'USDT',
                },
              },
            })
          }
        >
          <Typography color='grey.0' variant='body2'>
            {t('Buy')}
          </Typography>
        </Button>
      </TableCell>
    </TableRow>
  );
};
