import { useState } from 'react';
import { useTheme, IconButton, Link, Stack, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
// import moment from 'jalali-moment';

import MenuPopover from '../MenuPopover';
import Iconify from '../Iconify';
import { navigationUrl } from '../../navigationUrl';

import Image from '../Image';
import profileTick from '../../assets/profile-tick.svg';
import whiteTick from '../../assets/white-tick.svg';

// ----------------------------------------------------------------------

export default function NotificationPopover({ list = [], isAdmin = true }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen} sx={{ position: 'relative' }}>
        <Iconify icon='clarity:bell-line' sx={{ width: 22, height: '100%' }} color='grey.0' />
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{
            typography: 'caption',
            bgcolor: 'success.main',
            borderRadius: '50%',
            width: 20,
            height: 20,
            position: 'absolute',
            top: 0,
            right: 0,
            color: '#fff',
          }}
        >
          {list.length}
        </Stack>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          width: 419,
          background: theme.palette.grey[800],
          '& .MuiMenuItem-root': {
            typography: 'body2',
          },
        }}
      >
        <Stack sx={{ backgroundColor: 'success.main', pb: 1.5, pt: 2 }} spacing={1}>
          <Typography
            variant='h6'
            fontWeight={500}
            component='p'
            textAlign={'center'}
            color={'common.black'}
            lineHeight={1}
          >
            {list.length} {t('New')}
          </Typography>
          <Typography variant='body3' component='p' textAlign={'center'} color={'common.black'}>
            {t('Your Notifications')}
          </Typography>
        </Stack>

        {list.slice(0, 5).map((item) => (
          <Stack key={item.id} sx={{ borderBottom: '1px solid', borderColor: 'grey.600', px: 2, py: 1.5 }} spacing={1}>
            <Stack direction='row' justifyContent='space-between' alignItems={'center'}>
              <Stack direction={'row'} spacing={1.5} alignItems={'center'} justifyContent={'center'}>
                {
                  item?.title?.toLowerCase() === 'transaction completed' ?

                      <Iconify icon='lets-icons:done-duotone' color='green' sx={{ width: 28, height: 28 }} />
                    :
                    <Image src={profileTick} sx={{ width: 24, height: 24 }} />


                }
                <Typography variant='body2' fontFamily={'Poppins'} color={'success.main'}>
                  {item.title}
                </Typography>
              </Stack>
              {/* <Typography variant='body3' color={'grey.300'}>
                {moment(item.date).fromNow(true)}
              </Typography> */}
            </Stack>
            <Typography variant='body3' color={'grey.300'} sx={{ maxWidth: 371 }} noWrap>
              {item.message}
            </Typography>
          </Stack>
        ))}

        <Box display={'flex'} sx={{ alignItems: 'center', justifyContent: 'center', my: 2 }}>
          <Link
            component={RouterLink}
            variant='body3'
            to={isAdmin ? navigationUrl.admin_notification : navigationUrl.notification}
            sx={{ color: 'success.main', fontWeight: 500, fontFamily: 'Poppins' }}
            onClick={handleClose}
          >
            {t('View All Notifications')}
          </Link>
        </Box>
      </MenuPopover>
    </>
  );
}
