import { Button, MenuItem, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

import ModalContainer from '../../../../../../components/ModalContainer';
import {
  FormProvider,
  RHFNumberField,
  RHFRadioGroup,
  // RHFSelect,
  RHFTextField,
} from '../../../../../../components/hookForm';
import useTransactionSubmit from './hooks/useTransactionSubmit';
import useTransactionForm from './hooks/useTransactionForm';

const CreateFiatModal = ({ open, onClose, onReload, setStatusModal, selectedFiat, userId }) => {
  const { t } = useTranslation();

  const { handleSubmit, isSubmitting, methods, type, amount } = useTransactionForm(selectedFiat);

  let newAmount = selectedFiat?.amount || 0;
  if (type === 'Fiat_Withdraw') {
    newAmount -= +amount.replace(/,/g, '');
  } else {
    newAmount += +amount.replace(/,/g, '');
  }

  const { onSubmit } = useTransactionSubmit({ setStatusModal, onClose, onReload, userId, newAmount });

  return (
    <ModalContainer open={open} onClose={onClose} title={'Add transaction'} width={380}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ my: 3 }}>
          <RHFRadioGroup
            name='type'
            options={[
              { label: t('Fiat Deposit'), value: 'Fiat_Deposit' },
              { label: t('Fiat Withdraw'), value: 'Fiat_Withdraw' },
            ]}
            sx={{ '& .MuiFormControlLabel-root': { mr: 6 } }}
          />
        </Stack>
        <Stack spacing={2}>
          {/* {type === 'deposit' ? ( */}
          <>
            <RHFTextField
              name='fiatType'
              label={t('Fiat type')}
              variant='standard'
              fullWidth
              disabled
              sx={{ maxWidth: 330 }}
            />

            <RHFNumberField
              name='amount'
              label={t('Amount')}
              variant='standard'
              fullWidth
              thousandSeparator
              sx={{ maxWidth: 330 }}
            />

            <Stack direction='row' spacing={2} sx={{ '& .MuiFormLabel-root': { color: 'grey.400' }, pt: 1 }}>
              <TextField
                value={selectedFiat?.amount || 0}
                label={t('Current balance')}
                variant='standard'
                fullWidth
                disabled
              />
              <TextField value={newAmount} label={t('New balance')} variant='standard' fullWidth disabled />
            </Stack>

            <RHFTextField name='caption' label={t('Caption')} variant='standard' fullWidth sx={{ maxWidth: 330 }} />

            {/* <RHFTextField
                name='accountTransaction'
                label={'Account Transaction'}
                variant='standard'
                fullWidth
                sx={{ maxWidth: 330 }}
              />
              <RHFTextField name='caption' label={'Caption'} variant='standard' fullWidth sx={{ maxWidth: 330 }} /> */}
          </>
          {/* ) : type === 'withdraw' ? (
            <>
              <RHFTextField
                name='valueTransaction'
                label={'Value Transaction'}
                variant='standard'
                fullWidth
                sx={{ maxWidth: 330 }}
              />
              <RHFTextField
                name='accountTransaction'
                label={'Account Transaction'}
                variant='standard'
                fullWidth
                sx={{ maxWidth: 330 }}
              />
              <RHFTextField
                name='accountTransaction'
                label={'Account Transaction'}
                variant='standard'
                fullWidth
                sx={{ maxWidth: 330 }}
              />
              <RHFTextField name='caption' label={'Caption'} variant='standard' fullWidth sx={{ maxWidth: 330 }} />
            </>
          ) : (
            ''
          )} */}
        </Stack>
        <Stack direction={'row'} alignItems={'center'} spacing={2} sx={{ mt: 5 }}>
          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            loading={isSubmitting}
            sx={{ maxWidth: 162 }}
          >
            {t('Save')}
          </LoadingButton>

          <Button
            fullWidth
            size='large'
            color='inherit'
            variant='outlined'
            sx={{ typography: 'body3', fontWeight: 500, maxWidth: '50%' }}
            onClick={onClose}
          >
            {t('Cancel')}
          </Button>
        </Stack>
      </FormProvider>
    </ModalContainer>
  );
};

export default CreateFiatModal;
