import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { FormProvider, RHFTextField, RHFRadioGroup } from '../../../../../../components/hookForm';
import { useSnackbar } from 'notistack';
import Api from '../../../../../../http/adminHttp/serviceApiAdmin';

const SendForm = ({ onClose, userId }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // ------------------
  const validators = Yup.object().shape({
    message: Yup.string().required(t('please fill the blank')),
    title: Yup.string().notRequired(),
    type: Yup.string().notRequired(),
  });

  // ------------------
  const defaultValues = {
    message: '',
    title: '',
    type: 'NOTIFICATION',
  };

  // ------------------
  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  // ------------------
  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  // ------------------
  const onSubmit = async ({ message, type, title }) => {
    try {
      const response = await Api.sendNotification({
        message,
        recipient: 'SINGLE_USER',
        title,
        types: [type],
        userId: +userId,
      });

      if (response.status === 'SUCCESS') {
        enqueueSnackbar(t('The message sent successfully'), { variant: 'success' });

        reset();
        onClose();
      }
    } catch (e) {}
  };

  // ------------------
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack sx={{ mt: 3 }} spacing={3}>
        <RHFRadioGroup
          name='type'
          options={[
            { label: t('Notification'), value: 'NOTIFICATION' },
            { label: t('Email'), value: 'EMAIL' },
            { label: t('SMS'), value: 'SMS' },
          ]}
          sx={{ '& .MuiFormControlLabel-root': { mr: 5 }, flexDirection: 'column' }}
        />

        <RHFTextField name='title' label={t('Title')} variant='standard' fullWidth />

        <RHFTextField name='message' label={t('Message')} variant='standard' fullWidth multiline rows={3} />

        <LoadingButton fullWidth size='large' type='submit' variant='contained' loading={isSubmitting}>
          {t('Send')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};

export default SendForm;
