import { TableCell, TableRow, Typography, Box, Stack, IconButton } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import Iconify from '../../../../../../components/Iconify';

export default function AdminTomanTransactionTableRow({ row, index }) {
  const language = useSelector((store) => store.language);
  const {
    accountNumber,
    amount,
    depositType,
    firstName,
    lastName,
    status,
    trackingCode,
    userId,
    stripeTxId,
    fiatType,
    createDate,
    action,
    cardType,
    shetabGateway,
    id
  } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1, typography: 'body2' } }}>
      <TableCell align='center' sx={{ width: 100 }}>
        {index + 1}
      </TableCell>

      <TableCell  align='center'>
        <Typography variant='body2'>{accountNumber || '-'}</Typography>
      </TableCell>

      <TableCell align='center'>{amount}</TableCell>

      <TableCell  align='center'>{action}</TableCell>

      <TableCell>
        <Typography variant='body2'>{firstName+' '+lastName}</Typography>
      </TableCell>

      {/*<TableCell>*/}
      {/*  <Typography variant='body2'>{}</Typography>*/}
      {/*</TableCell>*/}

      <TableCell sx={{ width: 170 }} align='center'>
        <Box
          sx={{
            width: 130,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            p: 0.8,
            bgcolor: status === 'SUCCESS' ? 'rgba(48, 224, 161, 0.2)' : 'rgba(255, 69, 58, 0.2)',
          }}
        >
          <Typography variant='body2' color={status === 'SUCCESS' ? '#30E0A1' : '#FF453A'}>
            {status}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align='center'>{trackingCode || id || '-'}</TableCell>

      <TableCell align='center'>{userId}</TableCell>

      <TableCell align='center'>
        <Typography variant='body2'>{stripeTxId || '-'}</Typography>
      </TableCell>

      <TableCell>{fiatType}</TableCell>

      <TableCell>
        <Typography variant='body2'>
          {moment(createDate).locale(language).format('D MMM YYYY - HH:mm')}
        </Typography>
      </TableCell>

      {/*<TableCell align='center'>*/}
      {/*  <Stack direction={'row'} alignItems={'center'} spacing={1}>*/}
      {/*    <Typography variant='body2'>{cardType}</Typography>*/}
      {/*    <Typography variant='body2'>/</Typography>*/}
      {/*    <Typography variant='body2'>{shetabGateway}</Typography>*/}
      {/*  </Stack>*/}
      {/*</TableCell>*/}

      {/*<TableCell align='center' sx={{ width: 100 }}>*/}
      {/*  <IconButton>*/}
      {/*    <Iconify icon='ph:link-duotone' />*/}
      {/*  </IconButton>*/}
      {/*</TableCell>*/}
    </TableRow>
  );
}
