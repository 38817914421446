import { Box, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';

import Scrollbar from '../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../components/table';
import React, { useEffect, useState } from 'react';
import useTable from '../../../hooks/useTable';
import { useTranslation } from 'react-i18next';

const TABLE_HEAD = [
  { id: 'num', label: 'Num', align: 'center' },
  { id: 'createdDate', label: 'Date', align: 'left' },
  { id: 'pair', label: 'Pair', align: 'left' },
  { id: 'type', label: 'Type', align: 'center' },
  { id: 'side', label: 'Side', align: 'center' },
  { id: 'price', label: 'Price', align: 'left' },
  { id: 'amount', label: 'Amount', align: 'left' },
  { id: 'filed', label: 'Filed', align: 'left' },
  { id: 'total', label: 'Total', align: 'left' },
  { id: 'commission', label: 'Commission', align: 'left' },
  { id: 'state', label: 'State', align: 'left' },
];

export default function ReferralsReportTrade({ list }) {
  const { t } = useTranslation();
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });

  // --------------------
  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  useEffect(()=>{
    setTablePagination({ totalItems: list?.length });
  },[list])

  const viewForTable = list?.slice(page * 10 , (page * 10) + 10)

  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
        <Table size='small'>
          <TableHeadCustom headLabel={TABLE_HEAD} rowCount={list?.length || 0} />
          <TableBody>
            {list === null ? (
              <TableLoading isLoading />
            ) : (
              <>
                {viewForTable?.map((row, index) => (
                  <ReportTableRow key={index} row={row} index={index} />
                ))}

                <TableNoData isNotFound={!list?.length} />
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[10]}
          component='div'
          count={tablePagination.totalItems}
          rowsPerPage={10}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage={t('Rows per page')}
          labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
        />
      </Box>
    </Scrollbar>
  );
}

const ReportTableRow = ({ row, index }) => {
  const language = useSelector((store) => store.language);

  const { amount, commission, createdDate, executed, price, side, state, symbol, total, type } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1, typography: 'body2' } }}>
      <TableCell align='center'>{index + 1}</TableCell>

      <TableCell>{moment(createdDate).locale(language).format('YYYY-MM-DD HH:mm')}</TableCell>

      <TableCell>{symbol}</TableCell>

      <TableCell align='center'>{type}</TableCell>

      <TableCell align='center'>
        <Typography variant='body2' color={side === 'BUY' ? 'positive.main' : 'negative.main'}>
          {side}
        </Typography>
      </TableCell>

      <TableCell>{price}</TableCell>

      <TableCell>{amount}</TableCell>

      <TableCell>{executed}</TableCell>

      <TableCell>{total}</TableCell>

      <TableCell>{commission}</TableCell>

      <TableCell>{state}</TableCell>
    </TableRow>
  );
};
