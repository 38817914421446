import { Box, Button, Stack, styled, Typography, useTheme } from '@mui/material';
import moment from 'jalali-moment';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { BaseOptionChart } from '../../../components/chart';
import Scrollbar from '../../../components/Scrollbar';
import mergeDeep from '../../../utils/merge';

const periods = ['1 Month', '1 Year'];

export default function ReferralsChart({ info }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const language = useSelector((store) => store.language);

  // -------------------------
  const [periodSelected, setPeriodSelected] = useState(0);

  // -------------------------
  const chartOptions = mergeDeep(BaseOptionChart(), {
    stroke: {
      curve: 'smooth',
      width: 1.5,
    },
    legend: {
      show: false,
    },
    colors: ['#BD47FB', '#30E0A1'],
    chart: { animations: { enabled: true }, foreColor: theme.palette.grey[400] },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 0.5,
        inverseColors: true,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 70, 100],
      },
    },
    xaxis: {
      categories: [...Array(info?.incomes?.length ? info?.incomes?.length : 0)].map((item, index) => index)
        // new Array(12)
        // .fill(1)
        // .map((item, index) => moment().add(index, 'months').locale(language).format('MMM')),
    },
    yaxis: {
      labels: {
        formatter: (value) => `${value} $`,
      },
    },
    grid: {
      show: true,
      strokeDashArray: 4,
      borderColor: 'info.darkest',
    },
    tooltip: {
      x: { show: false },
      y: {
        formatter: (value) => `${value} $`,
        title: {
          // formatter: (label) => label,
          formatter: () => '',
        },
      },
      marker: { show: false },
    },
  });

  // -------------------------



  const cumulativeIncomeData = () =>{
    if(info?.incomes?.length > 0){
      let a =[]
      let b = 0
      for (let i in info?.incomes){
        b =  b + info?.incomes[i]
        a.push(b)
      }
      return a
    }else {
      return []
    }
  }

  return (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} spacing={2}>
        <Typography variant='h6' sx={{ fontWeight: 500 }}>
          {t('Your income chart')}
        </Typography>

        {/* <Stack direction={'row'} spacing={1} alignItems={'center'}>
          {periods.map((period, index) => (
            <PeriodButton
              size='small'
              color='inherit'
              selected={periodSelected === index}
              onClick={() => setPeriodSelected(index)}
              key={index}
            >
              <Typography variant='body4' color='grey.400' sx={{ fontWeight: 900 }}>
                {t(period)}
              </Typography>
            </PeriodButton>
          ))}
        </Stack> */}

        <Stack direction={'row'} alignItems={'center'} spacing={6}>
          {/* <Stack direction={'row'} justifyContent={'space-between'}>
            <BadgeIcon bgColor='#BD47FB' direction='right' />
            <Typography variant='subtitle4' color='#BD47FB'>
              {t('E-Money')}
            </Typography>
          </Stack>

          <Stack direction={'row'} justifyContent={'space-between'}>
            <BadgeIcon bgColor='#30E0A1' direction='right' />
            <Typography variant='subtitle4' color='#30E0A1'>
              {t('Symbols')}
            </Typography>
          </Stack> */}

          <Stack direction={'row'} justifyContent={'space-between'}>
            <BadgeIcon bgColor='#30E0A1' direction='right' />
            <Typography variant='subtitle4' color='#30E0A1'>
              {t('Income')}
            </Typography>
          </Stack>

          <Stack direction={'row'} justifyContent={'space-between'}>
            <BadgeIcon bgColor='#BD47FB' direction='right' />
            <Typography variant='subtitle4' color='#BD47FB'>
              {t('Income (Cumulative)')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Scrollbar>
        <Box sx={{ width: { xs: 600, md: '100%' }, pb: 2 }}>
          <ReactApexChart
            series={[
              // {
              //   name: 'E-Money',
              //   type: 'area',
              //   data: [10, 26, 21, 20, 20, 15, 18, 14, 28, 35, 30, 32],
              // },
              {
                name: 'Income (Cumulative)',
                type: 'area',
                data:cumulativeIncomeData() || [],
              },
              {
                name: 'Income',
                type: 'area',
                data: info?.incomes || [],
              },

              // {
              //   name: 'Referrals',
              //   type: 'area',
              //   data: info?.referrals || [],
              // },
              // {
              //   name: 'Trades',
              //   type: 'area',
              //   data: info?.trades || [],
              // },

            ]}
            options={chartOptions}
            height={330}
          />
        </Box>
      </Scrollbar>
    </>
  );
}

const BadgeIcon = styled(Box)(({ bgColor, direction = 'right', theme }) => ({
  width: 20,
  height: 20,
  borderRadius: direction === 'right' ? theme.spacing(10, 10, 0, 10) : theme.spacing(10, 10, 10, 0),
  backgroundColor: bgColor,
  ...(direction === 'left' && { marginLeft: 8 }),
  ...(direction === 'right' && { marginRight: 8 }),
}));

const PeriodButton = styled(Button)(({ selected, theme }) => ({
  width: 70,
  height: 30,
  borderRadius: 27,
  ...(selected && { backgroundColor: theme.palette.background.default, '& span': { color: theme.palette.grey[0] } }),
}));
