import { useEffect, useRef, useState } from 'react';
import Api from '../../../../http/serviceApi';

export default function useConvertAccept({ prices, fromSymbol, toSymbol, onSuccess, onBack }) {
  const intervalRef = useRef(null);
  const timerRef = useRef(15);
  const [timer, setTimer] = useState(15);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // -------------------------
  const onAccept = async () => {
    clearInterval(intervalRef.current);
    setIsSubmitting(true);

    try {
      const data = await Api.convertAccept(toSymbol, fromSymbol, prices.fromAmount);
      if (data.status === 'SUCCESS') {
        setIsSubmitting(false);
        onSuccess({ ...prices, toSymbol, fromSymbol, message: data.message, orderId: data.orderId });
        // onSuccess({ ...prices, fromSymbol, toSymbol, message: 'aaa', orderId: 111 });
      }
    } catch (e) {
      setIsSubmitting(false);
      onBack();
    }
  };

  // -------------------------
  useEffect(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      timerRef.current--;
      setTimer(timerRef.current);

      if (timerRef.current === 0) {
        clearInterval(intervalRef.current);

        setTimeout(() => {
          onBack();
        }, 2000);
      }
    }, 1000);

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  return {
    onAccept,
    isSubmitting,
    timer,
  };
}
