import PropTypes from 'prop-types';
import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MUIThemeProvider, StyledEngineProvider } from '@mui/material/styles';
//
import palette from './palette';
import typography, { FONT_RTL } from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const language = useSelector((store) => store.language);
  const themeDirection = useSelector((store) => store.themeDirection);
  const themeMode = useSelector((store) => store.themeMode);

  const themeOptions = useMemo(
    () => ({
      palette: palette[themeMode || 'dark'],
      typography: typography(language === 'fa' ? FONT_RTL : null),
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: shadows[themeMode || 'dark'],
      customShadows: customShadows[themeMode || 'dark'],
    }),
    [themeDirection, themeMode]
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
