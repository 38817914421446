import { useEffect } from 'react';
import { useState } from 'react';

import Api from '../http/serviceApi';
import Image from './Image';

export default function SymbolIcon({ symbol, width = 24, height = 24, ...other }) {
  const [logo, setLogo] = useState('');

  useEffect(() => {
    if (symbol) {
      getSymbolLogo();
    }
  }, [symbol]);

  const getSymbolLogo = async () => {
    const response = await Api.getSymbolLogo(symbol);
    if (response) {
      setLogo(response);
    }
  };

  return (
    <Image
      sx={{ flexShrink: 0 }}
      src={`data:image/jpeg;base64,${logo}`}
      style={{ width, height, borderRadius: '100%', backgroundColor: '#fff' }}
      {...other}
    />
  );
}
