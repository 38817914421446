import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { Box, Stack, Card, Typography, FormControl, RadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { v4 as uuidv4 } from 'uuid';

import { FormProvider, RHFSwitch } from '../../../../components/hookForm';

import GateWayRow from './gateWayRow';
import useDedicatedForm from './hooks/useDedicatedForm';
import useDedicatedSubmit from './hooks/useDedicatedSubmit';
import imgFastPay from '../../../../assets/gateways/fastPay.png';
import imgVandar from '../../../../assets/gateways/vandar.png';
import imgVisa from '../../../../assets/gateways/visa.png';
import imgZarinpal from '../../../../assets/gateways/zarinpal.png';
import imgZibal from '../../../../assets/gateways/zibal.png';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import { PageTitle } from '../../../../components/PageTitle';

const DedicatedGateway = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [gateways, setGateways] = useState([]);
  const [iranianPaymentStatus, setIranianPaymentStatus] = useState(false);

  const { methods, handleSubmit, isSubmitting } = useDedicatedForm(iranianPaymentStatus);
  const { onSubmit } = useDedicatedSubmit(gateways);

  const handleChangeInput = ({ target }, gateWay) => {
    const index = gateways.findIndex((c) => c.id === gateWay.id);

    const copyArray = [...gateways];
    const changedObject = copyArray[index];
    changedObject[target.name] = target.value;

    copyArray[index] = changedObject;
    setGateways(copyArray);
  };

  const handleChangeStatus = (gateWay) => {
    const index = gateways.findIndex((c) => c.id === gateWay.id);

    const copyArray = [...gateways];
    const changedObject = copyArray[index];
    changedObject.status = !changedObject.status;

    copyArray[index] = changedObject;
    setGateways(copyArray);
  };

  const fetchDedicatedGatewaysSettings = async () => {
    const response = await Api.getDedicatedGatewaySettings();
    if (response.status === 'SUCCESS') {
      const dedicatedGatewaySettingWithSymbols = response.dedicatedGatewaySettingInfoList.map((data) => ({
        ...data,
        id: uuidv4(),
        symbol:
          data.name === 'FastPay'
            ? imgFastPay
            : data.name === 'VISA'
            ? imgVisa
            : data.name === 'Zarinpal'
            ? imgZarinpal
            : data.name === 'zibal'
            ? imgZibal
            : data.name === 'vandar'
            ? imgVandar
            : '',
      }));
      setGateways(dedicatedGatewaySettingWithSymbols);
      setIranianPaymentStatus(response.iranianPayment);
    }
  };

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Dedicated Gateway settings' },
    });

    fetchDedicatedGatewaysSettings();
  }, []);

  return (
    <Stack sx={{ padding: { xs: '0 25px', lg: '0' } }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ mt: 3.5, px: { xs: 2, md: '40px' }, py: { xs: 3, md: '40px' } }}>
          <PageTitle
            title={'Dedicated Gateway settings'}
            subTitle={'You can not disable a port that is in the default state.'}
          />

          <Typography variant='body3' color='grey.100' sx={{ pt: 3, display: 'block' }}>
            {t('Iran GATEWAY')}
          </Typography>

          <Box sx={{ mt: 3 }}>
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup aria-labelledby='demo-radio-buttons-group-label' name='gateway'>
                {gateways
                  .filter((g) => ['Zarinpal', 'zibal', 'vandar'].indexOf(g.name) !== -1)
                  ?.map((gateWay, index) => (
                    <GateWayRow
                      key={index || gateWay.id}
                      data={gateWay}
                      handleChangeInput={handleChangeInput}
                      handleChangeStatus={handleChangeStatus}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </Box>

          <Typography variant='body3' color='grey.100' sx={{ pt: 3, display: 'block' }}>
            {t('International GATEWAY')}
          </Typography>
          <Box sx={{ mt: 3 }}>
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup aria-labelledby='demo-radio-buttons-group-label' name='gateway'>
                {gateways
                  .filter((g) => ['FastPay', 'VISA'].indexOf(g.name) !== -1)
                  ?.map((gateWay, index) => (
                    <GateWayRow
                      key={index || gateWay.id}
                      data={gateWay}
                      handleChangeInput={handleChangeInput}
                      handleChangeStatus={handleChangeStatus}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Card>

        <Card sx={{ mt: 2.5, p: { xs: 2, md: '20px 40px' } }}>
          <Stack direction='row' alignItems='center' spacing={1}>
            <RHFSwitch name='iranianPayment' />
            <Typography variant='body3'>{t('Iranian payment gateways Appearance')}</Typography>
          </Stack>
        </Card>

        {/* <Card sx={{ mt: 2.5, p: 5 }}>
          <Stack spacing={2.5} width={{ xs: '100%', md: '45%' }} sx={{ mb: 2.5 }}>
            <RHFTextField name='min_wallet' label={'Minimum wallet withdrawal amount'} variant='standard' fullWidth />
            <RHFTextField name='min_currency' label={'Minimum amount of currency sales'} variant='standard' fullWidth />

            <Stack direction='row' alignItems='center' spacing={1}>
              <RHFSwitch name='smsConfirm' />
              <Typography variant='body3'>{t('SMS confirmation for withdrawal and purchase')}</Typography>
            </Stack>
          </Stack>
          <Typography variant='body2' color={'grey.400'}>
            {t('Contract text')}
          </Typography>
          <Box width={{ xs: '100%', md: '65%' }} height={134} overflow='auto'>
            <Typography variant='body2'>
              {t(
                'This is the agreement between Iranianex.com and the user who uses the various services of the site. All users of Iranianex.com should read these rules completely and then become a member of the site if they accept them. Iranianex.com can update the terms of this agreement every month according to the changes in the laws. Registering on this site, using its services such as buying and selling, etc., means that you have read and accepted the terms of this contract. The user accepts that he is the legal owner.This is the agreement between Iranianex.com and the user who uses the various services of the site. '
              )}
            </Typography>
          </Box>
        </Card> */}

        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ mt: 2.5, maxWidth: { xs: 360, sm: 283 } }}
        >
          {t('Save')}
        </LoadingButton>
      </FormProvider>
    </Stack>
  );
};

export default DedicatedGateway;
