import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function useAccountListForm({ dollarLimit, rialLimit }) {
  const { t } = useTranslation();

  const validators = Yup.object().shape({
    dollarLimitAmount: Yup.string().required(t('Please input dollar limit amount!')),
    rialLimitAmount: Yup.string().required(t('Please input rial limit amount!')),
  });

  const defaultValues = {
    dollarLimitAmount: dollarLimit || 0,
    rialLimitAmount: rialLimit || 0,
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  return {
    handleSubmit,
    isSubmitting,
    methods,
  };
}
