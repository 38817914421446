// ----------------------------------------------------------------------

import typography from '../typography';

export default function Input(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.grey[300] },
          },
        },
        input: {
          color: theme.palette.grey[300],
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.grey[300],
          },

          '&:-webkit-autofill': {
            WebkitTextFillColor: theme.palette.mode === 'dark' ? '#a5adcf' : '#000',
            WebkitBoxShadow: `0 0 0 30px ${theme.palette.mode === 'dark' ? '#141518' : '#fff'} inset !important`,

            '&:hover': {
              WebkitTextFillColor: theme.palette.mode === 'dark' ? '#a5adcf' : '#000',
              WebkitBoxShadow: `0 0 0 30px ${theme.palette.mode === 'dark' ? '#141518' : '#fff'} inset !important`,
            },
            '&:focus': {
              WebkitTextFillColor: theme.palette.mode === 'dark' ? '#a5adcf' : '#000',
              WebkitBoxShadow: `0 0 0 30px ${theme.palette.mode === 'dark' ? '#141518' : '#fff'} inset !important`,
            },
            '&:active': {
              WebkitTextFillColor: theme.palette.mode === 'dark' ? '#a5adcf' : '#000',
              WebkitBoxShadow: `0 0 0 30px ${theme.palette.mode === 'dark' ? '#141518' : '#fff'} inset !important`,
            },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          ...theme.typography.body3,
          '&.Mui-disabled': {
            '& input': {
              WebkitTextFillColor: theme.palette.grey[500],
            },
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500_12],
          '&:hover': {
            backgroundColor: theme.palette.grey[500_16],
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.action.focus,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[500_32],
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.disabledBackground,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[500],
          ...typography.body3,
        },
        asterisk: {
          color: '#000',
        },
      },
    },
  };
}
