import React from 'react';
import { Form, Button, Row, Col, InputNumber } from 'antd';
import classes from '../verify/verifyForm.module.scss';
import '../verify/verifyFormAntStyles.scss';
import Api from '../../../http/serviceApi';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SET_USERINFO } from '../../../store/actionTypes';
import { setToken } from '../../../http/tokenHandler';
import { navigationUrl } from '../../../navigationUrl';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';

const VerifySoftOtpForm = (props) => {
  const [digit1, setDigit1] = React.useState('');
  const [digit2, setDigit2] = React.useState('');
  const [digit3, setDigit3] = React.useState('');
  const [digit4, setDigit4] = React.useState('');
  const [digit5, setDigit5] = React.useState('');
  const [digit6, setDigit6] = React.useState('');
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const focusTo = (elementName) => {
    let element = document.getElementById(elementName);
    element.focus();
  };

  React.useEffect(() => {
    focusTo('digit1');
  }, digit1 === null);

  const onDigit1Change = (value) => {
    setDigit1(value);

    if (value != null) {
      focusTo('digit2');
    }
  };
  const onDigit2Change = (value) => {
    setDigit2(value);

    if (value != null) {
      focusTo('digit3');
    } else {
      focusTo('digit1');
    }
  };
  const onDigit3Change = (value) => {
    setDigit3(value);

    if (value != null) {
      focusTo('digit4');
    } else {
      focusTo('digit2');
    }
  };
  const onDigit4Change = (value) => {
    setDigit4(value);

    if (value != null) {
      focusTo('digit5');
    } else {
      focusTo('digit3');
    }
  };
  const onDigit5Change = (value) => {
    setDigit5(value);

    if (value != null) {
      focusTo('digit6');
    } else {
      focusTo('digit4');
    }
  };
  const onDigit6Change = (value) => {
    setDigit6(value);

    if (value != null) {
    } else {
      focusTo('digit5');
    }
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        let otpArray = [values.digit1, values.digit2, values.digit3, values.digit4, values.digit5, values.digit6];
        let strOtp = '';
        for (var i = 0; i < otpArray.length; i++) {
          strOtp += otpArray[i];
        }

        const data = {
          emailAddress: location?.state?.emailAddress,
          otp: strOtp,
        };
        loginTwoFaVerify(data);
      })
      .catch((errorInfo) => {});
  };

  const loginTwoFaVerify = async (data) => {
    const response = await Api.loginTwoFaVerify(data);
    console.log('loginTwoFaVerify: ', response);
    if (response?.status === 'SUCCESS') {
      setToken(response.token, 'token');
      dispatch({
        type: SET_USERINFO,
        payload: {
          userInfo: response.userInfo,
        },
      });
      navigate(navigationUrl.panel);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.formTitleDiv}>
        <span className={classes.formTitleBig}>Verify Soft OTP</span>
        <span className={classes.formTitleSmall}>Enter your google authentication code.</span>
      </div>

      <Form
        layout='vertical'
        form={form}
        name='verify'
        initialValues={{
          remember: false,
        }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <Row justify='space-between' gutter={[10, 0]}>
          <Col span={4}>
            <Form.Item name='digit1'>
              <InputNumber
                id='digit1'
                size='large'
                min={0}
                max={9}
                value={digit1}
                maxLength={1}
                onChange={onDigit1Change}
                className={digit1 ? classes.textboxFilled : classes.textbox}
                autoComplete='off'
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='digit2'>
              <InputNumber
                id='digit2'
                size='large'
                min={0}
                max={9}
                value={digit2}
                maxLength={1}
                onChange={onDigit2Change}
                className={digit2 ? classes.textboxFilled : classes.textbox}
                autoComplete='off'
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='digit3'>
              <InputNumber
                id='digit3'
                size='large'
                min={0}
                max={9}
                value={digit3}
                maxLength={1}
                onChange={onDigit3Change}
                className={digit3 ? classes.textboxFilled : classes.textbox}
                autoComplete='off'
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='digit4'>
              <InputNumber
                id='digit4'
                size='large'
                min={0}
                max={9}
                value={digit4}
                maxLength={1}
                onChange={onDigit4Change}
                className={digit4 ? classes.textboxFilled : classes.textbox}
                autoComplete='off'
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='digit5'>
              <InputNumber
                id='digit5'
                size='large'
                min={0}
                max={9}
                value={digit5}
                maxLength={1}
                onChange={onDigit5Change}
                className={digit5 ? classes.textboxFilled : classes.textbox}
                autoComplete='off'
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='digit6'>
              <InputNumber
                id='digit6'
                size='large'
                min={0}
                max={9}
                value={digit6}
                maxLength={1}
                onChange={onDigit6Change}
                className={digit6 ? classes.textboxFilled : classes.textbox}
                autoComplete='off'
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type='primary' htmlType='submit' block shape='round'>
            verify
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default VerifySoftOtpForm;
