import React, { useEffect, useState } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import Api from '../../../../../../http/adminHttp/serviceApiAdmin';
import { prettyUppercases } from '../../../../../../utils';
import Scrollbar from '../../../../../../components/Scrollbar';

const User = ({ userId, open }) => {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  // ----------------
  const [list, setList] = useState([]);

  // ----------------
  const getList = async () => {
    try {
      // userId
      const response = await Api.getNotifications(0, 100000000);
      if (response) {
        setList(response.notificationInfos);
      }
    } catch (e) {
      setList([]);
    }
  };

  // ----------------------------------------------
  useEffect(() => {
    if (open) {
      getList();
    }
  }, [open]);

  // ----------------
  return (
    <Scrollbar height={'400px'}>
      <Stack spacing={1} sx={{ px: 1.5 }}>
        {list.map((item) => (
          <>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
                {t('Title')}
              </Typography>
              <Typography variant='body3' color={'grey.100'} noWrap sx={{ width: 200, textAlign: 'right' }}>
                {item.title}
              </Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
                {t('Message')}
              </Typography>
              <Typography variant='body3' color={'grey.100'} noWrap sx={{ width: 200, textAlign: 'right' }}>
                {item.message}
              </Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
                {t('Date')}
              </Typography>
              <Typography variant='body3' color={'grey.100'}>
                {moment(item.createdDate).locale(language).format('YYYY/MM/DD')}
              </Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography variant='body3' color={'grey.400'} sx={{ width: 80 }}>
                {t('By')}
              </Typography>
              <Typography variant='body3' color={'grey.100'}>
                {prettyUppercases(item.types?.[0])}
              </Typography>
            </Stack>

            <Divider />
          </>
        ))}
      </Stack>
    </Scrollbar>
  );
};

export default User;
