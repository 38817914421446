import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

import { setToken } from '../../../http/tokenHandler';
import { SET_USERINFO } from '../../../store/actionTypes';
import { navigationUrl } from '../../../navigationUrl';
import AuthLayout from '../../../components/authLayout/authLayout';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import LoginForm from '../../../components/login/loginForm';
import LoginOTP from '../../../components/login/loginOTP';
import useQuery from '../../../hooks/useQuery';
import LoadingScreen from '../../../components/LoadingScreen';
import Api from '../../../http/serviceApi';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const query = useQuery();
  const token = query.get('token');

  const [twoFaRequired, setTwoFaRequired] = useState(null);

  const onSuccess = (response, emailAddress) => {
    if (response.userType !== 'NORMAL') {
      return enqueueSnackbar(t('You are not a normal user!'), { variant: 'error' });
    }

    if (response.twoFaRequired) {
      const { smsAuthEnabled, emailAuthEnabled, softOtpAuthEnabled ,mobileNumber} = response.userInfo;
      setTwoFaRequired({ emailAddress, smsAuthEnabled, emailAuthEnabled, softOtpAuthEnabled ,mobileNumber});
    } else {
      setToken(response.token);
      dispatch({
        type: SET_USERINFO,
        payload: { userInfo: response.userInfo },
      });
      navigate(navigationUrl.panel);
    }
  };

  const getUserInfo = async () => {
    try {
      const response = await Api.getUserInfo();
      dispatch({
        type: SET_USERINFO,
        payload: { userInfo: response },
      });
      navigate(navigationUrl.panel);
    } catch (e) {
      setLoading(false);
    }
  };

  // social logins
  useEffect(() => {
    if (token) {
      setToken(token);
      getUserInfo();
    } else {
      setLoading(false);
    }
  }, [token]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <AuthLayout>
      {twoFaRequired ? <LoginOTP data={twoFaRequired} onSuccess={onSuccess} /> : <LoginForm onSuccess={onSuccess} />}
    </AuthLayout>
  );
};

export default Login;
