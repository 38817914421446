import { Card, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import { BottomShadowList } from './style';
import { fFloat, fPercent } from '../../../utils/formatNumber';

import SmallAreaChart from '../../../components/SmallAreaChart';
import SymbolIcon from '../../../components/SymbolIcon';

const TopAsset = ({ list }) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ pr: 1, pb: 0, position: 'relative' }}>
      <Typography variant='h6_secondary'>{t('Top Assets')}</Typography>

      <Scrollbar height={'270px'} sx={{ mt: 3, pr: 2, pb: 6 }}>
        {/* {loading ? (
          <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: 130 }}>
            <CircularProgress size={30} color='success' />
          </Stack>
        ) : ( */}
        <Stack spacing={1.8}>
          {list.map((item, index) => (
            <Stack key={index} direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
              <Stack>
                <Stack direction={'row'} spacing={1.2} alignItems={'center'}>
                  <SymbolIcon symbol={item.symbol} width={20} height={20} />
                  <Typography variant='subtitle2'>{fFloat(item.prices[item.prices.length - 1])} USD</Typography>
                </Stack>
                <Typography variant='body2' color='grey.400' sx={{ ml: 3.5 }}>
                  {item.name}
                </Typography>
              </Stack>
              <Stack alignItems={'flex-end'}>
                <SmallAreaChart data={item.prices} isPositive={item.changePercent >= 0} width={86} height={24} />

                <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
                  <Iconify
                    icon={item.changePercent >= 0 ? 'ph:arrow-up-fill' : 'ph:arrow-down-fill'}
                    color={item.changePercent >= 0 ? 'positive.main' : 'negative.main'}
                    height={12}
                  />
                  <Typography variant='body3' color={item.changePercent >= 0 ? 'positive.main' : 'negative.main'}>
                    {fPercent(item.changePercent >= 0 ? item.changePercent : -1 * item.changePercent)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
        {/* )} */}
      </Scrollbar>

      <BottomShadowList />
    </Card>
  );
};

export default TopAsset;
