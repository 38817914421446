import { Fragment } from 'react';
import { Box, Card, Divider } from '@mui/material';
import { Stack } from '@mui/system';
import moment from 'jalali-moment';

import Scrollbar from '../../../../components/Scrollbar';
import ViewTicketMessageCard from './viewTicketMessageCard';
import { useSelector } from 'react-redux';

export default function ViewTicketMessages({ messages = {}, isAdmin , userId}) {
  const userInfo = useSelector((store) => store.userInfo);
  const adminUserInfo = useSelector((store) => store.adminUserInfo);
  const language = useSelector((store) => store.language);

  let user = userInfo;
  if (isAdmin) {
    user = adminUserInfo;
  }

  return (
    <Card sx={{ px: { xs: 2, md: 5 }, py: 2 }}>
      <Scrollbar height='470px' id='ticket-messages'>
        <Stack sx={{ mr: { xs: 0, md: 3 } }}>
          {Object.entries(messages).map(([date, items]) => (
            <Fragment key={date}>
              <Divider sx={{ my: 2 }}>{moment(date).locale(language).format('ddd MM/DD')}</Divider>
             
                {items.map((item, index) =>{

                  return(
                  <ViewTicketMessageCard
                    isSelf={ isAdmin ? item.userId !== userId : item.userId !== user?.userId}
                    message={item.message}
                    messageImage={item.messageImage}
                    createDate={item.createDate}
                    key={index}
                  />
                )})}
              
            </Fragment>
          ))}
        </Stack>
      </Scrollbar>
    </Card>
  );
}
