import { Button, Col, Form, Radio, Row, Space } from 'antd';
import React, { useState } from 'react';
import { InputCustom } from '../../../../../components/InputCustom/inputCustom';
import '../btnBlockStyle.scss';
import classes from './advancedForm.module.scss';
import iconEmptyPic from '../../../../../assets/gallery.svg';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTranslation } from 'react-i18next';

const AdvancedForm = (props) => {
  const { t } = useTranslation();
  const [document, setDocument] = useState({});
  const [approvement, setApprovenent] = useState('');
  const [form] = Form.useForm();

  React.useEffect(() => {
    debugger;
    form.setFieldsValue({
      country: props.formData?.userInfo?.country,
      streetAddress: props.formData?.userInfo?.address,
      postalCode: props.formData?.userInfo?.postalCode,
      city: props.formData?.userInfo?.cityName,
    });
    setDocument(props.formData?.documents?.find((item) => item.type === 'BILL'));
  });

  const onFinish = () => {};

  const colPropsFormItem = {
    xs: 24,
    sm: 12,
    md: 10,
    lg: 8,
    xl: 6,
  };

  const onApprovementChange = (value) => {
    setApprovenent(value);
  };

  const onSaveClick = () => {
    props.onSaveClick(approvement);
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <Form
        form={form}
        layout='vertical'
        name='advancedForm'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <Row gutter={[20, 0]}>
          <Col {...colPropsFormItem}>
            <Form.Item name='country'>
              <InputCustom label={t('Country')} placeholder='Country' />
            </Form.Item>
          </Col>
          <Col {...colPropsFormItem}>
            <Form.Item name='streetAddress'>
              <InputCustom label={t('Street Address')} placeholder='Street Address' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 0]}>
          <Col {...colPropsFormItem}>
            <Form.Item name='postalCode'>
              <InputCustom label={t('Postal Code')} placeholder='Postal Code' />
            </Form.Item>
          </Col>
          <Col {...colPropsFormItem}>
            <Form.Item name='city'>
              <InputCustom label={t('City')} placeholder='City' />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <div className={classes.picDiv}>
            {document?.data ? (
              <img
                style={{ width: '100%', height: '100%' }}
                src={document?.data ? `data:image/jpeg;base64,${document?.data}` : null}
              />
            ) : (
              <img src={iconEmptyPic} style={{ position: 'absolute', marginLeft: '75px', marginTop: '75px' }} />
            )}
          </div>
        </Row>
        <Radio.Group
          disabled={props.formData?.userInfo?.authenticationLevel === 'ADVANCED_REQUESTED' ? false : true}
          onChange={onApprovementChange}
        >
          <Space direction='vertical'>
            <Radio value='Approve'>{t('Approve')}</Radio>
            <Radio value='Reject'>{t('Reject')}</Radio>
          </Space>
        </Radio.Group>
        <Row gutter={[10, 0]} style={{ marginTop: '20px' }}>
          <Col>
            <Button type='primary' shape='round' onClick={onSaveClick} loading={props.saveLoading}>
              {t('Save')}
            </Button>
          </Col>
          <Col>
            <Button shape='round'>{t('Send Notifiaction')}</Button>
          </Col>
          <Col>
            <Button
              type='primary'
              shape='round'
              className='btnBlock'
              onClick={props.onBlockClick}
              loading={props.blockLoading}
            >
              {t('Block')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AdvancedForm;
