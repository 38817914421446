import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

export const sectionList = [
  { label: 'Finance', value: 'FINANCE' },
  { label: 'Technical', value: 'TECHNICAL' },
  { label: 'KYC', value: 'KYC' },
  { label: 'OTC', value: 'OTC' },
  { label: 'Trade', value: 'TRADE' },
  { label: 'Futures', value: 'FUTURES' },
  { label: 'E-Money', value: 'E_MONEY' },
  { label: 'P2P', value: 'P2P' },
  { label: 'Convert', value: 'CONVERT' },
  { label: 'other', value: 'OTHER' },
];

export default function useTicketForm() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // -------------
  const validators = Yup.object().shape({
    name: Yup.string().required(t('Please input name!')),
    image: Yup.mixed().test('file', 'invalid file', (value) => {
      if (value && 5 <= value.size / 1024 / 1024) {
        enqueueSnackbar(t('The file must be smaller than 5MB!'), { variant: 'error' });

        return false;
      }

      return true;
    }),
    section: Yup.string().required(t('Please input section!')),
    description: Yup.string().required(t('Please input description!')),
    transactionId: Yup.string().notRequired(),
  });

  // -------------
  const defaultValues = {
    name: '',
    section: '',
    description: '',
    transactionId: '',
    image: '',
  };

  // -------------
  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  // -------------
  const {
    handleSubmit,
    formState: { isSubmitting },
    watch
  } = methods;

  // -------------
  return {
    handleSubmit,
    isSubmitting,
    methods,
    watch
  };
}
