import { TableRow, TableCell, Typography } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

export default function P2PHistoryTableTakeRow({ row, isAdmin = false }) {
  const language = useSelector((store) => store.language);

  const { takeAmount, firstName, lastName, emailAddress, mobile, takeTimestamp, price, orderSide, symbol } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 2, typography: 'body2', color: 'grey.100' } }}>
      <TableCell>{moment(takeTimestamp).locale(language).format('YYYY-MM-DD HH:mm')}</TableCell>

      {isAdmin && (
        <TableCell>
          <Typography variant='body3'>
            {firstName} {lastName}
          </Typography>
          {emailAddress && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {emailAddress}
            </Typography>
          )}
          {mobile && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {mobile}
            </Typography>
          )}
        </TableCell>
      )}

      <TableCell align='center'>
        <Typography
          variant='body2'
          color={(orderSide === 'BUY' && 'positive.main') || (orderSide === 'SELL' && 'negative.main') || 'grey.100'}
        >
          {orderSide}
        </Typography>
      </TableCell>

      <TableCell>
        {takeAmount}
        <Typography variant='body3' color='grey.300' sx={{ ml: 1 }}>
          {symbol}
        </Typography>
      </TableCell>

      <TableCell>
        {price}
        <Typography variant='body3' color='grey.300' sx={{ ml: 1 }}>
          USD
        </Typography>
      </TableCell>

      <TableCell>
        {orderSide === 'SELL' ? price * takeAmount : takeAmount}
        <Typography variant='body3' color='grey.300' sx={{ ml: 1 }}>
          {orderSide === 'SELL' ? 'USD' : symbol}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
