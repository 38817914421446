import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from '../../../../components/ModalContainer';
import { Button, Stack, Typography } from '@mui/material';

const FutureMarginModeModal = ({ open, onClose, defaultValue = 20, symbol, onConfirm }) => {

  const { t } = useTranslation();



  return (
    <ModalContainer open={open} onClose={onClose} title={t(`${symbol} Perpetual Margin Mode`)} width={420}>
      <Stack alignItems='center' direction={'row'} justifyContent={'space-between'} sx={{ mt: 3, mb: 3  }}>
        <Button
          variant='contained'
          size='small'
          color='inherit'
          sx={{
            borderRadius: 0.5,
            border:defaultValue === 'CROSS' ? '1px solid' :"",
            bgcolor: 'grey.400',
            typography: 'body3',
            width: '48%',
            '&:hover': { bgcolor: 'grey.600' },
          }}
          onClick={() => onConfirm("CROSS")}
        >
          {t('Cross')}
        </Button>
        <Button
          variant='contained'
          size='small'
          color='inherit'
          sx={{
            borderRadius: 0.5,
            bgcolor: 'grey.400',
            border:defaultValue === 'ISOLATED' ? '1px solid' :"",

            typography: 'body3',
            width: '48%',
            '&:hover': { bgcolor: 'grey.600' },
          }}
          onClick={() => onConfirm('ISOLATED')}
        >
          {t('Isolated')}
        </Button>
      </Stack>



      <Typography variant='body3' color={'grey.200'} fontWeight={500} sx={{ mt: 2.5, mb: 2 }}>
        {t('Switching the margin mode will only apply it to the selected contract.')}
      </Typography>

      <Typography variant='body4' color={'grey.100'} fontWeight={500} sx={{  mb: 1 }}>
        {t('Cross Margin Mode: All cross positions under the same margin\n' +
          'asset share the same asset cross margin batance. In the event\n' +
          'of liquidation, your assets full margin balance along with any\n' +
          'remaining open positions under the asset may be forfeited.')}
      </Typography>

      <Typography variant='body4' color={'grey.100'} fontWeight={500} sx={{  mb: 1 }}>
        {t('Isolated Margin Mode: Manage your risk on individual\n' +
          'positions by restricting the amount of margin allocated to\n' +
          'each. If the margin ratio of a position reached 100%, the\n' +
          'position will be liquidated, Margin can be added or removed to\n' +
          'positions using this mode.')}
      </Typography>



      <Stack alignItems='center' direction={'row'} justifyContent={'space-between'} sx={{ mt: 3, mb: 3  }}>
        <Button
          variant='contained'
          size='small'
          color='primary'
          sx={{
            borderRadius: 0.5,

            bgcolor: 'green.400',
            typography: 'body3',
            width: '100%',
            '&:hover': { bgcolor: 'green.600' },
          }}
          onClick={onClose}
        >
          {t('Confirm')}
        </Button>

      </Stack>
    </ModalContainer>
  );
};

export default FutureMarginModeModal;