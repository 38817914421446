import React from 'react';
import { Stack, Typography, Box } from '@mui/material';

import Image from '../../../../components/Image';
import { RHFTextField } from '../../../../components/hookForm';
import { useTranslation } from 'react-i18next';

const CommissionRow = ({ data }) => {
  const { t } = useTranslation();
  const { id, name, percentage, symbol } = data;

  return (
    <Stack direction='row' spacing={7.5}>
      <Typography sx={{ fontSize: '24px', display: 'flex', alignItems: 'center' }} width={272}>
        {symbol !== '' ? (
          <Image src={symbol} width={48} height={48} sx={{ mr: 2.8 }} />
        ) : (
          <Box sx={{ width: 48, height: 48, backgroundColor: 'info.darker', mr: 3 }} />
        )}
        {name}
      </Typography>

      <Box width={400}>
        <RHFTextField name='percentage' label={t('Percentage')} variant='standard' fullWidth value={percentage} />
      </Box>
    </Stack>
  );
};

export default CommissionRow;
