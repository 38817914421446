import { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Api from '../../../../http/serviceApi';
import { toNumber } from '../../../../utils/formatNumber';
import { navigationUrl } from '../../../../navigationUrl';
import { applyObjectToQueryString } from '../../../../utils';

export default function useDashboardCalculate() {
  const navigate = useNavigate();

  const [side, setSide] = useState('buy');
  const [spend, setSpend] = useState('');
  const [receive, setReceive] = useState('');
  const [spendSymbol, setSpendSymbol] = useState('USD');
  const [receiveSymbol, setReceiveSymbol] = useState('BTC');
  const [calculated, setCalculated] = useState(0);

  const handleSideChange = () => {
    setSide(side === 'buy' ? 'sell' : 'buy');

    const tempSymbol = spendSymbol;
    setSpendSymbol(receiveSymbol);
    setReceiveSymbol(tempSymbol);

    const tempValue = spend;
    setSpend(receive);
    setReceive(tempValue);
  };

  const lastFiat = useRef();
  const lastSymbol = useRef();
  const getCalculate = async () => {
    const fiat = side === 'buy' ? spendSymbol : receiveSymbol;
    const symbol = side === 'sell' ? spendSymbol : receiveSymbol;

    if (lastFiat.current === fiat && lastSymbol.current === symbol) return;

    try {
      const response = await Api.calculateDashboard(fiat, symbol);
      if (response.status === 'SUCCESS') {
        setCalculated(response.symbolValue);
        onCalculate(response.symbolValue);
        lastFiat.current = fiat;
        lastSymbol.current = symbol;
      }
    } catch (e) {
      setCalculated(1);
    }
  };

  useEffect(() => {
    getCalculate();
  }, [spendSymbol, receiveSymbol]);

  const onSelectSymbol = (value) => {
    if (side === 'buy') {
      setReceiveSymbol(value);
    } else {
      setSpendSymbol(value);
    }
  };

  useEffect(() => {
    onCalculate();
  }, [spend]);

  const onCalculate = (value) => {
    if (side === 'buy') {
      setReceive(toNumber(spend) * (value || calculated));
    } else {
      setReceive(toNumber(spend) / (value || calculated));
    }
  };

  const onExchange = () => {
    const params = { spend: spendSymbol, receive: receiveSymbol, value: toNumber(spend), side };
    navigate(`${navigationUrl.otc}?${applyObjectToQueryString(params)}`);
  };

  return {
    side,
    receiveSymbol,
    setReceiveSymbol,
    spendSymbol,
    setSpendSymbol,
    receive,
    setReceive,
    spend,
    setSpend,
    calculated,
    handleSideChange,
    onSelectSymbol,
    onExchange,
  };
}
