import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { BaseOptionChart } from '../../../../components/chart';
import mergeDeep from '../../../../utils/merge';

import fakeBar from '../../../../assets/fake-bar.png';
import fakeGrid from '../../../../assets/GridDark.png';
import { useLayoutEffect, useRef, useState } from 'react';

export default function TabChart({ chartData, chartLabels , label = 'Items' }) {
  const { t } = useTranslation();
  const [gridSize, setGridSize] = useState(0);
  const chart = useRef();
console.log(chartLabels , chartData)
  useLayoutEffect(() => {
    const onResize = () => {
      setGridSize(
        chart.current?.chartRef?.current?.querySelector?.('.apexcharts-grid')?.getBoundingClientRect?.()?.height
      );
    };

    onResize();
    chart.current?.chartRef?.current.addEventListener('resize', onResize);

    return () => chart.current?.chartRef?.current.removeEventListener('resize', onResize);
  }, [chartData, chartLabels]);

  const chartOptions = mergeDeep(BaseOptionChart(), {
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    legend: {
      show: false,
    },
    colors: ['#30E0A1', '#F27F16', '#252D55'],
    chart: { animations: { enabled: true } },
    grid: {
      show: true,
      strokeDashArray: 4,
      borderColor: 'info.darkest',
    },
    plotOptions: {
      bar: {
        columnWidth: '15%',
        borderRadius: 0,
      },
    },
    tooltip: {
      x: { show: false },
      y: {
        formatter: (seriesName ) => `${seriesName || 0} ${t(label)}`,
        title: {
          formatter: (label) => ` ${t(label)} `,
        },


      },
      marker: { show: false },
    },
    'apexcharts-grid': {},
    ...(chartLabels && {
      xaxis: {
        categories: chartLabels,
      },
    }),
  });

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          backgroundImage: `url("${fakeGrid}")`,
          backgroundRepeat: 'repeat',
          width: 'calc(100% - 50px)',
          height: `${gridSize + 27}px`,
          position: 'absolute',
          left: 35,
          top: 0,
        }}
      >
        <Box
          sx={{
            backgroundImage: `url("${fakeBar}")`,
            backgroundRepeat: 'repeat-x',
            backgroundPosition: '0 bottom',
            width: '100%',
            height: '100%',
          }}
        />
      </Box>

      <ReactApexChart
        ref={chart}
        series={
          chartData || [
            {
              name: 'Iran',
              type: 'line',
              data: [17, 25, 23, 20, 25, 20, 15, 18, 14, 10, 26, 21, 20, 28, 35, 30, 32, 28, 27, 20],
            },
            {
              name: 'Armenia',
              type: 'line',
              data: [10, 26, 21, 20, 28, 35, 30, 32, 28, 27, 20, 17, 25, 23, 20, 25, 20, 15, 18, 14],
            },
          ]
        }
        options={chartOptions}
        height={270}
      />
    </Box>
  );
}
