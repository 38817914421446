import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'antd';
import classesDark from './authentication.module.scss';
import classesLight from './authenticationLight.module.scss';
import { BsArrowRight } from 'react-icons/bs';
import { MdKeyboardArrowRight } from 'react-icons/md';
import Media from 'react-media';
import { screenSize } from '../../../common/screenSize';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { navigationUrl } from '../../../navigationUrl';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { SET_PAGE_STATE } from '../../../store/actionTypes';
import { useEffect } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Api from '../../../http/serviceApi';
import { fFloat, fNumber } from '../../../utils/formatNumber';

const Authentication = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((store) => store.userInfo);
  const isIranianPaymentActive = useSelector((store) => store.isIranianPaymentActive);

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Authentication' },
    });
  }, []);

  const classes = theme.palette.mode === 'dark' ? classesDark : classesLight;

  const colPropsFormRight = {
    xs: 24,
    sm: 24,
    md: 15,
    lg: 11,
    xl: 9,
  };
  const colPropsFormLeft = {
    xs: 24,
    sm: 24,
    md: 20,
    lg: 12,
    xl: 10,
  };

  const colPropsLevels = {
    xs: 24,
    sm: 24,
    md: 11,
    lg: 8,
    xl: 8,
  };

  // --------------------
  const [basicSetting, setBasicSetting] = useState();
  const [intermediateSetting, setIntermediateSetting] = useState();
  const [advancedSetting, setAdvancedSetting] = useState();

  // --------------------
  const fetchUserLevelSetting = async () => {
    try {
      const basic = await Api.getUserLevelSetting('BASIC');
      setBasicSetting(basic);

      const intermediate = await Api.getUserLevelSetting('INTERMEDIATE');
      setIntermediateSetting(intermediate);

      const advanced = await Api.getUserLevelSetting('ADVANCED');
      setAdvancedSetting(advanced);
    } catch (e) {}
  };

  // --------------------
  useEffect(() => {
    fetchUserLevelSetting();
  }, []);

  // --------------------
  return (
    <div>
      <Row justify='start'>
        <span style={{ marginBottom: '10px' }}>{t('Your current Limit')}</span>
      </Row>
      <Form layout='horizontal'>
        <Row justify='space-between' align='middle' className={classes.topRow}>
          {/* <Col {...colPropsFormLeft}>
                        <Form.Item className={classes.formItem}
                            label="Residential country/region"
                            name="country"
                        >
                            <Select
                                shape='round'
                                showSearch
                                placeholder="country/region"
                            />
                        </Form.Item>
                    </Col> */}
          <Col {...colPropsFormRight}>
            <Form.Item className={classes.formItem} label={t('Video Guide')} name='videoGuide'>
              <Button
                block
                className={classes.btnSwitchToEnterprise}
                shape='round'
                icon={<BsArrowRight size={19} style={{ marginRight: '10px' }} />}
              >
                {t('Switch to enterprise Account')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Media
        query={screenSize.largerThanSm}
        render={() => {
          return (
            <Row justify='start' gutter={[{ xs: 0, sm: 0, md: 50, lg: 30, xl: 75 }, 0]} className={classes.bottomRow}>
              <Col {...colPropsLevels} className={classes.levelCol}>
                <div className={classes.levelColTitle}>
                  <span>{t('Basic')}</span>
                  <span className={classes.levelColTitleLabel}>{t('Quick & Easy')}</span>
                </div>

                <div className={classes.levelColRequirementDiv}>
                  <div className={classes.requirementItem}>
                    <MdKeyboardArrowRight />
                    <span>{t('Personal Information')}</span>
                  </div>
                </div>

                <Button
                  block
                  className={
                    (userInfo?.authLevel === 'ADVANCED' ||
                    userInfo?.authLevel === 'ADVANCED_BY_ADMIN' ||
                    userInfo?.authLevel === 'ADVANCED_REJECTED' ||
                    userInfo?.authLevel === 'ADVANCED_REQUESTED' ||
                    userInfo?.authLevel === 'INTERMEDIATE' ||
                    userInfo?.authLevel === 'INTERMEDIATE_BY_ADMIN' ||
                    userInfo?.authLevel === 'INTERMEDIATE_REJECTED' ||
                    userInfo?.authLevel === 'INTERMEDIATE_REQUESTED' ||
                    userInfo?.authLevel === 'BASIC' ||
                    userInfo?.authLevel === 'BASIC_BY_ADMIN'
                      ? classes.btnGreen
                      : '') ||
                    (userInfo?.authLevel === 'BASIC_REQUESTED' ? classes.btnOrange : '') ||
                    classes.btnNormal
                  }
                  shape='round'
                  type='primary'
                  onClick={() => navigate(navigationUrl.auth_basic_identityinfo)}
                  disabled={userInfo?.authLevel !== 'REGISTERED' ? true : false}
                >
                  {(userInfo?.authLevel === 'ADVANCED' ||
                  userInfo?.authLevel === 'ADVANCED_BY_ADMIN' ||
                  userInfo?.authLevel === 'ADVANCED_REJECTED' ||
                  userInfo?.authLevel === 'ADVANCED_REQUESTED' ||
                  userInfo?.authLevel === 'INTERMEDIATE' ||
                  userInfo?.authLevel === 'INTERMEDIATE_BY_ADMIN' ||
                  userInfo?.authLevel === 'INTERMEDIATE_REJECTED' ||
                  userInfo?.authLevel === 'INTERMEDIATE_REQUESTED' ||
                  userInfo?.authLevel === 'BASIC' ||
                  userInfo?.authLevel === 'BASIC_BY_ADMIN' ? (
                    <span>{t('Verified')}</span>
                  ) : null) ||
                    (userInfo?.authLevel === 'BASIC_REQUESTED' ? <span>{t('Pending')}</span> : null) || (
                      <span>{t('Verify Now')}</span>
                    )}
                </Button>

                <Stack
                  sx={{ mt: 3, '& > div': { px: 0.5 }, '& > div:nth-child(even)': { bgcolor: 'grey.700' } }}
                  spacing={0.5}
                >
                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Withdrawal of cryptocurrency (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.fiatWithdrawal)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Withdrawal of Fiat (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.fiatWithdrawal)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Buying digital money (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.buyingDigitalMoneyDollar)}</Typography>
                  </Stack>

                  {/*{isIranianPaymentActive && (*/}
                  {/*  <Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*    <Typography variant='body4' color='grey.300'>*/}
                  {/*      {t('Buying digital money (equivalent to Rial)')}*/}
                  {/*    </Typography>*/}
                  {/*    <Typography variant='body4'>{fNumber(basicSetting?.buyingDigitalMoneyRial)}</Typography>*/}
                  {/*  </Stack>*/}
                  {/*)}*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Fees for buy Tether')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(basicSetting?.tetherBuyFee)}</Typography>*/}
                  {/*</Stack>*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Fees for Sell Tether')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(basicSetting?.tetherSellFee)}</Typography>*/}
                  {/*</Stack>*/}

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Futures Fee (Marker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.futuresMakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Futures Fee (Taker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.futuresTakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Trade Fee (Marker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.tradeMakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Trade Fee (Taker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.tradeTakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Fees for internal transaction')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.internalTransactionFee)}</Typography>
                  </Stack>

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('OTC Minimum Buy')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(basicSetting?.otcMinimumBuy)}</Typography>*/}
                  {/*</Stack>*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Min internal transaction')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(basicSetting?.minimumInternalTransaction)}</Typography>*/}
                  {/*</Stack>*/}

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max internal transaction')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.maximumInternalTransaction)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max Leverage')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.maxLeverage)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max Margin')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.maxMargin)}</Typography>
                  </Stack>
                </Stack>
              </Col>

              <Col {...colPropsLevels} className={`${classes.levelCol}`}>
                <div className={classes.levelColTitle}>
                  <span>{t('Intermediate')}</span>
                  <span className={classes.levelColTitleLabel}>{t('Most Popular')}</span>
                </div>

                <div className={classes.levelColRequirementDiv}>
                  <div className={classes.requirementItem}>
                    <MdKeyboardArrowRight />
                    <span>{t('All basic Requirements')}</span>
                  </div>
                  <div className={classes.requirementItem}>
                    <MdKeyboardArrowRight />
                    <span>{t('Government ID')}</span>
                  </div>
                  <div className={classes.requirementItem}>
                    <MdKeyboardArrowRight />
                    <span>{t('Facial Verification')}</span>
                  </div>
                  <div className={classes.requirementItem}>
                    <MdKeyboardArrowRight />
                    <span>
                      {t('Review time')} : 3 {t('days')}
                    </span>
                  </div>
                </div>

                <Button
                  block
                  className={
                    (userInfo?.authLevel === 'ADVANCED' ||
                    userInfo?.authLevel === 'ADVANCED_BY_ADMIN' ||
                    userInfo?.authLevel === 'ADVANCED_REJECTED' ||
                    userInfo?.authLevel === 'ADVANCED_REQUESTED' ||
                    userInfo?.authLevel === 'INTERMEDIATE' ||
                    userInfo?.authLevel === 'INTERMEDIATE_BY_ADMIN'
                      ? classes.btnGreen
                      : '') ||
                    (userInfo?.authLevel === 'INTERMEDIATE_REQUESTED' ? classes.btnOrange : '') ||
                    (userInfo?.authLevel === 'INTERMEDIATE_REJECTED' ? classes.btnRed : '') ||
                    classes.btnNormal
                  }
                  shape='round'
                  type='primary'
                  onClick={() => navigate(navigationUrl.auth_inter_select)}
                  disabled={
                    userInfo?.authLevel !== 'BASIC' &&
                    userInfo?.authLevel !== 'BASIC_BY_ADMIN' &&
                    userInfo?.authLevel !== 'INTERMEDIATE_REJECTED'
                      ? true
                      : false
                  }
                >
                  {(userInfo?.authLevel === 'ADVANCED' ||
                  userInfo?.authLevel === 'ADVANCED_BY_ADMIN' ||
                  userInfo?.authLevel === 'ADVANCED_REJECTED' ||
                  userInfo?.authLevel === 'ADVANCED_REQUESTED' ||
                  userInfo?.authLevel === 'INTERMEDIATE' ||
                  userInfo?.authLevel === 'INTERMEDIATE_BY_ADMIN' ? (
                    <span>{t('Verified')}</span>
                  ) : null) ||
                    (userInfo?.authLevel === 'INTERMEDIATE_REQUESTED' ? <span>{t('Pending')}</span> : null) ||
                    (userInfo?.authLevel === 'INTERMEDIATE_REJECTED' ? <span>{t('Rejected')}</span> : null) ||
                    (userInfo?.authLevel === 'BASIC_REQUESTED' || userInfo?.authLevel === 'REGISTERED' ? (
                      <span>{t('After Basic')}</span>
                    ) : null) ||
                    ((userInfo?.authLevel === 'BASIC' || userInfo?.authLevel === 'BASIC_BY_ADMIN') && (
                      <span>{t('Verify Now')}</span>
                    ))}
                </Button>

                <Stack
                  sx={{ mt: 3, '& > div': { px: 0.5 }, '& > div:nth-child(even)': { bgcolor: 'grey.700' } }}
                  spacing={0.5}
                >
                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Withdrawal of cryptocurrency (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.fiatWithdrawal)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Withdrawal of Fiat (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.fiatWithdrawal)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Buying digital money (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.buyingDigitalMoneyDollar)}</Typography>
                  </Stack>

                  {/*{isIranianPaymentActive && (*/}
                  {/*  <Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*    <Typography variant='body4' color='grey.300'>*/}
                  {/*      {t('Buying digital money (equivalent to Rial)')}*/}
                  {/*    </Typography>*/}
                  {/*    <Typography variant='body4'>{fNumber(intermediateSetting?.buyingDigitalMoneyRial)}</Typography>*/}
                  {/*  </Stack>*/}
                  {/*)}*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Fees for buy Tether')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(intermediateSetting?.tetherBuyFee)}</Typography>*/}
                  {/*</Stack>*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Fees for Sell Tether')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(intermediateSetting?.tetherSellFee)}</Typography>*/}
                  {/*</Stack>*/}

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Futures Fee (Marker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.futuresMakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Futures Fee (Taker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.futuresTakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Trade Fee (Marker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.tradeMakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Trade Fee (Taker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.tradeTakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Fees for internal transaction')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.internalTransactionFee)}</Typography>
                  </Stack>

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('OTC Minimum Buy')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(intermediateSetting?.otcMinimumBuy)}</Typography>*/}
                  {/*</Stack>*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Min internal transaction')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(intermediateSetting?.minimumInternalTransaction)}</Typography>*/}
                  {/*</Stack>*/}

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max internal transaction')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.maximumInternalTransaction)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max Leverage')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.maxLeverage)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max Margin')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.maxMargin)}</Typography>
                  </Stack>
                </Stack>
              </Col>

              <Col {...colPropsLevels} className={classes.levelCol}>
                <div className={classes.levelColTitle}>
                  <span>{t('Advanced')}</span>
                  <span className={classes.levelColTitleLabel}>{t('Highest Fiat Limit')}</span>
                </div>

                <div className={classes.levelColRequirementDiv}>
                  <div className={classes.requirementItem}>
                    <MdKeyboardArrowRight />
                    <span>{t('All basic Requirements')}</span>
                  </div>
                  <div className={classes.requirementItem}>
                    <MdKeyboardArrowRight />
                    <span>{t('Proof of Address')}</span>
                  </div>
                  <div className={classes.requirementItem}>
                    <MdKeyboardArrowRight />
                    <span>
                      {t('Review time')} : 3 {t('days')}
                    </span>
                  </div>
                </div>

                <Button
                  block
                  className={
                    (userInfo?.authLevel === 'ADVANCED' || userInfo?.authLevel === 'ADVANCED_BY_ADMIN'
                      ? classes.btnGreen
                      : '') ||
                    (userInfo?.authLevel === 'ADVANCED_REQUESTED' ? classes.btnOrange : '') ||
                    (userInfo?.authLevel === 'ADVANCED_REJECTED' ? classes.btnRed : '') ||
                    classes.btnNormal
                  }
                  shape='round'
                  type='primary'
                  onClick={() => navigate(navigationUrl.auth_advanced_address)}
                  disabled={
                    userInfo?.authLevel !== 'INTERMEDIATE' &&
                    userInfo?.authLevel !== 'INTERMEDIATE_BY_ADMIN' &&
                    userInfo?.authLevel !== 'ADVANCED_REJECTED'
                      ? true
                      : false
                  }
                >
                  {(userInfo?.authLevel === 'ADVANCED' || userInfo?.authLevel === 'ADVANCED_BY_ADMIN' ? (
                    <span>{t('Verified')}</span>
                  ) : (
                    ''
                  )) ||
                    (userInfo?.authLevel === 'ADVANCED_REQUESTED' ? <span>{t('Pending')}</span> : '') ||
                    (userInfo?.authLevel === 'ADVANCED_REJECTED' ? <span>{t('Rejected')}</span> : '') ||
                    (userInfo?.authLevel === 'BASIC' ||
                    userInfo?.authLevel === 'BASIC_BY_ADMIN' ||
                    userInfo?.authLevel === 'BASIC_REQUESTED' ||
                    userInfo?.authLevel === 'INTERMEDIATE_REJECTED' ||
                    userInfo?.authLevel === 'INTERMEDIATE_REQUESTED' ||
                    userInfo?.authLevel === 'REGISTERED' ? (
                      <span>{t('After Intermediate')}</span>
                    ) : null) ||
                    ((userInfo?.authLevel === 'INTERMEDIATE' || userInfo?.authLevel === 'INTERMEDIATE_BY_ADMIN') && (
                      <span>{t('Verify Now')}</span>
                    ))}
                </Button>

                <Stack
                  sx={{ mt: 3, '& > div': { px: 0.5 }, '& > div:nth-child(even)': { bgcolor: 'grey.700' } }}
                  spacing={0.5}
                >
                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Withdrawal of cryptocurrency (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.fiatWithdrawal)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Withdrawal of Fiat (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.fiatWithdrawal)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Buying digital money (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.buyingDigitalMoneyDollar)}</Typography>
                  </Stack>

                  {/*{isIranianPaymentActive && (*/}
                  {/*  <Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*    <Typography variant='body4' color='grey.300'>*/}
                  {/*      {t('Buying digital money (equivalent to Rial)')}*/}
                  {/*    </Typography>*/}
                  {/*    <Typography variant='body4'>{fNumber(advancedSetting?.buyingDigitalMoneyRial)}</Typography>*/}
                  {/*  </Stack>*/}
                  {/*)}*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Fees for buy Tether')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(advancedSetting?.tetherBuyFee)}</Typography>*/}
                  {/*</Stack>*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Fees for Sell Tether')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(advancedSetting?.tetherSellFee)}</Typography>*/}
                  {/*</Stack>*/}

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Futures Fee (Marker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.futuresMakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Futures Fee (Taker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.futuresTakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Trade Fee (Marker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.tradeMakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Trade Fee (Taker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.tradeTakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Fees for internal transaction')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.internalTransactionFee)}</Typography>
                  </Stack>

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('OTC Minimum Buy')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(advancedSetting?.otcMinimumBuy)}</Typography>*/}
                  {/*</Stack>*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Min internal transaction')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(advancedSetting?.minimumInternalTransaction)}</Typography>*/}
                  {/*</Stack>*/}

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max internal transaction')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.maximumInternalTransaction)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max Leverage')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.maxLeverage)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max Margin')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.maxMargin)}</Typography>
                  </Stack>
                </Stack>
              </Col>
            </Row>
          );
        }}
      />

      <Media
        query={screenSize.smallerThanMd}
        render={() => {
          return (
            <Row justify='start' className={classes.bottomRowForMobile}>
              <Col {...colPropsLevels} className={classes.levelColForMobile}>
                <div className={classes.levelColTitle}>
                  <span>{t('Basic')}</span>
                  <span className={classes.levelColTitleLabel}>{t('Quick & Easy')}</span>
                </div>

                <div className={classes.levelColRequirementDiv}>
                  <div className={classes.requirementItem}>
                    <MdKeyboardArrowRight style={{ margin: '0' }} />
                    <span>{t('Personal Information')}</span>
                  </div>
                </div>

                <Button
                  block
                  className={
                    (userInfo?.authLevel === 'ADVANCED' ||
                    userInfo?.authLevel === 'ADVANCED_BY_ADMIN' ||
                    userInfo?.authLevel === 'ADVANCED_REJECTED' ||
                    userInfo?.authLevel === 'ADVANCED_REQUESTED' ||
                    userInfo?.authLevel === 'INTERMEDIATE' ||
                    userInfo?.authLevel === 'INTERMEDIATE_BY_ADMIN' ||
                    userInfo?.authLevel === 'INTERMEDIATE_REJECTED' ||
                    userInfo?.authLevel === 'INTERMEDIATE_REQUESTED' ||
                    userInfo?.authLevel === 'BASIC' ||
                    userInfo?.authLevel === 'BASIC_BY_ADMIN'
                      ? classes.btnGreen
                      : '') || (userInfo?.authLevel === 'BASIC_REQUESTED' ? classes.btnOrange : '')
                  }
                  shape='round'
                  type='primary'
                  onClick={() => navigate(navigationUrl.auth_basic_identityinfo)}
                  disabled={userInfo?.authLevel !== 'REGISTERED' ? true : false}
                >
                  {(userInfo?.authLevel === 'ADVANCED' ||
                  userInfo?.authLevel === 'ADVANCED_BY_ADMIN' ||
                  userInfo?.authLevel === 'ADVANCED_REJECTED' ||
                  userInfo?.authLevel === 'ADVANCED_REQUESTED' ||
                  userInfo?.authLevel === 'INTERMEDIATE' ||
                  userInfo?.authLevel === 'INTERMEDIATE_BY_ADMIN' ||
                  userInfo?.authLevel === 'INTERMEDIATE_REJECTED' ||
                  userInfo?.authLevel === 'INTERMEDIATE_REQUESTED' ||
                  userInfo?.authLevel === 'BASIC' ||
                  userInfo?.authLevel === 'BASIC_BY_ADMIN' ? (
                    <span>{t('Verified')}</span>
                  ) : null) ||
                    (userInfo?.authLevel === 'BASIC_REQUESTED' ? <span>{t('Pending')}</span> : null) || (
                      <span>{t('Verify Now')}</span>
                    )}
                </Button>

                <Stack
                  sx={{ mt: 3, '& > div': { px: 0.5 }, '& > div:nth-child(even)': { bgcolor: 'grey.700' } }}
                  spacing={0.5}
                >
                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Withdrawal of cryptocurrency (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.fiatWithdrawal)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Withdrawal of Fiat (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.fiatWithdrawal)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Buying digital money (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.buyingDigitalMoneyDollar)}</Typography>
                  </Stack>

                  {/*{isIranianPaymentActive && (*/}
                  {/*  <Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*    <Typography variant='body4' color='grey.300'>*/}
                  {/*      {t('Buying digital money (equivalent to Rial)')}*/}
                  {/*    </Typography>*/}
                  {/*    <Typography variant='body4'>{fNumber(basicSetting?.buyingDigitalMoneyRial)}</Typography>*/}
                  {/*  </Stack>*/}
                  {/*)}*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Fees for buy Tether')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(basicSetting?.tetherBuyFee)}</Typography>*/}
                  {/*</Stack>*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Fees for Sell Tether')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(basicSetting?.tetherSellFee)}</Typography>*/}
                  {/*</Stack>*/}

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Futures Fee (Marker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.futuresMakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Futures Fee (Taker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.futuresTakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Trade Fee (Marker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.tradeMakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Trade Fee (Taker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.tradeTakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Fees for internal transaction')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.internalTransactionFee)}</Typography>
                  </Stack>

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('OTC Minimum Buy')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(basicSetting?.otcMinimumBuy)}</Typography>*/}
                  {/*</Stack>*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Min internal transaction')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(basicSetting?.minimumInternalTransaction)}</Typography>*/}
                  {/*</Stack>*/}

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max internal transaction')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.maximumInternalTransaction)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max Leverage')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.maxLeverage)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max Margin')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(basicSetting?.maxMargin)}</Typography>
                  </Stack>
                </Stack>
              </Col>

              <Col {...colPropsLevels} className={`${classes.levelColForMobile}`}>
                <div className={classes.levelColTitle}>
                  <span>{t('Intermediate')}</span>
                  <span className={classes.levelColTitleLabel}>{t('Most Popular')}</span>
                </div>

                <div className={classes.levelColRequirementDiv}>
                  <div className={classes.requirementItem}>
                    <MdKeyboardArrowRight />
                    <span>{t('All basic Requirements')}</span>
                  </div>
                  <div className={classes.requirementItem}>
                    <MdKeyboardArrowRight />
                    <span>{t('Government ID')}</span>
                  </div>
                  <div className={classes.requirementItem}>
                    <MdKeyboardArrowRight />
                    <span>{t('Facial Verification')}</span>
                  </div>
                  <div className={classes.requirementItem}>
                    <MdKeyboardArrowRight />
                    <span>
                      {t('Review time')} : 3 {t('days')}
                    </span>
                  </div>
                </div>

                <Button
                  block
                  className={
                    (userInfo?.authLevel === 'ADVANCED' ||
                    userInfo?.authLevel === 'ADVANCED_BY_ADMIN' ||
                    userInfo?.authLevel === 'ADVANCED_REJECTED' ||
                    userInfo?.authLevel === 'ADVANCED_REQUESTED' ||
                    userInfo?.authLevel === 'INTERMEDIATE' ||
                    userInfo?.authLevel === 'INTERMEDIATE_BY_ADMIN'
                      ? classes.btnGreen
                      : '') ||
                    (userInfo?.authLevel === 'INTERMEDIATE_REQUESTED' ? classes.btnOrange : '') ||
                    (userInfo?.authLevel === 'INTERMEDIATE_REJECTED' ? classes.btnRed : '')
                  }
                  shape='round'
                  type='primary'
                  onClick={() => navigate(navigationUrl.auth_inter_select)}
                  disabled={
                    userInfo?.authLevel !== 'BASIC' && userInfo?.authLevel !== 'INTERMEDIATE_REJECTED' ? true : false
                  }
                >
                  {(userInfo?.authLevel === 'ADVANCED' ||
                  userInfo?.authLevel === 'ADVANCED_BY_ADMIN' ||
                  userInfo?.authLevel === 'ADVANCED_REJECTED' ||
                  userInfo?.authLevel === 'ADVANCED_REQUESTED' ||
                  userInfo?.authLevel === 'INTERMEDIATE' ||
                  userInfo?.authLevel === 'INTERMEDIATE_BY_ADMIN' ? (
                    <span>{t('Verified')}</span>
                  ) : null) ||
                    (userInfo?.authLevel === 'INTERMEDIATE_REQUESTED' ? <span>{t('Pending')}</span> : null) ||
                    (userInfo?.authLevel === 'INTERMEDIATE_REJECTED' ? <span>{t('Rejected')}</span> : null) ||
                    (userInfo?.authLevel === 'BASIC_REQUESTED' || userInfo?.authLevel === 'REGISTERED' ? (
                      <span>{t('After Basic')}</span>
                    ) : null) ||
                    ((userInfo?.authLevel === 'BASIC' || userInfo?.authLevel === 'BASIC_BY_ADMIN') && (
                      <span>{t('Verify Now')}</span>
                    ))}
                </Button>

                <Stack
                  sx={{ mt: 3, '& > div': { px: 0.5 }, '& > div:nth-child(even)': { bgcolor: 'grey.700' } }}
                  spacing={0.5}
                >
                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Withdrawal of cryptocurrency (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.fiatWithdrawal)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Withdrawal of Fiat (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.fiatWithdrawal)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Buying digital money (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.buyingDigitalMoneyDollar)}</Typography>
                  </Stack>

                  {/*{isIranianPaymentActive && (*/}
                  {/*  <Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*    <Typography variant='body4' color='grey.300'>*/}
                  {/*      {t('Buying digital money (equivalent to Rial)')}*/}
                  {/*    </Typography>*/}
                  {/*    <Typography variant='body4'>{fNumber(intermediateSetting?.buyingDigitalMoneyRial)}</Typography>*/}
                  {/*  </Stack>*/}
                  {/*)}*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Fees for buy Tether')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(intermediateSetting?.tetherBuyFee)}</Typography>*/}
                  {/*</Stack>*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Fees for Sell Tether')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(intermediateSetting?.tetherSellFee)}</Typography>*/}
                  {/*</Stack>*/}

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Futures Fee (Marker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.futuresMakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Futures Fee (Taker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.futuresTakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Trade Fee (Marker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.tradeMakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Trade Fee (Taker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.tradeTakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Fees for internal transaction')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.internalTransactionFee)}</Typography>
                  </Stack>

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('OTC Minimum Buy')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(intermediateSetting?.otcMinimumBuy)}</Typography>*/}
                  {/*</Stack>*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Min internal transaction')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(intermediateSetting?.minimumInternalTransaction)}</Typography>*/}
                  {/*</Stack>*/}

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max internal transaction')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.maximumInternalTransaction)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max Leverage')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.maxLeverage)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max Margin')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(intermediateSetting?.maxMargin)}</Typography>
                  </Stack>
                </Stack>
              </Col>

              <Col {...colPropsLevels} className={classes.levelColForMobile}>
                <div className={classes.levelColTitle}>
                  <span>{t('Advanced')}</span>
                  <span className={classes.levelColTitleLabel}>{t('Highest Fiat Limit')}</span>
                </div>

                <div className={classes.levelColRequirementDiv}>
                  <div className={classes.requirementItem}>
                    <MdKeyboardArrowRight />
                    <span>{t('All basic Requirements')}</span>
                  </div>
                  <div className={classes.requirementItem}>
                    <MdKeyboardArrowRight />
                    <span>{t('Proof of Address')}</span>
                  </div>
                  <div className={classes.requirementItem}>
                    <MdKeyboardArrowRight />
                    <span>
                      {t('Review time')} : 3 {t('days')}
                    </span>
                  </div>
                </div>

                <Button
                  block
                  className={
                    (userInfo?.authLevel === 'ADVANCED' || userInfo?.authLevel === 'ADVANCED_BY_ADMIN'
                      ? classes.btnGreen
                      : '') ||
                    (userInfo?.authLevel === 'ADVANCED_REQUESTED' ? classes.btnOrange : '') ||
                    (userInfo?.authLevel === 'ADVANCED_REJECTED' ? classes.btnRed : '')
                  }
                  shape='round'
                  type='primary'
                  onClick={() => navigate(navigationUrl.auth_advanced_address)}
                  disabled={
                    userInfo?.authLevel !== 'INTERMEDIATE' && userInfo?.authLevel !== 'ADVANCED_REJECTED' ? true : false
                  }
                >
                  {(userInfo?.authLevel === 'ADVANCED' || userInfo?.authLevel === 'ADVANCED_BY_ADMIN' ? (
                    <span>{t('Verified')}</span>
                  ) : (
                    ''
                  )) ||
                    (userInfo?.authLevel === 'ADVANCED_REQUESTED' ? <span>{t('Pending')}</span> : '') ||
                    (userInfo?.authLevel === 'ADVANCED_REJECTED' ? <span>{t('Rejected')}</span> : '') ||
                    (userInfo?.authLevel === 'BASIC' ||
                    userInfo?.authLevel === 'BASIC_BY_ADMIN' ||
                    userInfo?.authLevel === 'BASIC_REQUESTED' ||
                    userInfo?.authLevel === 'INTERMEDIATE_REJECTED' ||
                    userInfo?.authLevel === 'INTERMEDIATE_REQUESTED' ||
                    userInfo?.authLevel === 'REGISTERED' ? (
                      <span>{t('After Intermediate')}</span>
                    ) : (
                      ''
                    )) ||
                    ((userInfo?.authLevel === 'INTERMEDIATE' || userInfo?.authLevel === 'INTERMEDIATE_BY_ADMIN') && (
                      <span>{t('Verify Now')}</span>
                    ))}
                </Button>

                <Stack
                  sx={{ mt: 3, '& > div': { px: 0.5 }, '& > div:nth-child(even)': { bgcolor: 'grey.700' } }}
                  spacing={0.5}
                >
                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Withdrawal of cryptocurrency (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.fiatWithdrawal)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Withdrawal of Fiat (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.fiatWithdrawal)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Buying digital money (equivalent to Dollar)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.buyingDigitalMoneyDollar)}</Typography>
                  </Stack>

                  {/*{isIranianPaymentActive && (*/}
                  {/*  <Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*    <Typography variant='body4' color='grey.300'>*/}
                  {/*      {t('Buying digital money (equivalent to Rial)')}*/}
                  {/*    </Typography>*/}
                  {/*    <Typography variant='body4'>{fNumber(advancedSetting?.buyingDigitalMoneyRial)}</Typography>*/}
                  {/*  </Stack>*/}
                  {/*)}*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Fees for buy Tether')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(advancedSetting?.tetherBuyFee)}</Typography>*/}
                  {/*</Stack>*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Fees for Sell Tether')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(advancedSetting?.tetherSellFee)}</Typography>*/}
                  {/*</Stack>*/}

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Futures Fee (Marker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.futuresMakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Futures Fee (Taker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.futuresTakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Trade Fee (Marker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.tradeMakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Trade Fee (Taker)')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.tradeTakerFee)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Fees for internal transaction')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.internalTransactionFee)}</Typography>
                  </Stack>

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('OTC Minimum Buy')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(advancedSetting?.otcMinimumBuy)}</Typography>*/}
                  {/*</Stack>*/}

                  {/*<Stack direction='row' alignItems='center' justifyContent='space-between'>*/}
                  {/*  <Typography variant='body4' color='grey.300'>*/}
                  {/*    {t('Min internal transaction')}*/}
                  {/*  </Typography>*/}
                  {/*  <Typography variant='body4'>{fFloat(advancedSetting?.minimumInternalTransaction)}</Typography>*/}
                  {/*</Stack>*/}

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max internal transaction')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.maximumInternalTransaction)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max Leverage')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.maxLeverage)}</Typography>
                  </Stack>

                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body4' color='grey.300'>
                      {t('Max Margin')}
                    </Typography>
                    <Typography variant='body4'>{fFloat(advancedSetting?.maxMargin)}</Typography>
                  </Stack>
                </Stack>
              </Col>
            </Row>
          );
        }}
      />
    </div>
  );
};

export default Authentication;
