import { useSnackbar } from 'notistack';

import Api from '../../../../../http/adminHttp/serviceApiAdmin';
import { useTranslation } from 'react-i18next';

export default function usePopupWebSubmit() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (payload) => {

    const data = {
      active: payload.active,
      popupType: 'WEB',
      showDays: payload.showDays,
    };
    const response = await Api.editPopUpbData(data ,payload.text.replace("<p>","").replace("</p>",""),
  );
    if (response?.status === 'SUCCESS') {
      enqueueSnackbar(t('The values saved successfully.'), { variant: 'success' });
    }
  };

  return {
    onSubmit,
  };
}
