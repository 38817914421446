import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { alpha, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import logo from '../assets/logo_small.png';
import { hideLoading, showLoading } from '../utils';
import Image from './Image';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export function LoadingPortal({ ...other }) {
  return createPortal(
    <>
      <RootStyle {...other}>
        <Image src={logo} sx={{ width: 50, height: 50, animation: 'zoom-in-zoom-out 3s ease infinite' }} />

        <Box
          sx={{
            width: 100,
            height: 100,
            borderRadius: '25%',
            position: 'absolute',
            border: (theme) => `solid 2px ${alpha(theme.palette.primary.dark, 0.24)}`,
            animation: `rotation 2s infinite linear`,
          }}
        />

        <Box
          sx={{
            width: 120,
            height: 120,
            borderRadius: '25%',
            position: 'absolute',
            border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
            animation: `rotation-reverse 2s infinite linear`,
          }}
        />
      </RootStyle>
    </>,
    document.getElementById('loading')
  );
}

export default function FakeLoadingScreen() {
  useEffect(() => {
    showLoading();

    return () => {
      hideLoading();
    };
  });
  return null;
}
