import { api } from './adminHttp';
import { Log } from '../serviceLog';
import navigationService from '../../utils/navigation';
import { navigationUrl } from '../../navigationUrl';
import { removeTokenAdmin } from './tokenHandlerAdmin';
import { isNetworkError } from '../http-error.interceptor';

export const interceptError = () => {
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // const originalRequest = error.config;

      // if (isNetworkError(error)) {
      //   return new Promise((resolve) => {
      //     if (originalRequest) {
      //       setTimeout(() => {
      //         resolve(api(originalRequest));
      //       }, 5000);
      //     }
      //   });
      // }

      if (error?.response?.status === 401) {
        if (error?.response?.data?.message !== 'Access denied') {
          removeTokenAdmin();
          navigationService.navigate(navigationUrl.admin_login);
        }
        console.log(error.response.data);
        return false;
      } else if (error?.code === 'ERR_NETWORK') {
        // navigationService.navigate('/0');
        console.log(error);
        return false;
      }

      Log(error);
      return Promise.reject(isNetworkError(error) ? 'CONNECTION_FAILED' : error?.response?.data || error);
    }
  );
};
