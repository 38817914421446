import { Vertify } from '@alex_xu/react-slider-vertify';
import { Backdrop, Box, Fade, IconButton, Modal } from '@mui/material';
import c1 from '../../assets/captcha/ver_01.jpg';
import c2 from '../../assets/captcha/Ver-02.jpg';
import c3 from '../../assets/captcha/Ver-03.jpg';
import c4 from '../../assets/captcha/Ver-04.jpg';

import Iconify from '../Iconify';
import sliderIcon from '../../assets/slider.svg';

const CaptchaModal = ({ visible, onCaptchaSuccess, onCancel }) => {


  const pics = [c1 , c2 , c3 , c4]
  function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const rndInt = pics[randomIntFromInterval(0, 3)]


  return (
    <Modal
      open={visible}
      onClose={onCancel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: { xs: 'translate(-50%, -50%)' },
            width: 360,
            height: 360,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 1,
            '& .refreshIcon': {
              top: 'auto !important',
              bottom: '-65px !important',
              right: '34px !important',
            },
            '& .sliderContainer': {
              height: '10px !important',
              background: '#E4E4E4 !important',
              borderRadius: 100,
            },
            '& .slider': {
              background: `url(${sliderIcon}) !important`,
              backgroundSize: '48px !important',
              backgroundPosition: '-3px 0 !important',
              boxShadow: 'none !important',
              outline: '0 !important',
              top: '-12px !important',
              backgroundRepeat: 'no-repeat !important',
              border: '0 !important',
            },
            '& .sliderIcon': {
              opacity: '0 !important',
            },
            '& .sliderMask': {
              border: '0px !important',
              height: '10px !important',
              borderRadius: '100px !important',
              top: '-1px !important',
            },
            '& .sliderContainer_fail': {
              '& .slider': {
                backgroundColor: 'transparent !important',
              },
            },
            '& .sliderContainer_success': {
              '& .slider': {
                backgroundColor: 'transparent !important',
              },
            },
          }}
        >
          <Box sx={{ position: 'relative', px: 1.25, pt: 7.75, pb: 2.12, width: '100%', height: '100%' }}>
            <IconButton onClick={onCancel} sx={{ position: 'absolute', bottom: 0, right: 0 }}>
              <Iconify icon='charm:circle-cross' color='#B3B3B3' sx={{ width: 25, height: 25 }} />
            </IconButton>

            <IconButton onClick={() => {}} sx={{ position: 'absolute', bottom: 0, left: 0 }}>
              <Iconify icon='material-symbols:help-outline-rounded' color='#B3B3B3' sx={{ width: 25, height: 25 }} />
            </IconButton>

            <Vertify
              onSuccess={onCaptchaSuccess}
              width={340}
              height={196}
              imgUrl={rndInt}
              // visible={visible}
              // onSuccess={() => navigate(navigationUrl.verify)}
              // onFail={() => alert('fail')}
              // onRefresh={() => alert('refresh')}
            />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CaptchaModal;
