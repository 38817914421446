import React from 'react';
import { Button, MenuItem, Stack, TextField } from '@mui/material';

import DatePicker from '../../../../../../components/DatePicker';
// import NumberRange from '../../../../../../components/NumberRange';
import { useTranslation } from 'react-i18next';
import ComplexToolbar from '../../../../../../components/ComplexToolbar';
import { getDefaultDateFilterValues } from '../../../../../../utils/filter';
import { prettyUppercases } from '../../../../../../utils';

const CryptoTransactionToolbar = ({
  typeFilter,
  setTypeFilter,
  depositStatusFilter,
  setDepositStatusFilter,
  withdrawStatusFilter,
  setWithdrawStatusFilter,
  dateFromFilter,
  setDateFromFilter,
  dateToFilter,
  setDateToFilter,
  transactionIdFilter,
  setTransactionIdFilter,
  onFilter,
  isExportLoading,
  onExport,
}) => {
  const { t } = useTranslation();
  const depositTypes = ['ALL', 'CREDITED', 'PENDING', 'SUCCESS'];
  const withdrawTypes = [
    'All',
    'Awaiting_Approval',
    'Cancelled',
    'Completed',
    'Email_Sent',
    'Failure',
    'Processing',
    'Rejected',
  ];

  const onReset = async () => {
    setTypeFilter('deposit');
    setDepositStatusFilter('SUCCESS');
    setWithdrawStatusFilter('Completed');
    setDateFromFilter(getDefaultDateFilterValues().firstDay);
    setDateToFilter(getDefaultDateFilterValues().lastDay);
    setTransactionIdFilter('');

    onFilter(true);
  };

  return (
    <ComplexToolbar
      onSearch={false}
      onExport={onExport}
      isExportLoading={isExportLoading}
      onPrint={() => console.log('print')}
    >
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} sx={{ mt: 2 }} alignItems={'center'}>
          {/* <TextField
            label={t('User')}
            variant='standard'
            fullWidth
            value={userNameFilter}
            onChange={(e) => setUserNameFilter(e.target.value)}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          ></TextField> */}

          <TextField
            select
            label={t('Type')}
            variant='standard'
            fullWidth
            value={typeFilter}
            onChange={(e) => setTypeFilter(e.target.value)}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            {/*<MenuItem value={'ALL'}>All</MenuItem>*/}
            <MenuItem value={'Deposit'}>Deposit</MenuItem>
            <MenuItem value={'Withdraw'}>Withdraw</MenuItem>
          </TextField>

          <Stack direction={'row'} sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}>
            <DatePicker
              label={t('From')}
              value={dateFromFilter}
              setValue={setDateFromFilter}
              variant='standard'
              maxDate={dateToFilter}
              fullWidth
              sx={{ minWidth: 75 }}
              InputProps={null}
            />
            <DatePicker
              label={t('To')}
              value={dateToFilter}
              setValue={setDateToFilter}
              variant='standard'
              minDate={dateFromFilter}
              fullWidth
              sx={{ minWidth: 75 }}
            />
          </Stack>
        </Stack>

        <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'center'} spacing={5}>
          <TextField
            select
            label={t('Deposit Status')}
            variant='standard'
            fullWidth
            value={depositStatusFilter}
            onChange={(e) => setDepositStatusFilter(e.target.value)}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            {depositTypes.map((deposit, index) => (
              <MenuItem key={index} value={deposit}>
                {t(prettyUppercases(deposit))}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label={t('Withdraw Status')}
            variant='standard'
            fullWidth
            value={withdrawStatusFilter}
            onChange={(e) => setWithdrawStatusFilter(e.target.value)}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            {withdrawTypes.map((withdraw, index) => (
              <MenuItem key={index} value={withdraw}>
                {t(prettyUppercases(withdraw))}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label={t('Transaction ID')}
            variant='standard'
            fullWidth
            value={transactionIdFilter}
            onChange={(e) => setTransactionIdFilter(e.target.value)}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          ></TextField>

          {/* <NumberRange
            label={'Balance'}
            value={balanceFilter}
            setValue={setBalanceFilter}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
          /> */}
        </Stack>
      </Stack>

      <Stack direction={'row'} spacing={2} sx={{ mt: 5, mb: 5.75 }}>
        <Button
          onClick={() => onFilter()}
          fullWidth
          variant='contained'
          sx={{ maxWidth: 204, typography: 'body3', fontWeight: 500 }}
        >
          {t('Filter')}
        </Button>
        <Button
          onClick={onReset}
          fullWidth
          variant='outlined'
          color='inherit'
          sx={{ maxWidth: 80, typography: 'body3', fontWeight: 500, borderColor: 'grey.400' }}
        >
          {t('Reset')}
        </Button>
      </Stack>
    </ComplexToolbar>
  );
};

export default CryptoTransactionToolbar;
