import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Stack, Typography, useTheme } from '@mui/material';
import ModalContainer from '../../../components/ModalContainer';
import SymbolIcon from '../../../components/SymbolIcon';

const DediactedWalletTableRowViewModal = ({ open, onClose, data }) => {

  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <ModalContainer open={open} onClose={onClose} title={'Details'} width={600}>
      <Stack sx={{ mt: 4.5, alignItems: 'center' }}>
        <SymbolIcon symbol={data?.symbol} width={80} height={80} />
        <Typography variant='body2' color={'grey.100'} fontWeight={500} sx={{ mt: 2.5, mb: 2 }}>

          {data?.symbol}
        </Typography>

        <Stack width={'80%'} justifyContent={'space-between'} direction={'row'} alignItems={'center'} spacing={4}>
          <Typography variant='body3' color={'grey.400'}  >
            {t('Name')}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {data?.name || '-'}
          </Typography>
        </Stack>


        <Stack width={'80%'} justifyContent={'space-between'} direction={'row'} alignItems={'center'} spacing={4}>
          <Typography variant='body3' color={'grey.400'}  >
            {t('Network')}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {data?.network || '-'}
          </Typography>
        </Stack>

        <Stack width={'80%'} justifyContent={'space-between'} direction={'row'} alignItems={'center'} spacing={4}>
          <Typography variant='body3' color={'grey.400'}  >
            {t('Withdraw Fee')+" (USDT)"}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {data?.withdrawFee || '-'}
          </Typography>
        </Stack>

        <Stack width={'80%'} justifyContent={'space-between'} direction={'row'} alignItems={'center'} spacing={4}>
          <Typography variant='body3' color={'grey.400'}  >
            {t('Contract Address')}
          </Typography>
          <Typography variant='body4' color={'grey.100'}>
            {data?.contractAddress || '-'}
          </Typography>
        </Stack>


        <Stack width={'80%'} justifyContent={'space-between'} direction={'row'} alignItems={'center'} spacing={4}>
          <Typography variant='body3' color={'grey.400'}  >
            {t('Decimal')}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {data?.decimal || '-'}
          </Typography>
        </Stack>

      </Stack>
    </ModalContainer>
  );
};

export default DediactedWalletTableRowViewModal;