import { TableRow, TableCell, Typography, Stack, Box } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import CopyItem from '../../../../components/CopyItem';
import SymbolIcon from '../../../../components/SymbolIcon';
import { prettyUppercases } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../components/Iconify';
import React from 'react';

// ----------------------------------------------------------------------

// const STATUS = [...DEPOSIT_STATUS, ...WITHDRAW_STATUS];

export default function CryptoTransactionTableRow({ row, isAdmin = false }) {
  const language = useSelector((store) => store.language);
  const { t } = useTranslation();

  const {
    symbol,
    firstName,
    lastName,
    emailAddress,
    mobile,
    address,
    transactionId,
    amount,
    createDate,
    transactionType,
    status,
    userId,
    origin
  } = row;
  // const foundStatus = STATUS.find((s) => s.value === status);

  return (
    <TableRow hover sx={{ '& td': { py: 2, typography: 'body2', color: 'grey.100' } }}>
      <TableCell>{moment(createDate).locale(language).format('YYYY-MM-DD HH:mm')}</TableCell>

      {isAdmin && (
        <TableCell>
          <Typography variant='body3'>
            {firstName} {lastName}
          </Typography>
          {emailAddress && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {emailAddress}
            </Typography>
          )}
          {mobile && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {mobile}
            </Typography>
          )}
        </TableCell>
      )}

      <TableCell>{transactionType}</TableCell>

      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ width: 80 }}>
          <SymbolIcon symbol={symbol} />
          <Typography variant='body3' noWrap sx={{ color: 'grey.100' }}>
            {symbol}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell >{amount}</TableCell>


        <TableCell>
          <Stack direction={'row'} alignItems={'center'} >
            {userId? userId : '-'}
            <Iconify icon='iconamoon:arrow-up-2-duotone' color='grey.0' sx={{ width: 22, height: 22 , rotate:'90deg' , mb:'2px'}} />
            {origin ? origin : '-'}
          </Stack>
        </TableCell>


      {/*{*/}
      {/*  !isAdmin &&*/}
      {/*  <TableCell>*/}
      {/*    <CopyItem value={address} textVariant='body4' />*/}
      {/*  </TableCell>*/}
      {/*}*/}

      <TableCell>
        <CopyItem value={transactionId} textVariant='body4' />
      </TableCell>

      {/*<TableCell>{status}</TableCell>*/}
      <TableCell sx={{ width: 120 }} align='center'>
        <Box
          sx={{
            width: 100,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            p: 1,
            bgcolor:
              (status === 'SUCCESS' && 'rgba(48, 224, 161, 0.1)') ||
              (status === 'Completed' && 'rgba(48, 224, 161, 0.1)') ||
              (status === 'FAILED' && 'rgba(224, 48, 48, 0.1)') ||
              'rgba(250, 174, 34, 0.1)',
          }}
        >
          <Typography
            variant='body3'
            sx={{
              color:
                (status === 'SUCCESS' && '#30E0A1') ||
                (status === 'Completed' && '#30E0A1') ||
                (status === 'FAILED' && 'negative.main') ||
                'rgb(218, 146, 13)',
              lineHeight: 1,
            }}
          >
            {t(prettyUppercases(status))}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
}
