import { TableRow, TableCell, Typography, IconButton, Stack, Box } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import { prettyUppercases } from '../../../../utils';
import Iconify from '../../../../components/Iconify';
import { useTranslation } from 'react-i18next';
import SymbolIcon from '../../../../components/SymbolIcon';

// ----------------------------------------------------------------------

export default function TradeHistoryTableRow({ row, onShowModalDetail, isAdmin = false }) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const {
    createdDate,
    firstName,
    lastName,
    emailAddress,
    mobile,
    symbol,
    side,
    type,
    price,
    amount,
    executed,
    total,
    state,
    commissionAsset,
    // transactionDate,
    // orderId,
    // updateTime,
    // isMaker,
    // commissionAsset,
    // stopPrice,
  } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 2, typography: 'body2', color: 'grey.100' } }}>
      <TableCell>{moment(createdDate).locale(language).format('YYYY-MM-DD HH:mm')}</TableCell>

      {isAdmin && (
        <TableCell>
          <Typography variant='body3'>
            {firstName} {lastName}
          </Typography>
          {emailAddress && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {emailAddress}
            </Typography>
          )}
          {mobile && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {mobile}
            </Typography>
          )}
        </TableCell>
      )}

      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ width: 140 }}>
          {commissionAsset && <SymbolIcon symbol={commissionAsset} />}
          <Typography variant='body3' noWrap sx={{ color: 'grey.100' }}>
            {symbol}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell align='center'>{type}</TableCell>

      <TableCell align='center'>
        <Typography variant='body2' color={side === 'BUY' ? 'positive.main' : 'negative.main'}>
          {side}
        </Typography>
      </TableCell>

      <TableCell align='center'>{price}</TableCell>

      <TableCell align='center'>{amount}</TableCell>

      <TableCell align='center'>{executed}</TableCell>

      <TableCell align='center'>{total}</TableCell>

      <TableCell sx={{ width: 120 }} align='center'>
        <Box
          sx={{
            width: 100,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            p: 1,
            bgcolor:
              (state === 'FILLED' && 'rgba(48, 224, 161, 0.1)') ||
              (state === 'CANCELED' && 'rgba(224, 48, 48, 0.1)') ||
              'rgba(250, 174, 34, 0.1)',
          }}
        >
          <Typography
            variant='body3'
            sx={{
              color:
                (state === 'FILLED' && '#30E0A1') || (state === 'CANCELED' && 'negative.main') || 'rgb(218, 146, 13)',
              lineHeight: 1,
            }}
          >
            {t(prettyUppercases(state))}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align='center' sx={{ width: 100 }}>
        <IconButton onClick={onShowModalDetail}>
          <Iconify icon='ph:link-duotone' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
