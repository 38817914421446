import { useEffect } from 'react';
import * as Yup from 'yup';
import moment from 'jalali-moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { getValue } from '@testing-library/user-event/dist/utils';

export default function useUserForm(userInfo, updateProfile = false, updatePolicy = false) {
  const { t } = useTranslation();

  const [avatar, setAvatar] = useState(null);
  const [bill, setBill] = useState(null);
  const [passport, setPassport] = useState(null);

  const validators = Yup.object().shape({
    ...(updateProfile && {
      middleName: Yup.string().notRequired(),
      countryId: Yup.string().required(t('Country is required')),
      address: Yup.string().notRequired(),
      birthday: Yup.string().required(t('Birthday is required')),
      postalCode: Yup.string().notRequired(),
      cityName: Yup.string().notRequired(),
      phoneNumber: Yup.string().notRequired(),
      password: Yup.string().notRequired(),
    }),
    ...(updatePolicy && {
      authenticationLevel: Yup.string().required(t('Select Authentication Level')),
      changePassword: Yup.string().required(t('Select Change Password State')),
      twoStepLogin: Yup.string().required(t('Select Two Step Login State')),
    }),
    ...(!updateProfile &&
      !updatePolicy && {
        status: Yup.string().required(t('Select Approve or Reject status')),
      }),
  });

  let status = '';
  if (userInfo?.authenticationLevel) {
    if (userInfo.authenticationLevel.indexOf('REJECTED') !== -1) {
      status = 'reject';
    } else if (userInfo.authenticationLevel.indexOf('REQUESTED') === -1) {
      status = 'approve';
    }
  }

  const defaultValues = {
    firstName: userInfo?.firstName || '',
    lastName: userInfo?.lastName || '',
    middleName: userInfo?.middleName || '',
    countryId: userInfo?.countryId || '',
    address: userInfo?.address || '',
    birthday: userInfo?.birthday || null,
    postalCode: userInfo?.postalCode || '',
    cityName: userInfo?.cityName || '',
    phoneNumber: userInfo?.phoneNumber || '',
    authCard: userInfo?.authCard || 'passport',
    amount: userInfo?.amount?.toLocaleString('en-US') || '',
    authenticationLevel: userInfo?.authenticationLevel || '',
    changePassword: userInfo?.changePassword ? t('Active') : t('Inactive'),
    twoStepLogin: userInfo?.twoStepLogin ? t('Active') : t('Inactive'),
    mobile: userInfo?.mobile || '',
    avatar: userInfo?.avatar,
    passport: userInfo?.passport,
    bill: userInfo?.bill,
    emailAddress: userInfo?.emailAddress || '',
    status,
    softOtpAuthEnable: userInfo?.softOtpAuthEnable || false,
    emailAuthEnable: userInfo?.emailAuthEnable || false,
    smsAuthEnable: userInfo?.smsAuthEnable || false,
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    getValues,
    reset,
  } = methods;

  const handleDropAvatar = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setAvatar(file);

        setValue(
          'avatar',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const handleDropPassport = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setPassport(file);

        setValue(
          'passport',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const handleDropBill = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setBill(file);

        setValue(
          'bill',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  useEffect(() => {
    if (userInfo) {
      reset(defaultValues);
    }
    if (!userInfo) {
      reset(defaultValues);
    }
  }, [userInfo]);

  return {
    handleSubmit,
    isSubmitting,
    methods,
    avatar,
    handleDropAvatar,
    handleDropBill,
    handleDropPassport,
  };
}
