import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Card, Stack, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { FormProvider, RHFNumberField, RHFSwitch, RHFTextField } from '../../../../components/hookForm';
import usePaySafeForm from './hooks/usePaySafeForm';
import usePaySafeSubmit from './hooks/usePaySafeSubmit';
import Image from '../../../../components/Image';
import paySafeLogo from '../../../../assets/inventories/paySafeCard.png';
import { PageTitle } from '../../../../components/PageTitle';
import Api from '../../../../http/adminHttp/serviceApiAdmin';

const PaySafeSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const { getValues, handleSubmit, isSubmitting, methods , watch } = usePaySafeForm(data);
  const { onSubmit } = usePaySafeSubmit();

  const fetchPerfectMoneyData = async () => {
    const response = await Api.getPaySafeData();
    if (response.status === 'SUCCESS') {
      setData(response);
    }
  };
  const queryPerfectMoneyPrice = watch('queryPerfectMoneyPrice')
  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Settings related to Pay Safe Voucher' },
    });
  }, []);

  useEffect(() => {
    fetchPerfectMoneyData();
  }, []);
  return (
    <Stack sx={{ padding: { xs: '0 20px 20px', lg: '0 0 97px' } }}>
      <FormProvider methods={methods} onSubmit={handleSubmit((...formData) => onSubmit(data, ...formData))}>
        <Card sx={{ mt: 5, px: { xs: 2, sm: 4 }, pt: { xs: 3, sm: 4 }, pb: { xs: 3, sm: 5.4 } }}>
          <Stack spacing={{ xs: 1.5, sm: 2.5 }} direction='row' sx={{ mb: 5 }} alignItems='center'>
            <Image src={paySafeLogo} sx={{ width: 48, height: 48 }} />
            <Box>
              <PageTitle title='Settings related to Pay Safe' subTitle='Caption' />
            </Box>
          </Stack>

          <Stack justifyContent={'space-between'} direction='row' sx={{ width: { xs: '100%', md: '65%' } }}>
            <Box width={'45%'}>
              <RHFTextField name={'accountID'} label={t('AccountID')} variant='standard' fullWidth />
            </Box>
            <Box width={'45%'}>
              <RHFTextField name={'password'} label={t('Password')} variant='standard' fullWidth />
            </Box>
          </Stack>

          <Stack
            justifyContent={'space-between'}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={'center'}
            sx={{ width: { xs: '100%', md: '65%' }, mt: 3 }}
          >
            <Box width={{ xs: '100%', sm: '45%' }}>
              <RHFTextField name={'walletId'} label={t('Wallet ID')} variant='standard' fullWidth />
            </Box>
            <Box width={{ xs: '100%', sm: '45%' }}>
              <Stack direction='row' alignItems='center' spacing={2} sx={{ mt: { xs: 4, sm: 2 } }}>
                <RHFSwitch name='queryPerfectMoneyPrice' />
                <Typography variant='body3'>{t('Query the price of dollars from the api')}</Typography>
              </Stack>
            </Box>
          </Stack>
        </Card>

        <Card sx={{ mt: 5, px: { xs: 2, sm: 4 }, py: { xs: 3, sm: 5.4 } }}>
          <Box>
            <PageTitle title={t('Pay Safe')} />
          </Box>

          <Stack direction='row' sx={{ my: 3, width: { xs: '100%', md: '65%' } }} justifyContent='space-between'>
            <Stack direction='row' alignItems='center' spacing={1} width={{ xs: '50%', sm: '45%' }}>
              <RHFSwitch name='purchaseStatus' />
              <Typography variant='body3'>{t('Purchase status')}</Typography>
            </Stack>
            <Stack direction='row' alignItems='center' spacing={1} width={'45%'}>
              <RHFSwitch name='saleStatus' />
              <Typography variant='body3'>{t('Sales status')}</Typography>
            </Stack>
          </Stack>

          <Stack direction='row' sx={{ my: 3, width: { xs: '100%', md: '65%' } }} justifyContent='space-between'>
            <Stack width={'45%'} spacing={3}>
              <RHFNumberField
                name={'purchaseDollarPrice'}
                label={t('Purchase price in dollars')}
                variant='standard'
                fullWidth
                disabled={getValues('queryPerfectMoneyPrice')}
              />
              <RHFNumberField
                name='purchasePercentage'
                label={t('Percentage on the price to buy')}
                variant='standard'
                fullWidth

              />
            </Stack>
            <Stack width={'45%'} spacing={3}>
              <RHFNumberField
                name={'saleDollarPrice'}
                label={t('Selling price in dollars')}
                variant='standard'
                fullWidth
                disabled={getValues('queryPerfectMoneyPrice')}
              />
              <RHFNumberField
                name={'salePercentage'}
                label={t('Percentage in price for sale')}
                variant='standard'
                fullWidth
              />
            </Stack>
          </Stack>

          {/*<Stack justifyContent={'space-between'} direction='row' sx={{ width: { xs: '100%', md: '65%' } }}>*/}
          {/*  <Box width={'45%'}>*/}
          {/*    <RHFNumberField*/}
          {/*      name={'purchasePercentageMarketing'}*/}
          {/*      label={t('Percentage in the price for ( marketing )')}*/}
          {/*      variant='standard'*/}
          {/*      fullWidth*/}
          {/*    />*/}
          {/*  </Box>*/}
          {/*  <Box width={'45%'}>*/}
          {/*    <RHFNumberField*/}
          {/*      name={'salePercentageMarketing'}*/}
          {/*      label={t('Percentage in the price for ( marketing )')}*/}
          {/*      variant='standard'*/}
          {/*      fullWidth*/}
          {/*    />*/}
          {/*  </Box>*/}
          {/*</Stack>*/}
        </Card>

        <Card sx={{ mt: 2.7, px: { xs: 2, sm: 4 }, py: { xs: 3, sm: 4 } }}>
          <PageTitle title={t('Pay Safe Voucher')} />

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ mt: 5, mb: 3, width: { xs: '100%', md: '66%' } }}
            justifyContent='space-between'
            spacing={{ xs: 2, sm: 0 }}
          >
            <Stack direction='row' alignItems='center' spacing={1} width={{ xs: '100%', sm: '51%' }}>
              <RHFSwitch name='voucherPurchaseStatus' />
              <Typography variant='body3'>{t('Purchase status of Pay Safe')}</Typography>
            </Stack>
            <Stack direction='row' alignItems='center' spacing={1} width={{ xs: '100%', sm: '44%' }}>
              <RHFSwitch name='voucherSaleStatus' />
              <Typography variant='body3'>{t('Pay Safe sales status')}</Typography>
            </Stack>
          </Stack>

          <Stack justifyContent={'space-between'} direction='row' sx={{ width: { xs: '100%', md: '65%' } }}>
            <Box width={'45%'}>
              <RHFNumberField
                name={'voucherPurchasePercentage'}
                label={t('Percentage in the price for purchase')}
                variant='standard'
                fullWidth
              />
            </Box>
            <Box width={'45%'}>
              <RHFNumberField
                name={'voucherSalePercentage'}
                label={t('Percentage in the price for sale')}
                variant='standard'
                fullWidth
              />
            </Box>
          </Stack>
        </Card>

        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ mt: 2, maxWidth: { xs: '100%', sm: 283 } }}
        >
          {t('Save')}
        </LoadingButton>
      </FormProvider>
    </Stack>
  );
};

export default PaySafeSettings;
