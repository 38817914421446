import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, Card, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useEffect } from 'react';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { PageTitle } from '../../../../components/PageTitle';
import { RHFTextField, FormProvider, RHFSwitch } from '../../../../components/hookForm';
import useDepositSettingForm from './hooks/useDepositSettingForm';
import useDepositSettingSubmit from './hooks/useDepositSettingSubmit';
import BankList from './bankList';
import TransferCard from './transferCard';
import Api from '../../../../http/adminHttp/serviceApiAdmin';

const DepositSetting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [bankList, setBankList] = useState([]);
  const [transferList, setTransferList] = useState([]);
  const [depositData, setDepositData] = useState({
    active: false,
    family: '',
    name: '',
    depositReceiptBankAccounts: [],
    depositReceiptTransferMethods: [],
  });

  const { handleSubmit, isSubmitting, methods, isActive } = useDepositSettingForm(depositData);
  const { onSubmit } = useDepositSettingSubmit(bankList, transferList, setDepositData);

  const fetchDepositReceiptSettings = async () => {
    const response = await Api.getDepositReceiptSettings();
    if (response.status === 'SUCCESS') {
      setDepositData({
        active: response.active,
        family: response.family,
        name: response.name,
        depositReceiptBankAccounts: response.depositReceiptBankAccounts.map((d) => ({
          ...d,
          id: uuidv4(),
          isNew: false,
        })),
        depositReceiptTransferMethods: response.depositReceiptTransferMethods.map((d) => ({
          ...d,
          id: uuidv4(),
          isNew: false,
        })),
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Deposit settings with receipt' },
    });

    fetchDepositReceiptSettings();
  }, []);

  return (
    <Stack sx={{ padding: { xs: '0 25px 97px', lg: '0 0 97px' } }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: '40px 0 12px 0', px: { xs: 2, sm: 4 }, py: { xs: 3, sm: 4 } }}>
          <PageTitle title='Deposit settings with receipt' subTitle='Caption' />

          <Stack direction='row' alignItems='center' spacing={1} sx={{ mt: 5, mb: 2.25 }}>
            <RHFSwitch name='active' />
            <Typography variant='body3'>{isActive ? t('Active') : t('Disable')}</Typography>
          </Stack>

          <Box sx={{ width: { xs: '100%', md: '30%' }, mt: 1 }}>
            <Stack direction='row' spacing={2}>
              <RHFTextField name='name' label={t("Account Holder's name")} variant='standard' fullWidth />
              <RHFTextField name='family' label={t('Family')} variant='standard' fullWidth />
            </Stack>
          </Box>
        </Card>

        <Card sx={{ my: 1.5, px: { xs: 2, sm: 4 }, py: { xs: 3, sm: 4 } }}>
          <BankList data={depositData.depositReceiptBankAccounts} bankList={bankList} setBankList={setBankList} />
        </Card>

        <Card sx={{ mt: 1.5, mb: 5, px: { xs: 2, sm: 4 }, py: { xs: 3, sm: 4 } }}>
          <TransferCard
            data={depositData.depositReceiptTransferMethods}
            transferList={transferList}
            setTransferList={setTransferList}
          />
        </Card>

        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ maxWidth: 283 }}
        >
          {t('Save')}
        </LoadingButton>
      </FormProvider>
    </Stack>
  );
};

export default DepositSetting;
