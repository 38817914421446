import React, { useEffect, useState } from 'react';
import { Button, Card, Divider, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { FormProvider, RHFSwitch, RHFTextField } from '../../../../components/hookForm';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { PageTitle } from '../../../../components/PageTitle';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { useDispatch } from 'react-redux';
import CustomSwitch from '../../../../components/CustomSwitch';
import { css } from 'styled-components';
import Iconify from '../../../../components/Iconify';

export const MainWalletSetting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [canEdit , setCanEdit] = useState(true)
  const [canEditTron , setCanEditTron] = useState(true)

  const [views , setViews] = useState({
    mainWalletAddress : false ,
    mainWalletPrivateKey : false ,
    gasPumpAddress : false ,
    gasPumpPrivateKey : false ,
    tronGasPumpAddress: false,
    tronGasPumpPrivateKey: false,
    tronMainWalletAddress: false,
    tronMainWalletPrivateKey: false
  })

  const validators = Yup.object().shape({
    mainWalletAddress: Yup.string().notRequired(),
    mainWalletPrivateKey: Yup.string().notRequired(),
    gasPumpAddress: Yup.string().notRequired(),
    gasPumpPrivateKey: Yup.string().notRequired(),

    tronGasPumpAddress: Yup.string().notRequired(),
    tronGasPumpPrivateKey: Yup.string().notRequired(),
    tronMainWalletAddress: Yup.string().notRequired(),
    tronMainWalletPrivateKey: Yup.string().notRequired(),


  });


  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Main Wallet Settings' },
    });
  }, []);


  const defaultValues = {
    mainWalletAddress: '',
    mainWalletPrivateKey: '',
    gasPumpAddress: '',
    gasPumpPrivateKey: '',
    tronGasPumpAddress: '',
    tronGasPumpPrivateKey: '',
    tronMainWalletAddress: '',
    tronMainWalletPrivateKey: ''

  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    control,


    formState: { isSubmitting },
  } = methods;



  const { enqueueSnackbar } = useSnackbar();


  const onSubmit = async (payload) => {
    const data = {
      ...payload,
    };
    try {
      let response;
      response = await Api.saveMainWalletAddress(data);

      if (response?.status === 'SUCCESS') {
        enqueueSnackbar(t('Successfully saved!'), { variant: 'success' });
      }
    } catch (err) {}
  };


  useEffect(()=>{
    const getData = async ()=>{
      try {
        const res = await Api.getMainWalletAddress()
        setValue('mainWalletAddress' ,res?.mainWalletAddress)
        setValue('mainWalletPrivateKey' ,res?.mainWalletPrivateKey)
        setValue('tronMainWalletAddress' ,res?.tronMainWalletAddress)
        setValue('tronMainWalletPrivateKey' ,res?.tronMainWalletPrivateKey)

        console.log(res)
      }catch (e) {

      }
    }

   getData()
  },[])
  return (
    <Stack sx={{ padding: { xs: '0 20px 20px', lg: '20px 0 97px' } }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card
          sx={{
            px: { xs: 2, md: 5 },
            py: { xs: 3, md: 5 },
          }}
         >
          <PageTitle
            title={'Ethereum, Polygon, Binance Networks (Listing Tokens)'}
            subTitle={
               ''
            }
          />
          <div style={{marginTop:"2rem"}}/>
          {/*<Stack direction='row' alignItems='center' spacing={1} sx={{mb:'1rem'}} >*/}
          {/*  <CustomSwitch value={canEdit} onChange={(e)=>setCanEdit(e?.target?.checked)} />*/}
          {/*  <Typography variant='body3'>{t('Can Edit')}</Typography>*/}
          {/*</Stack>*/}
          <Typography variant='subtitle3' sx={{  }}>
            {t('Main wallet addresses')}
          </Typography>
          <Grid container spacing={4} >
            <Grid item xs={12} md={12} lg={12}>
              <RHFTextField type={'text'}
                            InputProps={{
                              sx: css`
                  input {
                    /* @noflip */
                    direction: ltr;
                  }
                `
                            }}
                            name={'mainWalletAddress'} label={t('Main wallet address')} variant='standard' fullWidth disabled={!canEdit}/>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <RHFTextField
                type={'text'}
                InputProps={{
                  sx: css`
                  input {
                    /* @noflip */
                    direction: ltr;
                  }
                `
                }}
                name={'mainWalletPrivateKey'}
                label={t('Main wallet private key')}
                variant='standard'
                fullWidth
                disabled={!canEdit}
              />
            </Grid>

          </Grid>

          <div style={{marginTop:"3rem"}}/>


        </Card>
          <Divider sx={{my:'1rem'}} />

        <Card>
          <PageTitle
            title={'Tron (Listing Tokens)'}
            subTitle={
              ''
            }
          />
          <div style={{marginTop:"2rem"}}/>
          {/*<Stack direction='row' alignItems='center' spacing={1} sx={{mb:'1rem'}} >*/}
          {/*  <CustomSwitch value={canEditTron} onChange={(e)=>setCanEditTron(e?.target?.checked)} />*/}
          {/*  <Typography variant='body3'>{t('Can Edit')}</Typography>*/}
          {/*</Stack>*/}
          <Typography variant='subtitle3' sx={{  }}>
            {t('Main wallet addresses')}
          </Typography>
          <Grid container spacing={4}>
                <Grid item xs={12} md={12} lg={12}>
                    <RHFTextField
                      type={'text'}

                      InputProps={{
                        sx: css`
                  input {
                    /* @noflip */
                    direction: ltr;
                  }
                `
                      }}

                      name={'tronMainWalletAddress'} label={t('Main wallet address')} variant='standard' fullWidth disabled={!canEditTron}/>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <RHFTextField
                    type={'text'}

                    InputProps={{
                      sx: css`
                  input {
                    /* @noflip */
                    direction: ltr;
                  }
                `
                    }}

                    name={'tronMainWalletPrivateKey'}
                    label={t('Main wallet private key')}
                    variant='standard'
                    fullWidth
                    disabled={!canEditTron}
                  />
                </Grid>
          </Grid>

          <div style={{marginTop:"1.5rem"}}/>


        </Card>


        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ maxWidth: 300, typography: 'body3', fontWeight: 500, mt: 5 }}
        >
          {t('Save')}
        </LoadingButton>
      </FormProvider>
    </Stack>
  );
};
