import { useTranslation } from 'react-i18next';
import { m } from 'framer-motion';
// @mui
import { Button, Container, Stack, TextField, Typography } from '@mui/material';
// components
import { RootSubStyle, StartedBorder, StartedBox, StartedContent, StartedShadow } from '../style';
import MotionViewport from '../../../components/animate/MotionViewport';

import { varFade } from '../../../components/animate/variants/fade';

// ----------------------------------------------------------------------

export default function GetStarted() {
  const { t } = useTranslation();

  return (
    <RootSubStyle sx={{ position: 'relative', pt: { xs: 8.5, md: 0 } }}>
      <StartedShadow />

      <Container component={MotionViewport}>
        <m.div variants={varFade().in}>
          <StartedBox>
            <StartedContent>
              <Typography variant='h4_secondary' sx={{ fontWeight: 500 }}>
                {t('New users can earn up to {{amount}} in crypto rewards.', { amount: '$100' })}
              </Typography>

              <Typography variant='h6' color='grey.100' sx={{ mt: 2.5, fontWeight: 400 }}>
                {t('Ready to start trading cryptocurrency?')}
              </Typography>

              <Stack spacing={4} sx={{ mt: { xs: 1.5, md: 7 } }}>
                <TextField
                  variant='standard'
                  label={t('Enter you email address')}
                  sx={{ width: { xs: '95%', md: 354 } }}
                />

                <Button variant='contained' color='success' sx={{ width: 168, height: 45 }}>
                  <Typography variant='subtitle1'>{t('Get Started')}</Typography>
                </Button>
              </Stack>
            </StartedContent>

            <StartedBorder />
          </StartedBox>
        </m.div>
      </Container>
    </RootSubStyle>
  );
}
