import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Row, Col, InputNumber } from 'antd';
import classes from './adminVerifyEmailForm.module.scss';
import Api from '../../../http/adminHttp/serviceApiAdmin';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SET_USERINFO } from '../../../store/actionTypes';
import { setTokenAdmin } from '../../../http/adminHttp/tokenHandlerAdmin';
import { useSnackbar } from 'notistack';
import { navigationUrl } from '../../../navigationUrl';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTranslation } from 'react-i18next';

const AdminVerifyEmailForm = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [digit1, setDigit1] = useState('');
  const [digit2, setDigit2] = useState('');
  const [digit3, setDigit3] = useState('');
  const [digit4, setDigit4] = useState('');
  const [digit5, setDigit5] = useState('');
  const [form] = Form.useForm();
  const Ref = useRef(null);
  const [timer, setTimer] = useState('00:00:00');
  const [firstLoad, setFirstLoad] = useState(true);
  const [savedEmail, setSavedEmail] = useState('');
  const [savedOtp, setSavedOtp] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const focusTo = (elementName) => {
    let element = document.getElementById(elementName);
    element.focus();
  };

  useEffect(() => {
    focusTo('digit1');
  }, digit1 === null);

  useEffect(() => {
    clearTimer(getDeadTime());
    setFirstLoad(false);
    setSavedEmail(getSavedEmail());
    setSavedOtp(getSavedOtp());
  }, firstLoad);

  const onDigit1Change = (value) => {
    setDigit1(value);

    if (value != null) {
      focusTo('digit2');
    }
  };
  const onDigit2Change = (value) => {
    setDigit2(value);

    if (value != null) {
      focusTo('digit3');
    } else {
      focusTo('digit1');
    }
  };
  const onDigit3Change = (value) => {
    setDigit3(value);

    if (value != null) {
      focusTo('digit4');
    } else {
      focusTo('digit2');
    }
  };
  const onDigit4Change = (value) => {
    setDigit4(value);

    if (value != null) {
      focusTo('digit5');
    } else {
      focusTo('digit3');
    }
  };
  const onDigit5Change = (value) => {
    setDigit5(value);

    if (value != null) {
    } else {
      focusTo('digit4');
    }
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        let otpArray = [values.digit1, values.digit2, values.digit3, values.digit4, values.digit5, values.digit6];
        let strOtp = '';
        for (var i = 0; i < otpArray.length; i++) {
          strOtp += otpArray[i];
        }

        const data = {
          emailAddress: location?.state?.emailAddress,
          otp: strOtp,
        };
        loginTwoFaVerify(data);
      })
      .catch((errorInfo) => {});
  };

  const loginTwoFaVerify = async (data) => {
    const response = await Api.loginTwoFaVerify(data);
    console.log('loginTwoFaVerify: ', response);
    if (response?.status === 'SUCCESS') {
      setTokenAdmin(response.token);
      dispatch({
        type: SET_USERINFO,
        payload: {
          userInfo: response.userInfo,
        },
      });
      navigate(navigationUrl.admin);
    }
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : '0' + hours) +
          ':' +
          (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer('00:02:00');
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 120);
    return deadline;
  };

  const getSavedEmail = () => {
    return window.localStorage.getItem('emailAddress_register');
  };
  const getSavedOtp = () => {
    return window.localStorage.getItem('otp_register');
  };

  const onRequestAgainClick = async () => {
    const response = await Api.resendOtp(savedEmail);
    console.log(response);
    if (response?.status === 'SUCCESS') {
      clearTimer(getDeadTime());
      enqueueSnackbar('message: OTP code has been sent.', { variant: 'success' });
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.formTitleDiv}>
        <span className={classes.formTitleBig}>{t('Verify Email')}</span>
        <span className={classes.formTitleSmall}>
          t(`Code is sent to ${savedEmail} - ${savedOtp}`)
        </span>
      </div>

      <Form
        layout='vertical'
        form={form}
        name='verify'
        initialValues={{
          remember: false,
        }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <Row justify='space-between'>
          <Col span={4}>
            <Form.Item name='digit1'>
              <InputNumber
                id='digit1'
                size='large'
                min={0}
                max={9}
                value={digit1}
                maxLength={1}
                onChange={onDigit1Change}
                className={digit1 ? classes.textboxFilled : classes.textbox}
                autoComplete='off'
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='digit2'>
              <InputNumber
                id='digit2'
                size='large'
                min={0}
                max={9}
                value={digit2}
                maxLength={1}
                onChange={onDigit2Change}
                className={digit2 ? classes.textboxFilled : classes.textbox}
                autoComplete='off'
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='digit3'>
              <InputNumber
                id='digit3'
                size='large'
                min={0}
                max={9}
                value={digit3}
                maxLength={1}
                onChange={onDigit3Change}
                className={digit3 ? classes.textboxFilled : classes.textbox}
                autoComplete='off'
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='digit4'>
              <InputNumber
                id='digit4'
                size='large'
                min={0}
                max={9}
                value={digit4}
                maxLength={1}
                onChange={onDigit4Change}
                className={digit4 ? classes.textboxFilled : classes.textbox}
                autoComplete='off'
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='digit5'>
              <InputNumber
                id='digit5'
                size='large'
                min={0}
                max={9}
                value={digit5}
                maxLength={1}
                onChange={onDigit5Change}
                className={digit5 ? classes.textboxFilled : classes.textbox}
                autoComplete='off'
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name='timer'>
          <span className={classes.dontRecieveCode}>{timer}</span>
        </Form.Item>

        <Form.Item name='recieveCode'>
          <span className={classes.dontRecieveCode}>{t('Didn’t recieve code?')}</span>
          <Button type='text' onClick={onRequestAgainClick}>
            {t('Request Again')}
          </Button>
          {/* <a className={classes.requestAgain} href="">
                        Request Again
                    </a> */}
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit' block shape='round'>
            {t('Register')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminVerifyEmailForm;
