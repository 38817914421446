import { Box, Button, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../../components/DatePicker';
import SymbolIcon from '../../../../components/SymbolIcon';
import TabChart from './tabChart';
import { getDefaultDateFilterValues } from '../../../../utils/filter';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import moment from 'jalali-moment';
import { exportExcel } from '../../../../utils/excel';
import { useSelector } from 'react-redux';
import { exportPDF } from '../../../../utils/pdf';
import { BadgeIcon } from '../style';

export default function CryptoStatisticsTab() {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);
  const [data , setData] = useState([])
  const TYPES = [
    { label: t('Buy'), value: 'buy', color: '#30E0A1' },
    { label: t('Sell'), value: 'cell', color: '#CA3211' },
  ];
  const [filters, setFilters] = useState({
    symbol: 'BTC',
    startTime: getDefaultDateFilterValues().firstDay,
    endTime: getDefaultDateFilterValues().lastDay,
  });
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);

  const [allCharts , setAllCharts] = useState([])
  const getData = async () => {
    if(filters.symbol === 'ALL'){
      getAllCHartsData()
    }else {
      const response = await Api.getReportCryptoChartStatistics({
        ...filters,
        startTime: filters.startTime?.valueOf?.(),
        endTime: filters.endTime?.valueOf?.()
      });

      const datas = [
        {
          name: t('Crypto Buy'),
          type: 'line',
          data: response?.cryptoBuy?.map((t) => t.sum) || [],
          color: '#30E0A1',
        },
        {
          name: t('Crypto Sell'),
          type: 'line',
          data: response?.cryptoSell?.map((t) => t.sum) || [],
          color: '#CA3211',
        },
      ];

      setChartData(datas);
      setChartLabels(response?.crypto?.map((t) => moment(t.date).format('YYYY MMM D')));
    }


  };

  const getChartsAll = async (f) =>{
    try {
      const response = await Api.getReportCryptoChartStatistics(f);

      const datas = [
        {
          name: t('Crypto Buy'),
          type: 'line',
          data: response?.cryptoBuy?.map((t) => t.sum) || [],
          color: '#30E0A1',
        },
        {
          name: t('Crypto Sell'),
          type: 'line',
          data: response?.cryptoSell?.map((t) => t.sum) || [],
          color: '#CA3211',
        },
      ];

      const chartLabel = response?.crypto?.map((t) => moment(t.date).format('YYYY MMM D'))

      return {symbol:f.symbol , chartData: datas , chartLabels :chartLabel}
    }catch (e) {
      return
    }

  }



  const getAllCHartsData = async () =>{
    const allData = ['BTC' , 'ETH' , 'USDT' , "DOGE" , 'SHIB']
    let b = []
    for (let i in allData){

      const a = await getChartsAll({
        symbol : allData[i],
        startTime: filters.startTime?.valueOf?.(),
        endTime: filters.endTime?.valueOf?.()
      })

      b.push(a)
    }
    setAllCharts(b)
  }
  useEffect(() => {
    getData();
  }, []);

  const fetchActiveSymbols = async () => {

    try {
      const data = await Api.getActiveSymbols();

      if (data.status === 'SUCCESS') {
        // setDataSource(data.currencyInfos);
        // setDataAllowSource(data.currencyInfos);
        // setFiltered(data.currencyInfos);
        setData(data.currencyInfos)
      }
    } catch (e) {}
    // setLoading(false);
  };

  // -------------------------
  useEffect(() => {
    fetchActiveSymbols();
  }, []);
  const [isExportLoading, setIsExportLoading] = useState(false);

  // --------------------
  const onExport = async (type = 'Excel') => {
    if (chartData?.length === 0) return;

    setIsExportLoading(true);

    const list = [];
    chartData[0].data.forEach((d, index) => {
      list.push([chartLabels[index], ...chartData.map((cd) => cd.data[index])]);
    });

    try {
      let fileName = t('Crypto Statistics');

      const config = {
        language,
        fileName: `${fileName} - ${moment(filters.startTime).locale(language).format('YYYY-MM-DD')} - ${moment(
          filters.endTime
        )
          .locale(language)
          .format('YYYY-MM-DD')}`,
        heads: ['#', t('Date'), ...chartData.map((cd) => t(cd.name))],
        columnsWidth: [5, 19, 16, 20, 15, 21, 20],
        list,
      };
      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };


  return (
    <Grid container sx={{ mt: 5, mb: 2 }}>
      <Grid item xs={12} sm={5} md={4} sx={{ pl: 3 }}>
        <Stack spacing={4}>
          <Stack direction={'row'}>
            <DatePicker
              label={t('From')}
              value={filters.startTime}
              name='startTime'
              setValue={(startTime) => setFilters({ ...filters, startTime })}
              variant='standard'
              maxDate={filters.endTime}
              fullWidth
              sx={{ maxWidth: 150 }}
              InputProps={null}
            />
            <DatePicker
              label={t('To')}
              value={filters.endTime}
              setValue={(endTime) => setFilters({ ...filters, endTime })}
              variant='standard'
              minDate={filters.startTime}
              fullWidth
              sx={{ maxWidth: 150 }}
            />
          </Stack>

          <TextField
            select
            SelectProps={{
              native: false,
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 500, backgroundColor: 'black.0' } },
              },
            }}
            label={t('Select Coin')}
            variant='standard'
            fullWidth
            sx={{
              maxWidth: 300,
            }}
            value={filters.symbol}
            onChange={({ target: { value: symbol } }) => setFilters({ ...filters, symbol })}
          >
            <MenuItem value={'ALL'}>
              <Stack direction='row'>

                <Typography variant='body3' color='grey.300' sx={{ ml: 1 }}>
                  ALL
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem value={'BTC'}>
              <Stack direction='row'>
                <SymbolIcon symbol='BTC' />
                <Typography variant='body3' color='grey.300' sx={{ ml: 1 }}>
                  Bitcoin
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem value={'ETH'}>
              <Stack direction='row'>
                <SymbolIcon symbol='ETH' />
                <Typography variant='body3' color='grey.300' sx={{ ml: 1 }}>
                  Ethereum
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem value={'USDT'}>
              <Stack direction='row'>
                <SymbolIcon symbol='USDT' />
                <Typography variant='body3' color='grey.300' sx={{ ml: 1 }}>
                  Tether
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem value={'DOGE'}>
              <Stack direction='row'>
                <SymbolIcon symbol='DOGE' />
                <Typography variant='body3' color='grey.300' sx={{ ml: 1 }}>
                  Doge
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem value={'SHIB'}>
              <Stack direction='row'>
                <SymbolIcon symbol='SHIB' />
                <Typography variant='body3' color='grey.300' sx={{ ml: 1 }}>
                  Shiba
                </Typography>
              </Stack>
            </MenuItem>
            {
              data?.map((itm , idx)=>{
                return  <MenuItem value={itm?.symbol}>
                  <Stack direction='row'>
                    <SymbolIcon symbol={itm?.symbol} />
                    <Typography variant='body3' color='grey.300' sx={{ ml: 1 }}>
                      {itm?.name}
                    </Typography>
                  </Stack>
                </MenuItem>
              })
            }
          </TextField>
        </Stack>

        <Button
          fullWidth
          size='large'
          variant='contained'
          className='no-print'
          sx={{ maxWidth: 283, typography: 'body3', fontWeight: 500, mt: 23.7 }}
          onClick={() => getData()}
        >
          {t('Report')}
        </Button>
      </Grid>

      <Grid item xs={12} sm={7} md={8} sx={{ pr: 3, mt: { xs: 4, sm: 0 } }}>
        <Stack direction={'row'} spacing={3} justifyContent='flex-end'>
          {TYPES.reverse().map((TYPE, index) => (
            <Stack direction={'row'} spacing={0.5} key={index}>
              <Typography variant='body4' color='grey.100' sx={{ mt: 0.5 }}>
                {TYPE.label}
              </Typography>
              <BadgeIcon bgColor={TYPE.color} direction={'left'} />
            </Stack>
          ))}
        </Stack>
        <Box sx={{ mb: 2, mt: 1.5 }}>
          {
            filters.symbol === 'ALL' ?

              allCharts.map((item , idx)=>{
                return(
                  <div key={idx+1}>
                    <h3 style={{marginTop:"2rem"}}>
                      {item?.symbol}
                    </h3>
                    <TabChart chartData={item?.chartData} chartLabels={item?.chartLabels} label={`SUM ${item.symbol}`}/>

                  </div>
                )
              })


              :
              <TabChart chartData={chartData} chartLabels={chartLabels} label={`SUM ${filters.symbol}`} />
          }
        </Box>

        <Stack direction={'row'} justifyContent={'center'} spacing={4} className='no-print'>
          <Button
            color='inherit'
            size='large'
            variant='outlined'
            sx={{ width: 172, typography: 'body3', fontWeight: 500, color: 'grey.400' }}
            loading={isExportLoading}
            onClick={onExport}
          >
            {t('Excel')}
          </Button>
          <Button
            color='inherit'
            size='large'
            variant='outlined'
            sx={{ width: 172, typography: 'body3', fontWeight: 500, color: 'grey.400' }}
            onClick={() => window.print()}
          >
            {t('PDF')}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}
