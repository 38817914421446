import axios from 'axios';
import { Endpoint } from '../../configs/serviceConfig';
import { interceptError } from './admin-http-error.interceptor';
import { unwrapDataInterceptor } from './admin-unwrap-data.interceptor';

export const api = axios.create({
  baseURL: Endpoint,
  timeout: 180000,
});

interceptError();
unwrapDataInterceptor();

export default {
  get: api.get,
  post: api.post,
  put: api.put,
  delete: api.delete,
};
