const ar = {
    translation: {
  "Cryptizi": "كريبتيزي",
  "Hi, Welcome Back": "مرحبًا، مرحبًا بك مجددًا.",
  "Nice to meet you!": "سعدت بلقائك!",
  "Best Wallet For Your Coins": "أفضل محفظة لعملاتك",
  "Dashboard": "لوحة التحكم",
  "Wallet": "محفظة",
  "{{number}} Currencies": "{{number}} عملات",
  "Sign up": "التسجيل",
  "Log in": "تسجيل الدخول",
  "Search": "بحث",
  "No": "لا",
  "Yes": "نعم",
  "Balance": "الرصيد",
  "Income": "الدخل",
  "Expenses": "المصروفات",
  "Exchange": "التبادل",
  "Top Assets": "أعلى الأصول",
  "Transaction": "المعاملة",
  "All": "الكل",
  "Receive": "استلام",
  "Send": "إرسال",
  "Overall Growth": "النمو العام",
  "Open trade": "تداول مفتوح",
  "Market": "السوق",
  "Trade": "تداول",
  "P2P": "نقطة لنقطة",
  "E-Money": "الأموال الإلكترونية",
  "Convert": "تحويل مباشر",
  "Crypto": "شراء عملة رقمية",
  "Futures": "عقود آجلة",
  "Possibilities": "الإمكانيات",
  "Referrals & Income": "الإحالات والدخل",
  "Ticket": "تذكرة",
  "History": "التاريخ",
  "Authentication": "المصادقة",
  "Bank Card": "بطاقة بنكية",
  "Account settings": "إعدادات الحساب",
  "Academy": "الأكاديمية",
  "Security": "الأمان",
  "Identification": "التحقق من الهوية",
  "Referral": "الإحالة",
  "Verified": "تم التحقق",
  "Regular user": "مستخدم عادي",
  "Log out": "تسجيل الخروج",
  "Add New favorite": "إضافة مفضلة جديدة",
  "Total Balance": "الرصيد الإجمالي",
  "30 Days PNL": "ربح وخسارة خلال 30 يومًا",
  "Today PNL": "ربح وخسارة اليوم",
  "Withdraw": "سحب",
  "Deposit": "إيداع",
  "Your inventory": "مخزونك",
  "Rial": "ريال",
  "Coin": "عملة",
  "Total": "المجموع",
  "Available": "متاح",
  "In Order": "قيد التنفيذ",
  "BTC Value": "قيمة البيتكوين",
  "Action": "الإجراء",
  "Buy": "شراء",
  "Rows per page": "الصفوف لكل صفحة",
  "of": "من",
  
        "All Situations": "همه مواقف",
  "History of your orders or transactions": "تاريخ طلباتك أو معاملاتك",
  "You can enter the name and symbol of Coin, transaction ID, etc. in the search field":
    "يمكنك إدخال اسم العملة والرمز ومعرف المعاملة، إلخ في حقل البحث",
  "Spend": "",
  "Transaction requirements": "متطلبات المعاملة",
  "Convert ": "تحويل مباشر",

  "last 1hr": "آخر ساعة",
  "last 24hr": "آخر 24 ساعة",
  "last week": "الأسبوع الماضي",
  "last month": "الشهر الماضي",

  "Crypto convert": "تحويل مباشر للعملات الرقمية",
  "Futures Classic": "عقود آجلة كلاسيكية",
  "Token Listing": "قائمة الرموز",
  "Total Sell": "المبيعات الكلية",
  "APIs": "واجهات البرمجة",
  "iOS App": "تطبيق iOS",
  "Android App": "تطبيق Android",
  "Bank deposit": "إيداع بنكي",
  "Credit/Debit card": "بطاقة الائتمان/الخصم",
  "P2P trading": "تداول نقطة لنقطة",
  "Crytizi Convert": "تحويل مباشر لـ كريتيزي",
  "Spot": "نقطة",
  "Margin": "هوامش",
  "Markets": "الأسواق",
  "Strategy Trading": "تداول الاستراتيجية",
  "About Us": "حولنا",
  "Service": "الخدمة",
  "More": "المزيد",
  "Learn": "تعلم",
  "Community": "المجتمع",
  "About": "حول",
  "Contact us": "اتصل بنا",
  "Terms": "الشروط",
  "FAQ": "الأسئلة الشائعة",
  "Token listing": "قائمة الرموز",
  "Cryptizi Convert": "تحويل مباشر لـ كريتيزي",
  "Buy/Sell Crypto": "شراء/بيع العملات الرقمية",
  "USDⓈ-M Futures": "عقود آجلة USDⓈ-M",
  "Coin-M Futures": "عقود آجلة Coin-M",
  "Submit A request": "إرسال طلب",
  "Fees": "الرسوم",
  "Trading Rules": "قواعد التداول",
  "Browse Crypto Prices": "تصفح أسعار العملات الرقمية",
  "Cryptizi Academy": "أكاديمية كريتيزي",
  "Sign Up": "التسجيل",

  "We are the most trusted cryptocurrency platform.": "نحن أكثر منصات العملات الرقمية ثقة.",

  "View more prices": "عرض المزيد من الأسعار",

  "Build your crypto portfolio anywhere.": "انشئ محفظتك الرقمية في أي مكان.",

  "A powerful cryptocurrency exchange in your pocket. Buy, sell and trade crypto on the go.":
    "صرافة عملات رقمية قوية في جيبك. اشتري، بيع وتداول العملات الرقمية أثناء التنقل.",

  "Get the Cryptizi Wallet Mobile App Now!": "احصل على تطبيق محفظة كريبتيزي الجوالة الآن!",

  "The most trusted cryptocurrency platform.": "المنصة الأكثر ثقة للعملات الرقمية.",

  "Cryptizi has a variety of features that make it the best place to start trading.":
    "كريبتيزي لديها مجموعة من الميزات التي تجعلها أفضل مكان لبدء التداول.",

  "Buy and sell popular digital currencies, keep track of them in the one place.":
    "شراء وبيع العملات الرقمية الشهيرة، وتتبعها في مكان واحد.",

  "For added security, store your funds in a vault with time delayed withdrawals.":
    "لضمان الأمان، قم بتخزين أموالك في خزانة مع سحب بفترة زمنية مؤخرًا.",

  "Stay on top of the markets with the Cryptizi app for Android or iOS.":
    "ابق على اطلاع دائم على الأسواق باستخدام تطبيق كريبتيزي على نظامي Android أو iOS.",

  "Portofolio Manager": "مدير المحفظة",
  "Mobile Apps": "تطبيقات الجوال",

  "One click, instant payout with credit or debit card.": "دفع فوري بنقرة واحدة باستخدام بطاقة الائتمان أو الخصم.",

  "Become a crypto owner in minutes using your debit or credit card and quickly purchase top cryptocurrencies.":
    "كن مالكًا للعملات الرقمية في دقائق باستخدام بطاقة الخصم أو الائتمان واشتري بسرعة العملات الرقمية الرائدة.",

  "Intermediate Verification": "التحقق المتوسط",
  "Intermediate Verified": "تم التحقق المتوسط.",
  "Additional Information": "معلومات إضافية",
  "OK": "موافق",
  "Upgrade to Higher Limits": "الترقية إلى حدود أعلى",

  "New users can earn up to {{amount}} in crypto rewards.":
    "يمكن للمستخدمين الجدد كسب ما يصل إلى {{amount}} في مكافآت العملات الرقمية.",

  "Ready to start trading cryptocurrency?": "هل أنت جاهز لبدء تداول العملات الرقمية؟",

  "Enter you email address": "أدخل عنوان بريدك الإلكتروني",
  "Get Started": "البدء",

  "Your guide to the world of an open financial system. Get started with the easiest and most secure platform to buy and trade cryptocurrency.":
    "دليلك إلى عالم نظام مالي مفتوح. ابدأ مع أسهل وأكثر منصة آمنة لشراء وتداول العملات الرقمية.",

  "A trusted and secure cryptocurrency exchange.": "صرافة عملات رقمية موثوقة وآمنة.",

  "Cryptizi ©. All rights reserved.": "Cryptizi ©. كل الحقوق محفوظة.",
  "Terms Of Use": "شروط الاستخدام",
  "Privacy Policy": "سياسة الخصوصية",
  "Assets": "الأصول",
  "Last price": "آخر سعر",
  "Change": "التغيير",
  "Chart": "الرسم البياني",
  
  "Create an Account": "إنشاء حساب",
  "You are not a normal user!": "أنت لست مستخدمًا عاديًا!",
  "Forgot Password": "نسيت كلمة المرور",
  "Login": "تسجيل الدخول",
  "Please input your username!": "يرجى إدخال اسم المستخدم!",
  "Please input your password!": "يرجى إدخال كلمة المرور!",
  "Username": "اسم المستخدم",
  "Password": "كلمة المرور",
  "Remember me": "تذكرني",
  "You don't have permission to login!": "لا تمتلك الصلاحية لتسجيل الدخول!",
  "Please input your first name!": "يرجى إدخال الاسم الأول!",
  "Please input your last name!": "يرجى إدخال اسم العائلة!",
  "Please input your email!": "يرجى إدخال البريد الإلكتروني!",
  "Please input your password": "يرجى إدخال كلمة المرور!",
  "Please input your confirm password!": "يرجى إدخال تأكيد كلمة المرور!",
  "The confirm password does not match the password": "كلمة المرور غير متطابقة",
  "You must accept the terms and conditions!": "يجب أن تقبل الشروط والأحكام!",
  "I have read and agree to Cryptizi's Terms of Service and Privacy Policy.": "لقد قرأت ووافقت على شروط خدمة Cryptizi وسياسة الخصوصية.",
  "Confirm Password": "تأكيد كلمة المرور",
  "Register": "تسجيل",
  "First Name": "الاسم الأول",
  "Last Name": "اسم العائلة",
  "Email": "البريد الإلكتروني",
  "Enter the email verification code!": "أدخل رمز التحقق من البريد الإلكتروني!",
  "Enter the phone number verification code!": "أدخل رمز التحقق من رقم الهاتف!",
  "Enter the authenticator code!": "أدخل رمز المصادقة!",
  "Enter the {{number}}-digit code send to {{email}}": "أدخل رمز الرقم {{number}} المرسل إلى {{email}}",
  "Email verification code": "رمز التحقق من البريد الإلكتروني",
  "Phone number verification code": "رمز التحقق من رقم الهاتف",
  "Enter the {{number}}-digit code send to {{phone}}": "أدخل رمز الرقم {{number}} المرسل إلى {{phone}}",
  "Authenticator code": "رمز المصادقة",
  "Enter the {{number}}-digit code from Google authenticator": "أدخل رمز الرقم {{number}} من Google authenticator",
  "Passwords must have at least 8 characters and contain at least one uppercase letters, lowercase letters, number and symbol.": "يجب أن تحتوي كلمات المرور على الأقل على 8 أحرف وتحتوي على حرف كبير وحرف صغير ورقم ورمز.",
  "You do not have a registered bank card": "ليس لديك بطاقة بنك مسجلة!",
  "Card added successfully.": "تمت إضافة البطاقة بنجاح.",
  "Add a bank card": "إضافة بطاقة بنكية",
  "Type of Card": "نوع البطاقة",
  "Register the card": "تسجيل البطاقة",
  "Please input the type of your card": "يرجى إدخال نوع بطاقتك.",
  "Done Successfully": "تم بنجاح.",
  "Account Number": "رقم الحساب",
  "CVV2": "CVV2",
  "Month of date": "شهر",
  "Year of date": "سنة",
  "Card Number": "رقم البطاقة",
  "Please input the type of your card!": "يرجى إدخال نوع بطاقتك!",
  "Please input your card number!": "يرجى إدخال رقم البطاقة!",
  "Please input month of date!": "يرجى إدخال شهر التاريخ!",
  "Please input 2-digits number": "يرجى إدخال رقم مكون من 2 أرقام.",
  "Please input year of date!": "يرجى إدخال سنة التاريخ!",
  "Please input CVV2!": "يرجى إدخال CVV2!",
  "Please input your account number!": "يرجى إدخال رقم حسابك!",
  "Please input your Sheba number!": "يرجى إدخال رقم الشيبا الخاص بك!",
  "Please input a valid Sheba number!": "يرجى إدخال رقم الشيبا الصحيح!",
  "Card Holder name": "اسم حامل البطاقة",
  "Expiry date": "تاريخ الانتهاء",
  "To insert a bank card, fill out the form below and note that the bank card must be in your name.": "لإدراج بطاقة بنكية ، قم بملء الاستمارة أدناه ولاحظ أن البطاقة البنكية يجب أن تكون باسمك.",
  "A trusted and secure cryptocurrency exchange.": "تبادل عملات رقمية موثوق وآمن.",
  "Vault Protection": "حماية الخزنة",
  "Successful": "ناجح",
  "Learn & Earn": "تعلم واربح",
  "GET IT ON": "احصل عليها على",
  "Google Play": "متجر Google Play",
  "Download on the": "تحميل على",
  "App Store": "متجر التطبيقات",
  "Additional Infomation": "معلومات إضافية",
  "Countries": "الدول",
  "Traders": "التجار",
  "Trading Pairs": "أزواج التداول",
  "Are you sure to remove this symbol from your favorites list?": "هل أنت متأكد من إزالة هذا الرمز من قائمة المفضلة الخاصة بك؟",
  "Tips": "نصائح",
  "Deposit via receipt": "الإيداع عبر الإيصال",
  "Deposit to wallet": "الإيداع في المحفظة",
  "Deposit payment gateway": "بوابة الدفع للإيداع",
  "Back": "رجوع",
  "Go to payment": "الانتقال إلى الدفع",
  "Save": "حفظ",
  "When determining the deposit amount, make sure that the minimum purchase and sale amount in the exchange is {{amount}}.": "عند تحديد مبلغ الإيداع ، تأكد من أن الحد الأدنى للشراء والبيع في البورصة هو {{amount}}.",
  "In order to deposit money, you must use bank cards in your own name, which are registered and approved in the user section.": "لإيداع الأموال ، يجب عليك استخدام بطاقات بنكية باسمك الخاص والتي تم تسجيلها والموافقة عليها في قسم المستخدم.",
  "Payment is only possible through approved accounts, otherwise your payment will be unsuccessful.": "الدفع ممكن فقط من خلال الحسابات المعتمدة ، وإلا سيكون دفعك غير ناجح.",
  "Only for level {{level}} it is possible to deposit with a receipt.": "فقط للمستوى {{level}} يمكن إيداع مع إيصال.",
  "We believe Cryptizi is here to stay - and that a future worth building is one which opens its doors and invites everyone in.": "نعتقد أن Cryptizi هنا للبقاء - وأن المستقبل الذي يستحق البناء هو الذي يفتح أبوابه ويدعو الجميع.",
  "Please input the repeat password!": "يرجى إدخال كلمة المرور مرة أخرى!",
  "The repeat password does not match the password": "كلمة المرور المكررة غير متطابقة مع كلمة المرور الحالية",
  "Repeat Password": "تكرار كلمة المرور",
  "Successfully": "بنجاح",
  "Your email has been successfully verified": "تم التحقق بنجاح من بريدك الإلكتروني",
  "Verify Email": "تحقق من البريد الإلكتروني",
  "Request Again": "طلب مرة أخرى؟",
  "Didn’t receive code?": "لم تستلم الرمز؟",
  "Forgot password": "نسيت كلمة المرور",
  "The input is not a valid email!": "الإدخال ليس بريدًا إلكترونيًا صالحًا!",
  "Reset password": "إعادة تعيين كلمة المرور",
  "Back to login": "العودة لتسجيل الدخول",
  "No worries, we’ll send you reset instructions": "لا تقلق ، سنرسل لك تعليمات إعادة التعيين",
  "Submit": "إرسال",
  "Others": "آخرون",
  "Fiat Deposit": "إيداع نقدي",
  "USD": "دولار أمريكي",
  "You can enter the amount, transaction ID, etc. in the search field.": "يمكنك إدخال المبلغ أو معرف المعاملة ، إلخ في حقل البحث.",
  "List of Toman deposits and withdrawals": "قائمة الإيداعات والسحب بالتومان",
  "Please input the value!": "الرجاء إدخال القيمة!",
  "Please select a bank card!": "الرجاء تحديد بطاقة بنكية!",
  "You will see a list of your approved bank accounts": "سترى قائمة بحسابات البنك المعتمدة الخاصة بك",
  "Num": "رقم",
  "Date": "تاريخ",
  "Creation Date": "تاريخ الإنشاء",
  "Type": "النوع",
  "Value": "القيمة",
  "Inventory": "الجرد",
  "Condition": "الحالة",
  "Description": "الوصف",
  "details": "تفاصيل",
  "Please input the tracking code!": "يرجى إدخال رمز التتبع!",
  "Optional and to speed up transaction confirmation time": "اختياري ولتسريع وقت تأكيد المعاملة",
  "select to deposit": "حدد للإيداع",
  "Payer": "الدافع",
  "Tracking Code": "رمز التتبع",
  "Date and time of transaction": "تاريخ ووقت العملية",
  "How to deposit": "كيفية الإيداع",
  "Receipt image (optional)": "صورة الإيصال (اختياري)",
  "Sell": "بيع",
  "USDT": "تيثر",
  "Stay tuned for more coin types!": "ترقبوا المزيد من أنواع العملات!",
  "Enter Amount": "أدخل المبلغ",
  "All Payment Methods": "جميع طرق الدفع",
  "Refresh": "تحديث",
  "Advertiser": "المعلن",
  "Price": "السعر",
  "Available/Limits": "المتاح/الحدود",
  "Payment Method": "طريقة الدفع",
  "Order(s)": "الطلبات",
  "Limits": "الحدود",
  "Please select the currency!": "الرجاء تحديد العملة!",
  "Please input your wallet ID!": "الرجاء إدخال معرف المحفظة الخاص بك!",
  "Complete the following information to buy digital currency": "أكمل المعلومات التالية لشراء العملة الرقمية",
  "Received money": "استلم الأموال",
  "Please input the value currency!": "الرجاء إدخال قيمة العملة!",
  "Voucher Perfect Money": "قسيمة الأموال المثالية",
  "Price per unit of Perfect Money": "السعر لكل وحدة من المال المثالي",
  "You can also enter the amount in tomans": "يمكنك أيضًا إدخال المبلغ بالتومان",
  "Enter the relevant account ID": "أدخل معرف الحساب ذي الصلة",
  "With description": "مع وصف",
  "With security code": "مع رمز الأمان",
  "Payment method": "طريقة الدفع",
  "Visa/Mastercards": "فيزا/ماستركارد",
  "Shetab Card": "بطاقة شتاب",
  "Fast Pay": "دفع سريع",
  "By charging the wallet, make your payment without going to the bank portal": "من خلال شحن المحفظة، قم بدفعك دون الذهاب إلى بوابة البنك",
  "Pay": "دفع",
  "Select currency": "اختر العملة",
  "Value currency": "قيمة العملة",
  "Wallet ID": "معرف المحفظة",
  "One percent equals 0 Dollar": "1% يساوي 0 دولار",
  "The sales fee is deducted from the amount you receive": "يتم خصم رسوم البيع من المبلغ الذي تتلقاه",
  "Sales fee": "رسوم البيع",
  "Voucher Code": "رمز القسيمة",
  "Activation code": "رمز التفعيل",
  "The purchase fee will be added to your purchase amount": "ستتم إضافة رسوم الشراء إلى مبلغ الشراء الخاص بك",
  "Purchase fee": "رسوم الشراء",
  "cryptizi convert": "تحويل كريبتيزي",
  "Guaranteed price": "سعر مضمون",
  "Any pairs": "أي أزواج",
  "Zero fee": "رسوم صفر",
  "Orders": "الطلبات",
  "From": "من",
  "Spot wallet available": "المحفظة الفورية متاحة",
  "Please enter {{range}}": "الرجاء إدخال {{range}}",
  "To": "إلى",
  "Please enter 20-42000000": "الرجاء إدخال 20-42000000",
  "Enter an amount": "أدخل المبلغ",
  "Select Crypto": "اختر العملة المشفرة",
  "Deposit Crypto": "إيداع العملات الرقمية",
  "Withdraw Crypto": "سحب العملات الرقمية",
  "OTC": "OTC معاملات",
  "Please input coin!": "الرجاء إدخال نوع العملة!",
  "Please input TXID!": "الرجاء إدخال هش كود!",
  "Deposit crypto": "إيداع العملات الرقمية",
  "Recent Deposit": "الإيداع الأخير",
  "Bitcoin": "بيتكوين",
  "Network": "الشبكة",
  "Select Deposit network": "اختر شبكة الإيداع",
  "Copy": "نسخ",
  "Expected Arrival": "الوصول المتوقع",
  "{{number}} Network Confirmations": "{{number}} تأكيدات الشبكة",
  "Expected Unlock": "الفتح المتوقع",
  "Minimum deposit": "الحد الأدنى للإيداع",
  "Selected wallet": "المحفظة المحددة",
  "Main wallet": "المحفظة الرئيسية",
  "change": "تغيير",
  "Send Only {{symbol}} to this deposit address": "أرسل فقط {{symbol}} إلى هذا العنوان للإيداع",
  "Ensure the network is": "تأكد من أن الشبكة هي:",
  "Do not send NFTs to this address.": "لا ترسل NFT إلى هذا العنوان.",
  "learn how to deposit NFTs": "تعلم كيفية إيداع NFTs",
  "submit": "إرسال",
  "Video Tutorial": "الدورة التعليمية بالفيديو",
  "How to Withdraw crypto step-by-step Guide": "كيفية سحب العملات الرقمية دليل خطوة بخطوة",
  "Why has my withdrawal Not Arrived?": "لماذا لم يصل سحبي؟",
  "How to find My transaction ID (TxID)?": "كيفية العثور على هوية تراكنشي (TxID) الخاصة بي؟",
  "how to recover My BEP-20 token?": "كيفية استعادة توكن BEP-20 الخاص بي؟",
  "Deposit & withdrawal status query": "استعلام حالة الإيداع والسحب",
  "Select coin": "اختر العملة",
  "TXID": "هش كود",
  "Scan the code on the withdrawal page of the trading platform APP or wallet App":
    "مسح الرمز الموجود في صفحة السحب من تطبيق منصة التداول أو تطبيق المحفظة",
  "Send only {{symbol}} to this deposit address": "أرسل فقط {{symbol}} إلى هذا العنوان للإيداع",
  "Learn how to deposit NFTs": "تعلم كيفية إيداع NFTs",
  "Unmatched networks automatically removed. Select a network below":
    "تم إزالة الشبكات غير المتطابقة تلقائيًا. اختر شبكة أدناه",
  "SOL": "سول",
  "Solana": "سولانا",
  "TRX": "تي آر ايكس",
  "Tron (TRC20)": "ترون (TRC20)",
  "Arrival time = {{min}} min": "وقت الوصول = {{min}} دقيقة",
  "fee": "رسوم",
  "BSC ": "BSC ",
  "BNB Smart chain (BEP20)": "شبكة BNB الذكية (BEP20)",
  "Fee rules": "قوانين الرسوم",
  "Select Network": "اختر الشبكة",
  "Crypto Address": "عنوان العملة الرقمية",
  "Completed": "مكتمل",
  "Withdraw wallet": "سحب المحفظة",
  "Spot Wallet": "المحفظة الفورية",
  "TxID": "TxID",
  "View All": "عرض الكل",
  "Cryptizi user": "مستخدم كريبتيزي",
  "Recent Withdrawals": "السحوبات الأخيرة",
  "Select Withdrawal network": "اختر شبكة السحب",
  "SQl Network Withdrawal suspended. withdrawals can be processed through alternative network.":
    "تم تعليق سحب شبكة SQl. يمكن معالجة السحوبات من خلال شبكة بديلة.",
  "withdrawal": "السحب من الحساب",
  "Address": "العنوان",
  "Enter Address": "أدخل العنوان",
  "USDT balance": "رصيد USDT",
  "Network Fee": "رسوم الشبكة",
  "Minimum withdrawal": "الحد الأدنى للسحب",
  "24h remaining limit": "الحد المتبقي لمدة 24 ساعة",
  "Please enter SMS verification code!": "الرجاء إدخال رمز التحقق عبر الرسائل النصية!",
  "Amount": "المبلغ",
  "Receive {{receive}} {{symbol}} (Network fee {{fee}} USDT)":
    "استلام {{receive}} {{symbol}} (رسوم الشبكة {{fee}} USDT)",
  "Source": "المصدر",
  "Spot wallet": "المحفظة الفورية",
  "Verification code sent": "تم إرسال رمز التحقق",
  "security verification unavailable?": "التحقق من الأمان غير متاح؟",
  "Security verification": "التحقق من الأمان",
  "Withdrawal Request Submitted": "تم تقديم طلب السحب",
  "Please note that you will receive an email once it is completed":
    "يرجى ملاحظة أنك ستتلقى رسالة بريد إلكتروني عند الانتهاء",
  "The receiver will get": "سيحصل المستلم على",
  "Save Address": "حفظ العنوان",
  "One-Step Withdrawal": "السحب بخطوة واحدة",
  "Enable One-Step Withdrawal to withdraw to whitelis addresses easily.":
    "قم بتمكين السحب بخطوة واحدة للسحب إلى عناوين القائمة البيضاء بسهولة.",
  "Please input your Recipient!": "الرجاء إدخال المستلم الخاص بك!",
  "Cryptizi ID": "هوية Cryptizi",
  "Recipient’s email": "بريد المستلم",
  "Orders history": "تاريخ الطلبات",
  "Recive": "استلام",
  "Buy Crypto with Debit / Credit Card": "شراء العملات الرقمية ببطاقة الخصم / الائتمان",
  "max": "الحد الأقصى",
  "Trades History": "تاريخ التداول",
 "Orders History": "تاريخ الطلبيات",
  "Convert History": "تاريخ التحويل",
  "Internal Transactions": "المعاملات الداخلية",
  "Futures History": "تاريخ العقود الآجلة",
  "Crypto Transactions": "المعاملات الرقمية",
  "Fiat Transactions": "المعاملات بالعملات الورقية",
  "ALL": "الكل",
  "BUY": "شراء",
  "SELL": "بيع",
  "MARKET": "السوق",
  "LIMIT": "حد الطلب",
  "STOP LIMIT": "حد الإيقاف",
  "CANCELED": "تم الإلغاء",
  "EXPIRED": "منتهي الصلاحية",
  "FILLED": "ممتلئ",
  "NEW": "جديد",
  "PARTIALLY FILLED": "ممتلئ جزئياً",
  "PENDING CANCEL": "في انتظار الإلغاء",
  "REJECTED": "مرفوض",
  "Pair": "الزوج",
  "Side": "الجهة",
  "Filed": "تم التقديم",
  "Commission": "العمولة",
  "State": "الحالة",
  "Credit Card": "بطاقة الائتمان",
  "Please input a valid CVV2!": "الرجاء إدخال رقم CVV2 صحيح!",
  "Master Card": "بطاقة ماستركارد",
  "Visa Card": "بطاقة فيزا",
  "Please input your old email address!": "الرجاء إدخال عنوان البريد الإلكتروني القديم الخاص بك!",
  "Change Email": "تغيير البريد الإلكتروني",
  "Please input your new email!": "الرجاء إدخال البريد الإلكتروني الجديد الخاص بك!",
  "New Email": "بريد إلكتروني جديد",
  "Email changed successfully": "تم تغيير البريد الإلكتروني بنجاح",
  "Tickets": "التذاكر",
  "Order": "الطلبيات",
  "Account Settings": "إعدادات الحساب",
  "Settings Network": "إعدادات الشبكة",
  "Settings": "الإعدادات",
  "Users": "المستخدمين",
  "Admins": "المشرفين",
  "Referral marketing": "التسويق بالإحالة",
  "Crypto Wallet Transactions": "معاملات محفظة العملات الرقمية",
  "Wallet Transactions": "معاملات المحفظة",
  "Automatic Transactions": "المعاملات التلقائية",
  "List Of All Bank Cards": "قائمة بجميع بطاقات البنوك",
  "User's": "المستخدمين",
  "Notification": "الإشعارات",
  "Reporting": "التقارير",
  "Tether Setting": "إعدادات تيثر",
  "Inventory Settings": "إعدادات المخزون",
  "Daily Rial withdrawal ceiling": "حد سحب الريال اليومي",
  "Buy Digital Currency": "شراء العملة الرقمية",
  "API settings": "إعدادات واجهة برمجة التطبيقات",
  "General settings": "الإعدادات العامة",
  "IP Block": "حظر الآي بي",
  "User level settings": "إعدادات مستوى المستخدم",
  "Deposit With Receipt": "الإيداع بإيصال",
  "Popup Settings": "إعدادات النافذة المنبثقة",
  "Commission settings": "إعدادات العمولة",
  "Related To Reference Account": "متعلقة بحساب المرجع",
  "Automatic Deposit settings": "إعدادات الإيداع التلقائي",
  "Dedicated Gateway settings": "إعدادات البوابة المخصصة",
  "All users": "جميع المستخدمين",
  "Add user": "إضافة مستخدم جديد",
  "Add Admin": "إضافة مشرف",
  "Two-step login via Google": "تسجيل الدخول الثنائي عبر Google",
  "Activation": "التفعيل",
  "6-digit google code": "كود Google بستة أرقام",
  "Two-step login via Sms": "تسجيل الدخول الثنائي عبر الرسائل القصيرة",
  "Send SMS": "إرسال رسالة نصية",
  "SMS Code": "كود الرسالة النصية",
  "Two-step login via Email": "تسجيل الدخول الثنائي عبر البريد الإلكتروني",
  "Send Email": "إرسال بريد إلكتروني",
  "Email Code": "كود البريد الإلكتروني",
  "Tether price inquiry from api and automatic": "استعلام سعر تيثر من الواجهة البرمجية وتلقائي",
  "Settings Tether": "إعدادات تيثر",
  "Caption": "التسمية",
  "Please select a gateway!": "الرجاء تحديد البوابة!",
  "Inventory settings": "إعدادات المخزون",
  "API status for inventory": "حالة واجهة برمجة التطبيقات للمخزون",
  "Cancel": "إلغاء",
  "Buy digital currency": "شراء العملة الرقمية",
  "Sell digital currency": "بيع العملة الرقمية",
  "Digital currency": "العملة الرقمية",
  "Gateway Setting": "إعدادات البوابة",
  "Voucher Settings": "إعدادات القسائم",
  "Pay Safe Settings": "إعدادات الدفع الآمن",
  "Perfect Money Settings": "إعدادات الأموال المثالية",
  "Cryptocurrency Setting": "إعدادات العملات الرقمية",
  "Settings related to vouchers": "الإعدادات المتعلقة بالقسائم",
  "Purchase status": "حالة الشراء",
  "Sales status": "حالة البيع",
  "apikey": "مفتاح API",
  "seckey": "مفتاح الأمان",
  "Query the price of dollars from the api": "استعلام عن سعر الدولار من الواجهة البرمجية",
  "Purchase price in dollars": "سعر الشراء بالدولار",
  "Percentage on the price to buy": "نسبة على السعر للشراء",
  "Purchase price times this number": "سعر الشراء مرات هذا الرقم",
  "Selling price in dollars": "سعر البيع بالدولار",
  "Percentage in price for sale": "نسبة في السعر للبيع",
  "Selling price times this number": "سعر البيع مرات هذا الرقم",
  "Percentage in the price for purchase (above one hundred tomans)": "نسبة في السعر للشراء (فوق مائة تومان)",
  "Percentage in the price for sale (above one hundred tomans)": "نسبة في السعر للبيع (فوق مائة تومان)",
  "Settings related to Pay Safe Voucher": "الإعدادات المتعلقة بقسائم Pay Safe",
  "UserName": "اسم المستخدم",
  "AccountID": "رقم الحساب",
  "Purchase status of Perfect Money voucher": "حالة شراء قسيمة Perfect Money",
  "Settings related to Perfect Money": "الإعدادات المتعلقة بـ Perfect Money",
  "Perfect Money Voucher sales status": "حالة مبيعات قسيمة Perfect Money",
  "Perfect Money Voucher": "قسيمة Perfect Money",
  "Settings related to cryptocurrency reference account": "الإعدادات المتعلقة بحساب العملات الرقمية المرجعي",
  "Binance apikey": "مفتاح API لـ Binance",
  "Binance seckey": "مفتاح الأمان لـ Binance",
  "Settings Cryptocurrency": "إعدادات العملات الرقمية",
  "Enter the code that was sent to your previous mobile number and email in the box below": "أدخل الرمز الذي تم إرساله إلى رقم هاتفك المحمول وبريدك الإلكتروني السابق في الخانة أدناه",
  "New Email verification code": "رمز التحقق من البريد الإلكتروني الجديد",
  "Add Market": "إضافة سوق",
  "Name": "الاسم",
  "Symbol": "الرمز",
  "Name Persian": "الاسم بالفارسية",
  "Selector": "المحدد",
  "Network Status": "حالة الشبكة",
  "Settings network": "إعدادات الشبكة",
  "Please input networks name!": "الرجاء إدخال اسم الشبكة!",
  "Please input network's selector!": "الرجاء إدخال المحدد للشبكة!",
  "Please input network's persian name!": "الرجاء إدخال الاسم الفارسي للشبكة!",
  "Please input network's symbol!": "الرجاء إدخال الرمز للشبكة!",
  "Please input Minimum allowed!": "الرجاء إدخال الحد الأدنى المسموح به!",
  "Minimum allowed purchase": "الحد الأدنى المسموح به للشراء",
  "Please input Daily purchase amount!": "الرجاء إدخال مبلغ الشراء اليومي!",
  "IP test mode (to disable, leave the field blank)": "وضع اختبار الآي بي (للتعطيل، اترك الحقل فارغًا)",
  "Deactivating purchases from the site (inserting a message in the box below means deactivating purchases from the site)": "تعطيل الشراء من الموقع (إدراج رسالة في الخانة أدناه يعني تعطيل الشراء من الموقع)",
  "Daily purchase amount during registration": "المبلغ اليومي للشراء أثناء التسجيل",
  "Deposit settings with receipt": "إعدادات الإيداع مع الإيصال",
  "Bank name": "اسم البنك",
  "Card number": "رقم البطاقة",
  "Account number": "رقم الحساب",
  "Shaba number": "رقم الشبا",
  "Please input number of days!": "الرجاء إدخال عدد الأيام!",
  "Popup settings": "إعدادات النافذة المنبثقة",
  "After each login, it shows the password (that is, if he has not used remember me) and also shows it every few days if he has remembered me!": "بعد كل تسجيل دخول، يظهر كلمة المرور (أي إذا لم يكن قد استخدم تذكرني) ويظهر أيضا كل بضعة أيام إذا كان قد تذكرني!",
  "Percentage": "النسبة المئوية",
  "Automatic deposit settings": "إعدادات الإيداع التلقائي",
  "Business": "عمل",
  "Deposit model": "نموذج الإيداع",
  "Mobile": "الجوال",
  "Rejection of automatic deposit for the following banks": "رفض الإيداع التلقائي للبنوك التالية",
  "Sat": "السبت",
  "Sun": "الأحد",
  "Mon": "الاثنين",
  "Tue": "الثلاثاء",
  "Wed": "الأربعاء",
  "Thu": "الخميس",
  "Fri": "الجمعة",
  "Active": "نشط",
  "Token": "الرمز",
  "Inactive": "غير نشط",
  "Iran GATEWAY": "بوابة إيران",
  "Contract text": "نص العقد",
  "SMS confirmation for withdrawal and purchase": "تأكيد بواسطة الرسائل النصية لعمليات السحب والشراء",
  "International GATEWAY": "بوابة دولية",
  "Iranian payment gateways Appearance": "مظهر بوابات الدفع الإيرانية",
  "Minimum wallet withdrawal amount": "الحد الأدنى لمبلغ السحب من المحفظة",
  "Minimum amount of currency sales": "الحد الأدنى لمبلغ مبيعات العملة",
  "Dedicated Gateway settings": "إعدادات البوابة المخصصة",
  "disable": "تعطيل",
  "active": "نشط",
  "This is the agreement between Iranianex.com and the user who uses the various services of the site. All users of Iranianex.com should read these rules completely and then become a member of the site if they accept them. Iranianex.com can update the terms of this agreement every month according to the changes in the laws. Registering on this site, using its services such as buying and selling, etc., means that you have read and accepted the terms of this contract. The user accepts that he is the legal owner. This is the agreement between Iranianex.com and the user who uses the various services of the site.": "هذا هو الاتفاق بين Iranianex.com والمستخدم الذي يستخدم مختلف خدمات الموقع. يجب على جميع مستخدمي Iranianex.com قراءة هذه القواعد بشكل كامل ثم أن يصبحوا أعضاء في الموقع إذا قبلوها. يمكن لـ Iranianex.com تحديث شروط هذا الاتفاق شهريًا وفقًا للتغييرات في القوانين. التسجيل في هذا الموقع واستخدام خدماته مثل الشراء والبيع، وما إلى ذلك، يعني أنك قد قرأت وقبلت شروط هذا العقد. يقبل المستخدم أنه هو المالك القانوني. هذا هو الاتفاق بين Iranianex.com والمستخدم الذي يستخدم مختلف خدمات الموقع.",
  "Add New Admin": "إضافة مسؤول جديد",
  "Admin": "مسؤول",
  "Block": "حظر",
  "Edit": "تحرير",
  "Delete": "حذف",
  "List of accounts": "قائمة الحسابات",
  "Crypto transactions": "المعاملات الرمزية",
  "Bank cards": "بطاقات البنك",
  "Log": "سجل",
  "Toman transactions": "المعاملات بالتومان",
  "Please input name!": "الرجاء إدخال الاسم!",
  "Please input mobile!": "الرجاء إدخال رقم الجوال!",
  "Please input email!": "الرجاء إدخال البريد الإلكتروني!",
  "Please input password!": "الرجاء إدخال كلمة المرور!",
  "Add new admin": "إضافة مسؤول جديد",
  "Deactive": "غير نشط",
  "Delete user": "حذف المستخدم",
  "Access": "الوصول",
  "Two-step login": "تسجيل الدخول الثنائي",
  "Upload photo": "تحميل الصورة",
  "Change photo": "تغيير الصورة",
  "Notifications": "الإشعارات",
  "Wallet deposit and withdrawal": "إيداع وسحب المحفظة",
  "Filter": "تصفية",
  "View transactions": "عرض المعاملات",
  "Edit Admin": "تحرير المسؤول",
  "Wallet transactions": "معاملات المحفظة",
  "User wallet transactions that have been changed by this admin": "معاملات المحفظة للمستخدم التي تم تغييرها من قبل هذا المسؤول",
  "Bank": "البنك",
  "Amount of": "المبلغ من",
  "Currency": "العملة",
  "Status": "الحالة",
  "Detail": "التفاصيل",
  "Cryptocurrency wallet transactions": "معاملات محفظة العملات الرمزية",
  "Regarding": "بخصوص",
  "Business transactions": "معاملات الأعمال",
  "Transactions of a transaction": "معاملات معاملة",
  "ID transaction": "معرف المعاملة",
  "You can see the cryptocurrency wallet transactions that the admin has changed": "يمكنك رؤية معاملات محفظة العملات الرمزية التي قام المسؤول بتغييرها",
  "The list of users whose document image has been changed by this admin.": "قائمة المستخدمين الذين تم تغيير صورة وثيقتهم بواسطة هذا المسؤول.",
  "Level": "المستوى",
  "Add admin": "إضافة مسؤول",
  "New Admin": "مسؤول جديد",
  "You can not disable a port that is in the default state.": "لا يمكنك تعطيل منفذ في الحالة الافتراضية.",
  "Order type": "نوع الطلب",
  "Tether": "تتر",
  "User": "المستخدم",
  "Transfer fee": "رسوم التحويل",
  "network": "الشبكة",
  "The amount": "المبلغ",
  "Close": "إغلاق",
  "Address wallet": "عنوان المحفظة",
  "Displaying saved parameters (Click)": "عرض المعلمات المحفوظة (انقر)",
  "Transactions Details": "تفاصيل المعاملات",
  "Success": "نجاح",
  "Failed": "فشل",
  "You can see the total number of records on the current page, and for the total number of all displaysl": "يمكنك رؤية إجمالي عدد السجلات على الصفحة الحالية، ولإجمالي عدد جميع العروض",
  "Account list": "قائمة الحساب",
  "Group payment": "الدفع الجماعي",
  "Sheba's list": "قائمة الشيبا",
  "You can see the wallet transactions of users.": "يمكنك رؤية معاملات المحفظة للمستخدمين.",
  "View": "رؤية",
  "The transfer encountered an error": "واجهت النقل خطأ",
  "Transaction number": "رقم المعاملة",
  "Transaction amount": "مبلغ المعاملة",
  "IP": "عنوان IP",
  "Done": "تم",
  "New": "جديد",
  "Your Notifications": "إشعاراتك",
  "View All Notifications": "عرض جميع الإشعارات",
  "Sort by": "ترتيب حسب",
  "last 2 week": "آخر 2 أسبوع",
  "No Data": "لا توجد بيانات",
  "Dollar": "الدولار",
  "Payment unit": "وحدة الدفع",
  "Done successfully": "تمت بنجاح.",
  "Voucher code": "كود القسيمة",
  "MAX": "الحد الأقصى",
  "Select Coin": "اختيار العملة",
  "Main Wallet": "المحفظة الرئيسية",
  "Funding Wallet": "المحفظة التمويلية",
  "Select Wallet": "اختيار المحفظة",
  "Please select a coin firstly!": "يرجى تحديد عملة أولاً!",
  "Enter Tag": "إدخال الوسم",
  "Add New Address": "إضافة عنوان جديد",
  "Select from Address Book": "اختيار من دفتر العناوين",
  "Phone": "الهاتف",
  "balance": "الرصيد",
  "Receive {{receive}} {{symbol}}": "استلام {{receive}} {{symbol}}",
  "Top gainer (24h)": "أعلى مكسب (24 ساعة)",
  "Highest volume (24h)": "أعلى حجم (24 ساعة)",
  "Most Visited": "الأكثر زيارة",
  "New listing": "قائمة جديدة",
  "24h Change": "تغيير 24 ساعة",
  "24h Volume": "حجم 24 ساعة",
  "Market Cap": "قيمة السوق",
  "Crypto Market": "السوق الرمزية",
  "If you need help, watch the guide video in this section": "إذا كنت بحاجة إلى مساعدة، شاهد فيديو الدليل في هذا القسم",
  "Watch the movie": "شاهد الفيلم",
  "Your Share": "حصتك",
  "Share friends": "مشاركة الأصدقاء",
  "Reports": "التقارير",
  "List of trades in your subset": "قائمة التجارات في مجموعتك الفرعية",
  "Your income chart": "رسم بياني لدخلك",
  "List of introduced users": "قائمة المستخدمين المعرَّفين",
  "Link and introduction code": "رابط وكود المعرفة",
  "By providing a link or referral code to your friends, you can earn money from their transaction fees and also allocate a part of this income to them, which is both for their benefit and for your benefit.": "من خلال توفير رابط أو كود إحالة لأصدقائك، يمكنك كسب أموال من رسوم تحويلهم وكذلك تخصيص جزء من هذا الدخل لهم، مما يعود بالنفع عليهم وعليك أيضًا.",
  "Set percentage for submissions": "تعيين نسبة للمشاركات",
  "30% of your friends transaction fee is provided for you and by setting this section you can divide your income among the people you have introduced.": "تُقدم لك 30٪ من رسوم تحويل أصدقائك، ومن خلال تعيين هذا القسم يمكنك تقسيم دخلك بين الأشخاص الذين قدمتهم.",
  "Number": "رقم",
  "Details": "تفاصيل",
  "Add Ticket": "إضافة تذكرة",
  "Subject": "الموضوع",
  "Please input section!": "الرجاء إدخال القسم!",
  "Please input description!": "الرجاء إدخال الوصف!",
  "Finance": "المالية",
  "Technical": "تقني",
  "KYC": "KYC",
  "Name Ticket": "اسم التذكرة",
  "Section": "القسم",
  "History of your trades": "تاريخ تجاراتك",
  "Time": "الوقت",
  "History of your transactions": "تاريخ معاملاتك",
  "Past 30 days": "الـ30 يومًا الماضية",
  "Past 90 days": "الـ90 يومًا الماضية",
  "Credited": "مؤكد",
  "Pending": "قيد الانتظار",
  "Past 7 days": "الـ7 أيام الماضية",
  "Asset": "الأصول",
  "Enter TxID": "أدخل رقم المعاملة (TxID)",
  "Select Asset": "حدد الأصل",
  "Destination": "الوجهة",
  "Failure": "فشل",
  "Canceled": "تم الإلغاء",
  "Deposit ID": "هوية الإيداع",
  "Please input your old Email address!": "الرجاء إدخال عنوان البريد الإلكتروني القديم!",
  "Fiat Withdraw": "سحب العملة الورقية",
  "Withdrawal of wallet": "سحب من المحفظة",
  "Your recoverable inventory:": "المخزون الذي يمكن استعادته لديك:",
  "Equivalent daily withdrawal amount: 0 out of 500,000,000 Tomans": "المبلغ المكافئ للسحب اليومي: 0 من 500,000,000 تومان",
  "When determining the deposit amount, make sure that the minimum purchase and sale amount in the exchange is $ 11.": "عند تحديد مبلغ الإيداع، تأكد من أن الحد الأدنى للشراء والبيع في الصرافة هو 11 دولارًا.",
  "Bank account to receive": "الحساب البنكي للاستلام",
  "Buy & Sell": "الشراء والبيع",
  "All Model": "كل النماذج",
  "Management": "الإدارة",
  "Account Setting": "إعدادات الحساب",
  "Profile": "الملف الشخصي",
  "Waiting Actions": "الإجراءات في الانتظار",
  "Admin Utilities": "أدوات الإدارة",
  "Title": "العنوان",
  "Today": "اليوم",
  "Yesterday": "الأمس",
  "This week": "هذا الأسبوع",
  "This month": "هذا الشهر",
  "This Year": "هذا العام",
  "Total value": "القيمة الإجمالية",
  "Sales": "المبيعات",
  "System status": "حالة النظام",
  "Website": "الموقع الإلكتروني",
  "PaySafe Voucher": "قسيمة PaySafe",
  "PMvoucher": "قسيمة PM",
  "Name Currency": "اسم العملة",
  "Sale": "البيع",
  "Stock": "المخزون",
  "Amount of orders": "كمية الطلبات",
  "Sales number": "رقم المبيعات",
  "Selling price": "سعر البيع",
  "Purchases": "المشتريات",
  "Purchase amount": "مبلغ الشراء",
  "New User": "مستخدم جديد",
  "Most sales": "معظم المبيعات",
  "Most purchases": "معظم المشتريات",
  "Users waiting for Verify": "المستخدمون في انتظار التحقق",
  "Fiat Transaction Waiting": "انتظار معاملة العملة الورقية",
  "Reset": "إعادة تعيين",
  "You can see the list of users whose information must be confirmed.": "يمكنك رؤية قائمة المستخدمين الذين يجب تأكيد معلوماتهم.",
  "Edit / Delete": "تحرير / حذف",
  "Basic": "الأساسي",
  "Crypto wallet": "محفظة العملات الرقمية",
  "Fiat wallet": "محفظة العملات الورقية",
  "Crypto Transaction": "معاملة العملات الرقمية",
  "Cards": "البطاقات",
  "Trade history": "تاريخ التداول",
  "Earn ": "كسب ",
  "please fill the blank": "الرجاء ملء الفراغ",
  "Other": "آخر",
  "Intermediate": "متوسط",
  "Advanced": "متقدم",
  "Send Form": "إرسال النموذج",
  "Middle Name": "الاسم الأوسط",
  "Country": "البلد",
  "Street Address": "عنوان الشارع",
  "birthDate": "تاريخ الميلاد",
  "Postal Code": "الرمز البريدي",
  "City": "المدينة",
  "Phone Number": "رقم الهاتف",
  "Approve": "الموافقة",
  "Reject": "رفض",
  "Send Notification": "إرسال إشعار",
  "Wallet’s User": "مستخدم المحفظة",
  "Total inventory": "المخزون الإجمالي",
  "Equivalent to Dollar": "مكافئ للدولار",
  "Inventory available": "المخزون المتاح",
  "Create a transaction": "إنشاء معاملة",
  "Add transaction": "إضافة معاملة",
  "Error": "خطأ",
  "Edit User": "تحرير المستخدم",
  "Create": "إنشاء",
  "Update photo": "تحديث الصورة",
  "Value Transaction": "قيمة المعاملة",
  "Account Transaction": "معاملة الحساب",
  "Add Card": "إضافة بطاقة",
  "Add card": "إضافة بطاقة",
  "Shaba": "شبا",
  "Waiting": "في الانتظار",
  "Message": "الرسالة",
  "Payment": "الدفع",
  "Port": "المنفذ",
  "Order value": "قيمة الطلب",
  "success": "نجاح",
  "Earn": "كسب",
  "List of users that this user has introduced": "قائمة المستخدمين الذين قدمهم هذا المستخدم",
  "Value Order": "قيمة الطلب",
  "User commission": "عمولة المستخدم",
  "ID card": "بطاقة الهوية",
  "Passport": "جواز السفر",
  "Upload .PDF/.JPG/.JPEG/.PNG file and no more than 5M": "قم بتحميل ملف .PDF/.JPG/.JPEG/.PNG ولا يزيد عن 5 ميجابايت",
  "Select your Country / region": "اختر بلدك / منطقتك",
  "Change level": "تغيير المستوى",
  "Change password": "تغيير كلمة المرور",
  "Representative mobile": "الجوال التمثيلي",
  "List of all bank cards": "قائمة جميع بطاقات البنك",
  "To edit, delete or approve or disapprove a bank card, click on the link to be redirected to the bank cards section in the users profile.": "لتحرير أو حذف أو الموافقة أو عدم الموافقة على بطاقة بنك، انقر على الرابط للانتقال إلى قسم بطاقات البنك في ملف تعريف المستخدم.",
  "Edit card": "تحرير البطاقة",
  "Edit Card": "تحرير البطاقة",
  "Fiat transactions": "معاملات العملات الورقية",
  "Edit admin": "تحرير المشرف",
  "API Setting": "إعدادات واجهة برمجة التطبيقات",
  "Network Setting": "إعدادات الشبكة",
  "Referrals": "الإحالات",
  "Dollar balance": "رصيد الدولار",
  "Rial balance": "رصيد الريال",
  "Percentage in the price for ( marketing )": "النسبة المئوية في السعر للتسويق",
  "Perfect Money ": "بيرفكت موني",
  "Percentage in the price for purchase": "النسبة المئوية في السعر للشراء",
  "Percentage in the price for sale": "النسبة المئوية في السعر للبيع",
  "Network Name": "اسم الشبكة",
  "General Settings": "إعدادات عامة",
  "Type ...": "اكتب...",
  "Account Holder's name": "اسم صاحب الحساب",
  "Vandar": "فندر",
  "PIR": "PIR",
  "Zibal": "Zibal",
  "Add User": "إضافة مستخدم",
  "BASIC": "أساسي",
  "Disapproval": "عدم الموافقة",
  "REGISTERED": "مسجل",
  "BASIC_BY_ADMIN": "أساسي بواسطة المشرف",
  "INTERMEDIATE_REQUESTED": "تم طلب المتوسط",
  "ADVANCED_BY_ADMIN": "متقدم بواسطة المشرف",
  "INTERMEDIATE_BY_ADMIN": "متوسط بواسطة المشرف",
  "ADVANCED_REQUESTED": "تم طلب المتقدم",
  "Google": "Google",
  "LastName": "اسم العائلة",
  "Sms": "رسالة نصية",
  "Father Name": "اسم الأب",
  "National Number": "الرقم الوطني",
  "Birth Date": "تاريخ الميلاد",
  "Login Date": "تاريخ الدخول",
  "Verification": "التحقق",
  "Tell": "القول",
  "Add New Card": "إضافة بطاقة جديدة",
  "Support": "الدعم",
  "caption": "العنوان",
  "List of commission payments to the wallet": "قائمة بدفعات العمولة إلى المحفظة",
  "Referral Marketing": "تسويق الإحالة",
  "waiting": "في الانتظار",
  "Transfer completed successfully": "تم إكمال النقل بنجاح",
  "All User": "جميع المستخدمين",
  "Perfect Money buyers/sellers": "مشترين/بائعين Perfect Money",
  "CryptoCurrency buyers/sellers": "مشترين/بائعين العملات الرقمية",
  "Paysafe buyers/sellers": "مشترين/بائعين Paysafe",
  "Vouchers buyers/sellers": "مشترين/بائعين القسائم",
  "Referral Users": "مستخدمين محالين",
  "Verified Users": "مستخدمون موثوق بهم",
  "Users Who hasn't traded yet": "المستخدمين الذين لم يتداولوا بعد",
  "This category does not apply to header alerts and shows for everyone": "لا ينطبق هذا الفئة على إشعارات الرأس وتظهر للجميع",
  "The title does not apply to SMS": "العنوان لا ينطبق على الرسائل النصية",
  "Unverified Users": "مستخدمون غير موثوق بهم",
  "Notification type": "نوع الإشعار",
  "Users whose Google Authenticator is inactive": "المستخدمون الذين Google Authenticator غير نشط",
  "Users whose SMS Authenticator is inactive": "المستخدمون الذين SMS Authenticator غير نشط",
  "Basic level Users": "مستخدمون في المستوى الأساسي",
  "Intermediate Users": "مستخدمون في المستوى المتوسط",
  "Advanced Users": "مستخدمون في المستوى المتقدم",
  "User Statistics": "إحصائيات المستخدم",
  "Open trades": "الصفقات المفتوحة",
  "Futures Open position": "المواقف المفتوحة في الآجل",
  "Deposit Statistics": "إحصائيات الإيداع",
  "Crypto Statistics": "إحصائيات العملات الرقمية",
  "Successful Orders": "الصفقات الناجحة",
  "Withdraw Statistics": "إحصائيات السحب",
  "Unsuccessful Orders": "الصفقة الغير ناجحة",
  "12 H": "12 ساعة",
  "1 Day": "1 يوم",
  "1 Week": "1 أسبوع",
  "1 Months": "1 شهر",
  "1 Years": "1 سنة",
  "last day": "اليوم الماضي",
  "Buy/Sell": "شراء/بيع",
  "Desktop": "سطح المكتب",
  "Select": "تحديد",
  "Report": "تقرير",
  "Deactivate user": "تعطيل المستخدم",
  "Active user": "تنشيط المستخدم",
  "Excel": "Excel",
  "PDF": "PDF",
  "Gateway": "بوابة",
  "Post New Ad": "نشر إعلان جديد",
  "Remainder": "الباقي",
  "Ads List": "قائمة الإعلانات",
  "Post": "نشر",
  "Total Amount": "المبلغ الإجمالي",
  "With Fiat": "بالعملة الورقية",
  "Minimum order limit": "الحد الأدنى للطلب",
  "Maximum order limit": "الحد الأقصى للطلب",
  "Please select the type!": "الرجاء تحديد النوع!",
  "Perfect Money": "Perfect Money",
  "Voucher PaySafe": "Voucher PaySafe",
  "You can also enter the amount": "يمكنك أيضاً إدخال المبلغ",
  "Date & Time": "التاريخ والوقت",
  "Serial number": "الرقم التسلسلي",
  "Continue": "متابعة",
  "Hot": "حار",
  "Choose Payment method": "اختيار وسيلة الدفع",
  "Previous": "السابق",
  "Confirm": "تأكيد",
  "History of your orders": "تاريخ طلباتك",
  "Fiat": "عملة ورقية",
  "P2P History": "تاريخ P2P",
  "History of your P2P orders": "تاريخ طلباتك P2P",
  "TAKE": "أخذ",
  "MAKE": "صنع",
  "COMPLETED": "تم الانتهاء",
  "FAILED": "فشل",
  "PARTIALLY_COMPLETED": "تم الانتهاء جزئياً",
  "PENDING": "قيد الانتظار",
  "Please input your old Mobile!": "الرجاء إدخال رقم هاتفك القديم!",
  "Please input your new mobile!": "الرجاء إدخال رقم هاتفك الجديد!",
  "Change Mobile": "تغيير الهاتف",
  "New Mobile": "رقم هاتف جديد",
  "Please input your old Email Address!": "الرجاء إدخال عنوان بريدك الإلكتروني القديم!",
  "Paysafe total value": "القيمة الإجمالية لـ Paysafe",
  "Perfect Money total value": "القيمة الإجمالية لـ Perfect Money",
  "Tether total value": "القيمة الإجمالية لـ Tether",
  "Automatic Deposit History": "تاريخ تاريخ إيداع تلقائي",
  "Sheba number": "رقم الشيبا",
  "Unblock": "إلغاء الحظر",
  "undefined": "غير معرف",
  "Approved": "تمت الموافقة",
  "Rejectd": "تم الرفض",
  "You can see the list of commissions received by this user through introduction.":
    "يمكنك رؤية قائمة العمولات التي تم استلامها من قبل هذا المستخدم من خلال التعريف.",
  "INTERMEDIATE": "متوسط",
  "ADVANCED": "متقدم",
  "Family": "عائلة",
  "Please input first name!": "الرجاء إدخال الاسم الأول!",
  "Please input email address!": "الرجاء إدخال عنوان البريد الإلكتروني!",
  "Please input last name!": "الرجاء إدخال اسم العائلة!",
  "If you don't decide to change the password, leave this field blank":
    "إذا كنت لا تنوي تغيير كلمة المرور، اترك هذا الحقل فارغًا",
  "Access Denied": "تم رفض الوصول",
  "Fixed panel page header": "ترويسة الصفحة الثابتة لللوحة",
  "By notification": "عن طريق الإشعار",
  "Header panel pages only once": "ترويسة الصفحة مرة واحدة فقط",
  "by Email": "عبر البريد الإلكتروني",
  "Via SMS": "عبر الرسائل النصية",
  "Desktap": "الكمبيوتر",
  "Deactive user": "تعطيل المستخدم",
  "Buy Crypto": "شراء العملات الرقمية",
  "Contact Us": "اتصل بنا",
  "Privacy policy": "سياسة الخصوصية",
  "E-money": "العملات الإلكترونية",
  "Verify": "تحقق",
  "Announcement": "إعلان",
  "API": "واجهة برمجة التطبيقات (API)",
  "OTC Terms": "شروط OTC",
  "Convert Terms": "شروط التحويل",
  "Please enter {{min}}-{{max}}": "الرجاء إدخال {{min}}-{{max}}",
  "Favorites": "المفضلة",
  "All Cryptos": "جميع العملات الرقمية",
  "Spot Markets": "أسواق النقدية",
  "Futures Markets": "أسواق الآجل",
  "New Markets": "أسواق جديدة",
  "Metaverse": "العالم الافتراضي",
  "Gaming": "الألعاب",
  "Defi": "ديفي",
  "Innovation": "الابتكار",
  "Layer1/layer2": "الطبقة 1/الطبقة 2",
  "Fan Token": "رمز المعجب",
  "NFT": "رموز لا يمكن تداولها",
  "Storage": "التخزين",
  "Polkadot": "بولكادوت",
  "POS": "POS (ثابت الوزن)",
  "POW": "POW (اختيار المحتسب)",
  "1 Month": "شهر واحد",
  "Symbols": "الرموز",
  "1 Year": "سنة واحدة",
  "Open": "فتح",
  "Remove": "إزالة",
  "FINANCE": "التمويل",
  "TECHNICAL": "التقنية",
  "Close ticket": "إغلاق التذكرة",
  "Updated Date": "تاريخ التحديث",
  "Type a message": "اكتب رسالة",
  "How satisfying it was?": "كم كان الرضا؟",
  "Ticket closed": "تم إغلاق التذكرة",
  "Your current Limit": "حدك الحالي",
  "Switch to enterprise Account": "التبديل إلى حساب الشركة",
  "Video Guide": "دليل الفيديو",
  "Personal Information": "المعلومات الشخصية",
  "Crypto Deposit": "إيداع العملات الرقمية",
  "Unlimited": "غير محدود",
  "Most Popular": "الأكثر شيوعًا",
  "All basic Requirements": "كل المتطلبات الأساسية",
  "Government ID": "هوية حكومية",
  "Facial Verification": "التحقق من الوجه",
  "Review time": "وقت المراجعة",
  "days": "أيام",
  "Fiat Deposit & Withdrawal Limit": "حد الإيداع والسحب بالعملة الورقية",
  "Daily": "يوميًا",
  "P2P Transaction Limits": "حدود المعاملات بين الأفراد",
  "Crypto Withdraw": "سحب العملات الرقمية",
  "Highest Fiat Limit": "أعلى حد للعملة الورقية",
  "Proof of Address": "دليل العنوان",
  "Quick & Easy": "سريع وسهل",
  "WAITING": "في انتظار",
  "Advanced requested": "طلب متقدم",
  "Intermediate requested": "طلب متوسط",
  "ACTIVE": "نشط",
  "Intermediate by admin": "متوسط بواسطة المشرف",
  "Advanced by admin": "متقدم بواسطة المشرف",
  "Registered": "مسجل",
  "Select Approve or Reject status": "حدد حالة الموافقة أو الرفض",
  "Blocked": "محظور",
  "USDT Value": "قيمة USDT",
  "USD Value": "قيمة USD",
  "Please fill the blank": "يرجى ملء الفراغ",
  "Please select one": "يرجى اختيار واحد",
  "ID Card": "بطاقة الهوية",
  "User Image": "صورة المستخدم",
  "Country / region": "الدولة / المنطقة",
  "The user reject successfully": "تم رفض المستخدم بنجاح",
  "Advanced Verification": "التحقق المتقدم",
  "Confirm Residential Address": "تأكيد عنوان الإقامة",
  "Please choose and upload one of the following documents":
    "يرجى اختيار وتحميل واحد من الوثائق التالية",
  "Utility bill (electricity, gaz. water/sewage, fibre/broadband service)":
    "فاتورة الخدمات العامة (الكهرباء، الغاز، المياه/الصرف الصحي، الألياف/خدمة النطاق العريض)",
  "Select Photo": "حدد الصورة",
  "Upload .JPG/.JPEG/.PNG file and no more than 5M": "قم بتحميل ملف .JPG/.JPEG/.PNG ولا يزيد عن 5 ميجا",
  "Bank statement": "كشف حساب بنكي",
  "Basic Verification": "التحقق الأساسي",
  "Identity Information": "معلومات الهوية",
  "Nationality": "الجنسية",
  "Select your nationality": "حدد جنسيتك",
  "date of birth": "تاريخ الميلاد",
  "Please input your street address!": "الرجاء إدخال عنوان الشارع الخاص بك!",
  "Please input your city!": "الرجاء إدخال اسم مدينتك!",
  "Please input your phone number!": "الرجاء إدخال رقم هاتفك!",
  "Please input your postal code!": "الرجاء إدخال الرمز البريدي الخاص بك!",
  "Use a valid government-issued document": "استخدام وثيقة حكومية صالحة",
  "Only the following documents listed below will be accepted":
    "سيتم قبول الوثائق المدرجة أدناه فقط",
  "Id Card": "بطاقة الهوية",
  "Government-issued": "صادرة عن الحكومة",
  "place documents against a single-colored background":
    "وضع الوثائق أمام خلفية لون واحد",
  "Readable, well-lit, colored images": "صور قراءة ومضاءة جيدًا وملونة",
  "Original Full-size, unedited documents": "وثائق أصلية بحجم كامل، غير محررة",
  "No black and white images": "لا توجد صور سوداء وبيضاء",
  "No edited or expired documents": "لا وثائق تم تحريرها أو انتهت صلاحيتها",
  "file size must be between 10kb and 512kb in jpg/jpeg/png format":
    "يجب أن يكون حجم الملف بين 10 كيلو بايت و 512 كيلو بايت في تنسيق jpg/jpeg/png",
  "Basic information": "المعلومات الأساسية",
  "Change Password": "تغيير كلمة المرور",
  "Two-step verification": "التحقق من خطوتين",
  "Change email": "تغيير البريد الإلكتروني",
  "Receive SMS after transactions": "تلقي رسائل قصيرة بعد المعاملات",
  "Receive notifications after transactions (Guide)":
    "تلقي الإشعارات بعد المعاملات (الدليل)",
  "Activities": "الأنشطة",
  "Receive SMS after each login": "تلقي رسائل قصيرة بعد كل تسجيل دخول",
  "Receive emails after transactions": "تلقي رسائل البريد الإلكتروني بعد المعاملات",
  "Receive emails after each login": "تلقي رسائل البريد الإلكتروني بعد كل تسجيل دخول",
  "User ID": "معرف المستخدم",
  "Registration via: Website": "التسجيل عبر: الموقع الإلكتروني",
  "Registration Date": "تاريخ التسجيل",
  "Save Changes": "حفظ التغييرات",
  "Default": "افتراضي",
  "Please input a valid oldEmailAddress": "الرجاء إدخال عنوان بريد إلكتروني قديم صالح",
  "Please input a valid email": "الرجاء إدخال عنوان بريد إلكتروني صالح",
  "GO BACK": "العودة",
  "Total Buy": "إجمالي الشراء",
  "Total Referrals": "إجمالي الإحالات",
  "Total Tickets": "إجمالي التذاكر",
  "Total Transactions": "إجمالي المعاملات",
  "Highest Buy": "أعلى سعر شراء",
  "Highest Sell": "أعلى سعر بيع",
  "Total Trade": "إجمالي التداول",
  "Total Order": "إجمالي الطلب",
  "Total Fiat Income": "إجمالي دخل العملة الورقية",
  "Block user": "حظر المستخدم",
  "Intermediate user": "مستخدم متوسط",
  "Advanced user": "مستخدم متقدم",
  "items": "العناصر",
  "Close By Admin": "إغلاق بواسطة المشرف",
  "All tickets": "جميع التذاكر",
  "Ticket Title": "عنوان التذكرة",
  "Pending tickets": "التذاكر المعلقة",
  "Total messages": "إجمالي الرسائل",
  "Open tickets": "التذاكر المفتوحة",
  "Please input dollar limit amount!": "الرجاء إدخال مبلغ الحد بالدولار!",
  "Please input rial limit amount!": "الرجاء إدخال مبلغ الحد بالريال!",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.":
    "لتفعيل ذلك، انقر أولاً على زر إرسال رسالة نصية لإرسال رسالة نصية تحتوي على رمز التنشيط وأدخل الجاد في الحقل وانقر فوق زر التنشيط.",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.":
    "لتفعيل ذلك، انقر أولاً على زر إرسال البريد الإلكتروني لإرسال رمز إلى بريدك الإلكتروني وأدخل الرمز في الحقل وانقر فوق زر التنشيط. قد تكون قد استلمت بريدًا إلكترونيًا في مجلد (البريد العشوائي / الفاصل).",
  "To activate this feature, follow these steps:": "لتفعيل هذه الميزة، اتبع هذه الخطوات:",
  "2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program.":
    "2. بعد تثبيت وتشغيل برنامج Google Authenticator من خلال أحد الطرق التالية، أضف المفتاح إلى البرنامج.",
  "- Enter a provided key: Select this option and enter the following code carefully.":
    "- أدخل المفتاح المقدم: حدد هذا الخيار وأدخل الرمز التالي بعناية.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.":
    "3. أدخل الرمز المستلم (رقم مكون من 6 أرقام) في الخانة أدناه وانقر على زر التنشيط.",
  "1. Get the latest version of Google Authenticator from Google Play or Apple Store":
    "1. احصل على أحدث إصدار من Google Authenticator من Google Play أو Apple Store",
  "- Scan a barcode: Select this option and scan the following barcode.":
    "- مسح الباركود: حدد هذا الخيار وامسح الباركود التالي.",
  "WalletID": "معرف المحفظة",
  "All Networks Status": "حالة جميع الشبكات",
  "Networks Status": "حالة الشبكات",
  "UnVerified": "غير متحقق",
  "User level settings can be changed from this section, and please change the settings of this section carefully.":
    "يمكن تغيير إعدادات مستوى المستخدم من هذا القسم، ويرجى تغيير إعدادات هذا القسم بعناية.",
  "Please input withdrawal of cryptocurrency!": "الرجاء إدخال مبلغ سحب العملة الرقمية!",
  "Please input withdrawal of fiat!": "الرجاء إدخال مبلغ سحب العملة الورقية!",
  "Please input buying digital money (equivalent to dollar)!":
    "الرجاء إدخال مبلغ شراء العملة الرقمية (ما يعادل الدولار)!",
  "Please input buying digital money (equivalent to rial)!":
    "الرجاء إدخال مبلغ شراء العملة الرقمية (ما يعادل الريال)!",
  "Please input fees for buy cryptocurrency!": "الرجاء إدخال رسوم شراء العملة الرقمية!",
  "Please input fees for sell cryptocurrency!": "الرجاء إدخال رسوم بيع العملة الرقمية!",
  "Please input trade maker fee!": "الرجاء إدخال رسوم صانع التداول!",
  "Please input futures maker fee!": "الرجاء إدخال رسوم صانع التداول في المستقبل!",
  "Please input trade taker fee!": "الرجاء إدخال رسوم مأخذ التداول!",
  "Please input futures taker fee!": "الرجاء إدخال رسوم مأخذ التداول في المستقبل!",
  "Please input internal transaction fee!": "الرجاء إدخال رسوم التحويل الداخلي!",
  "Please input OTC minimum buy!": "الرجاء إدخال الحد الأدنى للشراء في OTC!",
  "Cryptocurrency & Fiat Withdrawal": "سحب العملات الرقمية والعملات الورقية",
  "Tether Fee": "رسوم Tether",
  "Calculation of percentage in the price for buy": "حساب النسبة المئوية في السعر للشراء",
  "Calculation of the percentage in the price for sale": "حساب النسبة المئوية في السعر للبيع",
  "Futures Fee": "رسوم المعاملات الآجلة",
  "Futures Fee (Coin-M Futures)": "رسوم المعاملات الآجلة (Coin-M Futures)",
  "Futures Fee (USDⓈ-M Futures)": "رسوم المعاملات الآجلة (USDⓈ-M Futures)",
  "Trade Fee": "رسوم التداول",
  "Internal Transaction Fee": "رسوم التحويل الداخلي",
  "OTC Minimum Buy": "الحد الأدنى للشراء في OTC",
  "DISAPPROVAL": "غير مُعتمد",
  "Advanced rejected": "مرفوضة متقدمة",
  "Are you sure to remove this user from users?": "هل أنت متأكد من رغبتك في إزالة هذا المستخدم من المستخدمين؟",
  "Are you sure to remove this ticket?": "هل أنت متأكد من رغبتك في إزالة هذا التذكرة؟",
  "Please input your mobile number!": "الرجاء إدخال رقم هاتفك المحمول!",
  "Please select your birth date": "الرجاء تحديد تاريخ ميلادك!",
  "Please select one!": "الرجاء اختيار واحد!",
  "Please input your email adress!": "الرجاء إدخال عنوان بريدك الإلكتروني!",
  "Please input your photo!": "الرجاء إدخال صورتك!",
  "Fiat type": "نوع العملة الورقية",
  "Fiat Status": "حالة العملة الورقية",
  "Withdraw Status": "حالة السحب",
  "Awaiting Approval": "في انتظار الموافقة",
  "Cancelled": "تم الإلغاء",
  "Email Sent": "تم إرسال البريد الإلكتروني",
  "Processing": "قيد المعالجة",
  "Rejected": "تم الرفض",
  "Transaction ID": "هوية المعاملة",
  "Deposit Status": "حالة الوديعة",
  "Deposit Id": "هوية الوديعة",
  "Create Date": "تاريخ الإنشاء",
  "Transaction Type": "نوع المعاملة",
  "Transaction Id": "هوية المعاملة",
  "Sheba": "الشيبا",
  "Deposit type": "نوع الوديعة",
  "Created Date": "تاريخ الإنشاء",
  "Expired": "منتهية الصلاحية",
  "Partially Filled": "مليئة جزئياً",
  "Pending Cancel": "قيد الإلغاء",
  "Limit": "حد",
  "Stop Limit": "حد التوقف",
  "Filled": "ممتلئ",
  "Transaction Date": "تاريخ المعاملة",
  "Stop Price": "سعر التوقف",
  "Stop limit": "حد التوقف",
  "Minimum limit": "الحد الأدنى",
  "Stop price": "سعر التوقف",
  "Are you sure to remove this admin account?": "هل أنت متأكد من رغبتك في إزالة هذا الحساب الإداري؟",
  "Dynamic profit percentage": "نسبة الربح الديناميكية",
  "User Share": "حصة المستخدم",
  "Iran": "إيران",
  "Armenia": "أرمينيا",
  "can see the list of commissions received by this user through introduction.":
    "يمكنك رؤية قائمة العمولات التي تلقاها هذا المستخدم من خلال التعريف.",
  "Crypto Desposit": "إيداع العملات الرقمية",
  "Monthly": "شهريًا",
  "Contry / region": "البلد / المنطقة",
  "Use a valid goverment-issued document": "استخدم مستندًا صادرًا عن الحكومة صالحًا",
  "place documents against a single-coloured background": "ضع المستندات على خلفية ملونة واحدة",
  "Readable, well-lit, colured images": "صور قراءة جيدة، مضاءة بشكل جيد، ملونة",
  "Total Trade": "إجمالي التجارة",
  "Account": "الحساب",
  "Tracking code": "رمز التتبع",
  "This transaction is in progress, and after review, you can either confirm or reject it.":
    "هذه المعاملة قيد التنفيذ، وبعد المراجعة، يمكنك تأكيد أو رفض ذلك.",
  "Are you sure to deny this transaction?": "هل أنت متأكد من رغبتك في رفض هذه المعاملة؟",
  "Are you sure to accept this transaction?": "هل أنت متأكد من رغبتك في قبول هذه المعاملة؟",
  "List of withdrawals": "قائمة السحب",
  "There is no record for this table": "لا يوجد سجل لهذا الجدول",
  "Recurring Buy": "شراء متكرر",
  "In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We`ve got to make it easier for you to activate with each one.":
    "من أجل زيادة مستوى أمان حسابك، نوصي بتمكين تسجيل الدخول ثنائي المرحلة. يمكنك تمكين تسجيل الدخول ثنائي المرحلة التالي، والذي يتضمن تسجيل الدخول ثنائي المرحلة عبر Google والبريد الإلكتروني ورسائل SMS، وأمان الطريقة هو تسجيل الدخول ثنائي المرحلة عبر Google، ولكن حتى تفكر في ثلاثة أنواع يجب أن نجعل من السهل عليك تنشيط كل نوع منها.",
  "Are you sure to remove this order?": "هل أنت متأكد من رغبتك في إزالة هذا الطلب؟",
  "I want to pay": "أرغب في الدفع",
  "I want to sell": "أرغب في البيع",
  "I will receive": "سأتلقى",
  "Deposit account number": "رقم حساب الإيداع",
  "The total number of users": "العدد الإجمالي للمستخدمين",
  "Total balance(Fiat)": "الرصيد الإجمالي (العملة الورقية)",
  "Total number of purchases": "إجمالي عدد الشراء",
  "Total sales": "إجمالي المبيعات",
  "Total sales amount": "إجمالي مبلغ المبيعات",
  "number of orders": "عدد الطلبات",
  "otal order amount": "إجمالي مبلغ الطلبات",
  "Transaction": "المعاملة",
  "Referral Users": "المستخدمون المشارين",
  "Existing users": "المستخدمون الحاليون",
  "Active users": "المستخدمون النشطون",
  "All tickets": "كل التذاكر",
  "Opened Tickets": "التذاكر المفتوحة",
  "Change": "التغيير",
  "Price": "السعر",
  "Pair": "الزوج",
  "Market Trades": "صفقات السوق",
  "Time Amount(MATIC) price(USDT)": "الزمن المبلغ(MATIC) السعر(USDT)",
  "Market Activities": "أنشطة السوق",
  "XMRBUSD": "XMRBUSD",
  "MANAUSDT": "MANAUSDT",
  "SLAPETH": "SLAPETH",
  "ONGBTS": "ONGBTS",
  "MATIC/USDT": "MATIC/USDT",
  "Binance": "بينانس",
  "Volume USDT": "حجم USDT",
  "Volume MATIC": "حجم MATIC",
  "low": "منخفض",
  "Hight": "عالي",
  "Polygon": "بوليجون",
  "STOP_LIMIT": "حد التوقف",
  "MARKET": "السوق",
  "LIMIT": "الحد",
  "Total": "الإجمالي",
  "Amout(MATIC)": "المبلغ(MATIC)",
  "Price(USDT)": "السعر(USDT)",
  "Recurring Buy": "الشراء المتكرر",
  "Rol 0.00% got .years for USDT Recurring Buy": "نسبة 0.00% لمدة .سنة للشراء المتكرر للـ USDT",
  "Oder Book": "كتاب الأوامر",
  "Sum(BTC)": "المجموع(BTC)",
  "Size(BTC)": "الحجم(BTC)",
  "Trades": "الصفقات",
  "BTC/USDT": "BTC/USDT",
  "Sep": "سبتمبر",
  "Oct": "أكتوبر",
  "Nov": "نوفمبر",
  "3y": "3 سنوات",
  "50y": "50 سنة",
  "Trade History": "تاريخ التداول",
  "Open Trades": "الصفقات المفتوحة",
  "Positione": "الموقف",
  "Margin Ratio": "نسبة الهامش",
  "Liq.Price": "سعر التصفية",
  "Entry Price": "سعر الدخول",
  "Size": "الحجم",
  "BTCUSDT": "BTCUSDT",
  "Layer1": "الطبقة 1",
  "Layer2": "الطبقة 2",
  "Fav": "المفضلة",
  "Number of referrals": "عدد المراجعات",
  "Number of transactions": "عدد المعاملات",
  "your total income is based on USD": "إجمالي دخلك يستند إلى الدولار",
  "Updated At": "تم التحديث في",
  "Creat At": "تم الإنشاء في",
  "Deleted": "تم الحذف",
  "Close by admin": "تم الإغلاق بواسطة المشرف",
  "Oder ID": "معرف الطلب",
  "Paysafe History": "تاريخ Paysafe",
  "Perfact Money History": "تاريخ Perfect Money",
  "Oder ID": "معرف الطلب",
  "Tx Code": "رمز المعاملة",
  "sum": "المجموع",
  "BUY": "شراء",
  "Unit price": "سعر الوحدة",
  "in order to increase the security leveled of yor account. we recommend that you enable your two-step login. you can enable the following two-step login. which includes two-step login via Google. email and SMS,and the safest method is two-step login via Google.but for you to consider three types we ot to make it easier for you to activate with each one.": "لزيادة مستوى أمان حسابك، نوصي بتمكين تسجيل الدخول المرحلتين الخاص بك. يمكنك تمكين تسجيل الدخول المرحلتين التالي: تسجيل الدخول المرحلتين عبر Google والبريد الإلكتروني والرسائل النصية (SMS)، وأمان طريقة تسجيل الدخول المرحلتين عبر Google. ولكن لكي تأخذ في اعتبارك ثلاثة أنواع، نقترح أن نجعلها أسهل لك للتنشيط مع كل نوع.",
  "Two-step login via Google": "تسجيل الدخول المرحلتين عبر Google",
  "Two-step login via Sms": "تسجيل الدخول المرحلتين عبر الرسائل النصية (SMS)",
  "Two-step login via Email": "تسجيل الدخول المرحلتين عبر البريد الإلكتروني",
  "Withdrawal of cryptocurrency(equivalent to Dollar)": "سحب العملة المشفرة (ما يعادل الدولار)",
  "Withdrawal of Fiat(equivalent to Dollar)": "سحب الفيات (ما يعادل الدولار)",
  "Buying digital money(equivalent to Dollar)": "شراء الأموال الرقمية (ما يعادل الدولار)",
  "Buying digital money(equivalent to Rial)": "شراء الأموال الرقمية (ما يعادل الريال)",
  "Fees for buy Tether": "رسوم شراء تيثر",
  "Fees for Sell Tether": "رسوم بيع تيثر",
  "Future Fee(Marker)": "رسوم المستقبل (المؤشر)",
  "Future Fee(Taker)": "رسوم المستقبل (المنفذ)",
  "Trade Fee(Marker)": "رسوم التداول (المؤشر)",
  "Trede Fee(Taker)": "رسوم التداول (المنفذ)",
  "Fees for internal transaction": "رسوم المعاملات الداخلية",
  "Min internal transaction": "الحد الأدنى للمعاملة الداخلية",
  "Max internal transaction": "الحد الأقصى للمعاملة الداخلية",
  "Max leverage": "الرافعة المالية القصوى",
  "Max Margin": "الهامش القصوى",
  "Card Number": "رقم البطاقة",
  "Month Of date": "شهر التاريخ",
  "Year of date": "سنة التاريخ",
  "Paysafe Price": "سعر Paysafe",
  "Deposit Type": "نوع الإيداع",
  "Voucher Currency": "وحدة الدفع",
  "First Name": "الاسم الأول",
  "Last Name": "الاسم الأخير",
  "Old Password": "كلمة المرور القديمة",
  "New Password": "كلمة المرور الجديدة",
  "Confirm Password": "تأكيد كلمة المرور",
  "Add Mobile": "إضافة رقم هاتف محمول",
  "There is no record for this table": "لا توجد سجلات لهذا الجدول",
  "Paysafe Price": "سعر Paysafe",
  "Deposite Type": "نوع الإيداع",
  "Voucher Currency": "وحدة الدفع",
  "Fiat Type": "نوع الفيات",
  "Perfect Money Price": "سعر Perfect Money",
  "TxID": "معرف المعاملة",
  "Origin": "المصدر",
  "Entry Price": "سعر الدخول",
  "Leverage": "الرافعة المالية",
  "Margin Ratio": "نسبة الهامش",
  "Value currency": "وحدة القيمة",
  "Address": "العنوان",
  "Recurring Buy": "الشراء المتكرر",
  "Long": "شراء",
  "Short": "بيع",
  "Cost": "تكلفة",
  "Max": "الحد الأقصى",
  "Introduction link": "رابط التعريف",
  "Introduction Code": "رمز التعريف",
  "Number of referrals": "عدد الإحالات",
  "Number of transactions": "عدد المعاملات",
  "Your total income is based on USD": "إجمالي دخلك يعتمد على الدولار",
  "Verify Now": "تحقق الآن",
  "After Basic": "بعد الأساسي",
  "After Intermediate": "بعد المتوسط",
  "Fiat": "فيات",
  "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "لم تقم بأي نشاط بعد. بمجرد بدء التداول في Cryptizi، سيظهر هنا رسم بياني مباشر.",
  "Save Change": "حفظ التغيير",
  "Setting Saved Successfully.": "تم حفظ الإعدادات بنجاح.",
  "Default setting restored successfully.": "تم استعادة الإعدادات الافتراضية بنجاح.",
  "Please enter Spend amount!": "الرجاء إدخال مبلغ الإنفاق!",
  "added to favorites successfully.": "تمت الإضافة إلى المفضلة بنجاح.",
  "Bad Request": "طلب خاطئ",
  "Enter the amount!": "الرجاء إدخال المبلغ!",
  "Please enter total amount!": "الرجاء إدخال المبلغ الإجمالي!",
  "Internal Server Error": "خطأ داخلي في الخادم",
  "Insufficient funds": "أموال غير كافية",
  "Please select a coin!": "الرجاء تحديد عملة!",
  "Please fill in all fields": "الرجاء ملء جميع الحقول",
  "Share percentage changed successfully.": "تم تغيير نسبة المشاركة بنجاح.",
  "deleted from your favorites": "تم حذفه من المفضلة",
  "Invalid request": "طلب غير صالح",
  "Invalid symbol USDTUSDT": "رمز غير صالح USDTUSDT",
  "Card ExpiryDate Month is invalid": "شهر انتهاء صلاحية البطاقة غير صالح",
  "card is expired": "انتهت صلاحية البطاقة",
  "Month of date": "شهر التاريخ",
  "Account Number": "رقم الحساب",
  "Card ExpiryDate Month is invalid": "شهر انتهاء صلاحية البطاقة غير صالح",
  "To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "لتفعيل هذه الميزة ، اتبع هذه الخطوات: \n 1. احصل على أحدث إصدار من Google Authenticator من Google Play أو Apple Store. \n 2. بعد تثبيت وتشغيل برنامج Google Authenticator عبر إحدى الطرق التالية ، أضف المفتاح إلى البرنامج. - مسح الباركود: حدد هذا الخيار ومسح الباركود التالي.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- أدخل المفتاح المقدم: حدد هذا الخيار وأدخل الرمز التالي بعناية.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. أدخل الرمز الذي تم استلامه (رقم مكون من 6 أرقام) في الصندوق أدناه وانقر فوق زر التنشيط.",
  "Google Code": "كود Google",
  "To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.": "للتفعيل ، انقر أولاً على زر إرسال رسالة نصية لإرسال رسالة نصية تحتوي على رمز التفعيل وأدخل gad في الحقل وانقر فوق زر التنشيط.",
  "Send SMS": "إرسال رسالة نصية",
  "Email Code": "كود البريد الإلكتروني",
  "To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.": "للتفعيل ، انقر أولاً على زر إرسال البريد الإلكتروني لإرسال كود إلى بريدك الإلكتروني وأدخل الكود في الحقل وانقر فوق زر التنشيط. قد تكون قد تلقيت بريدًا إلكترونيًا في مجلد (البريد العشوائي / الرسائل غير المرغوب فيها).",
  "Send Email": "إرسال بريد إلكتروني",
  "In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We got to make it easier for you to activate with each one.": "لزيادة مستوى أمان حسابك ، نوصي بتمكين تسجيل الدخول الثنائي. يمكنك تمكين تسجيل الدخول الثنائي التالي ، والذي يتضمن تسجيل الدخول الثنائي عبر Google والبريد الإلكتروني والرسائل النصية ، وأمان طريقة تسجيل الدخول الثنائي عبر Google ، ولكن من أجلك أن تفكر في ثلاثة أنواع حصلنا على جعلها أسهل لك تفعيل مع كل واحدة.",
  "Selecting higher leverage such as [10x] increases your liquidation risk. Always manage your risk levels.": "تحديد هرم عالي مثل [10x] يزيد من مخاطر التصفية الخاصة بك. دائما إدارة مستويات المخاطر الخاصة بك.",
  "Maximum position: 15,000,000 USDT": "الموقف الأقصى: 15,000,000 USDT",
  "Adjust Leverage": "ضبط الهامش",
  "Leverage": "الهامش",
  "Select Symbol": "حدد الرمز",
  "See what the potential risk and reward will be in monetary terms on any given trade. Use our Futures Calculator to establish your potential profit/loss on a future trade. Read tips on how to use.": "انظر إلى ما سيكون الخطر والمكافأة البالغين بالمصطلحات النقدية في أي معاملة معينة. استخدم محسن العقود الآجلة لدينا لتحديد الربح / الخسارة المحتملة في معاملة مستقبلية. اقرأ نصائح حول كيفية الاستخدام.",
  "Liquidation Price": "سعر التصفية",
  "Target Price": "سعر الهدف",
  "Initial Margin": "الهامش الابتدائي",
  "Shot": "بيع",
  "Long": "شراء",
  "Entry Price": "سعر الدخول",
  "Quantity": "الكمية",
  "Calculate": "حساب",
  "Result": "النتيجة",
  "Maximum position": "الموقف الأقصى",
  "Introduction code": "رمز التعريف",
  "Introduction link": "رابط التعريف",
  "No Data": "لا توجد بيانات",
  "Total": "الإجمالي",
  "Number of referrals": "عدد الإحالات",
  "Number of transactions": "عدد المعاملات",
  "Your total income is based on USD": "إجمالي دخلك يعتمد على الدولار",
  "introduction code": "رمز التعريف",
  "introduction link": "رابط التعريف",
  "The total number of users": "إجمالي عدد المستخدمين",
  "Total number of orders": "إجمالي عدد الطلبات",
  "Total sales amount": "إجمالي مبلغ المبيعات",
  "Total number of purchases": "إجمالي عدد المشتريات",
  "Total balance ( Fiat )": "إجمالي الرصيد (فيات)",
  "Total order amount": "إجمالي مبلغ الطلبات",
  "Total sales": "إجمالي المبيعات",
  "Total amount of purchases": "إجمالي مبلغ المشتريات",
  "Opened Tickets": "التذاكر المفتوحة",
  "Existing users": "المستخدمون الحاليون",
  "All tickets": "جميع التذاكر",
  "Referral Users": "المستخدمون المحالون",
  "Active users": "المستخدمون النشطون",
  "Transaction": "المعاملة",
  "Purchases amount": "مبلغ الشراء",
  "Sales amount": "مبلغ البيع",
  "Creat At": "تم الإنشاء في",
  "Updated At": "تم التحديث في",
  "Order": "الطلب",
  "Enter Order ID": "إدخال معرف الطلب",
  "Order ID": "معرف الطلب",
  "First Name": "الاسم الأول",
  "Last Name": "الاسم الأخير",
  "Open Trades": "الصفقات المفتوحة",
  "Tx-Code": "كود Tx",
  "Enter Tx-code": "إدخال كود Tx",
  "Tx Code": "كود Tx",
  "Paysafe Price": "سعر Paysafe",
  "Deposit Type": "نوع الإيداع",
  "Voucher Currency": "وحدة الدفع",
  "Paysafe History": "تاريخ Paysafe",
  "Perfect Money History": "تاريخ Perfect Money",
  "Fiat Amount": "مبلغ الفيات",
  "Perfect Money Price": "سعر Perfect Money",
  "There is no record for this table": "لا يوجد سجل لهذا الجدول",
  "Origin": "الأصل",
  "TxID": "معرف المعاملة",
  "Entry Price": "سعر الدخول",
  "Leverage": "الرافعة المالية",
  "Margin Ratio": "نسبة الهامش",
  "User id": "معرف المستخدم",
  "Fiat withdraw transaction waiting": "انتظار معاملة سحب العملة الورقية",
  "You can see the list of fiat transactions whose information must be confirmed.": "يمكنك رؤية قائمة بالمعاملات الورقية التي يجب تأكيد معلوماتها.",
  "Fiat Type": "نوع العملة الورقية",
  "Account": "الحساب",
  "Actions": "الإجراءات",
  "Fiat deposit transaction waiting": "انتظار معاملة إيداع العملة الورقية",
  "You can see the list of fiat transactions whose information must be confirmed.": "يمكنك رؤية قائمة بالمعاملات الورقية التي يجب تأكيد معلوماتها.",
  "Receipt": "الإيصال",
  "Token": "الرمز",
  "Getway": "البوابة",
  "Percentage in price for sale": "النسبة في السعر للبيع",
  "Purchase price in dollars": "سعر الشراء بالدولار",
  "Percentage on the price to buy": "النسبة على السعر للشراء",
  "Percentage in the price for ( marketing )": "النسبة في السعر للتسويق",
  "Selling price in dollars": "سعر البيع بالدولار",
  "Percentage in price for sale": "النسبة في السعر للبيع",
  "Percentage in the price for ( marketing )": "النسبة في السعر للتسويق",
  "Percentage in the price for purchase": "النسبة في السعر للشراء",
  "Percentage in the price for sale": "النسبة في السعر للبيع",
  "Inventory": "الجرد",
  "Active": "الحد الأدنى للشراء المسموح به",
  "Minimum allowed purchase": "الحد الأدنى للشراء المسموح به",
  "Only Iranian IPs are allowed": "يُسمح فقط بعناوين IP الإيرانية",
  "Deactivating purchase from site": "إلغاء تنشيط الشراء من الموقع",
  "IP Block": "حظر العنوان IP",
  "Num": "رقم",
  "Delete": "حذف",
  "Withdrawal of cryptocurrency (equivalent to Dollar)": "سحب العملة المشفرة (ما يعادل الدولار)",
  "Buying digital money (equivalent to Dollar)": "شراء الأموال الرقمية (ما يعادل الدولار)",
  "Fees for buy Tether": "رسوم شراء تيثر",
  "Marker": "العلامة",
  "Taker": "المأخذ",
  "Fees for internal transaction": "رسوم المعاملة الداخلية",
  "Min internal transaction": "الحد الأدنى للمعاملة الداخلية",
  "Internal Transaction": "المعاملة الداخلية",
  "Internal Transaction": "المعاملة الداخلية",
  "Max Leverage": "أقصى رافعة مالية",
  "Withdrawal of Fiat (equivalent to Dollar)": "سحب العملة الورقية (ما يعادل الدولار)",
  "Buying digital money (equivalent to Rial)": "شراء الأموال الرقمية (ما يعادل الريال)",
  "Fees for Sell Tether": "رسوم بيع تيثر",
  "OTC Minimum Buy": "أدنى شراء OTC",
  "Max internal transaction": "أقصى معاملة داخلية",
  "Max Margin": "أقصى هامش",
  "Futures settings": "إعدادات المستقبل",
  "Disabled": "معطل",
  "Enabled": "مفعل",
  "Max Borrow": "أقصى قرض",
  "Add More Coins": "إضافة المزيد من العملات",
  "List of bank accounts": "قائمة الحسابات البنكية",
  "Bank name": "اسم البنك",
  "Card number": "رقم البطاقة",
  "Account number": "رقم الحساب",
  "Shaba number": "رقم الشبا",
  "Add New": "إضافة جديد",
  "Transfer methods": "طرق التحويل",
  "Name": "الاسم",
  "Card to Card": "من بطاقة إلى بطاقة",
  "Bank deposit": "إيداع في البنك",
  "Remember me": "تذكرني",
  "Web Advertisement Setting": "إعدادات الإعلان على الويب",
  "Show how many days": "عرض عدد الأيام",
  "Advanced editor": "محرر متقدم",
  "Mobile Advertisement Setting": "إعدادات الإعلان على الهاتف المحمول",
  "Write Something awesome...": "اكتب شيئًا رائعًا...",
  "Zibal": "زيبال",
  "Vander": "فاندر",
  "Default": "افتراضي",
  "Total users": "إجمالي عدد المستخدمين",
  "Verified users": "المستخدمين الموثوق بهم",
  "Users are waiting": "المستخدمين في انتظار",
  "Rejected users": "المستخدمين المرفوضين",
  "User Id": "معرف المستخدم",
  "Mobile": "الجوال",
  "Tell": "الهاتف",
  "Select or Drage photo": "اختر أو اسحب الصورة",
  "Introduced user": "المستخدم المعرف به",
  "Total orders": "إجمالي الطلبات",
  "Total commission orders": "إجمالي طلبات العمولة",
  "Average commission per order": "العمولة المتوسطة لكل طلب",
  "There is no record for this table": "لا يوجد سجل لهذا الجدول",
  "Fiat Wallet Transaction": "معاملة محفظة العملات الورقية",
  "Notification Tite": "عنوان الإشعار",
  "Color background": "لون الخلفية",
  "Normal(black)": "عادي (أسود)",
  "Users": "المستخدمين",
  "Active days": "الأيام الفعّالة",
  "Headers": "الرؤوس",
  "Notification /Email /SMS": "الإشعار / البريد الإلكتروني / الرسائل القصيرة",
  "Types": "الأنواع",
  "Recipient": "المستلم",
  "Color": "اللون",
  "Active Days": "الأيام الفعّالة",
  "Total Trade": "إجمالي التجارة",
  "Russia": "روسيا",
  "United Arab Emirates": "الإمارات العربية المتحدة",
  "Saudi Arabia": "المملكة العربية السعودية",
  "User Statistics": "إحصائيات المستخدم",
  "Old Password": "كلمة المرور القديمة",
  "New Password": "كلمة المرور الجديدة",
  "Confirm Password": "تأكيد كلمة المرور",
  "In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We`ve got to make it easier for you to activate with each one.": "لزيادة مستوى أمان حسابك، نوصي بتمكين خاصية تسجيل الدخول المرحلتين. يمكنك تمكين تسجيل الدخول المرحلتين التالي، الذي يتضمن تسجيل الدخول المرحلتين عبر Google والبريد الإلكتروني والرسائل القصيرة، وأمان هذه الطريقة هو تسجيل الدخول المرحلتين عبر Google، ولكن لك مراعاة ثلاثة أنواع يجب عليك تسهيل عملية التنشيط لديك مع كل واحدة.",
  "To activate this feature, follow these steps: 1. Get the latest version of Google Authenticator from Google Play or Apple Store. 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "لتفعيل هذه الميزة، اتبع هذه الخطوات: 1. احصل على أحدث إصدار من Google Authenticator من Google Play أو Apple Store. 2. بعد تثبيت وتشغيل برنامج Google Authenticator من خلال أحد الطرق التالية، أضف المفتاح إلى البرنامج. - مسح الباركود: حدد هذا الخيار ومسح الباركود التالي.",
  "- Enter a provided key: Select this option and enter the following code carefully.": "- إدخال المفتاح المقدم: حدد هذا الخيار وأدخل الكود التالي بعناية.",
  "3. Enter the received code (6-digit number) in the box below and click the activate button.": "3. أدخل الكود المستلم (رقم مكون من 6 أرقام) في الخانة أدناه وانقر على زر التنشيط.",
  "Google Code": "كود Google",
  "New Email": "البريد الإلكتروني الجديد",
  "Add Mobile": "إضافة هاتف",
  "Internal Server Error": "خطأ في الخادم الداخلي",
  "Only normal user allowed": "يُسمح فقط بالمستخدم العادي",
  "Please input your old password!": "يرجى إدخال كلمة المرور القديمة!",
  "Passwords must have at least 8 characters and contain at least one uppercase letter, lowercase letter, number, and symbol.": "يجب أن تحتوي كلمات المرور على الأقل على 8 أحرف وتحتوي على حرف كبير وحرف صغير ورقم ورمز.",
  "Please input your confirm password!": "يرجى إدخال كلمة المرور التأكيدية!",
  "Passwords must have at least 8 characters and contain at least one uppercase letter, lowercase letter, number, and symbol.": "يجب أن تحتوي كلمات المرور على ما لا يقل عن 8 أحرف وتحتوي على حرف كبير وحرف صغير ورقم ورمز واحد على الأقل.",
  "Please input your confirm password!": "الرجاء إدخال كلمة المرور التأكيدية!",
  "Invalid soft otp": "رمز الاستجابة غير صالح",
  "Session not found": "لم يتم العثور على الجلسة",
  "Send SMS": "إرسال رسالة نصية قصيرة",
  "send SMS otp need phone number!": "تحتاج إلى رقم هاتف لإرسال رمز الاستجابة السريعة عبر الرسائل النصية!",
  "User Id": "رقم المستخدم",
  "Final amount": "المبلغ النهائي",
  "Fiat Type": "نوع العملة الورقية",
  "Waller ID": "رقم المحفظة",
  "Pay Safe Voucher": "قسيمة الدفع الآمنة",
  "Pay Safe": "الدفع الآمن",
  "Black": "أسود",
  "Red": "أحمر",
  "Green": "أخضر",
  "Advanced level": "مستوى متقدم",
  "Fixed alert": "تحذير ثابت",
  "Notification Fixed alert": "تحذير ثابت تم تحديده",
  "Test": "اختبار",
  "Fixed alert test": "اختبار تحذير ثابت",
  "Fixed alert test 2": "اختبار تحذير ثابت 2",
  "Welcome back": "مرحبًا مرة أخرى",
  "Please active your 2FA Authenticator": "يرجى تفعيل جهاز المصادقة الثنائية الخاص بك",
  "Success (green)": "نجاح (أخضر)",
  "Danger (red)": "خطر (أحمر)",
  "Warning (yellow)": "تحذير (أصفر)",
  "Order Book": "كتاب الطلبات",
  "Futures Fee(Marker)": "رسوم المستقبل (المشتري)",
  "Futures Fee(Taker)": "رسوم المستقبل (البائع)",
  "Trade Fee(Marker)": "رسوم التداول (المشتري)",
  "Trade Fee(Traker)": "رسوم التداول (البائع)",
      'Popup link': 'الرابط المنبثق',
      'Please choose an image': 'الرجاء اختيار صورةe',
      Tokens: 'الرموز',
      'Add token': 'أضف رمزًا مميزًا',
      'Main wallet setting': 'إعداد المحفظة الرئيسية',
      'Network setting': 'إعدادات الشبكة',
      "Listing":'قائمة',
      "Cryptizi offers a powerful platform to take advantage of a broad user base and solid infrastructure, improving your token's market visibility and accessibility." : "تقدم Cryptizi منصة قوية للاستفادة من قاعدة مستخدمين واسعة وبنية تحتية قوية، مما يحسن رؤية السوق وإمكانية الوصول إلى رمزك المميز.",
      "Application Process" :'عملية التطبيق',
      "Listing Application" : 'تطبيق الإدراج',
      "To apply for listing, simply click on 'Listing Application'. Our business manager will promptly get in touch with you.":"لتقديم طلب الإدراج، ما عليك سوى الضغط على طلب الإدراج . سيقوم مدير أعمالنا بالتواصل معك على الفور.",
      "For any queries, reach out to us at listing@cryptizi.com. Thank you!":"لأية استفسارات، تواصل معنا على listing@cryptizi.com. شكرًا لك!" ,
      "Ticket Number" : 'رقم التذكرة',
      "Created At" : 'أنشئت في',
      "Dedicated Wallet Settings" : 'إعداد المحفظة المخصصة'

    },
  };
  
  export default ar;
  