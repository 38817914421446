import { useEffect } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

export default function usePerfectMoneyForm( data ) {

  const validators = Yup.object().shape({
    accountId: Yup.string().notRequired(),
    password: Yup.string().notRequired(),
    walletId: Yup.string().notRequired(),
    queryPerfectMoneyPrice: Yup.boolean().notRequired(),
    purchaseStatus: Yup.boolean().notRequired(),
    saleStatus: Yup.boolean().notRequired(),
    purchaseDollarPrice: Yup.string().notRequired(),
    saleDollarPrice: Yup.string().notRequired(),
    purchasePercentage: Yup.string().notRequired(),
    salePercentage: Yup.string().notRequired(),
    purchasePercentageMarketing: Yup.string().notRequired(),
    salePercentageMarketing: Yup.string().notRequired(),
    voucherPurchaseStatus: Yup.boolean().notRequired(),
    voucherSaleStatus: Yup.boolean().notRequired(),
    voucherPurchasePercentage:Yup.string().notRequired(),
    voucherSalePercentage:Yup.string().notRequired()
  });

  const defaultValues = {
    accountId: data?.accountId || '',
    password: data?.password || '',
    walletId: data?.walletId || '',
    queryPerfectMoneyPrice: data?.queryPerfectMoneyPrice || false,
    purchaseStatus: data?.purchaseStatus || false,
    saleStatus: data?.saleStatus || false,
    purchaseDollarPrice: data?.purchaseDollarPrice || '',
    saleDollarPrice: data?.saleDollarPrice || '',
    purchasePercentage: data?.purchasePercentage || '',
    salePercentage: data?.salePercentage || '',
    purchasePercentageMarketing: data?.purchasePercentageMarketing || '',
    salePercentageMarketing: data?.salePercentageMarketing || '',
    voucherPurchaseStatus:  data?.voucherPurchaseStatus || false,
    voucherSaleStatus:  data?.voucherSaleStatus || false,
    voucherPurchasePercentage:data?.voucherPurchasePercentage || '',
    voucherSalePercentage:data?.voucherSalePercentage || '',
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    getValues
  } = methods;

  useEffect(() => {
    if (data) {
      reset(defaultValues);
    }
    if (!data) {
      reset(defaultValues);
    }
  }, [data]);

  return {
    handleSubmit,
    isSubmitting,
    methods,
    watch,
    getValues
  };
}
