import React, { useEffect } from 'react';
import { Table } from 'antd';
import './openOrdersTableAntdStyles.scss';
import classes from './openOrdersTable.module.scss';
import { MdDelete } from 'react-icons/md';
import Api from '../../../../../http/serviceApi';
import { convertTimeStampToDate } from '../../../../../utils/dateConverter';
import { useDispatch } from 'react-redux';
import { SET_USER_TRADEINFO, SET_Loading_USER_TRADEINFO } from '../../../../../store/actionTypes';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTheme } from '@mui/material';

const OpenOrdersTable = (props) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const openTradeInfos = useSelector((store) => store.openTradeInfos);
  const orderHistoryDate = useSelector((store) => store.orderHistoryDate);
  const loading_UserTradeInfo = useSelector((store) => store.loading_UserTradeInfo);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: 180,
      render: (key, record) => <span>{convertTimeStampToDate(key)}</span>,
    },
    {
      title: 'Pair',
      dataIndex: 'symbol',
      key: 'symbol',
      width: 100,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 100,
    },
    {
      title: 'Side',
      dataIndex: 'side',
      key: 'side',
      width: 45,
      sorter: (a, b) => {
        a = a.side || '';
        b = b.side || '';
        return a.localeCompare(b);
      },
      render: (key, record) => (
        <a href='/' className={key === 'BUY' ? classes.sideBuy : classes.sideSell}>
          {key}
        </a>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: 100,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
    },
    {
      title: 'Filled',
      dataIndex: 'executed',
      key: 'executed',
      width: 100,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 100,
    },
    {
      title: 'Trigger Conditions',
      dataIndex: 'triggerConditions',
      key: 'triggerConditions',
      width: 90,
    },
    {
      title: 'Cancel',
      dataIndex: 'cancelAll',
      key: 'cancelAll',
      width: 70,
      align: 'center',
      render: (key, record) => (
        <MdDelete className={classes.deleteRowButton} onClick={() => onRowDeleteClick(record)} />
      ),
    },
  ];

  useEffect(() => {
    getUserTradeInfo_Once();
    const interval = setInterval(getUserTradeInfo, 15000);
    return () => {
      clearInterval(interval);
    };
  }, [props.symbolInfo, orderHistoryDate]);

  const getUserTradeInfo_Once = async () => {
    dispatch({
      type: SET_Loading_USER_TRADEINFO,
      payload: {
        loading_UserTradeInfo: true,
      },
    });

    await getUserTradeInfo();

    dispatch({
      type: SET_Loading_USER_TRADEINFO,
      payload: {
        loading_UserTradeInfo: false,
      },
    });
  };

  const getUserTradeInfo = async () => {
    if (props.symbolInfo) {
      const data = {
        startDate: orderHistoryDate.startDate,
        finishDate: orderHistoryDate.finishDate,
        symbol: `${props.symbolInfo?.baseAsset}${props.symbolInfo?.quoteAsset}`,
      };
      const response = await Api.getUserTradeInfo(data);
      if (response?.status === 'SUCCESS') {
        dispatch({
          type: SET_USER_TRADEINFO,
          payload: {
            openTradeInfos: response.openTradeInfos,
            tradeHistoryInfos: response.tradeHistoryInfos,
          },
        });
      }
      console.log('getUserTradeInfo-response: ', response);
    }
  };

  const deleteOrder = async (record) => {
    dispatch({
      type: SET_Loading_USER_TRADEINFO,
      payload: {
        loading_UserTradeInfo: true,
      },
    });

    const response = await Api.deleteOrder(record?.orderId, record?.symbol);
    if (response?.status === 'SUCCESS') {
      enqueueSnackbar('The order deleted successfully.', { variant: 'success' });
      getUserTradeInfo();
    }
    console.log('deleteOrder: ', response);

    dispatch({
      type: SET_Loading_USER_TRADEINFO,
      payload: {
        loading_UserTradeInfo: false,
      },
    });
  };

  const onRowDeleteClick = (record) => {
    console.log(record);
    deleteOrder(record);
  };

  return (
    <div>
      <Table
        direction='horizental'
        className={theme.palette.mode === 'dark' ? 'openOrdersTableStyle' : 'openOrdersTableStyleLight'}
        dataSource={openTradeInfos}
        columns={columns}
        rowKey='symbol'
        pagination={{ defaultPageSize: 20 }}
        bordered={false}
        loading={loading_UserTradeInfo}
        scroll={{ x: 800, y: 250 }}
      />
    </div>
  );
};

export default OpenOrdersTable;
