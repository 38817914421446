import React, { useState } from 'react';
import { Stack, TextField, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ComplexToolbar from '../../../../../../components/ComplexToolbar';

const WalletToolbar = ({ onFilter, isExportLoading, onExport }) => {
  const { t } = useTranslation();

  const [nameFilter, setNameFilter] = useState('');

  const handleFilter = () => {
    onFilter({ nameFilter });
  };

  const resetFilter = () => {
    setNameFilter('');

    onFilter({
      nameFilter: '',
    });
  };

  return (
    <ComplexToolbar
      onSearch={false}
      onPrint={() => console.log('print')}
      onExport={onExport}
      isExportLoading={isExportLoading}
    >
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} sx={{ mt: 1 }} alignItems={'center'}>
          <TextField
            label={t('Name')}
            variant='standard'
            fullWidth
            sx={{ maxWidth: 300 }}
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />
        </Stack>
      </Stack>

      <Stack direction={'row'} spacing={2} sx={{ mt: 5, mb: 5.75 }}>
        <Button
          onClick={handleFilter}
          fullWidth
          variant='contained'
          sx={{ maxWidth: 204, typography: 'body3', fontWeight: 500 }}
        >
          {t('Filter')}
        </Button>
        <Button
          onClick={resetFilter}
          fullWidth
          variant='outlined'
          color='inherit'
          sx={{ maxWidth: 80, typography: 'body3', fontWeight: 500, borderColor: 'grey.400' }}
        >
          {t('Reset')}
        </Button>
      </Stack>
    </ComplexToolbar>
  );
};

export default WalletToolbar;
