// @mui
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { forwardRef } from 'react';
import { confirmable } from 'react-confirm';
import { useTranslation } from 'react-i18next';
import { FONT_RTL } from '../theme/typography.js';
import ThemeProvider from '../theme/index.js';
import { Provider } from 'react-redux';
import store from '../store/index.js';

// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => <Slide direction='up' ref={ref} {...props} />);

// ----------------------------------------------------------------------

function ConfirmModal({ show, proceed, confirmation, Content, options }) {
  const { t } = useTranslation();

  return (
    <Provider store={store}>
      <ThemeProvider>
        <Dialog
          open={show}
          TransitionComponent={Transition}
          onCancel={() => proceed(false)}
          sx={{ '& .MuiPaper-root': { backgroundColor: 'grey.800', borderRadius: '10px', p: 1 } }}
        >
          <DialogTitle sx={{ fontFamily: FONT_RTL, fontSize: 16, color: 'grey.0' }}>{confirmation}</DialogTitle>

          {Content && <DialogContent>{Content}</DialogContent>}

          <DialogActions sx={{ mb: 1.2 }}>
            <Button
              onClick={() => proceed(false)}
              sx={{ color: '#ccc', fontFamily: FONT_RTL, fontSize: 14, ml: 1, fontWeight: 600 }}
            >
              {t('No')}
            </Button>
            <Button
              onClick={() => proceed(true)}
              variant='contained'
              sx={{
                fontFamily: FONT_RTL,
                fontSize: 14,
                width: 100,
                borderRadius: '100px',
                background: 'linear-gradient(to left, #11C9BD, #0ABFB4, #03B6AA)',
              }}
            >
              {t('Yes')}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </Provider>
  );
}

export default confirmable(ConfirmModal);
