// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          ...theme.typography.body2,
          lineHeight: 1,
          borderRadius: '100px',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          height: 47,
        },
        containedPrimary: {
          background: theme.palette.gradients.primaryButton,
        },
        containedSecondary: {
          background: theme.palette.gradients.secondaryButton,
        },
        containedWarning: {
          color: '#fff',
          background: theme.palette.warningButton,
        },
      },
    },
  };
}
