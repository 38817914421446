import { useEffect } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

export default function usePopupWebForm(data) {

  const validators = Yup.object().shape({
    active: Yup.boolean().notRequired(),
    showDays: Yup.string().notRequired(),
    text: Yup.string().notRequired(),
  });

  const defaultValues = {
    active: data && data.status !== 'FAILURE' ? data.active : false,
    showDays: data && data.status !== 'FAILURE' ? data.showDays : '',
    text: data && data.status !== 'FAILURE' ? data.value : '',
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (data) {
      reset(defaultValues);
    }
    if (!data) {
      reset(defaultValues);
    }
  }, [data]);

  return {
    handleSubmit,
    isSubmitting,
    methods,
    isActive: watch('active'),
  };
}
