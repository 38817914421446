import { Autocomplete, IconButton, InputBase, Stack, TextField, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Iconify from '../Iconify';
import { adminNavConfig, userNavConfig } from '../../navConfig';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

export default function Searchbar({ isAdmin }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // console.log(isAdmin);
  const searchItems = useMemo(() => {
    const list = isAdmin ? adminNavConfig[0].items : userNavConfig;
    return list.reduce((acc, cur) => {
      if (cur.items?.length) {
        cur.items.forEach((item) => item.path && acc.push({ label: t(item.title), path: item.path }));
      } else if (cur.children?.length) {
        cur.children.forEach((item) => item.path && acc.push({ label: t(item.title), path: item.path }));
      } else {
        cur.path && acc.push({ label: t(cur.title), path: cur.path });
      }
      return acc;
    }, []);
  }, []);

  const theme = useTheme();

  return (
    <Stack
      direction='row'
      sx={{ backgroundColor: 'grey.700', borderRadius: 1, height: 40, width: 250, alignItems: 'center' }}
    >
      <Autocomplete
        size='small'
        sx={{ flexGrow: 1 }}
        options={searchItems}
        freeSolo
        onChange={(_, item) => navigate(item.path)}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              flex: 1,
              border: 'none',
              '& input': { ...theme.typography.body2, color: 'grey.0', '&::placeholder': { color: 'grey.400' } },
              '& .MuiOutlinedInput-root': {
                borderRadius: 0,
                '&:hover': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                },
              },
              '& fieldset': {
                border: 'none',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            placeholder={t('Search')}
          />
        )}
      />
      <IconButton type='button' aria-label='search'>
        <Iconify icon={'eva:search-fill'} width={22} height={22} color='grey.0' />
      </IconButton>
    </Stack>
  );
}
