import React, { useState } from 'react';
import { Button, Row, Col, Upload, Modal, Form, Image } from 'antd';
import classes from './interAdditionalInfo.module.scss';
import './interAdditionalInfoAntdStyles.scss';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import closeIcon from '../../../../../assets/close-circle.svg';
import tickIcon from '../../../../../assets/tick-circle.svg';
import { useNavigate } from 'react-router';
import { FaRegTrashAlt } from 'react-icons/fa';
import { SET_AUTHENTICATION_Intermediate } from '../../../../../store/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { navigationUrl } from '../../../../../navigationUrl';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTranslation } from 'react-i18next';

const InterAdditionalInfo = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [selectPic, setSelectPic] = useState('');
  const [selectValidationMessage, setSelectValidationMessage] = useState('');
  const dispatch = useDispatch();
  const interAuthInfo = useSelector((store) => store.interAuthInfo);

  const colProps = {
    xs: 24,
    sm: 24,
    md: 19,
    lg: 15,
    xl: 11,
  };

  const colPropsBotton = {
    xs: 24,
    sm: 10,
    md: 10,
    lg: 10,
    xl: 10,
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const onFileInputChange = (e) => {
    console.log('file: ', e.target.files[0]);
    let file = e.target.files[0];
    getBase64(file, (result) => {
      let spl = result.split('/')[1];
      let format = spl.split(';')[0];
      let resultRemovedheader = result.replace(`data:image/${format};base64,`, '');
      setSelectPic(resultRemovedheader);
      if (resultRemovedheader && resultRemovedheader !== '') setSelectValidationMessage('');
      console.log('selectPic: ', resultRemovedheader);
    });
  };

  const onContinueButtonClick = () => {
    if (!selectPic || selectPic === '') {
      setSelectValidationMessage(t('Please select a photo.'));
      return;
    }

    const data = {
      interAuthInfo: {
        ...interAuthInfo,
        docData: selectPic,
      },
    };
    console.log(data);
    dispatch({
      type: SET_AUTHENTICATION_Intermediate,
      payload: data,
    });
    navigate(navigationUrl.auth_inter_facescan);
  };

  return (
    <Row justify='center' style={{ marginLeft: '10px', marginRight: '10px' }}>
      <Col {...colProps}>
        <div className={classes.formBody}>
          <div className={classes.formTitleDiv}>
            <span className={classes.formTitleBig}>{t('Intermediate Verification')}</span>
            <span className={classes.formTitleSmall}>{t('Additional Infomation')}</span>
          </div>

          <div className={classes.items}>
            <div>
              <img src={tickIcon} />
              <span>{t('Government-issued')}</span>
            </div>
            <div>
              <img src={tickIcon} />
              <span>{t('Original Full-size, unedited documents')}</span>
            </div>
            <div>
              <img src={tickIcon} />
              <span>{t('place documents against a single-coloured background')}</span>
            </div>
            <div>
              <img src={tickIcon} />
              <span>{t('Readable, well-lit, colured images')}</span>
            </div>
            <div>
              <img src={closeIcon} />
              <span>{t('No black and white images')}</span>
            </div>
            <div>
              <img src={closeIcon} />
              <span>{t('No edited or expired documents')}</span>
            </div>
          </div>

          <div className={classes.picHelpText}>
            <span>{t('file size must be between 10kb and 512kb in jpg/jpeg/png format')}</span>
          </div>

          <Col>
            <div
              className={classes.uploadContainer}
              style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}
            >
              <div className={classes.uploadPhotoDiv}>
                {selectPic ? (
                  <Image src={`data:image/jpg;base64,${selectPic}`} />
                ) : (
                  <div>
                    <input
                      type='file'
                      accept='.jpg,.jpeg,.png'
                      name='Edit'
                      id='selectedFile'
                      onChange={onFileInputChange}
                      style={{ display: 'none' }}
                    />
                    <Button
                      type='text'
                      style={{
                        backgroundColor: 'transparent',
                        display: 'flex',
                        width: 'auto',
                        height: 'auto',
                      }}
                      onClick={() => document.getElementById('selectedFile').click()}
                    >
                      <div>
                        <AiOutlinePlusCircle size={24} />
                        <div
                          style={{
                            marginTop: 8,
                          }}
                        >
                          {t('Select Photo')}
                        </div>
                      </div>
                    </Button>
                  </div>
                )}
              </div>
              {selectPic && (
                <div className={classes.deleteIconDiv} onClick={() => setSelectPic('')}>
                  <FaRegTrashAlt />
                </div>
              )}
            </div>
            <span className={classes.validationText}>{selectValidationMessage}</span>
          </Col>

          <Row justify='space-evenly' style={{ marginTop: '40px' }}>
            <Col {...colPropsBotton}>
              <Form.Item>
                <Button type='primary' block shape='round' onClick={onContinueButtonClick}>
                  {t('Continue')}
                </Button>
              </Form.Item>
            </Col>
            <Col {...colPropsBotton}>
              <Form.Item>
                <Button type='default' block shape='round' onClick={() => navigate(navigationUrl.auth_inter_select)}>
                  {t('Back')}
                </Button>
              </Form.Item>
            </Col>
          </Row>

          <div className={classes.indicatorDiv}>
            <div className={classes.indicatorItemGray} />
            <div className={classes.indicatorItemGreen} />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default InterAdditionalInfo;
