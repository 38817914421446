import { Button, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import FillSelect from '../../../../components/FillSelect';
import Iconify from '../../../../components/Iconify';
import SymbolIcon from '../../../../components/SymbolIcon';
import { navigationUrl } from '../../../../navigationUrl';
import { symbolList } from '../request/p2pTab';

export default function P2PSection({ sideFilter, setSideFilter, symbolFilter, setSymbolFilter }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{ m: { xs: 3, md: 5 }, mb: 3.75 }}
      spacing={2}
    >
      <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'center'} spacing={3}>
        <Stack direction={'row'}>
          <ToggleButton active={sideFilter === 'BUY'} onClick={() => setSideFilter('BUY')} type='buy'>
            {t('Buy')}
          </ToggleButton>

          <ToggleButton active={sideFilter === 'SELL'} onClick={() => setSideFilter('SELL')} type='sell'>
            {t('Sell')}
          </ToggleButton>
        </Stack>

        {/* <Stack direction={'row'} spacing={1}>
          <Typography variant='body3'> {t('USDT')}</Typography>
          <Typography variant='body3'> {t('Stay tuned for more coin types!')}</Typography>
        </Stack> */}

        <FillSelect
          value={symbolFilter}
          onChange={setSymbolFilter}
          sx={{ minWidth: { xs: '100%', md: 200 }, '& .MuiSelect-select': { color: 'grey.0', py: 1, px: 2 } }}
        >
          {symbolList.map((symbol, index) => (
            <MenuItem value={symbol.label} key={index}>
              <Stack direction='row' alignItems={'center'} spacing={1.5}>
                <SymbolIcon symbol={symbol.icon} />
                <Typography variant='body2'>{symbol.label}</Typography>
              </Stack>
            </MenuItem>
          ))}
        </FillSelect>
      </Stack>

      <Button
        variant='contained'
        color='warning'
        sx={{
          width: { xs: '100%', sm: 160 },
          height: 40,
          borderRadius: 0,
        }}
        startIcon={<Iconify icon='eva:plus-outline' />}
        onClick={() => navigate(navigationUrl.p2p_request)}
      >
        {t('Post New Ad')}
      </Button>
    </Stack>
  );
}

const ToggleButton = ({ children, active = false, type = 'buy', ...other }) => {
  const theme = useTheme();

  return (
    <Button
      variant='contained'
      color={type === 'buy' ? 'success' : 'error'}
      sx={{
        width: 155,
        height: 40,
        borderRadius: 0,
        typography: 'body3',
        color: 'grey.0',
        ...(!active && { backgroundColor: 'grey.600', opacity: 0.6 }),
        '&:hover': {
          backgroundColor: type === 'buy' ? theme.palette.success.main : theme.palette.error.main,
          opacity: 0.8,
        },
      }}
      {...other}
    >
      {children}
    </Button>
  );
};
