import { useEffect, useState } from 'react';
import { Box, Card, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'jalali-moment';

import useTable, { getComparator } from '../../../../hooks/useTable';
import { PageTitle } from '../../../../components/PageTitle';
import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../components/table';
import { applySortFilter } from '../../../../utils';
import P2PHistoryTableMakeRow from './p2pHistoryTableMakeRow';
import P2PHistoryTableTakeRow from './p2pHistoryTableTakeRow';
import Api from '../../../../http/serviceApi';
import AdminApi from '../../../../http/adminHttp/serviceApiAdmin';
import P2PHistoryTableToolbar from './p2pHistoryTableToolbar';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { exportExcel } from '../../../../utils/excel';
import { getDefaultDateFilterValues } from '../../../../utils/filter';
import { exportPDF } from '../../../../utils/pdf';

// ----------------------------------------------------------------------

export default function P2PHistoryTable({ isAdmin = false }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((store) => store.language);

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'P2P History' },
    });
  }, []);

  const [sideFilter, setSideFilter] = useState('ALL'); // ALL, BUY, SELL
  const [stateFilter, setStateFilter] = useState('ALL'); // ALL, CANCELED, COMPLETED, FAILED, PARTIALLY_COMPLETED, PENDING
  const [sectionFilter, setSectionFilter] = useState('MAKE'); // MAKE, TAKE
  const [timeFilter, setTimeFilter] = useState({
    startTime: getDefaultDateFilterValues().firstDay,
    endTime: getDefaultDateFilterValues().lastDay,
  });
  const [transactionIdFilter, setTransactionIdFilter] = useState('');
  const [symbolFilter, setSymbolFilter] = useState('');
  const [amountFilter, setAmountFilter] = useState([0, 100000]);
  const [firstNameFilter, setFirstNameFilter] = useState('');
  const [lastNameFilter, setLastNameFilter] = useState('');

  const TABLE_HEAD =
    sectionFilter === 'MAKE'
      ? [
          { id: 'createDate', label: 'Time', align: 'left' },
          ...(isAdmin ? [{ id: 'user', label: 'User', align: 'left' }] : []),
          { id: 'side', label: 'Side', align: 'center' },
          { id: 'price', label: 'Price', align: 'left' },
          { id: 'amount', label: 'Amount', align: 'left' },
          { id: 'remainderAmount', label: 'Remainder', align: 'center' },
          { id: 'available', label: 'Available/Limits', align: 'left' },
          { id: 'transactionCode', label: 'Tx-code', align: 'center' },
          { id: 'state', label: 'State', align: 'center' },
          // ...(!isAdmin ? [{ id: 'action', label: 'Action', align: 'center' }] : []),
        ]
      : [
          { id: 'takeTimestamp', label: 'Time', align: 'left' },
          ...(isAdmin ? [{ id: 'user', label: 'User', align: 'left' }] : []),
          { id: 'orderSide', label: 'Side', align: 'center' },
          { id: 'price', label: 'Price', align: 'left' },
          { id: 'takeAmount', label: 'Amount', align: 'left' },
          { id: 'receive', label: 'Receive', align: 'left' },
        ];

  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
    defaultRowsPerPage: 200,
  });

  const [allData, setAllData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });

  const getData = async () => {
    try {
      const filters = {
        side: sideFilter,
        state: stateFilter,
        amount: amountFilter[0],
        amount2: amountFilter[1],
        endTime: timeFilter.endTime?.valueOf?.(),
        startTime: timeFilter.startTime?.valueOf?.(),
        ...(transactionIdFilter && { transactionId: transactionIdFilter }),
        ...(symbolFilter && { symbol: symbolFilter }),
        ...(isAdmin && {
          ...(firstNameFilter && { firstName: firstNameFilter }),
          ...(lastNameFilter && { lastName: lastNameFilter }),
        }),
      };

      if (isAdmin) {
        const response = await AdminApi.getReportP2PHistory(page, rowsPerPage, filters);
        if (response.status === 'SUCCESS') {
          setAllData(response);
        } else {
          setAllData([]);
        }
      } else {
        const response = await Api.getP2POrders(page, rowsPerPage, filters);
        if (response.status === 'SUCCESS') {
          setAllData(response);
        } else {
          setAllData([]);
        }
      }
    } catch (e) {
      setAllData([]);
    }
  };

  // ----------------------------------------------
  useEffect(() => {
    getData();
  }, [
    page,
    rowsPerPage,
    sideFilter,
    stateFilter,
    timeFilter,
    transactionIdFilter,
    symbolFilter,
    amountFilter,
    firstNameFilter,
    lastNameFilter,
  ]);

  useEffect(() => {
    if (allData) {
      if (isAdmin) {
        setTableData(allData.p2pOrders);
        setTablePagination({ totalItems: allData.p2pHistoryInfosTotalPages * rowsPerPage });
      } else {
        if (sectionFilter === 'MAKE') {
          setTableData([...allData.makeOrders].reverse());
          setTablePagination({ totalItems: allData.makeOrders.length });
        } else {
          setTableData(
            [...allData.takeOrders].map((t) => ({ ...t, orderSide: t.orderSide === 'BUY' ? 'SELL' : 'BUY' })).reverse()
          );
          setTablePagination({ totalItems: allData.takeOrders.length });
        }
      }
    }
  }, [sectionFilter, allData]);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);

  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    const filters = {
      side: sideFilter,
      state: stateFilter,
      amount: amountFilter[0],
      amount2: amountFilter[1],
      endTime: timeFilter.endTime?.valueOf?.(),
      startTime: timeFilter.startTime?.valueOf?.(),
      ...(transactionIdFilter && { transactionId: transactionIdFilter }),
      ...(symbolFilter && { symbol: symbolFilter }),
      ...(isAdmin && {
        ...(firstNameFilter && { firstName: firstNameFilter }),
        ...(lastNameFilter && { lastName: lastNameFilter }),
      }),
    };

    try {
      let result;
      if (isAdmin) {
        const response = await AdminApi.getReportP2PHistory(0, 10000000, filters);
        result = response.p2pOrders;
      } else {
        const response = await Api.getP2POrders(0, 10000000, filters);

        if (sectionFilter === 'MAKE') {
          result = [...response.makeOrders].reverse();
        } else {
          result = [...response.takeOrders]
            .map((t) => ({ ...t, orderSide: t.orderSide === 'BUY' ? 'SELL' : 'BUY' }))
            .reverse();
        }
      }

      let heads = [];
      // ----------- MAKE ---------------
      if (sectionFilter === 'MAKE') {
        heads = [
          '#',
          'Time',
          ...(isAdmin ? [t('Name'), t('Email'), t('Mobile')] : []),
          'Side',
          'Price',
          'Amount',
          'Remainder',
          'Available',
          'Limits',
          'State',
        ];
      }
      // ----------- TAKE ---------------
      else {
        heads = [
          '#',
          'Time',
          ...(isAdmin ? [t('Name'), t('Email'), t('Mobile')] : []),
          'Side',
          'Price',
          'Amount',
          'Receive',
        ];
      }

      let list = [];
      // ----------- MAKE ---------------
      if (sectionFilter === 'MAKE') {
        list = result.map((item) => [
          moment(item.createdDate).locale(language).format('YYYY-MM-DD HH:mm'),
          ...(isAdmin ? [`${item.firstName} ${item.lastName}`, item.emailAddress || '-', item.mobile || '-'] : []),
          item.side,
          `${item.price} USD`,
          `${item.amount} ${item.symbol}`,
          `${Math.round((item.remainderAmount / item.amount) * 100)} %`,
          `${item.remainderAmount} ${item.symbol}`,
          `${item.minLimit * item.price} ${item.maxLimit ? `- ${item.maxLimit * item.price}` : ''} USD`,
          item.state,
        ]);
      }
      // ----------- TAKE ---------------
      else {
        list = result.map((item) => [
          moment(item.takeTimestamp).locale(language).format('YYYY-MM-DD HH:mm'),
          ...(isAdmin ? [`${item.firstName} ${item.lastName}`, item.emailAddress || '-', item.mobile || '-'] : []),
          item.orderSide,
          `${item.takeAmount} ${item.symbol}`,
          `${item.price} USD`,
          `${item.orderSide === 'SELL' ? item.price * item.takeAmount : item.takeAmount} ${
            item.orderSide === 'SELL' ? 'USD' : item.symbol
          }`,
        ]);
      }

      const columnsWidth = [5, 15, ...(isAdmin ? [20, 30, 13] : []), 12, 12, 12, 12, 12, 12];

      // generate excel and download
      const config = {
        language,
        fileName: `${t('P2P History')} - ${sectionFilter === 'MAKE' ? t('Make') : t('Take')}`,
        heads,
        columnsWidth,
        list,
      };

      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  // ----------------------------------------------
  return (
    <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 }, mx: { xs: 2, lg: 0 } }}>
      <PageTitle
        title={'History of your P2P orders'}
        subTitle={'You can enter the name and symbol of Coin, transaction ID, etc. in the search field'}
      />

      <Box sx={{ mt: 5 }}>
        <P2PHistoryTableToolbar
          sideFilter={sideFilter}
          setSideFilter={setSideFilter}
          stateFilter={stateFilter}
          setStateFilter={setStateFilter}
          sectionFilter={sectionFilter}
          setSectionFilter={setSectionFilter}
          timeFilter={timeFilter}
          setTimeFilter={setTimeFilter}
          transactionIdFilter={transactionIdFilter}
          setTransactionIdFilter={setTransactionIdFilter}
          symbolFilter={symbolFilter}
          setSymbolFilter={setSymbolFilter}
          amountFilter={amountFilter}
          setAmountFilter={setAmountFilter}
          firstNameFilter={firstNameFilter}
          setFirstNameFilter={setFirstNameFilter}
          lastNameFilter={lastNameFilter}
          setLastNameFilter={setLastNameFilter}
          isAdmin={isAdmin}
          isExportLoading={isExportLoading}
          onExport={onExport}
        />

        <Scrollbar>
          <TableContainer sx={{ position: 'relative' }}>
            <Table size={'small'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length || 0}
                onSort={onSort}
              />

              <TableBody>
                {tableData === null ? (
                  <TableLoading isLoading />
                ) : (
                  <>
                    {applySortFilter({
                      tableData,
                      comparator: getComparator(order, orderBy),
                    }).map((row, index) =>
                      sectionFilter === 'MAKE' ? (
                        <P2PHistoryTableMakeRow key={row.id || index} row={row} onReload={getData} isAdmin={isAdmin} />
                      ) : (
                        <P2PHistoryTableTakeRow key={index} row={row} isAdmin={isAdmin} />
                      )
                    )}

                    <TableNoData isNotFound={!tableData?.length} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        {tableData?.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            component='div'
            count={tablePagination.totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
          />
        )}
      </Box>
    </Card>
  );
}
