import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Api from '../../../../../http/serviceApi';

export default function useDeposit({ setOpenSuccessModal }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [symbolSelected, setSymbolSelected] = useState(null);
  const [networkSelected, setNetworkSelected] = useState(null);
  const [addressHash, setAddressHash] = useState(null);
  const [depositTag, setDepositTag] = useState(null);
  const [txId, setTxId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSelectSymbol = (symbol) => {
    setAddressHash(null);
    setDepositTag(null);
    setSymbolSelected(symbol);
    setNetworkSelected(null);
  };

  const onSelectNetwork = async (networkObj) => {
    setAddressHash(null);
    setDepositTag(null);
    setNetworkSelected(networkObj);

    await fetchSymbolDepositAddress(symbolSelected, networkObj.network);
  };

  const fetchSymbolDepositAddress = async (symbol, network) => {
    try {
      const data = await Api.getSymbolDepositAddress(symbol, network);
      if (data.status === 'SUCCESS') {
        setAddressHash(data.depositInfo?.address);
        setDepositTag(data.depositInfo?.tag);
      }
    } catch (e) {}
  };

  const onSubmit = async () => {
    if (!symbolSelected) {
      return enqueueSnackbar(t('Please select a coin!'), { variant: 'warning' });
    }
    if (!networkSelected) {
      return enqueueSnackbar(t('Please select a network!'), { variant: 'warning' });
    }
    if (!txId) {
      return enqueueSnackbar(t('Please enter deposit TXID!'), { variant: 'warning' });
    }

    setIsSubmitting(true);

    try {
      const data = await Api.requestDeposit(txId);
      if (data.status === 'SUCCESS') {
        setOpenSuccessModal(true);

        // reset form
        setSymbolSelected(null);
        setNetworkSelected(null);
        setTxId('');
        setAddressHash(null);
        setDepositTag(null);
      }
    } catch (e) {}

    setIsSubmitting(false);
  };

  return {
    networkSelected,
    symbolSelected,
    onSelectNetwork,
    addressHash,
    depositTag,
    onSelectSymbol,
    txId,
    setTxId,
    onSubmit,
    isSubmitting,
  };
}
