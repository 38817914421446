import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import Iconify from '../../../components/Iconify';
import Image from '../../../components/Image';

import USD from '../../../assets/logo/dolar.svg';
import IRR from '../../../assets/logo/IRR.svg';
import EUR from '../../../assets/logo/EUR.svg';
import { NumberField } from '../../../components/NumberField';
import SymbolInput from '../../../components/SymbolInput';
import OTCConvert from './otcConvert';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import {
  FIAT_TYPES_JUST_IRR_EUR_USD,
  NEW_FIAT_TYPES_WITHOUT_IRR,
} from '../../common/history/cryptoTransaction/cryptoTransactionTableToolbar';

export const fiatList = [
  { label: 'USD', icon: USD },
  { label: 'IRR', icon: IRR },
  { label: 'EUR', icon: EUR },
  // { label: 'GBP', icon: '' },
  // { label: 'AUD', icon: '' },
  // { label: 'TRY', icon: '' },
  // { label: 'BRL', icon: '' },
  // { label: 'NGN', icon: '' },
  // { label: 'PLN', icon: '' },
  // { label: 'RUB', icon: '' },
  // { label: 'UAH', icon: '' },
  // { label: 'ZAR', icon: '' },
];

export const fiatListWithoutIRR = [
  { label: 'USD', icon: USD },
  // { label: 'IRR', icon: IRR },
  { label: 'EUR', icon: EUR },
  // { label: 'GBP', icon: '' },
  // { label: 'AUD', icon: '' },
  // { label: 'TRY', icon: '' },
  // { label: 'BRL', icon: '' },
  // { label: 'NGN', icon: '' },
  // { label: 'PLN', icon: '' },
  // { label: 'RUB', icon: '' },
  // { label: 'UAH', icon: '' },
  // { label: 'ZAR', icon: '' },
];

export default function OTCTab({
  side = 'buy',
  spendSymbol,
  receiveSymbol,
  onChangeSpendSymbol,
  onChangeReceiveSymbol,
  spend = 0,
  receive = 0,
  onChangeSpend,
  onChangeReceive,
  calculated,
  userWallet,
  onNext = () => {},
  openCryptoModal = () => {},
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [balance, setBalance] = useState(0);

  const isIranianPaymentActive = useSelector((store) => store.isIranianPaymentActive);




  useEffect(() => {
    if (userWallet && spendSymbol) {
      // BUY
      if (side === 'buy') {
        if (spendSymbol === 'USD') setBalance(userWallet.dollar);
        else if (spendSymbol === 'IRR') setBalance(userWallet.rial);
        else setBalance(0);
      }
      // SELL
      else {
        const found = userWallet.walletInfos.find((w) => w.symbol === spendSymbol);
        setBalance(found?.available || 0);
      }
    }
  }, [userWallet, spendSymbol]);

  const handleNext = () => {
    if (+spend === 0) {
      return enqueueSnackbar(t('Please enter Spend amount!'), { variant: 'warning' });
    }

    onNext();
  };

  return (
    <>
      <OTCConvert side={side} spendSymbol={spendSymbol} receiveSymbol={receiveSymbol} calculated={calculated} />

      <Box sx={{ mt: 5.8 }}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant='body3' color='grey.400'>
            {t('Spend')}
          </Typography>

          {/* {side === 'buy' ? (
            <Typography variant='body4' color='grey.300'>
              {t('Transaction requirements')}
            </Typography>
          ) : ( */}
          <Stack direction='row'>
            <Typography variant='body4' color='grey.300'>
              {t('Balance')} : {balance} {spendSymbol}
            </Typography>
            {/* <Typography variant='body4' color='secondary' sx={{ ml: 0.5 }}>
                {t('max')}
              </Typography> */}
          </Stack>
          {/* )} */}
        </Stack>

        {side === 'buy' ? (
          <FiatInput
            amount={spend}
            value={spendSymbol}
            onChange={onChangeSpendSymbol}
            onChangeAmount={onChangeSpend}
            onMax={() => onChangeSpend(balance)}
          />
        ) : (
          <SymbolInput
            amount={spend}
            symbol={spendSymbol}
            openModal={openCryptoModal}
            onChangeAmount={onChangeSpend}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Button
                    size='small'
                    color='warning'
                    sx={{
                      typography: 'body1',
                      fontWeight: 900,
                      px: 1,
                      minWidth: 50,
                      height: 24,
                      borderRadius: 0.6,
                    }}
                    onClick={() => onChangeSpend(balance)}
                  >
                    {t('MAX')}
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Box>

      <Box sx={{ mt: 5.8 }}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant='body3' color='grey.400'>
            {t('Receive')}
          </Typography>
        </Stack>

        {side === 'sell' ? (
          <FiatInput
            amount={receive}
            value={receiveSymbol}
            onChange={onChangeReceiveSymbol}
            onChangeAmount={onChangeReceive}
          />
        ) : (
          <SymbolInput
            amount={receive}
            onChangeAmount={onChangeReceive}
            symbol={receiveSymbol}
            openModal={openCryptoModal}
          />
        )}
      </Box>

      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mt: 5 }}>
        <Box>
          <Typography variant='body2' color={'grey.200'}>
            {t('Recurring Buy')}
          </Typography>

          <Stack direction={'row'} spacing={0.5} sx={{ mt: 1 }}>
            <Typography variant='body3' color={'grey.300'}>
              {t('Recurring Buy')}
            </Typography>
            <Typography variant='body3' color={'secondary'}>
              USDT
            </Typography>
            <Typography variant='body3' color={'grey.300'}>
              for
            </Typography>
            <Typography variant='body3' color={'secondary'}>
              3 years.
            </Typography>
            <Typography variant='body3' color={'grey.300'}>
              got
            </Typography>
            <Typography variant='body3' color={'secondary'}>
              0.00%
            </Typography>
            <Typography variant='body3' color={'grey.300'}>
              Rol
            </Typography>
          </Stack>
        </Box>

        <IconButton onClick={() => {}} sx={{ mr: -1.5 }}>
          <Iconify icon={'eva:arrow-ios-forward-outline'} />
        </IconButton>
      </Stack>

      <Button variant='contained' size='large' fullWidth onClick={handleNext} sx={{ mt: 5 }}>
        {t('Continue')}
      </Button>
    </>
  );
}

const FiatInput = ({ amount, onChangeAmount, value, onChange, onMax = null, ...other }) => {
  const { t } = useTranslation();
  const isIranianPaymentActive = useSelector((store) => store.isIranianPaymentActive);

  return (
    <Stack direction={'row'} alignItems={'center'} sx={{ mt: 1.5 }}>
      <NumberField
        variant='standard'
        value={amount}
        onChange={(event) => onChangeAmount(event.target.value)}
        fullWidth
        placeholder={'0'}
        thousandSeparator
        sx={{
          '& input': { color: 'grey.0', height: '1.55em' },
          '& .Mui-disabled': {
            '& input': { WebkitTextFillColor: 'grey.0 !important' },
            '&:before': { borderBottomStyle: 'solid !important' },
          },
        }}
        InputProps={{
          endAdornment: onMax && (
            <InputAdornment position='end'>
              <Button
                size='small'
                color='warning'
                sx={{
                  typography: 'body1',
                  fontWeight: 900,
                  px: 1,
                  minWidth: 50,
                  height: 24,
                  borderRadius: 0.6,
                }}
                onClick={onMax}
              >
                {t('MAX')}
              </Button>
            </InputAdornment>
          ),
        }}
        {...other}
      />
      <Divider orientation='vertical' variant='middle' flexItem />
      <TextField
        fullWidth
        select
        variant='standard'
        label=''
        value={value}
        onChange={(event) => onChange(event.target.value)}
        SelectProps={{
          sx: { pl: 1 },
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' } },
          },
        }}
        sx={{
          width: 140,
        }}
      >
        {fiatList
          .filter((f) => (isIranianPaymentActive ? true : f.label !== 'IRR'))
          .map((item, index) => (
            <MenuItem
              key={index}
              value={item.label}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
              }}
            >
              <Stack direction={'row'} spacing={1.2} alignItems={'center'}>
                <Image src={item.icon} sx={{ width: 20 }} />

                <Typography variant='subtitle2_secondary'>{item.label}</Typography>
              </Stack>
            </MenuItem>
          ))}
      </TextField>
    </Stack>
  );
};
