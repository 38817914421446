import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Api from '../../../../../http/serviceApi';
import { toNumber } from '../../../../../utils/formatNumber';

export default function useWithdraw({ setOpenSecurityVerificationModal, isExternal = true, userWallet }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [symbolSelected, setSymbolSelected] = useState(null);
  const [networkSelected, setNetworkSelected] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [symbolSelectedWallet, setSymbolSelectedWallet] = useState(null);
  const [amount, setAmount] = useState('');
  const [recipientType, setRecipientType] = useState('email');
  const [fee , setFee ] = useState(null)
  const [externalValues, setExternalValues] = useState({
    address: '',
    addressTag: '',
  });
  const [internalValues, setInternalValues] = useState({
    recipientId: '',
    recipientEmailAddress: '',
    recipientPhoneNumber: '',
  });

  const onSelectSymbol = async (symbol) => {
    setNetworkSelected(null);
    setSymbolSelectedWallet(null);
    setAmount('');
    setSymbolSelected(symbol);

      const getFee = await Api.getCurrencyFeeFOrWithdraw(symbol)
      setFee(getFee)

  };

  const onSelectNetwork = async (networkObj) => {
    setNetworkSelected(networkObj);
  };

  useEffect(() => {
    if (symbolSelected) {
      const found = userWallet.walletInfos.find((w) => w.symbol === symbolSelected);
      setSymbolSelectedWallet(found);
    }
  }, [userWallet, symbolSelected]);

  const onChangeExternalValues = (field, value) => {
    setExternalValues((prev) => ({ ...prev, [field]: value }));
  };

  const onChangeInternalValues = (field, value) => {
    setInternalValues((prev) => ({ ...prev, [field]: value }));
  };

  /**
   * ***************************************************
   * Pre Withdraw
   */
  const onSubmit = async () => {
    const requestAmount = toNumber(amount);

    if (!symbolSelected) {
      return enqueueSnackbar(t('Please select a coin!'), { variant: 'warning' });
    }
    if (!amount) {
      return enqueueSnackbar(t('Please enter the amount!'), { variant: 'warning' });
    }
    if (requestAmount > symbolSelectedWallet?.available || 0) {
      return enqueueSnackbar(t(`You don't have enough {{symbol}} in your wallet`, { symbol: symbolSelected || '' }), {
        variant: 'warning',
      });
    }
    if (isExternal) {
      if (!networkSelected) {
        return enqueueSnackbar(t('Please select a network!'), { variant: 'warning' });
      }
      if (requestAmount < networkSelected?.withdrawMin || 0) {
        return enqueueSnackbar(
          t('Minimum withdrawal is {{amount}} {{symbol}}', {
            amount: networkSelected?.withdrawMin || 0,
            symbol: symbolSelected || '',
          }),
          {
            variant: 'warning',
          }
        );
      }
      if (!externalValues.address) {
        return enqueueSnackbar(t('Please enter the address!'), { variant: 'warning' });
      }
      if (!new RegExp(networkSelected?.addressRegex).test(externalValues.address)) {
        return enqueueSnackbar(t('Please enter the correct address'), { variant: 'warning' });
      }
      if (networkSelected?.requireMemo && !new RegExp(networkSelected?.memoRegex).test(externalValues.addressTag)) {
        return enqueueSnackbar(t('Please enter the correct memo'), { variant: 'warning' });
      }
    } else {
      // TODO: if (requestAmount < internalWithdrawMin || 0) {
      if (requestAmount < 0) {
        return enqueueSnackbar(
          t('Minimum withdrawal is {{amount}} {{symbol}}', {
            amount: networkSelected?.withdrawMin || 0,
            symbol: symbolSelected || '',
          }),
          {
            variant: 'warning',
          }
        );
      }
      if (recipientType === 'email' && !internalValues.recipientEmailAddress) {
        return enqueueSnackbar(t('Please enter the recipient’s email!'), { variant: 'warning' });
      }
      if (recipientType === 'phone' && !internalValues.recipientPhoneNumber) {
        return enqueueSnackbar(t('Please enter the recipient’s phone number!'), { variant: 'warning' });
      }
      if (recipientType === 'id' && !internalValues.recipientId) {
        return enqueueSnackbar(t('Please enter the recipient’s ID!'), { variant: 'warning' });
      }
    }

    setIsSubmitting(true);

    try {
      const data = await Api.preWithdraw({
        ...(isExternal
          ? externalValues
          : {
              recipientId: recipientType === 'id' ? internalValues.recipientId : null,
              recipientEmailAddress: recipientType === 'email' ? internalValues.recipientEmailAddress : null,
              recipientPhoneNumber: recipientType === 'phone' ? internalValues.recipientPhoneNumber : null,
            }),

        amount: requestAmount,
        network: networkSelected?.network,
        symbol: symbolSelected,
        type: isExternal ? 'EXTERNAL' : 'INTERNAL',
      });

      if (data.status === 'SUCCESS') {

          const getFee = await Api.getCurrencyFeeFOrWithdraw(symbolSelected)
          setFee(getFee)

        setOpenSecurityVerificationModal(true);
      }
    } catch (e) {}

    setIsSubmitting(false);
  };

  /**
   * ***************************************************
   * Reset form
   */
  const resetForm = () => {
    setSymbolSelected(null);
    setNetworkSelected(null);
    setAmount('');
    setSymbolSelectedWallet(null);

    setExternalValues({
      address: '',
      addressTag: '',
    });
    setInternalValues({
      recipientId: '',
      recipientEmailAddress: '',
      recipientPhoneNumber: '',
    });
  };

  return {
    networkSelected,
    symbolSelected,
    onSelectNetwork,
    onSelectSymbol,
    onSubmit,
    isSubmitting,
    amount,
    setAmount,
    symbolSelectedWallet,
    resetForm,
    externalValues,
    onChangeExternalValues,
    internalValues,
    onChangeInternalValues,
    recipientType,
    setRecipientType,
    fee ,
  };
}
