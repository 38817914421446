import React, { useState } from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';

import RowAddFavorite from './rowAddFavorite';
import ModalContainer from '../../../../components/ModalContainer';
import Api from '../../../../http/serviceApi';
import { useEffect } from 'react';
import ModalFilter from '../../../../components/ModalFilter';

const itemsPerPage = 15;

const AddFavoriteModal = ({ open, onClose, onSelectionChange, favoriteList }) => {
  const [filtered, setFiltered] = useState([]);

  // fetching data from server
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAllCurrencies = async () => {
    setLoading(true);
    try {
      const response = await Api.getAllCurrencies();
      if (response?.status === 'SUCCESS') {
        setDataSource(response.currencies);
        setFiltered(response.currencies);
      }
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    fetchAllCurrencies();
  }, []);

  // lazy load list
  const [hasMore, setHasMore] = useState(true);
  const [countPerPage, setCountPerPage] = useState(itemsPerPage);
  const loadMore = () => {
    if (countPerPage >= filtered.length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setCountPerPage((prev) => prev + itemsPerPage);
      }, 500);
    }
  };

  const onSearch = (newList) => {
    setFiltered(newList);
    setCountPerPage(itemsPerPage);
    if (itemsPerPage < newList.length) {
      setHasMore(true);
    }
  };

  useEffect(() => {
    if (!open) {
      setFiltered(dataSource);
    }
  }, [open]);

  return (
    <ModalContainer open={open} onClose={onClose} title={'Add New favorite'} width={400}>
      <ModalFilter dataSource={dataSource} setFiltered={onSearch} />

      {loading ? (
        <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: 340 }}>
          <CircularProgress size={30} color='success' />
        </Stack>
      ) : (
        <Box
          sx={{
            height: 340,
            overflow: 'auto',
            '&::-webkit-scrollbar': { width: 18 },
            '&::-webkit-scrollbar-track': { backgroundColor: 'transparent' },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#53555f',
              borderRadius: '18px',
              border: '6px solid transparent',
              backgroundClip: 'content-box',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#6c6e75',
            },
          }}
        >
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={
              <Stack key='loader' alignItems={'center'} justifyContent={'center'} sx={{ my: 2 }}>
                <CircularProgress size={20} color='success' />
              </Stack>
            }
            useWindow={false}
          >
            {filtered.slice(0, countPerPage).map((item) => (
              <RowAddFavorite
                key={item.symbol}
                item={item}
                onChange={onSelectionChange}
                selected={favoriteList.find((f) => f.symbol === item.symbol)}
              />
            ))}
          </InfiniteScroll>
        </Box>
      )}
    </ModalContainer>
  );
};

export default AddFavoriteModal;
