import { TableRow, TableCell, Typography, IconButton } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { fFloat } from '../../../../utils/formatNumber';
import Iconify from '../../../../components/Iconify';
import Api from '../../../../http/serviceApi';

// ----------------------------------------------------------------------

export default function FuturesOpenTradeTableRow({ row, onReload }) {
  const language = useSelector((store) => store.language);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    amount,
    commission,
    commissionAsset,
    createdDate,
    executed,
    isMaker,
    orderId,
    price,
    side,
    state,
    stopPrice,
    symbol,
    total,
    transactionDate,
    type,
    updateTime,
  } = row;

  // ---------------
  const onDelete = async () => {
    try {
      const response = await Api.deleteFuturesOrder(orderId);
      if (response.status === 'SUCCESS') {
        enqueueSnackbar(t('Deleted successfully'), { variant: 'success' });
        onReload();
      }
    } catch (e) {}
  };

  return (
    <TableRow hover sx={{ '& td': { py: 2, typography: 'body2', color: 'grey.100' } }}>
      <TableCell>{moment(createdDate).locale(language).format('YYYY-MM-DD HH:mm:ss')}</TableCell>

      <TableCell align='center'>{symbol}</TableCell>

      <TableCell align='center'>{type}</TableCell>

      <TableCell align='center'>
        <Typography variant='body3' color={side === 'BUY' ? 'positive.main' : 'negative.main'}>
          {side}
        </Typography>
      </TableCell>

      <TableCell align='center'>{fFloat(price)}</TableCell>

      <TableCell align='center'>{fFloat(amount)}</TableCell>

      <TableCell align='center'>{fFloat(executed)}</TableCell>

      <TableCell>
        <IconButton onClick={onDelete}>
          <Iconify icon='eva:trash-2-outline' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
