import { useTranslation } from 'react-i18next';
import { Button, MenuItem, Stack, TextField } from '@mui/material';
import moment from 'jalali-moment';
import DatePicker from '../../../../components/DatePicker';
import ComplexToolbar from '../../../../components/ComplexToolbar';
import { FIAT_STATUS } from '../../../common/history/fiatTransaction/fiatTransactionTableToolbar';

export default function WalletFiatTransactionToolbar({
  dateFromFilter,
  setDateFromFilter,
  dateToFilter,
  setDateToFilter,
  fiatStatusFilter,
  setFiatStatusFilter,

  onFilter,
  onExport,
  isExportLoading,
}) {
  const { t } = useTranslation();

  const onReset = async () => {
    setDateFromFilter(moment(dateFromFilter).startOf('days').valueOf());
    setDateToFilter(moment(dateToFilter).endOf('days').valueOf());
    setFiatStatusFilter('SUCCESS');
    onFilter(true);
  };

  return (
    <ComplexToolbar onExport={onExport} onPrint={() => console.log('print')} isExportLoading={isExportLoading}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} sx={{ mt: 2 }} alignItems={'center'}>
          <TextField
            select
            label={t('Status')}
            variant='standard'
            fullWidth
            value={fiatStatusFilter}
            onChange={(e) => setFiatStatusFilter(e.target.value)}
            sx={{ maxWidth: 300 }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            {FIAT_STATUS.map((item) => (
              <MenuItem key={item.value} value={item.value} sx={{ typography: 'body2' }}>
                {t(item.label)}
              </MenuItem>
            ))}
          </TextField>

          <Stack direction={'row'}>
            <DatePicker
              label={t('From')}
              value={dateFromFilter}
              setValue={setDateFromFilter}
              variant='standard'
              maxDate={dateToFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
              InputProps={null}
            />
            <DatePicker
              label={t('To')}
              value={dateToFilter}
              setValue={setDateToFilter}
              variant='standard'
              minDate={dateFromFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
            />
          </Stack>
        </Stack>

        {/* <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'center'} spacing={5}>
          <TextField
            label={t('TxID')}
            variant='standard'
            placeholder={'Enter TxID'}
            fullWidth
            sx={{ maxWidth: 300 }}
            value={transactionIdFilter}
            onChange={(e) => setTransactionIdFilter(e.target.value)}
          />

          <TextField
            label={t('Currency')}
            variant='standard'
            fullWidth
            sx={{ maxWidth: 300 }}
            value={symbolFilter}
            onChange={(e) => setSymbolFilter(e.target.value)}
          />
        </Stack> */}
      </Stack>

      <Stack direction='row' spacing={2} sx={{ mt: 5, mb: 5.75 }}>
        <Button
          onClick={() => onFilter()}
          fullWidth
          variant='contained'
          sx={{ width: 200, typography: 'body3', fontWeight: 500 }}
        >
          {t('Filter')}
        </Button>
        <Button
          onClick={onReset}
          fullWidth
          variant='outlined'
          color='inherit'
          sx={{ width: 80, typography: 'body3', fontWeight: 500, borderColor: 'grey.400' }}
        >
          {t('Reset')}
        </Button>
      </Stack>
    </ComplexToolbar>
  );
}
