import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import UserApi from '../../../../http/serviceApi';
import AdminApi from '../../../../http/adminHttp/serviceApiAdmin';
import OptInput from '../../../../components/OptInput';
import useTimer from '../../../../hooks/useTimer';

const OtpEmailForm = ({ emails, emailAuthEnabled, onFinished, isAdmin = true }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newEmailOtp, setNewEmailOtp] = useState('');
  const [emailOtp, setEmailOtp] = useState('');

  const { onRequestAgainClick, timer, stopTimer, activeTimerState } = useTimer({
    emailAddress: emails?.oldEmailAddress,
    operationType: 'ChangeEmail',
  });

  const onSubmit = async () => {
    if (!newEmailOtp) {
      return enqueueSnackbar(t('Enter the new email verification code!'), { variant: 'error' });
    }
    if (!emailOtp && emailAuthEnabled) {
      return enqueueSnackbar(t('Enter the email verification code!'), { variant: 'error' });
    }

    setIsSubmitting(true);
    try {
      let response;
      if (isAdmin) {
        response = await AdminApi.changeEmail(newEmailOtp, emailOtp);
      } else {
        response = await UserApi.changeEmail(newEmailOtp, emailOtp);
      }

      if (response?.status === 'SUCCESS') {
        stopTimer();
        onFinished(response);
      }
    } catch (e) {}

    setIsSubmitting(false);
  };

  return (
    <>
      <Typography variant='body3_secondary' color={'grey.100'} component='p' sx={{ mb: 5 }}>
        {t('Enter the code that was sent to your previous mobile number and email in the box below')}
      </Typography>

      <OptInput
        code={newEmailOtp}
        setCode={setNewEmailOtp}
        label={t('New Email verification code')}
        description={t('Enter the {{number}}-digit code send to {{email}}', {
          email: emails?.newEmailAddress || '',
          number: 5,
        })}
      />

      {emailAuthEnabled && (
        <OptInput
          code={emailOtp}
          setCode={setEmailOtp}
          label={t('Email verification code')}
          description={t('Enter the {{number}}-digit code send to {{email}}', {
            email: emails?.oldEmailAddress || '',
            number: 5,
          })}
        />
      )}

      <Stack sx={{ mt: 5 }}>
        <Typography variant='body3' sx={{ letterSpacing: 1 }}>
          {timer}
        </Typography>
        <Stack direction='row' alignItems={'center'}>
          <Typography variant='body3_secondary' color='grey.400'>
            {t('Didn’t recieve code?')}
          </Typography>
          <Button
            color='warning'
            sx={{ typography: 'body3_secondary', borderRadius: 1, px: 1 }}
            onClick={onRequestAgainClick}
            size='small'
            disabled={!activeTimerState}
          >
            {t('Request Again')}
          </Button>
        </Stack>
      </Stack>

      <Box sx={{ width: 320 }}>
        <LoadingButton
          fullWidth
          size='large'
          type='button'
          variant='contained'
          loading={isSubmitting}
          onClick={() => onSubmit(null)}
          sx={{ mt: 2.5, typography: 'body3' }}
        >
          {t('Change Email')}
        </LoadingButton>
      </Box>
    </>
  );
};

export default OtpEmailForm;
