import { TableRow, TableCell, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import confirmWrapper from '../../../utils/confirm';
import Api from '../../../http/adminHttp/serviceApiAdmin';
import Iconify from '../../../components/Iconify';
import { prettyUppercases } from '../../../utils';

export default function AdminNotificationTableRow({ row, index, onReload, tab }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { id, title, message, types, recipient, color, activeDays } = row;

  // ----------------------------------------------
  const onDelete = async () => {
    if (!(await confirmWrapper(t('Are you sure to remove this notification?')))) {
      return false;
    }

    try {
      const response = await Api.deleteNotification(id);
      if (response?.status === 'SUCCESS') {
        onReload();
        enqueueSnackbar(t('The notification is deleted successfuly'), { variant: 'success' });
      }
    } catch (e) {}
    return true;
  };

  // ----------------------------------------------
  return (
    <TableRow hover sx={{ '& td': { py: 1.5, typography: 'body2' } }}>
      <TableCell align='center' sx={{ width: 80 }}>
        {index + 1}
      </TableCell>

      <TableCell>{title}</TableCell>

      <TableCell>{message}</TableCell>

      <TableCell>
        {types.map(prettyUppercases).map((type) => (
          <Typography component='p' variant='body4'>
            {type}
          </Typography>
        ))}
      </TableCell>

      <TableCell align='center'>{prettyUppercases(recipient)}</TableCell>

      <TableCell align='center'>{prettyUppercases(color)}</TableCell>

      {tab === 'headers' && (
        <>
          <TableCell align='center'>{activeDays || '-'}</TableCell>

          <TableCell align='center' sx={{ width: 100 }}>
            <IconButton onClick={onDelete}>
              <Iconify icon='eva:trash-2-outline' />
            </IconButton>
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
