import { Backdrop, Box, Fade, IconButton, Modal, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Iconify from './Iconify';

const ModalContainer = ({ open, onClose, children, title, width = 400, sx = {}, hasCloseButton = true }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: { sm: '50%' },
            left: { sm: '50%' },
            transform: { sm: 'translate(-50%, -50%)' },
            width: { xs: '100%', sm: width },
            height: { xs: '100%', sm: 'auto' },
            overflow: { xs: 'scroll', sm: 'auto' },
            bgcolor: 'background.modal',
            boxShadow: { sm: 24 },
            p: { xs: 3, sm: 2.5 },
            borderRadius: { sm: 1.5 },
            ...sx,
          }}
        >
          <>
            <Stack direction='row-reverse' alignItems={'center'} justifyContent={'space-between'}>
              {hasCloseButton && (
                <IconButton onClick={onClose}>
                  <Iconify icon='akar-icons:cross' color='grey.0' sx={{ width: 10, height: 10 }} />
                </IconButton>
              )}
              {title && <Typography variant='body1'>{t(title)}</Typography>}
            </Stack>

            <Stack direction='column' sx={{ overflowY: 'auto', maxHeight: '80vh' }}>
              {children}
            </Stack>
          </>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalContainer;
