import { compose, createStore } from 'redux';

import { reducer } from './reducer';

const devtools =
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  process.env.NODE_ENV === 'development' &&
  window.__REDUX_DEVTOOLS_EXTENSION__();

const middlewares = compose(devtools || ((a) => a));

const store = createStore(reducer, middlewares);

export default store;
