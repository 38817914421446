import React, { useRef, useState } from 'react';
import { IconButton, InputBase, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Iconify from './Iconify';
import { debounced } from '../utils';

export default function ModalFilter({ dataSource, setFiltered }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [searchValue, setSearchValue] = useState('');
  const onSearch = useRef(
    debounced((q) => {
      onFilter(q);
    }, 500)
  ).current;

  const onFilter = (q) => {

    if (q === '') {
      setFiltered(dataSource || []);
    } else {
      setFiltered((dataSource || []).filter((d) => d.symbol.indexOf(q) !== -1 || d.name.indexOf(q) !== -1));
    }
  };

  return (
    <Stack
      direction='row'
      sx={{ backgroundColor: 'grey.600', borderRadius: 1.5, height: 36, my: 3, px: 0.7 }}
      spacing={0.5}
    >
      <IconButton type='button' aria-label='search'>
        <Iconify icon={'ep:search'} width={25} height={25} color='grey.100' />
      </IconButton>

      <InputBase
        sx={{
          flex: 1,
          '& input': { ...theme.typography.body4, color: 'grey.0', '&::placeholder': { color: 'grey.0' } },
        }}
        placeholder={t('Search')}
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e) => {
          const value = e.target.value.toUpperCase();
          setSearchValue(value);
          onSearch(value);
        }}
        value={searchValue}
      />
    </Stack>
  );
}
