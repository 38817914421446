import { TableRow, TableCell, IconButton, Typography, Box } from '@mui/material';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Iconify from '../../../components/Iconify';
import { prettyUppercases } from '../../../utils';
import { navigationUrl } from '../../../navigationUrl';

export default function ReferralMarketingCommissionRow({ row, index, onView }) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const navigate = useNavigate();
  const { refereeId, referrerId, orderAmount, createDate, referrerIncome,amount, condition, authenticationLevel, type } = row;

  return (
    <TableRow
      hover
      sx={{ cursor: 'pointer', '& td': { py: 1.5, typography: 'body2' } }}
      onClick={() => navigate(navigationUrl.admin_edit_user(refereeId))}
    >
      <TableCell align='center' sx={{ width: 80 }}>
        {index + 1}
      </TableCell>
      <TableCell>{moment(createDate).locale(language).format('D MMM YYYY - HH:mm')}</TableCell>

      <TableCell align='center'>
        {refereeId}
      </TableCell>


      <TableCell align='center'>
        {referrerId}
      </TableCell>

      <TableCell align='center'>
        {orderAmount}
      </TableCell>

      <TableCell align='center'>
        {referrerIncome}
      </TableCell>

      <TableCell align='center'>
        {type}
      </TableCell>

      {/*<TableCell>{mobileNumber || '-'}</TableCell>*/}


      {/*<TableCell align='center'>{amount}</TableCell>*/}

      {/*<TableCell align='center'>*/}
      {/*  <Box*/}
      {/*    sx={{*/}
      {/*      maxWidth: 130,*/}
      {/*      borderRadius: '22px',*/}
      {/*      textAlign: 'center',*/}
      {/*      m: 'auto',*/}
      {/*      py: 0.8,*/}
      {/*      px: 2,*/}
      {/*      ...(condition === 'ACTIVE'*/}
      {/*        ? { bgcolor: 'rgba(48, 224, 161, 0.2)' }*/}
      {/*        : condition === 'DISAPPROVAL'*/}
      {/*        ? { bgcolor: 'rgba(250, 34, 86, 0.2)' }*/}
      {/*        : condition === 'WAITING'*/}
      {/*        ? { bgcolor: 'rgba(242, 127, 22, 0.2)' }*/}
      {/*        : ''),*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Typography*/}
      {/*      variant='body4'*/}
      {/*      sx={{*/}
      {/*        lineHeight: 1,*/}
      {/*        py: 0.4,*/}
      {/*        ...(condition === 'ACTIVE'*/}
      {/*          ? { color: '#30E0A1' }*/}
      {/*          : condition === 'DISAPPROVAL'*/}
      {/*          ? { color: 'error.darker' }*/}
      {/*          : condition === 'WAITING'*/}
      {/*          ? { color: 'warning.main' }*/}
      {/*          : ''),*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {t(condition)}*/}
      {/*    </Typography>*/}
      {/*  </Box>*/}
      {/*</TableCell>*/}

      {/*<TableCell align='center'>{t(prettyUppercases(authenticationLevel))}</TableCell>*/}

      {/*<TableCell align='center' sx={{ width: 100 }}>*/}
      {/*  <IconButton onClick={onView}>*/}
      {/*    <Iconify icon='carbon:view' sx={{ width: 24, height: 24 }} />*/}
      {/*  </IconButton>*/}
      {/*</TableCell>*/}
    </TableRow>
  );
}
