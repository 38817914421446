import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { SET_PAGE_STATE } from '../../../store/actionTypes';
import Image from '../../../components/Image';

import convertBack from '../../../assets/convertBack.png';
import close from '../../../assets/close.svg';
import info from '../../../assets/info.svg';
import pair from '../../../assets/pair.svg';

import ConvertForm from './convertForm';
import ConvertResult from './convertResult';
import { Link } from 'react-router-dom';
import { navigationUrl } from '../../../navigationUrl';

export default function Convert() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Convert' },
    });
  }, []);

  const [showResult, setShowResult] = useState(null);

  return (
    <Box sx={{ mx: { xs: 2, lg: 0 } }}>
      <Stack
        alignItems={{ xs: 'flex-end', md: 'center' }}
        justifyContent={'center'}
        spacing={2}
        sx={{
          backgroundImage: `url(${convertBack})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPositionY: 'center',
          backgroundPositionX: { xs: -80, md: 0 },
          height: 168,
          borderRadius: 1.5,
          position: 'relative',
          pr: { xs: 3, md: 0 },
          '&:before': {
            content: "''",
            position: 'absolute',
            left: 0,
            top: 0,
            // backgroundColor: 'rgb(20 21 24 / 60%)',
            backgroundColor: 'black.400_6',
            width: '100%',
            height: '100%',
            borderRadius: 1.5,
            display: { xs: 'block', md: 'none' },
          },
        }}
      >
        <Typography variant='h6' color='#fff' fontWeight={500} sx={{ zIndex: 1 }}>
          {t('cryptizi convert')}
        </Typography>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 6.5 }}>
          <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ zIndex: 1 }}>
            <Image src={close} sx={{ width: 16, height: 16 }} />
            <Typography variant='body4' color='#fff'>
              {t('Zero fee')}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ zIndex: 1 }}>
            <Image src={info} sx={{ width: 16, height: 16 }} />
            <Typography variant='body4' color='#fff'>
              {t('Guaranteed price')}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ zIndex: 1 }}>
            <Image src={pair} sx={{ width: 16, height: 16 }} />
            <Typography variant='body4' color='#fff'>
              {t('Any pairs')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Card sx={{ position: 'relative' }}>
        <Button
          variant='outlined'
          color='inherit'
          component={Link}
          to={navigationUrl.convert_history}
          sx={{
            position: 'absolute',
            right: { xs: 10, lg: 40 },
            top: { xs: 10, lg: 40 },
            zIndex: 1,
            borderColor: 'grey.400',
            typography: 'body3',
            height: 40,
            width: 124,
            '&:hover': { color: '#eee' },
          }}
        >
          {t('Orders')}
        </Button>

        <Box sx={{ width: { xs: '100%', md: 627 }, m: 'auto', mt: { xs: 5, lg: 0 } }}>
          {!!showResult ? (
            <ConvertResult onBack={() => setShowResult(null)} showResult={showResult} />
          ) : (
            <ConvertForm onSuccess={setShowResult} />
          )}
        </Box>
      </Card>
    </Box>
  );
}
