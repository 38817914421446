import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import moment from 'jalali-moment';

import Api from '../../../../../http/adminHttp/serviceApiAdmin';
import { navigationUrl } from '../../../../../navigationUrl';
import { imageToBase64 } from '../../../../../utils';
import { useTranslation } from 'react-i18next';

export default function useAddUserSubmit(cardsAmount, userImg, reset) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const onSubmit = async (payload) => {
    let totalCardAmount = cardsAmount.filter((each) => each !== undefined);

    let cards = [];
    for (let i = 0; i < totalCardAmount.length; i++) {
      let object = {};
      if (
        [
          payload[`cardNumber_${totalCardAmount[i]}`],
          payload[`shaba_${totalCardAmount[i]}`],
          payload[`accountNumber_${totalCardAmount[i]}`],
          // payload[`bank_${totalCardAmount[i]}`],
        ].every((element) => element === undefined)
      ) {
        //Do nothing
      } else {
        object[`cardNumber`] = payload[`cardNumber_${totalCardAmount[i]}`]
          ? payload[`cardNumber_${totalCardAmount[i]}`]
          : '';
        object[`shebaNum`] = payload[`shaba_${totalCardAmount[i]}`] ? payload[`shaba_${totalCardAmount[i]}`] : '';
        object[`accountNumber`] = payload[`accountNumber_${totalCardAmount[i]}`]
          ? payload[`accountNumber_${totalCardAmount[i]}`]
          : '';
        // object[`bank`] = payload[`bank_${totalCardAmount[i]}`] ? payload[`bank_${totalCardAmount[i]}`] : "";

        cards.push(object);
      }
    }

    let attachFile = null;
    if (userImg) {
      attachFile = await imageToBase64(userImg);
    }

    let finalPayload = {
      birthday: moment(payload.birthDay.$d).valueOf(),
      emailAddress: payload.emailAddress || '',
      firstName: payload.firstName,
      lastName: payload.lastName,
      mobile: payload.mobile,
      phoneNumber: payload.phoneNumber || '',
      userBankCardRequestList: cards,
      userImage: attachFile,
      password:payload.password,
      verification: payload.verification.toUpperCase(),
    };

    try {
      const data = await Api.addUserByAdmin(finalPayload);

      if (data?.status === 'SUCCESS') {
        enqueueSnackbar(t('The new user was successfully registered.'), { variant: 'success' });
        reset();
        navigate(navigationUrl.admin_user);
      }
    } catch (e) {}
  };

  return {
    onSubmit,
  };
}
