import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { SET_PAGE_STATE } from '../../../store/actionTypes';
import { Box, Button, Card, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
import Statistic from '../../admin/mainPage/statistic';
import FutureStatistic from './FutureStatistic';
import FutureWalletBalanceAndCharts from './futureWalletBalanceAndCharts';
import FutureWalletTables from './futureWalletTables';
import useWallet from '../../../hooks/useWallet';
import LoadingScreen from '../../../components/LoadingScreen';
import useFutureWallet from './useFutureWallet';
import FutureTransferModal from './FutureTransferModal';

const FuturesWallet = () => {

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const [firstTime , setFirstTime] = useState(true)
  const [valueVisible, setValueVisible] = useState(false);
  const [transferModal ,setTransferModal] = useState(false)
  const { userWallet, loading , onReloadWallet } = useFutureWallet();


  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Futures Wallet' },
    });
  }, []);

  const refreshWallet = () =>{
    onReloadWallet()
  }
  if (loading && firstTime) {
    setFirstTime(false)
    return <LoadingScreen />;
  }
  return (
    <Box sx={{ px: { xs: 3, lg: 0 } }}>
      <Grid container spacing={2.5}>
        <FutureStatistic userWallet={userWallet}/>
      </Grid>
        <FutureWalletBalanceAndCharts userWallet={userWallet} setTransferModal={()=>setTransferModal(true)}/>
        <FutureWalletTables userWallet={userWallet} setShowModal={()=>setTransferModal(true)}/>

      <FutureTransferModal  open={transferModal} onClose={()=>setTransferModal(false)} refreshWallet={refreshWallet}/>
    </Box>

  );
};

export default FuturesWallet;