import React, { useState, useEffect } from 'react';
import { Box, Typography, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

// import { RHFTextField } from '../../../../components/hookForm';
import CustomSwitch from '../../../../components/CustomSwitch';
import Image from '../../../../components/Image';

const GateWayRow = ({ data, handleChangeInput, handleChangeStatus }) => {
  const { t } = useTranslation();
  const { name, token, status, symbol } = data;

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(status);
  }, [status]);

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='flex-end' spacing={2} sx={{ mb: 3.75 }}>
      <Stack direction='row' alignItems='center' sx={{ width: { xs: 85, md: 125 } }}>
        {symbol !== '' ? (
          <Image
            src={`${symbol}`}
            sx={{ mr: { xs: 1, md: 2.5 }, width: { xs: 30, md: 40 }, height: { xs: 30, md: 40 } }}
          />
        ) : (
          <Box sx={{ width: 48, height: 48, backgroundColor: 'info.darker', mr: { xs: 1, md: 2.5 } }} />
        )}

        <Typography variant='body2'>{name}</Typography>
      </Stack>

      <TextField
        label={t('Token')}
        name='token'
        variant='standard'
        defaultValue={token}
        onChange={(e) => handleChangeInput(e, data)}
        sx={{
          width: { xs: 100, md: 200 },
          '& label.MuiFormLabel-root': {
            color: 'grey.400',
          },
          '& .Mui-disabled': {
            '& input': { WebkitTextFillColor: 'grey.550' },
            '&:before': { borderBottomStyle: 'solid' },
          },
        }}
      />

      <Stack direction='row' alignItems={'center'} spacing={1} sx={{ width: { xs: 50, sm: 110 } }}>
        <CustomSwitch
          name='status'
          checked={isActive}
          onClick={() => {
            setIsActive(!isActive);
            handleChangeStatus(data);
          }}
        />
        <Typography variant='body3' sx={{ display: { xs: 'none', sm: 'inline-block' } }}>
          {isActive ? t('active') : t('disable')}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default GateWayRow;
