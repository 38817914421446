import { useEffect, useState } from 'react';
import { Box, Card, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'jalali-moment';

import useTable, { getComparator } from '../../../../hooks/useTable';
import { PageTitle } from '../../../../components/PageTitle';
import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../components/table';
import { applySortFilter } from '../../../../utils';
import PerfectMoneyHistoryTableRow from './perfectMoneyHistoryTableRow';
import Api from '../../../../http/serviceApi';
import AdminApi from '../../../../http/adminHttp/serviceApiAdmin';
import PerfectMoneyHistoryTableToolbar from './perfectMoneyHistoryTableToolbar';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { exportExcel } from '../../../../utils/excel';
import PerfectMoneyHistoryModal from './perfectMoneyHistoryModal';
import { getDefaultDateFilterValues } from '../../../../utils/filter';
import { exportPDF } from '../../../../utils/pdf';

// ----------------------------------------------------------------------

export default function PerfectMoneyHistoryTable({ isAdmin = false }) {
  const language = useSelector((store) => store.language);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Perfect Money History' },
    });
  }, []);

  const [timeFilter, setTimeFilter] = useState({
    startTime: getDefaultDateFilterValues().firstDay,
    endTime: getDefaultDateFilterValues().lastDay,
  });
  const [sideFilter, setSideFilter] = useState('ALL'); // ALL, BUY, SELL
  const [statusFilter, setStatusFilter] = useState('ALL'); // ALL, COMPLETED, FAILED, PENDING
  const [typeFilter, setTypeFilter] = useState('ALL'); // ALL, E_VOUCHER, NORMAL
  const [transactionIdFilter, setTransactionIdFilter] = useState('');
  const [fiatAmountFilter, setFiatAmountFilter] = useState([0, 100000]);
  const [firstNameFilter, setFirstNameFilter] = useState('');
  const [lastNameFilter, setLastNameFilter] = useState('');
  const [userIdFilter, setUserIdFilter] = useState('');

  const TABLE_HEAD = [
    { id: 'createDate', label: 'Time', align: 'left' },
    ...(isAdmin ? [{ id: 'user', label: 'User', align: 'left' }] : []),
    { id: 'currency', label: 'Currency', align: 'center' },
    { id: 'side', label: 'Side', align: 'center' },
    { id: 'fiatAmount', label: 'Fiat Amount', align: 'center' },
    { id: 'perfectMoneyPrice', label: 'Perfect Money Price', align: 'center' },
    { id: 'perfectMoneyAmount', label: 'Amount', align: 'center' },
    { id: 'voucherAmount', label: 'Type', align: 'center' },
    { id: 'voucherCurrency', label: 'Currency', align: 'center' },
    { id: 'detail', label: 'Detail', align: 'center' },
  ];

  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });
  const [showModalDetail, setShowModalDetail] = useState(null);

  const getData = async () => {
    try {
      const filters = {
        endTime: timeFilter.endTime?.valueOf?.(),
        startTime: timeFilter.startTime?.valueOf?.(),
        side: sideFilter,
        type: typeFilter,
        status: statusFilter,
        userId: userIdFilter,
        ...(transactionIdFilter && { transactionId: transactionIdFilter }),
        ...(isAdmin
          ? {
              fiatAmount: fiatAmountFilter[0],
              fiatAmount2: fiatAmountFilter[1],
              ...(firstNameFilter && { firstName: firstNameFilter }),
              ...(lastNameFilter && { lastName: lastNameFilter }),
            }
          : {
              amount: fiatAmountFilter[0],
              amount2: fiatAmountFilter[1],
            }),
      };

      if (isAdmin) {
        const response = await AdminApi.getReportPerfectMoneyHistory(page, rowsPerPage, filters);
        if (response.status === 'SUCCESS') {
          setTableData(response.perfectMoneyHistoryInfoList);
          setTablePagination({ totalItems: response.perfectMoneyHistoryInfosTotalPages * rowsPerPage });
        } else {
          setTableData([]);
        }
      } else {
        const response = await Api.getUserPerfectMoneyHistory(page, rowsPerPage, filters);
        if (response.status === 'SUCCESS') {
          setTableData(response.perfectMoneyHistoryInfoList);
          setTablePagination({ totalItems: response.perfectMoneyHistoryInfosTotalPages * rowsPerPage });
        } else {
          setTableData([]);
        }
      }
    } catch (e) {
      setTableData([]);
    }
  };

  useEffect(() => {
    getData();
  }, [
    page,
    rowsPerPage,
    sideFilter,
    timeFilter,
    statusFilter,
    typeFilter,
    transactionIdFilter,
    fiatAmountFilter,
    firstNameFilter,
    lastNameFilter,
    userIdFilter,
  ]);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);
  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    const filters = {
      endTime: timeFilter.endTime?.valueOf?.(),
      startTime: timeFilter.startTime?.valueOf?.(),
      side: sideFilter,
      type: typeFilter,
      status: statusFilter,
      ...(transactionIdFilter && { transactionId: transactionIdFilter }),
      ...(isAdmin
        ? {
            fiatAmount: fiatAmountFilter[0],
            fiatAmount2: fiatAmountFilter[1],
            ...(firstNameFilter && { firstName: firstNameFilter }),
            ...(lastNameFilter && { lastName: lastNameFilter }),
          }
        : {
            amount: fiatAmountFilter[0],
            amount2: fiatAmountFilter[1],
          }),
    };

    try {
      let response;
      if (isAdmin) {
        response = await AdminApi.getReportPerfectMoneyHistory(0, 10000000, filters);
      } else {
        response = await Api.getUserPerfectMoneyHistory(0, 10000000, filters);
      }

      const heads = [
        '#',
        t('Time'),
        ...(isAdmin ? [t('Name'), t('Email'), t('Mobile')] : []),
        t('Fiat Amount'),
        t('Perfect Money Price'),
        t('Perfect Money Amount'),
        t('Voucher Amount'),
        t('Voucher Currency'),
        t('Side'),
        t('Wallet ID'),
      ];

      const columnsWidth = [5, 17, ...(isAdmin ? [16, 29, 14] : []), 12, 19, 22, 16, 17, 7, 11];

      // generate excel and download
      const config = {
        language,
        fileName: t('Perfect Money History'),
        heads: heads,
        columnsWidth,
        list: response.perfectMoneyHistoryInfoList.map((item) => [
          moment(item.createdDate).locale(language).format('YYYY-MM-DD HH:mm'),
          ...(isAdmin ? [`${item.firstName} ${item.lastName}`, item.emailAddress || '-', item.mobile || '-'] : []),
          item.fiatAmount,
          item.perfectMoneyPrice || '-',
          item.perfectMoneyAmount || '-',
          item.voucherAmount || '-',
          item.voucherCurrency || '-',
          item.side,
          item.walletId,
        ]),
      };

      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  // ----------------------------------------------

  return (
    <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 }, mx: { xs: 2, lg: 0 } }}>
      <PageTitle
        title={'Perfect Money History'}
        subTitle={'You can enter the name and symbol of Coin, transaction ID, etc. in the search field'}
      />

      <Box sx={{ mt: 5 }}>
        <PerfectMoneyHistoryTableToolbar
          isAdmin={isAdmin}
          isExportLoading={isExportLoading}
          onExport={onExport}
          sideFilter={sideFilter}
          setSideFilter={setSideFilter}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          typeFilter={typeFilter}
          setTypeFilter={setTypeFilter}
          timeFilter={timeFilter}
          setTimeFilter={setTimeFilter}
          transactionIdFilter={transactionIdFilter}
          setTransactionIdFilter={setTransactionIdFilter}
          fiatAmountFilter={fiatAmountFilter}
          setFiatAmountFilter={setFiatAmountFilter}
          firstNameFilter={firstNameFilter}
          setFirstNameFilter={setFirstNameFilter}
          lastNameFilter={lastNameFilter}
          setLastNameFilter={setLastNameFilter}
          userIdFilter={userIdFilter}
          setUserIdFilter={setUserIdFilter}
        />

        <Scrollbar>
          <TableContainer sx={{ position: 'relative' }}>
            <Table size={'small'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length || 0}
                onSort={onSort}
              />

              <TableBody>
                {tableData === null ? (
                  <TableLoading isLoading />
                ) : (
                  <>
                    {applySortFilter({
                      tableData,
                      comparator: getComparator(order, orderBy),
                    }).map((row, index) => (
                      <PerfectMoneyHistoryTableRow
                        key={index}
                        row={row}
                        isAdmin={isAdmin}
                        onShowModalDetail={() => setShowModalDetail(row)}
                      />
                    ))}

                    <TableNoData isNotFound={!tableData?.length} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        {tableData?.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            component='div'
            count={tablePagination.totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
          />
        )}
      </Box>

      <PerfectMoneyHistoryModal
        open={!!showModalDetail}
        onClose={() => setShowModalDetail(null)}
        data={showModalDetail}
        status={statusFilter}
        type={typeFilter}
        isAdmin={isAdmin}
      />
    </Card>
  );
}
