import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { InputAdornment, MenuItem, Stack, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

import Api from '../../../http/serviceApi';
import { FormProvider, RHFNumberField, RHFSelect, RHFTextField } from '../../../components/hookForm';
import Image from '../../../components/Image';
import StatusModal from '../../../components/StatusModal';

import imgShetabCard from '../../../assets/bankCard/shetab-card.png';
import imgVisaCard from '../../../assets/bankCard/visa-card.png';
import imgMasterCard from '../../../assets/bankCard/master-card.png';
import useBankCardForm from '../../../hooks/useBankCardForm';

const CARD_INTERNATIONAL_FORM = {
  MASTER_CARD: true,
  VISA_CARD: true,
  SHETAB_CARD: false,
};

export default function AddBankCard({ reloadList }) {
  const user = useSelector((store) => store.userInfo);
  const { t } = useTranslation();
  const isIranianPaymentActive = useSelector((store) => store.isIranianPaymentActive);

  const CARD_TYPE = [
    { value: 'MASTER_CARD', label: 'Master Card', icon: imgMasterCard },
    { value: 'VISA_CARD', label: 'Visa Card', icon: imgVisaCard },
    ...(isIranianPaymentActive ? [{ value: 'SHETAB_CARD', label: 'Shetab Card', icon: imgShetabCard }] : []),
  ];

  const [statusModal, setStatusModel] = useState(false);

  const { handleSubmit, methods, reset, setValue, isSubmitting, cardType } = useBankCardForm({ userMode: true });

  useEffect(() => {
    setValue('firstName', user.firstName);
    setValue('lastName', user.lastName);
  }, [user]);

  const onSubmit = async (payload) => {
    try {
      const response = await Api.addBankCard({ ...payload, shebaNum: payload.shebaNum ? `IR${payload.shebaNum}` : '' });
      if (response?.status === 'SUCCESS') {
        setStatusModel(true);
        reset();
        setValue('firstName', user.firstName);
        setValue('lastName', user.lastName);
        reloadList();
      }
    } catch (e) {}
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ maxWidth: { xs: '100%', md: 490 }, mt: 6, mb: 5 }} spacing={3}>
          <RHFSelect
            name='cardType'
            SelectProps={{
              native: false,
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'common.black' } },
              },
            }}
            label='Type of Card'
            variant='standard'
            fullWidth
          >
            {CARD_TYPE.map((type) => (
              <MenuItem value={type.value}>
                <Stack direction='row' spacing={1} alignItems={'center'}>
                  <Image src={type.icon} />
                  <Typography typography='body3' color='grey.300'>
                    {t(type.label)}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </RHFSelect>

          {cardType && (
            <>
              <Stack direction='row' spacing={2}>
                <RHFTextField name='firstName' label={t('First Name')} fullWidth variant='standard' disabled />
                <RHFTextField name='lastName' label={t('Last Name')} fullWidth variant='standard' disabled />
              </Stack>

              <RHFNumberField
                name='cardNumber'
                label={t('Card Number')}
                variant='standard'
                format='#### #### #### ####'
                sx={{ '& input': { letterSpacing: 0.5 } }}
              />

              <Stack direction='row' spacing={2}>
                <RHFNumberField name='expiryDateMonth' label={t('Month of date')} variant='standard' format='##' />
                <RHFNumberField name='expiryDateYear' label={t('Year of date')} variant='standard' format='##' />
              </Stack>

              <RHFNumberField name='cvv2' label={t('CVV2')} variant='standard' thousandSeparator={false} />

              {!CARD_INTERNATIONAL_FORM[cardType] && (
                <>
                  <RHFNumberField
                    name='accountNumber'
                    label={t('Account Number')}
                    variant='standard'
                    thousandSeparator={false}
                  />
                  <RHFNumberField
                    name='shebaNum'
                    label={t('Shaba')}
                    variant='standard'
                    thousandSeparator={false}
                    inputProps={{ maxLength: 24 }}
                    sx={{ '& input': { letterSpacing: 1 } }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Typography color='grey.300' variant='body2'>
                            IR
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </>
              )}
            </>
          )}
        </Stack>

        <LoadingButton
          type='submit'
          variant='contained'
          sx={{ width: { xs: '100%', md: 320 }, typography: 'body3_secondary' }}
          size='large'
          loading={isSubmitting}
        >
          {t('Register the card')}
        </LoadingButton>
      </FormProvider>

      <StatusModal
        open={statusModal}
        onClose={() => setStatusModel(false)}
        type='success'
        title={t('Successful')}
        subtitle='Done Successfully'
      />
    </>
  );
}
