import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function useNewNetworkForm() {
  const { t } = useTranslation();

  const validators = Yup.object().shape({
    name: Yup.string().required(t("Please input network's name!")),
    persian: Yup.string().required(t("Please input network's persian name!")),
    symbol: Yup.string().required(t("Please input network's symbol!")),
    selector: Yup.string().required(t("Please input network's selector!")),
    status: Yup.boolean(),
  });

  const defaultValues = {
    name: '',
    persian: '',
    symbol: '',
    selector: '',
    status: true,
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;

  return {
    handleSubmit,
    isSubmitting,
    methods,
    status: watch('status'),
  };
}
