import { Button, Divider, Stack, Typography } from '@mui/material';
import Iconify from './Iconify';
import { NumberField } from './NumberField';
import SymbolIcon from './SymbolIcon';

export default function SymbolInput({
  openModal,
  amount,
  onChangeAmount,
  symbol,
  disabledValue = false,
  disabledSymbol = false,
  ...other
}) {
  return (
    <Stack direction={'row'} alignItems={'center'} sx={{ mt: 1.5 }}>
      <NumberField
        variant='standard'
        value={amount}
        onChange={(event) => onChangeAmount(event.target.value)}
        fullWidth
        placeholder={'0'}
        thousandSeparator
        disabled={disabledValue}
        sx={{
          '& input': {
            color: 'grey.100',
            height: '1.55em',
          },
          '& .Mui-disabled': {
            '& input': { WebkitTextFillColor: 'grey.0' },
            '&:before': { borderBottomStyle: 'solid' },
          },
        }}
        {...other}
      />

      <Divider orientation='vertical' variant='middle' flexItem />

      <Button
        onClick={openModal}
        size='small'
        color='inherit'
        disabled={disabledSymbol}
        sx={{
          width: 140,
          justifyContent: 'flex-start',
          borderBottom: '1px solid rgba(145, 158, 171, 0.56)',
          borderRadius: 0,
          '&:hover': {
            borderBottomColor: 'grey.0',
            background: 'none',
          },
        }}
      >
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
          <Stack direction={'row'} spacing={1.2} alignItems={'center'} sx={{ ml: 0.5 }}>
            <SymbolIcon symbol={symbol} width={20} height={20} />
            <Typography variant='subtitle2_secondary'>{symbol}</Typography>
          </Stack>
          <Iconify icon={'eva:arrow-ios-downward-fill'} sx={{ width: 15, height: 15, mr: -0.7, color: 'grey.300' }} />
        </Stack>
      </Button>
    </Stack>
  );
}
