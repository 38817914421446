import { useEffect, useState } from 'react';
import { Box, Card, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'jalali-moment';

import useTable, { getComparator } from '../../../../hooks/useTable';
import { PageTitle } from '../../../../components/PageTitle';
import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../components/table';
import { applySortFilter } from '../../../../utils';
import FiatTransactionTableRow from './fiatTransactionTableRow';
import Api from '../../../../http/serviceApi';
import AdminApi from '../../../../http/adminHttp/serviceApiAdmin';
import FiatTransactionTableToolbar from './fiatTransactionTableToolbar';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { exportExcel } from '../../../../utils/excel';
import { getDefaultDateFilterValues } from '../../../../utils/filter';
import { exportPDF } from '../../../../utils/pdf';

// ----------------------------------------------------------------------

export default function FiatTransactionTable({ isAdmin = false }) {
  const language = useSelector((store) => store.language);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Fiat Transactions' },
    });
  }, []);

  const TABLE_HEAD = [
    { id: 'createDate', label: 'Time', align: 'left' },
    ...(isAdmin ? [{ id: 'user', label: 'User', align: 'left' }] : []),
    { id: 'amount', label: 'Amount', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'depositId', label: 'Deposit ID', align: 'left' },
  ];

  const [fiatTypeFilter, setFiatTypeFilter] = useState('ALL'); // ALL, AUD, BRL, EUR, GBP, IRR, NGN, PLN, RON, RUB, TRY, UAH, USD, ZAR
  const [fiatStatusFilter, setFiatStatusFilter] = useState('ALL'); // CANCELED, FAILURE, NEW, SUCCESS
  const [timeFilter, setTimeFilter] = useState({
    startTime: getDefaultDateFilterValues().firstDay,
    endTime: getDefaultDateFilterValues().lastDay,
  });

  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });

  const getData = async () => {
    try {
      const filters = {
        type: 'Fiat',
        allTransaction: 'false',
        fiatType: fiatTypeFilter,
        endTime: timeFilter.endTime?.valueOf?.(),
        startTime: timeFilter.startTime?.valueOf?.(),
        fiatStatus: fiatStatusFilter,
        depositStatus: 'SUCCESS',
        withdrawStatus: 'Completed',
      };

      if (isAdmin) {
        const response = await AdminApi.getReportTransaction(page, rowsPerPage, filters);
        if (response.status === 'SUCCESS') {
          setTableData(response.fiatDepositInfos);
          setTablePagination({ totalItems: response.fiatDepositInfosTotalPages * rowsPerPage });
        } else {
          setTableData([]);
        }
      } else {
        const response = await Api.getUserTransaction(page, rowsPerPage, filters);
        if (response.status === 'SUCCESS') {
          setTableData(response.fiatDepositInfos);
          setTablePagination({ totalItems: response.fiatDepositInfosTotalPages * rowsPerPage });
        } else {
          setTableData([]);
        }
      }
    } catch (e) {
      setTableData([]);
    }
  };

  useEffect(() => {
    getData();
  }, [page, rowsPerPage, fiatStatusFilter, timeFilter, fiatTypeFilter]);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);
  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    const filters = {
      type: 'Fiat',
      fiatType: fiatTypeFilter,
      allTransaction: 'false',
      endTime: timeFilter.endTime?.valueOf?.(),
      startTime: timeFilter.startTime?.valueOf?.(),
      fiatStatus: fiatStatusFilter,
      depositStatus: 'SUCCESS',
      withdrawStatus: 'Completed',
    };

    try {
      let response;
      if (isAdmin) {
        response = await AdminApi.getReportTransaction(0, 10000000, filters);
      } else {
        response = await Api.getUserTransaction(0, 10000000, filters);
      }

      const heads = [
        '#',
        t('Time'),
        ...(isAdmin ? [t('Name'), t('Email'), t('Mobile')] : []),
        t('Amount'),
        t('Status'),
        t('Deposit ID'),
      ];

      const columnsWidth = [5, 15, ...(isAdmin ? [20, 30, 13] : []), 6, 12, 12];

      // generate excel and download
      const config = {
        language,
        fileName: t('Fiat Transaction'),
        heads: heads,
        columnsWidth,
        list: response.fiatDepositInfos.map((item) => [
          moment(item.createdDate).locale(language).format('YYYY-MM-DD HH:mm'),
          ...(isAdmin ? [`${item.firstName} ${item.lastName}`, item.emailAddress || '-', item.mobile || '-'] : []),
          item.amount,
          item.status,
          item.depositId,
        ]),
      };

      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  // ----------------------------------------------
  return (
    <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 }, mx: { xs: 2, lg: 0 } }}>
      <PageTitle
        title={'History of your transactions'}
        subTitle={'You can enter the name and symbol of Coin, transaction ID, etc. in the search field'}
      />

      <Box sx={{ mt: 5 }}>
        <FiatTransactionTableToolbar
          fiatTypeFilter={fiatTypeFilter}
          setFiatTypeFilter={setFiatTypeFilter}
          fiatStatusFilter={fiatStatusFilter}
          timeFilter={timeFilter}
          setFiatStatusFilter={setFiatStatusFilter}
          setTimeFilter={setTimeFilter}
          isExportLoading={isExportLoading}
          onExport={onExport}
        />

        <Scrollbar>
          <TableContainer sx={{ position: 'relative' }}>
            <Table size={'small'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length || 0}
                onSort={onSort}
              />

              <TableBody>
                {tableData === null ? (
                  <TableLoading isLoading />
                ) : (
                  <>
                    {applySortFilter({
                      tableData,
                      comparator: getComparator(order, orderBy),
                    }).map((row) => (
                      <FiatTransactionTableRow key={row.transactionId} row={row} isAdmin={isAdmin} />
                    ))}

                    <TableNoData isNotFound={!tableData?.length} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        {tableData?.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            component='div'
            count={tablePagination.totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
          />
        )}
      </Box>
    </Card>
  );
}
