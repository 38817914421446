import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from '../../../components/ModalContainer';
import { alpha, Button, Card, Divider, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material';
import Image from '../../../components/Image';
import sale from '../../../assets/sale.svg';
import purchase from '../../../assets/purchase.svg';
import Iconify from '../../../components/Iconify';

import USDT from '../../../assets/logo/USDT.svg';
import { NumberField } from '../../../components/NumberField';
import Api from '../../../http/serviceApi';
import { SET_TOAST } from '../../../store/actionTypes';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import useFutureWallet from './useFutureWallet';
import useWallet from '../../../hooks/useWallet';




export const FIAT_LIST = [
  { label: 'USDT', icon: USDT },

];
const FutureTransferModal = ({ open, onClose, refreshWallet }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [transferFromFuture , setTransferFromFuture] = useState(true)
  const [fiatSelected , setFiatSelected] = useState( 'USDT')
  const [amount , setAmount] = useState(0)
  const [isSubmitting , setIsSubmitting] = useState(false)
  const confirmTransfer = async () =>{
    setIsSubmitting(true)
      if (transferFromFuture){
        try {
            const res = await Api.transferToSpotWallet(amount)
            dispatch({ type: SET_TOAST, payload: { type: '', message: 'Transfer submitted successfully' } });
            if(refreshWallet) refreshWallet()
            onClose()

        }catch (e) {
          dispatch({ type: SET_TOAST, payload: { type: 'error'  } });
        }finally {setIsSubmitting(false)}
      }else {
        try {
          const res = await Api.transferToFuturesWallet(amount)
          dispatch({ type: SET_TOAST, payload: { type: '', message: 'Transfer submitted successfully' } });
          if(refreshWallet) refreshWallet()
          onClose()

        }catch (e) {
          dispatch({ type: SET_TOAST, payload: { type: 'error'  } });
        }finally {setIsSubmitting(false)}

      }
  }

  const { userWallet : userWalletFuture, loading : loadingFuture , onReloadWallet : onReloadWalletFuture } = useFutureWallet();
  const { userWallet, loading , onReloadWallet } = useWallet();

   const found = userWallet.walletInfos.find((w) => w.symbol === "USDT");

  return (
    <ModalContainer  open={open} onClose={onClose} title={t(`Transfer`)} width={420}>
        <Card width={'100%'}
              sx={{
                mt: 2, backgroundColor: 'black.100', borderRadius:1, py: 1, px: 2, border: '1px solid black.200',
                position:"relative"
              }}
        >
          {
            !transferFromFuture &&
            <>
              <Stack width={'80%'} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'start'}
              >
                <Typography width={'30px'} variant={'body4'} color={'grey.100'}>
                  {t('From')}
                </Typography>
                <Stack
                  alignItems={'center'}
                  justifyContent={'center'}
                  sx={{ backgroundColor: alpha('#F27F16', 0 ), borderRadius: '100%', width: 48, height: 48  }}
                >
                  <Image src={purchase} />
                </Stack>
                <Typography>
                  {t('Funding Wallet')}
                </Typography>
              </Stack>
              <Divider sx={{ width: '100%' }}></Divider>
            </>

          }


          <Stack width={'80%'} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'start'}>
            <Typography width={'30px'} variant={'body4'} color={'grey.100'}>
              {t(!transferFromFuture? 'To' :"From")}
            </Typography>
            <Stack
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ backgroundColor: alpha('#F27F16', 0 ), borderRadius: '100%', width: 48, height: 48 }}
            >
              <Image src={sale} />
            </Stack>
            <Typography>
              {t('Future Wallet')}
            </Typography>
          </Stack>

          {
            transferFromFuture &&
            <>
              <Divider sx={{ width: '100%' }}></Divider>

              <Stack width={'80%'} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'start'}
              >
                <Typography width={'30px'} variant={'body4'} color={'grey.100'}>
                  {t('To')}
                </Typography>
                <Stack
                  alignItems={'center'}
                  justifyContent={'center'}
                  sx={{ backgroundColor: alpha('#F27F16', 0 ), borderRadius: '100%', width: 48, height: 48  }}
                >
                  <Image src={purchase} />
                </Stack>
                <Typography>
                  {t('Funding Wallet')}
                </Typography>
              </Stack>
            </>
          }
          <Stack position={'absolute'}  right={'30px'} top={'33px'} sx={{cursor:'pointer'}} onClick={()=>setTransferFromFuture(!transferFromFuture)}>
            <Stack
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ backgroundColor: 'black.200', borderRadius: '100%', width: 48, height: 48  }}
            >
              <Iconify icon={"gg:arrows-exchange-alt-v"} sx={{fontSize:"1.8rem"}}/>
            </Stack>

          </Stack>
        </Card>


      <Card
        sx={{
          mt: 6 , borderRadius:1, py: 1, px:0, border: '1px solid black.200', backgroundColor:"rgba(0,0,0,0)",
          position:"relative"
        }}
      >

        <Stack direction={'row'} spacing={1} sx={{mb:1}}>
          <Typography color={'grey.300'} typography='body1'>
            {t('Avbl')}
          </Typography>
          <Typography color={'common.white'} typography='body1'>
            {transferFromFuture ? `${userWalletFuture?.available} USDT` : `${found?.available} USDT`}
          </Typography>

        </Stack>

        <TextField
          fullWidth
          select
          variant='outlined'
          label='Coin'
          value={fiatSelected}
          onChange={(event) => setFiatSelected(event.target.value)}
          SelectProps={{

            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260 } },

            },
          }}
          sx={{
              backgroundColor: 'black.100',
            '& .MuiInput-root::before': {
              display: 'none',
            },
            '& .MuiInput-root::after': {
              display: 'none',
            },
            width: { xs: '100%', md: '100%' },
          }}
        >
          {FIAT_LIST.map((item, index) => (
            <MenuItem
              key={index}
              value={item.label}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
              }}
            >
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Image src={item.icon} sx={{ width: 20 }} />

                <Typography variant='subtitle2_secondary'>{item.label}</Typography>
              </Stack>
            </MenuItem>
          ))}
        </TextField>

        <NumberField
          label={'Amount'}
          variant='outlined'
          value={amount}
          onChange={(event) => setAmount(event.target.value)}
          fullWidth
          placeholder='0'
          thousandSeparator
          sx={{
              mt:2,

             backgroundColor: 'black.100',
            '& .MuiInput-root::before': {
              display: 'none',
            },
            '& .MuiInput-root::after': {
              display: 'none',
            },
            '& input': { color: 'grey.0', height: '1.55em' },
            '& .Mui-disabled': {
              '& input': { WebkitTextFillColor: 'grey.0 !important' },
              '&:before': { borderBottomStyle: 'solid !important' },
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>

                  <Stack direction='row' spacing={1}>
                    <Button
                      size='small'
                      color='warning'
                      sx={{
                        typography: 'body1',
                        fontWeight: 900,
                        p: 0,
                        minWidth: 50,
                        height: 24,
                        borderRadius: 0.6,
                      }}
                      onClick={() => setAmount(transferFromFuture ? userWalletFuture?.available  :found?.available)}
                    >
                      {t('MAX')}
                    </Button>
                    <Divider orientation='vertical' variant='middle' flexItem />
                    <Typography variant='body1_secondary' sx={{ fontWeight: 900 }}>
                      {0 || ''}
                    </Typography>
                  </Stack>

              </InputAdornment>
            ),
          }}
        />
      </Card>

      <LoadingButton
        fullWidth
        variant='contained'
        color='primary'
        onClick={confirmTransfer}
        sx={{ width: '100%' , height: { xs: 40, lg: 47 },mt:2 }}
        loading={isSubmitting}

      >
        {t('Confirm')}
      </LoadingButton>
    </ModalContainer>

  );
};

export default FutureTransferModal;