import { Card, Checkbox, FormControlLabel, FormGroup, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../../components/PageTitle';
import { NumberField } from '../../../components/NumberField';
import { LoadingButton } from '@mui/lab';
import Api from '../../../http/adminHttp/serviceApiAdmin';
import { useSnackbar } from 'notistack';

const RECIPIENTS = [
  { value: 'ALL', label: 'All User' },
  { value: 'UNVERIFIED', label: 'Unverified Users' },
  { value: 'GOOGLE_AUTH_INACTIVE', label: 'Users whos Google Authenticator is inactive' },
  { value: 'SMS_AUTH_INACTIVE', label: 'Users whos SMS Authenticator is inactive' },
  { value: 'BASIC_LEVEL', label: 'Basic level Users' },
  { value: 'INTERMEDIATE_LEVEL', label: 'Intermediate Users' },
  { value: 'ADVANCED_LEVEL', label: 'Advanced Users' },
  { value: 'PERFECT_MONEY_BUYERS_SELLERS', label: 'Perfect Money buyers/sellers' },
  { value: 'CRYPTO_BUYERS_SELLERS', label: 'CryptoCurrency buyers/sellers' },
  { value: 'PAYSAFE_BUYERS_SELLERS', label: 'Paysafe buyers/sellers' },
  { value: 'PERFECT_MONEY_VOUCHER_BUYERS_SELLERS', label: 'Vouchers buyers/sellers' },
  { value: 'REFERRAL_USERS', label: 'Referral Users' },
  { value: 'WITHOUT_TRADES', label: 'Users Who hasnt trade yet' },
];

const COLORS = [
  { value: 'BLACK', label: 'Normal (black)' },
  { value: 'GREEN', label: 'Success (green)' },
  { value: 'RED', label: 'Danger (red)' },
  { value: 'YELLOW', label: 'Warning (yellow)' },
];

export default function AdminNotificationForm({ onReload }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // --------------
  const [activeDays, setActiveDays] = useState('');
  const [color, setColor] = useState(COLORS[0].value);
  const [message, setMessage] = useState('');
  const [recipient, setRecipient] = useState(RECIPIENTS[0].value);
  const [title, setTitle] = useState('');
  const [types, setTypes] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // --------------
  const onTypeSelect = (type) => {
    const newTypes = [...types];

    if (types.indexOf(type) !== -1) {
      setTypes(newTypes.filter((t) => type !== t));
    } else {
      newTypes.push(type);
      setTypes(newTypes);
    }
  };

  // ------------------
  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      const response = await Api.sendNotification({
        activeDays,
        color,
        message,
        recipient,
        title,
        types,
      });
      if (response.status === 'SUCCESS') {
        onReload();
        enqueueSnackbar(t('The message sent successfully'), { variant: 'success' });
        setActiveDays('');
        setMessage('');
        setTitle('');
      }
    } catch (e) {}

    setIsSubmitting(false);
  };

  // --------------
  return (
    <Card sx={{ py: 5 }}>
      <PageTitle title='Notification type' />

      <Stack sx={{ mt: 4 }}>
        <FormGroup>
          <Stack direction={'row'}>
            <FormControlLabel
              control={
                <Checkbox onChange={() => onTypeSelect('FIXED_ALERT')} checked={types.indexOf('FIXED_ALERT') !== -1} />
              }
              label={t('Fixed panel page header')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => onTypeSelect('ONE_TIME_ALERT')}
                  checked={types.indexOf('ONE_TIME_ALERT') !== -1}
                />
              }
              label={t('Header panel pages only once')}
            />
          </Stack>

          <Stack direction={'row'} sx={{ mt: 2 }} spacing={2}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => onTypeSelect('NOTIFICATION')}
                  checked={types.indexOf('NOTIFICATION') !== -1}
                />
              }
              label={t('By notification')}
            />
            <FormControlLabel
              control={<Checkbox onChange={() => onTypeSelect('EMAIL')} checked={types.indexOf('EMAIL') !== -1} />}
              label={t('by Email')}
            />
            <FormControlLabel
              control={<Checkbox onChange={() => onTypeSelect('SMS')} checked={types.indexOf('SMS') !== -1} />}
              label={t('Via SMS')}
            />
          </Stack>
        </FormGroup>
      </Stack>

      <Stack sx={{ mt: 5 }} spacing={1}>
        <TextField
          select
          SelectProps={{
            native: false,
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' } },
            },
          }}
          label={t('User’s')}
          variant='standard'
          fullWidth
          sx={{
            maxWidth: 320,
          }}
          onChange={(e) => setRecipient(e.target.value)}
          value={recipient}
        >
          {RECIPIENTS.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Typography variant='body3' color='grey.300'>
                {t(item.label)}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
        <Typography variant='body2' color={'grey.400'} sx={{ maxWidth: 400 }}>
          {t('This category does not apply to header alerts and shows for everyone')}
        </Typography>
      </Stack>

      <Stack sx={{ mt: 3 }} spacing={1}>
        <TextField
          label={t('Notification Title')}
          variant='standard'
          fullWidth
          sx={{
            maxWidth: 320,
          }}
          placeholder={t('Write your topic')}
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />
        <Typography variant='body2' color={'grey.400'}>
          {t('The title does not apply to sms')}
        </Typography>
      </Stack>

      <Stack sx={{ mt: 3 }} spacing={1}>
        <TextField
          label={t('Message')}
          variant='standard'
          fullWidth
          sx={{
            maxWidth: 320,
          }}
          placeholder={t('Write your topic')}
          onChange={(e) => setMessage(e.target.value)}
          value={message}
        />
      </Stack>

      <Stack sx={{ mt: 4 }}>
        <TextField
          select
          SelectProps={{
            native: false,
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' } },
            },
          }}
          label={t('Color background')}
          variant='standard'
          fullWidth
          sx={{
            maxWidth: 320,
          }}
          onChange={(e) => setColor(e.target.value)}
          value={color}
        >
          {COLORS.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Typography variant='body3' color='grey.300'>
                {t(item.label)}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <Stack sx={{ mt: 4 }} spacing={1}>
        <NumberField
          label={t('Active days')}
          variant='standard'
          fullWidth
          sx={{
            maxWidth: 320,
          }}
          onChange={(e) => setActiveDays(e.target.value)}
          value={activeDays}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size='large'
        variant='contained'
        onClick={onSubmit}
        loading={isSubmitting}
        sx={{ maxWidth: 283, typography: 'body3', fontWeight: 500, mt: 4 }}
      >
        {t('Send')}
      </LoadingButton>
    </Card>
  );
}
