import React, { useState } from 'react';
import { Button, MenuItem, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DatePicker from '../../../../../../components/DatePicker';
import NumberRange from '../../../../../../components/NumberRange';
import ComplexToolbar from '../../../../../../components/ComplexToolbar';

const OrdersToolbar = ({
  typeFilter,
  setTypeFilter,
  dateFromFilter,
  setDateFromFilter,
  dateToFilter,
  setDateToFilter,
  handleFilter,
  handleResetFilters,
  isExportLoading,
  onExport,
}) => {
  const { t } = useTranslation();
  // const levels = ['Unverified', 'Basic', 'Intermediate', 'Advanced'];

  // const [userNameFilter, setUserNameFilter] = useState('');
  // const [profileFilter, setProfileFilter] = useState('');
  // const [typeFilter, setTypeFilter] = useState('');
  // const [balanceFilter, setBalanceFilter] = useState([0, 50000]);
  // const [dateFromFilter, setDateFromFilter] = useState(null);
  // const [dateToFilter, setDateToFilter] = useState(null);
  // const [levelFilter, setLevelFilter] = useState('');

  // const handleFilter = () => {
  //   onFilter({ userNameFilter, profileFilter, otherFilter, balanceFilter, dateFromFilter, dateToFilter, levelFilter });
  // };

  // const resetFilter = () => {
  //   setUserNameFilter('');
  //   setProfileFilter('');
  //   setOtherFilter('');
  //   setBalanceFilter([0, 50000]);
  //   setDateFromFilter(null);
  //   setDateToFilter(null);
  //   setLevelFilter('');

  //   onFilter({
  //     useNameFilter: '',
  //     profileFilter: '',
  //     otherFilter: '',
  //     balanceFilter: [0, 50000],
  //     dateFromFilter: null,
  //     dateToFilter: null,
  //     levelFilter: '',
  //   });
  // };

  return (
    <ComplexToolbar
      onSearch={false}
      onExport={onExport}
      isExportLoading={isExportLoading}
      onPrint={() => console.log('print')}
    >
      {/* <Stack spacing={3}> */}
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} sx={{ mt: 2 }} alignItems={'center'}>
        {/* <TextField
            label={t('User')}
            variant='standard'
            fullWidth
            value={userNameFilter}
            onChange={(e) => setUserNameFilter(e.target.value)}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          ></TextField> */}

        {/* <TextField
            select
            label={t('Profile')}
            variant='standard'
            fullWidth
            value={profileFilter}
            onChange={(e) => setProfileFilter(e.target.value)}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            <MenuItem value={'all'}>{t('All')}</MenuItem>
          </TextField> */}

        <Stack direction={'row'} sx={{ maxWidth: 320, width: { xs: '100%', md: '50%' } }}>
          <DatePicker
            label={t('From')}
            value={dateFromFilter}
            setValue={setDateFromFilter}
            variant='standard'
            maxDate={dateToFilter}
            fullWidth
            sx={{ minWidth: 75 }}
            InputProps={null}
          />
          <DatePicker
            label={t('To')}
            value={dateToFilter}
            setValue={setDateToFilter}
            variant='standard'
            minDate={dateFromFilter}
            fullWidth
            sx={{ minWidth: 75 }}
          />
        </Stack>

        <TextField
          select
          label={t('Type')}
          variant='standard'
          fullWidth
          value={typeFilter}
          onChange={(e) => setTypeFilter(e.target.value)}
          sx={{ maxWidth: 200, width: { xs: '100%', md: '50%' } }}
          SelectProps={{
            MenuProps: {
              sx: {
                '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                '& .MuiMenuItem-root': { typography: 'body2' },
              },
            },
          }}
        >
          {/*<MenuItem value={'ALL'}>{t('All')}</MenuItem>*/}
          <MenuItem value={'otc'}>{t('OTC')}</MenuItem>
          <MenuItem value={'p2p'}>{t('P2P')}</MenuItem>
          <MenuItem value={'trade'}>{t('Trade')}</MenuItem>
          <MenuItem value={'futures'}>{t('Futures')}</MenuItem>
          <MenuItem value={'paysafe'}>{t('Pay safe')}</MenuItem>
          <MenuItem value={'perfectmoney'}>{t('Perfect Money')}</MenuItem>

        </TextField>
      </Stack>

      {/* <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'center'} spacing={5}>
     

          <TextField
            select
            label={t('Level')}
            variant='standard'
            fullWidth
            value={levelFilter}
            onChange={(e) => setLevelFilter(e.target.value)}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            {levels.map((level, index) => (
              <MenuItem key={index} value={level.toLowerCase()}>
                {level}
              </MenuItem>
            ))}
          </TextField>

          <NumberRange
            label={'Balance'}
            value={balanceFilter}
            setValue={setBalanceFilter}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
          />
        </Stack> */}
      {/* </Stack> */}

      <Stack direction={'row'} spacing={2} sx={{ mt: 5, mb: 5.75 }}>
        <Button
          onClick={handleFilter}
          fullWidth
          variant='contained'
          sx={{ maxWidth: 204, typography: 'body3', fontWeight: 500 }}
        >
          {t('Filter')}
        </Button>
        <Button
          onClick={handleResetFilters}
          fullWidth
          variant='outlined'
          color='inherit'
          sx={{ maxWidth: 80, typography: 'body3', fontWeight: 500, borderColor: 'grey.400' }}
        >
          {t('Reset')}
        </Button>
      </Stack>
    </ComplexToolbar>
  );
};

export default OrdersToolbar;
