import { Box, Button, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TabChart from './tabChart';
import DatePicker from '../../../../components/DatePicker';
import { useState } from 'react';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import { useEffect } from 'react';
import moment from 'jalali-moment';
import { getDefaultDateFilterValues } from '../../../../utils/filter';
import { exportExcel } from '../../../../utils/excel';
import { useSelector } from 'react-redux';
import { exportPDF } from '../../../../utils/pdf';

export default function FuturesOpenPositionTab() {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const [filters, setFilters] = useState({
    startTime: getDefaultDateFilterValues().firstDay,
    endTime: getDefaultDateFilterValues().lastDay,
  });
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);

  const getData = async () => {
    const response = await Api.getReportFuturesOpenChartStatistics({
      startTime: filters.startTime?.valueOf?.(),
      endTime: filters.endTime?.valueOf?.(),
    });

    const datas = [
      {
        name: t('Crypto'),
        type: 'line',
        data: response?.crypto?.map((t) => t.sum) || [],
        color: '#F27F16',
      },
    ];

    setChartData(datas);
    setChartLabels(response?.crypto?.map((t) => moment(t.date).format('YYYY MMM D')));
  };

  useEffect(() => {
    getData();
  }, []);

  const [isExportLoading, setIsExportLoading] = useState(false);

  // --------------------
  const onExport = async (type = 'Excel') => {
    if (chartData?.length === 0) return;

    setIsExportLoading(true);

    const list = [];
    chartData[0].data.forEach((d, index) => {
      list.push([chartLabels[index], ...chartData.map((cd) => cd.data[index])]);
    });

    try {
      let fileName = t('Futures Open position');

      const config = {
        language,
        fileName: `${fileName} - ${moment(filters.startTime).locale(language).format('YYYY-MM-DD')} - ${moment(
          filters.endTime
        )
          .locale(language)
          .format('YYYY-MM-DD')}`,
        heads: ['#', t('Date'), ...chartData.map((cd) => t(cd.name))],
        columnsWidth: [5, 19, 16, 20, 15, 21, 20],
        list,
      };

      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  return (
    <Grid container sx={{ mt: 5, mb: 2 }}>
      <Grid item xs={12} sm={5} md={4} sx={{ pl: 3 }}>
        <Stack spacing={4}>
          <Stack direction={'row'}>
            <DatePicker
              label={t('From')}
              value={filters.startTime}
              name='startTime'
              setValue={(startTime) => setFilters({ ...filters, startTime })}
              variant='standard'
              maxDate={filters.endTime}
              fullWidth
              sx={{ maxWidth: 150 }}
              InputProps={null}
            />
            <DatePicker
              label={t('To')}
              value={filters.endTime}
              setValue={(endTime) => setFilters({ ...filters, endTime })}
              variant='standard'
              minDate={filters.startTime}
              fullWidth
              sx={{ maxWidth: 150 }}
            />
          </Stack>
        </Stack>
        <Button
          fullWidth
          size='large'
          variant='contained'
          className='no-print'
          sx={{ maxWidth: 283, typography: 'body3', fontWeight: 500, mt: 39 }}
          onClick={() => getData()}
        >
          {t('Report')}
        </Button>
      </Grid>

      <Grid item xs={12} sm={7} md={8} sx={{ pr: 3, mt: { xs: 4, sm: 0 } }}>
        <Box sx={{ mb: 2, mt: 1.5 }}>
          <div style={{ height: 'auto' }}>
            <TabChart chartData={chartData} chartLabels={chartLabels}  label={'Sum USDT'}/>
          </div>
        </Box>

        <Stack direction={'row'} justifyContent={'center'} spacing={4} className='no-print'>
          <Button
            color='inherit'
            size='large'
            variant='outlined'
            sx={{ width: 172, typography: 'body3', fontWeight: 500, color: 'grey.400' }}
            loading={isExportLoading}
            onClick={onExport}
          >
            {t('Excel')}
          </Button>
          <Button
            color='inherit'
            size='large'
            variant='outlined'
            sx={{ width: 172, typography: 'body3', fontWeight: 500, color: 'grey.400' }}
            onClick={() => window.print()}
          >
            {t('PDF')}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}
