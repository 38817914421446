import { Autocomplete, CircularProgress, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ComplexToolbar from '../../../../components/ComplexToolbar';
import CustomSwitch from '../../../../components/CustomSwitch';
import Api from '../../../../http/adminHttp/serviceApiAdmin';

export default function NetworkToolbar({
  onSearch,
  onReload = () => {},
  isAllActive = true,
  networkList = [],
  selectedNetwork,
  setSelectedNetwork,
  selectedStatus,
  setSelectedStatus,
  dataFiltered,
}) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const handleSwitch = async () => {
    setLoading(true);

    try {
      let response;

      if (selectedNetwork) {
        for (let i = 0; i < dataFiltered.length; i++) {
          response = await Api.editNetworkStatus({
            name: dataFiltered[i].name,
            symbol: dataFiltered[i].symbol,
            status: !isAllActive,
            symbolType: 'SELECTED',
          });
        }
      } else {
        response = await Api.editNetworkStatus({ allStatus: !isAllActive, symbolType: 'ALL' });
      }

      if (response.status === 'SUCCESS') {
        onReload();
      }
    } catch (e) {}

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent={'space-between'}
      alignItems={'center'}
      spacing={2}
      sx={{ mr: { xs: 0, md: 3 } }}
    >
      <Stack direction={{ xs: 'column', md: 'row' }} alignItems='center'>
        <ComplexToolbar onSearch={onSearch} defaultFilter='search' />

        <Stack direction={{ xs: 'column', md: 'row' }} alignItems='center' spacing={2}>
          <Autocomplete
            disablePortal
            options={networkList}
            sx={{
              width: 180,
              '& .MuiFormLabel-root': {
                color: 'grey.300',
              },
            }}
            renderInput={(params) => <TextField {...params} label={t('Network Name')} />}
            size='small'
            value={selectedNetwork}
            onChange={(e, newValue) => setSelectedNetwork(newValue)}
          />

          <TextField
            select
            size='small'
            label={t('Network Status')}
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            sx={{
              width: 180,
              '& .MuiFormLabel-root': {
                color: 'grey.300',
              },
            }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                },
              },
            }}
          >
            <MenuItem value='all'>{t('All')}</MenuItem>
            <MenuItem value='active'>{t('Active')}</MenuItem>
            <MenuItem value='inactive'>{t('Inactive')}</MenuItem>
          </TextField>
        </Stack>
      </Stack>

      {isAllActive !== null && (
        <Stack direction='row' alignItems='center' spacing={2}>
          {loading ? (
            <CircularProgress size={20} sx={{ color: 'grey.0' }} />
          ) : (
            <Typography variant='body2' color='grey.100'>
              {selectedNetwork ? `${t('Networks Status')} (${selectedNetwork})` : t('All Networks Status')}
            </Typography>
          )}
          <CustomSwitch checked={isAllActive} name='activation' onClick={handleSwitch} />
        </Stack>
      )}
    </Stack>
  );
}
