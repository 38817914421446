import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import UserApi from '../../../../http/serviceApi';
import AdminApi from '../../../../http/adminHttp/serviceApiAdmin';
import OptInput from '../../../../components/OptInput';
import useTimer from '../../../../hooks/useTimer';

const OtpMobileForm = ({ mobiles, onFinished, isAdmin = true }) => {
  const userInfo = useSelector((store) => store.userInfo);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const type = userInfo?.mobileNumber ? 'CHANGE' : 'ADD';

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newSmsOtp, setNewMobileOtp] = useState('');
  const [oldSmsOtp, setMobileOtp] = useState('');
  console.log(mobiles);
  const { onRequestAgainClick, timer, stopTimer, activeTimerState } = useTimer({
    isAdmin,
    mobiles: mobiles,
    type,
    operationType: 'ChangeMobile',
  });

  const onSubmit = async () => {
    if (!newSmsOtp) {
      return enqueueSnackbar(t('Enter the new mobile verification code!'), { variant: 'error' });
    }
    if (type === 'CHANGE' && !oldSmsOtp) {
      return enqueueSnackbar(t('Enter the old mobile verification code!'), { variant: 'error' });
    }

    setIsSubmitting(true);
    try {
      let response;
      if (isAdmin) {
        response = await AdminApi.changeOrAddMobile(newSmsOtp, oldSmsOtp, type);
      } else {
        response = await UserApi.changeOrAddMobile(newSmsOtp, oldSmsOtp, type);
      }

      if (response?.status === 'SUCCESS') {
        stopTimer();
        onFinished(response);
      }
    } catch (e) {}

    setIsSubmitting(false);
  };

  return (
    <>
      <Typography variant='body3_secondary' color={'grey.100'} component='p' sx={{ mb: 5 }}>
        {type === 'CHANGE'
          ? t('Enter the codes that was sent to your old mobile number and new mobile number in the fields below')
          : t('Enter the code that was sent to your mobile number in the field below')}
      </Typography>

      {type === 'CHANGE' && (
        <OptInput
          code={oldSmsOtp}
          setCode={setMobileOtp}
          label={t('Old Mobile verification code')}
          description={t('Enter the {{number}}-digit code send to {{mobile}}', {
            mobile: mobiles?.oldMobile || '',
            number: 5,
          })}
        />
      )}

      <OptInput
        code={newSmsOtp}
        setCode={setNewMobileOtp}
        label={t('New Mobile verification code')}
        description={t('Enter the {{number}}-digit code send to {{mobile}}', {
          mobile: mobiles?.newMobile || '',
          number: 5,
        })}
      />

      <Stack sx={{ mt: 5 }}>
        <Typography variant='body3' sx={{ letterSpacing: 1 }}>
          {timer}
        </Typography>
        <Stack direction='row' alignItems={'center'}>
          <Typography variant='body3_secondary' color='grey.400'>
            {t('Didn’t recieve code?')}
          </Typography>
          <Button
            color='warning'
            sx={{ typography: 'body3_secondary', borderRadius: 1, px: 1 }}
            onClick={onRequestAgainClick}
            size='small'
            disabled={!activeTimerState}
          >
            {t('Request Again')}
          </Button>
        </Stack>
      </Stack>

      <Box sx={{ width: 320 }}>
        <LoadingButton
          fullWidth
          size='large'
          type='button'
          variant='contained'
          loading={isSubmitting}
          onClick={() => onSubmit(null)}
          sx={{ mt: 2.5, typography: 'body3' }}
        >
          {type === 'CHANGE' ? t('Change Mobile') : t('Add Mobile')}
        </LoadingButton>
      </Box>
    </>
  );
};

export default OtpMobileForm;
