import { Button, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';

import SmallAreaChart from '../../../components/SmallAreaChart';
import SymbolIcon from '../../../components/SymbolIcon';
import { navigationUrl } from '../../../navigationUrl';
import { fCurrency, fPercent, fShortenNumber } from '../../../utils/formatNumber';
import Iconify from '../../../components/Iconify';
import Api from '../../../http/serviceApi';
import confirmWrapper from '../../../utils/confirm';

export default function MarketTableRow({ row }) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // -----------------
  const { symbol, name, price, priceChange, volume, marketCap, prices, isFav } = row;

  // -----------------
  const [isFavState, setIsFavState] = useState(isFav);
  useEffect(() => {
    setIsFavState(isFav);
  }, [isFav]);

  // -----------------
  const onAddDeleteFavorite = async () => {
    let response = {};

    if (!isFavState) {
      setIsFavState((prev) => !prev);
      response = await Api.addUserFavoriteWallet(symbol);
    } else {
      if (!(await confirmWrapper(t('Are you sure to remove this symbol from your favorites list?')))) {
        return false;
      }

      setIsFavState((prev) => !prev);
      response = await Api.deleteUserFavoriteWallet(symbol);
    }

    if (response?.status === 'SUCCESS') {
      enqueueSnackbar(
        !isFavState
          ? t('{{symbol}} added to favorites successfully.', { symbol })
          : t('{{symbol}} deleted from your favorites.', { symbol }),
        { variant: 'success' }
      );
    } else if (response?.status === 'FAILURE') {
      enqueueSnackbar(response.message, { variant: 'error' });
    }

    return true;
  };

  // -----------------
  return (
    <TableRow hover>
      <TableCell align='center'>
        <IconButton onClick={onAddDeleteFavorite}>
          <Iconify
            icon='eva:star-outline'
            sx={{ width: 20, height: 20, ...(isFavState && { color: 'warningButton' }) }}
          />
        </IconButton>
      </TableCell>

      <TableCell>
        <Stack direction='row' spacing={2} alignItems='center' sx={{ py: 2.5 }}>
          <SymbolIcon symbol={symbol} sx={{ width: 24, height: 24 }} />

          <Stack direction={'row'}>
            <Typography sx={{ width: 65 }} variant='subtitle2'>
              {symbol}
            </Typography>
            <Typography variant='body2' noWrap color='grey.300'>
              {name}
            </Typography>
          </Stack>
        </Stack>
      </TableCell>

      <TableCell>
        <Typography variant='body2' color={priceChange >= 0 ? '#30E0A1' : '#FA2256'}>
          {fCurrency(price)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant='body2' color={priceChange >= 0 ? '#30E0A1' : '#FA2256'}>
          {fPercent(priceChange >= 0 ? priceChange : -1 * priceChange)}
        </Typography>
      </TableCell>

      <TableCell>{fShortenNumber(volume).toUpperCase()}</TableCell>

      <TableCell>$ {fShortenNumber(marketCap).toUpperCase()}</TableCell>

      <TableCell>
        <SmallAreaChart data={prices} isPositive={priceChange >= 0} width={172} height={28} />
      </TableCell>

      <TableCell>
        <Button
          color='warning'
          onClick={() =>
            navigate(navigationUrl.trade, {
              state: {
                passedFromWallet: {
                  // symbol: tradingPair,
                  symbol: `${symbol}USDT`,
                  baseAsset: symbol,
                  quoteAsset: 'USDT',
                },
              },
            })
          }
        >
          {t('Trade')}
        </Button>
      </TableCell>

      <TableCell>
        <Button color='warning' onClick={() => navigate(`${navigationUrl.otc}?symbol=${symbol}`)}>
          {t('Buy')}
        </Button>
      </TableCell>
    </TableRow>
  );
}
