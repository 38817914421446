import React from 'react';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import { RHFTextField } from '../../../../components/hookForm';
import useVerifyViaEmail from './hooks/useVerifyViaEmail';

const VerificationViaEmail = ({ isSubmitting }) => {
  const { t } = useTranslation();

  const { isSending, handleSendingEmail } = useVerifyViaEmail();

  return (
    <Stack sx={{ mt: 2.5, mb: 5 }}>
      <Typography variant='body3' color={'grey.300'}>
        {t(
          'To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.'
        )}
      </Typography>

      <LoadingButton
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        loading={isSending}
        sx={{ maxWidth: 150, mt: 5 }}
        onClick={handleSendingEmail}
      >
        {t('Send Email')}
      </LoadingButton>

      <Stack width={'50%'} spacing={2.25} direction={'row'} sx={{ mt: 5 }}>
        <RHFTextField name={'emailCode'} label={t('Email Code')} variant='standard' fullWidth />
        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ maxWidth: 150 }}
        >
          {t('Activation')}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default VerificationViaEmail;
