import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export default function useTransactionForm(selectedSymbol) {
  const { t } = useTranslation();

  const validators = Yup.object().shape({
    type: Yup.string().required(),
    amount: Yup.string().required(t('Please fill the blank')),
    symbol: Yup.string().required(t('Please select one')),
    caption: Yup.string().required(t('Please fill the blank')),
  });

  const defaultValues = {
    type: 'Deposit',
    amount: '',
    symbol: selectedSymbol?.symbol || '',
    caption: '',
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset,
  } = methods;

  useEffect(() => {
    if (selectedSymbol) {
      reset(defaultValues);
    }
    if (!selectedSymbol) {
      reset(defaultValues);
    }
  }, [selectedSymbol]);

  return {
    handleSubmit,
    isSubmitting,
    methods,
    type: watch('type'),
    amount: watch('amount'),
  };
}
