import Slider from 'react-slick';
import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, CardHeader, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Iconify from '../../../components/Iconify';
import CarouselArrows from '../../../components/carousel/CarouselArrows';
import Image from '../../../components/Image';

import tether from '../../../assets/logo/USDT.svg';
import perfectMoney from '../../../assets/logo/PM.svg';
import paySafe from '../../../assets/logo/paysef.svg';
import Api from '../../../http/adminHttp/serviceApiAdmin';

// ----------------------------------------------------------------------

export default function CurrencySlider({ title, subheader, ...other }) {
  const theme = useTheme();

  // --------------------
  const carouselRef = useRef(null);

  // --------------------
  const settings = {
    speed: 1000,
    autoplaySpeed: 5000,
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    adaptiveHeight: true,
    autoplay: true,
    rtl: Boolean(theme.direction === 'rtl'),
  };

  // --------------------
  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  // --------------------
  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  // --------------------
  const [list, setList] = useState([]);

  // --------------------
  const getData = async () => {
    try {
      const response = await Api.getEMoneyStatistics();

      setList([
        {
          name: 'Tether total value',
          total: response?.tetherTotalAmount || 0,
          buy: response?.tetherBuyAmount || 0,
          sell: response?.tetherSellAmount || 0,
          icon: tether,
        },
        {
          name: 'Perfect Money total value',
          total: response?.perfectMoneyTotalAmount || 0,
          buy: response?.perfectMoneyBuyAmount || 0,
          sell: response?.perfectMoneySellAmount || 0,
          icon: perfectMoney,
        },
        {
          name: 'Paysafe total value',
          total: response?.paysafeTotalAmount || 0,
          buy: response?.paysafeBuyAmount || 0,
          sell: response?.paysafeSellAmount || 0,
          icon: paySafe,
        },
      ]);
    } catch (e) {}
  };

  // ---------------------
  useEffect(() => {
    getData();
  }, []);

  // ---------------------
  return (
    <Stack
      {...other}
      sx={{
        '& .MuiCardHeader-root': {
          padding: 0,
        },
      }}
    >
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <CarouselArrows
            customIcon={'ic:round-keyboard-arrow-right'}
            onNext={handleNext}
            onPrevious={handlePrevious}
            sx={{ '& .arrow': { width: 28, height: 28, p: 0 } }}
          />
        }
        sx={{
          '& .MuiCardHeader-action': {
            alignSelf: 'center',
          },
        }}
      />

      <Slider ref={carouselRef} {...settings}>
        {list.map((item, index) => (
          <ReviewItem key={index} item={item} />
        ))}
      </Slider>
    </Stack>
  );
}

// ----------------------------------------------------------------------

function ReviewItem({ item }) {
  const { name, total, buy, sell, icon } = item;
  const { t } = useTranslation();

  return (
    <>
      <Stack sx={{ mt: 3, mb: 4 }} spacing={2} alignItems='center'>
        <Image src={icon} sx={{ width: 95, height: 95 }} />
        <Typography variant='body2'>{t(name)}</Typography>
        <Typography>{total}</Typography>
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          borderRadius: 1.5,
          backgroundColor: 'rgba(250, 34, 86, 0.1)',
          border: '1px solid rgba(250, 34, 86, 0.5)',
          height: 50,
          p: 2.5,
          mb: 1.8,
          mx: 1,
        }}
      >
        <Stack direction={'row'} spacing={2} justifyContent={'center'}>
          <Iconify icon={'ph:arrow-down-fill'} color={'error.darker'} height={20} />
          <Typography variant='body2'>{t('Buy')}</Typography>
        </Stack>
        <Typography>{buy}</Typography>
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          borderRadius: 1.5,
          backgroundColor: 'rgba(48, 224, 161, 0.05)',
          border: '1px solid rgba(48, 224, 161, 0.5)',
          height: 50,
          p: 2.5,
          mx: 1,
        }}
      >
        <Stack direction={'row'} spacing={2} justifyContent={'center'}>
          <Iconify icon={'ph:arrow-up-fill'} color={'positive.main'} height={20} />
          <Typography variant='body2'>{t('Sales')}</Typography>
        </Stack>
        <Typography>{sell}</Typography>
      </Stack>
    </>
  );
}
