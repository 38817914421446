import { useEffect } from 'react';
import { useState } from 'react';
import useQuery from '../../../../hooks/useQuery';
import useWallet from '../../../../hooks/useWallet';
import Api from '../../../../http/serviceApi';

export default function useConvertForm() {
  const query = useQuery();

  // -------------------------
  const { userWallet } = useWallet();

  // -------------------------
  const [fromAmount, setFromAmount] = useState('');
  const [toAmount, setToAmount] = useState('');
  const [fromSymbol, setFromSymbol] = useState(query.get('symbol') || 'USDT');
  const [toSymbol, setToSymbol] = useState('');
  const [symbolSelectedWallet, setSymbolSelectedWallet] = useState(null);
  const [convertPairs, setConvertPairs] = useState([]);
  const [selectedConvertPair, setSelectedConvertPair] = useState(null);

  // -------------------------
  useEffect(() => {
    if (fromSymbol) {
      const found = userWallet.walletInfos.find((w) => w.symbol === fromSymbol);
      setSymbolSelectedWallet(found);
    }
  }, [userWallet, fromSymbol]);

  const onMax = () => {
    setFromAmount(symbolSelectedWallet?.available || 0);
  };

  // -------------------------
  const onSwap = () => {
    const tempAmount = fromAmount;
    setFromAmount(toAmount);
    setToAmount(tempAmount);

    const tempSymbol = fromSymbol;
    setFromSymbol(toSymbol);
    setToSymbol(tempSymbol);
  };

  // -------------------------
  const getConvertPairs = async () => {
    setConvertPairs([]);
    try {
      const data = await Api.getConvertPairs(fromSymbol);

      if (data.status === 'SUCCESS') {
        setConvertPairs(data.convertPairs);

        if (!data.convertPairs.find((p) => p.toAsset === toSymbol)) {
          setToSymbol(data.convertPairs?.[0]?.toAsset || 'USDT');
        }
      }
    } catch (e) {}
  };

  // -------------------------
  useEffect(() => {
    if (fromSymbol) {
      getConvertPairs();
    }
  }, [fromSymbol]);

  // -------------------------
  useEffect(() => {
    if (toSymbol && convertPairs?.length > 0) {
      const found = convertPairs.find((p) => p.toAsset === toSymbol);
      if (found) setSelectedConvertPair(found);
    }
  }, [toSymbol, convertPairs]);

  // -------------------------
  return {
    fromAmount,
    setFromAmount,
    toAmount,
    setToAmount,
    fromSymbol,
    setFromSymbol,
    toSymbol,
    setToSymbol,
    symbolSelectedWallet,
    convertPairs,
    selectedConvertPair,
    onMax,
    onSwap,
  };
}
