export const checkStrongPassword = (value) => {
  let fillCount = 0;
  const str = value?.toString();
  const matched1 = str.match(/.*[0-9].*/);
  if (matched1?.length > 0) fillCount++;
  const matched2 = str.match(/.*[a-z].*/);
  if (matched2?.length > 0) fillCount++;
  const matched3 = str.match(/.*[A-Z].*/);
  if (matched3?.length > 0) fillCount++;
  const matched4 = str.match(/^.{8,}$/);
  if (matched4?.length > 0) fillCount++;
  const matched5 = str.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
  if (matched5?.length > 0) fillCount++;

  return fillCount;
};

export const throttled = (fn, delay = 1500) => {
  let lastCall = 0;
  return (...args) => {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return null;
    }
    lastCall = now;
    return fn(...args);
  };
};

export const debounced = (fn, delay = 300) => {
  let timerId;
  return (...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
};

export const applySortFilter = ({ tableData, comparator }) => {
  if (tableData === null) return tableData;

  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  return tableData;
};

export const base64ToImage = (base64) => `data:image/jpeg;base64,${base64}`;

export const imageToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () =>
      resolve(
        reader.result
          .replace('data:image/jpeg;base64,', '')
          .replace('data:image/png;base64,', '')
          .replace('data:image/jpg;base64,', '')
      );

    reader.onerror = (error) => reject(error);
  });

export const applyObjectToQueryString = (object) =>
  Object.keys(object)
    .map((key) => {
      return `${key}=${encodeURIComponent(object[key])}`;
    })
    .join('&');

export const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const hideLoading = () => {
  document.getElementById('loading').style.display = 'none';
};

export const showLoading = (delay = 0) => {
  document.getElementById('loading').style.display = 'block';

  if (delay > 0) {
    setTimeout(() => {
      hideLoading();
    }, delay);
  }
};

export const prettyUppercases = (value) => {
  if (!value) return '';
  const level = value.replace(/_/g, ' ').toLowerCase();
  return level.charAt(0).toUpperCase() + level.slice(1);
};

export const sum = (numbers) => numbers.reduce((acc, num) => acc + num, 0);

export const openBase64 = (src) => {
  const image = new Image();
  image.src = src;
  const w = window.open('');
  w.document.write(image.outerHTML);
};

export const hasHtmlTags = (input) => {
  const htmlTagsRegex = /<[a-z][\s\S]*>/i;
  return htmlTagsRegex.test(input);
};
