import { Box, Card, Link, ListItem, Stack, Toolbar } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { m } from 'framer-motion';

export const RootStyle = styled(m.div)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
  },
}));

export const BackgroundShadow = styled(Box)({
  position: 'absolute',
  top: 0,
  zIndex: 9,
  left: '50%',
  transform: 'translateX(-50%)',
  width: 228,
  height: 114,
  background: 'radial-gradient(50% 50% at 50% 50%, rgba(87, 174, 254, 0.64) 0%, rgba(87, 254, 134, 0.16) 100%)',
  opacity: 0.6,
  filter: 'blur(62px)',
});

export const BannerContentStyle = styled((props) => <Stack spacing={2} {...props} />)(({ theme }) => ({
  zIndex: 10,
  maxWidth: 665,
  margin: 'auto',
  textAlign: 'center',
  position: 'relative',
  paddingTop: theme.spacing(15),
  [theme.breakpoints.up('md')]: {
    margin: 'unset',
    textAlign: 'left',
    paddingBottom: theme.spacing(15),
  },
}));

export const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

export const HeroOverlayStyle = styled(m.img)({
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

export const HeroImgStyle = styled(m.img)(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 9,
  width: '100%',
  margin: 'auto',
  position: 'absolute',
  display: 'none',
  [theme.breakpoints.up('md')]: {
    right: '8%',
    width: 'auto',
    height: '80vh',
    display: 'block',
  },
}));

export const RootSubStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(8.5),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(8.5),
  },
}));

export const CardStyle = styled(Card)(({ theme }) => {
  const shadowCard = (opacity) =>
    theme.palette.mode === 'light'
      ? alpha(theme.palette.grey[500], opacity)
      : alpha(theme.palette.common.black, opacity);

  return {
    border: 0,
    maxWidth: 380,
    minHeight: 440,
    margin: 'auto',
    textAlign: 'center',
    padding: theme.spacing(10, 5, 0),
    boxShadow: theme.customShadows.z12,
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    '&.cardLeft': {
      [theme.breakpoints.up('md')]: { marginTop: -40 },
    },
    '&.cardCenter': {
      [theme.breakpoints.up('md')]: {
        marginTop: -80,
        backgroundColor: theme.palette.background.paper,
        boxShadow: `-40px 40px 80px 0 ${shadowCard(0.4)}`,
        '&:before': {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          content: "''",
          margin: 'auto',
          position: 'absolute',
          width: 'calc(100% - 40px)',
          height: 'calc(100% - 40px)',
          borderRadius: Number(theme.shape.borderRadius) * 2,
          backgroundColor: theme.palette.background.paper,
          boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`,
        },
      },
    },
  };
});

export const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: 64,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: 88,
  },
}));

export const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

export const TopbarDivider = styled('div')(() => ({
  height: 1,
  opacity: 0.4,
  background:
    'linear-gradient(90deg, rgba(255, 255, 255, 0) -2.03%, #FFFFFF 50.31%, rgba(255, 255, 255, 0.05) 102.66%)',
}));

export const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle3,
  color: theme.palette.common.white,
  marginRight: theme.spacing(4),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': {
    opacity: 0.8,
    textDecoration: 'none',
  },
}));

export const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...theme.typography.body2,
  padding: 0,
  marginTop: theme.spacing(3),
  color: theme.palette.common.white,
  transition: theme.transitions.create('color'),
  '&:hover': {
    color: theme.palette.common.white,
    opacity: 0.7,
  },
}));

export const TopAssetsCard = styled(m.div)(({ theme }) => ({
  background: theme.palette.grey[700],
  backdropFilter: 'blur(12px)',
  borderRadius: '12px',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(5.5),
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(0, 4, 3),
  },
}));

export const PlatformBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  background: alpha(theme.palette.grey[800], 0.5),
  borderRadius: '10px',
  width: 300,
  height: 234,
  padding: '64px 32px 0',
  margin: 'auto',
}));

export const PlatformIcon = styled(Box)({ position: 'absolute', top: -25, left: 32, zIndex: 2 });

export const PlatformShadow = styled(Box)(({ color }) => ({
  background: color,
  opacity: 0.9,
  filter: 'blur(55px)',
  borderRadius: 40,
  width: 54,
  height: 54,
}));

export const PlatformBorder = styled(Box)({
  position: 'absolute',
  border: '2px solid rgba(93, 101, 136, 0.4)',
  width: '100%',
  height: '100%',
  borderRadius: '10px',
  left: -10,
  top: -10,
  zIndex: 1,
});

export const PayoutBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 283,
  height: 366,
  margin: 'auto',
}));

export const PayoutContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  background: theme.palette.grey[900],
  borderRadius: '6px',
  padding: theme.spacing(2.5),
  zIndex: 2,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
}));

export const PayoutBorder = styled(Box)({
  position: 'absolute',
  border: '1px solid #34384C',
  width: '100%',
  height: '100%',
  borderRadius: '6px',
  transform: 'rotate(175deg)',
  top: 0,
  left: 0,
  zIndex: 1,
});

export const PayoutLogoWrapper = styled(Stack)({ position: 'relative', width: 75, height: 75 });

export const PayoutLogoShadow = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'absolute',
  background: 'rgba(48, 224, 161, 0.2)',
  opacity: '0.3',
  border: '2px solid rgba(48, 224, 161, 0.5)',
  boxShadow: '0px 0px 22px rgba(48, 224, 161, 0.5)',
  borderRadius: '37.5px',
});

export const PayoutShadow = styled(Box)({
  position: 'absolute',
  width: 634,
  height: 634,
  left: 0,
  top: 0,
  background: 'radial-gradient(50% 50% at 50% 50%, rgba(87, 174, 254, 0.24) 0%, rgba(87, 174, 254, 0.06) 100%)',
  opacity: 0.3,
  filter: 'blur(62px)',
});

export const PayoutShadowSmall = styled(Box)({
  position: 'absolute',
  width: 500,
  height: 500,
  right: 0,
  top: -250,
  background: 'radial-gradient(50% 50% at 50% 50%, rgba(87, 94, 254, 0.24) 0%, rgba(87, 93, 254, 0.06) 100%)',
  opacity: 0.3,
  filter: 'blur(62px)',
});

export const PayoutCurveShape = styled('img')(({ position = 'left', theme }) => ({
  position: 'absolute',
  width: 224,
  height: 264,
  ...(position === 'left'
    ? { left: 0, top: -110, transform: theme.direction === 'rtl' ? 'scaleX(-1)' : 'scaleX(1)' }
    : { right: 0, top: -150, transform: theme.direction === 'rtl' ? 'scaleX(1)' : 'scaleX(-1)' }),
}));

export const CircleWrapper = styled(Stack)(({ size }) => ({
  position: 'relative',
  width: size === 'small' ? 210 : 340,
  height: size === 'small' ? 210 : 340,
  border: '1px solid #34384C',
  borderRadius: '168px',
}));

export const CircleShadow = styled(Box)(({ size }) => ({
  position: 'absolute',
  width: size === 'small' ? 240 : 380,
  height: size === 'small' ? 240 : 380,
  left: 0,
  top: 0,
  background: 'radial-gradient(50% 50% at 50% 50%, rgba(87, 174, 254, 0.24) 0%, rgba(87, 174, 254, 0.06) 100%)',
  opacity: 0.3,
  filter: 'blur(62px)',
}));

export const CounterBack = styled(Box)(({ theme }) => ({
  position: 'absolute',
  height: 146,
  width: '100%',
  background: theme.palette.grey[700],
  opacity: 0.5,
  left: 0,
  top: '50%',
  transform: 'translateY(-50%)',
}));

export const TrustWrapper = styled(Stack)({ position: 'relative', width: 582, height: 582 });

export const TrustShadow = styled(Box)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'radial-gradient(50% 50% at 50% 50%, rgb(87 134 254 / 63%) 0%, rgba(24, 85, 239, 0.08) 100%)',
  opacity: '0.3',
  filter: 'blur(62px)',
  left: 0,
  top: 0,
});

export const PortfolioWrapper = styled(Stack)({ position: 'relative', width: 667, height: 667 });

export const PortfolioShadow = styled(Box)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'radial-gradient(50% 50% at 50% 50%, rgba(215, 216, 253, 0.24) 0%, rgba(150, 153, 235, 0.06) 100%)',
  opacity: '0.4',
  filter: 'blur(62px)',
  left: 0,
  top: 0,
});

export const StartedBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  background: alpha(theme.palette.grey[900], 0.8),
  borderRadius: '10px',
  width: '100%',
  height: 420,
  [theme.breakpoints.up('md')]: {
    height: 385,
  },
}));

export const StartedContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 2,
  padding: '30px 16px',
  [theme.breakpoints.up('md')]: {
    padding: '58px 98px',
  },
}));

export const StartedBorder = styled(Box)({
  position: 'absolute',
  transform: 'rotate(-1.6deg)',
  borderRadius: '5px',
  border: '1px solid #34384C',
  width: '100%',
  height: '100%',
  left: -8,
  top: -6,
  zIndex: 1,
});

export const StartedShadow = styled(Box)({
  position: 'absolute',
  width: 500,
  height: 500,
  left: 0,
  top: 0,
  background: 'radial-gradient(50% 50% at 50% 50%, rgba(251, 87, 254, 0.24) 0%, rgba(251, 87, 254, 0.06) 100%)',
  opacity: 0.3,
  filter: 'blur(62px)',
});
