import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';

import Image from './Image';
import Api from '../http/serviceApi';

import googleLogo from '../assets/social/google.svg';
import facebookLogo from '../assets/social/facebook.svg';
import appleLogo from '../assets/social/apple.svg';

export default function SocialLogin({ referralCode = '' }) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(null);

  const onClick = (provider) => {
    setLoading(provider);
    window.location.replace(Api.oauthLogin(provider, referralCode));
  };

  return (
    <Stack direction='row' sx={{ mt: 5 }} spacing={1}>
      <LoadingButton
        variant='contained'
        color='inherit'
        sx={{
          backgroundColor: theme.palette.mode === 'dark' ? 'grey.0' : 'grey.700',
          color: theme.palette.mode === 'dark' ? '#525252' : '#000',
          typography: 'body3',
          borderRadius: 1.5,
          px: '12px',
          width: '33%',
          '&:hover': { backgroundColor: '#dadada' },
        }}
        startIcon={<Image src={googleLogo} sx={{ width: 20, height: 20 }} />}
        onClick={() => onClick('GOOGLE')}
        loading={loading === 'GOOGLE'}
      >
        {t('Google')}
      </LoadingButton>

      <LoadingButton
        variant='contained'
        color='inherit'
        sx={{
          backgroundColor: '#1877F2',
          color: '#fff',
          typography: 'body3',
          borderRadius: 1.5,
          px: '12px',
          width: '33%',
          '&:hover': { backgroundColor: '#0d5bc2' },
        }}
        startIcon={<Image src={facebookLogo} sx={{ width: 20, height: 20 }} />}
        onClick={() => onClick('FACEBOOK')}
        loading={loading === 'FACEBOOK'}
      >
        {t('Facebook')}
      </LoadingButton>

      <LoadingButton
        variant='contained'
        color='inherit'
        sx={{
          backgroundColor: '#525252',
          color: theme.palette.mode === 'dark' ? 'grey.0' : '#fff',
          typography: 'body3',
          borderRadius: 1.5,
          px: '12px',
          width: '33%',
          '&:hover': { backgroundColor: '#3b3b3b' },
        }}
        startIcon={<Image src={appleLogo} sx={{ width: 16, height: 20 }} />}
        onClick={() => onClick('APPLE')}
        loading={loading === 'APPLE'}
      >
        {t('Apple')}
      </LoadingButton>
    </Stack>
  );
}
