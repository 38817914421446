import { Button, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import moment from 'jalali-moment';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { LoadingButton } from '@mui/lab';

import { FormProvider, RHFDateTimePicker, RHFNumberField, RHFTextField } from '../../../components/hookForm';
import { navigationUrl } from '../../../navigationUrl';
import Iconify from '../../../components/Iconify';
import Api from '../../../http/serviceApi';
import { imageToBase64 } from '../../../utils';
import { useState } from 'react';
import { useEffect } from 'react';
import { TRANSFER_METHODS } from '../../../constants/transferMethods';

export default function DepositReceiptForm({ selectedCurrency, bankCardList }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // ------------------------------------
  const [image, setImage] = useState();
  const [active , setActive] = useState(false)
  // ------------------------------------
  const validators = Yup.object().shape({
    amount: Yup.string().required(t('Please input the value!')),
    account: Yup.string().required(t('Please select a bank card!')),
    trackingCode: Yup.string().required(t('Please input the tracking code!')),
  });

  // ------------------------------------
  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues: {
      account: '',
      amount: '',
      trackingCode: '',
      date: null,
      transferMethod: 'CARD_TO_CARD',
      depositAccountNumber: '',
    },
  });

  // ------------------------------------
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  // ------------------------------------
  const onSubmit = async (payload) => {
    try {
      const response = await Api.receiptDepositFiat({
        ...payload,
        account: payload.account.replace(/\s/g, ''),
        amount: +payload.amount.replace(',', ''),
        date: moment(payload.date).startOf('days').valueOf(),
        fiatType: selectedCurrency,
        image: image ? await imageToBase64(image) : '',
      });

      if (response?.status === 'SUCCESS') {
        enqueueSnackbar(t('Deposit request successfully submitted'), { variant: 'success' });
        reset();
        setImage(null);
        document.getElementById('imageField').value = '';
      }
    } catch (e) {
      console.log(e);
    }
  };

  // ------------------------------------
  const onImage = (e) => {
    setImage(e.target.files[0]);
  };

  const [exchangeAccounts, setExchangeAccounts] = useState([]);
  const getExchangeAccounts = async () => {
    try {
      const response = await Api.exchangeAccountForDeposit();
      if (response) {
        setExchangeAccounts(response.depositReceiptBankAccounts);
        setActive(response?.active)
      }
    } catch (e) {}
  };

  useEffect(() => {
    getExchangeAccounts();
  }, []);

  const [transferMethod, setTransferMethod] = useState('CARD_TO_CARD');
  methods.watch(({ transferMethod }) => {
    setTransferMethod(transferMethod);
  });

  // ------------------------------------
  return (
    !active  ?
      <Typography variant='body3' color='secondary'>
        {t('This method is unavailable', { level: 5 })}
      </Typography>

      :
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant='body3' color='secondary'>
        {t('Only for level {{level}} it is possible to deposit with a receipt.', { level: 5 })}
      </Typography>

      <Stack spacing={2.5} sx={{ mt: 5 }}>
        <RHFNumberField
          name='amount'
          label='Value'
          fullWidth
          variant='standard'
          thousandSeparator
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Typography color='grey.100' variant='body2'>
                  {selectedCurrency}
                </Typography>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name='account'
          label={t('Payer')}
          select
          fullWidth
          variant='standard'
          helperText={t('You will see a list of your approved bank accounts')}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' } },
            },
          }}
        >
          {bankCardList
            .filter((card) => {
              if (selectedCurrency === 'USD') return card.cardType !== 'SHETAB_CARD';
              return card.cardType === 'SHETAB_CARD';
            })
            .map((card, index) => (
              <MenuItem key={index} value={card.cardNumber}>
                <Typography variant='body2'>
                  {card.cardNumber
                    .replace(/[^\dA-Z]/g, '')
                    .replace(/(.{4})/g, '$1 ')
                    .trim()}
                </Typography>
              </MenuItem>
            ))}
        </RHFTextField>

        <RHFTextField
          name='trackingCode'
          label={t('Tracking Code')}
          fullWidth
          variant='standard'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Iconify icon={'ph:command'} sx={{ width: 30, height: 30, color: 'grey.100', mb: 2, mr: 0.5 }} />
              </InputAdornment>
            ),
          }}
        />

        <RHFDateTimePicker
          variant='standard'
          name='date'
          label={t('Date and time of transaction')}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Iconify icon={'akar-icons:calendar'} sx={{ width: 23, height: 23, color: 'grey.100', mb: 2, mr: 1 }} />
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name='transferMethod'
          select
          variant='standard'
          label={t('Transfer method')}
          sx={{ '& svg': { mr: 1.5, color: 'grey.100' } }}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' } },
            },
          }}
        >
          {TRANSFER_METHODS.map((type, index) => (
            <MenuItem value={type.value} key={index}>
              <Typography typography='body3' color='grey.300'>
                {t(type.label)}
              </Typography>
            </MenuItem>
          ))}
        </RHFTextField>

        {transferMethod === 'DEPOSIT_TO_ACCOUNT' ? (
          <RHFTextField
            name='depositAccountNumber'
            select
            variant='standard'
            label={t('Deposit account number')}
            sx={{ '& svg': { mr: 1.5, color: 'grey.100' } }}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' } },
              },
            }}
          >
            {exchangeAccounts?.map((type, index) => (
              <MenuItem value={type.accountNumber} key={index}>
                <Typography typography='body3' color='grey.300'>
                  {`${type.bankName} ${type.accountNumber}`}
                </Typography>
              </MenuItem>
            ))}
          </RHFTextField>
        ) : transferMethod === 'CARD_TO_CARD' ? (
          <RHFTextField
            name='depositAccountNumber'
            select
            variant='standard'
            label={t('deposit card number')}
            sx={{ '& svg': { mr: 1.5, color: 'grey.100' } }}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' } },
              },
            }}
          >
            {exchangeAccounts?.map((type, index) => {
              const typeCurrency = type?.accountType === 'RIAL' ? "IRR" : type?.accountType


              return typeCurrency === selectedCurrency ?  <MenuItem value={type.cardNumber} key={index}>
                <Typography typography='body3' color='grey.300'>
                  {`${type.bankName} ${type.cardNumber}`}
                </Typography>
              </MenuItem> : null
            }

             )}
          </RHFTextField>
        ) : (
          <RHFTextField
            name='depositAccountNumber'
            select
            variant='standard'
            label={t('deposit sheba number')}
            sx={{ '& svg': { mr: 1.5, color: 'grey.100' } }}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' } },
              },
            }}
          >
            {exchangeAccounts?.map((type, index) => (
              <MenuItem value={type.shebaNumber} key={index}>
                <Typography typography='body3' color='grey.300'>
                  {`${type.bankName} ${type.shebaNumber}`}
                </Typography>
              </MenuItem>
            ))}
          </RHFTextField>
        )}

        <TextField
          onChange={onImage}
          type='file'
          id='imageField'
          fullWidth
          helperText={t('Optional and to speed up transaction confirmation time')}
          label={t('Receipt image (optional)')}
          InputLabelProps={{ shrink: true }}
          sx={{
            '& input': { cursor: 'pointer' },
            '& input::-webkit-file-upload-button': { display: 'none' },
            '& input::file-selector-button': { display: 'none' },
          }}
          variant='standard'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Iconify icon={'uis:upload-alt'} sx={{ width: 25, height: 25, color: 'grey.100', mb: 2, mr: 1 }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction='row' sx={{ mt: 6.25 }} spacing={2}>
        <LoadingButton
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ width: '60%', typography: 'body3' }}
        >
          {t('Save')}
        </LoadingButton>

        <Button
          onClick={() => navigate(navigationUrl.wallet)}
          variant='outlined'
          color='inherit'
          sx={{ width: '40%', typography: 'body3' }}
        >
          {t('Back')}
        </Button>
      </Stack>
    </FormProvider>
  );
}
