import React from "react";
import { Result } from "antd";
import { NavLink } from "react-router-dom";

const InternetDisconnected = () => {
    return (
        <div>
            <Result
                status="500"
                title="Internet Connection Error"
                subTitle="Internet Disconnected."
                extra={
                    <></>
                }
            />
        </div>
    )
}

export default InternetDisconnected;