import { alpha, Stack, Typography } from '@mui/material';

import Image from '../../../components/Image';

import referralIcon from '../../../assets/referral.svg';
import transactionIcon from '../../../assets/order.svg';
import incomeIcon from '../../../assets/income.svg';

export default function ReferralsStatistic({ referral, transactions, income }) {
  const statistic = [
    { value: referral, label: 'Number of referrals', image: referralIcon, color: '#72DC60' },
    { value: transactions, label: 'Number of transactions', image: transactionIcon, color: '#BD47FB' },
    { value: income, label: 'Your total income is based on USD', image: incomeIcon, color: '#F27F16' },
  ];

  return (
    <Stack spacing={2}>
      {statistic?.map((item, index) => (
        <Stack
          key={index}
          direction={'row'}
          sx={{ backgroundColor: 'grey.800', borderRadius: 1.5, p: 2.2 }}
          spacing={2}
        >
          <Stack
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ backgroundColor: alpha(item.color, 0.1), borderRadius: '100%', width: 48, height: 48 }}
          >
            <Image src={item.image} />
          </Stack>
          <Stack spacing={'0.25'}>
            <Typography variant='h6' fontWeight={500}>
              {item.value}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {item.label}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
