import PropTypes from 'prop-types';
// @mui
import { TableRow, TableCell, CircularProgress, Stack } from '@mui/material';

TableLoading.propTypes = {
  isLoading: PropTypes.bool,
};

export default function TableLoading({ isLoading }) {
  return (
    <TableRow>
      {isLoading && (
        <TableCell colSpan={12}>
          <Stack alignItems='center' sx={{ my: 4.5 }}>
            <CircularProgress size={30} color='success' />
          </Stack>
        </TableCell>
      )}
    </TableRow>
  );
}
