import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import UserApi from '../../../../http/serviceApi';
import AdminApi from '../../../../http/adminHttp/serviceApiAdmin';

import { FormProvider, RHFTextField } from '../../../../components/hookForm';

const ChangeEmailForm = ({ onSuccess, isAdmin = true }) => {
  const { t } = useTranslation();
  const userInfo = useSelector((store) => store.userInfo);

  const validators = Yup.object().shape({
    oldEmailAddress: Yup.string()
      .email(t('Please input a valid oldEmailAddress'))
      .required(t(t('Please input your oldEmailAddress!'))),
    newEmailAddress: Yup.string().email(t('Please input a valid email')).required(t('Please input your new email!')),
  });

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues: {
      oldEmailAddress: userInfo?.email || '',
      newEmailAddress: '',
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async ({ oldEmailAddress, newEmailAddress }) => {
    try {
      let response;
      if (isAdmin) {
        response = await AdminApi.preChangeEmail(oldEmailAddress, newEmailAddress);
      } else {
        response = await UserApi.preChangeEmail(oldEmailAddress, newEmailAddress);
      }

      if (response?.status === 'SUCCESS') {
        onSuccess({ oldEmailAddress, newEmailAddress });
      }
    } catch (e) {}
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ width: 320 }}>
        <RHFTextField name='oldEmailAddress' label={t('Email')} variant='standard' fullWidth disabled />

        <RHFTextField name='newEmailAddress' label={t('New Email')} variant='standard' fullWidth sx={{ mt: 2 }} />

        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ mt: 7.5, typography: 'body3' }}
        >
          {t('Change Email')}
        </LoadingButton>
      </Box>
    </FormProvider>
  );
};

export default ChangeEmailForm;
