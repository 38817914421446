import { Card, Box, Typography, Stack } from '@mui/material';
import React from 'react';

import Image from '../components/Image';

const BriefInfoCard = ({ data }) => {
  return (
    <Card sx={{ p: 2.5, width: '100%', height: 88, display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          width: 48,
          height: 48,
          borderRadius: '50%',
          backgroundColor: data.bgColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Image src={data.icon} sx={{ width: 24, height: 24 }} />
      </Box>
      <Stack sx={{ ml: 2 }}>
        <Typography variant='body1'>{data.value}</Typography>
        <Typography variant='body2'>{data.name}</Typography>
      </Stack>
    </Card>
  );
};

export default BriefInfoCard;
