import React from 'react';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import { RHFTextField } from '../../../../components/hookForm';
import useVerifyViaSms from './hooks/useVerifyViaSms';

const VerificationViaSms = ({ isSubmitting }) => {
  const { t } = useTranslation();

  const { isSending, handleSendingSms } = useVerifyViaSms();

  return (
    <Stack sx={{ mt: 2.5, mb: 5 }}>
      <Typography variant='body3' color={'grey.300'}>
        {t(
          'To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.'
        )}
      </Typography>

      <LoadingButton
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        loading={isSending}
        sx={{ maxWidth: 150, mt: 5 }}
        onClick={handleSendingSms}
      >
        {t('Send SMS')}
      </LoadingButton>

      <Stack width={'50%'} spacing={2.25} direction={'row'} sx={{ mt: 5 }}>
        <RHFTextField name={'smsCode'} label={t('SMS Code')} variant='standard' fullWidth />
        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ maxWidth: 150 }}
        >
          {t('Activation')}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default VerificationViaSms;
