import { Box, Card, CircularProgress, Stack, Typography, selectClasses } from '@mui/material';
import moment from 'jalali-moment';
import ReactApexChart from 'react-apexcharts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseOptionChart } from '../../../components/chart';
import mergeDeep from '../../../utils/merge';
import { PeriodButton } from './style';
import TimeChart from './timeChart';
import Api from '../../../http/adminHttp/serviceApiAdmin';

import fakeBar from '../../../assets/fake-bar.png';
import fakeGrid from '../../../assets/GridDark.png';

const COLORS = ['#F27F16', '#155eeb', '#69F216', '#9747FF', '#1BA27A', '#00EAFF', '#CA3211'];

const CHART_COUNTRY = [
  { label: 'Iran', value: 'Iran, Islamic Republic of' },
  { label: 'Armenia', value: 'Armenia' },
  { label: 'Russia', value: 'Russian Federation' },
  { label: 'United Arab Emirates', value: 'United Arab Emirates' },
  { label: 'Saudi Arabia', value: 'Saudi Arabia' },
];

export default function LineChart() {
  const { t } = useTranslation();

  // --------------------
  const [period, setPeriod] = useState('MONTH');
  const [countrySelected, setCountrySelected] = useState(CHART_COUNTRY[0].value);

  // --------------------
  const [chartData, setChartData] = useState(null);
  const [chartCountries, setChartCountries] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);

  // --------------------
  const getData = async () => {
    setChartData(null);
    setChartCountries([]);
    setChartLabels([]);
    setCountrySelected(CHART_COUNTRY[0].value);

    try {
      const response = await Api.getReportBuySellStatisticsByCountry(period);

      let countries = {};
      const labels = [];
      let hasData = false;
      Object.entries(response.reportBuySellChartByCountryInfo || {}).forEach(([time, { buys, sells }]) => {
        for (let i = 0; i < buys.length; i++) {
          const buy = buys[i];
          const sell = sells[i];

          countries[buy.countryName] = [
            ...(countries[buy.countryName] || []),
            { buy: buy?.count || 0, sell: sell?.count || 0 },
          ];
        }

        if (buys?.[0]?.count > 0 || sells?.[0]?.count > 0) {
          hasData = true;
          labels.push(moment(+time).format('MMM D'));
        }
      });

      if (hasData && Object.values(countries)?.length > 0) {
        setChartCountries(Object.keys(countries));

        setChartData(countries);
        setChartLabels(labels);
      } else {
        setChartData([]);
        setChartCountries([]);
        setChartLabels([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  console.log('chartData', chartData, countrySelected);

  // ---------------------
  useEffect(() => {
    getData();
  }, [period]);

  // ---------------------
  return (
    <Card sx={{ px: 5, py: 4 }}>
      <Stack direction={'row'} justifyContent={'space-between'} sx={{ mb: 3 }}>
        <Typography variant='body1_secondary' sx={{ fontWeight: 900 }}>
          {t('Buy/Sell')}
        </Typography>

        <TimeChart period={period} setPeriod={setPeriod} />
      </Stack>

      {chartData === null && chartCountries?.length === 0 ? (
        <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: 191 }}>
          <CircularProgress color='success' size={40} />
        </Stack>
      ) : (
        <>
          {chartData?.[countrySelected] && chartData[countrySelected].length > 0 ? (
            <>
              <Box sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    backgroundImage: `url("${fakeGrid}")`,
                    backgroundRepeat: 'repeat',
                    width: 'calc(100% - 70px)',
                    height: 'calc(100% - 50px)',
                    position: 'absolute',
                    left: 40,
                    bottom: 50,
                  }}
                >
                  <Box
                    sx={{
                      backgroundImage: `url("${fakeBar}")`,
                      backgroundRepeat: 'repeat-x',
                      backgroundPosition: '0 115px',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </Box>

                <Chart chartData={chartData[countrySelected]} chartLabels={chartLabels} />
              </Box>
            </>
          ) : (
            <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: 191 }}>
              <Typography>{t('No Data')}</Typography>
            </Stack>
          )}
        </>
      )}

      <Stack direction='row' justifyContent={'flex-end'} alignItems='center' spacing={1} sx={{ mt: 2 }}>
        {CHART_COUNTRY.map((item) => (
          <PeriodButton
            size='small'
            color='inherit'
            selected={countrySelected === item.value}
            onClick={() => setCountrySelected(item.value)}
            sx={{ width: 'auto', px: 2 }}
            key={item.value}
          >
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography variant='body4' fontWeight={500}>
                {t(item.label)}
              </Typography>
              {/* <BadgeIcon bgColor={COLORS[index]} direction='left' /> */}
            </Stack>
          </PeriodButton>
        ))}
      </Stack>
    </Card>
  );
}

const Chart = ({ chartData, chartLabels }) => {
  const { t } = useTranslation();

  const chartOptions = mergeDeep(BaseOptionChart(), {
    stroke: {
      curve: 'smooth',
      width: 1.5,
    },
    legend: {
      show: false,
    },
    colors: COLORS,
    chart: { animations: { enabled: true } },
    xaxis: {
      categories: chartLabels,
    },
    grid: {
      show: true,
      strokeDashArray: 4,
      borderColor: 'info.darkest',
    },
    plotOptions: {
      bar: {
        columnWidth: '15%',
        borderRadius: 0,
      },
    },
    tooltip: {
      x: { show: false },
      y: {
        formatter: (seriesName) => `${seriesName || 0} ${t('items')}`,
        title: {
          formatter: (label) => ` ${t(label)} `,
        },
      },
      marker: { show: false },
    },
  });

  return (
    <ReactApexChart
      series={[
        {
          name: t('Buy'),
          type: 'line',
          data: chartData.map((c) => c.buy),
        },
        {
          name: t('Sell'),
          type: 'line',
          data: chartData.map((c) => c.sell),
        },
      ]}
      options={chartOptions}
      height={176}
    />
  );
};
