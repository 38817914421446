import React from 'react';
import { Slider, Stack, TextField } from '@mui/material';
import { fNumber } from '../utils/formatNumber';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const NumRangeWithInput = ({ label, value, setValue, min = 0, max = 50000, ...other }) => {
  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Stack direction={'column'} alignItems={'start'} justifyContent={'start'} style={{marginTop:"0px"}}>
      <label style={{fontSize:"0.7rem" , color:"#5D6588"}}>
        {label}
      </label>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'start'}>
        <TextField
          // label={t(`min`)}
          variant='standard'
          type={'number'}
          style={{width:'25%'}}
          {...other}
          value={value?.[0]}
          onChange={(e)=>{
           setValue([parseInt(e.target.value) > 50000 ? 50000 : parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value), value?.[1]])
          }}
        />
        <TextField
          // label={t(`max`)}
          variant='standard'
          style={{width:'25%' , marginLeft:'10px'}}
          type={'number'}
          {...other}
          value={value?.[1]}
          onChange={(e)=>{
            setValue([value?.[0] , parseInt(e.target.value) > 50000 ? 50000 : parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value)])
          }}
        />
        <Slider
          getAriaLabel={() => 'Temperature range'}
          value={value}
          onChange={handleChange}
          style={{width:"50%" , marginLeft:"1rem"}}
          isRtl={false}
          min={min}
          max={max}
          size='small'
        />
      </Stack>
    </Stack>

  );
};

export default NumRangeWithInput;