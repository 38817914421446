import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import CaptchaModal from './captchaModal';
import { checkStrongPassword } from '../../utils';
import Api from '../../http/serviceApi';
import { FormProvider, RHFCheckbox, RHFTextField } from '../hookForm';
import Iconify from '../Iconify';
import { css } from 'styled-components';
import SocialLogin from '../SocialLogin';
import useQuery from '../../hooks/useQuery';

const passwordStrongColor = ['grey.400', 'red', 'orange', 'yellow', 'yellow', 'green'];

const RegisterForm = ({ onSuccess }) => {
  const { t } = useTranslation();
  const query = useQuery();

  const referralCode = query.get('referral') ? query.get('referral') : '';

  const [showPassword, setShowPassword] = useState(false);
  const [showRepassword, setShowRepassword] = useState(false);
  const [passLevel, setPassLevel] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const validators = Yup.object().shape({
    firstName: Yup.string().required(t('Please input your first name!')),
    lastName: Yup.string().required(t('Please input your last name!')),
    emailAddress: Yup.string().required(t('Please input your email!')),
    password: Yup.string()
      .required(t('Please input your password!'))
      .test(
        'strong-password',
        t(
          'Passwords must have at least 8 characters and contain at least one uppercase letters, lowercase letters, number and symbol.'
        ),
        (value) => checkStrongPassword(value) === 5
      ),
    repeatPassword: Yup.string()
      .required(t('Please input the repeat password!'))
      .oneOf([Yup.ref('password'), null], t('The repeat password does not match the password')),
    terms: Yup.boolean().oneOf([true], t('You must accept the terms and conditions!')),
  });

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      password: '',
      repeatPassword: '',
      terms: false,
    },
  });

  const {
    handleSubmit,
    getValues,
    watch,
    formState: { isSubmitting },
  } = methods;

  const password = watch('password');

  const onSubmit = async () => {
    setIsModalVisible(true);
  };

  const onCaptchaSuccess = async () => {
    try {
      const response = await Api.userPreRegistration({
        ...getValues(),
        referralCode,
        captchaData: 'captcha',
        captchaId: 'captchaId',
        repeatPassword:undefined
      });
      if (response?.status === 'SUCCESS') {
        setIsModalVisible(false);
        onSuccess({ email: getValues('emailAddress') });
      }
    } catch (e) {
      setIsModalVisible(false);
    }
  };

  useEffect(() => {
    if (!password) setPassLevel(0);
    const fillCount = checkStrongPassword(password);
    setPassLevel(fillCount);
  }, [password]);

  return (
    <>
      <Box>
        <Typography variant='h4' color={'grey.100'} sx={{ fontWeight: 400 }}>
          {t('Nice to meet you!')}
        </Typography>
        <Typography variant='body4' color={'grey.400'}>
          {t('Best Wallet For Your Coins')}
        </Typography>
      </Box>

      <Box sx={{ width: 320, mt: 6.5 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <Stack spacing={3}>
            <RHFTextField name='firstName' label={t('First Name')} variant='standard' fullWidth />

            <RHFTextField name='lastName' label={t('Last Name')} variant='standard' fullWidth />

            <RHFTextField
              name='emailAddress'
              label={t('Email')}
              variant='standard'
              fullWidth
              InputProps={{
                sx: css`
                  input {
                    /* @noflip */
                    direction: ltr;
                  }
                `,
              }}
            />

            <RHFTextField
              name='password'
              label={t('Password')}
              variant='standard'
              type={showPassword ? 'text' : 'password'}
              fullWidth
              InputProps={{
                autoComplete: 'new-password',
                sx: css`
                  input {
                    /* @noflip */
                    direction: ltr;
                  }
                `,
                endAdornment: (
                  <>
                    <InputAdornment position='end'>
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                        <Iconify
                          icon={showPassword ? 'ant-design:eye-outlined' : 'ant-design:eye-invisible-outlined'}
                          sx={{ width: 18, mr: 0.5 }}
                        />
                      </IconButton>
                    </InputAdornment>

                    <Stack
                      direction='row'
                      justifyContent={'space-between'}
                      sx={{ width: '100%', position: 'absolute', top: '36px' }}
                    >
                      {new Array(5).fill(1).map((i, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: 59,
                            height: '1px',
                            backgroundColor: passLevel >= index + 1 ? passwordStrongColor[passLevel] : 'grey.400',
                          }}
                        />
                      ))}
                    </Stack>
                  </>
                ),
              }}
            />

            <RHFTextField
              name='repeatPassword'
              label={t('Repeat Password')}
              variant='standard'
              type={showRepassword ? 'text' : 'password'}
              fullWidth
              InputProps={{
                sx: css`
                  input {
                    /* @noflip */
                    direction: ltr;
                  }
                `,
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setShowRepassword(!showRepassword)} edge='end'>
                      <Iconify
                        icon={showRepassword ? 'ant-design:eye-outlined' : 'ant-design:eye-invisible-outlined'}
                        sx={{ width: 18, mr: 0.5 }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <RHFCheckbox
                name='terms'
                label={"I have read and agree to Cryptizi's Terms of Service and Privacy Policy."}
                sx={{ '& .MuiTypography-root': { color: 'grey.400', fontSize: 14 } }}
              />
            </Stack>
          </Stack>

          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            loading={isSubmitting}
            sx={{ mt: 6.25 }}
          >
            {t('Register')}
          </LoadingButton>
        </FormProvider>

        <SocialLogin referralCode={referralCode} />
      </Box>

      <CaptchaModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onCaptchaSuccess={onCaptchaSuccess}
      />
    </>
  );
};

export default RegisterForm;
