import { useState, useEffect } from 'react';
import { Box, Button, Divider, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Image from '../../../../components/Image';
import USD from '../../../../assets/logo/dolar.svg';
import { NumberField } from '../../../../components/NumberField';
import SymbolIcon from '../../../../components/SymbolIcon';

export const symbolList = [
  { label: 'USDT', icon: 'USDT' },
  { label: 'BTC', icon: 'BTC' },
  { label: 'ETH', icon: 'ETH' },
  { label: 'DAI', icon: 'DAI' },
];
export const fiatList = [{ label: 'USD', icon: USD }];

export default function P2PTab({
  side = 'buy',
  symbol,
  fiat,
  onChangeFiat,
  onChangeSymbol,
  amount = 0,
  price = 0,
  onChangeAmount,
  onChangePrice,
  userWallet,
  maxLimit = 0,
  minLimit = 0,
  onChangeMaxLimit,
  onChangeMinLimit,
}) {
  const { t } = useTranslation();

  const [symbolBalance, setSymbolBalance] = useState(0);
  const [fiatBalance, setFiatBalance] = useState(0);

  useEffect(() => {
    if (userWallet) {
      if (symbol) {
        const found = userWallet.walletInfos.find((w) => w.symbol === symbol);
        setSymbolBalance(found?.available || 0);
      }

      if (fiat) {
        if (fiat === 'USD') setFiatBalance(userWallet.dollar);
        else if (fiat === 'IRR') setFiatBalance(userWallet.rial);
        else setFiatBalance(0);
      }
    }
  }, [userWallet, symbol, fiat]);

  const assetInput = (
    <Box sx={{ mt: 5.5 }}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant='body3' color='grey.300'>
          {t('Total Amount')}
        </Typography>

        <Typography variant='body3' color='grey.300'>
          {t('Asset')}
        </Typography>

        {/* <Stack direction='row'>
      <Typography variant='body4' color='grey.300'>
        {t('Balance')} : {balance} {symbol}
      </Typography>
    </Stack> */}
      </Stack>

      <Input
        amount={amount}
        value={symbol}
        onChange={onChangeSymbol}
        onChangeAmount={onChangeAmount}
        type='symbol'
        side={side}
        onMax={() => onChangeAmount(symbolBalance)}
      />
    </Box>
  );

  const fiatInput = (
    <Box sx={{ mt: 5.5 }}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant='body3' color='grey.300'>
          {t('Price')}
        </Typography>

        <Typography variant='body3' color='grey.300'>
          {t('With Fiat')}
        </Typography>
      </Stack>

      <Input
        amount={price}
        value={fiat}
        onChange={onChangeFiat}
        onChangeAmount={onChangePrice}
        type='fiat'
        side={side}
        // onMax={() => onChangePrice(fiatBalance)}
      />
    </Box>
  );

  useEffect(() => {
    if (+amount > 0 && +price > 0) {
      onChangeMaxLimit(amount * price);
    }
  }, [amount, price]);

  return (
    <>
      {assetInput}

      {fiatInput}

      <Stack direction='row' justifyContent='space-between' spacing={5} sx={{ mt: 5 }}>
        <NumberField
          variant='standard'
          fullWidth
          label={t('Minimum order limit')}
          thousandSeparator
          value={minLimit}
          placeholder={'0'}
          onChange={(e) => onChangeMinLimit(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Typography color='grey.300' variant='body3'>
                  {fiat}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <NumberField
          variant='standard'
          fullWidth
          label={t('Maximum order limit')}
          thousandSeparator
          value={maxLimit}
          placeholder={'0'}
          onChange={(e) => onChangeMaxLimit(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Typography color='grey.300' variant='body3'>
                  {fiat}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </>
  );
}

const Input = ({ amount, onChangeAmount, value, onChange, onMax = null, side = 'buy', type = 'symbol', ...other }) => {
  const { t } = useTranslation();

  return (
    <Stack direction={'row'} alignItems={'center'} sx={{ mt: 1.5 }}>
      <NumberField
        variant='standard'
        value={amount}
        onChange={(event) => onChangeAmount(event.target.value)}
        fullWidth
        placeholder='0'
        thousandSeparator
        InputProps={{
          endAdornment: onMax && (
            <InputAdornment position='end'>
              <Button
                size='small'
                color='warning'
                sx={{
                  typography: 'body1',
                  fontWeight: 900,
                  px: 1,
                  minWidth: 50,
                  height: 24,
                  borderRadius: 0.6,
                }}
                onClick={onMax}
              >
                {t('MAX')}
              </Button>
            </InputAdornment>
          ),
        }}
        sx={{
          '& input': { color: 'grey.0', height: '1.55em' },
          '& .Mui-disabled': {
            '& input': { WebkitTextFillColor: 'grey.0 !important' },
            '&:before': { borderBottomStyle: 'solid !important' },
          },
        }}
        {...other}
      />
      <Divider orientation='vertical' variant='middle' flexItem />
      <TextField
        fullWidth
        select
        variant='standard'
        label=''
        value={value}
        onChange={(event) => onChange(event.target.value)}
        SelectProps={{
          sx: { pl: 1 },
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' } },
          },
        }}
        sx={{
          width: 140,
        }}
      >
        {(type === 'symbol' ? symbolList : fiatList).map((item, index) => (
          <MenuItem
            key={index}
            value={item.label}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
            }}
          >
            <Stack direction={'row'} spacing={1.2} alignItems={'center'}>
              {type === 'symbol' ? (
                <SymbolIcon symbol={item.icon} width={side === 'sell' ? 21 : 20} height={side === 'buy' ? 21 : 20} />
              ) : (
                <Image src={item.icon} sx={{ width: side === 'buy' ? 20 : 21 }} />
              )}

              <Typography variant='subtitle2_secondary'>{item.label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
};
