import { useState } from "react";

export default function useVerifyViaEmail () {
    const [isSending, setIsSending] = useState(false);

    const handleSendingEmail = () => {
        console.log("Email has been sent");
    }

    return {
        isSending,
        handleSendingEmail
    }

}