export default function useVerificationSubmit() {
    const onSubmit = async (payload) => {
      console.log('====================================');
      console.log({ payload });
      console.log('====================================');
    };
  
    return {
      onSubmit,
    };
  }