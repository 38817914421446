import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import Api from '../../../../../http/serviceApi';
import { useSelector } from 'react-redux';

export default function useWithdrawVerification({ onSuccess, onClose, stopTimer }) {
  const { t } = useTranslation();
  const user = useSelector((store) => store.userInfo);

  const validators = Yup.object().shape({
    emailOtp: Yup.string(), //.required(t('Please enter email verification code!')),
    smsOtp: Yup.string(), //.required(t('Please enter phone verification code!')),
    softOtp: Yup.string(), //.required(t('Please enter authenticator code!')),
  });

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues: {
      smsOtp: '',
      emailOtp: '',
      softOtp: '',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (payload) => {
    try {
      const response = await Api.verifyWithdraw(payload);
      if (response?.status === 'SUCCESS') {
        handleClose();
        stopTimer();
        onSuccess();
      }
    } catch (e) {}
  };

  const handleClose = () => {
    reset();
    stopTimer();
    onClose();
  };

  return {
    handleClose,
    methods,
    handleSubmit,
    onSubmit,
    isSubmitting,
    showSmsOtp: user?.smsAuthEnabled || false,
    showSoftOtp: user?.softOtpAuthEnabled || false,
  };
}
