import React from 'react';
import { Form, Input, Select, Button, Row, Col, Radio } from 'antd';
import classes from './interAuthSelect.module.scss';
import './interAuthSelectAntdStyles.scss';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { SET_AUTHENTICATION_Intermediate } from '../../../../../store/actionTypes';
import Api from '../../../../../http/serviceApi';
import { useEffect } from 'react';
import { navigationUrl } from '../../../../../navigationUrl';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTranslation } from 'react-i18next';

const InterAuthSelect = () => {
  const { t } = useTranslation();
  const [radioValue, setRadioValue] = React.useState('PASSPORT');
  const [country, setCountry] = React.useState('');
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((store) => store.userInfo);

  const colProps = {
    xs: 24,
    sm: 24,
    md: 17,
    lg: 15,
    xl: 10,
  };

  const colPropsBotton = {
    xs: 24,
    sm: 10,
    md: 10,
    lg: 10,
    xl: 10,
  };

  const colPropsRadioItem = {
    xs: 24,
    sm: 18,
    md: 18,
    lg: 18,
    xl: 18,
  };

  const getCountryById = async () => {
    const response = await Api.getCountryById(userInfo.countryId);
    if (response) {
      setCountry(response.name);
    }
  };

  useEffect(() => {
    getCountryById();
  }, []);

  const onRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const onContinueButtonClick = () => {
    const data = {
      interAuthInfo: {
        docType: radioValue,
      },
    };
    dispatch({
      type: SET_AUTHENTICATION_Intermediate,
      payload: data,
    });
    console.log(data);
    navigate(navigationUrl.auth_inter_additionalinfo);
  };

  return (
    <Row justify='center' style={{ marginLeft: '10px', marginRight: '10px' }}>
      <Col {...colProps}>
        <div className={classes.formBody}>
          <div className={classes.formTitleDiv}>
            <span className={classes.formTitleBig}>{t('Intermediate Verification')}</span>
            <span className={classes.formTitleSmall}></span>
          </div>

          <Form
            layout='vertical'
            name='intermediateAuthentication'
            initialValues={{
              remember: true,
            }}
            autoComplete='off'
          >
            <div className={classes.fixDataDiv}>
              <span>{t('Contry / region')}</span>
              <span>{country}</span>
            </div>

            <div className={classes.insideTextDiv}>
              <span className={classes.insideTextWhite}>{t('Use a valid goverment-issued document')}</span>
              <span className={classes.insideTextGray}>
                {t('Only the following documents listed below will be accepted')}
              </span>
            </div>

            <Radio.Group
              className='radioChoosePassport'
              style={{ display: 'block' }}
              onChange={onRadioChange}
              value={radioValue}
              optionType='button'
              buttonStyle='outline'
            >
              <Row>
                <Col {...colPropsRadioItem}>
                  <Radio.Button value='PASSPORT' className={classes.radioItem}>
                    {t('Passport')}
                  </Radio.Button>
                </Col>
                <Col {...colPropsRadioItem}>
                  <Radio.Button value='NATIONAL_CARD' className={classes.radioItem}>
                    {t('Id Card')}
                  </Radio.Button>
                </Col>
              </Row>
            </Radio.Group>

            <Row justify='space-evenly' style={{ marginTop: '40px' }}>
              <Col {...colPropsBotton}>
                <Form.Item>
                  <Button type='primary' block shape='round' onClick={onContinueButtonClick}>
                    {t('Continue')}
                  </Button>
                </Form.Item>
              </Col>
              <Col {...colPropsBotton}>
                <Form.Item>
                  <Button type='default' block shape='round' onClick={() => navigate(navigationUrl.auth)}>
                    {t('Back')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <div className={classes.indicatorDiv}>
            <div className={classes.indicatorItemGreen} />
            <div className={classes.indicatorItemGray} />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default InterAuthSelect;
