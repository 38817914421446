import React from 'react';
import { Button, MenuItem, Stack, TextField } from '@mui/material';

import DatePicker from '../../../../../../components/DatePicker';
// import NumberRange from '../../../../../../components/NumberRange';
import { useTranslation } from 'react-i18next';
import ComplexToolbar from '../../../../../../components/ComplexToolbar';
import NumberRange from '../../../../../../components/NumberRange';
import NumRangeWithInput from '../../../../../../components/NumRangeWithInput';

const FiatTransactionToolbar = ({
  typeFilter,
  setTypeFilter,
  fiatStatusFilter,
  setFiatStatusFilter,
  depositStatusFilter,
  setDepositStatusFilter,
  withdrawStatusFilter,
  setWithdrawStatusFilter,
  dateFromFilter,
  setDateFromFilter,
  dateToFilter,
  setDateToFilter,
  handleFilter,
  handleResetFilters,
  transactionIdFilter,
  setTransactionIdFilter,
  isExportLoading,
  onExport,
  amountFilter,
  setAmountFilter,
}) => {
  const { t } = useTranslation();
  const depositTypes = ['ALL', 'Credited', 'Pending', 'Success'];
  const withdrawTypes = [
    'All',
    'Awaiting Approval',
    'Cancelled',
    'Completed',
    'Email Sent',
    'Failure',
    'Processing',
    'Rejected',
  ];
  const fiatTypes = ['ALL', 'Canceled', 'Failure', 'New', 'Success'];
  // const [userNameFilter, setUserNameFilter] = useState('');
  // const [profileFilter, setProfileFilter] = useState('');
  // const [otherFilter, setOtherFilter] = useState('');
  // const [balanceFilter, setBalanceFilter] = useState([0, 50000]);
  // const [dateFromFilter, setDateFromFilter] = useState(null);
  // const [dateToFilter, setDateToFilter] = useState(null);
  // const [levelFilter, setLevelFilter] = useState('');

  // const handleFilter = () => {
  //   onFilter({ userNameFilter, profileFilter, otherFilter, balanceFilter, dateFromFilter, dateToFilter, levelFilter });
  // };

  // const resetFilter = () => {
  //   setUserNameFilter('');
  //   setProfileFilter('');
  //   setOtherFilter('');
  //   setBalanceFilter([0, 50000]);
  //   setDateFromFilter(null);
  //   setDateToFilter(null);
  //   setLevelFilter('');

  //   onFilter({
  //     useNameFilter: '',
  //     profileFilter: '',
  //     otherFilter: '',
  //     balanceFilter: [0, 50000],
  //     dateFromFilter: null,
  //     dateToFilter: null,
  //     levelFilter: '',
  //   });
  // };

  return (
    <ComplexToolbar
      onSearch={false}
      onExport={onExport}
      isExportLoading={isExportLoading}
      onPrint={() => console.log('print')}
    >
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} sx={{ mt: 2 }} alignItems={'center'}>
          {/* <TextField
            label={t('User')}
            variant='standard'
            fullWidth
            value={userNameFilter}
            onChange={(e) => setUserNameFilter(e.target.value)}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          ></TextField> */}

          <TextField
            select
            label={t('Type')}
            variant='standard'
            fullWidth
            value={typeFilter}
            onChange={(e) => setTypeFilter(e.target.value)}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            <MenuItem value={'ALL'}>{t('All')}</MenuItem>
            <MenuItem value={'deposit'}>{t('Deposit')}</MenuItem>
            <MenuItem value={'withdraw'}>{t('Withdraw')}</MenuItem>
          </TextField>

          <TextField
            select
            label={t('Fiat Status')}
            variant='standard'
            fullWidth
            value={fiatStatusFilter}
            onChange={(e) => setFiatStatusFilter(e.target.value)}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            {fiatTypes.map((fiat, index) => (
              <MenuItem key={index} value={fiat.toUpperCase()}>
                {t(`${fiat}`)}
              </MenuItem>
            ))}
          </TextField>

          <Stack direction={'row'} sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}>
            <DatePicker
              label={t('From')}
              value={dateFromFilter}
              setValue={setDateFromFilter}
              variant='standard'
              maxDate={dateToFilter}
              fullWidth
              sx={{ minWidth: 75 }}
              InputProps={null}
            />
            <DatePicker
              label={t('To')}
              value={dateToFilter}
              setValue={setDateToFilter}
              variant='standard'
              minDate={dateFromFilter}
              fullWidth
              sx={{ minWidth: 75 }}
            />
          </Stack>
        </Stack>

        <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'center'} spacing={5}>
          {/* <TextField
            select
            label={t('Other')}
            variant='standard'
            fullWidth
            value={otherFilter}
            onChange={(e) => setOtherFilter(e.target.value)}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            <MenuItem value={'all'}>{t('All')}</MenuItem>
          </TextField> */}
          <TextField
            select
            label={t('Deposit Status')}
            variant='standard'
            fullWidth
            value={depositStatusFilter}
            onChange={(e) => setDepositStatusFilter(e.target.value)}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            {depositTypes.map((deposit, index) => (
              <MenuItem key={index} value={deposit.toUpperCase()}>
                {t(`${deposit}`)}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label={t('Withdraw Status')}
            variant='standard'
            fullWidth
            value={withdrawStatusFilter}
            onChange={(e) => setWithdrawStatusFilter(e.target.value)}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            {withdrawTypes.map((withdraw, index) => (
              <MenuItem key={index} value={withdraw.replace(' ', '_')}>
                {t(`${withdraw}`)}
              </MenuItem>
            ))}
          </TextField>

          {/*<NumberRange*/}
          {/*  value={amountFilter}*/}
          {/*  setValue={setAmountFilter}*/}
          {/*  variant='standard'*/}
          {/*  fullWidth*/}
          {/*  max={100000}*/}
          {/*  label={t('Amount')}*/}
          {/*  sx={{*/}
          {/*    minWidth: 150,*/}
          {/*    width: { xs: '100%', md: '50%' },*/}
          {/*  }}*/}
          {/*/>*/}
          <NumRangeWithInput
            max={100000}
            label={t('Amount')}
            variant='standard'
            value={amountFilter}
            setValue={setAmountFilter}
            sx={{
              minWidth: 150,
              width: { xs: '100%', md: '50%' },
            }}  />
          <TextField
            label={t('Transaction ID')}
            variant='standard'
            fullWidth
            value={transactionIdFilter}
            onChange={(e) => setTransactionIdFilter(e.target.value)}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          ></TextField>

          {/* <NumberRange
            label={'Balance'}
            value={balanceFilter}
            setValue={setBalanceFilter}
            sx={{ minWidth: 150, width: { xs: '100%', md: '50%' } }}
          /> */}
        </Stack>
      </Stack>

      <Stack direction={'row'} spacing={2} sx={{ mt: 5, mb: 5.75 }}>
        <Button
          onClick={handleFilter}
          fullWidth
          variant='contained'
          sx={{ maxWidth: 204, typography: 'body3', fontWeight: 500 }}
        >
          {t('Filter')}
        </Button>
        <Button
          onClick={handleResetFilters}
          fullWidth
          variant='outlined'
          color='inherit'
          sx={{ maxWidth: 80, typography: 'body3', fontWeight: 500, borderColor: 'grey.400' }}
        >
          {t('Reset')}
        </Button>
      </Stack>
    </ComplexToolbar>
  );
};

export default FiatTransactionToolbar;
