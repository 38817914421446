import React from 'react';
import { TableRow, TableCell, Typography, Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Image from '../../../../../../components/Image';
import plus from '../../../../../../assets/plus.svg';
import USD from '../../../../../../assets/logo/dolar.svg';
import IRR from '../../../../../../assets/logo/IRR.svg';

const FiatWalletTableRow = ({ row, setShowModal, setSelectedFiat }) => {
  const { t } = useTranslation();
  const { amount, blocked, type } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1, typography: 'body2' } }}>
      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Image src={type === 'USD' ? USD : IRR} />
          <Typography variant='body2' sx={{ ml: 2 }}>
            {type}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell align='center'>{amount.toLocaleString('en-US', { minimumFractionDigits: 3 })}</TableCell>
      <TableCell align='center'>{blocked.toLocaleString('en-US', { minimumFractionDigits: 3 })}</TableCell>

      <TableCell align='center'>
        <Box
          sx={{
            width: 105,
            borderRadius: '8px',
            textAlign: 'center',
            p: 0.8,
            bgcolor: 'rgba(48, 224, 161, 0.2)',
            cursor: 'pointer',
            m: 'auto ',
          }}
          onClick={() => {
            setShowModal(true);
            setSelectedFiat(row);
          }}
        >
          <Typography
            variant='body2'
            color={'#30E0A1'}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Image src={plus} sx={{ width: 12, height: 12, mr: 1.25 }} />
            {t('Create')}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default FiatWalletTableRow;
