import React , { useState ,useEffect} from 'react';
import { Box, Stack, Card, Tab, Tabs, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FutureWalletAssetDetails from './FutureWalletAssetDetails';
import FuturesPositionTable from '../futures/futuresPositionTable/futuresPositionTable';
import Api from '../../../http/serviceApi';


const FutureWalletTables = ({userWallet ,setShowModal}) => {
  const { t } = useTranslation();

  const [walletTab, setWalletTab] = useState(0);
  const handleWalletTabChange = (e, newTab) => {
    setWalletTab(newTab);
  };

  const [positionsTableData, setPositionsTableData] = useState(null);
  const [positionsTablePagination, setPositionsTablePagination] = useState({ totalItems: 0 });


  const getPositionData = async () => {


    try {
      const response = await Api.getFuturesPositions();
      if (response.status === 'SUCCESS') {
        setPositionsTableData(response.positionInfos);
        setPositionsTablePagination({ totalItems: response.positionInfos.length });
      } else {
        setPositionsTableData([]);
      }
    } catch (e) {
      setPositionsTableData([]);
    }

  };
  useEffect(() => {
    getPositionData();
  }, []);

  return (
    <Card sx={{ py:2, px: 4, mt: 3 }}>
      <Box sx={{ borderBottom: 2, borderColor: 'divider', maxWidth: '100%' }}>
        <Tabs value={walletTab} onChange={handleWalletTabChange} variant='scrollable' scrollButtons='auto'>
          <Tab label={t('Asset Details')} sx={{ width: 140 }} />
          <Tab label={t('Positions')} sx={{ width: 146 }} />
        </Tabs>

        <TabPanel value={walletTab} index={0}>
          <FutureWalletAssetDetails userWallet={userWallet} setShowModal={setShowModal}/>
        </TabPanel>

        <TabPanel value={walletTab} index={1}>
          <FuturesPositionTable
            positionsTableData={positionsTableData}
            positionsTablePagination={positionsTablePagination}
            onReload={() => {
              console.log('reload')
            }}
          />
        </TabPanel>
      </Box>

    </Card>


  );
};

export default FutureWalletTables;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && <Box sx={{ mt: 2.5 }}>{children}</Box>}
    </div>
  );
}
