import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export const PageTitle = ({ title, subTitle }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant='subtitle1' sx={{ mb: { xs: 1, sm: 2 } }}>
        {t(title)}
      </Typography>
      <Typography variant='body3' color='grey.100'>
        {t(subTitle)}
      </Typography>
    </>
  );
};
