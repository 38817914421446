import React from 'react';
import { Stack, InputAdornment, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { RHFTextField, RHFDatePicker, RHFSelect, RHFNumberField } from '../../../../components/hookForm';
import Iconify from '../../../../components/Iconify';
import { RHFCustomUploadImage } from '../../../../components/hookForm/RHFUpload';

const AddUserInputs = ({ handleDrop }) => {
  const { t } = useTranslation();

  return (
    <Stack sx={{ width: { sx: '100%', sm: '60%', md: '92%' }, mt: 5 }}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} justifyContent='space-between'>
        <RHFTextField name={'firstName'} label={t('Name')} variant='standard' fullWidth sx={{ minWidth: 200 }} />
        <RHFTextField name={'lastName'} label={t('LastName')} variant='standard' fullWidth sx={{ minWidth: 200 }} />
        <RHFNumberField name={'mobile'} label={t('Mobile')} variant='standard' fullWidth sx={{ minWidth: 200 }} />
      </Stack>

      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={5}
        justifyContent='space-between'
        sx={{ mt: { xs: 5, md: 3 } }}
      >
        <RHFTextField name={'emailAddress'} label={t('Email')} variant='standard' fullWidth sx={{ minWidth: 200 }} />
        <RHFNumberField name={'phoneNumber'} label={t('Tell')} variant='standard' fullWidth sx={{ minWidth: 200 }} />

        <RHFDatePicker
          label={t('Birth Date')}
          name={'birthDay'}
          variant='standard'
          sx={{ minWidth: 200, width: '100%' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Iconify icon={'akar-icons:calendar'} sx={{ width: 23, height: 23, color: 'grey.100', mb: 2, mr: 1 }} />
              </InputAdornment>
            ),
          }}
        />

        {/*   <RHFTextField name={'fatherName'} label={'Father Name'} variant='standard' fullWidth sx={{ minWidth: 200 }} />
        <RHFTextField
          name={'nationalNumber'}
          label={'National Number'}
          variant='standard'
          fullWidth
          sx={{ minWidth: 200 }}
        /> */}
      </Stack>

      {/* <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={5}
        justifyContent='space-between'
        sx={{ mt: { xs: 5, md: 3 } }}
      >
        <RHFDatePicker
          label={'Birth Date'}
          name={'birthDay'}
          variant='standard'
          sx={{ minWidth: 200, width: '100%' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Iconify icon={'akar-icons:calendar'} sx={{ width: 23, height: 23, color: 'grey.100', mb: 2, mr: 1 }} />
              </InputAdornment>
            ),
          }}
        />

          <RHFDatePicker
          label={'Login Date'}
          name={'loginDate'}
          variant='standard'
          sx={{ minWidth: 200,  width:"100%"  }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Iconify icon={'akar-icons:calendar'} sx={{ width: 23, height: 23, color: 'grey.100', mb: 2, mr: 1 }} />
              </InputAdornment>
            ),
          }}
        />

        <RHFSelect
          name='verification'
          SelectProps={{
            native: false,
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' } },
            },
          }}
          label='Verification'
          variant='standard'
          fullWidth
          sx={{ minWidth: 200 }}
        >
          <MenuItem value={'google'}>
            <Typography typography='body3' color='grey.300'>
              {t('Google')}
            </Typography>
          </MenuItem>
          <MenuItem value={'email'}>
            <Typography typography='body3' color='grey.300'>
              {t('Email')}
            </Typography>
          </MenuItem>
          <MenuItem value={'sms'}>
            <Typography typography='body3' color='grey.300'>
              {t('Sms')}
            </Typography>
          </MenuItem>
        </RHFSelect>
      </Stack> */}

      <Stack direction={{ xs: 'column', md: 'row' }}
             spacing={5}
             justifyContent='space-between'
             sx={{ mt: { xs: 5, md: 3 } }}>
        <RHFTextField name={'password'} label={t('Password')} variant='standard' fullWidth sx={{ minWidth: 200 }} />

        <RHFSelect
          name='verification'
          SelectProps={{
            native: false,
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' } },
            },
          }}
          label={t('Verification')}
          variant='standard'
          fullWidth
          sx={{ minWidth: 200 }}
        >
          <MenuItem value={'google'}>
            <Typography typography='body3' color='grey.300'>
              {t('Google')}
            </Typography>
          </MenuItem>
          <MenuItem value={'email'}>
            <Typography typography='body3' color='grey.300'>
              {t('Email')}
            </Typography>
          </MenuItem>
          <MenuItem value={'sms'}>
            <Typography typography='body3' color='grey.300'>
              {t('Sms')}
            </Typography>
          </MenuItem>
        </RHFSelect>

        <RHFNumberField name={'a'} label={t('a')} variant='standard' fullWidth sx={{ minWidth: 200 , opacity:0 ,pointerEvents:"none"}} />

      </Stack>

      <Stack sx={{ mt: 5, width: { sx: '100%', md: '40%' } }}>
        <RHFCustomUploadImage name='userImg' accept='image/*' showChangePhotoLabel onDrop={handleDrop} />
      </Stack>
    </Stack>
  );
};

export default AddUserInputs;
