import React from 'react';
import { Stack, Button, MenuItem, Typography, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { RHFTextField, RHFSelect } from '../../../../components/hookForm';
import Iconify from '../../../../components/Iconify';

const AddCardInputs = ({ handleAddCard, cardsAmount, handleDeleteCard }) => {
  const { t } = useTranslation();

  return (
    <Stack sx={{ mt: 1 }}>
      {cardsAmount.map(
        (card, index) =>
          card !== undefined && (
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={5}
              justifyContent='space-between'
              alignItems={'flex-end'}
              key={index}
              sx={{ mt: { xs: 7, md: 3 } }}
            >
              <RHFTextField
                name={`cardNumber_${card}`}
                label={t('Card Number')}
                variant='standard'
                fullWidth
                sx={{ minWidth: 150 }}
              />
              <RHFTextField
                name={`shaba_${card}`}
                label={t(`IBAN${"\\"}SHABA Number`)}
                variant='standard'
                fullWidth
                sx={{ minWidth: 200 }}
              />
              <RHFTextField
                name={`accountNumber_${card}`}
                label={t('Account Number')}
                variant='standard'
                fullWidth
                sx={{ minWidth: 200 }}
              />
              {/* <RHFSelect
                name={`bank_${card}`}
                SelectProps={{
                  native: false,
                  MenuProps: {
                    sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' } },
                  },
                }}
                label='Bank'
                variant='standard'
                fullWidth
                sx={{ minWidth: 200 }}
              >
                <MenuItem value={'shahr'}>
                  <Typography typography='body3' color='grey.300'>
                    Shahr
                  </Typography>
                </MenuItem>
                <MenuItem value={'ayande'}>
                  <Typography typography='body3' color='grey.300'>
                    Ayande
                  </Typography>
                </MenuItem>
                <MenuItem value={'saderat'}>
                  <Typography typography='body3' color='grey.300'>
                    Saderat
                  </Typography>
                </MenuItem>
                <MenuItem value={'sepah'}>
                  <Typography typography='body3' color='grey.300'>
                    Sepah
                  </Typography>
                </MenuItem>
                <MenuItem value={'pasargad'}>
                  <Typography typography='body3' color='grey.300'>
                    Pasargad
                  </Typography>
                </MenuItem>
              </RHFSelect> */}
              <IconButton sx={{ pb: 0 }} onClick={() => handleDeleteCard(index)}>
                <Iconify icon='eva:trash-2-outline' width={24} height={24} color='grey.100' />
              </IconButton>
            </Stack>
          )
      )}
      <Button variant='outlined' color='inherit' size='large' sx={{ width: 204, mt: 5 }} onClick={handleAddCard}>
        {t('Add New Card')}
      </Button>
    </Stack>
  );
};

export default AddCardInputs;
