import React from 'react';
import { Stack, Box, Typography, Tooltip, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';

import copy from '../../../../assets/copy.svg';
import Image from '../../../../components/Image';
import { RHFTextField } from '../../../../components/hookForm';
import useVerifyViaGoogle from './hooks/useVerifyViaGoogle';

const VerificationViaGoogle = ({ isSubmitting }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { qr, code } = useVerifyViaGoogle();

  return (
    <Stack sx={{ mt: 2.5, mb: 5 }}>
      <Typography variant='body3' color={'grey.300'}>
        {t('To activate this feature, follow these steps:')}
      </Typography>
      <Typography variant='body3' color={'grey.300'}>
        {t('1. Get the latest version of Google Authenticator from Google Play or Apple Store')}
      </Typography>
      <Typography variant='body3' color={'grey.300'}>
        {t(
          '2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program.'
        )}
        <br />
        {t('- Scan a barcode: Select this option and scan the following barcode.')}
      </Typography>

      {qr && (
        <Box width={116} height={116} sx={{ mt: 2.5 }}>
          <img src={qr} alt='scan it' />
        </Box>
      )}

      <Typography variant='body3' color={'grey.300'} sx={{ mt: 2.5 }}>
        {t('- Enter a provided key: Select this option and enter the following code carefully.')}
      </Typography>

      <CopyToClipboard text={code} onCopy={() => enqueueSnackbar(t('Copied!'))}>
        <Tooltip title={t('Copy')}>
          <IconButton sx={{ mt: 2.5, mr: 'auto' }}>
            <Image src={copy} sx={{ width: 24, height: 24 }} objectFit={'contain'} />
            <Typography sx={{ fontSize: 22, ml: 1.25 }} color='#30E0A1'>
              {code}
            </Typography>
          </IconButton>
        </Tooltip>
      </CopyToClipboard>

      <Typography variant='body3' color={'grey.300'} sx={{ mt: 2.5 }}>
        {t('3. Enter the received code (6-digit number) in the box below and click the activate button.')}
      </Typography>

      <Stack width={'50%'} spacing={2.25} direction={'row'} sx={{ mt: 5 }}>
        <RHFTextField name={'googleCode'} label={t('6-digit google code')} variant='standard' fullWidth />
        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ maxWidth: 150 }}
        >
          {t('Activation')}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default VerificationViaGoogle;
