import { Container, Divider, Link, Stack, styled, Typography } from '@mui/material';

import useUserAuth from '../../hooks/useUserAuth';
import LoadingScreen from '../../components/LoadingScreen';
import TopMenu from '../landing/sections/topMenu';
import Footer from '../landing/sections/footer';

export default function TermsOTC() {
  const { loading } = useUserAuth(false);

  // if (loading) {
  //   return <LoadingScreen />;
  // }

  return (
    <Stack sx={{ minHeight: 1 }}>
      <TopMenu />

      <RootStyle>
        <Container>
          <Typography variant='h5'>Cryptizi OTC Terms</Typography>

          <Typography variant='body2' color='grey.300' sx={{ mt: 4 }}>
            Version: 17 February 2023
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            These Cryptizi OTC Terms (this “<strong>Agreement</strong>”) shall apply to all OTC Transactions entered
            into between Cryptizi and you.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            All terms and references used in this Agreement that are not defined herein shall have the meaning given to
            them in the{' '}
            <Link href='https://www.Cryptizi.com/terms-of-use' color='primary.main'>
              Cryptizi Terms of Use
            </Link>
            , as updated from time to time (the “Terms of Use”). Where a term is defined both in the Terms of Use and in
            this Agreement, for the purposes of this Agreement only, the definition in this Agreement shall prevail.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            This Agreement shall be supplemental to and is to be read together with the Terms of Use. References in the
            Terms of Use to the Cryptizi Services and/or Transactions shall include references to OTC Transactions and
            the related services contemplated hereunder. In the event of any conflict or inconsistency between this
            Agreement and the Terms of Use, this Agreement shall prevail with respect to the services contemplated
            hereunder unless expressly stated otherwise.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            This version of this Agreement shall replace and supersede any previous agreements between us with respect
            to OTC Transactions and any previous versions of this Agreement that may have been accepted through, or
            otherwise made available on, the Platform. By entering into OTC Transactions, you confirm your acceptance of
            this Agreement and the Terms of Use.
          </Typography>

          <Typography variant='h6' sx={{ mt: 3 }}>
            1. Definitions and Interpretation
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“Accepted Order”</strong> has the meaning given to it in clause 4.2.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“Agreed Communication Channel”</strong> means such methods of communication as approved by Cryptizi
            and agreed between you and Cryptizi from time to time, which may include (without limitation) telephone,
            email, Whatsapp, Telegram, online chat functionality and, if available, the Platform. Cryptizi may withdraw
            the use of an Agreed Communication Channel at any time for any reason.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“Algo Order”</strong> has the meaning given to it in clause 6.1.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“Expiry Time”</strong> has the meaning given to it in clause 4.3;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“Futures”</strong> means COIN-M Futures, USDS-M Futures (as each term is defined in the FSA) or any
            other futures contracts that Cryptizi may allow at its sole discretion to be traded on through an Agreed
            Communication Channel.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“FSA”</strong> has the meaning given to it in clause 5.3.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“Manifest Error”</strong> means any error, omission or misquote (whether an error of Cryptizi or any
            third party) which is manifest or palpable, including a misquote by any representative of Cryptizi taking
            into account the current market and currently advertised quotes, or any error or lack of clarity of any
            information, source, commentator, official, official result or pronunciation. The fact that you may have
            entered into, or refrained from entering into, a corresponding financial commitment, contract or trade in
            reliance on a Transaction entered into with Cryptizi (or that you have suffered or may suffer any loss
            whether direct, indirect, consequential or otherwise) is deemed irrelevant as a factor and shall not be
            taken into account in determining whether a situation amounts to a Manifest Error.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“Order”</strong> means an instruction issued by you to Cryptizi through an Agreed Communication
            Channel in such form as specified by Cryptizi from time to time, which shall constitute a request to enter
            into an OTC Transaction on the basis of the parameters as set out in the Order, and shall include (without
            limitation) an Algo Order.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“OTC Services”</strong> means the provision of Prices by Cryptizi to you, your submission of Trade
            Instructions and/or Orders, and the entry into OTC Transactions, and any related services provided by
            Cryptizi or any Cryptizi Affiliate or third party in connection therewith.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“OTC Transaction”</strong> means any bilateral over the counter transaction in Digital Assets
            entered into between Cryptizi and you pursuant to a Trade Instruction or an Order submitted by you (or on
            your behalf) to Cryptizi through an Agreed Communication Channel.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“Platform”</strong> means means the digital platform made accessible via any website, page, feature,
            or content owned or operated by Cryptizi, any mobile application developed or provided by Cryptizi or any
            API made available to you or third-party applications relying on such an API or by such other means as
            Cryptizi may prescribe from time to time for the use of Cryptizi Services.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“Price”</strong> has the meaning given to it in clause 2.1.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“Price Request”</strong> means a request made by you through an Agreed Communication Channel for a
            Price.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“Products”</strong> means such products as Cryptizi enables you to trade in from time to time for
            the purposes of the OTC services, which may include (without limitation), spot transactions in certain
            Digital Assets as permitted from time to time by Cryptizi, Futures and such other products as permitted in
            Cryptizi’s sole discretion.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“Related Persons”</strong> has the meaning given to it in clause 8.2(a).
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“Trade Confirmation”</strong> has the meaning given to it in clause 3.7.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“Trade Instruction”</strong> means a trade request or instruction issued by you to Cryptizi through
            an Agreed Communication Channel in such form as specified by Cryptizi from time to time, which shall
            constitute an offer made by you to enter into an OTC Transaction on the basis of a Price provided by
            Cryptizi
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“Trading Limits”</strong> has the meaning given to it in clause 5.8.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>“Trading Rules”</strong> means any trading rules, contract specifications and other terms applicable
            to the Products as made available on the Platform from time to time, which, in respect of OTC Transactions
            in Futures, shall include the Futures Trading Rules (as defined in the FSA).
          </Typography>

          <Typography variant='h6' sx={{ mt: 3 }}>
            2. Prices
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            2.1 Cryptizi may provide indicative prices in OTC Transactions in such Products as agreed from time to time
            (“Prices”). Prices will be provided in response to a Price Request through an Agreed Communication Channel
            and will consist of either a bid price and an offer price or both a bid price and an offer price, as
            determined by Cryptizi in its sole discretion. You acknowledge and agree that Cryptizi shall determine
            Prices in its sole discretion and may discontinue providing Prices in some or all Products or some or all
            Digital Assets and/or cease the use of an Agreed Communication Channel at any time for any reason. Cryptizi
            provides no representations as to the accuracy or fitness for purpose of any Agreed Communication Channel,
            nor does Cryptizi accept any responsibility for any Loss incurred by you arising out of or in connection
            with your use of an Agreed Communication Channel.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            2.2 Cryptizi shall have sole discretion as to whether it wishes to respond to a Price Request. The provision
            of a Price by Cryptizi, whether following a Price Request or otherwise, does not constitute an offer to you
            to enter into an OTC Transaction at that Price, nor does it obligate Cryptizi to accept any subsequent Trade
            Instruction submitted by you.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            2.3 Prices are proprietary in nature and so the Prices made available by Cryptizi may differ to other prices
            provided in the relevant Digital Assets by other providers. Prices are subject to constant change and whilst
            Cryptizi seeks to provide Prices that are up to date, circumstances may arise where Prices provided are
            invalid by the time that a Trade Instruction is received by Cryptizi in relation to that Price. Cryptizi
            shall determine how long Prices remain valid in its sole discretion. A Trade Instruction submitted in
            relation to a Price that is no longer valid will be rejected.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            2.4 You acknowledge that the Prices are provided solely for your use of the OTC Services and you may not
            communicate or otherwise use the Prices for any other reason without the prior consent of Cryptizi unless
            otherwise provided herein. 2.5 You acknowledge that any Price provided by Cryptizi may be inclusive of
            bid-ask spread and/or a mark-up determined by Cryptizi to be appropriate for you and the type of OTC
            Transactions. Cryptizi is not under any obligation to disclose the specific amount of any bid-ask spread
            and/or mark-up to you (unless otherwise agreed in writing or required by law). 2.6 You acknowledge and agree
            that to the extent you are accessing and using the Cryptizi Convert Services, the Convert Terms of Use shall
            apply to govern the use of the Cryptizi Convert Services, including the use of any Cryptizi API Connection
            (as each term is defined therein).
          </Typography>

          <Typography variant='h6' sx={{ mt: 3 }}>
            3. Trade Instructions
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            3.1 To enter into an OTC Transaction on the basis of a Price provided by Cryptizi, you may do so by entering
            a Trade Instruction. Each Trade Instruction must include such details as requested from Cryptizi from time
            to time, depending on the type of OTC Transaction you are looking to enter into, including (without
            limitation), the relevant trading pairs, the Price (which must be latest Price received from Cryptizi and
            shall not have expired or been withdrawn by Cryptizi, provided that Cryptizi may in its discretion execute a
            Trade Instruction which references a Price that has expired) and the applicable notional amount (which shall
            be no less than any minimum amount and no more than any maximum amount that may be specified by Cryptizi
            from time to time).
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            3.2 Cryptizi shall have no obligation to accept any Trade Instruction and may do so in its sole discretion.
            Acceptance by Cryptizi of any Trade Instruction which does not comply with all of the requirements set out
            in this clause 3 shall not obligate Cryptizi to accept any subsequent non-confirming Trade Instruction.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            3.3 Where any Trade Instruction is ambiguous or inconsistent with any other Trade Instruction, Cryptizi
            shall be entitled to rely and act upon any Trade Instruction in accordance with any reasonable
            interpretation thereof which Cryptizi believes in good faith to be the correct interpretation.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            3.4 A Trade Instruction submitted by or on your behalf through an Agreed Communication Channel constitutes
            an offer to enter into an OTC Transaction between you and Cryptizi on the terms as set out in such Trade
            Instruction, which may be accepted or rejected by Cryptizi in its sole discretion. Cryptizi may reject a
            Trade Instruction for any reason and nothing in this Agreement or the Terms of Use (or otherwise) obliges
            Cryptizi to enter into any OTC Transaction with you in response to a Trade Instruction. Cryptizi may refuse
            to enter into any OTC Transaction, accept or otherwise act on any Trade Instruction without giving any
            reason or any notice to you. Cryptizi shall be entitled (but not obliged) to verify and be satisfied with
            respect to the identity of the person purporting to give any Trade Instruction or the source and origin of
            any Trade Instruction and Cryptizi may rely or act upon any Trade Instruction unless and until Cryptizi is
            satisfied as to the matters on which Cryptizi sought verification. A Trade Instruction which is not received
            by Cryptizi for any reason, including the failure or delay of any Agreed Communication Channel, shall be
            deemed rejected. Once submitted to Cryptizi, a Trade Instruction cannot be withdrawn, cancelled or amended
            by you once executed unless otherwise permitted by Cryptizi in its sole discretion.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            3.5 Execution of a Trade Instruction by Cryptizi, which shall occur upon express confirmation by Cryptizi to
            you through an Agreed Communication Channel that the related OTC Transaction is “done” or has otherwise been
            “executed”, will constitute a binding agreement to an OTC Transaction between Cryptizi and you on the terms
            of the relevant Trade Instruction. You acknowledge and agree that, subject to the rights of Cryptizi as
            otherwise set out in this Agreement, an OTC Transaction cannot be cancelled, amended or unwound once entered
            into without the consent of both parties regardless of whether the corresponding Trade Instruction was
            provided as a result of any inaccuracy or error by you, including in relation to the terms of the OTC
            Transaction.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            3.6 Cryptizi shall notify you of the execution of a Trade Instruction and entry into of an OTC Transaction
            through an Agreed Communication Channel but any failure or delay in providing such notice shall not affect
            the effectiveness of such OTC Transaction. If you do not receive a message through an Agreed Communication
            Channel indicating that a Trade Instruction has been accepted and that an OTC Transaction has been entered
            into within promptly after submitting the Trade Instruction to Cryptizi, you shall promptly contact Cryptizi
            through an Agreed Communication Channel to confirm the status of the relevant OTC Transaction.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            3.7 Upon agreement, Cryptizi may provide you with a written confirmation, setting out the terms of the OTC
            Transaction (a “Trade Confirmation”). You shall be responsible for comparing any Trade Confirmation or other
            record of any OTC Transaction entered into between you and Cryptizi pursuant to the OTC Services to your own
            records of OTC Transactions entered into with Cryptizi and shall notify Cryptizi of any discrepancies.
          </Typography>

          <Typography variant='h6' sx={{ mt: 3 }}>
            4. Orders
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            4.1 If permitted by Cryptizi, you may submit Orders to Cryptizi through an Agreed Communication Channel by
            specifying the terms of such Order in the manner as directed by Cryptizi from time to time. In addition, you
            may request to amend or cancel existing Orders by contacting Cryptizi through an Agreed Communication
            channel provided such Orders have not been executed. Cryptizi may in its sole discretion accept or reject
            any request to amend or cancel an Order. To the extent that Cryptizi accepts your request to amend an
            existing Order, the amended Order will be treated as a new Order placed by you. Cryptizi will execute Orders
            in accordance with this Agreement and any other terms provided on the Platform.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            4.2 Cryptizi may in its sole discretion accept or reject any Order and any such acceptance or rejection
            shall be effective upon determination by Cryptizi. Acceptance of an Order does not amount to a binding
            agreement between Cryptizi and you and does not oblige Cryptizi to enter into any OTC Transaction with you.
            An Order which has been accepted by Cryptizi (an “Accepted Order”) may be subsequently returned to you in
            Cryptizi’s sole discretion and Cryptizi shall not be under any obligation to execute any OTC Transaction
            with you in response to an Order regardless of whether the Order is an Accepted Order.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            4.3 An Accepted Order may be executed by Cryptizi in whole or in part at any time after acceptance but no
            later than the latest time for execution (if any) specified by you (the “Expiry Time”). Any Order (or part
            of any Order) not executed prior to the Expiry Time shall expire at such Expiry Time. Execution of an Order
            (or part of an Order) by Cryptizi (which shall be effected by execution of a transaction in the Digital
            Asset markets (which may be through the Platform, with a third party liquidity provider or with a Cryptizi
            Affiliate) in respect of such Order) shall constitute a binding agreement to an OTC Transaction between you
            and Cryptizi on the terms of such Order. Cryptizi shall notify you of such execution through an Agreed
            Communication Channel but any failure or delay in providing such notice shall not affect the effectiveness
            of such OTC Transaction.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            4.4 Cryptizi provides no guarantee that any Order can be filled in its entirety as this depends on factors
            outside of Cryptizi’s control. Cryptizi may add or remove types of Orders at its sole discretion.
          </Typography>

          <Typography variant='h6' sx={{ mt: 3 }}>
            5. OTC Transactions
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5.1 Unless otherwise agreed, Cryptizi will enter OTC Transactions as principal. You agree that you will
            enter into OTC Transactions as principal and not as agent on behalf of any other party, unless otherwise
            expressly agreed by Cryptizi in writing. Subject to the foregoing, Cryptizi’s duties and obligations under
            this Agreement and any OTC Transaction shall be solely to you and, unless expressly agreed otherwise,
            Cryptizi accepts no duties, obligations or responsibilities to any other party, regardless of whether
            Cryptizi is made aware of any such third-parties.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5.2 You acknowledge and agree that the OTC Services are provided on a non-advised basis, meaning that
            Cryptizi will not provide you with any advice as to the merits of any OTC Transactions. You acknowledge and
            agree that you are solely responsible for any decisions taken in respect of any Trade Instructions, Orders
            or OTC Transactions made. Cryptizi will not provide you with any investment, legal, tax or other form of
            advice, nor can you request any such advice from Cryptizi.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5.3 You acknowledge and agree that the terms in this Agreement shall be further supplemented by Trading
            Rules, where applicable. In the event of any conflict or inconsistency between the terms in this Agreement
            and the Trading Rules, the Trading Rules shall prevail with respect to the services contemplated hereunder
            unless expressly stated otherwise. You further acknowledge and agree that to the extent that you are
            entering into an OTC Transaction in respect of Futures, you shall be deemed to have agreed and accepted, and
            undertake to comply with, the terms of the Futures Service Agreement (the “FSA”), which terms shall apply to
            all OTC Transactions in Futures and shall be deemed to supplement and form part of this Agreement. In the
            event of any conflict or inconsistency between the terms of the FSA and the terms of this Agreement, the
            terms of the FSA shall prevail with respect to OTC Transactions in Futures only.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5.4 You acknowledge and agree that you bear sole responsibility for any reporting of OTC Transactions as may
            be required under applicable law and regulation and that Cryptizi will not provide any infrastructure,
            support or assistance with respect to the reporting of OTC Transactions.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5.5 Cryptizi offers no representation as to or guarantee of liquidity in any Digital Asset and you
            acknowledge and agree that Cryptizi has no ability to control liquidity and that it takes no responsibility
            for any Loss experienced by you which is caused by or contributed to by illiquidity in any Digital Asset.
            You acknowledge and agree that you shall bear the sole responsibility and risk in relation to OTC
            Transactions entered into by you pursuant to this Agreement.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5.6 You acknowledge and agree that, if:
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, ml: 5, textAlign: 'justify' }}>
            A. Cryptizi determines that one or more prices at which one or more OTC Transactions are executed differs
            materially from the prevailing market price for the relevant OTC Transaction(s) at such time, or are
            otherwise erroneous in nature (including without limitation inadvertently presenting a bid price as an offer
            price or vice versa), due to the transmission of one or more incorrect prices by Cryptizi and/or in the
            event of extreme market volatility; or
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, ml: 5, textAlign: 'justify' }}>
            B. Cryptizi has reason to believe that you have executed an OTC Transaction with improper intent or
            determines that there may be suspected market manipulation, including (without limitation), capitalising on
            opportunities where the executable price of a Transaction does not reflect prevailing market rates, or
            taking unfair advantage of the way in which Cryptizi offers prices,
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            then, in each case, Cryptizi is authorised by you (without any payment or penalty due by either Cryptizi or
            you and provided that such action is in compliance with Applicable Law) to either:
          </Typography>

          <ul style={{ marginLeft: 50 }}>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
                cancel/void such OTC Transaction(s) and treat such OTC Transaction(s) as if they had never been entered
                into; or
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                amend the price(s) of such OTC Transaction(s) (upon notice from Cryptizi to you of the amended price(s))
                to reflect the prevailing market price(s) as at the relevant time, as determined by Cryptizi by
                reference to an available source for fair market pricing.
              </Typography>
            </li>
          </ul>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5.7 If Cryptizi cancels an OTC Transaction pursuant to the rights referred to in clause 5.6 above, Cryptizi
            will reverse any transfers of Digital Assets that have been made to and/or from your Account in connection
            with such OTC Transaction as if that OTC Transaction had never taken place. If Cryptizi amends an OTC
            Transaction pursuant to the rights referred to in clause{' '}
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5.6 Cryptizi will confirm the details of the amended terms of the OTC Transaction to you through an Agreed
            Communication channel and will affect such transfers of Digital Assets to and/or from your Account as are
            required to reflect the terms of the amended OTC Transaction. You acknowledge and agree that you shall be
            solely responsible for your own transactions with any third parties that may have been entered into in
            connection with or reliance on any OTC Transaction(s) that may be subject to any cancellation or amendment
            pursuant to Cryptizi’s rights in clause 5.6 above.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5.8 You acknowledge and agree that any Trade Instructions, Orders or OTC Transactions may be subject to risk
            and/or credit limits imposed by Cryptizi from time to time which may be general or specific to you, may
            apply to all or certain Digital Assets only and at all times are imposed, amended, maintained or removed at
            the sole discretion of Cryptizi (“Trading Limits”). Cryptizi may reject, or cancel or amend any Trade
            Instruction or Order that, in its sole discretion, breaches or will breach a Trading Limit. Cryptizi shall
            have no liability to you for any Loss incurred in connection with any Trade Instructions or Orders which are
            rejected, or cancelled or amended by Cryptizi in circumstances where such Orders breach a Trading Limit. You
            undertake to cooperate with Cryptizi and supply all information and data that Cryptizi considers reasonably
            necessary to set appropriate Trading Limits with respect to the OTC Services.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5.9 You further acknowledge and agree that, in the absence of Manifest Error, Cryptizi’s records as to OTC
            Transactions entered into with you shall be final and conclusive. However, Cryptizi may at any time rectify
            any error on any entry, statement, Trade Confirmation or advice which has been proved to its satisfaction,
            and may demand immediate repayment from you of any Digital Assets erroneously paid over to you as a result
            of such error. Any and all objections by you shall not be valid unless accompanied by supporting evidence
            for such objections. You undertake and agree to maintain appropriate records of all Orders and OTC
            Transactions as required pursuant to Applicable Law.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5.10 Subject to Cryptizi’s rights as otherwise set out in this clause 5, a Trade Confirmation which does not
            accurately reflect the relevant OTC Transaction entered into via an Agreed Communication Channel: (a) does
            not affect the validity of the OTC Transaction which the Trade Confirmation evidences; and (b) where there
            is Manifest Error, does not entitle you to enforce whatever is inaccurately recorded in the Trade
            Confirmation.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5.11 Cryptizi reserves the right to void or reverse from the outset any OTC Transaction involving or
            deriving from a Manifest Error or to amend the details of such OTC Transaction to reflect that which
            Cryptizi considers in its sole and absolute discretion acting in good faith to be the correct or fair
            details of such a Transaction absent Manifest Error.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5.12 Without prejudice to the generality of the other terms in this Agreement, and in the absence of fraud
            or bad faith, Cryptizi shall in no circumstances be liable to you for any Loss or demand of whatsoever
            nature you may suffer or incur in connection with any Manifest Error howsoever arising, whether direct or
            indirect, special or consequential, including, but not limited to, loss of profit, loss of opportunity or
            even if Cryptizi had been advised of the possibility of the same arising or that the same were reasonably
            foreseeable.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5.13 Cryptizi shall bear no Loss and/or liability whatsoever for failing to accept any Trade Instruction
            and/or Order for exercising or failing to exercise any discretion, power or authority conferred upon
            Cryptizi by this Agreement. Cryptizi shall not be liable to you for any and all Loss incurred by you arising
            from any loss or delay in the transmission or wrongful interception of any Trade Instruction and/or Order
            through an Agreed Communication Channel, including any equipment or system owned and/or operated by or for
            Cryptizi.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5.14 You agree to pay any applicable fees, expenses, charges and obligations related to your receipt of the
            OTC Services as specified by Cryptizi from time to time, together with any other amounts payable to Cryptizi
            under this Agreement and the Terms of Use.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5.15 All OTC Transactions are settled by way of a transfer of Digital Assets directly to and from your
            Account on a per trade basis. Netting will not be applied between multiple OTC Transactions unless otherwise
            agreed in writing between you and Cryptizi. Cryptizi may agree to permit settlement of OTC Transactions to
            and from an external wallet held by you outside of the Cryptizi infrastructure, however is not obliged to do
            so. If Cryptizi permits settlement of OTC Transactions to an external wallet, you shall be solely liable for
            the payment of all fees and charges associated with such settlement and you undertake to fully reimburse
            Cryptizi on demand for any fees, charges or Loss incurred in connection with such settlement.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5.16 Cryptizi shall be under no obligation to provide valuations of OTC Transactions but if valuations are
            provided, they shall be for information purposes only and are intended solely for your own use. Unless
            expressly stated otherwise, any valuations will be indicative values based on mid-market levels from time to
            time on the date specified. Although valuations represent Cryptizi’s estimate of the current economic value
            of an OTC Transaction based upon mid-market levels, the valuations may also include adjustments to take
            account of, amongst other things, transaction size, market liquidity, and/or use of capital. For certain OTC
            Transactions, it may be necessary to make assumptions relating to future market conditions and derive
            valuations from internal proprietary models. Valuations based on different assumptions and/or models may
            yield different results. Any valuations provided by Cryptizi with respect to OTC Transactions neither
            represent the actual terms at which new OTC Transactions could be entered into nor the actual terms at which
            existing OTC Transactions could be liquidated or unwound. Cryptizi shall not be liable for any errors or
            omissions in computing or disseminating any valuation and shall not be liable for any Loss, claim or damages
            (whether direct, indirect, consequential or otherwise) which may arise to valuations provided to you that
            are used by you or any third party.
          </Typography>

          <Typography variant='h6' sx={{ mt: 3 }}>
            6. Algorithmic Orders
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            6.1 If permitted by Cryptizi, you may submit an Order for Cryptizi to execute an OTC Transaction by means of
            an algorithmic execution tool made available by (and proprietary to) Cryptizi, which will seek to execute
            your Order by means of a pre-determined execution methodology for the relevant algorithm (an “Algo Order”)
            You acknowledge and agree that any execution methodology selected by you in connection with an Algo Order
            will not guarantee that the OTC Transaction requested will be executed in full and that the effectiveness of
            any algorithm may depend on the validity of the assumptions underlying it and prevailing market conditions,
            which may impact these assumptions.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            6.2 You acknowledge and agree that you shall be solely responsible for ensuring that any Algo Order is
            appropriate for your needs and that any algorithm selected will be consistent with any applicable Trading
            Limits or other parameters, limitations or conditions that may apply to your execution of any OTC
            Transaction. Cryptizi is not responsible for your choice of algorithm and shall not be responsible for
            evaluating your goals in selecting any algorithm. The output and offering of any algorithm is not investment
            advice or any recommendation as to the use, appropriateness or suitability of any OTC Transaction to be
            executed or the applicable execution methodology. You acknowledge that your use of any algorithm may be
            limited by Applicable Law and that you shall not provide any Algo Order to Cryptizi unless you have
            determined that it is consistent with Applicable Law.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            6.3 Cryptizi will continue to act as principal to any OTC Transaction executed by means of an algorithm
            selected by you. Cryptizi will either execute Algo Orders against its own internal liquidity or may route
            all or a portion of the Algo Order to the Platform or a third party liquidity provider or execution venue.
            When execution arises by means of a third party liquidity provider or execution venue, Cryptizi will enter
            into the relevant portion of the OTC Transaction on the third party venue as principal and will enter into a
            back-to-back transaction with you to mirror the terms that Cryptizi receives for the portion of the OTC
            Transaction executed on the third party venue.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            6.4 Cryptizi does not guarantee execution of the entire amount of your Algo Order. Your Algo Orders may be
            executed by partial fills at different prices and/or through different execution venues. Any “odd lots”
            (i.e. amounts below the minimum amount tradeable on a particular execution venue) will be directed to all
            execution venues that accept the relevant amount for execution, unless otherwise agreed between us. If
            Cryptizi has been unable to execute the entire amount of your Algo Order, the remaining portion of your Algo
            Order will not be executed. 6.5 You acknowledge and agree that different execution venues may be selected
            for different Algo Order types (for example, as determined by parameters such as the aggressiveness and
            duration of the relevant Algo Orders). In the case where an Algo Order could have an impact on the market
            and/or adversely impact availability of liquidity across the relevant product, Cryptizi may implement
            certain controls in its discretion in relation to the execution of such Algo Order (including, without
            limitation, selecting different execution venues).
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            6.6 Cryptizi charges a fee for Algo Orders, which will be agreed in advance with you. The applicable fee is
            added as a component to the Price of the resultant OTC Transaction(s) executed. Fees charged may vary
            depending on the algorithmic strategies selected.
          </Typography>

          <Typography variant='h6' sx={{ mt: 3 }}>
            7. Representations and warranties
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            7.1 In addition and without prejudice to the representations and warranties made by the Parties in the Terms
            of Use (and, if applicable, the FSA), you hereby represent and warrant as follows each time you enter into
            an OTC Transaction:
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, ml: 5, textAlign: 'justify' }}>
            A. You are capable of assessing the merits of and understanding (on your own behalf or through independent
            professional advice), and understand and accept the risks and the terms and conditions of the relevant OTC
            Transaction and are capable of assuming, and assume, the risks of that OTC Transaction; and
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, ml: 5, textAlign: 'justify' }}>
            B. you are acting for your own account and have made your own independent decisions to enter into the
            relevant OTC Transaction and as to whether the OTC Transaction is appropriate and proper for you based on
            your own judgment and upon advice from such advisors as you have deemed necessary. You are not relying on
            any communication (written or oral) made by or on behalf of Cryptizi as investment advice or as a
            recommendation to enter into the OTC Transaction, it being understood that information or explanations in
            relation to the terms of an OTC Transaction shall not be considered as investment advice or a recommendation
            to enter into that transaction. Cryptizi is not acting as your fiduciary or adviser in connection with that
            OTC Transaction.
          </Typography>

          <Typography variant='h6' sx={{ mt: 3 }}>
            8. Limitations on liability
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            8. Limitations on liability 8.1 You acknowledge and agree that the use of an Agreed Communication Channel in
            connection with the OTC Services is entirely at your own risk and accordingly, you assume full
            responsibility and risk of loss resulting from or relating to such use.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            8.2 Without prejudice to the foregoing and without prejudice to or duplication of any disclaimers,
            limitations or liability and indemnity provisions set out in the Terms of Use (and, if applicable, the FSA),
            and except to the extent that liability cannot be excluded under any applicable law or regulation, you
            acknowledge and agree that:
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            (a) neither Cryptizi nor any of its affiliates, their respective directors, shareholders, member, officers,
            employees, attorneys, agents, representative, suppliers or contractors (“Related Persons”) shall be liable
            for any Loss, damage or claim of any kind whatsoever arising directly or indirectly as a result of: (i) any
            Price provided by Cryptizi; (ii) any errors in or omissions from an Agreed Communication Channel; (iii) any
            failure of an Agreed Communication Channel to transmit, or any delay in the transmission or receipt of any
            Trade Instruction or Order, any rejection or non-execution of any Trade Instruction or Order, or any delay
            by you in the receipt of any notification (including any Trade Confirmation) confirming that any Trade
            Instruction or Order has or has not been executed; or (iv) any unauthorised access to or use of an Agreed
            Communication Channel;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            (b) neither Cryptizi nor any Related Persons shall have any liability arising from Trade Instructions,
            Orders, investment decisions or purchases of any third party goods or services (including financial
            instruments and Digital Assets) based on any Price or any other information provided through an Agreed
            Communication Channel or otherwise;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            (c) under no circumstances shall Cryptizi or any Related Persons be liable for any damages whatsoever,
            whether direct, indirect, punitive, special or consequential, that are directly or indirectly attributable
            to the use of, or inability to use, an Agreed Communication Channel or the OTC Services, even if advised of
            the possibility of such damages or if such damages were foreseeable;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            (d) neither Cryptizi nor any Related Persons shall be liable for any failure to perform any obligation under
            this Agreement or for any delay in the performance thereof, due to force or condition beyond Cryptizi’s
            reasonable control; and
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            (e) the total aggregate liability of Cryptizi and its Related Persons in connection with this Agreement and
            the OTC Services, whether based in contract, tort (including negligence or strict liability), or otherwise,
            will not exceed [one hundred thousand U.S. Dollars (USD 100,000). The foregoing limitations will apply
            notwithstanding any failure of essential purpose of any limited remedy.
          </Typography>

          <Divider sx={{ mt: 8, mb: -6 }} />
        </Container>

        <Footer />
      </RootStyle>
    </Stack>
  );
}

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  width: '100%',
  marginTop: 130,

  direction: theme.direction === 'rtl' ? 'rtl' : 'ltr',
}));
