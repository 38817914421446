import { Stack } from '@mui/material';

import { RHFTextField } from '../../../../components/hookForm';
import { useTranslation } from 'react-i18next';

const days = [
  { name: 'satTime', label: 'Sat' },
  { name: 'sunTime', label: 'Sun' },
  { name: 'monTime', label: 'Mon' },
  { name: 'tueTime', label: 'Tue' },
  { name: 'wedTime', label: 'Wed' },
  { name: 'thuTime', label: 'Thu' },
  { name: 'friTime', label: 'Fri' },
];

const WorkingHours = () => {
  const { t } = useTranslation();
  return (
    <Stack
      direction='row'
      justifyContent={'space-between'}
      flexWrap={'wrap'}
      sx={{ width: { xs: '100%', md: 680 }, mt: 5 }}
    >
      {days.map((day, index) => (
        <RHFTextField
          key={index}
          name={day.name}
          label={t(day.label)}
          variant='standard'
          fullWidth
          sx={{ maxWidth: 320, mb: 2.8 }}
        />
      ))}
    </Stack>
  );
};

export default WorkingHours;
