import React from 'react';
import { IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import moment from 'jalali-moment';
import Iconify from '../../../components/Iconify';
import SymbolIcon from '../../../components/SymbolIcon';
import { prettyUppercases } from '../../../utils';

const DedicatedWalletSettingsTableRow = ({ row, onShowModalDetail , handleEdit}) => {

  const {
    name,
    symbol,
    network,
    withdrawFee
  } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 2, typography: 'body2', color: 'grey.100' } }}>
      <TableCell>{name}</TableCell>

      <TableCell align='left'>
        <Stack
          direction={'row'} justifyContent={'start'} alignItems={'center'} spacing={1}
        >
          <SymbolIcon symbol={symbol} width={30} height={30} />
          <Typography variant='body4' color={'grey.100'}>
            { symbol}
          </Typography>

        </Stack>


      </TableCell>
      <TableCell align='center'>{withdrawFee }</TableCell>
      <TableCell align='center'>{network }</TableCell>
      <TableCell align='center' sx={{ width: 100 }}>
        <IconButton onClick={onShowModalDetail}>
          <Iconify icon='eva:eye-outline' />
        </IconButton>
      </TableCell>
      <TableCell align='center' sx={{ width: 100 }}>
        <IconButton onClick={() => handleEdit(row)}>
          <Iconify icon='eva:edit-2-outline'  />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default DedicatedWalletSettingsTableRow;