import { IconButton, Stack, Typography } from '@mui/material';

import InfoIcon from '../../assets/info-circle.svg';
import Iconify from '../Iconify';
import Api from '../../http/serviceApi';

// ----------------------------------------------------------------------

const COLORS = {
  BLACK: '#A8A8A9',
  GREEN: '#11CABE',
  RED: '#FA2256',
  YELLOW: '#F27F16',
};

export default function NotificationFixed({ list = [], allowSeen = false, onReload }) {
  const onSeen = async (id) => {
    try {
      const response = await Api.seenNotification(id);
      if (response.status === 'SUCCESS') {
        onReload();
      }
    } catch (e) {}
  };

  return (
    <>
      {list.map((item) => (
        <Stack
          key={item.id}
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            mx: { xs: 2, lg: 0 },
            mb: 2.5,
            mt: -1,
            px: 2,
            py: 1,
            borderRadius: 1.5,
            bgcolor: COLORS?.[item.color] || '',
          }}
        >
          <Stack direction='row' alignItems='center' spacing={1}>
            <img src={InfoIcon} alt='' style={{ width: 20, height: 20 }} />
            <Typography variant='body2' color='#fff'>
              {item.message}
            </Typography>
          </Stack>

          {allowSeen && (
            <IconButton onClick={() => onSeen(item.id)}>
              <Iconify icon='akar-icons:cross' color='#fff' sx={{ width: 10, height: 10 }} />
            </IconButton>
          )}
        </Stack>
      ))}
    </>
  );
}
