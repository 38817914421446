import { useState } from 'react';
import { Checkbox, Stack, Typography } from '@mui/material';

import SymbolIcon from '../../../../components/SymbolIcon';

const RowAddFavorite = ({ item, onChange, selected }) => {
  const [checked, setChecked] = useState(selected);

  const onChecked = (e) => {
    setChecked(e.target.checked);
    onChange(item.symbol, e.target.checked ? 'add' : 'delete');
  };

  return (
    <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}>
      <Stack direction='row' alignItems={'center'} spacing={1}>
        <SymbolIcon symbol={item.symbol} />

        <Typography variant='subtitle2'>{item.symbol}</Typography>
        <Typography variant='body3' color='grey.300' noWrap>
          {item.name}
        </Typography>
      </Stack>
      <Checkbox sx={{ '& svg': { fill: checked ? '#30E0A1' : '#A5ADCF' } }} checked={checked} onChange={onChecked} />
    </Stack>
  );
};

export default RowAddFavorite;
