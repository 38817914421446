//
import Button from './Button';
import Checkbox from './Checkbox';
import ControlLabel from './ControlLabel';
import CssBaseline from './CssBaseline';
import Input from './Input';
import Link from './Link';
import LoadingButton from './LoadingButton';
import Typography from './Typography';
import Card from './Card';
import Table from './Table';
import Backdrop from './Backdrop';
import Select from './Select';
import Tabs from './Tabs';
import Radio from './Radio';
import Paper from './Paper';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return Object.assign(
    Button(theme),
    CssBaseline(theme),
    LoadingButton(theme),
    Typography(theme),
    ControlLabel(theme),
    Input(theme),
    Link(theme),
    Checkbox(theme),
    Card(theme),
    Table(theme),
    Backdrop(theme),
    Select(theme),
    Tabs(theme),
    Radio(theme),
    Paper(theme)
  );
}
