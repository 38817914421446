import { Container, Typography, Button, Box, useTheme } from '@mui/material';
import { m } from 'framer-motion';
import useUserAuth from '../../hooks/useUserAuth';
import LoadingScreen from '../../components/LoadingScreen';
import TopMenu from '../landing/sections/topMenu';
import Footer from '../landing/sections/footer';
import { varFade } from '../../components/animate/variants/fade';
import { useTranslation } from 'react-i18next';
import listingImage from '../../assets/landing/listing.png';
import { BannerContentStyle, HeroImgStyle } from '../landing/style';
import { Stack } from 'rsuite';
import styled from 'styled-components';

export default function Listing() {
  const { t } = useTranslation();
  const theme = useTheme();

  const { loading } = useUserAuth(false);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Stack sx={{ minHeight: 1 }}>
      <TopMenu />

      <RootStyle theme={theme}>
        <HeroImgStyle alt='hero' src={listingImage} variants={varFade().inUp} />
        <Container>
          <BannerContentStyle>
            <m.div variants={varFade().inRight}>
              <Typography
                variant='h3'
                sx={{
                  color: 'common.white',
                  textTransform: 'uppercase',
                  fontWeight: '600',
                  width: { xs: '100%', md: 665 },
                  display: 'inline-block',
                }}
              >
                {t('Listing')}
              </Typography>
            </m.div>

            <m.div variants={varFade().inRight}>
              <Typography
                variant='h6'
                sx={{ color: 'grey.300', width: { xs: '100%', md: '80%' }, fontWeight: 'normal' }}
              >
                {t(
                  "Cryptizi offers a powerful platform to take advantage of a broad user base and solid infrastructure, improving your token's market visibility and accessibility."
                )}
              </Typography>
            </m.div>

            <m.div variants={varFade().inRight}>
              <Button
                sx={{
                  mt: { xs: 2, md: 6 },
                  borderRadius: 1,
                  width: { xs: '100%', md: 'auto', px: '30px' },
                  height: 45,
                }}
                size='large'
                variant='contained'
                color='success'
                target='_blank'
                href={'https://forms.gle/6UdTmSeH75pYxrKF9'}
              >
                <Typography variant='subtitle1'>{t('Listing Application')}</Typography>
              </Button>
            </m.div>

            <m.div variants={varFade().inRight}>
              <Typography
                mt={4}
                variant='h5'
                sx={{
                  //   color: 'common.white',
                  //   textTransform: 'uppercase',
                  fontWeight: 600,
                  //   width: { xs: '100%', md: 665 },
                  //   display: 'inline-block',
                }}
              >
                {t('Application Process')}
              </Typography>
            </m.div>
            <m.div variants={varFade().inRight}>
              <Typography
                variant='h6'
                sx={{ color: 'grey.300', width: { xs: '100%', md: '100%' }, fontWeight: 'normal' }}
              >
                {t(
                  `To apply for listing, simply click on 'Listing Application'. Our business manager will promptly get in touch with you.`
                )}
              </Typography>
              <Typography
                variant='h6'
                sx={{ color: 'grey.300', width: { xs: '100%', md: '97%' }, fontWeight: 'normal', mt: 2 }}
              >
                {t(`For any queries, reach out to us at`)}{' '}
                <a href='mailto:listing@cryptizi.com'>listing@cryptizi.com</a>. {t(`Thank you!`)}
              </Typography>
            </m.div>
          </BannerContentStyle>
        </Container>
        <Footer />
      </RootStyle>
    </Stack>
  );
}

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  width: '100%',
  marginTop: 130,

  direction: theme.direction === 'rtl' ? 'rtl' : 'ltr',
}));
