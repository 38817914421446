import React from 'react';
import { TableRow, TableCell, Typography, Box, Stack, Button } from '@mui/material';
import SymbolIcon from '../../../components/SymbolIcon';
import { useTranslation } from 'react-i18next';
import { navigationUrl } from '../../../navigationUrl';

const FutureWalletAssetDetailsRow = ({ row, setShowModal, setSelectedSymbol }) => {

  const { name, symbol, totalAssets, positionMargin, orderMargin, unrealizedPNL, availableBalance, action } = row;

  const { t } = useTranslation();

  return (
    <TableRow hover sx={{ '& td': { py: 1, typography: 'body2' } }}>
      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <SymbolIcon symbol={symbol} />
          <Typography
            variant='body2'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 140,
              whiteSpace: 'nowrap',
              display: 'block',
              ml: 2,
            }}
          >
            {name}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell align='center'>{totalAssets}</TableCell>
      <TableCell align='center'>{positionMargin}</TableCell>
      <TableCell align='center'>{orderMargin}</TableCell>
      <TableCell align='center'>{unrealizedPNL}</TableCell>
      <TableCell align='center'>{availableBalance}</TableCell>
      <TableCell align='center'>

        <Button color='success'  onClick={() => setShowModal(true)}>
          <Typography color='success.main' variant='body3'>
            {t('Transfer')}
          </Typography>
        </Button>
      </TableCell>



    </TableRow>
  );
};

export default FutureWalletAssetDetailsRow;