import {useEffect} from "react";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

export default function useGatewayForm(data) {

  const validators = Yup.object().shape({
    active: Yup.boolean(),
    // business: Yup.string().notRequired(),
    walletId: Yup.string().notRequired(),
    gatewayDepositModel: Yup.string().notRequired(),
    mobile: Yup.string().notRequired(),
    token: Yup.string().notRequired(),
    satTime: Yup.string().notRequired(),
    sunTime: Yup.string().notRequired(),
    monTime: Yup.string().notRequired(),
    tueTime: Yup.string().notRequired(),
    wedTime: Yup.string().notRequired(),
    thuTime: Yup.string().notRequired(),
    friTime: Yup.string().notRequired(),
  });

  const defaultValues = {
    active : data.active || false,
    // business:  data.business || '',
    walletId: data.walletId || "",
    gatewayDepositModel:data.gatewayDepositModel || "",
    mobile: data.mobile || "",
    token: data.token || "",
    satTime: data.satTime || "",
    sunTime: data.sunTime || "",
    monTime: data.monTime || "",
    tueTime: data.tueTime || "",
    wedTime: data.wedTime || "",
    thuTime: data.thuTime || "",
    friTime: data.friTime || "",
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = methods;

  useEffect(() => {
    if (data) {
      reset(defaultValues);
    }
    if (!data) {
      reset(defaultValues);
    }
  }, [data]);

  return {
    handleSubmit,
    isSubmitting,
    methods,
    isActive: watch('active'),
  };
}
