import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Card, Stack, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { FormProvider, RHFTextField } from '../../../../components/hookForm';
import useCryptoForm from './hooks/useCryptoForm';
import useCryptoSubmit from './hooks/useCryptoSubmit';
import Image from '../../../../components/Image';
import cryptoLogo from '../../../../assets/inventories/binance.png';
import { PageTitle } from '../../../../components/PageTitle';
import Api from '../../../../http/adminHttp/serviceApiAdmin';

const CryptoCurrencySetting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fetchReferenceAccountInfo = async () => {
    const response = await Api.getReferenceAccountInfo();
    setCryptoDat(response);
  };

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Settings Cryptocurrency' },
    });

    fetchReferenceAccountInfo();
  }, []);

  const [cryptoData, setCryptoDat] = useState({});

  const { handleSubmit, isSubmitting, methods } = useCryptoForm(cryptoData);
  const { onSubmit } = useCryptoSubmit();

  return (
    <Stack sx={{ padding: { xs: '0 20px 20px', lg: '0 0 97px' } }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ mt: 5, px: { xs: 2, sm: 4 }, py: { xs: 3, sm: 4 } }}>
          <Stack spacing={2.5} direction='row' sx={{ mb: 5 }} alignItems='center'>
            <Image src={cryptoLogo} width={48} height={48} />
            <Box>
              <PageTitle title={t('Settings related to cryptocurrency reference account')} subTitle='Caption' />
            </Box>
          </Stack>
          <Stack sx={{ width: { xs: '100%', md: '75%' } }} spacing={3}>
            <RHFTextField name={'binanceApiValue'} label={t('Binance apikey')} variant='standard' fullWidth />
            <RHFTextField name={'binanceSecValue'} label={t('Binance seckey')} variant='standard' fullWidth />
          </Stack>
        </Card>
        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ mt: 5, maxWidth: 283 }}
        >
          {t('Save')}
        </LoadingButton>
      </FormProvider>
    </Stack>
  );
};
export default CryptoCurrencySetting;
