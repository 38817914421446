import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Api from '../../../../../http/adminHttp/serviceApiAdmin';
import { navigationUrl } from '../../../../../navigationUrl';

export default function useAdminSubmit(isEdit, userId = null) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const onSubmit = async (payload) => {
    try {
      const finalPayload = {
        emailAddress: payload.emailAddress,
        firstName: payload.firstName,
        lastName: payload.lastName,
        mobileNumber: payload.mobileNumber,
        adminAccessInfos: [
          { accessType: 'ORDERS', status: payload.ORDERS },
          { accessType: 'TICKETS', status: payload.TICKETS },
          { accessType: 'USERS', status: payload.USERS },
          { accessType: 'SETTING', status: payload.SETTING },
          { accessType: 'NOTIFICATION', status: payload.NOTIFICATION },
          { accessType: 'API_SETTING', status: payload.API_SETTING },
          { accessType: 'ACADEMY', status: payload.ACADEMY },
          { accessType: 'ADMINS', status: payload.ADMINS },
          { accessType: 'REFERRALS', status: payload.REFERRALS },
          { accessType: 'REPORTING', status: payload.REPORTING },
          { accessType: 'NETWORK_SETTING', status: payload.NETWORK_SETTING },
          { accessType: 'USERS_WAITING_FOR_VERIFY', status: payload.USERS_WAITING_FOR_VERIFY },
          { accessType: 'FIAT_TRANSACTION_WAITING', status: payload.FIAT_TRANSACTION_WAITING },
          { accessType: 'LIST_OF_ALL_BANK_CARD', status: payload.LIST_OF_ALL_BANK_CARD },
          { accessType: 'WAITING_ACTIONS', status: payload.WAITING_ACTIONS },
          { accessType: 'TOKEN_LISTING', status: payload.TOKEN_LISTING },
          { accessType:  'MAIN_WALLET' , status: payload?.MAIN_WALLET},
          { accessType:  'GAS_CONFIG' , status: payload?.GAS_CONFIG},
          { accessType:  'DEDICATED_WALLET' , status: payload?.DEDICATED_WALLET}


        ],
      };

      console.log(payload , finalPayload)

      if (isEdit) {
        if (payload.password) {
          finalPayload.password = payload.password;
        }

        const response = await Api.updateAdmin({
          ...finalPayload,
          userId,
          twoStepLogin: payload.twoStepLogin === 'Active',
        });
        if (response?.status === 'SUCCESS') {
          enqueueSnackbar(t('The admin account is updated successfuly'), { variant: 'success' });
        }
      } else {
        const response = await Api.insertAdmin({ ...finalPayload, password: payload.password });
        if (response?.status === 'SUCCESS') {
          enqueueSnackbar(t('The admin account is inserted successfuly'), { variant: 'success' });
          navigate(navigationUrl.admin_list);
        }
      }
    } catch (e) {}
  };

  return {
    onSubmit,
  };
}
