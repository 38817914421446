import React from 'react';
import { Table } from 'antd';
import './marketTradeTableAntdStyles.scss';
import { convertTimeStampToTime } from '../../../../utils/dateConverter';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';

const MarketTradeTable = (props) => {
  const theme = useTheme();
  // const [orderBook, setOrderBook] = useState(null);
  // const [loading, setLoading] = useState(true)
  const orderBook = useSelector((store) => store.orderBook);
  const loading_OrderBook = useSelector((store) => store.loading_OrderBook);

  const columns = [
    {
      title: `Price(${props.symbolInfo?.quoteAsset})`,
      dataIndex: 'p',
      key: 'p',
      align: 'left',
      render: (key, record) => <span className={record.m ? 'priceRed' : 'priceGreen'}>{key}</span>,
    },
    {
      title: `Amount(${props.symbolInfo?.baseAsset})`,
      dataIndex: 'a',
      key: 'a',
      align: 'right',
    },
    {
      title: 'Time',
      dataIndex: 'd',
      key: 'd',
      align: 'right',
      render: (key, record) => <span>{convertTimeStampToTime(key)}</span>,
    },
  ];

  return (
    <div>
      <Table
        direction='horizental'
        className={theme.palette.mode === 'dark' ? 'marketTradeTableStyle' : 'marketTradeTableStyleLight'}
        dataSource={orderBook?.tradeInfos}
        columns={columns}
        rowKey=''
        pagination={false}
        bordered={false}
        loading={loading_OrderBook}
        scroll={{ x: 280, y: 150 }}
      />
    </div>
  );
};

export default MarketTradeTable;
