import { TableRow, TableCell, Typography } from '@mui/material';

import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import { fFloat } from '../../../../utils/formatNumber';

// ----------------------------------------------------------------------

export default function FuturesTradeHistoryTableRow({ row }) {
  const language = useSelector((store) => store.language);

  const {
    amount,
    commission,
    commissionAsset,
    createdDate,
    executed,
    isMaker,
    orderId,
    price,
    side,
    state,
    stopPrice,
    symbol,
    total,
    transactionDate,
    type,
    updateTime,
  } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 2, typography: 'body2', color: 'grey.100' } }}>
      <TableCell>{moment(createdDate).locale(language).format('YYYY-MM-DD HH:mm:ss')}</TableCell>

      <TableCell align='center'>{symbol}</TableCell>

      <TableCell align='center'>{type}</TableCell>

      <TableCell align='center'>
        <Typography variant='body3' color={side === 'BUY' ? 'positive.main' : 'negative.main'}>
          {side}
        </Typography>
      </TableCell>

      <TableCell align='center'>{fFloat(price)}</TableCell>

      <TableCell align='center'>{fFloat(amount)}</TableCell>

      <TableCell align='center'>{fFloat(commission)}</TableCell>

      <TableCell align='center'>{state}</TableCell>
    </TableRow>
  );
}
