import { useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { DatePicker as XDatePicker } from '@mui/x-date-pickers';
import Iconify from './Iconify';
import { useTranslation } from 'react-i18next';

export default function DatePicker({ label, value, setValue, error, minDate, maxDate, ...other }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <XDatePicker
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderInput={(props) => (
        <TextField
          {...props}
          error={false}
          variant='standard'
          onClick={(e) => setOpen(true)}
          helperText={error?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Iconify icon={'akar-icons:calendar'} sx={{ width: 23, height: 23, color: 'grey.100', mb: 2, mr: 1 }} />
              </InputAdornment>
            ),
          }}
          {...other}
        />
      )}
      label={t(label)}
      value={value}
      onChange={setValue}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
}
