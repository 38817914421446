import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';
import {
  Box,
  List,
  Link,
  Drawer,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  IconButton,
  Stack,
  Typography, Button,
} from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import NavSectionVertical from '../../../components/sideMenu/navbar';
import Image from '../../../components/Image';
import { useTranslation } from 'react-i18next';
import { navigationUrl } from '../../../navigationUrl';
import { useSelector } from 'react-redux';
import LanguagePopover from '../../../components/mainNavBar/languagePopover';
import importIcon from '../../../assets/landing/import.svg';
import importLightIcon from '../../../assets/landing/import_light.svg';

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

MenuMobile.propTypes = {
  isOffset: PropTypes.bool,
  isHome: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function MenuMobile({ isOffset, isHome, navConfig }) {
  const theme = useTheme();

  const { pathname } = useLocation();
  const user = useSelector((store) => store.userInfo);
  const isLoggedIn = !!user?.userId
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (drawerOpen) {
      handleDrawerClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const [open, setOpen] = useState(-1);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleDrawerOpen}
        sx={{
          mr: 1,
          ...(isHome && { color: 'common.white' }),
          ...(isOffset && { color: 'text.primary' }),
        }}
      >
        <Iconify icon={'eva:menu-2-fill'} />
      </IconButton>

      <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: { px: 3, py: 3, width: "100%", backgroundImage: 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)'} }}
      >
        <Scrollbar>
          <Stack
            direction={theme.direction === 'rtl' ? 'row-reverse' : 'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            spacing={1}
            sx={{ mr: 1, mb: 4 }}
          >
            <IconButton
              onClick={handleDrawerClose}
              sx={{
                mr: 1,
                ...(isHome && { color: 'common.white' }),
                ...(isOffset && { color: 'text.primary' }),
              }}
            >
              <Iconify icon={'eva:menu-2-fill'} />
            </IconButton>

            <Image src={'/assets/logo.png'} sx={{ width: 32 }} disabledEffect />


          </Stack>
          {!isLoggedIn && (
            <Stack
              direction={theme.direction === 'rtl' ? 'row-reverse' : 'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              spacing={1}
              sx={{ mx: 1, mb: 4 }}
            >
              <Button
                sx={{ borderRadius: 0, width: '48%', height: 47, px: 0 }}
                size='large'
                variant='outlined'
                color='success'
                component={RouterLink}
                to={navigationUrl.register}
              >
                <Typography variant='body3_secondary' color='positive.main'>
                  {t('Sign up')}
                </Typography>
              </Button>

              <Button
                sx={{ borderRadius: 0, width: '48%', height: 47, px: 0 }}
                size='large'
                variant='contained'
                color='success'
                component={RouterLink}
                to={navigationUrl.panel}
              >
                <Typography variant='body3_secondary'>{t('Log in')}</Typography>
              </Button>
            </Stack>
          )}

          <Stack
            direction={theme.direction === 'rtl' ? 'row-reverse' : 'row'}
            alignItems={'center'}
            justifyContent={'start'}
            spacing={1}
            sx={{ mx: 1, mb: 4 }}
            >
            <Typography variant='body2' color='grey.100'>
               <LanguagePopover mobile={true}/>
            </Typography>
            <Stack
              sx={{ width: 57, height: 40, backgroundColor: 'grey.700', borderRadius: 0.5 }}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Typography variant='body2' color='grey.100'>
                USD
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={theme.direction === 'rtl' ? 'row-reverse' : 'row'}

            sx={{ width: '60%', height: 40, backgroundColor: 'grey.700', borderRadius: 0.5 }}
            alignItems={'center'}
            justifyContent={'center'}
          >
            DownLoad App
            <Image src={theme.palette.mode === 'dark' ? importIcon : importLightIcon} alt='' sx={{ml:4}} />
          </Stack>
          <List disablePadding>
            {navConfig.map((link , index) => (
              open === index || open === -1 ?
              <MenuMobileItem key={link.title} item={link} setOpen={setOpen} open={open === index} index={index}/> : null
            ))}
          </List>
        </Scrollbar>
      </Drawer>
    </>
  );
}

// ----------------------------------------------------------------------

MenuMobileItem.propTypes = {
  isOpen: PropTypes.bool,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

function MenuMobileItem({ item , setOpen,open, index }) {
  const { t } = useTranslation();
  const { title, path, icon, children } = item;

  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';

  // const [open, setOpen] = useState(false);

  const handleOpen = () => {

    if(open ) {
      setOpen(-1);
    }else {
      setOpen(index);
    }
  };

  if (children) {
    return (
      <>
        <ListItemStyle onClick={handleOpen}>
          {/* <ListItemIcon>
            {icon && (
              <Image
                src={theme.palette.mode === 'dark' ? icon : icon.replace('.svg', '-light.svg')}
                alt=''
                sx={{ height: 20 }}
              />
            )}
          </ListItemIcon> */}

          <ListItemText disableTypography primary={t(title || 'Dashboard')} sx={{ color: 'grey.0' }} />
          {isRTL ? (
            <Iconify
              icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-back-fill'}
              sx={{ width: 16, height: 16, ml: 1, color: 'grey.0' }}
            />
          ) : (
            <Iconify
              icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
              sx={{ width: 16, height: 16, ml: 1, color: 'grey.0' }}
            />
          )}
        </ListItemStyle>

        <Collapse in={open} timeout='auto' unmountOnExit>
          <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
            <NavSectionVertical
              navConfig={children}
              // sx={{
              //   '& .MuiList-root:last-of-type .MuiListItemButton-root': {
              //     height: 200,
              //     backgroundSize: 'cover',
              //     backgroundPosition: 'center',
              //     bgcolor: 'background.neutral',
              //   },
              // }}
            />
          </Box>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      to={path}
      component={RouterLink}
      end={path === '/'}
      sx={{
        '&.active': {
          color: 'primary.main',
          fontWeight: 'fontWeightMedium',
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      }}
    >
      {/* <ListItemIcon>
        {icon && (
          <Image
            src={theme.palette.mode === 'dark' ? icon : icon.replace('.svg', '-light.svg')}
            alt=''
            sx={{ height: 20 }}
          />
        )}
      </ListItemIcon> */}
      <ListItemText disableTypography primary={t(title)} sx={{ color: 'grey.0' }} />
    </ListItemStyle>
  );
}
