import { Stack, Card, Box, TablePagination, TableBody, TableContainer, Table, MenuItem } from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../../../../components/PageTitle';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../components/table';
import Scrollbar from '../../../../components/Scrollbar';
import useTable from '../../../../hooks/useTable';
import confirmWrapper from '../../../../utils/confirm';
import { useSnackbar } from 'notistack';
import WaitingWithdrawFiatTableRow from './waitingWithdrawFiatTableRow';
import WaitingWithdrawFiatModal from './waitingWithdrawFiatModal';
import { FormProvider, RHFSelect, RHFTextField } from '../../../../components/hookForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Typography } from 'antd';
import { TRANSFER_METHODS } from '../../../../constants/transferMethods';

// ===================================================================

const TABLE_HEAD = [
  { id: 'createDate', label: 'Date', align: 'left' },
  { id: 'user', label: 'User', align: 'left' },
  { id: 'amount', label: 'Amount', align: 'center' },
  { id: 'fiatType', label: 'Fiat Type', align: 'left' },
  { id: 'account', label: 'Account', align: 'left' },
  { id: 'trackingCode', label: 'Tracking Code', align: 'left' },
  { id: 'actions', label: 'Actions', align: 'center' },
];

// ===================================================================

const WaitingWithdrawFiat = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  // --------------------
  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Fiat withdraw transaction waiting' },
    });
  }, []);

  // --------------------
  const [tableData, setTableData] = useState(null);
  const [exchangeAccounts, setExchangeAccounts] = useState([]);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });
  const [showModalDetail, setShowModalDetail] = useState(null);

  // --------------------
  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  // --------------------
  const getList = async () => {
    try {
      const response = await Api.getFiatWithdraw();
      if (response) {
        setTableData(response.fiatWithdrawInfos);
        setTablePagination({ totalItems: response.fiatWithdrawInfos.length });
      }
    } catch (e) {}
  };

  const getExchangeAccounts = async () => {
    try {
      const response = await Api.exchangeAccountForDeposit();
      if (response) {
        setExchangeAccounts(response.depositReceiptBankAccounts);
      }
    } catch (e) {}
  };

  // --------------------
  useEffect(() => {
    getList();
    getExchangeAccounts();
  }, []);

  // --------------------

  const acceptFormValidators = Yup.object().shape({
    adminAccountNumber: Yup.string().required(),
    transferMethod: Yup.string().required(),
    trackingCode: Yup.string().required(),
    message: Yup.string(),
  });

  const acceptForm = useForm({
    resolver: yupResolver(acceptFormValidators),
  });

  const onAccept = async (id) => {
    acceptForm.setValue('trackingCode', showModalDetail?.trackingCode);
    if (
      !(await confirmWrapper(
        t(`Are you sure to accept this transaction?`),
        <FormProvider methods={acceptForm} autoComplete='off'>
          <RHFSelect
            name='transferMethod'
            SelectProps={{
              native: false,
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' } },
              },
            }}
            sx={{ minWidth: 200, mb: 2 }}
            label={t('Type of Card')}
            variant='standard'
            fullWidth
          >
            {TRANSFER_METHODS.map((type, index) => (
              <MenuItem value={type.value} key={index}>
                <Typography typography='body3' color='grey.300'>
                  {t(type.label)}
                </Typography>
              </MenuItem>
            ))}
          </RHFSelect>
          <RHFSelect
            name='adminAccountNumber'
            SelectProps={{
              native: false,
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' } },
              },
            }}
            sx={{ minWidth: 200, mb: 2 }}
            label={t('Account')}
            variant='standard'
            fullWidth
          >
            {exchangeAccounts?.map((type, index) => (
              <MenuItem value={type.accountNumber} key={index}>
                <Typography typography='body3' color='grey.300'>
                  {t(type.accountNumber)}
                </Typography>
              </MenuItem>
            ))}
          </RHFSelect>
          <RHFTextField
            name='trackingCode'
            label={t('Tracking code')}
            sx={{ minWidth: 200, mb: 2 }}
            variant='standard'
            fullWidth
          />
          <RHFTextField name='message' label={t('Message')} variant='standard' fullWidth />
        </FormProvider>
      ))
    ) {
      return false;
    }
    try {
      const response = await Api.acceptFiatWithdraw(id, {
        ...acceptForm.control._formValues,
      });
      if (response?.status === 'SUCCESS') {
        getList();
        enqueueSnackbar(t('The transaction is accepted successfuly'), { variant: 'success' });
      }
    } catch (e) {
    } finally {
      acceptForm.reset();
      setShowModalDetail(null);
    }
    return true;
  };

  // --------------------
  const denyFormValidators = Yup.object().shape({
    message: Yup.string(),
  });

  const denyForm = useForm({
    resolver: yupResolver(denyFormValidators),
  });

  const onDeny = async (id) => {
    if (
      !(await confirmWrapper(
        t(`Are you sure to deny this transaction?`),
        <FormProvider methods={denyForm} autoComplete='off'>
          <RHFTextField name='message' label={t('Message')} variant='standard' fullWidth />
        </FormProvider>
      )) ||
      denyForm.control.getFieldState().invalid
    ) {
      return false;
    }
    try {
      const response = await Api.denyFiatWithdraw(id, denyForm.control._formValues.message);
      if (response?.status === 'SUCCESS') {
        getList();
        enqueueSnackbar(t('The transaction is denied successfully'), { variant: 'success' });
      }
    } catch (e) {
      console.error(e);
    } finally {
      denyForm.reset();
      setShowModalDetail(null);
    }
    return true;
  };

  // --------------------
  return (
    <Stack sx={{ padding: { xs: '0 20px 20px', lg: '0 0 97px' }, mt: 0.5 }}>
      <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 } }}>
        <PageTitle
          title='Fiat withdraw transaction waiting'
          subTitle='You can see the list of fiat transactions whose information must be confirmed.'
        />

        <Scrollbar sx={{ mt: 3 }}>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size='small'>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length || 0}
                onSort={onSort}
              />

              <TableBody>
                {tableData === null ? (
                  <TableLoading isLoading />
                ) : (
                  <>
                    {tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => (
                      <WaitingWithdrawFiatTableRow
                        key={row.userId}
                        row={row}
                        index={index}
                        onShowModalDetail={() => setShowModalDetail(row)}
                      />
                    ))}

                    <TableNoData isNotFound={!tableData?.length} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        {tableData?.length > 0 && (
          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              component='div'
              count={tablePagination.totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              labelRowsPerPage={t('Rows per page')}
              labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
            />
          </Box>
        )}
        {showModalDetail && (
          <WaitingWithdrawFiatModal
            open={!!showModalDetail}
            data={showModalDetail}
            onClose={() => setShowModalDetail(null)}
            onAccept={() => onAccept(showModalDetail.id)}
            onDeny={() => onDeny(showModalDetail.id)}
          />
        )}
      </Card>
    </Stack>
  );
};

export default WaitingWithdrawFiat;
