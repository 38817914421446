import { Button, Row, Col, Collapse } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck, AiOutlineCaretRight, AiOutlineCaretDown } from 'react-icons/ai';

import classes from './accountSettingTwoStep.module.scss';
import { InputCustom } from '../../../../components/InputCustom/inputCustom';
import iconCopy from '../../../../assets/copy.svg';
import UserApi from '../../../../http/serviceApi';
import AdminApi from '../../../../http/adminHttp/serviceApiAdmin';
import Api from '../../../../http/serviceApi';
import { SET_USERINFO } from '../../../../store/actionTypes';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';

const { Panel } = Collapse;
const activationType = {
  SOFT_OTP: 'SOFT_OTP',
  SMS_OTP: 'SMS_OTP',
  EMAIL_OTP: 'EMAIL_OTP',
};

const AccountSettingTwoStep = ({ isAdmin = true }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [googleCode, setGoogleCode] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [emailCode, setEmailCode] = useState('');
  const [currentActivation, setCurrentActivation] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [secretCode, setSecretCode] = useState('');
  const userInfo = useSelector((store) => store.userInfo);

  const onGoogleCodeChange = (e) => {
    setGoogleCode(e.target.value);
  };
  const onSmsCodeChange = (e) => {
    setSmsCode(e.target.value);
  };
  const onEmailCodeChange = (e) => {
    setEmailCode(e.target.value);
  };

  const colStyle = {
    marginRight: '20px',
    marginBottom: '20px',
  };

  const colPropsInput = {
    xs: 24,
    sm: 24,
    md: 10,
    lg: 8,
    xl: 8,
  };

  const btnStyle = {
    type: 'primary',
    shape: 'round',
  };

  const btnCancelStyle = {
    type: 'danger',
    shape: 'round',
  };

  const onCollapseChange = async (key) => {
    setCurrentActivation(key);

    if (key === activationType.SOFT_OTP) {
      const response = await preTwoFaActivation(
        {
          activationType: activationType.SOFT_OTP,
        },
        !userInfo?.softOtpAuthEnabled
      );
      if (response?.status === 'SUCCESS') {
        setQrCode(response.qrCode);
        setSecretCode(response.secret);
      }
    }
  };

  const preTwoFaActivation = async (data, doActive) => {
    let response;

    if (doActive) {
      if (isAdmin) {
        response = await AdminApi.preTwoFaActivation(data);
      } else {
        response = await UserApi.preTwoFaActivation(data);
      }
    } else {
      if (isAdmin) {
        response = await AdminApi.preTwoFaDeActivation(data);
      } else {
        response = await UserApi.preTwoFaDeActivation(data);
      }
    }

    return response;
  };

  const twoFaActivation = async () => {
    let otp = '';
    let doActive = true;

    switch (currentActivation) {
      case activationType.SOFT_OTP: {
        otp = googleCode;
        doActive = !userInfo?.softOtpAuthEnabled;
        break;
      }
      case activationType.SMS_OTP: {
        otp = smsCode;
        doActive = !userInfo?.smsAuthEnabled;
        break;
      }
      case activationType.EMAIL_OTP: {
        otp = emailCode;
        doActive = !userInfo?.emailAuthEnabled;
        break;
      }
      default:
        break;
    }

    const data = doActive
      ? {
          activationType: currentActivation,
        }
      : { deActivationType: currentActivation };

    let response;
    if (doActive) {
      if (isAdmin) {
        response = await AdminApi.twoFaActivation(data, otp);
      } else {
        response = await UserApi.twoFaActivation(data, otp);
      }
    } else {
      if (isAdmin) {
        response = await AdminApi.twoFaDeActivation(data, otp);
      } else {
        response = await UserApi.twoFaDeActivation(data, otp);
      }
    }

    if (response?.status === 'SUCCESS') {
      getUserInfo();
      enqueueSnackbar(currentActivation + (doActive ? ' actived' : ' deactivated') + ' successfully.', {
        variant: 'info',
      });
      setEmailCode('');
      setGoogleCode('');
      setSmsCode('');
    }
    console.log(response);
  };

  const onSendCodeClick = async () => {
    let doActive = true;

    switch (currentActivation) {
      case activationType.SOFT_OTP: {
        doActive = !userInfo?.softOtpAuthEnabled;
        break;
      }
      case activationType.SMS_OTP: {
        doActive = !userInfo?.smsAuthEnabled;
        break;
      }
      case activationType.EMAIL_OTP: {
        doActive = !userInfo?.emailAuthEnabled;
        break;
      }
      default:
        break;
    }

    const data = doActive
      ? {
          activationType: currentActivation,
        }
      : { deActivationType: currentActivation };

    const response = await preTwoFaActivation(data, doActive);
    if (response?.status === 'SUCCESS') {
      enqueueSnackbar(currentActivation + ' sent.', { variant: 'success' });
    }
  };

  const getUserInfo = async () => {
    const response = await Api.getUserInfo();
    if (response) {
      dispatch({
        type: SET_USERINFO,
        payload: {
          userInfo: response,
        },
      });
    }
  };

  return (
    <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 } }}>
      <Row>
        <Col>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ marginBottom: '30px', color: '#717aa5' }}>
              {t(
                'In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We`ve got to make it easier for you to activate with each one.'
              )}
            </span>

            <Collapse
              ghost
              accordion
              onChange={onCollapseChange}
              className={classes.collapse}
              expandIcon={({ isActive }) => (isActive ? <AiOutlineCaretDown /> : <AiOutlineCaretRight />)}
            >
              <Panel
                header={
                  <span style={{ color: userInfo?.softOtpAuthEnabled ? '#32b826' : '#828bb4' }}>
                    {t('Two-step login via Google')}
                    {userInfo?.softOtpAuthEnabled && <AiOutlineCheck className={classes.collapseActive} />}
                  </span>
                }
                key={activationType.SOFT_OTP}
              >
                <p>
                  {t('To activate this feature, follow these steps')}:
                  <br />
                  {t('1. Get the latest version of Google Authenticator from Google Play or Apple Store.')}
                  <br />
                  {t('2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.')}
                </p>
                <img src={qrCode} style={{ width: '130px' }} alt='' />
                {/* <Image src={barcodeSample} /> */}
                <br />
                <br />
                <p>- {t('Enter a provided key: Select this option and enter the following code carefully.')}</p>
                <div className={classes.providedKeyDiv}>
                  <img src={iconCopy} alt='' />
                  <span>{secretCode}</span>
                </div>
                <p>{t('3. Enter the received code (6-digit number) in the box below and click the activate button.')}</p>

                <Row style={{ marginTop: '30px' }}>
                  <Col {...colPropsInput} style={colStyle}>
                    <InputCustom
                      label={t('6-digit google code')}
                      value={googleCode}
                      placeholder={t('Google Code')}
                      required={true}
                      onChange={onGoogleCodeChange}
                    />
                  </Col>
                  <Col>
                    <Button {...(userInfo?.softOtpAuthEnabled ? btnCancelStyle : btnStyle)} onClick={twoFaActivation}>
                      {userInfo?.softOtpAuthEnabled ? t('Deactivation') : t('Activation')}
                    </Button>
                  </Col>
                </Row>
              </Panel>
              <Panel
                header={
                  <span style={{ color: userInfo?.smsAuthEnabled ? '#32b826' : '#828bb4' }}>
                    {t('Two-step login via Sms')}
                    {userInfo?.smsAuthEnabled && <AiOutlineCheck className={classes.collapseActive} />}
                  </span>
                }
                key={activationType.SMS_OTP}
              >
                <p>
                  {t(
                    'To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.'
                  )}
                </p>
                <Button {...btnStyle} onClick={onSendCodeClick}>
                  {t('Send SMS')}
                </Button>
                <Row style={{ marginTop: '30px' }}>
                  <Col {...colPropsInput} style={colStyle}>
                    <InputCustom
                      label={t('SMS Code')}
                      alue={smsCode}
                      placeholder={t('SMS Code')}
                      required={true}
                      onChange={onSmsCodeChange}
                    />
                  </Col>
                  <Col>
                    <Button {...(userInfo?.smsAuthEnabled ? btnCancelStyle : btnStyle)} onClick={twoFaActivation}>
                      {userInfo?.smsAuthEnabled ? t('Deactivation') : t('Activation')}
                    </Button>
                  </Col>
                </Row>
              </Panel>
              <Panel
                header={
                  <span style={{ color: userInfo?.emailAuthEnabled ? '#32b826' : '#828bb4' }}>
                    {t('Two-step login via Email')}
                    {userInfo?.emailAuthEnabled && <AiOutlineCheck className={classes.collapseActive} />}
                  </span>
                }
                key={activationType.EMAIL_OTP}
              >
                <p>
                  {t(
                    'To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.'
                  )}
                </p>
                <Button {...btnStyle} onClick={onSendCodeClick}>
                  Send Email
                </Button>
                <Row style={{ marginTop: '30px' }}>
                  <Col {...colPropsInput} style={colStyle}>
                    <InputCustom
                      label={t('Email Code')}
                      value={emailCode}
                      placeholder={t('Email Code')}
                      required={true}
                      onChange={onEmailCodeChange}
                    />
                  </Col>
                  <Col>
                    <Button {...(userInfo?.emailAuthEnabled ? btnCancelStyle : btnStyle)} onClick={twoFaActivation}>
                      {userInfo?.emailAuthEnabled ? t('Deactivation') : t('Activation')}
                    </Button>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default AccountSettingTwoStep;
