import { useTranslation } from 'react-i18next';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useConvertAccept from './hooks/useConvertAccept';
import { fFloat } from '../../../utils/formatNumber';

export default function ConvertPrice({ fromSymbol, toSymbol, prices, onSuccess, onBack, fromAmount, toAmount }) {
  const { t } = useTranslation();

  const { onAccept, isSubmitting, timer } = useConvertAccept({
    prices,
    fromSymbol,
    toSymbol,
    onSuccess,
    onBack,
  });

  return (
    <>
      <Stack spacing={2} sx={{ mt: 5, mb: 3.5 }}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant='body2' color={'grey.100'}>
            {t('Price')}
          </Typography>
          <Typography variant='body2' color={'grey.100'}>
            1 {fromSymbol} = {fFloat(prices.toAmount / fromAmount)} {toSymbol}
          </Typography>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant='body2' color={'grey.100'}>
            {t('Inverse Price')}
          </Typography>
          <Typography variant='body2' color={'grey.100'}>
            1 {toSymbol} = {fFloat(1 / (prices.toAmount / fromAmount))} {fromSymbol}
          </Typography>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant='body2' color={'grey.100'}>
            {t('Total Price')}
          </Typography>
          <Typography variant='h5' color={'success.main'} fontWeight='400'>
            {prices.toAmount} {toSymbol}
          </Typography>
        </Stack>
      </Stack>

      <Divider sx={{ mb: 1 }} />

      <Typography variant='body3' color={'grey.100'}>
        {t('Please confirm conversion within the time')}
      </Typography>

      <Stack direction={'row'} alignItems={'center'} spacing={3.3} sx={{ mt: 5 }}>
        <Button
          variant='contained'
          size='large'
          color='inherit'
          fullWidth
          sx={{
            backgroundColor: 'grey.400',
            typography: 'body3',
            fontWeight: 500,
            width: '50%',
            '&:hover': {
              backgroundColor: 'grey.600',
            },
          }}
          onClick={onBack}
        >
          {t('Back')}
        </Button>

        <LoadingButton
          variant='contained'
          size='large'
          color='primary'
          loading={isSubmitting}
          fullWidth
          sx={{
            typography: 'body3',
            fontWeight: 500,
            width: '50%',
          }}
          onClick={onAccept}
        >
          {timer === 0 ? (
            t('Conversion Time is Over')
          ) : (
            <>
              {t('Convert')}
              <Typography
                variant='body2'
                color='inherit'
                sx={{
                  width: 50,
                  textAlign: 'center',
                  display: 'inline-block',
                  lineHeight: 1,
                  letterSpacing: 1,
                }}
              >
                ({timer}s)
              </Typography>
            </>
          )}
        </LoadingButton>
      </Stack>
    </>
  );
}
