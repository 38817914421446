import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

RHFAutocomplete.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
  freeSolo: PropTypes.bool,
};

export default function RHFAutocomplete({ name, variant, options = [], label, freeSolo = false, ...other }) {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const filter = createFilterOptions();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Autocomplete
            {...field}
            freeSolo={freeSolo}
            onChange={(event, newValue) => {
              if (newValue?.value) {
                field.onChange(newValue.value);
              } else if (freeSolo && newValue && newValue.inputValue) {
                // Create a new value from the user input
                field.onChange({ newItem: newValue.inputValue });
              } else {
                field.onChange(newValue || '');
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (freeSolo) {
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.title);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `${t('Add New Item')}: "${inputValue}"`,
                  });
                }
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur={!freeSolo}
            handleHomeEndKeys
            options={options}
            getOptionLabel={(option) => {
              if (typeof option === 'number') {
                return options?.find((o) => o.value === option)?.title || '';
              }
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Add "xxx" option created dynamically
              if (option.newItem) {
                return option.newItem;
              }
              // Regular option
              return option.title;
            }}
            renderOption={(props, option) => <li {...props}>{option.title}</li>}
            renderInput={(params) => (
              <TextField variant={variant} label={t(label)} {...params} helperText={error?.message} error={!!error} />
            )}
            {...other}
          />

          {/* {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )} */}
        </>
      )}
    />
  );
}
