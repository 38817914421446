import { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ModalContainer from '../../../../../../components/ModalContainer';
import SendForm from './sendForm';
import User from './user';

const NotificationModal = ({ open, onClose, userId }) => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (e, newTab) => {
    setTab(newTab);
  };

  const { t } = useTranslation();

  return (
    <ModalContainer open={open} onClose={onClose} title={'Notification'} width={380} sx={{ p: 5 }}>
      <Box sx={{ borderBottom: 2, borderColor: 'divider', maxWidth: 300, mt: 3.75, mx: 'auto' }}>
        <Tabs value={tab} onChange={handleTabChange} variant='scrollable' scrollButtons='auto'>
          <Tab label={t('Send Form')} sx={{ width: 150 }} />
          <Tab label={t('User')} sx={{ width: 150 }} />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <SendForm onClose={onClose} userId={userId} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <User userId={userId} open={open} />
      </TabPanel>
    </ModalContainer>
  );
};

export default NotificationModal;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && <Box sx={{ mt: 2.5 }}>{children}</Box>}
    </div>
  );
}
