import { useSnackbar } from 'notistack';

import Api from '../../../../../http/adminHttp/serviceApiAdmin';
import { useTranslation } from 'react-i18next';

export default function useDepositSettingSubmit(bankList, transferList, setDepositData) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (payload) => {
    const data = {
      active: payload.active,
      depositReceiptBankAccounts: bankList.filter((b) => !!b.bankName),
      depositReceiptTransferMethods: transferList.filter((t) => !!t.name),
      family: payload.family,
      name: payload.name,
    };
    const response = await Api.editDepositReceiptSettings(data);
    if (response?.status === 'SUCCESS') {
      enqueueSnackbar(t('The values saved successfully.'), { variant: 'success' });

      setDepositData({
        ...data,
        depositReceiptBankAccounts: data.depositReceiptBankAccounts.map((b) => ({ ...b, isNew: false })),
        depositReceiptTransferMethods: data.depositReceiptTransferMethods.map((b) => ({ ...b, isNew: false })),
      });
    }
  };

  return {
    onSubmit,
  };
}
