import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import Api from '../http/serviceApi';
import { getToken } from '../http/tokenHandler';
import { navigationUrl } from '../navigationUrl';
import { SET_IRANIAN_PAYMENT_STATUS, SET_USERINFO } from '../store/actionTypes';
import navigationService from '../utils/navigation';

export default function useUserAuth(navigateToLogin = false) {
  const dispatch = useDispatch();
  const userInfo = useSelector((store) => store.userInfo);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigationService.navigate = navigate;
    navigationService.location = location;
  }, []);

  const getUserInfo = async () => {
    setLoading(true);

    const hasToken = getToken();
    if (hasToken) {
      const response = await Api.getUserInfo();
      if (response) {
        dispatch({
          type: SET_USERINFO,
          payload: {
            userInfo: response,
          },
        });

        const res = await Api.getIranianPaymentStatus();
        if (res) {
          dispatch({
            type: SET_IRANIAN_PAYMENT_STATUS,
            payload: {
              isIranianPaymentActive: res,
            },
          });
        }
      } else {
        if (navigateToLogin) navigate(navigationUrl.login);
      }
    } else {
      if (navigateToLogin) navigate(navigationUrl.login);
    }

    setLoading(false);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return { loading, userInfo };
}
