import { m } from 'framer-motion';
import { Box, Button, Card, Container, IconButton, Stack, Tooltip, Typography } from '@mui/material';
// import CopyToClipboard from 'react-copy-to-clipboard';
// import { useSnackbar } from 'notistack';
import moment from 'jalali-moment';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { useEffect } from 'react';

import LoadingScreen from '../../../components/LoadingScreen';
import useUserAuth from '../../../hooks/useUserAuth';
import TopMenu from '../../landing/sections/topMenu';
import MotionContainer from '../../../components/animate/MotionContainer';
import { varBounce } from '../../../components/animate/variants/bounce';
import Image from '../../../components/Image';
import { navigationUrl } from '../../../navigationUrl';

import successIcon from '../../../assets/success_icon.svg';
import errorIcon from '../../../assets/error_icon.svg';
import useQuery from '../../../hooks/useQuery';
import Api from '../../../http/serviceApi';
// import Iconify from '../../../components/Iconify';

export default function PerfectMoneyPayment({ success = true }) {
  const { loading } = useUserAuth(false);
  // const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const query = useQuery();
  const { t } = useTranslation();

  // const [data, setData] = useState([]);
  const [info, setInfo] = useState(null);

  const varifyRequest = async () => {
    const params = {};
    for (const [key, value] of query) {
      params[key] = value;
    }

    try {
      if (success) {
        await Api.perfectMoneyPayment(params);
        setInfo({ success: true, message: 'Done successfully' });
      } else {
        await Api.perfectMoneyNoPayment(params.PAYMENT_ID);
        setInfo({ success: false, message: 'The payment has been canceled' });
      }
    } catch (e) {
      setInfo({ success: false, message: e.message });
    }
  };

  useEffect(() => {
    // const list = [];
    // for (const [title, value] of query) {
    //   let filteredTitle = title.replace(/_/g, ' ').toLowerCase();
    //   filteredTitle = filteredTitle.charAt(0).toUpperCase() + filteredTitle.slice(1);

    //   let filteredValue = value;
    //   if (title === 'TIMESTAMPGMT') {
    //     filteredValue = moment.unix(filteredValue).format('YYYY/MM/DD - HH:mm');
    //   }

    //   list.push({ title: filteredTitle, value: filteredValue });
    // }
    // setData(list);
    varifyRequest();
  }, [query]);

  if (loading || info === null) {
    return <LoadingScreen />;
  }

  return (
    <Stack sx={{ minHeight: 1 }}>
      <TopMenu />

      <RootStyle>
        <Container component={MotionContainer}>
          <ContentStyle sx={{ maxWidth: { xs: 300, md: 400 } }}>
            <m.div variants={varBounce().in}>
              <Card sx={{ textAlign: 'center', mt: 5 }}>
                <Box
                  sx={{
                    width: 91,
                    height: 91,
                    backgroundColor: 'grey.600',
                    backdropFilter: 'blur(7px)',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mx: 'auto',
                  }}
                >
                  <Image src={info.success ? successIcon : errorIcon} sx={{ m: 'auto', width: 40 }} />
                </Box>

                <Typography variant='body1' color={info.success ? '#30E0A1' : 'error.darker'} sx={{ mt: 2, mb: 1 }}>
                  {t('Perfect Money')}
                </Typography>
                <Typography variant='body3' color='grey.300' component={'p'} sx={{ mb: 5 }}>
                  {t(info.message)}
                </Typography>

                {/* {data.map((item) => (
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    sx={{ mb: 1.5 }}
                    spacing={0.5}
                  >
                    <Typography variant='body3' color='grey.300'>
                      {t(item.title)}
                    </Typography>
                    <Stack direction={'row'} alignItems='center' justifyContent={'center'} spacing={1}>
                      <Typography variant='body3' color='grey.100'>
                        {item.value || '-'}
                      </Typography>
                    </Stack>
                  </Stack>
                ))} */}

                <Button
                  variant='outlined'
                  color='inherit'
                  sx={{ width: 144, mt: 3, mb: 2, typography: 'body3' }}
                  size='large'
                  onClick={() => navigate(info.success ? navigationUrl.order_history : navigationUrl.perfectMoney)}
                >
                  {info.success ? t('Orders') : t('Back')}
                </Button>
              </Card>
            </m.div>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Stack>
  );
}

const RootStyle = styled('div')(() => ({
  minHeight: '100vh',
  width: '100%',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));
