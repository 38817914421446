import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid } from '@mui/material';

import FuturesTables from './futuresTables';
import FuturesForm from './futuresForm';
import FuturesOrders from './futuresOrders';
import FuturesSymbol from './futuresSymbol';
import { SET_PAGE_STATE } from '../../../store/actionTypes';
import TradingViewChart from '../../../components/tardingViewChart/tardingViewChart';
import useResponsive from '../../../hooks/useResponsive';

export default function Futures() {
  const dispatch = useDispatch();
  const [reloadTime, setReloadTime] = useState(new Date());
  const isMobile = useResponsive('down', 'sm');

  const [selectPrice, setSelectPrice] = useState('');
  const [showSymbolModal, setShowSymbolModal] = useState(false);
  const [orderBook, setOrderBook] = useState({
    trades: [],
    asks: [],
    bids: [],
    lastPrice: 0,
    isPositive: true,
    dollarPrice: 0,
  });

  // ---------------------
  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Futures' },
    });
  }, []);

  // ---------------------
  const [selectedSymbol, setSelectedSymbol] = useState({ baseAsset: 'BTC', quoteAsset: 'USDT' });

  // ---------------------
  return (
    <Box sx={{ px: { xs: 2, lg: 0 }, height: '100%' }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          lg={9.5}
          sx={{
            bgcolor: 'grey.900',
            height: '100%',
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            borderTopRightRadius: { xs: 10, lg: 0 },
            borderBottomRightRadius: { xs: 10, lg: 0 },
          }}
        >
          <Grid container>
            <Grid item xs={12} md={9}>
              <FuturesSymbol
                selectedSymbol={selectedSymbol}
                setSelectedSymbol={setSelectedSymbol}
                lastPrice={orderBook.lastPrice}
                isPositive={orderBook.isPositive}
                dollarPrice={orderBook.dollarPrice}
                showSymbolModal={showSymbolModal}
                setShowSymbolModal={setShowSymbolModal}
              />

              <TradingViewChart
                symbolInfo={selectedSymbol}
                iframeSx={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
                height={isMobile ? '400px' : '620px'}
              />
            </Grid>

            <Grid item xs={12} md={3} sx={{ borderLeft: '1px solid', borderColor: 'grey.600' }}>
              <FuturesOrders
                selectedSymbol={selectedSymbol}
                orderBook={orderBook}
                setOrderBook={setOrderBook}
                onSelectPrice={setSelectPrice}
              />
            </Grid>

            <Grid item xs={12} sx={{ p: 2, borderTop: '1px solid', borderColor: 'grey.600' }}>
              <FuturesTables selectedSymbol={selectedSymbol} reloadTime={reloadTime} />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          lg={2.5}
          sx={{
            bgcolor: 'grey.700',
            height: '100%',
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            borderTopLeftRadius: { xs: 10, lg: 0 },
            borderBottomLeftRadius: { xs: 10, lg: 0 },
            py: 1,
            borderLeft: '1px solid',
            borderColor: 'grey.600',
            mt: { xs: 2, lg: 0 },
          }}
        >
          <FuturesForm
            selectedSymbol={selectedSymbol}
            selectPrice={selectPrice}
            onReload={() => setReloadTime(new Date())}
            onShowSymbolModal={() => setShowSymbolModal(true)}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
