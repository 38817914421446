import React from 'react';
import { Row, Col } from 'antd';
import classes from './basicAuthAdditionalInfo.module.scss';
import BasicAuthAdditionalInfoForm from './basicAuthAdditionalInfoForm';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';

const BasicAuthAdditionalInfo = () => {
  const colPropsForm = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
  };

  return (
    <div className={classes.container}>
      <div className={classes.formBody}>
        <Col {...colPropsForm}>
          <BasicAuthAdditionalInfoForm />
        </Col>
      </div>
    </div>
  );
};

export default BasicAuthAdditionalInfo;
