import { api } from './http';

export const setToken = (accessToken) => {
  if (accessToken) {
    window.localStorage.setItem('token', accessToken);
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    removeToken();
  }
};

export const getToken = () => {
  const accessToken = window.localStorage.getItem('token');
  if (accessToken) {
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    return accessToken;
  }

  return null;
};

export const removeToken = () => {
  window.localStorage.removeItem('token');
  delete api.defaults.headers.common.Authorization;
};
