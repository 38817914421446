import React, { memo, useState } from 'react';
import { useEffect } from 'react';
import Datafeed from './datafeed';
import TradingView from './charting_library.standalone';
import { Box, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import CustomLoading from '../CustomLoading';

// window.tvWidget.activeChart().setTimezone(timezone)

const TradingViewChart = ({ symbolInfo, iframeSx = {}, height = '410px' }) => {
  const language = useSelector((store) => store.language);
  const themeMode = useSelector((store) => store.themeMode);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const script = document.createElement('script');
    script.type = 'text/jsx';
    script.async = true;
    script.src = '/scripts/charting_library/charting_library.js';
    document.body.appendChild(script);

    window.tvWidget = new TradingView.widget({
      symbol: `Binance:${symbolInfo?.baseAsset ? `${symbolInfo.baseAsset}/${symbolInfo.quoteAsset}` : 'BTC/USD'}`, // default symbol
      interval: '1D', // default interval
      fullscreen: false, // displays the chart in the fullscreen mode
      container: 'tv_chart_container',
      datafeed: Datafeed,
      library_path: '/scripts/charting_library/',
      timezone: 'Asia/Tehran',
      theme: themeMode,
      locale: language || 'en', // fa
      custom_css_url: '/styles/trading-view-themed.css',
      layout: '3s',
      volumePaneSize: 'large',
      width: '100%',
      height,
      logo: {
        // image: '/assets/logo_tiny.png',
        // link: 'https://cryptizi.com/',
        image: '/assets/rounded-logo-tv.png',
        link: 'https://www.tradingview.com/',

      },
      loading_screen: { backgroundColor: themeMode === 'dark' ? '#1D1F25' : '#f3f3f4' },
      time_frames: [
        { text: '50y', resolution: '6M' },
        { text: '3y', resolution: 'W' },
        { text: '8m', resolution: 'D' },
        { text: '2m', resolution: 'D' },
        { text: '1m', resolution: '60' },
        { text: '1w', resolution: '30' },
        { text: '7d', resolution: '30' },
        { text: '5d', resolution: '10' },
        { text: '3d', resolution: '10' },
        { text: '2d', resolution: '5' },
        { text: '1d', resolution: '5' },
      ],
    });

    window.tvWidget.onChartReady(() => {
      setLoading(false);
    });

    return () => script.remove();
  }, [language, themeMode]);

  // Changing theme listener
  useEffect(() => {
    window.tvWidget.onChartReady(() => {
      // window.tvWidget.changeTheme('dark');

      if (themeMode === 'dark') {
        window.tvWidget.applyOverrides({
          'paneProperties.background': '#1D1F25',
          'paneProperties.backgroundType': 'solid',
          'paneProperties.vertGridProperties.color': 'rgba(42, 46, 57, 0.7)',
          'paneProperties.horzGridProperties.color': 'rgba(42, 46, 57, 0.7)',
        });
      } else {
        window.tvWidget.applyOverrides({
          'paneProperties.background': '#fcfcfc',
          'paneProperties.backgroundType': 'solid',
          'paneProperties.vertGridProperties.color': '#F2F3F3',
          'paneProperties.horzGridProperties.color': '#F2F3F3',
        });
      }
    });
  }, [language, themeMode]);

  // Changing symbol listener
  useEffect(() => {
    window.tvWidget.onChartReady(() => {
      window.tvWidget
        .activeChart()
        .setSymbol(`Binance:${symbolInfo?.baseAsset ? `${symbolInfo.baseAsset}/${symbolInfo.quoteAsset}` : 'BTC/USD'}`);

      // window.tvWidget.activeChart().setLanguage('fa');
    });
  }, [symbolInfo?.baseAsset]);

  return (
    <Box sx={{ position: 'relative', borderBottom: themeMode === 'dark' ? '1px solid #2A2E39' : '1px solid #ddd' }}>
      {loading && (
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: '100%',
            backgroundColor: themeMode === 'dark' ? '#1D1F25' : '#f4f4f4',
          }}
        >
          <CustomLoading imageSize={40} innerBoxSize={70} outerBoxSize={80} />
        </Stack>
      )}

      <Box id='tv_chart_container' sx={{ '& iframe': { backgroundColor: '#1D1F25', ...iframeSx } }}></Box>
    </Box>
  );
};

export default memo(TradingViewChart, (prev, next) => prev.symbolInfo === next.symbolInfo);
