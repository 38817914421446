import { LoadingButton } from '@mui/lab';
import { Box, Card, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'jalali-moment';
import { FormProvider, RHFSelect, RHFTextField } from '../../../../components/hookForm';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import useTicketForm, { sectionList } from './hooks/useTicketForm';
import useTicketSubmit from './hooks/useTicketSubmit';
import useTicketInit from './hooks/useTicketInit';
import { prettyUppercases } from '../../../../utils';
import SymbolIcon from '../../../../components/SymbolIcon';
import Image from '../../../../components/Image';

import USD from '../../../../assets/logo/dolar.svg';
import IRR from '../../../../assets/logo/IRR.svg';
import PMIcon from '../../../../assets/logo/PM.svg';
import PSIcon from '../../../../assets/logo/PS.svg';
import Iconify from '../../../../components/Iconify';

export default function AddTicket() {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const [img ,setImg] = useState(undefined)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: t('Add Ticket') },
    });
  }, []);

  const { handleSubmit, isSubmitting, methods } = useTicketForm();

  const { transactions } = useTicketInit();
  const { onSubmit } = useTicketSubmit({ transactions });


  return (
    <Box sx={{ mx: { xs: 2, lg: 0 } }}>
      <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 } }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={5}>
            <RHFTextField
              name='name'
              label={t('Subject')}
              variant='standard'
              fullWidth
              sx={{
                maxWidth: 320,
              }}
            ></RHFTextField>

            <RHFSelect
              name='section'
              SelectProps={{
                native: false,
                MenuProps: {
                  sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'common.black' } },
                },
              }}
              label={t('Section')}
              variant='standard'
              fullWidth
              sx={{
                maxWidth: 320,
              }}
            >
              {sectionList.map((category) => (
                <MenuItem value={category.value} key={category.value}>
                  <Typography typography='body3' color='grey.300'>
                    {t(category.label)}
                  </Typography>
                </MenuItem>
              ))}
            </RHFSelect>

            <RHFSelect
              name='transactionId'
              SelectProps={{
                native: false,
                MenuProps: {
                  sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'common.black' } },
                },
              }}
              label={t('Transaction')}
              variant='standard'
              fullWidth
              sx={{
                maxWidth: 730,
              }}
            >
              {transactions.reverse().map((transaction, index) => (
                <MenuItem value={transaction.transactionId} key={index}>
                  <Stack direction='row' spacing={1}>
                    {transaction?.type === 'PAY_SAFE' && <Image src={PSIcon} sx={{ width: 18, height: 18 }} />}

                    {transaction?.type === 'PERFECT_MONEY' && <Image src={PMIcon} sx={{ width: 18, height: 18 }} />}

                    {transaction?.symbol && (
                      <SymbolIcon
                        symbol={
                          transaction.symbol !== 'USDT' ? transaction.symbol.replace('USDT', '') : transaction.symbol?.toUpperCase()
                        }
                        width={18}
                        height={18}
                      />
                    )}

                    {transaction?.fiatType === 'USD' && <Image src={USD} sx={{ width: 18, height: 18 }} />}
                    {transaction?.fiatType === 'IRR' && <Image src={IRR} sx={{ width: 18, height: 18 }} />}

                    <Typography typography='body3' color='grey.300'>
                      {transaction?.amount || ''} {transaction?.symbol || ''} {transaction?.fiatType || ''} -{' '}
                      {t(prettyUppercases(transaction.type))}{' '}
                      {transaction?.side ? `(${t(prettyUppercases(transaction.side))})` : ''}
                      {transaction?.futuresSide ? `(${t(prettyUppercases(transaction.futuresSide))})` : ''} -{' '}
                      {moment(transaction.createDate).locale(language).format('YYYY MMM D - HH:mm')}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
            </RHFSelect>

            <RHFTextField
              name='description'
              label={t('Message')}
              multiline
              rows={5}
              variant='standard'
              sx={{
                maxWidth: 730,
              }}
            />

            <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} alignItems={{ xs: 'flex-start', md: 'center' }}>
              <Stack direction={'row'} spacing={1} alignItems='center'>
                <IconButton type='button' component='label' sx={{ backgroundColor: 'grey.600', width: 52, height: 52 }}>
                  <Iconify icon={'fluent:attach-24-regular'} color='grey.0' sx={{ width: 17, height: 17 }} />
                  <TextField
                    name='image'
                    type='file'
                    hidden
                    onChange={(e) => {
                      methods.setValue('image', e.target?.files?.[0])
                      setImg(e.target?.files?.[0])
                    }}
                  />
                </IconButton>

                <LoadingButton
                  fullWidth
                  size='large'
                  variant='contained'
                  loading={isSubmitting}
                  type='submit'
                  sx={{
                    width: 200,
                    typography: 'body3',
                    fontWeight: 500,
                  }}
                >
                  {t('Send')}
                </LoadingButton>
              </Stack>

              {(img === null || img === undefined  || img === '') ? null : (
                <Stack direction='row' alignItems={'center'} sx={{ pl: 2 }} spacing={1}>
                  <IconButton sx={{ p: 0 }} onClick={() => {
                    methods.setValue('image', null)
                    setImg(null)
                  }}>
                    <Iconify icon='eva:close-outline' color='error.main' sx={{ width: 15, height: 15 }} />
                  </IconButton>
                  <Typography variant='body4' color='grey.300' sx={{ pt: 0.1 }}>
                    {img?.name || ''}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        </FormProvider>
      </Card>
    </Box>
  );
}
