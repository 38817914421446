import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ModalContainer from '../../../../components/ModalContainer';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';

import paySafe from '../../../../assets/logo/paysef.svg';
import { prettyUppercases } from '../../../../utils';
import Image from '../../../../components/Image';
import Scrollbar from '../../../../components/Scrollbar';
import CopyItem from '../../../../components/CopyItem';

export default function PeysafeHistoryModal({ open, onClose, data, status = 'COMPLETED', isAdmin = false }) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <ModalContainer open={open} onClose={onClose} title={'Details'} width={600}>
      <Stack sx={{ mt: 1.5, alignItems: 'center' }}>
        <Image src={paySafe} sx={{ width: 40, height: 40 }} />
        <Typography variant='body3' color={'grey.100'} fontWeight={400} sx={{ mt: 1, mb: 1 }}>
          {t('Paysafe')}
        </Typography>

        <Box
          sx={{
            width: 120,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            mb: 3,
            p: 0.8,
            bgcolor:
              (status === 'COMPLETED' && 'rgba(48, 224, 161, 0.1)') ||
              (status === 'FAILED' && 'rgba(224, 48, 48, 0.1)') ||
              'rgba(250, 174, 34, 0.1)',
          }}
        >
          <Typography
            variant='body2'
            sx={{
              color:
                (status === 'COMPLETED' && '#30E0A1') ||
                (status === 'FAILED' && 'negative.main') ||
                'rgb(218, 146, 13)',
            }}
          >
            {t(prettyUppercases(status))}
          </Typography>
        </Box>
      </Stack>

      <Stack direction={{ xs: 'row', sm: 'row' }} justifyContent={'space-between'} spacing={2}>
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} sx={{ mb: 1.5 }} spacing={2}>
          {isAdmin && (
            <Stack spacing={1.5}>
              <Stack direction={'row'} alignItems={'center'} spacing={4}>
                <Typography variant='body3' color={'grey.400'} sx={{ width: 40 }}>
                  {t('User')}
                </Typography>
                <Typography variant='body3' color={'grey.100'}>
                  {data?.firstName} {data?.lastName}
                </Typography>
              </Stack>

              <Stack direction={'row'} alignItems={'center'} spacing={4}>
                <Typography variant='body3' color={'grey.400'} sx={{ width: 40 }}>
                  {t('Mobile')}
                </Typography>
                <Typography variant='body3' color={'grey.100'}>
                  {data?.mobile || '-'}
                </Typography>
              </Stack>

              <Stack direction={'row'} alignItems={'center'} spacing={4}>
                <Typography variant='body3' color={'grey.400'} sx={{ width: 40 }}>
                  {t('Email')}
                </Typography>
                <Typography variant='body3' color={'grey.100'}>
                  {data?.emailAddress || '-'}
                </Typography>
              </Stack>
            </Stack>
          )}

          <Stack spacing={1.5}>
            <Stack direction={'row'} alignItems={'center'} spacing={4}>
              <Typography variant='body3' color={'grey.400'} sx={{ width: 120 }}>
                {t('Side')}
              </Typography>
              <Typography
                variant='body4'
                color={
                  (data?.side === 'BUY' && 'positive.main') || (data?.side === 'SELL' && 'negative.main') || 'grey.100'
                }
              >
                {data?.side}
              </Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} spacing={4}>
              <Typography variant='body3' color={'grey.400'} sx={{ width: 120 }}>
                {t('Date')}
              </Typography>
              <Typography variant='body3' color={'grey.100'}>
                {moment(data?.createdDate).locale(language).format('YYYY/M/D')}
              </Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} spacing={4}>
              <Typography variant='body3' color={'grey.400'} sx={{ width: 120 }}>
                {t('Deposit Type')}
              </Typography>
              <Typography variant='body3' color={'grey.100'}>
                {t(prettyUppercases(data?.depositType))}
              </Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} spacing={4}>
              <Typography variant='body3' color={'grey.400'} sx={{ width: 120 }}>
                {t('Voucher Currency')}
              </Typography>
              <Typography variant='body3' color={'grey.100'}>
                {data?.voucherCurrency}
              </Typography>
            </Stack>


          </Stack>
        </Stack>


        <Stack>
          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 120 }}>
              {t('Tx Code')}
            </Typography>
            <Typography variant='body3' color={'grey.100'}>
              {data?.transactionCode || ''}
            </Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 150, mt: -1 }}>
              {t('Voucher Code')}
            </Typography>
            <CopyItem
              value={data?.voucherCode}
              textVariant='body4'
              textWidth={200}
              sx={{ mt: '-8px !important', ml: '22px !important' }}
            />
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Typography variant='body3' color={'grey.400'} sx={{ width: 150, mt: -1 }}>
              {t('Activation Code')}
            </Typography>
            <CopyItem
              value={data?.voucherActivationCode}
              textVariant='body4'
              textWidth={200}
              sx={{ mt: '-8px !important', ml: '22px !important' }}
            />
          </Stack>
        </Stack>

      </Stack>

      <Scrollbar>
        <Table size='small' sx={{ mt: 5, ...(isLight && { bgcolor: 'grey.700', px: 0.5, borderRadius: 1 }) }}>
          <TableHead>
            <TableRow sx={{ '& th': { typography: 'body2' } }}>
              <TableCell align='center'>#</TableCell>
              <TableCell align='left'>{t('Description')}</TableCell>
              <TableCell align='center'>
                {t('Amount')}/{t('Number')}
              </TableCell>
              <TableCell align='center'>{t('Unit price')}</TableCell>
              <TableCell align='center'>{t('Total Amount')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ '& td': { typography: 'body2' } }}>
            <TableRow>
              <TableCell align='center'>1</TableCell>
              <TableCell align='left'>{t('Peysafe')}</TableCell>
              <TableCell align='center'>{data?.paysafeAmount}</TableCell>
              <TableCell align='center'>{data?.paysafePrice}</TableCell>
              <TableCell align='center'>
                {data?.fiatAmount} {data?.fiatType}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='center'>2</TableCell>
              <TableCell align='left'>
                {data?.side === 'BUY' ? t('Purchase') : t('Sales')} {t('fee')}
              </TableCell>
              <TableCell align='center'></TableCell>
              <TableCell align='center'>{data?.feePerUnit || 0}</TableCell>
              <TableCell align='center'>{data?.feeAmount || 0}</TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={4} />
              <TableCell align='center' sx={{ color: 'grey.300', typography: 'body1' }}>
                {t('sum')}: {(data?.fiatAmount || 0) + (data?.feeAmount || 0)} {data?.fiatType}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Scrollbar>

      <Button
        fullWidth
        variant='contained'
        size='large'
        sx={{
          typography: 'body3',
          fontWeight: 500,
          mt: 4.25,
        }}
        onClick={onClose}
      >
        {t('Close')}
      </Button>
    </ModalContainer>
  );
}
