import { useEffect } from 'react';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { useDispatch } from 'react-redux';
import { TokenForm } from '../../../../components/adminTokens/TokenForm';

export const AddToken = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Add Token' },
    });
  }, []);

  return (
    <>
      <TokenForm mode={'add'}></TokenForm>
    </>
  );
};
