import React from 'react';
import { Card, Stack, Typography, Box, Switch, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { PageTitle } from '../../../../components/PageTitle';
import { FormProvider, RHFEditor, RHFNumberField, RHFSwitch, RHFTextField } from '../../../../components/hookForm';
import usePopupWebForm from './hooks/usePopupWebForm';
import usePopupWebSubmit from './hooks/usePopupWebSubmit';
import CustomSwitch from '../../../../components/CustomSwitch';

const WebCard = ({ data }) => {
  const { t } = useTranslation();

  const { handleSubmit, isSubmitting, methods, isActive } = usePopupWebForm(data);
  const { onSubmit } = usePopupWebSubmit();

  const [isAdvancedEditor, setAdvancedEditor] = useState(false);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 5, px: { xs: 2, sm: 4 }, py: { xs: 3, sm: 4 } }}>
        <PageTitle
          title='Popup settings'
          subTitle='After each login, it shows the password (that is, if he has not used remember me) and also shows it every few days if he has remembered me!'
        />
        <Box sx={{ mt: 5 }}>
          <Typography variant='subtitle3' sx={{ mt: 1 }}>
            {t('Web Advertisement Setting')}
          </Typography>
        </Box>

        <Stack direction='row' alignItems={'center'} spacing={1} sx={{ mt: 2.25 }}>
          <RHFSwitch name='active' />
          <Typography variant='body3'>{isActive  ? t('Active') : t('Disable')}</Typography>
        </Stack>

        <Box sx={{ width: { xs: '100%', md: '45%', lg: '25%' }, mt: 2.25 }}>
          <RHFNumberField name='showDays' label={t('Show how many days?')} variant='standard' fullWidth />
        </Box>

        <Box sx={{ width: { xs: '100%', md: '60%' }, mt: 2.75 }}>
          <FormControlLabel
            control={
              <CustomSwitch
                checked={isAdvancedEditor}
                onChange={() => setAdvancedEditor((prev) => !prev)}
                sx={{ mr: 1 }}
              />
            }
            label={t('Advanced editor')}
            sx={{ my: 2, mx: 0.5 }}
          />

          {isAdvancedEditor ? <RHFEditor name='text' /> : <RHFTextField name='text' multiline rows={7.5} />}
        </Box>

        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ maxWidth: 283, mt: 5 }}
        >
          {t('Save')}
        </LoadingButton>
      </Card>
    </FormProvider>
  );
};

export default WebCard;
