import { styled, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const TextFieldStyle = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 0,
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #7c829d',
      },
    },
  },
  '& .MuiSelect-select': {
    padding: '9px 16px 8px',
    color: theme.palette.grey[300],
    zIndex: 1,
    ...theme.typography.body3,
  },
  '& fieldset': {
    background: theme.palette.grey[700],
    border: '1px solid grey.600',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    background: theme.palette.grey[700],
    border: '1px solid grey.600',
  },
  '& .MuiSelect-icon': {
    color: theme.palette.grey[0],
    fill: theme.palette.grey[0],
    zIndex: 1,
  },
  '& .MuiFormLabel-root': {
    top: '-7px',
    '&.Mui-focused': {
      top: '0 !important',
    },
    '&.MuiFormLabel-filled': {
      top: 0,
    },
  },
}));

// ----------------------------------------------------------------------

export default function FillSelect({ value, onChange, label, children, ...other }) {
  const { t } = useTranslation();

  return (
    <TextFieldStyle
      select
      variant='outlined'
      label={t(label)}
      value={value}
      onChange={({ target }) => onChange(target.value)}
      SelectProps={{
        MenuProps: {
          sx: {
            '& .MuiPaper-root': {
              maxHeight: 300,
              borderRadius: 0,
              backgroundColor: 'grey.800',
              backgroundImage: 'none',
              border: '1px solid grey.400',
            },
          },
        },
      }}
      {...other}
    >
      {children}
    </TextFieldStyle>
  );
}
