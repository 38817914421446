import { TextField } from '@mui/material';
import { NumericFormat, PatternFormat } from 'react-number-format';

// ----------------------------------------------------------------------

export const NumberField = ({ format, ...props }) => {
  if (format) {
    return <PatternFormat customInput={TextField} {...props} format={format} />;
  }

  return <NumericFormat customInput={TextField} {...props} />;
};
