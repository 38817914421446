import React, { useEffect } from 'react';
import ModalContainer from '../../../components/ModalContainer';
import { FormProvider, RHFNumberField, RHFTextField } from '../../../components/hookForm';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useAddUserForm from '../user/addUser/hooks/useAddUserForm';
import useAddUserSubmit from '../user/addUser/hooks/useAddUserSubmit';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useAddEditDedicatedWallet from './useAddEditDedicatedWallet';
import SymbolIcon from '../../../components/SymbolIcon';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AdminApi from '../../../http/adminHttp/serviceApiAdmin';
import { SET_TOAST } from '../../../store/actionTypes';

const AddDedicatedWallet = ({open, onClose, data , getData}) => {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);
  const info = data

  const dispatch = useDispatch();
  const toast = useSelector((state) => state.toast);

  const {
    handleSubmit,
    isSubmitting,
    methods,
    reset,
  } = useAddEditDedicatedWallet(info);

  const onSubmit = async (e) =>{
    const d = {
      ...(data?.symbol !== data?.network && {"contractAddress": e?.contractAddress}),
      "currencySymbol": info?.symbol,
      "decimal": e?.decimal,
      "network": info?.network,
      "withdrawFee": e?.withdrawFee
    }
    try {
      const res = await AdminApi.editDedicatedWallet(d)
      onClose()
      getData()
      dispatch({ type: SET_TOAST, payload: { type: '', message: 'New settings submitted successfully' } });

    }catch (err){
      dispatch({ type: SET_TOAST, payload: { type: 'error', message: 'An error occurred!' } });

    }
  }



  return (
    <ModalContainer open={open} onClose={()=>{
      reset()
      onClose()
    }} title={data?.name ? 'Edit' : 'Add'} width={600}>
      <Stack sx={{ mt: 4.5, alignItems: 'center' }}>
        <SymbolIcon symbol={data?.symbol} width={80} height={80} />
        <Typography variant='body2' color={'grey.100'} fontWeight={500} sx={{ mt: 2.5, mb: 2 }}>

          {data?.symbol}
        </Typography>

        <Stack width={'80%'} justifyContent={'space-between'} direction={'row'} alignItems={'center'} spacing={4}>
          <Typography variant='body3' color={'grey.400'}  >
            {t('Name')}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {data?.name || '-'}
          </Typography>
        </Stack>


        <Stack width={'80%'} justifyContent={'space-between'} direction={'row'} alignItems={'center'} spacing={4}>
          <Typography variant='body3' color={'grey.400'}  >
            {t('Network')}
          </Typography>
          <Typography variant='body3' color={'grey.100'}>
            {data?.network || '-'}
          </Typography>
        </Stack>


        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
          <RHFTextField name={'contractAddress'} label={t('Contract Address')} value={data?.symbol === data?.network ? 'Native': ''} disabled={data?.symbol === data?.network} variant='standard' fullWidth sx={{ minWidth: 200 , marginTop:1 }} />
          <RHFTextField name={'withdrawFee'} label={t('Withdraw Fee')+' (USDT)'} variant='standard' fullWidth sx={{ minWidth: 200 , marginTop:1}} />
          <RHFTextField name={'decimal'} label={t('Decimal')} variant='standard' fullWidth sx={{ minWidth: 200 , marginTop:1}} />


          <Stack width={'100%'} justifyContent={'center'} direction={'row'} alignItems={'center'} spacing={4} sx={{my:3}}>
            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              loading={isSubmitting}
              sx={{ maxWidth: 300, typography: 'body3', fontWeight: 500, mt: 5 }}
            >
              {t('Submit')}
            </LoadingButton>
          </Stack>

        </FormProvider>

      </Stack>

    </ModalContainer>
  );
};

export default AddDedicatedWallet;