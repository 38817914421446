import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useDispatch } from 'react-redux';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import WebCard from './webCard';
import MobileCard from './mobileCard';
import Api from '../../../../http/adminHttp/serviceApiAdmin';

const PopupSettings = () => {
  const dispatch = useDispatch();
  const [webData, setWebData] = useState({
    active: false,
    popupType: '',
    showDays: '',
    status: '',
    value: '',
  });
  const [mobileData, setMobileData] = useState({
    active: false,
    popupType: '',
    showDays: '',
    status: '',
    value: '',
  });

  const fetchWebData = async () => {
    const response = await Api.getPopUpbData('WEB');
    setWebData({
      active: response.active,
      popupType: response.popupType,
      showDays: response.showDays,
      status: response.status,
      value: response.value,
    });
  };

  const fetchMobileData = async () => {
    const response = await Api.getPopUpbData('MOBILE');
    setMobileData({
      ...response,
      active: response.active,
      popupType: response.popupType,
      showDays: response.showDays,
      status: response.status,
      value: response.value,
    });
  };

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Popup settings' },
    });

    fetchWebData();
    fetchMobileData();
  }, []);

  return (
    <Stack sx={{ padding: { xs: '0 20px 20px', lg: '0 0 97px' } }}>
      <WebCard data={webData} />
      <MobileCard data={mobileData} />
    </Stack>
  );
};

export default PopupSettings;
