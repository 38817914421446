import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Stack,
  InputBase,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Iconify from '../Iconify';
import { debounced } from '../../utils';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

// ----------------------------------------------------------------------

TableHeadCustom.propTypes = {
  onSort: PropTypes.func,
  onSearch: PropTypes.func,
  orderBy: PropTypes.string,
  headLabel: PropTypes.array,
  rowCount: PropTypes.number,
  numSelected: PropTypes.number,
  onSelectAllRows: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  sx: PropTypes.object,
};

export default function TableHeadCustom({
  order,
  orderBy,
  rowCount = 0,
  headLabel,
  numSelected = 0,
  onSort,
  onSearch = () => {},
  onVisiblePrice = () => {},
  visiblePrice = false,
  onSelectAllRows,
  noBorder = false,
  sx,
}) {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const handleSearch = useRef(
    debounced((q) => {
      onSearch(q);
    }, 700)
  ).current;

  return (
    <TableHead sx={sx}>
      <TableRow>
        {onSelectAllRows && (
          <TableCell padding='checkbox'>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event) => onSelectAllRows(event.target.checked)}
            />
          </TableCell>
        )}

        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.width,
              minWidth: headCell.minWidth,
              ...(noBorder && { border: 'none' }),
            }}
          >
            <Box
              sx={{
                ...(headCell.showSearchBox && {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }),
              }}
            >
              {onSort ? (
                <TableSortLabel
                  hideSortIcon
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={() => onSort(headCell.id)}
                  sx={{ textTransform: 'capitalize', cursor: 'default' }}
                >
                  {typeof headCell.label === 'string' ? t(headCell.label) : headCell.label}

                  {orderBy === headCell.id ? (
                    <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                t(headCell.label)
              )}

              <Stack direction='row' spacing={1}>
                {headCell.visiblePriceButton && (
                  <IconButton onClick={onVisiblePrice}>
                    <Iconify icon={visiblePrice ? 'ant-design:eye-outlined' : 'ant-design:eye-invisible-outlined'} />
                  </IconButton>
                )}

                {headCell.showSearchBox && (
                  <Stack
                    direction='row'
                    alignItems={'center'}
                    sx={{ backgroundColor: 'grey.700', borderRadius: 1, px: 2, height: 38 }}
                  >
                    <InputBase
                      sx={{
                        flex: 1,
                        '& input': { typography: 'body2', color: 'grey.0', '&::placeholder': { color: 'grey.400' } },
                      }}
                      placeholder={t('Search')}
                      inputProps={{ 'aria-label': 'search' }}
                      value={searchValue}
                      onChange={({ target }) => {
                        setSearchValue(target.value);
                        handleSearch(target.value);
                      }}
                    />

                    <Iconify icon={'eva:search-fill'} width={22} height={22} color='grey.0' />
                  </Stack>
                )}
              </Stack>
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
