import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'jalali-moment';

import Scrollbar from '../../../../components/Scrollbar';

export default function FuturesOrderTrades({ selectedSymbol, trades }) {
  const { t } = useTranslation();

  // ----------------
  return (
    <Box sx={{ py: 1.5, borderTop: '1px solid', borderColor: 'grey.600', mt: 2 }}>
      <Typography variant='subtitle3' color='common.white' sx={{ px: 1.8 }}>
        {t('Trades')}
      </Typography>

      <Stack sx={{ mt: 2 }}>
        <Stack direction='row' sx={{ py: 0.5, px: 1, pb: 1 }}>
          <Typography color={'grey.300'} typography='body4' sx={{ width: '33%' }} lineHeight={1}>
            {t('Price')} ({selectedSymbol.quoteAsset})
          </Typography>
          <Typography color={'grey.300'} typography='body4' sx={{ width: '33%', textAlign: 'right' }} lineHeight={1}>
            {t('Amount')} ({selectedSymbol.baseAsset})
          </Typography>
          <Typography color={'grey.300'} typography='body4' sx={{ width: '33%', textAlign: 'right' }} lineHeight={1}>
            {t('Time')}
          </Typography>
        </Stack>

        <Scrollbar height={'152px'} sx={{ px: 1, height: '152px' }}>
          {trades.map((trade, index) => (
            <TradeRow key={index} {...trade} />
          ))}
        </Scrollbar>
      </Stack>
    </Box>
  );
}

// ----------------------------------------------------
const TradeRow = ({ isSell, price, amount, time }) => (
  <Stack
    direction='row'
    sx={{
      py: 0.5,
    }}
  >
    <Typography
      color={isSell ? 'negative.main' : 'positive.main'}
      typography='body4'
      sx={{ width: '33%' }}
      lineHeight={1}
    >
      {price}
    </Typography>
    <Typography color={'grey.100'} typography='body4' sx={{ width: '33%', textAlign: 'right' }} lineHeight={1}>
      {amount}
    </Typography>
    <Typography color={'grey.100'} typography='body4' sx={{ width: '33%', textAlign: 'right' }} lineHeight={1}>
      {moment(time).format('HH:mm:ss')}
    </Typography>
  </Stack>
);
