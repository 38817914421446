import { Button, MenuItem, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ComplexToolbar from '../../../../components/ComplexToolbar';
import DatePicker from '../../../../components/DatePicker';
import { getDefaultDateFilterValues } from '../../../../utils/filter';

export default function TicketListToolbar({
  onFilter,
  statusFilter,
  setStatusFilter,
  idFilter,
  setIdFilter,
  subjectFilter,
  setSubjectFilter,
  firstNameFilter,
  setFirstNameFilter,
  lastNameFilter,
  setLastNameFilter,
  dateFromFilter,
  setDateFromFilter,
  dateToFilter,
  setDateToFilter,
}) {
  const { t } = useTranslation();

  const onReset = async () => {
    setStatusFilter('');
    setIdFilter('');
    setSubjectFilter('');
    setFirstNameFilter('');
    setLastNameFilter('');
    setDateFromFilter(getDefaultDateFilterValues().firstDay);
    setDateToFilter(getDefaultDateFilterValues().lastDay);
    onFilter(false);
  };

  return (
    <>
      <ComplexToolbar>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 2 }} alignItems={'center'}>
          <TextField
            select
            label={t('Status')}
            variant='standard'
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            sx={{ width: { xs: '100%', md: 150 } }}
            SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                  '& .MuiMenuItem-root': { typography: 'body2' },
                },
              },
            }}
          >
            <MenuItem value={''}>{t('ALL')}</MenuItem>
            <MenuItem value={'CLOSE'}>{t('Close')}</MenuItem>
            <MenuItem value={'CLOSE_BY_ADMIN'}>{t('Close By Admin')}</MenuItem>
            <MenuItem value={'OPEN'}>{t('Open')}</MenuItem>
            <MenuItem value={'PENDING'}>{t('Pending')}</MenuItem>
          </TextField>

          <TextField
            label={t('Number')}
            variant='standard'
            value={idFilter}
            onChange={(e) => setIdFilter(e.target.value)}
            sx={{ width: { xs: '100%', md: 100 } }}
          />

          <Stack direction={'row'}>
            <DatePicker
              label={t('From')}
              value={dateFromFilter}
              setValue={setDateFromFilter}
              variant='standard'
              maxDate={dateToFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
              InputProps={null}
            />
            <DatePicker
              label={t('To')}
              value={dateToFilter}
              setValue={setDateToFilter}
              variant='standard'
              minDate={dateFromFilter}
              fullWidth
              sx={{ maxWidth: 150 }}
            />
          </Stack>

          <TextField
            label={t('Subject')}
            variant='standard'
            value={subjectFilter}
            onChange={(e) => setSubjectFilter(e.target.value)}
            sx={{ width: { xs: '100%', md: 200 } }}
          />

          <TextField
            label={t('First Name')}
            variant='standard'
            value={firstNameFilter}
            onChange={(e) => setFirstNameFilter(e.target.value)}
            sx={{ width: { xs: '100%', md: 180 } }}
          />

          <TextField
            label={t('Last Name')}
            variant='standard'
            value={lastNameFilter}
            onChange={(e) => setLastNameFilter(e.target.value)}
            sx={{ width: { xs: '100%', md: 180 } }}
          />
        </Stack>

        <Stack direction={'row'} spacing={{ xs: 2, md: 3 }} sx={{ mt: 5, mb: 5.75 }}>
          <Button
            onClick={onFilter}
            fullWidth
            variant='contained'
            sx={{ maxWidth: 150, typography: 'body3', fontWeight: 500 }}
          >
            {t('Filter')}
          </Button>

          {/*<Button*/}
          {/*  onClick={onReset}*/}
          {/*  fullWidth*/}
          {/*  variant='outlined'*/}
          {/*  color='inherit'*/}
          {/*  sx={{ maxWidth: 80, typography: 'body3', fontWeight: 500, borderColor: 'grey.400' }}*/}
          {/*>*/}
          {/*  {t('Reset')}*/}
          {/*</Button>*/}
        </Stack>
      </ComplexToolbar>
    </>
  );
}
