import { Button, Card, Divider, Grid, IconButton, Link, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

import Image from '../../../../components/Image';
import { navigationUrl } from '../../../../navigationUrl';
import DepositBarcode from './depositBarcode';
import SelectNetworkModal from '../../../../components/SelectNetworkModal';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import copy from '../../../../assets/copy_white.svg';
import ActiveCryptoModal from '../../../../components/activeCryptoModal/activeCryptoModal';
import SymbolIcon from '../../../../components/SymbolIcon';
import Iconify from '../../../../components/Iconify';
import StatusModal from '../../../../components/StatusModal';
import useDeposit from './hooks/useDeposit';
import DepositFAQ from './depositFAQ';
import DepositWalletModal from './depositWalletModal';
import useQuery from '../../../../hooks/useQuery';

export default function DepositCryptoRequest() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Deposit Crypto' },
    });
  }, []);

  const [openNetworkModal, setOpenNetworkModal] = useState(false);
  const [openSymbolModal, setOpenSymbolModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openSelectWalletModal, setOpenSelectWalletModal] = useState(false);
  const [selectedSymbolData, setSelectedSymbolData] = useState(false);

  const {
    networkSelected,
    symbolSelected,
    onSelectNetwork,
    addressHash,
    depositTag,
    onSelectSymbol,
    txId,
    setTxId,
    onSubmit,
    isSubmitting,
  } = useDeposit({ setOpenSuccessModal });

  const onOpenNetworkModal = () => {
    if (!symbolSelected) {
      return enqueueSnackbar(t('Please select a coin firstly!'), { variant: 'warning' });
    }
    setOpenNetworkModal(true);
  };

  useEffect(() => {
    if (query.get('symbol')) onSelectSymbol(query.get('symbol'));
  }, [query]);

  console.log(networkSelected ,selectedSymbolData ,(!selectedSymbolData.listedByAdmin || !networkSelected?.withoutTxId))
  return (
    <>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={8}>
          <Card sx={{ px: { xs: 2, md: 3 }, py: { xs: 3, md: 3 }, mx: { xs: 2, md: 0 } }}>
            <Stack spacing={4}>
              <Stack direction='row' justifyContent={'space-between'} alignItems={'center'} spacing={2}>
                <Typography variant='h6' sx={{ fontWeight: 500 }}>
                  {t('Deposit crypto')}
                </Typography>

                <Button
                  variant={'outlined'}
                  color={'inherit'}
                  size='medium'
                  onClick={() => navigate(`${navigationUrl.crypto_transaction}?type=deposit`)}
                  sx={{ borderColor: 'grey.400', py: 1, typography: 'body3' }}
                >
                  {t('Recent Deposit')}
                </Button>
              </Stack>
              <Stack spacing={3}>
                {/* ************************** select coin ************************** */}
                <Stack alignItems={'flex-start'}>
                  <Typography variant='body3' sx={{ color: 'grey.400' }}>
                    {t('Coin')}
                  </Typography>

                  <Button
                    fullWidth
                    color='inherit'
                    sx={{ borderRadius: 0, maxWidth: 550, justifyContent: 'space-between' }}
                    onClick={() => setOpenSymbolModal(true)}
                    endIcon={
                      <Iconify icon={'eva:arrow-ios-downward-fill'} sx={{ width: 15, height: 15, color: 'grey.300' }} />
                    }
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      {symbolSelected ? (
                        <>
                          <SymbolIcon symbol={symbolSelected} width={22} height={22} />
                          <Typography variant='body2' sx={{ color: 'grey.100' }}>
                            {symbolSelected}
                          </Typography>
                        </>
                      ) : (
                        <Typography variant='body2' sx={{ color: 'grey.100' }}>
                          {t('Select Coin')}
                        </Typography>
                      )}
                    </Stack>
                  </Button>

                  <Divider sx={{ width: '100%', maxWidth: 550 }}></Divider>
                </Stack>

                {/* ************************** select network ************************** */}
                <Stack alignItems={'flex-start'}>
                  <Typography variant='body3' sx={{ color: 'grey.400' }}>
                    {t('Network')}
                  </Typography>

                  <Button
                    fullWidth
                    color='inherit'
                    sx={{ borderRadius: 0, maxWidth: 550, justifyContent: 'space-between' }}
                    onClick={onOpenNetworkModal}
                    endIcon={
                      <Iconify icon={'eva:arrow-ios-downward-fill'} sx={{ width: 15, height: 15, color: 'grey.300' }} />
                    }
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      {networkSelected ? (
                        <>
                          <Typography variant='body2' sx={{ color: 'grey.100' }}>
                            {networkSelected?.network || ''}
                          </Typography>
                          <Typography variant='body3' sx={{ color: 'grey.300' }}>
                            {networkSelected?.name || ''}
                          </Typography>
                        </>
                      ) : (
                        <Typography variant='body2' sx={{ color: 'grey.100' }}>
                          {t('Select Deposit network')}
                        </Typography>
                      )}
                    </Stack>
                  </Button>

                  <Divider sx={{ width: '100%', maxWidth: 550 }}></Divider>
                </Stack>

                {/* ************************** enter txid ************************** */}
                {selectedSymbolData.listedByAdmin || networkSelected?.withoutTxId ||  networkSelected == null? null : (
                  <TextField
                    label={'TXID'}
                    variant='standard'
                    fullWidth
                    sx={{ maxWidth: 550 }}
                    value={txId}
                    onChange={(e) => setTxId(e.target.value)}
                  />
                ) }

                {/* ************************** network info ************************** */}
                <Stack>
                  <Typography variant='body3' color={'grey.400'}>
                    {t('Address')}
                  </Typography>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    justifyContent='space-between'
                    sx={{ maxWidth: 550 }}
                  >
                    <Typography variant='body2' color={'grey.100'} sx={{ overflowWrap: 'break-word', width: '100%' }}>
                      {addressHash ? addressHash : '. . .'}
                    </Typography>

                    <Stack direction='row'>
                      <CopyToClipboard text={addressHash} onCopy={() => enqueueSnackbar(t('Copied!'))}>
                        <Tooltip title={t('Copy')}>
                          <IconButton disabled={!addressHash}>
                            <Image src={copy} />
                          </IconButton>
                        </Tooltip>
                      </CopyToClipboard>

                      <DepositBarcode
                        addressHash={addressHash}
                        network={networkSelected?.name || ''}
                        symbol={symbolSelected || ''}
                      />
                    </Stack>
                  </Stack>
                </Stack>

                {depositTag && (
                  <Stack>
                    <Typography variant='body3' color={'grey.400'} sx={{ mt: -2.5 }}>
                      {t('Tag')}
                    </Typography>

                    <Typography
                      variant='body2'
                      color={'grey.100'}
                      sx={{ overflowWrap: 'break-word', width: '100%', mt: 1 }}
                    >
                      {depositTag}
                    </Typography>
                  </Stack>
                )}
              </Stack>

              <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 0 }}>
                <Stack sx={{ width: 270 }}>
                  <Typography variant='body3' color={'grey.400'}>
                    {t('Expected Arrival')}
                  </Typography>
                  <Typography variant='body2' color={'grey.100'}>
                    {t('{{number}} Network Confirmations', { number: networkSelected?.minConfirm || 0 })}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant='body3' color={'grey.400'}>
                    {t('Expected Unlock')}
                  </Typography>
                  <Typography variant='body2' color={'grey.100'}>
                    {t('{{number}} Network Confirmations', { number: networkSelected?.unlockConfirm || 0 })}
                  </Typography>
                </Stack>
              </Stack>

              <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 0 }}>
                <Stack spacing={0.6} sx={{ width: 270 }}>
                  <Typography variant='body3' color={'grey.400'}>
                    {t('Minimum deposit')}
                  </Typography>
                  <Typography variant='body2' color={'grey.100'}>
                    {networkSelected?.depositMin || 0} {symbolSelected || ''}
                  </Typography>
                </Stack>

                <Stack>
                  <Typography variant='body3' color={'grey.400'}>
                    {t('Selected wallet')}
                  </Typography>
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Typography variant='body2' color={'grey.100'}>
                      {t('Main wallet')}
                    </Typography>
                    <Button
                      color='warning'
                      size='small'
                      sx={{ borderRadius: 1, py: 0.5, px: 1 }}
                      onClick={() => setOpenSelectWalletModal(true)}
                    >
                      {t('change')}
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack sx={{ mt: 3, mb: 5 }} spacing={1}>
              {addressHash && (
                <>
                  <Typography variant='body4' color={'grey.100'}>
                    {t('Send Only {{symbol}} to this deposit address', { symbol: symbolSelected })}
                  </Typography>
                  <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                    <Typography variant='body4' color={'grey.100'}>
                      {t('Ensure the network is')}
                    </Typography>
                    <Typography variant='body4' color={'error.darker'}>
                      {networkSelected?.name || ''}
                    </Typography>
                  </Stack>
                </>
              )}

              <Stack direction={{ xs: 'column', md: 'row' }} spacing={1}>
                <Typography variant='body4' color='grey.100'>
                  {t('Do not send NFTs to this address.')}
                </Typography>
                <Link
                  component={RouterLink}
                  variant='body4'
                  to={'/'}
                  sx={{ color: 'grey.100' }}
                  underline='always'
                  color='inherit'
                >
                  {t('learn how to deposit NFTs')}
                </Link>
              </Stack>
            </Stack>

            {/* ************************** button ************************** */}
            {(!selectedSymbolData.listedByAdmin && !networkSelected?.withoutTxId) && (
              <LoadingButton
                variant='contained'
                fullWidth
                sx={{ maxWidth: 450, typography: 'body3', fontWeight: 500 }}
                size={'large'}
                loading={isSubmitting}
                onClick={onSubmit}
              >
                {t('submit')}
              </LoadingButton>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <DepositFAQ />
        </Grid>
      </Grid>

      <ActiveCryptoModal
        open={openSymbolModal}
        onClose={() => setOpenSymbolModal(false)}
        onSelect={(symbol, data) => (onSelectSymbol(symbol), setSelectedSymbolData(data))}
        selectedSymbol={symbolSelected}
      />

      <SelectNetworkModal

        open={openNetworkModal}
        onClose={() => setOpenNetworkModal(false)}
        symbol={symbolSelected}
        onSelect={onSelectNetwork}
        isWithdraw={false}
      />

      <StatusModal
        open={openSuccessModal}
        onClose={() => setOpenSuccessModal(false)}
        onAction={() => setOpenSuccessModal(false)}
        delay={0}
        buttonLabel='Close'
      />

      <DepositWalletModal open={openSelectWalletModal} onClose={() => setOpenSelectWalletModal(false)} />
    </>
  );
}
