import { CircularProgress, IconButton, MenuItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FillSelect from '../../../../components/FillSelect';
import Iconify from '../../../../components/Iconify';
import FillTextField from '../../../../components/FillTextField';
import DatePicker from '../../../../components/DatePicker';
import FillRangeDatePicker from '../../../../components/FillRangeDatePicker';

const STATUS = [
  { value: 'ALL', label: 'All' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'FAILED', label: 'Failed' },
  { value: 'PENDING', label: 'Pending' },
];

export default function ConvertHistoryTableToolbar({
  isAdmin = false,
  isExportLoading = false,
  onExport = () => {},
  statusFilter,
  timeFilter,
  setStatusFilter,
  setTimeFilter,
  transactionIdFilter,
  setTransactionIdFilter,
  firstNameFilter,
  setFirstNameFilter,
  lastNameFilter,
  setLastNameFilter,
}) {
  const { t } = useTranslation();

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent='space-between'
      alignItems={'flex-end'}
      spacing={2}
      sx={{ mb: { xs: 3, md: 9 } }}
    >
      <Stack
        spacing={2}
        sx={{ width: '100%' }}
        flexDirection={'row'}
        alignItems={'flex-end'}
        gap={'15px'}
        flexWrap={'wrap'}
      >
        <FillRangeDatePicker
          value={timeFilter}
          setValue={setTimeFilter}
          variant='outlined'
          fullWidth
          sx={{ width: { xs: '100%', md: 335 } }}
          InputProps={null}
        />

        <FillSelect
          value={statusFilter}
          onChange={setStatusFilter}
          label={t('Status')}
          sx={{ width: { xs: '100%', md: 160 } }}
        >
          {STATUS.map((item) => (
            <MenuItem key={item.value} value={item.value} sx={{ typography: 'body2' }}>
              {t(item.label)}
            </MenuItem>
          ))}
        </FillSelect>

        <FillTextField
          value={transactionIdFilter}
          onChange={setTransactionIdFilter}
          label={t('Tx-Code')}
          placeholder={t('Enter Tx-Code')}
          sx={{ width: { xs: '100%', md: 160 } }}
          isDebounced
        />

        {isAdmin && (
          <>
            <FillTextField
              value={firstNameFilter}
              onChange={setFirstNameFilter}
              label={t('First name')}
              sx={{ width: { xs: '100%', md: 160 } }}
              isDebounced
            />

            <FillTextField
              value={lastNameFilter}
              onChange={setLastNameFilter}
              label={t('Last name')}
              sx={{ width: { xs: '100%', md: 160 } }}
              isDebounced
            />
          </>
        )}
      </Stack>

      {onExport && (
        <>
          {isExportLoading ? (
            <CircularProgress size={22} color='success' sx={{ margin: '11px !important' }} />
          ) : (
            <>
              <IconButton onClick={onExport}>
                <Iconify icon='tabler:receipt' sx={{ width: 28, height: 28 }} />
              </IconButton>
              <IconButton onClick={() => onExport('PDF')}>
                <Iconify icon='majesticons:printer-line' sx={{ width: 28, height: 28 }} />
              </IconButton>
            </>
          )}
        </>
      )}
    </Stack>
  );
}
