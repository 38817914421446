import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Stack, Table, TableBody, TableContainer, TablePagination, Box } from '@mui/material';

import { SET_PAGE_STATE } from '../../../store/actionTypes';
import { PageTitle } from '../../../components/PageTitle';
import Scrollbar from '../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../components/table';
import useTable from '../../../hooks/useTable';
import FiatTableRow from './automaticDepositHistoryTableRow';
import useAutomaticDepositHistoryInit from './hook/useAutomaticDepositHistoryInit';
import AutomaticDepositHistoryToolbar from './automaticDepositHistoryToolbar';

// ===================================================================

const TABLE_HEAD = [
  { id: 'num', label: 'Num', align: 'center' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'sheba', label: 'Sheba number', align: 'left' },
  { id: 'type', label: 'Type', align: 'center' },
  { id: 'amount', label: 'Balance', align: 'center' },
  { id: 'date', label: 'Date', align: 'left' },
];

// ===================================================================

const AutomaticDepositHistory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Automatic Deposit History' },
    });
  }, []);

  const { dataFiltered, onFilter, isExportLoading, onExport } = useAutomaticDepositHistoryInit();
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  return (
    <Stack sx={{ padding: { xs: '0 20px 20px', lg: '0 0 97px' }, mt: 0.5 }}>
      <Card sx={{ py: 5 }}>
        <PageTitle title='Automatic Deposit History' />
        <AutomaticDepositHistoryToolbar onFilter={onFilter} onExport={onExport} isExportLoading={isExportLoading} />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size='small'>
              <TableHeadCustom headLabel={TABLE_HEAD} rowCount={dataFiltered?.length} />

              <TableBody>
                {dataFiltered === null ? (
                  <TableLoading isLoading />
                ) : (
                  <>
                    {dataFiltered.map((row, index) => (
                      <FiatTableRow key={index} row={row} index={index} />
                    ))}

                    <TableNoData isNotFound={!dataFiltered?.length} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        {dataFiltered?.length > 0 && (
          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              component='div'
              // count={totalFiats}
              count={15}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              labelRowsPerPage={t('Rows per page')}
              labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
            />
          </Box>
        )}
      </Card>
    </Stack>
  );
};

export default AutomaticDepositHistory;
