import { Container, Typography, Button, Box, useTheme } from '@mui/material';
import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { navigationUrl } from '../../../navigationUrl';
import MotionContainer from '../../../components/animate/MotionContainer';
import { varFade } from '../../../components/animate/variants/fade';
import { BackgroundShadow, BannerContentStyle, HeroImgStyle, HeroOverlayStyle, RootStyle } from '../style';

import bannerImage from '../../../assets/landing/background-top.svg';
import phoneImage from '../../../assets/landing/phoneCrypto.png';
import phoneImageLight from '../../../assets/landing/phoneCryptoLight.png';

export default function LandingBanner() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <MotionContainer>
      <RootStyle sx={{ mt: { md: 5 } }}>
        <BackgroundShadow />

        {theme.palette.mode === 'dark' && <HeroOverlayStyle alt='overlay' src={bannerImage} variants={varFade().in} />}

        <HeroImgStyle
          alt='hero'
          src={theme.palette.mode === 'dark' ? phoneImage : phoneImageLight}
          variants={varFade().inUp}
        />

        <Container>
          <BannerContentStyle>
            <m.div variants={varFade().inRight}>
              <Typography
                variant='h1_secondary'
                sx={{
                  color: 'common.white',
                  textTransform: 'uppercase',
                  fontWeight: 400,
                  width: { xs: '100%', md: 665 },
                  display: 'inline-block',
                }}
              >
                {t('A trusted and secure cryptocurrency exchange.')}
              </Typography>
            </m.div>

            <m.div variants={varFade().inRight}>
              <Typography variant='body1' sx={{ color: 'grey.300', width: { xs: '100%', md: 450 } }}>
                {t(
                  'Your guide to the world of an open financial system. Get started with the easiest and most secure platform to buy and trade cryptocurrency.'
                )}
              </Typography>
            </m.div>

            <m.div variants={varFade().inRight}>
              <Button
                sx={{ mt: { xs: 2, md: 6 }, borderRadius: 0, width: { xs: '100%', md: 168 }, height: 45 }}
                size='large'
                variant='contained'
                color='success'
                component={RouterLink}
                to={navigationUrl.panel}
              >
                <Typography variant='subtitle1_secondary'>{t('Get Started')}</Typography>
              </Button>
            </m.div>
          </BannerContentStyle>
        </Container>
      </RootStyle>
      <Box sx={{ height: { md: '100vh' } }} />
    </MotionContainer>
  );
}
