import { Backdrop, Box, Button, Fade, Modal, Stack, Typography, useTheme } from '@mui/material';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import successIcon from '../assets/success_icon.svg';
import errorIcon from '../assets/error_icon.svg';
import Image from './Image';
import { prettyUppercases } from '../utils';

export default function StatusModal({
  open,
  onClose,
  type = 'success',
  title = 'Successful',
  subtitle = 'Done Successfully',
  buttonLabel = 'Back',
  onAction = null,
  delay = 4000,
  data={}
}) {
  const theme = useTheme();
  const { t } = useTranslation();


  const timer = useRef();
  useEffect(() => {
    if (open && delay > 0) {
      if (timer?.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        onClose();
      }, delay);
    } else {
      if (timer?.current) {
        clearTimeout(timer.current);
      }
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: { sm: '50%' },
            left: { sm: '50%' },
            transform: { sm: 'translate(-50%, -50%)' },
            width: 400,
            height: 'auto',
            background: theme.palette.mode === 'dark' ? 'rgba(47, 50, 65, 0.8)' : '#fff',
            boxShadow: 25,
            py: 4,
            borderRadius: '10px',
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              width: 91,
              height: 91,
              backgroundColor: 'grey.600',
              backdropFilter: 'blur(7px)',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mx: 'auto',
            }}
          >
            <Image src={type === 'success' ? successIcon : errorIcon} sx={{ m: 'auto', width: 40 }} />
          </Box>

          <Typography
            variant='body1'
            color={type === 'success' ? '#30E0A1' : 'error.darker'}
            sx={{ mt: 4, mb: onAction ? 1 : 2 }}
          >
            {t(title)}
          </Typography>
          <Typography variant='body3' color='grey.300' component={'p'}>
            {t(subtitle)}
          </Typography>

          {
            data?.side ?
              <Stack sx={{width:"100%" , p:4}} spacing={1.5}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={4}>
                  <Typography variant='body3' color={'grey.400'}  >
                    {t('Amount')}
                  </Typography>
                  <Typography variant='body3' color={'grey.100'}>
                    {`${data?.fiatAmount ? data?.fiatAmount :data?.receive} ${data?.fiat}`}
                  </Typography>
                </Stack>

                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={4}>
                  <Typography variant='body3' color={'grey.400'}  >
                    {data?.side}
                  </Typography>
                  <Typography variant='body3' color={'grey.100'}>
                    {`${data?.fiatAmount ?data?.receive : data?.spend} ${data?.symbol}`}
                  </Typography>
                </Stack>
              </Stack>
              : null
          }


          {onAction && (
            <Button
              variant='outlined'
              color='inherit'
              sx={{ width: 144, mt: 5, typography: 'body3' }}
              size='large'
              onClick={onAction}
            >
              {t(buttonLabel)}
            </Button>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}
