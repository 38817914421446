import React from 'react';
import { Table } from 'antd';
import './marketActivitiesTableAntdStyles.scss';
import classes from './marketActivitiesTable.module.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import Api from '../../../../http/serviceApi';
import { CgArrowLongUp } from 'react-icons/cg';
import { TbArrowsUp } from 'react-icons/tb';
import iconUp from '../../../../assets/trade/arrows/up.svg';
import iconDown from '../../../../assets/trade/arrows/down.svg';
import { useTheme } from '@mui/material';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';

const MarketActivitiesTable = () => {
  const theme = useTheme();
  const [marketActivities, setMarketActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstTime, setFirstTime] = useState(false);

  const columns = [
    {
      dataIndex: 'symbol',
      key: 'symbol',
      align: 'left',
      render: (key, record) => (
        <div className={classes.firstColItem}>
          <div className={classes.pairCoinsDiv}>
            <span>{key}</span>
            <span>{''}</span>
            <span>{''}</span>
          </div>
          <span className={classes.time}>{'17:00:04'}</span>
        </div>
      ),
    },
    {
      dataIndex: 'percentChange',
      key: 'percentChange',
      align: 'right',
      render: (key, record) => (
        <div className={classes.secondColItem}>
          <span className={key < 0 ? classes.percentRed : classes.percentGreen}>{key}</span>
        </div>
      ),
    },
    {
      dataIndex: 'col2',
      key: 'col2',
      align: 'right',
      render: (_, record) => (
        <div className={classes.thirdColItem}>
          {(() => {
            if (record?.percentChange > 0 && record?.percentChange < 5)
              return (
                <div className={classes.backPositive}>
                  <CgArrowLongUp style={{ transform: 'rotate(45deg)' }} />
                </div>
              );
            else if (record?.percentChange >= 5 && record?.percentChange < 10)
              return (
                <div className={classes.backPositive}>
                  <TbArrowsUp style={{ transform: 'rotate(45deg)' }} />
                </div>
              );
            else if (record?.percentChange >= 10)
              return (
                <div className={classes.backPositive}>
                  <img src={iconUp} alt='' />
                </div>
              );
            else if (record?.percentChange < 0 && record?.percentChange > -5)
              return (
                <div className={classes.backNegative}>
                  <CgArrowLongUp style={{ transform: 'rotate(135deg)' }} />
                </div>
              );
            else if (record?.percentChange <= -5 && record?.percentChange > -10)
              return (
                <div className={classes.backNegative}>
                  <TbArrowsUp style={{ transform: 'rotate(135deg)' }} />
                </div>
              );
            else if (record?.percentChange <= -10)
              return (
                <div className={classes.backNegative}>
                  <img src={iconDown} alt='' />
                </div>
              );
          })()}
        </div>
      ),
    },
  ];

  useEffect(() => {
    getMarketActivity();
    const interval = setInterval(getMarketActivity, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [firstTime]);

  const getMarketActivity = async () => {
    const response = await Api.getMarketActivity();
    if (response?.status === 'SUCCESS') {
      setMarketActivities(response.activities);
    }
    console.log('getMarketActivity: ', response);
    setLoading(false);
  };

  return (
    <div>
      <Table
        direction='horizental'
        className={theme.palette.mode === 'dark' ? 'tradeTableStyle' : 'tradeTableStyleLight'}
        dataSource={marketActivities}
        columns={columns}
        rowKey=''
        pagination={false}
        bordered={false}
        loading={loading}
        scroll={{ x: 280, y: 180 }}
        showHeader={false}
      />
    </div>
  );
};

export default MarketActivitiesTable;
