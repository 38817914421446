//
import { InputSelectIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Select() {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: InputSelectIcon,
      },
      // variants: [
      //   {
      //     props: { variant: 'filled' },
      //     style: {
      //       borderRadius: 0,
      //       background: 'rgba(47, 50, 65, 0.5)',
      //       border: '1px solid #34384C',
      //       '& .MuiSelect-filled': { paddingTop: 9 },
      //       '&:after': {
      //         borderBottom: 0,
      //       },
      //       '&:before': {
      //         borderBottom: 0,
      //       },
      //       '&:hover': {
      //         '&:before': {
      //           borderBottom: '0 !important',
      //         },
      //       },
      //     },
      //   },
      // ],
      // styleOverrides: {
      //   root: {
      //     '&.MuiSelect-filled': {
      //       border: 0,
      //     },
      //   },
      // },
    },
  };
}
