import React, { useState } from 'react';
import { Button, Row, Col, Upload, Modal, Form, Image } from 'antd';
import classes from './advancedAuthSelect.module.scss';
import './advancedAuthSelectAntdStyles.scss';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import tickIcon from '../../../../../assets/tick-circle.svg';
import { useNavigate } from 'react-router';
import { FaRegTrashAlt } from 'react-icons/fa';
import { showNotification } from '../../../../../utils/notification';
import Api from '../../../../../http/serviceApi';
import { navigationUrl } from '../../../../../navigationUrl';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTranslation } from 'react-i18next';

const AdvancedAuthSelect = () => {
  const { t } = useTranslation();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [selectPic, setSelectPic] = useState('');
  const [selectValidationMessage, setSelectValidationMessage] = useState('');
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const colProps = {
    xs: 24,
    sm: 22,
    md: 15,
    lg: 13,
    xl: 10,
  };

  const colPropsBotton = {
    xs: 24,
    sm: 10,
    md: 10,
    lg: 10,
    xl: 10,
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const onFileInputChange = (e) => {
    console.log('file: ', e.target.files[0]);
    let file = e.target.files[0];
    getBase64(file, (result) => {
      let spl = result.split('/')[1];
      let format = spl.split(';')[0];
      let resultRemovedheader = result.replace(`data:image/${format};base64,`, '');
      setSelectPic(resultRemovedheader);
      if (resultRemovedheader && resultRemovedheader !== '') setSelectValidationMessage('');
    });
  };

  const onContinueButtonClick = () => {
    if (!selectPic || selectPic === '') {
      setSelectValidationMessage(t('Please select a photo.'));
      return;
    }

    const data = {
      docData: selectPic,
      docType: 'BILL',
    };
    setUserAdvanceAuthInfo(data);
  };

  const setUserAdvanceAuthInfo = async (data) => {
    setLoading(true);
    const response = await Api.setUserAdvanceAuthInfo(data);
    console.log('response:', response);
    if (response?.status === 'SUCCESS') {
      navigate(navigationUrl.auth_advanced_verified);
    }
    setLoading(false);
  };

  return (
    <Row justify='center' style={{ marginLeft: '10px', marginRight: '10px' }}>
      <Col {...colProps}>
        <div className={classes.formBody}>
          <div className={classes.formTitleDiv}>
            <span className={classes.formTitleBig}>{t('Advanced Verification')}</span>
            <span className={classes.formTitleSmall}>{t('Confirm Residential Address')}</span>
          </div>

          <div className={classes.picHelpText}>
            <span>{t('Please choose and upload one of the following documents')}:</span>
          </div>

          <div className={classes.items}>
            <div>
              <img src={tickIcon} />
              <span>{t('Bank statement')}</span>
            </div>
            <div>
              <img src={tickIcon} />
              <span>{t('Utility bill (electricity, gaz. water/sewage, fibre/broadband service)')}</span>
            </div>
          </div>

          <Col>
            <div
              className={classes.uploadContainer}
              style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}
            >
              <div className={classes.uploadPhotoDiv}>
                {selectPic ? (
                  <Image src={`data:image/jpg;base64,${selectPic}`} />
                ) : (
                  <div>
                    <input
                      type='file'
                      accept='.jpg,.jpeg,.png'
                      id='selectedFile'
                      onChange={onFileInputChange}
                      style={{ display: 'none' }}
                    />
                    <Button
                      type='text'
                      style={{
                        backgroundColor: 'transparent',
                        display: 'flex',
                        width: 'auto',
                        height: 'auto',
                      }}
                      onClick={() => document.getElementById('selectedFile').click()}
                    >
                      <div>
                        <AiOutlinePlusCircle size={24} />
                        <div
                          style={{
                            marginTop: 8,
                          }}
                        >
                          {t('Select Photo')}
                        </div>
                      </div>
                    </Button>
                  </div>
                )}
              </div>
              {selectPic && (
                <div className={classes.deleteIconDiv} onClick={() => setSelectPic('')}>
                  <FaRegTrashAlt />
                </div>
              )}
            </div>
            <span className={classes.validationText}>{selectValidationMessage}</span>
          </Col>

          <div className={classes.picHelpText}>
            <span>{t('Upload .JPG/.JPEG/.PNG file and no more than 5M')}</span>
          </div>

          <Row justify='space-evenly' style={{ marginTop: '40px' }}>
            <Col {...colPropsBotton}>
              <Form.Item>
                <Button type='primary' block shape='round' onClick={onContinueButtonClick} loading={loading}>
                  {t('Continue')}
                </Button>
              </Form.Item>
            </Col>
            <Col {...colPropsBotton}>
              <Form.Item>
                <Button
                  type='default'
                  block
                  shape='round'
                  onClick={() => navigate(navigationUrl.auth_advanced_address)}
                >
                  {t('Back')}
                </Button>
              </Form.Item>
            </Col>
          </Row>

          <div className={classes.indicatorDiv}>
            <div className={classes.indicatorItemGray} />
            <div className={classes.indicatorItemGreen} />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AdvancedAuthSelect;
