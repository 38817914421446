import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import Api from '../../http/serviceApi';
import OptInput from '../OptInput';
import useTimer from '../../hooks/useTimer';

const RegisterOTP = ({ emailAddress, onFinished }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailOtp, setEmailOtp] = useState('');

  const { onRequestAgainClick, timer, stopTimer, activeTimerState } = useTimer({
    emailAddress,
    operationType: 'Register',
  });

  const onSubmit = async () => {
    if (!emailOtp) {
      return enqueueSnackbar(t('Enter the email verification code!'), { variant: 'error' });
    }

    setIsSubmitting(true);
    try {
      const response = await Api.userRegistration({ emailAddress, otp: emailOtp, registrationType: 'WEB' });
      if (response?.status === 'SUCCESS') {
        stopTimer();
        onFinished(response);
      }
    } catch (e) {}

    setIsSubmitting(false);
  };

  return (
    <>
      <Typography variant='h4' color={'grey.100'} sx={{ fontWeight: 400, mb: 6 }}>
        {t('Verify Email')}
      </Typography>

      <OptInput
        code={emailOtp}
        setCode={setEmailOtp}
        label={t('Email verification code')}
        description={t('Enter the {{number}}-digit code send to {{email}}', { email: emailAddress, number: 5 })}
        shouldAutoFocus
      />

      <Stack sx={{ mt: 5 }}>
        <Typography variant='body3' sx={{ letterSpacing: 1 }}>
          {timer}
        </Typography>
        <Stack direction='row' alignItems={'center'}>
          <Typography variant='body3_secondary' color='grey.400'>
            {t('Didn’t recieve code?')}
          </Typography>
          <Button
            color='warning'
            sx={{ typography: 'body3_secondary', borderRadius: 1, px: 1 }}
            onClick={onRequestAgainClick}
            size='small'
            disabled={!activeTimerState}
          >
            {t('Request Again')}
          </Button>
        </Stack>
      </Stack>

      <Box sx={{ width: 320 }}>
        <LoadingButton
          fullWidth
          size='large'
          type='button'
          variant='contained'
          loading={isSubmitting}
          onClick={() => onSubmit(null)}
          sx={{ mt: '20px' }}
        >
          {t('Register')}
        </LoadingButton>
      </Box>
    </>
  );
};

export default RegisterOTP;
