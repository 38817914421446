import React from 'react';
import NetworkSettingTable from './networkSettingTable';
import { PageTitle } from '../../../../components/PageTitle';
import { Button, Card, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import { useEffect } from 'react';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import useTable from '../../../../hooks/useTable';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFNumberField, RHFSelect, RHFTextField } from '../../../../components/hookForm';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

export const NetworkSetting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState();

  const getData = async () => {
    try {
      const response = await Api.getTokenNetworkInfo();
      setTableData(response.tokenNetworkSettingInfoList);
    } catch (error) {}
  };

  useEffect(() => {
    getData();
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Tokens' },
    });
  }, []);

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  const validators = Yup.object().shape({
    network: Yup.string().required(),
    withdrawFee: Yup.number().required(),
    minWithdrawAmount: Yup.number().required(),
  });

  const defaultValues = {
    network: '',
    withdrawFee: null,
    minWithdrawAmount: null,
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (payload) => {
    const data = {
      ...payload,
    };
    try {
      let response;
      const isEdit = tableData.some((item) => item.network === payload.network);
      if (isEdit) {
        response = await Api.editTokenNetworkInfo(data);
      } else {
        response = await Api.addTokenNetworkInfo(data);
      }

      if (response?.status === 'SUCCESS') {
        getData();
        enqueueSnackbar(t('Successfully saved!'), { variant: 'success' });
      }
    } catch (err) {}
  };

  return (
    <Stack sx={{ padding: { xs: '0 20px 20px', lg: '0 0 97px' }, mt: 0.5 }}>
      <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 } }}>
        <PageTitle title='Token' subTitle='You can not disable a port that is in the default state.' />
        <Stack sx={{ padding: { xs: '0 20px 20px', lg: '20px 0 97px' } }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <RHFSelect
                  name={`network`}
                  SelectProps={{
                    native: false,
                    MenuProps: {
                      sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' } },
                    },
                  }}
                  label={t('Network')}
                  variant='standard'
                  fullWidth
                >
                  <MenuItem value={'BSC'}>
                    <Typography typography='body3' color='grey.300'>
                      {t('BSC')}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'ETH'}>
                    <Typography typography='body3' color='grey.300'>
                      {t('ETH')}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'MATIC'}>
                    <Typography typography='body3' color='grey.300'>
                      {t('MATIC')}
                    </Typography>
                  </MenuItem>
                </RHFSelect>
              </Grid>
              <Grid item xs={12} md={4}>
                <RHFNumberField
                  name={'minWithdrawAmount'}
                  label={t('Min withdraw amount')}
                  variant='standard'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <RHFNumberField name={'withdrawFee'} label={t('Withdraw fee')} variant='standard' fullWidth />
              </Grid>
            </Grid>
            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              loading={isSubmitting}
              sx={{ maxWidth: 200, typography: 'body3', fontWeight: 500, mt: 5 }}
            >
              {t('Save')}
            </LoadingButton>
          </FormProvider>
        </Stack>

        <NetworkSettingTable
          tableData={tableData}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          handleEdit={(data) => Object.entries(data).forEach(([key, value]) => setValue(key, value))}
        />
      </Card>
    </Stack>
  );
};
