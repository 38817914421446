import { TableRow, TableCell, Typography, IconButton, Stack, Box } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import Image from '../../../../components/Image';

import perfectMoney from '../../../../assets/logo/PM.svg';
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

export default function PerfectMoneyHistoryTableRow({ row, onShowModalDetail, isAdmin = false }) {
  const language = useSelector((store) => store.language);

  const {
    fiatAmount,
    firstName,
    lastName,
    emailAddress,
    createdDate,
    mobile,
    perfectMoneyAmount,
    perfectMoneyPrice,
    voucherAmount,
    voucherCurrency,
    type,
    fiatType,
    side,
  } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 2, typography: 'body2', color: 'grey.100' } }}>
      <TableCell>{moment(createdDate).locale(language).format('YYYY-MM-DD HH:mm')}</TableCell>

      {isAdmin && (
        <TableCell>
          <Typography variant='body3'>
            {firstName} {lastName}
          </Typography>
          {emailAddress && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {emailAddress}
            </Typography>
          )}
          {mobile && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {mobile}
            </Typography>
          )}
        </TableCell>
      )}

      <TableCell align='center'>
        {/*<Stack direction='row' alignItems='center' spacing={0.5}>*/}
          <Image src={perfectMoney} sx={{ width: 30, height: 30, m: 'auto' }} />
          {/*<Typography variant='body3'>USD</Typography>*/}
        {/*</Stack>*/}
      </TableCell>


      <TableCell sx={{ width: 120 }} align='center'>
        <Box
          sx={{
            width: 100,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            p: 1,
            bgcolor:
              (side?.toLowerCase() === 'buy' && 'rgba(48, 224, 161, 0.1)') ||
              (side?.toLowerCase() === 'sell' && 'rgba(224, 48, 48, 0.1)') ||
              'rgba(250, 174, 34, 0.1)',
          }}
        >
          <Typography
            variant='body3'
            sx={{
              color:
                (side?.toLowerCase() === 'buy' && '#30E0A1') ||
                (side?.toLowerCase() === 'sell' && 'negative.main') ||
                'rgb(218, 146, 13)',
              lineHeight: 1,
            }}
          >
            {side}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align='center'>{fiatAmount}</TableCell>

      <TableCell align='center'>{perfectMoneyPrice || '-'}</TableCell>

      <TableCell align='center'>{perfectMoneyAmount || '-'}</TableCell>

      <TableCell align='center'>{type || '-'}</TableCell>

      <TableCell align='center'>{fiatType || '-'}</TableCell>

      <TableCell align='center' sx={{ width: 100 }}>
        <IconButton onClick={onShowModalDetail}>
          <Iconify icon='ph:link-duotone' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
