import { useState } from 'react';
import { Card } from '@mui/material';

import ChangeEmailForm from './changeEmailForm';
import OtpEmailForm from './otpEmailForm';
import StatusModal from '../../../../components/StatusModal';

const AccountSettingChangeEmail = ({ isAdmin = true }) => {
  const [statusModal, setStatusModel] = useState(false);
  const [step, setStep] = useState(0);
  const [emails, setEmails] = useState({ oldEmailAddress: '', newEmailAddress: '' });

  const onSuccess = (payload) => {
    setEmails(payload);
    setStep(1);
  };

  const onFinished = () => {
    setStatusModel(true);
  };

  const onBack = () => {
    setStatusModel(false);
    setStep(0);
  };

  return (
    <>
      <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 } }}>
        {step === 0 && <ChangeEmailForm onSuccess={onSuccess} isAdmin={isAdmin} />}

        {step === 1 && (
          <OtpEmailForm
            smsAuthEnabled
            emailAuthEnabled
            softOtpAuthEnabled
            onFinished={onFinished}
            emails={emails}
            isAdmin={isAdmin}
          />
        )}
      </Card>

      <StatusModal
        open={statusModal}
        onClose={() => setStatusModel(false)}
        delay={0}
        type='success'
        title='Successfully'
        subtitle='Email changed successfully'
        onAction={onBack}
      />
    </>
  );
};

export default AccountSettingChangeEmail;
