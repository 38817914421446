import { useState } from 'react';
import { Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useTable from '../../../../hooks/useTable';
import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../components/table';
import FuturesPositionTableRow from './futuresPositionTableRow';
import FuturesMarginModal from '../futuresModals/futuresMarginModal';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'symbol', label: 'Symbol', align: 'left' },
  { id: 'amount', label: 'Size', align: 'left' },
  { id: 'entryPrice', label: 'Entry Price', align: 'left' },
  { id: 'liquidationPrice', label: 'Liq.Price', align: 'left' },
  { id: 'marginRation', label: 'Margin Ratio', align: 'left' },
  { id: 'margin', label: 'Margin', align: 'left' },
  // { id: 'pnl', label: 'PNL(ROE %)', align: 'left' },
  { id: 'pnl', label: 'PNL', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'closeAll', label: '', align: 'left' },
];

// ----------------------------------------------------------------------

export default function FuturesPositionTable({ positionsTableData, positionsTablePagination, onReload }) {
  const { t } = useTranslation();

  const [showEditMargin, setShowEditMargin] = useState(null);

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });
  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ position: 'relative', minWidth: 1100 }}>
          <Table size={'small'}>
            <TableHeadCustom headLabel={TABLE_HEAD} rowCount={positionsTableData?.length || 0} />

            <TableBody>
              {positionsTableData === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {positionsTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <FuturesPositionTableRow
                      key={row.id}
                      row={row}
                      onReload={onReload}
                      onEditMargin={() => setShowEditMargin(row)}
                    />
                  ))}

                  <TableNoData isNotFound={!positionsTableData?.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      {positionsTableData?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 200]}
          component='div'
          count={positionsTablePagination.totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage={t('Rows per page')}
          labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
        />
      )}

      <FuturesMarginModal open={!!showEditMargin} onClose={() => setShowEditMargin(null)} data={showEditMargin} />
    </>
  );
}
