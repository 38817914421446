import { Card } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageTitle } from '../../../components/PageTitle';
import { SET_PAGE_STATE } from '../../../store/actionTypes';
import BankCardTable from './bankCardTable';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function BankCard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'List of all bank cards' },
    });
  }, []);

  return (
    <>
      <Card>
        <PageTitle
          title={t('List of all bank cards')}
          subTitle={
            t('To edit, delete or approve or disapprove a bank card, click on the link to be redirected to the bank cards section in the users profile.')
          }
        />

        <BankCardTable />
      </Card>
    </>
  );
}
