import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format('$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.00%');
}

export function fFloat(number, decimalCount = 2) {
  if (Math.abs(number) < 0.01) {
    const nonZeroIndex = number
      .toString()
      .slice(number.toString().indexOf('.') + 1)
      .search(/[^0]/);

    return numeral(number).format(`0,0.${'0'.repeat(nonZeroIndex + 1)}`);
  }

  return numeral(number).format(`0,0.${'0'.repeat(decimalCount)}`);
}

export function fDecimal(number) {
  return numeral(number).format('0,0.00000000');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00 a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function toNumber(value) {
  if (value === '') return 0;
  return +`${value}`.replace(/,/g, '');
}

export const fDigit = (value) => {
  if (!value) return '0';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
