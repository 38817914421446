import { m } from 'framer-motion';
// @mui
import { Box, Container, Grid, Typography } from '@mui/material';
// components
import { PlatformBorder, PlatformBox, PlatformIcon, PlatformShadow, RootSubStyle } from '../style';
import MotionViewport from '../../../components/animate/MotionViewport';

import { varFade } from '../../../components/animate/variants/fade';
import Image from '../../../components/Image';

import graphIcon from '../../../assets/landing/graph.svg';
import lockIcon from '../../../assets/landing/lock.svg';
import smartphoneIcon from '../../../assets/landing/smartphone.svg';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Platform() {
  const { t } = useTranslation();

  return (
    <RootSubStyle>
      <Container component={MotionViewport}>
        <Box sx={{ textAlign: 'center' }}>
          <m.div variants={varFade().inUp}>
            <Typography variant='h3' sx={{ fontWeight: 400 }}>
              {t('The most trusted cryptocurrency platform.')}
            </Typography>
          </m.div>
          <m.div variants={varFade().inDown}>
            <Typography variant='body2' color='grey.300' sx={{ mt: 3 }}>
              {t('Cryptizi has a variety of features that make it the best place to start trading.')}
            </Typography>
          </m.div>
        </Box>

        <m.div variants={varFade().inUp}>
          <Grid container sx={{ mt: { xs: 3, md: 16 } }} spacing={{ xs: 7.5, md: 0 }}>
            <Grid item xs={12} md={4}>
              <PlatformBox>
                <PlatformIcon>
                  <PlatformShadow color='#F27F16' />
                  <Image src={graphIcon} alt='' sx={{ width: 50, position: 'absolute', left: 0, top: 0 }} />
                </PlatformIcon>

                <Typography variant='h6'>{t('Portofolio Manager')}</Typography>
                <Typography variant='body2' color='grey.300' sx={{ mt: 3, lineHeight: '24px' }}>
                  {t('Buy and sell popular digital currencies, keep track of them in the one place.')}
                </Typography>

                <PlatformBorder />
              </PlatformBox>
            </Grid>

            <Grid item xs={12} md={4}>
              <PlatformBox>
                <PlatformIcon>
                  <PlatformShadow color='#30E0A1' />
                  <Image src={lockIcon} alt='' sx={{ width: 50, position: 'absolute', left: 0, top: 0 }} />
                </PlatformIcon>

                <Typography variant='h6'>{t('Vault Protection')}</Typography>
                <Typography variant='body2' color='grey.300' sx={{ mt: 3, lineHeight: '24px' }}>
                  {t('For added security, store your funds in a vault with time delayed withdrawals.')}
                </Typography>

                <PlatformBorder />
              </PlatformBox>
            </Grid>

            <Grid item xs={12} md={4}>
              <PlatformBox>
                <PlatformIcon>
                  <PlatformShadow color='#BD47FB' />
                  <Image src={smartphoneIcon} alt='' sx={{ width: 50, position: 'absolute', left: 0, top: 0 }} />
                </PlatformIcon>

                <Typography variant='h6'>{t('Mobile Apps')}</Typography>
                <Typography variant='body2' color='grey.300' sx={{ mt: 3, lineHeight: '24px' }}>
                  {t('Stay on top of the markets with the Cryptizi app for Android or iOS.')}
                </Typography>

                <PlatformBorder />
              </PlatformBox>
            </Grid>
          </Grid>
        </m.div>
      </Container>
    </RootSubStyle>
  );
}
