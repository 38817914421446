import { useEffect, useRef, useState } from 'react';
import Api from '../../../../http/serviceApi';
import { toNumber } from '../../../../utils/formatNumber';

export default function usePerfectMoneyCalculate({ side, setValue, watch }) {
  const [unitData, setUnitData] = useState({ price: 0, purchaseFee: 0, saleFee: 0 });

  const { type, fiatType, perfectMoneyAmount, fiatAmount } = watch();

  const getPerfectMoneyUnit = async () => {
    try {
      const res = await Api.getPerfectMoneyUnit(side, type, fiatType);
      setUnitData(res);

      setValue('purchase', res.purchaseFee);
      setValue('sales', res.saleFee);

      const cal = toNumber(perfectMoneyAmount || 0) * res.price;
      setValue('fiatAmount', cal);
      lastFiatAmount.current = cal;
    } catch (e) {}
  };

  const getPaysafeUnit = async () => {
    try {
      const res = await Api.getPaysafeUnit(side, fiatType);
      setUnitData(res);

      setValue('purchase', res.purchaseFee);
      setValue('sales', res.saleFee);

      const cal = toNumber(perfectMoneyAmount || 0) * res.price;
      setValue('fiatAmount', cal);
      lastFiatAmount.current = cal;
    } catch (e) {}
  };

  useEffect(() => {
    if (type === 'PAYSAFE') {
      getPaysafeUnit();
    } else {
      getPerfectMoneyUnit();
    }
  }, [type, fiatType]);

  const lastFiatAmount = useRef(null);
  const lastPerfectMoneyAmount = useRef(null);

  const onCalculate = (value, changeFiat = false) => {
    if (changeFiat && lastFiatAmount.current !== toNumber(fiatAmount)) {
      const cal = toNumber(fiatAmount || 0) / value;
      setValue('perfectMoneyAmount', cal);
      lastPerfectMoneyAmount.current = cal;
    }
    //
    else if (!changeFiat && lastPerfectMoneyAmount.current !== toNumber(perfectMoneyAmount)) {
      const cal = toNumber(perfectMoneyAmount || 0) * value;
      setValue('fiatAmount', cal);
      lastFiatAmount.current = cal;
    }
  };

  useEffect(() => {
    onCalculate(unitData.price, false);
  }, [perfectMoneyAmount]);

  useEffect(() => {
    onCalculate(unitData.price, true);
  }, [fiatAmount]);

  return { unitData };
}
