// ----------------------------------------------------------------------

import { Box, Button, Card, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'jalali-moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { PageTitle } from '../../../../components/PageTitle';
import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../components/table';
import useTable from '../../../../hooks/useTable';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import { navigationUrl } from '../../../../navigationUrl';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import AdminListToolbar from './adminListToolbar';
import AdminTableRow from './adminTableRow';
import { useSnackbar } from 'notistack';
import confirmWrapper from '../../../../utils/confirm';
import { exportExcel } from '../../../../utils/excel';
import { exportPDF } from '../../../../utils/pdf';

const TABLE_HEAD = [
  { id: 'num', label: 'Num', align: 'center' },
  { id: 'firstName', label: 'First Name', align: 'left' },
  { id: 'lastName', label: 'Last Name', align: 'left' },
  { id: 'mobile', label: 'Mobile', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'condition', label: 'Condition', align: 'center' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'edit', label: 'Edit', align: 'center' },
  { id: 'delete', label: 'Delete', align: 'center' },
];

// ----------------------------------------------------------------------

export default function AdminList() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const language = useSelector((store) => store.language);

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Admins' },
    });
  }, []);

  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });

  const [conditionFilter, setConditionFilter] = useState('');
  const [dateFromFilter, setDateFromFilter] = useState(null);
  const [dateToFilter, setDateToFilter] = useState(null);

  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  // ----------------------------------------------
  const getAllAdmin = async (withFilter = true) => {
    try {
      const filters = {};

      if (withFilter) {
        if (dateFromFilter && dateToFilter) {
          filters.startTime = moment(dateFromFilter).startOf('days').valueOf();
          filters.endTime = moment(dateToFilter).endOf('days').valueOf();
        }

        if (conditionFilter) filters.userCondition = conditionFilter;
      }

      const response = await Api.getAllAdmin(page, rowsPerPage, filters);
      if (response) {
        setTableData(response.adminInfoList);
        setTablePagination({ totalItems: response.adminInfoListTotalPages * rowsPerPage });
      }
    } catch (e) {
      setTableData([]);
    }
  };

  // ----------------------------------------------
  useEffect(() => {
    getAllAdmin();
  }, [page, rowsPerPage]);

  // ----------------------------------------------
  const onDelete = async (userId) => {
    if (!(await confirmWrapper(t('Are you sure to remove this admin account?')))) {
      return false;
    }

    try {
      const response = await Api.deleteAdmin(userId);

      if (response?.status === 'SUCCESS') {
        getAllAdmin();
        enqueueSnackbar(t('The admin account is deleted successfuly'), { variant: 'success' });
      }
    } catch (e) {}
    return true;
  };

  // ----------------------------------------------
  const onChangeStatus = async (userId, isActive = true) => {
    try {
      const response = await Api.changeStatusAdmin(userId, isActive);

      if (response?.status === 'SUCCESS') {
        enqueueSnackbar(t(`The admin account is ${isActive ? 'activated' : 'de-activeted'} successfuly`), {
          variant: 'success',
        });
      }
    } catch (e) {
      getAllAdmin();
    }
    return true;
  };

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);
  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    const filters = {};
    if (dateFromFilter && dateToFilter) {
      filters.startTime = moment(dateFromFilter).startOf('days').valueOf();
      filters.endTime = moment(dateToFilter).endOf('days').valueOf();
    }

    if (conditionFilter) filters.userCondition = conditionFilter;

    try {
      const response = await Api.getAllAdmin(0, 10000000, filters);

      // generate excel and download
      const config = {
        language,
        fileName: t('Admins'),
        heads: ['#', t('First Name'), t('Last Name'), t('Mobile'), t('Email'), t('Condition'), t('Date')],
        columnsWidth: [5, 15, 15, 12, 34, 12, 23],
        list: response.adminInfoList.map((item) => [
          item.firstName,
          item.lastName,
          '-',
          item.emailAddress,
          item.condition ? t('Active') : t('Inactive'),
          item.registeredDate ? moment(item.registeredDate).locale(language).format('D MMM YYYY - HH:mm') : '-',
        ]),
      };

      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  // --------------------
  return (
    <>
      <Button
        variant='contained'
        onClick={() => navigate(navigationUrl.admin_new)}
        size='large'
        sx={{
          typography: 'body2',
          width: 160,
          mt: 2.5,
          mb: 3.5,
        }}
        className='no-print'
      >
        {t('Add New Admin')}
      </Button>

      <Card>
        <PageTitle title={'Support'} subTitle={'Caption'} />

        <AdminListToolbar
          conditionFilter={conditionFilter}
          setConditionFilter={setConditionFilter}
          dateFromFilter={dateFromFilter}
          setDateFromFilter={setDateFromFilter}
          dateToFilter={dateToFilter}
          setDateToFilter={setDateToFilter}
          onFilter={getAllAdmin}
          isExportLoading={isExportLoading}
          onExport={onExport}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size='small'>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length || 0}
                onSort={onSort}
              />
              <TableBody>
                {tableData === null ? (
                  <TableLoading isLoading />
                ) : (
                  <>
                    {tableData?.map((row, index) => (
                      <AdminTableRow
                        key={row.userId}
                        row={row}
                        index={index}
                        onDelete={() => onDelete(row.userId)}
                        onChangeStatus={onChangeStatus}
                      />
                    ))}

                    <TableNoData isNotFound={!tableData?.length} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        {tableData?.length > 0 && (
          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              component='div'
              count={tablePagination.totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              labelRowsPerPage={t('Rows per page')}
              labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
            />
          </Box>
        )}
      </Card>
    </>
  );
}
