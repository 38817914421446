import { TableRow, TableCell, Typography, IconButton, Stack } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import { fFloat } from '../../../../utils/formatNumber';
import Iconify from '../../../../components/Iconify';
import SymbolIcon from '../../../../components/SymbolIcon';

// ----------------------------------------------------------------------

export default function ConvertHistoryTableRow({ row, onShowModalDetail, isAdmin = false }) {
  const language = useSelector((store) => store.language);

  const {
    symbol,
    firstName,
    lastName,
    emailAddress,
    mobile,
    amount,
    entryPrice,
    pnl,
    leverage,
    createDate,
    margin,
    marginRatio,
    type
  } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 2, typography: 'body2', color: 'grey.100' } }}>
      <TableCell>{moment(createDate).locale(language).format('YYYY-MM-DD HH:mm')}</TableCell>

      {isAdmin && (
        <TableCell>
          <Typography variant='body3'>
            {firstName} {lastName}
          </Typography>
          {emailAddress && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {emailAddress}
            </Typography>
          )}
          {mobile && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {mobile}
            </Typography>
          )}
        </TableCell>
      )}

      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ width: 80 }}>
          <SymbolIcon symbol={symbol} />
          <Typography variant='body3' noWrap sx={{ color: 'grey.100' }}>
            {symbol}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell align='center'>{amount}</TableCell>

      <TableCell align='center'>{fFloat(entryPrice)}</TableCell>

      <TableCell align='center'>{leverage}</TableCell>
      <TableCell align='center'>{type}</TableCell>

      <TableCell align='center'>{fFloat(pnl)}</TableCell>

      <TableCell align='center'>{fFloat(margin)}</TableCell>

      <TableCell align='center'>{fFloat(marginRatio)}</TableCell>

      <TableCell align='center' sx={{ width: 100 }}>
        <IconButton onClick={onShowModalDetail}>
          <Iconify icon='ph:link-duotone' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
