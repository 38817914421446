import { m } from 'framer-motion';
// @mui
import { Box, Button, Container, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
// components
import {
  PayoutBorder,
  PayoutBox,
  PayoutContent,
  PayoutCurveShape,
  PayoutLogoShadow,
  PayoutLogoWrapper,
  PayoutShadow,
  PayoutShadowSmall,
  RootSubStyle,
} from '../style';
import MotionViewport from '../../../components/animate/MotionViewport';

import { varFade } from '../../../components/animate/variants/fade';
import Image from '../../../components/Image';
import Iconify from '../../../components/Iconify';

import tickIcon from '../../../assets/landing/tick.svg';
import curveShape from '../../../assets/landing/curveShape.svg';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Payout() {
  const { t } = useTranslation();

  return (
    <RootSubStyle sx={{ position: 'relative' }}>
      <PayoutShadow />
      <PayoutShadowSmall />
      <PayoutCurveShape src={curveShape} sx={{ display: { xs: 'none', md: 'block' } }} />

      <Container component={MotionViewport}>
        <Grid container sx={{ mt: { xs: -5, md: 8 } }} spacing={{ xs: 6.5, md: 0 }}>
          <Grid item xs={12} md={8}>
            <Box sx={{ pt: { xs: 0, md: 12.5 }, pl: { xs: 0, md: 10 }, textAlign: { xs: 'center', md: 'left' } }}>
              <m.div variants={varFade().inRight}>
                <Typography
                  variant='h3_secondary'
                  color='grey.100'
                  sx={{ fontWeight: 500, width: { xs: '100%', md: 500 }, display: 'inline-block' }}
                >
                  {t('One click, instant payout with credit or debit card.')}
                </Typography>
              </m.div>
              <m.div variants={varFade().inRight}>
                <Typography variant='body2' color='grey.300' sx={{ mt: 3, width: { xs: '100%', md: 440 } }}>
                  {t(
                    'Become a crypto owner in minutes using your debit or credit card and quickly purchase top cryptocurrencies.'
                  )}
                </Typography>
              </m.div>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <m.div variants={varFade().inUp}>
              <PayoutBox>
                <PayoutContent>
                  <Stack justifyContent={'space-between'} alignItems={'center'} sx={{ height: '100%' }}>
                    <Stack
                      direction='row'
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      sx={{ width: '100%' }}
                    >
                      <Typography variant='body4'>{t('Intermediate Verification')}</Typography>
                      <IconButton>
                        <Iconify icon='akar-icons:cross' color='grey.0' sx={{ width: 10, height: 10 }} />
                      </IconButton>
                    </Stack>

                    <Stack sx={{ width: '100%' }} alignItems={'center'}>
                      <PayoutLogoWrapper justifyContent={'center'} alignItems={'center'}>
                        <PayoutLogoShadow />
                        <Image src={tickIcon} alt='' sx={{ width: 97.5, height: 55, mt: -1 }} />
                      </PayoutLogoWrapper>

                      <Typography variant='body4' color='grey.100' sx={{ mt: 2 }}>
                        {t('Intermediate Verified')}
                      </Typography>
                      <Typography variant='caption' color='grey.300'>
                        {t('Additional Infomation')}
                      </Typography>
                    </Stack>

                    <Stack spacing={1}>
                      <Button variant='contained' color='success' size='small' sx={{ width: 160, height: 23 }}>
                        <Typography variant='body4'>{t('OK')}</Typography>
                      </Button>
                      <Button
                        variant='contained'
                        color='inherit'
                        size='small'
                        sx={{
                          width: 160,
                          height: 23,
                          backgroundColor: 'grey.400',
                          '&:hover': {
                            backgroundColor: 'grey.600',
                          },
                        }}
                      >
                        <Typography variant='caption'>{t('Upgrade to Higher Limits')}</Typography>
                      </Button>
                    </Stack>
                  </Stack>
                </PayoutContent>

                <PayoutBorder sx={{ display: { xs: 'none', md: 'block' } }} />
              </PayoutBox>
            </m.div>
          </Grid>
        </Grid>
      </Container>
    </RootSubStyle>
  );
}
