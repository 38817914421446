import { TableRow, TableCell } from '@mui/material';

import moment from 'jalali-moment';
import { useSelector } from 'react-redux';

export default function AdminLogTableRow({ row, index }) {
  const { message, registerDate } = row;
  const language = useSelector((store) => store.language);

  return (
    <TableRow hover sx={{ '& td': { py: 1.5, typography: 'body2', color: 'grey.100' } }}>
      <TableCell align='center' sx={{ width: 100 }}>
        {index + 1}
      </TableCell>

      <TableCell>{message}</TableCell>

      <TableCell sx={{ width: 220 }}>{moment(registerDate).locale(language).format('D MMM YYYY - HH:mm')}</TableCell>
    </TableRow>
  );
}
