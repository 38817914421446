import { useSnackbar } from 'notistack';
import Api from '../../../../../http/adminHttp/serviceApiAdmin';
import { useTranslation } from 'react-i18next';


export default function useGatewaySubmit(values, gateway) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (payload) => {

    const data = {
      active: payload.active,
      bankName: values,
      friTime: payload.friTime,
      gatewayDepositModel: payload.gatewayDepositModel,
      mobile: payload.mobile,
      monTime: payload.monTime,
      name: gateway,
      satTime: payload.satTime,
      sunTime: payload.sunTime,
      thuTime: payload.thuTime,
      token: payload.token,
      tueTime: payload.tueTime,
      walletId: payload.walletId,
      wedTime: payload.wedTime,
    };

    const response = await Api.editGatewaySettings(data);
    if(response.status === 'SUCCESS'){
      enqueueSnackbar(t('The values saved successfully.'), { variant: 'success' });
    }
  };

  return {
    onSubmit,
  };
}
