import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';

import ModalContainer from '../ModalContainer';
import RowSymbol from './rowSymbol';
import Api from '../../http/serviceApi';
import ModalFilter from '../ModalFilter';

const itemsPerPage = 15;

export default function ActiveCryptoModal({
  open,
  onClose,
  onSelect = () => {},
  title = 'Select Coin',
  allowSymbols = null,
  selectedSymbol,
}) {
  const [filtered, setFiltered] = useState([]);
  const [dataAllowSource, setDataAllowSource] = useState([]);

  // fetching data from server
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const lastSelectedSymbol = useRef(null);

  // -------------------------
  const fetchActiveSymbols = async () => {
    setLoading(true);
    try {
      const data = await Api.getActiveSymbols();

      if (data.status === 'SUCCESS') {
        setDataSource(data.currencyInfos);
        setDataAllowSource(data.currencyInfos);
        setFiltered(data.currencyInfos);
      }
    } catch (e) {}
    setLoading(false);
  };

  // -------------------------
  useEffect(() => {
    fetchActiveSymbols();
  }, []);

  useLayoutEffect(() => {
    if (dataSource?.length && selectedSymbol && lastSelectedSymbol.current !== selectedSymbol) {
      lastSelectedSymbol.current = selectedSymbol;
      const data = dataSource.find((item) => item.symbol === selectedSymbol);
      data && onSelect(data.symbol, data);
    }
  }, [selectedSymbol, dataSource]);

  // -------------------------
  useEffect(() => {
    if (allowSymbols?.length > 0) {
      const list = dataSource.filter((d) => allowSymbols.indexOf(d.symbol) !== -1);
      setDataAllowSource(list);
      setFiltered(list);
    }
  }, [allowSymbols]);

  // lazy load list
  const [hasMore, setHasMore] = useState(true);
  const [countPerPage, setCountPerPage] = useState(itemsPerPage);
  const loadMore = () => {
    if (countPerPage >= filtered.length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setCountPerPage((prev) => prev + itemsPerPage);
      }, 300);
    }
  };

  const onSearch = (newList) => {
    setFiltered(newList);
    setCountPerPage(itemsPerPage);
    if (itemsPerPage < newList.length) {
      setHasMore(true);
    }
  };

  useEffect(() => {
    if (!open) {
      setFiltered(dataAllowSource);
    }
  }, [open]);

  return (
    <ModalContainer open={open} onClose={onClose} title={title} width={400}>
      <ModalFilter dataSource={dataAllowSource} setFiltered={onSearch} />

      {loading ? (
        <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: 340 }}>
          <CircularProgress size={30} color='success' />
        </Stack>
      ) : (
        <Box
          sx={{
            height: 340,
            overflow: 'auto',
            '&::-webkit-scrollbar': { width: 18 },
            '&::-webkit-scrollbar-track': { backgroundColor: 'transparent' },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#53555f',
              borderRadius: '18px',
              border: '6px solid transparent',
              backgroundClip: 'content-box',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#6c6e75',
            },
          }}
        >
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={
              <Stack key='loader' alignItems={'center'} justifyContent={'center'} sx={{ my: 2 }}>
                <CircularProgress size={20} color='success' />
              </Stack>
            }
            useWindow={false}
          >
            {filtered.slice(0, countPerPage).map((item) => (
              <RowSymbol
                key={item.symbol}
                item={item}
                onSelect={() => {
                  onSelect(item.symbol, item);

                  onClose();
                }}
              />
            ))}
          </InfiniteScroll>
        </Box>
      )}
    </ModalContainer>
  );
}
