import { TableCell, TableRow, Typography, Box, Stack, IconButton, Avatar } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import Iconify from '../../../../../../components/Iconify';
const AdminTomanTransactionReceiptTableRow = ({ row, index ,setModalData}) => {
  const language = useSelector((store) => store.language);
  const {
    accountNumber,
    amount,
    depositType,
    firstName,
    lastName,
    status,
    trackingCode,
    userId,
    stripeTxId,
    fiatType,
    createDate,
    cardType,
    shetabGateway,
    receiptImageUrl,
    emailAddress,
    mobile,
  } = row;

  return (

    <TableRow hover sx={{ '& td': { py: 1, typography: 'body2' } }}>
      <TableCell align='center' sx={{ width: 100 }}>
        {index + 1}
      </TableCell>

      <TableCell>
        <Typography variant='body2'>{accountNumber}</Typography>
      </TableCell>

      <TableCell align='center'>{amount}</TableCell>
      <TableCell align='center'>{fiatType}</TableCell>


      {moment(createDate).locale(language).format('D MMM YYYY - HH:mm')}

      <TableCell>
        <Typography variant='body2'>{firstName}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant='body2'>{lastName}</Typography>
      </TableCell>

      <TableCell sx={{ width: 170 }} align='center'>
        <Box
          sx={{
            width: 130,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            p: 0.8,
            bgcolor: status === 'SUCCESS' ? 'rgba(48, 224, 161, 0.2)' : 'rgba(255, 69, 58, 0.2)',
          }}
        >
          <Typography variant='body2' color={status === 'SUCCESS' ? '#30E0A1' : '#FF453A'}>
            {status}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align='center'>{trackingCode}</TableCell>

      <TableCell align='center'>{userId}</TableCell>

      {/*<TableCell align='center'>*/}
      {/*    <Typography variant='body2'>{stripeTxId}</Typography>*/}
      {/*</TableCell>*/}

      {/*<TableCell>{fiatType}</TableCell>*/}

      {/*<TableCell>*/}
      {/*    <Typography variant='body2'>*/}
      {/*        {moment(createDate).locale(language).format('D MMM YYYY - HH:mm')}*/}
      {/*    </Typography>*/}
      {/*</TableCell>*/}

      {/*<TableCell align='center'>*/}
      {/*    <Stack direction={'row'} alignItems={'center'} spacing={1}>*/}
      {/*        <Typography variant='body2'>{cardType}</Typography>*/}
      {/*        <Typography variant='body2'>/</Typography>*/}
      {/*        <Typography variant='body2'>{shetabGateway}</Typography>*/}
      {/*    </Stack>*/}
      {/*</TableCell>*/}

      {/*<TableCell align='center'>*/}
      {/*    <Typography variant='body2'>{emailAddress}</Typography>*/}
      {/*</TableCell>*/}

      {/*<TableCell align='center'>*/}
      {/*    <Typography variant='body2'>{mobile}</Typography>*/}
      {/*</TableCell>*/}

      {/*<TableCell align='center' sx={{ width: 100 }}>*/}
      {/*    {receiptImageUrl && (*/}
      {/*        <Avatar*/}
      {/*            alt="Receipt Image"*/}
      {/*            src={receiptImageUrl}*/}
      {/*            sx={{ width: 48, height: 48, m: 'auto' }}*/}
      {/*        />*/}
      {/*    )}*/}
      {/*</TableCell>*/}

      <TableCell align='center' sx={{ width: 100 }}>
        <IconButton onClick={()=>setModalData(row)}>
          <Iconify icon='ph:link-duotone' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default AdminTomanTransactionReceiptTableRow;