import React from 'react';
import { Form, Input, Select, Button, Row, Col } from 'antd';
import classes from './advancedAuthAddressForm.module.scss';
import { InputCustom } from '../../../../../components/InputCustom/inputCustom';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Api from '../../../../../http/serviceApi';
import { navigationUrl } from '../../../../../navigationUrl';
import { useTranslation } from 'react-i18next';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';

const AdvancedAuthAddressForm = () => {
  const { t } = useTranslation();
  const [country, setCountry] = React.useState('');
  const [postalCode, setPostalCode] = React.useState('');
  const [city, setCity] = React.useState('');
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const userInfo = useSelector((store) => store.userInfo);

  const onAddressChange = (e) => {
    // setAddress(e.target.value)
  };
  const onPostalCodeChange = (e) => {
    setPostalCode(e.target.value);
  };
  const onCityChange = (e) => {
    setCity(e.target.value);
  };

  useEffect(() => {
    getCountryById();
  }, []);

  const getCountryById = async () => {
    const response = await Api.getCountryById(userInfo.countryId);
    if (response) {
      setCountry(response.name);
    }
  };

  const colPropsForm = {
    xs: 24,
    sm: 11,
    md: 11,
    lg: 11,
    xl: 11,
  };

  const colPropsButton = {
    xs: 24,
    sm: 10,
    md: 10,
    lg: 10,
    xl: 10,
  };

  return (
    <div className={classes.container}>
      <div className={classes.formTitleDiv}>
        <span className={classes.formTitleBig}>{t('Advanced Verification')}</span>
        <span className={classes.formTitleSmall}>{t('Confirm Residential Address')}</span>
      </div>

      <Form layout='vertical' name='advancedAuthentication' autoComplete='off'>
        <div className={classes.fixDataDiv}>
          <span>{t('Contry / region')}</span>
          <span>{country}</span>
        </div>

        <div className={classes.fixDataDiv}>
          <span>{t('Street Address')}</span>
          <span>{userInfo.address}</span>
        </div>

        <Row justify='space-between'>
          <Col {...colPropsForm}>
            <div className={classes.fixDataDiv}>
              <span>{t('Postal Code')}</span>
              <span>{userInfo.postalCode}</span>
            </div>
          </Col>
          <Col {...colPropsForm}>
            <div className={classes.fixDataDiv}>
              <span>{t('City')}</span>
              <span>{userInfo.cityName}</span>
            </div>
          </Col>
        </Row>

        <Row justify='space-evenly' style={{ marginTop: '50px' }}>
          <Col {...colPropsButton}>
            <Form.Item>
              <Button type='primary' block shape='round' onClick={() => navigate(navigationUrl.auth_advanced_select)}>
                {t('Continue')}
              </Button>
            </Form.Item>
          </Col>
          <Col {...colPropsButton}>
            <Form.Item>
              <Button type='default' block shape='round' onClick={() => navigate(navigationUrl.auth)}>
                {t('Back')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div className={classes.indicatorDiv}>
        <div className={classes.indicatorItemGreen} />
        <div className={classes.indicatorItemGray} />
      </div>
    </div>
  );
};

export default AdvancedAuthAddressForm;
