import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function useVoucherForm() {
  const { t } = useTranslation();

  const validators = Yup.object().shape({
    google: Yup.boolean().notRequired(),
    sms: Yup.boolean().notRequired(),
    email: Yup.boolean().notRequired(),
    googleCode: Yup.string().notRequired(),
    smsCode: Yup.string().notRequired(),
    emailCode: Yup.string().notRequired(),
  });

  const defaultValues = {
    google:true,
    sms:false,
    email:false,
    googleCode:'',
    smsCode:'',
    emailCode:'',
  };

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = methods;

  return {
    handleSubmit,
    isSubmitting,
    methods,
    google: watch('google'),
    sms: watch('sms'),
    email: watch('email'),
  };
}
