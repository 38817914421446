import { Stack, MenuItem, Typography, Box, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import ModalContainer from '../../../components/ModalContainer';
import { FormProvider, RHFNumberField, RHFSelect } from '../../../components/hookForm';
import useBankCardForm from '../../../hooks/useBankCardForm';
import { CARD_INTERNATIONAL_FORM, CARD_TYPE } from './bankCardTable';
import Image from '../../../components/Image';
import { useEffect } from 'react';
import Api from '../../../http/adminHttp/serviceApiAdmin';

//=====================================================

export default function EditCardModal({ open, onClose, data = null, userId = null, reload, userInfo }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { handleSubmit, methods, reset, setValue, isSubmitting, cardType } = useBankCardForm({ userMode: false });

  const onSubmit = async (payload) => {
    try {
      let response;
      if (data) {
        response = await Api.updateBankCard({ cardId: data.cardId, ...payload });
      } else {
        response = await Api.addBankCard(userId, {
          ...payload,
          firstName: userInfo?.firstName || '',
          lastName: userInfo?.lastName || '',
        });
      }

      if (response.status === 'SUCCESS') {
        enqueueSnackbar(t(`The bank card is ${data ? 'updated' : 'added'} successfully`), { variant: 'success' });

        reload();
        onClose();
        reset();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (data) {
      Object.entries(data).forEach(([field, value]) => {
        setValue(field, field === 'shebaNum' ? value.replace('IR', '') : value);
      });
    }
  }, [data]);

  return (
    <ModalContainer open={open} onClose={onClose} title={data ? 'Edit Card' : 'Add Card'} width={380}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack alignItems={'center'} sx={{ mt: 3, mb: 2, px: 2 }} spacing={3}>
          <RHFSelect
            name='cardType'
            SelectProps={{
              native: false,
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' } },
              },
            }}
            label={t('Type of Card')}
            variant='standard'
            fullWidth
          >
            {CARD_TYPE.map((type, index) => (
              <MenuItem value={type.value} key={index}>
                <Stack direction='row' spacing={1} alignItems={'center'}>
                  <Image src={type.icon} />
                  <Typography typography='body3' color='grey.300'>
                    {t(type.label)}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </RHFSelect>

          {cardType && (
            <>
              <RHFNumberField
                name='cardNumber'
                label={t('Card Number')}
                variant='standard'
                format='#### #### #### ####'
                sx={{ '& input': { letterSpacing: 0.5 } }}
              />

              <Stack direction='row' spacing={2}>
                <RHFNumberField name='expiryDateMonth' label={t('Month of date')} variant='standard' format='##' />
                <RHFNumberField name='expiryDateYear' label={t('Year of date')} variant='standard' format='##' />
              </Stack>

              <RHFNumberField name='cvv2' label='CVV2' variant='standard' thousandSeparator={false} />

              {!CARD_INTERNATIONAL_FORM[cardType] && (
                <>
                  <RHFNumberField
                    name='accountNumber'
                    label={t('Account Number')}
                    variant='standard'
                    thousandSeparator={false}
                  />
                  <RHFNumberField
                    name='shebaNum'
                    label={t('Shaba')}
                    variant='standard'
                    thousandSeparator={false}
                    inputProps={{ maxLength: 24 }}
                    sx={{ '& input': { letterSpacing: 1 } }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Typography color='grey.300' variant='body2'>
                            IR
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </>
              )}
            </>
          )}

          <Box sx={{ width: '100%' }}>
            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              loading={isSubmitting}
              sx={{ mt: 2 }}
            >
              {t(data ? 'Edit Card' : 'Add Card')}
            </LoadingButton>
          </Box>
        </Stack>
      </FormProvider>
    </ModalContainer>
  );
}
