import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import backend from 'i18next-http-backend';

import fa from '../locales/fa/translation';
import ru from '../locales/web-ru';
import ar from '../locales/cryptizi-ar'
import tr from '../locales/web-tr'
import ku from '../locales/web - ku';
import arm from '../locales/web-hy-Cryptizi';


i18n
  // .use(backend)
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources: { fa  , ru , ar  , tr , ku , arm},
    lng: 'en', // default language
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      addPath: 'http://localhost:4000/add?lng={{lng}}&ns={{ns}}',
    },
    saveMissing: true, // send not translated keys to endpoint
  });

export default i18n;
