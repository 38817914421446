import { useTheme } from '@mui/material/styles';
import ReactApexChart from 'react-apexcharts';
import mergeDeep from '../utils/merge';
import { BaseOptionChart } from './chart';

export default function SmallAreaChart({
  isPositive = true,
  data = [17, 25, 23, 20, 25, 20, 15, 18, 14, 10, 26, 21, 20, 28, 35, 30, 32, 28, 27, 20],
  width = 137,
  height = 38,
}) {
  const theme = useTheme();


  const chartOptions = mergeDeep(BaseOptionChart(), {
    chart: { animations: { enabled: true }, sparkline: { enabled: true } },
    stroke: { width: 1 },
    fill: {
      colors: [theme.palette.grey[0]],
      type: 'gradient',
      gradient: {
        shade: 'dark',
        shadeIntensity: 1,
        type: 'vertical',
        opacityFrom: 0.7,
        opacityTo: 0,
        stops: [0, 95, 100],
      },
    },
    tooltip: {
      x: { show: false },
      y: {
        formatter: (seriesName) => seriesName,
        title: {
          formatter: () => '',
        },
      },
      marker: { show: false },
    },
  });

  const positiveChartOptions = { ...chartOptions, colors: [theme.palette.positive.main] };
  const negativeChartOptions = { ...chartOptions, colors: [theme.palette.negative.main] };
  const d = data?.length === 0 ? [0,0,0,0,0,0] : data

  return (
    <ReactApexChart
      type='area'
      series={[{ data : d }]}
      options={isPositive ? positiveChartOptions : negativeChartOptions}
      width={width}
      height={height}
    />
  );
}
