export const navigationUrl = {
  landing: '/',
  about: '/about',
  contact: '/contact',
  terms: '/terms-of-use',
  faq: '/faq',
  privacy: '/privacy-policy',
  otcTerms: '/terms-otc',
  convertTerms: '/terms-convert',
  tokens: '/listing',

  learn_earn: '/learn/earn',
  learn_browseCryptoPrices: '/learn/browseCryptoPrices',
  learn_academy: '/learn/academy',
  learn_androidApp: '/learn/androidApp',
  learn_iosApp: '/learn/iosApp',

  login: '/login',
  register: '/register',
  verify: '/verify',
  verifySoftOtp: '/VerifySoftOtp',
  forgotpass: '/forgotpass',
  forgotpass_checkemail: '/forgotpass/checkemail',
  forgotpass_newpass: '/forgotpass/newpass',
  forgotpass_resetok: '/forgotpass/resetok',
  panel: '/panel',
  auth: '/panel/authentication',
  auth_basic_identityinfo: '/panel/authentication/basic/identityinfo',
  auth_basic_additionalinfo: '/panel/authentication/basic/additionalinfo',
  auth_basic_verified: '/panel/authentication/basic/verified',
  auth_inter_select: '/panel/authentication/intermediate/select',
  auth_inter_additionalinfo: '/panel/authentication/intermediate/additionalinfo',
  auth_inter_verified: '/panel/authentication/intermediate/verified',
  auth_inter_facescan: '/panel/authentication/intermediate/facescan',
  auth_advanced_address: '/panel/authentication/advanced/address',
  auth_advanced_select: '/panel/authentication/advanced/select',
  auth_advanced_verified: '/panel/authentication/advanced/verified',
  accountSetting: '/panel/accountSetting',
  wallet: '/panel/wallet',
  futures_wallet: '/panel/futuresWallet',
  openTrade: '/panel/openTrade',
  bankcard: '/panel/bankCard',
  trade: '/panel/trade',
  p2p_list: '/panel/p2p/list',
  p2p_request: '/panel/p2p/request',
  perfectMoney: '/panel/eMoney',
  convert: '/panel/convert',
  otc: '/panel/otc',
  futures: '/panel/futures',
  market: '/panel/market',
  deposit_crypto_request: '/panel/depositCrypto/request',
  deposit_crypto_list: '/panel/depositCrypto/list',
  withdraw_crypto_request: '/panel/withdrawCrypto/request',
  withdraw_crypto_list: '/panel/withdrawCrypto/list',
  fiatDeposit: '/panel/fiatDeposit',
  fiatWithdraw: '/panel/fiatWithdraw',
  history_trade: '/panel/tradeHistory',
  history_transaction: '/panel/transactionHistory',
  order_history: '/panel/orderHistory',
  p2p_history: '/panel/p2pHistory',
  convert_history: '/panel/convertHistory',
  internal_transaction: '/panel/internalTransaction',
  futures_history: '/panel/futuresHistory',
  paysafe_history: '/panel/paysafeHistory',
  perfectMoney_history: '/panel/perfectMoneyHistory',
  crypto_transaction: '/panel/cryptoTransaction',
  fiat_transaction: '/panel/fiatTransaction',
  referrals_income: '/panel/referralsIncome',
  spot: '/panel/spot',
  margin: '/panel/margin',
  strategy_trading: '/panel/strategyTrading',
  ticket_list: '/panel/ticket/list',
  ticket_new: '/panel/ticket/new',
  ticket_view: (id) => `/panel/ticket/view/${id}`,
  notification: '/panel/notification',

  admin_login: '/admin/login',
  admin_verifyEmail: '/admin/verifyEmail',
  admin_forgotpass: '/admin/forgotpass',
  admin_forgotpass_checkemail: '/admin/forgotpass/checkemail',
  admin_forgotpass_newpass: '/admin/forgotpass/newpass',
  admin_forgotpass_resetok: '/admin/forgotpass/resetok',
  admin: '/admin',
  admin_user: '/admin/user',
  admin_user_waiting: '/admin/waitingUser',
  admin_add_user: '/admin/addUser',
  admin_edit_user: (userId) => `/admin/user/edit/${userId}`,
  admin_user_management: '/admin/user/management',
  admin_list: '/admin/admin/list',
  admin_new: '/admin/admin/new',
  admin_edit: (id) => `/admin/admin/edit/${id}`,
  admin_setting_userLevel: '/admin/setting/userLevel',
  admin_setting_ipBlock: '/admin/setting/ipBlock',
  admin_setting_network: '/admin/setting/network',
  admin_setting_new_network: '/admin/setting/network/new',
  admin_setting_general: '/admin/setting/general',
  admin_setting_auto_deposit: '/admin/setting/autoDeposit',
  admin_setting_dedicatedGateway: '/admin/setting/dedicatedGateway',
  admin_setting_deposit: '/admin/setting/deposit',
  admin_setting_popup: '/admin/setting/popup',
  admin_setting_commission: '/admin/setting/commission',
  admin_setting_inventory: '/admin/setting/inventory',
  admin_setting_voucher: '/admin/setting/voucher',
  admin_setting_perfectMoney: '/admin/setting/perfectMoney',
  admin_setting_paySafe: '/admin/setting/paySafe',
  admin_setting_cryptoCurrency: '/admin/setting/cryptoCurrency',
  admin_setting_tether: '/admin/setting/tether',
  admin_setting_withdrawal_ceiling: '/admin/setting/withdrawalCeiling',
  admin_setting_digital_currency: '/admin/setting/digitalCurrency',
  admin_setting_verification: '/admin/setting/verification',
  admin_wallet_transaction: '/admin/wallet/transaction',
  admin_wallet_crypto_transaction: '/admin/wallet/cryptoTransaction',
  admin_reporting: '/admin/report',
  admin_referral_marketing: '/admin/referralMarketing',
  admin_bank_card: '/admin/bankCard',
  admin_ticket_list: '/admin/ticket/list',
  admin_ticket_new: '/admin/ticket/new',
  admin_ticket_view: (id) => `/admin/ticket/view/${id}`,
  admin_order: '/admin/order',
  admin_history: '/admin/history',
  admin_fiat_withdraw_waiting: '/admin/waitingWithdrawFiat',
  admin_fiat_deposit_waiting: '/admin/waitingDepositFiat',
  admin_automatic_transaction: '/admin/automaticTransaction',
  admin_user_level_setting: '/admin/setting/userLevel',
  futures_setting: '/admin/setting/futures',
  admin_related_reference_account: '/admin/relatedReferenceAccount',
  admin_notification: '/admin/notification',
  admin_setting_notification: '/admin/settingNotification',
  admin_account_setting: '/admin/accountSetting',
  admin_automatic_deposit_history: '/admin/automaticDepositHistory',
  admin_history_trade: '/admin/tradeHistory',
  admin_open_trade: '/admin/openTrade',
  admin_order_history: '/admin/orderHistory',
  admin_p2p_history: '/admin/p2pHistory',
  admin_paysafe_history: '/admin/paysafeHistory',
  admin_perfectMoney_history: '/admin/perfectMoneyHistory',
  admin_convert_history: '/admin/convertHistory',
  admin_internal_transaction: '/admin/internalTransaction',
  admin_futures_history: '/admin/futuresHistory',
  admin_crypto_transaction: '/admin/cryptoTransaction',
  admin_fiat_transaction: '/admin/fiatTransaction',
  admin_dedicated_wallet_settings: '/admin/dedicated-wallet-settings',

  admin_token_list: '/admin/token',
  admin_token_add: '/admin/token/add',
  admin_token_edit: (id) => `/admin/token/edit/${id}`,

  admin_token_main_wallet_setting: '/admin/token/mainWalletSetting',
  admin_token_gas_pump_wallet_setting: '/admin/token/gasPumpWalletSetting',
  admin_token_network_setting: '/admin/token/networkSetting',
  fullNodePage:'/admin/dedicated-wallet-settings',
  notFound: '/404',
};
