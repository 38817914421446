import React from 'react';
import { TableCell, TableRow, Typography, Box, Stack, IconButton } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SymbolIcon from '../../../../../../components/SymbolIcon';
import { prettyUppercases } from '../../../../../../utils';

const TradeHistoryTableRow = ({ row, index, tradeType }) => {
  const language = useSelector((store) => store.language);
  const { t } = useTranslation();

  const { transactionDate, commissionAsset, side, state, type, amount, commission, price, stopPrice, total } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1, typography: 'body2' } }}>
      <TableCell align='center' sx={{ width: 100 }}>
        {index + 1}
      </TableCell>

      <TableCell>{transactionDate ? moment(transactionDate).locale(language).format('D MMM YYYY - HH:mm') :'-'}</TableCell>

      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <SymbolIcon symbol={commissionAsset} />
          <Typography variant='body3'>{commissionAsset}</Typography>
        </Stack>
      </TableCell>

      <TableCell align='center'>{t(prettyUppercases(side))}</TableCell>
      <TableCell align='center'>{t(prettyUppercases(state))}</TableCell>
      <TableCell align='center'>{t(prettyUppercases(type))}</TableCell>
      <TableCell align='center'>
        <Typography variant='body3'>{amount?.toLocaleString('en-US')}</Typography>
      </TableCell>

      <TableCell align='center'>
        <Typography variant='body3'>{commission?.toLocaleString('en-US')}</Typography>
      </TableCell>

      <TableCell align='center'>
        <Typography variant='body3'>{price?.toLocaleString('en-US')}</Typography>
      </TableCell>

      <TableCell align='center'>
        <Typography variant='body3'>{stopPrice?.toLocaleString('en-US')}</Typography>
      </TableCell>

      <TableCell align='center'>
        <Typography variant='body3'>{total?.toLocaleString('en-US')}</Typography>
      </TableCell>

      {/* <TableCell>
        <Stack spacing={0.5}>
          <Typography variant='body2'>{name}</Typography>
          <Typography variant='body2'>{mobile}</Typography>
        </Stack>
      </TableCell> */}

      {/* <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <Iconify
            icon={arrow === 'up' ? 'ph:arrow-up-fill' : 'ph:arrow-down-fill'}
            color={arrow === 'up' ? 'error.darker' : 'positive.main'}
            height={18}
          />
          <Typography variant='body2' color={arrow === 'up' ? 'error.darker' : 'positive.main'}>
            {type}
          </Typography>
        </Stack>
      </TableCell> */}

      {/* <TableCell sx={{ width: 90, display: 'flex' }} align='center'>
        <Box
          sx={{
            width: 100,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            p: 0.8,
            ...(tradeType === 'open'
            ? { backgroundColor: 'rgba(48, 224, 161, 0.2)' }
            : { backgroundColor: 'rgba(250, 34, 86, 0.2)' }),
          }}
        >
          <Typography variant='body2' color={tradeType === 'done' ? 'error.darker' : '#30E0A1'}>
            {t(prettyUppercases(tradeType))}
          </Typography>
        </Box>

        <IconButton>
          <Iconify icon='ph:link-duotone' />
        </IconButton>
      </TableCell> */}
    </TableRow>
  );
};

export default TradeHistoryTableRow;
