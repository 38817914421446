import { useState, useEffect } from 'react';

import Label from './Label';
import Api from '../http/adminHttp/serviceApiAdmin';
import { useSelector } from 'react-redux';
import { useRef } from 'react';

export default function TicketCounter() {
  const [counter, setCounter] = useState(0);
  const lastUpdateTicket = useSelector((state) => state.lastUpdateTicket);

  const reloadTimer = useRef();

  const getData = async () => {
    if (reloadTimer.current) clearTimeout(reloadTimer.current);

    try {
      const res = await Api.getTicketStatistics();
      setCounter(res.openTicketCount);
    } catch (e) {}

    // Getting new data each 3 min
    reloadTimer.current = setTimeout(() => {
      getData();
    }, 1000 * 60 * 3);
  };

  useEffect(() => {
    getData();
  }, [lastUpdateTicket]);

  return <Label color='warning'>{counter}</Label>;
}
