import React from 'react';
import { Form, Button } from 'antd';
import classes from './forgotPasswordForm.module.scss';
import { InputCustom } from '../../../components/InputCustom/inputCustom';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { useNavigate } from 'react-router';
import Api from '../../../http/serviceApi';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { navigationUrl } from '../../../navigationUrl';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  let navigate = useNavigate();

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        forgetPassword(values?.emailAddress);
      })
      .catch((errorInfo) => {});
  };

  const forgetPassword = async (emailAddress) => {
    setLoading(true);
    const response = await Api.forgetPassword(emailAddress);
    if (response?.status === 'SUCCESS') {
      navigate(navigationUrl.forgotpass_checkemail, {
        state: {
          emailAddress: emailAddress,
          pageUrl: response?.message,
        },
      });
    } else {
      enqueueSnackbar(response?.message, { variant: 'error' });
    }
    setLoading(false);
    console.log('forgetPass_emailAddress', emailAddress);
    console.log('forgetPass_response', response);
  };

  return (
    <div className={classes.container}>
      <div className={classes.formTitleDiv}>
        <span className={classes.formTitleBig}>{t('Forgot password')}</span>
        <span className={classes.formTitleSmall}>{t('No worries, we’ll send you reset instructions')}</span>
      </div>

      <Form
        layout='vertical'
        form={form}
        name='forgotPass'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete='off'
      >
        <Form.Item
          className={classes.formItem}
          name='emailAddress'
          rules={[
            {
              required: true,
              message: t('Please input your email!'),
            },
            {
              type: 'email',
              message: t('The input is not a valid email!'),
            },
          ]}
        >
          <InputCustom value={email} label='Email' placeholder='Email' required={true} onChange={onEmailChange} />
        </Form.Item>

        <Form.Item>
          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            loading={loading}
            sx={{ mt: 3, typography: 'body3' }}
          >
            {t('Reset password')}
          </LoadingButton>
        </Form.Item>
      </Form>

      <div className={classes.btnBackDiv}>
        <HiOutlineArrowNarrowLeft className={classes.icon} />
        <Button type='link' shape='round' className={classes.btnBack} onClick={() => navigate(navigationUrl.login)}>
          {t('Back to login')}
        </Button>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
