import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { CARD_TYPE } from '../pages/admin/bankCard/bankCardTable';

export default function useBankCardForm({ userMode = true }) {
  const { t } = useTranslation();

  const validators = Yup.object().shape({
    cardType: Yup.string().required(t('Please input the type of your card!')),
    ...(userMode && {
      firstName: Yup.string().required(t('Please input your first name!')),
      lastName: Yup.string().required(t('Please input your last name!')),
    }),
    cardNumber: Yup.string().required(t('Please input your card number!')),
    // .matches(/^[0-9]{16}$/, t('Please input {{number}}-digits number!', { number: 16 })),
    expiryDateMonth: Yup.string().required(t('Please input month of date!')),
    // .matches(/^[0-9]{2}$/, t('Please input {{number}}-digits number', { number: 2 })),
    expiryDateYear: Yup.string().required(t('Please input year of date!')),
    // .matches(/^[0-9]{2}$/, t('Please input {{number}}-digits number', { number: 2 })),
    cvv2: Yup.string()
      // .matches(/^[0-9]+$/, t('Please input a valid CVV2!'))
      .required(t('Please input a valid CVV2!')),
    accountNumber: Yup.string().when('cardType', {
      is: 'SHETAB_CARD',
      then: Yup.string().required(t('Please input your account number!')),
    }),
    shebaNum: Yup.string().when('cardType', {
      is: 'SHETAB_CARD',
      then: Yup.string().required(t('Please input your Sheba number!')),
      // .matches(/^[0-9]{24}$/, t('Please input a valid Sheba number!')),
    }),
  });

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues: {
      cardType: CARD_TYPE[1].value || '',
      firstName: '',
      lastName: '',
      cardNumber: '',
      expiryDateMonth: '',
      expiryDateYear: '',
      cvv2: '',
      accountNumber: '',
      shebaNum: '',
    },
  });

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isSubmitting },
  } = methods;

  return { handleSubmit, methods, reset, setValue, isSubmitting, cardType: watch('cardType') };
}
