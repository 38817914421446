import React, { useState } from 'react';
import { Button, Row, Col, Form } from 'antd';
import { useSnackbar } from 'notistack';

import classes from './accountSettingChangePass.module.scss';
import { InputCustom } from '../../../../components/InputCustom/inputCustom';
import { InputPassStrong } from '../../../../components/inputPassStrength/inputPassStrength';
import UserApi from '../../../../http/serviceApi';
import AdminApi from '../../../../http/adminHttp/serviceApiAdmin';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { LoadingButton } from '@mui/lab';
import { Card } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AccountSettingChangePass = ({ isAdmin = true }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passLevel, setPassLevel] = useState(0);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [form] = Form.useForm();

  let fillCount = 0;
  const checkValue = (val) => {
    fillCount = 0;
    if (!val) {
      setPassLevel(0);
      return;
    }
    let str = val?.toString();
    const matched1 = str.match(/.*[0-9].*/);
    if (matched1?.length > 0) fillCount++;
    const matched2 = str.match(/.*[a-z].*/);
    if (matched2?.length > 0) fillCount++;
    const matched3 = str.match(/.*[A-Z].*/);
    if (matched3?.length > 0) fillCount++;
    const matched4 = str.match(/^.{8,}$/);
    if (matched4?.length > 0) fillCount++;
    const matched5 = str.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
    if (matched5?.length > 0) fillCount++;

    setPassLevel(fillCount);
  };

  const onOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };
  const onNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    checkValue(e.target.value);
  };
  const onConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        };
        changePassword(data);
      })
      .catch((errorInfo) => {});
  };

  const changePassword = async (data) => {
    let response;
    if (isAdmin) {
      response = await AdminApi.changePassword(data);
    } else {
      response = await UserApi.changePassword(data);
    }

    if (response?.status === 'SUCCESS') {
      enqueueSnackbar(t('password changed successfully.'), { variant: 'success' });
      form.resetFields();
    }
    console.log(response);
  };

  const colPropsForm = {
    xs: 24,
    sm: 24,
    md: 13,
    lg: 8,
    xl: 8,
  };

  return (
    <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 } }}>
      <Row>
        <Col {...colPropsForm}>
          <div>
            <Form
              layout='vertical'
              form={form}
              name='login'
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete='off'
              style={{ width: 320 }}
            >
              <Form.Item
                className={classes.formItem}
                name='oldPassword'
                rules={[
                  {
                    required: true,
                    message: t('Please input your old password!'),
                  },
                ]}
              >
                <InputCustom
                  type='password'
                  label={t('Old Password')}
                  value={oldPassword}
                  placeholder={t('Old Password')}
                  required={true}
                  onChange={onOldPasswordChange}
                />
              </Form.Item>
              <Form.Item
                className={classes.formItem}
                name='newPassword'
                rules={[
                  {
                    required: true,
                    message: t('Please input your newPassword!'),
                  },
                  {
                    validator: async () => {
                      return passLevel === 5 ? Promise.resolve() : Promise.reject('error');
                    },
                    message:
                      t('Passwords must have at least 8 characters and contain at least one uppercase letters, lowercase letters, number and symbol.'),
                  },
                ]}
                tooltip={t('What do you want others to call you?')}
              >
                <InputPassStrong
                  type='password'
                  value={newPassword}
                  label={t('New Password')}
                  required={true}
                  levelFill={passLevel}
                  onChange={onNewPasswordChange}
                />
              </Form.Item>
              <Form.Item
                className={classes.formItem}
                name='confirmPassword'
                rules={[
                  {
                    required: true,
                    message: t('Please input your confirm password!'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error(t('The two passwords that you entered do not match!')));
                    },
                  }),
                ]}
              >
                <InputCustom
                  type='password'
                  label={t('Confirm Password')}
                  value={confirmPassword}
                  placeholder={t('Confirm Password')}
                  required={true}
                  onChange={onConfirmPasswordChange}
                />
              </Form.Item>

              <Form.Item style={{ marginTop: '20px' }}>
                <LoadingButton size='large' type='submit' variant='contained' sx={{ width: 200, typography: 'body3' }}>
                  {t('Save Changes')}
                </LoadingButton>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default AccountSettingChangePass;
