import { Box, Button, Card, Stack, TablePagination, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
// import moment from 'jalali-moment';

import { SET_PAGE_STATE } from '../../../store/actionTypes';
import Image from '../../../components/Image';
import whiteTick from '../../../assets/white-tick.svg';
import Api from '../../../http/serviceApi';
import useTable from '../../../hooks/useTable';
import LoadingScreen from '../../../components/LoadingScreen';
import Label from '../../../components/Label';
import ModalContainer from '../../../components/ModalContainer';
import Iconify from '../../../components/Iconify';

const COLORS = {
  BLACK: '#000',
  GREEN: 'success.main',
  RED: 'error.main',
  YELLOW: 'warning.main',
};

export default function Notification() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // ------------------
  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Your Notifications' },
    });
  }, []);

  // ------------------
  const [openMore, setOpenMore] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });

  // ------------------
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
    defaultRowsPerPage: 10,
  });

  // ------------------
  const getData = async () => {
    try {
      const response = await Api.getAllNotifications(page, rowsPerPage);
      setTableData(response.notificationInfos);
      setTablePagination({ totalItems: response.totalItems });
    } catch (e) {
      setTableData([]);
    }
  };

  // ------------------
  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);

  // ------------------
  const onSeen = async (id) => {
    try {
      const response = await Api.seenNotification(id);
      if (response.status === 'SUCCESS') {
        // enqueueSnackbar(t('Seen'), { variant: 'success' });
        getData();
      }
    } catch (e) {}
  };

  // ------------------
  if (tableData === null) {
    return <LoadingScreen />;
  }

  // ------------------
  return (
    <Card>
      {tableData.map((item) => (
        <Box
          key={item.id}
          sx={{ borderBottom: '1px solid', borderColor: 'grey.600', p: 2, ...(item.seen && { opacity: 0.7 }) }}
        >
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={3}>
              {
                item?.title?.toLowerCase() === 'transaction completed' ?
                  <Stack
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ backgroundColor: COLORS[item.color], width: 52, height: 52, borderRadius: '100%' }}
                  >
                    <Iconify icon='lets-icons:done-duotone' sx={{ width: 28, height: 28 }} />

                  </Stack>

                  :
                  <Stack
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ backgroundColor: COLORS[item.color], width: 52, height: 52, borderRadius: '100%' }}
                  >
                    <Image src={whiteTick} sx={{ width: 24, height: 24 }} />
                  </Stack>

              }

              <Box>
                <Stack direction='row' spacing={2} alignItems={'center'} sx={{ mb: 1 }}>
                  <Stack direction={'row'} spacing={1.5} alignItems={'center'} justifyContent={'center'}>
                    <Typography variant={item.seen ? 'body2' : 'subtitle2'} fontFamily={'Poppins'}>
                      {item.title}
                    </Typography>
                  </Stack>
                  {/* <Typography variant='body3' color={'grey.100'}>
                {moment(item.date).fromNow()}
              </Typography> */}
                </Stack>

                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                  <Typography variant='body3' color={'grey.100'} noWrap sx={{ maxWidth: 150 }}>
                    {item.message}
                  </Typography>
                  <Typography
                    variant='body3'
                    color={'primary.main'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setOpenMore(item);
                      if (!item.seen) onSeen(item.id);
                    }}
                  >
                    {t('More')}
                  </Typography>
                </Stack>
              </Box>
            </Stack>

            {item.seen ? <Label color='success'>{t('Seen')}</Label> : <Label color='warning'>{t('Unseen')}</Label>}
          </Stack>
        </Box>
      ))}

      {tableData?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 200]}
          component='div'
          count={tablePagination.totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage={t('Rows per page')}
          labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
        />
      )}

      <ModalContainer open={!!openMore} onClose={() => setOpenMore(null)} title={openMore?.title} width={600}>
        <Typography sx={{ mt: 2 }}>{openMore?.message}</Typography>
      </ModalContainer>
    </Card>
  );
}
