const arm = {
  translation: {
    Cryptizi: 'Cryptizi',
    'Hi, Welcome Back': 'Բարև, Բարի գալուստ:',
    'Nice to meet you!': 'Ուրախ եմ ծանոթանալու!',
    'Best Wallet For Your Coins': 'Լավագույն դրամապանակը ձեր մետաղադրամների համար',
    Dashboard: 'Վահանակ',
    Wallet: 'Դրամապանակ',
    '{{number}} Currencies': '{{number}} արժույթ',
    'Sign up': 'Գրանցվել',
    'Log in': 'Մուտք գործել',
    Search: 'Որոնել',
    No: 'Ոչ',
    Yes: 'Այո',
    Balance: 'Մնացորդ',
    Income: 'Եկամուտ',
    Expenses: 'Ծախսեր',
    Exchange: 'Փոխանակում',
    'Top Assets': 'Լավագույն ակտիվներ',
    Transaction: 'Գործարք',
    All: 'Ամենը',
    Receive: 'Ստանալ',
    Send: 'Ուղարկել',
    'Overall Growth': 'Ընդհանուր աճ',
    'Open trade': 'Բաց առևտուր',
    Market: 'Շուկա',
    Trade: 'Առևտուր',
    P2P: 'Հավասար-հավասար',
    'E-Money': 'Էլեկտրոնային գումար',
    Convert: 'Փոխարկել',
    Crypto: 'Կրիպտո',
    Futures: 'Ապագա գործարքներ',
    Possibilities: 'Հնարավորություններ',
    'Referrals & Income': 'Հղումներ և եկամուտ',
    Ticket: 'Տոմս',
    History: 'Պատմություն',
    Authentication: 'Անվտանգություն',
    'Bank Card': 'Բանկային քարտ',
    'Account settings': 'Հաշվի կարգավորումներ',
    Academy: 'Ակադեմիա',
    Security: 'Անվտանգություն',
    Identification: 'Նույնականացում',
    Referral: 'Հղում',
    Verified: 'Հաստատված',
    'Regular user': 'Սովորական օգտատեր',
    'Log out': 'Դուրս գալ',
    'Add New favorite': 'Ավելացնել նոր նախընտրելի',
    'Total Balance': 'Ընդհանուր մնացորդ',
    '30 Days PNL': '30 օրվա PNL',
    "Today's PNL": 'Այսօրվա PNL',
    Withdraw: 'Հանել',
    Deposit: 'Լրացնել',
    'Your inventory': 'Ձեր պահուստը',
    Rial: 'Ռիալ',
    Coin: 'Մետաղադրամ',
    Total: 'Ընդհանուր',
    Available: 'Հասանելի',
    'In Order': 'Հերթով',
    'BTC Value': 'BTC արժեք',
    Action: 'Գործողություն',
    Buy: 'Գնել',
    'Rows per page': 'Տողերի քանակը մեկ էջում',
    of: 'ից',

    'All Situations': 'Բոլոր իրավիճակները',
    'History of your orders or transactions': 'Ձեր պատվերների կամ գործարքների պատմությունը',
    'You can enter the name and symbol of Coin, transaction ID, etc. in the search field':
      'Դուք կարող եք մուտքագրել մետաղադրամի անունը և նշանը, գործարքի ID-ն և այլն որոնման դաշտում:',
    Spend: '',
    'Transaction requirements': 'Գործարքի պահանջներ',
    'Convert ': 'Փոխարկել',

    // '1 min': '1 րոպե',
    // '3 min': '3 րոպե',
    // '30 min': '30 րոպե',
    // '1 hour': '1 ժամ',
    // '24 hours': '24 ժամ',
    // '1 day': '1 օր',
    // '1 week': '1 շաբաթ',
    // '1 month': '1 ամիս',
    // '3 month': '3 ամիս',

    'last 1hr': 'վերջին 1 ժամը',
    'last 24hr': 'վերջին 24 ժամը',
    'last week': 'վերջին շաբաթը',
    'last month': 'վերջին ամիսը',

    'Crypto convert': 'Կրիպտո փոխարկել',
    'Futures Classic': 'Դասական ապագա գործարքներ',
    'Token Listing': 'Թոկենների ցուցակ',
        'Total Sell': 'Ընդհանուր վաճառք',
    APIs: 'APIs',
    'iOS App': 'iOS հավելված',
    'Android App': 'Android հավելված',
    'Bank deposit': 'Բանկային ավանդ',
    'Credit/Debit card': 'Վարկային/Դեբետային քարտ',
    'P2P trading': 'Հավասար-հավասար առևտուր',
    'Crytizi Convert': 'Cryptizi փոխարկում',
    Spot: 'Սպոտ',
    Margin: 'Մարժին',
    Markets: 'Շուկաներ',
    'Strategy Trading': 'Ռազմավարական առևտուր',
    'About Us': 'Մեր մասին',
    Service: 'Սպասարկում',
    More: 'Ավելի շատ',
    Learn: 'Սովորել',
    Community: 'Համայնք',
    About: 'Մեր մասին',
    'Contact us': 'Կապվեք մեզ հետ',
    Terms: 'Պայմաններ',
    FAQ: 'ՀՏՀ',
    'Token listing': 'Թոկենների ցուցակ',
    'Cryptizi Convert': 'Cryptizi փոխարկում',
    'Buy/Sell Crypto': 'Գնել/Վաճառել կրիպտո',
    'USDⓈ-M Futures': 'USDⓈ-M ապագա գործարքներ',
    'Coin-M Futures': 'Coin-M ապագա գործարքներ',
    'Submit A request': 'Ներկայացնել հայտ',
    Fees: 'Վճարներ',
    'Trading Rules': 'Առևտրի կանոններ',
    'Browse Crypto Prices': 'Դիտել կրիպտո գներ',
    'Cryptizi Academy': 'Cryptizi ակադեմիա',
    'Sign Up': 'Գրանցվել',

    'We are the most trusted cryptocurrency platform.': 'Մենք ամենավստահելի կրիպտո պլատֆորմն ենք.',

    'View more prices': 'Դիտել ավելի շատ գներ',

    'Build your crypto portfolio anywhere.': 'Ստեղծեք ձեր կրիպտո պորտֆոլիոն ամենուր.',

    'A powerful cryptocurrency exchange in your pocket. Buy, sell and trade crypto on the go.':
      'Ուժեղ կրիպտո փոխանակում ձեր գրպանում: Գնեք, վաճառեք և առևտուր կատարեք ճանապարհին.',

    'Get the Cryptizi Wallet Mobile App Now!': 'Ստացեք Cryptizi Wallet բջջային հավելվածն այժմ!',

    'The most trusted cryptocurrency platform.': 'Ամենավստահելի կրիպտո պլատֆորմը.',

    'Cryptizi has a variety of features that make it the best place to start trading.':
      'Cryptizi-ն ունի բազմազան հատկանիշներ, որոնք այն դարձնում են լավագույն վայր առևտուր սկսելու համար.',

    'Buy and sell popular digital currencies, keep track of them in the one place.':
      'Գնեք և վաճառեք հայտնի թվային արժույթներ, հետևեք նրանց մեկ տեղում.',

    'For added security, store your funds in a vault with time delayed withdrawals.':
      'Լրացուցիչ անվտանգության համար, պահեք ձեր միջոցները ժամանակային հապաղմամբ դուրսբերում ունեցող պահարանում.',

    'Stay on top of the markets with the Cryptizi app for Android or iOS.':
      'Cryptizi հավելվածով Android կամ iOS-ի համար մնացեք շուկաների վերին մասում.',

    'Portofolio Manager': 'Պորտֆոլիո կառավարիչ',
    'Mobile Apps': 'Բջջային հավելվածներ',

    'One click, instant payout with credit or debit card.': 'Մեկ սեղմում, ակնթարթային վճարում վարկային կամ դեբետային քարտով.',

    'Become a crypto owner in minutes using your debit or credit card and quickly purchase top cryptocurrencies.':
      'Կդառնաք կրիպտո սեփականատեր մի քանի րոպեում, օգտագործելով ձեր դեբետային կամ վարկային քարտը և արագ գնել լավագույն կրիպտոարժույթներ.',

    'Intermediate Verification': 'Միջին հաստատում',
    'Intermediate Verified': 'Միջին հաստատված',
    'Additional Information': 'Լրացուցիչ տեղեկություն',
    OK: 'Լավ',
    'Upgrade to Higher Limits': 'Արժեքների բարձրացմանը հասնել',

    'New users can earn up to {{amount}} in crypto rewards.':
      'Նոր օգտատերերը կարող են ստանալ մինչև {{amount}} կրիպտո մրցանակներ.',

    'Ready to start trading cryptocurrency?': 'Պատրա՞ստ եք սկսել կրիպտո առևտուր:',

    'Enter you email address': 'Մուտքագրեք ձեր էլ․ հասցեն.',
    'Get Started': 'Սկսել',

    'Your guide to the world of an open financial system. Get started with the easiest and most secure platform to buy and trade cryptocurrency.':
      'Ձեր ուղեցույցը բաց ֆինանսական համակարգի աշխարհում: Սկսեք ամենահեշտ և ամենապաշտպանված պլատֆորմով կրիպտո գնելու և առևտուր կատարելու համար.',

    'A trusted and secure crypto currency exchange.': 'Վստահելի և պաշտպանված կրիպտո փոխանակում.',

    'Cryptizi ©. All rights reserved.': 'Cryptizi ©. Բոլոր իրավունքները պաշտպանված են',
    'Terms Of Use': 'Օգտագործման պայմաններ',
    'Privacy Policy': 'Գաղտնիության քաղաքականություն',
    Assets: 'Ակտիվներ',
    'Last price': 'Վերջին գինը',
    Change: 'Փոփոխություն',
    Chart: 'Գծապատկեր',

    'Create an Account': 'Ստեղծել հաշիվ',
    'You are not a normal user!': 'Դուք սովորական օգտատեր չեք!',
    'Forgot Password': 'Մոռացել եք գաղտնաբառը',
    Login: 'Մուտք գործել',
    'Please input your username!': 'Խնդրում ենք մուտքագրել ձեր օգտագործողի անունը!',
    'Please input your password!': 'Խնդրում ենք մուտքագրել ձեր գաղտնաբառը!',
    Username: 'Օգտագործողի անուն',
        Password: 'Գաղտնաբառ',
    'Remember me': 'Հիշիր ինձ',
    "You don't have permission to login!": 'Դուք չունեք մուտք գործելու թույլտվություն!',
    'Please input your first name!': 'Խնդրում ենք մուտքագրել ձեր անունը!',
    'Please input your last name!': 'Խնդրում ենք մուտքագրել ձեր ազգանունը!',
    'Please input your email!': 'Խնդրում ենք մուտքագրել ձեր էլ. փոստը!',
    'Please input your password': 'Խնդրում ենք մուտքագրել ձեր գաղտնաբառը',
    'Please input your confirm password!': 'Խնդրում ենք հաստատեք ձեր գաղտնաբառը!',
    'The confirm password does not match the password': 'Գաղտնաբառերը չեն համընկնում',
    'You must accept the terms and conditions!': 'Դուք պետք է ընդունեք պայմանները և կանոնները!',
    "I have read and agree to Cryptizi's Terms of Service and Privacy Policy.":
      'Ես կարդացել եմ և համաձայն եմ Cryptizi-ի ծառայությունների պայմանների և գաղտնիության քաղաքականության հետ:',
    'Confirm Password': 'Հաստատեք գաղտնաբառը',
    Register: 'Գրանցվել',
    'First Name': 'Անուն',
    'Last Name': 'Ազգանուն',
    Email: 'Էլ. փոստ',
    'Enter the email verification code!': 'Մուտքագրեք էլ. փոստի հաստատման կոդը!',
    'Enter the phone number verification code!': 'Մուտքագրեք հեռախոսի համարի հաստատման կոդը!',
    'Enter the authenticator code!': 'Մուտքագրեք հաստատման կոդը!',
    'Enter the {{number}}-digit code send to {{email}}': 'Մուտքագրեք {{number}} թվանշան կոդը ուղարկված {{email}}-ին',
    'Email verification code': 'Էլ. փոստի հաստատման կոդ',
    'Phone number verification code': 'Հեռախոսի համարի հաստատման կոդ',
    'Enter the {{number}}-digit code send to {{phone}}': 'Մուտքագրեք {{number}} թվանշան կոդը ուղարկված {{phone}}-ին',
    'Authenticator code': 'Հաստատման կոդ',

    'Enter the {{number}}-digit code from Google authenticator':
      'Մուտքագրեք {{number}} թվանշան կոդը Google հաստատման հավելվածից',

    'Passwords must have at least 8 characters and contain at least one uppercase letters, lowercase letters, number and symbol.':
      'Գաղտնաբառը պետք է պարունակի առնվազն 8 նիշ և առնվազն մեկ մեծատառ, փոքրատառ, թիվ և խորհրդանիշ:',

    'You do not have a registered bank card': 'Դուք գրանցված բանկային քարտ չունեք!',
    'Card added successfully.': 'Քարտը հաջողությամբ ավելացվեց:',
    'Add a bank card': 'Ավելացնել բանկային քարտ',
    'Type of Card': 'Քարտի տեսակ',
    'Register the card': 'Գրանցել քարտը',
    'Please input the type of your card': 'Խնդրում ենք մուտքագրել ձեր քարտի տեսակը:',
    'Done Successfully': 'Հաջողությամբ ավարտված է:',
    'Account Number': 'Հաշվի համարը',
    CVV2: 'CVV2',
    'Month of date': 'Ամիս',
    'Year of date': 'Տարի',
    'Card Number': 'Քարտի համարը',
    'Please input the type of your card!': 'Խնդրում ենք մուտքագրել ձեր քարտի տեսակը!',
    'Please input your card number!': 'Խնդրում ենք մուտքագրել ձեր քարտի համարը!',
    'Please input month of date!': 'Խնդրում ենք մուտքագրել ամսաթիվը!',
    'Please input 2-digits number': 'Խնդրում ենք մուտքագրել երկնիշ թիվը:',
    'Please input year of date!': 'Խնդրում ենք մուտքագրել տարեթիվը!',
    'Please input CVV2!': 'Խնդրում ենք մուտքագրել CVV2-ը!',
    'Please input your account number!': 'Խնդրում ենք մուտքագրել ձեր հաշվի համարը!',
    'Please input your Sheba number!': 'Խնդրում ենք մուտքագրել ձեր Շեբա համարը!',
    'Please input a valid Sheba number!': 'Խնդրում ենք մուտքագրել ճիշտ Շեբա համարը!',
    'Card Holder name': 'Քարտի տիրոջ անունը',
    'Expiry date': 'Վավերականության ժամկետը',

    'To insert a bank card, fill out the form below and note that the bank card must be in your name.':
      'Բանկային քարտ մուտքագրելու համար լրացրեք ստորև ներկայացված ձևը և նկատի ունեցեք, որ բանկային քարտը պետք է լինի ձեր անունով:',

    'A trusted and secure cryptocurrency exchange.': 'Վստահելի և պաշտպանված կրիպտո փոխանակում:',
    'Vault Protection': 'Պահարանային պաշտպանություն',
    Successful: 'Հաջողությամբ',
    'Learn & Earn': 'Սովորել և վաստակել',
    'GET IT ON': 'Սկսել',
    'Google Play': 'Google Play',
    'Download on the': 'Ներբեռնել',
    'App Store': 'App Store',
    'Additional Infomation': 'Լրացուցիչ տեղեկություններ',
    Countries: 'Երկրներ',
    Traders: 'Առևտրականներ',
    'Trading Pairs': 'Առևտրային զույգեր',

    'Are you sure to remove this symbol from your favorites list?':
      'Համոզվա՞ծ եք, որ ցանկանում եք հեռացնել այս նշանը ձեր նախընտրելիների ցուցակից:',

    Tips: 'Խորհուրդներ',
    'Deposit via receipt': 'Ավանդ ընդունել կտրոնով',
    'Deposit to wallet': 'Ավանդ գանձել դրամապանակում',
    'Deposit payment gateway': 'Ավանդի վճարային դարպաս',
    Back: 'Վերադառնալ',
    'Go to payment': 'Գնալ վճարման',
    Save: 'Պահպանել',

    'When determining the deposit amount, make sure that the minimum purchase and sale amount in the exchange is {{amount}}.':
      'Հաշվարկելով ավանդի գումարը, համոզվեք, որ փոխանակման մեջ նվազագույն գնման և վաճառքի գումարը {{amount}} է:',

    'In order to deposit money, you must use bank cards in your own name, which are registered and approved in the user section.':
      'Գումար ավանդելու համար պետք է օգտագործեք ձեր անունով բանկային քարտեր, որոնք գրանցված և հաստատված են օգտատիրոջ բաժնում:',
 

      'Payment is only possible through approved accounts, otherwise your payment will be unsuccessful.':
      'Վճարումը հնարավոր է միայն հաստատված հաշիվների միջոցով, հակառակ դեպքում ձեր վճարումը չի հաջողվի.',

    'Only for level {{level}} it is possible to deposit with a receipt.':
      'Միայն {{level}} մակարդակի համար հնարավոր է ավանդադրել կտրոնով.',

    'We believe Cryptizi is here to stay - and that a future worth building is one which opens its doors and invites everyone in.':
      'Մենք հավատում ենք, որ Cryptizi-ն այստեղ է մնալու համար և ապագան արժե կառուցել, որը բացում է իր դռները և հրավիրում է բոլորին:',

    'Please input the repeat password!': 'Խնդրում ենք կրկնեք գաղտնաբառը!',
    'The repeat password does not match the password': 'Կրկնված գաղտնաբառը չի համընկնում գաղտնաբառի հետ',
    'Repeat Password': 'Կրկնեք գաղտնաբառը',
    Successfully: 'Հաջողությամբ',
    'Your email has been successfully verified': 'Ձեր էլ․ փոստը հաջողությամբ հաստատվել է:',
    'Verify Email': 'Հաստատել էլ․ փոստը',
    'Request Again': 'Կրկին պահանջել',
    'Didn’t recieve code?': 'Չե՞ք ստացել կոդը:',
    'Forgot password': 'Մոռացել եք գաղտնաբառը',
    'The input is not a valid email!': 'Մուտքագրված էլ․ փոստը անվավեր է!',
    'Reset password': 'Վերականգնել գաղտնաբառը',
    'Back to login': 'Վերադառնալ մուտք գործելուն',
    'No worries, we’ll send you reset instructions':
      'Մի անհանգստացեք, մենք կուղարկենք ձեզ վերականգնման հրահանգներ',
    Submit: 'Ներկայացնել',
    Others: 'Ուրիշներ',
    'Fiat Deposit': 'Ֆիատ ավանդ',
    USD: 'USD',

    'You can enter the amount, transaction ID, etc. in the search field.':
      'Դուք կարող եք մուտքագրել գումարը, գործարքի ID-ն և այլն որոնման դաշտում:',

    'List of Toman deposits and withdrawals': 'Տոմանի ավանդների և դուրսբերումների ցուցակ',
    'Please input the value!': 'Խնդրում ենք մուտքագրել արժեքը!',
    'Please select a bank card!': 'Խնդրում ենք ընտրել բանկային քարտ!',
    'You will see a list of your approved bank accounts': 'Դուք կտեսնեք ձեր հաստատված բանկային հաշիվների ցանկը',
    Num: 'Համար',
    Date: 'Ամսաթիվ',
    'Creation Date': 'Ստեղծման ամսաթիվ',
    Type: 'Տեսակ',
    Value: 'Արժեք',
    Inventory: 'Ֆահաուցացում',
    Condition: 'Պայման',
    Description: 'Նկարագրություն',
    details: 'Մանրամասներ',
    'Please input the tracking code!': 'Խնդրում ենք մուտքագրել հետևման կոդը!',
    'Optional and to speed up transaction confirmation time': 'Ընտրովի և գործարքի հաստատման ժամանակի արագացման համար',
    'select to deposit': 'Ընտրեք ավանդը',
    Payer: 'Վճարող',
    'Tracking Code': 'Հետևման կոդ',
    'Date and time of transaction': 'Գործարքի ամսաթիվն ու ժամը',
    'How to deposit': 'Ինչպես ավանդադրել',
    'Receipt image (optional)': 'Կտրոնի պատկեր (ընտրովի)',
    Sell: 'Վաճառք',
    USDT: 'USDT',
    'Stay tuned for more coin types!': 'Հետևեք նոր մետաղադրամների տեսակների համար!',
    'Enter Amount': 'Մուտքագրեք գումարը',
    'All Payment Methods': 'Բոլոր վճարման մեթոդները',
    Refresh: 'Թարմացնել',
    Advertiser: 'Գովազդատու',
    Price: 'Գին',
    'Available/Limits': 'Հասանելի/Սահմանափակումներ',
    'Payment Method': 'Վճարման մեթոդ',
    'Order(s)': 'Պատվեր(ներ)',
    Limits: 'Սահմանափակումներ',
    'Please select the currency!': 'Խնդրում ենք ընտրել արժույթը!',
    'Please input your wallet ID!': 'Խնդրում ենք մուտքագրել ձեր դրամապանակի ID-ն!',

    'Complete the following information to buy digital currency': 'Լրացրեք հետևյալ տեղեկությունները թվային արժույթ գնելու համար',

    'Received money': 'Ստացված գումար',
    'Please input the value currency!': 'Խնդրում ենք մուտքագրել արժույթի արժեքը!',
    'Voucher Perfect Money': 'Վաուչեր Perfect Money',
    'Price per unit of Perfect Money': 'Perfect Money-ի մեկ միավորի գին',
    'You can also enter the amount in tomans': 'Դուք կարող եք նաև մուտքագրել գումարը տոմաններով',
    'Enter the relevant account ID': 'Մուտքագրեք համապատասխան հաշվի ID-ն',
    'With description': 'Նկարագրությամբ',
    'With security code': 'Անվտանգության կոդով',
    'Payment method': 'Վճարման մեթոդ',
    'Visa/Mastercards': 'Visa/Mastercard',
    'Shetab Card': 'Shetab քարտ',
    'Fast Pay': 'Արագ վճարում',

    'By charging the wallet, make your payment without going to the bank portal':
      'Դրամապանակը լիցքավորելով՝ կատարեք ձեր վճարումը առանց բանկի դարպասի գնալու',

    Pay: 'Վճարել',
    'Select currency': 'Ընտրեք արժույթը',
    'Value currency': 'Արժույթի արժեք',
    'Wallet ID': 'Դրամապանակի ID',
    'One percent equals 0 Dollar': 'Մեկ տոկոսը հավասար է 0 դոլարի',
    'The sales fee is deducted from the amount you receive': 'Վաճառքի վճարը կրճատվում է ձեր ստացած գումարից',
    'Sales fee': 'Վաճառքի վճար',
    'Voucher Code': 'Վաուչերի կոդ',
    'Activation code': 'Ակտիվացման կոդ',
    'The purchase fee will be added to your purchase amount': 'Գնման վճարը կավելանա ձեր գնման գումարին',
    'Purchase fee': 'Գնման վճար',
    'cryptizi convert': 'Cryptizi փոխարկում',
    'Guaranteed price': 'Երաշխավորված գին',
    'Any pairs': 'Ցանկացած զույգեր',
    'Zero fee': 'Զրո վճար',
        Orders: 'Պատվերներ',
    From: 'Ից',
    'Spot wallet available': 'Հասանելի Spot դրամապանակ',
    'Please enter {{range}}': 'Խնդրում ենք մուտքագրել {{range}}',
    To: 'Դեպի',
    'Please enter 20-42000000': 'Խնդրում ենք մուտքագրել 20-42000000',
    'Enter an amount': 'Մուտքագրեք գումարը',
    'Select Crypto': 'Ընտրեք կրիպտո',
    'Deposit Crypto': 'Կրիպտոյի ավանդադրում',
    'Withdraw Crypto': 'Կրիպտոյի դուրսբերում',
    OTC: 'OTC առևտուր',
    'Please input coin!': 'Խնդրում ենք մուտքագրել մետաղադրամը!',
    'Please input TXID!': 'Խնդրում ենք մուտքագրել TXID!',
    'Deposit crypto': 'Կրիպտոյի ավանդադրում',
    'Recent Deposit': 'Վերջին ավանդը',
    Bitcoin: 'Բիթքոին',
    Network: 'Ցանց',
    'Select Deposit network': 'Ընտրեք ավանդի ցանցը',
    Copy: 'Պատճենել',
    'Expected Arrival': 'Սպասվող ժամանումը',
    '{{number}} Network Confirmations': 'Ցանցի հաստատումներ {{number}}',
    'Expected Unlock': 'Սպասվող բացում',
    'Minimum deposit': 'Նվազագույն ավանդ',
    'Selected wallet': 'Ընտրված դրամապանակ',
    'Main wallet': 'Հիմնական դրամապանակ',
    change: 'Փոփոխել',
    'Send Only {{symbol}} to this deposit address': 'Ուղարկեք միայն {{symbol}} այս ավանդի հասցեում',
    'Ensure the network is': 'Համոզվեք, որ ցանցը:',
    'Do not send NFTs to this address.': 'Մի ուղարկեք NFT-ներ այս հասցեին.',
    'learn how to deposit NFTs': 'սովորեք ինչպես ավանդադրել NFT-ներ',
    submit: 'Ներկայացնել',
    'Video Tutorial': 'Տեսադաս',
    'How to Withdraw crypto step-by-step Guide': 'Գիդ կրիպտոյի դուրսբերման քայլ առ քայլ',
    'Why has my withdrawal Not Arrived?': 'Ինչու՞ իմ դուրսբերումը չի հասել:',
    'How to find My transaction ID (TxID)?': 'Ինչպես գտնել իմ գործարքի ID-ն (TxID)?',
    'how to recover My BEP-20 token?': 'Ինչպես վերականգնել իմ BEP-20 թոքենը?',
    'Deposit & withdrawal status query': 'Ավանդի և դուրսբերման վիճակի հարցում',
    'Select coin': 'Ընտրեք մետաղադրամը',
    TXID: 'TXID',

    'Scan the code on the withdrawal page of the trading platform APP or wallet App':
      'Սկանավորեք կոդը առևտրային պլատֆորմի APP-ի կամ դրամապանակի APP-ի դուրսբերման էջում',

    'Send only {{symbol}} to this deposit address': 'Ուղարկեք միայն {{symbol}} այս ավանդի հասցեում',
    'Learn how to deposit NFTs': 'Սովորեք ինչպես ավանդադրել NFT-ներ',

    'Unmatched networks automatically removed. Select a network below':
      'Չհամընկնող ցանցերը ավտոմատ կերպով հեռացվում են: Ընտրեք ցանցը ստորև',

    SOL: 'SOL',
    Solana: 'Սոլանա',
    TRX: 'TRX',
    'Tron (TRC20)': 'Թրոն (TRC20)',
    'Arrival time = {{min}} min': 'Ժամանումը = {{min}} րոպե',
    fee: 'Վճար',
    'BSC ': 'BSC ',
    'BNB Smart chain (BEP20)': 'BNB Սմարթ շղթա (BEP20)',
    'Fee rules': 'Վճարի կանոններ',
    'Select Network': 'Ընտրեք ցանցը',
    'Crypto Address': 'Կրիպտո հասցե',
    Completed: 'Ավարտված է',
    'Withdraw wallet': 'Դրամապանակի դուրսբերում',
    'Spot Wallet': 'Spot դրամապանակ',
    TxID: 'TxID',
    'View All': 'Դիտել բոլորը',
    'Cryptizi user': 'Cryptizi օգտատեր',
    'Recent Withdrawals': 'Վերջին դուրսբերումները',
    'Select Withdrawal network': 'Ընտրեք դուրսբերման ցանցը',

    'SQl Network Withdrawal suspended. withdrawals can be processed through alternative network.':
      'SQl ցանցի դուրսբերումը կասեցվել է: դուրսբերումները կարող են մշակվել այլընտրանքային ցանցով.',

    withdrawal: 'Դուրսբերում',

    Address: 'Հասցե',
    'Enter Address': 'Մուտքագրեք հասցեն',

    'USDT balance': 'USDT մնացորդ',
    'Network Fee': 'Ցանցի վճար',
    'Minimum withdrawal': 'Նվազագույն դուրսբերում',
    '24h remaining limit': '24 ժամ մնացած սահմանափակում',
    'Please enter SMS verification code!': 'Խնդրում ենք մուտքագրել SMS հաստատման կոդը!',
    Amount: 'Գումար',
    'Receive {{receive}} {{symbol}} (Network fee {{fee}} USDT)':
      'Ստացեք {{receive}} {{symbol}} (Ցանցի վճար {{fee}} USDT)',
    Source: 'Աղբյուր',
    'Spot wallet': 'Spot դրամապանակ',
    'Verification code sent': 'Հաստատման կոդը ուղարկված է',
    'security verification unavailable?': 'Անվտանգության հաստատումը հասանելի չէ?',
    'Security verification': 'Անվտանգության հաստատում',
    'Withdrawal Request Submitted': 'Դուրսբերման հայտը ներկայացված է',

    'Please note that you will receive an email once it is completed':
      'Խնդրում ենք նկատի ունենալ, որ ավարտելուց հետո դուք կստանաք էլ․ նամակ',

    'The receiver will get': 'Ստացողը կստանա',
    'Save Address': 'Պահպանել հասցեն',
    'One-Step Withdrawal': 'Մեկ քայլով դուրսբերում',

    'Enable One-Step Withdrawal to withdraw to whitelis addresses easily.':
      'Ակտիվացրեք մեկ քայլով դուրսբերումը՝ հեշտությամբ դուրսբերելու համար ցուցակից նշված հասցեներ։',
 

       'Please input your Recipient!': 'Խնդրում ենք մուտքագրել ձեր ստացողին!',
    'Cryptizi ID': 'Cryptizi ID',
    'Recipient’s email': 'Ստացողի էլ․ փոստը',
    'Orders history': 'Պատվերների պատմություն',
    Recive: 'Ստանալ',
    'Buy Crypto with Debit / Credit Card': 'Գնել կրիպտո դեբետային/վարկային քարտով',
    max: 'Առավելագույնը',
    'Trades History': 'Առևտրի պատմություն',
    'Orders History': 'Պատվերների պատմություն',
    'Convert History': 'Փոխարկման պատմություն',
    'Internal Transactions': 'Ներքին գործարքներ',
    'Futures History': 'Ապագա գործարքների պատմություն',
    'Crypto Transactions': 'Կրիպտո գործարքներ',
    'Fiat Transactions': 'Ֆիատ գործարքներ',
    ALL: 'Բոլոր',
    BUY: 'Գնել',
    SELL: 'Վաճառել',
    MARKET: 'Շուկա',
    LIMIT: 'Սահմանափակում',
    'STOP LIMIT': 'Կանգառի սահմանափակում',
    CANCELED: 'Չեղարկված',
    EXPIRED: 'Ժամկետանց',
    FILLED: 'Լրացված',
    NEW: 'Նոր',
    'PARTIALLY FILLED': 'Մասամբ լրացված',
    'PENDING CANCEL': 'Սպասվող չեղարկում',
    REJECTED: 'Մերժված',
    Pair: 'Զույգ',
    Side: 'Կողմ',
    Filed: 'Հանձնված',
    Commission: 'Հանձնաժողով',
    State: 'Պետություն',
    'Credit Card': 'Վարկային քարտ',
    'Please input a valid CVV2!': 'Խնդրում ենք մուտքագրել վավեր CVV2!',
    'Master Card': 'Մաստեր քարտ',
    'Visa Card': 'Վիզա քարտ',
    'Please input your old email address!': 'Խնդրում ենք մուտքագրել ձեր հին էլ. փոստի հասցեն!',
    'Change Email': 'Փոխել էլ․ փոստը',
    'Please input your new email!': 'Խնդրում ենք մուտքագրել ձեր նոր էլ․ փոստը',
    'New Email': 'Նոր էլ․ փոստ',
    'Email changed successfully': 'Էլ․ փոստը հաջողությամբ փոխվել է',
    Tickets: 'Տոմսեր',
    Order: 'Պատվեր',
    'Account Settings': 'Հաշվի կարգավորումներ',
    'Settings Network': 'Կարգավորումների ցանց',
    Settings: 'Կարգավորումներ',
    Users: 'Օգտատերեր',
    Admins: 'Ադմիններ',
    'Referral marketing': 'Հղման մարքեթինգ',
    'Crypto Wallet Transactions': 'Կրիպտո դրամապանակի գործարքներ',
    'Wallet Transactions': 'Դրամապանակի գործարքներ',
    'Automatic Transactions': 'Ավտոմատ գործարքներ',
    'List Of All Bank Cards': 'Բոլոր բանկային քարտերի ցանկը',
    "User's": 'Օգտատիրոջ',
    Notification: 'Ծանուցում',
    Reporting: 'Զեկուցում',
    'Tether Setting': 'Tether կարգավորումներ',
    'Inventory Settings': 'Պահուստի կարգավորումներ',
    'Daily Rial withdrawal ceiling': 'Օրական ռիալ դուրսբերման սահմանաչափ',
    'Buy Digital Currency': 'Գնել թվային արժույթ',
    'API settings': 'API կարգավորումներ',
    'General settings': 'Ընդհանուր կարգավորումներ',
    'IP Block': 'IP արգելափակում',
    'User level settings': 'Օգտատերերի մակարդակի կարգավորումներ',
    'Deposit With Receipt': 'Ավանդը կտրոնով',
    'Popup Settings': 'Popup կարգավորումներ',
    'Commission settings': 'Հանձնաժողովի կարգավորումներ',
    'Related To Reference Account': 'Հղումային հաշվի հետ կապված',
    'Automatic Deposit settings': 'Ավտոմատ ավանդի կարգավորումներ',
    'Dedicated Gateway settings': 'Նվիրված դարպասի կարգավորումներ',
    'All users': 'Բոլոր օգտատերերը',
    'Add user': 'Ավելացնել նոր օգտատեր',
    'Add Admin': 'Ավելացնել ադմին',
    'Two-step login via Google': 'Երկու քայլով մուտք Google-ի միջոցով',
    Activation: 'Ակտիվացում',
    '6-digit google code': '6-նիշանոց Google կոդ',
    'Two-step login via Sms': 'Երկու քայլով մուտք SMS-ի միջոցով',
    'Send SMS': 'Ուղարկել SMS',
    'SMS Code': 'SMS կոդ',
    'Two-step login via Email': 'Երկու քայլով մուտք էլ. փոստի միջոցով',
    'Send Email': 'Ուղարկել էլ. փոստ',
    'Email Code': 'Էլ․ փոստի կոդ',
    'Tether price inquiry from api and automatic': 'Tether գնի հարցում API-ից և ավտոմատ',
    'Settings Tether': 'Tether կարգավորումներ',
    Caption: 'Վերնագիր',
    'Please select a gateway!': 'Խնդրում ենք ընտրել դարպաս!',
    'Inventory settings': 'Պահուստի կարգավորումներ',
    'API status for inventory': 'API-ի վիճակը պահուստի համար',
    Cancel: 'Չեղարկել',
    'Buy digital currency': 'Գնել թվային արժույթ',
    'Sell digital currency': 'Վաճառել թվային արժույթ',
    'Digital currency': 'Թվային արժույթ',
    'Gateway Setting': 'Դարպասի կարգավորումներ',
    'Voucher Settings': 'Վաուչերի կարգավորումներ',
    'Pay Safe Settings': 'Անվտանգ վճարման կարգավորումներ',
    'Perfect Money Settings': 'Perfect Money կարգավորումներ',
    'Cryptocurrency Setting': 'Կրիպտոարժույթի կարգավորումներ',
    'Settings related to vouchers': 'Կուպոններին վերաբերող կարգավորումներ',
    'Purchase status': 'Գնման կարգավիճակ',
    'Sales status': 'Վաճառքի կարգավիճակ',
    apikey: 'api բանալի',
    seckey: 'Անվտանգության բանալի',
    'Query the price of dollars from the api': 'Հարցում կատարեք դոլարի գնի վերաբերյալ API-ից',
    'Purchase price in dollars': 'Գնման գին դոլարով',
    'Percentage on the price to buy': 'Գնման գնի տոկոս',
    'Purchase price times this number': 'Գնման գինը բազմապատկված այս թվով',
    'Selling price in dollars': 'Վաճառքի գին դոլարով',
    'Percentage in price for sale': 'Վաճառքի գնի տոկոս',
    'Selling price times this number': 'Վաճառքի գինը բազմապատկված այս թվով',

    'Percentage in the price for purchase (above one hundred tomans)': 'Գնման գնի տոկոս (100 տոմանից վերև)',

    'Percentage in the price for sale (above one hundred tomans)': 'Վաճառքի գնի տոկոս (100 տոմանից վերև)',

    'Settings related to Pay Safe Voucher': 'Pay Safe կուպոնի կարգավորումներ',
    UserName: 'Օգտատիրոջ անուն',
    AccountID: 'Հաշվի ID',
    'Purchase status of Perfect Money voucher': 'Perfect Money կուպոնի գնման կարգավիճակ',
    'Settings related to Perfect Money': 'Perfect Money-ի կարգավորումներ',
    'Perfect Money Voucher sales status': 'Perfect Money կուպոնի վաճառքի կարգավիճակ',
    'Perfect Money Voucher': 'Perfect Money կուպոն',
    'Settings related to cryptocurrency reference account': 'Թվային արժույթի հղման հաշվի կարգավորումներ',
    'Binance apikey': 'Binance API բանալի',
    'Binance seckey': 'Binance անվտանգության բանալի',
    'Settings Cryptocurrency': 'Թվային արժույթի կարգավորումներ',

    'Enter the code that was sent to your previous mobile number and email in the box below':
      'Մուտքագրեք կոդը, որը ուղարկվել է ձեր նախորդ հեռախոսահամարին և էլ․ փոստին ստորև ներկայացված դաշտում',

    'New Email verification code': 'Նոր էլ․ փոստի հաստատման կոդ',
    'Add Market': 'Ավելացնել շուկա',
    Name: 'Անուն',
    Symbol: 'Նշան',
    'Name Persian': 'Անուն (պարսկերեն)',
    Selector: 'Ընտրիչ',
    'Network Status': 'Ցանցի կարգավիճակ',
    'Settings network': 'Ցանցի կարգավորումներ',
    "Please input network's name!": 'Խնդրում ենք մուտքագրել ցանցի անունը!',
    "Please input network's selector!": 'Խնդրում ենք մուտքագրել ցանցի ընտրիչը!',
    "Please input network's persian name!": 'Խնդրում ենք մուտքագրել ցանցի պարսկերեն անունը!',
    "Please input network's symbol!": 'Խնդրում ենք մուտքագրել ցանցի նշանը!',
    'Please input Minimum allowed!': 'Խնդրում ենք մուտքագրել նվազագույնը թույլատրվածը!',
    'Minimum allowed purchase': 'Նվազագույն թույլատրված գնում',
    'Please input Daily purchase amount!': 'Խնդրում ենք մուտքագրել օրական գնման գումարը!',
    'IP test mode (to disable, leave the field blank)': 'IP թեստի ռեժիմ (անջատելու համար թողեք դաշտը դատարկ)',

    'Deactivating purchases from the site (inserting a message in the box below means deactivating purchases from the site)':
      'Ապաակտիվացնել գնումները կայքից (ստորև նշված դաշտում հաղորդագրություն մուտքագրելը նշանակում է, որ գնումները կայքից ապաակտիվացված են)',

    'Daily purchase amount during registration': 'Օրական գնման գումար գրանցման ընթացքում',
    'Deposit settings with receipt': 'Ավանդի կարգավորումներ կտրոնով',
    'Bank name': 'Բանկի անունը',
    'Card number': 'Քարտի համարը',
    'Account number': 'Հաշվի համարը',
    'Shaba number': 'Շաբա համարը',
    'Please input number of days!': 'Խնդրում ենք մուտքագրել օրերի քանակը!',
    'Popup settings': 'Popup կարգավորումներ',

    'After each login, it shows the password (that is, if he has not used remember me) and also shows it every few days if he has remembered me!':
      'Յուրաքանչյուր մուտքից հետո, այն ցույց է տալիս գաղտնաբառը (այսինքն, եթե նա չի օգտագործել remember me), ինչպես նաև ցույց է տալիս այն ամեն մի քանի օրում, եթե նա հիշել է ինձ!',

    Percentage: 'Տոկոս',
    'Automatic deposit settings': 'Ավտոմատ ավանդի կարգավորումներ',
    Business: 'Բիզնես',
    'Deposit model': 'Ավանդի մոդել',
    Mobile: 'Բջջային',
    'Rejection of automatic deposit for the following banks': 'Ավտոմատ ավանդի մերժում հետևյալ բանկերի համար',
    Sat: 'Շաբաթ',
    Sun: 'Կիրակի',
    Mon: 'Երկուշաբթի',
    Tue: 'Երեքշաբթի',
    Wed: 'Չորեքշաբթի',
    Thu: 'Հինգշաբթի',
    Fri: 'Ուրբաթ',
    Active: 'Ակտիվ',
    Token: 'Թոքեն',
    Inactive: 'Անգործուն',
    'Iran GATEWAY': 'Իրան Դարպաս',
    'Contract text': 'Պայմանագրի տեքստը',
    'SMS confirmation for withdrawal and purchase': 'SMS հաստատում դուրսբերման և գնման համար',
    'International GATEWAY': 'Միջազգային Դարպաս',
    'Iranian payment gateways Appearance': 'Իրանական վճարային դարպասների տեսք',
    'Minimum wallet withdrawal amount': 'Դրամապանակի նվազագույն դուրսբերման գումար',
    'Minimum amount of currency sales': 'Նվազագույն արժույթի վաճառքի գումար',
    'Dedicated  Gateway settings': 'Նվիրված դարպասի կարգավորումներ',
    disable: 'Ապաակտիվացնել',
    active: 'Ակտիվ',

    'This is the agreement between Iranianex.com and the user who uses the various services of the site. All users of Iranianex.com should read these rules completely and then become a member of the site if they accept them. Iranianex.com can update the terms of this agreement every month according to the changes in the laws. Registering on this site, using its services such as buying and selling, etc., means that you have read and accepted the terms of this contract. The user accepts that he is the legal owner.This is the agreement between Iranianex.com and the user who uses the various services of the site. ':
      'Սա համաձայնագիր է Iranianex.com-ի և օգտատերի միջև, ով օգտվում է կայքի տարբեր ծառայություններից: Iranianex.com-ի բոլոր օգտատերերը պետք է ամբողջությամբ կարդան այս կանոնները և, եթե դրանք ընդունեն, ապա անդամագրվեն կայքում: Iranianex.com-ը կարող է ամսեկան թարմացնել այս համաձայնագրի պայմանները օրենքների փոփոխությունների համաձայն: Կայքում գրանցվելը, դրա ծառայություններից օգտվելը, ինչպես գնման, այնպես էլ վաճառքի և այլն, նշանակում է, որ դուք կարդացել և ընդունել եք այս պայմանագրի պայմանները: Օգտատերը ընդունում է, որ նա օրինական սեփականատերն է:',

    'Add New Admin': 'Ավելացնել նոր ադմին',
    Admin: 'Ադմին',
    Block: 'Արգելափակում',
    Edit: 'Խմբագրել',
    Delete: 'Ջնջել',
    'List of accounts': 'Հաշիվների ցուցակ',
    'Crypto transactions': 'Կրիպտո գործարքներ',
    'Bank cards': 'Բանկային քարտեր',
    Log: 'Տեղեկամատյան',
    'Toman transactions': 'Տոման գործարքներ',
    'Please input name!': 'Խնդրում ենք մուտքագրել անունը!',
    'Please input mobile!': 'Խնդրում ենք մուտքագրել բջջային համարը!',
    'Please input email!': 'Խնդրում ենք մուտքագրել էլ․ փոստը!',
    'Please input password!': 'Խնդրում ենք մուտքագրել գաղտնաբառը!',
    'Add new admin': 'Ավելացնել նոր ադմին',
    Deactive: 'Անգործուն',
    'Delete user': 'Ջնջել օգտատիրոջը',
    Access: 'Մուտք',
    'Two-step login': 'Երկու քայլով մուտք',
    'Upload photo': 'Վերբեռնել լուսանկար',
    'Change photo': 'Փոխել լուսանկարը',
    Notifications: 'Ծանուցումներ',
    'Wallet deposit and withdrawal': 'Դրամապանակի ավանդ և դուրսբերում',
    Filter: 'Ֆիլտր',
       'View transactions': 'Դիտել գործարքները',
    'Edit Admin': 'Խմբագրել ադմինին',
    'Wallet transactions': 'Դրամապանակի գործարքներ',

    'User wallet transactions that have been changed by this admin':
      'Օգտատիրոջ դրամապանակի գործարքները, որոնք փոխվել են այս ադմինի կողմից',

    Bank: 'Բանկ',
    'Amount of': 'Գումարի չափը',
    Currency: 'Արժույթ',
    Status: 'Կարգավիճակ',
    Detail: 'Մանրամասներ',
    'Cryptocurrency wallet transactions': 'Թվային արժույթի դրամապանակի գործարքներ',
    Regarding: 'Համաձայն',
    'Business transactions': 'Բիզնես գործարքներ',
    'Transactions of a transaction': 'Գործարքի գործարքներ',
    'ID transaction': 'Գործարքի ID',

    'You can see the cryptocurrency wallet transactions that the admin has changed':
      'Դուք կարող եք տեսնել թվային արժույթի դրամապանակի գործարքները, որոնք ադմինը փոխել է',

    'The list of users whose document image has been changed by this admin.':
      'Օգտատերերի ցանկը, որոնց փաստաթղթերի պատկերը փոփոխվել է այս ադմինի կողմից.',

    Level: 'Մակարդակ',
    'Add admin': 'Ավելացնել ադմին',
    'New Admin': 'Նոր ադմին',

    'You can not disable a port that is in the default state.':
      'Դուք չեք կարող ապաակտիվացնել նավահանգիստը, որը գտնվում է նախնական վիճակում.',

    'Order type': 'Պատվերի տեսակ',
    Tether: 'Tether',
    User: 'Օգտատեր',
    'Transfer fee': 'Փոխանցման վճար',
    network: 'Ցանց',
    'The amount': 'Գումարը',
    Close: 'Փակել',
    'Address wallet': 'Դրամապանակի հասցե',
    'Displaying saved parameters (Click)': 'Ցուցադրել պահպանված պարամետրերը (Սեղմեք)',
    'Transactions Details': 'Գործարքների մանրամասներ',
    Success: 'Հաջողություն',
    Failed: 'Չհաջողվեց',

    'You can see the total number of records on the current page, and for the total number of all displaysl':
      'Դուք կարող եք տեսնել ընդհանուր գրառումների քանակը ընթացիկ էջում և ընդհանուր ցուցադրվածների քանակը',

    'Account list': 'Հաշիվների ցուցակ',
    'Group payment': 'Խմբային վճարում',
    "Sheba's list": 'Շեբա ցուցակը',
    'You can see the wallet transactions of users.': 'Դուք կարող եք տեսնել օգտատերերի դրամապանակի գործարքները.',
    View: 'Դիտել',
    'The transfer encountered an error': 'Փոխանցումը հանդիպեց սխալի',
    'Transaction number': 'Գործարքի համարը',
    'Transaction amount': 'Գործարքի գումարը',
    IP: 'IP',
    Done: 'Կատարված է',
    New: 'Նոր',
    'Your Notifications': 'Ձեր ծանուցումները',
    'View All Notifications': 'Դիտել բոլոր ծանուցումները',
    'Sort by': 'Դասավորել ըստ',
    'last 2 week': 'Վերջին 2 շաբաթը',
    'No Data': 'Տվյալներ չկան',
    Dollar: 'Դոլար',
    'Payment unit': 'Վճարման միավոր',
    'Done successfully': 'Հաջողությամբ ավարտվեց',
    'Voucher code': 'Վաուչերի կոդ',
    MAX: 'Առավելագույն',
    'Select Coin': 'Ընտրեք մետաղադրամը',
    'Main Wallet': 'Հիմնական դրամապանակ',
    'Funding Wallet': 'Ֆինանսավորման դրամապանակ',
    'Select Wallet': 'Ընտրեք դրամապանակը',
    'Please select a coin firstly!': 'Խնդրում ենք նախ ընտրել մետաղադրամը!',
    'Enter Tag': 'Մուտքագրեք պիտակը',
    'Add New Address': 'Ավելացնել նոր հասցե',
    'Select from Address Book': 'Ընտրել հասցեների գրքից',
    Phone: 'Հեռախոս',
    balance: 'Մնացորդ',
    'Receive {{receive}} {{symbol}}': 'Ստանալ {{receive}} {{symbol}}',
    'Top gainer (24h)': 'Լավագույն աճող (24 ժամ)',
    'Highest volume (24h)': 'Բարձրագույն ծավալ (24 ժամ)',
    'Most Visited': 'Ամենաշատ այցելված',
    'New listing': 'Նոր ցուցակագրում',
    '24h Change': '24 ժամվա փոփոխություն',
    '24h Volume': '24 ժամվա ծավալ',
    'Market Cap': 'Շուկայական արժեք',
    'Crypto Market': 'Կրիպտո շուկա',
    'If you need help, watch the guide video in this section':
      'Եթե օգնության կարիք ունեք, դիտեք ուղեցույց տեսանյութը այս բաժնում',
    'Watch the movie': 'Դիտել ֆիլմը',
    'Your Share': 'Ձեր բաժինը',
    'Share friends': 'Բաժանորդագրել ընկերներին',
       Reports: 'Զեկույցներ',
    'List of trades in your subset': 'Ձեր ենթախմբում կատարված առևտրերի ցանկը',
    'Your income chart': 'Ձեր եկամուտների գծապատկեր',
    'List of introduced users': 'Ներկայացված օգտատերերի ցուցակ',
    'Link and introduction code': 'Հղում և ներկայացման կոդ',
    'By providing a link or referral code to your friends, you can earn money from their transaction fees and also allocate a part of this income to them, which is both for their benefit and for your benefit.':
      'Հղում կամ ներկայացման կոդ տրամադրելով ձեր ընկերներին, կարող եք վաստակել նրանց գործարքների վճարներից և նաև ձեր եկամտի մի մասը հատկացնել նրանց, ինչը թե՛ նրանց համար և թե՛ ձեր համար է շահավետ։',
    'Set percentage for submissions': 'Սահմանել տոկոսը ներկայացումների համար',
    '30% of your friends transaction fee is provided for you and by setting this section you can divide your income among the people you have introduced.':
      'Ձեր ընկերների գործարքների վճարների 30%-ը տրամադրվում է ձեզ, և այս բաժինը կարգավորելով կարող եք ձեր եկամուտը բաժանել այն անձանց միջև, որոնք ներկայացրել եք:',
    Number: 'Համար',
    Details: 'Մանրամասներ',
    'Add Ticket': 'Ավելացնել տոմս',
    Subject: 'Թեմա',
    'Please input section!': 'Խնդրում ենք մուտքագրել բաժինը!',
    'Please input description!': 'Խնդրում ենք մուտքագրել նկարագրությունը!',
    Finance: 'Ֆինանսներ',
    Technical: 'Տեխնիկական',
    KYC: 'KYC',
    'Name Ticket': 'Տոմսի անուն',
    Section: 'Բաժին',
    'History of your trades': 'Ձեր առևտրերի պատմություն',
    Time: 'Ժամանակ',
    'History of your transactions': 'Ձեր գործարքների պատմություն',
    'Past 30 days': 'Վերջին 30 օրերը',
    'Past 90 days': 'Վերջին 90 օրերը',
    Credited: 'Հաշվարկված է',
    Pending: 'Սպասման մեջ',
    'Past 7 days': 'Վերջին 7 օրերը',
    Asset: 'Ակտիվ',
    'Enter TxID': 'Մուտքագրեք TxID',
    'Select Asset': 'Ընտրեք ակտիվը',
    Destination: 'Նպատակակետ',
    Failure: 'Չհաջողված',
    Canceled: 'Չեղարկված',
    'Deposit ID': 'Ավանդի ID',
    'Please input your old Email address!': 'Խնդրում ենք մուտքագրել ձեր հին էլ. փոստի հասցեն!',
    'Fiat Withdraw': 'Ֆիատ դուրսբերում',
    'Withdrawal of wallet': 'Դրամապանակի դուրսբերում',
    'Your recoverable inventory:': 'Ձեր վերականգնվող պահուստը:',
    'Equivalent daily withdrawal amount: 0 out of 500,000,000 Tomans':
      'Օրական դուրսբերման համարժեք գումարը: 0-ից 500,000,000 տոման',
    'When determining the deposit amount, make sure that the minimum purchase and sale amount in the exchange is $ 11.':
      'Ավանդի գումարը որոշելուց, համոզվեք, որ նվազագույն գնման և վաճառքի գումարը փոխանակման մեջ $11 է:',
    'Bank account to receive': 'Բանկային հաշիվ ստանալու համար',
    'Buy & Sell': 'Գնել և վաճառել',
    'All Model': 'Բոլոր մոդելները',
    Management: 'Կառավարում',
    'Account Setting': 'Հաշվի կարգավորումներ',
    Profile: 'Պրոֆիլ',
    'Waiting Actions': 'Սպասվող գործողություններ',
    'Admin Utilities': 'Ադմինի գործիքներ',
    Title: 'Վերնագիր',
    Today: 'Այսօր',
    Yesterday: 'Երեկ',
    'This week': 'Այս շաբաթ',
    'This month': 'Այս ամիս',
    'This Year': 'Այս տարի',
    'Total value': 'Ընդհանուր արժեք',
    Sales: 'Վաճառքներ',
    'System status': 'Համակարգի վիճակ',
    Website: 'Կայք',
    'PaySafe Voucher': 'PaySafe վաուչեր',
    PMvoucher: 'PMvoucher',
    'Name Currency': 'Արժույթի անունը',
    Sale: 'Վաճառք',
    Stock: 'Պահուստ',
    'Amount of orders': 'Պատվերների քանակը',
    'Sales number': 'Վաճառքի համար',
    'Selling price': 'Վաճառքի գին',
    Purchases: 'Գնումներ',
    'Purchase amount': 'Գնման գումարը',
    'New User': 'Նոր օգտատեր',
    'Most sales': 'Ամենաշատ վաճառքներ',
    'Most purchases': 'Ամենաշատ գնումներ',
    'Users waiting for Verify': 'Օգտատերեր, որոնք սպասում են հաստատման',
    'Fiat Transaction Waiting': 'Ֆիատ գործարքի սպասում',
    Reset: 'Վերականգնել',
    'You can see the list of users whose information must be confirmed.':
      'Դուք կարող եք տեսնել այն օգտատերերի ցանկը, որոնց տեղեկատվությունը պետք է հաստատվի:',
    'Edit / Delete': 'Խմբագրել / Ջնջել',
    Basic: 'Հիմնական',
    'Crypto wallet': 'Կրիպտո դրամապանակ',
    'Fiat wallet': 'Ֆիատ դրամապանակ',
    'Crypto Transaction': 'Կրիպտո գործարք',
    Cards: 'Քարտեր',
    'Trade history': 'Առևտրի պատմություն',
    'Earn ': 'Վաստակել',
    'please fill the blank': 'Խնդրում ենք լրացնել դատարկ մասը',
    Other: 'Այլ',
        Intermediate: 'Միջին',
    Advanced: 'Առաջադեմ',
    'Send Form': 'Ուղարկել ձևը',
    'Middle Name': 'Միջին անուն',
    Country: 'Երկիր',
    'Street Address': 'Փողոցի հասցե',
    birthDate: 'Ծննդյան ամսաթիվ',
    'Postal Code': 'Փոստային կոդ',
    City: 'Քաղաք',
    'Phone Number': 'Հեռախոսահամար',
    Approve: 'Հաստատել',
    Reject: 'Մերժել',
    'Send Notification': 'Ուղարկել ծանուցում',
    'Wallet’s User': 'Դրամապանակի օգտատեր',
    'Total inventory': 'Ընդհանուր պահուստ',
    'Equivalent to Dollar': 'Համարժեք դոլարին',
    'Inventory available': 'Հասանելի պահուստ',
    'Create a transaction': 'Ստեղծել գործարք',
    'Add transaction': 'Ավելացնել գործարք',
    Error: 'Սխալ',
    'Edit User': 'Խմբագրել օգտատիրոջը',
    Create: 'Ստեղծել',
    'Update photo': 'Թարմացնել լուսանկարը',
    'Value Transaction': 'Գործարքի արժեք',
    'Account Transaction': 'Հաշվի գործարք',
    'Add Card': 'Ավելացնել քարտ',
    'Add card': 'Ավելացնել քարտ',
    Shaba: 'Շաբա',
    Waiting: 'Սպասման մեջ',
    Message: 'Հաղորդագրություն',
    Payment: 'Վճարում',
    Port: 'Դարպաս',
    'Order value': 'Պատվերի արժեք',
    success: 'Հաջողություն',
    Earn: 'Վաստակել',
    'List of users that this user has introduced': 'Օգտատերերի ցուցակը, որոնց ներկայացրել է այս օգտատերը',
    'Value Order': 'Պատվերի արժեք',
    'User commission': 'Օգտատիրոջ հանձնաժողով',
    'ID card': 'Նույնականացման քարտ',
    Passport: 'Անձնագիր',
    'Upload .PDF/.JPG/.JPEG/.PNG file and no more than 5M': 'Վերբեռնել .PDF/.JPG/.JPEG/.PNG ֆայլ (առավելագույնը 5Մ)',
    'Select your Country / region': 'Ընտրեք ձեր երկիր / տարածաշրջանը',
    'Change level': 'Փոխել մակարդակը',
    'Change password': 'Փոխել գաղտնաբառը',
    'Representative mobile': 'Ներկայացուցչի բջջային',
    'List of all bank cards': 'Բոլոր բանկային քարտերի ցուցակ',
    'To edit, delete or approve or disapprove a bank card, click on the link to be redirected to the bank cards section in the users profile.':
      'Խմբագրելու, ջնջելու կամ հաստատելու կամ չհաստատելու համար բանկային քարտը, սեղմեք հղումը՝ օգտատերերի պրոֆիլի բանկային քարտերի բաժնում փոխանցվելու համար.',
    'Edit card': 'Խմբագրել քարտը',
    'Edit Card': 'Խմբագրել քարտը',
    'Fiat transactions': 'Ֆիատ գործարքներ',
    'Edit admin': 'Խմբագրել ադմինին',
    'API Setting': 'API կարգավորումներ',
    'Network Setting': 'Ցանցի կարգավորումներ',
    Referrals: 'Հղումներ',
    'Dollar balance': 'Դոլարի մնացորդ',
    'Rial balance': 'Ռիալի մնացորդ',
    'Percentage in the price for ( marketing )': 'Գնի տոկոսը (մարքեթինգի համար)',
    'Perfect Money ': 'Perfect Money',
    'Percentage in the price for purchase': 'Գնի տոկոսը գնման համար',
    'Percentage in the price for sale': 'Գնի տոկոսը վաճառքի համար',
    'Network Name': 'Ցանցի անունը',
    'General Settings': 'Ընդհանուր կարգավորումներ',
    'Type ...': 'Տեսակ ...',
    "Account Holder's name": 'Հաշվի տիրոջ անունը',
    Vandar: 'Vandar',
    PIR: 'PIR',
    Zibal: 'Zibal',
    'Add User': 'Ավելացնել օգտատեր',
    BASIC: 'Հիմնական',
    Disapproval: 'Չհաստատում',
    REGISTERED: 'Գրանցված',
    BASIC_BY_ADMIN: 'Հիմնական (ադմինի կողմից)',
    INTERMEDIATE_REQUESTED: 'Միջին (պահանջված)',
    ADVANCED_BY_ADMIN: 'Առաջադեմ (ադմինի կողմից)',
    INTERMEDIATE_BY_ADMIN: 'Միջին (ադմինի կողմից)',
    ADVANCED_REQUESTED: 'Առաջադեմ (պահանջված)',
    Google: 'Google',
    LastName: 'Ազգանուն',
    Sms: 'SMS',
    'Father Name': 'Հոր անունը',
    'National Number': 'Ազգային համար',
    'Birth Date': 'Ծննդյան ամսաթիվ',
    'Login Date': 'Մուտքի ամսաթիվ',
    Verification: 'Հաստատում',
    Tell: 'Ասել',
    'Add New Card': 'Ավելացնել նոր քարտ',
    Support: 'Աջակցություն',
    caption: 'Վերնագիր',
    'List of commission payments to the wallet': 'Հանձնաժողովի վճարումների ցուցակը դեպի դրամապանակ',
    'Referral Marketing': 'Հղման մարքեթինգ',
    waiting: 'Սպասման մեջ',
     'Transfer completed successfully': 'Փոխանցումը հաջողությամբ ավարտվեց',
    'All User': 'Բոլոր օգտատերերը',
    'Perfect Money buyers/sellers': 'Perfect Money գնորդներ/վաճառողներ',
    'CryptoCurrency buyers/sellers': 'Կրիպտոարժույթի գնորդներ/վաճառողներ',
    'Paysafe buyers/sellers': 'Paysafe գնորդներ/վաճառողներ',
    'Vouchers buyers/sellers': 'Վաուչերների գնորդներ/վաճառողներ',
    'Referral Users': 'Հղման օգտատերեր',
    'Verified Users': 'Հաստատված օգտատերեր',
    'Users Who hasnt trade yet': 'Օգտատերեր, որոնք դեռ չեն առևտուր արել',
    'This category does not apply to header alerts and shows for everyone': 'Այս կատեգորիան չի կիրառվում գլխագրի ահազանգերի և ցուցադրումների համար',
    'The title does not apply to sms': 'Վերնագիրը չի կիրառվում SMS-ի համար',
    'Unverified Users': 'Չհաստատված օգտատերեր',
    'Notification type': 'Ծանուցման տեսակ',
    'Users whos Google Authenticator is inactive': 'Օգտատերեր, որոնց Google Authenticator-ը անգործունակ է',
    'Users whos SMS Authenticator is inactive': 'Օգտատերեր, որոնց SMS Authenticator-ը անգործունակ է',
    'Basic level Users': 'Հիմնական մակարդակի օգտատերեր',
    'Intermediate Users': 'Միջին մակարդակի օգտատերեր',
    'Advanced Users': 'Առաջադեմ մակարդակի օգտատերեր',
    'User Statistics': 'Օգտատերերի վիճակագրություն',
    'Open trades': 'Բաց գործարքներ',
    'Futures Open position': 'Ապագա բաց դիրք',
    'Deposit Statistics': 'Ավանդների վիճակագրություն',
    'Crypto Statistics': 'Կրիպտո վիճակագրություն',
    'Successful Orders': 'Հաջող գործարքներ',
    'Withdraw Statistics': 'Դուրսբերման վիճակագրություն',
    'Unsuccessful Orders': 'Չհաջողված գործարք',
    '12 H': '12 ժամ',
    '1 Day': '1 օր',
    '1 Week': '1 շաբաթ',
    '1 Months': '1 ամիս',
    '1 Years': '1 տարի',
    'last day': 'Վերջին օր',
    'Buy/Sell': 'Գնել/Վաճառել',
    Desktop: 'Համակարգիչ',
    Select: 'Ընտրել',
    Report: 'Զեկույց',
    'Deactivate user': 'Ապաակտիվացնել օգտատիրոջը',
    'Active user': 'Ակտիվ օգտատեր',
    Excel: 'Excel',
    PDF: 'PDF',
    Gateway: 'Դարպաս',
    'Post New Ad': 'Տեղադրել նոր հայտարարություն',
    Remainder: 'Մնացորդ',
    'Ads List': 'Հայտարարությունների ցուցակ',
    Post: 'Հայտարարություն',
    'Total Amount': 'Ընդհանուր գումար',
    'With Fiat': 'Ֆիատով',
    'Minimum order limit': 'Նվազագույն պատվերի սահմանաչափ',
    'Maximum order limit': 'Առավելագույն պատվերի սահմանաչափ',
    'Please select the type!': 'Խնդրում ենք ընտրել տեսակը!',
    'Perfect Money': 'Perfect Money',
    'Voucher PaySafe': 'PaySafe վաուչեր',
    'You can also enter the amount': 'Դուք կարող եք նաև մուտքագրել գումարը',
    'Date & Time': 'Ամսաթիվ և Ժամանակ',
    'Serial number': 'Սերիական համար',
    Continue: 'Շարունակել',
    Hot: 'Տաք',
    'Choose Payment method': 'Ընտրել վճարման եղանակը',
    Previous: 'Նախորդ',
    Confirm: 'Հաստատել',
    'History of your orders': 'Ձեր պատվերների պատմություն',
    Fiat: 'Ֆիատ',
    'P2P History': 'P2P պատմություն',
    'History of your P2P orders': 'Ձեր P2P պատվերների պատմություն',
    TAKE: 'Վերցնել',
    MAKE: 'Ստեղծել',
    COMPLETED: 'Ավարտված է',
    FAILED: 'Չհաջողվեց',
    PARTIALLY_COMPLETED: 'Մասամբ ավարտված է',
    PENDING: 'Սպասման մեջ',
    'Please input your oldMobile!': 'Խնդրում ենք մուտքագրել ձեր հին բջջային համարը!',
    'Please input your new mobile!': 'Խնդրում ենք մուտքագրել ձեր նոր բջջային համարը!',
    'Change Mobile': 'Փոխել բջջայինը',
    'New Mobile': 'Նոր բջջային',
    'Please input your oldEmailAddress!': 'Խնդրում ենք մուտքագրել ձեր հին էլ․ փոստի հասցեն!',
    'Paysafe total value': 'PaySafe ընդհանուր արժեք',
    'Perfect Money total value': 'Perfect Money ընդհանուր արժեք',
    'Tether total value': 'Tether ընդհանուր արժեք',
    'Automatic Deposit History': 'Ավտոմատ ավանդի պատմություն',
    'Sheba number': 'Շաբա համար',
    Unblock: 'Ապաբլոկավորել',
    undefined: 'Անորոշ',
    Approved: 'Հաստատված',
    Rejectd: 'Մերժված',
    'You can see the list of commissions received by this user through introduction.':
      'Դուք կարող եք տեսնել այս օգտատիրոջ կողմից ներկայացված հանձնաժողովների ցուցակը:',
    INTERMEDIATE: 'Միջին',
    ADVANCED: 'Առաջադեմ',
    Family: 'Ընտանիք',
    'Please input first name!': 'Խնդրում ենք մուտքագրել անունը!',
    'Please input email address!': 'Խնդրում ենք մուտքագրել էլ․ փոստի հասցեն!',
    'Please input last name!': 'Խնդրում ենք մուտքագրել ազգանունը!',
    "If you don't decide to change the password, leave this field blank":
      'Եթե չեք որոշում փոխել գաղտնաբառը, թողեք այս դաշտը դատարկ',
    'Access Denied': 'Մուտքը մերժված է',
    'Fixed panel page header': 'Ֆիքսված պանելի էջի վերնագիր',
    'By notification': 'Ծանուցմամբ',
    'Header panel pages only once': 'Պանելի էջի վերնագրերը միայն մեկ անգամ',
    'by Email': 'Էլ․ փոստով',
    'Via SMS': 'SMS-ով',
    Desktap: 'Համակարգիչ',
    'Deactive user': 'Ապաակտիվացնել օգտատիրոջը',
    'Buy Crypto': 'Գնել կրիպտո',
    'Contact Us': 'Կապվեք մեզ հետ',
    'Privacy policy': 'Գաղտնիության քաղաքականություն',
    'E-money': 'Էլեկտրոնային գումար',
    Verify: 'Հաստատել',
    Announcement: 'Հայտարարություն',
    API: 'API',
    'OTC Terms': 'OTC պայմաններ',
    'Convert Terms': 'Փոխարկման պայմաններ',
    'Please enter {{min}}-{{max}}': 'Խնդրում ենք մուտքագրել {{min}}-{{max}}',
    Favorites: 'Նախընտրածներ',
    'All Cryptos': 'Բոլոր կրիպտոարժույթները',
    'Spot Markets': 'Ներկայիս շուկաներ',
    'Futures Markets': 'Ապագա շուկաներ',
    'New Markets': 'Նոր շուկաներ',
    Metaverse: 'Մետավերս',
    Gaming: 'Խաղեր',
    Defi: 'Դեֆի',
    Innovation: 'Նորարարություն',
    'Layer1/layer2': 'Շերտ 1/շերտ 2',
    'Fan Token': 'Հավատարիմների թոքեն',
        NFT: 'Թոքեններ, որոնք չեն կարող փոխարինվել',
    Storage: 'Պահպանում',
    Palkadot: 'Պալկադոտ',
    POS: 'POS',
    POW: 'POW',
    '1 Month': '1 ամիս',
    Symbols: 'Նշաններ',
    '1 Year': '1 տարի',
    Open: 'Բացել',
    Remove: 'Հեռացնել',
    FINANCE: 'Ֆինանսներ',
    TECHNICAL: 'Տեխնիկական',
    'Close ticket': 'Փակել տոմսը',
    'Updated Date': 'Թարմացված ամսաթիվ',
    'Type a message': 'Գրել հաղորդագրություն',
    'How satisfying it was ?': 'Որքան բավարարված էիք?',
    'Ticket closed': 'Տոմսը փակվեց',
    'Your current Limit': 'Ձեր ընթացիկ սահմանաչափը',
    'Switch to enterprise Account': 'Փոխել ձեռնարկության հաշվի',
    'Video Guide': 'Տեսադաս',
    'Personal Information': 'Անձնական տվյալներ',
    'Crypto Deposit': 'Կրիպտոյի ավանդադրում',
    Unlimited: 'Անսահմանափակ',
    'Most Popular': 'Ամենահայտնի',
    'All basic Requirements': 'Բոլոր հիմնական պահանջները',
    'Government ID': 'Կառավարական ID',
    'Facial Verification': 'Դեմքի հաստատում',
    'Review time': 'Վերանայման ժամանակ',
    days: 'օրեր',
    'Fiat Deposit & Withdrawal Limit': 'Ֆիատ ավանդի և դուրսբերման սահմանաչափ',
    Daily: 'Օրական',
    'P2P Transaction Limits': 'P2P գործարքների սահմանաչափեր',
    'Crypto Withdraw': 'Կրիպտոյի դուրսբերում',
    'Highest Fiat Limit': 'Ամենաբարձր Ֆիատ սահմանաչափ',
    'Proof of Address': 'Հասցեի ապացույց',
    'Quick & Easy': 'Արագ և հեշտ',
    WAITING: 'Սպասման մեջ',
    'Advanced requested': 'Առաջադեմ պահանջված է',
    'Intermediate requested': 'Միջին պահանջված է',
    ACTIVE: 'Ակտիվ',
    'Intermediate by admin': 'Միջին ադմինի կողմից',
    'Advanced by admin': 'Առաջադեմ ադմինի կողմից',
    Registered: 'Գրանցված',
    'Select Approve or Reject status': 'Ընտրեք հաստատման կամ մերժման կարգավիճակը',
    Blocked: 'Արգելափակված',
    'USDT Value': 'USDT արժեքը',
    'USD Value': 'USD արժեքը',
    'Please fill the blank': 'Խնդրում ենք լրացնել դատարկ մասը',
    'Please select one': 'Խնդրում ենք ընտրել մեկը',
    'ID Card': 'Նույնականացման քարտ',
    'User Image': 'Օգտատիրոջ պատկեր',
    'Country / region': 'Երկիր / տարածաշրջան',
    'The user reject successfully': 'Օգտատերը հաջողությամբ մերժվեց',
    'Advanced Verification': 'Առաջադեմ հաստատում',
    'Confirm Residential Address': 'Հաստատել բնակության հասցեն',
    'Please choose and upload one of the following documents': 'Խնդրում ենք ընտրել և վերբեռնել հետևյալ փաստաթղթերից մեկը',
    'Utility bill (electricity, gaz. water/sewage, fibre/broadband service)':
      'Կոմունալ վճար (էլեկտրականություն, գազ, ջուր/կոյուղի, օպտիկա/հեռարձակման ծառայություն)',
    'Select Photo': 'Ընտրել լուսանկար',
    'Upload .JPG/.JPEG/.PNG file and no more than 5M': 'Վերբեռնել .JPG/.JPEG/.PNG ֆայլ և ոչ ավելի քան 5 ՄԲ',
    'Bank statement': 'Բանկային հաշվետվություն',
    'Basic Verification': 'Հիմնական հաստատում',
    'Identity Information': 'Նույնականացման տվյալներ',
    Nationality: 'Ազգություն',
    'Select your nationality': 'Ընտրեք ձեր ազգությունը',
    'date of birth': 'Ծննդյան ամսաթիվ',
    'Please input your street address!': 'Խնդրում ենք մուտքագրել ձեր փողոցի հասցեն!',
    'Please input your city!': 'Խնդրում ենք մուտքագրել ձեր քաղաքը!',
    'Please input your phone number!': 'Խնդրում ենք մուտքագրել ձեր հեռախոսահամարը!',
    'Please input your postal code!': 'Խնդրում ենք մուտքագրել ձեր փոստային կոդը!',
    'Use a valid government-issued document': 'Օգտագործեք վավեր կառավարական փաստաթուղթ',
    'Only the following documents listed below will be accepted': 'Ընդունվում են միայն ստորև նշված փաստաթղթերը',
    'Id Card': 'Նույնականացման քարտ',
    'Government-issued': 'Կառավարության կողմից տրված',
    'place documents against a single-colored background': 'Փաստաթղթերը տեղադրեք միագույն ֆոնի վրա',
    'Readable, well-lit, colored images': 'Կարդացվող, լավ լուսավորված, գունավոր պատկերներ',
    'Original Full-size, unedited documents': 'Անվերջապես խոշոր չափերով, անխաթար փաստաթղթեր',
    'No black and white images': 'Չկան սև ու սպիտակ պատկերներ',
    'No edited or expired documents': 'Չկան խմբագրված կամ ժամկետանց փաստաթղթեր',
    'file size must be between 10kb and 512kb in jpg/jpeg/png format': 'Ֆայլի չափը պետք է լինի 10kb-ից 512kb jpg/jpeg/png ձևաչափով',
    'Basic information': 'Հիմնական տվյալներ',
    'Change Password': 'Փոխել գաղտնաբառը',
    'Two-step verification': 'Երկու քայլով հաստատում',
    'Change email': 'Փոխել էլ. փոստը',
    'Receive SMS after transactions': 'Ստանալ SMS գործարքից հետո',
    'Receive notifications after transactions (Guide)': 'Ստանալ ծանուցումներ գործարքից հետո (Ուղեցույց)',
    Activities: 'Գործունեություններ',
    'Receive SMS after each login': 'Ստանալ SMS յուրաքանչյուր մուտքից հետո',
    'Receive emails after transactions': 'Ստանալ էլ. նամակներ գործարքից հետո',
    'Receive emails after each login': 'Ստանալ էլ. նամակներ յուրաքանչյուր մուտքից հետո',
    'User ID': 'Օգտատիրոջ ID',
    'Registration via : Website': 'Գրանցում միջոցով: Կայք',
    'Registration Date': 'Գրանցման ամսաթիվ',
    'Save Changes': 'Պահպանել փոփոխությունները',
    Default: 'Նախնական',
    'Please input a valid oldEmailAddress': 'Խնդրում ենք մուտքագրել վավեր հին էլ. փոստի հասցե',
    'Please input a valid email': 'Խնդրում ենք մուտքագրել վավեր էլ. փոստի հասցե',
    'GO BACK': 'Վերադառնալ',
    'Total Buy': 'Ընդհանուր գնում',
       'Total Referrals': 'Ընդհանուր հղումներ',
    'Total Tickets': 'Ընդհանուր տոմսեր',
    'Total Transactions': 'Ընդհանուր գործարքներ',
    'Highest Buy': 'Առավելագույն գնում',
    'Highest Sell': 'Առավելագույն վաճառք',
    'Total Tade': 'Ընդհանուր գործարք',
    'Total Order': 'Ընդհանուր պատվեր',
    'Total Fiat Income': 'Ընդհանուր Fiat եկամուտ',
    'Block user': 'Արգելափակել օգտատիրոջը',
    'Intermediate user': 'Միջին օգտատեր',
    'Advanced user': 'Առաջադեմ օգտատեր',
    items: 'Առարկաներ',
    'Close By Admin': 'Փակված ադմինի կողմից',
    'All tickets': 'Բոլոր տոմսերը',
    'Ticket Title': 'Տոմսի վերնագիր',
    'Pending tickets': 'Սպասող տոմսեր',
    'Total messages': 'Ընդհանուր հաղորդագրություններ',
    'Open tickets ': 'Բաց տոմսեր',
    'Please input dollar limit amount!': 'Խնդրում ենք մուտքագրել դոլարի սահմանաչափի գումարը!',
    'Please input rial limit amount!': 'Խնդրում ենք մուտքագրել ռիալի սահմանաչափի գումարը!',
    'To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.':
      'Ակտիվացնելու համար, սեղմեք «Ուղարկել SMS» կոճակը, որից հետո ձեզ կուղարկվի ակտիվացման կոդով հաղորդագրություն, ապա մուտքագրեք կոդը դաշտում և սեղմեք «Ակտիվացնել» կոճակը:',
    'To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.':
      'Ակտիվացնելու համար, սեղմեք «Ուղարկել էլ. փոստ» կոճակը, որից հետո ձեր էլ. փոստին կուղարկվի կոդ, ապա մուտքագրեք կոդը դաշտում և սեղմեք «Ակտիվացնել» կոճակը: Հնարավոր է, որ էլ. փոստը ստացված լինի (Սպամ / Junk) պանակում:',
    'To activate this feature, follow these steps:': 'Ակտիվացնելու համար հետևեք այս քայլերին:',
    '2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program.':
      '2. Տեղադրելու և աշխատացնելուց հետո Google Authenticator ծրագիրը, կատարեք հետևյալ քայլերից մեկով, որպեսզի հավելեք բանալին ծրագրին:',
    '- Enter a provided key: Select this option and enter the following code carefully.':
      '- Մուտքագրեք տրամադրված բանալին: Ընտրեք այս տարբերակը և մուտքագրեք հետևյալ կոդը զգուշությամբ:',
    '3. Enter the received code (6-digit number) in the box below and click the activate button.':
      '3. Մուտքագրեք ստացված կոդը (6 նիշանոց) ներքևի դաշտում և սեղմեք «Ակտիվացնել» կոճակը:',
    '1. Get the latest version of Google Authenticator from Google Play or Apple Store':
      '1. Ստացեք Google Authenticator-ի վերջին տարբերակը Google Play-ից կամ Apple Store-ից',
    '- Scan a barcode: Select this option and scan the following barcode.':
      '- Սկանավորեք գծագին: Ընտրեք այս տարբերակը և սկանավորեք հետևյալ գծագին:',
    WalletID: 'Դրամապանակի ID',
    'All Networks Status': 'Բոլոր ցանցերի վիճակը',
    'Networks Status': 'Ցանցերի վիճակ',
    UnVerified: 'Չհաստատված',
    'User level settings can be changed from this section, and please change the settings of this section carefully.':
      'Օգտատիրոջ մակարդակի կարգավորումները կարելի է փոխել այս բաժնում, և խնդրում ենք զգուշորեն փոխել այս բաժնի կարգավորումները:',
    'Please input withdrawal of cryptocurrency!': 'Խնդրում ենք մուտքագրել կրիպտոարժույթի դուրսբերման գումարը!',
    'Please input withdrawal of fiat!': 'Խնդրում ենք մուտքագրել Fiat-ի դուրսբերման գումարը!',
    'Please input buying digital money (equivalent to dollar)!':
      'Խնդրում ենք մուտքագրել թվային գումարի գնման գումարը (համարժեք է դոլարին)!',
    'Please input buying digital money (equivalent to rial)!':
      'Խնդրում ենք մուտքագրել թվային գումարի գնման գումարը (համարժեք է ռիալին)!',
    'Please input fees for buy cryptocurrency!': 'Խնդրում ենք մուտքագրել կրիպտոարժույթի գնման վճարները!',
    'Please input fees for sell cryptocurrency!': 'Խնդրում ենք մուտքագրել կրիպտոարժույթի վաճառքի վճարները!',
    'Please input trade maker fee!': 'Խնդրում ենք մուտքագրել առևտրի ստեղծողի վճարը!',
    'Please input futures maker fee!': 'Խնդրում ենք մուտքագրել ֆյուչերսների ստեղծողի վճարը!',
    'Please input trade taker fee!': 'Խնդրում ենք մուտքագրել առևտրի ընդունողի վճարը!',
    'Please input futures taker fee!': 'Խնդրում ենք մուտքագրել ֆյուչերսների ընդունողի վճարը!',
    'Please input internal transaction fee!': 'Խնդրում ենք մուտքագրել ներքին գործարքի վճարը!',
    'Please input OTC minimum buy!': 'Խնդրում ենք մուտքագրել OTC նվազագույն գնման գումարը!',
    'Cryptocurrency & Fiat Withdrawal': 'Կրիպտոարժույթի և Fiat-ի դուրսբերում',
    'Tether Fee': 'Tether-ի վճար',
    'Calculation of percentage in the price for buy': 'Գնի տոկոսի հաշվարկը գնման համար',
    'Calculation of the percentage in the price for sale': 'Գնի տոկոսի հաշվարկը վաճառքի համար',
    'Futures Fee': 'Ֆյուչերսների վճար',
    'Futures Fee (Coin-M Futures)': 'Ֆյուչերսների վճար (Coin-M Futures)',
    'Futures Fee (USDⓈ-M Futures)': 'Ֆյուչերսների վճար (USDⓈ-M Futures)',
    'Trade Fee': 'Առևտրի վճար',
    'Internal Transaction Fee': 'Ներքին գործարքի վճար',
    'OTC Minimum Buy': 'OTC նվազագույն գնում',
    DISAPPROVAL: 'Չհաստատում',
    'Advanced rejected': 'Առաջադեմ մերժված է',
    'Are you sure to remove this user from users?': 'Համոզվա՞ծ եք, որ ցանկանում եք հեռացնել այս օգտատիրոջը օգտատերերից:',
    'Are you sure to remove this ticket?': 'Համոզվա՞ծ եք, որ ցանկանում եք հեռացնել այս տոմսը:',
    'Please input your mobile number!': 'Խնդրում ենք մուտքագրել ձեր բջջային համարը!',
    'Please select your birth date': 'Խնդրում ենք ընտրել ձեր ծննդյան ամսաթիվը:',
    'Please select one!': 'Խնդրում ենք ընտրել մեկը!',
    'Please input your email adress!': 'Խնդրում ենք մուտքագրել ձեր էլ. փոստի հասցեն!',
    'Please input your photo!': 'Խնդրում ենք ներմուծել ձեր լուսանկարը!',
    'Fiat type': 'Fiat տեսակը',
    'Fiat Status': 'Fiat-ի կարգավիճակ',
    'Withdraw Status': 'Դուրսբերման կարգավիճակ',
    'Awaiting Approval': 'Սպասում է հաստատման',
    Cancelled: 'Չեղարկված',
    'Email Sent': 'Էլ. փոստը ուղարկված է',
    Processing: 'Մշակման մեջ',
    Rejected: 'Մերժված',
    'Transaction ID': 'Գործարքի ID',
    'Deposit Status': 'Ավանդի կարգավիճակ',
    'Deposit Id': 'Ավանդի ID',
    'Create Date': 'Ստեղծման ամսաթիվ',
    'Transaction Type': 'Գործարքի տեսակը',
    'Transaction Id': 'Գործարքի ID',
    Sheba: 'Շաբա',
    'Deposit type': 'Ավանդի տեսակը',
    'Created Date': 'Ստեղծման ամսաթիվ',
    Expired: 'Ժամկետանց',
    'Partially Filled': 'Մասամբ լրացված',
    'Pending Cancel': 'Սպասում է չեղարկմանը',
    Limit: 'Սահմանաչափ',
    'Stop Limit': 'Կանգառի սահմանաչափ',
    Filled: 'Լրացված',
    'Transaction Date': 'Գործարքի ամսաթիվ',
    'Stop Price': 'Կանգառի գին',
    'Stop limit': 'Կանգառի սահմանաչափ',
    'Minimum limit': 'Նվազագույն սահմանաչափ',
    'Stop price': 'Կանգառի գին',
    'Are you sure to remove this admin account?': 'Համոզվա՞ծ եք, որ ցանկանում եք հեռացնել այս ադմին հաշիվը:',
    'Dynamic profit percentage': 'Դինամիկ շահույթի տոկոս',
    'User Share': 'Օգտատիրոջ բաժին',
    Iran: 'Իրան',
    Armenia: 'Հայաստան',

    /**
     * **************************************************************************************
     * Please translate the below statements
     */
       'can see the list of commissions received by this user through introduction.':
      'شما می‌توانید لیست کمیسیون‌های دریافتی توسط این کاربر از طریق معرفی را ببینید.',
    'Crypto Desposit': 'واریز رمزارز',
    Monthly: 'Ամսական',
    'Contry / region': 'Երկիր / տարածաշրջան',
    'Use a valid goverment-issued document': 'Օգտագործեք վավեր պետական ​​փաստաթուղթ',
    'place documents against a single-coloured background': 'Փաստաթղթերը տեղադրեք միագույն ֆոնի վրա',
    'Readable, well-lit, colured images': 'Կարդացվող, լավ լուսավորված, գունավոր պատկերներ',
    'Total Trade': 'Ընդհանուր առևտուր',
    Account: 'Հաշիվ',
    'Tracking code': 'Հետևման կոդ',
    'This transaction is in progress, and after review, you can either confirm or reject it.':
      'Այս գործարքը ընթացքի մեջ է, և վերանայումից հետո կարող եք հաստատել կամ մերժել այն.',
    'Are you sure to deny this transaction?': 'Համոզվա՞ծ եք, որ ցանկանում եք մերժել այս գործարքը:',
    'Are you sure to accept this transaction?': 'Համոզվա՞ծ եք, որ ցանկանում եք հաստատել այս գործարքը:',
    'List of withdrawals': 'Հեռացումների ցանկը',
    'There is no record for this table': 'Այս աղյուսակի համար գրառում չկա',
    'Recurring Buy': 'Կրկնվող գնում',
    'In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We`ve got to make it easier for you to activate with each one.':
      'Ձեր հաշվի անվտանգության մակարդակը բարձրացնելու համար մենք առաջարկում ենք ակտիվացնել ձեր երկու քայլով մուտքը: Դուք կարող եք ակտիվացնել հետևյալ երկքայլ մուտքը, որը ներառում է երկքայլ մուտքը Google-ի, էլփոստի և SMS-ի միջոցով, իսկ ամենաանվտանգ մեթոդը Google-ի միջոցով երկքայլ մուտքն է, բայց հաշվի առնելով այս երեք տեսակները, մենք պետք է հեշտացնենք դրանցից յուրաքանչյուրով ակտիվացումը:',
    'Are you sure to remove this order?': 'Համոզվա՞ծ եք, որ ցանկանում եք հեռացնել այս պատվերը:',
    'I want to pay': 'Ես ցանկանում եմ վճարել',
    'I want to sell': 'Ես ցանկանում եմ վաճառել',
    'I will receive': 'Ես կստանամ',
    'Deposit account number': 'Ավանդի հաշվի համարը',
    'The total number of users': 'Օգտատերերի ընդհանուր քանակը',
    'Total balance(Fiat)': 'Ընդհանուր մնացորդ (Fiat)',
    'Total number of purchases': 'Գնումների ընդհանուր քանակը',
    'Total sales': 'Ընդհանուր վաճառքներ',
    'Total sales amount': 'Վաճառքի ընդհանուր գումարը',
    'number of orders': 'Պատվերների քանակը',
    'otal order amount': 'Պատվերի ընդհանուր գումարը',
    Transaction: 'Գործարք',
    'Referral Users': 'Հղված օգտատերեր',
    'Existing users': 'Գոյություն ունեցող օգտատերեր',
    'Active users': 'Ակտիվ օգտատերեր',
    'All tickets': 'Բոլոր տոմսերը',
    'Opened Tickets': 'Բացված տոմսեր',
    Change: 'Փոփոխել',
    Price: 'Գին',
    Pair: 'Զույգ',
    'Market Trades': 'Շուկայի գործարքներ',
    'Time Amount(MATIC) price(USDT)': 'Ժամանակ Քանակ (MATIC) գին (USDT)',
    'Market Activities': '',
    XMRBUSD: 'XMRBUSD',
    MANAUSDT: 'MANAUSDT',
    SLAPETH: 'SLAPETH',
    ONGBTS: 'ONGBTS',
    'MATIC/USDT': 'MATIC/USDT',
    Binance: 'Binance',
    'Volume USDT': 'Ծավալ USDT',
    'Volume MATIC': 'Ծավալ MATIC',
    low: 'ցածր',
    Hight: 'բարձր',
    Change: 'Փոփոխել',
    Polygon: 'Պոլիգոն',
    STOP_LIMIT: 'Կանգառի սահմանաչափ',
    MARKET: 'Շուկա',
    LIMIT: 'Սահմանաչափ',
    Total: 'Ընդհանուր',
    'Amout(MATIC)': 'Քանակ (MATIC)',
    'Price(USDT)': 'Գին (USDT)',
    'Recurring Buy': 'Կրկնվող գնում',
    'Rol 0.00% got .years for USDT Recurring Buy': '0.00% դրույքաչափ .տարիների համար USDT կրկնվող գնում',
    'Oder Book': 'Պատվերների գիրք',
    'Sum(BTC)': 'Գումար (BTC)',
    'Size(BTC)': 'Չափ (BTC)',
    Trades: 'Առևտուր',
    'BTC/USDT': 'BTC/USDT',
    Sep: 'Սեպտեմբեր',
    Oct: 'Հոկտեմբեր',
    Nov: 'Նոյեմբեր',
    '3y': '3 տարի',
    '50y': '50 տարի',
    'Trade History': 'Առևտրի պատմություն',
    'Open Trades': 'Բացված առևտուրներ',
    Positione: 'Դիրք',
    'Margin Ratio': 'Մարժի հարաբերակցություն',
    'Liq.Price': 'Լուծարային գին',
    'Entry Price': 'Մուտքի գին',
    Size: 'Չափ',
    BTCUSDT: 'BTCUSDT',
    Layer1: 'Շերտ 1',
    Layer2: 'Շերտ 2',
    Fav: 'Սիրելի',
    'Number of referrals': 'Հղումների քանակ',
    'Number of transactions': 'Գործարքների քանակ',
    'your total income is based on USD': 'Ձեր ընդհանուր եկամուտը հիմնված է ԱՄՆ դոլարի վրա',
    'Updated At': 'Թարմացվել է',
    'Creat At': 'Ստեղծվել է',
    Deleted: 'Հեռացված',
    'Close by admin': 'Փակվել է ադմինի կողմից',
    'Oder ID': 'Պատվերի ID',
    'Paysafe History': 'Paysafe-ի պատմություն',
    'Perfact Money History': 'Perfect Money-ի պատմություն',
    'Tx Code': 'Գործարքի կոդ',
    sum: 'Գումար',
    BUY: 'Գնել',
    'Unit price': 'Գին մեկ միավորի համար',
    "in order to increase the security leveled of yor account. we recommend that you enable your two-step login. you can enable the following two-step login. which includes two-step login via Google. email and SMS,and the safest method is two-step login via Google. but for you to consider three types we've got to make it easier for you to activate with each one.":
      'Հաշվի անվտանգության մակարդակը բարձրացնելու համար մենք առաջարկում ենք ակտիվացնել ձեր երկու քայլով մուտքը: Դուք կարող եք ակտիվացնել հետևյալ երկքայլ մուտքը, որը ներառում է երկքայլ մուտքը Google-ի, էլփոստի և SMS-ի միջոցով, իսկ ամենաանվտանգ մեթոդը Google-ի միջոցով երկքայլ մուտքն է, բայց հաշվի առնելով այս երեք տեսակները, մենք պետք է հեշտացնենք դրանցից յուրաքանչյուրով ակտիվացումը:',
    'Two-step login via Google': 'Երկքայլ մուտք Google-ի միջոցով',
    'Two-step login via Sms': 'Երկքայլ մուտք SMS-ի միջոցով',
    'Two-step login via Email': 'Երկքայլ մուտք էլփոստի միջոցով',
    'Withdrawal of cryptocurrency(equivalent to Dollar)': 'Կրիպտոարժույթի դուրսբերում (համարժեք դոլարին)',
    'Withdrawal of Fiat(equivalent to Dollar)': 'Fiat-ի դուրսբերում (համարժեք դոլարին)',
    'Buying digital money(equivalent to Dollar)': 'Թվային գումարի գնում (համարժեք դոլարին)',
    'Buying digital money(equivalent to Rial)': 'Թվային գումարի գնում (համարժեք ռիալին)',
    'Fees for buy Tether': 'Tether-ի գնման վճարներ',
    'Fees for Sell Tether': 'Tether-ի վաճառքի վճարներ',
    'Future Fee(Marker)': 'Ապագա վճար (նշիչ)',
    'Future Fee(Taker)': 'Ապագա վճար (ընդունիչ)',
    'Trade Fee(Marker)': 'Առևտրի վճար (նշիչ)',
    'Trede Fee(Taker)': 'Առևտրի վճար (ընդունիչ)',
    'Fees for internal transaction': 'Ներքին գործարքների վճարներ',
    'Min internal transaction': 'Ներքին գործարքի նվազագույնը',
    'Max internal transaction': 'Ներքին գործարքի առավելագույնը',
    'Max leverage': 'Առավելագույն լծակ',
    'Max Margin': 'Առավելագույն մարժին',
    'Card Number': 'Քարտի համարը',
    'Month Of date': 'Ամսվա ամսաթիվ',
    'Year of date': 'Տարվա ամսաթիվ',
    'Paysafe Price': 'Paysafe-ի գինը',
    'Deposit Type': 'Ավանդի տեսակը',
    'Voucher Currency': 'Վաուչերի արժույթ',
    'First Name': 'Անուն',
    'Last Name': 'Ազգանուն',
    'Old Password': 'Հին գաղտնաբառ',
    'New Password': 'Նոր գաղտնաբառ',
    'Confirm Password': 'Հաստատել գաղտնաբառը',
    'Add Mobile': 'Ավելացնել բջջային հեռախոսահամարը',
    'There is no record for this table': 'Այս աղյուսակի համար գրառում չկա',
    'Paysafe Price': 'Paysafe-ի գինը',
    'Deposite Type': 'Ավանդի տեսակը',
    'Voucher Currency': 'Վաուչերի արժույթ',
    'Fiat Type': 'Fiat-ի տեսակը',
    'Perfect Money Price': 'Perfect Money-ի գինը',
    TxID: 'Գործարքի ID',
    Origin: 'Ծագում',
    'Entry Price': 'Մուտքի գին',
    Leverage: 'Լծակ',
    'Margin Ratio': 'Մարժի հարաբերակցություն',
    'Value currency': 'Արժույթի արժեք',
    Address: 'Հասցե',
    'Recurring Buy': 'Կրկնվող գնում',
    Long: 'Երկարաժամկետ',
    Short: 'Կարճաժամկետ',
    Cost: 'Արժեք',
    Max: 'Առավելագույն',
    'Introduction link': 'Հղման կապ',
    'Introduction Code': 'Հղման կոդ',
    'Number of referrals': 'Հղումների քանակ',
    'Number of transactions': 'Գործարքների քանակ',
    'Your total income is based on USD': 'Ձեր ընդհանուր եկամուտը հիմնված է ԱՄՆ դոլարի վրա',
    'Verify Now': 'Հաստատել հիմա',
    'After Basic': 'Հիմնականից հետո',
    'After Intermediate': 'Միջինից հետո',
    Fiat: 'Fiat',
    "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.":
      'Դուք դեռևս որևէ գործունեություն չեք ունեցել։ Երբ սկսեք առևտրել cryptizi-ում, այստեղ կհայտնվի կենդանի գրաֆիկ:',
    'Save Change': 'Պահպանել փոփոխությունը',
    'Setting Saved Successfully.': 'Կարգավորումը հաջողությամբ պահպանվեց:',
    'Default setting restored successfully.': 'Նախնական կարգավորումը հաջողությամբ վերականգնվեց:',
    'Please enter Spend amount!': 'Խնդրում ենք մուտքագրել ծախսի գումարը!',
    'added to favorites successfully.': 'Ավելացված է նախընտրածներին հաջողությամբ:',
    'Bad Request': 'Սխալ հարցում',
    'Enter the amount!': 'Մուտքագրեք գումարը!',
    'Please enter total amount!': 'Խնդրում ենք մուտքագրել ընդհանուր գումարը!',
    'Internal Server Error': 'Ներքին սերվերի սխալ',
    'Insufficient funds': 'Անբավարար միջոցներ',
    'Please select a coin!': 'Խնդրում ենք ընտրել մետաղադրամ!',
    'Please fill in all fields': 'Խնդրում ենք լրացնել բոլոր դաշտերը',
    'Share percentage changed successfully.': 'Բաժնեմասի տոկոսը հաջողությամբ փոփոխվեց:',
    'deleted from your favorites': 'հեռացված է ձեր նախընտրածներից',
    'Invalid request': 'Անվավեր հարցում',
    'Invalid symbol USDTUSDT': 'Անվավեր նշան USDTUSDT',
    'Card ExpiryDate Month is invalid': 'Քարտի ավարտման ամսաթիվը անվավեր է',
    'card is expired': 'քարտը ժամկետանց է',
    'Month of date': 'Ամսվա ամսաթիվ',
    'Account Number': 'Հաշվի համարը',
    'Card ExpiryDate Month is invalid': 'Քարտի ավարտման ամսաթիվը անվավեր է',
    'To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.':
      'Այս հատկությունը ակտիվացնելու համար հետևեք այս քայլերին:\n 1. Վերջին տարբերակը ստացեք Google Authenticator-ից Google Play-ից կամ Apple Store-ից:\n 2. Տեղադրելուց և աշխատացնելուց հետո Google Authenticator ծրագրի միջոցով հետևեք հետևյալ մեթոդներից մեկին՝ ծրագրին բանալին ավելացնելու համար: - Սկանավորեք գծագին: Ընտրեք այս տարբերակը և սկանավորեք հետևյալ գծագին:',
    '- Enter a provided key: Select this option and enter the following code carefully.':
      '- Մուտքագրեք տրամադրված բանալին: Ընտրեք այս տարբերակը և մուտքագրեք հետևյալ կոդը զգուշությամբ:',
    '3. Enter the received code (6-digit number) in the box below and click the activate button.':
      '3. Մուտքագրեք ստացված կոդը (6 նիշանոց) ներքևի դաշտում և սեղմեք «Ակտիվացնել» կոճակը:',
    'Google Code': 'Google-ի կոդ',
    'To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.':
      'Ակտիվացնելու համար, սեղմեք «Ուղարկել SMS» կոճակը, որից հետո ձեզ կուղարկվի ակտիվացման կոդով հաղորդագրություն, ապա մուտքագրեք կոդը դաշտում և սեղմեք «Ակտիվացնել» կոճակը:',
    'Send SMS': 'Ուղարկել SMS',
    'Email Code': 'Էլփոստի կոդ',
    'To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.':
      'Ակտիվացնելու համար, սեղմեք «Ուղարկել էլ. փոստ» կոճակը, որից հետո ձեր էլ. փոստին կուղարկվի կոդ, ապա մուտքագրեք կոդը դաշտում և սեղմեք «Ակտիվացնել» կոճակը: Հնարավոր է, որ էլ. փոստը ստացված լինի (Սպամ / Junk) պանակում:',
    'Send Email': 'Ուղարկել էլ. փոստ',
    "In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We've got to make it easier for you to activate with each one.":
      'Ձեր հաշվի անվտանգության մակարդակը բարձրացնելու համար մենք առաջարկում ենք ակտիվացնել ձեր երկու քայլով մուտքը: Դուք կարող եք ակտիվացնել հետևյալ երկքայլ մուտքը, որը ներառում է երկքայլ մուտքը Google-ի, էլփոստի և SMS-ի միջոցով, իսկ ամենաանվտանգ մեթոդը Google-ի միջոցով երկքայլ մուտքն է, բայց հաշվի առնելով այս երեք տեսակները, մենք պետք է հեշտացնենք դրանցից յուրաքանչյուրով ակտիվացումը:',
    'Selecting higher leverage such as [10x] increases your liquidation risk. Always manage your risk levels.':
      'Լծակի բարձրացումը, օրինակ՝ [10x], բարձրացնում է ձեր լուծարքի ռիսկը: Միշտ կառավարեք ձեր ռիսկի մակարդակները:',
    'Maximum position: 15,000,000 USDT': 'Առավելագույն դիրք: 15,000,000 USDT',
    'Adjust Leverage': 'Կարգավորել լծակը',
    Leverage: 'Լծակ',
'Select Symbol': 'Ընտրել նշանը',
'See what the potential risk and reward will be in monetary terms on any given trade. Use our Futures Calculator to establish your potential profit/loss on a future trade. Read tips on how to use.':
  'Տեսեք, թե ինչպիսի վտանգ և պարգև կգոյանա ցանկացած առևտրի համար ֆինանսական տերմիններով: Օգտագործեք մեր Ֆյուչերսների հաշվիչը՝ ձեր հնարավոր շահույթը/կորուստը որոշելու համար ապագա առևտրում: Կարդացեք օգտագործման խորհուրդները:',
'Liquidation Price': 'Լիկվիդացիայի գին',
'Target Price': 'Նպատակային գին',
'Initial Margin': 'Սկզբնական մարժա',
Shot: 'Վաճառք',
Long: 'Գնում',
'Entry Price': 'Մուտքագին',
Quantity: 'Քանակ',
Calculate: 'Հաշվել',
Result: 'Արդյունք',
'Maximum position': 'Առավելագույն դիրք',
'Introduction code': 'Ներկայացման կոդ',
'Introduction link': 'Ներկայացման հղում',
'No Data': 'Տվյալներ չկան',
Total: 'Ընդհանուր',
'Number of referrals': 'Ներկայացումների քանակը',
'Number of transactions': 'Գործարքների քանակը',
'Your total income is based on USD': 'Ձեր ընդհանուր եկամուտը հաշվվում է դոլարով',
'introduction code': 'Ներկայացման կոդ',
'introduction link': 'Ներկայացման հղում',
'The total number of users': 'Օգտատերերի ընդհանուր քանակը',
'Total number of orders': 'Պատվերների ընդհանուր քանակը',
'Total sales amount': 'Ընդհանուր վաճառքի գումարը',
'Total number of purchases': 'Գնումների ընդհանուր քանակը',
'Total balance ( Fiat )': 'Ընդհանուր մնացորդ (Ֆիատ)',
'Total order amount': 'Պատվերների ընդհանուր գումարը',
'Total sales': 'Ընդհանուր վաճառքներ',
'Total amount of purchases': 'Գնումների ընդհանուր գումարը',
'Opened Tickets': 'Բացված տոմսեր',
'Existing users': 'Գործող օգտատերեր',
'All tickets': 'Բոլոր տոմսերը',
'Referral Users': 'Ներկայացված օգտատերեր',
'Active users': 'Ակտիվ օգտատերեր',
Transaction: 'Գործարք',
'Purchases amount': 'Գնումների գումար',
'Sales amount': 'Վաճառքի գումար',
'Creat At': 'Ստեղծման ամսաթիվ',
'Updated At': 'Թարմացման ամսաթիվ',
Order: 'Պատվեր',
'Enter Tx-Code': 'Մուտքագրեք պատվերի համարը',
'Tx-Code': 'Պատվերի համարը',
'First Name': 'Անուն',
'Last Name': 'Ազգանուն',
'Open Trades': 'Բացված պատվերներ',
'Tx-Code': 'Գործարքի կոդ',
'Enter Tx-code': 'Մուտքագրեք գործարքի կոդը',
'Tx Code': 'Գործարքի կոդ',
'Paysafe Price': 'Paysafe գին',
'Deposit Type': 'Վճարման տեսակ',
'Voucher Currency': 'Վկայականի արժույթ',
'Paysafe History': 'Paysafe պատմություն',
'Perfect Money History': 'Perfect Money պատմություն',
'Fiat Amount': 'Ֆիատի գումար',
'Perfect Money Price': 'Perfect Money գին',
'There is no record for this table': 'Այս աղյուսակում տվյալներ չկան',
Origin: 'Ծագում',
TxID: 'Գործարքի ID',
'Entry Price': 'Մուտքի գին',
Leverage: 'Կարգավորիչ',
'Margin Ratio': 'Մարժայի հարաբերակցություն',
'User id': 'Օգտատերի ID',
'Fiat withdraw transaction waiting': 'Ֆիատի դուրսբերման գործարքը սպասում է',
'You can see the list of fiat transactions whose information must be confirmed.':
  'Դուք կարող եք տեսնել ֆիատի գործարքների ցուցակը, որոնց տեղեկատվությունը պետք է հաստատվի:',
'Fiat Type': 'Ֆիատի տեսակ',
Account: 'Հաշիվ',
Actions: 'Գործողություններ',
'Fiat deposit transaction waiting': 'Ֆիատի մուտքագրման գործարքը սպասում է',
Receipt: 'Կտրոն',
Token: 'Թոկեն',
Getway: 'Դարպաս',
'Percentage in price for sale': 'Վաճառքի գնային տոկոս',
'Purchase price in dollars': 'Գնումների գինը դոլարով',
'Percentage on the price to buy': 'Գնման գնի տոկոս',
'Percentage in the price for ( marketing )': 'Գնային տոկոս (մարքեթինգ)',
'Selling price in dollars': 'Վաճառքի գինը դոլարով',
Inventory: 'Պաշար',
Active: 'Ակտիվ',
'Minimum allowed purchase': 'Նվազագույն թույլատրելի գնում',
'Only Iranian IPs are allowed': 'Միայն իրանական IP-ները թույլատրված են',
'Deactivating purchase from site': 'Կայքից գնումների դադարեցում',
'IP Block': 'IP արգելափակում',
Num: 'Համար',
Delete: 'Ջնջել',
'Withdrawal of cryptocurrency (equivalent to Dollar)': 'Կրիպտոարժույթի դուրսբերում (համարժեք դոլարով)',
'Buying digital money (equivalent to Dollar)': 'Թվային գումարի գնում (համարժեք դոլարով)',
'Fees for buy Tether': 'Tether-ի գնման վճարներ',
Marker: 'Նշանիչ',
Taker: 'Վերցնող',
'Fees for internal transaction': 'Ներքին գործարքի վճարներ',
'Min internal transaction': 'Նվազագույն ներքին գործարք',
'Internal Transaction': 'Ներքին գործարք',
'Max Leverage': 'Առավելագույն կարգավորիչ',
'Withdrawal of Fiat (equivalent to Dollar)': 'Ֆիատի դուրսբերում (համարժեք դոլարով)',
'Buying digital money (equivalent to Rial)': 'Թվային գումարի գնում (համարժեք ռիալով)',
'Fees for Sell Tether': 'Tether-ի վաճառքի վճարներ',
'OTC Minimum Buy': 'OTC նվազագույն գնում',
'Max internal transaction': 'Առավելագույն ներքին գործարք',
'Max Margin': 'Առավելագույն մարժա',
'Futures settings': 'Ֆյուչերսների կարգավորումներ',
Disabled: 'Անջատված',
Enabled: 'Միացված',
'Max Borrow': 'Առավելագույն փոխառում',
'Add More Coins': 'Ավելացնել այլ մետաղադրամներ',
'List of bank accounts': 'Բանկային հաշիվների ցուցակ',
'Bank name': 'Բանկի անուն',
'Card number': 'Քարտի համար',
'Account number': 'Հաշվի համար',
'Shaba number': 'Շաբա համար',
'Add New': 'Ավելացնել նորը',
'Transfer methods': 'Փոխանցման մեթոդներ',
Name: 'Անուն',
'Card to Card': 'Քարտից քարտ',
'Bank deposit': 'Բանկային ավանդ',
'Remember me': 'Հիշել ինձ',
'Web Advertisement Setting': 'Վեբ գովազդի կարգավորումներ',
'Show how many days': 'Ցուցադրել օրերի քանակը',
'Advanced editor': 'Առաջադեմ խմբագրիչ',
'Mobile Advertisement Setting': 'Բջջային գովազդի կարգավորումներ',
'Write Something awesome...': 'Գրեք ինչ-որ բան հիանալի...',
Zibal: 'Զիբալ',
Vander: 'Վանդեր',
Default: 'Լռելյայն',
'Total users': 'Օգտատերերի ընդհանուր քանակը',
'Verified users': 'Հաստատված օգտատերեր',
'Users are waiting': 'Օգտատերերը սպասում են',
'Rejected users': 'Մերժված օգտատերեր',
'User Id': 'Օգտատերի ID',
Mobile: 'Բջջային',
Tell: 'Հեռախոս',
'Select or Drage photo': 'Ընտրել կամ քաշել լուսանկարը',
'Introduced user': 'Ներկայացված օգտատեր',
'Total orders': 'Ընդհանուր պատվերներ',
'Total commission orders': 'Ընդհանուր հանձնարարական պատվերներ',
'Average commission per order': 'Միջին հանձնարարական մեկ պատվերի համար',
'There is no record for this table': 'Այս աղյուսակում տվյալներ չկան',
'Fiat Wallet Transaction': 'Ֆիատ դրամապանակի գործարք',
'Notification Tite': 'Ծանուցման վերնագիր',
'Color background': 'Ներկի ֆոն',
'Normal(black)': 'Սովորական (սև)',
Users: 'Օգտատերեր',
'Active days': 'Ակտիվ օրեր',
Headers: 'Կարգավիճակներ',
'Notification /Email /SMS': 'Ծանուցում / Էլ․փոստ / SMS',
Types: 'Տեսակներ',
Recipient: 'Ստացող',
Color: 'Գույն',
'Active Days': 'Ակտիվ օրեր',
'Total Trade': 'Ընդհանուր առևտուր',
Russia: 'Ռուսաստան',
'United Arab Emirates': 'Միացյալ Արաբական Էմիրություններ',
'Saudi Arabia': 'Սաուդյան Արաբիա',
'User Statistics': 'Օգտատերերի վիճակագրություն',
'Old Password': 'Հին գաղտնաբառ',
'New Password': 'Նոր գաղտնաբառ',
'Confirm Password': 'Հաստատել գաղտնաբառը',
'In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We`ve got to make it easier for you to activate with each one.':
  'Ձեր հաշվի անվտանգության մակարդակը բարձրացնելու համար խորհուրդ ենք տալիս միացնել երկաստիճան մուտքը: Դուք կարող եք միացնել հետևյալ երկաստիճան մուտքը, որը ներառում է մուտք Google-ի, էլ. փոստի և SMS-ի միջոցով, իսկ ամենաապահով մեթոդը Google-ի միջոցով է, բայց ձեզ համար մենք մտածել ենք այս երեք տեսակի մասին, որպեսզի ակտիվացումը հեշտ լինի յուրաքանչյուրի հետ:',
'To activate this feature, follow these steps: 1. Get the latest version of Google Authenticator from Google Play or Apple Store. 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.':
  'Այս հատկությունը ակտիվացնելու համար հետևեք այս քայլերին՝ 1. Ներբեռնեք Google Authenticator-ի վերջին տարբերակը Google Play-ից կամ Apple Store-ից: 2. Հետո ծրագրի տեղադրման և գործարկման, հետևեք հաջորդ եղանակներից մեկին և ավելացրեք բանալին ծրագրին: - Սքանավորեք մեկ բանալին՝ Ընտրեք այս տարբերակը և սքանավորեք հաջորդ բանալին:',
'- Enter a provided key: Select this option and enter the following code carefully.':
  '- Մուտքագրեք ներկայացված բանալին՝ Ընտրեք այս տարբերակը և մուտքագրեք հաջորդ կոդը ուշադիր:',
'3. Enter the received code (6-digit number) in the box below and click the activate button.':
  '3. Մուտքագրեք ստացած կոդը (6 նիշ) ստորև բերված դաշտում և սեղմեք ակտիվացման կոճակը:',
'Google Code': 'Google կոդ',
'New Email': 'Նոր էլ.փոստ',
'Add Mobile': 'Ավելացնել բջջայինը',
'Internal Server Error': 'Ներքին սերվերի սխալ',
'Only normal user allowed': 'Միայն սովորական օգտատեր է թույլատրված',
'Please input your old password!': 'Խնդրում ենք մուտքագրեք ձեր հին գաղտնաբառը!',
'Passwords must have at least 8 characters and contain at least one uppercase letter, lowercase letter, number, and symbol.':
  'Գաղտնաբառերը պետք է պարունակեն առնվազն 8 նիշ և ներառեն առնվազն մեկ մեծատառ, փոքրատառ, թիվ և նշան:',
'Please input your confirm password!': 'Խնդրում ենք մուտքագրեք ձեր հաստատման գաղտնաբառը!',
'Invalid soft otp': 'Անվավեր փափուկ OTP',
'Session not found': 'Սեանսը չի գտնվել',
'Send SMS': 'Ուղարկել SMS',
'send SMS otp need phone number!': 'SMS OTP ուղարկելու համար անհրաժեշտ է հեռախոսահամար:',
'User Id': 'Օգտատերի ID',
'Final amount': 'Վերջնական գումար',
'Fiat Type': 'Ֆիատի տեսակ',
'Waller ID': 'Դրամապանակի ID',
'Pay Safe Voucher': 'Pay Safe վաուչեր',
'Pay Safe': 'Pay Safe',
'Popup link': 'Պատուհանի հղում',
'Please choose an image': 'Խնդրում ենք ընտրեք մի պատկեր',
Tokens: 'Թոկեններ',
'Add token': 'Ավելացնել թոկեն',
'Main Wallet Settings': 'Հիմնական դրամապանակի կարգավորումներ',
'Network setting': 'Ցանցի կարգավորումներ',
'Listing': 'Ցուցակում',
'Cryptizi offers a powerful platform to take advantage of a broad user base and solid infrastructure, improving your token\'s market visibility and accessibility.':
  'Cryptizi-ն առաջարկում է հզոր պլատֆորմ՝ օգտագործելու լայն օգտատերերի բազան և ամուր ենթակառուցվածքը՝ բարելավելով ձեր թոկենի շուկայի տեսանելիությունն ու հասանելիությունը:',
'Application Process': 'Դիմումի գործընթաց',
'Listing Application': 'Ցուցակման դիմում',
'To apply for listing, simply click on \'Listing Application\'. Our business manager will promptly get in touch with you.':
  'Ցուցակման դիմումի համար պարզապես սեղմեք «Ցուցակման դիմում»: Մեր բիզնես մենեջերը արագ կապ կլինի ձեզ հետ:',
'For any queries, reach out to us at listing@cryptizi.com. Thank you!':
  'Հարցերի դեպքում դիմեք մեզ listing@cryptizi.com էլ. հասցեով: Շնորհակալություն!',
'Ticket Number': 'Տոմսի համարը',
'Created At': 'Ստեղծվել է',
'Dedicated Wallet Settings': 'Նվիրված դրամապանակի կարգավորումներ',
    'Wallet Settings':"դրամապանակի կարգավորումներ"
  },
};

export default arm;
