import { Box, OutlinedInput, InputLabel, MenuItem, FormControl, Select, Chip } from '@mui/material';
import Iconify from './Iconify';

export default function MultipleSelectChip({ options, itemHeight = 48, itemPaddingTop = 8, label, name ,  setValues, values}) {

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: itemHeight * 4.5 + itemPaddingTop,
        width: { xs: '100%', md: '60%' },
      },
    },
  };

  const handleChange = ({ target: { value } }) => {
    setValues(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleDelete = (e, value) => {
    e.preventDefault();
    let copied = [...values];
    for (var i = 0; i < copied.length; i++) {
      if (copied[i] === value) {
        copied.splice(i, 1);
      }
    }
    setValues(copied);
  };

  return (
    <div>
      <FormControl sx={{ width: '100%', height: '100%' }}>
        {label && <InputLabel id='multiple-chip-label'>{label}</InputLabel>}
        <Select
          labelId='multiple-chip-label'
          id='multiple-chip'
          multiple
          value={values}
          onChange={handleChange}
          input={<OutlinedInput id='select-multiple-chip' label={label && label} />}
          sx={{ '& .MuiSelect-select': { p: 1.3 } }}
          renderValue={() => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {values.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  deleteIcon={
                    <Iconify
                      icon='eva:close-circle-outline'
                      onMouseDown={(event) => event.stopPropagation()}
                      sx={{
                        width: 20,
                        height: 20,
                      }}
                    />
                  }
                  onDelete={(e) => handleDelete(e, value)}
                  sx={{
                    backgroundColor: 'grey.600',
                    '& .MuiChip-deleteIcon': {
                      color: 'grey.0',
                    },
                  }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          name={name}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option} sx={{ fontSize: '14px', color: 'grey.100' }}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
