import { TableRow, TableCell, Typography, Button, Stack, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { fCurrency, fDecimal } from '../../../../utils/formatNumber';
import Iconify from '../../../../components/Iconify';
import { navigationUrl } from '../../../../navigationUrl';
import SymbolIcon from '../../../../components/SymbolIcon';

// ----------------------------------------------------------------------

export default function WalletTableRow({ row, valueVisible = false  , setOpenTransfer}) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';

  const { symbol, name, amount, available, blocked, btcValue, usdtValue, tradingPair } = row;

  return (
    <TableRow hover>
      <TableCell>
        <Stack direction='row' spacing={2} alignItems='center' sx={{ py: 0.3 }}>
          <SymbolIcon symbol={symbol} sx={{ width: 24, height: 24 }} />

          <Stack>
            <Typography variant='subtitle2'>{symbol}</Typography>
            <Typography variant='body3' noWrap color='grey.300'>
              {name}
            </Typography>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell>
        {valueVisible ? (
          <Typography variant='body3'>{fDecimal(amount)}</Typography>
        ) : (
          <Typography variant='h6' sx={{ letterSpacing: 2, pt: 1.2, pl: 0.5 }}>
            ***
          </Typography>
        )}
      </TableCell>

      <TableCell>
        {valueVisible ? (
          <Typography variant='body3'>{fDecimal(available)}</Typography>
        ) : (
          <Typography variant='h6' sx={{ letterSpacing: 2, pt: 1.2, pl: 2.5 }}>
            ***
          </Typography>
        )}
      </TableCell>

      <TableCell>
        {valueVisible ? (
          <Typography variant='body3'>{fDecimal(blocked)}</Typography>
        ) : (
          <Typography variant='h6' sx={{ letterSpacing: 2, pt: 1.2, pl: 1.8 }}>
            ***
          </Typography>
        )}
      </TableCell>

      <TableCell>
        <Stack>
          {valueVisible ? (
            <>
              <Typography variant='body3'>{fDecimal(btcValue)}</Typography>
              <Typography variant='body4' color='grey.300'>
                ≈ {fCurrency(usdtValue)}
              </Typography>
            </>
          ) : (
            <Typography variant='h6' sx={{ letterSpacing: 2, pt: 1.2, pl: 1.8 }}>
              ***
            </Typography>
          )}
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction='row'>
          <Button color='success' onClick={setOpenTransfer}>
            <Typography color='success.main' variant='body3'>
              {t('Transfer')}
            </Typography>
          </Button>

          <Button color='success' onClick={() => navigate(`${navigationUrl.deposit_crypto_request}?symbol=${symbol}`)}>
            <Typography color='success.main' variant='body3'>
              {t('Deposit')}
            </Typography>
          </Button>

          <Button color='success' onClick={() => navigate(`${navigationUrl.withdraw_crypto_request}?symbol=${symbol}`)}>
            <Typography color='success.main' variant='body3'>
              {t('Withdraw')}
            </Typography>
          </Button>

          <Button
            color='success'
            onClick={() =>
              navigate(navigationUrl.trade, {
                state: {
                  passedFromWallet: {
                    symbol: tradingPair === 'USDTUSDT' ? 'BTCUSDT' :tradingPair,
                    baseAsset: symbol === 'USDT' ? 'USDC' : symbol,
                    quoteAsset: 'USDT',
                  },
                },
              })
            }
          >
            <Typography color='success.main' variant='body3'>
              {t('Trade')}
            </Typography>
          </Button>

          <Button color='success' onClick={() => navigate(`${navigationUrl.convert}?symbol=${symbol}`)}>
            <Typography color='success.main' variant='body3'>
              {t('Convert')}
            </Typography>
          </Button>

          {/*<Button color='inherit'>*/}
          {/*  <Iconify icon={isRTL ? 'ep:arrow-left-bold' : 'ep:arrow-right-bold'} />*/}
          {/*</Button>*/}
        </Stack>
      </TableCell>
    </TableRow>
  );
}
