import { useSnackbar } from 'notistack';

import Api from '../../../../../http/adminHttp/serviceApiAdmin';
import { useTranslation } from 'react-i18next';

export default function usePerfectMoneySubmit() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();


  const onSubmit = async (payload) => {
    const response = await Api.editPerfectMoneyData(payload);
    if (response?.status === 'SUCCESS') {
      enqueueSnackbar(t('The values saved successfully.'), { variant: 'success' });
    }
  };

  return {
    onSubmit,
  };
}
