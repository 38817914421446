import React from 'react';
import { TableRow, TableCell, Box, Typography } from '@mui/material';
import moment from 'jalali-moment';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const AutomaticDepositHistoryTableRow = ({ row, index }) => {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);
  const { firstName, lastName, sheba, type, balance, date } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1.5, typography: 'body2' } }}>
      <TableCell align='center' sx={{ width: 80 }}>
        {index + 1}
      </TableCell>

      <TableCell>
        {firstName} {lastName}
      </TableCell>

      <TableCell>{sheba}</TableCell>

      <TableCell align='center'>
        <Box
          sx={{
            maxWidth: 130,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            py: 0.8,
            px: 2,
            ...(type === 'ACTIVE'
              ? { bgcolor: 'rgba(48, 224, 161, 0.2)' }
              : type === 'DISAPPROVAL'
              ? { bgcolor: 'rgba(250, 34, 86, 0.2)' }
              : type === 'WAITING'
              ? { bgcolor: 'rgba(242, 127, 22, 0.2)' }
              : ''),
          }}
        >
          <Typography
            variant='body4'
            sx={{
              lineHeight: 1,
              py: 0.4,
              ...(type === 'ACTIVE'
                ? { color: '#30E0A1' }
                : type === 'DISAPPROVAL'
                ? { color: 'error.darker' }
                : type === 'WAITING'
                ? { color: 'warning.main' }
                : ''),
            }}
          >
            {t(type)}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align='center'>{balance}</TableCell>

      <TableCell>{moment(date).locale(language).format('D MMM YYYY - HH:mm')}</TableCell>
    </TableRow>
  );
};

export default AutomaticDepositHistoryTableRow;
