import React from 'react';
import { Stack, Typography } from '@mui/material';

import { RHFRadioGroup } from '../../../../../components/hookForm';
// import { RHFCustomUploadImage } from '../../../../../components/hookForm/RHFUpload';
import Image from '../../../../../components/Image';
import iconEmptyPic from '../../../../../assets/gallery.svg';
import { useTranslation } from 'react-i18next';
import { openBase64 } from '../../../../../utils';

const IntermediateTab = ({ data, handleDrop }) => {
  const { t } = useTranslation();

  return (
    <Stack sx={{ mt: 5 }} spacing={2.5}>
      {/* <RHFRadioGroup
        name='authCard'
        options={[
          { label: 'ID card', value: 'idCard' },
          { label: 'Passport', value: 'passport' },
        ]}
        sx={{ flexDirection: 'column' }}
      /> */}
      <Typography variant='body3'>
        {t('Passport')}/{t('ID Card')}
      </Typography>

      <Stack
        alignItems='center'
        justifyContent='center'
        sx={{
          mt: 5,
          width: 320,
          height: 200,
          border: '1px solid',
          borderColor: 'grey.500',
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        {data?.passport ? (
          <button
            onClick={() => openBase64(data?.passport)}
            style={{ outline: 0, backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
          >
            <Image src={data?.passport} />
          </button>
        ) : (
          <Image src={iconEmptyPic} sx={{ width: 30 }} />
        )}
      </Stack>

      <Typography variant='body3'>{t('User Image')}</Typography>

      <Stack
        alignItems='center'
        justifyContent='center'
        sx={{
          mt: 5,
          width: 320,
          height: 200,
          border: '1px solid',
          borderColor: 'grey.500',
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        {data?.userImage ? (
          <button
            onClick={() => openBase64(data?.userImage)}
            style={{ outline: 0, backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
          >
            <Image src={data?.userImage} />
          </button>
        ) : (
          <Image src={iconEmptyPic} sx={{ width: 30 }} />
        )}
      </Stack>
    </Stack>
  );
};

export default IntermediateTab;
