import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, MenuItem, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Image from '../../../components/Image';
import Api from '../../../http/serviceApi';
import { SET_PAGE_STATE } from '../../../store/actionTypes';
import LoadingScreen from '../../../components/LoadingScreen';
import FillSelect from '../../../components/FillSelect';
import SmallAreaChart from '../../../components/SmallAreaChart';
import DepositPaymentForm from './depositPaymentForm';
import DepositReceiptForm from './depositReceiptForm';
import DepositTable from './depositTable';
import useWallet from '../../../hooks/useWallet';

import iconSampleWalletRial from '../../../assets/sampleWalletIconGreen.svg';
import iconSampleWalletUSD from '../../../assets/sampleWalletIconPurple.svg';

export default function FiatDeposit() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isIranianPaymentActive = useSelector((store) => store.isIranianPaymentActive);

  // ---------------------------
  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Fiat Deposit' },
    });
  }, []);

  // ---------------------------
  const [depositTab, setDepositTab] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [bankCardList, setBankCardList] = useState([]);

  // ---------------------------
  const { userWallet, loading } = useWallet();

  // ---------------------------
  useEffect(() => {
    getBankCard();
  }, []);

  // ---------------------------
  const getBankCard = async () => {
    const response = await Api.getBankCard();
    if (response) {
      setBankCardList(response.bankCardInfos);
    }
  };

  // ---------------------------
  if (loading) {
    return <LoadingScreen />;
  }

  // ---------------------------
  return (
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={8}>
          <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 } }}>
            <Stack direction='row' justifyContent={'space-between'}>
              <Stack direction='row' spacing={1.5}>
                <Stack
                  justifyContent={'center'}
                  alignItems={'center'}
                  sx={{ backgroundColor: 'rgb(114, 220, 96, 0.1)', width: 48, height: 48, borderRadius: '100%' }}
                >
                  <Image src={selectedCurrency === 'USD' ? iconSampleWalletUSD : iconSampleWalletRial} alt='' />
                </Stack>
                <Stack>
                  <Typography variant='h6' sx={{ fontWeight: 500 }}>
                    {selectedCurrency === 'USD' ? userWallet.dollar : userWallet.rial}
                  </Typography>
                  <Typography variant='body3' color='grey.100'>
                    {t('Your inventory')}
                  </Typography>
                </Stack>
              </Stack>

              <FillSelect
                value={selectedCurrency}
                onChange={setSelectedCurrency}
                sx={{ minWidth: 145, '& .MuiSelect-select': { color: 'grey.0' } }}
              >
                {isIranianPaymentActive && (
                  <MenuItem value={'IRR'} sx={{ typography: 'body3' }}>
                    {t('Rial')}
                  </MenuItem>
                )}
                <MenuItem value={'USD'} sx={{ typography: 'body3' }}>
                  {t('USD')}
                </MenuItem>
              </FillSelect>

              <SmallAreaChart
                data={[17, 25, 23, 20, 25, 20, 15, 18, 14, 10, 26, 21, 20, 28, 35, 30, 32, 28, 27, 20]}
                isPositive
                width={137}
                height={38}
              />
            </Stack>

            <Typography variant='h6' sx={{ mt: 6, fontWeight: 500 }}>
              {t('Deposit to wallet')}
            </Typography>

            <Box sx={{ mt: 2.5, width: { xs: '100%', md: 440 } }}>
              <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
                <Tabs value={depositTab} onChange={(e, newValue) => setDepositTab(newValue)}>
                  <Tab label={t('Deposit payment gateway')} sx={{ width: '50%' }} />
                  <Tab label={t('Deposit via receipt')} sx={{ width: '50%' }} />
                </Tabs>
              </Box>

              <TabPanel value={depositTab} index={0}>
                <DepositPaymentForm selectedCurrency={selectedCurrency} bankCardList={bankCardList} />
              </TabPanel>

              <TabPanel value={depositTab} index={1}>
                <DepositReceiptForm selectedCurrency={selectedCurrency} bankCardList={bankCardList} />
              </TabPanel>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 }, height: '100%' }}>
            <Typography variant='h6' sx={{ fontWeight: 500 }}>
              {t('Tips')}
            </Typography>
            <Typography component={'p'} variant='body3' color='grey.100' sx={{ mt: 2 }}>
              {t(
                'In order to deposit money, you must use bank cards in your own name, which are registered and approved in the user section.'
              )}
            </Typography>
            <Typography component={'p'} variant='body3' color='grey.100' sx={{ mt: 2.5 }}>
              {t(
                'When determining the deposit amount, make sure that the minimum purchase and sale amount in the exchange is {{amount}}.',
                { amount: '$ 11' }
              )}
            </Typography>
          </Card>
        </Grid>
      </Grid>

      <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 }, mt: 2 }}>
        <Typography sx={{ mb: 2, fontWeight: 500 }} variant='h6'>
          {t('List of deposits')}
        </Typography>
        <Typography variant='body3' color={'grey.200'}>
          {t('You can enter the amount, transaction ID, etc. in the search field.')}
        </Typography>

        <Box sx={{ mt: 5 }}>
          <DepositTable />
        </Box>
      </Card>
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && <Box sx={{ mt: 2.5 }}>{children}</Box>}
    </div>
  );
}
