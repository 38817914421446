import { TableRow, TableCell, Typography, Stack, Box } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import SymbolIcon from '../../../../components/SymbolIcon';
import { prettyUppercases } from '../../../../utils';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function ConvertHistoryTableRow({ row, isAdmin = false }) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.language);

  const { fromAmount, firstName, lastName, emailAddress, mobile, status, fromSymbol, toAmount, toSymbol, createDate ,transactionCode} =
    row;

  return (
    <TableRow hover sx={{ '& td': { py: 2, typography: 'body2', color: 'grey.100' } }}>
      <TableCell>{moment(createDate).locale(language).format('YYYY-MM-DD HH:mm')}</TableCell>

      {isAdmin && (
        <TableCell>
          <Typography variant='body3'>
            {firstName} {lastName}
          </Typography>
          {emailAddress && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {emailAddress}
            </Typography>
          )}
          {mobile && (
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {mobile}
            </Typography>
          )}
        </TableCell>
      )}

      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ width: 140 }}>
          <SymbolIcon symbol={fromSymbol} />
          <Typography variant='body3' noWrap sx={{ color: 'grey.100' }}>
            {fromAmount} {fromSymbol}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ width: 140 }}>
          <SymbolIcon symbol={toSymbol} />
          <Typography variant='body3' noWrap sx={{ color: 'grey.100' }}>
            {toAmount} {toSymbol}
          </Typography>
        </Stack>
      </TableCell>
      {/*{isAdmin && (*/}
      {/*  <TableCell>*/}
      {/*      <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>*/}
      {/*        {transactionCode}*/}
      {/*      </Typography>*/}
      {/*  </TableCell>*/}
      {/*)}*/}

        <TableCell>
            <Typography variant='body4' color='grey.300' style={{ display: 'block' }}>
              {transactionCode}
            </Typography>
        </TableCell>


      <TableCell sx={{ width: 120 }} align='center'>
        <Box
          sx={{
            width: 100,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            p: 1,
            bgcolor:
              (status === 'COMPLETED' && 'rgba(48, 224, 161, 0.1)') ||
              (status === 'FAILED' && 'rgba(224, 48, 48, 0.1)') ||
              'rgba(250, 174, 34, 0.1)',
          }}
        >
          <Typography
            variant='body3'
            sx={{
              color:
                (status === 'COMPLETED' && '#30E0A1') ||
                (status === 'FAILED' && 'negative.main') ||
                'rgb(218, 146, 13)',
              lineHeight: 1,
            }}
          >
            {t(prettyUppercases(status))}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
}
