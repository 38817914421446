import React , { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, MenuItem, Stack } from '@mui/material';
import FillRangeDatePicker from '../../../components/FillRangeDatePicker';
import FillTextField from '../../../components/FillTextField';
import ActiveCryptoModal from '../../../components/activeCryptoModal/activeCryptoModal';
import FillSelect from '../../../components/FillSelect';
import ActiveNetworkModal from '../../../components/activeNetworkModal/ActiveNetworkModal';
const TYPES = [
  { value: 'ALL', label: 'ALL' },
  { value: 'NOT_SET', label: 'NOT SET' },

];
const DedicatedWalletTableToolbar = ({
     nameFilter,
     setNameFilter,
     typeFilter,
     setTypeFilter,
     timeFilter,
     setTimeFilter,
     symbolFilter,
     setSymbolFilter,
     networkNameFilter,
     setNetworkNameFilter,
     onFilter
}) => {

  const { t } = useTranslation();
  const [openSymbolModal, setOpenSymbolModal] = useState(false);
  const [openNetworkModal, setOpenNetworkModal] = useState(false);

  const onReset = ()=>{
    setNameFilter('')
    setTypeFilter("ALL")
    setSymbolFilter('')
    setNetworkNameFilter('')
  }
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent='space-between'
      alignItems={'flex-start'}
      spacing={2}
      sx={{ mb: { xs: 3, md: 9 } }}
    >
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Stack sx={{ width: '100%' }} flexDirection={'row'} alignItems={'flex-end'} gap={'15px'} flexWrap={'wrap'}>
          {/*<FillRangeDatePicker*/}
          {/*  value={timeFilter}*/}
          {/*  setValue={setTimeFilter}*/}
          {/*  variant='outlined'*/}
          {/*  fullWidth*/}
          {/*  sx={{ width: { xs: '100%', md: 335 } }}*/}
          {/*  InputProps={null}*/}
          {/*/>*/}
          <FillSelect
            value={typeFilter}
            variant='standard'

            onChange={setTypeFilter}
            label={t('Type')}
            sx={{ width: { xs: '100%', md: 160 } }}
          >
            {TYPES.map((type) => (
              <MenuItem key={type.value} value={type.value} sx={{ typography: 'body2' }}>
                {t(type.label)}
              </MenuItem>
            ))}
          </FillSelect>
          <FillTextField
            value={nameFilter}
            onChange={setNameFilter}
            variant='standard'

            label={t('Name')}
            sx={{ width: { xs: '100%', md: 160 } }}
            isDebounced
          />
          {/*<FillTextField*/}
          {/*  value={symbolFilter}*/}
          {/*  onChange={setSymbolFilter}*/}
          {/*  variant='standard'*/}

          {/*  label={t('Symbol')}*/}
          {/*  sx={{ width: { xs: '100%', md: 160 } }}*/}
          {/*  onClick={() => setOpenSymbolModal(true)}*/}
          {/*  isDebounced*/}
          {/*  hasCloseButton*/}
          {/*/>*/}
          <FillTextField
            value={networkNameFilter}
            variant='standard'
            onClick={() => setOpenNetworkModal(true)}


            onChange={setNetworkNameFilter}
            label={t('Network name')}
            sx={{ width: { xs: '100%', md: 160 } }}
            isDebounced
          />

        </Stack>

        {/*<Stack direction={'row'} spacing={2} sx={{ mt: 5, mb: 5.75 }}>*/}
        {/*  <Button*/}
        {/*    onClick={onFilter}*/}
        {/*    fullWidth*/}
        {/*    variant='contained'*/}
        {/*    sx={{ maxWidth: 204, typography: 'body3', fontWeight: 500 }}*/}
        {/*  >*/}
        {/*    {t('Filter')}*/}
        {/*  </Button>*/}
        {/*  <Button*/}
        {/*    onClick={onReset}*/}
        {/*    fullWidth*/}
        {/*    variant='outlined'*/}
        {/*    color='inherit'*/}
        {/*    sx={{ maxWidth: 80, typography: 'body3', fontWeight: 500, borderColor: 'grey.400' }}*/}
        {/*  >*/}
        {/*    {t('Reset')}*/}
        {/*  </Button>*/}
        {/*</Stack>*/}

      </Stack>

      <ActiveCryptoModal
        open={openSymbolModal}
        onClose={() => setOpenSymbolModal(false)}
        onSelect={setSymbolFilter}
        title='Select Symbol'
      />
      <ActiveNetworkModal
        open={openNetworkModal}
        onClose={() => setOpenNetworkModal(false)}
        onSelect={setNetworkNameFilter}
        title='Select Network'
      />
    </Stack>
  );
};

export default DedicatedWalletTableToolbar;