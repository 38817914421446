import { Stack, Card, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import { FormProvider, RHFSwitch, RHFTextField } from '../../../../components/hookForm';
import useNewNetworkForm from './hooks/useNewNetworkForm';
import useNewNetworkSubmit from './hooks/useNewNetworkSubmit';

const NewNetwork = () => {
  const { t } = useTranslation();

  const { handleSubmit, isSubmitting, methods, status } = useNewNetworkForm();

  const { onSubmit } = useNewNetworkSubmit();

  return (
    <Stack sx={{ padding: { xs: '0 25px', lg: '0' } }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ mt: 12, width: { xs: '100%', md: '50%', lg: '40%' } }}>
          <Stack spacing={2.5}>
            <RHFTextField name='name' label={t('Name')} variant='standard' fullWidth />

            <RHFTextField name='persian' label={t('Name Persian')} variant='standard' fullWidth />

            <RHFTextField name='symbol' label={t('Symbol')} variant='standard' fullWidth />

            <RHFTextField name='selector' label={t('Selector')} variant='standard' fullWidth />

            <Stack direction='row' alignItems='center' spacing={1} sx={{ mt: '40px !important' }}>
              <RHFSwitch name='status' />
              <Typography variant='body2' sx={{ color: 'grey.400' }}>
                {status ? t('Active') : t('Inactive')}
              </Typography>
            </Stack>
          </Stack>
        </Card>

        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ mt: 6.25, maxWidth: 150 }}
        >
          {t('Save')}
        </LoadingButton>
      </FormProvider>
    </Stack>
  );
};

export default NewNetwork;
