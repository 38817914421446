import { TableRow, TableCell, IconButton } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';

import CustomSwitch from '../../../../components/CustomSwitch';
import Iconify from '../../../../components/Iconify';
import { navigationUrl } from '../../../../navigationUrl';

export default function AdminTableRow({ row, index, onDelete, onChangeStatus }) {
  const navigate = useNavigate();
  const language = useSelector((store) => store.language);

  const { userId, firstName, lastName, emailAddress, isActive, registeredDate } = row;
  const [condition, setCondition] = useState(isActive);

  const handleSwitch = (e) => {
    onChangeStatus(userId, !condition);
    setCondition((prev) => !prev);
  };

  return (
    <TableRow hover sx={{ '& td': { py: 1.5, typography: 'body2' } }}>
      <TableCell align='center' sx={{ width: 80 }}>
        {index + 1}
      </TableCell>

      <TableCell>{firstName}</TableCell>
      <TableCell>{lastName}</TableCell>

      <TableCell>-</TableCell>

      <TableCell>{emailAddress}</TableCell>

      <TableCell align='center' sx={{ width: 150 }}>
        <CustomSwitch checked={condition} name='activation' onClick={handleSwitch} />
      </TableCell>

      <TableCell>
        {registeredDate ? moment(registeredDate).locale(language).format('D MMM YYYY - HH:mm') : '-'}
      </TableCell>

      {/* <TableCell align='center' sx={{ width: 100 }}>
        <IconButton>
          <Iconify icon='eva:slash-outline' />
        </IconButton>
      </TableCell> */}

      <TableCell align='center' sx={{ width: 100 }}>
        <IconButton onClick={() => navigate(navigationUrl.admin_edit(userId))}>
          <Iconify icon='eva:edit-2-outline' />
        </IconButton>
      </TableCell>

      <TableCell align='center' sx={{ width: 100 }}>
        <IconButton onClick={onDelete}>
          <Iconify icon='eva:trash-2-outline' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
