import { Button, Col, DatePicker, Form, Radio, Row, Select, Space, Switch } from 'antd';
import React, { useState } from 'react';
import { InputCustom } from '../../../../../components/InputCustom/inputCustom';
import '../btnBlockStyle.scss';
import classes from './otherForm.module.scss';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const OtherForm = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  React.useEffect(() => {
    form.setFieldsValue({
      // amount: props.formData?.amount,
      twoStepLogin: props.formData?.userInfo?.twoStepLogin,
      changePassword: props.formData?.userInfo?.changePassword,
    });
  });

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          authenticationLevel: values.changeLevel,
          changePassword: values.changePassword,
          twoStepLogin: values.twoStepLogin,
        };
        props.onSaveClick(data);
      })
      .catch((errorInfo) => {});
  };

  const colPropsFormItem = {
    xs: 24,
    sm: 12,
    md: 10,
    lg: 8,
    xl: 6,
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <Form
        layout='horizontal'
        name='otherForm'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete='off'
        form={form}
      >
        <Row gutter={[20, 0]}>
          <Col {...colPropsFormItem}>
            <Form.Item name='amount' direction='horizental'>
              <InputCustom label={t('Amount')} placeholder={t('Amount')} />
            </Form.Item>
          </Col>
          <Col {...colPropsFormItem}>
            <Form.Item name='changeLevel'>
              <Select
                size='small'
                className={classes.comboCustom}
                bordered={false}
                shape='round'
                placeholder={t('Change Level')}
                // defaultValue={() => {
                //     const authLevel = props.formData?.userInfo?.authenticationLevel;
                //     if (authLevel?.includes('ADVANCED'))
                //         return "ADVANCED"
                //     else if (authLevel?.includes('BASIC'))
                //         return "BASIC"
                //     else if (authLevel?.includes('INTERMEDIATE'))
                //         return "INTERMEDIATE"
                //     else if (authLevel?.includes('BASIC') || authLevel === "REGISTERED")
                //         return "BASIC"
                // }}
              >
                <Option value='BASIC' key='BASIC'>
                  {t('BASIC')}
                </Option>
                <Option value='INTERMEDIATE' key='INTERMEDIATE'>
                  {t('INTERMEDIATE')}
                </Option>
                <Option value='ADVANCED' key='ADVANCED'>
                  {t('ADVANCED')}
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 0]}>
          <Col {...colPropsFormItem}>
            <Form.Item valuePropName='checked' name='changePassword' label={t('Change Password')}>
              <Switch />
            </Form.Item>
          </Col>
          <Col {...colPropsFormItem}>
            <Form.Item valuePropName='checked' name='twoStepLogin' label={t('Two Step Login')}>
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col {...colPropsFormItem}>
            <Form.Item name='representativeMobile'>
              <InputCustom label={t('Representative Mobile')} placeholder={t('Representative Mobile')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 0]} style={{ marginTop: '20px' }}>
          <Col>
            <Button type='primary' shape='round' htmlType='submit'>
              {t('Save')}
            </Button>
          </Col>
          <Col>
            <Button shape='round'>Send Notifiaction</Button>
          </Col>
          <Col>
            <Button
              type='primary'
              shape='round'
              className='btnBlock'
              onClick={props.onBlockClick}
              loading={props.blockLoading}
            >
              {('Block')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default OtherForm;
