import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Box, Button, Card, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Image from '../../../components/Image';
import Api from '../../../http/serviceApi';
import { SET_PAGE_STATE } from '../../../store/actionTypes';
import FavoriteItem from './favoriteItem/favoriteItem';
import WalletTable from './walletTable/walletTable';
import AddFavoriteModal from './addFavoriteModal/addFavoriteModal';
import CarouselArrows from '../../../components/carousel/CarouselArrows';

import IconBTC from '../../../assets/logo/BTC.svg';
import iconAddFavorite from '../../../assets/addButton.svg';
import iconSampleWallet from '../../../assets/sampleWalletIcon.svg';
import { useSnackbar } from 'notistack';
import { navigationUrl } from '../../../navigationUrl';
import confirmWrapper from '../../../utils/confirm';
import useWallet from '../../../hooks/useWallet';
import LoadingScreen from '../../../components/LoadingScreen';
import WalletChart from './walletChart';
import { fFloat } from '../../../utils/formatNumber';
import Iconify from '../../../components/Iconify';

export default function Wallet() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const [valueVisible, setValueVisible] = useState(false);

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Wallet' },
    });
  }, []);

  const [showModal, setshowModal] = useState(false);
  const [favoriteWalletList, setFavoriteWalletList] = useState([]);

  const { userWallet, loading } = useWallet();

  useEffect(() => {
    fetchUserFavoriteWallet();
  }, []);

  const onAddDeleteFavorite = async (symbol, type = 'add') => {
    let response = {};

    if (type === 'add') {
      response = await Api.addUserFavoriteWallet(symbol);
    } else {
      if (!(await confirmWrapper(t('Are you sure to remove this symbol from your favorites list?')))) {
        return false;
      }

      response = await Api.deleteUserFavoriteWallet(symbol);
    }

    if (response?.status === 'SUCCESS') {
      enqueueSnackbar(
        type === 'add'
          ? t('{{symbol}} added to favorites successfully.', { symbol })
          : t('{{symbol}} deleted from your favorites.', { symbol }),
        { variant: 'success' }
      );
      fetchUserFavoriteWallet();
    } else if (response?.status === 'FAILURE') {
      enqueueSnackbar(response.message, { variant: 'error' });
    }

    return true;
  };

  const fetchUserFavoriteWallet = async () => {
    const response = await Api.getUserFavoriteWallet();
    if (response?.status === 'SUCCESS') {
      setFavoriteWalletList(response.fwalletInfos);
    } else if (response?.status === 'FAILURE') {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  const openModal = () => {
    setshowModal(true);
  };

  const closeModal = (e) => {
    setshowModal(false);
  };

  const carouselRef = useRef(null);
  const [selectSlide, setSelectSlide] = useState(0);

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  const sliderSettings = {
    dots: false,
    arrows: false,
    slidesToShow: 3,
    infinite: true,
    swipeToSlide: false,
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current, next) => setSelectSlide(next),
    responsive: [
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Box sx={{ px: { xs: 3, lg: 0 } }}>
      <Grid container sx={{ height: { xs: 500, sm: 240 } }} spacing={2.5}>
        <Grid item xs={12} sm={2.5} xl={2}>
          <Button
            sx={{
              border: '2px dashed',
              borderColor: 'grey.600',
              width: '100%',
              height: '100%',
              borderRadius: '12px',
            }}
            onClick={openModal}
          >
            <Stack alignItems={'center'}>
              <Image src={iconAddFavorite} alt='' sx={{ width: { xs: 90, sm: 70, md: 90 }, mb: 4 }} />
              <Typography variant='body3' color='grey.300'>
                {t('Add New favorite')}
              </Typography>
            </Stack>
          </Button>
        </Grid>

        <Grid item xs={12} sm={9.5} xl={10}>
          <Box sx={{ position: 'relative' }}>
            {favoriteWalletList?.length > 0 && (
              <CarouselArrows
                filled
                onPrevious={handlePrevious}
                onNext={handleNext}
                customIcon={'eva:arrow-ios-forward-fill'}
                selectSlide={selectSlide}
                sx={{
                  ml: '-20px',
                  '& .arrow': {
                    mt: '-14px',
                    '&.left': { left: -16 },
                    '&.right': { right: -16 },
                    '& button': { width: 28, height: 28, borderRadius: '50%', p: 0.75 },
                  },
                }}
              >
                <Slider ref={carouselRef} {...sliderSettings}>
                  {favoriteWalletList?.map((item) => (
                    <FavoriteItem
                      key={item.symbol}
                      chartType='area'
                      data={item}
                      id={item?.symbol}
                      onDelete={() => onAddDeleteFavorite(item?.symbol, 'delete')}
                    />
                  ))}

                  {favoriteWalletList.length < 3 &&
                    new Array(3 - favoriteWalletList.length)
                      .fill(1)
                      .map((i, index) => <Box key={index} sx={{ pl: '20px' }} />)}
                </Slider>
              </CarouselArrows>
            )}
          </Box>
        </Grid>
      </Grid>

      <Card sx={{ mt: { xs: 0, sm: 2 }, mb: 2, px: { xs: 2, md: 3, lg: 4, xl: 6 } }}>
        <Grid container spacing={{ xs: 4, md: 0 }}>
          <Grid item xs={12} md={4} lg={3.8} xl={3.3}>
            <Stack
              alignItems={{ xs: 'center', md: 'flex-start' }}
              sx={{ float: { xs: 'none', md: 'left' }, width: { xs: '100%', lg: 260 } }}
            >
              <Stack direction='row' alignItems='center' spacing={3}>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                  <Image src={IconBTC} alt='' sx={{ width: 36 }} />
                  <Typography variant='h5'>BTC</Typography>
                </Stack>
                <IconButton onClick={() => setValueVisible((prev) => !prev)}>
                  <Iconify icon={valueVisible ? 'ant-design:eye-outlined' : 'ant-design:eye-invisible-outlined'} />
                </IconButton>
              </Stack>

              <Typography variant='body2' color='grey.400' sx={{ mt: 4 }}>
                {t('Total Balance')}
              </Typography>

              <Typography variant='h4' sx={{ fontWeight: 600 }}>
                {valueVisible ? userWallet?.btcValue : '***'}
              </Typography>

              <Typography variant='h5' color={'grey.300'} sx={{ fontWeight: 400 }}>
                {valueVisible ? userWallet?.usdtValue : '***'} USD
              </Typography>

              <Stack direction={'row'} sx={{ mt: 4 }} spacing={{ xs: 1, lg: 2 }}>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => navigate(navigationUrl.withdraw_crypto_request)}
                  sx={{ width: { xs: 100, lg: 123 }, height: { xs: 40, lg: 47 } }}
                >
                  {t('Withdraw')}
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => navigate(navigationUrl.deposit_crypto_request)}
                  sx={{ width: { xs: 100, lg: 123 }, height: { xs: 40, lg: 47 } }}
                >
                  {t('Deposit')}
                </Button>
              </Stack>
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            lg={4.4}
            xl={5.4}
            sx={{
              borderLeft: { xs: 'none', md: `1px solid ${theme.palette.grey[600]}` },
              borderRight: { xs: 'none', md: `1px solid ${theme.palette.grey[600]}` },
            }}
          >
            <Stack
              direction='row'
              justifyContent={'space-between'}
              sx={{
                width: { xs: '100%', lg: 340, xl: 390 },
                margin: 'auto',
                textAlign: 'center',
                mt: 2,
              }}
            >
              <WalletChart userWallet={userWallet} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={4} lg={3.8} xl={3.3}>
            <Stack
              alignItems={{ xs: 'center', md: 'flex-start' }}
              sx={{ float: { xs: 'none', md: 'right' }, width: { xs: '100%', md: 220, lg: 260 } }}
            >
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Stack
                  justifyContent={'center'}
                  alignItems={'center'}
                  sx={{ backgroundColor: 'rgb(114, 220, 96, 0.1)', width: 42, height: 42, borderRadius: '100%' }}
                >
                  <Image src={iconSampleWallet} alt='' sx={{ width: 22 }} />
                </Stack>
                <Typography variant='h5'>USD</Typography>
              </Stack>

              <Typography variant='body2' color='grey.400' sx={{ mt: 3 }}>
                {t('Your inventory')}
              </Typography>

              <Typography variant='h4' sx={{ fontWeight: 600 }}>
                {valueVisible ? fFloat(userWallet?.dollar) : '***'} USD
              </Typography>

              {/* <Typography variant='h5' color={'grey.300'} sx={{ fontWeight: 400 }}>
                {userWallet?.rial} {t('Rial')}
              </Typography> */}

              <Stack direction={'row'} sx={{ mt: 8.5 }} spacing={2}>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => navigate(navigationUrl.fiatWithdraw)}
                  sx={{ width: { xs: 100, lg: 123 }, height: { xs: 40, lg: 47 } }}
                >
                  {t('Withdraw')}
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => navigate(navigationUrl.fiatDeposit)}
                  sx={{ width: { xs: 100, lg: 123 }, height: { xs: 40, lg: 47 } }}
                >
                  {t('Deposit')}
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Card>

      <WalletTable
        dataSource={userWallet.walletInfos}
        loading={loading}
        valueVisible={valueVisible}
        setValueVisible={setValueVisible}
      />

      <AddFavoriteModal
        open={showModal}
        onClose={closeModal}
        favoriteList={favoriteWalletList}
        onSelectionChange={onAddDeleteFavorite}
      />
    </Box>
  );
}
