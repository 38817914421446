import { Box, Stack, Table, TableBody, TableContainer, TablePagination, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../../../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../../../components/table';
import useTable from '../../../../../../hooks/useTable';
import Api from '../../../../../../http/adminHttp/serviceApiAdmin';
import AdminUserTableRow from './adminUserTableRow';
import AdminUserToolbar from './adminUserToolbar';
import { useSelector } from 'react-redux';
import { exportExcel } from '../../../../../../utils/excel';
import moment from 'jalali-moment';
import { prettyUppercases } from '../../../../../../utils';
import { exportPDF } from '../../../../../../utils/pdf';

const TABLE_HEAD = [
  { id: 'num', label: 'Num', align: 'center' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'mobile', label: 'Mobile', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'amount', label: 'Amount', align: 'center' },
  { id: 'condition', label: 'Condition', align: 'center' },
  { id: 'level', label: 'Level', align: 'center' },
  { id: 'detail', label: 'Detail', align: 'center' },
];

export default function AdminUserTab({ userId }) {
  const language = useSelector((store) => store.language);

  const { t } = useTranslation();

  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  const [tableData, setTableData] = useState([]);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });

  // ----------------------------------------------
  const getAdminUsersCredential = async () => {
    try {
      const filters = { userId };
      const response = await Api.getAdminUsersCredential(page, rowsPerPage, filters);
      if (response) {
        setTableData(response.adminUserInfoList);
        setTablePagination({ totalItems: response.adminUserInfosTotalPages * rowsPerPage });
      }
    } catch (e) {
      setTableData([]);
    }
  };

  // ----------------------------------------------
  useEffect(() => {
    if (userId) getAdminUsersCredential();
  }, [page, rowsPerPage, userId]);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);
  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    const filters = { userId };

    try {
      const response = await Api.getAdminUsersCredential(0, 10000000, filters);

      // generate excel and download
      const config = {
        language,
        fileName: `${t('Admin')} - ${t('Users')}`,
        heads: ['#', t('Name'), t('Mobile'), t('Date'), t('Amount'), t('Condition'), t('Level')],
        columnsWidth: [5, 22, 13, 17, 8, 9, 25],
        list: response.adminUserInfoList.map((item) => [
          `${item.firstName} ${item.lastName}`,
          item.mobileNumber,
          moment(item.registeredDate).locale(language).format('D MMM YYYY - HH:mm'),
          item.amount,
          `${t(prettyUppercases(item.condition))}`,
          `${t(prettyUppercases(item.authenticationLevel))}`,
        ]),
      };
      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }
    setIsExportLoading(false);
  };

  return (
    <>
      <Stack spacing={1} sx={{ mb: 2, mt: 5 }}>
        <Typography variant='h6' fontWeight={500}>
          {t('Users')}
        </Typography>
        <Typography variant='body3' color={'grey.100'}>
          {t('The list of users whose document image has been changed by this admin.')}
        </Typography>
      </Stack>

      <AdminUserToolbar isExportLoading={isExportLoading} onExport={onExport} />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          <Table size='small'>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData.length}
              onSort={onSort}
            />
            <TableBody>
              {tableData === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {tableData?.map((row, index) => (
                    <AdminUserTableRow key={row.id} row={row} index={index} />
                  ))}

                  <TableNoData isNotFound={!tableData?.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={tablePagination.totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage={t('Rows per page')}
          labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
        />
      </Box>
    </>
  );
}
