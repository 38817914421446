import React, { useState } from 'react';
import { Button, Col, Row, Segmented, Table, DatePicker } from 'antd';
import './orderHistoryTableAntdStyles.scss';
import classes from './orderHistoryTable.module.scss';
import { convertTimeStampToDate } from '../../../../../utils/dateConverter';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { SET_ORDER_HISTORY_DATE } from '../../../../../store/actionTypes';
import { useTheme } from '@mui/material';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';

const { RangePicker } = DatePicker;

const OrderHistoryTable = (props) => {
  const theme = useTheme();
  const [date, setDate] = useState({
    startDate: Date.now() - 86400000,
    finishDate: Date.now(),
  });
  const [timeFilter, setTimeFilter] = useState('1 Day');
  const [datePickerValue, setDatePickerValue] = useState(null);
  const tradeHistoryInfos = useSelector((store) => store.tradeHistoryInfos);
  const dispatch = useDispatch();
  const loading_UserTradeInfo = useSelector((store) => store.loading_UserTradeInfo);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: 180,
      render: (key) => <span>{convertTimeStampToDate(key)}</span>,
    },
    {
      title: 'Pair',
      dataIndex: 'symbol',
      key: 'symbol',
      width: 100,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 100,
    },
    {
      title: 'Side',
      dataIndex: 'side',
      key: 'side',
      width: 45,
      render: (key) => (
        <a href='/' className={key === 'BUY' ? classes.sideBuy : classes.sideSell}>
          {key}
        </a>
      ),
    },
    // {
    //     title: 'Average',
    //     dataIndex: 'average',
    //     key: 'average',
    // },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: 100,
    },
    {
      title: 'Executed',
      dataIndex: 'executed',
      key: 'executed',
      width: 100,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 100,
    },
    {
      title: 'Trigger Conditions',
      dataIndex: 'triggerConditions',
      key: 'triggerConditions',
      width: 90,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 150,
    },
    {
      dataIndex: 'all',
      key: 'all',
      width: 30,
      filters: [
        {
          text: 'Canceled',
          value: 'CANCELED',
        },
        {
          text: 'Filled',
          value: 'EXPIRED',
        },
        {
          text: 'New',
          value: 'NEW',
        },
        {
          text: 'Partially Filled',
          value: 'PARTIALLY_FILLED',
        },
        {
          text: 'Pending Cancel',
          value: 'PENDING_CANCEL',
        },
        {
          text: 'Rejected',
          value: 'REJECTED',
        },
      ],
      onFilter: (value, record) => record.state?.indexOf(value) === 0,
    },
  ];

  const subColumns = [
    {
      title: 'Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (key) => <span>{convertTimeStampToDate(key)}</span>,
    },
    {
      title: 'Trading Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Executed',
      dataIndex: 'executed',
      key: 'type',
    },
    {
      title: 'Transaction Fee',
      dataIndex: 'transactionFee',
      key: 'transactionFee',
      render: (key, record) => <span>{record.commission + ' ' + record.commissionAsset}</span>,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Rol',
      dataIndex: 'isMaker',
      key: 'isMaker',
      render: (key) => <span>{key ? 'Maker' : 'Taker'}</span>,
    },
  ];

  const onTimeFilterChange = (key) => {
    setTimeFilter(key);
    switch (key) {
      case '1 Day': {
        dispatch({
          type: SET_ORDER_HISTORY_DATE,
          payload: {
            orderHistoryDate: {
              startDate: Date.now() - 86400000,
              finishDate: Date.now(),
            },
          },
        });
        break;
      }
      case '1 Week': {
        dispatch({
          type: SET_ORDER_HISTORY_DATE,
          payload: {
            orderHistoryDate: {
              startDate: Date.now() - 86400000 * 7,
              finishDate: Date.now(),
            },
          },
        });
        break;
      }
      case '1 Month': {
        dispatch({
          type: SET_ORDER_HISTORY_DATE,
          payload: {
            orderHistoryDate: {
              startDate: Date.now() - 86400000 * 7 * 4,
              finishDate: Date.now(),
            },
          },
        });
        break;
      }
      case '3 Months': {
        dispatch({
          type: SET_ORDER_HISTORY_DATE,
          payload: {
            orderHistoryDate: {
              startDate: Date.now() - 86400000 * 7 * 4 * 3,
              finishDate: Date.now(),
            },
          },
        });
        break;
      }
      default:
        break;
    }
  };

  const onRangePikerChange = (value, dateString) => {
    setDatePickerValue(value);
  };

  const onSearchBtnClick = () => {
    debugger;
    setDate({
      startDate: datePickerValue[0]?.unix() * 1000,
      finishDate: datePickerValue[1]?.unix() * 1000,
    });
  };
  const onResetBtnClick = () => {
    setTimeFilter('1 Day');
  };

  return (
    <div>
      <Row gutter={[10, 0]}>
        <Col>
          <Segmented
            disabled={loading_UserTradeInfo}
            style={{
              backgroundColor: 'transparent',
              color: theme.palette.mode === 'dark' ? '#A5ADCF' : '#000',
              marginTop: '10px',
              marginLeft: '10px',
              marginRight: '20px',
            }}
            onMouseOver={(e) => {
              e.target.style.color = theme.palette.mode === 'dark' ? '#A5ADCF' : '#555';
            }}
            onMouseLeave={(e) => {
              e.target.style.color = theme.palette.mode === 'dark' ? '#A5ADCF' : '#555';
            }}
            options={['1 Day', '1 Week', '1 Month', '3 Months']}
            value={timeFilter}
            onChange={onTimeFilterChange}
          />
        </Col>
        <Col>
          <div
            style={{
              marginLeft: '10px',
              marginTop: '10px',
              marginRight: '20px',
              color: theme.palette.mode === 'dark' ? '#A5ADCF' : '#000',
            }}
          >
            <span>Time</span>
            <RangePicker disabled={loading_UserTradeInfo} bordered={false} onChange={onRangePikerChange} />
          </div>
        </Col>
        <Col>
          <div style={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px', color: '#A5ADCF' }}>
            <Button
              type='default'
              onClick={onSearchBtnClick}
              disabled={!datePickerValue}
              style={{ color: theme.palette.mode === 'dark' ? '#fff' : '#000' }}
            >
              Search
            </Button>

            <Button
              type='text'
              onClick={onResetBtnClick}
              disabled={loading_UserTradeInfo}
              style={{ color: theme.palette.mode === 'dark' ? '#fff' : '#000' }}
            >
              Reset
            </Button>
          </div>
        </Col>
      </Row>

      <Table
        direction='horizental'
        className={theme.palette.mode === 'dark' ? 'orderHistoryTableStyle' : 'orderHistoryTableStyleLight'}
        dataSource={tradeHistoryInfos}
        columns={columns}
        rowKey='createdDate'
        pagination={{ defaultPageSize: 20 }}
        bordered={false}
        loading={loading_UserTradeInfo}
        scroll={{ x: 800, y: 250 }}
        expandedRowRender={(record) => {
          let array = [];
          array.push(record);
          return (
            <>
              <Row align='middle' gutter={[20, 10]} className={classes.expandTextContainer}>
                <Col>
                  <div>
                    <span className={classes.title}>Time Updated: </span>
                    <span className={classes.txt}>{convertTimeStampToDate(record.updateTime)}</span>
                  </div>
                </Col>
                <Col>
                  <div>
                    <span className={classes.title}>Order No: </span>
                    <span className={classes.txt}>{record.orderId}</span>
                  </div>
                </Col>
              </Row>
              <Table columns={subColumns} dataSource={array} pagination={false} />
            </>
          );
        }}
        size='small'
      />
    </div>
  );
};

export default OrderHistoryTable;
