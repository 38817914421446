import React from 'react';
import { Stack, Button, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

const ButtonsComponent = ({ userInfo, showSaveButton = true, isSubmitting, setBlockModal, setNotifModal }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 2, md: 5 }}
      sx={{ width: { xs: '100%', md: '80%' }, mt: 5 }}
      alignItems={'center'}
    >
      {showSaveButton && (
        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{
            maxWidth: 266,
            typography: 'body3',
            fontWeight: 500,
            '&.Mui-disabled': { background: theme.palette.grey[400] },
          }}
        >
          {t('Save')}
        </LoadingButton>
      )}

      <Button
        fullWidth
        size='large'
        variant='outlined'
        color='inherit'
        sx={{ maxWidth: 266, typography: 'body3', fontWeight: 500 }}
        onClick={() => setNotifModal(true)}
      >
        {t('Send Notification')}
      </Button>

      <Button
        fullWidth
        size='large'
        variant='contained'
        color='error'
        sx={{
          maxWidth: 266,
          typography: 'body3',
          fontWeight: 500,
          backgroundColor: 'rgba(250, 34, 86, 0.2)',
          color: '#FA2256',
          '&:hover': { color: 'grey.0' },
        }}
        onClick={() => setBlockModal(true)}
      >
        {userInfo?.active ? t('Block') : t('Unblock')}
      </Button>
    </Stack>
  );
};

export default ButtonsComponent;
